import { Injectable } from '@angular/core';
import { Route, Routes } from '@angular/router';

import { flatten } from 'lodash-es';

import { ProductInjector } from '../products/product-injector';
import { ROUTE_PROCESSOR, RouteProcessor } from './route-processor';

/**
 * @stable
 */
@Injectable({
    providedIn: 'root',
})
export class RouteProcessorService {
    constructor(private productInjector: ProductInjector) {}

    processRoutes(routes: Routes[]): Routes {
        return flatten(routes)
            .map((r) => this.processRoute(r)!)
            .filter((r) => r);
    }

    private processRoute(route: Route) {
        let processedRoute: Route | null = route;
        for (const processor of this.productInjector.getMultiple<RouteProcessor>(ROUTE_PROCESSOR)) {
            processedRoute = processor.process(route);
            if (processedRoute == null) {
                return null;
            }
        }

        if (processedRoute.children) {
            processedRoute.children = processedRoute.children.map((c) => this.processRoute(c)!).filter((c) => c);
        }

        return processedRoute;
    }
}
