import { BetBuilderProvider } from '@cds/betting-offer/add-ons';

import { BetslipGroupInformation } from '../groups/betslip-group-information';
import { HorseRacingPreFix, ParticipantPickType, PickType } from './pick-models';

export abstract class PickId {
    fixtureId: string;
    subscriptionContext: string[] = [];
    parentLinkedEventId?: string;

    protected constructor(readonly id: string) {}

    static isEmpty(id: string | PickId): boolean {
        if (typeof id === 'string') {
            return !id;
        } else {
            return id.isEmpty();
        }
    }

    static Empty(): PickId {
        class EmptyPickId extends PickId {
            constructor() {
                super('');
                this.fixtureId = '';
            }

            getPickType(): PickType {
                throw Error('Empty Pick Id');
            }

            copy(): PickId {
                return new EmptyPickId();
            }
        }

        return new EmptyPickId();
    }

    isEmpty(): boolean {
        return !this.id;
    }

    isEqual(other?: PickId | string): boolean {
        if (!other) {
            return false;
        }

        if (typeof other === 'string') {
            return this.id === other;
        }

        return this.id === other.id;
    }

    toString(): string {
        return this.id;
    }

    abstract getPickType(): PickType;

    abstract copy(): PickId;
}

export class V1PickId extends PickId {
    private static readonly ID_PREFIX = 'v1';
    private static readonly PICK_REG_EX: RegExp = new RegExp(`${V1PickId.ID_PREFIX}-e:(\\d+)/m:(\\d+)/o:(-?\\d+)`);

    private static asString(eventId: string, marketId: number, optionId: number): string {
        return `${V1PickId.ID_PREFIX}-e:${eventId}/m:${marketId}/o:${optionId}`;
    }

    static isId(id: PickId): id is V1PickId;
    static isId(id: string): boolean;
    static isId(id: string | PickId): boolean {
        const asString = typeof id === 'string' ? id : id.toString();

        return V1PickId.PICK_REG_EX.test(asString);
    }

    /**
     * Do not use it. Use pickIdFactory
     *
     * @param id
     */
    static fromString(id: string): V1PickId {
        const matches = id.match(V1PickId.PICK_REG_EX) as RegExpMatchArray;

        return new V1PickId(matches[1] /*eventId*/, +matches[2] /*marketId*/, +matches[3] /*optionId*/);
    }

    constructor(
        public eventId: string,
        public marketId: number,
        public optionId: number,
    ) {
        super(V1PickId.asString(eventId, marketId, optionId));
        this.fixtureId = eventId.toString();
    }

    getPickType(): PickType {
        return PickType.V1Pick;
    }

    copy(): V1PickId {
        return new V1PickId(this.eventId, this.marketId, this.optionId);
    }
}

export abstract class V2PickId extends PickId {
    static isId(id: PickId): id is V2PickId {
        return id instanceof V2PickId;
    }

    protected constructor(
        id: string,
        public override fixtureId: string,
    ) {
        super(id);
    }
}

export class V2ParticipantPickId extends V2PickId {
    protected static readonly ID_PREFIX = 'v2-p';
    private static readonly PICK_REG_EX: RegExp = new RegExp(
        `${V2ParticipantPickId.ID_PREFIX}-(win|fc|cfc|tc|ctc)-f:([\\d:]+)/m:(\\d+)((/p:(\\d+):(\\d+))+)`,
    );

    private static readonly PICK_PARTICIPANT_REG_EX: RegExp = new RegExp('/p:(\\d+):(\\d+)');

    private static getPrefix(pickType: ParticipantPickType): string {
        switch (pickType) {
            case ParticipantPickType.Win:
                return 'win';
            case ParticipantPickType.Forecast:
                return 'fc';
            case ParticipantPickType.CombinationForecast:
                return 'cfc';
            case ParticipantPickType.Tricast:
                return 'tc';
            case ParticipantPickType.CombinationTricast:
                return 'ctc';
        }

        return '';
    }

    private static asString(fixtureId: string, marketId: number, participantIds: { [id: number]: number }, pickType: ParticipantPickType): string {
        const prefix = `${V2ParticipantPickId.ID_PREFIX}-${V2ParticipantPickId.getPrefix(pickType)}-f:${fixtureId}/m:${marketId}/`;
        const entries = Object.entries(participantIds).sort((a, b) => +a[0] - +b[0]);
        const participants: string[] = [];
        for (const [key, value] of entries) {
            participants.push(`p:${key}:${value}`);
        }

        return prefix + participants.join('/');
    }

    static override isId(id: PickId): id is V2ParticipantPickId;
    static override isId(id: string): boolean;
    static override isId(id: string | PickId): boolean {
        const asString = typeof id === 'string' ? id : id.toString();

        return V2ParticipantPickId.PICK_REG_EX.test(asString);
    }

    /**
     * Do not use it. Use pickIdFactory
     *
     * @param id
     */
    static fromString(id: string): V2ParticipantPickId {
        const matches = id.match(V2ParticipantPickId.PICK_REG_EX) as RegExpMatchArray;
        const type = matches[1];
        let pickType: ParticipantPickType;
        switch (type) {
            case 'win':
                pickType = ParticipantPickType.Win;
                break;
            case 'fc':
                pickType = ParticipantPickType.Forecast;
                break;
            case 'cfc':
                pickType = ParticipantPickType.CombinationForecast;
                break;
            case 'tc':
                pickType = ParticipantPickType.Tricast;
                break;
            case 'ctc':
                pickType = ParticipantPickType.CombinationTricast;
                break;
            default:
                throw new Error('Unknown participant pick type');
        }
        const fixtureId = matches[2];
        const marketId = +matches[3];
        const participantIds: { [id: number]: number } = {};
        let match = matches[4];
        while (match.length > 0) {
            const pm = match.match(V2ParticipantPickId.PICK_PARTICIPANT_REG_EX) as RegExpMatchArray;
            participantIds[+pm[1]] = +pm[2];
            match = match.replace(V2ParticipantPickId.PICK_PARTICIPANT_REG_EX, '');
        }

        return pickType === ParticipantPickType.Win
            ? // tslint:disable-next-line:no-use-before-declare
              new V2WinPickId(fixtureId, marketId, +Object.keys(participantIds)[0])
            : new V2ParticipantPickId(fixtureId, marketId, participantIds, pickType);
    }

    constructor(
        fixtureId: string,
        public marketId: number,
        public participantIds: { [id: number]: number },
        public pickType: ParticipantPickType,
    ) {
        super(V2ParticipantPickId.asString(fixtureId, marketId, participantIds, pickType), fixtureId);
    }

    getPickType(): PickType {
        return PickType.V2ParticipantPick;
    }

    copy(): V2ParticipantPickId {
        return new V2ParticipantPickId(this.fixtureId, this.marketId, this.participantIds, this.pickType);
    }

    get participants(): number[] {
        return Object.keys(this.participantIds).map((k) => +k);
    }
}

export class V2WinPickId extends V2ParticipantPickId {
    private static readonly V2WIN_PICK_REG_EX: RegExp = new RegExp(`${V2ParticipantPickId.ID_PREFIX}-win-f:([\\d:]+)/m:(\\d+)((/p:(\\d+):(\\d+))+)`);

    constructor(
        public override fixtureId: string,
        public override marketId: number,
        public participantId: number,
    ) {
        super(fixtureId, marketId, { [participantId]: 1 }, ParticipantPickType.Win);
    }

    static override isId(id: PickId): id is V2WinPickId;
    static override isId(id: string): boolean;
    static override isId(id: string | PickId): boolean {
        const asString = typeof id === 'string' ? id : id.toString();

        return V2WinPickId.V2WIN_PICK_REG_EX.test(asString);
    }

    override copy(): V2WinPickId {
        return new V2WinPickId(this.fixtureId, this.marketId, this.participantId);
    }
}
export class V2OptionMarketPickId extends V2PickId {
    private static readonly ID_PREFIX = 'v2-om';
    private static readonly PICK_REG_EX: RegExp = new RegExp(`${V2OptionMarketPickId.ID_PREFIX}-f:([\\d:]+)/om:(\\d+)/o:(\\d+)/p:(\\d+)`);

    private static asString(fixtureId: string, optionMarketId: number, optionId: number, priceId: number): string {
        return `${V2OptionMarketPickId.ID_PREFIX}-f:${fixtureId}/om:${optionMarketId}/o:${optionId}/p:${priceId}`;
    }

    /**
     * Do not use it. Use pickIdFactory
     *
     * @param id
     */
    static fromString(id: string): V2OptionMarketPickId {
        const matches = id.match(V2OptionMarketPickId.PICK_REG_EX) as RegExpMatchArray;

        return new V2OptionMarketPickId(matches[1] /*fixtureId*/, +matches[2] /*marketId*/, +matches[3] /*optionId*/, +matches[4] /*priceId*/);
    }

    constructor(
        public override fixtureId: string,
        public optionMarketId: number,
        public optionId: number,
        public priceId: number,
    ) {
        super(V2OptionMarketPickId.asString(fixtureId, optionMarketId, optionId, priceId), fixtureId);
    }

    static override isId(id: PickId): id is V2OptionMarketPickId;
    static override isId(id: string): boolean;
    static override isId(id: string | PickId): boolean {
        const asString = typeof id === 'string' ? id : id.toString();

        return V2OptionMarketPickId.PICK_REG_EX.test(asString);
    }

    copy(): V2OptionMarketPickId {
        return new V2OptionMarketPickId(this.fixtureId, this.optionMarketId, this.optionId, this.priceId);
    }

    getPickType(): PickType {
        return PickType.V2OptionMarketPick;
    }
}

export class V2OptionMarketXCastPickId extends V2PickId {
    private static readonly ID_PREFIX = 'v2-om';
    private static readonly PICK_REG_EX: RegExp = new RegExp(
        `${V2OptionMarketXCastPickId.ID_PREFIX}-?(fc|cfc|tc|ctc)?-f:([\\d:]+)/om:(\\d+)((/o:(\\d+):?(\\d+)?)*)((/p:(\\d+):?(\\d+)?)*)`,
    );

    private static readonly PICK_OPTION_REG_EX: RegExp = new RegExp('/o:(\\d+):(\\d+)');
    private static readonly PICK_PRICE_REG_EX: RegExp = new RegExp('/p:(\\d+):(\\d+)');

    private static asString(
        fixtureId: string,
        optionMarketId: number,
        optionIds: { [id: number]: number },
        priceIds: { [id: number]: number },
        pickType: ParticipantPickType,
    ): string {
        const prefix = `${V2OptionMarketXCastPickId.ID_PREFIX}-${V2OptionMarketXCastPickId.getPrefix(pickType)}-f:${fixtureId}/om:${optionMarketId}/`;
        const optionEntries = Object.entries(optionIds).sort((a, b) => +a[0] - +b[0]);
        const options: string[] = [];
        for (const [key, value] of optionEntries) {
            options.push(`o:${key}:${value}`);
        }
        const priceEntries = Object.entries(priceIds).sort((a, b) => +a[0] - +b[0]);
        const prices: string[] = [];
        for (const [key, value] of priceEntries) {
            prices.push(`p:${key}:${value}`);
        }

        return prefix + options.join('/') + '/' + prices.join('/');
    }

    /**
     * Do not use it. Use pickIdFactory
     *
     * @param id
     */
    static fromString(id: string): V2OptionMarketXCastPickId {
        const matches = id.match(V2OptionMarketXCastPickId.PICK_REG_EX) as RegExpMatchArray;
        const type = matches[1];
        let pickType: ParticipantPickType;
        switch (type) {
            case HorseRacingPreFix.Forecast:
                pickType = ParticipantPickType.Forecast;
                break;
            case HorseRacingPreFix.CombinationForecast:
                pickType = ParticipantPickType.CombinationForecast;
                break;
            case HorseRacingPreFix.Tricast:
                pickType = ParticipantPickType.Tricast;
                break;
            case HorseRacingPreFix.CombinationTricast:
                pickType = ParticipantPickType.CombinationTricast;
                break;
            default:
                throw new Error('Unknown option market pick type');
        }
        const fixtureId = matches[2];
        const marketId = +matches[3];
        const optionIds: { [id: number]: number } = {};
        const prices: { [id: number]: number } = {};
        let match = matches[4];
        while (match.length > 0) {
            const pm = match.match(V2OptionMarketXCastPickId.PICK_OPTION_REG_EX) as RegExpMatchArray;
            optionIds[+pm[1]] = +pm[2];
            match = match.replace(V2OptionMarketXCastPickId.PICK_OPTION_REG_EX, '');
        }
        let pricematch = matches[8];
        while (pricematch.length > 0) {
            const pm = pricematch.match(V2OptionMarketXCastPickId.PICK_PRICE_REG_EX) as RegExpMatchArray;
            prices[+pm[1]] = +pm[2];
            pricematch = pricematch.replace(V2OptionMarketXCastPickId.PICK_PRICE_REG_EX, '');
        }

        return new V2OptionMarketXCastPickId(fixtureId, marketId, optionIds, prices, pickType);
    }

    constructor(
        public override fixtureId: string,
        public optionMarketId: number,
        public optionIds: { [id: number]: number },
        public priceIds: { [id: number]: number },
        public pickType: ParticipantPickType,
    ) {
        super(V2OptionMarketXCastPickId.asString(fixtureId, optionMarketId, optionIds, priceIds, pickType), fixtureId);
    }

    static override isId(id: PickId): id is V2OptionMarketXCastPickId;
    static override isId(id: string): boolean;
    static override isId(id: string | PickId): boolean {
        const asString = typeof id === 'string' ? id : id.toString();

        return V2OptionMarketXCastPickId.PICK_REG_EX.test(asString);
    }

    copy(): V2OptionMarketXCastPickId {
        return new V2OptionMarketXCastPickId(this.fixtureId, this.optionMarketId, this.optionIds, this.priceIds, this.pickType);
    }

    getPickType(): PickType {
        return PickType.V2OptionMarketXcastPick;
    }

    private static getPrefix(pickType: ParticipantPickType): string {
        switch (pickType) {
            case ParticipantPickType.Forecast:
                return 'fc';
            case ParticipantPickType.CombinationForecast:
                return 'cfc';
            case ParticipantPickType.Tricast:
                return 'tc';
            case ParticipantPickType.CombinationTricast:
                return 'ctc';
        }

        return '';
    }
}

export class BetBuilderPickId extends V2PickId {
    private static readonly ID_PREFIX = 'bb';
    private static readonly PICK_REG_EX: RegExp = new RegExp(`${BetBuilderPickId.ID_PREFIX}-e:([\\d:]+)/f:([\\d:]+)/om:(\\d+)/o:(\\d+)/p:(\\d+)`);

    private static asString(eventId: string, fixtureId: string, optionMarketId: number, optionId: number, priceId: number): string {
        const event = eventId.indexOf(':') !== -1 ? eventId.split(':').pop() : eventId;

        return `${BetBuilderPickId.ID_PREFIX}-e:${event}/f:${fixtureId}/om:${optionMarketId}/o:${optionId}/p:${priceId}`;
    }

    static override isId(id: PickId): id is BetBuilderPickId;
    static override isId(id: string): boolean;
    static override isId(id: string | PickId): boolean {
        const asString = typeof id === 'string' ? id : id.toString();

        return BetBuilderPickId.PICK_REG_EX.test(asString);
    }

    /**
     * Do not use it. Use pickIdFactory
     *
     * @param id
     */
    static fromString(id: string): BetBuilderPickId {
        const matches = id.match(BetBuilderPickId.PICK_REG_EX) as RegExpMatchArray;

        return new BetBuilderPickId(
            matches[1] /*eventId*/,
            matches[2] /*fixtureId*/,
            +matches[3] /*marketId*/,
            +matches[4] /*optionId*/,
            +matches[5] /*priceId*/,
        );
    }

    constructor(
        public eventId: string,
        fixtureId: string,
        public optionMarketId: number,
        public optionId: number,
        public priceId: number,
    ) {
        super(BetBuilderPickId.asString(eventId, fixtureId, optionMarketId, optionId, priceId), fixtureId);
    }

    getPickType(): PickType {
        return PickType.BetBuilderPick;
    }

    copy(): BetBuilderPickId {
        return new BetBuilderPickId(this.eventId, this.fixtureId, this.optionMarketId, this.optionId, this.priceId);
    }
}

export class GroupPickId extends PickId {
    private static readonly ID_PREFIX = 'GROUP';
    private static readonly PICK_REG_EX: RegExp = new RegExp(`${GroupPickId.ID_PREFIX}-e:([^/]+)(?:\/(.+))?`);

    private static asString(groupInfo: BetslipGroupInformation): string {
        return `${GroupPickId.ID_PREFIX}-e:${groupInfo.groupId}/${groupInfo.provider}`;
    }

    static isId(id: PickId): id is GroupPickId;
    static isId(id: string): boolean;
    static isId(id: string | PickId): boolean {
        const asString = typeof id === 'string' ? id : id.toString();

        return GroupPickId.PICK_REG_EX.test(asString);
    }

    /**
     * Do not use it. Use pickIdFactory
     *
     * @param id
     */
    static fromString(id: string): GroupPickId {
        const matches = id.match(GroupPickId.PICK_REG_EX) as RegExpMatchArray;

        return new GroupPickId({ groupId: matches[1], provider: matches[2] ? (matches[2] as BetBuilderProvider) : BetBuilderProvider.Angstrom });
    }

    constructor(public groupInfo: BetslipGroupInformation) {
        super(GroupPickId.asString(groupInfo));
        this.fixtureId = groupInfo.groupId;
    }

    getPickType(): PickType {
        return PickType.GroupedPicks;
    }

    copy(): GroupPickId {
        return new GroupPickId(this.groupInfo);
    }
}

export type GroupEnabledPickIds = V1PickId | V2OptionMarketPickId | V2ParticipantPickId;
export const isGroupEnabledPickId = (pickId: PickId): pickId is GroupEnabledPickIds =>
    V1PickId.isId(pickId) || V2OptionMarketPickId.isId(pickId) || V2ParticipantPickId.isId(pickId);

export const pickIdFactory = function (id: string): PickId {
    if (V1PickId.isId(id)) {
        // V1 pick
        return V1PickId.fromString(id);
    }
    if (V2ParticipantPickId.isId(id)) {
        // V2 participant pick
        return V2ParticipantPickId.fromString(id);
    }
    if (V2OptionMarketPickId.isId(id)) {
        // V2 Option market pick
        return V2OptionMarketPickId.fromString(id);
    }
    if (V2OptionMarketXCastPickId.isId(id)) {
        return V2OptionMarketXCastPickId.fromString(id);
    }
    if (BetBuilderPickId.isId(id)) {
        return BetBuilderPickId.fromString(id);
    }
    if (PickId.isEmpty(id)) {
        return PickId.Empty();
    }
    if (GroupPickId.isId(id)) {
        return GroupPickId.fromString(id);
    }
    throw new Error('Unknown pick id string');
};

export const pickIdOrNullFactory = function (id: string): PickId | null {
    if (V1PickId.isId(id)) {
        // V1 pick
        return V1PickId.fromString(id);
    }
    if (V2ParticipantPickId.isId(id)) {
        // V2 participant pick
        return V2ParticipantPickId.fromString(id);
    }
    if (V2OptionMarketPickId.isId(id)) {
        // V2 Option market pick
        return V2OptionMarketPickId.fromString(id);
    }
    if (V2OptionMarketXCastPickId.isId(id)) {
        return V2OptionMarketXCastPickId.fromString(id);
    }
    if (BetBuilderPickId.isId(id)) {
        return BetBuilderPickId.fromString(id);
    }
    if (PickId.isEmpty(id)) {
        return PickId.Empty();
    }
    if (GroupPickId.isId(id)) {
        return GroupPickId.fromString(id);
    }

    return null;
};
