import { Injectable } from '@angular/core';

import { ExpiringCache } from '@frontend/sports/common/base-utils';
import { Observable, of } from 'rxjs';

import { ExpiringCacheFactory } from '../common/expiring-cache.factory';
import { CookieProvider } from '../cookie-provider/cookie-provider.service';
import { ApiService } from '../http/api.service';
import { CdnaUserProfile } from './model';

@Injectable({ providedIn: 'root' })
export class CustomerDnaProfileProviderService {
    private cache: ExpiringCache;
    private readonly cookieName: string = 'customerDnaProfileId';

    constructor(
        private cookieProvider: CookieProvider,
        private apiService: ApiService,
        cacheFactory: ExpiringCacheFactory,
    ) {
        this.cache = cacheFactory.create(3600);
    }

    getProfileId(): string | null {
        return this.cookieProvider.getCookie(this.cookieName);
    }

    getProfile(): Observable<CdnaUserProfile | undefined> {
        const profileId = this.getProfileId();

        return profileId ? this.loadProfile(profileId) : of(undefined);
    }

    private loadProfile(profileId: string): Observable<CdnaUserProfile> {
        const source = this.apiService.get<CdnaUserProfile>('customerDna', { profileId });

        return this.cache.getOrCreate(`customerDnaProfile-${profileId}`, source);
    }
}
