import { Component } from '@angular/core';

import { EpcotConfig, TopNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { Observable, map } from 'rxjs';

import { AdaptiveLayoutService } from '../../layout/adaptive-layout.service';
import { NavigationService } from '../navigation.service';

@Component({
    selector: 'ms-navigation',
    templateUrl: 'navigation.html',
})
export class NavigationComponent {
    viewModel$: Observable<{ showSeparator: boolean; isDesktopNavCompact: boolean }>;
    isEpcotEnabled: boolean;

    constructor(
        public layout: AdaptiveLayoutService,
        public navigation: NavigationService,
        public config: TopNavigationConfig,
        public epcotConfig: EpcotConfig,
    ) {
        this.viewModel$ = config.whenReady.pipe(
            map(() => ({
                showSeparator: config.isDesktopNavigationCompact && !!config.topNavigationItems?.length,
                isDesktopNavCompact: config.isDesktopNavigationCompact,
            })),
        );
        this.isEpcotEnabled = this.epcotConfig.isEnabled;
    }
}
