import { now } from 'lodash-es';
import { Observable, ReplaySubject, of, throwError, timer } from 'rxjs';
import { catchError, share } from 'rxjs/operators';

interface CacheItem {
    source: Observable<any>;
    expiration: number;
}

export class ExpiringCache {
    private cacheInternal = new Map<string, CacheItem>();
    private time: number;

    /**
     * Creates an instance of ExpiringCache.
     *
     * @param cacheTime in seconds
     * @memberof ExpiringCache
     */
    constructor(cacheTime: number) {
        if (!cacheTime) {
            throw new Error('Cache time should be defined');
        }

        this.time = cacheTime * 1000;
    }

    getOrCreate<T>(key: string, source: Observable<T>): Observable<T> {
        this.validateCache();

        if (this.cacheInternal.has(key)) {
            return this.getInternal(key);
        }

        return this.create(key, source);
    }

    get<T>(key: string): Observable<T | undefined> {
        this.validateCache();

        if (this.cacheInternal.has(key)) {
            return this.getInternal(key);
        }

        return of(undefined);
    }

    create<T>(key: string, source: Observable<T>): Observable<T> {
        const transformed = source.pipe(
            catchError((error) => {
                this.cacheInternal.delete(key);

                return throwError(error);
            }),
            share({
                connector: () => new ReplaySubject(1),
                resetOnComplete: () => timer(this.time),
                resetOnRefCountZero: () => timer(this.time),
            }),
        );

        this.cacheInternal.set(key, { source: transformed, expiration: this.time + now() });

        return this.getInternal(key);
    }

    has(key: string): boolean {
        this.validateCache();

        return this.cacheInternal.has(key);
    }

    remove(key: string): boolean {
        return this.cacheInternal.delete(key);
    }

    clear(): void {
        this.cacheInternal.clear();
    }

    private validateCache(): void {
        this.cacheInternal.forEach((value, key) => {
            if (value.expiration < now()) {
                this.cacheInternal.delete(key);
            }
        });
    }

    private getInternal<T>(key: string): Observable<T> {
        return this.cacheInternal.get(key)!.source;
    }
}
