import { BetSlipType } from '@bpos';

import { BetPlacementErrorIcon } from '../bet-placement-error-icon';
import { BetslipError } from '../betslip-error';
import { ClientErrorType } from '../client-error-type';
import { PreCheckErrorMixin } from '../pre-check/pre-check-error';

export class RewardTokensSlipTypeError extends PreCheckErrorMixin(BetslipError) {
    constructor(slipType: BetSlipType) {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensSlipTypeError;
        this.hasClientValidation = true;
        this.slipType = slipType;
    }

    readonly slipType: BetSlipType;
}
