import { Injectable, NgZone } from '@angular/core';

import { RealityCheckConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { OnAppInit } from '@frontend/vanilla/core';
import { first } from 'rxjs';

import { TickerInterval, TickerService } from '../../common/ticker.service';
import { CookieProvider } from '../../cookie-provider/cookie-provider.service';
import { ButtonType, DialogType } from '../../modal/dialog/modal-dialog.model';
import { ModalDialogService } from '../../modal/dialog/modal-dialog.service';
import { UserService } from './user.service';

export enum RealityCheckEvent {
    OnLogin = 'OnLogin',
    OnBetPlacement = 'OnBetPlacement',
    OnBetPlacementWithDuration = 'OnBetPlacementWithDuration',
}

const RealityCheckCookie = {
    RealityTime: '',
    FirstBetPlacement: '',
};

@Injectable({
    providedIn: 'root',
})
export class RealityCheckService implements OnAppInit {
    private readonly realityCheckTimerKey = 'REALITY_CHECK_TIMER';
    private readonly BET_PLACE_SUCCESS = 'BET_PLACE_SUCCESS';
    private isSubscribed = false;
    private remainingTime = 0;
    private _dialogText: string;
    private _buttonText: string;

    constructor(
        private userService: UserService,
        private modalDialogService: ModalDialogService,
        private sitecore: Sitecore,
        private cookieProvider: CookieProvider,
        private realityCheckConfig: RealityCheckConfig,
        private tickerService: TickerService,
        private dispatcher: DispatcherService,
        private zone: NgZone,
    ) {
        switch (this.realityCheckConfig.realityCheckEvent) {
            case RealityCheckEvent.OnBetPlacement:
            case RealityCheckEvent.OnBetPlacementWithDuration:
                this.dispatcher
                    .on(this.BET_PLACE_SUCCESS)
                    .pipe(first())
                    .subscribe(() => {
                        this.scheduleNotification();
                        this.setFirstBetPlacementCookie();
                    });

                break;
            case RealityCheckEvent.OnLogin:
                this.userService.onUserLogin$.subscribe(() => this.scheduleNotification());
                break;
        }
        this.userService.onUserLogout$.subscribe(() => this.onUserLogout());
    }

    get dialogText() {
        if (this.realityCheckConfig.realityCheckEvent === RealityCheckEvent.OnBetPlacementWithDuration) {
            this._dialogText = this.sitecore.globalMessages.RealityCheckWithTimerMessage.replace(/{duration}/, this.getTimeDuration());
        }
        if (!this._dialogText) {
            this._dialogText = this.sitecore.globalMessages.RealityCheckMessage;
        }

        return this._dialogText;
    }

    get buttonText() {
        if (!this._buttonText) {
            this._buttonText = this.sitecore.globalMessages.RealityCheckButtonTitle;
        }

        return this._buttonText;
    }

    onAppInit(): void {
        if (this.cookieProvider.getCookie(this.realityCheckConfig.cookieName)) {
            const cookieObj = JSON.parse(this.cookieProvider.getCookie(this.realityCheckConfig.cookieName));
            RealityCheckCookie.FirstBetPlacement = cookieObj.FirstBetPlacement;
        }
        if (this.cookieRemainingTime) {
            this.scheduleNotification();
        }
    }

    private onUserLogout(): void {
        if (this.isSubscribed) {
            this.tickerService.unsubscribe(this.realityCheckTimerKey);
            this.isSubscribed = false;
        }

        this.cookieProvider.deleteCookie(this.realityCheckConfig.cookieName);
    }

    private get realityCheckEnabled(): boolean {
        return Boolean(
            this.userService.isAuthenticated &&
                this.userService.jurisdiction &&
                this.realityCheckConfig.jurisdictions.some((j) => j === this.userService.jurisdiction),
        );
    }

    private showNotification(): void {
        this.updateRemainingTime();

        if (this.remainingTime > 0) {
            return;
        }

        const contentData = {
            type: DialogType.Info,
            title: '',
            firstParagraph: this.dialogText,
            secondParagraph: '',
            buttons: [
                {
                    text: this.buttonText,
                    type: ButtonType.Ok,
                },
            ],
        };

        this.zone.run(() =>
            this.modalDialogService.openMessage(contentData).result.then(() => {
                this.scheduleNotification();
            }),
        );
    }

    scheduleNotification(): void {
        if (!this.realityCheckEnabled) {
            return;
        }

        if (this.cookieRemainingTime > 0) {
            this.updateRemainingTime();
        } else {
            this.resetRemainingTime();
        }

        if (this.isSubscribed) {
            this.tickerService.unsubscribe(this.realityCheckTimerKey);
        }

        this.tickerService.subscribe({
            id: this.realityCheckTimerKey,
            interval: TickerInterval.Minute,
            onTick: () => this.showNotification(),
        });

        this.isSubscribed = true;
    }

    private updateRemainingTime(): void {
        this.remainingTime = (this.cookieRemainingTime || this.realityCheckConfig.updateInterval) - 1;
        this.setRealityCookie(this.remainingTime);
    }

    private get cookieRemainingTime(): number {
        let remaingTime;
        if (this.cookieProvider.getCookie(this.realityCheckConfig.cookieName)) {
            const cookieObj = JSON.parse(this.cookieProvider.getCookie(this.realityCheckConfig.cookieName));
            remaingTime = cookieObj.RealityTime;
        }

        return Number(remaingTime);
    }

    private resetRemainingTime(): void {
        this.remainingTime = this.realityCheckConfig.updateInterval;
        this.setRealityCookie(this.remainingTime);
    }

    private setRealityCookie(value: number): void {
        RealityCheckCookie.RealityTime = value.toString();
        this.cookieProvider.setCookie(this.realityCheckConfig.cookieName, JSON.stringify(RealityCheckCookie), { expires: 1 });
    }

    setFirstBetPlacementCookie(): void {
        RealityCheckCookie.FirstBetPlacement = new Date().toString();
        if (this.cookieProvider.getCookie(this.realityCheckConfig.cookieName)) {
            const cookieObj = JSON.parse(this.cookieProvider.getCookie(this.realityCheckConfig.cookieName));
            if (cookieObj.FirstBetPlacement !== '') RealityCheckCookie.FirstBetPlacement = cookieObj.FirstBetPlacement;
        }
        this.cookieProvider.setCookie(this.realityCheckConfig.cookieName, JSON.stringify(RealityCheckCookie));
    }

    private getTimeDuration(): string {
        let betPlaceTime = new Date();
        if (this.cookieProvider.getCookie(this.realityCheckConfig.cookieName)) {
            const cookieObj = JSON.parse(this.cookieProvider.getCookie(this.realityCheckConfig.cookieName));
            betPlaceTime = new Date(cookieObj.FirstBetPlacement);
        }

        const diff = (new Date().getTime() - betPlaceTime.getTime()) / 1000;
        const diffMs = Math.max(Math.ceil(diff), 0);

        let hours = Math.floor(diffMs / (60 * 60));
        const minutes = Math.floor((diffMs / 60) % 60);
        if (minutes > 58) {
            hours += 1;
        }

        return hours.toString();
    }
}
