import { Injectable } from '@angular/core';

import { EpcotConfig } from '@frontend/sports/common/client-config-data-access';

@Injectable({ providedIn: 'root' })
export class EpcotConfigService {
    constructor(private epcotConfig: EpcotConfig) {}

    isEnabled(module?: EpcotModule): boolean {
        return this.epcotConfig.isEnabled && (!module || this.epcotConfig.modules.includes(module));
    }

    get isShowMyBetsPopup(): boolean {
        return this.isEnabled(EpcotModule.MyBetsBetSlip);
    }
}

export enum EpcotModule {
    QuickBet = 'quickbet',
    OptionPanel = 'optionpanel',
    Scoreboard = 'scoreboard',
    Betslip = 'betslip',
    MyBetsBetSlip = 'mybetsbetslip',
    Search = 'search',
    Teampages = 'teampages',
    Rewards = 'rewards',
    Statsindicator = 'statsindicator',
    Favorites = 'favorites',
    Errors = 'errors',
    TopEvents = 'topevents',
    BetGenerator = 'parlaygenerator',
    Grid = 'grid',
    EventDetails = 'eventdetails',
    PopularBets = 'popularbets',
}
