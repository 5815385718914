import { Pipe, PipeTransform, inject } from '@angular/core';

import { CurrencyPipeInput } from './model';
import { MoneyService } from './money.service';

@Pipe({
    name: 'msCurrency',
})
export class CurrencyPipe implements PipeTransform {
    private readonly moneyService = inject(MoneyService);

    transform(
        input: CurrencyPipeInput,
        symbol: boolean = false,
        fractionSize: number = 2,
        hideEmptyFraction: boolean = false,
        locale?: string,
        currencyCode?: string,
        formatCurrency: boolean = true,
    ): string {
        return this.moneyService.transform(input, symbol, fractionSize, hideEmptyFraction, locale, currencyCode, formatCurrency);
    }
}
