/**
 * We should convert picks from instance objects to plain objects and use these helpers instead of methods or properties of the pick.
 * We need to do this, in order to keep plain objects in the betslip state and to apply easily updates and mutate the object as well serializing it.
 */
import { SportConstant } from '@frontend/sports/common/base-utils';
import { Decimal } from 'decimal.js';

import { IBetslipState } from '../../../base/store/state';
import { BetslipType } from '../../../core/betslip-type';
import { Fraction } from '../../../core/odds/fraction';
import { CalculatedOdds, emptyCalculatedOdds } from '../../../core/odds/odds';
import { OddsConverter } from '../../../core/odds/odds-converter';
import { OddsOperations } from '../../../core/odds/operations';
import { BetslipBetBuilderPick } from '../../../core/picks/betslip-bet-builder-pick';
import { BetslipPick } from '../../../core/picks/betslip-pick';
import { BetslipV1Pick } from '../../../core/picks/betslip-v1-pick';
import { BetslipV2OptionMarketPick } from '../../../core/picks/betslip-v2-option-market-pick';
import { BetslipV2ParticipantPick } from '../../../core/picks/betslip-v2-participant-pick';
import { BetslipV2Pick } from '../../../core/picks/betslip-v2-pick';
import { BetslipV2StandardPick } from '../../../core/picks/betslip-v2-standard-pick';
import { BetslipV2WinParticipantPick } from '../../../core/picks/betslip-v2-win-participant-pick';
import { PickId } from '../../../core/picks/pick-id';
import {
    HorseRaceParticipantPickType,
    HorseRacePickType,
    IBasePrice,
    IPlaceTerms,
    ParticipantPickType,
    PickOddsState,
    PickType,
    PriceType,
} from '../../../core/picks/pick-models';
import {
    BetslipV2HorseRaceOptionMarketPick,
    BetslipV2HorseRaceWinParticipantPick,
    BetslipV2HorseRaceXCastPick,
    BetslipV2OptionMarketXCastRacePick,
} from '../../../core/picks/sport-specific/betslip-v2-horse-race-picks';
import { OverAskFlowState, OverAskMarketTypeChange } from '../../../model/over-ask/over-ask';

/**
 * Check BetslipV2WinParticipantPick::getEachWayOdds
 *
 * @param price
 * @param placeTerms
 */
export function getEachWayOdds(price: IBasePrice, placeTerms: IPlaceTerms): CalculatedOdds {
    if (price.type === PriceType.Fixed && OddsOperations.isOddsValid(price.nativeOdds)) {
        const placeTermsFactor = new Fraction(placeTerms.numerator, placeTerms.denominator);

        const euOdds = OddsConverter.decimalToEachWay(new Decimal(price.nativeOdds.decimals), placeTermsFactor);
        const ukOdds = OddsConverter.fractionToEachWay(Fraction.fromJSON(price.nativeOdds.fractional), placeTermsFactor);
        const usOdds = OddsConverter.usToEachWay(new Decimal(price.nativeOdds.moneyline), placeTermsFactor);

        return {
            decimals: euOdds,
            fractional: ukOdds,
            moneyline: usOdds,
        };
    } else {
        return emptyCalculatedOdds;
    }
}

export const isPickEachWayCapable = function (pick: BetslipPick): pick is BetslipV2WinParticipantPick | BetslipV2OptionMarketPick {
    return (BetslipV2WinParticipantPick.isPick(pick) || BetslipV2OptionMarketPick.isPick(pick)) && pick.isEachWay;
};

const getPickEachWayTerms = function (pick: BetslipPick): IPlaceTerms | null {
    if (isPickEachWayCapable(pick)) {
        return pick.market.placeTerms;
    }

    return null;
};

export function getPickEachWayInfo(pick: BetslipPick): { placeTerms: IPlaceTerms } | null {
    const placeTerms = getPickEachWayTerms(pick);

    return placeTerms ? { placeTerms } : null;
}

export function getPickPrice(pick: BetslipPick): IBasePrice | null {
    if (pick.oddsState !== PickOddsState.Open) {
        return null;
    }
    const currentPrice = pick.currentPrice;
    if (currentPrice) {
        const { type, nativeOdds } = currentPrice;

        return { type, nativeOdds: { ...nativeOdds } };
    }

    return null;
}

export function getPickPriceIds(pick: BetslipPick): number[] {
    if (BetslipV2ParticipantPick.isPick(pick) && pick.participants.length > 1) {
        return pick.prices.map((pr) => pr.id); // When we have pick with multiple participants return their price ids
    }
    const price = pick.currentPrice;
    if (price) {
        return [price.id];
    }

    return [];
}

export function getSku(pick: BetslipPick): string {
    if (pick instanceof BetslipV2StandardPick) {
        const sportPick = pick;

        return [sportPick.sportId, sportPick.leagueId, sportPick.fixture.fixtureId, sportPick.option.id].join('-');
    }

    if (pick instanceof BetslipV1Pick) {
        const sportPick = pick;

        return [sportPick.sportId, sportPick.league.id, sportPick.event.id, sportPick.option.id].join('-');
    }

    if (pick instanceof BetslipBetBuilderPick) {
        const betBuilderPick = pick;

        return [betBuilderPick.sportId, betBuilderPick.leagueId, betBuilderPick.eventId, betBuilderPick.option.id].join('-');
    }

    if (pick instanceof BetslipV2ParticipantPick) {
        const racePick = pick;

        return [
            racePick.sportId,
            racePick.fixture.league ? racePick.leagueId : 0,
            racePick.fixture.fixtureId,
            (racePick.participants && racePick.participants.map((p) => p.fixtureParticipantId).join('_')) || racePick.id,
        ].join('-');
    }

    return '-';
}

export function isEachWay(pickId: PickId, context: IBetslipState): boolean {
    if (context.overAskState.flowState !== OverAskFlowState.None) {
        const pick = context.overAskState.bets.flatMap((b) => b.picks).find((p) => p.pick.id.isEqual(pickId))!;

        if (pick.changes.marketType) {
            return pick.changes.marketType === OverAskMarketTypeChange.EachWay;
        }

        return pick.isEachWay;
    }

    switch (context.types.base.currentSelectedType) {
        case BetslipType.Single:
            return context.types.singleBet.picks[pickId.toString()].isEachWay;
        case BetslipType.Combo:
            return context.types.comboBet.isEachWay;
        case BetslipType.System:
            return context.types.systemBet.isEachWay;
        case BetslipType.EditBet:
            return false;
        default:
            throw new Error('No betslip type selected');
    }
}

export function isSportPick(pick: BetslipPick): boolean {
    if (BetslipBetBuilderPick.isPick(pick)) {
        return false;
    }

    if (BetslipV2Pick.isPick(pick)) {
        return !isRacePick(pick);
    }

    return true;
}

export function isRacePick(pick: BetslipV2Pick): pick is HorseRacePickType {
    return pick.fixture.sportId === SportConstant.Horses || pick.fixture.sportId === SportConstant.Greyhounds;
}

export function isBOGRacePick(pick: BetslipV2Pick): pick is HorseRacePickType {
    return (
        (pick.fixture.sportId === SportConstant.Horses && pick instanceof BetslipV2ParticipantPick) ||
        (pick instanceof BetslipV2HorseRaceOptionMarketPick &&
            pick.pickType === ParticipantPickType.Win &&
            (pick.isStartingPriceAvailable() || pick.isFixedPriceAvailable())) ||
        pick.fixture.sportId === SportConstant.Greyhounds
    );
}

export function isRaceParticipantPick(pick: BetslipV2Pick): pick is HorseRaceParticipantPickType {
    return pick.fixture.sportId === SportConstant.Horses && pick.id.getPickType() === PickType.V2ParticipantPick;
}

export function isXCastPick(pick: BetslipPick) {
    return BetslipV2HorseRaceXCastPick.isPick(pick) || BetslipV2OptionMarketXCastRacePick.isPick(pick);
}

export function isStartingPriceSelected(pick: BetslipPick): boolean {
    return (
        (pick instanceof BetslipV2HorseRaceWinParticipantPick || pick instanceof BetslipV2OptionMarketPick) &&
        pick.currentPrice?.type === PriceType.StartingPrice
    );
}

export function isHybridPick(contexts: string[]): boolean {
    const jointContext = contexts.join('|');

    const regex = /^(?=.*v1)(?=.*v2)/;
    const match = jointContext.match(regex);

    return match !== null;
}
