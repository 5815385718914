import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { VirtualCompetitionImageType } from '@frontend/sports/common/base-utils';
import { ImagesConfig } from '@frontend/sports/common/client-config-data-access';
import { UtilsService } from '@frontend/vanilla/core';

import { EpcotConfigService } from '../../common/epcot-config.service';
import { ImageRootPath } from '../../participant-image/participant-image.component';

@Component({
    selector: 'ms-tournament-groups-participants',
    templateUrl: 'tournament-groups-participants.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class TournamentGroupsParticipantsComponent implements OnInit {
    constructor(
        private imagesConfig: ImagesConfig,
        private utilsService: UtilsService,
        private changeRef: ChangeDetectorRef,
        private epcotConfigService: EpcotConfigService,
    ) {
        this.isEpcotEnabled = this.epcotConfigService.isEnabled();
    }

    private static FALLBACK_IMAGE = 'fallback';
    private static FALLBACK_FLAGS_IMAGE = 'fallback_flag';
    private static FALLBACK_IMAGE_EPCOT = 'fallback_epcot';

    @Input() participantId: string;
    @Input() sportId: number;
    @Input() imageType: VirtualCompetitionImageType;

    fallbackUrl: string;
    participantUrl: string;
    showParticipant = false;
    isEpcotEnabled = false;

    ngOnInit(): void {
        const participantTemplateUrl = this.imagesConfig.participantImageUrl;

        let fallbackImage = this.isEpcotEnabled
            ? TournamentGroupsParticipantsComponent.FALLBACK_IMAGE_EPCOT
            : TournamentGroupsParticipantsComponent.FALLBACK_IMAGE;
        // when we have default imageType show home jursey
        let participantId = this.participantId + '_1';

        if (this.imageType === VirtualCompetitionImageType.Flags) {
            fallbackImage = this.isEpcotEnabled
                ? TournamentGroupsParticipantsComponent.FALLBACK_IMAGE_EPCOT
                : TournamentGroupsParticipantsComponent.FALLBACK_FLAGS_IMAGE;
            participantId = this.participantId;
        }

        this.participantUrl = this.utilsService.format(participantTemplateUrl, ImageRootPath.Live, this.sportId, participantId);
        this.fallbackUrl = this.utilsService.format(participantTemplateUrl, ImageRootPath.Live, this.sportId, fallbackImage);
    }

    participantImageLoad(): void {
        this.showParticipant = true;
        this.changeRef.markForCheck();
    }
}
