import { FixtureViewType } from '@cds/betting-offer';
import { EventParticipant } from 'packages/sports/web/app/src/event-model/model/event.model';

import { BetslipPick } from './betslip-pick';
import { PickId } from './pick-id';
import { IPickTracking, IPrice, PriceType } from './pick-models';
import { SignedName } from './signed-name.model';

export class BetslipUnknownPick extends BetslipPick {
    static isPick(pick: BetslipPick): pick is BetslipUnknownPick {
        return pick instanceof BetslipUnknownPick;
    }

    override get eventParticipants(): EventParticipant[] | undefined {
        return undefined;
    }

    override get eventViewType(): FixtureViewType | undefined {
        return undefined;
    }

    constructor(
        pickId: PickId,
        tracking?: IPickTracking,
        protected legs?: BetslipUnknownPick[],
    ) {
        super();
        this.id = pickId;
        this.market = {
            id: 0,
            isVisible: false,
            isBetBuilderEnabled: false,
        };
        this.priceType = PriceType.NoPrice;
        this.acceptedPrice = {
            price: null,
            isManuallyAccepted: false,
        };
        this.isAvailable = false;
        this.tracking = tracking || { source: '' };
    }

    copy(): BetslipPick {
        return new BetslipUnknownPick(this.id);
    }

    getLegs(): BetslipPick[] {
        return this.legs ?? [this];
    }

    get eventName(): SignedName {
        return {
            name: '',
            signature: '',
        };
    }

    get isLive(): boolean {
        return false;
    }

    isMarketVisible(): boolean {
        return false;
    }

    protected isPriceVisible(): boolean {
        return false;
    }

    get isVirtual(): boolean {
        return false;
    }

    get marketName(): SignedName {
        return {
            name: '',
            signature: '',
        };
    }

    get eventDate(): Date {
        return new Date();
    }

    get regionName(): SignedName {
        return {
            name: '',
            signature: '',
        };
    }

    get competitionName(): SignedName {
        return {
            name: '',
            signature: '',
        };
    }

    get competitionId(): number {
        return 0;
    }

    get leagueName(): SignedName {
        return {
            name: '',
            signature: '',
        };
    }

    get optionName(): SignedName {
        return {
            name: '',
            signature: '',
        };
    }

    get prices(): IPrice[] {
        return [];
    }

    get sportName(): SignedName {
        return {
            name: '',
            signature: '',
        };
    }

    get partitionId(): number | undefined {
        return undefined;
    }

    get legsCount(): number {
        return this.legs?.length ?? 0;
    }

    sportId: number;
}
