@if (tooltipsService.getTooltip(itemName)?.isActive) {
    <div class="ngxp__tooltip tooltip-info" [attr.data-testid]="itemName">
        <div class="float-ui-container ngxp__animation ngxp__inner">
            <span (click)="tooltipsService.closeTooltip()" class="ui-icon ui-close theme-ex"></span>
            <div [vnDynamicHtml]="tooltipsService.getTooltip(itemName)?.text || ''"></div>
            <div class="tooltips-nav">
                @if (tooltipsService.hasPrevious(itemName)) {
                    <span (click)="previous(itemName)">{{ commonMessages.PopoverPreviousText }}</span>
                }
                @if (tooltipsService.hasNext(itemName)) {
                    <span (click)="next(itemName)">{{ commonMessages.PopoverNextText }}</span>
                } @else {
                    <span (click)="tooltipsService.closeTooltip()">{{ commonMessages.PopoverCloseText }}</span>
                }
            </div>
        </div>
    </div>
}
