import { EventEmitter, Injectable, Type } from '@angular/core';

import { StylesService } from '@frontend/vanilla/core';
import { BetslipDigitalModule } from 'packages/sports/common/betslip/digital/betslip-digital.module';
import { QuickBetBuilderDrawerSwitchComponent } from 'packages/sports/common/betslip/modules/quick-bet/components/bet-builder-drawer/quick-bet-builder-drawer-switch.component';

import { ComponentLoaderService, ComponentProxy } from '../deferred/component-loader.service';
import ModuleLoaderService from '../deferred/module-loader.service';
import { LoggerFactory } from '../logging/logger-factory.service';

export interface RightColumnBinding {
    inColumn: boolean;
}

export interface EditMyBetAddPicksContainerMobileComponentBinding {
    picksContainerBottomOffset: EventEmitter<number>;
}

export interface BetslipBarComponentBindings {
    barClick: EventEmitter<void>;
    myBetsClick: EventEmitter<void>;
}

@Injectable({ providedIn: 'root' })
export class BetslipDigitalComponentLoaderService extends ComponentLoaderService<BetslipDigitalModule> {
    moduleName = 'BetslipDigitalModule';

    constructor(moduleLoader: ModuleLoaderService, loggerFactory: LoggerFactory, stylesService: StylesService) {
        super(moduleLoader, loggerFactory, stylesService);
    }

    getBetslipComponent(): ComponentProxy<RightColumnBinding> {
        return this.getComponentProxy((module) => module.betslipComponent);
    }

    getEditBetComponent(): ComponentProxy<RightColumnBinding> {
        return this.getComponentProxy((module) => module.editBetComponent);
    }

    getQuickBetComponent(): ComponentProxy {
        return this.getComponentProxy((module) => module.quickBetContainerComponent);
    }

    getQuickBetBuilderDrawerSwitchComponent(data: Partial<QuickBetBuilderDrawerSwitchComponent>): ComponentProxy {
        const proxy = this.getComponentProxy((module) => module.quickBetBuilderDrawerSwitchComponent);
        proxy.update(data);

        return proxy;
    }

    getEditBetAddPicksContainerMobileComponent(): ComponentProxy<EditMyBetAddPicksContainerMobileComponentBinding> {
        return this.getComponentProxy((module) => module.editBetAddPicksContainerMobileComponent);
    }

    getModule(): Promise<Type<BetslipDigitalModule>> {
        return import(/* webpackChunkName: "betslip-digital" */ 'packages/sports/common/betslip/digital/betslip-digital.module').then(
            (m) => m.BetslipDigitalModule,
        );
    }

    getRewardTokensComponent(): ComponentProxy {
        return this.getComponentProxy((module) => module.rewardTokensBannerComponent);
    }

    getBetslipReturnsMessageComponent(): ComponentProxy {
        return this.getComponentProxy((module) => module.betslipReturnsMessagesComponent);
    }

    getBetslipBarComponent(): ComponentProxy<BetslipBarComponentBindings> {
        return this.getComponentProxy((module) => module.betslipBarComponent);
    }
}
