import { Action, ActionReducer, Creator, ReducerTypes, createReducer } from '@ngrx/store';
import { ActionCreator } from '@ngrx/store/src/models';
import produce from 'immer';

function convertReducer<TState, TAction extends Action = Action>(reducer: ActionReducer<TState, TAction>): ActionReducer<TState, TAction> {
    return (state: TState | undefined, action: TAction) => {
        return produce(state, (draft: TState) => reducer(draft, action)) as TState;
    };
}

export function createImmutableReducer<TState, TAction extends Action = Action>(
    initialState: TState,
    ...ons: ReducerTypes<TState, ActionCreator[]>[]
): ActionReducer<TState, TAction> {
    const reducer = createReducer(initialState, ...ons);

    return convertReducer(reducer);
}

export function combineReducers<TState, TAction extends Action = Action>(
    initialState: TState,
    ...reducers: ActionReducer<TState, TAction>[]
): ActionReducer<TState, TAction> {
    return (state = initialState, action) => {
        let newState = state;
        for (const reducer of reducers) {
            newState = reducer(newState, action);
        }

        return newState;
    };
}

export function isAction<TResult extends Action>(
    action: Action,
    actionFunc: ActionCreator<string, Creator<any[], TResult>>,
): action is TResult & boolean {
    return actionFunc.type === action.type;
}
