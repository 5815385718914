import { NgModule } from '@angular/core';

import { FavouriteEventToggleComponent } from './toggle/favourite-event-toggle.component';
import { FavouriteLeagueToggleComponent } from './toggle/favourite-league-toggle.component';
import { FavouriteParticipantToggleComponent } from './toggle/favourite-participant-toggle.component';

@NgModule({
    imports: [FavouriteEventToggleComponent, FavouriteLeagueToggleComponent, FavouriteParticipantToggleComponent],
    declarations: [],
    exports: [FavouriteEventToggleComponent, FavouriteLeagueToggleComponent, FavouriteParticipantToggleComponent],
})
export class FavouritesModule {}
