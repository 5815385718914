import { BetslipType } from '../../core/betslip-type';
import { SystemSlipType } from '../bet-generation/slip-type';
import { ISingleBetPickState, ISingleBetState } from '../single-bet/state';
import { ISystemBetState } from '../system-bet/state';
import { IBetslipTypeState } from '../types/state';
import { BetslipError } from '../validation/errors/betslip-error';
import { UnderMinimumStakePreCheckError } from '../validation/errors/pre-check/under-minimum-stake-pre-check-error';

export function GetSingleBetStake(singleBet: ISingleBetState, pickId: string): string | null {
    return singleBet.picks[pickId]?.actualStake;
}

export function GetSummaryStake(types: IBetslipTypeState): string | null {
    if (types.base.currentSelectedType === BetslipType.Combo) {
        return types.comboBet?.actualStake;
    } else if (types.base.currentSelectedType === BetslipType.System) {
        return types.systemBet?.actualStake;
    } else if (types.base.currentSelectedType === BetslipType.Teaser) {
        return types.teaserBet.actualStake;
    }

    return null;
}

export function GetStakeForSystemType(systemBetState: ISystemBetState, type: SystemSlipType): string | null {
    const typeStakes = systemBetState.linearTypeStakes ?? {};

    return typeStakes[type.key].actualStake ?? null;
}

const MAX_POSSIBLE_DIGIT = '9';

const canPotentialyTypeDigitsToReachMinStake = (stake: string, minimumStake: string) => {
    if (stake.indexOf('.') < 0 && minimumStake.indexOf('.') >= 0) {
        return true;
    }
    let highestPotentialStake = stake;
    const minStakeDecimalPlaces = minimumStake.split('.')[1]?.length || 0;
    const stakeDecimalPlaces = stake.split('.')[1]?.length || 0;
    for (let i = 0; i < minStakeDecimalPlaces - stakeDecimalPlaces; i++) {
        highestPotentialStake += MAX_POSSIBLE_DIGIT;
    }
    if (highestPotentialStake.charAt(highestPotentialStake.length - 1) === '.') {
        highestPotentialStake = highestPotentialStake.substring(0, highestPotentialStake.length - 1);
    }

    return Number(highestPotentialStake) >= Number(minimumStake);
};

export const HideMinError = (minimumStake: number, isNumpadOpen: boolean, totalStake: string | null) => {
    if (!totalStake) {
        return true;
    }

    const actualStake = Number(totalStake);
    if (isNumpadOpen && actualStake < minimumStake) {
        return canPotentialyTypeDigitsToReachMinStake(totalStake, Number(minimumStake).toString());
    }

    return actualStake >= minimumStake;
};

export function isSameStakeAppliedToAllSingles(picks: Record<string, ISingleBetPickState>): boolean {
    const stakes = Object.values(picks)
        .filter((pick) => pick.isSelected)
        .map((pick) => pick.stake);

    return new Set(stakes).size === 1;
}

export function validateStake(stakeError: BetslipError | undefined, actualStake: string | null, isNumpadOpen: boolean): BetslipError | null {
    if (!stakeError) {
        return null;
    }

    if (!stakeError.hasClientValidation) {
        return null;
    }

    if (stakeError instanceof UnderMinimumStakePreCheckError) {
        const shouldHide = HideMinError(stakeError.minimumStake.toNumber(), isNumpadOpen, actualStake);
        if (shouldHide) {
            return null;
        }
    }

    return stakeError;
}

export function parseStakeFieldStake(stakeString: string | null | undefined): number | null {
    const numberStake = stakeString ? +stakeString : null;

    if (numberStake === 0) {
        // 0 stake should count as no stake being entered
        return null;
    }

    return numberStake;
}
