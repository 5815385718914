import { Directive, HostListener, Input, inject } from '@angular/core';

import { Logger, MenuActionsService, MenuContentItem } from '@frontend/vanilla/core';

/**
 * @whatItDoes Executes click actions defined for the item and registered either in Vanilla or Product side.
 *
 * @howToUse
 *
 * ```
 * <ng-container vnMenuItemAction [item]="item" [origin]="origin" />
 * ```
 * @stable
 */
@Directive({
    standalone: true,
    selector: '[vnMenuItemAction]',
})
export class MenuItemActionDirective {
    private menuActionsService = inject(MenuActionsService);
    private log = inject(Logger);

    @HostListener('click', ['$event']) async onClick($event: Event) {
        await this.click($event);
    }

    @Input() item: MenuContentItem;
    @Input() origin: string;
    @Input() processClick: boolean | undefined;

    async click(event: Event) {
        if (this.processClick !== false) {
            await this.menuActionsService.processClick(event, this.item, this.origin, false).catch((error) => {
                this.log.error('Error while tracking menu item click.', error);
            });
        }
    }
}
