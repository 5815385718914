import { IRewardToken } from '../../../../reward-tokens/reward-tokens.model';
import { BetPlacementErrorIcon } from '../../bet-placement-error-icon';
import { BetslipError } from '../../betslip-error';
import { ClientErrorType } from '../../client-error-type';

export class RewardTokensBetBuilderError extends BetslipError {
    constructor(token: IRewardToken) {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensBetBuilderError;
        this.hasClientValidation = true;
        this.token = token;
    }

    readonly token: IRewardToken;
}
