import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { PrettyUrlsConfig } from '@frontend/sports/common/client-config-data-access';
import { ParsedUrl, UrlService } from '@frontend/vanilla/core';
import { cloneDeep, isEqual, toInteger } from 'lodash-es';

import { RouterEventsService } from '../navigation-core/router-events.service';
import { CompetitionRoute } from '../navigation/navigation.models';

type RegExpMapper = (result: RegExpExecArray) => CompetitionRoute;

@Injectable({
    providedIn: 'root',
})
export class CompetitionRouteService {
    private parsedUrl: ParsedUrl;
    private parsedRoute: CompetitionRoute = {};
    private matchers = new Map<string, RegExp>();
    private mappers = new Map<string, RegExpMapper>();
    protected get base(): string {
        return '(/.+/sports)';
    }

    constructor(
        routerEvents: RouterEventsService,
        private router: Router,
        private urlService: UrlService,
        private urlConfig: PrettyUrlsConfig,
    ) {
        const context = (...options: string[]) => `(/(${options.join('|')}))`;
        const register = (key: string, regex: string, mapper: RegExpMapper) => {
            this.matchers.set(key, new RegExp(regex, 'i'));
            this.mappers.set(key, mapper);
        };

        const couponContext = context(this.urlConfig.translations.coupons);
        const competitionContext = context(this.urlConfig.translations.competitions);
        const conferencesContext = context(this.urlConfig.translations.conferences);
        const tournamentContext = context(this.urlConfig.translations.tournaments);
        const teampagesContext = context(this.urlConfig.translations.teamPages);
        const betsContext = '/' + this.urlConfig.translations.bets;
        const standingsContext = '/' + this.urlConfig.translations.standings;
        const liveContext = context(this.urlConfig.translations.live);
        const worldCupHubContext = context(this.urlConfig.translations.worldCupHub);

        const baseOptions = [
            this.urlConfig.translations.betting,
            this.urlConfig.translations.calendar,
            this.urlConfig.translations.in30minutes,
            this.urlConfig.translations.in60minutes,
            this.urlConfig.translations.in180minutes,
            this.urlConfig.translations.today,
            this.urlConfig.translations.nextRaces,
            this.urlConfig.translations.tomorrow,
            this.urlConfig.translations.after2days,
            this.urlConfig.translations.after3days,
            this.urlConfig.translations.next2days,
            this.urlConfig.translations.next3days,
            this.urlConfig.translations.next5days,
            this.urlConfig.translations.midWeek,
            this.urlConfig.translations.thisWeekend,
        ];
        const eventContext = context(...baseOptions, this.urlConfig.translations.coupons);
        const baseContext = context(...baseOptions);

        const node = '(/(([^/]+)-)?(\\d+))'; // name-identifier with optional name
        const virtualNode = '(/(([^/]+)-)?(0:(\\d+)))';

        register('bets', `${this.base}${betsContext}${node}${node}?${node}?${node}?$`, this.getBetsRoute);
        register('bets-virtual', `${this.base}${betsContext}${node}${node}${virtualNode}$`, this.getBetsVirtualRoute);
        register('sport', `${this.base}${node}${eventContext}?${competitionContext}?$`, this.getSportRoute);
        register('sport-modular', `${this.base}${node}/modular$`, this.getSportRoute); // temporary, needs to be removed after the route switch technique will be included
        register('calendar', `${this.base}${eventContext}$`, this.getCalendarRoute);
        register('conferences', `${this.base}${node}${conferencesContext}${node}?${node}?${node}?$`, this.getConferencesRoute);
        register('betting', `${this.base}${node}${baseContext}${node}?${node}?$`, this.getBettingRoute);
        register('betting-virtual', `${this.base}${node}${baseContext}${node}${virtualNode}${node}?$`, this.getVirtualBettingRoute);
        register('live', `${this.base}${liveContext}${node}${node}?${node}?$`, this.getLiveRoute);
        register('live-conference', `${this.base}${liveContext}${node}${node}?${node}?${node}?$`, this.getLiveConferenceRoute);
        register('live-virtual', `${this.base}${liveContext}${node}${node}${virtualNode}${node}?$`, this.getVirtualLiveRoute);
        register('betting-multi', `${this.base}${node}${baseContext}/(\\d+(,\\d+)*)$`, this.getBettingSportRoute);
        register('coupon', `${this.base}${node}${couponContext}(/${this.urlConfig.translations.betBuilder}|${node})?$`, this.getCouponRoute);
        register('tournament', `${this.base}${node}${tournamentContext}(/([^/]+))?${node}?$`, this.getTournamentRoute);
        register('teamPages', `${this.base}${node}${teampagesContext}(/([^/]+))?${node}?$`, this.getCommon);
        register('standings', `${this.base}${standingsContext}${node}${node}${node}?$`, this.getStandingsRoute);
        register('standings-virtual', `${this.base}${standingsContext}${node}${node}${virtualNode}${node}?$`, this.getStandingsVirtualRoute);
        register(
            'coupon-competition',
            `${this.base}${node}${couponContext}${node}${node}(/${this.urlConfig.translations.betBuilder}|${node})$`,
            this.getCouponCompetitionRoute,
        );
        register(
            'coupon-virtual',
            `${this.base}${node}${couponContext}${node}${virtualNode}(/${this.urlConfig.translations.betBuilder}|${node})$`,
            this.getCouponVirtualCompetitionRoute,
        );
        register('worldCupHub', `${this.base}${node}${worldCupHubContext}`, this.getWorldCupHubRoute);

        routerEvents.currentRoutesRecognized.subscribe((event) => {
            if (event) {
                const url = event.urlAfterRedirects || event.url || this.router.url;
                const parsed = this.urlService.parse(url);

                if (!this.parsedUrl || !isEqual(this.parsedUrl.path(), parsed.path()) || !isEqual(this.parsedUrl.search, parsed.search)) {
                    this.parsedUrl = parsed;
                    this.parsedRoute = this.parseUrl(parsed);
                }
            }
        });
    }

    current(): string {
        // as from vanilla code, they depend on window.location.pathname which is always encoded, so we need to decode
        return decodeURI(this.parsedUrl.url());
    }

    path(): string {
        // as from vanilla code, they depend on window.location.pathname which is always encoded, so we need to decode
        return decodeURI(this.parsedUrl.path());
    }

    params(): CompetitionRoute {
        return cloneDeep(this.parsedRoute);
    }

    parse(url: string): CompetitionRoute {
        const parsed = this.urlService.parse(url);

        return this.parseUrl(parsed);
    }

    private parseUrl(url: ParsedUrl): CompetitionRoute {
        let result: CompetitionRoute = {};

        const decoded = decodeURI(url.path());

        for (const type of this.matchers.keys()) {
            const matcher = this.matchers.get(type);
            const mapper = this.mappers.get(type);

            if (matcher && mapper) {
                const match = matcher.exec(decoded);

                if (match) {
                    result = mapper(match);

                    break;
                }
            }
        }

        return result;
    }

    private getQuery = (key: string) => this.parsedUrl.search.get(key) || undefined;
    private getInteger = (value?: string) => toInteger(value) || undefined;
    private getIntegerArray = (value?: string) => {
        const values = (value || '')
            .split(',')
            .map(this.getInteger)
            .filter((current) => !!current) as number[];

        if (values.length === 0) {
            return;
        }

        if (values.length === 1) {
            return values.pop();
        }

        return values;
    };

    private getSportRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        subContext: result[9],
    });

    private getBettingRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        region: this.getInteger(result[11]),
        regionName: result[10],
        league: this.getIntegerArray(result[15]),
        leagueName: result[14],
    });

    private getConferencesRoute: RegExpMapper = (result) => ({
        ...this.getBettingRoute(result),
        conference: result[19] ? this.getInteger(result[19]) || 0 : undefined,
        conferenceName: result[18],
    });

    private getVirtualBettingRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        region: this.getInteger(result[11]),
        regionName: result[10],
        league: this.getIntegerArray(result[16]),
        leagueName: result[14],
        virtualCompetitionGroup: this.getInteger(result[20]),
        virtualCompetitionGroupName: result[19],
        isVirtual: true,
    });

    private getBettingSportRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        league: this.getIntegerArray(result[8]),
    });

    private getCouponRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        coupon: this.getInteger(result[12]),
        couponName: result[11],
    });

    private getTournamentRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        tournament: result[9],
        league: this.getIntegerArray(result[13]),
        leagueName: result[12],
    });

    private getStandingsRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        context: this.urlConfig.translations.standings,
        leagueName: result[12],
        league: toInteger(result[13]),
        region: toInteger(result[9]),
        regionName: result[8],
    });

    private getStandingsVirtualRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        context: this.urlConfig.translations.standings,
        virtualCompetitionGroup: result[18] !== undefined ? toInteger(result[18]) : undefined,
        virtualCompetitionGroupName: result[17],
        isVirtual: true,
        leagueName: result[12],
        league: toInteger(result[14]),
        region: toInteger(result[9]),
        regionName: result[8],
    });

    private getCalendarRoute: RegExpMapper = (result) => ({
        base: result[1],
        context: result[3],
    });

    private getWorldCupHubRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        context: this.urlConfig.translations.worldCupHub,
    });

    private getBetsRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        league: this.getInteger(result[13]),
        leagueName: result[12],
        region: this.getInteger(result[9]),
        regionName: result[8],
        conference: this.getInteger(result[17]),
        conferenceName: result[16],
        marketOffer: this.getQuery('category'),
        outrightCategory: this.getQuery('outrightCategory'),
        specialCategory: this.getQuery('specialCategory'),
        context: this.urlConfig.translations.bets,
    });

    private getBetsVirtualRoute: RegExpMapper = (result) => ({
        ...this.getCommon(result),
        marketOffer: this.getQuery('category'),
        outrightCategory: this.getQuery('outrightCategory'),
        specialCategory: this.getQuery('specialCategory'),
        context: this.urlConfig.translations.bets,
        isVirtual: true,
        leagueName: result[12],
        league: toInteger(result[14]),
        region: toInteger(result[9]),
        regionName: result[8],
    });

    private getCommon: RegExpMapper = (result) => ({
        base: result[1],
        sport: this.getInteger(result[5]),
        sportName: result[4],
        context: result[7],
        marketTemplate: this.getInteger(this.getQuery('marketTemplate')),
        marketCategory: this.getInteger(this.getQuery('marketCategory')),
        marketOffer: this.getQuery('tab'),
        dynamicOfferCategory: this.getQuery('dynamicCategory'),
    });

    private getLiveRoute: RegExpMapper = (result) => ({
        base: result[1],
        sport: this.getInteger(result[7]),
        sportName: result[6],
        context: result[3],
        region: this.getInteger(result[11]),
        regionName: result[10],
        league: this.getIntegerArray(result[15]),
        leagueName: result[14],
        marketTemplate: this.getInteger(this.getQuery('marketTemplate')),
        marketCategory: this.getInteger(this.getQuery('marketCategory')),
        marketOffer: this.getQuery('tab'),
    });

    private getLiveConferenceRoute: RegExpMapper = (result) => ({
        ...this.getLiveRoute(result),
        conference: this.getInteger(result[19]),
        conferenceName: result[18],
    });

    private getVirtualLiveRoute: RegExpMapper = (result) => ({
        ...this.getLiveRoute(result),
        league: this.getIntegerArray(result[16]),
        virtualCompetitionGroup: this.getInteger(result[20]),
        virtualCompetitionGroupName: result[19],
        isVirtual: true,
    });

    private getCouponCompetitionRoute: RegExpMapper = (result) => ({
        ...this.getBettingRoute(result),
        coupon: this.getInteger(result[20]),
        couponName: result[19],
    });

    private getCouponVirtualCompetitionRoute: RegExpMapper = (result) => ({
        base: result[1],
        sport: this.getInteger(result[5]),
        sportName: result[4],
        context: result[7],
        region: this.getInteger(result[11]),
        regionName: result[10],
        league: this.getIntegerArray(result[16]),
        leagueName: result[14],
        isVirtual: true,
        coupon: this.getInteger(result[21]),
        couponName: result[20],
    });
}
