import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { MessageType } from '@cds/push';
import { BetBuilderOfferUpdateCommand } from '@cds/push/bet-builder-commands';
import {
    FixtureUpdateCommand,
    GameDeleteCommand,
    GameUpdateCommand,
    OptionMarketDeleteCommand,
    OptionMarketUpdateCommand,
    SlimScoreboardCommand,
} from '@cds/push/fixture-commands';
import { ScoreboardSlim } from '@cds/scoreboards/v1';
import { ConnectionConfig } from '@frontend/sports/common/client-config-data-access';

import {
    BetBuilderSubscription,
    CdsPushService,
    CdsSubscription,
    FixtureSlimSubscription,
    GameSubscription,
    OptionMarketSubscription,
    ScoreboardSlimSubscription,
} from '../cds/cds-push.service';
import { FixtureFactory } from '../event-model/mappers/fixture.factory';
import { EventModel } from '../event-model/model/event.model';
import { BaseSubscriptionService, EventSubscription, EventSubscriptionRequest, MessageCallback, MessageHandler } from './base-subscription.service';
import { updateBetbuilderPrecreatedGroup } from './utils';

/**
 * This service should be used for subscribing event for push. Should not be used for
 * detail event. It automatically subscribes to scoreboard slim and all markets that
 * are part of the response. Should be typically used for marquee, live navigation etc.
 *
 * @export
 * @class EventSubscriptionService
 * @extends {BaseSubscriptionService}
 */
@Injectable({ providedIn: 'root' })
export class EventSubscriptionService extends BaseSubscriptionService<EventModel> {
    constructor(
        private fixtureFactory: FixtureFactory,
        push: CdsPushService,
        protected connection: ConnectionConfig,
    ) {
        super(push);
    }

    override subscribe(request: EventSubscriptionRequest<EventModel>[]): EventSubscription<EventModel> {
        if (request.some((current) => !this.isNormalEvent(current.event))) {
            console.warn('No support for detailed event');
        }

        return super.subscribe(request.filter((current) => this.isNormalEvent(current.event)));
    }

    protected getContextSubscriptions(event: EventModel, callback: MessageCallback): CdsSubscription[] {
        const subscriptions: CdsSubscription[] = [];

        subscriptions.push(new FixtureSlimSubscription(event.offerContext, event.id, this.connection.culture, callback));
        subscriptions.push(new ScoreboardSlimSubscription(event.offerContext, event.id, this.connection.culture, callback));

        event.optionGroups.forEach((optionGroup) => {
            if (event.offerSource === OfferSource.V2 || event.hybridFixtureData) {
                subscriptions.push(new OptionMarketSubscription(event.offerContext, event.id, optionGroup.id, this.connection.culture, callback));
            } else {
                subscriptions.push(new GameSubscription(event.offerContext, event.id, optionGroup.id, this.connection.culture, callback));
            }
        });

        if (event.precreatedOptionGroups && event.precreatedOptionGroups.length) {
            const betBuilderSubscriptions = event.precreatedOptionGroups
                .filter((g) => g.builderOptionPricing && g.builderOptionPricing.groupId)
                .map((g) => new BetBuilderSubscription([g.builderOptionPricing.groupId], callback));
            subscriptions.push(...betBuilderSubscriptions);
        }

        return subscriptions;
    }

    protected getHandlers(): Map<MessageType, MessageHandler<EventModel>> {
        const handlers = new Map<MessageType, MessageHandler<EventModel>>();

        handlers.set(MessageType.GameDelete, (evt, cmd) => this.deleteOptionGroup(evt, cmd));
        handlers.set(MessageType.GameUpdate, (evt, cmd) => this.updateOptionGroup(evt, cmd));
        handlers.set(MessageType.OptionMarketDelete, (evt, cmd) => this.deleteOptionGroup(evt, cmd));
        handlers.set(MessageType.OptionMarketUpdate, (evt, cmd) => this.updateOptionGroup(evt, cmd));
        handlers.set(MessageType.ScoreboardSlim, (evt, cmd) => this.updateScoreboard(evt, cmd));
        handlers.set(MessageType.FixtureUpdate, (evt, cmd) => this.updateFixture(evt, cmd));
        handlers.set(MessageType.BetBuilderOfferUpdate, (evt, cmd) => this.updateBetbuilderPrecreatedOffer(evt, cmd));

        return handlers;
    }

    private isNormalEvent(event: EventModel): boolean {
        return !event.optionSets || event.optionSets.length === 0;
    }

    protected deleteOptionGroup(event: EventModel, command: GameDeleteCommand & OptionMarketDeleteCommand): EventModel {
        const optionGroupId = command.gameId || command.marketId;
        this.fixtureFactory.deleteOptionGroup(event, optionGroupId.toString());

        return event;
    }

    protected updateOptionGroup(event: EventModel, command: GameUpdateCommand & OptionMarketUpdateCommand): EventModel {
        const optionGroup = command.game || command.optionMarket;
        this.fixtureFactory.updateOptionGroup(event, optionGroup);

        return event;
    }

    protected updateScoreboard(event: EventModel, command: SlimScoreboardCommand): EventModel {
        const scoreboard = command.scoreboard as ScoreboardSlim;
        this.fixtureFactory.updateScoreboard(event, scoreboard);

        return event;
    }

    protected updateFixture(event: EventModel, command: FixtureUpdateCommand): EventModel {
        this.fixtureFactory.update(event, command.isOpenForBetting, command.stage);

        return event;
    }

    private updateBetbuilderPrecreatedOffer(event: EventModel, command: BetBuilderOfferUpdateCommand): EventModel {
        updateBetbuilderPrecreatedGroup(event, command.sgpId, command.odds, command.suspensionState, command.legs);

        return event;
    }
}
