import { Injectable } from '@angular/core';

import { MarqueeData, MarqueeRequest } from '@cds/query-objects';
import { MarqueeConfig } from '@frontend/sports/common/client-config-data-access';
import { MarqueeTile } from '@frontend/sports/types/components/content';
import { Observable, map } from 'rxjs';

import { CompetitionPageType } from './marquee-tile.model';
import { MarqueeTileService } from './marquee-tile.service';

export interface IMarqueeRequestInfo {
    request: MarqueeRequest;
    tiles: MarqueeTile[];
}

@Injectable({ providedIn: 'root' })
export class MarqueeRequestBuilderService {
    constructor(
        private marqueeConfig: MarqueeConfig,
        private marqueeTileService: MarqueeTileService,
    ) {}

    buildForFixture(fixtureId: string): Observable<IMarqueeRequestInfo> {
        return this.marqueeTileService.getForFixture(fixtureId).pipe(map((sitecoreTiles) => this.buildMarqueeRequest(sitecoreTiles)));
    }

    buildForTeamPagesFixture(fixtureIds: string[]): IMarqueeRequestInfo | undefined {
        const sitecoreTiles = this.marqueeTileService.getForTeamPagesFixture(fixtureIds);
        if (sitecoreTiles.length) {
            return this.buildMarqueeRequest(sitecoreTiles);
        }

        return;
    }

    buildForPriceBoostFixture(): IMarqueeRequestInfo | undefined {
        const sitecoreTiles = this.marqueeTileService.getForPriceBoostFixture();
        if (sitecoreTiles.length) {
            return this.buildMarqueeRequest(sitecoreTiles);
        }

        return;
    }

    buildForCompetition(ids: number[], sportId: number, type: CompetitionPageType): Observable<IMarqueeRequestInfo> {
        return this.marqueeTileService.getForCompetition(ids, sportId, type).pipe(map((tiles) => this.buildMarqueeRequest(tiles)));
    }

    buildMarqueeRequest(sitecoreTiles: MarqueeTile[]): IMarqueeRequestInfo {
        const marqueeRequestData: MarqueeData[] = sitecoreTiles.map((tile, index) => {
            return {
                fixtureId: tile.fixtureId,
                minimumOdds: tile.previousOdds,
                requestIndex: index,
                gameTemplateIds: tile.gameTemplateIds,
                gridGroupId: tile.gridGroupId,
                gridGroupIds: tile.gridGroupIds?.join(','),
                ...(tile.gameId && { gameId: tile.gameId }),
                ...(tile.resultId && { resultId: tile.resultId }),
                ...(tile.optionMarketId && { optionMarketId: tile.optionMarketId }),
                ...(tile.optionId && { optionId: tile.optionId }),
                happenings: tile.optionMarketParameters?.happening,
                marketTypes: tile.optionMarketParameters?.marketTypes,
                periods: tile.optionMarketParameters?.period,
                positions: tile.optionMarketParameters?.position,
                optionGroupId: tile.optionGroupId,
            };
        });
        // filter out only tiles with event after the requestIndex has been set to all tiles
        const marqueeData = marqueeRequestData.filter((md) => md.fixtureId);

        return {
            request: {
                marqueeData,
                take: this.marqueeConfig.maxTiles,
            },
            tiles: sitecoreTiles,
        };
    }
}
