import { Fixture, ImageProfile } from '@cds/betting-offer';
import { BetBuilderFixture } from '@cds/betting-offer/domain-specific/bet-builder';
import { NumberDictionary } from '@frontend/sports/common/base-utils';
import { Widget, WidgetLayoutResponse, WidgetLayoutTemplate, WidgetPage } from '@frontend/sports/types/components/widget';

import { BetBuilderPrecreatedModel } from '../betbuilder/model/bet-builder.model';
import { EventModel, SetTab } from '../event-model/model/event.model';
import { Tile } from '../highlights-marquee/marquee-tile.model';
import { getLeagueId } from './event-details-common.utils';

export interface DetailedEventViewModel {
    model?: EventModel;
    promotedTemplate: number[];
    preCreated?: BetBuilderPrecreatedModel;
    hasMarqueeAvailable: boolean;
    virtualCompetitionDetails?: VirtualCompetitionDetails;
    tiles: Tile[];
    fixture?: Fixture;
    betBuilderFixture?: BetBuilderFixture;
    widgetLayoutResponse?: WidgetLayoutResponse;
}

export interface ModularEventViewModel {
    widgetPage?: WidgetPage;
    layoutTemplate?: WidgetLayoutTemplate;
    groupedWidgets: NumberDictionary<Widget<unknown>[]>;
    widgetPositions: number[];
}

export interface ResultEventModel {
    model?: EventModel;
    preCreated?: BetBuilderPrecreatedModel;
    virtualCompetitionDetails: VirtualCompetitionDetails | undefined;
    tiles?: Tile[];
    fixture?: Fixture;
    betBuilderFixture?: BetBuilderFixture;
}

export enum HeaderContentDensity {
    Tiny,
    Small,
    Medium,
    Large,
    Fullscreen,
    Auto,
}

export enum TreeItemType {
    Sport,
    Region,
    League,
    Event,
    Favourite,
    FavouriteLeague,
}

export enum LeftNavType {
    Live,
    Prematch,
    Competition,
}

export interface NavEventModel {
    data: EventModel;
    selected: boolean;
    showMarkets?: boolean;
    hidden?: boolean; // to hide favourited items from left nav
    favourited?: boolean;
}

export interface BaseTreeItem {
    children: TreeItem[];
}

export interface TreeItem extends BaseTreeItem {
    id: number;
    name: string;
    type: TreeItemType;
    selected: boolean;
    expanded: boolean;
    loading: boolean;
    icon: string;
    events: NavEventModel[];
    count?: number;
    class?: string;
    toggleMarkets?: boolean;
    collapsible: boolean;
    leftNavType: LeftNavType;
    sportId?: number;
    hidden?: boolean; // to hide favourited items from left nav
    hideToggleMarkets?: boolean;
    hideFavorites?: boolean;
    isVirtual?: boolean;
    virtualCompetitionId?: number;
    imageProfile?: ImageProfile;
}

export interface GroupedEvents {
    [leagueId: number]: EventModel[];
}

export class SelectionInfo {
    sportId: number;
    leagueId: number;
    eventId: string;

    constructor(event: EventModel) {
        return {
            sportId: event.sport.id,
            leagueId: getLeagueId(event),
            eventId: event.id,
        };
    }
}

export enum FeatureTab {
    Scoreboard = 1,
    Video = 2,
    Simulation = 3,
    Statistics = 4,
}

export enum MediaTab {
    Scoreboard = 'scoreboard',
    Video = 'video',
    Simulation = 'simulation',
    Statistics = 'statistics',
}

export enum FeatureUrl {
    Scoreboard = 'score',
    Video = 'video',
    Simulation = 'animation',
    Statistics = 'stats',
}

export interface VirtualCompetitionDetails {
    id: number;
    name: string;
    group?: { id: number; name: string };
}

export interface PrepareOptionGroupsParams {
    event: EventModel;
    activeTab?: string | SetTab;
    groupedSetsIds?: number[];
    tag?: string;
    setTags?: string[];
    isSgp?: boolean;
    isPrecreatedBABTab?: boolean;
}

export const DispatcherEvent = {
    EventDetails: {
        Expanded: 'EVENT_DETAILS_EXPANDED',
        SetTab: 'EVENT_DETAILS_SET_TAB',
    },
};
