import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
@Pipe({
    name: 'msTrust',
    standalone: true,
})
export class TrustPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string, type: 'html' | 'url' | 'style' | 'resourceurl'): SafeHtml | SafeStyle | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);
            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);
            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);
            case 'resourceurl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);
        }
        throw new Error(`Can not trust type: ${type}`);
    }
}
