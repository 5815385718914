import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PlainLinkComponent } from '@frontend/vanilla/core';

import { RemoveHrefDirective } from './remove-href.directive';

@NgModule({
    imports: [CommonModule, PlainLinkComponent],
    declarations: [RemoveHrefDirective],
    exports: [RemoveHrefDirective, PlainLinkComponent],
})
export class NavigationCoreModule {}
