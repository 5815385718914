import { Injectable } from '@angular/core';

import { CountItem } from '@frontend/sports/common/base-utils';
import { sumBy } from 'lodash-es';
import { NEVER, Subject, combineLatest, switchMap } from 'rxjs';
import { distinctUntilKeyChanged } from 'rxjs/operators';

import { BreadcrumbsSlotService } from '../breadcrumbs/breadcrumbs-slot.service';
import { AdaptiveLayoutService } from '../layout/adaptive-layout.service';

interface LiveNowLinkData {
    sports: CountItem[];
    url?: string;
}

const loadLiveNowItemComponent = () => import('./live-now-item.component').then((c) => c.LiveNowItemComponent);

@Injectable({ providedIn: 'root' })
export class LiveNowLinkRegisterService {
    private data$ = new Subject<LiveNowLinkData | void>();

    constructor(
        private adaptiveLayout: AdaptiveLayoutService,
        private breadcrumbSlot: BreadcrumbsSlotService,
    ) {
        const layoutState$ = this.adaptiveLayout.stateChange$.pipe(distinctUntilKeyChanged('headerSubNavigation'));

        combineLatest([this.data$, layoutState$])
            .pipe(
                switchMap(([data, state]) => {
                    if (!data?.sports?.length || !state.headerSubNavigation) {
                        this.breadcrumbSlot.clearSlot();

                        return NEVER;
                    }

                    const liveCount = sumBy(data.sports, (sport: CountItem) => sport.counts.live);

                    if (liveCount) {
                        return loadLiveNowItemComponent().then((component) => ({
                            component,
                            inputs: {
                                count: liveCount,
                                liveUrl: data.url,
                                sport: data.sports.length === 1 && data.sports[0].id ? data.sports[0] : undefined,
                            },
                        }));
                    }
                    this.breadcrumbSlot.clearSlot();

                    return NEVER;
                }),
            )
            .subscribe(({ component, inputs }) => this.setLiveButton(component, inputs));
    }

    addLiveButtonWithMultipleSports(sports: CountItem[], url: string): void {
        this.data$.next({ sports, url });
    }

    addLiveButton(sport?: CountItem, url?: string): void {
        this.data$.next({ sports: sport ? [sport] : [], url });
    }

    removeLiveButton(): void {
        this.data$.next();
    }

    private setLiveButton(
        component: any,
        inputs: {
            count: number;
            liveUrl?: string;
            sport?: CountItem;
        },
    ): void {
        this.breadcrumbSlot.setSlot({
            component,
            inputs,
        });
    }
}
