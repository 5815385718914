import { Injectable } from '@angular/core';

import { ARCConfig, ArcUserConfig } from '@frontend/sports/common/client-config-data-access';
import { IProfileConfiguration } from '@frontend/sports/types/components/a-r-c';
import { ClientConfigService, LocalStoreService, RtmsService } from '@frontend/vanilla/core';
import { Observable, merge, of } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { PageVisibilityService } from '../data-refresh/page-visibility.service';
import { UserService } from '../user/services/user.service';
import { IARCProfileConfiguration, ProfileActionName, ProfileMessageStorageKeys } from './arc.model';

@Injectable({
    providedIn: 'root',
})
export class ArcRestrictionService {
    readonly isUserOnPlayBreak$: Observable<boolean> = of(false);
    readonly arcProfileDetails$ = of(this.m2User.arcProfileDetails);

    readonly gamingCrossSellEnabled$ = of(false);
    readonly preBetslipMessageEnabled$ = of(false);
    readonly postBetslipMessageEnabled$ = of(false);
    readonly quickBetEnabled$ = of(false);
    readonly overAskEnabled$ = of(false);

    private readonly playBreakMessages = {
        PLAY_BREAK_START_EVENT: true,
        PLAY_BREAK_END_EVENT: true,
    };

    constructor(
        private rtmsService: RtmsService,
        private m2User: UserService,
        private clientConfig: ClientConfigService,
        private arcConfig: ARCConfig,
        private pageVisibilityService: PageVisibilityService,
        private localStorage: LocalStoreService,
    ) {
        if (arcConfig.isUserProfilingEnabled) {
            this.m2User.onUserLogin$.subscribe(() => {
                this.localStorage.set(ProfileMessageStorageKeys.actionForMessagedisplayPre, ProfileMessageStorageKeys.loginActionPrebet);
                this.localStorage.set(ProfileMessageStorageKeys.actionForMessagedisplayPost, ProfileMessageStorageKeys.loginActionPostbet);
            });
            this.arcProfileDetails$ = this.m2User.onAuthenticationChange$.pipe(
                map(() => m2User.arcProfileDetails),
                startWith(m2User.arcProfileDetails),
                shareReplay(1),
            );
            this.gamingCrossSellEnabled$ = this.arcProfileDetails$.pipe(
                map((profile) => this.getGamingCrossSellState(profile)),
                shareReplay(1),
            );

            this.preBetslipMessageEnabled$ = this.arcProfileDetails$.pipe(
                map((profile) => this.getPreBetslipMessageState(profile)),
                shareReplay(1),
            );
            this.postBetslipMessageEnabled$ = this.arcProfileDetails$.pipe(
                map((profile) => this.getPostBetslipMessageState(profile)),
                shareReplay(1),
            );
            this.quickBetEnabled$ = this.arcProfileDetails$.pipe(
                map((profile) => this.getQuickBetState(profile)),
                shareReplay(1),
            );
            this.overAskEnabled$ = this.arcProfileDetails$.pipe(
                map((profile) => this.getOverAskState(profile)),
                shareReplay(1),
            );
        }
        if (arcConfig.isPlayBreakEnabled) {
            const playBreakStateFromRTMS$ = this.rtmsService.messages.pipe(
                filter((m) => this.playBreakMessages[m.type]),
                switchMap(() => this.clientConfig.reload([ArcUserConfig])),
                map(() => this.m2User.arcPlayBreak),
            );

            const playBreakStateAfterLogin$ = this.m2User.onUserLogin$.pipe(map(() => this.m2User.arcPlayBreak));
            const playBreakStateAfterPageVisibility$ = this.pageVisibilityService.visibilityChange().pipe(
                filter((p) => this.visibilityChange(p.isVisible, p.pageHiddenDuration)),
                switchMap(() => this.clientConfig.reload([ArcUserConfig])),
                map(() => this.m2User.arcPlayBreak),
            );

            this.isUserOnPlayBreak$ = merge(
                playBreakStateFromRTMS$,
                playBreakStateAfterLogin$,
                playBreakStateAfterPageVisibility$,
                of(this.m2User.arcPlayBreak),
            ).pipe(shareReplay(1));
        }
    }

    private visibilityChange(isVisible: boolean, pageHiddenDuration?: number): boolean {
        if (!isVisible) {
            return false;
        }

        const pageHiddenForTooLong =
            (pageHiddenDuration || 0) > this.arcConfig.pageHiddenReloadThreshold && this.arcConfig.pageHiddenReloadThreshold > 0;

        return pageHiddenForTooLong;
    }

    get profileDetails(): Observable<IARCProfileConfiguration> {
        return this.arcProfileDetails$;
    }

    private getGamingCrossSellState(profileDetails: IProfileConfiguration): boolean {
        return (
            profileDetails.profileActions.some((action) => action.name === ProfileActionName.RemoveGamingCrossSell) &&
            this.arcConfig.profileActionsSwitch.isRemoveGamingCrosssellEnabled
        );
    }

    private getPreBetslipMessageState(profileDetails: IProfileConfiguration): boolean {
        return (
            profileDetails.profileActions.some((action) => action.name === ProfileActionName.PreBetSlipMessage) &&
            this.arcConfig.profileActionsSwitch.isBetslipMessagingEnabled
        );
    }

    private getPostBetslipMessageState(profileDetails: IProfileConfiguration): boolean {
        return (
            profileDetails.profileActions.some((action) => action.name === ProfileActionName.PostBetSlipMessage) &&
            this.arcConfig.profileActionsSwitch.isBetslipMessagingEnabled
        );
    }

    private getQuickBetState(profileDetails: IProfileConfiguration): boolean {
        return (
            profileDetails.profileActions.some((action) => action.name === ProfileActionName.RemoveQuickBet) &&
            this.arcConfig.profileActionsSwitch.isRemoveQuickbetEnabled
        );
    }

    private getOverAskState(profileDetails: IProfileConfiguration): boolean {
        return (
            profileDetails.profileActions.some((action) => action.name === ProfileActionName.RemoveOverAsk) &&
            this.arcConfig.profileActionsSwitch.isRemoveOverAskEnabled
        );
    }
}
