import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ms-modal-backdrop',
    template: '',
    standalone: true,
})
export class ModalBackdropComponent {
    @Input() backdropClass: string;
    @HostBinding()
    get class(): string {
        return 'modal-backdrop ' + (this.backdropClass ? ' ' + this.backdropClass : '');
    }
}
