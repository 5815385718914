import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { userStateSelector } from '../selectors';
import { IUserRootState, IUserState } from '../state';

@Injectable({ providedIn: 'root' })
export default class UserStateService {
    constructor(private store: Store<IUserRootState>) {}

    userState$(): Observable<IUserState> {
        return this.store.select(userStateSelector);
    }
}
