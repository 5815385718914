<div [class]="'modal-dialog' + (size ? ' modal-' + size : '') + (centered ? ' modal-dialog-centered' : '')" role="document">
    <div class="modal-content">
        <div class="modal-dialog-header-wrapper" *ngIf="showDialogHeader" id="headerWrapper">
            <div class="header" [class.default-header]="!headerTemplate">
                <div *ngIf="showBackNav" (click)="goBack()" class="header-back">
                    <i class="theme-left"></i>
                </div>
                <ng-container *ngIf="!headerTemplate; else headerTemplate">
                    <ng-container *ngIf="!hideCloseButton">
                        <ng-container *ngIf="showCloseBtnText">
                            <button class="betstation-btn-close" (click)="close()">
                                <span class="text">{{ sitecore.globalMessages.Close }}</span>
                                <i class="close-icon theme-ex" [msAutomation]="'header-close-icon'"></i>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="!showCloseBtnText">
                            <i
                                class="close theme-ex"
                                *msShowInEpcot="{ showInEpcot: false }"
                                (click)="close()"
                                [msAutomation]="'header-close-icon'"></i>
                            <button class="close" *msShowInEpcot="{ showInEpcot: true }" (click)="close()" [msAutomation]="'header-close-icon'">
                                {{ sitecore.globalMessages.Close }}
                            </button>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <div class="header-title">{{ title }}</div>
            </div>
        </div>
        <div class="modal-body"><ng-content></ng-content></div>
    </div>
</div>
