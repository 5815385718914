import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PlainLinkComponent } from '@frontend/vanilla/core';

import { AutomationModule } from '../automation/automation.module';
import { ScrollAdapterComponent } from '../common/scroll-adapter/scroll-adapter.component';
import { CrmPromotionModule } from '../crm-promotion/crm-promotion.module';
import { DirectivesModule } from '../directives/directives.module';
import { TabBarFontResizeDirective } from './tab-bar-font-resize.directive';
import { TabBarComponent } from './tab-bar.component';

@NgModule({
    imports: [CommonModule, DirectivesModule, AutomationModule, CrmPromotionModule, PlainLinkComponent, ScrollAdapterComponent],
    exports: [TabBarComponent, TabBarFontResizeDirective],
    declarations: [TabBarComponent, TabBarFontResizeDirective],
})
export class TabBarModule {}
