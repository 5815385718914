import { OfferSource } from '@cds';
import { Competition, Region } from '@cds/betting-offer/tags';
import { CountItem } from '@frontend/sports/common/base-utils';
import { DomainEvent } from '@frontend/sports/common/dispatcher-utils';
import { ParticipantDetail } from '@frontend/sports/types/components/team-pages';

import { EventParticipant, EventParticipantImage } from '../event-model/model/event.model';
import { FavouritesSport } from '../favourites/favourites.model';
import { LeagueGroup } from '../grid-base/grid.model';
import { ObservableGrid } from '../grid-base/observable-grid';
import { Tile } from '../highlights-marquee/marquee-tile.model';
import { TabBarItem } from '../tab-bar/tab-bar.models';

export interface TeamPagesDetailsModel {
    teampagesGridViewModel: TeamPagesGridViewModel;
    bannerViewModel: BannerViewModel;
    marqueeTiles: Tile[];
    sportCounts: CountItem;
}
export interface TeamPagesNavigationViewModel {
    teams: Team[];
    competitionTabs: TabBarItem<string>[];
    currentSport: FavouritesSport;
    selectedTeam: Team;
    hasFavouritedTeams: boolean;
    popularTeams: ParticipantDetail[];
}
export interface TeamPagesViewModel {
    teamPagesDetailsModel: TeamPagesDetailsModel;
    teamPagesNavigationViewModel: TeamPagesNavigationViewModel;
}

export interface Team {
    name: string;
    id: number;
    participantImage?: EventParticipantImage;
    sportIcon?: string;
    isFavourited: boolean;
    isSelected: boolean;
    sport?: FavouritesSport;
    favouriteParticipant?: EventParticipant;
    offerSource?: OfferSource;
    competitionId?: string;
    teamType?: FavouriteType;
}

export enum FavouriteType {
    League = 'L',
    Participant = 'P',
    ParticipantV2 = 'P2',
    Fixture = 'F',
    FixtureV2 = 'F2',
}

export enum FixtureView {
    Grid,
    GridList,
    Standings,
}

export enum FixtureTab {
    Matches = 1,
    Outrights = 2,
    Standings = 3,
    Specials = 4,
}

export interface BannerViewModel {
    team: Team;
    backgroundImage: string;
}

export class TeamPagesDispatcherEvents {
    static onPopupClosed = new DomainEvent<string>('ON_POPUP_CLOSED');
}

export enum TeamMenuItem {
    Showmore = '9999',
    MyTeams = '0',
}
export interface TeamPageCompetition {
    id: string;
    name: string;
    sportId: number;
}

export interface TeamPagesGridModel {
    tabs: TabBarItem[];
    gridModel?: ObservableGrid;
    view?: FixtureView;
    standings?: CountItem;
    currentStandingsGroups?: LeagueGroup[];
    currentStandingsSport?: CountItem;
    currentStandings?: CountItem;
    groups?: LeagueGroup[];
    noMarketsForTeam?: boolean;
    loading?: boolean;
    fixtureIds?: string[];
}

export interface TeamPagesGridViewModel {
    teamPagesGridModel: TeamPagesGridModel;
    selectedTeam: Team;
}

export interface MasterDataResponse {
    competition: Competition;
    region: Region;
}
