import { OddsDisplayFormat } from '@frontend/sports/types/models/user-settings';

import { IConfigSettings, IUserBettingSettings } from './model/user.models';

export const userFeatureKey = 'sports-user';

export interface IUserRootState {
    [userFeatureKey]: IUserState;
}

export interface IUserState {
    isAuthenticated: boolean;
    bettingSettings: IUserBettingSettings;
    configSettings: IConfigSettings;
    walletLoaded: boolean;
    oddsFormat?: OddsDisplayFormat;
}

export const defaultUserState: IUserState = {
    isAuthenticated: false,
    bettingSettings: {} as IUserBettingSettings,
    configSettings: {} as IConfigSettings,
    walletLoaded: false,
};
