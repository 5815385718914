import { ImageProfile } from '@cds/betting-offer';
import { CountItem, ItemType, LeagueItem, SportConstant, StringDictionary, children } from '@frontend/sports/common/base-utils';
import { Tournament } from '@frontend/sports/types/components/tournaments';
import { immerable } from 'immer';

import { IntervalFilter } from '../calendar/count-interval.service';
import { TreeItem } from '../common/item/item.model';
import { UrlParam } from '../navigation-core/url-builder';

export class Lazy<T> {
    private cached?: T;

    constructor(private creator: () => T) {}

    get value(): T {
        if (this.cached === undefined) {
            this.cached = this.creator();
        }

        return this.cached;
    }
}

export interface TreeOptions {
    urlMapper: (sport?: CountItem, region?: CountItem, league?: CountItem, group?: CountItem) => string;
    countMapper: (item?: CountItem) => number;
    priceBoostCountMapper?: (item?: CountItem) => number;
    title?: string;
    subTitle?: string;
    topTitle?: string;
    moreTitle?: string;
    allTitle?: string;
    seoOptimize?: boolean;
    allOptions?: TreeOptions;
    eSportsLobby?: boolean;
}

export class BaseTreeItem implements TreeItem {
    [immerable] = true;

    private _href: string;

    constructor() {
        this.children = [];
    }

    get href(): string {
        return this._href;
    }

    set href(value: string) {
        this._href = value;
    }

    id: string | number;
    children: TreeItem[];
    active: boolean;
    expanded: boolean;
    arrow?: boolean;
    count?: number;
    icon?: string;
    title: string;
    tracking?: string;
    attribute?: StringDictionary<string | number>;
    liveCount?: number;
    context?: string;
    compoundId?: string;
    priceBoostCount?: number;
    regions?: number[];
}

export class LazyTreeItem extends BaseTreeItem {
    private lazyHref: Lazy<string>;

    constructor([sport, region, league, group]: CountItem[], options: TreeOptions) {
        super();

        const current = group || league || region || sport;

        if (current) {
            this.id = current.id;
            this.expanded = options.seoOptimize || !current.children.length;

            this.attribute = {
                id: this.id,
                type: current.type.toLowerCase(),
            };
        }

        this.lazyHref = new Lazy<string>(() => options.urlMapper(sport, region, league));
        this.count = options.countMapper(current);
        this.priceBoostCount = options.priceBoostCountMapper ? options.priceBoostCountMapper(current) : 0;
        this.active = false;
    }

    override get href(): string {
        return this.lazyHref.value;
    }

    override set href(value: string) {
        console.error(`LazyTreeItem should not have it's 'href' property set. Attempted value: ${value}`);
    }
}

export class SportTreeItem extends LazyTreeItem {
    constructor(sport: CountItem, options: TreeOptions) {
        super([sport], options);

        const childrenType = getItemTypeForSport(sport.id);

        const sportTitleAndIcon = () => {
            this.icon = sport.icon ?? `sports-${sport.id}`;
            this.title = sport.name;
        };

        if (options.title && !options.eSportsLobby) {
            this.subTitle = options.subTitle;
            this.moreTitle = options.moreTitle;
            this.title = options.title;
            this.count = undefined;
            this.priceBoostCount = undefined;
        } else if (options.eSportsLobby) {
            sportTitleAndIcon();
            this.count = undefined;
        } else {
            sportTitleAndIcon();
        }

        this.children = children(sport, childrenType).map((region) => new RegionTreeItem(sport, region, options));
        this.liveCount = sport.counts.live;
    }

    subTitle?: string;
    moreTitle?: string;
}

export class RegionTreeItem extends LazyTreeItem {
    constructor(sport: CountItem, region: CountItem, options: TreeOptions) {
        super([sport, region], options.allOptions ? options.allOptions : options);

        this.icon = region.type === ItemType.Region ? `country-icon c${region.id}` : 'sports-tournament';
        this.title = region.name;
        this.children = children(region, ItemType.Competition).map((league) => new LeagueTreeItem(sport, region, league, options));

        if (this.children.length && (options.seoOptimize || this.children.length > 1)) {
            const all = new RegionTreeItem(sport, { ...region, children: [] }, options.allOptions ? options.allOptions : options);

            all.title = `${options.allTitle} ${region.name}`;
            all.icon = 'theme-competitions-all';

            if (all.attribute) {
                all.attribute.target = 'all';
            }

            this.children.unshift(all);
        }
    }
}

export class LeagueTreeItem extends LazyTreeItem {
    constructor(sport: CountItem, region: CountItem, league: LeagueItem, options: TreeOptions) {
        const leagueOptions = { ...options, urlMapper: () => options.urlMapper(sport, region, league) };

        super([sport, region, league], leagueOptions);

        this.sport = sport.id;
        this.region = region.name;
        this.title = league.name;
        this.icon = league.icon;
        this.isVirtual = league.isVirtual;
        this.siblings = league.isVirtual ? league.siblings : undefined;
        this.liveCount = league.counts.live;
        this.realCompetitionId = league.realCompetitionId;
        this.compoundId = league.compoundId;
        this.imageProfile = league.imageProfile;
    }

    sport: number;
    region: string;
    isVirtual: boolean;
    siblings?: number[];
    realCompetitionId?: number;
    imageProfile?: ImageProfile;
}

export class VirtualGroupTreeItem extends LazyTreeItem {
    constructor(sport: CountItem, region: CountItem, league: LeagueItem, group: LeagueItem, options: TreeOptions) {
        const groupOptions = { ...options, urlMapper: () => options.urlMapper(sport, region, league, group) };

        super([sport, region, league, group], groupOptions);

        this.sport = sport.id;
        this.region = region.name;
        this.title = `${league.name} - ${group.name}`;
        this.icon = group.icon;
        this.favouriteId = group.siblings[0];
    }

    sport: number;
    region: string;
    favouriteId: number;
}

export class TournamentTreeItem extends BaseTreeItem {
    constructor(sport: CountItem, tournament: Tournament, urlMapper: (sport: UrlParam, name: string) => string) {
        super();

        this.id = tournament.navigation.name;
        this.title = tournament.navigation.filter.title || tournament.navigation.name;
        this.icon = tournament.navigation.icon || 'sports-tournament';
        this.href = urlMapper(sport, tournament.navigation.name);

        this.attribute = {
            id: this.id,
            type: 'tournament',
        };
    }
}

export class CalendarTreeItem extends BaseTreeItem {
    constructor(sport: CountItem, interval: IntervalFilter, urlMapper: (sport: UrlParam, name: string) => string) {
        super();

        this.id = interval.name;
        this.title = interval.title;
        this.icon = 'theme-competitions-all';
        this.href = urlMapper(sport, interval.name);
        this.attribute = {
            id: this.id,
            type: 'calendar',
        };
    }
}

export interface SportTreeState {
    sport?: number;
    sportExpanded?: boolean;
    region?: number;
    regionExpanded?: (string | number)[];
    league?: number | number[];
    tournament?: string;
    isVirtual?: boolean;
    virtualCompetitionGroup?: number;
    context?: string;
}

export function getItemTypeForSport(sportId: number): ItemType.Tournament | ItemType.Region {
    return sportId === SportConstant.Tennis ? ItemType.Tournament : ItemType.Region;
}
