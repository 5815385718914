import { Injectable, inject } from '@angular/core';

import { LAUNCH_DARKLY_CONTEXT_PROVIDER, LaunchDarklyContextProvider, ProductInjector } from '@frontend/vanilla/core';
import { LDContext } from 'launchdarkly-js-client-sdk';

@Injectable({
    providedIn: 'root',
})
export class LaunchDarklyContextProviderService {
    productInjector = inject(ProductInjector);

    /**
     * Return the current LDContext, without callind the providers again.
     */
    context: LDContext;

    /**
     * Reloads LDContext by fetching the providers again.
     */
    async reloadContext() {
        await this.getContext();
    }

    /**
     * Get all Context injectors and return the generated LDContext.
     */
    async getContext() {
        const providers = this.productInjector.getMultiple<LaunchDarklyContextProvider>(LAUNCH_DARKLY_CONTEXT_PROVIDER);
        for (let index = 0; index < providers.length; index++) {
            const contexts = await providers[index]!.getProviders();
            this.context = Object.assign({}, this.context, contexts);
        }

        return this.context;
    }
}
