import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

import { isNumber } from 'lodash-es';

import { NumpadUtilsService } from './numpad-utils.service';

@Directive({
    selector: '[msValidateStakeInput]',
})
export class ValidateStakeInputDirective {
    @Output() stakepaste: EventEmitter<string> = new EventEmitter<string>();

    constructor(
        private utils: NumpadUtilsService,
        private element: ElementRef,
    ) {}

    @HostListener('keypress', ['$event'])
    onKeypress(e: KeyboardEvent): boolean {
        const value = this.getValue(this.element.nativeElement, e.key);
        if (this.utils.isValidInput(value)) {
            return true;
        }

        e.preventDefault();

        return false;
    }

    @HostListener('paste', ['$event'])
    onPaste(e: ClipboardEvent): boolean {
        const clipboardData = e.clipboardData || window['clipboardData'];
        const pastedText = clipboardData.getData('text');
        const value = this.getValue(this.element.nativeElement, pastedText);

        if (this.utils.isValidInput(value)) {
            this.stakepaste.emit(value);
        }

        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    private getValue(inputElement: HTMLInputElement, key: string): string {
        const value = inputElement.value;

        const position = isNumber(inputElement.selectionStart) ? inputElement.selectionStart : undefined;

        return value.substr(0, position) + key + value.substr(inputElement.selectionEnd || value.length);
    }
}
