import { Injectable } from '@angular/core';

import { OnAppInit } from '../bootstrap/bootstrapper.service';
import { Page } from '../client-config/page.client-config';
import { RemoteLogger } from './remote-logger';

@Injectable()
export class LoggingBootstrapService implements OnAppInit {
    constructor(private page: Page) {}

    onAppInit() {
        const loggingConfig = this.page.logging;
        const disableLogLevels: { [key: string]: RegExp | null } = {};
        if (loggingConfig.disableLogLevels)
            Object.entries(loggingConfig.disableLogLevels).forEach(([key, value]) => {
                disableLogLevels[key.toLowerCase()] = value ? new RegExp(value.pattern) : null;
            });

        RemoteLogger.configure({
            isEnabled: loggingConfig.isEnabled,
            url: loggingConfig.url,
            debounceInterval: loggingConfig.debounceInterval,
            maxErrorsPerBatch: loggingConfig.maxErrorsPerBatch,
            disableLogLevels: disableLogLevels,
        });
    }
}
