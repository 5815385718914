import { InjectionToken } from '@angular/core';

import { BetslipModuleLoaderService } from './betslip-module-loader.service';
import { IAccaBoostConfig } from './config/acca-boost-config';
import { BetRecommenderConfig, BetRecommenderConfigData } from './config/bet-recommender-config';
import { IBetslipBarConfig } from './config/betslip-bar-config';
import { BetslipOnboardingConfig } from './config/betslip-onboarding-config';
import { ICrmConfig } from './config/crm-config';
import { ICurrencyConfig } from './config/currency-config';
import { IBetStationEDSTriggersConfig } from './config/eds-config';
import { IMultiReturnsMessageConfig } from './config/multi-returns-message-config';
import { INotificationsConfig } from './config/notifications-config';
import { IOddsConfig } from './config/odds-config';
import IAccountBalanceService from './services/account-balance.service';
import IAlternativeTranslationsService from './services/alternative-translations.service';
import IApiService from './services/api.service';
import IArcRestrictionService from './services/arc-restriction.service';
import IBalanceService from './services/balance.service';
import { IBetStationFreeBetDetailsDialogService } from './services/bet-station-freebet-details-dialog.service';
import { IBetStationFreeBetErrorService } from './services/bet-station-freebet-error.service';
import { IBetStationFreeBetPersistenceService } from './services/bet-station-freebet-persistence.service';
import { IBetStationFreeBetTrackingService } from './services/bet-station-freebet-tracking.service';
import { IBetStationInfoService } from './services/bet-station-info.service';
import IBetslipConfig from './services/betslip-config';
import IBetslipOddsAcceptanceService from './services/betslip-odds-acceptance.service';
import IBetslipUpsellApi from './services/betslip-upsell-api.service';
import IBettingOfferApi from './services/betting-offer-api.service';
import ICashierService from './services/cashier.service';
import ICdsPushService from './services/cds-push.service';
import { IColumnLayoutService } from './services/column-layout.service';
import ICompetitionLogosService from './services/competition-logos.service';
import { ICrmOfferLoaderService } from './services/crm-offer-loader-service';
import { ICrmPromotionProviderService } from './services/crm-promotion-provider.service';
import { ICrmPromotionReplaceParametersService } from './services/crm-promotion-replace-parameters.service';
import ICustomerOfferService from './services/customer-offer.service';
import IEpcotConfigService from './services/epcot-config.service';
import IFixtureFactory from './services/fixture.factory';
import IGridSubscriptionService from './services/grid-subscription.service';
import ILocalStoreService from './services/local-store.service';
import { ILoggerFactory } from './services/logger-factory.service';
import { IModalDialogService } from './services/modal-dialog.service';
import IMoneyService from './services/money-service';
import { IMyBetsSummaryService } from './services/my-bets-summary.service';
import { INavigationRouterService } from './services/navigation-router.service';
import INumpadDisplayService from './services/numpad-display.service';
import IOddsSettingsService from './services/odds-settings.service';
import { IPickSourceProvider } from './services/pick-source.provider';
import { PopupManager } from './services/popup-manager';
import { IRedirectHelperService } from './services/redirect-helper.service';
import { IRetailTracingBetPlacementService } from './services/retail-tracing-bet-placement.service';
import { RouterEventsService } from './services/router-events.service';
import ISitecore from './services/sitecore.service';
import IStakeConfig from './services/stake-config';
import IStringInterpolationPipe from './services/string-interpolation-pipe';
import ITaxationConfig from './services/taxation-config';
import ITokensCalculatorService from './services/tokens-calculator.service';
import { TrackingService } from './services/tracking-service';
import IBetslipUpsellConfig from './services/upsell-config';
import IUserStateService from './services/user-state.service';
import IUserService from './services/user.service';
import VanillaIntlService from './services/vanilla-intl.service';

export const BETSLIP_MODULE_LOADER_SERVICE = new InjectionToken<BetslipModuleLoaderService>('Betslip module loader token');

/* Services */
export const BETTING_OFFER_API_INJECTION_TOKEN = new InjectionToken<IBettingOfferApi>('SPORTS_BETTING_OFFER_API_INJECTION_TOKEN');
export const CUSTOMER_OFFER_INJECTION_TOKEN = new InjectionToken<ICustomerOfferService>('SPORTS_CUSTOMER_OFFER_INJECTION_TOKEN');
export const ALTERNATIVE_TRANSLATION_INJECTION_TOKEN = new InjectionToken<IAlternativeTranslationsService>('ALTERNATIVE_TRANSLATION_INJECTION_TOKEN');
export const USER_SERVICE_INJECTION_TOKEN = new InjectionToken<IUserService>('SPORTS_USER_SERVICE_INJECTION_TOKEN');
export const STRING_INTERPOLATION_PIPE_INJECTION_TOKEN = new InjectionToken<IStringInterpolationPipe>(
    'SPORTS_STRING_INTERPOLATION_PIPE_INJECTION_TOKEN',
);
export const MONEY_SERVICE_INJECTION_TOKEN = new InjectionToken<IMoneyService>('SPORTS_CURRENCY_SERVICE_INJECTION_TOKEN');
export const INTL_SERVICE_INJECTION_TOKEN = new InjectionToken<VanillaIntlService>('VANILLA_INTL_SERVICE_INJECTION_TOKEN');
export const ACCOUNT_BALANCE_INJECTION_TOKEN = new InjectionToken<IAccountBalanceService>('SPORTS_ACCOUNT_BALANCE_INJECTION_TOKEN');
export const BALANCE_INJECTION_TOKEN = new InjectionToken<IBalanceService>('VANILLA_BALANCE_SERVICE');
export const API_SERVICE_INJECTION_TOKEN = new InjectionToken<IApiService>('SPORTS_API_SERVICE_INJECTION_TOKEN');
export const TOKEN_CALCULATOR_SERVICE_INJECTION_TOKEN = new InjectionToken<ITokensCalculatorService>(
    'SPORTS_TOKEN_CALCULATOR_SERVICE_INJECTION_TOKEN',
);
export const CDS_PUSH_INJECTION_TOKEN = new InjectionToken<ICdsPushService>('SPORTS_CDS_PUSH_INJECTION_TOKEN');
export const FRESH_DATA_INJECTION_TOKEN = new InjectionToken<ICdsPushService>('SPORTS_FRESH_DATA_INJECTION_TOKEN');
export const LOGGER_FACTORY_INJECTION_TOKEN = new InjectionToken<ILoggerFactory>('SPORTS_LOGGER_FACTORY_INJECTION_TOKEN');
export const BET_STATION_INFO_INJECTION_TOKEN = new InjectionToken<IBetStationInfoService>('BET_STATION_INFO_INJECTION_TOKEN');
export const MODAL_DIALOG_SERVICE_INJECTION_TOKEN = new InjectionToken<IModalDialogService>('MODAL_DIALOG_SERVICE_INJECTION_TOKEN');
export const POPUP_MANAGER_INJECTION_TOKEN = new InjectionToken<PopupManager>('SPORTS_POPUP_MANAGER_INJECTION_TOKEN');
export const USER_STATE_SERVICE_INJECTION_TOKEN = new InjectionToken<IUserStateService>('USER_STATE_SERVICE_INJECTION_TOKEN');
export const ODDS_SETTINGS_SERVICE = new InjectionToken<IOddsSettingsService>('BET_STATION_ODDS_SETTINGS_SERVICE');
export const REDIRECT_HELPER_SERVICE = new InjectionToken<IRedirectHelperService>('SPORTS_REDIRECT_HELPER_SERVICE');
export const USER_SETTINGS_SERVICE_INJECTION_TOKEN = new InjectionToken<IUserService>('SPORTS_USER_SETTINGS_SERVICE_INJECTION_TOKEN');
export const TRACKING_SERVICE_INJECTION_TOKEN = new InjectionToken<TrackingService>('SPORTS_TRACKING_SERVICE_INJECTION_TOKEN');
export const ROUTER_EVENTS_SERVICE_INJECTION_TOKEN = new InjectionToken<RouterEventsService>('SPORTS_ROUTER_EVENTS_SERVICE_INJECTION_TOKEN');
export const CRM_OFFER_LOADER_SERVICE_INJECTION_TOKEN = new InjectionToken<ICrmOfferLoaderService>('CRM_OFFER_LOADER_SERVICE_INJECTION_TOKEN');
export const COLUMN_LAYOUT_SERVICE_INJECTION_TOKEN = new InjectionToken<IColumnLayoutService>('COLUMN_LAYOUT_SERVICE_INJECTION_TOKEN');
export const CRM_PROMOTION_PROVIDER_SERVICE_INJECTION_TOKEN = new InjectionToken<ICrmPromotionProviderService>(
    'CRM_PROMOTION_PROVIDER_SERVICE_INJECTION_TOKEN',
);
export const CRM_PROMOTION_REPLACE_PARAMETERS_SERVICE_INJECTION_TOKEN = new InjectionToken<ICrmPromotionReplaceParametersService>(
    'CRM_PROMOTION_REPLACE_PARAMETERS_SERVICE_INJECTION_TOKEN',
);
export const NAVIGATION_ROUTER_SERVICE = new InjectionToken<INavigationRouterService>('SPORTS_NAVIGATION_ROUTER_SERVICE');

export const BET_STATION_BETSLIP_ODDS_ACCEPTANCE = new InjectionToken<IBetslipOddsAcceptanceService>('BET_STATION_BETSLIP_ODDS_ACCEPTANCE');
export const ARC_RESTRICTION_INJECTION_TOKEN = new InjectionToken<IArcRestrictionService>('ARC_RESTRICTION_INJECTION_TOKEN');
export const COMPETITION_LOGOS_INJECTION_TOKEN = new InjectionToken<ICompetitionLogosService>('COMPETITION_LOGOS_INJECTION_TOKEN');
export const LOCAL_STORE_INJECTION_TOKEN = new InjectionToken<ILocalStoreService>('LOCAL_STORE_INJECTION_TOKEN');
export const FIXTURE_FACTORY_INJECTION_TOKEN = new InjectionToken<IFixtureFactory>('FIXTURE_FACTORY_INJECTION_TOKEN');
export const BETSLIP_UPSELL_API_INJECTION_TOKEN = new InjectionToken<IBetslipUpsellApi>('BETSLIP_UPSELL_API_INJECTION_TOKEN');
export const NUMPAD_DISPLAY_INJECTION_TOKEN = new InjectionToken<INumpadDisplayService>('NUMPAD_DISPLAY_INJECTION_TOKEN');
export const CASHIER_SERVICE_INJECTION_TOKEN = new InjectionToken<ICashierService>('CASHIER_SERVICE_INJECTION_TOKEN');
export const GRID_SUBSCRIPTION_SERVICE_INJECTION_TOKEN = new InjectionToken<IGridSubscriptionService>('GRID_SUBSCRIPTION_SERVICE_INJECTION_TOKEN');
export const RETAIL_TRACING_SERVICE_INJECTION_TOKEN = new InjectionToken<IRetailTracingBetPlacementService>('RETAIL_TRACING_SERVICE_INJECTION_TOKEN');

/* Configs */
export const BETSLIP_CONFIG_INJECTION_TOKEN = new InjectionToken<IBetslipConfig>('SPORTS_BETSLIP_CONFIG_INJECTION_TOKEN');
export const CONNECTION_CONFIG_INJECTION_TOKEN = new InjectionToken<ICdsPushService>('SPORTS_CONNECTION_CONFIG_INJECTION_TOKEN');
export const NOTIFICATION_CONFIG_INJECTION_TOKEN = new InjectionToken<INotificationsConfig>('SPORTS_NOTIFICATION_CONFIG_INJECTION_TOKEN');
export const SITECORE_INJECTION_TOKEN = new InjectionToken<ISitecore>('SPORTS_SITECORE_INJECTION_TOKEN');
export const STAKE_CONFIG_INJECTION_TOKEN = new InjectionToken<IStakeConfig>('SPORTS_STAKE_CONFIG_INJECTION_TOKEN');
export const TAXATION_CONFIG_INJECTION_TOKEN = new InjectionToken<ITaxationConfig>('SPORTS_TAXATION_CONFIG_INJECTION_TOKEN');
export const CURRENCY_CONFIG_INJECTION_TOKEN = new InjectionToken<ICurrencyConfig>('SPORTS_CURRENCY_CONFIG_INJECTION_TOKEN');
export const ODDS_CONFIG_INJECTION_TOKEN = new InjectionToken<IOddsConfig>('BET_STATION_ODDS_CONFIG_INJECTION_TOKEN');
export const MULTI_RETURNS_MESSAGE_CONFIG_INJECTION_TOKEN = new InjectionToken<IMultiReturnsMessageConfig>(
    'MULTI_RETURNS_MESSAGE_CONFIG_INJECTION_TOKEN',
);

export const BET_STATION_FREEBET_ERROR_SERVICE = new InjectionToken<IBetStationFreeBetErrorService>('BET_STATION_FREE_BET_ERROR_SERVICE');
export const BET_STATION_FREEBET_PERSISTENCE_SERVICE = new InjectionToken<IBetStationFreeBetPersistenceService>(
    'BET_STATION_FREE_BET_PERSISTENCE_SERVICE',
);
export const BET_STATION_FREEBET_DETAILS_DIALOG_SERVICE = new InjectionToken<IBetStationFreeBetDetailsDialogService>(
    'BET_STATION_FREEBET_DETAILS_DIALOG_SERVICE',
);

export const BET_STATION_FREEBET_TRACKING_SERVICE = new InjectionToken<IBetStationFreeBetTrackingService>('BET_STATION_FREEBET_TRACKING_SERVICE');
export const ACCA_BOOST_CONFIG_INJECTION_TOKEN = new InjectionToken<IAccaBoostConfig>('ACCA_BOOST_CONFIG_INJECTION_TOKEN');
export const CRM_CONFIG_INJECTION_TOKEN = new InjectionToken<ICrmConfig>('CRM_CONFIG_INJECTION_TOKEN');
export const UPSELL_CONFIG_INJECTION_TOKEN = new InjectionToken<IBetslipUpsellConfig>('SPORTS_UPSELL_CONFIG_INJECTION_TOKEN');
export const BET_RECOMMENDER_CONFIG_INJECTION_TOKEN = new InjectionToken<BetRecommenderConfig>('BET_RECOMMENDER_CONFIG_INJECTION_TOKEN');
export const BET_RECOMMENDER_CONFIG_DATA_INJECTION_TOKEN = new InjectionToken<BetRecommenderConfigData>(
    'BET_RECOMMENDER_CONFIG_DATA_INJECTION_TOKEN',
);
export const BETSLIP_ONBOARDING_CONFIG_INJECTION_TOKEN = new InjectionToken<BetslipOnboardingConfig>('BETSLIP_ONBOARDING_CONFIG_INJECTION_TOKEN');
export const BET_STATION_EDS_TRIGGERS_CONFIG_INJECTION_TOKEN = new InjectionToken<IBetStationEDSTriggersConfig>(
    'BET_STATION_EDS_TRIGGERS_CONFIG_INJECTION_TOKEN',
);
export const BETSLIP_BAR_CONFIG_INJECTION_TOKEN = new InjectionToken<IBetslipBarConfig>('BETSLIP_BAR_CONFIG_INJECTION_TOKEN');
export const EPCOT_CONFIG_INJECTION_TOKEN = new InjectionToken<IEpcotConfigService>('EPCOT_CONFIG_INJECTION_TOKEN');

export const PICK_SOURCE_PROVIDER_INJECTION_TOKEN = new InjectionToken<IPickSourceProvider>('PICK_SOURCE_PROVIDER_INJECTION_TOKEN');

export const MY_BETS_SUMMARY_SERVICE_INJECTION_TOKEN = new InjectionToken<IMyBetsSummaryService>('MY_BETS_SUMMARY_SERVICE_INJECTION_TOKEN');
export const ODDS_VIEW_MODE = new InjectionToken<boolean>('ODDS_VIEW_MODE');
