<ms-scroll-adapter [msScrollSelectedInView]="active && getGroupMenuItemId(active)" [scrollSize]="200" [stretch]="false">
    <ul class="group-container" [class.card-container]="model.displayMode === VirtualCompetitionDisplayMode.Cards">
        <li
            class="group-item"
            *ngFor="let group of model.groups; trackBy: trackTabsBy; let index = index"
            [class.active]="group.active"
            [class.no-participants]="model.displayMode === VirtualCompetitionDisplayMode.Cards && (!group.participants || !group.participants.length)"
            [attr.data-menu-item-id]="getGroupMenuItemId(group)">
            <a [href]="group.url" (click)="selected($event, group)" *ngIf="group.url">
                <ng-template
                    *ngTemplateOutlet="
                        model.displayMode === VirtualCompetitionDisplayMode.Pills ? pillTemplate : cardTemplate;
                        context: { $implicit: group }
                    ">
                </ng-template>
                <ms-favourite-league-toggle
                    [sport]="model.sportId"
                    [id]="group.favouriteId"
                    [name]="group.favouriteName"
                    [tracking]="{ source: 'Groups Carousel', position: index }"
                    class="favourite-icon"
                    [class.hidden]="!group.active && !group.isFavourite"
                    *ngIf="group.canBeFavourited">
                </ms-favourite-league-toggle>
            </a>
        </li>
    </ul>
</ms-scroll-adapter>

<ng-template #pillTemplate let-group>
    <div class="pills-template">
        <div class="title pills">
            <span>{{ group.title }}</span>
        </div>
    </div>
</ng-template>

<ng-template #cardTemplate let-group>
    <div class="cards-template">
        <div class="title">
            <span>{{ group.title }}</span>
        </div>
        <div *ngIf="group.participants && group.participants.length > 0" class="cards-participants-container">
            <div *ngFor="let participant of group.participants" class="cards-participant-item">
                <div class="participant-image">
                    <ms-tournament-groups-participants
                        [participantId]="participant.participantId"
                        [sportId]="model.sportId"
                        [imageType]="model.imageType">
                    </ms-tournament-groups-participants>
                </div>
                <div class="participant-code">{{ participant.code }}</div>
            </div>
        </div>
    </div>
</ng-template>
