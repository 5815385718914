import { Injectable } from '@angular/core';

import { BetBuilderFixture } from '@cds/betting-offer/domain-specific/bet-builder';
import { VirtualCompetition, VirtualCompetitionGroup } from '@cds/betting-offer/tags';
import { StatisticsMode } from '@cds/query-objects';
import { BetBuilderConfig, BettingInsightsConfiguration } from '@frontend/sports/common/client-config-data-access';
import { LocalStoreService, NativeAppService } from '@frontend/vanilla/core';
import { includes, isEmpty, maxBy, sumBy, uniq, uniqBy } from 'lodash-es';

import { BetBuilderMapperService } from '../betbuilder/services/betbuilder-mapper.service';
import { BettingOfferService } from '../cds/betting-offer.service';
import { DetailedFixtureFactory } from '../event-model/mappers/detailed-fixture.factory';
import { DetailedGroupingFactory } from '../event-model/mappers/detailed-grouping.factory';
import {
    EnhancedFixtureViewGroupingConfiguration,
    EventModel,
    EventOptionGroup,
    EventOptionGroupType,
    EventOptionSet,
    EventOptionSetHeaderTab,
    ExtendedDisplayType,
    MyMarketModel,
    OptionGroup,
    PRECREATED_BAB_EMPTY_THEME_ID,
    ParticipantInfo,
    SetTab,
    getPitcherByParticipantType,
    getRankByParticipantType,
    getWinLossStatsByParticipantType,
} from '../event-model/model/event.model';
import { StatisticsConfigService } from '../statistics/statistics-config.service';
import { PrepareOptionGroupsParams, ResultEventModel, VirtualCompetitionDetails } from './event-details-common.models';

@Injectable({ providedIn: 'root' })
export class EventDetailsService {
    private storageKey = 'sia_my_markets';
    participantInfos: ParticipantInfo[];
    teamRecordsEnabled = false;
    teamRanksEnabled = false;
    teamPitchersEnabled = false;

    constructor(
        private bettingOffer: BettingOfferService,
        private fixtureFactory: DetailedFixtureFactory,
        private groupingFactory: DetailedGroupingFactory,
        private betBuilderMapper: BetBuilderMapperService,
        private betBuilderConfig: BetBuilderConfig,
        private bettingInsightsConfig: BettingInsightsConfiguration,
        public statisticsConfigService: StatisticsConfigService,
        private localStore: LocalStoreService,
        private nativeAppService: NativeAppService,
    ) {}

    async getEvent(id: string, excludeMarkets: boolean = false, includeRelatedFixtures?: boolean): Promise<EventModel | undefined> {
        const cdsEntity = await this.bettingOffer.getFixtureViewModel(
            id,
            excludeMarkets,
            StatisticsMode.None,
            false,
            this.bettingInsightsConfig.isEnabled,
            includeRelatedFixtures,
        );
        if (!cdsEntity) {
            return;
        }

        return this.fixtureFactory.create({
            fixture: cdsEntity.fixture,
            splitFixtures: cdsEntity.splitFixtures,
            linkedFixture: cdsEntity.linkedFixture,
            grouping: cdsEntity.grouping,
            loadEventSitemap: true,
            bettingInsights: cdsEntity.bettingInsights,
        });
    }

    async getMainLiveEvent(id: string): Promise<EventModel | undefined> {
        const cdsEntity = await this.bettingOffer.getMainLiveFixtureViewModel(id);

        if (!cdsEntity) {
            return;
        }

        return this.fixtureFactory.create({
            fixture: cdsEntity.fixture,
            splitFixtures: cdsEntity.splitFixtures,
            linkedFixture: cdsEntity.linkedFixture,
            grouping: cdsEntity.grouping,
            loadEventSitemap: true,
        });
    }

    async getModel(id: string): Promise<ResultEventModel | undefined> {
        const statsMode = this.statisticsConfigService.getStatisticsModesBySportId();
        const cdsEntity = await this.bettingOffer.getFixtureViewModel(
            id,
            false,
            statsMode,
            this.betBuilderConfig.isPrecreatedBetBuilderEnabled,
            this.bettingInsightsConfig.isEnabled,
        );
        if (!cdsEntity) {
            return;
        }
        this.addBetbuilderToGrouping(cdsEntity.grouping, cdsEntity.preCreatedOffer);

        return {
            model: this.fixtureFactory.create({
                fixture: cdsEntity.fixture,
                splitFixtures: cdsEntity.splitFixtures,
                linkedFixture: cdsEntity.linkedFixture,
                grouping: cdsEntity.grouping,
                precreated: cdsEntity.preCreatedOffer,
                loadEventSitemap: true,
                bettingInsights: cdsEntity.bettingInsights,
                excludePriceboostedMarketGrouping: !this.nativeAppService.isTerminal,
            }),
            preCreated: this.betBuilderMapper.mapFixture(cdsEntity.preCreatedOffer),
            virtualCompetitionDetails: this.mapVirtualCompetitionDetails(
                cdsEntity.fixture.virtualCompetition,
                cdsEntity.fixture.virtualCompetitionGroup,
            ),
            fixture: cdsEntity.fixture,
            betBuilderFixture: cdsEntity.preCreatedOffer,
        };
    }

    private mapVirtualCompetitionDetails(
        virtualCompetition?: VirtualCompetition,
        virtualCompetitionGroup?: VirtualCompetitionGroup,
    ): VirtualCompetitionDetails | undefined {
        if (!virtualCompetition) {
            return;
        }

        return {
            id: virtualCompetition.id,
            name: virtualCompetition.name.value,
            group: virtualCompetitionGroup ? { id: virtualCompetitionGroup.id, name: virtualCompetitionGroup.name.value } : undefined,
        };
    }

    addBetbuilderToGrouping(
        grouping: EnhancedFixtureViewGroupingConfiguration,
        preCreatedOffer?: BetBuilderFixture,
    ): EnhancedFixtureViewGroupingConfiguration {
        const themedTabs = uniq(preCreatedOffer?.precreatedMarketsInfo.map((x) => x.themedTab?.toUpperCase()));
        const sportSpecificThemedTabs = this.betBuilderConfig.themedTabs[grouping.sportId] || [];
        const themedTabNames = sportSpecificThemedTabs.filter((i) => includes(themedTabs, i.key?.toUpperCase().trim())).map((s) => s.name);
        const marketTabId = maxBy(grouping.marketTabs.map((x) => x.id));
        if (marketTabId) {
            let count = marketTabId + 1;
            if (themedTabNames?.length) {
                themedTabNames.forEach((tabs) => {
                    grouping.marketTabs.push({ id: count, name: tabs || '', sortOrder: count });
                    count++;
                });
            }

            grouping.marketTabs.push({ id: count, name: '', sortOrder: count });
        }

        return grouping;
    }

    prepareEventOptionGroups(params: PrepareOptionGroupsParams): EventOptionGroup[] {
        const { event, groupedSetsIds, tag, setTags, isSgp } = params;
        const activeTab = params.activeTab ?? SetTab.All;
        let optionGroups = [];

        if (activeTab === SetTab.MyMarkets) {
            optionGroups = this.getOptionGroupForMyMarkets(event);
        } else if (groupedSetsIds && groupedSetsIds.length > 0) {
            optionGroups = this.getMultipleOptionGroups(event.optionGroups, groupedSetsIds);

            if (setTags && setTags.length >= 1) {
                optionGroups = optionGroups.filter(
                    (group) =>
                        group.detailedGrouping &&
                        group.detailedGrouping.tags &&
                        group.detailedGrouping.tags.length >= setTags.length &&
                        setTags.every((t, index) => t === group.detailedGrouping.tags![index].key) &&
                        (isSgp ? group.isBetBuilder : true),
                );
            }
        } else {
            optionGroups =
                activeTab === SetTab.All
                    ? event.optionGroups
                    : event.optionGroups.filter((group) => group.detailedGrouping && group.detailedGrouping.marketSet === activeTab);

            if (tag) {
                optionGroups = optionGroups.filter((group) => group.detailedGrouping?.tags?.length && group.detailedGrouping.tags[0].key === tag);
            }

            if (isSgp) {
                optionGroups = optionGroups.filter((group) => group.isBetBuilder);
            }
        }

        optionGroups = this.distinctOptionGroups(optionGroups);

        if (!params.isPrecreatedBABTab) {
            optionGroups = this.filterBetBuilderThemedOptionGroups(optionGroups);
        }

        if (params.isPrecreatedBABTab) {
            if (params.event.precreatedOptionGroups?.length) {
                return this.groupingFactory.transformEventGroupsPerPrecreatedGroup(optionGroups, params.event.precreatedOptionGroups);
            }

            return this.groupingFactory.groupMarkets(optionGroups, true, true);
        }

        return this.groupingFactory.groupMarkets(optionGroups);
    }

    private filterBetBuilderThemedOptionGroups(optionGroups: EventOptionGroup[]): EventOptionGroup[] {
        const isThemeTabAssigned = optionGroups.some(
            (og) =>
                og.detailedGrouping.displayType === ExtendedDisplayType.BetBuilder &&
                og.detailedGrouping.marketTabId !== PRECREATED_BAB_EMPTY_THEME_ID,
        );
        if (isThemeTabAssigned)
            return optionGroups.filter((optionGroup) => optionGroup.detailedGrouping.marketTabId !== PRECREATED_BAB_EMPTY_THEME_ID);

        return optionGroups;
    }

    isPreCreatedBABTab(event: EventModel, activeTab: string | SetTab = SetTab.All): boolean {
        return event.optionSets.find((t) => t.id === Number(activeTab))?.isPrecreated!;
    }

    getOptionGroupForMyMarkets(event: EventModel): EventOptionGroup[] {
        const myMarkets = this.localStore.get(this.storageKey) as MyMarketModel;
        if ((event.live && myMarkets?.live) || (!event.live && myMarkets?.prematch)) {
            const sportMarkets = event.live ? myMarkets.live[event.sport.id] : myMarkets.prematch[event.sport.id];

            if (sportMarkets) {
                return event.optionGroups.filter((group) => {
                    if (group.detailedGrouping && sportMarkets) {
                        const market = sportMarkets.find(
                            (sMarket: OptionGroup) =>
                                sMarket.market_group_id === group.detailedGrouping.marketSet &&
                                sMarket.tv1_market_group_item === group.detailedGrouping.marketOrder,
                        );
                        if (market) {
                            group.position = market.position;

                            return true;
                        }

                        return false;
                    }

                    return false;
                });
            }
        }

        return [];
    }

    prepareEventOptionSetHeaderTab(set: EventOptionSet, optionGroups: EventOptionGroup[]): EventOptionSetHeaderTab {
        const optionSetHeaderTab = <EventOptionSetHeaderTab>{
            id: set.id,
            name: set.name,
            count: optionGroups.length,
            optionGroups,
        };

        return optionSetHeaderTab;
    }

    calculateEventOptionGroupsCount(event: EventModel): number {
        const optionGroups = this.prepareEventOptionGroups({ event });

        return sumBy(optionGroups, (value) => value.getSubGroups(EventOptionGroupType.Period).length || 1);
    }

    private distinctOptionGroups(optionGroups: EventOptionGroup[]): EventOptionGroup[] {
        return uniqBy(optionGroups, (item) => `${item.id}-${item.templateId}-${item.detailedGrouping.displayType}`);
    }

    private getMultipleOptionGroups(optionGroups: EventOptionGroup[], groupIds: number[]): EventOptionGroup[] {
        const groups: EventOptionGroup[] = [];
        groupIds.forEach((g) => {
            groups.push(...optionGroups.filter((group) => group.detailedGrouping && group.detailedGrouping.marketSet === g));
        });

        return groups;
    }

    setParticipantInfos(event: EventModel): ParticipantInfo[] {
        this.teamPitchersEnabled = !!this.statisticsConfigService.pitcherEnabledSports[event.sport.id];
        this.teamRecordsEnabled = !isEmpty(this.statisticsConfigService.teamRecordsFormat[event.sport.id]);
        this.teamRanksEnabled = !!this.statisticsConfigService.teamRankEnabledSports[event.sport.id];

        return (this.participantInfos = event.participants.map((participant) => {
            const rank = this.teamRanksEnabled ? getRankByParticipantType(event, participant.type) : undefined;
            const pitcher = !event.scoreboard.started && this.teamPitchersEnabled ? getPitcherByParticipantType(event, participant.type) : undefined;
            const teamRecordsFormat = this.statisticsConfigService.teamRecordsFormat[event.sport.id];
            const winLossStats =
                !event.scoreboard.started && this.teamRecordsEnabled
                    ? getWinLossStatsByParticipantType(event, participant.type!, teamRecordsFormat)
                    : undefined;

            return {
                participant,
                rank,
                winLossStats,
                pitcher,
            } as ParticipantInfo;
        }));
    }
}
