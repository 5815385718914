import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import {
    DetailedGrouping,
    DisplayType,
    Fixture,
    Game,
    Option,
    OptionMarket,
    OptionMarketStatus,
    OptionStatus,
    Participant,
    ParticipantPriceType,
    TeamGrouping,
    TotalsPrefix,
    Translation,
    Visibility,
} from '@cds/betting-offer';
import { OfferGroup, Tag } from '@cds/betting-offer/grouping/fixture-view';
import { SportConstant } from '@frontend/sports/common/base-utils';
import { BetBuilderConfig, MarketGroupingConfig } from '@frontend/sports/common/client-config-data-access';
import { NativePrice } from '@frontend/sports/common/odds-lib';
import { replace, toNumber } from 'lodash-es';

import { ComboPreventionMinimum, ComboPreventionType } from '../../event-model/model/scoreboard.model';
import { sortOptions } from '../helpers/option-sorting';
import {
    ComboPrevention,
    EnhancedFixtureViewGroupingConfiguration,
    EventOption,
    EventOptionGroup,
    EventOptionGrouping,
    ResultSorting,
    SetTab,
    toResultSorting,
} from '../model/event.model';
import { Tv2MarketParameters } from '../model/tv2.model';
import { CommonFixtureFactory } from './common-fixture.factory';
import { CommonMarketFactory } from './common-market.factory';
import { MarketDisplayFallbackService } from './sports-market-grouping-fallback.service';

export type DetailedFixtureMarket = Game | OptionMarket;
interface MarketFactory {
    create(
        market: DetailedFixtureMarket,
        fixture: string,
        fixtureOnline: boolean,
        grouping: EnhancedFixtureViewGroupingConfiguration,
    ): EventOptionGroup[];
    canCreate(market: DetailedFixtureMarket): boolean;
}
interface RegionalisedGrouping {
    offerGroup: OfferGroup;
    marketOrder: number;
    tags: Tag[];
    playerStatsProps: string[];
    teamGrouping: TeamGrouping;
}
abstract class MarketFactoryBase extends CommonMarketFactory {
    constructor(
        protected marketGroupingConfig: MarketGroupingConfig,
        betbuilderConfig: BetBuilderConfig,
    ) {
        super(betbuilderConfig);
    }

    protected getPeriodValue(
        game: Game | OptionMarket,
        detailedGrouping: DetailedGrouping,
        grouping: EnhancedFixtureViewGroupingConfiguration,
    ): string {
        if (detailedGrouping.displayType === DisplayType.PlayerMilestone || detailedGrouping.displayType === DisplayType.PlayerMilestoneOverUnder) {
            return this.replaceAttrValue(game as OptionMarket, detailedGrouping.displayType);
        }
        if (detailedGrouping.marketTabId !== undefined && grouping.marketTabs) {
            const marketTab = grouping.marketTabs.find((t) => t.id === detailedGrouping.marketTabId);
            if (marketTab) {
                return marketTab.name;
            }
        }
        const period = 'parameters' in game ? game.parameters.find((param) => param.key === Tv2MarketParameters.Period)?.value ?? '' : '';

        return grouping.parameterTranslations?.['period' + period] ?? '';
    }

    private replaceAttrValue(game: OptionMarket, displayType: DisplayType): string {
        return replace(game?.attr, ',', '.') + (displayType === DisplayType.PlayerMilestone ? '+' : '');
    }

    protected getTranslatedValue(translation: Translation): string {
        return translation.short || translation.value;
    }

    protected mapDetailedGrouping(
        detailedGrouping: DetailedGrouping,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        index: number,
        source: OfferSource,
        attrValue?: string,
        forceRegularDisplayType: boolean = false,
        isPriceBoosted = false,
        excludePriceboostedMarketGrouping = false,
    ): EventOptionGrouping {
        const regionalisedOfferGroup = this.getRegionalisedOfferGroup(detailedGrouping, grouping);
        // isRegular flagged is enabled for few display types, where the mandatory conditions doesn't meet to display in particular display type.
        // If those conditions are not met we are converting these display types to regular.
        const optionGrouping = <EventOptionGrouping>{
            marketSet: regionalisedOfferGroup ? regionalisedOfferGroup.offerGroup.id : SetTab.Other,
            name: detailedGrouping.name,
            marketOrder: regionalisedOfferGroup ? regionalisedOfferGroup.marketOrder : this.getPrecreatedBABOrderPosition(grouping),
            periodGroup: this.getPeriodGroup(detailedGrouping, grouping, attrValue),
            marketGroup: detailedGrouping.subIndex,
            marketHelpPath: detailedGrouping.marketHelpPath,
            marketTabId: detailedGrouping.marketTabId,
            optionColumnId: detailedGrouping.optionColumnId,
            displayType: detailedGrouping.displayType,
            orderType: detailedGrouping.orderType,
            marketGroupId: detailedGrouping.marketGroupItemId,
            marketGroupItemId: detailedGrouping.marketGroupItemId,
            tags: regionalisedOfferGroup?.tags,
            playerStatsProps: regionalisedOfferGroup?.playerStatsProps,
            teamGrouping: regionalisedOfferGroup?.teamGrouping || undefined,
        };
        if (forceRegularDisplayType && grouping.sportId !== SportConstant.Horses) {
            optionGrouping.marketOrder = isPriceBoosted ? 0 : index;
            optionGrouping.periodGroup = undefined;
            optionGrouping.marketGroup = undefined;
            optionGrouping.displayType = DisplayType.Regular;
            if (isPriceBoosted && !excludePriceboostedMarketGrouping) {
                optionGrouping.marketGroup = Number.MAX_VALUE;
            }
        }

        return optionGrouping;
    }

    private getPrecreatedBABOrderPosition(grouping: EnhancedFixtureViewGroupingConfiguration): number {
        const tv1MarketGroupItemPosition = grouping.buildABetSettings.tv1MarketGroupItems?.marketGroupItemPosition;
        const tv2MarketGroupItemPosition = grouping.buildABetSettings.tv2MarketGroupItems?.marketGroupItemPosition;

        return tv1MarketGroupItemPosition || tv2MarketGroupItemPosition;
    }

    private getRegionalisedOfferGroup(
        detailedGrouping: DetailedGrouping,
        grouping: EnhancedFixtureViewGroupingConfiguration,
    ): RegionalisedGrouping | null {
        const regionalisedOfferGroup = grouping.marketGroups.find((mg) => mg.groupId === detailedGrouping.marketGroupId);
        if (!regionalisedOfferGroup) {
            return null;
        }
        const order = regionalisedOfferGroup.marketGroupItems.findIndex((m) => m.id === detailedGrouping.marketGroupItemId);
        if (order === -1) {
            return null;
        }

        if (!this.marketGroupingConfig.regionalisedGroupingEnabled || !detailedGrouping.marketGroupId || !detailedGrouping.marketGroupItemId) {
            return {
                offerGroup: {
                    id: regionalisedOfferGroup.id,
                    groupId: '',
                    marketGroupItems: [],
                    name: detailedGrouping.name || '',
                    tv1MarketGroupItems: [],
                    tag: { key: '', value: '' },
                },
                marketOrder: Number.MAX_VALUE,
                tags: [],
                playerStatsProps: [],
                teamGrouping: TeamGrouping.None,
            };
        }

        return {
            offerGroup: regionalisedOfferGroup,
            marketOrder: order,
            tags: regionalisedOfferGroup.marketGroupItems[order].tags,
            playerStatsProps: regionalisedOfferGroup.marketGroupItems[order].playerStatsProps,
            teamGrouping: regionalisedOfferGroup.marketGroupItems[order].teamGrouping,
        };
    }

    private getPeriodGroup(
        detailedGrouping: DetailedGrouping,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        attrValue?: string,
    ): number | undefined {
        if (
            !!attrValue &&
            (detailedGrouping.displayType === DisplayType.PlayerMilestone || detailedGrouping.displayType === DisplayType.PlayerMilestoneOverUnder)
        ) {
            if (isNaN(+attrValue)) {
                return +attrValue.replace(',', '.');
            }
            return +attrValue;
        }
        if (detailedGrouping.marketTabId !== undefined && grouping.marketTabs) {
            const marketTab = grouping.marketTabs.find((t) => t.id === detailedGrouping.marketTabId);
            if (marketTab) {
                return marketTab.sortOrder;
            }
        }

        return;
    }
}
class GameMarketFactory extends MarketFactoryBase implements MarketFactory {
    constructor(
        private marketFallback: MarketDisplayFallbackService,
        marketGroupingConfig: MarketGroupingConfig,
        betbuilderConfig: BetBuilderConfig,
    ) {
        super(marketGroupingConfig, betbuilderConfig);
    }

    canCreate(market: DetailedFixtureMarket): market is Game & boolean {
        return 'visibility' in market;
    }

    create(
        market: DetailedFixtureMarket,
        fixture: string,
        fixtureOnline: boolean,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        longIds?: string[],
        isPartOfSgpGroup?: boolean,
    ): EventOptionGroup[] {
        return market.grouping.detailed.map((gameGrouping) =>
            this.createOptionGroup(market, fixture, fixtureOnline, gameGrouping, grouping, longIds, isPartOfSgpGroup),
        );
    }

    private createOptionGroup(
        market: DetailedFixtureMarket,
        fixture: string,
        fixtureOnline: boolean,
        gameGrouping: DetailedGrouping,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        longIds?: string[],
        isPartOfSgpGroup?: boolean,
    ): EventOptionGroup {
        const game = market as Game;
        const combo = new ComboPrevention();
        combo.minimum = ComboPreventionMinimum[game.combo2];
        combo.restriction = ComboPreventionType[game.combo1];
        const optionGroup = new EventOptionGroup();
        optionGroup.id = game.id.toString();
        optionGroup.originalId = game.id.toString();
        optionGroup.comboPrevention = combo;
        optionGroup.templateId = game.templateId?.toString() ?? '';
        optionGroup.name = this.getTranslatedValue(game.name);
        optionGroup.visible = game.visibility === Visibility.Visible;
        optionGroup.online = fixtureOnline && optionGroup.visible;
        optionGroup.hidden = game.visibility === Visibility.Hidden;
        optionGroup.detailedGrouping = this.mapDetailedGrouping(
            gameGrouping,
            grouping,
            game.id,
            OfferSource.V1,
            undefined,
            this.marketFallback.shouldForceRegularDisplayTypeTV1(gameGrouping, game),
        );
        optionGroup.periodName = this.getPeriodValue(game, gameGrouping, grouping);
        const categoryId = game.templateCategory && game.templateCategory.id;
        optionGroup.gridGroups = this.getGridGroups(game.grouping, categoryId);
        optionGroup.resultSorting = toResultSorting(game.resultOrder);
        optionGroup.options = this.mapOptions(game, fixture, fixtureOnline, optionGroup.resultSorting, gameGrouping, grouping, gameGrouping.subIndex);
        optionGroup.categoryAttribute = game.attr;
        optionGroup.parameterTranslations = grouping.parameterTranslations;
        optionGroup.templateCategoryId = game.categoryId;
        optionGroup.templateCategory = game.category;
        optionGroup.player1 = game.player1 && this.getTranslatedValue(game.player1);
        optionGroup.player2 = game.player2 && this.getTranslatedValue(game.player2);
        optionGroup.isPartOfSgpGroup = !!isPartOfSgpGroup;
        optionGroup.source = OfferSource.V1;
        this.patch(optionGroup, market);

        return optionGroup;
    }

    private mapOptions(
        game: Game,
        fixtureId: string,
        fixtureOnline: boolean,
        resultSorting: ResultSorting,
        detailedGrouping: DetailedGrouping,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        order?: number,
        longIds?: string[],
    ): EventOption[] {
        const options = game.results.map((result) => {
            const option = new EventOption();
            option.id = result.id;
            option.name = result.name.short || result.name.value;
            option.nativePrice = NativePrice.fromNativePrice(result);
            option.visible = result.visibility === Visibility.Visible && result.odds > 1 && game.visibility === Visibility.Visible;
            option.online = fixtureOnline && option.visible;
            option.optionGroupId = game.id;
            option.templateId = game.templateId;
            option.originalEventId = fixtureId;
            option.order = order;
            option.playerId = result.playerId;
            option.player1 = game.player1 && this.getTranslatedValue(game.player1);
            option.player1Id = game.player1Id;
            option.player2 = game.player2 && this.getTranslatedValue(game.player2);
            option.player2Id = game.player2Id;
            option.optionColumnId = detailedGrouping.optionColumnId;
            if (detailedGrouping.optionColumnId !== undefined && grouping.optionColumns) {
                const optionColumn = grouping.optionColumns.find((t) => t.id === detailedGrouping.optionColumnId);
                if (optionColumn) {
                    option.optionColumnName = optionColumn.name;
                }
            }
            option.signature = {
                event: '',
                league: '',
                market: this.getSign(game.name),
                option: this.getSign(result.name),
                region: '',
                sport: '',
            };
            option.overridenName = result.sourceName && result.sourceName.value;
            option.marketAttribute = game.attr;
            option.optionAttribute = result.attr;
            option.totalsPrefix = result.totalsPrefix;
            option.optionSpread = game.spread;
            option.isBetBuilder = !!game.isBetBuilder;
            if (longIds?.length) {
                option.longId = longIds.join(';');
            }

            return option;
        });

        return sortOptions(options, resultSorting);
    }

    private getSign(name: Translation): string {
        return (name.short && name.shortSign) || (name.value && name.sign) || '';
    }
}
class OptionMarketFactory extends MarketFactoryBase implements MarketFactory {
    constructor(
        private marketFallback: MarketDisplayFallbackService,
        marketGroupingConfig: MarketGroupingConfig,
        private commonFactory: CommonFixtureFactory,
        betbuilderConfig: BetBuilderConfig,
    ) {
        super(marketGroupingConfig, betbuilderConfig);
    }

    canCreate(market: DetailedFixtureMarket): market is OptionMarket & boolean {
        return 'status' in market;
    }

    create(
        market: DetailedFixtureMarket,
        fixture: string,
        fixtureOnline: boolean,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        longIds?: string[],
        isPartOfSgpGroup?: boolean,
        participants?: Participant[],
        isPriceBoosted?: boolean,
        linkedFixture?: Fixture,
        excludePriceboostedMarketGrouping?: boolean,
    ): EventOptionGroup[] {
        return market.grouping.detailed.map((detailedGrouping) =>
            this.createOptionGroup(
                market,
                fixture,
                fixtureOnline,
                detailedGrouping,
                grouping,
                participants,
                isPriceBoosted,
                linkedFixture,
                longIds,
                isPartOfSgpGroup,
                excludePriceboostedMarketGrouping,
            ),
        );
    }

    private createOptionGroup(
        market: DetailedFixtureMarket,
        fixture: string,
        fixtureOnline: boolean,
        detailedGrouping: DetailedGrouping,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        participants: Participant[] | undefined,
        isPriceBoosted?: boolean,
        linkedFixture?: Fixture,
        longIds?: string[],
        isPartOfSgpGroup?: boolean,
        excludePriceboostedMarketGrouping?: boolean,
    ): EventOptionGroup {
        const optionMarket = market as OptionMarket;
        const optionGroup = new EventOptionGroup();
        optionGroup.id = optionMarket.id.toString();
        const combo = new ComboPrevention();
        combo.minimum = optionMarket.minCombo ?? 1;
        combo.restriction = ComboPreventionType[optionMarket.comboPrevention];
        optionGroup.comboPrevention = combo;
        optionGroup.isEachWay = !!optionMarket.isEachWay;
        optionGroup.isStartingPriceAvailable = !!optionMarket.isStartingPriceEnabled;
        optionGroup.isWinPriceAvailable = !!optionMarket.isWinPriceEnabled;
        optionGroup.placeTerms = optionMarket.placeTerms;
        optionGroup.originalId = optionMarket.id.toString();
        optionGroup.detailedGrouping = this.mapDetailedGrouping(
            detailedGrouping,
            grouping,
            optionMarket.id,
            OfferSource.V2,
            optionMarket.attr,
            this.marketFallback.shouldForceRegularDisplayTypeTV2(detailedGrouping, optionMarket) || isPriceBoosted,
            isPriceBoosted,
            excludePriceboostedMarketGrouping,
        );
        optionGroup.periodName = this.getPeriodValue(optionMarket, detailedGrouping, grouping);
        const categoryId = optionMarket.templateCategory && optionMarket.templateCategory.id;
        optionGroup.gridGroups = this.getGridGroups(optionMarket.grouping, categoryId);
        optionGroup.name = this.getTranslatedValue(optionMarket.name);
        optionGroup.visible = optionMarket.status === OptionMarketStatus.Visible;
        optionGroup.online = fixtureOnline && optionGroup.visible;
        optionGroup.hidden = optionMarket.status === OptionMarketStatus.Hidden;
        optionGroup.resultSorting = optionMarket.resultOrder ? toResultSorting(optionMarket.resultOrder) : undefined;
        optionGroup.originalParameters = optionMarket.parameters; // ToDo: To be removed after cds obsolete markettype enums are updated in FE.
        optionGroup.player1 = optionMarket.player1 && this.getTranslatedValue(optionMarket.player1);
        optionGroup.player2 = optionMarket.player2 && this.getTranslatedValue(optionMarket.player2);
        optionGroup.source = optionMarket.source;
        optionGroup.options = this.mapOptions(
            optionMarket,
            fixture,
            fixtureOnline,
            detailedGrouping,
            grouping,
            detailedGrouping.subIndex,
            isPriceBoosted,
            longIds,
        );

        if (optionGroup.resultSorting) optionGroup.options = sortOptions(optionGroup.options, optionGroup.resultSorting);

        if (optionMarket.fixtureParticipantId) {
            optionGroup.fixtureParticipantId = optionMarket.fixtureParticipantId;

            if (participants) {
                const overrideParticipantNameMarkets = this.commonFactory.shouldOverrideParticipantNames(optionMarket, grouping.sportId);
                const playerName = participants.find((x) => x.id === optionMarket.fixtureParticipantId)?.name;
                // when a new market is added, player is not available in push update.Hence removing the options
                if (!playerName && overrideParticipantNameMarkets) {
                    optionGroup.options = [];
                } else {
                    optionGroup.player1 = playerName && this.getTranslatedValue(playerName);
                    optionGroup.options.forEach((opt: EventOption) => {
                        opt.player1 = optionGroup.player1;
                        if (
                            overrideParticipantNameMarkets &&
                            optionGroup.player1 &&
                            detailedGrouping.displayType !== DisplayType.PlayerMilestone &&
                            detailedGrouping.displayType !== DisplayType.PlayerMilestoneOverUnder
                        ) {
                            opt.name = optionGroup.player1;
                        }
                    });
                }
            }
        }
        optionGroup.parameters = optionMarket.parameters;
        optionGroup.categoryAttribute = optionMarket.attr;
        optionGroup.parameterTranslations = grouping.parameterTranslations;
        optionGroup.isPartOfSgpGroup = !!isPartOfSgpGroup;
        optionGroup.templateCategory = optionMarket.templateCategory?.category;

        const templateId = optionMarket.parameters.find((f) => f.key === Tv2MarketParameters.TemplateId);
        if (templateId) {
            optionGroup.templateId = templateId.value;
        }

        const templateCategoryId = optionMarket.parameters.find((f) => f.key === Tv2MarketParameters.CategoryId);
        if (templateCategoryId) {
            optionGroup.templateCategoryId = Number(templateCategoryId.value);
        }

        this.patch(optionGroup, market, participants, excludePriceboostedMarketGrouping);
        this.patchDetailedGrouping(optionGroup, market, grouping, participants, this.marketGroupingConfig.correctScoreOptionsSortOrder);

        return optionGroup;
    }

    private mapOptions(
        optionMarket: OptionMarket,
        fixture: string,
        fixtureOnline: boolean,
        detailedGrouping: DetailedGrouping,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        order?: number,
        isPriceBoosted?: boolean,
        longIds?: string[],
    ): EventOption[] {
        optionMarket.options = this.filterOptionsByMarketType(optionMarket, grouping.sportId);

        if (isPriceBoosted) {
            optionMarket.options = this.filterOptionsByBoostedPrice(optionMarket);
        }

        return optionMarket.options.map((current, index) => {
            const option = new EventOption();
            option.id = current.id;
            option.name = current.name.short || current.name.value;
            option.nativePrice = NativePrice.fromNativePrice(current.price);
            option.priceId = current.price.id;
            option.boostedPriceId = current.boostedPrice?.id;
            option.boostedPrice = current.boostedPrice && NativePrice.fromNativePrice(current.boostedPrice);
            option.visible =
                current.status === OptionStatus.Visible &&
                (current.participantPriceType === ParticipantPriceType.StartingPrice || option.nativePrice.greaterThan(1)) &&
                optionMarket.status === OptionMarketStatus.Visible;
            option.online = fixtureOnline && option.visible;
            option.optionGroupId = optionMarket.id;
            option.originalEventId = fixture;
            option.order = order;
            option.marketAttribute = optionMarket.attr;
            option.optionAttribute =
                optionMarket.source === OfferSource.V1 ? current.attr : this.configureOptionAttribute(optionMarket, detailedGrouping, index);
            option.optionColumnId = detailedGrouping.optionColumnId;
            option.optionSpread = optionMarket.spread;
            option.player1 = optionMarket.player1 && this.getTranslatedValue(optionMarket.player1);
            option.player2 = optionMarket.player2 && this.getTranslatedValue(optionMarket.player2);
            option.player1Id = optionMarket.player1Id;
            option.player2Id = optionMarket.player2Id;
            if (detailedGrouping.optionColumnId !== undefined && grouping.optionColumns) {
                const optionColumn = grouping.optionColumns.find((t) => t.id === detailedGrouping.optionColumnId);
                if (optionColumn) {
                    option.optionColumnName = optionColumn.name;
                }
            }
            option.playerId = current.participantId;
            option.optionPlayerId = current.parameters?.fixtureParticipant;
            option.totalsPrefix =
                detailedGrouping.displayType === DisplayType.PlayerCombined
                    ? <TotalsPrefix>current.parameters?.optionTypes?.[0]
                    : optionMarket.source === OfferSource.V1
                      ? current.totalsPrefix
                      : undefined;
            option.signature = {
                event: '',
                league: '',
                market: this.getSign(optionMarket.name),
                option: this.getSign(current.name),
                region: '',
                sport: '',
            };
            if (longIds?.length) {
                option.longId = longIds.join(';');
            }
            option.participantPriceType = current.participantPriceType;
            option.priceHistory = current.price.priceHistory;
            option.status = current.status;
            option.isBetBuilder = !!optionMarket.isBetBuilder;

            const templateId = optionMarket.parameters.find((f) => f.key === Tv2MarketParameters.TemplateId);
            if (templateId) {
                option.templateId = Number(templateId.value);
            }

            if (isPriceBoosted) {
                option.optionMarketName = this.getTranslatedValue(optionMarket.name);
            }

            return option;
        });
    }

    private filterOptionsByBoostedPrice(optionMarket: OptionMarket) {
        return optionMarket.options.filter((option) => option.boostedPrice && NativePrice.fromNativePrice(option.boostedPrice).greaterThan(1));
    }

    private filterOptionsByMarketType(optionMarket: OptionMarket, sportId: number): Option[] {
        const marketType = optionMarket.parameters.find((x) => x.key === Tv2MarketParameters.MarketType)?.value;
        const optionTypesToFilter = this.marketGroupingConfig.optionTypesToFilterTV2MarketOptions.find(
            (o) => o.sportId === sportId && o.marketType === marketType,
        )?.optionTypes;
        if (!optionTypesToFilter) {
            return optionMarket.options;
        }

        return optionMarket.options.filter((option) =>
            optionTypesToFilter.some((optionTypeFilters) =>
                optionTypeFilters.every((currentFilter) => option.parameters.optionTypes.includes(currentFilter)),
            ),
        );
    }

    private configureOptionAttribute(optionMarket: OptionMarket, detailedGrouping: DetailedGrouping, index: number): string | undefined {
        switch (detailedGrouping.displayType) {
            case DisplayType.Spread:
            case DisplayType.SixPack: {
                const parameterValue: string | undefined = optionMarket.parameters.find(
                    (x) => x.key === Tv2MarketParameters.DecimalHandicap || x.key === Tv2MarketParameters.Handicap,
                )?.value;

                if (!parameterValue) {
                    return detailedGrouping.displayType === DisplayType.SixPack ? ' ' : '0';
                }

                const numericValue = toNumber(parameterValue) * (index === 0 ? 1 : -1);

                if (isNaN(numericValue)) {
                    return parameterValue;
                }

                return (numericValue > 0 ? '+' : '') + numericValue.toString();
            }
            case DisplayType.PlayerMilestone:
            case DisplayType.PlayerMilestoneOverUnder:
                return optionMarket.options[index]?.parameters?.optionTypes[0];
            default:
                return undefined;
        }
    }

    private getSign(name: Translation): string {
        return (name.short && name.shortSign) || (name.value && name.sign) || '';
    }
}
@Injectable({ providedIn: 'root' })
export class DetailedFixtureMarketFactory {
    constructor(
        private marketFallback: MarketDisplayFallbackService,
        private marketGroupingConfig: MarketGroupingConfig,
        private commonFactory: CommonFixtureFactory,
        private betbuilderConfig: BetBuilderConfig,
    ) {}

    private factories = [
        new GameMarketFactory(this.marketFallback, this.marketGroupingConfig, this.betbuilderConfig),
        new OptionMarketFactory(this.marketFallback, this.marketGroupingConfig, this.commonFactory, this.betbuilderConfig),
    ];

    create(
        market: DetailedFixtureMarket,
        fixture: string,
        fixtureOnline: boolean,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        participants?: Participant[],
        isPriceBoosted?: boolean,
        linkedFixture?: Fixture,
        longIds?: string[],
        isPartOfSgpGroup?: boolean,
        excludePriceboostedMarketGrouping?: boolean,
    ): EventOptionGroup[] {
        for (const factory of this.factories) {
            if (factory.canCreate(market)) {
                return factory.create(
                    market,
                    fixture,
                    fixtureOnline,
                    grouping,
                    longIds,
                    isPartOfSgpGroup,
                    participants,
                    isPriceBoosted,
                    linkedFixture,
                    excludePriceboostedMarketGrouping,
                );
            }
        }
        throw new Error('No market factory found for market');
    }

    update(optionGroup: EventOptionGroup, update: EventOptionGroup, fixtureOnline: boolean, isPriceBoosted?: boolean): EventOptionGroup {
        const updatedGroup = Object.assign(new EventOptionGroup(), optionGroup);
        updatedGroup.comboPrevention = update.comboPrevention;
        updatedGroup.templateId = update.templateId;
        updatedGroup.hidden = update.hidden && (!updatedGroup.groupedMarkets || !updatedGroup.groupedMarkets.length);
        updatedGroup.gridGroups = update.gridGroups;
        updatedGroup.resultSorting = update.resultSorting;
        updatedGroup.detailedGrouping.periodGroup = update.detailedGrouping.periodGroup;
        // Update the market order only for betting offer not grouped explicitly for market grouping configuration.
        if (update.detailedGrouping.marketSet === SetTab.Other) {
            updatedGroup.detailedGrouping.marketOrder = update.detailedGrouping.marketOrder;
        }
        if (isPriceBoosted && !update.options.length) {
            updatedGroup.options = [];
        } else {
            this.updateOptions(updatedGroup.options, update.options, fixtureOnline);
            if (isPriceBoosted) {
                updatedGroup.options = this.filterAndUpdatePriceBoostedOptions(updatedGroup.options, updatedGroup.name);
            }
        }
        updatedGroup.options = sortOptions(updatedGroup.options, ResultSorting.Order, optionGroup.resultSorting);
        this.updateGroupMarketIds(updatedGroup, update);
        updatedGroup.online = fixtureOnline && (update.online || updatedGroup.options.some((option) => option.online));
        updatedGroup.player1 = update.player1;
        updatedGroup.player2 = update.player2;

        return updatedGroup;
    }

    private updateOptions(currentOptions: EventOption[], updateOptions: EventOption[], fixtureOnline: boolean): void {
        if (!updateOptions.length) {
            return;
        }
        const marketId = updateOptions[0].optionGroupId;
        const currentOptionsList = [...currentOptions];
        currentOptionsList.forEach((current) => {
            if (current.optionGroupId !== marketId) {
                return;
            }
            const currentOptionIndex = currentOptions.findIndex((option) => option.id === current.id);
            const update = updateOptions.find((updateOption) => updateOption.id === current.id);
            if (update) {
                currentOptions[currentOptionIndex] = this.createOption(update, fixtureOnline, current);
            } else {
                currentOptions.splice(currentOptionIndex, 1);
            }
        });
        updateOptions
            .filter((update) => !currentOptions.find((current) => current.optionGroupId === update.optionGroupId && current.id === update.id))
            .forEach((create) => {
                currentOptions.push(this.createOption(create, fixtureOnline, undefined));
            });
    }

    private updateGroupMarketIds(optionGroup: EventOptionGroup, update: EventOptionGroup): void {
        if (optionGroup.id !== update.id && optionGroup.groupedMarkets && !optionGroup.groupedMarkets.find((id) => id === update.id.toString())) {
            optionGroup.groupedMarkets = optionGroup.groupedMarkets || [];
            optionGroup.groupedMarkets.push(update.id);
        }
    }

    private createOption(update: EventOption | undefined, fixtureOnline: boolean, current: EventOption = new EventOption()): EventOption {
        if (!update) {
            return current;
        }
        current.id = update.id;
        current.name = update.name;
        current.nativePrice = update.nativePrice.clone();
        current.priceId = update.priceId;
        current.visible = !!update.visible;
        current.online = fixtureOnline && current.visible;
        current.optionGroupId = update.optionGroupId;
        current.originalEventId = update.originalEventId;
        current.order = update.order;
        current.optionColumnId = update.optionColumnId;
        current.signature = {
            event: '',
            league: '',
            market: update.signature.market,
            option: update.signature.option,
            region: '',
            sport: '',
        };
        current.marketAttribute = update.marketAttribute;
        current.optionAttribute = update.optionAttribute;
        current.optionSpread = update.optionSpread;
        current.player1 = update.player1;
        current.playerId = update.playerId;
        current.optionColumnName = update.optionColumnName;
        current.boostedPrice = update.boostedPrice;
        current.boostedPriceId = update.boostedPriceId;
        current.totalsPrefix = update.totalsPrefix;
        current.participantPriceType = update.participantPriceType;
        current.priceHistory = update.priceHistory;
        current.status = update.status;
        current.optionMarketName = update.optionMarketName;

        return current;
    }

    private filterAndUpdatePriceBoostedOptions(options: EventOption[], optionMarketName: string): EventOption[] {
        return options
            .filter((option) => option.boostedPrice?.greaterThan(1))
            .map((option) => {
                option.showBoostedPrice = true;
                option.optionMarketName = optionMarketName;

                return option;
            });
    }
}
