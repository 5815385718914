import { pprops } from '@frontend/sports/common/base-utils';
import { createAction, props } from '@ngrx/store';

import { EventDetailsColumnType } from '../event-model/helpers/event-media-helper.service';
import { EventModel } from '../event-model/model/event.model';
import { EventSwitcherItem, MediaSetEventInternal, MediaTab, RestoreStatePayload } from '../media-api/model';
import { MediaState } from './media.state';

export class Actions {
    private static readonly INIT = '@media/INIT';
    private static readonly TOGGLE_MEDIA_MODULE = '@media/TOGGLE_MEDIA_MODULE';
    private static readonly TOGGLE_EXPAND = '@media/TOGGLE_EXPAND';
    private static readonly BREAKPOINT_CHANGED = '@media/BREAKPOINT_CHANGED';
    private static readonly UPDATE_SCOREBOARD = '@media/UPDATE_SCOREBOARD';
    private static readonly SET_ACTIVE_TAB = '@media/SET_ACTIVE_TAB';
    private static readonly TOGGLE_PIN = '@media/TOGGLE_PIN';
    private static readonly FETCH_VIDEO_EVENTS = '@media/FETCH_VIDEO_EVENTS';
    private static readonly START_FETCH_VIDEO_EVENTS = '@media/START_FETCH_VIDEO_EVENTS';
    private static readonly SET_VIDEO_SWITCHER_CONTENT = '@media/SET_VIDEO_SWITCHER_CONTENT';
    private static readonly SET_AVAILABLE_EXPAND_LOCATION = '@media/SET_AVAILABLE_EXPAND_LOCATION';
    private static readonly TOGGLE_AUTOPLAY = '@media/TOGGLE_AUTOPLAY';
    private static readonly SET_EVENT_INTERNAL = '@media/SET_EVENT_INTERNAL';
    private static readonly TOGGLE_UNPIN_INFO_MESSAGE = '@media/TOGGLE_UNPIN_INFO_MESSAGE';
    private static readonly TOGGLE_COLLAPSED_STATE = '@media/TOGGLE_COLLAPSED_STATE';
    private static readonly ACCEPT_UNPIN_INFO_MESSAGE = '@media/ACCEPT_UNPIN_INFO_MESSAGE';
    private static readonly START_USER_SERVICE_EVENTS_LISTENER = '@media/START_USER_SERVICE_EVENTS_LISTENER';
    private static readonly PIN_ENABLED_STATE_TOGGLE = '@media/PIN_ENABLED_STATE_TOGGLE';
    private static readonly UNSET_EVENT = '@media/UNSET_EVENT';
    private static readonly RESTORE_STATE = '@media/RESTORE_STATE';
    private static readonly START_RESTORE_STATE = '@media/START_RESTORE_STATE';
    private static readonly RESET_STATE = '@media/RESET_STATE';
    private static readonly POPULATE_FROM_STORAGE = '@media/POPULATE_FROM_STORAGE';

    static readonly init = createAction(Actions.INIT);

    static readonly toggleMediaModule = createAction(Actions.TOGGLE_MEDIA_MODULE, props<{ value: boolean }>());

    static readonly togglePin = createAction(Actions.TOGGLE_PIN, props<{ value: boolean }>());

    static readonly toggleAutoplay = createAction(Actions.TOGGLE_AUTOPLAY, props<{ value: boolean }>());

    static readonly toggleExpand = createAction(Actions.TOGGLE_EXPAND, props<{ value: boolean }>());

    static readonly breakpointChanged = createAction(Actions.BREAKPOINT_CHANGED);

    static readonly setActiveTab = createAction(Actions.SET_ACTIVE_TAB, props<{ tab: MediaTab }>());

    static readonly setVideoSwitcherContent = createAction(Actions.SET_VIDEO_SWITCHER_CONTENT, props<{ content: EventSwitcherItem[] }>());

    static readonly setAvailableExpandLocation = createAction(Actions.SET_AVAILABLE_EXPAND_LOCATION, props<{ value: boolean }>());

    static readonly startFetchVideoEvents = createAction(Actions.START_FETCH_VIDEO_EVENTS);

    static readonly fetchVideoEvents = createAction(Actions.FETCH_VIDEO_EVENTS);

    static readonly updateScoreboard = createAction(Actions.UPDATE_SCOREBOARD, props<{ model: EventModel }>());

    static readonly setEventInternal = createAction(Actions.SET_EVENT_INTERNAL, pprops<MediaSetEventInternal>());

    static readonly toggleUnpinInfoMessage = createAction(Actions.TOGGLE_UNPIN_INFO_MESSAGE, props<{ value: boolean }>());

    static readonly acceptUnpinInfoMessage = createAction(Actions.ACCEPT_UNPIN_INFO_MESSAGE);

    static readonly toggleCollapsedState = createAction(Actions.TOGGLE_COLLAPSED_STATE, props<{ value: boolean }>());

    static readonly startUserServiceEventsListener = createAction(Actions.START_USER_SERVICE_EVENTS_LISTENER);

    static readonly pinEnabledStateToggle = createAction(Actions.PIN_ENABLED_STATE_TOGGLE, props<{ value: boolean }>());

    static readonly unsetEvent = createAction(Actions.UNSET_EVENT, props<{ columnType: EventDetailsColumnType }>());

    static readonly restoreState = createAction(Actions.RESTORE_STATE, pprops<RestoreStatePayload>());

    static readonly startRestoreState = createAction(Actions.START_RESTORE_STATE);

    static readonly resetState = createAction(Actions.RESET_STATE, props<{ value: boolean | undefined }>());

    static readonly populateFromStorage = createAction(Actions.POPULATE_FROM_STORAGE, props<{ state: MediaState }>());
}
