import { SystemKey } from '../system-bet/models';
import { SlipTypeKeys } from './betslip-system-type';

export abstract class SlipType {
    private _key: string;
    private _name: string;
    private _basis: number[];

    constructor(key: string, name: string, basis: number[]) {
        this._key = key;
        this._name = name;
        this._basis = basis;
    }

    get key(): string {
        return this._key;
    }

    get name(): string {
        return this._name;
    }

    get basis(): number[] {
        return this._basis;
    }

    equals(object: SlipType | null | undefined): boolean {
        if (!object) {
            return false;
        }

        return this.key === object.key && SlipType.isBasisEqual(this.basis, object.basis);
    }

    toJSON(): { key: string; name: string; basis: number[] } {
        return {
            key: this.key,
            name: this.name,
            basis: this.basis,
        };
    }

    private static isBasisEqual(thisBasis: number[], otherBasis: number[]): boolean {
        if (thisBasis.length !== otherBasis.length) {
            return false;
        }
        for (let i = 0; i < thisBasis.length; i++) {
            if (thisBasis[i] !== otherBasis[i]) {
                return false;
            }
        }

        return true;
    }
}

export class SingleSlipType extends SlipType {
    constructor() {
        super(SlipTypeKeys.Single, '', [1]);
    }
}

export class ComboSlipType extends SlipType {
    constructor() {
        super(SlipTypeKeys.Combo, '', [-1]);
    }
}

export class SystemSlipType extends SlipType {
    constructor(key: string, name: string, basis: number[]) {
        super(key, name, basis);
    }
}

export class SystemBankerSinglesSlipType extends SystemSlipType {
    constructor() {
        super(SystemKey.BankerSingles, '', [1]);
    }
}
