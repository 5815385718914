import { DomainEvent } from '@frontend/sports/common/dispatcher-utils';
import { PickId } from 'packages/sports/common/betslip/core/picks/pick-id';

/* tslint:disable variable-name */

export class BaseBetslipEvents {
    static BetslipPluginStateChanged = new DomainEvent<void>('BETSLIP_PLUGIN_STATE_CHANGED');
    static ClickOutsideRewardTokensContainer = new DomainEvent<void>('BETSLIP_CLICK_OUTSIDE_TOKENS_CONTAINER');
    static Initialized = new DomainEvent<{ [key: string]: PickId }>('BETSLIP_INITIALIZED');
    static HomeMarqueAddPick = new DomainEvent<PickId>('homeMarquee/add-pick-by-id');
    static BetPlaceSuccess = new DomainEvent<void>('BET_PLACE_SUCCESS');
}

export class BaseBetslipActions {
    static RemoveAllPicks = new DomainEvent<{ selected?: boolean; visible: boolean }>('BETSLIP_REMOVE_ALL_PICKS');
}

export interface PicksChangedData {
    count: number;
    auto: boolean;
    afterBetPlacement?: boolean;
}
