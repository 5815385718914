import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output } from '@angular/core';

import { ISimpleChanges, isDefined } from '@frontend/sports/common/base-utils';
import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { EpcotConfigService, EpcotModule } from '../../common/epcot-config.service';
import { EventParticipantImage } from '../../event-model/model/event.model';
import { LoggerFactory } from '../../logging/logger-factory.service';
import { SportsRemoteLogger } from '../../logging/sports-remote-logger.service';
import { RedirectHelperService } from '../../navigation-core/redirect-helper.service';
import { TrackData } from '../../tracking/tracking.models';
import { UserService } from '../../user/services/user.service';
import { FavouriteType, FavouritesViewModel } from '../favourites.model';
import { FavouritesService } from '../favourites.service';
import { FavouriteToggleTrackingService } from './favourite-toggle-tracking.service';

export interface FavouriteToggleTracking {
    name: string;
    source: string;
    position?: number;
    additional?: Partial<TrackData>;
}

@Component({
    selector: 'ms-favourite-toggle',
    templateUrl: 'favourite-toggle.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class FavouriteToggleComponent implements OnChanges, OnDestroy {
    @Input() id: string;
    @Input() name: string;
    @Input() sport: number;
    @Input() type: FavouriteType;
    @Input() static: boolean;
    @Input() tracking: FavouriteToggleTracking;
    @Input() participantImage?: EventParticipantImage;
    @Output() toggle = new EventEmitter<boolean>();
    @HostBinding('class') className = 'favourite-toggle';

    model?: FavouritesViewModel;
    disabled: boolean;
    toggleClass: boolean;
    flag = false;
    private isEpcotEnabled: boolean;
    private subscription?: Subscription;
    private readonly logger: SportsRemoteLogger;

    constructor(
        private userService: UserService,
        private favouritesService: FavouritesService,
        private redirectHelper: RedirectHelperService,
        private changeRef: ChangeDetectorRef,
        private trackingService: FavouriteToggleTrackingService,
        loggerFactory: LoggerFactory,
        public sitecore: Sitecore,
        private epcotConfigService: EpcotConfigService,
    ) {
        this.logger = loggerFactory.getLogger('FavouriteToggleComponent');
        this.isEpcotEnabled = this.epcotConfigService.isEnabled(EpcotModule.Favorites);
    }

    setToggle(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.flag = true;
        if (!this.userService.isAuthenticated) {
            this.redirectHelper.goToLogin();
        } else {
            if (this.model) {
                this.remove();
            } else {
                this.add();
            }
        }
    }

    ngOnChanges(changes: ISimpleChanges<FavouriteToggleComponent>): void {
        if (changes.static) {
            if (this.static) {
                this.unsubscribe();
            } else {
                this.subscribe();
            }
        }

        this.setState();
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    private unsubscribe(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
    }

    private subscribe(): void {
        if (!this.subscription) {
            this.subscription = this.favouritesService.change.subscribe(() => this.setState());
        }
    }

    private remove(): void {
        this.toggleClass = false;
        this.favouritesService
            .remove(this.model!)
            .pipe(this.handleError())
            .subscribe(() => this.toggled(this.sitecore.favouritesMessages.RemoveSuccess));
    }

    private add(): void {
        this.toggleClass = true;
        this.favouritesService
            .add(this.id, this.sport, this.type, this.name, this.participantImage)
            .pipe(this.handleError())
            .subscribe(() => this.toggled(this.sitecore.favouritesMessages.AddSuccess, false, this.isEpcotEnabled ? '' : 'theme-success-i'));
    }

    private handleError = () => (source: Observable<any>) =>
        source.pipe(
            map(() => true),
            catchError((error: any) => {
                this.toggleClass = !!this.model;
                if (typeof error === 'string') {
                    this.toggled(error, true);
                } else {
                    this.logger.error(error, 'Saving favourites failed');
                }

                return of(false);
            }),
            filter((result) => result),
        );

    private toggled(message: string, toggleError: boolean = false, icon?: string): void {
        this.favouritesService.notify(message, icon);
        this.setState();

        const isToggled = isDefined(this.model);
        this.toggle.emit(isToggled);
        this.changeRef.markForCheck();
        if (!toggleError) {
            this.trackingService.trackToggle(isToggled, this.type, this.tracking);
        }
    }

    private setState(): void {
        this.disabled = !this.userService.isAuthenticated;
        this.model = this.favouritesService.get(this.id, this.sport, this.type);
        if (!this.flag) {
            this.toggleClass = !!this.model;
        }

        this.changeRef.markForCheck();
    }
}
