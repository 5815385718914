import { BetSlip } from '@bpos/v1/my-bets';
import { BetslipListType, OpenBetsSummary } from '@frontend/sports/types/models/my-bets';

import { MyBetsEventModel } from '../../my-bets/models/my-bets-event.model';

export enum MyBetsRaceBetType {
    Win = 0,
    EachWay,
    Place,
    Forecast,
    Tricast,
    CombinationForecast,
    CombinationTricast,
}

export interface PinnedBets {
    betslipNumber: string;
    isMigratedBet: boolean;
}

export interface MyBetsFilteredResponse {
    events: { [key: string]: MyBetsEventModel };
    betslips: BetSlip[];
    summary: OpenBetsSummary;
    typeFilter: BetslipListType;
    errorLoadingBets: boolean | undefined;
}

export const myBetsFilteredErrorResponse = (): MyBetsFilteredResponse => ({
    betslips: [],
    events: {},
    summary: {
        openEvents: {},
        openBetsCount: 0,
        liveBetsCount: 0,
        betNumbers: [],
        isMigrationSummary: false,
        hasError: false,
    },
    typeFilter: BetslipListType.Settled,
    errorLoadingBets: true,
});

export interface MyBetsSingleResponse {
    betslip: BetSlip | null;
    events: { [key: string]: MyBetsEventModel };
}

export const myBetsSingleErrorResponse = (): MyBetsSingleResponse => ({
    betslip: null,
    events: {},
});

export interface MyBetsListSlimResponse {
    betslips: BetSlip[];
    events: { [key: string]: MyBetsEventModel };
    errorLoadingBets?: boolean;
}

export const myBetsListSlimErrorResponse = (): MyBetsListSlimResponse => ({
    betslips: [],
    events: {},
    errorLoadingBets: true,
});
