import { SourceOfPick } from '../betslip-base/models/pick-models';
import { PickType } from '../betslip-base/models/pick-type.enum';

/**
 * EVENT NAMES
 * can be any string except the 'error.XXXXXX' format which is reserved for error reporting
 * and except the 'pageView' that is reserved for the page navigation/view.
 */
const eventConstants = {
    EVENT_PAGE_VIEW: 'pageView',
    EVENT_CART_CHECKOUT: 'Cart.checkout',
    EVENT_PURCHASE: 'purchase',
    EVENT_CHECKOUT: 'checkout',
    EVENT_CART_PURCHASE: 'Cart.purchase',
    EVENT_CART_ADD: 'Cart.betAdded',
    EVENT_CART_REMOVE: 'Cart.betRemove',
    EVENT_CLICKS: 'Event.Clicks',
    EVENT_BETSLIP_ERROR: 'Betslip_Error',
    EVENT_EARLYPAYOUT: 'event.earlyPayout',
    EVENT_EDIT_BET: 'Event.Functionality.EditMyBet',
    EVENT_EDIT_FAVOURITES: 'Event.Functionality.EditMyFavourites',
    EVENT_LINE_SWITCHER: 'Event.LineSwitcher',
    EVENT_MEDIA: 'Event.SiteElement.Interactions',
    EVENT_FUNC_MARKET_SWITCH: 'Event.Functionality.MarketSwitch',
    EVENT_FUNC_BETS: 'Event.Functionality.Bets',
    EVENT_FUNC_SHOWHIDE_BETS: 'Event.Functionality.ShowHideBets',
    EVENT_INIT_QUICKBET: 'Event.InitiateQB',
    EVENT_NAVIGATION_MENUS: 'Event.NavigationMenus',
    EVENT_AUTO_EARLYPAYOUT_SET: 'event.AutoearlyPayoutSet',
    EVENT_VIDEO_INTERACTIONS: 'Event.VideoInteractions',
    EVENT_FAVOURITES: 'event.favourites',
    EVENT_GAME_OPEN: 'gameOpen',
    EVENT_VIRTUAL_OPEN: 'virtualOpen',
    EVENT_MINI_GAME_OPEN: 'miniGameOpen',
    EVENT_RELOAD_HIDDEN: 'reloadHidden',
    EVENT_RELOAD_OFFLINE: 'reloadOffline',
    EVENT_PREF_NAV: 'perfNav',
    EVENT_PERF_APP_LOAD: 'perfAppLoad',
    EVENT_TRACKING: 'Event.Tracking',
    EVENT_PODVIEW: 'Event.podView',
    EVENT_AZSPORTS: 'Event.NavigationMenus',
    EVENT_ALERT_ENABLED: 'Alert Enabled',
    EVENT_MARKET_SWITCH: 'Event.MarketSwitch',
    EVENT_GEOLOCATION_ERROR: 'GEOLocation.ERROR',
    EVENT_TRIGGER_GEO_FETCH: 'GEOLocation.FETCH',
    EVENT_OVERASK: 'Event.OverAsk',
    EVENT_CONTENT_VIEW: 'contentView',
    EVENT_BET_STATUS: 'Event.BetStatus',
    EVENT_CART_ADD_BALANCE: 'cart.addBalance',
    MESSAGE_VIEW: 'Message View',
    EVENT_CORE_NAVIGATION: 'Event.CoreNavigation',
    EVENT_TEASER_BETS: 'Event.TeaserBets',
    EVENT_NAME: 'event.name',
    EVENT_CLICK: 'click',
    SEE_ALL: 'see all',
    SEE_ALL_OVERLAY: 'see all overlay',
    LOAD: 'load',
    CLOSE: 'close',
    SPORTS_EVENT: 'sports event',
};

type EventConstants = typeof eventConstants;

export const NOT_APPLICABLE = 'not applicable';
export const TRACKING_GAMEDETAILS = 'live|penalties|red cards';

export const TRACKING_NO_GAMEDETAILS = 'no game details available';

/**
 * VARIABLES
 * for data objects variables with deeper hierarchy the JavaScript dot notation must be used (e.g. user.profile.accountID)
 */
const dataConstants = {
    BUTTON_CLICK_EVENT: 'Event.buttonClick' as const,
    CART_PURCHASE: 'cart.purchase' as const,
    CART_CHECKOUT: 'cart.checkout' as const,
    TRANSACTION_PRODUCT_ODDS: 'transaction.Products.odds' as const,
    COMPONENT_ACTION_EVENT: 'component.ActionEvent' as const,
    COMPONENT_AUTO_EARLYPAYOUT: 'component.AutoEarlyPayout' as const,
    COMPONENT_CATEGORY_EVENT: 'component.CategoryEvent' as const,
    COMPONENT_CATEGORY_SUB_TYPE: 'component.categorySubType' as const,
    COMPONENT_CATEGORY_TYPE: 'component.categoryType' as const,
    COMPONENT_EVENT_NAME: 'component.EventName' as const,
    COMPONENT_EVENT_DETAILS: 'component.EventDetails' as const,
    COMPONENT_FAVOURITES_ACTION: 'component.favourites-action' as const,
    COMPONENT_GAME_CONTAINER_DESCRIPTION: 'component.gameContainerDescription' as const,
    COMPONENT_GAME_CONTAINER_LOCATION: 'component.gameContainerLocation' as const,
    COMPONENT_GAME_DISPLAY_NAME: 'component.gameDisplayName' as const,
    COMPONENT_GAME_FAVOURITE: 'component.gameFavourite' as const,
    COMPONENT_GAME_GRID_POSITION: 'component.Game.GridPosition' as const,
    COMPONENT_GAME_NAME: 'component.gameName' as const,
    COMPONENT_GAME_PLAYING_STATUS: 'component.gamePlayingStatus' as const,
    COMPONENT_GAME_POSITION: 'component.Game.position' as const,
    COMPONENT_GAME_SECTION: 'component.Game.section' as const,
    COMPONENT_GAME_STATUS: 'component.gameStatus' as const,
    COMPONENT_GAME_TYPE: 'component.gameType' as const,
    COMPONENT_LABEL_EVENT: 'component.LabelEvent' as const,
    COMPONENT_LEAGUE_ID: 'component.LeagueId' as const,
    COMPONENT_LOCATION_EVENT: 'component.LocationEvent' as const,
    COMPONENT_MODULE_NAME: 'component.moduleName' as const,
    COMPONENT_MARKET_ID: 'component.MarketID' as const,
    COMPONENT_POSITION_EVENT: 'component.PositionEvent' as const,
    COMPONENT_PAGE_LAYOUT: 'component.pageLayout' as const,
    COMPONENT_MODULE_POSITION: 'component.modulePosition' as const,
    COMPONENT_MODULE_CUSTOM_NAME: 'component.moduleCustName' as const,
    COMPONENT_REFERRED_BET: 'component.referredBet' as const,
    COMPONENT_SITE_ELEMENT_INTERCATIONS: 'component.SiteElement.Interactions' as const,
    COMPONENT_SLIP_ID: 'component.SlipID' as const,
    COMPONENT_SPORT_ID: 'component.SportId' as const,
    COMPONENT_URL_CLICKED: 'component.URLClicked' as const,
    CUSTOMER_ID: 'customer.id' as const,
    COMPONENT_CONTENT_POSITION: 'component.ContentPosition' as const,
    COMPONENT_MODULE_SOURCE: 'component.moduleSource' as const,
    COMPONENT_MODULE_TYPE: 'component.moduleType' as const,
    COMPONENT_BET_SOURCE: 'component.betsource' as const,
    COMPONENT_MODULE_AFFILIATION_CONFIG: 'component.moduleAffiliationConfig' as const,
    PAGE_BETSLIP_ERROR: 'page.Betslip_Error' as const,
    PAGE_DISPLAYED_ELEMENT: 'page.displayed.element' as const,
    PAGE_EDITBET_ERROR: 'page.EditedBet_Error' as const,
    PAGE_NAME: 'page.name' as const,
    PAGE_NAVIGATION_MENUS: 'page.navigationMenus' as const,
    PAGE_PROMOTION_ID: 'page.promotionID' as const,
    PAGE_REFERRING_ACTION: 'page.referringAction' as const,
    PAGE_RELOAD_AFTER_HIDDEN: 'page.reloadAfterPageHidden' as const,
    PAGE_RELOAD_AFTER_OFFLINE: 'page.reloadAfterOffline' as const,
    PAGE_SITE_SECTION: 'page.siteSection' as const,
    PAGE_URL: 'page.url' as const,
    MARQUEE_CONTENT_LOGIC: 'marquee.contentLogic' as const,
    PERFORMANCE_APP_COMPILAION: 'performance.appCompilation' as const,
    PERFORMANCE_APP_RUN: 'performance.appRun' as const,
    PERFORMANCE_ASSETS_FETCH: 'performance.assetsFetch' as const,
    PERFORMANCE_CDSAPI: 'performance.cdsApi' as const,
    PERFORMANCE_CLIENT_BOOTSTRAP: 'performance.clientBootstrap' as const,
    PERFORMANCE_DOM_LOADED: 'performance.domContentLoaded' as const,
    PERFORMANCE_INTERACTIVE: 'performance.interactive' as const,
    PERFORMANCE_LOAD: 'performance.load' as const,
    MARKET_ID: 'marketID' as const,
    Transaction_Product_Category: 'transactionProducts.category' as const,
    Transaction_Product_BetType: 'transactionProducts.betType' as const,
    PERFORMANCE_NAV: 'performance.nav' as const,
    PRODUCT_BET_SPORTS: 'product.betSports' as const,
    PRODUCT_PICK_DETAILS: 'product.pickDetails' as const,
    VIDEO_INTERNAL_EVENT_ID: 'video.InternalEventID' as const,
    VIDEO_PROVIDER_EVENT_ID: 'video.ProviderEventID' as const,
    INVOKE_PRODUCT: 'invoker.product' as const,
    INVOKE_INTERFACE: 'invoker.interface' as const,
    SPORT_EVENT_ID: 'sportEventID' as const,
    SPORT_LEAGUE_ID: 'leagueID' as const,
    GEOLOCATION_FETCH_TYPE: 'geolocation.fetchType' as const,
    GEOLOCATION_ERRORCODE: 'geolocation.errorCode' as const,
    GEOLOCATION_ERRORMESSAGE: 'geolocation.errorMessage' as const,
    SHOW_MODULE: 'showmodule' as const,
    BETS_BOOSTS: 'betsBoosts' as const,
    BET_TICKET_ID: 'betTicketID' as const,
    COMPONENT_RESULT_ID: 'component.ResultID' as const,
    SPORT_ID: 'sportID' as const,
    RESULT_ID: 'resultID' as const,
    REGION_ID: 'regionID' as const,
    BET_TYPE: 'betType' as const,
    PROMO_ID: 'promoID' as const,
    MESSAGE_TYPE: 'messageType' as const,
    GAME_DETAILS: 'gameDetails' as const,
    COMPONENT_SITECORE_ID: 'component.sitecoreID' as const,
    COMPONENT_USER_SEGMENTS: 'component.userSegment' as const,
    MARQUEE_NAME: 'marqueeName' as const,
    SITECORE_TEMPLATE_ID: 'page.sitecoretemplateid' as const,
    MARQUEE_TYPE: 'marqueeType' as const,
    SHOW_MARQUEE: 'showmarquee' as const,
    MARQUEE_RECOMMENDATION_TYPE: 'component.recommendationtype' as const,
    BET_DETAILS: 'bet_details' as const,
    BET_CALENDER: 'bet_calendar' as const,
    QUICKBET: 'quickbet' as const, // Track when user taps on the place bet button
    PlaceBetSuccess: { PAGE_NAME: 'M2_slip_placed' }, // Track when bet placement is successful
    PlaceBet: { PAGE_NAME: 'M2_slip_action_PlaceBet' as const },
    TOPEVENTS_POD_DATA: 'podData' as const,
    Event_ID: 'eventId' as const,
    SHOW_NEXTRACES: 'shownextraces' as const,
    SELECTIONS_IN_SLIP: 'selectionsInSlip' as const,
    VARIANT: 'variant' as const,
    EVENT_NAME: 'event.name' as const,
};

type DataConstants = typeof dataConstants;

const miscellaneousConstants = {
    LINE_SWITCHER_TRACKING: 'BetslipLineSwitcher' as const,
};

type MiscellaneousConstants = typeof miscellaneousConstants;

export enum VideoStreamTrackingStatus {
    VideoStreaming = 'Video Streaming',
    VideoInitiated = 'Video-Initiated',
    NotApplicable = 'not applicable',
    VideoStarted = 'VideoStarted',
    VideoContent = 'Video Content Not Available Message',
    UserNotLoggedIn = 'Log In to watch video live',
    StreamingAvailableCta = 'streaming available CTA',
}

export enum EsportTrackingValues {
    Esports = 'esports',
    LivePage = 'live page',
    LivePageShowMoreCta = 'show more',
    BannerMessage = 'esports welcome message',
}

type TrackingConstants = EventConstants & DataConstants & MiscellaneousConstants;

export const trackingConstants: TrackingConstants = {
    ...eventConstants,
    ...dataConstants,
    ...miscellaneousConstants,
};
interface TrackGeneral {
    events?: string | undefined;
    earlyPayoutAmount?: number;
    earlyPayoutSlipID?: string;
    error?: boolean;
    eventId?: any;
    pushUpdate?: boolean;
    autoCashoutAmount?: number;
    autoCashoutType?: string;
    sportId?: number;
    name?: string;
    source?: string;
    [trackingConstants.PAGE_NAME]: string | undefined;
    [trackingConstants.PAGE_DISPLAYED_ELEMENT]: string | undefined;
    [trackingConstants.PAGE_URL]: string | undefined;
    [trackingConstants.PAGE_PROMOTION_ID]: string | undefined;
    [trackingConstants.BUTTON_CLICK_EVENT]: string | undefined;
    [trackingConstants.PAGE_BETSLIP_ERROR]: string | undefined;
    [trackingConstants.PAGE_REFERRING_ACTION]: string | undefined;
    [trackingConstants.COMPONENT_CATEGORY_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_LABEL_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_ACTION_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_EVENT_DETAILS]: string | undefined;
    [trackingConstants.COMPONENT_URL_CLICKED]: string | undefined;
    [trackingConstants.COMPONENT_LOCATION_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_POSITION_EVENT]: string | undefined;
    [trackingConstants.PAGE_RELOAD_AFTER_HIDDEN]: number | undefined;
    [trackingConstants.PAGE_RELOAD_AFTER_OFFLINE]: number | undefined;
}

export interface TrackPlaceBet {
    transactionId: string;
    transactionAffiliation: string;
    transactionTotal: number;
    transactionProducts: TrackBetslipPick[];
    numberOfBets: number;
    currencyCode: string;
    oddsSettings?: string;
    [trackingConstants.PAGE_NAME]: string;
    [trackingConstants.PRODUCT_BET_SPORTS]: string;
    [trackingConstants.CART_PURCHASE]: string;
    [trackingConstants.CART_CHECKOUT]: string;
    [trackingConstants.TRANSACTION_PRODUCT_ODDS]: string;
    [trackingConstants.COMPONENT_SLIP_ID]?: string | undefined;
    betRequestID?: string;
}

export interface NextRaces {
    [trackingConstants.COMPONENT_MODULE_NAME]: string;
    [trackingConstants.COMPONENT_MODULE_POSITION]: string;
    [trackingConstants.COMPONENT_CONTENT_POSITION]: string | number;
    [trackingConstants.COMPONENT_PAGE_LAYOUT]: string;
    [trackingConstants.COMPONENT_MODULE_CUSTOM_NAME]: string;
    [trackingConstants.COMPONENT_MODULE_SOURCE]: string;
}

export interface TrackAddRemoveBet {
    name?: string;
    category?: string;
    price?: number;
    transactionId: string;
    transactionProducts: TrackBetslipPick;
    quantity?: number;
    transactionAffiliation?: string;
    sku?: string;
    insurance?: string;
    bet_details?: string;
    sport_id?: number;
    market_id?: number;
    league_id?: number;
    result_id?: number;
    betType?: string;
    [trackingConstants.PRODUCT_PICK_DETAILS]: string;
}

export interface TrackBetslipPick {
    name: string;
    category: string;
    betslipID?: string;
    betRequestID?: string;
    systemBetType?: PickType | string;
    transactionAffiliate?: TransactionAffiliate;
    transactionAffiliation?: string;
    insurance?: string;
    quantity: number;
    price: number;
    sku: string;
    odds?: string;
    bet_details?: string;
    variant?: string;
    bet_calendar?: string;
    transactionId?: string;
    [trackingConstants.COMPONENT_CATEGORY_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_LABEL_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_POSITION_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_EVENT_DETAILS]: string | undefined;
    [trackingConstants.COMPONENT_URL_CLICKED]: string | undefined;
    [trackingConstants.COMPONENT_ACTION_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_LOCATION_EVENT]: string | undefined;
    [trackingConstants.QUICKBET]: string | undefined;
    [trackingConstants.COMPONENT_CONTENT_POSITION]: string | number | undefined;
    [trackingConstants.BET_TYPE]: string | undefined;
    [trackingConstants.RESULT_ID]: string | undefined;
    [trackingConstants.SPORT_ID]: any;
    [trackingConstants.MARKET_ID]: string | undefined;
    [trackingConstants.SPORT_LEAGUE_ID]: string | undefined;
    [trackingConstants.PRODUCT_PICK_DETAILS]: string | undefined;
    [trackingConstants.COMPONENT_BET_SOURCE]: string | undefined;
    [trackingConstants.COMPONENT_MODULE_TYPE]: string | undefined;
    [trackingConstants.GAME_DETAILS]: string | undefined;
}
export interface TrackEntainUiBetBuilderPickRemoval {
    [trackingConstants.COMPONENT_CATEGORY_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_LABEL_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_ACTION_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_POSITION_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_LOCATION_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_EVENT_DETAILS]: string | undefined;
    [trackingConstants.COMPONENT_URL_CLICKED]: string | undefined;
}

export interface TrackBetBuilderSwitcherClick {
    [trackingConstants.COMPONENT_CATEGORY_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_LABEL_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_ACTION_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_POSITION_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_LOCATION_EVENT]: string | undefined;
    [trackingConstants.COMPONENT_EVENT_DETAILS]: string | undefined;
    [trackingConstants.COMPONENT_URL_CLICKED]: string | undefined;
    [trackingConstants.SPORT_ID]: string | undefined;
    [trackingConstants.SPORT_LEAGUE_ID]: string | undefined;
}

export interface TrackVideoStreaming {
    [trackingConstants.COMPONENT_CATEGORY_EVENT]: string;
    [trackingConstants.COMPONENT_LABEL_EVENT]: string;
    [trackingConstants.COMPONENT_POSITION_EVENT]: string;
    [trackingConstants.COMPONENT_EVENT_DETAILS]: string;
    [trackingConstants.COMPONENT_URL_CLICKED]: string;
    [trackingConstants.COMPONENT_ACTION_EVENT]: string;
    [trackingConstants.COMPONENT_LOCATION_EVENT]: string;
    [trackingConstants.VIDEO_PROVIDER_EVENT_ID]: string;
    [trackingConstants.VIDEO_INTERNAL_EVENT_ID]: string;
}
export interface TrackBetFinder {
    [trackingConstants.COMPONENT_CATEGORY_EVENT]: string;
    [trackingConstants.COMPONENT_LABEL_EVENT]: string;
    [trackingConstants.COMPONENT_ACTION_EVENT]: string;
    [trackingConstants.COMPONENT_POSITION_EVENT]: string;
    [trackingConstants.COMPONENT_LOCATION_EVENT]: string;
    [trackingConstants.COMPONENT_EVENT_DETAILS]: string;
    [trackingConstants.COMPONENT_URL_CLICKED]: string | undefined;
    [trackingConstants.SPORT_EVENT_ID]?: string | undefined;
    [trackingConstants.SPORT_LEAGUE_ID]?: string | undefined;
}

export interface TrackPriceBoost {
    [trackingConstants.COMPONENT_CATEGORY_EVENT]: string;
    [trackingConstants.COMPONENT_LABEL_EVENT]: string;
    [trackingConstants.COMPONENT_ACTION_EVENT]: string;
    [trackingConstants.COMPONENT_POSITION_EVENT]: string;
    [trackingConstants.COMPONENT_LOCATION_EVENT]: string;
    [trackingConstants.COMPONENT_EVENT_DETAILS]: string;
    [trackingConstants.COMPONENT_URL_CLICKED]: string;
    [trackingConstants.SPORT_ID]: string;
}
export interface TrackUpdate {
    [trackingConstants.PAGE_REFERRING_ACTION]: string;
    [trackingConstants.COMPONENT_CATEGORY_EVENT]?: string | undefined;
    [trackingConstants.PAGE_DISPLAYED_ELEMENT]?: string | undefined;
}

export interface TrackError {
    message: string;
    code?: string;
}

export interface TrackCrossSaleData {
    [trackingConstants.COMPONENT_CATEGORY_EVENT]: string;
    [trackingConstants.COMPONENT_LABEL_EVENT]: string;
    [trackingConstants.COMPONENT_ACTION_EVENT]: string;
    [trackingConstants.COMPONENT_POSITION_EVENT]: string;
    [trackingConstants.COMPONENT_LOCATION_EVENT]: string;
    [trackingConstants.COMPONENT_EVENT_DETAILS]: string;
    [trackingConstants.COMPONENT_URL_CLICKED]: string;
}

export interface TrackRetailAddToBalance {
    transactionId: string;
    transactionTotal: number;
    transactionProducts: TrackRetailBetslipPick[];
    ticketID: string;
    numberOfBets: number;
    currencyCode: string;
    [trackingConstants.COMPONENT_SLIP_ID]: string;
    numberOfCombinations: number;
    betTicketStatus: string;
}

export interface TrackRetailBetslipPick {
    category: string;
    transactionAffiliate: TransactionAffiliate;
    quantity: number;
    odds?: string;
    sportEventID: string;
    betStatus: string;
}

export interface TrackOveraskData {
    sku?: string[];
    [trackingConstants.COMPONENT_ACTION_EVENT]: string;
    [trackingConstants.COMPONENT_LABEL_EVENT]: string;
    [trackingConstants.COMPONENT_CATEGORY_EVENT]: string;
    [trackingConstants.COMPONENT_LOCATION_EVENT]: string;
    [trackingConstants.COMPONENT_POSITION_EVENT]: string;
    [trackingConstants.COMPONENT_EVENT_DETAILS]: string;
    [trackingConstants.COMPONENT_URL_CLICKED]: string;
}

export interface ComponentGameTrackingData {
    [trackingConstants.COMPONENT_GAME_NAME]: string;
    [trackingConstants.COMPONENT_GAME_POSITION]: string | number;
    [trackingConstants.COMPONENT_GAME_SECTION]: string;
    [trackingConstants.COMPONENT_GAME_DISPLAY_NAME]: string;
    [trackingConstants.COMPONENT_GAME_TYPE]: string;
    [trackingConstants.COMPONENT_CONTENT_POSITION]: string;
}

export type TrackData =
    | TrackGeneral
    | TrackPlaceBet
    | TrackAddRemoveBet
    | TrackVideoStreaming
    | TrackCrossSaleData
    | TrackBetFinder
    | TrackOveraskData
    | TrackRetailAddToBalance
    | NextRaces
    | ComponentGameTrackingData
    | OddsPreferenceTracking
    | { [trackingConstants.SHOW_MODULE]: any }
    | { [trackingConstants.COMPONENT_SPORT_ID]: any }
    | { [trackingConstants.MARKET_ID]: string }
    | { [trackingConstants.SPORT_ID]: number | string }
    | { [trackingConstants.SPORT_LEAGUE_ID]: number | string }
    | { [trackingConstants.COMPONENT_USER_SEGMENTS]: string | undefined }
    | { [trackingConstants.COMPONENT_SITECORE_ID]: string | undefined }
    | { [trackingConstants.COMPONENT_MARKET_ID]: string | number | undefined }
    | { [trackingConstants.PAGE_SITE_SECTION]: string | undefined }
    | { [trackingConstants.COMPONENT_AUTO_EARLYPAYOUT]: string | undefined }
    | { [trackingConstants.COMPONENT_SITE_ELEMENT_INTERCATIONS]: string | undefined }
    | { [trackingConstants.GEOLOCATION_FETCH_TYPE]: string | undefined }
    | { [trackingConstants.COMPONENT_MODULE_AFFILIATION_CONFIG]: string | undefined }
    | { [trackingConstants.GEOLOCATION_ERRORCODE]: string | number | undefined }
    | { [trackingConstants.GEOLOCATION_ERRORMESSAGE]: string | number | undefined }
    | { [trackingConstants.REGION_ID]: string | number | undefined }
    | { [trackingConstants.SHOW_MARQUEE]: any }
    | { [trackingConstants.BETS_BOOSTS]: any }
    | { [trackingConstants.SHOW_NEXTRACES]: NextRaces[] }
    | { [trackingConstants.PAGE_NAVIGATION_MENUS]: string };

export interface ITrackAction {
    name: string;
    type: TrackingType;
    data?: any;
}

export interface IDialogTracking {
    name: string;
    type: TrackingType;
    data?: any;
}

export enum TransactionAffiliate {
    Livebook = 'Livebook',
    Mainbook = 'Mainbook',
    MainAndLive = 'Livebook/Mainbook',
}

export enum TrackingType {
    PageName,
    Referring,
    EventClick,
}

export enum TrackNavArea {
    NavTop = 'TopNav',
    NavBottom = 'BottomNav',
    NavSub = 'SubNav',
    NavLive = 'LiveNav',
    NavVirtual = 'VirtualNav',
    NavBreadcrumb = 'Breadcrumb',
}

export enum ExternalClient {
    Wrapper = 'sportsw',
    PokerNativeApp = 'poker',
    PokerDesktopClient = 'pokervc',
    PokerMac = 'pokermc',
}

export interface TrackShowcase {
    [trackingConstants.COMPONENT_CATEGORY_EVENT]: string;
    [trackingConstants.COMPONENT_LABEL_EVENT]: string;
    [trackingConstants.COMPONENT_POSITION_EVENT]: string;
    [trackingConstants.COMPONENT_EVENT_DETAILS]: string;
    [trackingConstants.COMPONENT_URL_CLICKED]: string;
    [trackingConstants.COMPONENT_ACTION_EVENT]: string;
    [trackingConstants.COMPONENT_LOCATION_EVENT]: string;
    [trackingConstants.SPORT_EVENT_ID]: string;
    [trackingConstants.SPORT_ID]: string;
}
export interface TrackExternalClient {
    [trackingConstants.INVOKE_PRODUCT]: string;
    [trackingConstants.INVOKE_INTERFACE]: string;
}

export enum CrossSaleTracking {
    CrossSellCategoryEvent = 'X - Sell',
    CrossSellClick = 'Cross Sell - Click',
    SeeAllClick = 'See All - Click',

    Virtuals = 'Virtuals',
    VirtualGames = 'Virtual Games',
    Casino = 'Casino',

    Vyml = 'Virtuals You Might Like',
    LinkBanner = 'Link Banner',
    Gyml = 'Games You Might Like',
    JackpotBanner = 'Jackpot Banner',

    TopPage = 'Top Page',
    MidPage = 'Mid Page',
    BottomPage = 'Bottom Page',

    Fralis = 'Fralis',

    GameSectionGyml = 'sports/GamesYouMightLike',
    GameSectionLinkBanner = 'sports/LinkBanner',
    GameSectionJackpotBanner = 'sports/JackpotBanner',
    GameSectionVyml = 'sports/VirtualsYouMightLike',
    GameSectionOnDemand = 'sports/OnDemand',
}

export enum MultiBuilderTracking {
    MultiBuilder = 'Multi Builder',
    MultiBuilderNav = 'MultiBuilderNav_',
    BetSlipErrors = 'Uncombinable Selections Error',
}

export enum BetFinderTracking {
    Search = 'search',
    SportSearch = 'sports search',
    NotApplicable = 'not applicable',
    ClickResult = 'click result',
    SearchSuggested = 'search suggested item',
    SearchResultList = 'search result list',
    Competition = 'Competition',
    SportEvent = 'Sport Event',
}

export enum PriceBoostTracking {
    Sports = 'sports',
    TabName = 'price boost',
    Click = 'click',
    NotApplicable = 'not applicable',
    SportCompetition = 'Competitions page',
    BetStationCompetition = 'BetStation Competitions page',
}

export enum TrackingLocation {
    EditBet = 'Edit Bet',
    QuickBet = 'Quick Bet',
    BetReceipt = 'Bet Receipt',
    MyBets = 'My Bets',
    Betslip = 'Betslip',
    OverAskPending = 'bet under review screen',
    OverAskPendingRejectPopup = 'cancel under review screen confirmation pop up',
    OverAskReviewRejectPopup = 'cancel offer confirmation',
}

export interface TrackingSource {
    source: string;
    position?: number;
    gameDetails?: string;
    moduleType?: string;
    sourceOfPick?: SourceOfPick;
    isFallbackMarketEnabled?: boolean;
    isAutomatedMarquee?: boolean;
    marqueeName?: string;
    sitecoreTemplateId?: string;
    marqueeType?: string;
    calendarTitle?: string;
    controllerTabName?: string;
    playersName?: string;
    betDetails?: string;
    variant?: string;
}
export enum RewardValidationTracking {
    TotalOdds = 'bet does not meet the criteria: total odds',
    SelectionLevelMinimumOdds = 'bet does not meet the criteria: min. odds',
    MinimumLegs = 'bet does not meet the criteria: min. legs',
    MinimumStake = 'bet does not meet the criteria: min. stake',
    MaximumStake = 'bet does not meet the criteria: max. stake',
    SlipType = 'bet does not meet the criteria: {{criteria}}',
}
export enum CalendarFilterTracking {
    Live = 'Live',
    Today = 'Today',
    Tomorrow = 'Tomorrow',
    In30minutes = 'In 30 Min',
    In3Hours = 'In 3 Hrs',
    In60Minutes = 'In 60 Min',
    In2Days = 'In 2 Days',
    In3Days = 'In 3 Days',
}

export enum TopEventsTracking {
    BetBuilder = 'BetBuilder',
    Grid = 'Grid',
    Outright = 'Outright',
    Single = 'Single',
    PropsMultiPod = 'PropsMultiPod',
    HandpickedOptionsPod = 'HandpickedOptionsPod',
}

export interface ChevronClickTrackingData {
    eventDetails: string;
    urlClicked?: string;
}

export class OddsPreferenceTracking {
    // SpeedTooltip: show
    static SpeedTooltipAndShow = { [trackingConstants.PAGE_NAME]: 'M2_slip_action_SpeedTooltip_show' };
    // SpeedTooltip: click
    static SpeedTooltipAndClick = { [trackingConstants.PAGE_NAME]: 'M2_slip_action_SpeedTooltip_click' };
    // SpeedTooltip: Track when user 'Accept any changes' button in modal popup
    static SpeedTooltipAndAnyOdds = { [trackingConstants.PAGE_NAME]: 'M2_slip_action_SpeedTooltip/AOdds' };
}
