// eslint-disable-next-line no-restricted-imports
import { LogType, RemoteLogger } from '@frontend/vanilla/core';

export class SportsRemoteLogger {
    private loggerName: string | undefined;
    private readonly MS2_LOGGER_TOKEN = 'ms2logger';

    constructor(
        loggerName: string,
        private vanillaLogger: RemoteLogger,
    ) {
        const alphaNumeric = /^[a-zA-Z0-9]+$/g;

        if (!loggerName || !alphaNumeric.test(loggerName)) {
            throw new Error(`Logger name should be alphanumeric for easy elasticsearch lookup`);
        }

        this.loggerName = loggerName;
    }

    error(message: string): void;
    error(errorLikeObj: any, additionalMessage?: string): void;
    error(error: any, additionalMessage?: string): void {
        if (!error) {
            return;
        }

        if (typeof error === 'string') {
            error = new Error(error);
        }

        error.description = this.MS2_LOGGER_TOKEN;
        error.fileName = this.loggerName;

        this.vanillaLogger.logError(error, additionalMessage);
    }

    info(message: string): void {
        if (!message) {
            return;
        }

        const formattedMessage = [this.MS2_LOGGER_TOKEN, this.loggerName, message].filter((e) => e).join(' ');

        this.vanillaLogger.log(formattedMessage, LogType.Info);
    }

    warning(message: string): void {
        if (!message) {
            return;
        }

        const formattedMessage = [this.MS2_LOGGER_TOKEN, this.loggerName, message].filter((e) => e).join(' ');

        this.vanillaLogger.log(formattedMessage, LogType.Warning);
    }
}
