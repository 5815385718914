import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    TemplateRef,
    ViewEncapsulation,
    booleanAttribute,
    numberAttribute,
} from '@angular/core';

export const DS_BUTTON_KIND_ARRAY = ['primary', 'secondary', 'tertiary', 'success', 'utility'] as const;
export type DsButtonKind = (typeof DS_BUTTON_KIND_ARRAY)[number];

export const DS_BUTTON_VARIANTS_ARRAY = ['filled', 'outline', 'flat'] as const;
export type DsButtonVariant = (typeof DS_BUTTON_VARIANTS_ARRAY)[number];

export const DS_BUTTON_SIZES_ARRAY = ['small', 'medium', 'large'] as const;
export type DsButtonSize = (typeof DS_BUTTON_SIZES_ARRAY)[number];

@Component({
    selector: 'button[ds-button], a[ds-button]',
    standalone: true,
    exportAs: 'ds-button',
    imports: [CommonModule],
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss',
    host: {
        'class': 'ds-button',
        '[class]': 'hostClass',
        '[class.ds-btn-disabled]': 'disabled ? true : null',
        '[attr.disabled]': 'disabled ? true : null',
        '[attr.aria-disabled]': 'disabled ? true : null',
        '[attr.tabindex]': 'disabled ? -1 : tabIndex',
        '[class.ds-button-loading]': 'loading ? true : null',
        '[class.ds-inverse]': 'inverse',
        '[class.ds-button-truncate]': 'truncate',
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DsButton {
    @Input() size: DsButtonSize = 'large';

    @Input() variant: DsButtonVariant = 'filled';

    @Input() kind: DsButtonKind = 'primary';

    @Input({ transform: booleanAttribute }) inverse = false;

    @Input({ transform: booleanAttribute }) loading = false;

    @Input({ transform: booleanAttribute }) disabled = false;

    @Input({ transform: numberAttribute }) tabIndex?: number;

    @Input({ transform: booleanAttribute }) truncate = false;

    @Input({ transform: booleanAttribute }) wrapText = false;

    @ContentChild('loadingTemplate') loadingTemplate?: TemplateRef<any>;

    get hostClass() {
        return `ds-btn-${this.variant}-${this.kind} ds-btn-${this.size}`;
    }
}

export const DS_BUTTON_NOT_SUPPORTED_CONFIG: { [key: string]: DsButtonKind[] } = {
    flat: ['secondary', 'primary', 'success'],
    outline: ['success', 'utility'],
    filled: ['utility'],
};
