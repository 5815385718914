import { Injectable } from '@angular/core';

import { isDefined } from '@frontend/sports/common/base-utils';
import { MyBetsConfig } from '@frontend/sports/common/client-config-data-access';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { OpenBetsSummary } from '@frontend/sports/types/models/my-bets';
import { sum } from 'lodash-es';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { EventModel } from '../event-model/model/event.model';
import { UserService } from '../user/services/user.service';
import { SummaryApiService } from './summary-api.service';

@Injectable({
    providedIn: 'root',
})
export class MyBetsSummaryService {
    private readonly defaultSummary: OpenBetsSummary = {
        openEvents: {},
        liveBetsCount: 0,
        openBetsCount: 0,
        betNumbers: [],
        isMigrationSummary: false,
        hasError: false,
    };

    private readonly EARLY_PAYOUT_SUCCESS = 'EARLY_PAYOUT_SUCCESS';
    private readonly BET_PLACE_SUCCESS = 'BET_PLACE_SUCCESS';
    private readonly CASH_OUT_ALREADY_EXECUTED = 'CASH_OUT_ALREADY_EXECUTED';

    private _summaryUpdate = new BehaviorSubject<Readonly<OpenBetsSummary> | undefined>(undefined);
    readonly summaryUpdate = this._summaryUpdate.pipe(filter(isDefined));

    private stopped$: Subject<void>;

    constructor(
        private loader: SummaryApiService,
        private dispatcherService: DispatcherService,
        private userService: UserService,
        private mybetsConfig: MyBetsConfig,
    ) {
        this.stopped$ = new Subject<void>();

        if (this.userService.isAuthenticated) {
            this.start();
        }

        this.userService.onUserLogin$.subscribe(() => this.start());
        this.userService.onUserLogout$.subscribe(() => this.stop());
    }

    get summary(): Readonly<OpenBetsSummary> {
        return this._summaryUpdate.value || this.defaultSummary;
    }

    update(summary: OpenBetsSummary): void {
        this._summaryUpdate.next(summary);
    }

    getOpenBetsCount(evt: EventModel): number {
        const openEvents = this.summary.openEvents;
        const eventIds = [evt.id, ...(evt.splitFixtureIds || [])];
        const eventCounts = eventIds.filter((eid) => openEvents[eid]).map((eid) => openEvents[eid].openBetsCount);

        return sum(eventCounts);
    }

    private start(): void {
        this.stop();
        this.subscribeBetPlacementActions();
        if (!this.mybetsConfig.isOpenSummaryLoadingRestricted) {
            timer(0, this.mybetsConfig.myBetsRefreshInMilliseconds || 120000)
                .pipe(takeUntil(this.stopped$))
                .subscribe(() => this.refresh());
        }
    }

    async refresh(aggresive: boolean = false): Promise<void> {
        if (aggresive || !this._summaryUpdate.value || this.summary.openBetsCount > 0) {
            let summary: OpenBetsSummary;
            try {
                summary = await this.loader.getOpenBetsCount();
            } catch {
                summary = { ...this.defaultSummary };
            }

            this.update(summary);
        }
    }

    private stop(): void {
        this.stopped$.next();
    }

    private subscribeBetPlacementActions(): void {
        this.dispatcherService.on(this.EARLY_PAYOUT_SUCCESS).pipe(takeUntil(this.stopped$)).subscribe(this.onBetPlacementAction);

        this.dispatcherService.on(this.BET_PLACE_SUCCESS).pipe(takeUntil(this.stopped$)).subscribe(this.onBetPlacementAction);

        this.dispatcherService.on(this.CASH_OUT_ALREADY_EXECUTED).pipe(takeUntil(this.stopped$)).subscribe(this.onBetPlacementAction);
    }

    private onBetPlacementAction = () => {
        this.refresh(true);
    };
}
