import { Injectable } from '@angular/core';

import { Logger } from '../logging/logger';

@Injectable({
    providedIn: 'root',
})
export class AnchorTrackingHelperService {
    constructor(private log: Logger) {}

    createTrackingData(anchorElement: HTMLAnchorElement): { [prop: string]: string } {
        const keyString = anchorElement.getAttribute('data-tracking-keys');
        const valueString = anchorElement.getAttribute('data-tracking-values');
        try {
            const trackingLinkParameters: { [prop: string]: string } = {};
            if (anchorElement instanceof HTMLAnchorElement) {
                const trackingEventName = anchorElement.getAttribute('data-tracking-event');
                if (trackingEventName) {
                    if (keyString && valueString) {
                        const keys = JSON.parse(keyString);
                        const values = JSON.parse(valueString);

                        if (!Array.isArray(keys)) {
                            throw new Error('data-tracking-keys did not deserialize to an array');
                        }

                        if (!Array.isArray(values)) {
                            throw new Error('data-tracking-values did not deserialize to an array');
                        }

                        for (let i = 0; i < keys.length; i++) {
                            if (values[i]) {
                                trackingLinkParameters[keys[i]] = values[i];
                            }
                        }
                    }
                }
            }
            return trackingLinkParameters;
        } catch (err) {
            this.log.warn(
                `Failed deserializing tracking data for plain link. Most likely because of invalid JSON. Keys=${keyString} Values=${valueString}`,
                err,
            );
            return {};
        }
    }

    getTrackingEventName(anchorElement: HTMLAnchorElement): string | null {
        if (anchorElement instanceof HTMLAnchorElement) {
            return anchorElement.getAttribute('data-tracking-event');
        } else return null;
    }
}
