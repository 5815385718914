import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { CountItem, RouteTag, SportConstant, isAntepost } from '@frontend/sports/common/base-utils';
import {
    CalendarConfig,
    LiveNavigationConfig,
    PrettyUrlsConfig,
    Sitecore,
    TeamPagesConfig,
    TimeFilterConfig,
    WorldCupHubConfig,
} from '@frontend/sports/common/client-config-data-access';
import { Observable, Subject, firstValueFrom, of } from 'rxjs';

import { CalendarService } from '../../calendar/calendar.service';
import { SportsCacheService } from '../../client-caching/sports-cache.service';
import { CompetitionRouteService } from '../../competition-list/competition-route.service';
import { AdaptiveLayoutState } from '../../layout/adaptive-layout.service';
import { SportUrlParam, UrlHelperService } from '../../navigation-core/url-helper.service';
import { SubNavigationItem } from '../../sub-navigation/sub-navigation.models';
import { WorldCupHubService } from '../../world-cup-hub/world-cup-hub.service';

export enum SubNavigation {
    Featured = 1,
    Competitions,
    Coupons,
    Calendar,
    Live,
    TeamPages,
    EachWayMarkets,
    WorldCupHub,
}

@Injectable({ providedIn: 'root' })
export class SportSubNavigationService implements OnDestroy {
    private layout?: AdaptiveLayoutState;
    private routeSnapshot: ActivatedRouteSnapshot;
    private sport = -1;
    private tabs$ = new Subject<SubNavigationItem[]>();

    constructor(
        private urlHelper: UrlHelperService,
        private urlConfig: PrettyUrlsConfig,
        private sitecore: Sitecore,
        private service: SportsCacheService,
        private router: CompetitionRouteService,
        private calendar: CalendarService,
        private teamPagesConfig: TeamPagesConfig,
        private timeFilterConfig: TimeFilterConfig,
        private calendarConfig: CalendarConfig,
        private liveNavConfig: LiveNavigationConfig,
        private worldCupHubService: WorldCupHubService,
        private worldCupHubConfig: WorldCupHubConfig,
    ) {}

    resolveTabs(layout: AdaptiveLayoutState, routeSnapshot: ActivatedRouteSnapshot, sport: number): Observable<SubNavigationItem[]> {
        this.layout = layout;
        this.sport = sport;
        this.routeSnapshot = routeSnapshot;

        if (this.removeSubNavigation(sport)) {
            return of([]);
        }

        this.getCountsCached();

        return this.tabs$.asObservable();
    }

    ngOnDestroy(): void {
        this.tabs$.complete();
    }

    private removeSubNavigation(sport: number): boolean {
        const route = this.router.params();

        return (
            !sport ||
            (this.layout &&
                this.layout.flags.shouldHideSubNavigation &&
                this.calendar.isInterval(route.context) &&
                !this.timeFilterConfig.isTimeFilterEnabled) ||
            (!this.routeSnapshot.data.model && this.routeSnapshot.data.tag !== RouteTag.WorldCupHub) ||
            (this.routeSnapshot.data.tag === RouteTag.Featured &&
                (!this.routeSnapshot.data.model.fixtures.length || !this.routeSnapshot.data.model.sport))
        );
    }

    private async getCountsCached(): Promise<void> {
        // children check i needed for golf, when no v1 offer it should not add the sub nav
        const result = await firstValueFrom(this.service.getSport(this.sport));

        if (!result || isAntepost(result)) {
            return;
        }
        const tabs = this.getTabs(result);
        this.setTabs(tabs);
    }

    private setActive(tabs: SubNavigationItem[]): SubNavigationItem[] {
        const tag = this.getActive();

        return tabs.map((tab) => ({ ...tab, active: tab.id === tag }));
    }

    private getActive(): SubNavigation {
        const route = this.router.params();
        if (
            route.context === this.urlConfig.translations.betting ||
            route.context === this.urlConfig.translations.tournaments ||
            this.routeSnapshot.data.tag === RouteTag.EventDetails
        ) {
            return SubNavigation.Competitions;
        }

        if (route.context === this.urlConfig.translations.coupons) {
            return SubNavigation.Coupons;
        }

        if (route.context === this.urlConfig.translations.calendar || this.calendar.isInterval(route.context)) {
            return SubNavigation.Calendar;
        }

        if (route.context === this.urlConfig.translations.teamPages) {
            return SubNavigation.TeamPages;
        }

        if (route.context === this.urlConfig.translations.worldCupHub) {
            return SubNavigation.WorldCupHub;
        }

        return SubNavigation.Featured;
    }

    private getDefault(sport: CountItem): SubNavigationItem[] {
        return this.setActive([
            {
                active: false,
                id: SubNavigation.Featured,
                title: this.sitecore.globalMessages.Featured,
                url: this.urlHelper.getSportUrl(sport, SportUrlParam.Featured),
                ignoreBrowserHistory: true,
            },
            {
                active: false,
                id: SubNavigation.Competitions,
                title: this.sitecore.globalMessages.Competitions,
                url: this.urlHelper.getSportUrl(sport, SportUrlParam.Betting, SportUrlParam.Competitions),
                ignoreBrowserHistory: true,
            },
        ]);
    }

    private getTabs(sport: CountItem): SubNavigationItem[] {
        const tag = this.getActive();
        const tabs = this.getDefault(sport);
        if (sport) {
            if (sport.id === SportConstant.Soccer && this.worldCupHubService.isEnabled) {
                tabs.push({
                    id: SubNavigation.WorldCupHub,
                    title: this.sitecore.globalMessages.WorldCupHub,
                    url: this.urlHelper.getSportUrl(sport, SportUrlParam.WorldCupHub),
                    ignoreBrowserHistory: true,
                    badges: this.worldCupHubConfig.isNewBadgeEnabled
                        ? [{ class: 'badge badge-primary', title: this.sitecore.globalMessages.NewBadgeText, icon: '' }]
                        : [],
                    classes: 'badge-direction',
                });
            }

            if (sport.meta.coupons || tag === SubNavigation.Coupons) {
                tabs.push({
                    id: SubNavigation.Coupons,
                    title: this.sitecore.globalMessages.Coupons,
                    url: this.urlHelper.getCouponUrl(sport),
                    ignoreBrowserHistory: true,
                });
            }

            if ((sport.meta.calendar || tag === SubNavigation.Calendar) && this.calendarConfig.showCalendarTab) {
                tabs.push({
                    id: SubNavigation.Calendar,
                    title: this.sitecore.globalMessages.Calendar,
                    url: this.getCalendarUrl(sport, tag),
                    ignoreBrowserHistory: this.layout && this.layout.bottomNavigation,
                });
            }
            if (sport.meta.teamPages || tag === SubNavigation.TeamPages) {
                tabs.push({
                    id: SubNavigation.TeamPages,
                    title: this.sitecore.globalMessages.Teams,
                    url: this.urlHelper.getSportUrl(sport, SportUrlParam.TeamPages),
                    ignoreBrowserHistory: true,
                    badges: this.teamPagesConfig.isNewBadgeEnabled
                        ? [{ class: 'badge badge-primary', title: this.sitecore.globalMessages.NewBadgeText, icon: '' }]
                        : [],
                    classes: 'badge-direction',
                });
            }

            if (sport.id === SportConstant.Golf && this.liveNavConfig.isEachWayMarketsTabEnabled) {
                tabs.push({
                    id: SubNavigation.EachWayMarkets,
                    title: this.sitecore.globalMessages.EachWay,
                    url: this.urlHelper.getSportUrl(sport, SportUrlParam.Antepost),
                    ignoreBrowserHistory: true,
                });
            }

            if (sport.counts.live && !(this.layout && this.layout.headerSubNavigation)) {
                tabs.push({
                    count: sport.counts.live,
                    classes: 'tab-live',
                    icon: 'theme-right',
                    id: SubNavigation.Live,
                    title: this.sitecore.event.Live,
                    url: this.urlHelper.getLiveSportUrl({ sport, isEsport: sport.isEsport }),
                    ignoreBrowserHistory: true,
                });
            }
        }

        return this.setActive(tabs);
    }

    private setTabs(tabs: SubNavigationItem[]): void {
        this.tabs$.next(tabs);
    }

    private getCalendarUrl(sport: CountItem, tag: SubNavigation): string {
        if (this.timeFilterConfig.isTimeFilterEnabled) {
            return this.getSelectedInterval(sport);
        } else {
            return this.urlHelper.getSportUrl(sport, SportUrlParam.Calendar);
        }
    }

    private getSelectedInterval(sport: CountItem): string {
        const context = sport.meta.calendarIntervals?.length ? sport.meta.calendarIntervals[0] : SportUrlParam.Today;

        return this.urlHelper.getSportUrl(sport, context);
    }
}
