import { BetslipType } from '../../../core/betslip-type';
import { PickId, pickIdFactory } from '../../../core/picks/pick-id';
import { IBetBuilderPickState } from '../../bet-builder/models';
import { IComboBetState } from '../../combo-bet/state';
import { ISingleBetPickState } from '../../single-bet/state';
import { ITeaserBetState } from '../../teaser-bet/state';
import { IBetslipTypeState } from '../../types/state';
import { AccaBoostToken, IRewardToken, RewardTokenContext, SelectedRewardTokenState } from '../reward-tokens.model';
import { IRewardTokenEligibility, IRewardTokenEligibilityState, TokensRecord } from '../state';
import { byAccaPriority, isAccaBoostToken } from './reward-tokens.utils';

// Todo - LinearTokens: Split this and regular utils into elgibility-utils and select-utils

export type RewardTokenAwareState = IComboBetState | ISingleBetPickState | ITeaserBetState | IBetBuilderPickState;
export type TokenAwarePicksState = Record<string, ISingleBetPickState | IBetBuilderPickState>;

export function getSelectedToken(tokens: TokensRecord, type: RewardTokenAwareState): IRewardToken | null {
    if (!type?.rewardTokenId) {
        return null;
    }

    return tokens[type.rewardTokenId] ?? null;
}

export function getSelectedTokenForContext(context: RewardTokenContext, tokens: TokensRecord, types: IBetslipTypeState): IRewardToken | null {
    switch (context.betslipType) {
        case BetslipType.Single:
            return getSelectedTokenForPick(context.pickId, tokens, types.singleBet.picks);
        case BetslipType.Combo:
            return getSelectedToken(tokens, types.comboBet);
        case BetslipType.Teaser:
            return getSelectedToken(tokens, types.teaserBet);
        case BetslipType.BetBuilder:
            return getSelectedTokenForPick(context.pickId, tokens, types.betBuilder.picks);
        default:
            return null;
    }
}

export function getSelectedTokenAndEligibilityForContext(
    context: RewardTokenContext,
    eligibilityState: IRewardTokenEligibilityState,
    tokens: TokensRecord,
    types: IBetslipTypeState,
): [IRewardToken | null, IRewardTokenEligibility | null] {
    switch (context.betslipType) {
        case BetslipType.Single: {
            return getSelectedTokenAndEligibilityForPick(context.pickId, tokens, types.singleBet.picks, eligibilityState.singleBet);
        }
        case BetslipType.Combo: {
            return getSelectedTokenAndEligibility(tokens, types.comboBet, eligibilityState.comboBet);
        }
        case BetslipType.Teaser: {
            return getSelectedTokenAndEligibility(tokens, types.teaserBet, eligibilityState.teaserBet);
        }
        case BetslipType.BetBuilder: {
            return getSelectedTokenAndEligibilityForPick(context.pickId, tokens, types.betBuilder.picks, eligibilityState.betBuilder);
        }
        default:
            return [null, null];
    }
}

export function getAllAssignedTokens(tokens: TokensRecord, types: IBetslipTypeState): IRewardToken[] {
    return getAllAssignedTokensWithFilter(tokens, types, false);
}

export function getAllSelectedTokens(tokens: TokensRecord, types: IBetslipTypeState): IRewardToken[] {
    return getAllAssignedTokensWithFilter(tokens, types, true);
}

export function getTokenSelectionContext(tokenId: string, types: IBetslipTypeState): RewardTokenContext | undefined {
    if (types.comboBet.rewardTokenId === tokenId) {
        return {
            betslipType: BetslipType.Combo,
        };
    }

    if (types.teaserBet.rewardTokenId === tokenId) {
        return {
            betslipType: BetslipType.Teaser,
        };
    }

    const selectedSinglePickId = getPickIdBySelectedToken(tokenId, types.singleBet.picks);

    if (selectedSinglePickId) {
        return {
            pickId: selectedSinglePickId,
            betslipType: BetslipType.Single,
        };
    }

    const selectedBetBuilderPickId = getPickIdBySelectedToken(tokenId, types.betBuilder.picks);

    if (selectedBetBuilderPickId) {
        return {
            pickId: selectedBetBuilderPickId,
            betslipType: BetslipType.BetBuilder,
        };
    }

    return undefined;
}

export function getSelectedTokenState(
    rewardTokenId: string,
    tokens: Record<string, IRewardToken>,
    eligibilityState: IRewardTokenEligibility[],
): SelectedRewardTokenState {
    const token = tokens[rewardTokenId] ?? null;
    const tokenEligibility = (eligibilityState ?? []).find((e) => rewardTokenId && e.tokenId === rewardTokenId) ?? null;

    return {
        tokenId: rewardTokenId,
        token,
        tokenEligibility,
    };
}

export function getEligibilityStateForContext(
    context: RewardTokenContext,
    eligibilityState: IRewardTokenEligibilityState,
): IRewardTokenEligibility[] {
    switch (context.betslipType) {
        case BetslipType.Single: {
            if (!context.pickId) {
                return [];
            }

            return eligibilityState.singleBet[context.pickId.toString()] ?? [];
        }
        case BetslipType.Combo:
            return eligibilityState.comboBet;
        case BetslipType.Teaser:
            return eligibilityState.teaserBet;
        case BetslipType.BetBuilder: {
            if (!context.pickId) {
                return [];
            }

            return eligibilityState.betBuilder[context.pickId.toString()] ?? [];
        }
        default:
            return [];
    }
}

export function getSelectedAndEligibleAccaBoost(
    eligibilityState: IRewardTokenEligibilityState,
    tokens: TokensRecord,
    types: IBetslipTypeState,
): AccaBoostToken | null {
    // Acca Boost token can only be applied to a combo bet
    const selectedToken = getSelectedTokenForContext({ betslipType: BetslipType.Combo }, tokens, types);

    if (!isAccaBoostToken(selectedToken)) {
        return null;
    }

    const isEligible = eligibilityState.comboBet.find((e) => e.tokenId === selectedToken.userTokenId)?.isEligible;

    return isEligible ? selectedToken : null;
}

export function getEligibleAccaBoost(eligibilityState: IRewardTokenEligibilityState, tokens: TokensRecord): AccaBoostToken | null {
    const allEligibleTokens = eligibilityState.comboBet.filter((e) => e.isEligible).map((e) => tokens[e.tokenId]);

    return allEligibleTokens.filter(isAccaBoostToken).sort(byAccaPriority)[0] ?? null;
}

export function isTokenRecoverable(tokenId: string, tokens: Record<string, IRewardToken>, eligibilityState: IRewardTokenEligibility[]): boolean {
    const token = tokens[tokenId];

    if (!token) {
        return false;
    }

    const eligibility = eligibilityState.find((e) => e.tokenId === tokenId);

    return !!eligibility && eligibility.isEligible;
}

// Helper functions to avoid code duplication

function getSelectedTokenForPick(pickId: PickId | undefined, tokens: TokensRecord, awareStates: TokenAwarePicksState): IRewardToken | null {
    if (!pickId) {
        return null;
    }
    const pickState = awareStates[pickId.toString()];

    return getSelectedToken(tokens, pickState);
}

function getSelectedTokenAndEligibilityForPick(
    pickId: PickId | undefined,
    tokens: TokensRecord,
    awareStates: TokenAwarePicksState,
    eligibilityStates: Record<string, IRewardTokenEligibility[]>,
): [IRewardToken | null, IRewardTokenEligibility | null] {
    if (!pickId) {
        return [null, null];
    }

    const pickState = awareStates[pickId.toString()];
    const eligibilities = eligibilityStates[pickId.toString()] ?? [];

    return getSelectedTokenAndEligibility(tokens, pickState, eligibilities);
}

function getSelectedTokenAndEligibility(
    tokens: TokensRecord,
    awareState: RewardTokenAwareState,
    eligibilities: IRewardTokenEligibility[],
): [IRewardToken | null, IRewardTokenEligibility | null] {
    const token = getSelectedToken(tokens, awareState);
    const tokenEligibility = token ? eligibilities.find((e) => e.tokenId === token.userTokenId) ?? null : null;

    return [token, tokenEligibility];
}

function getAllAssignedTokensWithFilter(tokens: TokensRecord, types: IBetslipTypeState, filterUnselectedPicks: boolean = false): IRewardToken[] {
    const singlePicks = Object.values(types.singleBet.picks);
    const betBuilderPicks = Object.values(types.betBuilder.picks);

    const picks = [...singlePicks, ...betBuilderPicks];

    const filteredPicks = filterUnselectedPicks ? picks.filter((pick) => pick.isSelected) : picks;

    const pickTokenIds = filteredPicks.map((pick) => pick.rewardTokenId);

    const tokenIds = [...pickTokenIds, types.comboBet.rewardTokenId, types.teaserBet.rewardTokenId].filter((id) => !!id) as string[];

    return tokenIds.filter((id) => tokens[id]).map((id) => tokens[id]);
}

function getPickIdBySelectedToken(selectedTokenId: string, awarePicksState: TokenAwarePicksState): PickId | undefined {
    const pick = Object.entries(awarePicksState).find(([, value]) => value.rewardTokenId === selectedTokenId);

    return pick ? pickIdFactory(pick[0]) : undefined;
}
