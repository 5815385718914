import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

import { Subscription } from 'rxjs';

import { ScrollContainerService } from '../common/scroll-container.service';
import { ColumnLayoutService } from '../layout/column-layout.service';

@Directive({
    selector: '[msScrolledToBottom]',
})
export class ScrolledToBottomDirective implements OnInit, OnDestroy {
    private readonly DEFAULT_SCROLL_TOLERANCE = 170; // number of px before reaching the footer

    @Input() scrollContext: 'self' | 'document' = 'self'; // self = when scroll current element, document when document scrolling
    @Input() toleranceValue: number = this.DEFAULT_SCROLL_TOLERANCE;
    @Input() toleranceType: 'exact' | 'relative' = 'exact';
    @Output() scrollAtBottom = new EventEmitter<void>();
    @Output() scrollAtMid = new EventEmitter<boolean>();

    private subscription: Subscription;
    private previousTop = 0;
    private scrollTolerance?: number;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private layout: ColumnLayoutService,
        private scrollContainer: ScrollContainerService,
    ) {}

    ngOnInit(): void {
        this.subscription = this.scrollContext === 'self' ? this.subscribeElement() : this.subscribeContainer();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private subscribeElement(): Subscription {
        const handler = (event: Event) => this.onScroll(event.target as HTMLElement | Window);
        const listener = this.renderer.listen(this.elementRef.nativeElement, 'scroll', handler);

        return new Subscription(listener);
    }

    private subscribeContainer(): Subscription {
        return this.scrollContainer.scroll.subscribe((container) => this.onScroll(container as HTMLElement | Window));
    }

    private getHeight(element: HTMLElement | Window): { clientHeight: number; scrollHeight: number; scrollTop: number } {
        let target: HTMLElement;
        let scrollTop: number;

        if ('pageYOffset' in element) {
            const window = element;
            target = window.document.documentElement;
            scrollTop = window.pageYOffset;
        } else {
            target = element;
            scrollTop = target.scrollTop;
        }

        return {
            clientHeight: target.clientHeight,
            scrollHeight: target.scrollHeight,
            scrollTop,
        };
    }

    private setTolerance(clientHeight: number): void {
        this.scrollTolerance = this.toleranceType === 'relative' ? this.toleranceValue * clientHeight : this.toleranceValue;
    }

    private onScroll = (element: HTMLElement | Window) => {
        const { clientHeight, scrollHeight, scrollTop } = this.getHeight(element);
        const offsetTop = this.previousTop < scrollTop;

        if (!this.scrollTolerance) {
            this.setTolerance(clientHeight);
        }

        if (offsetTop && scrollTop + clientHeight + this.layout.currentFooterSize + this.scrollTolerance! >= scrollHeight) {
            this.scrollAtBottom.emit();
        }

        const height = clientHeight / 2;
        const isMidLevel = scrollTop - height >= 0;
        this.scrollAtMid.emit(isMidLevel);
        this.previousTop = scrollTop;
    };
}
