import { Inject, Injectable } from '@angular/core';

import { FixturePage, FixtureType, OfferCategory } from '@cds/betting-offer';
import { CountsFixturesResponse, FixtureView, LobbyResponse, PicksView, SportsLobbyResponse } from '@cds/betting-offer/domain-specific';
import { LiveHighlightsResponse } from '@cds/betting-offer/domain-specific/live';
import { SuggestResponse } from '@cds/betting-offer/domain-specific/text-search';
import { VirtualFixtureView, VirtualSportView } from '@cds/betting-offer/domain-specific/virtual';
import { TagCount, TagType } from '@cds/betting-offer/tags';
import {
    BatchRequest,
    CountsFixturesRequest,
    FavouriteCountsQuery,
    FixtureCountsRequest,
    FixtureRequest,
    FixtureState,
    FixtureViewRequest,
    LiveHighlightsRequest,
    LobbyRequest,
    OfferMapping,
    ParticipantMapping,
    PicksRequest,
    ScoreboardMode,
    SortByCriteria,
    SportsLobbyRequest,
    TextQuery,
    Tv1PickData,
    Tv2PickData,
    VirtualOfferRequest,
} from '@cds/query-objects';
import { ExpiringCache, SportConstant, isDefined, isV2CompoundId } from '@frontend/sports/common/base-utils';
import {
    BaseTranslationConfig,
    BetFinderConfig,
    BettingOfferConfig,
    CdsConfig,
    MarketGroupingConfig,
    SportsConfig,
} from '@frontend/sports/common/client-config-data-access';
import { Page } from '@frontend/vanilla/core';
import { chunk, concat, merge } from 'lodash-es';
import { Observable, firstValueFrom, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { BaseCdsApi } from './cds-api.service';
import { BaseCdsApiFactory, CDS_API_FACTORY } from './cds-api.service.factory';
import { CdsRequestOptions } from './cds-request-options';

export interface BatchResponse {
    [key: string]: FixturePage;
}

@Injectable({ providedIn: 'root' })
export class BettingOfferApi {
    private cdsApi: BaseCdsApi;
    private pickCache: ExpiringCache;
    private readonly logger: SportsRemoteLogger;

    constructor(
        @Inject(CDS_API_FACTORY) cdsApiServiceFactory: BaseCdsApiFactory,
        private betFinderConfig: BetFinderConfig,
        private cdsConfig: CdsConfig,
        private sportConfig: SportsConfig,
        private baseTranslationConfig: BaseTranslationConfig,
        private page: Page,
        loggerFactory: LoggerFactory,
        private marketGroupingConfig: MarketGroupingConfig,
        private bettingOfferConfig: BettingOfferConfig,
    ) {
        this.cdsApi = cdsApiServiceFactory({ endpoint: '/bettingoffer' });
        this.pickCache = new ExpiringCache(0.5);
        this.logger = loggerFactory.getLogger('BettingOfferApi');
    }

    getFixtureView(
        id: string,
        statsMode: string,
        excludeMarkets: boolean,
        includePrecreated: boolean,
        isBettingInsightsEnabled: boolean,
        gridGroupIds?: string,
        useRegionalisedConfiguration: boolean = this.marketGroupingConfig.regionalisedGroupingEnabled,
        gameIds?: string,
        optionMarketIds?: string,
        includeRelatedFixtures: boolean = this.bettingOfferConfig.includeLinkedFixtures && !isV2CompoundId(id),
    ): Promise<FixtureView | undefined> {
        const offerMappingFilter = gridGroupIds || gameIds || optionMarketIds ? OfferMapping.Filtered : OfferMapping.All;
        const offerMapping = excludeMarkets ? OfferMapping.None : offerMappingFilter;

        return firstValueFrom(
            this.cdsApi.get<FixtureView, FixtureViewRequest>('/fixture-view', {
                offerMapping,
                gridGroupIds,
                gameIds,
                optionMarketIds,
                scoreboardMode: ScoreboardMode.Full,
                fixtureIds: id,
                state: FixtureState.Latest,
                includePrecreatedBetBuilder: includePrecreated,
                supportVirtual: this.sportConfig.virtuals.isEnabled,
                isBettingInsightsEnabled,
                useRegionalisedConfiguration,
                includeRelatedFixtures,
                statisticsModes: statsMode,
            }),
        );
    }

    getVirtualFixtureView(sport: number, competition: number, fixture: string): Observable<VirtualFixtureView | undefined> {
        return this.cdsApi.get<VirtualFixtureView, VirtualOfferRequest>('/virtual/fixture-view', {
            offerMapping: OfferMapping.All,
            sportIds: sport.toString(),
            competitionIds: competition.toString(),
            fixtureIds: fixture,
            scheduleSize: this.sportConfig.virtuals.upcomingEventCount,
        });
    }

    /**
     * Caches the request for 500ms so that requests coming with the same parameters will not make multiple CDS calls.
     * This happens because calls are coming from Quickbet/Betslip at the same time
     *
     * @param request
     * @returns
     * @memberof BettingOfferApi
     */
    getPicks(request: PicksRequest, options?: CdsRequestOptions): Observable<FixturePage | undefined> {
        const requestKey = this.buildPicksRequestKey('picks', request);
        if (this.useBaseTranslation()) {
            request.alternateLanguage = this.baseTranslationConfig.defaultLanguage;
        }

        const source = this.cdsApi.post<PicksView>(`/picks`, request, options).pipe(map((response) => response && response.fixturePage));

        return this.pickCache.getOrCreate(requestKey, source);
    }

    getReBetPicksView(request: PicksRequest, options?: CdsRequestOptions): Observable<PicksView | undefined> {
        if (this.useBaseTranslation()) {
            request.alternateLanguage = this.baseTranslationConfig.defaultLanguage;
        }

        return this.cdsApi.post<PicksView>(`/picks/re-bet`, request, options);
    }

    /**
     * Caches the request for 500ms so that requests coming with the same parameters will not make multiple CDS calls.
     * This happens because calls are coming from Quickbet/Betslip at the same time
     *
     * @param request
     * @returns
     * @memberof BettingOfferApi
     */
    getPicksViewCache(request: PicksRequest, options?: CdsRequestOptions): Observable<PicksView | undefined> {
        const requestKey = this.buildPicksRequestKey('picks-view', request);
        if (this.useBaseTranslation()) {
            request.alternateLanguage = this.baseTranslationConfig.defaultLanguage;
        }

        const source = this.cdsApi.post<PicksView>(`/picks`, request, options).pipe(map((response) => response));

        return this.pickCache.getOrCreate(requestKey, source);
    }

    getPicksWithFallback(request: PicksRequest): Observable<PicksView | undefined> {
        if (this.useBaseTranslation()) {
            request.alternateLanguage = this.baseTranslationConfig.defaultLanguage;
        }

        return this.cdsApi.post<PicksView>(`/picks-with-fallback`, request);
    }

    getCounts(request: FixtureCountsRequest, options?: CdsRequestOptions): Observable<TagCount[] | undefined> {
        return this.cdsApi.get<TagCount[]>('/counts', request, options);
    }

    getSportList(interval?: { from?: string; to?: string }, sportIds?: number[]): Observable<TagCount[] | undefined> {
        return this.cdsApi.get<TagCount[], FixtureCountsRequest>('/counts', {
            state: FixtureState.Latest,
            tagTypes: TagType.Sport,
            extendedTags: TagType.Sport,
            sortBy: SortByCriteria.Tags,
            supportVirtual: this.sportConfig.virtuals.isEnabled,
            sportIds: sportIds?.join(','),
            ...interval,
        });
    }

    getVirtualSportList(): Observable<VirtualSportView[] | undefined> {
        return this.cdsApi.get<VirtualSportView[], VirtualOfferRequest>('/virtual/sports', {
            scheduleSize: this.sportConfig.virtuals.upcomingEventCount,
        });
    }

    getLiveSportList(): Observable<TagCount[] | undefined> {
        const tagsToFetch = [TagType.Sport, TagType.Region, TagType.Tournament, TagType.Competition, TagType.VirtualCompetition].join(',');

        return this.cdsApi.get<TagCount[]>(
            '/counts',
            {
                state: FixtureState.Live,
                tagTypes: tagsToFetch,
                extendedTags: tagsToFetch,
                sortBy: SortByCriteria.Tags,
            },
            { addCustomerDnaProfileId: true },
        );
    }

    getPrematchEventTree(region: number, sport: number): Observable<TagCount[] | undefined> {
        const request: FixtureCountsRequest = {
            state: FixtureState.Latest,
            tagTypes: [TagType.Sport, TagType.Region, TagType.Tournament, TagType.Competition].join(','),
            extendedTags: [TagType.Sport, TagType.Region, TagType.Tournament, TagType.Competition].join(','),
            sortBy: SortByCriteria.Tags,
            sportIds: sport.toString(),
        };

        if (sport === SportConstant.Tennis) {
            request.tournamentIds = region.toString();
        } else {
            request.regionIds = region.toString();
        }

        return this.cdsApi.get<TagCount[]>('/counts', request);
    }

    getSport(sport: number, includeDynamicCategories?: boolean, interval?: { from?: string; to?: string }): Observable<TagCount[] | undefined> {
        return this.cdsApi.get<TagCount[]>('/counts', {
            state: FixtureState.Latest,
            tagTypes: [
                TagType.Sport,
                TagType.Region,
                TagType.Tournament,
                TagType.Competition,
                TagType.VirtualCompetition,
                TagType.VirtualCompetitionGroup,
            ].join(','),
            extendedTags: [
                TagType.Sport,
                TagType.Region,
                TagType.Tournament,
                TagType.Competition,
                TagType.VirtualCompetition,
                TagType.VirtualCompetitionGroup,
            ].join(','),
            sportIds: sport,
            sortBy: SortByCriteria.Tags,
            ...interval,
            participantMapping: ParticipantMapping.All,
            includeDynamicCategories: !!includeDynamicCategories,
        });
    }

    getCountsPerSport(sportId: SportConstant, tagType: TagType): Observable<TagCount[] | undefined> {
        return this.cdsApi.get<TagCount[]>('/counts', {
            state: FixtureState.Latest,
            tagTypes: tagType,
            sportIds: sportId,
        });
    }

    getCountsForConference(
        conferenceId?: number,
        competitionId?: number,
        interval?: { from?: string; to?: string },
        includeDynamicCategories?: boolean,
    ): Observable<TagCount[] | undefined> {
        return this.cdsApi.get<TagCount[]>('/counts', {
            tagTypes: TagType.Conference.toString(),
            extendedTags: '',
            conferenceIds: conferenceId?.toString(),
            competitionIds: competitionId?.toString(),
            includeDynamicCategories: !!includeDynamicCategories,
            ...interval,
        });
    }

    getFixtureList(request: FixtureRequest): Observable<FixturePage | undefined> {
        return this.cdsApi.get<FixturePage>(`/fixtures`, request);
    }

    getCountsFixtures(request: CountsFixturesRequest): Observable<CountsFixturesResponse | undefined> {
        return this.cdsApi.post<CountsFixturesResponse>(`/counts-fixtures`, request, { addCustomerDnaProfileId: true });
    }

    getBatchFixtureList(request: BatchRequest<FixtureRequest>[]): Observable<BatchResponse | undefined> {
        const pagedRequests = chunk(request, this.cdsConfig.maxFixtureBatchSize);
        const requestObservables = pagedRequests.map((r: any) => this.cdsApi.post<BatchResponse>(`/fixtures-batch`, r));

        return forkJoin(requestObservables).pipe(
            catchError((err) => {
                this.logger.error(err, `Failed calling fixture-batch endpoint. Request: ${JSON.stringify(request)}`);

                return [];
            }),
            map((results: any) => merge({}, ...results.filter(isDefined))),
        );
    }

    getFixtureListBatch(request: BatchRequest<FixtureRequest>[]): Observable<BatchResponse | undefined> {
        const pagedRequests = chunk(request, this.cdsConfig.maxFixtureBatchSize);
        const requestObservables = pagedRequests.map((r: any) => this.cdsApi.post<BatchResponse>(`/fixtures-merged-batches`, r));

        return forkJoin(requestObservables).pipe(
            catchError((err) => {
                this.logger.error(err, `Failed calling fixtures-merged-batches endpoint. Request: ${JSON.stringify(request)}`);

                return [];
            }),
            map((results: any) => merge({}, ...results.filter(isDefined))),
        );
    }

    getFixtureListByText(request: FixtureRequest & { queryText: string; queryLanguage: string }): Observable<FixturePage | undefined> {
        return this.cdsApi.get<FixturePage>(`/fixtures/by-text`, request);
    }

    getBatchCounts(request: BatchRequest<FixtureCountsRequest>[]): Observable<{ [key: string]: TagCount[] } | undefined> {
        const pagedRequests = chunk(request, this.cdsConfig.maxFixtureBatchSize);
        const requestObservables = pagedRequests.map((r: any) => this.cdsApi.post<{ [key: string]: TagCount[] }>('/counts-batch', r));

        return forkJoin(requestObservables).pipe(
            catchError((err) => {
                this.logger.error(err, `Failed calling counts-batch endpoint. Request: ${JSON.stringify(request)}`);

                return [];
            }),
            map((results: any) => merge({}, ...results.filter(isDefined))),
        );
    }

    getFavouriteCounts(request: BatchRequest<FavouriteCountsQuery>[]): Observable<{ [key: string]: TagCount[] } | undefined> {
        return this.cdsApi.post<{ [key: string]: TagCount[] }>('/counts-batch-favourites', request);
    }

    getSportsLobby(request: SportsLobbyRequest): Observable<SportsLobbyResponse | undefined> {
        return this.cdsApi.post<SportsLobbyResponse>('/lobby/sport', request);
    }

    getLobby(request: LobbyRequest): Observable<LobbyResponse | undefined> {
        return this.cdsApi.post<LobbyResponse>('/lobby', request, { addCustomerDnaProfileId: true });
    }

    getSearchResult(request: TextQuery): Observable<FixturePage | undefined> {
        return this.cdsApi.get<FixturePage>(`/fixtures/by-text`, {
            queryText: request.queryText,
            queryLanguage: request.queryLanguage,
            take: this.betFinderConfig.maxEventsAmount,
            offerMapping: OfferMapping.Filtered,
            offerCategories: [OfferCategory.Gridable, OfferCategory.Other, OfferCategory.Specials, OfferCategory.Outrights].join(','),
            fixtureTypes: FixtureType.Standard,
        });
    }

    getSuggestion(request: TextQuery): Observable<SuggestResponse | undefined> {
        return this.cdsApi.get<SuggestResponse>(`/fixtures/by-text/suggestions`, {
            queryText: request.queryText,
            queryLanguage: request.queryLanguage,
        });
    }

    getLiveHighlights(request: LiveHighlightsRequest): Observable<LiveHighlightsResponse | undefined> {
        return this.cdsApi.get<LiveHighlightsResponse>('/live/highlights', request, { addCustomerDnaProfileId: true });
    }

    getLiveEsports(request: LiveHighlightsRequest): Observable<LiveHighlightsResponse | undefined> {
        return this.cdsApi.get<LiveHighlightsResponse>('/live/esports', request, { addCustomerDnaProfileId: true });
    }

    getLiveStreams(request: LiveHighlightsRequest): Observable<LiveHighlightsResponse | undefined> {
        return this.cdsApi.get<LiveHighlightsResponse>('/live/streams', request, { addCustomerDnaProfileId: true });
    }

    getMainLiveFixtureList$(request: FixtureRequest): Observable<FixturePage | undefined> {
        return this.cdsApi.get<FixturePage>(`/main-to-live/fixtures`, request);
    }

    getMainLiveFixtureView$(fixtureId: string, useRegionalisedConfiguration: boolean = true): Observable<FixtureView | undefined> {
        return this.cdsApi.get<FixtureView, FixtureViewRequest>(`/main-to-live/fixture-view`, {
            offerMapping: OfferMapping.All,
            scoreboardMode: ScoreboardMode.Full,
            fixtureIds: fixtureId,
            state: FixtureState.Live,
            includePrecreatedBetBuilder: false,
            supportVirtual: this.sportConfig.virtuals.isEnabled,
            useRegionalisedConfiguration,
        });
    }

    private useBaseTranslation(): boolean {
        return this.baseTranslationConfig.isEnabled && this.baseTranslationConfig.defaultLanguage.toUpperCase() !== this.page.lang.toUpperCase();
    }

    private buildPicksRequestKey(prefix: string, request: PicksRequest): string {
        return concat(
            request.tv1Picks.map((p) => this.buildPickV1Key(prefix, p)),
            request.tv2Picks.map((p) => this.buildPickV2Key(prefix, p)),
        ).join('|');
    }

    private buildPickV1Key(prefix: string, pick: Tv1PickData): string {
        return `${prefix}-${pick.fixtureId}-${pick.gameId}-${pick.resultId}-${pick.useLiveFallback || false}`;
    }

    private buildPickV2Key(prefix: string, pick: Tv2PickData): string {
        return `${prefix}-${pick.fixtureId}-${pick.optionMarketId}-${pick.participantId}-${pick.isClassicBetBuilder}`;
    }
}
