import { Injectable } from '@angular/core';

import { BetSlipType } from '@bpos';
import { flatten, keyBy, uniq } from 'lodash-es';
import { AccaBoostLadderItem, AccaBoostSportConfig, AccaBoostToken } from 'packages/sports/common/betslip/modules/reward-tokens/reward-tokens.model';

import { DateProviderService } from '../common/date-provider.service';
import { PayoutType, RewardTokenType } from '../tokens-base/token-base.models';
import { AccaBoostLadderItemData, AccaBoostTokenData } from './acca-boost-data.model';

@Injectable({ providedIn: 'root' })
export class AccaBoostTokenFactoryService {
    map(data: AccaBoostTokenData): AccaBoostToken {
        return {
            startDate: new Date(),
            payoutType: PayoutType.Bonus,
            tokenType: '',
            id: data.id.toString(),
            expiryDate: DateProviderService.getDate(data.validUntil),
            userTokenId: `acca-${data.id}`,
            rewardTokenType: RewardTokenType.AccaBoost,
            cmsItemId: data.sitecorePath,
            boostLadder: this.getDistinctLadderValues(data.boostLadder),
            minimumLegs: data.boostLadder[0].numberOfLegs,
            maxReward: data.maxReward.amount,
            priority: data.priority,
            countryCodes: data.countryCodes,
            filter: {
                minOddsPerLeg: data.minOddsPerLeg,
                sports: data.sports.map((s) => ({
                    id: s.sportId,
                    name: s.sportName,
                })),
                competitions: [],
                meetings: [],
                fixtures: [],
                marketTemplates: [],
                marketParameters: [],
                markets: [],
                options: [],
                betSlipType: BetSlipType.Combo,
                gameType: data.fixtureType,
                sportsConfigs: data.sports.reduce(
                    (result, sportConfig) => {
                        result[sportConfig.sportId] = {
                            gridGroups: keyBy(uniq(flatten(sportConfig.gridGroupIds || []))),
                            competitions: keyBy(uniq(flatten(sportConfig.competitionIds || []))),
                        };

                        return result;
                    },
                    {} as Record<number, AccaBoostSportConfig>,
                ),
                schedule: {
                    daysOfTheWeek: data.daysOfWeek,
                    timesOfTheDay: [],
                },
            },
        };
    }

    private getDistinctLadderValues(boostLadder: AccaBoostLadderItemData[]): AccaBoostLadderItem[] {
        return boostLadder.reduce((result, item) => {
            const lastItem = result.length ? result[result.length - 1] : null;
            if (!lastItem || item.ratio !== lastItem.ratio) {
                result.push(item);
            }

            return result;
        }, [] as AccaBoostLadderItemData[]);
    }
}
