import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { Game, OptionMarket, OptionMarketStatus, OptionStatus, TotalsPrefix, Visibility } from '@cds/betting-offer';
import { BetBuilderConfig } from '@frontend/sports/common/client-config-data-access';
import { NativePrice } from '@frontend/sports/common/odds-lib';

import { ComboPreventionMinimum, ComboPreventionType } from '../../event-model/model/scoreboard.model';
import { sortOptions } from '../helpers/option-sorting';
import { ComboPrevention, EventOption, EventOptionGroup, ResultSorting, toResultSorting } from '../model/event.model';
import { Tv2MarketParameters } from '../model/tv2.model';
import { CommonMarketFactory } from './common-market.factory';

export type FixtureMarket = Game | OptionMarket;

interface MarketFactory {
    create(market: FixtureMarket): EventOptionGroup;

    canCreate(market: FixtureMarket, source: OfferSource | undefined): boolean;
}

class GameMarketFactory extends CommonMarketFactory implements MarketFactory {
    canCreate(market: FixtureMarket): market is Game & boolean {
        return 'visibility' in market;
    }

    create(market: FixtureMarket): EventOptionGroup {
        const game = market as Game;

        const combo = new ComboPrevention();
        combo.minimum = ComboPreventionMinimum[game.combo2];
        combo.restriction = ComboPreventionType[game.combo1];

        const optionGroup = new EventOptionGroup();
        optionGroup.id = game.id.toString();
        optionGroup.comboPrevention = combo;
        const categoryId = game.templateCategory && game.templateCategory.id;
        optionGroup.gridGroups = this.getGridGroups(game.grouping, categoryId);
        optionGroup.templateId = game.templateId.toString();
        optionGroup.name = game.name.value;
        optionGroup.winning = !!game.isMain;
        optionGroup.online = game.visibility === Visibility.Visible;
        optionGroup.hidden = game.visibility === Visibility.Hidden;
        optionGroup.resultSorting = toResultSorting(game.resultOrder);
        optionGroup.options = this.mapOptions(game, optionGroup.resultSorting);

        optionGroup.balanced = game.balanced === 1;
        optionGroup.optionSpread = game.spread;
        optionGroup.twoThreeWay = game.isMain;
        optionGroup.categoryAttribute = market.attr;
        optionGroup.description = game.description;
        optionGroup.templateCategoryId = game.categoryId;
        optionGroup.templateCategory = game.category;
        optionGroup.source = OfferSource.V1;

        this.patch(optionGroup, market);

        return optionGroup;
    }

    private mapOptions(game: Game, resultSorting: ResultSorting): EventOption[] {
        const options = game.results.map((result) => {
            const option = new EventOption();

            option.id = result.id;
            option.name = result.name.value;
            option.nativePrice = NativePrice.fromNativePrice(result);
            option.online = result.visibility === Visibility.Visible && result.odds > 1;
            option.optionAttribute = result.attr;
            option.optionGroupId = game.id;
            option.player1 = game.player1 && (game.player1.value || game.player1.short);
            option.signature = {
                event: '',
                league: '',
                market: '',
                option: '',
                region: '',
                sport: '',
            };

            option.overridenName = result.sourceName && result.sourceName.value;
            option.marketAttribute = game.attr;
            option.totalsPrefix = result.totalsPrefix;
            option.optionColumnId = game.grouping.detailed.length && game.grouping.detailed[0].optionColumnId;
            option.playerId = result.playerId;
            option.isBetBuilder = !!game.isBetBuilder;

            return option;
        });

        return sortOptions(options, resultSorting);
    }
}

class OptionMarketFactory extends CommonMarketFactory implements MarketFactory {
    canCreate(market: FixtureMarket): market is OptionMarket & boolean {
        return 'status' in market;
    }

    create(market: FixtureMarket): EventOptionGroup {
        const optionMarket = market as OptionMarket;

        const combo = new ComboPrevention();
        combo.minimum = optionMarket.minCombo || ComboPreventionMinimum.Single;
        combo.restriction = ComboPreventionType[optionMarket.comboPrevention];

        const optionGroup = new EventOptionGroup();
        optionGroup.id = optionMarket.id.toString();
        optionGroup.name = optionMarket.name.short || optionMarket.name.value;
        optionGroup.online = optionMarket.status === OptionMarketStatus.Visible;
        optionGroup.hidden = optionMarket.status === OptionMarketStatus.Hidden;
        optionGroup.options = this.mapOptions(optionMarket);
        optionGroup.gridGroups = this.getGridGroups(optionMarket.grouping);
        optionGroup.balanced = optionMarket.balanced === 1;
        optionGroup.parameters = optionMarket.parameters;
        optionGroup.optionSpread = optionMarket.spread;
        optionGroup.categoryAttribute = market.attr;
        optionGroup.winning = !!optionMarket.isMain;
        optionGroup.isEachWay = !!optionMarket.isEachWay;
        optionGroup.placeTerms = optionMarket.placeTerms;
        optionGroup.resultSorting = optionMarket.resultOrder ? toResultSorting(optionMarket.resultOrder) : undefined;
        optionGroup.description = optionMarket.description;
        optionGroup.source = optionMarket.source;
        optionGroup.templateCategory = optionMarket.templateCategory?.category;
        const templateId = optionMarket.parameters.find((f) => f.key === Tv2MarketParameters.TemplateId);
        if (templateId) {
            optionGroup.templateId = templateId.value;
        }
        const templateCategoryId = optionMarket.parameters.find((f) => f.key === Tv2MarketParameters.CategoryId);
        if (templateCategoryId) {
            optionGroup.templateCategoryId = Number(templateCategoryId.value);
        }

        this.patch(optionGroup, market);

        return optionGroup;
    }

    private mapOptions(optionMarket: OptionMarket): EventOption[] {
        return optionMarket.options.map((current) => {
            const option = new EventOption();

            option.id = current.id;
            option.name = current.name.short || current.name.value;
            option.nativePrice = NativePrice.fromNativePrice(current.price);
            option.priceId = current.price.id;
            option.online = current.status === OptionStatus.Visible && option.nativePrice.greaterThan(1);
            option.optionGroupId = optionMarket.id;
            if (current.boostedPrice != null) {
                option.boostedPrice = NativePrice.fromNativePrice(current.boostedPrice);
                option.boostedPriceId = current.boostedPrice?.id;
            }
            option.player1 = optionMarket.player1 && (optionMarket.player1.value || optionMarket.player1.short);
            option.signature = {
                event: '',
                league: '',
                market: '',
                option: '',
                region: '',
                sport: '',
            };

            option.overridenName = current.sourceName && current.sourceName.value;
            option.marketAttribute = current.attr ?? optionMarket.attr;
            option.optionColumnId = optionMarket.grouping?.detailed[0]?.optionColumnId;
            option.playerId = current.participantId;
            option.optionAttribute = optionMarket.source === OfferSource.V1 ? current.attr : undefined;
            option.totalsPrefix =
                optionMarket.source === OfferSource.V1 ? current.totalsPrefix : this.getValidTotalsPrefixType(current.parameters?.optionTypes);
            option.optionPlayerId = current.parameters?.fixtureParticipant;
            option.isBetBuilder = !!optionMarket.isBetBuilder;
            option.optionTypes = current.parameters?.optionTypes;

            return option;
        });
    }

    private getValidTotalsPrefixType(optionTypes?: string[]): TotalsPrefix | undefined {
        const totalsPrefixType = optionTypes?.find((type) => type === TotalsPrefix.Over || type === TotalsPrefix.Under);

        return totalsPrefixType ? (totalsPrefixType as TotalsPrefix) : undefined;
    }
}

@Injectable({ providedIn: 'root' })
export class FixtureMarketFactory {
    constructor(private betbuilderConfig: BetBuilderConfig) {}
    private factories = [new GameMarketFactory(this.betbuilderConfig), new OptionMarketFactory(this.betbuilderConfig)];

    create(market: FixtureMarket): EventOptionGroup {
        for (const factory of this.factories) {
            if (factory.canCreate(market)) {
                return factory.create(market);
            }
        }

        throw new Error('No market factory found for market');
    }
}
