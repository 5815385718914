import { NgModule } from '@angular/core';

import { CurrencyPipe } from './currency.pipe';

@NgModule({
    imports: [],
    declarations: [CurrencyPipe],
    exports: [CurrencyPipe],
})
export class MoneyModule {}
