import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { SimpleCallback } from '@frontend/sports/common/base-utils';
import { Observable, timer } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TimerService implements OnDestroy {
    private timeouts = new Map<number, SimpleCallback>();
    private intervals = new Map<number, SimpleCallback>();
    private frames = new Map<number, SimpleCallback>();

    constructor(private ngZone: NgZone) {}

    setTimeout(handler: SimpleCallback, time?: number): SimpleCallback {
        return this.setCallback(handler, this.timeouts, (callback) => window.setTimeout(callback, time), window.clearTimeout);
    }

    setInterval(handler: SimpleCallback, time: number): SimpleCallback {
        return this.setCallback(handler, this.intervals, (callback) => window.setInterval(callback, time), window.clearInterval, true);
    }

    setAnimationFrame(handler: SimpleCallback): SimpleCallback {
        return this.setCallback(handler, this.frames, window.requestAnimationFrame, window.cancelAnimationFrame);
    }

    setTimeoutOutsideAngular(handler: SimpleCallback, time?: number): SimpleCallback {
        return this.ngZone.runOutsideAngular(() => {
            return this.setTimeout(handler, time);
        });
    }

    setIntervalOutsideAngular(handler: SimpleCallback, time: number): SimpleCallback {
        return this.ngZone.runOutsideAngular(() => {
            return this.setInterval(handler, time);
        });
    }

    private setCallback(
        callback: SimpleCallback,
        map: Map<number, SimpleCallback>,
        scheduler: (callback: SimpleCallback) => number,
        canceller: (handle: number) => void,
        continous: boolean = false,
    ): SimpleCallback {
        const val = scheduler(() => {
            callback();

            if (!continous) {
                map.delete(val);
            }
        });

        const removeCallback = () => {
            canceller(val);
            map.delete(val);
        };

        map.set(val, removeCallback);

        return removeCallback;
    }

    timer(dueTime: number | Date = 0): Observable<number> {
        return timer(dueTime);
    }

    removeAll(): void {
        for (const collection of [this.timeouts, this.intervals, this.frames]) {
            for (const destructor of collection.values()) {
                destructor();
            }
        }
    }

    ngOnDestroy(): void {
        this.removeAll();
    }
}
