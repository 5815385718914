import { Injectable } from '@angular/core';

import { ClientConfigProductName } from '../client-config/client-config.decorator';
import { ProductService } from '../products/product.service';
import { ApiBase } from './api-base.service';
import { ApiServiceFactory } from './api-service-factory.service';

/**
 * @whatItDoes Shared features implementation of {@link ApiBase}. Calls shared features api.
 *
 * @stable
 */
@Injectable({
    providedIn: 'root',
    useFactory: sharedFeaturesApiServiceFactory,
    deps: [ApiServiceFactory, ProductService],
})
export class SharedFeaturesApiService extends ApiBase {}

export function sharedFeaturesApiServiceFactory(apiServiceFactory: ApiServiceFactory, productService: ProductService) {
    return apiServiceFactory.createForProduct(SharedFeaturesApiService, {
        product: ClientConfigProductName.SF,
        area: null,
        forwardProductApiRequestHeader: productService.getMetadata(ClientConfigProductName.SF).isEnabled,
    });
}
