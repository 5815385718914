import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { UserService } from '../../user/services/user.service';

@Injectable({ providedIn: 'root' })
export class TennisPointsVisibilityService {
    constructor(
        private m2User: UserService,
        private sitecore: Sitecore,
    ) {}

    canDisplayPoints(leagueId: number): boolean {
        if (!this.sitecore.scoreboardPointsOmit?.length) {
            return true;
        }

        if (this.m2User.isAuthenticated) {
            return true;
        }

        return !this.sitecore.scoreboardPointsOmit.some((league) => league === leagueId);
    }
}
