import { createSelector } from '@ngrx/store';

import { betslipSelector, selectIsLinearBetslip } from '../../base/store/selectors';
import { BetslipType } from '../../core/betslip-type';
import { PickId } from '../../core/picks/pick-id';
import { selectBetBuilderPicksInfo, selectBetslipFlattenedPicksList, selectNonBetBuilderPicks } from '../picks/selectors';

export const betslipTypeStateSelector = createSelector(betslipSelector, (b) => b.types);

export const betslipTabsStateSelector = createSelector(
    betslipTypeStateSelector,
    selectBetBuilderPicksInfo,
    (betslipTypeState, betBuilderPicksState) => ({ betslipTypeState, betBuilderPicksState }),
);
export const editBetStateSelector = createSelector(betslipTypeStateSelector, (state) => state.editBet);
export const editBetPicksListSelector = createSelector(betslipSelector, (state) => state.types.editBet.picks.pickList);
export const editBetPickStateSelector = createSelector(betslipTypeStateSelector, (state) => state.editBet.picks.pickState);

export const editBetAddedPicksListSelector = createSelector(betslipSelector, (state) => state.types.editBet.addPicksState.pickList);

export const editBetPickSelectorFactory = (pickId: PickId) =>
    createSelector(editBetPicksListSelector, editBetAddedPicksListSelector, (pickList, pickAddedList) =>
        [...pickList, ...pickAddedList].find((p) => p.id['id'].toString() === pickId.toString()),
    );

export const betslipSelectedTypeStateSelector = createSelector(betslipTypeStateSelector, (b) => b.base.currentSelectedType);

export const selectIsSingleBetSelected = createSelector(betslipSelectedTypeStateSelector, (selected) => selected === BetslipType.Single);
export const selectSinglePicksState = createSelector(betslipTypeStateSelector, (b) => b.singleBet);
export const selectComboPicksState = createSelector(betslipTypeStateSelector, (b) => b.comboBet);
export const selectSystemPicksState = createSelector(betslipTypeStateSelector, (b) => b.systemBet);
export const selectBetBuilderPicksState = createSelector(betslipTypeStateSelector, (b) => b.betBuilder);
export const selectTeaserPicksState = createSelector(betslipTypeStateSelector, (b) => b.teaserBet);

export const selectSingleBetPicks = createSelector(
    selectSinglePicksState,
    selectBetslipFlattenedPicksList,
    selectNonBetBuilderPicks,
    selectIsLinearBetslip,
    (singleBet, picksList, nonBetBuilderPickList, isLinear) => {
        const picks = isLinear ? nonBetBuilderPickList : picksList;

        return picks.filter((pick) => singleBet.picks[pick.id.toString()]);
    },
);

export const selectSystemBetPicks = createSelector(
    selectSystemPicksState,
    selectBetslipFlattenedPicksList,
    selectNonBetBuilderPicks,
    selectIsLinearBetslip,
    (systemBet, picksList, nonBetBuilderPickList, isLinear) => {
        const picks = isLinear ? nonBetBuilderPickList : picksList;

        return picks.filter((pick) => systemBet.picks[pick.id.toString()]);
    },
);

export const selectSinglePicksCount = createSelector(selectSingleBetPicks, (picks) => picks.length);
export const selectComboPicksCount = createSelector(selectComboPicksState, (b) => Object.keys(b.picks).length);
export const selectSystemPicksCount = createSelector(selectSystemBetPicks, (picks) => picks.length);
export const selectBetBuilderPicksCount = createSelector(selectBetBuilderPicksState, (b) => Object.keys(b.picks).length);
export const selectTeaserPicksCount = createSelector(selectTeaserPicksState, (b) => Object.keys(b.picks).length);
export const selectTypesPickCount = createSelector(
    selectSinglePicksCount,
    selectComboPicksCount,
    selectSystemPicksCount,
    selectBetBuilderPicksCount,
    selectTeaserPicksCount,

    (singlePicksCount, comboPicksCount, systemPicksCount, betBuilderPicksCount, teaserPicksCount) => ({
        singlePicksCount,
        comboPicksCount,
        systemPicksCount,
        betBuilderPicksCount,
        teaserPicksCount,
    }),
);

export const selectHasBetBuilderStatePicks = createSelector(selectBetBuilderPicksCount, (count) => count > 0);
