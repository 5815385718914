import { ComponentRef, Directive, OnDestroy, Type, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[msComponentHost]',
})
export class ComponentHostDirective<TComponent> implements OnDestroy {
    constructor(private viewContainerRef: ViewContainerRef) {}

    componentRef: ComponentRef<TComponent> | null = null;

    createComponent(type: Type<TComponent>): ComponentRef<TComponent> {
        this.componentRef = this.viewContainerRef.createComponent(type);

        return this.componentRef;
    }

    clear(): void {
        this.viewContainerRef.clear();
        this.componentRef = null;
    }

    ngOnDestroy(): void {
        this.clear();
    }
}
