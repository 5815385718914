import { Inject, Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { Participant, ParticipantType } from '@cds/betting-offer';
import { Competition } from '@cds/betting-offer/tags';
import { ExpiringCache, SportConstant } from '@frontend/sports/common/base-utils';
import { TeamPagesConfig } from '@frontend/sports/common/client-config-data-access';
import { CompetitionDetail } from '@frontend/sports/types/components/team-pages';
import { LocalStoreService } from '@frontend/vanilla/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseCdsApi } from '../cds/cds-api.service';
import { BaseCdsApiFactory, CDS_API_FACTORY } from '../cds/cds-api.service.factory';
import { MasterdataApiService } from '../cds/cds-masterdata-api.service';
import { FavouriteType, Team, TeamPageCompetition } from './team-pages.model';

@Injectable({ providedIn: 'root' })
export class TeamPagesApiService {
    private cdsApi: BaseCdsApi;
    private teamsByComepetitionCache: ExpiringCache = new ExpiringCache(600);
    private teamsCountByCompetitionsCache: ExpiringCache = new ExpiringCache(600);
    private seeMoreStorageKey = 'seeMoreCompetition';
    constructor(
        @Inject(CDS_API_FACTORY) cdsApiServiceFactory: BaseCdsApiFactory,
        private teamPagesConfig: TeamPagesConfig,
        private m2LocalStore: LocalStoreService,
        private cdsMasterApi: MasterdataApiService,
    ) {
        this.cdsApi = cdsApiServiceFactory({ endpoint: '/masterdata' });
    }

    getTeamsByCompetitionId(id: string, sportId: number): Observable<Team[]> {
        if (!id) {
            return of([]);
        }
        const source = this.cdsApi.get<Participant[]>('/competitions/participants/v2', { competitionId: id, sportId }).pipe(
            map((response) => {
                if (!response) {
                    return [];
                }

                return response
                    .filter(
                        (item) =>
                            item && item.participantId && (item.type && sportId === SportConstant.Soccer ? item.type === ParticipantType.Team : true),
                    )
                    .map((item) => ({
                        id: item.participantId,
                        name: item.name.value || '',
                        competitionId: id,
                        isFavourited: false,
                        isSelected: false,
                        teamType: this.getFavouriteType(item.source),
                        offerSource: item.source,
                        participantImage: item.image,
                    }));
            }),
        );

        return this.teamsByComepetitionCache.getOrCreate(`teamPages-comp-${id}`, source);
    }

    mapTeam(participant: Participant, competitionId: string): Team {
        return {
            id: participant.participantId,
            name: participant.name.value || '',
            competitionId,
            isFavourited: false,
            isSelected: false,
            teamType: this.getFavouriteType(participant.source),
            offerSource: participant.source,
            participantImage: participant.image,
        };
    }

    getTeamsByQueryText(sportId: number, query: string): Observable<Team[]> {
        return this.cdsApi.get<Participant[]>('/participants/by-text', { sportId, query }).pipe(
            map((response) => {
                if (!response) {
                    return [];
                }

                return response.map((participant) => ({
                    id: participant.participantId,
                    name: participant.name.value,
                    isSelected: false,
                    isFavourited: false,
                    offerSource: participant.source,
                    teamType: this.getFavouriteType(participant.source),
                }));
            }),
        );
    }

    getCompetitionsAndTeamsCount(competitions: CompetitionDetail[], sportId?: number | 0): Observable<CompetitionDetail[]> {
        const seeMoreComp = this.seeMoreCompetition;
        const competitionData = [...competitions];
        let source: Observable<CompetitionDetail[]>;
        let compIds: string;
        if (seeMoreComp?.sportId === sportId && !competitions.some((comp) => comp.competitionId === seeMoreComp?.id)) {
            competitionData.push({
                competitionId: seeMoreComp.id,
                competitionName: seeMoreComp.name,
            });
            compIds = competitionData.map((s) => s.competitionId).join(',');
            source = forkJoin([this.GetCompetitionsWithSeeMore(competitionData, seeMoreComp), this.getCompetitionName(seeMoreComp)]).pipe(
                map(([response, name]) => {
                    return this.getCompetitionTabs(response, name, seeMoreComp);
                }),
            );
        } else {
            compIds = competitionData.map((s) => s.competitionId).join(',');
            source = this.GetCompetitionsWithSeeMore(competitionData, seeMoreComp).pipe(
                map((response) => {
                    return this.getCompetitionTabs(response);
                }),
            );
        }

        return this.teamsCountByCompetitionsCache.getOrCreate(`teampages-count-${compIds}`, source);
    }

    private getCompetitionTabs(response: CompetitionDetail[], name?: string, seeMoreComp?: TeamPageCompetition): CompetitionDetail[] {
        if (!response) {
            return [];
        }
        let data = [...response];
        const seeMoreCompetition = data.find((x) => x.competitionId === seeMoreComp?.id);
        if (seeMoreCompetition) {
            seeMoreCompetition.competitionName = name ?? '';
            data = data.filter((x) => x.competitionId !== seeMoreCompetition.competitionId);
            data.push(seeMoreCompetition);
        }

        return data;
    }

    GetCompetitionsWithSeeMore(competitionData: CompetitionDetail[], seeMoreComp: TeamPageCompetition): Observable<CompetitionDetail[]> {
        return this.cdsMasterApi.getCompetitionCount(competitionData).pipe(
            map((response) => {
                if (!response) {
                    return [];
                }
                const competitionwithteam = competitionData.filter((c) => response[c.competitionId] > 0);
                if (competitionwithteam.length > this.teamPagesConfig.maxCompetitions) {
                    const seemorecompetition = competitionwithteam.find((k) => k.competitionId === seeMoreComp?.id);
                    if (seemorecompetition) {
                        const data = competitionwithteam.splice(0, this.teamPagesConfig.maxCompetitions - 1);
                        data.push(seemorecompetition);

                        return data;
                    }

                    return competitionwithteam.splice(0, this.teamPagesConfig.maxCompetitions);
                }

                return competitionwithteam;
            }),
        );
    }

    getCompetitionName(competition: TeamPageCompetition): Observable<string> {
        return this.cdsApi.post<Competition>('/info/competition/v2', { CompetitionId: competition.id }).pipe(
            map((res) => {
                return res?.name?.value ?? '';
            }),
        );
    }

    get seeMoreCompetition(): TeamPageCompetition {
        return this.m2LocalStore.get(this.seeMoreStorageKey) as TeamPageCompetition;
    }

    private getFavouriteType(source: OfferSource | undefined): FavouriteType {
        if (source === OfferSource.V2) {
            return FavouriteType.ParticipantV2;
        }

        return FavouriteType.Participant;
    }
}
