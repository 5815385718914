import { SchemaMarkupType } from './schema-markup-enum';

export class SeoMarkupConstants {
    static regionWorldCode = 'WRL';
    static ldJsonPrefix = 'ldJson_';
    static markupSchemaContext = 'http://schema.org';
}

export class SeoGenericSchema {
    '@type': string;
    '@id': string;
    'name': string;

    'constructor'(type: SchemaMarkupType, name?: string, id?: string) {
        this['@type'] = type;
        if (name) {
            this.name = name;
        }
        if (id) {
            this['@id'] = id;
        }
    }
}

export class SeoPlaceSchema extends SeoGenericSchema {
    address: SeoPostalAddressSchema;

    constructor(name: string, address: SeoPostalAddressSchema) {
        super(SchemaMarkupType.Place, name);
        this.address = address;
    }
}

export class SeoSportsEventSchema extends SeoGenericSchema {
    '@context' = SeoMarkupConstants.markupSchemaContext;
    'startDate': string;
    'url': string;
    'location': SeoPlaceSchema;

    'constructor'(name: string, startDate: string, url: string, location: SeoPlaceSchema) {
        super(SchemaMarkupType.SportsEvent, name);
        this.startDate = startDate;
        this.url = url;
        this.location = location;
    }
}

export class SeoPostalAddressSchema extends SeoGenericSchema {
    addressCountry: string;

    constructor(name: string, addressCountry: string, isRegion: boolean) {
        if (!isRegion) {
            super(SchemaMarkupType.PostalAddress, name);
        } else {
            super(SchemaMarkupType.PostalAddress);
            this.addressCountry = addressCountry;
        }
    }
}

export class SeoLdJsonSchemaContainer {
    '@graph': Array<SeoSportsEventSchema> | SeoBreadcrumbSchema;

    'constructor'(graph: Array<SeoSportsEventSchema> | SeoBreadcrumbSchema) {
        this['@graph'] = graph;
    }
}

export class SeoItemSchema extends SeoGenericSchema {
    constructor(id: string) {
        super(SchemaMarkupType.Thing, '', id);
    }
}

export class SeoListItemSchema extends SeoGenericSchema {
    item: SeoItemSchema;
    position: number;

    constructor(item: SeoItemSchema, name: string, position: number) {
        super(SchemaMarkupType.ListItem, name);
        this.item = item;
        this.position = position;
    }
}

export class SeoBreadcrumbSchema extends SeoGenericSchema {
    '@context' = SeoMarkupConstants.markupSchemaContext;
    'itemListElement': Array<SeoListItemSchema>;

    'constructor'(itemListElement: Array<SeoListItemSchema>) {
        super(SchemaMarkupType.BreadcrumbList);
        this.itemListElement = itemListElement;
    }
}
