<ms-widget-slot
    class="col-12"
    *ngIf="top.length"
    [page]="page"
    [widget]="top"
    [layoutTemplate]="layoutTemplate"
    [ratio]="1"
    msAutomation="widget-slot-top"></ms-widget-slot>
<ms-widget-slot
    class="col-3"
    *ngIf="leftVisible"
    [page]="page"
    [widget]="left"
    [ratio]="3 / 12"
    [layoutTemplate]="layoutTemplate"
    msAutomation="widget-slot-left"></ms-widget-slot>
<ms-widget-slot
    [ngClass]="leftVisible ? 'col-9' : 'col-12'"
    [ratio]="leftVisible ? 9 / 12 : 1"
    *ngIf="center.length"
    [page]="page"
    [widget]="center"
    [layoutTemplate]="layoutTemplate"
    msAutomation="widget-slot-center"></ms-widget-slot>
