import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { WindowRef } from '@frontend/vanilla/core';
import { fromEvent, merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@HooksWireup()
@Directive({
    selector: '[msOutsideInteraction]',
})
export class OutsideInteractionDirective extends OnDestroyCleanup implements AfterViewInit {
    @Output() msOutsideInteraction = new EventEmitter<void>();

    @Input() set msOutsideInteractionExclude(value: string) {
        this.excludeSelectors = (value || '').split(',');
    }

    private excludeSelectors: string[] | undefined = undefined;

    constructor(
        private elementRef: ElementRef,
        private windowRef: WindowRef,
        private ngZone: NgZone,
    ) {
        super();
    }

    ngAfterViewInit(): void {
        const events = ['click'];

        this.ngZone.runOutsideAngular(() => {
            merge(...events.map((event) => fromEvent(this.windowRef.nativeWindow, event)))
                .pipe(
                    filter((e) => !this.isOwnClick(e)),
                    takeUntil(this.destroyed$),
                )
                .subscribe(() => this.msOutsideInteraction.next());
        });
    }

    private isOwnClick(event: Event): boolean {
        const target = event.target as HTMLElement;
        let isOwnEvent = !!target && this.element.contains(target);

        if (!isOwnEvent && this.excludeSelectors) {
            this.excludeSelectors.forEach((selector) => (isOwnEvent = isOwnEvent || target.matches(selector)));
        }

        return isOwnEvent;
    }

    private get element(): HTMLElement {
        return this.elementRef.nativeElement;
    }
}
