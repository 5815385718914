import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { LinkListLayout } from '@frontend/sports/types/components/widget/types';
import { PlainLinkComponent } from '@frontend/vanilla/core';

import { AutomationModule } from '../automation/automation.module';
import { EpcotConfigService } from '../common/epcot-config.service';
import { ItemComponent } from '../common/item/item.component';
import { QuicklinkDirective } from '../quicklink/quicklink.directive';
import { TrackingService } from '../tracking/tracking.service';
import { BadgeComponent } from './badge.component';

@Component({
    selector: 'ms-promo-item',
    templateUrl: './promo-item.html',
    standalone: true,
    imports: [AutomationModule, NgTemplateOutlet, BadgeComponent, NgClass, PlainLinkComponent, QuicklinkDirective],
})
export class PromoItemComponent extends ItemComponent implements OnChanges {
    @Input() badgesEnabled = false;
    @Input() showLiveBadge = false;
    @Input() popup: string;
    @Input() leagueTitle?: string;
    @Input() leagueIcon?: string;
    @Input() layout?: LinkListLayout;

    isMiniCarouselLayout: boolean;
    readonly isEpcotEnabled = this.epcotConfigService.isEnabled();

    constructor(
        public sitecore: Sitecore,
        trackerService: TrackingService,
        private epcotConfigService: EpcotConfigService,
    ) {
        super(trackerService);
    }

    ngOnChanges(): void {
        this.isMiniCarouselLayout = this.layout === LinkListLayout.MiniCarousel && !!this.leagueTitle && this.isEpcotEnabled;
    }
}
