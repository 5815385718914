import { BetslipType } from '../../../../core/betslip-type';
import { IBetBuilderBetState, IBetBuilderPickState } from '../../../bet-builder/models';
import { IComboBetState } from '../../../combo-bet/state';
import { isFreebetToken } from '../../../reward-tokens/services/reward-tokens.utils';
import { IRewardTokensState, TokensRecord } from '../../../reward-tokens/state';
import { ISingleBetPickState, ISingleBetState } from '../../../single-bet/state';
import { ISystemBetState } from '../../../system-bet/state';
import { ITeaserBetState } from '../../../teaser-bet/state';
import { IBetslipTypeState } from '../../../types/state';
import { TotalStake, getBetBuilderStakes, getComboStake, getSingleBetStakes, getTeaserStake } from './stake-utils';

export function getStakedTypes(typeState: IBetslipTypeState, tokens: TokensRecord): BetslipType[] {
    const linearTypes = typeState.base.linearTypes;

    const types: BetslipType[] = [];

    if (linearTypes[BetslipType.Single] && hasSingleBetStake(typeState.singleBet, tokens)) {
        types.push(BetslipType.Single);
    }

    if (linearTypes[BetslipType.BetBuilder] && hasBetBuilderBetStake(typeState.betBuilder, tokens)) {
        types.push(BetslipType.BetBuilder);
    }

    if (linearTypes[BetslipType.Combo] && hasComboBetStake(typeState.comboBet, tokens)) {
        types.push(BetslipType.Combo);
    }

    if (linearTypes[BetslipType.System] && hasSystemBetStake(typeState.systemBet)) {
        types.push(BetslipType.System);
    }

    if (linearTypes[BetslipType.Teaser] && hasTeaserBetStake(typeState.teaserBet, tokens)) {
        types.push(BetslipType.Teaser);
    }

    return types;
}

export function hasSingleBetStake(singleState: ISingleBetState, tokens: TokensRecord): boolean {
    return Object.values(singleState.picks).some((a) => hasSingleBetPickStake(a, tokens));
}

export function hasSingleBetPickStake(singleBetPickState: ISingleBetPickState, tokens: TokensRecord): boolean {
    return singleBetPickState.stake !== null || hasFreeBetStake(singleBetPickState.rewardTokenId, tokens);
}

export function hasComboBetStake(comboState: IComboBetState, tokens: TokensRecord): boolean {
    return comboState.stake !== null || hasFreeBetStake(comboState.rewardTokenId, tokens);
}

export function hasBetBuilderBetStake(betBuilderState: IBetBuilderBetState, tokens: TokensRecord): boolean {
    return Object.values(betBuilderState.picks).some((a) => hasBetBuilderBetPickStake(a, tokens));
}

export function hasBetBuilderBetPickStake(betBuilderBetPickState: IBetBuilderPickState, tokens: TokensRecord): boolean {
    return betBuilderBetPickState.stake !== null || hasFreeBetStake(betBuilderBetPickState.rewardTokenId, tokens);
}

export function hasTeaserBetStake(teaserState: ITeaserBetState, tokens: TokensRecord): boolean {
    return teaserState.stake != null || hasFreeBetStake(teaserState.rewardTokenId, tokens);
}

function hasFreeBetStake(tokenId: string | null, tokens: TokensRecord): boolean {
    return !!tokenId && isFreebetToken(tokens[tokenId]);
}

export function hasSystemBetStake(systemState: ISystemBetState): boolean {
    return Object.values(systemState.linearTypeStakes).some((stake) => stake.stake !== null);
}

export function getTotalStakesForAllTypes(typeState: IBetslipTypeState, tokensState: IRewardTokensState): TotalStake[] {
    const linearTypes = typeState.base.linearTypes;

    const singleBetStakes = linearTypes[BetslipType.Single] ? getSingleBetStakes(typeState.singleBet, tokensState, true) : [];
    const comboBetStakes = linearTypes[BetslipType.Combo] ? getComboStake(typeState.comboBet, tokensState) : [];
    const systemBetStakes = linearTypes[BetslipType.System] ? getSystemStakesLinear(typeState.systemBet) : [];
    const teaserBetStakes = linearTypes[BetslipType.Teaser] ? getTeaserStake(typeState.teaserBet, tokensState) : [];
    const betBuilderStakes = linearTypes[BetslipType.BetBuilder] ? getBetBuilderStakes(typeState.betBuilder, tokensState) : [];

    return [...singleBetStakes, ...comboBetStakes, ...systemBetStakes, ...teaserBetStakes, ...betBuilderStakes];
}

function getSystemStakesLinear(systemState: ISystemBetState) {
    return Object.values(systemState.linearTypeStakes).map((type) => {
        const stake = type.stake && type.stake * type.betCount;

        return {
            stake: type.isEachWay && stake ? stake * 2 : stake,
            freeBetAmount: null,
        };
    });
}
