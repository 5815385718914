import { clone } from 'lodash-es';

export enum ScrollSpeedConstants {
    Default = 'default',
}

export class ScrollSpeed {
    private _breakpoints: { [key: string]: string };
    private _default: string;

    constructor(config: { [key: string]: string }) {
        const parsedConfig = this.parseConfig(config);
        this._breakpoints = parsedConfig.breakpoints;
        this._default = parsedConfig.default;
    }

    get breakpoints(): { [key: string]: string } {
        return this._breakpoints;
    }

    get default(): string {
        return this._default;
    }

    private parseConfig(config: { [key: string]: string }): { breakpoints: { [key: string]: string }; default: string } {
        const clonedConfig = clone(config);

        const defaultValue = clonedConfig[ScrollSpeedConstants.Default];
        delete clonedConfig[ScrollSpeedConstants.Default];

        return {
            breakpoints: clonedConfig,
            default: defaultValue,
        };
    }
}
