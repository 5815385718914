import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { SimpleCallback } from '@frontend/sports/common/base-utils';
import { DeviceService } from '@frontend/vanilla/core';
import { Subscription } from 'rxjs';

import { TimerService } from '../../common/timer.service';

@Directive({
    selector: '[msScrollIosHack]',
})
export class ScrollIosHackDirective implements OnInit, OnDestroy {
    private orientationSubscription?: Subscription;
    private timeout?: SimpleCallback;

    constructor(
        private timerService: TimerService,
        private deviceService: DeviceService,
        private renderer: Renderer2,
        private element: ElementRef,
    ) {}

    ngOnInit(): void {
        if (!this.deviceService.isiOS) {
            return;
        }

        this.orientationSubscription = this.deviceService.orientation.subscribe(this.onOrientationChange);
    }

    ngOnDestroy(): void {
        this.orientationSubscription?.unsubscribe();

        if (this.timeout) {
            this.timeout();
        }
    }

    private onOrientationChange = () => {
        this.changeOverflowScrolling('auto');

        this.timeout = this.timerService.setTimeoutOutsideAngular(() => {
            this.changeOverflowScrolling('touch');
        }, 1000);
    };

    private changeOverflowScrolling = (value: string) => {
        this.renderer.setStyle(this.element.nativeElement, 'webkitOverflowScrolling', value);
    };
}
