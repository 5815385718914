import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { PicksView } from '@cds/betting-offer/domain-specific';
import { PicksRequest, Tv1PickData, Tv2PickData } from '@cds/query-objects';
import { isDefined } from '@frontend/sports/common/base-utils';
import { NavigationService, UrlService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { BettingOfferApi } from 'packages/sports/web/app/src/cds/betting-offer-api.service';
import { OptionParams, OptionQuery, OptionRequest } from 'packages/sports/web/app/src/navigation-core/pick-uri.model';
import { PickUriService } from 'packages/sports/web/app/src/navigation-core/pick-uri.service';
import { RouterEventsService } from 'packages/sports/web/app/src/navigation-core/router-events.service';
import { UserService } from 'packages/sports/web/app/src/user/services/user.service';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

import { IBetslipRootState } from '../base/store/state';
import { ExternalBetslipActions } from '../core/external-betslip-actions';

enum UriParam {
    Options = 'options',
    Stake = 'stake',
    Currency = 'currency',
    Type = 'type',
    Source = 'source',
    RefBet = 'refbet',
    Offersource = 'offerSource',
}

@Injectable()
export default class ExternalAffiliateEffects {
    constructor(
        private store: Store<IBetslipRootState>,
        private routerService: RouterEventsService,
        private pickUriService: PickUriService,
        private bettingService: BettingOfferApi,
        private userService: UserService,
        private navigationService: NavigationService,
        private urlService: UrlService,
    ) {
        this.listenToRouter();
    }

    private listenToRouter() {
        this.routerService.currentActivationEnd
            .pipe(
                filter(isDefined),
                map((route) => this.pickUriService.parse(route.snapshot.queryParams, this.userService.currency)),
                filter((request: OptionRequest) => request.query.length > 0),
                distinctUntilChanged(isEqual),
                switchMap((request: OptionRequest) =>
                    this.bettingService.getPicksWithFallback(this.mapPicksRequest(request)).pipe(
                        tap((picksView: PicksView | undefined) => {
                            this.cleanUrl();

                            if (!picksView || !picksView.fixturePage.fixtures.length) {
                                this.store.dispatch(ExternalBetslipActions.shareBet());
                            } else {
                                this.store.dispatch(
                                    ExternalBetslipActions.requestAddAffiliatePicks({
                                        request,
                                        picksView,
                                    }),
                                );
                            }
                        }),
                    ),
                ),
            )
            .subscribe();
    }

    private cleanUrl(): void {
        const location = this.urlService.current();
        const params: (keyof OptionParams)[] = [
            UriParam.Options,
            UriParam.Stake,
            UriParam.Currency,
            UriParam.Type,
            UriParam.Source,
            UriParam.RefBet,
            UriParam.Offersource,
        ];

        for (const param of params) {
            location.search.delete(param);
        }

        this.navigationService.goTo(location, { replace: true });
    }

    private mapPicksRequest(request: OptionRequest): PicksRequest {
        const tv1Picks: Tv1PickData[] = [];
        const tv2Picks: Tv2PickData[] = [];

        const picksData = request.query.map((pick) => {
            if (pick.tv1Legs) {
                tv1Picks.push(...pick.tv1Legs);
            }

            if (pick.tv2Legs) {
                tv2Picks.push(...pick.tv2Legs);
            }

            if ((!pick.tv1Legs || pick.tv1Legs.length === 0) && (!pick.tv2Legs || pick.tv2Legs.length === 0)) {
                const offerSource = this.getOfferSource(pick, request.offerSource);
                const pickData = this.mapOptionQueryToPick(pick, offerSource);

                if (offerSource === OfferSource.V1) {
                    tv1Picks.push(pickData as Tv1PickData);
                } else {
                    tv2Picks.push(pickData as Tv2PickData);
                }
            }

            return {
                fixtureId: pick.fixture!, // contract on cds side should be updated
                gameId: pick.optionGroup,
                participantId: pick.option ? Math.abs(pick.option) : pick.option,
                optionMarketId: pick.optionGroup,
                resultId: pick.option,
                useLiveFallback: true,
                offerSource: this.getOfferSource(pick, request.offerSource),
            };
        });

        return {
            picks: tv1Picks.length || tv2Picks.length ? [] : picksData,
            includeSourceFixturesForPrecreatedBuildABet: picksData.some((p) => p.offerSource.toLowerCase() === OfferSource.BetBuilder.toLowerCase()),
            tv1Picks,
            tv2Picks,
        };
    }

    private mapOptionQueryToPick(optionQuery: OptionQuery, offerSource: OfferSource): Tv1PickData | Tv2PickData {
        if (offerSource === OfferSource.V1) {
            const pickData: Tv1PickData = {
                fixtureId: optionQuery.fixture!,
                gameId: optionQuery.optionGroup,
                resultId: optionQuery.option,
                useLiveFallback: true,
            };

            return pickData;
        } else {
            const pickData: Tv2PickData = {
                fixtureId: optionQuery.fixture!,
                optionId: optionQuery.option,
                optionMarketId: optionQuery.optionGroup,
                participantId: optionQuery.option ? Math.abs(optionQuery.option) : optionQuery.option,
                isClassicBetBuilder: false,
            };

            if (offerSource === OfferSource.BetBuilder) {
                pickData.isClassicBetBuilder = true;
            }

            return pickData;
        }
    }

    private getOfferSource(pick: OptionQuery, offerSource?: string): OfferSource {
        if (offerSource?.toLowerCase() === OfferSource.BetBuilder.toLowerCase()) {
            return OfferSource.BetBuilder;
        }

        return pick.fixture?.includes(':') ? OfferSource.V2 : OfferSource.V1;
    }
}
