import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { NativeAppService, Page } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../http/api.service';

export interface PrettyUrlApiResponse {
    [language: string]: string;
}

@Injectable({ providedIn: 'root' })
export class NoMatchRouteGuard {
    constructor(
        private router: Router,
        private nativeAppService: NativeAppService,
        private apiService: ApiService,
        private page: Page,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> | boolean {
        if (!this.nativeAppService.isNativeWrapper) {
            return true;
        }
        const urlParams = {
            url: state.url,
            sourceLanguage: 'en',
            targetLanguages: this.page.lang,
        };

        return this.apiService.get<PrettyUrlApiResponse>('url', urlParams).pipe(
            map((result) => {
                if (result[this.page.lang] !== decodeURI(state.url)) {
                    return this.router.parseUrl(result[this.page.lang]);
                }

                return true;
            }),
        );
    }
}
