import { Injectable } from '@angular/core';

import { TopNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { DslService } from '@frontend/vanilla/core';
import { Observable, combineLatest, defer, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { SportsCacheService } from '../../client-caching/sports-cache.service';
import { NavigationItemsMapper } from '../navigation-items-mapper';
import { TopMenuContentItem } from '../navigation.models';
import { BaseNavigationItemsProvider } from './base-navigation-items.provider';

@Injectable({ providedIn: 'root' })
export class FixedNavigationItemsProvider extends BaseNavigationItemsProvider {
    readonly navigationItems$: Observable<TopMenuContentItem[]>;

    constructor(
        private navigationConfig: TopNavigationConfig,
        private dslService: DslService,
        protected mapper: NavigationItemsMapper,
        protected override sportsCache: SportsCacheService,
    ) {
        super(sportsCache);

        const evaluatedItems$ = defer(() =>
            of(this.navigationConfig.topNavigationItems?.length ? this.navigationConfig.fixedItems : this.navigationConfig.afterSports),
        ).pipe(switchMap((data) => this.dslService.evaluateContent(data)));

        this.navigationItems$ = combineLatest([evaluatedItems$, this.hasLiveItems$]).pipe(
            map(([items, hasLive]) => {
                const options = {
                    hasLive,
                    isFixedItem: true,
                };

                return this.mapper.mapItems(items, '', options);
            }),
            startWith([]),
        );
    }
}
