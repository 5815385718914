import { Injectable } from '@angular/core';

import { PrettyUrlsConfig } from '@frontend/sports/common/client-config-data-access';
import produce from 'immer';
import { clone, trim } from 'lodash-es';

import { SegmentNormalizer } from '../navigation-core/url-builder';
import { FavouriteType, FavouritesDto, FavouritesViewModel } from './favourites.model';

@Injectable({ providedIn: 'root' })
export class FavouritesModelFactory {
    private normalizer: SegmentNormalizer;

    constructor(urlConfig: PrettyUrlsConfig) {
        this.normalizer = new SegmentNormalizer(urlConfig);
    }

    toModel(dto: FavouritesDto | FavouritesViewModel): Readonly<FavouritesViewModel> {
        return produce<FavouritesViewModel>(dto as any, (draft) => {
            draft.id = this.getToken(draft.type, draft.name || '', draft.sport.id, draft.itemId || 0);
            draft.key = this.getKey(draft);
            draft.count = 0;
            draft.region = clone(dto.region);
            draft.sport.name = dto.sport.name;
            draft.imageProfile = dto.imageProfile;
            draft.participantImage = dto.participantImage;
        });
    }

    toDto(val: FavouritesViewModel): Readonly<FavouritesDto> {
        return val as FavouritesDto;
    }

    private getKey(value: FavouritesDto | FavouritesViewModel): string {
        return trim([value.type, 0, value.sport.id, value.itemId, value.name].join(','), ',');
    }

    private getToken(type: FavouriteType, name: string, sport: number, item: number | string): string {
        return this.normalizer.normalize(`${type} ${name} ${sport || 0} ${item || ''}`);
    }
}
