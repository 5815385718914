import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutomationDirective } from './automation.directive';

@NgModule({
    imports: [CommonModule],
    declarations: [AutomationDirective],
    exports: [AutomationDirective],
})
export class AutomationModule {}
