import { Injectable } from '@angular/core';

import { TagCount } from '@cds/betting-offer/tags';
import { CountItem, ExpiringCache, sportModelMethods } from '@frontend/sports/common/base-utils';
import { ClientCachingConfig, TeamPagesConfig, TeamPagesModuleConfig } from '@frontend/sports/common/client-config-data-access';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { CalendarService } from '../calendar/calendar.service';
import { SportIntervalCounts } from '../calendar/count-interval.service';
import { BettingOfferApi } from '../cds/betting-offer-api.service';
import { MasterdataApiService } from '../cds/cds-masterdata-api.service';
import { UserService } from '../user/services/user.service';

@Injectable({
    providedIn: 'root',
})
export class SportsCacheService {
    private liveSportList: ExpiringCache = new ExpiringCache(this.clientCachingConfig.liveSportListTimeout);
    private sportListCache: ExpiringCache = new ExpiringCache(this.clientCachingConfig.sportListTimeout);
    private sportItemCache: ExpiringCache = new ExpiringCache(this.clientCachingConfig.sportTimeout);

    constructor(
        private clientCachingConfig: ClientCachingConfig,
        private bettingContent: BettingOfferApi,
        private calendarService: CalendarService,
        private teamPagesData: TeamPagesModuleConfig,
        private teamPagesConfig: TeamPagesConfig,
        private masterdataApi: MasterdataApiService,
        userService: UserService,
    ) {
        userService.onAuthenticationChange$.subscribe(() => {
            this.sportListCache.clear();
        });
    }

    getSportList(): Observable<CountItem[]> {
        const source = this.bettingContent.getSportList().pipe(map((result) => sportModelMethods.fromTag(result)));

        return this.sportListCache.getOrCreate('sport-list', source);
    }

    getLiveSportList(): Observable<CountItem[]> {
        const source = this.bettingContent.getLiveSportList().pipe(map((result) => sportModelMethods.fromTag(result)));

        return this.liveSportList.getOrCreate('live-sport-list', source);
    }

    getSport(id: number, competitionId?: number, conferenceId?: number, includeDynamicCategories?: boolean): Observable<CountItem | undefined> {
        const sportSource = forkJoin([
            this.bettingContent.getSport(id, includeDynamicCategories),
            this.getSportCalendarCounts(id),
            this.isTeamPagesEnabled(id),
        ]);

        return this.sportItemCache.getOrCreate(`sport-${id}${includeDynamicCategories ? `-withDynamicCategories` : ''}`, sportSource).pipe(
            switchMap(([tag, calendar, team]) =>
                combineLatest([
                    of(tag),
                    this.getConference(conferenceId, competitionId, undefined, includeDynamicCategories),
                    of(calendar),
                    of(team),
                ]),
            ),
            map(([tag, conference, calendar, team]) => {
                const sport = sportModelMethods.fromTag((tag || []).concat(conference || [])).pop();
                if (sport) {
                    return {
                        ...sport,
                        meta: {
                            ...sport.meta,
                            calendar: this.isCalendarEnabled(calendar),
                            teamPages: team,
                            calendarIntervals: this.getCalendarIntervals(calendar),
                        },
                    };
                }

                return;
            }),
        );
    }

    // TODO: New call to bettingoffer/counts is needed because CDS requires CopmetitionId to process Conferences
    // Revert these changes when CDS starts returning Conferences counts per SportId
    getConference(
        conferenceId?: number,
        competitionId?: number,
        interval?: { from?: string; to?: string },
        includeDynamicCategories?: boolean,
    ): Observable<TagCount[] | undefined> {
        if (!conferenceId || !competitionId) {
            return of(undefined);
        }

        const conference = this.bettingContent.getCountsForConference(conferenceId, competitionId, interval, includeDynamicCategories);

        return !interval
            ? this.sportItemCache.getOrCreate(
                  `competition-${competitionId}-conference-${conferenceId}${includeDynamicCategories ? `-withDynamicCategories` : ''}`,
                  conference,
              )
            : conference;
    }

    getAllSportsList(): Observable<CountItem[]> {
        const result = forkJoin([this.getSportList(), this.getLiveSportList()]).pipe(
            map(([latestSports, liveSports]) => {
                const allSports = new Array<CountItem>();
                allSports.push(...latestSports);
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let index = 0; index < allSports.length; index++) {
                    if (liveSports.find((x) => x.id === allSports[index].id)) {
                        allSports[index] = liveSports.find((x) => x.id === allSports[index].id)!;
                    } else {
                        allSports[index].counts.live = 0;
                    }
                }

                return allSports;
            }),
        );

        return result;
    }

    private getSportCalendarCounts(id: number): Observable<SportIntervalCounts | undefined> {
        return this.calendarService.getCalendarCounts(id.toString());
    }

    private isTeamPagesEnabled(sportId: number) {
        const id = Number(sportId);
        const isTeamPagesEnabledForSport = this.teamPagesConfig.whitelistedSports.includes(id);

        if (this.teamPagesConfig.isEnabled && isTeamPagesEnabledForSport) {
            return this.teamPagesData.whenReady.pipe(
                first(),
                switchMap(() => {
                    const sports = this.teamPagesData.teamPageItems.sportDetails;
                    const competitions = sports?.find((s) => s.sportId === sportId)?.competitionDetails;
                    if (competitions?.length) {
                        return this.masterdataApi.getCompetitionsAndTeamsCount(competitions).pipe(
                            map((p) => {
                                return p.length > 0;
                            }),
                        );
                    }

                    return of(false);
                }),
            );
        }

        return of(false);
    }

    private isCalendarEnabled(counts: SportIntervalCounts | undefined): boolean {
        return !!(counts?.intervals && Object.keys(counts.intervals).length);
    }

    private getCalendarIntervals(counts: SportIntervalCounts | undefined): string[] {
        if (counts?.intervals) {
            return Object.keys(counts.intervals)
                .filter((key) => key !== 'live')
                .map((key) => counts.intervals[key]!.name);
        }

        return [];
    }
}
