import { OfferSource } from '@cds';
import {
    DisplayType,
    Fixture,
    FixtureType,
    FixtureViewType,
    HybridFixtureData,
    ImageProfile,
    OfferCategory,
    OptionStatus,
    OrderType,
    Parameter,
    ParticipantPriceType,
    Price,
    ResultOrder,
    TeamGrouping,
    TotalsPrefix,
} from '@cds/betting-offer';
import { BetBuilderProvider, FixtureAddons, FixtureForm, TennisSurface } from '@cds/betting-offer/add-ons';
import { Rank, SeasonStandings, StartingPitcher } from '@cds/betting-offer/add-ons/statistics';
import { BettingInsightsModel } from '@cds/betting-offer/betting-insights';
import { BetBuilderOptionGroupInfo } from '@cds/betting-offer/domain-specific/bet-builder';
import { FixtureViewGroupingConfiguration, OfferGroup, Tag } from '@cds/betting-offer/grouping/fixture-view';
import { ScoreboardSlim } from '@cds/scoreboards/v1';
import { PlayerStats } from '@cds/statistics/player';
import { StringDictionary, getSourceEventId } from '@frontend/sports/common/base-utils';
import { NativePrice } from '@frontend/sports/common/odds-lib';
import { CompetitionRecordsFormat } from '@frontend/sports/types/components/statistics';
import { isEmpty } from 'lodash-es';

import { FeedType } from '../../media-api/model';
import { EventScoreboard, ScoreboardType } from './scoreboard.model';

export function getProvider(addons: FixtureAddons | undefined): EventProvider | undefined {
    if (addons) {
        if (addons.playtech) {
            return { id: addons.playtech, type: EventProviderType.Playtech };
        } else if (addons.inspired) {
            return { id: addons.inspired, type: EventProviderType.Inspired };
        }
    }

    return;
}

export function getRankByParticipantType(event: EventModel, participantType?: EventParticipantType): number | undefined {
    if (participantType === EventParticipantType.Home) {
        return event.rank?.homeTeam;
    }

    if (participantType === EventParticipantType.Away) {
        return event.rank?.awayTeam;
    }

    return;
}

export function getPitcherByParticipantType(event: EventModel, participantType?: EventParticipantType): string | undefined {
    let pitcher;
    let pitcherName;
    if (event.startingPitcher?.homeTeam && participantType === EventParticipantType.Home) {
        pitcher = {
            firstName: event.startingPitcher?.homeTeam?.firstName,
            lastName: event.startingPitcher?.homeTeam?.lastName,
            preferredName: event.startingPitcher?.homeTeam?.preferredName,
        };
    }

    if (event.startingPitcher?.awayTeam && participantType === EventParticipantType.Away) {
        pitcher = {
            firstName: event.startingPitcher?.awayTeam?.firstName,
            lastName: event.startingPitcher?.awayTeam?.lastName,
            preferredName: event.startingPitcher?.awayTeam?.preferredName,
        };
    }

    if (pitcher) {
        pitcherName = pitcher.firstName?.charAt(0) + '. ' + pitcher.lastName;
    }

    return pitcherName;
}

export function getWinLossStatsByParticipantType(
    event: EventModel,
    participantType: EventParticipantType,
    competitionRecordsFormat: CompetitionRecordsFormat,
): string | undefined {
    let wonLossStats;
    let winLossStatsFormat;
    if (participantType === EventParticipantType.Home && event.seasonStandings?.homeTeam) {
        wonLossStats = {
            wonMatches: event.seasonStandings?.homeTeam?.wonEvents,
            lossMatches: event.seasonStandings?.homeTeam?.lostEvents,
            drawMatches: event.seasonStandings?.homeTeam?.drawEvents,
            lostEventsOvertime: event.seasonStandings?.homeTeam?.lostEventsOvertime,
        };
    }

    if (participantType === EventParticipantType.Away && event.seasonStandings?.awayTeam) {
        wonLossStats = {
            wonMatches: event.seasonStandings?.awayTeam?.wonEvents,
            lossMatches: event.seasonStandings?.awayTeam?.lostEvents,
            drawMatches: event.seasonStandings?.awayTeam?.drawEvents,
            lostEventsOvertime: event.seasonStandings?.awayTeam?.lostEventsOvertime,
        };
    }

    if (wonLossStats) {
        let competitionRecordFormat = competitionRecordsFormat.competitionOverrides && competitionRecordsFormat.competitionOverrides[event.league.id];

        if (!competitionRecordFormat) {
            competitionRecordFormat = competitionRecordsFormat.default;
        }

        winLossStatsFormat = competitionRecordFormat
            .replace('{{WW}}', wonLossStats.wonMatches ? wonLossStats.wonMatches.toString() : '0')
            .replace('{{LL}}', wonLossStats.lossMatches ? wonLossStats.lossMatches.toString() : '0')
            .replace('-{{DD}}', wonLossStats.drawMatches ? '-' + wonLossStats.drawMatches.toString() : '')
            .replace('-{{LOT}}', wonLossStats.lostEventsOvertime ? '-' + wonLossStats.lostEventsOvertime.toString() : '');
    }

    return winLossStatsFormat;
}

export enum SetTab {
    All = -1,
    BetBuilder = -2,
    MyBets = -3,
    MyMarkets = -4,
    Other = 10000,
    Sgp = -5,
}

export type GameStateType = 'Kilometer' | 'Stage' | 'Lap';

export enum EventParticipantType {
    Away = 0,
    Home = 1,
}

export interface EventProvider {
    id: string;
    type: EventProviderType;
}

export enum EventProviderType {
    Inspired = 'inspired',
    Playtech = 'playtech',
}

export enum SortOrder {
    Ascending = 'asc',
    Descending = 'desc',
}

export interface EventAnimationLogos {
    homeLogo?: string;
    awayLogo?: string;
}

export interface EventParticipant {
    id?: number;
    fixtureParticipantId?: number;
    name: string;
    shortName?: string;
    country?: string;
    countryId?: number;
    shirtColor?: string;
    image?: EventParticipantImage;
    type?: EventParticipantType;
    teamId?: number;
}

export interface ParticipantInfo {
    participant: EventParticipant;
    participantNames: string[];
    isSingleContestant: boolean;
    rank?: number;
    winLossStats?: string;
    pitcher: string;
    redCards?: number[];
}

export interface EventParticipantImage {
    jersey?: string;
    logo?: string;
    rotateJersey?: boolean;
    logoColor?: string;
    darkColor?: string;
    lightColor?: string;
    isParticipantProfile?: boolean;
}

export enum PairGamePeriod {
    Penalties = 9,
    Suspended = 256,
    Finished = 255,
}
export enum ExtendedDisplayType {
    BetBuilder = 'BetBuilder',
    BetBuilderStandard = 'BetBuilderStandard',
    BetBuilderSixPack = 'BetBuilderSixPack',
    BetBuilderOverUnder = 'BetBuilderOverUnder',
    BetBuilderCorrectScore = 'BetBuilderCorrectScore',
    BetBuilderSpread = 'BetBuilderSpread',
    BetBuilderRaceTo = 'BetBuilderRaceTo',
    BetBuilderYesNo = 'BetBuilderYesNo',
    BetBuilderGoalScorer = 'BetBuilderGoalScorer',
    BetBuilderPlayerProps = 'BetBuilderPlayerProps',
    BetBuilderAngstrom = 'BetbuilderAngstrom',
    AutomatedPriceBoost = 'AutomatedPriceBoost',
}

export enum EventDetailsTags {
    PriceBoost = 'PriceBoost',
    PreCreatedBab = 'PreCreatedBAB',
}

export interface EventOptionSet {
    id: number;
    name: string;
    count: number;
    storageId: string;
    tags?: Tag[];
    marketGroupTag?: Tag;
    isPrecreated?: boolean;
}

export interface EventOptionSetHeaderTab extends EventOptionSet {
    optionGroups: EventOptionGroup[];
}

export interface SlimScoreboard {
    started: boolean;
    type?: string;
    scores?: ScoreboardSlim;
    participant1?: any;
    participant2?: any;
    points?: string[];
    setScore?: string;
    participant1WonSets?: number;
    participant2WonSets?: number;
    period?: { id?: number; name: string };
    timerVisible?: boolean;
    messages?: any[];
    time?: any;
    timeIndicator?: string;
    visible?: boolean;
    bookieTicker?: any;
    hasMessage?: any;
    visibleFields?: any;
    gameTimeInSeconds?: number;
    matchType?: any;
    currentlyServing?: number;
    isGeneralTournament?: boolean;
}

export interface CrmPromoIdsMap {
    templateIds: StringDictionary<string>;
    markets: StringDictionary<number>;
    options: StringDictionary<number>;
}

export class ComboPrevention {
    minimum: number;
    restriction: number;
}

export class PlaceTerms {
    numerator: number;
    denominator: number;
    places: number;
}

export class EventSignature {
    event: string;
    league: string;
    market: string;
    option: string;
    region: string;
    sport: string;
}

export class EventOption {
    id: number;
    name: string;
    optionAttribute?: string;
    online: boolean;
    visible: boolean;
    signature: EventSignature;
    playerId?: number;
    teamId?: number;
    /**
     * optionGroupId in context of sportsadmin marketconfig reflects the marketId
     *
     * @type {number}
     * @memberof EventOption
     */
    optionGroupId: number;
    originalEventId?: string;
    order?: number;
    marketAttribute?: string;
    priceId?: number;
    nativePrice: NativePrice;
    templateId?: number;
    boostedPriceId?: number;

    // TODO: remove as it is placed for marquee, should be on a separate model
    overridenName?: string;
    /**
     * optionColumnId reflects the order of the columns configured in sportsadmin (e.g. totals, money line and spread)
     *
     * @type {number}
     * @memberof EventOption
     */
    optionColumnId?: number;
    optionColumnName?: string;
    totalsPrefix?: TotalsPrefix;
    optionSpread?: number;
    player1?: string;
    player2?: string;
    player1Id?: number;
    player2Id?: number;
    longId?: string;
    shortId?: string;
    description?: string;
    boostedPrice?: NativePrice;
    image?: EventParticipantImage;

    //
    /**
     * Id of a player within a team
     *
     * @type {number}
     * @memberof EventOption
     */
    optionPlayerId?: number;
    participantPriceType?: ParticipantPriceType;
    priceHistory?: Price[];
    status?: OptionStatus;
    showBoostedPrice?: boolean;
    optionMarketName?: string;
    /**
     * @description Flags the option as being bet builder enabled, ie can be grouped with other bet buildable markets.
     * NB!: on CDS it's a market level flag, but since in FE we're grouping multiple markets under one group on
     * event details page, we have to keep it on Option level
     */
    isBetBuilder: boolean;
    optionTypes?: string[];
    player1Score?: number;
    player2Score?: number;
}

export class EventOptionGrouping {
    /**
     * used on event details for the option set to group markets
     * into sets (tabs), coming from market grouping configuration
     *
     * @type {string}
     * @memberof EventOptionGrouping
     */
    marketSet: number;
    /**
     * used on event details for the option set to order grouped
     * markets, coming from market grouping configuration
     *
     * @type {number}
     * @memberof EventOptionGrouping
     */
    marketOrder: number;
    /**
     * used on event details for the period tabs
     * comming from market grouping configuration
     *
     * @type {number}
     * @memberof EventOptionGrouping
     */
    periodGroup?: number;
    /**
     * used on event details for to group markets
     * into groups, coming from market grouping configuration
     *
     * @type {number}
     * @memberof EventOptionGrouping
     */
    marketGroup?: number;
    name?: string;

    /**
     * used on event details for fetching help from sitecore
     * comming from market grouping configuration
     *
     * @type {string}
     * @memberof EventOptionGrouping
     */

    marketHelpPath?: string;
    marketTabId?: number;

    optionColumnId?: number;

    displayType: DisplayType | ExtendedDisplayType;

    orderType: OrderType;

    /**
     * represents sportsadmin's internal marketGroup storage id
     * used to find regionalized marketGroup config
     *
     * @type {string}
     * @memberof EventOptionGrouping
     */
    marketGroupId: string;

    /**
     * represents sportsadmin's internal marketGroup item storage id
     * used to find regionalized marketGroup item config
     *
     * @type {string}
     * @memberof EventOptionGrouping
     */
    marketGroupItemId: string;

    tags?: Tag[];

    /**
     * used to decide which stat(s) value of a player should be shown when event is live
     * comming from tv1 market grouping configuration
     */
    playerStatsProps?: string[];

    /**
     * used to show/hide and set default option(all | popular) when enabled
     * comming from tv1 market grouping configuration
     */
    teamGrouping?: TeamGrouping;

    /**
     * represents sportsadmin's internal betbuider precreated option group id
     * used to display different betbuider precreated option groups on event details page
     *
     * @type {string}
     * @memberof EventOptionGrouping
     */
    precreatedGroupId?: string;
}

export enum EventOptionGroupType {
    Regular = 'Regular',
    Period = 'Period',
    MarketTab = 'MarketTab',
    DynamicMarket = 'DynamicMarket',
    SixPack = 'SixPack',
}

export class OptionsToShow {
    v1?: number[];
    v2?: number[];
}

export class EventOptionGroup {
    balanced?: boolean;
    categoryAttribute?: string;
    comboPrevention: ComboPrevention;
    extended?: boolean;
    hidden: boolean;
    id: string;
    originalId: string;
    name: string;
    description?: string;
    position: number;
    fixtureParticipantId?: number;
    linkedSibling?: boolean;
    isEachWay: boolean;
    placeTerms?: PlaceTerms;
    isStartingPriceAvailable: boolean;
    isWinPriceAvailable: boolean;
    templateCategory?: OfferCategory;
    /** set only in case of a V1 Game */
    templateCategoryId?: number;

    /**
     * combined value of the event to be opened for betting and the provided flag by backend
     * if option group is open for betting or not, added redundant with the online flag on the
     * event, for performance reasons
     *
     * @type {boolean}
     * @memberof EventOptionGroup
     */
    online: boolean;
    /**
     * flag provided by backend if option group is open for betting or not
     *
     * @type {boolean}
     * @memberof EventOptionGroup
     */
    visible: boolean;
    optionSpread?: number;
    templateId: string;
    options: EventOption[];
    winning: boolean;
    twoThreeWay?: boolean;
    groupedMarkets?: string[];
    subGroups?: EventOptionGroup[];

    /**
     * Property will be available only for TV1. For TV2 the options come already sorted
     *
     * @type {ResultSorting}
     * @memberof EventOptionGroup
     */
    resultSorting?: ResultSorting;
    parameters?: Parameter[];
    parameterTranslations?: StringDictionary<string>;
    type: EventOptionGroupType;
    detailedGrouping: EventOptionGrouping;
    optionsToShow?: OptionsToShow;

    /**
     * used on the grid, need to have separate one as mapper
     * should work for both event types, grid and detailed event
     *
     * @type {string[]}
     * @memberof EventOptionGroup
     */
    gridGroups?: string[];
    /**
     * used on event details for the period tabs
     * comming from market grouping configuration
     *
     * @type {string}
     * @memberof EventOptionGroup
     */
    periodName?: string;

    player1?: string;
    player2?: string;

    /**
     * Used in OGP betbuilder markets to define whether
     * an additional information panel for multi-pick selection
     * is shown
     *
     * @type {boolean}
     * @memberof EventOptionGroup
     */
    isMultipleSelection?: boolean;
    source?: OfferSource;

    /**
     * Concated option participant ids
     */
    participantIds?: string;

    /**
     * To map option SubPeriodNumber value to group angstrom drive markets.
     */
    subPeriodNumber?: string;

    /**
     * ToDo: To be removed after cds obsolete markettype enums are updated in FE.
     * Used in regular-option-group to identify the dynamic correctscore in angstrom drive markets.
     */
    originalParameters?: Parameter[];

    /**
     * @description Flags the market as being part of betbuilder precreated group.
     */
    isPartOfSgpGroup?: boolean;

    hasSubGroups(type: EventOptionGroupType): boolean {
        return this.subGroups ? this.subGroups.some((s) => s.type === type) : false;
    }

    getSubGroups(type: EventOptionGroupType): EventOptionGroup[] {
        return this.subGroups ? this.subGroups.filter((s) => s.type === type) : [];
    }

    hasGroup(group: string | number): boolean {
        return this.gridGroups?.includes(group.toString()) ?? false;
    }

    hasAnyGroup(): boolean {
        return !isEmpty(this.gridGroups);
    }

    get isBetBuilder(): boolean {
        return this.options.some((o) => o.isBetBuilder);
    }
}

export enum ResultSorting {
    Alphabet = 'Alphabet',
    Default = 'Default',
    Odds = 'Odds',
    Order = 'Order',
}

export function toResultSorting(order: ResultOrder): ResultSorting {
    switch (order) {
        case ResultOrder.Alphabet:
            return ResultSorting.Alphabet;
        case ResultOrder.Default:
            return ResultSorting.Default;
        case ResultOrder.Odds:
            return ResultSorting.Odds;
    }
}

export class LeagueModel {
    id: number;
    name: string;
    sign?: string;
    parentLeagueId?: number;
    combiLeague?: boolean;
    groupId?: number;
    stageId?: number;
    realCompetitionId?: number;
    imageProfile?: ImageProfile;

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
}

/**
 * The Tournament or Region of the current event
 *
 * @export
 * @class RegionModel
 */
export class RegionModel {
    id: number;
    code?: string;
    name: string;
    sign?: string;

    // This maps to the original region coming from the API
    originalRegion?: RegionModel;
}

export class SportModel {
    id: number;
    name: string;
    virtual: boolean;
    sign?: string;
    isEsport?: boolean;
}

export class EventStream {
    id: string;
    provider: string;
    twitchChannel?: string;
    streamUrl?: string;
    feedType?: FeedType;
}

export class LinkedFixture {
    id: string;
    context: string;
}

export interface EventSitemapSet {
    id: string | number;
    groups?: number[];
    children?: EventSitemapSet[];
    name: string;
}

export interface BaseEventModel {
    id: string;
    fixtureScoreboard: ScoreboardSlim;
    scoreboard: EventScoreboard;
    sport: SportModel;
    league: LeagueModel;
    splitFixtureIds?: string[];
    playerStats?: PlayerStats[];
    offerContext?: string[];
    fixtureType: FixtureType;
}

export class EventModel implements BaseEventModel {
    fixtureScoreboard: ScoreboardSlim;
    splitFixtureIds?: string[];
    marketsDictionary: Record<string, EventOptionGroup[]>;

    id: string;
    league: LeagueModel;
    live: boolean;
    name: string;
    nameSign?: string;
    description?: string;
    optionGroups: EventOptionGroup[];
    optionSets: EventOptionSet[];
    sitemapSets?: EventSitemapSet[];
    online: boolean;

    /**
     * If a main and a live event (V1) are grouped, this id represents the group
     *
     * @type {number}
     * @memberof EventModel
     */
    groupId?: number;

    /**
     * V2 correspondent for VirtualCompetitionGroup that we have for V1
     *
     * @type {number}
     * @memberof EventModel
     */
    competitionGroupId?: number;
    participants: EventParticipant[];
    teams: EventParticipant[];
    players: EventParticipant[];
    published: boolean;
    region: RegionModel;
    scoreboard: EventScoreboard;
    sport: SportModel;
    startDate: Date;
    cutOffDate: Date;
    stream?: EventStream;
    liveAlert?: boolean;
    offerSource: OfferSource;
    offerContext?: string[];
    fixtureType: FixtureType;
    statsId?: number;
    surface?: TennisSurface;
    provider?: EventProvider;
    betRadarCoverageLevel?: number;
    betRadarVisualizationId?: number;
    hasFixtureStats: boolean;
    fixtureForm?: FixtureForm;
    rank?: Rank;
    seasonStandings?: SeasonStandings;
    startingPitcher?: StartingPitcher;
    hasLeagueStats: boolean;
    viewType: FixtureViewType;
    gamesCount?: number;
    runningBallDataId?: string;
    betBuilderFixtureId?: string;
    sportcastId?: number;
    betBuilderTradingV2FixtureId?: string;
    totalMarketsCount: number;
    winGameLinkedFixture?: Fixture;
    imgEventId?: number;
    showParticipant?: boolean;
    priceBoostCount?: number;
    isPriceBoosted?: boolean;
    bettingInsights?: BettingInsightsModel;
    linkedFixture?: EventModel;
    hybridFixtureData?: HybridFixtureData;
    tv2RelatedFixtureId?: string;
    offerCategories: OfferCategory[];
    /**
     * @description Provider(agnostic) specific fixture identifier.
     */
    betBuilderInfo: BetBuilderInfo | null;
    playerStats?: PlayerStats[];

    precreatedOptionGroups?: BetBuilderOptionGroupInfo[];
    constructor() {
        this.optionGroups = [];
        this.optionSets = [];
        this.participants = [];
        this.players = [];
        this.scoreboard = new EventScoreboard();
        this.scoreboard.started = false;
        this.published = true;
        this.marketsDictionary = {};
    }

    isSetGame(): boolean {
        return this.isGameOfType(ScoreboardType.SetGame);
    }

    isTournamentGame(): boolean {
        return this.isGameOfType(ScoreboardType.TournamentGame) || this.fixtureType === FixtureType.Tournament;
    }

    isPairGame(): boolean {
        return this.isGameOfType(ScoreboardType.PairGame) && this.participants.length === 2;
    }

    isBaseballGame(): boolean {
        return this.isGameOfType(ScoreboardType.BaseballGame);
    }

    isPeriodGame(): boolean {
        return this.isGameOfType(ScoreboardType.PeriodGame);
    }

    isLeaderboardGame(): boolean {
        return this.isGameOfType(ScoreboardType.LeaderboardGame);
    }

    isStarted(): boolean {
        const diff = this.startDate.getTime() - Date.now();

        return diff <= 0 || this.scoreboard.started;
    }

    isFinished(): boolean {
        return !!this.scoreboard.period && this.scoreboard.period.id === PairGamePeriod.Finished;
    }

    getBestPerformingParticipant(): EventParticipantType | undefined {
        if (this.fixtureForm) {
            if (this.fixtureForm.homeTeamHomeForm) {
                return EventParticipantType.Home;
            }
            if (this.fixtureForm.awayTeamAwayForm) {
                return EventParticipantType.Away;
            }
            if (this.fixtureForm.overallHomeTeamForm || this.fixtureForm.overallAwayTeamForm) {
                if ((this.fixtureForm.overallHomeTeamForm?.winCount || 0) >= (this.fixtureForm.overallAwayTeamForm?.winCount || 0)) {
                    return EventParticipantType.Home;
                }

                return EventParticipantType.Away;
            }
        }

        return undefined;
    }

    isBestPerformingOption(option: EventOption, optionGroup: EventOptionGroup): boolean {
        const optionName = option.overridenName;

        if (optionGroup.winning && optionName !== 'X') {
            const bestOption = (participant: EventParticipantType | undefined) => {
                if (participant === undefined) {
                    return false;
                }
                if (this.viewType === FixtureViewType.American) {
                    return participant === EventParticipantType.Home ? '2' : '1';
                }

                return participant === EventParticipantType.Home ? '1' : '2';
            };

            if (optionName === bestOption(this.getBestPerformingParticipant())) {
                return true;
            }
        }

        return false;
    }

    getBetBuilderConsumerFixtureId(): string | undefined {
        return this.betBuilderFixtureId || getSourceEventId(this.betBuilderTradingV2FixtureId ?? '');
    }

    getBetBuilderIdByStage(): string | null {
        // NOTE: BetBuilderProvider.Default corresponds to Sportscast and that flow is stil based on legacy Addons.SportcastId. See cleanup SPO-101062
        const betBuilderId =
            !this.live && this.betBuilderInfo?.betBuilderId && this.betBuilderInfo?.betBuilderProvider !== BetBuilderProvider.Default
                ? this.betBuilderInfo?.betBuilderId
                : null;
        return betBuilderId;
    }

    private isGameOfType(type: ScoreboardType): boolean {
        return this.scoreboard.type === type;
    }
}

export interface MyMarketModel {
    live: SportOptionGroup | SportOptionGroupOrder;
    prematch: SportOptionGroup | SportOptionGroupOrder;
}

export interface SportOptionGroup {
    [id: string]: OptionGroup[];
}

export interface SportOptionGroupOrder {
    [id: number]: number[];
}

export interface OptionGroup {
    market_group_id: number;
    tv1_market_group_item: number;
    position: number;
}

export interface VerticalDesignOptions {
    isVerticalDesignEnabled: boolean;
    showOptionAttributeDecorator: boolean;
    showOptionName: boolean;
}

export interface EnhancedFixtureViewGroupingConfiguration extends FixtureViewGroupingConfiguration {
    apbGroups: OfferGroup[];
    preCreatedGroups: OfferGroup[];
}

/**
 * Market Options which should not be aligned center, values are from Sportcast.
 */
export enum MarketOptionType {
    WhoWillScore = 6,
    WhoWillGetCarded = 7,
    DoubleChance = 16,
    HowWillaTeamWin = 26,
    WhatElseWillHappen = 28,
}
export enum BetBuilderRowOfOptions {
    Single = 1,
}
export enum BetBuilderNoOfColumns {
    Double = 2,
    Triple = 3,
}

export const PRECREATED_BAB_EMPTY_THEME_ID = -1;

export enum TeamGroupingValues {
    Default,
    AscendingOdds,
}

export enum OddsSorting {
    AscendingOrder,
    DescendingOrder,
}

export interface BetBuilderInfo {
    betBuilderId: string;
    betBuilderProvider: BetBuilderProvider;
}

export enum OptionTypes {
    NotExists = 'NotExists',
    Equals = 'Equals',
    ToHappen = 'ToHappen',
    NotToHappen = 'NotToHappen',
    Max = 'Max',
}

export enum CorrectScoreHeaderButtonTabs {
    CorrectScoreCompactView = 'CorrectScoreCompactViewTitle',
    CorrectScoreRegularView = 'CorrectScoreRegularViewTitle',
}
