import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { WidgetContext } from '@frontend/sports/types/components/widget';

import { TabBarItem, TabBarMode } from '../../tab-bar/tab-bar.models';

export enum WidgetTabBarDisplay {
    Default,
    Icon,
    Logo,
}

export interface WidgetTabBarItem extends TabBarItem<string> {
    display: WidgetTabBarDisplay;
    context: Partial<WidgetContext>;
    itemId: string | number;
    sportId?: number;
    widgetId: string;
}

@Component({
    selector: 'ms-widget-tab-bar',
    templateUrl: './widget-tab-bar.component.html',
})
export class WidgetTabBarComponent {
    @HostBinding('class') className = 'widget-tab-bar';

    @Input() mode = TabBarMode.Tab;
    @Input() tabs: WidgetTabBarItem[];
    @Input() stretch = false;
    @Output() select = new EventEmitter<WidgetTabBarItem>();

    display = WidgetTabBarDisplay;
}
