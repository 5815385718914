import { Component, Input } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { CrmPromotionsBanners } from '@frontend/sports/types/components/sitecore';

import { OfferStatus, Promotion } from '../crm-offer-data/crm-offer.model';
import { TrackingSource } from '../tracking/tracking.models';
import { OfferStatusService } from './offer-status.service';
import { CTALocation, CrmPromotionTracking } from './tracking.service';

@Component({
    selector: 'ms-crm-promotion-banner-more-info',
    templateUrl: './banner-more-info.html',
})
export class BannerMoreInfoComponent {
    tncVisible = true;
    offersStatusEnum = OfferStatus;
    translation: CrmPromotionsBanners;

    @Input() promotion: Promotion;
    @Input() tracking?: TrackingSource;

    constructor(
        public sitecore: Sitecore,
        private offerStatusService: OfferStatusService,
        private trackingService: CrmPromotionTracking,
    ) {
        this.translation = sitecore.crmPromotionsBanners;
    }

    toggleTnc(): void {
        this.tncVisible = !this.tncVisible;
    }

    onButtonClick(event: Event): void {
        event.stopPropagation();

        this.trackingService.trackCTA({
            promotion: this.promotion,
            trackingSource: this.tracking?.source,
            ctaLocation: CTALocation.MoreDetails,
            position: this.tracking?.position,
        });

        this.offerStatusService.setOfferStatus(this.promotion).subscribe((response) => {
            this.promotion.status = response;
        });
    }
}
