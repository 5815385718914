import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { CouponTypes } from '@frontend/sports/common/coupons-lib';
import { now, times } from 'lodash-es';

import { EventModel } from '../event-model/model/event.model';
import { SubscriptionTopic } from '../event-subscription/base-subscription.service';
import { OptionGroupService } from '../option-group/option-group.service';
import { Column, GridGrouping, GridSorting, Group, GroupBadge } from './grid.model';
import { ObservableGridProvider } from './observable-grid.provider';

export interface GridOption {
    activeGrouping?: number | string;
    marketGrouping?: boolean;
    moreGrouping?: boolean;
    grouping?: GridGrouping;
    disableGroupSorting?: boolean;
    sorting?: GridSorting;
    collapsedThreshold?: number;
    subscriptionTopic?: SubscriptionTopic;
    groupOrder?: string[];
    badges?: Record<string, GroupBadge>;
    includeRetailGroups?: boolean;
    excludeDefaultMainMarket?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ObservableGridCustomColumnProvider extends ObservableGridProvider {
    constructor(
        protected override sitecore: Sitecore,
        protected optionGroupService: OptionGroupService,
    ) {
        super(sitecore);
    }

    getCustomColumns(couponType: CouponTypes, options?: GridOption, events?: EventModel[]): Column[] {
        const periodGroups = this.optionGroupService.getPeriodGroupsFromEvents(couponType, events);
        const count = couponType === CouponTypes.GoalScorer ? 1 : Math.min(4, periodGroups.length);
        if (count === 0) {
            return options?.excludeDefaultMainMarket ? [] : [this.getBaseColumn()];
        }

        return times(count).map((current, index) => this.getCustomColumn(periodGroups, index, options));
    }

    private getCustomColumn(source: Group[], active: number, options?: GridOption) {
        let mapped: Group[];

        if (source.length === 0) {
            mapped = [this.getBaseGroup()];
        } else {
            mapped = source.map((group, index) => this.getPeriodGroup(group, index === active, options && options.badges));
        }

        return {
            id: (now() + active).toString(32),
            groups: mapped,
            enabled: true,
            more: !!options?.moreGrouping,
        };
    }

    private getPeriodGroup(group: Group, active: boolean, badges?: Record<string, GroupBadge>): Group {
        return {
            id: group.id,
            name: group.name,
            active,
            extended: false,
            visible: true,
            badge: badges && badges[group.id],
            scoreBoardPeriodId: group.scoreBoardPeriodId,
            isFallbackGroup: group.isFallbackGroup,
            fallbackGridGroupIds: group.fallbackGridGroupIds,
        };
    }
}
