import { Inject, Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { betslipSelector } from '../base/store/selectors';
import { IBetslipRootState } from '../base/store/state';
import { BetslipState } from '../core/betslip-state';
import { BetslipType } from '../core/betslip-type';
import { ExternalBetslipActions, PickAddPayload } from '../core/external-betslip-actions';
import { BetslipModuleLoaderService } from '../integration/betslip-module-loader.service';
import { EditBetActions } from '../modules/edit-bet/actions';
import { BetslipResultActions } from '../modules/result/actions';
import { BetslipTypesActions } from '../modules/types/actions';
import { BETSLIP_MODULE_LOADER_SERVICE } from './sports-injection-services';

@Injectable()
export class ExternalBetslipEffects {
    constructor(
        private actions$: Actions,
        private store: Store<IBetslipRootState>,
        @Inject(BETSLIP_MODULE_LOADER_SERVICE) private betslipLoaderModule: BetslipModuleLoaderService,
    ) {}

    closeResultOnAddPickEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ExternalBetslipActions.addPick,
                ExternalBetslipActions.addMultiplePicks,
                ExternalBetslipActions.addBetBuilderPick,
                ExternalBetslipActions.addEntainUiBetBuilderPick,
                ExternalBetslipActions.requestAddAffiliatePicks,
                ExternalBetslipActions.addGroupPick,
            ),
            switchMap(() => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    withLatestFrom(this.store.select(betslipSelector)),
                    filter(([_, currentState]) => currentState.base.state === BetslipState.Result),
                    concatMap(([_, currentState]) => [
                        BetslipTypesActions.setSelectedType({ betslipType: null, selectedByUser: false, systemInfo: null }),
                        BetslipTypesActions.setActiveTypes({ betslipTypes: [] }),
                        currentState.types.base.currentSelectedType === BetslipType.EditBet
                            ? EditBetActions.exitEditBet()
                            : BetslipResultActions.close(),
                    ]),
                );
            }),
        );
    });

    handleAddPickActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.addPick, ExternalBetslipActions.echoAddPick),
            switchMap((action) => {
                return this.getRequestAddPickActions([action]);
            }),
        );
    });

    handleAddPicksActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.addMultiplePicks),
            switchMap((action) => {
                return this.getRequestAddPickActions(action.picks);
            }),
        );
    });

    handleAddGroupPickActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.addGroupPick),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.requestAddGroupPick({ payload: action.picks, headerTemplate: action.headerTemplate });
                    }),
                );
            }),
        );
    });

    handleAddBetBuilderPickActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.addBetBuilderPick, ExternalBetslipActions.echoAddBetBuilderPick),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.requestAddBetBuilderPick({
                            pickId: action.pickId,
                            tracking: action.tracking,
                            eventData: action.eventData,
                            additionalInfo: action.additionalInfo,
                        });
                    }),
                );
            }),
        );
    });

    handleAddEntainUiBetBuilderPickActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.addEntainUiBetBuilderPick),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.requestAddEntainUiBetBuilderPick({
                            pickId: action.pickId,
                            tracking: action.tracking,
                            eventData: action.eventData,
                            eventModel: action.eventModel,
                            market: action.betBuilderOptionMarket,
                            longId: [action.longId],
                        });
                    }),
                );
            }),
        );
    });

    handleRemoveEntainUIPickActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.removeEntainUiBetBuilderPick),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.requestRemoveEntainUiBetBuilderPick({
                            pickId: action.pickId,
                            longId: action.longId,
                            eventId: action.eventId,
                        });
                    }),
                );
            }),
        );
    });

    handleCheckPrecreatedBABConversion$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.checkPrecreatedBABConversion),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.checkPrecreatedBABConversion({
                            pickId: action.pickId,
                            eventData: action.eventData,
                            eventModel: action.eventModel,
                            market: action.market,
                            longId: action.longId,
                            headerTemplate: action.headerTemplate,
                        });
                    }),
                );
            }),
        );
    });

    handleRemovePickActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.removePick, ExternalBetslipActions.echoRemovePick),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.requestRemovePick({ pickId: action.pickId });
                    }),
                );
            }),
        );
    });

    handleRemovePicksFromGroupActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.removePicksFromGroup),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.requestRemovePicksFromGroup({ pickIds: action.pickIds });
                    }),
                );
            }),
        );
    });

    handleAffiliateRequestActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.requestAddAffiliatePicks),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.addAffiliatePicks(action);
                    }),
                );
            }),
        );
    });

    handleAffiliateShareBetRequestActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.shareBet),
            switchMap(() => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.shareAffiliatePicks();
                    }),
                );
            }),
        );
    });

    handleRemoveMultiplePicksActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.removeMultiplePicks, ExternalBetslipActions.echoRemoveMultiplePicks),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.requestRemoveMultiplePicks({ pickIds: action.pickIds });
                    }),
                );
            }),
        );
    });

    handleStartEditMyBetActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.startEditMyBet),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.EditBetActions.startEditMyBet({ payload: action.payload });
                    }),
                );
            }),
        );
    });

    handleNotificationSettingUpdateActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.requestNotificationSettingUpdate),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.SettingsActions.updateNotificationSettings(action);
                    }),
                );
            }),
        );
    });

    private getRequestAddPickActions(payload: PickAddPayload[]) {
        return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
            mergeMap((module) => [
                ...payload.map((y) =>
                    module.BetslipPickActions.requestAddPick({
                        pickId: y.pickId,
                        tracking: y.tracking,
                        priceId: y.priceId,
                        isPriceBoosted: y.isPriceBoosted,
                        groupInfo: y.groupInfo,
                        parentLinkedEventId: y.parentLinkedEventId,
                        isBetBuilderEnabled: y.isBetBuilder ?? false,
                    }),
                ),
                module.RewardTokensActions.closeDialog(),
            ]),
        );
    }

    handleAddWebAppPickActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.addWebAppPick),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.addWebAppPick(action);
                    }),
                );
            }),
        );
    });

    handleRemoveWebAppPickActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.removeWebAppPick),

            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.removeWebAppPick(action);
                    }),
                );
            }),
        );
    });

    handleSetRewardTokenActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.setBetstationFreeBetToken),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.RewardTokensActions.setBetstationFreeBetToken(action);
                    }),
                );
            }),
        );
    });

    handleToggleWinPickActionEffect$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ExternalBetslipActions.toggleWinPick),
            switchMap((action) => {
                return from(this.betslipLoaderModule.loadBetslipModule()).pipe(
                    map((module) => {
                        return module.BetslipPickActions.toggleWinPick({ pickId: action.pickId });
                    }),
                );
            }),
        );
    });
    //
    // private loadPickFromApi(payload: IAddPickPayload): Observable<Action> {
    // 	const pickId = pickIdFactory(payload.pickId);
    // 	const effect = this;
    // 	const request = this.betslipPicksRequestBuilder.buildRequestFromPickId([pickId]);
    // 	return from(this.bettingOffer.getPicks(request)).pipe(
    // 		filter(fp => Boolean(fp && fp.fixtures.length)),
    // 		concatMap(function* (response: FixturePage | undefined): Iterable<Action> {
    // 			const fixture = response!.fixtures[0];
    // 			if (fixture) {
    // 				let priceId;
    // 				if (payload.extra.type === AddPickPayloadType.Race) {
    // 					priceId = payload.extra.priceId;
    // 				}
    // 				const betslipPick = effect.pickFactory.createPick(pickId, fixture, null, payload.extra.sourceOfBet, priceId);
    // 				if (!!betslipPick) {
    // 					yield PickActions.addedPick({ pick: betslipPick, pickId: betslipPick.id.toString(), priceId });
    // 				}
    // 			}
    // 		})
    // 	);
    // }
}
