import { Injectable } from '@angular/core';

import { NativeAppService, OnAppInit, UrlService } from '@frontend/vanilla/core';

import { ArcRestrictionService } from '../arc/arc-restriction.service';
import { CookieProvider } from '../cookie-provider/cookie-provider.service';
import { ExternalClient, TrackExternalClient, trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';

@Injectable({ providedIn: 'root' })
export class ExternalNativeAppService implements OnAppInit {
    private channels: { [key: string]: string } = {};
    hideGamingOverlayByArc: boolean;
    constructor(
        private urlService: UrlService,
        private cookieProvider: CookieProvider,
        private trackingService: TrackingService,
        private nativeAppService: NativeAppService,
        private arcRestrictionService: ArcRestrictionService,
    ) {
        this.channels['desktoplobby'] = 'desktop lobby';
        this.channels['desktoptable'] = 'desktop table';
        this.channels['mobilelobby'] = 'mobile lobby';
        this.arcRestrictionService.gamingCrossSellEnabled$.subscribe((state) => {
            this.hideGamingOverlayByArc = state;
        });
    }

    onAppInit(): void | Promise<any> {
        const parsedUrl = this.urlService.current();
        const invokeModule = parsedUrl.search.get('invokeModule');

        if (this.nativeAppService.applicationName) {
            if (invokeModule != null) {
                this.cookieProvider.setCookie('invokeModule', invokeModule, { expires: 1 });
            }
            const trackingData = this.getTrackingData(this.nativeAppService.applicationName, invokeModule!);
            this.trackingService.update(trackingData);
        }
    }

    private getTrackingData(value: string, channel: string): Partial<TrackExternalClient> {
        switch (value.toLowerCase()) {
            case ExternalClient.Wrapper:
                return {
                    [trackingConstants.INVOKE_PRODUCT]: 'sports to sports',
                    [trackingConstants.INVOKE_INTERFACE]: '',
                };
            case ExternalClient.PokerNativeApp:
            case ExternalClient.PokerDesktopClient:
            case ExternalClient.PokerMac:
                return {
                    [trackingConstants.INVOKE_PRODUCT]: 'poker to sports',
                    [trackingConstants.INVOKE_INTERFACE]: this.channels[channel],
                };
            default:
                return {};
        }
    }

    isPokerNativeApp(): boolean {
        return this.nativeAppService.applicationName?.toLowerCase() === 'poker';
    }
}
