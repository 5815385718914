import { Injectable } from '@angular/core';

import { OnAppInit } from '../bootstrap/bootstrapper.service';
import { vanillaAppExport } from '../utils/vanilla-app-export';
import { DslEnvService } from './dsl-env.service';
import { DslEnvExecutionMode } from './dsl.models';

@Injectable()
export class DslBootstrapService implements OnAppInit {
    constructor(private dslEnvService: DslEnvService) {}

    onAppInit() {
        this.dslEnvService.registerNotReadyDslsProviders([
            'Affordability',
            'Balance',
            'BonusBalance',
            'BonusAbuserInformation',
            'ConnectedAccounts',
            'CurfewStatus',
            'DepositLimits',
            'Geolocation',
            'KycStatus',
            'MohDetails',
            'PlayBreak',
            'PlayerLimits',
            'SelfExclusion',
            'SessionFundSummary',
            'SofStatusDetails',
            'TourneyTokenBalance',
            'UserFlags',
            'UserScrub',
            'UserSummary',
            'Offer',
            'BonusAward',
            'Gamification',
        ]);

        vanillaAppExport('diagnostics', 'evaluateDsl', (expression: string) => {
            return this.dslEnvService.run(expression);
        });

        vanillaAppExport('diagnostics', 'executeDsa', (action: string) => {
            return this.dslEnvService.run(action, DslEnvExecutionMode.Action);
        });
    }
}
