import { DecisionSource } from '@bpos/v1/over-ask';
import { createAction, props } from '@ngrx/store';

import BetslipActions from '../../base/store/actions';

export class BetslipResultActions {
    static readonly ACTION_SCHEMA = `${BetslipActions.ACTION_SCHEMA}/result`;

    private static readonly CLOSE = `${BetslipResultActions.ACTION_SCHEMA}/CLOSE`;

    private static readonly GO_TO_MY_BETS = `${BetslipResultActions.ACTION_SCHEMA}/GO_TO_MY_BETS`;

    private static readonly CLEAR_RESULT = `${BetslipResultActions.ACTION_SCHEMA}/CLEAR_RESULT`;

    private static readonly KEEP_PLACED_BETS_IN_BETSLIP = `${BetslipResultActions.ACTION_SCHEMA}/KEEP_PLACED_BETS_IN_BETSLIP`;

    private static readonly SHOW_BET_STATION_RESULT = `${BetslipResultActions.ACTION_SCHEMA}/SHOW_BET_STATION_RESULT`;

    private static readonly DECISION_SOURCE = `${BetslipResultActions.ACTION_SCHEMA}/IS_DECISION_SOURCE_AUTO`;

    private static readonly KEEP_PLACED_BETS_IN_LINEAR_SINGLE = `${BetslipResultActions.ACTION_SCHEMA}/KEEP_PLACED_BETS_IN_LINEAR_SINGLE`;

    private static readonly KEEP_PLACED_BETS_IN_LINEAR_COMBO = `${BetslipResultActions.ACTION_SCHEMA}/KEEP_PLACED_BETS_IN_LINEAR_COMBO`;

    private static readonly KEEP_PLACED_BETS_IN_LINEAR_SYSTEM = `${BetslipResultActions.ACTION_SCHEMA}/KEEP_PLACED_BETS_IN_LINEAR_SYSTEM`;

    private static readonly KEEP_PLACED_BETS_IN_LINEAR_BET_BUILDER = `${BetslipResultActions.ACTION_SCHEMA}/KEEP_PLACED_BETS_IN_LINEAR_BET_BUILDER`;

    private static readonly KEEP_PLACED_BETS_IN_LINEAR_TEASER = `${BetslipResultActions.ACTION_SCHEMA}/KEEP_PLACED_BETS_IN_LINEAR_TEASER`;

    /**
     * Called when closing result screen, e.g. navigate to my bets from result.
     */
    static close = createAction(BetslipResultActions.CLOSE);

    static goToMyBets = createAction(BetslipResultActions.GO_TO_MY_BETS);

    static keepPlacedBetsInBetslip = createAction(BetslipResultActions.KEEP_PLACED_BETS_IN_BETSLIP, props<{ keepPlacedBetsInBetslip: boolean }>());

    static keepPlacedBetsInLinearSingle = createAction(
        BetslipResultActions.KEEP_PLACED_BETS_IN_LINEAR_SINGLE,
        props<{ keepPlacedBetsInLinearSingle: boolean }>(),
    );

    static keepPlacedBetsInLinearCombo = createAction(
        BetslipResultActions.KEEP_PLACED_BETS_IN_LINEAR_COMBO,
        props<{ keepPlacedBetsInLinearCombo: boolean }>(),
    );

    static keepPlacedBetsInLinearSystem = createAction(
        BetslipResultActions.KEEP_PLACED_BETS_IN_LINEAR_SYSTEM,
        props<{ systemKey: string; keepPlacedBetsInLinearSystem: boolean }>(),
    );

    static keepPlacedBetsInLinearBetBuilder = createAction(
        BetslipResultActions.KEEP_PLACED_BETS_IN_LINEAR_BET_BUILDER,
        props<{ groupId: string; keepPlacedBetsInLinearBetBuilder: boolean }>(),
    );

    static keepPlacedBetsInLinearTeaser = createAction(
        BetslipResultActions.KEEP_PLACED_BETS_IN_LINEAR_TEASER,
        props<{ keepPlacedBetsInLinearTeaser: boolean }>(),
    );

    static decisionSource = createAction(
        BetslipResultActions.DECISION_SOURCE,
        props<{ decisionSource?: DecisionSource; isOfferModified: boolean }>(),
    );

    /**
     * Show modal on Betstation eg: Bet Placed. Would you like to retain your selection(s)?
     */
    static showBetStationResult = createAction(BetslipResultActions.SHOW_BET_STATION_RESULT);

    static clearResult = createAction(BetslipResultActions.CLEAR_RESULT);
}
