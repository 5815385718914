import { BetslipError, CleanStrategy, ErrorOrigin } from '../betslip-error';

export interface PreCheckErrorMessages {
    AllowedStakes: string;
    ComboPrevention: string;
    ComboPreventionSameEvent: string;
    ForecastSystemBet: string;
    FreeBetAdded: string;
    minComboBetsCount: string;
    MinimumCombo: string;
    MinStakePerBet: string;
    MinStakeTotal: string;
    MinStakeValue: string;
    minSystemBetsCount: string;
    BetbuilderSystemNotAllowed: string;
    MultipleSinglesNotAllowed: string;
    NotEnoughMoney: string;
    NotMultiple: string;
    PickAdded: string;
    PickAlreadyAdded: string;
    PickNotFound: string;
    PickRemoved: string;
    PicksMaximum: string;
    LessStakeAfterTax: string;
    SelectExactRaceSystemBets: string;
    SelectMaxComboBets: string;
    SelectMaxRaceComboBets: string;
    SelectMaxSystemBets: string;
    SelectMinComboBets: string;
    SelectMinComboBetsLinear: string;
    SelectMinSingleBets: string;
    SelectMinSystemBets: string;
    SelectMaxSystemBetsBanker: string;
    SelectMinSystemBetsBanker: string;
    SelectMaxTeaserBets: string;
    MinTeaserBetsCount: string;
    SelectMinTeaserBets: string;
}

// We initially used the message key directly matching an error to a specific message type, this means we couldn't have specific messages for linear
// Solution:
// - New abstraction here (ErrorDetailSpecifier)
// - Resolve the ErrorDetailSpecifier to a Message Key in a later step
export enum ErrorDetailsSpecifier {
    MinComboBetsCount,
    MinSystemBetsCount,
    MinTeaserBetsCount,
    PicksMaximum,
    SelectMaxComboBets,
    SelectMaxRaceComboBets,
    SelectMaxSystemBets,
    SelectMinComboBets,
    SelectMinSingleBets,
    SelectMinSystemBets,
    SelectMaxSystemBetsBanker,
    SelectMinSystemBetsBanker,
    SelectMinTeaserBets,
    SelectMaxTeaserBets,
    ForecastSystemBet,
}

export interface IPickPreCheckError {
    pickId: string;
}

interface TClassType<T = {}> {
    new (...args: any[]): T;
    prototype: T;
}

export function PreCheckErrorMixin<TBaseClass extends TClassType<BetslipError> = TClassType<BetslipError>>(
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    BaseErrorType: TBaseClass,
): TBaseClass {
    return class extends BaseErrorType {
        constructor(...args: any[]) {
            super(...args);
            this.origin = ErrorOrigin.BetSlip;
            this.cleanStrategy = CleanStrategy.Always;
            this.hasClientValidation = true;
        }
    };
}
