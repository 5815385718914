import { TemplateRef } from '@angular/core';

import { OddsAcceptanceMode } from '@bpos';
import { OfferSource } from '@cds';
import { Fixture } from '@cds/betting-offer';
import { PicksView } from '@cds/betting-offer/domain-specific';
import { pprops } from '@frontend/sports/common/base-utils';
import { createAction, props } from '@ngrx/store';
import { BetBuilderMarket } from 'packages/sports/web/app/src/betbuilder/model/bet-builder.model';
import { EventModel } from 'packages/sports/web/app/src/event-model/model/event.model';
import { OptionRequest } from 'packages/sports/web/app/src/navigation-core/pick-uri.model';

import { EditMyBetPicksPayload } from '../model/edit-mybet/edit-bet-init';
import { BetStationFreeBetToken } from '../modules/reward-tokens/reward-tokens.model';
import { BetslipType } from './betslip-type';
import { BetslipGroupInformation } from './groups/betslip-group-information';
import { BetBuilderPickId, PickId, V1PickId, V2OptionMarketPickId, V2OptionMarketXCastPickId, V2ParticipantPickId } from './picks/pick-id';
import { IPickTracking } from './picks/pick-models';

export interface BetBuilderV1EventData {
    event: {
        id: string;
        name: string;
        date: Date;
        isLive?: boolean;
        groupId?: number;
    };
    league: {
        id: number;
        name: string;
        parentLeagueId?: number;
        realCompetitionId?: number;
    };
    region: {
        id: number;
        name: string;
    };
    offerSource: OfferSource;
    betBuilderTradingV2FixtureId?: string;
}

export interface PickAddPayload {
    pickId: V1PickId | V2OptionMarketPickId | V2ParticipantPickId | V2OptionMarketXCastPickId;
    tracking: IPickTracking; // Pick source tracking information
    priceId?: number; // In case when pick can have multiple prices the call can choose which one to be selected ( optional )
    isPriceBoosted?: boolean;
    groupInfo?: BetslipGroupInformation;
    parentLinkedEventId?: string;
    isBetBuilder?: boolean;
}
export interface PickRemovePayload {
    pickId: V1PickId | V2OptionMarketPickId | V2ParticipantPickId;
    tracking: IPickTracking; // Pick source tracking information
    priceId?: number; // In case when pick can have multiple prices the call can choose which one to be selected ( optional )
    isPriceBoosted?: boolean;
}

export interface BABAdditionalInfo {
    sportcastId?: number;
    betgeniusId?: number;
    useV2Key?: boolean;
    longIds?: string[];
}

export enum BetslipHost {
    Digital = 'Digital',
    BetStation = 'BetStation',
}

export class ExternalBetslipActions {
    static readonly ACTION_SCHEMA = `PICKS`;

    private static readonly ADD_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/ADD_PICK`;
    private static readonly ADD_GROUP_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/ADD_GROUP_PICK`;
    private static readonly ADD_MULTIPLE_PICKS = `${ExternalBetslipActions.ACTION_SCHEMA}/ADD_MULTIPLE_PICKS`;
    private static readonly ADD_BET_BUILDER_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/ADD_BET_BUILDER_PICK`;
    private static readonly ECHO_ADD_BET_BUILDER_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/ADDED_BET_BUILDER_PICK`;
    private static readonly ECHO_ADD_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/ADDED_PICK`;
    private static readonly ADD_ENTAIN_UI_BET_BUILDER_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/ADD_ENTAIN_UI_BET_BUILDER_PICK`;
    private static readonly REMOVE_ENTAIN_UI_BET_BUILDER_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/REMOVE_ENTAIN_UI_BET_BUILDER_PICK`;
    private static readonly CHECK_PRECREATED_BAB_CONVERSION = `${ExternalBetslipActions.ACTION_SCHEMA}/CHECK_PRECREATED_BAB_CONVERSION`;

    private static readonly REMOVE_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/REMOVE_PICK`;
    private static readonly ECHO_REMOVE_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/REMOVED_PICK`;
    private static readonly REMOVE_MULTIPLE_PICKS = `${ExternalBetslipActions.ACTION_SCHEMA}/REMOVE_MULTIPLE_PICKS`;
    private static readonly ECHO_REMOVE_MULTIPLE_PICKS = `${ExternalBetslipActions.ACTION_SCHEMA}/REMOVED_MULTIPLE_PICKS`;
    private static readonly REMOVE_PICKS_FROM_GROUP = `${ExternalBetslipActions.ACTION_SCHEMA}/REMOVE_PICKS_FROM_GROUP`;

    private static readonly REQUEST_BETSLIP_TYPE = `${ExternalBetslipActions.ACTION_SCHEMA}/REQUEST_BETSLIP_TYPE`;

    private static readonly REQUEST_ADD_AFFILIATE_PICKS = `${ExternalBetslipActions.ACTION_SCHEMA}/REQUEST_ADD_AFFILIATE_PICKS`;
    private static readonly SHARE_AFFILIATE_PICKS = `${ExternalBetslipActions.ACTION_SCHEMA}/SHARE_AFFILIATE_PICKS`;
    private static readonly START_EDIT_MY_BET = `${ExternalBetslipActions.ACTION_SCHEMA}/START_EDIT_MY_BET`;

    private static readonly REQUEST_NOTIFICATION_SETTING_UPDATE = `${ExternalBetslipActions.ACTION_SCHEMA}/REQUEST_NOTIFICATION_SETTING_UPDATE`;

    private static readonly ADD_WEB_APP_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/ADD_WEB_APP_PICK`;
    private static readonly REMOVE_WEB_APP_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/REMOVE_WEB_APP_PICK`;

    private static readonly SET_REWARD_TOKEN = `${ExternalBetslipActions.ACTION_SCHEMA}/SET_REWARD_TOKEN`;
    private static readonly TOGGLE_WIN_PICK = `${ExternalBetslipActions.ACTION_SCHEMA}/TOGGLE_WIN_PICK`;

    /**
     * Action called with request to add v1 or option market pick to betslip,
     * Optionally set stake value when first pick to override the default stake.
     */
    static addPick = createAction(ExternalBetslipActions.ADD_PICK, props<PickAddPayload & { stake?: number }>());

    /**
     * Action called with request to add several v1 or option market picks in a SGP group to betslip
     */
    static addGroupPick = createAction(
        ExternalBetslipActions.ADD_GROUP_PICK,
        props<{ picks: PickAddPayload[]; headerTemplate?: TemplateRef<any> }>(),
    );

    /**
     * Action called with request to add v1 or option market pick to betslip
     */

    static addMultiplePicks = createAction(ExternalBetslipActions.ADD_MULTIPLE_PICKS, props<{ picks: PickAddPayload[]; stake?: number }>());
    /**
     * Action called with request to add bet builder pick to betslip, when v1
     */
    static addBetBuilderPick = createAction(
        ExternalBetslipActions.ADD_BET_BUILDER_PICK,
        props<{ pickId: BetBuilderPickId; tracking: IPickTracking; eventData: BetBuilderV1EventData | null; additionalInfo?: BABAdditionalInfo }>(),
    );

    /**
     * Action called with request to add entain ui bet builder pick to betslip
     */
    static addEntainUiBetBuilderPick = createAction(
        ExternalBetslipActions.ADD_ENTAIN_UI_BET_BUILDER_PICK,
        props<{
            pickId: BetBuilderPickId;
            tracking: IPickTracking;
            eventModel: EventModel;
            betBuilderOptionMarket: BetBuilderMarket;
            eventData: BetBuilderV1EventData;
            longId: string;
        }>(),
    );

    /**
     * Action called with request to remove entain ui bet builder pick to betslip
     */
    static removeEntainUiBetBuilderPick = createAction(
        ExternalBetslipActions.REMOVE_ENTAIN_UI_BET_BUILDER_PICK,
        props<{
            pickId: BetBuilderPickId;
            longId: string;
            eventId: string;
        }>(),
    );

    /**
     * Action called with request to check if there is already a customized BAB for the event on the betslip
     */
    static checkPrecreatedBABConversion = createAction(
        ExternalBetslipActions.CHECK_PRECREATED_BAB_CONVERSION,
        props<{
            pickId: BetBuilderPickId;
            eventModel: EventModel;
            market: BetBuilderMarket;
            eventData: BetBuilderV1EventData;
            longId: string[];
            headerTemplate: TemplateRef<any>;
            isBetslip?: boolean;
        }>(),
    );

    /**
     * Action called when pick is added to betslip
     */
    static echoAddPick = createAction(ExternalBetslipActions.ECHO_ADD_PICK, props<PickAddPayload>());

    /**
     * Action called with request to add bet builder pick to betslip on betstation cross screen
     */
    static echoAddBetBuilderPick = createAction(
        ExternalBetslipActions.ECHO_ADD_BET_BUILDER_PICK,
        props<{ pickId: BetBuilderPickId; tracking: IPickTracking; eventData: BetBuilderV1EventData | null; additionalInfo?: BABAdditionalInfo }>(),
    );

    /**
     * Action called with request to remove pick from betslip
     */
    static removePick = createAction(ExternalBetslipActions.REMOVE_PICK, props<{ pickId: PickId }>());

    /**
     * Action called when pick is removed from betslip
     */
    static echoRemovePick = createAction(ExternalBetslipActions.ECHO_REMOVE_PICK, props<{ pickId: PickId }>());

    /**
     * Remove picks from the slip
     */
    static removeMultiplePicks = createAction(ExternalBetslipActions.REMOVE_MULTIPLE_PICKS, props<{ pickIds: PickId[] }>());

    /**
     * Action called when pick is removed from betslip
     */
    static echoRemoveMultiplePicks = createAction(ExternalBetslipActions.ECHO_REMOVE_MULTIPLE_PICKS, props<{ pickIds: PickId[] }>());

    /**
     * Remove picks from group in the slip
     */
    static removePicksFromGroup = createAction(ExternalBetslipActions.REMOVE_PICKS_FROM_GROUP, props<{ pickIds: PickId[] }>());

    /**
     * Action called in order betslip to change its type
     */
    static requestBetslipType = createAction(ExternalBetslipActions.REQUEST_BETSLIP_TYPE, props<{ betslipType: BetslipType }>());

    /**
     * Action called when affiliate deep link is found
     */
    static requestAddAffiliatePicks = createAction(
        ExternalBetslipActions.REQUEST_ADD_AFFILIATE_PICKS,
        props<{ request: OptionRequest; picksView: PicksView }>(),
    );

    /**
     * Action called to share bet (in course of affiliate deep link)
     */
    static shareBet = createAction(ExternalBetslipActions.SHARE_AFFILIATE_PICKS);

    /**
     * Action called when bet editing is started
     */
    static startEditMyBet = createAction(ExternalBetslipActions.START_EDIT_MY_BET, props<{ payload: EditMyBetPicksPayload }>());

    /**
     * Action called to update (all - e.g. email or app) notification settings
     */
    static requestNotificationSettingUpdate = createAction(
        ExternalBetslipActions.REQUEST_NOTIFICATION_SETTING_UPDATE,
        props<{ oddsAcceptance?: OddsAcceptanceMode; emailNotify?: boolean; appNotify?: boolean }>(),
    );

    /**
     * Action called to add a pick via web bridge service (e.g. used for AB testing)
     */
    static addWebAppPick = createAction(
        ExternalBetslipActions.ADD_WEB_APP_PICK,
        props<{
            fixture: Fixture;
            option: number;
        }>(),
    );
    /**
     
     * Action called to remove a pick via web bridge service (e.g. used for AB testing)
     
     */

    static removeWebAppPick = createAction(
        ExternalBetslipActions.REMOVE_WEB_APP_PICK,

        props<{
            fixture: Fixture;
            option: number;
        }>(),
    );

    /**
     * Action called to set a reward token (e.g. via free-bet.service)
     */
    static setBetstationFreeBetToken = createAction(ExternalBetslipActions.SET_REWARD_TOKEN, pprops<BetStationFreeBetToken>());

    /**
     * Action called when Toggle Win pick is called
     */
    static toggleWinPick = createAction(
        ExternalBetslipActions.TOGGLE_WIN_PICK,
        props<{ pickId: V1PickId | V2OptionMarketPickId | V2ParticipantPickId }>(),
    );
}
