import { OddsDisplayFormat as SportsOddsDisplayFormat } from '@frontend/sports/types/models/user-settings';

export enum OddsDisplayFormat {
    /* eslint-disable @typescript-eslint/naming-convention */
    EU = 'EU',
    UK = 'UK',
    US = 'US',
    /* eslint-enable @typescript-eslint/naming-convention */
}

export function toBetslipOddsFormat(sportsOddsDisplayFormat: SportsOddsDisplayFormat | null | undefined): OddsDisplayFormat | null | undefined {
    if (sportsOddsDisplayFormat === null) {
        return null;
    }

    if (typeof sportsOddsDisplayFormat === 'undefined') {
        return undefined;
    }

    return sportsOddsDisplayFormat as unknown as OddsDisplayFormat;
}
