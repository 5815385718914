import { BasePrice } from '@cds/betting-offer';
import { LegInfo, SuspensionState } from '@cds/betting-offer/domain-specific/bet-builder';

import { EventModel } from '../event-model/model/event.model';

export function updateBetbuilderPrecreatedGroup(
    event: EventModel,
    sgpId: string,
    odds: BasePrice,
    suspensionState: SuspensionState,
    legs: LegInfo[],
): void {
    if (event.precreatedOptionGroups) {
        const group = event.precreatedOptionGroups.find((g) => g.builderOptionPricing.sgpId === sgpId);
        if (group) {
            const updatedGroup = { ...group };
            const updatedPricing = {
                ...group.builderOptionPricing,
                odds,
                suspensionState,
                legInformation: legs,
            };
            updatedGroup.builderOptionPricing = updatedPricing;

            const index = event.precreatedOptionGroups.indexOf(group, 0);
            if (index > -1) {
                const updatedArray = [...event.precreatedOptionGroups];
                updatedArray.splice(index, 1);
                updatedArray.push(updatedGroup);
                event.precreatedOptionGroups = updatedArray;
            }
        }
    }
}
