import { Component } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';

@Component({
    selector: 'ms-promotion-badge',
    templateUrl: './badge.html',
    standalone: true,
})
export class BadgeComponent {
    promotionTitle: string;

    constructor(public sitecore: Sitecore) {
        this.promotionTitle = sitecore.crmPromotions.Promotion;
    }
}
