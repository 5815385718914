import { Injectable } from '@angular/core';

import { NgrxReportsConfiguration } from '@frontend/sports/common/client-config-data-access';
import { LocalStoreService, SessionStoreService } from '@frontend/vanilla/core';

@Injectable({ providedIn: 'root' })
export class NgrxReportsService<T> {
    private readonly STORE_KEY = 'ngrx-reports-v1';
    actions: T[] = [];
    private threshold = 0;

    constructor(
        private ngrxReportsConfig: NgrxReportsConfiguration,
        private local: LocalStoreService,
        private session: SessionStoreService,
    ) {}

    addAction(action: T) {
        if (this.actions.length >= this.ngrxReportsConfig.maxActions) {
            this.actions.shift();
        }

        this.actions.push(action);
        this.threshold++;
        if (this.threshold === this.ngrxReportsConfig.actionsUpdateThreshold) {
            this.local.set(this.STORE_KEY, this.actions);
            this.session.set(this.STORE_KEY, this.actions);
            this.threshold = 0;
        }
    }

    getActions(): T[] {
        return this.local.get(this.STORE_KEY)!;
    }
}
