import { Injectable } from '@angular/core';

import { TrackingService as VanillaTrackingService } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';

import { TrackData, TrackError, TrackExternalClient, TrackUpdate, trackingConstants } from './tracking.models';

@Injectable({ providedIn: 'root' })
export class TrackingService {
    constructor(private vanillaTracking: VanillaTrackingService) {}

    get whenReady(): Observable<void> {
        return this.vanillaTracking.whenReady;
    }

    /**
     * @description Updates the Datalayer and triggers the event specified by the eventName parameter
     * @param eventName name of the event
     * @param trackingObject (optional) object to update the Datalayer with
     * @param options (optional) timeout in milliseconds for the Google Tag Manager
     */
    track(eventName: string, trackingObject?: Partial<TrackData>, options?: any): void {
        this.vanillaTracking.triggerEvent(eventName, trackingObject, options);
        this.clean();
    }

    /**
     * @description Updates the Datalayer without triggering any event
     * @param data object to update the Datalayer with
     */
    update(trackingObject: Partial<TrackUpdate> | Partial<TrackExternalClient>): void {
        this.vanillaTracking.updateDataLayer(trackingObject);
    }

    /**
     * @description Triggers a special error event by pushing it to the Datalayer
     * @param errorDetail convention is to use an object with two properties:
     * 1. 'message' with the string description of the error and
     * 2. 'code' with the error identificator
     */
    trackError(errorDetail: TrackError): void {
        this.vanillaTracking.reportError(errorDetail);
    }

    /**
     * @description Similar to {@link track} incl. additional update event triggered
     * @param eventName name of the event
     * @param trackingObject (optional) object to update the Datalayer with
     * @param options (optional) timeout in milliseconds for the Google Tag Manager
     */
    triggerEventWithCleaning(eventName?: string, trackingObject?: Partial<TrackData>, options?: any): void {
        this.vanillaTracking.triggerEvent(eventName || 'pageView', trackingObject, options);
        this.clean();
    }

    private clean(): void {
        this.vanillaTracking.updateDataLayer({ [trackingConstants.PAGE_REFERRING_ACTION]: '' });
    }
}
