import { Injectable } from '@angular/core';

import { EventDetailsConfig } from '@frontend/sports/common/client-config-data-access';

import { DateTimeParser } from '../../common/date-time-parser.service';
import { IconInfo, Message } from '../../event-model/model/scoreboard.model';

@Injectable({ providedIn: 'root' })
export class MessageParser {
    constructor(
        private dateParser: DateTimeParser,
        private eventDetailsConfig: EventDetailsConfig,
    ) {}

    parseMessages(messages: Message[], sportId: number, skipTime?: boolean): Message[] {
        const result: Message[] = [];

        if (!messages || messages.length === 0) {
            return result;
        }

        let time: string;
        let icon: IconInfo | null;

        for (const message of messages) {
            time = message.time;
            icon = message.icon;

            if (time && !skipTime) {
                time = this.dateParser.getTime(Number(message.time));
            }

            if (!icon) {
                icon = this.processIcon(message.type, sportId);
            }

            result.push({
                icon,
                text: message.text,
                time,
                type: message.type,
                order: message.order,
                teamType: message.teamType,
            });
        }

        return result;
    }

    private processIcon(messageType: number, sportId: number): IconInfo | null {
        const icon = this.getIcon(messageType, sportId);

        if (icon && messageType > 1) {
            icon.name = `sports-sb-${icon.name}`;
        }

        return icon;
    }

    private getIcon(messageType: number, sportId: number): IconInfo | null {
        const icons = this.eventDetailsConfig.messageIcons;

        if (messageType === 0) {
            return null;
        }

        if (messageType === 1) {
            return icons['0']['1'];
        }

        const id = sportId.toString();
        const type = messageType.toString();
        const icon: IconInfo = { name: '', color: '' };

        if (icons && icons[id] && icons[id][type]) {
            icon.color = icons[id][type].color;
            icon.name = icons[id][type].name;
            icon.highlight = icons[id][type].highlight;
        } else {
            return null;
        }

        return icon;
    }
}
