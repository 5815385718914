import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';

import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import {
    HtmlNode,
    LocationChangeEvent,
    MediaQueryService,
    MenuAction,
    MenuActionsService,
    NavigationService,
    UrlService,
    WindowRef,
} from '@frontend/vanilla/core';
import { HeaderSearchService } from '@frontend/vanilla/shared/header';
import { extend } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { distinctUntilKeyChanged, filter, map } from 'rxjs/operators';

import { EpcotConfigService } from '../common/epcot-config.service';
import { TimerService } from '../common/timer.service';
import { ModalDismissReasons } from '../modal/common/modal-dismiss-reasons';
import { ModalRef } from '../modal/common/modal-ref';
import { PopupAction, PopupClosingResult } from '../modal/common/modal-window.component';
import { DialogAnimation, ModalDialogOptions } from '../modal/dialog/modal-dialog.model';
import { ModalDialogService } from '../modal/dialog/modal-dialog.service';
import { NavigationHelper } from '../navigation-core/navigation-helper.service';
import { RedirectHelperService } from '../navigation-core/redirect-helper.service';
import { ModelPopupTypes, UrlHelperService } from '../navigation-core/url-helper.service';
import { Events as RacingEvents } from '../racing/models/events';
import { trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { UserService } from '../user/services/user.service';
import { SensitivePageTrackerService } from './sensitive-page-tracking.service';

export type PopupTypes =
    | 'betfinder'
    | 'betslip'
    | 'my-bets'
    | 'sports'
    | 'grouped-sports'
    | 'archived-bets'
    | 'bet-generator'
    | 'transaction-bets'
    | 'bet-builder-overlay'
    | 'hr-help-overlay'
    | 'hr-info-overlay'
    | 'gr-help-overlay'
    | 'gr-info-overlay'
    | 'event-switcher'
    | 'ngrx-reports';

interface PopupStack {
    name: PopupTypes;
    handle: ModalRef;
}

@Injectable({ providedIn: 'root' })
export class PopupManager {
    currentPopup: PopupTypes | null = null;
    fullReloadRequired: boolean;
    private popupStack: PopupStack[] = [];
    private largeScreen = false;
    private popups: { [key: string]: { component: any; options: ModalDialogOptions<any> } } = {};
    private _setLastActiveItem = new Subject<void>();
    private opening: boolean;

    constructor(
        private dialogService: ModalDialogService,
        private sensitivePageTracker: SensitivePageTrackerService,
        private htmlNode: HtmlNode,
        private tracker: TrackingService,
        private dispatcher: DispatcherService,
        private navigationService: NavigationService,
        private location: Location,
        private router: Router,
        private urlService: UrlService,
        private windowRef: WindowRef,
        private urlHelperService: UrlHelperService,
        private mediaService: MediaQueryService,
        private timerService: TimerService,
        private headerSearchService: HeaderSearchService,
        private epcotConfigService: EpcotConfigService,
        private redirectHelper: RedirectHelperService,
        private userService: UserService,
        private menuActionsService: MenuActionsService,
        private navigationHelper: NavigationHelper,
    ) {
        this.navigationService.locationChange.subscribe((e: LocationChangeEvent) => this.locationChangeHandler(e));

        // adding a reference to the layout module is creating a circular dependency
        this.mediaService
            .observe()
            .pipe(
                map(() => ({ large: this.mediaService.isActive('gt-md') })),
                distinctUntilKeyChanged('large'),
            )
            .subscribe(this.screenSizeChanged);

        if (this.epcotConfigService.isEnabled()) {
            this.headerSearchService.clickEvent.subscribe((_) => {
                this.open('betfinder');
            });
        }

        this.router.events.pipe(filter((event) => event instanceof RouterEvent)).subscribe((event) => {
            if (!(event instanceof RouterEvent)) {
                return;
            }
            const { search } = this.urlService.parse(event.url);
            if (search.has('popup')) {
                const requestedPopup = search.get('popup') as PopupTypes;
                for (let i = this.popupStack.length - 1; i >= 0; i--) {
                    if (this.popupStack[i].name === requestedPopup) {
                        break;
                    }
                    this.close(PopupAction.CloseByNavigation);
                }

                return;
            }

            if (this.isOpen()) {
                this.closeAll();
            }
        });
    }

    get setLastActiveItem(): Observable<unknown> {
        return this._setLastActiveItem.asObservable();
    }

    setFullReloadRequired(): void {
        this.fullReloadRequired = true;
    }

    configure(name: PopupTypes, component: any, options: ModalDialogOptions<any>): void {
        if (this.popups[name]) {
            throw new Error(`Popup '${name}' has already been configured`);
        }

        this.popups[name] = {
            component,
            options,
        };
    }

    isShowingBackButton(name: PopupTypes): boolean {
        const popupConfig = this.popups[name];
        if (popupConfig?.options?.settings) {
            return popupConfig.options.settings.showBackNav || false;
        } else {
            return false;
        }
    }

    updateDialogAnimation(
        name: PopupTypes,
        animationType?: DialogAnimation,
        showBackNav: boolean = false,
        closePreviousModalWindow: boolean = false,
    ): void {
        const popupConfig = this.popups[name];
        if (popupConfig?.options?.settings) {
            popupConfig.options.settings.animation = animationType;
            popupConfig.options.settings.showBackNav = showBackNav;
            popupConfig.options.settings.closePrevious = closePreviousModalWindow;
        }
    }

    updateDialogTitle(name: PopupTypes, title: string): void {
        this.popupStack.find((p) => p.name === name)?.handle.updateTitle(title);
    }

    open(name: PopupTypes, animationType?: DialogAnimation, options?: ModalDialogOptions<any>): void {
        try {
            if (this.opening) {
                return;
            }
            this.opening = true;
            if (!this.popups[name]) {
                throw new Error(`Popup '${name}' cannot be opened as it has not been configured`);
            }

            const popupAlreadyOpened = this.popupStack.some((ps) => ps.name === name);
            if (popupAlreadyOpened) {
                this.opening = false;

                return;
            }

            if (this.popups[name].options.settings?.needsAuthentication) {
                if (!this.userService.isAuthenticated) {
                    this.handlePopupUnauth(name);
                    this.opening = false;

                    return;
                }
            }

            const parentPopupCloseDelay = 50;

            if (this.currentPopup) {
                const popupConfig = this.popups[this.currentPopup];
                const closePrevious = this.popups[name]?.options?.settings?.closePrevious;
                if (popupConfig.options.settings?.closeOnChildPopup || closePrevious) {
                    this.close(PopupAction.CloseByNextPopup);
                }
                if (name === ModelPopupTypes.MyBets && animationType && popupConfig.options.settings) {
                    popupConfig.options.settings.animation = animationType;
                }
            }
            this.currentPopup = name;

            if (options) {
                this.popups[this.currentPopup].options = {
                    data: { ...this.popups[this.currentPopup].options, ...options.data },
                    settings: { ...this.popups[this.currentPopup].options.settings, ...options.settings },
                };
            }

            this.timerService.setTimeout(() => {
                this.openHandler();
                this.opening = false;
            }, parentPopupCloseDelay);
        } catch (e) {
            this.opening = false;
            throw e;
        }
    }

    close(action: PopupAction = PopupAction.ManualClose, index: number = -1): void {
        const popupHandle = index >= 0 ? this.popupStack[index] : this.popupStack[this.popupStack.length - 1];

        if (!popupHandle) {
            return;
        }

        this.dispatcher.dispatch(RacingEvents.Popup.PopupClosed);
        popupHandle.handle.close({ closeAction: action });

        this.currentPopup = null;
    }

    dismiss_PreviousPopup(popupName: PopupTypes, action: PopupAction = PopupAction.ManualClose, windowClass: string = ''): void {
        const popupHandle = this.popupStack?.find((p) => p.name === popupName);

        if (!popupHandle || popupHandle.name !== popupName) {
            return;
        }

        this.dispatcher.dispatch(RacingEvents.Popup.PopupClosed, popupName);
        if (windowClass) {
            popupHandle.handle.addWindowClass(windowClass);
            this.timerService.setTimeoutOutsideAngular(() => {
                popupHandle.handle.dismiss({ closeAction: action });
            }, 500);
        } else {
            popupHandle.handle.dismiss({ closeAction: action });
        }

        this.currentPopup = null;
    }

    closeAll(): void {
        for (let index = this.popupStack.length - 1; index >= 0; index--) {
            this.close(PopupAction.ManualClose, index);
        }

        this.popupStack = [];
    }

    toggle(name: PopupTypes): void {
        if (this.isOpen()) {
            this.close();
        } else {
            this.open(name);
        }
    }

    isOpen(): boolean {
        return !!this.popupStack.length;
    }

    openBetslip(fromBottomNav?: boolean): void {
        if (fromBottomNav) {
            this.tracker.track(trackingConstants.EVENT_PAGE_VIEW, {
                [trackingConstants.PAGE_NAME]: 'M2_slip_open_bottomBubble',
                [trackingConstants.PAGE_URL]: 'in11288',
            });
        } else {
            this.tracker.track(trackingConstants.EVENT_PAGE_VIEW, {
                [trackingConstants.PAGE_NAME]: 'M2_slip_open_topRightButton',
                [trackingConstants.PAGE_URL]: 'in11246',
            });
        }

        this.open(ModelPopupTypes.Betslip);
    }

    isPopupOpen(popupName: string): boolean {
        return popupName === this.currentPopup;
    }

    isPopupInStack(popupName: string): boolean {
        return this.popupStack.some((popup) => popup.name === popupName);
    }

    private screenSizeChanged = (screen: { large: boolean }): void => {
        this.largeScreen = screen.large;
        if (screen.large) {
            if (this.currentPopup === ModelPopupTypes.Betslip) {
                this.close(PopupAction.ManualClose);
            }
        }
    };

    private openInternal(name: PopupTypes | null): void {
        if (!name) {
            throw new Error(`Trying to open popup with no name`);
        }

        if (!this.popups[name]) {
            throw new Error(`Popup '${name}' cannot be opened as it has not been configured`);
        }

        this.dispatcher.dispatch(RacingEvents.Popup.BeforePopupDisplayed, name);

        const popupConfig = this.popups[name];
        popupConfig.options.settings = extend({}, popupConfig.options.settings, {
            beforeDismiss: () => {
                this.dispatcher.dispatch(RacingEvents.Popup.PopupClosed, name);

                return true;
            },
        });
        if (this.currentPopup === 'sports') {
            popupConfig.options.settings.container = document.querySelector<HTMLElement>('.az-menu-container') || undefined;
        }
        const handle = this.dialogService.openPopup(popupConfig.component, popupConfig.options);
        let currentUrl = this.navigationService.location.path();
        if (currentUrl === this.urlHelperService.getAtoZUrl()) {
            currentUrl = this.urlHelperService.getHomeUrl();
        }
        const currentQueryParams = this.navigationService.location.absUrl().split('?')[1];
        const queryParams = `popup=${name}`;
        if (currentQueryParams !== queryParams) {
            this.location.go(currentUrl, queryParams);
        }
        this.popupStack.push({ name, handle });
        this.show();

        handle.result.then((r) => this.onClosing(r, name)).catch((e) => this.onClosing(e, name));
    }

    private locationChangeHandler(e: LocationChangeEvent): void {
        if (e.nextUrl) {
            const nextUrl = this.urlService.parse(e.nextUrl);
            if (nextUrl.search.has('popup')) {
                const requestedPopup = nextUrl.search.get('popup') as PopupTypes;
                if (this.shouldSkipLocationChange(requestedPopup)) {
                    return;
                }
                const popupInStack = this.popupStack.some((ps) => ps.name === requestedPopup);

                if (!popupInStack && (!this.largeScreen || this.allowedInLargeScreen(requestedPopup))) {
                    this.open(requestedPopup);
                }

                return;
            }
        }
        this.closeAll();

        const previousUrl = this.urlService.parse(e.previousUrl);
        if (previousUrl.search.has('popup') && previousUrl.search.get('popup') === 'sports') {
            this._setLastActiveItem.next();
        }
    }

    private handlePopupUnauth(name: string): void {
        const returnUrl = `?popup=${name}`;
        /* 
            Switching to the home URL as the reference for popup navigation. 
            This is necessary because if the popup URL remains unchanged after login, the locationChangeHandler won't detect it to trigger popup opening. 
        */
        this.navigationService.goTo(this.urlHelperService.getHomeUrl(), { replace: true });

        // On successful login url locationchanger can handle the popup as the url changes
        this.menuActionsService.invoke(MenuAction.GOTO_LOGIN, '', [undefined, undefined, { returnUrl }]);
    }

    private shouldSkipLocationChange(popup: PopupTypes): boolean {
        return ['sports', 'bet-builder-overlay', 'event-switcher'].indexOf(popup) > -1;
    }

    private allowedInLargeScreen(popup: PopupTypes): boolean {
        return (
            [
                'betfinder',
                'sports',
                'grouped-sports',
                'bet-radar',
                'archived-bets',
                'bet-builder-overlay',
                'hr-help-overlay',
                'gr-help-overlay',
                'ngrx-reports',
            ].indexOf(popup) > -1
        );
    }

    private openHandler = (): void => {
        const oldPopup = this.popupStack.length > 0 ? this.popupStack[this.popupStack.length - 1].name : '';
        this.openInternal(this.currentPopup);
        this.sensitivePageTracker.dispatchSensitivePagesEvent(this.currentPopup, oldPopup);
    };

    private onClosing = (result: PopupClosingResult | ModalDismissReasons, name: PopupTypes) => {
        const previousPopUp = this.popupStack[0];
        this.popupStack = this.popupStack.filter((p) => p.name !== name);

        this.dispatcher.dispatch('ON_POPUP_CLOSED', name);
        if (!this.popupStack.length) {
            // last popup was closed
            this.hide();
        }

        if (!this.isClosedManually(result)) {
            // navigation occurred so we do nothing
            return;
        }

        if (this.isFirstLoadAndClosingBetslipPopup(previousPopUp.name)) {
            const oldPopup = this.popupStack.length > 0 ? this.popupStack[this.popupStack.length - 1].name : null;

            this.router.navigate([this.navigationService.location.path()], {
                queryParams: { popup: oldPopup },
                queryParamsHandling: 'merge',
                replaceUrl: true,
            });

            return;
        }

        if (!(previousPopUp.name === 'betfinder' && this.currentPopup === 'betslip')) {
            if (previousPopUp.name === 'my-bets' && this.epcotConfigService.isShowMyBetsPopup && this.windowRef.nativeWindow.history.length <= 2) {
                this.redirectHelper.goHome();
            } else {
                this.location.back();
            }
        }
    };

    private isFirstLoadAndClosingBetslipPopup(closingPopup: string): boolean {
        return !this.navigationHelper.allowBack() && closingPopup === 'betslip';
    }

    private isClosedManually(result: PopupClosingResult | ModalDismissReasons): boolean {
        if (this.isPopupActionResult(result)) {
            if (result.closeAction === PopupAction.ManualClose) {
                return true;
            }
        } else {
            if (result === ModalDismissReasons.BackdropClick || result === ModalDismissReasons.Esc) {
                return true;
            }
        }

        return false;
    }

    private hide(): void {
        this.htmlNode.setCssClass('popup-on', false);
    }

    private show(): void {
        if (this.currentPopup === 'sports') {
            this.htmlNode.setCssClass('popup-on az-menu-desktop', true);

            return;
        }
        this.htmlNode.setCssClass('popup-on', true);
    }

    private isPopupActionResult(result: any): result is PopupClosingResult {
        return !!result?.closeAction;
    }
}
