import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { Logger } from '../logging/logger';
import { ProductInjector } from '../products/product-injector';
import { ProductService } from '../products/product.service';
import { DslCacheService } from './dsl-cache.service';
import { DSL_NOT_READY, DslRecorderService } from './dsl-recorder.service';
import { DSL_VALUES_PROVIDER } from './dsl-values-provider';
import { DslContext, DslEnvExecutionMode, DslEnvResult, DslValuesProvider } from './dsl.models';

/**
 * @whatItDoes Runs vanilla DSL expressions and returns a result.
 *
 * @howToUse
 *
 * ```
 * const result = this.dslEnvService.run(expression);
 * ```
 *
 * @description
 *
 * Low level service for evaluating DSL expressions.
 *
 * @stable
 */
@Injectable({
    providedIn: 'root',
})
export class DslEnvService {
    private context: DslContext = {};
    private changeEvents = new Subject<Set<string>>();

    constructor(
        private dslRecorderService: DslRecorderService,
        private dslCacheService: DslCacheService,
        private productInjector: ProductInjector,
        private productService: ProductService,
        private log: Logger,
    ) {
        this.dslCacheService.invalidation.subscribe((deps: Set<string>) => this.changeEvents.next(deps));

        //Necessary for embedded products as the change from host to another product happens without page refresh and might run later so dsl context won't have the providers for cached expressions.
        this.productService.productChanged.subscribe(() => this.getContext());
    }

    get change(): Observable<Set<string>> {
        return this.changeEvents;
    }

    private _notRegisteredRecordables: string[] = [];

    /**
     * List of recordables not registered due to module not yet loaded.
     */
    get notRegisteredRecordables(): string[] {
        return this._notRegisteredRecordables;
    }

    whenStable(): Observable<void> {
        return this.dslCacheService.whenStable();
    }

    run(expression: string, executionMode = DslEnvExecutionMode.Expression): DslEnvResult {
        let dslResult: DslEnvResult = { result: undefined, deps: new Set() };

        if (!expression) {
            dslResult = { result: true, deps: new Set() };

            return dslResult;
        }

        if (executionMode === DslEnvExecutionMode.Expression) {
            const cached = this.dslCacheService.get(expression);

            if (cached) {
                this.log.debug(`eval DSL ${expression} to ${cached.result} (cached)`);

                dslResult = { result: cached.result, deps: cached.dependencies, notReady: cached.notReady };

                return dslResult;
            }
        }

        this.dslRecorderService.beginRecording();

        const context = this.getContext();
        const notRegisteredDeps = this.notRegisteredRecordables.filter((x: string) => expression.toLowerCase().match(new RegExp(`\\.${x}\\.`, 'ig')));

        dslResult.notReady = false;

        if (notRegisteredDeps.length > 0) {
            dslResult.notReady = true;
            dslResult.error = new Error(`Expression contains DSL providers not yet registered: ${this.notRegisteredRecordables.join(',')}.`);
        } else {
            try {
                const fn = this.buildFunction(expression, executionMode);
                dslResult.result = fn.call(null, context);
            } catch (err: any) {
                if (err.message === DSL_NOT_READY) {
                    dslResult.notReady = true;
                } else {
                    dslResult.error = err;
                    this.log.errorRemote(`Error occurred when evaluating DSL '${expression}'`, err);
                }
            }
        }

        const recording = this.dslRecorderService.endRecording();
        dslResult.deps = recording.deps;

        //Manually add not registered deps as create recordable did not run yet for them. So when they load cache can be invalidated forcing reevaluation.
        notRegisteredDeps.forEach((x: string) => dslResult.deps.add(x));

        this.log.debug(`eval DSL ${expression} to ${dslResult.result}`);
        this.dslCacheService.set(expression, dslResult.result, dslResult.deps, dslResult.notReady);

        return dslResult;
    }

    addToContext(factories: DslValuesProvider[]) {
        if (factories?.length > 0) {
            const newContext = factories.reduce((a: {}, p: DslValuesProvider) => this.enrichContext(a, p), {});
            Object.keys(newContext).forEach((provider: string) => this.lazyProviderReady(provider));

            this.context = Object.assign(this.context, newContext);
        }
    }

    registerNotReadyDslsProviders(recordableKeys: string[]) {
        this.notRegisteredRecordables.push(...recordableKeys.map((r: string) => r.toLowerCase()));
    }

    private getContext(): DslContext {
        const factories = this.productInjector.getMultiple(DSL_VALUES_PROVIDER);
        const context = factories.reduce((a: {}, p: DslValuesProvider) => this.enrichContext(a, p), {});
        this.context = Object.assign(this.context, context);

        return this.context;
    }

    private enrichContext(context: object, provider: DslValuesProvider): Object & DslContext {
        const providers = provider.getProviders();

        for (const name in providers) {
            if (providers[name]) {
                providers[name]!.providerName = name;
            }
        }

        return Object.assign(context, providers);
    }

    private buildFunction(expression: string, executionMode: DslEnvExecutionMode): Function {
        let body: string;

        switch (executionMode) {
            case DslEnvExecutionMode.Expression:
                body = `return (${expression});`;
                break;
            case DslEnvExecutionMode.Action:
                body = expression;
                break;
            default:
                throw new Error();
        }

        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        return new Function('c', `"use strict"; ${body}`);
    }

    private lazyProviderReady(recordableKey: string) {
        this._notRegisteredRecordables = this.notRegisteredRecordables.filter((x: string) => x != recordableKey.toLowerCase());
        //Invalidate expressions that might have run before provider was registered so they can reevaluate
        this.dslCacheService.invalidate([recordableKey.toLowerCase()]);
    }
}
