import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { padStart, template, templateSettings } from 'lodash-es';

import { DateProviderService } from './date-provider.service';

@Injectable({ providedIn: 'root' })
export class DateTimeParser {
    constructor(public sitecore: Sitecore) {}

    getStartsAtTime(date: Date): string {
        const now = DateProviderService.getDate();
        const delta = Math.floor((date.getTime() - now.getTime()) / 60000);

        if (delta < 0) {
            return this.sitecore.globalMessages.NotStarted;
        }

        if (delta === 0) {
            return this.sitecore.globalMessages.StartsNow;
        }

        if (delta < 11) {
            templateSettings.interpolate = /{{([\s\S]+?)}}/g;
            const startingInMinutes = template(this.sitecore.globalMessages.StartingInMinutes);
            const startingInMinute = template(this.sitecore.globalMessages.StartingInMinute);

            const message = delta > 1 ? startingInMinutes({ delta }) : startingInMinute({ delta });

            return message;
        }

        return this.sitecore.globalMessages.NotStarted;
    }

    getTime(time: number): string {
        const minutes = time % 60;
        const hours = (time - minutes) / 60;
        const formattedMinutes = padStart(minutes.toString(), 2, '0');

        return `${hours}:${formattedMinutes}`;
    }
}
