import { Directive, HostListener, Input, OnInit, inject } from '@angular/core';

import { Logger, MenuActionsService, MenuContentItem, TrackingService, WebAnalyticsEventType } from '@frontend/vanilla/core';

/**
 * @whatItDoes Adds data to the datalayer based on item tracking data provided via Sitecore in item parameters or WebAnalytics section.
 *
 *
 * @howToUse
 *
 * ```
 * <ng-container vnMenuItemTracking [item]="item"  />
 * ```
 * @stable
 */
@Directive({
    standalone: true,
    selector: '[vnMenuItemTracking]',
})
export class MenuItemTrackingDirective implements OnInit {
    private trackingService = inject(TrackingService);
    private menuActionsService = inject(MenuActionsService);
    private log = inject(Logger);

    @HostListener('click') async onClick() {
        await this.click();
    }

    @Input() item: MenuContentItem;

    ngOnInit() {
        this.trackingService.trackEvents(this.item, WebAnalyticsEventType.load);
    }

    async click() {
        await this.menuActionsService.trackClick(this.item, true).catch((error) => {
            this.log.error('Error while tracking menu item click.', error);
        });
    }
}
