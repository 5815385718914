import { Injectable } from '@angular/core';

import { Fixture, FixturePage } from '@cds/betting-offer';
import { CountsFixturesResponse } from '@cds/betting-offer/domain-specific';
import { Competition } from '@cds/betting-offer/tags';
import {
    CountItem,
    ItemCount,
    SportConstant,
    isCompetitionItem,
    isVirtualCompetitionGroupItem,
    sportModelMethods,
} from '@frontend/sports/common/base-utils';
import { FavouritesConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { min, orderBy } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SubscriptionTopic } from '../event-subscription/base-subscription.service';
import { GridGrouping, LeagueGroup } from '../grid-base/grid.model';
import { ObservableGrid } from '../grid-base/observable-grid';
import { GridOption } from '../grid-base/observable-grid-custom-column-provider';
import { ObservableGridFactory } from '../grid-base/observable-grid.factory';
import { StatisticsApiService } from '../statistics/statistics-api.service';
import { TabBarItem } from '../tab-bar/tab-bar.models';
import { TeamPagesCoreMarqueeService } from './team-pages-core-marquee.service';
import { TeamPagesStandingService } from './team-pages-standing.service';
import { FixtureTab, FixtureView, Team, TeamPagesGridModel } from './team-pages.model';

@Injectable({ providedIn: 'root' })
export class TeamPagesCoreGridService {
    constructor(
        private teamPagesCoreMarqueeService: TeamPagesCoreMarqueeService,
        public sitecore: Sitecore,
        private config: FavouritesConfig,
        private gridFactory: ObservableGridFactory,
        private teamPagesStandingService: TeamPagesStandingService,
        private cdsStatsService: StatisticsApiService,
    ) {}

    getGridModel(selectedTeam: Team | undefined): Observable<TeamPagesGridModel> {
        if (!selectedTeam) {
            return of({ tabs: [] });
        }

        return this.teamPagesCoreMarqueeService
            .getFixtureCounts(selectedTeam.id, selectedTeam.sport!.id)
            .pipe(switchMap((fixtureCounts) => this.setGridModel(selectedTeam, fixtureCounts)));
    }

    getFixturesForTab(teamPagesGridTabsModel: TeamPagesGridModel, tabId: number, team: Team, data?: FixturePage): Observable<TeamPagesGridModel> {
        teamPagesGridTabsModel.view = this.getView(tabId);

        if (tabId === FixtureTab.Standings) {
            return this.getStandingsForTeam(teamPagesGridTabsModel, team.id.toString());
        }
        if (team.id && team.sport?.id) {
            if (!data) {
                return this.teamPagesCoreMarqueeService
                    .getFixtures(team.id, team.sport.id, tabId)
                    .pipe(map((fixturedata) => this.getResult(teamPagesGridTabsModel, tabId, team, fixturedata)));
            }

            return of(this.getResult(teamPagesGridTabsModel, tabId, team, data));
        }
        teamPagesGridTabsModel.noMarketsForTeam = true;

        return of(teamPagesGridTabsModel);
    }

    private buildGridModel(fixtures: Fixture[], options: GridOption = {}): ObservableGrid {
        return this.gridFactory.fromResponse(`teamsPages`, fixtures, options);
    }

    private getResult(teamPagesGridTabsModel: TeamPagesGridModel, tabId: number, team: Team, data?: FixturePage): TeamPagesGridModel {
        const nonMatchesTab = tabId === FixtureTab.Outrights || tabId === FixtureTab.Specials;
        let competitionsOrder: CountItem[] = [];
        if (nonMatchesTab && teamPagesGridTabsModel.standings) {
            competitionsOrder = this.getCompetitionItems([teamPagesGridTabsModel.standings]);
        }

        const gridOptions = {
            marketGrouping: teamPagesGridTabsModel?.view === FixtureView.GridList,
            collapsedThreshold: this.getCollapseSize(tabId),
            subscriptionTopic: this.getTopic(tabId),
            grouping: GridGrouping.League,
        };
        const fixtures = data?.fixtures;
        teamPagesGridTabsModel.fixtureIds = data?.fixtures.map((k) => k.id);
        if (fixtures?.length) {
            let sortedFixtures = fixtures;

            teamPagesGridTabsModel.tabs.forEach((x) => {
                if (x.id === FixtureTab.Matches && tabId === FixtureTab.Matches) {
                    x.count = fixtures?.length;
                }
            });

            if (nonMatchesTab && competitionsOrder.length > 1) {
                sortedFixtures = orderBy(fixtures, (f) => {
                    return this.getCompetitionOrder(f.competition, competitionsOrder);
                });
            }

            if (teamPagesGridTabsModel) {
                teamPagesGridTabsModel.currentStandingsGroups = [];
                teamPagesGridTabsModel.gridModel = this.buildGridModel(sortedFixtures, gridOptions);
            }
        }

        teamPagesGridTabsModel.noMarketsForTeam = !fixtures?.length;

        return teamPagesGridTabsModel;
    }

    private getCompetitionOrder<T extends LeagueGroup | Competition>(item: T, competitionsOrder: CountItem[]): number {
        return competitionsOrder.find((co) => co.id === item.id)?.order ?? 0;
    }

    private getStandingsForTeam(teamPagesGridTabsModel: TeamPagesGridModel, teamId: string): Observable<TeamPagesGridModel> {
        if (teamPagesGridTabsModel) {
            delete teamPagesGridTabsModel.gridModel;
            if (teamId) {
                teamPagesGridTabsModel.currentStandings = teamPagesGridTabsModel.standings;

                if (teamPagesGridTabsModel.currentStandings) {
                    const standings = this.teamPagesStandingService.getStandingsGroups({
                        sport: teamPagesGridTabsModel.currentStandings,
                        expandedGroups: 2,
                    });

                    teamPagesGridTabsModel.currentStandingsSport = teamPagesGridTabsModel.standings;
                    teamPagesGridTabsModel.currentStandingsGroups = standings;

                    const groupIds = teamPagesGridTabsModel.currentStandingsGroups?.map((group) => group.id);
                    if (!groupIds) {
                        return of(teamPagesGridTabsModel);
                    }
                    if (teamPagesGridTabsModel.currentStandingsSport?.id !== SportConstant.Soccer) {
                        return of(teamPagesGridTabsModel);
                    }

                    return this.cdsStatsService.getCompetitionsStatistics(groupIds, teamPagesGridTabsModel.currentStandingsSport?.id).pipe(
                        map((statistics) => {
                            if (statistics) {
                                teamPagesGridTabsModel.groups = teamPagesGridTabsModel.currentStandingsGroups?.map((standingGroup) => ({
                                    ...standingGroup,
                                    statistics: statistics.find((s) => s.competitionId === standingGroup.id),
                                }));
                            }

                            return teamPagesGridTabsModel;
                        }),
                    );
                }
            }
            teamPagesGridTabsModel.currentStandingsGroups = [];
        }

        return of(teamPagesGridTabsModel);
    }

    private getView(tabId: number): FixtureView {
        if (tabId === FixtureTab.Matches) {
            return FixtureView.Grid;
        }

        if (tabId === FixtureTab.Standings) {
            return FixtureView.Standings;
        }

        return FixtureView.GridList;
    }

    private getCompetitionItems(countItems: CountItem[]): CountItem[] {
        const result: CountItem[] = [];
        for (const countItem of countItems) {
            if (countItem.children.length > 0) {
                result.push(...this.getCompetitionItems(countItem.children));
            } else if (isCompetitionItem(countItem) || isVirtualCompetitionGroupItem(countItem)) {
                result.push(countItem);
            }
        }

        return result;
    }

    private getCollapseSize(tabId: number): number | undefined {
        return tabId === FixtureTab.Outrights || tabId === FixtureTab.Specials ? this.config.collapseSize : undefined;
    }

    private getTopic(tabId: number): SubscriptionTopic | undefined {
        if (tabId === FixtureTab.Specials) {
            return SubscriptionTopic.Specials;
        }

        if (tabId === FixtureTab.Outrights) {
            return SubscriptionTopic.Outrights;
        }

        return undefined;
    }

    private setGridModel(selectedTeam: Team, countsFixturesResponse: CountsFixturesResponse | undefined): Observable<TeamPagesGridModel> {
        const gridTabCountsItem = sportModelMethods.fromTag(countsFixturesResponse?.counts);
        const teamPagesGridTabsModel: TeamPagesGridModel = {
            standings: gridTabCountsItem[0],
            tabs: this.getNavigation(gridTabCountsItem),
        };

        const selectedTab = teamPagesGridTabsModel.tabs.find((tabs) => tabs.active);

        if (selectedTab) {
            return this.getFixturesForTab(teamPagesGridTabsModel, selectedTab?.id, selectedTeam, countsFixturesResponse?.fixtures);
        }

        teamPagesGridTabsModel.noMarketsForTeam = true;

        return of(teamPagesGridTabsModel);
    }

    private getNavigation(gridTabCountsItem: CountItem[]): TabBarItem[] {
        if (!gridTabCountsItem.length) {
            return [];
        }

        const navigation: TabBarItem[] = [];

        const tabCounts = gridTabCountsItem[0];
        const counts = tabCounts.counts;
        let competitionStandings = false;

        competitionStandings = tabCounts && this.hasLeagueStatistics(tabCounts.children);
        const matchesCount = min([counts.preMatch + counts.live, counts.gridable + counts.nonGridable + counts.other]);
        const shouldShowStandings = tabCounts.id === SportConstant.Soccer && competitionStandings;
        const tabToSelect = this.getTabToSelect(counts, shouldShowStandings, matchesCount!);

        const append = (tab: FixtureTab, count: number, selected?: boolean) => {
            if (count) {
                navigation.push({
                    id: tab,
                    title: this.sitecore.event[FixtureTab[tab]],
                    count,
                    active: selected,
                });
            }
        };

        if (matchesCount) {
            navigation.push({
                id: FixtureTab.Matches,
                title: this.sitecore.event[FixtureTab[FixtureTab.Matches]],
                active: tabToSelect === FixtureTab.Matches,
                count: matchesCount,
            });
        }

        append(FixtureTab.Outrights, counts.outrights, tabToSelect === FixtureTab.Outrights);
        append(FixtureTab.Specials, counts.specials, tabToSelect === FixtureTab.Specials);

        if (shouldShowStandings) {
            navigation.push({
                id: FixtureTab.Standings,
                title: this.sitecore.event[FixtureTab[FixtureTab.Standings]],
                count: 0,
                active: tabToSelect === FixtureTab.Standings,
            });
        }

        return navigation;
    }

    private hasLeagueStatistics(countItems: CountItem[]): boolean {
        for (const countItem of countItems) {
            if (countItem.children.length > 0) {
                const hasStats = this.hasLeagueStatistics(countItem.children);
                if (hasStats) {
                    return hasStats;
                }
            } else if ((isCompetitionItem(countItem) || isVirtualCompetitionGroupItem(countItem)) && countItem.standings) {
                return true;
            }
        }

        return false;
    }

    private getTabToSelect(counts: ItemCount, shouldShowStandings: boolean, matchesCount: number): FixtureTab {
        if (matchesCount) {
            return FixtureTab.Matches;
        }
        if (counts.outrights) {
            return FixtureTab.Outrights;
        }
        if (shouldShowStandings) {
            return FixtureTab.Standings;
        }
        if (counts.specials) {
            return FixtureTab.Specials;
        }

        return FixtureTab.Matches;
    }
}
