import { Injectable } from '@angular/core';

import { StatisticsMode } from '@cds/query-objects';
import { NumberDictionary, SportConstant } from '@frontend/sports/common/base-utils';
import { StatisticsConfig } from '@frontend/sports/common/client-config-data-access';
import { CompetitionRecordsFormat } from '@frontend/sports/types/components/statistics';
import { isEmpty, keyBy } from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class StatisticsConfigService {
    private readonly teamRankEnabledSportsList: NumberDictionary<number>;
    private readonly pitcherEnabledSportsList: NumberDictionary<number>;

    constructor(private statisticsConfig: StatisticsConfig) {
        this.teamRankEnabledSportsList = keyBy(this.statisticsConfig.teamRankEnabledSports, (sport) => sport);
        this.pitcherEnabledSportsList = keyBy(this.statisticsConfig.pitcherEnabledSports, (sport) => sport);
    }

    get teamRankEnabledSports(): NumberDictionary<number> {
        return this.teamRankEnabledSportsList;
    }

    get pitcherEnabledSports(): NumberDictionary<number> {
        return this.pitcherEnabledSportsList;
    }

    get teamRecordsFormat(): { [sport: number]: CompetitionRecordsFormat } {
        return this.statisticsConfig.teamRecordsFormat;
    }

    showStats(sportId: SportConstant): boolean {
        return !!(this.teamRankEnabledSports[sportId] || this.pitcherEnabledSports[sportId] || !isEmpty(this.teamRecordsFormat[sportId]));
    }

    getStatisticsModesBySportId(sportId?: number): string {
        const statModes: string[] = [];
        let showRank;
        let showPitcher;
        let showRecords;
        if (sportId) {
            showRank = !!this.teamRankEnabledSportsList[sportId];
            showPitcher = !!this.pitcherEnabledSportsList[sportId];
            showRecords = !isEmpty(this.statisticsConfig.teamRecordsFormat[sportId]);
        } else {
            showRank = this.statisticsConfig.teamRankEnabledSports?.length !== 0;
            showPitcher = this.statisticsConfig.pitcherEnabledSports?.length !== 0;
            showRecords = !isEmpty(this.statisticsConfig.teamRecordsFormat);
        }

        if (!showRank && !showPitcher && !showRecords) {
            return StatisticsMode.None.toString();
        }

        if (showRank && showPitcher && showRecords) {
            return StatisticsMode.All.toString();
        }

        if (showRank) {
            statModes.push(StatisticsMode.Rank.toString());
        }
        if (showPitcher) {
            statModes.push(StatisticsMode.Pitchers.toString());
        }
        if (showRecords) {
            statModes.push(StatisticsMode.SeasonStandings.toString());
        }

        return statModes.toString();
    }
}
