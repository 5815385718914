import { Injectable, Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { MarqueeTilesConfig, SportsUserConfig } from '@frontend/sports/common/client-config-data-access';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { GeoLocationStatus } from '@frontend/sports/types/components/content';
import { ClientConfigService, GeolocationService, OnAppInit } from '@frontend/vanilla/core';
import { ReplaySubject, combineLatest, from } from 'rxjs';
import { concatMap, distinctUntilChanged, first, map, skip, startWith, switchMap, tap } from 'rxjs/operators';

import { UserEvents } from '../model/user.models';

@Injectable({
    providedIn: 'root',
})
export class SportsClientConfigRefreshService implements OnAppInit {
    private readonly BET_PLACE_SUCCESS = 'BET_PLACE_SUCCESS';
    private _locationDependantDataNeeded$ = new ReplaySubject<void>();

    readonly locationDependantDataNeeded$ = this._locationDependantDataNeeded$.asObservable();

    constructor(
        private geolocationService: GeolocationService,
        private injector: Injector,
        private dispatcher: DispatcherService,
        private sportsUserConfig: SportsUserConfig,
        private marqueeTilesConfig: MarqueeTilesConfig,
        private router: Router,
    ) {}

    onAppInit(): void {
        const clientConfig = this.injector.get(ClientConfigService);

        const firstNavigationFinished = this.router.events.pipe(first((e) => e instanceof NavigationEnd));

        this.geolocationService.whenReady
            .pipe(
                first(),
                concatMap(() => {
                    return combineLatest([this.geolocationService.positionChanges, firstNavigationFinished, this.marqueeTilesConfig.whenReady]).pipe(
                        map(([location, _]) => location.mappedLocation?.locationId),
                        distinctUntilChanged(),
                        switchMap(() => from(clientConfig.reload([MarqueeTilesConfig]))),
                        tap(() => this.dispatcher.dispatch(UserEvents.ClientConfigRefreshed)),
                        map(() => this.marqueeTilesConfig.tiles.some((t) => t.geoLocationStatus === GeoLocationStatus.Restricted)),
                        startWith(false),
                        distinctUntilChanged(),
                        skip(1), // we don't care about the first value which is emitted from startWith - it's used just for distinctUntilChanged
                    );
                }),
            )
            .subscribe(() => {
                this._locationDependantDataNeeded$.next();
            });

        this.dispatcher.on(this.BET_PLACE_SUCCESS).subscribe(() => {
            if (this.sportsUserConfig.isEligibleForNewCustomerOffer) {
                clientConfig.reload([SportsUserConfig]);
            }
        });
    }
}
