import { Injectable } from '@angular/core';

import { CookieName } from './cookie/cookie.models';
import { CookieService } from './cookie/cookie.service';
import { WindowRef } from './window/window-ref.service';

/**
 * @whatItDoes Provides information about PWA related features.
 *
 * @stable
 */
@Injectable({
    providedIn: 'root',
})
export class PWAService {
    get isStandaloneApp(): boolean {
        return this.isStandalone;
    }

    private readonly isStandalone: boolean;

    constructor(private windowRef: WindowRef, private cookieService: CookieService) {
        this.isStandalone =
            windowRef.nativeWindow.matchMedia('(display-mode: standalone)').matches ||
            (this.windowRef.nativeWindow.navigator as any).standalone ||
            this.cookieService.get(CookieName.StandaloneOverride) === '1';
    }
}
