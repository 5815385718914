import { AsyncPipe, NgIf } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

import { HooksWireup, LeagueItem, interpolateDoubleBracesString } from '@frontend/sports/common/base-utils';
import { CompetitionTeamsPayload } from '@frontend/sports/types/components/widget/types';
import { Observable, ReplaySubject, of } from 'rxjs';
import { concatMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { SportsCacheService } from '../../client-caching/sports-cache.service';
import { CompetitionRouteService } from '../../competition-list/competition-route.service';
import { ComponentProxy } from '../../deferred/component-loader.service';
import { ComponentProxyDirective } from '../../deferred/component-proxy.directive';
import { TeamPagesCompetitionViewBinding, TeamPagesComponentLoaderService } from '../../deferred/team-pages-component-loader.service';
import { RouterEventsService } from '../../navigation-core/router-events.service';
import { TeamPagesApiService } from '../../teampages-core/team-pages-api.service';
import { TeamPagesCoreService } from '../../teampages-core/team-pages-core.service';
import { TeamPagesViewModel } from '../../teampages-core/team-pages.model';
import { WidgetCommonModule } from '../../widget/common/widget-common.module';
import { WidgetComponent } from '../../widget/core/widget-registry.service';
import { PruneHelperService } from './competitions/prune-helper.service';
import { VirtualCompetitionService } from './virtual-competition.service';

@HooksWireup()
@Component({
    selector: 'ms-competition-teams-widget',
    templateUrl: 'competition-teams-widget.html',
    standalone: true,
    imports: [NgIf, AsyncPipe, WidgetCommonModule, ComponentProxyDirective],
})
export class CompetitionTeamsWidgetComponent extends WidgetComponent<CompetitionTeamsPayload> {
    private payload = new ReplaySubject<CompetitionTeamsPayload>(1);
    private payload$ = this.payload.asObservable();

    readonly teamPagesCompetitionComponent: ComponentProxy<TeamPagesCompetitionViewBinding>;

    readonly vm$: Observable<TeamPagesViewModel | undefined>;
    title: string | undefined = undefined;

    @HostBinding('class') className = 'competition-teams-widget grid-wrapper';
    @HostBinding('class.competition-teams-widget--standalone') get standalone() {
        return !this.parent;
    }

    constructor(
        private loader: TeamPagesComponentLoaderService,
        private teamPagesApi: TeamPagesApiService,
        private teamPagesCore: TeamPagesCoreService,
        private routeEvents: RouterEventsService,
        private bettingCache: SportsCacheService,
        private competitionRoute: CompetitionRouteService,
        private pruneService: PruneHelperService,
        private virtualCompetitionService: VirtualCompetitionService,
    ) {
        super();

        this.teamPagesCompetitionComponent = this.loader.getTeamPagesComponent();

        this.vm$ = this.payload$.pipe(
            withLatestFrom(this.routeEvents.currentActivationEnd),
            switchMap(([payload, route]) => {
                const sport$ = this.bettingCache.getSport(this.competitionRoute.params().sport!);

                return sport$.pipe(
                    concatMap((sport) => {
                        if (!route?.snapshot) {
                            return of(undefined);
                        }

                        const { participants, competitionId } = payload;
                        const teams = participants
                            .filter((item) => item && item.participantId)
                            .map((item) => this.teamPagesApi.mapTeam(item, competitionId));

                        const leagueRoute = this.competitionRoute.params();
                        const prunedSport = this.pruneService.prune(sport, leagueRoute);
                        const competition = this.virtualCompetitionService.getCompetition(prunedSport, leagueRoute, !!leagueRoute.isVirtual);

                        this.title = this.interpolateTitle(payload.title, competition);

                        return this.teamPagesCore.getTeamPageViewModel(teams, route.snapshot);
                    }),
                );
            }),
            tap((model) => {
                if (model) {
                    this.teamPagesCompetitionComponent.update({ teampagesViewModel: model });
                }
                this.setWidgetLoaded();
            }),
        );
    }

    override onData(payload: CompetitionTeamsPayload): void {
        this.payload.next(payload);
    }

    private interpolateTitle(title: string, league: LeagueItem | undefined): string {
        return interpolateDoubleBracesString(title, { competition: league?.name ?? '' });
    }
}
