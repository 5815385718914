import { Decimal } from 'decimal.js';

export class NativeFraction {
    numerator: Decimal;
    denominator: Decimal;

    constructor(n: number | Decimal, d: number | Decimal = 1) {
        this.numerator = new Decimal(n);
        this.denominator = new Decimal(d);
    }

    static empty(): NativeFraction {
        return new NativeFraction(0, 0);
    }

    static toFraction(value: Decimal): NativeFraction {
        if (value.lessThanOrEqualTo(0)) {
            return NativeFraction.empty();
        }

        const dp = value.decimalPlaces();
        if (dp <= 3) {
            const denominator = new Decimal(Math.pow(10, dp));

            return new NativeFraction(value.times(denominator).minus(denominator), denominator).simplify();
        }

        const values = value.toFraction(999);
        const num = values[0];
        const denom = values[1];

        return new NativeFraction(num.minus(denom), denom).simplify();
    }

    private static getCommonDivisor(a: Decimal, b: Decimal): Decimal {
        let temp: Decimal;
        while (!b.equals(0)) {
            temp = b;
            b = a.modulo(b);
            a = temp;
        }

        return a;
    }

    getValue(): Decimal {
        if (this.denominator.equals(0)) {
            return new Decimal(0);
        }

        return this.numerator.add(this.denominator).dividedBy(this.denominator);
    }

    private simplify(): NativeFraction {
        const greatestCommonDivisor = NativeFraction.getCommonDivisor(this.numerator, this.denominator);

        return new NativeFraction(this.numerator.dividedBy(greatestCommonDivisor), this.denominator.dividedBy(greatestCommonDivisor));
    }
}
