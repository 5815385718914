import { Injectable } from '@angular/core';

import { WindowRef } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class CssSupportService {
    private cssSupports: (feature: string, value: string) => boolean;

    constructor(private window: WindowRef) {
        this.cssSupports = this.window.nativeWindow.CSS && this.window.nativeWindow.CSS.supports;
    }

    isFeatureSupported(feature: string, value: string): boolean {
        if (!this.cssSupports) {
            return false;
        }

        return this.cssSupports(feature, value);
    }

    isTouchActionSupported(): boolean {
        return this.isFeatureSupported('touch-action', 'pan-y');
    }
}
