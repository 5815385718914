import { Injectable } from '@angular/core';

import { TimerService } from '@rtms/client/lib/timer';

import { WorkerType } from '../web-worker/web-worker.models';
import { WebWorkerService } from '../web-worker/web-worker.service';

@Injectable({
    providedIn: 'root',
})
export class RtmsTimerService implements TimerService {
    constructor(private webWorkerService: WebWorkerService) {}

    setTimeout(operation: () => void, timeout: number): Worker | undefined {
        return this.webWorkerService.createWorker(WorkerType.RtmsTimeout, { timeout }, operation);
    }

    setInterval(operation: () => void, interval: number): Worker | undefined {
        return this.webWorkerService.createWorker(WorkerType.RtmsInterval, { interval }, operation);
    }

    clearTimeout() {
        this.webWorkerService.removeWorker(WorkerType.RtmsTimeout);
    }

    clearInterval() {
        this.webWorkerService.removeWorker(WorkerType.RtmsInterval);
    }
}
