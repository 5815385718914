import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { WindowRef } from '@frontend/vanilla/core';
import { delay, map, takeUntil } from 'rxjs/operators';

import { CharsetSizeService } from '../common/charset-size.service';
import { NumpadService } from './numpad.service';
import { StakeInputMode } from './stake-input-mode.strategy';

@HooksWireup()
@Directive({
    selector: '[msStakeFontResizer]',
})
export class StakeFontResizerDirective extends OnDestroyCleanup implements AfterViewInit {
    // hardcoded styling values, change when stake styling changes
    private readonly constants = {
        stakeFieldSelector: '.stake-field-container',
        stakeInputSelector: '.stake-input-value',
        stakeLeftPadding: { withError: 14, withOutError: 5 },
        minimumFontSize: 11,
        minimumPaddingSize: 1,

        // magic numbers to make sure the size is always small enough
        resizedFontReducer: 1,
        resizedPaddingReducer: 2,
    };

    private containerWidth: number;

    /* eslint-disable-next-line @angular-eslint/no-input-rename */
    @Input('msStakeFontResizer') resizerOptions: {
        inputMode: StakeInputMode;
        hasError: boolean;
    };

    private childElement: HTMLElement;
    private defaultRightPadding: number;
    private defaultFont: number;
    private isInput: boolean;

    constructor(
        private stakeElement: ElementRef,
        private numpadService: NumpadService,
        private renderer: Renderer2,
        private charsetService: CharsetSizeService,
        private window: WindowRef,
    ) {
        super();
    }

    ngAfterViewInit(): void {
        this.numpadService.state$
            .pipe(
                map((state) => state.stake),
                delay(20),
                takeUntil(this.destroyed$),
            )
            .subscribe((stake) => this.stateChange(stake.formatted));
    }

    private stateChange(stake: string): void {
        if (!this.valuesLoaded) {
            this.loadValues();
        }

        if (this.valuesLoaded) {
            this.reduceSize(stake);
        }
    }

    private reduceSize(stake: string): void {
        if (this.hasOverflow(stake)) {
            const ratio = this.spaceAvailable / this.spaceNeeded(stake);

            const newFont = Math.max(
                this.constants.minimumFontSize,
                Math.min(this.defaultFont, this.defaultFont * ratio - this.constants.resizedFontReducer),
            );

            const newPadding = Math.max(
                this.constants.minimumPaddingSize,
                Math.min(this.defaultRightPadding, Math.floor(this.defaultRightPadding * ratio - this.constants.resizedPaddingReducer)),
            );

            this.setStyle(newFont, newPadding);
        } else {
            this.setStyle(this.defaultFont, this.defaultRightPadding);
        }
    }

    private loadValues(): void {
        this.isInput = this.resizerOptions.inputMode === StakeInputMode.Typing;
        this.childElement = this.stakeElement.nativeElement.querySelector(
            this.isInput ? this.constants.stakeInputSelector : this.constants.stakeFieldSelector,
        );

        const style = this.window.nativeWindow.getComputedStyle(this.childElement);

        // only set default values, when field is visible (in EditMybet & AutoCashout stake is first hidden)
        if (!style.width.includes('px')) {
            return;
        }

        this.containerWidth = parseFloat(style.width);
        this.defaultFont = parseFloat(style.fontSize);
        this.defaultRightPadding = parseFloat(style.paddingRight || '0');
    }

    private setStyle(fontSize: number, paddingSize: number): void {
        this.renderer.setAttribute(this.childElement, 'style', `font-size: ${fontSize}px; padding-right: ${paddingSize}px;`);
    }

    private hasOverflow(stake: string): boolean {
        return this.isInput ? this.spaceNeeded(stake) >= this.spaceAvailable : this.spaceNeeded(stake) > this.spaceAvailable;
    }

    private spaceNeeded(stake: string): number {
        const decimalCount = (stake.match(/\d/g) || []).length;
        const separatorCount = stake.length - decimalCount;
        const averageDecimalWidth = this.charsetService.getAverageDecimalSize(this.defaultFont) + 0.1;
        const averageSeparatorWidth = this.charsetService.getAverageSeparatorSize(this.defaultFont);

        return decimalCount * averageDecimalWidth + separatorCount * averageSeparatorWidth + this.defaultRightPadding + this.leftPadding;
    }

    private get spaceAvailable(): number {
        return this.containerWidth;
    }

    private get leftPadding(): number {
        return this.resizerOptions.hasError ? this.constants.stakeLeftPadding.withError : this.constants.stakeLeftPadding.withOutError;
    }

    private get valuesLoaded(): boolean {
        return !!this.childElement && !!this.defaultFont && !!this.defaultRightPadding && !!this.containerWidth;
    }
}
