import { createSelector } from '@ngrx/store';

import { BetslipType } from '../../core/betslip-type';
import { SlipId } from '../bet-generation/models';
import { getBetBuilderSlipIdFromString, getSingleSlipIdFromString, getSystemSlipIdFromString } from '../bet-generation/services/slip.utils';
import { betslipTypeStateSelector } from '../types/selectors';
import { IBetslipTypeState } from '../types/state';

export const selectLinearStakeForSlip = (slipId: SlipId, betslipType: BetslipType) =>
    createSelector(betslipTypeStateSelector, (types: IBetslipTypeState): string | null => {
        switch (betslipType) {
            case BetslipType.Combo:
                return types.comboBet.actualStake;

            case BetslipType.Teaser:
                return types.teaserBet.actualStake;

            case BetslipType.Single: {
                const pickEntry = Object.entries(types.singleBet.picks).find(([pickId]) => getSingleSlipIdFromString(pickId) === slipId);

                if (!pickEntry) {
                    return null;
                }

                const [, pick] = pickEntry;

                return pick.actualStake ?? null;
            }

            case BetslipType.System: {
                const type = Object.keys(types.systemBet.linearTypeStakes).find((k) => getSystemSlipIdFromString(k) === slipId);

                if (!type) {
                    return null;
                }

                const typeStake = types.systemBet.linearTypeStakes[type];

                return typeStake?.actualStake ?? null;
            }

            case BetslipType.EditBet: {
                return types.editBet.addedStake.toString();
            }

            case BetslipType.BetBuilder: {
                const pickEntry = Object.entries(types.betBuilder.picks).find(([pickId]) => getBetBuilderSlipIdFromString(pickId) === slipId);

                if (!pickEntry) {
                    return null;
                }

                const [, pick] = pickEntry;

                return pick.actualStake ?? null;
            }
        }
    });
