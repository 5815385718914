import { Injectable, Injector, NgZone, inject, runInInjectionContext } from '@angular/core';
import { PRIMARY_OUTLET, Params, Route, Router, UrlMatchResult, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';

import { TimerService } from '@frontend/vanilla/core';

import { SportRoute } from '../router/multi-route.model';
import { PATTERN_ALIAS, PathDetails, findPathDetails, getPreResolveRoutes } from './util';

// Using a global registry, so we can keep it populated across lazy-loaded
// modules with different parent injectors which create instance of the registry.
const globalRegistry: UrlTree[] = [];

@Injectable({ providedIn: 'root' })
export class PrefetchRegistry {
    readonly #router = inject(Router);
    readonly #injector = inject(Injector);
    readonly #ngZone = inject(NgZone);
    readonly #timerService = inject(TimerService);

    readonly #trees: UrlTree[] = globalRegistry;
    readonly #routeToPreResolve = new Map<string, (() => void)[]>();
    readonly #preResolveRoutes: Route[] = [];

    add(tree: UrlTree) {
        this.#trees.push(tree);
        this.#setLinkPreResolver(tree);
    }

    has(tree: UrlTree) {
        return this.#trees.some((t) => t === tree);
    }

    addPreResolvers(route: SportRoute) {
        this.#setPreResolver(route);
        const preResolveRoutes = getPreResolveRoutes(route);
        if (preResolveRoutes.length) {
            this.#preResolveRoutes.push(...preResolveRoutes);
        }
    }

    shouldPrefetch(route: SportRoute) {
        if (this.#trees.length === 0) return false; // Do not check if in prefetch trees if is empty
        const { url, matcherRoutes } = findPathDetails(this.#router.config, route);
        const tree = this.#router.parseUrl(url);
        tree['matchers'] = matcherRoutes;
        return this.#trees.some(containsTree.bind(null, tree));
    }

    preResolve(treeOrUrl: UrlTree | string) {
        const url = typeof treeOrUrl === 'string' ? treeOrUrl : this.#router.serializeUrl(treeOrUrl);
        if (this.#routeToPreResolve.has(url)) {
            const preResolvers = this.#routeToPreResolve.get(url) ?? [];
            preResolvers.forEach((preResolve) => {
                this.#timerService.scheduleIdleCallback(() => {
                    runInInjectionContext(this.#injector, () => {
                        this.#ngZone.runOutsideAngular(() => {
                            preResolve();
                        });
                    });
                });
            });
        }
    }

    #setLinkPreResolver(tree: UrlTree) {
        const preResolveFns: (() => void)[] = [];
        for (const route of this.#preResolveRoutes) {
            const preResolveRouteTree = this.#getExtendedTree(route);
            const matchResult = matchingSegmentGroups(tree.root, preResolveRouteTree.root, preResolveRouteTree.root.segments);
            if (matchResult) {
                preResolveFns.push(() => route.data!.preResolve({ data: route.data, params: matchResultToParams(matchResult) }));
            }
        }

        if (preResolveFns.length) {
            const url = this.#router.serializeUrl(tree);
            this.#routeToPreResolve.set(url, preResolveFns);
            this.preResolve(url);
        }
    }

    #setPreResolver(inputRoute: SportRoute) {
        const preResolveRoutes = getPreResolveRoutes(inputRoute);
        const urls = new Map<string, (() => void)[]>();
        preResolveRoutes.forEach((route) => {
            const matches = this.#getMatches(route);
            matches.forEach((match) => {
                const [[url, params]] = Object.entries(match);

                // The data passed to the preResolve fn is a basic mock of activated route
                const preResolve = () => route.data!.preResolve({ data: route.data, params });
                if (urls.has(url)) {
                    urls.get(url)!.push(preResolve);
                } else {
                    urls.set(url, [preResolve]);
                }
            });
        });
        for (const [url, preResolvers] of urls) {
            this.#routeToPreResolve.set(url, preResolvers);
            this.preResolve(url);
        }
    }

    #getExtendedTree(route: SportRoute) {
        const { url, matcherRoutes } = findPathDetails(this.#router.config, route);
        return extendTreeWithMatcherRoutes(this.#router.parseUrl(url), matcherRoutes);
    }

    #getMatches(route: SportRoute, trees = this.#trees): [{ [url: string]: Params }] {
        const tree = this.#getExtendedTree(route);

        return trees
            .map((registeredTree) => {
                const itemUrl = this.#router.serializeUrl(registeredTree);
                const matchingSegments = matchingSegmentGroups(registeredTree.root, tree.root, tree.root.segments);
                if (matchingSegments) {
                    return { [itemUrl]: matchResultToParams(matchingSegments) };
                }
                return null;
            })
            .filter(Boolean) as [{ [url: string]: Params }];
    }
}

function matchResultToParams(matches: boolean | UrlMatchResult[]): Params {
    const params = {};
    if (typeof matches === 'boolean') return params;
    for (const { posParams } of matches) {
        for (const p in posParams) {
            params[p] = posParams[p].path;
        }
    }
    return params;
}

function extendTreeWithMatcherRoutes(tree: UrlTree, matcherRoutes: SportRoute[]) {
    if (!matcherRoutes.length) return tree;

    extendUrlSegmentGroup(tree.root, matcherRoutes);

    return tree;
}

function extendUrlSegmentGroup(group: UrlSegmentGroup, matcherRoutes: SportRoute[], currentMatcher = 0) {
    for (const segment of group.segments) {
        if (segment.path === PATTERN_ALIAS) {
            segment['route'] = matcherRoutes[currentMatcher];
            currentMatcher++;
        }
    }
    if (group.hasChildren()) {
        for (const c in group.children) {
            extendUrlSegmentGroup(group.children[c], matcherRoutes, currentMatcher);
        }
    }
}

function containsQueryParams(container: Params, containee: Params): boolean {
    // TODO: This does not handle array params correctly.
    return Object.keys(containee).length <= Object.keys(container).length && Object.keys(containee).every((key) => containee[key] === container[key]);
}

function containsTree(containee: UrlTree, container: UrlTree): boolean {
    return (
        containsQueryParams(container.queryParams, containee.queryParams) &&
        containsSegmentGroup(container.root, containee.root, containee.root.segments, containee['matchers'])
    );
}

type UrlSegmentWithRoute = {
    route?: SportRoute;
} & UrlSegment;

function matchesSegmentGroup(registered: UrlSegment[], current: (UrlSegment | UrlSegmentWithRoute)[]): boolean | UrlMatchResult[] {
    if (registered.length !== current.length) return false;
    let results: UrlMatchResult[] = [];

    for (let i = 0; i < registered.length; i++) {
        if (registered[i].path === current[i].path) {
            continue;
        }
        if (current[i].path.startsWith(':') || registered[i].path.startsWith(':')) {
            continue;
        }

        if (current[i].path !== PATTERN_ALIAS) {
            return false;
        }
        const urlMatch: UrlMatchResult | null = current[i]['route'].matcher([registered[i]], {}, current[i]['route']);
        if (!urlMatch) {
            return false;
        }
        results.push(urlMatch);
    }
    return results.length ? results : true;
}

function matchingSegmentGroups(container: UrlSegmentGroup, containee: UrlSegmentGroup, containeePaths: UrlSegment[]): boolean | UrlMatchResult[] {
    if (container.segments.length > containeePaths.length) {
        const current = container.segments.slice(0, containeePaths.length);
        return !containee.hasChildren() && matchesSegmentGroup(current, containeePaths);
    }
    if (container.segments.length !== containeePaths.length) {
        const current = containeePaths.slice(0, container.segments.length);
        const matchResults = matchesSegmentGroup(container.segments, current);
        if (!matchResults || !container.children[PRIMARY_OUTLET]) return false;
        const next = containeePaths.slice(container.segments.length);
        const matchedChildren = matchingSegmentGroups(container.children[PRIMARY_OUTLET], containee, next); //@TODO needs to concat results
        if (matchedChildren) {
            if (matchResults === true) {
                return matchedChildren; // @TODO need to concat
            }
            if (matchedChildren === true) {
                return matchedChildren;
            }
            return matchResults.concat(matchedChildren);
        }
    }
    const matchResults = matchesSegmentGroup(container.segments, containeePaths);
    if (!matchResults) return false;
    if (!containee.hasChildren()) return matchResults;

    const results: UrlMatchResult[] = typeof matchResults === 'boolean' ? [] : matchResults;
    let result = false;
    for (const c in containee.children) {
        if (!container.children[c]) break;
        const matchedChildren = matchingSegmentGroups(container.children[c]!, containee.children[c]!, containee.children[c]!.segments);
        if (typeof matchedChildren !== 'boolean') {
            results.push(...matchedChildren);
        } else if (matchedChildren) {
            result = matchedChildren;
        }
    }
    if (results.length > 0) {
        return results;
    }
    return result;
}

function containsSegmentGroup(
    container: UrlSegmentGroup,
    containee: UrlSegmentGroup,
    containeePaths: UrlSegment[],
    matchers: PathDetails['matcherRoutes'],
): boolean {
    if (container.segments.length > containeePaths.length) {
        const current = container.segments.slice(0, containeePaths.length);
        if (!equalPath(current, containeePaths, matchers)) return false;
        return !containee.hasChildren();
    } else if (container.segments.length === containeePaths.length) {
        if (!equalPath(container.segments, containeePaths, matchers)) return false;
        if (!containee.hasChildren()) return true;

        for (const c in containee.children) {
            if (!container.children[c]) break;
            if (containsSegmentGroup(container.children[c]!, containee.children[c]!, containee.children[c]!.segments, matchers)) return true;
        }
        return false;
    } else {
        const current = containeePaths.slice(0, container.segments.length);
        const next = containeePaths.slice(container.segments.length);
        if (!equalPath(container.segments, current, matchers)) return false;
        if (!container.children[PRIMARY_OUTLET]) return false;
        return containsSegmentGroup(container.children[PRIMARY_OUTLET], containee, next, matchers);
    }
}

function equalPath(as: UrlSegment[], bs: UrlSegment[], matchers: PathDetails['matcherRoutes']): boolean {
    if (as.length !== bs.length) return false;
    return as.every((a, i) => a.path === bs[i].path || a.path.startsWith(':') || bs[i].path.startsWith(':') || matchesMatcher(a, bs, i, matchers));
}

function matchesMatcher(a: UrlSegment, bs: UrlSegment[], i: number, matchers?: PathDetails['matcherRoutes']): boolean {
    if (matchers && matchers.length && matchers[0].matcher && bs[i].path === PATTERN_ALIAS) {
        // @TODO: This does not remove the matcher so it does not work properly if route contains multiple URL matchers
        return !!matchers[0].matcher([a], [] as any, matchers[0]);
    }
    return false;
}
