import { Injectable } from '@angular/core';

import { WindowRef } from '../../browser/window/window-ref.service';
import { DslRecorderService } from '../dsl-recorder.service';
import { DslRecordable, DslValuesProvider } from '../dsl.models';

@Injectable()
export class RequestHeadersDslValuesProvider implements DslValuesProvider {
    constructor(
        private dslRecorderService: DslRecorderService,
        private windowRef: WindowRef,
    ) {}

    getProviders(): { [provider: string]: DslRecordable } {
        return {
            RequestHeaders: this.dslRecorderService.createRecordable('requestHeaders').createProperty({
                name: 'UserAgent',
                get: () => this.windowRef.nativeWindow.navigator.userAgent,
                deps: [],
            }),
        };
    }
}
