import { Injectable, OnDestroy } from '@angular/core';

import { find } from 'lodash-es';

import { ColumnLayoutService } from './column-layout.service';
import { ColumnComponentRegistration, ColumnSize, LayoutColumn } from './layout.model';

@Injectable({ providedIn: 'root' })
export class ColumnLayoutRegister implements OnDestroy {
    private resized: LayoutColumn[] = [];
    private readonly registeredComponents: ColumnComponentRegistration[] = [];

    constructor(private columnLayout: ColumnLayoutService) {}

    register(registration: ColumnComponentRegistration): void {
        this.columnLayout.register(registration);
        this.registeredComponents.push(registration);
    }

    resizeColumn(size: ColumnSize, column: LayoutColumn): void {
        if (!find(this.resized, column)) {
            this.resized.push(column);
        }

        this.columnLayout.setColumnSize(size, column);
    }

    ngOnDestroy(): void {
        this.clear();
    }

    private clear(): void {
        this.registeredComponents.forEach((registration) => this.columnLayout.remove(registration.component, registration.location));

        this.registeredComponents.splice(0, this.registeredComponents.length);
        this.resized.forEach((column) => this.columnLayout.setColumnSize(ColumnSize.Default, column));
    }
}
