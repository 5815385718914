import { Inject, Injectable } from '@angular/core';

import { CompetitionBatchRequest } from '@cds/query-objects';
import { CompetitionStatistics } from '@cds/statistics/competition';
import { FixtureStatistics } from '@cds/statistics/fixture';
import { Observable, firstValueFrom } from 'rxjs';

import { BaseCdsApi } from './cds-api.service';
import { BaseCdsApiFactory, CDS_API_FACTORY } from './cds-api.service.factory';

@Injectable({ providedIn: 'root' })
export class StatisticsApi {
    private cdsApi: BaseCdsApi;

    constructor(@Inject(CDS_API_FACTORY) cdsApiServiceFactory: BaseCdsApiFactory) {
        this.cdsApi = cdsApiServiceFactory({ endpoint: '/statistics' });
    }

    getCompetitionStatistics(
        competitionId: number,
        sportId: number,
        stageId?: number,
        groupId?: number,
    ): Observable<CompetitionStatistics | undefined> {
        return this.cdsApi.get<CompetitionStatistics>('/competition', {
            competitionId,
            sportId,
            stageId,
            groupId,
        });
    }

    getCompetitionStatisticsBatch(competitionIds: string, sportId: number): Observable<CompetitionStatistics[] | undefined> {
        return this.cdsApi.get<CompetitionStatistics[]>('/competition-batch', {
            competitionIds,
            sportId,
        });
    }

    getCompetitionStatisticsBatchV2(competitionBatchRequest: CompetitionBatchRequest): Observable<CompetitionStatistics[] | undefined> {
        return this.cdsApi.post<CompetitionStatistics[]>('/competition-batch/v2', competitionBatchRequest);
    }

    getVirtualCompetitionStatistics(
        sportId: number,
        virtualCompetitionId: number,
        virtualCompetitionGroupIds?: string,
    ): Observable<CompetitionStatistics[] | undefined> {
        return this.cdsApi.get<CompetitionStatistics[]>('/virtual-competitions', {
            sportId,
            virtualCompetitionId,
            virtualCompetitionGroupIds,
        });
    }

    getFixtureStatisticsBatch(fixtureIds: string, sportId: number): Observable<FixtureStatistics[] | undefined> {
        return this.cdsApi.get<FixtureStatistics[]>('/fixtures-batch', {
            fixtureIds,
            sportId,
        });
    }

    getFixtureStatistics(fixtureId: string, sportId: number): Promise<FixtureStatistics | undefined> {
        return firstValueFrom(
            this.cdsApi.get<FixtureStatistics>('/fixture', {
                fixtureId,
                sportId,
            }),
        );
    }
}
