import { createSelector } from '@ngrx/store';

import { getLegsCount } from '../../core/utils';
import { betslipPicksListSelector } from '../picks/selectors';
import { betslipTypeStateSelector } from '../types/selectors';
import { BetBuilderPick, LinearBetBuilderPickId } from './models';

export const selectBetBuilderTypeState = createSelector(betslipTypeStateSelector, (betslipTypeState) => betslipTypeState.betBuilder);

export const selectBetBuilderTypeStatePicks = createSelector(selectBetBuilderTypeState, (betBuilderTypeState) => betBuilderTypeState.picks);

export const selectBetBuilderPicks = createSelector(
    selectBetBuilderTypeStatePicks,
    betslipPicksListSelector,
    (betBuilderPicks, pickList) => pickList.filter((pick) => !!betBuilderPicks[pick.id.toString()]) as BetBuilderPick[],
);

export const selectBetBuilderPickStakeFactory = (pickId: LinearBetBuilderPickId) =>
    createSelector(selectBetBuilderTypeStatePicks, (picks) => {
        const pick = picks[pickId.toString()];

        if (!pick) {
            return {
                actualStake: null,
                stake: null,
            };
        }

        return {
            actualStake: pick.actualStake,
            stake: pick.stake,
        };
    });

export const selectBetBuilderPickLegsFactory = (pickId: LinearBetBuilderPickId) =>
    createSelector(betslipPicksListSelector, (pickList) => {
        const pick = pickList.find((p) => p.id.isEqual(pickId));

        return pick ? getLegsCount([pick]) : 0;
    });
