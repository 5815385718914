import { toLower, values } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { ColumnSize, ColumnSizeData, Layout, LayoutColumn, TaskScheduler } from './layout.model';

export class MeasuredColumn {
    private sizes: Map<ColumnSize, number> = new Map<ColumnSize, number>();
    private _currentSize = ColumnSize.Default;

    constructor(readonly column: LayoutColumn) {
        this.sizes.set(ColumnSize.Default, 0);
    }

    get width(): number {
        return this.sizes.get(this._currentSize) || 0;
    }

    setWidth(width: number): void {
        this.sizes.set(this._currentSize, width);
    }

    setSize(size: ColumnSize): void {
        this._currentSize = size;
    }

    get currentSize(): ColumnSize {
        return this._currentSize;
    }
}

export class ColumnMeasurement {
    private _layoutSize: { [key: string]: MeasuredColumn } = {
        [LayoutColumn.Left]: new MeasuredColumn(LayoutColumn.Left),
        [LayoutColumn.Center]: new MeasuredColumn(LayoutColumn.Center),
        [LayoutColumn.Right]: new MeasuredColumn(LayoutColumn.Right),
    };

    private _columnSizeChanged = new BehaviorSubject<ColumnSizeData>({ column: LayoutColumn.Left, size: ColumnSize.Default, width: 0 });

    get columnSizeChanged(): Observable<ColumnSizeData> {
        return this._columnSizeChanged.asObservable();
    }

    get layoutSize(): { [key: string]: MeasuredColumn } {
        return this._layoutSize;
    }

    constructor(private deviceLayout: Layout) {}

    measureLayout(scheduler: TaskScheduler): void {
        const visibleColumns = this.deviceLayout.visibleColumns;
        const allMeasured = visibleColumns.every((c) => this._layoutSize[c].width > 0);

        if (allMeasured) {
            this.pushSizeChanges();

            return;
        }

        scheduler.schedule(() => {
            const left = this.getColumnWidth(LayoutColumn.Left);
            const center = this.getColumnWidth(LayoutColumn.Center);
            const right = this.getColumnWidth(LayoutColumn.Right);

            this._layoutSize[LayoutColumn.Right].setWidth(right);

            this._layoutSize[LayoutColumn.Left].setWidth(left);
            this._layoutSize[LayoutColumn.Center].setWidth(center);

            this.pushSizeChanges();
        });
    }

    updateColumnSize(change: { column: LayoutColumn; size: ColumnSize }): void {
        if (change && change.column !== LayoutColumn.Right) {
            throw new Error('Can set a ColumnSize only for LayoutColumn.Right. Attempted value: ' + change.column);
        }

        this._layoutSize[change.column].setSize(change.size);
    }

    reset(): void {
        values(this._layoutSize).forEach((mc) => mc.setWidth(0));
    }

    private pushSizeChanges(): void {
        this._columnSizeChanged.next({
            column: LayoutColumn.Left,
            size: ColumnSize.Default,
            width: this._layoutSize[LayoutColumn.Left].width,
        });
        this._columnSizeChanged.next({
            column: LayoutColumn.Center,
            size: ColumnSize.Default,
            width: this._layoutSize[LayoutColumn.Center].width,
        });
        this._columnSizeChanged.next({
            column: LayoutColumn.Right,
            size: this._layoutSize[LayoutColumn.Right].currentSize || ColumnSize.Default,
            width: this._layoutSize[LayoutColumn.Right].width,
        });
    }

    private getColumnWidth(column: LayoutColumn): number {
        const element = this.getElement(column);
        const getFloat = (value: string | null) => parseFloat(value || '0');

        if (!element) {
            return 0;
        }

        const style = getComputedStyle(element);

        const xPadding = getFloat(style.paddingLeft) + getFloat(style.paddingRight);
        const xBorder = getFloat(style.borderLeftWidth) + getFloat(style.borderRightWidth);

        // Element width and height minus padding and border
        return element.offsetWidth - xPadding - xBorder;
    }

    private getElement(column: LayoutColumn): HTMLElement | null {
        if (column === LayoutColumn.Center) {
            return document.querySelector<HTMLElement>(`.column-${toLower(column)}`) ?? document.querySelector<HTMLElement>(`.column-wrapper`);
        }

        return document.querySelector<HTMLElement>(`.column-${toLower(column)}`);
    }
}
