import { Injectable } from '@angular/core';

import { Observable, concat, count } from 'rxjs';

import { BestOddsFixtureLikeInput } from '../models/models';

@Injectable()
export abstract class BestOddsGuaranteedService {
    abstract isFixtureEligible(fixture: BestOddsFixtureLikeInput): Observable<boolean>;

    getEligibleFixturesCount(fixtures: BestOddsFixtureLikeInput[]): Observable<number> {
        return concat(...fixtures.map((f) => this.isFixtureEligible(f))).pipe(count((eligible) => eligible));
    }
}
