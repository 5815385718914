import { EdsPromoToken, PromoToken } from '@bpos/common/bet-placement';
import { Decimal } from 'decimal.js';

import { BetslipPick } from '../../core/picks/betslip-pick';
import { IBasePrice, PickOddsState } from '../../core/picks/pick-models';
import { SlipId } from '../../modules/bet-generation/models';
import { SlipType } from '../../modules/bet-generation/slip-type';

export enum OverAskFlowState {
    None, // OverAsk is not active
    Pending, // User is waiting for trader response,
    Review, // User is reviewing trader offer.
}

export enum OverAskRejectReason {
    CancelWaiting = 1,
    UserRejectOffer = 2,
    TraderRejectOffer = 3,
    AcceptOfferTimeout = 4,
    BettingOfferNotAvailable = 5,
    PlaceBetFailed = 6,
    TechnicalError = 7,
    ArcUserProfileRestriction = 8,
}

export enum OverAskBetslipError {
    OfferExpired = 'ErrorOfferExpired',
    OfferRejected = 'ErrorOfferRejected',
    FlowFailed = 'ErrorFlowFailed',
    PlaceBetFailed = 'ErrorPlaceBetFailed',
    MaximumWinOfBetExceeded = 'ErrorMaximumWinOfBetExceeded',
}

export enum OverAskMarketTypeChange {
    Win = 'WIN',
    EachWay = 'EW',
}

export interface OverAskPickChanges {
    price?: IBasePrice; // Change in the price
    marketType?: OverAskMarketTypeChange; // Change in market type.
}

export interface OverAskBetChange {
    stake?: Decimal; // Change in the bet stake
}

export interface OverAskPick {
    index: number;
    pick: BetslipPick;
    isEachWay: boolean;
    isBanker: boolean;
    price: IBasePrice;
    oddsState: PickOddsState;
    changes: OverAskPickChanges;
}

export interface OverAskBet {
    slipId: SlipId;
    index: number;
    stake: Decimal;
    type: SlipType;
    isRemoved: boolean;
    picks: OverAskPick[];
    changes: OverAskBetChange;
    promoTokens: PromoToken[];
    edsPromoTokens: EdsPromoToken[];
    betCount?: number;
}

export interface IOverAskMessages {
    acceptChanges: string;
    acceptChangesAndPlaceBet: string;
    cancel: string;
    newOdds: string;
    stake: string;
    newStake: string;
    pendingRejectDialogTitle: string;
    pendingBetPlacementStatus: string;
    pendingCheckingYourBet: string;
    reviewInfoMessage: string;
    rejectDialogCancelButton: string;
    pendingRejectDialogContent: string;
    pendingRejectDialogDismissButton: string;
    pendingTitle: string;
    reviewBadgeTitle: string;
    reviewLimitWarning: string;
    reviewInPlayBadgeTitle: string;
    reviewInPlayInfoMessage: string;
    reviewOfferExpires: string;
    reviewRejectDialogTitle: string;
    reviewRejectDialogContent: string;
    reviewRejectDialogDismissButton: string;
    summaryOfferChanges: string;
    removed: string;
    undo: string;
    totalOdds: string;
    totalStake: string;
    possibleWinnings: string;
    possibleWinningsNet: string;
    tax: string;
    maxReturn: string;
    ErrorOfferRejected: string;
    ErrorOfferExpired: string;
    ErrorOfferRejectedTitle: string;
    ErrorOfferExpiredTitle: string;
    ErrorFlowFailed: string;
    ErrorFlowFailedTitle: string;
    ErrorMaximumWinOfBetExceeded: string;
    ErrorOfferZeroStakeHint: string;
    betslipLockedToastMessage: string;
    winMarketAbbr: string;
    eachWayAbbr: string;
    stakeChangeHeader: string;
    stakeChangeMsg: string;
    stakeChangeToggle: string;
    editStakeChangeDesktopUrl: string;
    editStakeChangeMobileUrl: string;
}
