import { ElementRef, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';

import { InternalFeaturesConfig } from '@frontend/sports/common/client-config-data-access';

@Injectable({ providedIn: 'root' })
export class AutomationService implements OnDestroy {
    get isActive(): boolean {
        return this.internalFeatureConfig.isAutomationDebugEnabled;
    }

    constructor(
        rendererFactory: RendererFactory2,
        private internalFeatureConfig: InternalFeaturesConfig,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    private readonly attributeName = 'data-automation';
    private renderer: Renderer2;
    private destroy = () => {};

    private removeAllAttributes(elementRef: ElementRef): void {
        const element = elementRef.nativeElement as Element;
        const attributes = Object.keys(element.attributes)
            .map((key) => element.attributes[key].name)
            .filter((n) => n.startsWith(this.attributeName));

        for (const attr of attributes) {
            this.renderer.removeAttribute(element, attr);
        }
    }

    addAttr(elementRef: ElementRef, value: string | { [key: string]: any } | null | undefined): void {
        if (!this.isActive) {
            return;
        }

        this.removeAllAttributes(elementRef);

        if (value == null || typeof value === 'string') {
            // @ts-ignore No idea why there is a typescript error here.
            this.setSimpleAttribute(elementRef, value);
        } else {
            this.setExtendedAttribute(elementRef, value);
        }

        this.destroy = () => this.removeAllAttributes(elementRef);
    }

    private setSimpleAttribute(elementRef: ElementRef, value: string | null | undefined): void {
        if (typeof value === 'undefined') {
            this.renderer.setAttribute(elementRef.nativeElement, this.attributeName, 'undefined');

            return;
        }

        if (value === null) {
            this.renderer.setAttribute(elementRef.nativeElement, this.attributeName, 'null');

            return;
        }

        this.renderer.setAttribute(elementRef.nativeElement, this.attributeName, value);
    }

    private setExtendedAttribute(elementRef: ElementRef, value: { [key: string]: any }): void {
        const keys = Object.keys(value);

        for (const key of keys) {
            this.renderer.setAttribute(elementRef.nativeElement, `${this.attributeName}-${key}`, value[key]);
        }
    }

    ngOnDestroy(): void {
        this.destroy();
    }
}
