import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { HooksWireup, OnDestroyCleanup, isDefined } from '@frontend/sports/common/base-utils';
import { CurrencyConfig } from '@frontend/sports/common/client-config-data-access';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { TimerService } from '../common/timer.service';
import { UiManagerActions } from '../ui-manager/ui-manager.actions';
import { IUiRootState } from '../ui-manager/ui-manager.state';
import { UiStates } from '../ui-manager/ui-states.enum';
import { ISelectionProvider, NumpadOperation, NumpadState } from './model';
import { NumpadService } from './numpad.service';
import NumpadActions from './store/actions';
import { INumpadRootState } from './store/numpad.state';

export enum StakeInputBehavior {
    TypingAndNumpad,
    OnlyTyping,
}

@HooksWireup()
@Component({
    selector: 'ms-stake-input',
    templateUrl: './stake-input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StakeInputComponent extends OnDestroyCleanup implements OnInit, OnDestroy, ISelectionProvider {
    @Input() disabled: boolean;
    @Input() emptyCharacter: string;
    @Input() adornmentStart?: string;
    @Input() behavior = StakeInputBehavior.TypingAndNumpad;

    @ViewChild('inputRef', { static: true }) inputRef: ElementRef;

    private focused: boolean;
    private timeout?: () => void;

    private get stakeElm(): HTMLInputElement {
        return <HTMLInputElement>this.inputRef.nativeElement;
    }

    constructor(
        public numpadService: NumpadService,
        private timerService: TimerService,
        private currencyConfig: CurrencyConfig,
        private store: Store<INumpadRootState & IUiRootState>,
    ) {
        super();
    }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();

        this.store.dispatch(UiManagerActions.removeState({ state: UiStates.MarketSubTypeTooltipShown }));
    }

    ngOnInit(): void {
        this.numpadService.registerSelectionProvider(this);
        this.numpadService.state$.pipe(takeUntil(this.destroyed$)).subscribe((state) => this.stateChange(state));
    }

    private stateChange(state: NumpadState): void {
        this.stakeElm.value = state.stake.isEmpty
            ? ''
            : this.currencyConfig.showStakeCurrency
              ? state.stake.currencyFormatted
              : state.stake.formatted;

        if (state.operation === NumpadOperation.Open) {
            this.stakeElm.select();
            this.focus();
        }

        if (this.focused && state.isOpened && isDefined(state.selectionStart)) {
            this.focus(state.selectionStart);
        }
    }

    ngOnDestroy(): void {
        this.cleanTimeout();
    }

    onBlur(): void {
        this.timeout = this.timerService.setTimeoutOutsideAngular(() => {
            this.focused = false;
            this.store.dispatch(NumpadActions.numpadFocus({ numpadFocus: false }));
        }, 200);

        if (this.behavior === StakeInputBehavior.OnlyTyping) {
            this.numpadService.confirm();
        }
    }

    onFocus(): void {
        this.cleanTimeout();
        this.focused = true;
        this.store.dispatch(NumpadActions.numpadFocus({ numpadFocus: true }));

        this.numpadService.open();
    }

    onPaste(event: ClipboardEvent): void {
        const text = event.clipboardData?.getData('text');
        if (text) {
            this.numpadService.type(text);
        }
    }

    onKeyUp($event: KeyboardEvent): void {
        const target = $event.target as HTMLInputElement;

        this.numpadService.type(target.value);
    }

    getSelection(currentValue: string): { start: number; end: number } {
        const length = currentValue.length;
        if (this.focused) {
            return {
                start: this.stakeElm.selectionStart !== null ? this.stakeElm.selectionStart : length,
                end: this.stakeElm.selectionEnd !== null ? this.stakeElm.selectionEnd : length,
            };
        }

        return {
            start: length,
            end: length,
        };
    }

    private cleanTimeout(): void {
        if (this.timeout) {
            this.timeout();
            delete this.timeout;
        }
    }

    private focus(position?: number): void {
        this.timerService.setTimeoutOutsideAngular(() => {
            if (position) {
                this.stakeElm.setSelectionRange(position, position);
            }

            this.stakeElm.focus();
        });
    }
}
