import { SystemSlipType } from '../bet-generation/slip-type';

export enum SystemKey {
    Patent = 'patent',
    Lucky15 = 'lucky15',
    Lucky31 = 'lucky31',
    Lucky63 = 'lucky63',
    Trixie = 'trixie',
    Yankee = 'yankee',
    Canadian = 'canadian',
    Heinz = 'heinz',
    SuperHeinz = 'superheinz',
    Goliath = 'goliath',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    MOfN = 'mofn',
    BankerSingles = 'systembankersingles',
}

export enum SystemModesLinearMOfN {
    System2of3 = 'system2of3',
    System2of4 = 'system2of4',
    System2of5 = 'system2of5',
    System2of6 = 'system2of6',
    System2of7 = 'system2of7',
    System2of8 = 'system2of8',
    System3of4 = 'system3of4',
    System3of5 = 'system3of5',
    System3of6 = 'system3of6',
    System3of7 = 'system3of7',
    System3of8 = 'system3of8',
    System4of5 = 'system4of5',
    System4of6 = 'system4of6',
    System4of7 = 'system4of7',
    System4of8 = 'system4of8',
    System5of6 = 'system5of6',
    System5of7 = 'system5of7',
    System5of8 = 'system5of8',
    System6of7 = 'system6of7',
    System6of8 = 'system6of8',
    System7of8 = 'system7of8',
}

export interface SystemModes {
    canadian: string;
    goliath: string;
    heinz: string;
    lucky15: string;
    lucky31: string;
    lucky63: string;
    patent: string;
    superheinz: string;
    system2of3: string;
    system2of4: string;
    system2of5: string;
    system2of6: string;
    system2of7: string;
    system2of8: string;
    system3of4: string;
    system3of5: string;
    system3of6: string;
    system3of7: string;
    system3of8: string;
    system4of5: string;
    system4of6: string;
    system4of7: string;
    system4of8: string;
    system5of6: string;
    system5of7: string;
    system5of8: string;
    system6of7: string;
    system6of8: string;
    system7of8: string;
    trixie: string;
    yankee: string;
}

export interface ISlipInfo {
    slipType: SystemSlipType;
    betCount: number;
}

export enum LinearSystemBetTracking {
    SystemBetPositionEvent = 'Sytem bet expand/collapse',
    HidePickListEventDetails = 'Collapse system bet',
    ViewPickListEventDetails = 'Expand system bet',
    SystemBetPosition = 'system bet',
}

export interface ISystemSlipTypeInfo {
    slipType: SystemSlipType;
    betCount: number;
    typeName: string;
}

export const LinearSystemBetPositionEvent = 'Linear System Bet Info Popup';
export const LinearSystemBetEventDetailsOpened = 'System Bet Info Icon Open';
export const LinearSystemBetEventDetailsClosed = 'System Bet Info Icon Closed';
export const LinearSystemBetActionEvent = 'Click';
