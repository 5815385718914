import { Injectable } from '@angular/core';

import { InternalFeaturesConfig } from '@frontend/sports/common/client-config-data-access';
// eslint-disable-next-line no-restricted-imports
import { RemoteLogger } from '@frontend/vanilla/core';

import { DebugLogger } from './debug-logger.service';
import { SportsRemoteLogger } from './sports-remote-logger.service';

@Injectable({
    providedIn: 'root',
})
export class LoggerFactory {
    constructor(
        private vanillaLogger: RemoteLogger,
        private internalFeatures: InternalFeaturesConfig,
    ) {}

    getLogger(loggerName: string): SportsRemoteLogger {
        return new SportsRemoteLogger(loggerName, this.vanillaLogger);
    }

    getDebugLogger(loggerName: string): DebugLogger {
        return new DebugLogger(`Debug${loggerName}`, this.vanillaLogger, this.internalFeatures);
    }
}
