import { Injectable } from '@angular/core';

import { Decimal } from 'decimal.js';

import { NumpadUtilsService } from './numpad-utils.service';

@Injectable({ providedIn: 'root' })
export class StakeUpdateService {
    constructor(private utils: NumpadUtilsService) {}

    increment(currentValue: string, value: number): string {
        return new Decimal(currentValue || 0).add(value).toString();
    }

    remove(value: string, start: number, end: number): { value: string; position: number } {
        if (value.length === 0 || (start === 0 && end === 0)) {
            return {
                value,
                position: 0,
            };
        }

        const moreCharactersSelected = start !== end;
        const position = moreCharactersSelected ? start : start ? start - 1 : 0;

        const newValue = this.slice(value, position, end);

        return {
            value: newValue,
            position,
        };
    }

    append(value: string, character: string, start: number, end: number): { value: string; position: number } {
        let newValue = this.slice(value, start, end, character);

        if (!this.utils.isValidInput(newValue)) {
            return {
                value,
                position: start,
            };
        }

        newValue = this.utils.normalizeInput(newValue);

        const allCharactersSelected = end - start === value.length;
        const position = !value.length || allCharactersSelected ? newValue.length : start + 1;

        return {
            value: newValue,
            position,
        };
    }

    private slice(value: string, start: number, end: number, character?: string): string {
        character = character || '';

        return value.slice(0, start) + character + value.slice(end);
    }
}
