import { Injectable } from '@angular/core';

import { StringDictionary, hasValue } from '@frontend/sports/common/base-utils';
import { OnAppInit } from '@frontend/vanilla/core';
import { flatten, keyBy, uniq } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { CrmOfferLoaderService } from '../crm-offer-data/crm-offer-loader.service';
import { Filter, Promotion } from '../crm-offer-data/crm-offer.model';
import { Campaign } from '../crm-offer-data/crm-offer.server.model';
import { CdsSubscriptionService } from './cds-subscription.service';
import { CrmPromotionFactory } from './crm-promotion.factory';

export interface PromotionMap {
    sports: StringDictionary<number>;
    meetings: StringDictionary<number>;
    competitions: StringDictionary<number>;
    fixtures: StringDictionary<string>;
    templateIds: StringDictionary<string>;
    markets: StringDictionary<number>;
    options: StringDictionary<number>;
}

@Injectable({ providedIn: 'root' })
export class CrmPromotionProviderService implements OnAppInit {
    private _promotionsMap$ = new BehaviorSubject<PromotionMap>({
        sports: {},
        meetings: {},
        competitions: {},
        fixtures: {},
        templateIds: {},
        markets: {},
        options: {},
    });

    private _promotions$ = new BehaviorSubject<Promotion[]>([]);

    readonly promotionsMap$ = this._promotionsMap$.asObservable();
    readonly promotions$ = this._promotions$.asObservable();

    private subscribedPromotions: StringDictionary<string> = {};

    constructor(
        private crmPromotionLoader: CrmOfferLoaderService,
        private crmPromotionFactory: CrmPromotionFactory,
        private subscriptionService: CdsSubscriptionService,
    ) {}

    onAppInit(): void {
        this.crmPromotionLoader.promotions$
            .pipe(
                map((campaigns) => this.mapPromotions(campaigns)),
                tap((promotions) => this.promotionChanged(promotions)),
                switchMap((promos) => this.subscribeToPush(promos)),
            )
            .subscribe();
    }

    private subscribeToPush(promos: Promotion[]): Promotion[] {
        if (!this.hasNewPromotions(promos)) {
            return promos;
        }

        this.subscribedPromotions = {};
        promos.forEach((promo) => (this.subscribedPromotions[promo.id] = promo.id));

        this.subscriptionService.subscribeToPush(promos).subscribe((promotions) => this.promotionChanged(promotions));

        return promos;
    }

    private hasNewPromotions(promos: Promotion[]): boolean {
        return promos.some((promo) => !this.subscribedPromotions[promo.id]);
    }

    private mapPromotions(campaigns: Campaign[]): Promotion[] {
        return this.crmPromotionFactory.toModelList(campaigns);
    }

    private promotionChanged(promotions: Promotion[]): void {
        const filters = flatten(promotions.map((promotion) => promotion.allFilters)).filter(hasValue);
        const promoMap = this.getPromotionMap(filters);

        this._promotionsMap$.next(promoMap);
        this._promotions$.next(promotions);
    }

    private getPromotionMap(filters: Filter[]): PromotionMap {
        return {
            sports: keyBy(uniq(flatten(filters.map((f) => f.sportIds)))),
            meetings: keyBy(uniq(flatten(filters.map((f) => f.meetingIds)))),
            competitions: keyBy(uniq(flatten(filters.map((f) => f.competitionIds)))),
            fixtures: keyBy(uniq(flatten(filters.map((f) => f.fixtureIds)))),
            templateIds: keyBy(uniq(flatten(filters.map((f) => f.marketTemplateIds)))),
            markets: keyBy(uniq(flatten(filters.map((f) => f.marketIds)))),
            options: keyBy(uniq(flatten(filters.map((f) => f.optionIds)))),
        };
    }
}
