import { NgClass, NgIf } from '@angular/common';
import { Component, HostBinding, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'ms-toaster',
    template: `
        <i *ngIf="toast.icon" [ngClass]="toast.icon"></i>
        <span [innerHTML]="toast.message"></span>
        <i class="theme-ex" (click)="toast.dismiss()"></i>
    `,
    standalone: true,
    imports: [NgIf, NgClass],
})
export class ToasterComponent {
    @HostBinding('class') className = 'toaster';

    // TODO Angular 14 update: Setting type for the toast parameter is breaking the tests. Investigation needed.
    constructor(@Inject(MAT_SNACK_BAR_DATA) public toast: any) {}
}
