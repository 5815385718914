import { Directive, EventEmitter, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';

import { ComponentLoaderComponent } from './component-loader.component';
import { ComponentProxy } from './component-loader.service';

@Directive({
    selector: '[msComponentProxy]',
    standalone: true,
})
export class ComponentProxyDirective implements OnDestroy {
    private componentProxy: ComponentProxy<any> | undefined;

    @Output() componentLoaded = new EventEmitter<void>();

    @Input() set msComponentProxyData(data: any | undefined) {
        if (data && this.componentProxy) {
            this.componentProxy.update(data);
        }
    }

    @Input() set msComponentProxy(value: ComponentProxy<any> | undefined) {
        this.destroy();

        this.componentProxy = value;

        this.viewRef.createComponent(ComponentLoaderComponent);

        if (!this.componentProxy) {
            return;
        }

        this.componentProxy.create(this.viewRef).subscribe(() => this.componentLoaded.next());
    }

    constructor(private viewRef: ViewContainerRef) {}

    ngOnDestroy(): void {
        this.destroy();
    }

    private destroy(): void {
        this.componentProxy?.destroy();
        this.viewRef.clear();
    }
}
