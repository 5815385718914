import { Injectable, NgZone } from '@angular/core';

import { WindowRef } from '@frontend/vanilla/core';

enum ScrollDirection {
    Left = 'Left',
    Right = 'Right',
    Top = 'Top',
    Bottom = 'Bottom',
}

@Injectable({ providedIn: 'root' })
export class SmoothScroll {
    constructor(
        private _window: WindowRef,
        private zone: NgZone,
    ) {}

    private requestAnimationFrameFn =
        (this._window.nativeWindow.requestAnimationFrame && this._window.nativeWindow.requestAnimationFrame.bind(window)) || this.fn;

    private fn(cb: Function): void {
        cb();
    }

    scrollLeftTo(el: HTMLElement, target: number, duration: number, forceDuration: boolean = false): void {
        this.smoothScrollTo(el, target, duration, ScrollDirection.Left, forceDuration);
    }

    scrollTopTo(el: HTMLElement, target: number, duration: number, forceDuration: boolean = false): void {
        this.smoothScrollTo(el, target, duration, ScrollDirection.Top, forceDuration);
    }

    private smoothScrollTo(element: Element, target: number, duration: number, direction: ScrollDirection, forceDuration: boolean): void {
        if ('scrollBehavior' in document.documentElement.style && !forceDuration) {
            const scrollToOptions: ScrollToOptions = {
                behavior: 'smooth',
            };
            scrollToOptions[direction.toLowerCase()] = target;
            element.scroll(scrollToOptions);
        } else {
            this.doStepScrolling(element, target, duration, direction);
        }
    }

    /* istanbul ignore next */
    private doStepScrolling(element: Element, target: number, duration: number, direction: ScrollDirection): void {
        target = Math.round(target);
        duration = Math.round(duration);
        const scrollDirection = `scroll${direction}`;
        if (duration < 0) {
            return;
        }
        if (duration === 0) {
            element[scrollDirection] = target;

            return;
        }
        if (!element || isNaN(element[scrollDirection])) {
            return;
        }

        const startTime = Date.now();
        const endTime = startTime + duration;
        const startPos = element[scrollDirection];
        const previousPos = startPos;
        const distance = target - startPos;

        // boostrap the animation process
        this.runRafOutsideZone(() => this.scrollFrame(true, startTime, endTime, startPos, previousPos, element, distance, scrollDirection));
    }

    // This is like a think function from a game loop
    private scrollFrame(
        firstRun: boolean,
        startTime: number,
        endTime: number,
        startPos: number,
        previousPos: number,
        element: Element,
        distance: number,
        scrollDirection: string,
    ): void {
        if (!firstRun && element && element[scrollDirection] !== previousPos) {
            return;
        }

        firstRun = false;
        // set the scroll value for this frame
        const now = Date.now();
        const point = this.smoothStep(startTime, endTime, now);
        const frameTop = Math.round(startPos + distance * point);
        element[scrollDirection] = frameTop;

        // check if we're done!
        if (now >= endTime) {
            return;
        }

        // If we were supposed to scroll but didn't, then we
        // probably hit the limit, so consider it done
        if (element[scrollDirection] === previousPos && element[scrollDirection] !== frameTop) {
            // return;
        }
        previousPos = element[scrollDirection];

        // schedule next frame for execution
        this.runRafOutsideZone(() => this.scrollFrame(false, startTime, endTime, startPos, previousPos, element, distance, scrollDirection));
    }

    // based on http://en.wikipedia.org/wiki/Smoothstep
    private smoothStep(start: number, end: number, point: number): number {
        if (point <= start) {
            return 0;
        }
        if (point >= end) {
            return 1;
        }
        const x = (point - start) / (end - start); // interpolation

        return x * x * (3 - 2 * x);
    }

    private runRafOutsideZone(callback: Function): void {
        this.zone.runOutsideAngular(() => this.requestAnimationFrameFn(callback));
    }
}
