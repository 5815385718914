<div class="stake-input-container" [ngClass]="{ disabled: disabled }">
    <i class="theme-error-i" (click)="onFocus()"></i>
    <span *ngIf="adornmentStart" class="stake-input-adornment stake-input-adornment--start">{{ adornmentStart }}</span>

    <input
        class="stake-input-value"
        type="text"
        placeholder="{{ emptyCharacter }}"
        msValidateStakeInput
        #inputRef
        autocomplete="off"
        [attr.disabled]="disabled ? '' : null"
        (focus)="onFocus()"
        (paste)="onPaste($event)"
        (keyup)="onKeyUp($event)"
        (blur)="onBlur()" />
</div>
