import { PRIMARY_OUTLET, Route, Routes } from '@angular/router';

import { SportRoute } from '../router/multi-route.model';

export const PATTERN_ALIAS = '__MatcherPlaceholder__';

export type PathDetails = { url: string; matcherRoutes: SportRoute[] };

// copy-paste of ngx-quicklink
export const findPathDetails = (config: SportRoute[], route: SportRoute): PathDetails => {
    config = config.slice();
    const parent = new Map<Route, Route>();
    const visited = new Set<Route>();
    while (config.length) {
        const el = config.shift();
        if (!el) {
            continue;
        }
        visited.add(el);
        if (el === route) break;

        let children = el.children ?? (el as any)._loadedRoutes ?? [];

        const lazyChildren = (el as any)._loadedRoutes || [];

        for (const lazyChild of lazyChildren) {
            if (lazyChild && lazyChild.children) {
                children = children.concat(lazyChild.children);
            }
        }
        children.forEach((r: Route) => {
            if (visited.has(r)) return;
            parent.set(r, el);
            config.push(r);
        });
    }
    let path = '';
    let current: Route | undefined = route;
    let matcherRoutes: SportRoute[] = [];

    while (current) {
        const currentPath = current.matcher ? PATTERN_ALIAS : current.path;
        if (current.matcher) {
            matcherRoutes.unshift(current);
        }
        if (isPrimaryRoute(current)) {
            path = `/${currentPath}${path}`;
        } else {
            path = `/(${current.outlet}:${currentPath}${path})`;
        }
        current = parent.get(current);
    }
    /**
     * Use replace with regex to sanitize generated url
     * 1. Remove duplicate slashes
     *  - If route contains multiple /  it will transform them only
     *    example url -> ///section///sub-section/// = /section/sub-section/
     * 2. Remove trailing slash
     *   If url contains a trailing slash it will Segment to the UrlSegmentGroup with an empty path string
     *   because of this the tree never matches in the
     *   example url -> /section/sub-section/ = /section/sub-section
     */
    const url = path.replace(/[\/]+/g, '/').replace(/\/$/, '');
    return { url, matcherRoutes };
};

export function getPreResolveRoutes(route: Route) {
    const preResolveRoutes = [];
    if (route.data?.preResolve) {
        preResolveRoutes.push(route);
    }
    const children: Routes = route.children ?? route['_loadedRoutes'];
    if (children?.length) {
        children.forEach((child) => {
            preResolveRoutes.push(...getPreResolveRoutes(child));
        });
    }
    return preResolveRoutes;
}

function isPrimaryRoute(route: Route) {
    return route.outlet === PRIMARY_OUTLET || !route.outlet;
}
