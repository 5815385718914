export enum SitemapRoutingToken {
    SitemapHomeResolver = 'SitemapHomeResolver',
    SitemapEmptyResolver = 'SitemapEmptyResolver',
    CalendarSitemapResolver = 'CalendarSitemapResolver',
    EventDetailsSitemapResolver = 'EventDetailsSitemapResolver',
    SportSitemapResolver = 'SportSitemapResolver',
    CompetitionSitemapResolver = 'CompetitionSitemapResolver',
    CouponSitemapResolver = 'CouponSitemapResolver',
    RegionSitemapResolver = 'RegionSitemapResolver',
    StandingsSitemapResolver = 'StandingsSitemapResolver',
    LiveSitemapResolver = 'LiveSitemapResolver',
    MarketGroupSitemapResolver = 'MarketGroupSitemapResolver',
    FavouritesSitemapResolver = 'FavouritesSitemapResolver',
    MultiBuilderSitemapResolver = 'MultiBuilderSitemapResolver',
    EsportsSitemapResolver = 'EsportsSitemapResolver',
    MultiSportsHubSitemapResolver = 'MultiSportsHubSitemapResolver',
}
