export enum UiStates {
    Home = 'HOME',
    EditBet = 'EDIT_BET',
    EditBetAddSelection = 'EDIT_BET_ADD_SELECTION',
    EditBetAddStake = 'EDIT_BET_ADD_STAKE',
    EditBetSwapSelection = 'EDIT_BET_SWAP_SELECTION',
    EditBetResult = 'EDIT_BET_RESULT',
    RightColumnVisible = 'RIGHT_COLUMN_VISIBLE',
    QuickBet = 'QUICK_BET',
    QuickBetBuilder = 'QUICK_BET_BUILDER',
    LineSwitcher = 'LINE_SWITCHER',
    OverAsk = 'OVER_ASK',
    SheetViewOpened = 'SHEET_VIEW_OPENED',
    MarketSubTypeTooltipShown = 'MARKET_SUB_TYPE_TOOLTIP_SHOWN',
}

export const uiStatesThatShouldBeRestored_NotAuthenticated: UiStates[] = [UiStates.Home, UiStates.QuickBet, UiStates.OverAsk]; // TODO: Move OverAsk to Authenticated states in the final version
export const uiStatesThatShouldBeRestored_Authenticated: UiStates[] = [...uiStatesThatShouldBeRestored_NotAuthenticated];
