import { ParticipantMarketStatus, ParticipantOption, ParticipantPriceType, ParticipantType } from '@cds/betting-offer';

import { BetslipPick } from './betslip-pick';
import { BetslipV2ParticipantPick } from './betslip-v2-participant-pick';
import { V2WinPickId } from './pick-id';
import { IBetslipV2PickParticipant, IBetslipV2PickWinParticipantMarket, IBetslipV2WinParticipantPickStorage, IPrice, PriceType } from './pick-models';
import { SignedName } from './signed-name.model';

/**
 * General Win market pick
 */
export abstract class BetslipV2WinParticipantPick extends BetslipV2ParticipantPick {
    override id: V2WinPickId;
    override market: IBetslipV2PickWinParticipantMarket;

    static override isPick(pick: BetslipPick): pick is BetslipV2WinParticipantPick {
        return pick instanceof BetslipV2WinParticipantPick;
    }

    protected constructor() {
        super();
    }

    protected override initPropertiesFromJSON(value: IBetslipV2WinParticipantPickStorage): void {
        super.initPropertiesFromJSON(value);
        this.market = value.market;
    }

    protected override updateFromParticipantOptions(markets: ParticipantOption[]): void {
        super.updateFromParticipantOptions(markets);
        if (markets.length) {
            const fixedMarket = markets.find((m) => m.priceType === ParticipantPriceType.Fixed);
            const startingPriceMarket = markets.find((m) => m.priceType === ParticipantPriceType.StartingPrice);
            this.market.isStartingPriceAvailable = !!(startingPriceMarket && startingPriceMarket.marketStatus === ParticipantMarketStatus.Visible);
            this.market.isVisible = !!(fixedMarket && fixedMarket.marketStatus === ParticipantMarketStatus.Visible);
            this.market.isEachWay = (fixedMarket && fixedMarket.properties.isEachWay) || false;
            this.market.placeTerms = (fixedMarket && fixedMarket.properties.placeTerms) || null;
        }
    }

    override isMarketVisible(): boolean {
        if (!this.currentPrice) {
            return false;
        }
        switch (this.currentPrice.type) {
            case PriceType.Fixed:
                return this.market.isVisible;
            case PriceType.StartingPrice:
                return this.market.isStartingPriceAvailable;
            default:
                // only regular price ans starting price for win market
                return false;
        }
    }

    isStartingPriceAvailable(): boolean {
        return this.market.isStartingPriceAvailable && this.prices.some((pr) => pr.type === PriceType.StartingPrice && pr.isVisible);
    }

    isFixedPriceAvailable(): boolean {
        return this.market.isVisible && this.prices.some((pr) => pr.type === PriceType.Fixed && pr.isVisible);
    }

    override toJSON(): IBetslipV2WinParticipantPickStorage {
        const base = super.toJSON();

        return {
            ...base,
            market: {
                id: this.market.id,
                isVisible: this.market.isVisible,
                isStartingPriceAvailable: this.market.isStartingPriceAvailable,
                isEachWay: this.market.isEachWay,
                marketType: this.market.marketType,
                placeTerms: this.market.placeTerms && { ...this.market.placeTerms },
                isBetBuilderEnabled: false,
            },
        };
    }

    get participant(): IBetslipV2PickParticipant {
        return this.participants[0];
    }

    get isEachWay(): boolean {
        return this.market.isEachWay && this.participant.type !== ParticipantType.Favourite;
    }

    override get optionName(): SignedName {
        return this.participant.name;
    }

    override get prices(): IPrice[] {
        // Small optimization over participants pick, not to use flatArray. Here we know that there is only one participant
        return this.participant.prices.filter((pr) => pr.marketId === this.market.id);
    }

    override get currentPrice(): IPrice | undefined {
        return this.prices.find((p) => p.type === this.priceType);
    }

    abstract override copy(): BetslipV2WinParticipantPick;
}
