import { Directive, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { ISimpleChanges } from '@frontend/sports/common/base-utils';

@Directive({
    selector: '[msToolbarButtonsHeight]',
})
export class ToolbarButtonsHeightDirective implements OnChanges {
    @Input() isExpanded: boolean;

    private readonly MAX_BUTTONS_CONTAINER_WIDTH = 0.85;

    constructor(
        private renderer: Renderer2,
        private element: ElementRef,
    ) {}

    ngOnChanges(changes: ISimpleChanges<ToolbarButtonsHeightDirective>): void {
        if (changes.isExpanded?.currentValue && !changes.isExpanded?.previousValue) {
            this.trySetButtonsFixedHeight();
        }
    }

    private trySetButtonsFixedHeight(): void {
        const buttons = this.element.nativeElement.querySelectorAll('.toolbar-button') as HTMLElement[];
        const toolbarComponent = this.element.nativeElement.closest('.my-bets-toolbar');
        let allButtonsWidth = 0;

        for (const button of buttons) {
            allButtonsWidth += button.offsetWidth;
        }

        if (allButtonsWidth > toolbarComponent.offsetWidth * this.MAX_BUTTONS_CONTAINER_WIDTH) {
            buttons.forEach((b) => this.renderer.addClass(b, 'fixed-height'));
        }
    }
}
