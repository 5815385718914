import { ComboPreventionType } from '@cds/betting-offer';

export enum CombinationPrevention {
    /// <summary>
    /// No value was chosen for ComboPrevention.
    /// </summary>
    Default = 0,

    /// <summary>
    /// This bet can be combined with any other bet.
    /// </summary>
    AllCombo = 1,

    /// <summary>
    /// This bet cannot be combined with any other bet.
    /// </summary>
    NoCombo = 2,

    /// <summary>
    /// This bet cannot be combined with a bet from the same sport.
    /// </summary>
    NoSportCombo = 3,

    /// <summary>
    /// This bet cannot be combined with a bet from the same league.
    /// </summary>
    NoLeagueCombo = 4,

    /// <summary>
    /// This bet cannot be combined with a bet from the same event.
    /// </summary>
    NoEventCombo = 6,

    /// <summary>
    /// This bet can only be combined with a bet from the same league but a different event.
    /// </summary>
    OnlySameLeagueDifferentEvent = 7,

    /// <summary>
    /// This bet can only be combined with live bets
    /// </summary>
    OnlyLiveEvents = 8,

    /// <summary>
    /// This bet can only be combined with live bets from a different event.
    /// </summary>
    OnlyDifferentLiveEvents = 9,

    /// <summary>
    /// This bet cannot be combined with a bet from the same competition.
    /// </summary>
    NoCompetitionCombo = 10,

    /// <summary>
    /// This bet cannot be combined with a bet from the same fixture.
    /// </summary>
    NoFixtureCombo = 11,

    /// <summary>
    /// This bet cannot be combined with a bet from the same competition group.
    /// </summary>
    NoGroupCombo = 12,
}

export function toComboPrevention(status: ComboPreventionType): CombinationPrevention {
    switch (status) {
        case ComboPreventionType.AllCombo:
            return CombinationPrevention.AllCombo;
        case ComboPreventionType.AllLiveEvents:
            return CombinationPrevention.OnlyLiveEvents;
        case ComboPreventionType.NoCombo:
            return CombinationPrevention.NoCombo;
        case ComboPreventionType.NoEventCombo:
            return CombinationPrevention.NoEventCombo;
        case ComboPreventionType.NoFixtureCombo:
            return CombinationPrevention.NoFixtureCombo;
        case ComboPreventionType.NoEventSameLeagueCombo:
            return CombinationPrevention.OnlySameLeagueDifferentEvent;
        case ComboPreventionType.NoCompetitionCombo:
            return CombinationPrevention.NoCompetitionCombo;
        case ComboPreventionType.NoLeagueCombo:
            return CombinationPrevention.NoLeagueCombo;
        case ComboPreventionType.NoSportCombo:
            return CombinationPrevention.NoSportCombo;
        case ComboPreventionType.OtherLiveEvents:
            return CombinationPrevention.OnlyDifferentLiveEvents;
        case ComboPreventionType.NoGroupCombo:
            return CombinationPrevention.NoGroupCombo;
    }
}
