import { BetslipType } from '../../../core/betslip-type';
import { BetslipPick } from '../../../core/picks/betslip-pick';
import { PickId } from '../../../core/picks/pick-id';
import { SlipTypeKeys } from '../betslip-system-type';
import { ISlip, SlipId } from '../models';
import { ComboSlipType, SingleSlipType, SystemSlipType } from '../slip-type';

const SYSTEM_SLIP_ID_PREFIX = 'system__';
const BET_BUILDER_SLIP_ID_PREFIX = `${SlipTypeKeys.BetBuilder}__`;
const SINGLE_SLIP_ID_PREFIX = `${SlipTypeKeys.Single}__`;

export function getSlipBetslipType(slip: ISlip): BetslipType | undefined {
    const type = slip.type;

    if (type instanceof SingleSlipType) {
        return BetslipType.Single;
    }

    if (type instanceof ComboSlipType) {
        return BetslipType.Combo;
    }

    if (type instanceof SystemSlipType) {
        return BetslipType.System;
    }

    return undefined;
}

export function getEditBetSlipId(): string {
    return 'editbet';
}

export function getComboSlipId(): string {
    return SlipTypeKeys.Combo;
}

export function getSystemSlipId(slipType: SystemSlipType): string {
    return getSystemSlipIdFromString(slipType.key);
}

export function getSystemSlipIdFromString(slipKey: string): string {
    return `${SYSTEM_SLIP_ID_PREFIX}${slipKey}`;
}

export function isSystemSlipId(slipId: SlipId): boolean {
    return slipId.startsWith(SYSTEM_SLIP_ID_PREFIX);
}

export function getSystemTypeKeyFromSlipId(slipId: SlipId): string | undefined {
    if (!isSystemSlipId(slipId)) {
        return undefined;
    }

    return slipId.substring(SYSTEM_SLIP_ID_PREFIX.length);
}

export function isComboSlipId(slipId: SlipId): boolean {
    return slipId === getComboSlipId();
}

export function getSingleSlipId(pickId: PickId): string {
    return pickId ? getSingleSlipIdFromString(pickId.toString()) : '';
}

export function getSingleSlipIdFromString(pickId: string): string {
    return `${SINGLE_SLIP_ID_PREFIX}${pickId}`;
}

export function getBetBuilderSlipId(pickId: PickId): string {
    return getBetBuilderSlipIdFromString(pickId.toString());
}

export function getBetBuilderSlipIdFromString(pickId: string): string {
    return `${BET_BUILDER_SLIP_ID_PREFIX}${pickId}`;
}

export function isSingleSlipId(slipId: SlipId): boolean {
    return slipId.startsWith(`${SINGLE_SLIP_ID_PREFIX}`);
}

export function getTeaserSlipId(): string {
    return 'teaser';
}

export function isTeaserSlipId(slipId: SlipId): boolean {
    return slipId === getTeaserSlipId();
}

export function isBetBuilderSlipId(slipId: SlipId): boolean {
    return slipId.startsWith(BET_BUILDER_SLIP_ID_PREFIX);
}

export function getBetslipTypeFromId(slipId: SlipId): BetslipType | null {
    if (isSingleSlipId(slipId)) {
        return BetslipType.Single;
    }

    if (isComboSlipId(slipId)) {
        return BetslipType.Combo;
    }

    if (isTeaserSlipId(slipId)) {
        return BetslipType.Teaser;
    }

    if (isBetBuilderSlipId(slipId)) {
        return BetslipType.BetBuilder;
    }

    if (isSystemSlipId(slipId)) {
        return BetslipType.System;
    }

    return null;
}

/**
 * Returns a unique list of picks accross multiple slips
 */
export function getUniqueSlipPicks(slips: ISlip[]): BetslipPick[] {
    const allPicks = slips.flatMap((slip) => Object.values(slip.slipPicks));

    return allPicks.reduce(
        (acc, curr, i) => {
            if (acc.some((pick) => pick.id === curr.pick.id)) {
                return acc;
            }

            return acc.concat([curr.pick]);
        },
        <BetslipPick[]>[],
    );
}
