export enum RouteTag {
    AntepostFixture = 'AntepostFixture',
    Competition = 'Competition',
    CouponDetails = 'CouponDetails',
    Coupons = 'Coupons',
    EventDetails = 'EventDetails',
    Featured = 'Featured',
    HorseRaceDetails = 'HorseRaceDetails',
    GreyhoundRaceDetails = 'GreyhoundRaceDetails',
    Horses = 'Horses',
    RacingFuture = 'RacingFuture',
    RacingToday = 'RacingToday',
    RacingTomorrow = 'RacingTomorrow',
    RacingAntepostSpecials = 'RacingAntepostSpecials',
    RacingTodayResults = 'RacingTodayResults',
    RacingRaces = 'RacingRaces',
    Live = 'Live',
    LiveAll = 'LiveAll',
    LiveEsports = 'LiveEsports',
    LiveHighlights = 'LiveHighlights',
    LiveRegion = 'LiveRegion',
    LiveSport = 'LiveSport',
    LiveVideo = 'LiveVideo',
    LiveLeague = 'LiveLeague',
    LiveConference = 'LiveConference',
    Lobby = 'Lobby',
    MultiBuilder = 'MultiBuilder',
    MyBets = 'MyBets',
    Settings = 'Settings',
    SportList = 'SportList',
    SportLobby = 'SportLobby',
    VirtualFixture = 'VirtualFixture',
    MyTeams = 'MyTeams',
    TeamPages = 'TeamPages',
    HorsesBetFilterResults = 'HorsesBetFilterResults',
    EarlyPayoutSettings = 'EarlyPayoutSettings',
    GeneralBetslipSettings = 'GeneralBetslipSettings',
    WorldCupHub = 'WorldCupHub',
    EsportsLobby = 'EsportsLobby',
    TopScreenLobby = 'TopScreenLobby',
    MultiSportsLobby = 'MultiSportsLobby',
}
