import { exhaustiveCheckGuard } from './extended-types';

type PlaceholderValueType = string | number | boolean;
type StringInput = string | null | undefined;

/**
 * Format a string which is using double curly braces for param placement.
 *
 * @param str
 * @param obj
 * @param isCaseSensitive
 * @see interpolateSingleBracesString
 */
export function interpolateDoubleBracesString(str: StringInput, obj: Record<string, PlaceholderValueType>, isCaseSensitive: boolean = true): string {
    return interpolateStringInternal(str, obj, { placeholderStyle: PlaceholderStyle.DoubleCurlyBraces, caseSensitive: isCaseSensitive });
}

/**
 * Format a string which is using single curly braces for param placement.
 *
 * @param str string to format
 * @param obj object to be used as params
 * @param isCaseSensitive
 * @example
 * formatObject('Hello {developer}', { developer: 'MS2 Developer' })
 */
export function interpolateSingleBracesString(str: StringInput, obj: Record<string, PlaceholderValueType>, isCaseSensitive: boolean = true): string {
    return interpolateStringInternal(str, obj, { placeholderStyle: PlaceholderStyle.SingleCurlyBraces, caseSensitive: isCaseSensitive });
}

/**
 * Formats a string using array of values
 *
 * @param str string to format
 * @param args array of values to be used
 * @example
 * formatString('Hello {0}', ['MS2 Developer'])
 */
export function formatString(str: StringInput, ...args: PlaceholderValueType[]): string {
    return interpolateStringInternal(str, args, { placeholderStyle: PlaceholderStyle.SingleCurlyBraces });
}

enum PlaceholderStyle {
    SingleCurlyBraces = 1,
    DoubleCurlyBraces = 2,
}

interface FormatObjectOptions {
    caseSensitive: boolean;
    placeholderStyle: PlaceholderStyle;
}
const defaultFormatObjectOptions: FormatObjectOptions = {
    caseSensitive: true,
    placeholderStyle: PlaceholderStyle.SingleCurlyBraces,
};

function interpolateStringInternal(
    str: StringInput,
    obj: Record<string, PlaceholderValueType> | PlaceholderValueType[],
    options: Partial<FormatObjectOptions> = defaultFormatObjectOptions,
): string {
    if (!str) {
        return '';
    }

    const mergedOptions = Object.assign<FormatObjectOptions, Partial<FormatObjectOptions>>(defaultFormatObjectOptions, options);
    const flags = mergedOptions.caseSensitive ? 'gm' : 'gmi';

    let keyToPlaceholderAction: (placeholderName: string) => string = (placeholderName) => placeholderName;
    switch (mergedOptions.placeholderStyle) {
        case PlaceholderStyle.SingleCurlyBraces:
            keyToPlaceholderAction = (placeholderName) => `\\{${placeholderName}\\}`;
            break;
        case PlaceholderStyle.DoubleCurlyBraces:
            keyToPlaceholderAction = (placeholderName) => `\\{\\{${placeholderName}\\}\\}`;
            break;
        default:
            exhaustiveCheckGuard(mergedOptions.placeholderStyle);
    }

    let result = str;
    const keys = Object.keys(obj);
    for (const key of keys) {
        result = result.replace(new RegExp(keyToPlaceholderAction(key), flags), obj[key]);
    }

    return result;
}

/**
 * Returns hash value of a string
 *
 * @param str string to calculate
 
 */
export function hashCode(str: string): number {
    let hash = 0;
    let i;
    let chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + chr;
        // eslint-disable-next-line no-bitwise
        hash |= 0;
    }

    return hash;
}
