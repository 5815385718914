import { Injectable, NgZone } from '@angular/core';

import { OfferSource } from '@cds';
import { MessageEnvelope, MessageType } from '@cds/push';
import { InPlaySwitchCommand } from '@cds/push/fixture-commands';
import { bufferThrottle } from '@frontend/sports/common/base-utils';
import { BettingOfferConfig } from '@frontend/sports/common/client-config-data-access';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { isArray, without } from 'lodash-es';
import { Observable } from 'rxjs';
import { filter, finalize, map, share } from 'rxjs/operators';

import { TimerService } from '../common/timer.service';
import { EPSDetailedLogger } from '../logging/eps-detailed-logger.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { MainLiveEventsSwitchPayload, mainLiveEventsSwitchEvent } from './cds-dispatcher-events';
import { CdsPushProvider } from './cds-push.provider';

const separator = '|';
const topicSeparator = '-';

enum Topic {
    All = 'all',
    Grid = 'grd',
    Game = 'gam',
    NonGridable = 'ngrd',
    Specials = 'spc',
    Outrights = 'out',
    Scoreboard = 'sbf',
    ScoreboardSlim = 'sbs',
    OptionMarket = 'fxm',
    ParticipantMarket = 'fxp',
    Fixture = 'fxt',
    PlayerStats = 'psts',
}

export interface CdsSubscription {
    readonly callback: (message: MessageEnvelope) => void;
    readonly key: string[];
}

export abstract class BaseSubscription implements CdsSubscription {
    constructor(
        readonly contexts: string[] | undefined,
        readonly eventId: string,
        readonly lang: string,
        readonly callback: (message: MessageEnvelope) => void,
    ) {}

    protected abstract readonly suffix: string;
    get key(): string[] {
        if (!this.contexts) {
            //can be undefined for splitFixtures - setting this here explicitely with V1, as splitFixtures are always V1
            const ctx = this.getKey(OfferSource.V1, this.lang, this.eventId).toLowerCase();

            return [this.getKey(ctx, this.suffix)];
        }

        return this.contexts.map((ctx) => {
            const context = this.getContext(this.eventId, ctx, this.lang);

            return this.getKey(context, this.suffix);
        });
    }

    protected getTopic(topic: Topic, ...params: (string | number | undefined)[]): string {
        return [topic, ...params].join(topicSeparator);
    }

    protected getKey(...params: (string | number | undefined)[]): string {
        return params.filter((param) => param !== undefined && param.toString()).join(separator);
    }

    protected getContext(eventId: string, context: string | undefined, language: string): string {
        if (context) {
            return context;
        }

        return this.getKey(OfferSource.V1, language, eventId).toLowerCase();
    }
}

abstract class BaseMarketSubscription extends BaseSubscription {
    constructor(
        override readonly contexts: string[] | undefined,
        override readonly eventId: string,
        protected readonly marketId: string,
        override readonly lang: string,
        override readonly callback: (message: MessageEnvelope) => void,
    ) {
        super(contexts, eventId, lang, callback);
    }
}

export class GridSubscription extends BaseSubscription {
    protected readonly suffix: string = Topic.Grid;
}

export class NonGridableSubscription extends BaseSubscription {
    protected readonly suffix: string = Topic.NonGridable;
}

export class SpecialsSubscription extends BaseSubscription {
    protected readonly suffix: string = Topic.Specials;
}

export class OutrightsSubscription extends BaseSubscription {
    protected readonly suffix: string = Topic.Outrights;
}

export class ScoreboardSlimSubscription extends BaseSubscription {
    protected readonly suffix: string = Topic.ScoreboardSlim;
}

export class GameSubscription extends BaseMarketSubscription {
    protected readonly suffix: string = this.getTopic(Topic.Game, this.marketId);
}

export class OptionMarketSubscription extends BaseMarketSubscription {
    protected readonly suffix: string = this.getTopic(Topic.OptionMarket, this.marketId);
}

export class ParticipantMarketSubscription extends BaseMarketSubscription {
    protected readonly suffix: string = this.getTopic(Topic.ParticipantMarket, this.marketId);
}

export class FixtureAllSubscription extends BaseSubscription {
    protected readonly suffix: string = Topic.All;
}

export class FixtureSlimSubscription extends BaseSubscription {
    protected readonly suffix: string = Topic.Fixture;
}

export class ScoreboardSubscription extends BaseSubscription {
    protected readonly suffix: string = Topic.Scoreboard;
}

export class PlayerStatsSubscription extends BaseSubscription {
    protected readonly suffix: string = Topic.PlayerStats;
}

export class CachoutSubscription implements CdsSubscription {
    constructor(
        private readonly betNumber: string,
        readonly callback: (message: MessageEnvelope) => void,
    ) {}

    get key(): string[] {
        return ['eps|cashout:' + this.betNumber];
    }
}

export class CampaignSubscription implements CdsSubscription {
    constructor(
        private readonly params: { campaignId: string; userAccountId?: string },
        readonly callback: (message: MessageEnvelope) => void,
    ) {}

    get key(): string[] {
        return [['spc', this.params.campaignId, this.params.userAccountId].filter((key) => !!key).join(separator)];
    }
}

export class OverAskSubscription implements CdsSubscription {
    static createKey(offerId: string): string {
        return 'overask|offerupdate|' + offerId;
    }

    constructor(
        private readonly offerId: string,
        readonly callback: (message: MessageEnvelope) => void,
    ) {}

    get key(): string[] {
        return [OverAskSubscription.createKey(this.offerId)];
    }
}

export class BetBuilderSubscription implements CdsSubscription {
    static createKey(sgpId: string): string {
        return 'betbuilder|updates|' + sgpId;
    }

    constructor(
        private readonly context: string[],
        readonly callback: (message: MessageEnvelope) => void,
    ) {}

    get key(): string[] {
        return this.context.map((ctx) => BetBuilderSubscription.createKey(ctx));
    }
}

@Injectable({ providedIn: 'root' })
export class CdsPushService {
    private subscriptions: Map<string, ((message: MessageEnvelope) => void)[]> = new Map();
    private observableSubscriptions: Map<string, Observable<any>> = new Map<string, Observable<any>>();
    private logger: SportsRemoteLogger;
    private readonly mainToLiveTransitionDelay: number;

    constructor(
        private pushProvider: CdsPushProvider,
        private ngZone: NgZone,
        loggerFactory: LoggerFactory,
        private epsLogger: EPSDetailedLogger,
        private dispatcher: DispatcherService,
        private timerService: TimerService,
        private bettingOfferConfig: BettingOfferConfig,
    ) {
        this.logger = loggerFactory.getLogger('CdsPushService');
        this.mainToLiveTransitionDelay =
            this.bettingOfferConfig.mainToLiveFixedPushDelay + Math.floor(Math.random() * this.bettingOfferConfig.mainToLiveMaxRandomPushDelay);

        this.pushProvider.messageReceived$
            /**
             * Performance optimization:
             * Because of increased amount of TV2 push messages and because they are usually recieved by client in bursts of ~200 messages
             * we've introduced time-based buffering (defined in pushBufferingDuration) and later propagation in the system. It has a downside
             * of delaying every push message update by the buffering duration.
             */
            .pipe(bufferThrottle(this.bettingOfferConfig.pushBufferingDuration, { leading: false, trailing: true }))
            .subscribe((envelopes) => {
                this.handleMain2LiveTransitionMessages(envelopes);
                this.handlePushMessage(envelopes);
            });
    }

    subscribe(subscriptions: CdsSubscription | CdsSubscription[]): () => void {
        const list = isArray(subscriptions) ? subscriptions : [subscriptions];

        this.ngZone.runOutsideAngular(async () => {
            await this.ensureConnected();

            const newSubscriptions = [];
            for (const sub of list) {
                for (const key of sub.key) {
                    if (this.subscriptions.has(key)) {
                        const existing = this.subscriptions.get(key)!;
                        existing.push(sub.callback);
                    } else {
                        this.subscriptions.set(key, [sub.callback]);

                        newSubscriptions.push(key);
                    }
                }
            }

            if (newSubscriptions.length > 0) {
                this.pushProvider.subscribe({ topics: newSubscriptions });
            }
        });

        return () => {};
    }

    subscribe$<T>(topic: string): Observable<T> {
        const existing = this.observableSubscriptions.get(topic);
        if (existing) {
            return existing;
        }
        this.ngZone.runOutsideAngular(async () => {
            await this.ensureConnected();
            this.pushProvider.subscribe({ topics: [topic] });
        });

        return this.pushProvider.messageReceived$.pipe(
            filter((envelope) => envelope.topic === topic),
            map((envelope) => envelope.payload as T),
            finalize(() => {
                this.observableSubscriptions.delete(topic);
                this.ngZone.runOutsideAngular(() => {
                    this.pushProvider.cancelSubscription({ topics: [topic] });
                });
            }),
            share(),
        );
    }

    async restart(): Promise<void> {
        this.subscriptions = new Map();
        await this.pushProvider.restart();
    }

    unsubscribe(subscriptions: CdsSubscription | CdsSubscription[]): void {
        const list = isArray(subscriptions) ? subscriptions : [subscriptions];

        this.ngZone.runOutsideAngular(() => {
            const topics: string[] = [];

            list.forEach((current) => {
                try {
                    for (const key of current.key) {
                        if (this.checkSubscription(current)) {
                            topics.push(key);
                        }
                    }
                } catch (error) {
                    this.logger.error(error);
                }
            });

            this.pushProvider.cancelSubscription({ topics });
        });
    }

    private checkSubscription(cdsSubscription: CdsSubscription): boolean {
        if (!this.pushProvider || this.pushProvider.isDisconnected || !cdsSubscription) {
            return false;
        }

        for (const key of cdsSubscription.key) {
            let callbacks = this.subscriptions.get(key);

            if (callbacks) {
                callbacks = without(callbacks, cdsSubscription.callback);

                if (callbacks && !callbacks.length) {
                    this.subscriptions.delete(key);

                    return true;
                } else {
                    this.subscriptions.set(key, callbacks);
                }
            }
        }

        return false;
    }

    private handlePushMessage = (messages: MessageEnvelope[]) => {
        messages = messages.filter((m) => m.messageType !== MessageType.MainToLiveUpdate);
        const callbackList: { callbacks: ((message: MessageEnvelope) => void)[]; message: MessageEnvelope }[] = [];

        for (const message of messages) {
            const callbacks = this.subscriptions.get(message.topic);

            if (!callbacks || !callbacks.length) {
                console.warn(`Received push message for not subscribed topic: ${message.topic}`);

                continue;
            }

            if (message.messageType === MessageType.CashoutUpdate) {
                this.epsLogger.logPush(message);
            }

            callbackList.push({
                callbacks,
                message,
            });
        }

        this.ngZone.run(() =>
            callbackList.forEach((item) => {
                item.callbacks.forEach((cb) => cb(item.message));
            }),
        );
    };

    private handleMain2LiveTransitionMessages = (messages: MessageEnvelope[]) => {
        if (!this.bettingOfferConfig.isMainToLiveTransitionEnabled) {
            return;
        }

        const payloads = messages
            .filter((m) => m.messageType === MessageType.MainToLiveUpdate)
            .map((message) => {
                const messagePayload = <InPlaySwitchCommand>message.payload;
                const eventPayload = messagePayload.switchedFixtures.reduce(
                    (result, val) => {
                        result.events[val.preMatchId] = val.inPlayId;

                        return result;
                    },
                    { events: {} } as MainLiveEventsSwitchPayload,
                );

                return eventPayload;
            });
        if (!payloads.length) {
            return;
        }

        this.timerService.setTimeout(() => {
            payloads.forEach((p) => this.dispatcher.dispatch(mainLiveEventsSwitchEvent, p));
        }, this.mainToLiveTransitionDelay);
    };

    private async ensureConnected(): Promise<void> {}
}
