import { Injectable } from '@angular/core';

import { OnAppInit } from '../../bootstrap/bootstrapper.service';
import { CookieName } from '../cookie/cookie.models';
import { CookieService } from '../cookie/cookie.service';
import { WindowEvent, WindowRef } from './window-ref.service';

@Injectable({
    providedIn: 'root',
})
export class WindowEventsBootstrapService implements OnAppInit {
    constructor(private windowRef: WindowRef, private cookieService: CookieService) {}

    onAppInit() {
        this.windowRef.nativeWindow.addEventListener(WindowEvent.SocialCookieDropped, () => this.cookieService.put(CookieName.AbSocialLog, 'Y'));
    }
}
