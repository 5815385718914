import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HooksWireup, OnDestroyCleanup, RouteTag } from '@frontend/sports/common/base-utils';
import { WindowRef } from '@frontend/vanilla/core';
import { BehaviorSubject, map, takeUntil } from 'rxjs';

import { RouterEventsService } from '../navigation-core/router-events.service';

export enum ScrollFreezeServiceTypes {
    All,
    Page,
    CustomScrollbar,
    None,
}

@HooksWireup()
@Injectable({ providedIn: 'root' })
export class ScrollFreezeService extends OnDestroyCleanup {
    private _freezeCountPage = 0;
    private _freezeCountPs = 0;
    private urlWhenScreenWasFrozen: string;

    private currentRouteTag$ = new BehaviorSubject<RouteTag | undefined>(undefined);

    constructor(
        private window: WindowRef,
        private router: Router,
        routerEvent: RouterEventsService,
    ) {
        super();

        routerEvent.currentActivationEnd
            .pipe(
                map((ae) => ae?.snapshot?.data?.tag),
                takeUntil(this.destroyed$),
            )
            .subscribe(this.currentRouteTag$);
    }

    freezeScroll(type?: ScrollFreezeServiceTypes): void {
        if (type === ScrollFreezeServiceTypes.None) {
            return;
        }
        switch (type) {
            case ScrollFreezeServiceTypes.CustomScrollbar:
                this.freezeCountPs++;
                break;
            case ScrollFreezeServiceTypes.All:
                this.freezeCountPs++;
                this.freezePage();
                break;
            case ScrollFreezeServiceTypes.Page:
            default:
                this.freezePage();
                break;
        }
    }

    private freezePage(): void {
        this.freezeCountPage++;
        if (this.freezeCountPage > 1) {
            return;
        }

        this.urlWhenScreenWasFrozen = this.router.url;

        this.bodyStyle.setProperty('top', `-${this.scrollingElement.scrollTop}px`);
        this.bodyElement.classList.add('noscroll');
    }

    forceScroll(x?: number, y?: number): void {
        if (this.isScrollFrozen) {
            this.bodyStyle.setProperty('top', `${-1 * (y || 0)}px`);
        } else {
            this.window.nativeWindow.scroll({ top: y, left: x });
        }
    }

    releaseScroll(type?: ScrollFreezeServiceTypes): void {
        if (type === ScrollFreezeServiceTypes.None) {
            return;
        }
        switch (type) {
            case ScrollFreezeServiceTypes.CustomScrollbar:
                this.freezeCountPs--;
                break;
            case ScrollFreezeServiceTypes.All:
                this.freezeCountPs--;
                this.releaseScrollPage();
                break;
            case ScrollFreezeServiceTypes.Page:
            default:
                this.releaseScrollPage();
                break;
        }
    }

    releaseScrollPage(): void {
        this.freezeCountPage--;
        if (this.freezeCountPage) {
            return;
        }

        // When 'position' css-property is removed (set by .noscroll class) element autoscrolls to top,
        // thus we need to save top position and manually scroll to it
        // PS: Safari returns wrong value so we check style.top first.
        const currentTopStr = this.bodyElement.style.top || this.window.nativeWindow.getComputedStyle(this.bodyElement)['top'] || '';

        this.bodyStyle.removeProperty('top');
        this.bodyElement.classList.remove('noscroll');

        if (this.shouldSkipResetScroll) {
            return;
        }

        const currentTop = Math.abs(parseInt(currentTopStr.replace(/px/gi, ''), 10));
        if (currentTop >= 0) {
            this.scrollingElement.scrollTop = currentTop;
        }
    }

    // if only difference is the popup being removed then do the scroll-reset on EDP so it doesnt scroll to top
    private get shouldSkipResetScroll(): boolean {
        if (this.currentRouteTag$.value !== RouteTag.EventDetails) {
            return this.isUrlHasChanged();
        }

        const parsedUrl = this.router.parseUrl(this.router.url);
        delete parsedUrl.queryParams?.popup;

        return this.isUrlHasChanged(parsedUrl.toString());
    }

    get isScrollFrozen(): boolean {
        return Boolean(this.bodyElement.classList && this.bodyElement.classList.contains('noscroll')) || this.freezeCountPs > 0;
    }

    private get scrollingElement(): Element {
        return this.window.nativeWindow.document.scrollingElement || this.window.nativeWindow.document.body;
    }

    private get bodyElement(): HTMLElement {
        return this.window.nativeWindow.document.body;
    }

    private get bodyStyle(): CSSStyleDeclaration {
        return this.bodyElement.style;
    }

    private get freezeCountPage(): number {
        return this._freezeCountPage;
    }

    private set freezeCountPage(value: number) {
        this._freezeCountPage = value < 0 ? 0 : value;
    }

    private get freezeCountPs(): number {
        return this._freezeCountPs;
    }

    private set freezeCountPs(value: number) {
        this._freezeCountPs = value < 0 ? 0 : value;
    }

    private isUrlHasChanged(url?: string): boolean {
        url ??= this.router.url;

        return url !== this.urlWhenScreenWasFrozen;
    }
}
