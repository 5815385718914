import { MessageEnvelope } from '@cds/push';
import { CashoutNotification, ErrorKind, PickState, PickWithState } from '@cds/push/cashout-commands';
import { Deferred } from '@frontend/sports/common/base-utils';
import { EarlyPayoutConfig } from '@frontend/sports/common/client-config-data-access';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { values } from 'lodash-es';
import { Subject } from 'rxjs';
import { debounceTime, groupBy, mergeMap } from 'rxjs/operators';

import { CachoutSubscription, CdsPushService } from '../../cds/cds-push.service';
import { LoggerFactory } from '../../logging/logger-factory.service';
import { SportsRemoteLogger } from '../../logging/sports-remote-logger.service';
import { EarlyPayoutDispatcherEvent } from '../../my-bets/models/early-payout.model';
import { UserService } from '../../user/services/user.service';
import {
    EarlyPayoutPossibility,
    EarlyPayoutResultedPickState,
    EarlyPayoutSubscription,
    EarlyPayoutsWithBetslip,
    IEarlyPayoutPicksResult,
} from '../models/early-payout';
import { EarlyPayoutConst, EarlyPayoutErrorTypes, EarlyPayoutStates } from '../models/early-payout-types';
import { MyBetsBetslipBase } from '../models/my-bets-viewmodels';
import { EarlyPayoutSubscriptionMapperService } from './early-payout-subscription-mapper.service';
import { EarlyPayoutService } from './early-payout.service';
import { EarlyPayoutHelpers } from './my-bets-earlypayout-helpers';

export class EarlyPayoutSubscriptionService {
    private timer: number;
    private useBatch = false;
    private resubscribeBetNumbersOnExpiredDate = false;
    private callback?: (earlyPayoutsWithBetslip: EarlyPayoutsWithBetslip) => void;
    private subscribedList: EarlyPayoutSubscription[] = [];
    private potentialEPbetslips: MyBetsBetslipBase[] = [];

    private subject = new Subject<CashoutNotification>();
    private subscriptions: { [key: string]: CachoutSubscription } = {};
    private logger: SportsRemoteLogger;

    constructor(
        private epConfig: EarlyPayoutConfig,
        private cdsPush: CdsPushService,
        private earlyPayoutService: EarlyPayoutService,
        private epHelpers: EarlyPayoutHelpers,
        private dispatcher: DispatcherService,
        private userService: UserService,
        private earlyPayoutSubscriptionMapperService: EarlyPayoutSubscriptionMapperService,
        loggerFactory: LoggerFactory,
    ) {
        this.subject
            .pipe(
                groupBy((message) => message.betNumber),
                mergeMap((messageGroup) => messageGroup.pipe(debounceTime(10))),
            )
            .subscribe((message) => this.handleEarlyPayoutPush(message));
        this.logger = loggerFactory.getLogger('EarlyPayoutSubscriptionService');
    }

    subscribeEarlyPayoutChanges(
        potentialEPbetslips: MyBetsBetslipBase[],
        betslipsToSubscribe: MyBetsBetslipBase[],
        useBatchCalls: boolean = false,
        callback?: (ep: EarlyPayoutsWithBetslip) => void,
    ): void {
        this.potentialEPbetslips = potentialEPbetslips;
        this.resubscribeBetNumbersOnExpiredDate = true;
        this.callback = callback;

        const newSubscriptions = this.createSubscriptions(betslipsToSubscribe);

        this.subscribedList.push(...newSubscriptions);

        this.subscribeEarlyPayoutValueChanges(this.subscribedList, useBatchCalls);
    }

    unsubscribeEarlyPayoutService(): void {
        this.resubscribeBetNumbersOnExpiredDate = false;

        this.cdsPush.unsubscribe(values(this.subscriptions));

        this.subscriptions = {};
        this.subscribedList = [];

        clearTimeout(this.timer);
    }

    private createSubscriptions(betslips: MyBetsBetslipBase[]): EarlyPayoutSubscription[] {
        const tempSubscriptions: EarlyPayoutSubscription[] = betslips.map((betslip: MyBetsBetslipBase) => {
            return {
                betNumber: betslip.betslipRealId,
            };
        });

        return tempSubscriptions;
    }

    private handleEarlyPayoutValuePushUpdate = (earlyPayouts: EarlyPayoutPossibility[]): void => {
        this.epHelpers.mapPermanentlyDisabledEarlyPayouts(earlyPayouts, this.potentialEPbetslips);
        this.epHelpers.mapDisabledEarlyPayouts(this.potentialEPbetslips);
        this.epHelpers.mapHiddenEarlyPayouts(this.potentialEPbetslips);

        const eligibleBetslips = this.potentialEPbetslips.filter((match) => !this.epHelpers.isEarlyPayoutDisabled(match));
        const earlyPayoutsWithBetslip = this.epHelpers.mapEarlyPayoutsWithBetslip(eligibleBetslips, earlyPayouts);
        const earlyPayoutAcceptanceMode = this.userService.earlyPayoutAcceptance || EarlyPayoutConst.ModeHigher;

        earlyPayoutsWithBetslip.forEach((match) => {
            const matchedBetslip = match.betslip;
            const earlyPayout = match.earlyPayout;

            if (this.callback) {
                this.callback(match);
            }

            // set new values
            if (matchedBetslip.earlyPayout) {
                if (
                    matchedBetslip.earlyPayout.state === EarlyPayoutStates.Confirm &&
                    matchedBetslip.earlyPayout.value > earlyPayout.earlyPayoutValue &&
                    earlyPayoutAcceptanceMode === EarlyPayoutConst.ModeHigher
                ) {
                    this.dispatcher.dispatch(EarlyPayoutDispatcherEvent.CashOut.PushToLower, true);
                }
                matchedBetslip.earlyPayout.acceptMode = earlyPayoutAcceptanceMode;
                matchedBetslip.earlyPayout.value = earlyPayout.earlyPayoutValue;
                matchedBetslip.earlyPayout.valueWithoutStake = this.epHelpers.getValueWithoutFreeBetStake(
                    matchedBetslip.stake.value,
                    earlyPayout.earlyPayoutValue,
                    matchedBetslip.isFreeBet,
                );
                matchedBetslip.earlyPayout.earlyPayoutPossible = earlyPayout.earlyPayoutPossible;

                if (earlyPayout.earlyPayoutPossible && matchedBetslip.earlyPayout.state === EarlyPayoutStates.ModeHigher) {
                    return;
                }

                if (matchedBetslip.earlyPayout.state === EarlyPayoutStates.Preparing) {
                    return;
                }

                if (earlyPayout.earlyPayoutPossible && matchedBetslip.earlyPayout.state === EarlyPayoutStates.Disabled) {
                    matchedBetslip.earlyPayout.state = EarlyPayoutStates.Allowed;
                    matchedBetslip.earlyPayout.error = undefined;
                }

                if (!earlyPayout.earlyPayoutPossible && earlyPayout.earlyPayoutError!.type === EarlyPayoutErrorTypes.DuplicatedCashout) {
                    matchedBetslip.earlyPayout.state = EarlyPayoutStates.Completed;
                    this.dispatcher.dispatch(EarlyPayoutDispatcherEvent.CashOut.CashOutAlreadyExecuted);

                    return;
                }

                if (!earlyPayout.earlyPayoutPossible) {
                    matchedBetslip.earlyPayout.state = EarlyPayoutStates.Disabled;

                    if (earlyPayout.earlyPayoutError) {
                        matchedBetslip.earlyPayout.pushUpdate = true;
                        matchedBetslip.earlyPayout.error = earlyPayout.earlyPayoutError;
                    }
                }
            }
        });

        this.dispatcher.dispatch(EarlyPayoutDispatcherEvent.CashOut.Updated);
    };

    private subscribeEarlyPayoutValueChanges(bettingData: EarlyPayoutSubscription[], useBatchCalls?: boolean): void {
        const subscriptions: CachoutSubscription[] = [];

        bettingData.forEach((data) => {
            if (this.epConfig.isEarlyPayoutPushEnabled) {
                const bet = data.betNumber;

                if (!this.subscriptions[bet]) {
                    const subscription = new CachoutSubscription(bet, this.handler);

                    this.subscriptions[bet] = subscription;
                    subscriptions.push(subscription);
                }
            }
        });

        this.useBatch = useBatchCalls === true;
        this.cdsPush.subscribe(subscriptions);
    }

    private handler = (data: MessageEnvelope) => {
        this.subject.next(data.payload);
    };

    private convertPicksToPickStatus(picks?: PickWithState[]): IEarlyPayoutPicksResult[] {
        if (!picks) {
            return [];
        }

        return picks
            .filter((pick) => pick.pickState === PickState.Won || pick.pickState === PickState.Lost || pick.pickState === PickState.Cancelled)
            .map((pick) => ({
                optionId: pick.id,
                state: this.convertEarlyPayoutPushPickWithStateToEarlyPayoutResultedPickState(pick.pickState),
            }));
    }

    private convertEarlyPayoutPushPickWithStateToEarlyPayoutResultedPickState(state: PickState): EarlyPayoutResultedPickState {
        switch (state) {
            case PickState.Won:
                return EarlyPayoutResultedPickState.Won;
            case PickState.Lost:
                return EarlyPayoutResultedPickState.Lost;
            default:
                return EarlyPayoutResultedPickState.Cancelled;
        }
    }

    private async handleEarlyPayoutPush(data: CashoutNotification): Promise<void> {
        if (!data.subscriptionExpired) {
            const earlyPayoutError = data.errorKind
                ? {
                      type: this.earlyPayoutSubscriptionMapperService.convertPushUpdateErrorKindToEarlyPayoutError(
                          data.errorKind ?? ErrorKind.Unknown,
                          data.picks,
                      ),
                      reason: data.betNotAllowedReason,
                      picks: this.earlyPayoutSubscriptionMapperService.convertPicksToPickStatus(data.picks),
                  }
                : undefined;

            this.handleEarlyPayoutValuePushUpdate([
                {
                    betNumber: data.betNumber,
                    earlyPayoutPossible: data.possible,
                    earlyPayoutValue: data.possible ? data.value : 0,
                    earlyPayoutError,
                    resultedPicks: this.convertPicksToPickStatus(data.picks),
                },
            ]);
        }

        if (this.resubscribeBetNumbersOnExpiredDate && data.subscriptionExpired) {
            const betNumbersToCheck = [data.betNumber];
            const cancelationToken = new Deferred<void>();

            // TODO group consequent push updates (i.e. 200 mls) and call checkEarlyPayoutAndSubscribe with several bet numbers
            try {
                const response = await this.earlyPayoutService.checkEarlyPayoutAndSubscribe(betNumbersToCheck, cancelationToken);
                const betNumbers = response.earlyPayouts.filter((bet) => bet.earlyPayoutPossible).map((bet) => bet.betNumber);
                if (betNumbers.length) {
                    const betNumber = betNumbers[0];
                    this.subscribeEarlyPayoutValueChanges([{ betNumber }], this.useBatch);
                }

                const betNumbersWithCashOutComplete = response.earlyPayouts
                    .filter((ep) => !ep.earlyPayoutPossible && ep.earlyPayoutError?.type === EarlyPayoutErrorTypes.DuplicatedCashout)
                    .map((ep) => ep.betNumber);

                if (betNumbersWithCashOutComplete.length) {
                    const earlyPayoutNotPossible: EarlyPayoutPossibility[] = betNumbersWithCashOutComplete.map(
                        (ep) =>
                            <EarlyPayoutPossibility>{
                                betNumber: ep,
                                earlyPayoutPossible: false,
                                earlyPayoutValue: 0,
                                earlyPayoutError: { type: EarlyPayoutErrorTypes.DuplicatedCashout },
                            },
                    );

                    this.handleEarlyPayoutValuePushUpdate(earlyPayoutNotPossible);
                }
            } catch (e) {
                this.logger.error(e, 'handleEarlyPayoutPush');
            }
        }
    }
}
