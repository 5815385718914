import { InjectionToken } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ISavedState {}

export interface StateSaver<TState, TStateStorage> {
    save(state: TState): TStateStorage;
}

export interface StateLoader<TStateStorage, TState> {
    load(state: TStateStorage | undefined): TState;
}

export const _STATE_STORAGE_PERSIST_SERVICES = new InjectionToken<Map<string, StateSaver<any, any>>>('Internal state storage persist services');
export const _STATE_STORAGE_PERSIST_SERVICE = new InjectionToken('Internal state storage persist service - single instance');
