import { Injectable, NgZone } from '@angular/core';

import { WindowRef } from '@frontend/vanilla/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { EventService } from './browser/window-event.service';

export type ScrollElement = Element | Window;

@Injectable({
    providedIn: 'root',
})
export class ScrollContainerService {
    target: ScrollElement;
    eventTarget: ScrollElement;
    private scrollSubject = new Subject<ScrollElement>();
    private scrollSubscription: Subscription;

    constructor(
        private zone: NgZone,
        private windowRef: WindowRef,
    ) {
        this.setTarget((document.scrollingElement || this.windowRef.nativeWindow) as ScrollElement, this.windowRef.nativeWindow);
    }

    get scroll(): Observable<ScrollElement> {
        return this.scrollSubject.asObservable();
    }

    get scrollTop(): number {
        return this.target['scrollY'] || this.target['scrollTop'] || 0;
    }

    get height(): number {
        return this.target['innerHeight'] || this.target['clientHeight'];
    }

    get container(): HTMLElement {
        return this.target['document'] || this.target;
    }

    get scrollHeight(): number {
        return this.target['scrollHeight'];
    }

    scrollTo(x?: number, y?: number): void {
        requestAnimationFrame(() => {
            const element = this.target as Element;

            if (x !== undefined) {
                element.scrollLeft = -x;
            }

            if (y !== undefined) {
                element.scrollTop = -y;
            }
        });
    }

    scrollToDelta(x?: number, y?: number): void {
        requestAnimationFrame(() => {
            const element = this.target as Element;

            if (x !== undefined) {
                element.scrollLeft = element.scrollLeft + x;
            }

            if (y !== undefined) {
                element.scrollTop = element.scrollTop + y;
            }
        });
    }

    /**
     * Set the element that will be scrolling.
     *
     * The method has two parameters because on mobile, at least, we can have the following situation:
     *   - when calling window.scrollTop the property is 0 even if the page is scrolled
     *   - if we use document.scrollingElement then the scrollTop property is correct but the scroll event does not fire on this element
     *
     * So the `target` element is the one that returns the correct `scrollTop` and the `scrollEventTarget` is the element on which the `scroll` event fires.
     *
     *                    :(
     *
     * @param target - element that returns the correct `scrollTop` value
     * @param [scrollEventTarget] - element on which the `scroll` event fires
     * @memberof ScrollContainerService
     */
    setTarget(target: ScrollElement, scrollEventTarget?: ScrollElement): void {
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }

        this.eventTarget = scrollEventTarget || target;
        const scrollService = new EventService(this.zone, this.eventTarget, 'scroll');

        this.target = target;
        this.scrollSubscription = scrollService.subscribe(() => this.scrollSubject.next(target));
    }
}
