import { Injectable } from '@angular/core';

import { BetslipConfig } from '@frontend/sports/common/client-config-data-access';
import { NativeAppService } from '@frontend/vanilla/core';

import { TrackNavArea } from '../tracking/tracking.models';

export interface NativeAppEvent {
    eventName: string;
    parameters?: { [key: string]: any };
}

export type NativeAppEventHandler = (parameters?: { [key: string]: any }) => void;

export interface NativeAppEventCommand {
    execute: NativeAppEventHandler;
}

export type NativeAppEventUnsubscribe = () => void;

export enum BridgeEvent {
    // outgoing
    BetPlaced = 'BetPlaced',
    SensitivePageOpened = 'SensitivePage',
    SliderGameOpened = 'OpenSliderGame',
    TrackingTurnover = 'TrackingTurnover',
    AddToBetslip = 'BetSlip',
    BetConfirmation = 'BetConfirmation',
    BetPlacement = 'BetPlacement',
    MenuIteamNavigation = 'MenuIteamNavigation',
    ShareMyBet = 'ShareMyBet',

    // incoming
    NavigateTo = 'NavigateTo',
    BetSharedSuccessfully = 'BetSharedSuccessfully',
}

export enum BetType {
    Single = 'Single',
    Multi = 'Multi',
    System = 'System',
    Unknown = 'Unknown',
}

export interface BetParameters {
    eventName: string;
    eventId: string;
    marketName: string;
    marketId: string;
    resultName: string;
    resultId: string;
    sportName: string;
    sportId: string;
}

export interface SensitivePage {
    isSensitiveForPushNotifications: string;
    isSensitiveForSliderGames: string;
    action: string;
    id: string;
    type: string;
}

// TODO - this should be declared in the betslip but for now stays here as it is until refactoring of betslip
export enum PickType {
    Race,
    Sport,
}
@Injectable({ providedIn: 'root' })
export class EventBridgeService {
    constructor(
        private nativeApp: NativeAppService,
        private betslipConfig: BetslipConfig,
    ) {}

    // used for rate the app
    notifyBetPlaced(isSuccessful: boolean): void {
        this.send(BridgeEvent.BetPlaced, { isSuccessful });
    }

    notifyTrackingTurnover(currencyCode: string, stake: number): void {
        this.send(BridgeEvent.TrackingTurnover, { currencyCode, stakeAmount: stake });
    }

    // only temporary - will be moved to wrapper side
    notifySensitivePage(page: SensitivePage): void {
        this.send(BridgeEvent.SensitivePageOpened, {
            isSensitiveForPushNotifications: page.isSensitiveForPushNotifications,
            isSensitiveForSliderGames: page.isSensitiveForSliderGames,
            action: page.action,
            id: page.id,
            type: page.type,
        });
    }

    notifyOpenOverlay(): void {
        this.notifySensitivePage({
            isSensitiveForPushNotifications: 'false',
            isSensitiveForSliderGames: 'false',
            action: 'open',
            id: '',
            type: 'overlay',
        });
    }

    notifyCloseOverlay(): void {
        this.notifySensitivePage({
            isSensitiveForPushNotifications: 'false',
            isSensitiveForSliderGames: 'false',
            action: 'close',
            id: '',
            type: 'overlay',
        });
    }

    notifySliderGameOpening(sliderGameId: string): void {
        this.send(BridgeEvent.SliderGameOpened, { gameTypeId: sliderGameId });
    }

    // used for OL tracking
    // TODO: replace with picks:MS2.Services.Betslip.SportPick
    notifyAddSportPickToBetslip(pick: any): void {
        if (!this.betslipConfig.disableOLTracking) {
            this.send(BridgeEvent.AddToBetslip, {
                eventName: pick.eventName,
                eventId: pick.eventId,
                marketName: pick.marketName,
                marketId: pick.marketId,
                resultName: pick.optionName,
                resultId: pick.optionId,
                sportId: pick.sportId,
            });
        }
    }

    // used for OL tracking
    // TODO: replace with picks:MS2.Services.Betslip.Pick[]
    notifyBetConfirmation(picks: any, betType: string, stake: number): void {
        if (!this.betslipConfig.disableOLTracking) {
            this.send(BridgeEvent.BetConfirmation, {
                betType,
                stake: stake.toString(),
                bets: this.mapPicksToBetParameters(picks),
            });
        }
    }

    // used for OL tracking
    notifySuccessfulBetPlacements(betslipData: any, currency: any): void {
        if (!this.betslipConfig.disableOLTracking) {
            this.send(BridgeEvent.BetPlacement, {
                betType: betslipData.betType,
                bets: this.mapPicksToBetParameters(betslipData.picks),
                stake: betslipData.stake,
                totalPickCount: betslipData.totalPickCount,
                isEachWaySelected: betslipData.isEachWaySelected,
                isLive: betslipData.picks.some((pick: any) => pick.live) ? 'true' : 'false',
                currency,
            });
        }
    }

    notifyBetSharing(data: any): void {
        this.send(BridgeEvent.ShareMyBet, data);
    }

    navigationClick(section: TrackNavArea, ccbKey?: string): void {
        if (ccbKey) {
            this.send(BridgeEvent.MenuIteamNavigation, {
                ccbKey,
                section,
            });
        }
    }

    private send(eventName: string, data?: { [key: string]: any }): void {
        if (!eventName) {
            throw new Error('EventName is not defined');
        }

        this.nativeApp.sendToNative(<NativeAppEvent>{
            eventName,
            parameters: data,
        });
    }

    // TODO: replace with picks:MS2.Services.Betslip.Pick[]
    private mapPicksToBetParameters(picks: any): any {
        const mappedBets: any[] = [];
        picks.forEach((pick: any) => {
            // pick: MS2.Services.Betslip.Pick
            if (pick.type === PickType.Sport) {
                // TODO: fix the type when betslip is refactored
                const sportPick = pick; // as MS2.Services.Betslip.SportPick;
                mappedBets.push({
                    eventName: sportPick.eventName,
                    eventId: sportPick.eventId.toString(),
                    marketName: sportPick.marketName,
                    marketId: sportPick.marketId.toString(),
                    resultName: sportPick.optionName,
                    resultId: sportPick.optionId.toString(),
                    sportId: sportPick.sportId.toString(),
                    sportName: this.getSportNameFromId(sportPick.sportId),
                });
            } else if (pick.type === PickType.Race) {
                // 2017-11-06 - no tracking needed for horse racing
            }
        });

        return mappedBets;
    }

    private getSportNameFromId(sportId: number): string {
        const sportIdToName = {
            4: 'Football',
            5: 'Tennis',
            7: 'Basketball',
        };

        return sportIdToName[sportId] || 'Other';
    }
}
