const regexPattern = /(?!1:)\d+:(\d+)$/;

export function getSourceEventId(id: string): string {
    return id.split(':')[1] || id;
}

export function isV2CompoundId(id: string): boolean {
    return regexPattern.test(id);
}

export function safeEventId(id: string): string {
    return id.replace(':', '-');
}

export function getPartition(id: string): number | undefined {
    if (id.indexOf(':') > -1) {
        return +id.split(':')[0];
    } else {
        return undefined;
    }
}
