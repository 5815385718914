import { ModuleWithProviders, NgModule } from '@angular/core';

import {
    AccaBoostConfig,
    BetslipBarConfig,
    BetslipConfig,
    BetslipOnboardingConfig,
    BetslipUpsellConfig,
    ConnectionConfig,
    CrmConfig,
    CurrencyConfig,
    NotificationsConfig,
    ReturnsMessageConfig,
    Sitecore,
    StakeConfig,
    TaxationConfig,
} from '@frontend/sports/common/client-config-data-access';
import { CashierService, IntlService, LocalStoreService } from '@frontend/vanilla/core';
import { EffectsModule } from '@ngrx/effects';
import { AccountBalanceService } from 'packages/sports/web/app/src/account-balance/account-balance.service';
import { ArcRestrictionService } from 'packages/sports/web/app/src/arc/arc-restriction.service';
import { BetslipUpsellApi } from 'packages/sports/web/app/src/cds/betslip-upsell-api.service';
import { BettingOfferApi } from 'packages/sports/web/app/src/cds/betting-offer-api.service';
import { CdsPushService } from 'packages/sports/web/app/src/cds/cds-push.service';
import { EpcotConfigService } from 'packages/sports/web/app/src/common/epcot-config.service';
import { StringInterpolationPipe } from 'packages/sports/web/app/src/common/string-interpolation.pipe';
import { CompetitionLogosService } from 'packages/sports/web/app/src/competition-logos/competition-logos.service';
import { CrmOfferLoaderService } from 'packages/sports/web/app/src/crm-offer-data/crm-offer-loader.service';
import { FreshDataService } from 'packages/sports/web/app/src/data-refresh/fresh-data.service';
import { FixtureFactory } from 'packages/sports/web/app/src/event-model/mappers/fixture.factory';
import { GridSubscriptionService } from 'packages/sports/web/app/src/event-subscription/grid-subscription.service';
import { CustomerOfferService } from 'packages/sports/web/app/src/hidden-market-core/customer-offer.service';
import { ApiService } from 'packages/sports/web/app/src/http/api.service';
import { ColumnLayoutService } from 'packages/sports/web/app/src/layout/column-layout.service';
import { LoggerFactory } from 'packages/sports/web/app/src/logging/logger-factory.service';
import { ModalDialogService } from 'packages/sports/web/app/src/modal/dialog/modal-dialog.service';
import { MoneyService } from 'packages/sports/web/app/src/money/money.service';
import { MyBetsSummaryService } from 'packages/sports/web/app/src/my-bets-base/my-bets-summary.service';
import { RedirectHelperService } from 'packages/sports/web/app/src/navigation-core/redirect-helper.service';
import { RouterEventsService } from 'packages/sports/web/app/src/navigation-core/router-events.service';
import { NavigationRouterService } from 'packages/sports/web/app/src/navigation/navigation-router.service';
import { NumpadDisplayService } from 'packages/sports/web/app/src/numpad/numpad-display.service';
import { PickSourceProvider } from 'packages/sports/web/app/src/option-pick/pick-source.provider';
import { PopupManager } from 'packages/sports/web/app/src/popup/popup-manager.service';
import { TokensCalculatorService } from 'packages/sports/web/app/src/tokens-base/tokens-calculator.service';
import { TrackingService } from 'packages/sports/web/app/src/tracking/tracking.service';
import { UserSettingsService } from 'packages/sports/web/app/src/user/services/user-settings.service';
import UserStateService from 'packages/sports/web/app/src/user/services/user-state.service';
import { UserService } from 'packages/sports/web/app/src/user/services/user.service';

import { BetslipHost } from '../core/external-betslip-actions';
import { BetslipDigitalModuleLoaderService } from './betslip-digital-module-loader.service';
import ExternalAffiliateEffects from './external-affiliate.effects';
import { ExternalBetslipEffects } from './external-betslip-effects';
import AlternativeTranslationsService from './services/alternative-translations-impl.service';
import {
    ACCA_BOOST_CONFIG_INJECTION_TOKEN,
    ACCOUNT_BALANCE_INJECTION_TOKEN,
    ALTERNATIVE_TRANSLATION_INJECTION_TOKEN,
    API_SERVICE_INJECTION_TOKEN,
    ARC_RESTRICTION_INJECTION_TOKEN,
    BETSLIP_BAR_CONFIG_INJECTION_TOKEN,
    BETSLIP_CONFIG_INJECTION_TOKEN,
    BETSLIP_MODULE_LOADER_SERVICE,
    BETSLIP_ONBOARDING_CONFIG_INJECTION_TOKEN,
    BETSLIP_UPSELL_API_INJECTION_TOKEN,
    BETTING_OFFER_API_INJECTION_TOKEN,
    CASHIER_SERVICE_INJECTION_TOKEN,
    CDS_PUSH_INJECTION_TOKEN,
    COLUMN_LAYOUT_SERVICE_INJECTION_TOKEN,
    COMPETITION_LOGOS_INJECTION_TOKEN,
    CONNECTION_CONFIG_INJECTION_TOKEN,
    CRM_CONFIG_INJECTION_TOKEN,
    CRM_OFFER_LOADER_SERVICE_INJECTION_TOKEN,
    CURRENCY_CONFIG_INJECTION_TOKEN,
    CUSTOMER_OFFER_INJECTION_TOKEN,
    EPCOT_CONFIG_INJECTION_TOKEN,
    FIXTURE_FACTORY_INJECTION_TOKEN,
    FRESH_DATA_INJECTION_TOKEN,
    GRID_SUBSCRIPTION_SERVICE_INJECTION_TOKEN,
    INTL_SERVICE_INJECTION_TOKEN,
    LOCAL_STORE_INJECTION_TOKEN,
    LOGGER_FACTORY_INJECTION_TOKEN,
    MODAL_DIALOG_SERVICE_INJECTION_TOKEN,
    MONEY_SERVICE_INJECTION_TOKEN,
    MULTI_RETURNS_MESSAGE_CONFIG_INJECTION_TOKEN,
    MY_BETS_SUMMARY_SERVICE_INJECTION_TOKEN,
    NAVIGATION_ROUTER_SERVICE,
    NOTIFICATION_CONFIG_INJECTION_TOKEN,
    NUMPAD_DISPLAY_INJECTION_TOKEN,
    PICK_SOURCE_PROVIDER_INJECTION_TOKEN,
    POPUP_MANAGER_INJECTION_TOKEN,
    REDIRECT_HELPER_SERVICE,
    ROUTER_EVENTS_SERVICE_INJECTION_TOKEN,
    SITECORE_INJECTION_TOKEN,
    STAKE_CONFIG_INJECTION_TOKEN,
    STRING_INTERPOLATION_PIPE_INJECTION_TOKEN,
    TAXATION_CONFIG_INJECTION_TOKEN,
    TOKEN_CALCULATOR_SERVICE_INJECTION_TOKEN,
    TRACKING_SERVICE_INJECTION_TOKEN,
    UPSELL_CONFIG_INJECTION_TOKEN,
    USER_SERVICE_INJECTION_TOKEN,
    USER_SETTINGS_SERVICE_INJECTION_TOKEN,
    USER_STATE_SERVICE_INJECTION_TOKEN,
} from './sports-injection-services';

@NgModule({
    imports: [EffectsModule.forFeature([ExternalBetslipEffects, ExternalAffiliateEffects])],
})
export class BetslipDigitalIntegrationModule {
    static forRoot(): ModuleWithProviders<BetslipDigitalIntegrationModule> {
        return {
            ngModule: BetslipDigitalIntegrationModule,
            providers: [
                { provide: BetslipHost, useValue: BetslipHost.Digital },

                // services
                { provide: BETSLIP_MODULE_LOADER_SERVICE, useClass: BetslipDigitalModuleLoaderService },
                { provide: SITECORE_INJECTION_TOKEN, useExisting: Sitecore },
                { provide: BETTING_OFFER_API_INJECTION_TOKEN, useExisting: BettingOfferApi },
                { provide: CUSTOMER_OFFER_INJECTION_TOKEN, useExisting: CustomerOfferService },
                { provide: ALTERNATIVE_TRANSLATION_INJECTION_TOKEN, useClass: AlternativeTranslationsService },
                { provide: USER_SERVICE_INJECTION_TOKEN, useExisting: UserService },
                { provide: STRING_INTERPOLATION_PIPE_INJECTION_TOKEN, useExisting: StringInterpolationPipe },
                { provide: MONEY_SERVICE_INJECTION_TOKEN, useExisting: MoneyService },
                { provide: ACCOUNT_BALANCE_INJECTION_TOKEN, useExisting: AccountBalanceService },
                { provide: API_SERVICE_INJECTION_TOKEN, useExisting: ApiService },
                { provide: TOKEN_CALCULATOR_SERVICE_INJECTION_TOKEN, useExisting: TokensCalculatorService },
                { provide: CDS_PUSH_INJECTION_TOKEN, useExisting: CdsPushService },
                { provide: FRESH_DATA_INJECTION_TOKEN, useExisting: FreshDataService },
                { provide: LOGGER_FACTORY_INJECTION_TOKEN, useExisting: LoggerFactory },
                { provide: INTL_SERVICE_INJECTION_TOKEN, useExisting: IntlService },
                { provide: MODAL_DIALOG_SERVICE_INJECTION_TOKEN, useExisting: ModalDialogService },
                { provide: POPUP_MANAGER_INJECTION_TOKEN, useExisting: PopupManager },
                { provide: USER_STATE_SERVICE_INJECTION_TOKEN, useExisting: UserStateService },
                { provide: REDIRECT_HELPER_SERVICE, useExisting: RedirectHelperService },
                { provide: USER_SETTINGS_SERVICE_INJECTION_TOKEN, useExisting: UserSettingsService },
                { provide: TRACKING_SERVICE_INJECTION_TOKEN, useExisting: TrackingService },
                { provide: ROUTER_EVENTS_SERVICE_INJECTION_TOKEN, useExisting: RouterEventsService },
                { provide: CRM_OFFER_LOADER_SERVICE_INJECTION_TOKEN, useExisting: CrmOfferLoaderService },
                { provide: COLUMN_LAYOUT_SERVICE_INJECTION_TOKEN, useExisting: ColumnLayoutService },
                { provide: ARC_RESTRICTION_INJECTION_TOKEN, useExisting: ArcRestrictionService },
                { provide: COMPETITION_LOGOS_INJECTION_TOKEN, useExisting: CompetitionLogosService },
                { provide: LOCAL_STORE_INJECTION_TOKEN, useExisting: LocalStoreService },
                { provide: FIXTURE_FACTORY_INJECTION_TOKEN, useExisting: FixtureFactory },
                { provide: BETSLIP_UPSELL_API_INJECTION_TOKEN, useExisting: BetslipUpsellApi },
                { provide: NUMPAD_DISPLAY_INJECTION_TOKEN, useExisting: NumpadDisplayService },
                { provide: CASHIER_SERVICE_INJECTION_TOKEN, useExisting: CashierService },
                { provide: GRID_SUBSCRIPTION_SERVICE_INJECTION_TOKEN, useExisting: GridSubscriptionService },
                { provide: NAVIGATION_ROUTER_SERVICE, useExisting: NavigationRouterService },
                { provide: EPCOT_CONFIG_INJECTION_TOKEN, useExisting: EpcotConfigService },
                { provide: PICK_SOURCE_PROVIDER_INJECTION_TOKEN, useExisting: PickSourceProvider },
                { provide: MY_BETS_SUMMARY_SERVICE_INJECTION_TOKEN, useExisting: MyBetsSummaryService },

                // Configs
                { provide: BETSLIP_CONFIG_INJECTION_TOKEN, useExisting: BetslipConfig },
                { provide: STAKE_CONFIG_INJECTION_TOKEN, useExisting: StakeConfig },
                { provide: TAXATION_CONFIG_INJECTION_TOKEN, useExisting: TaxationConfig },
                { provide: CONNECTION_CONFIG_INJECTION_TOKEN, useExisting: ConnectionConfig },
                { provide: NOTIFICATION_CONFIG_INJECTION_TOKEN, useExisting: NotificationsConfig },
                { provide: CURRENCY_CONFIG_INJECTION_TOKEN, useExisting: CurrencyConfig },
                { provide: MULTI_RETURNS_MESSAGE_CONFIG_INJECTION_TOKEN, useExisting: ReturnsMessageConfig },
                { provide: ACCA_BOOST_CONFIG_INJECTION_TOKEN, useExisting: AccaBoostConfig },
                { provide: CRM_CONFIG_INJECTION_TOKEN, useExisting: CrmConfig },
                { provide: UPSELL_CONFIG_INJECTION_TOKEN, useExisting: BetslipUpsellConfig },
                { provide: BETSLIP_ONBOARDING_CONFIG_INJECTION_TOKEN, useExisting: BetslipOnboardingConfig },
                { provide: BETSLIP_BAR_CONFIG_INJECTION_TOKEN, useExisting: BetslipBarConfig },
            ],
        };
    }
}
