import { InjectionToken } from '@angular/core';

import { NumpadService } from './numpad.service';

export interface ISelectionProvider {
    getSelection(currentValue: string): { start: number; end: number };
}

export interface StakeModel {
    value: string;
    formatted: string;
    currencyFormatted: string;
    isEmpty: boolean;
    numpadInput?: number;
    position?: number;
}

export interface NumpadState {
    stake: StakeModel;
    isOpened: boolean;
    selectionStart?: number;
    operation?: NumpadOperation;
    confirmed?: boolean;
}

export const defaultNumpadState: NumpadState = {
    isOpened: false,
    stake: {
        formatted: '',
        value: '',
        currencyFormatted: '',
        isEmpty: true,
        numpadInput: undefined,
        position: undefined,
    },
};

export interface NumpadHostOptions {
    id: string;
    numpadService: NumpadService;
}

export interface KeypadOptions {
    id: string;
    showConfirm: boolean;
    showIncrementalStakes: boolean;
    focusOnLoad: boolean;
    numpadSource: NumpadSource;
}

export interface NumpadValidationParams {
    maxLeadingDigits: number;
    maxTrailingDigits: number;
}

export enum NumpadSource {
    BetSlip = 'BetSlip',
    QuickBet = 'Quick Bet',
    BettingSettings = 'Betting Setting',
    EditMyBet = 'Edit My Bet',
    AutoCashout = '',
}

export enum NumpadOperation {
    Open,
    Close,
    Append,
    Increment,
    Remove,
    Type,
}

export const NUMPAD_VALIDATION_PARAMETERS_TOKEN = new InjectionToken<NumpadValidationParams>('NUMPAD_VALIDATION_PARAMETERS_TOKEN');

export enum NumpadAction {
    None,
    IncrementalStakeUsed,
    KeypadUsed,
    KeypadTyped,
}

export interface StakeChangedEvent {
    action: NumpadAction;
    stake: string;
}

export enum Tracking {
    Betting = 'Betting',
    NotApplicable = 'not applicable',
    Opening = 'In-Line KeyPad - Loaded',
    Confirmation = 'OK Button - Clicked',
    Delete = 'Delete Button - Clicked',
}
