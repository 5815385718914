import { Injectable } from '@angular/core';

import { Fixture } from '@cds/betting-offer';
import { FixtureView } from '@cds/betting-offer/domain-specific';
import { FixtureRequest } from '@cds/query-objects';
import { isDefined } from '@frontend/sports/common/base-utils';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BettingOfferApi } from './betting-offer-api.service';

@Injectable({ providedIn: 'root' })
export class CdsMainLiveService {
    constructor(private bettingOfferApi: BettingOfferApi) {}

    getFixtures$(fixtureRequest: FixtureRequest): Observable<Fixture[]> {
        return this.bettingOfferApi.getMainLiveFixtureList$(fixtureRequest).pipe(
            filter(isDefined),
            map((r) => r.fixtures),
        );
    }

    getFixtureView$(fixtureId: string): Observable<FixtureView> {
        return this.bettingOfferApi.getMainLiveFixtureView$(fixtureId).pipe(filter(isDefined));
    }
}
