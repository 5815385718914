import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';

export enum GameLauncherSection {
    Gyml = 'gyml',
    Link = 'link',
    Jackpot = 'jackpot',
}

export interface GameLauncherModel {
    name: string;
    section: GameLauncherSection;
    position: number;
    title: string;
    provider: string;
    lobbyPosition: string;
}

export const gameLauncherResolver: ResolveFn<GameLauncherModel> = (route: ActivatedRouteSnapshot): GameLauncherModel => ({
    name: route.params.name,
    section: route.params.section,
    position: route.params.position,
    title: route.params.title,
    provider: route.params.provider,
    lobbyPosition: route.params.lobbyPosition,
});
