export enum TabBarMode {
    Pill = 'pill',
    Tab = 'tab',
}

export interface TabBarContentStyles {
    backgroundColor?: string;
    borderColor?: string;
    color?: string;
}

export interface TabBarItem<T extends number | string = number> {
    id: T;
    title: string;
    icon?: string;
    url?: string;
    count?: number;
    active?: boolean;
    classes?: string;
    openCountVisible?: boolean;
    disabled?: boolean;
    attribute?: string;
    ignoreBrowserHistory?: boolean;
    badges?: TabBarBadge[];
    showSignPost?: boolean;
    overrideStyles?: { activeStyles?: TabBarContentStyles; inactiveStyles?: TabBarContentStyles };
}

export interface TabBarEvent<T extends number | string = number> {
    active: TabBarItem<T>;
}

export interface TabBarBadge {
    class: string;
    icon: string;
    title?: string;
}

export interface SportTabBarItem extends TabBarItem {
    liveCount?: number;
}
