import { Tv1PickData, Tv2PickData } from '@cds/query-objects';

export interface OptionQuery {
    fixture?: string;
    optionGroup?: number;
    option?: number;
    offerSource?: string;
    priceId?: number;
    tv1Legs?: Tv1PickData[];
    tv2Legs?: Tv2PickData[];
}

export interface OptionRequest {
    query: OptionQuery[];
    stake: number | null;
    type: string | null;
    source: string;
    offerSource?: string;
}

export interface OptionParams {
    options?: string;
    stake?: string;
    currency?: string;
    type?: string;
    source?: string;
    refbet?: string;
    offerSource?: string;
}

export interface PickUriParams {
    stake?: number;
    type?: string;
    currency?: string;
    source?: string;
    userId?: string;
    addPage?: boolean;
    betslipId?: string;
    offerSource?: string;
}

export enum BetslipConstants {
    Single = 'Single',
    Combo = 'Combo',
    System = 'System',
}

export enum OptionParameters {
    OfferSource = 'offerSource',
    Source = 'source',
    Refbet = 'refbet',
    Currency = 'currency',
    Stake = 'stake',
    Type = 'type',
    Options = 'options',
}
