import { Injectable } from '@angular/core';

import { CookieService } from '@frontend/vanilla/core';

import { DateProviderService } from '../common/date-provider.service';

@Injectable({ providedIn: 'root' })
export class CookieProvider {
    constructor(private cookies: CookieService) {}

    getCookie(name: string): string {
        return this.cookies.get(name);
    }

    setCookie(name: string, value: string, options?: { expires?: number }): void {
        const expire = this.cookieExpirationDate(options?.expires);

        return this.cookies.put(name, value, { expires: expire });
    }

    deleteCookie(name: string): void {
        return this.cookies.remove(name);
    }

    private cookieExpirationDate(days?: number): Date | undefined {
        if (days == null) {
            return;
        }

        const result = DateProviderService.getDate();
        result.setDate(result.getDate() + days);

        return result;
    }
}
