import { Injectable } from '@angular/core';

import { Tag } from '@cds/betting-offer/grouping/fixture-view';
import { groupBy, isEqual, uniqWith } from 'lodash-es';

import { AngstromTagIdValues } from '../../event-details-sitemap/event-details-sitemap.models';
import { EnhancedFixtureViewGroupingConfiguration, EventOptionGroup, EventOptionSet, EventSitemapSet } from '../model/event.model';

@Injectable({ providedIn: 'root' })
export class EventSitemapGroupingFactory {
    getSitemapSet(
        optionSets: EventOptionSet[],
        grouping: EnhancedFixtureViewGroupingConfiguration,
        optionGroups: EventOptionGroup[],
    ): EventSitemapSet[] {
        const sets: EventSitemapSet[] = this.buildPrimaryLevelItems(optionSets);

        sets.forEach((s) => {
            const marketGroupsPerSet = grouping.marketGroups.filter((x) => (x.tag?.key ? x.tag.key === s.id : x.id === s.id));
            const marketGroupItemsTV2 = marketGroupsPerSet?.map((m) => m.marketGroupItems).flat(1);
            const marketGroupItemsTV1 = marketGroupsPerSet?.map((m) => m.tv1MarketGroupItems).flat(1);

            const marketGroupItems = marketGroupItemsTV2.concat(marketGroupItemsTV1);

            if (marketGroupItems.length) {
                const children: EventSitemapSet[] = [];
                const hasTaggedGroupItems = marketGroupItems.some((m) => m.tags.length !== 0);

                if (hasTaggedGroupItems) {
                    const optTags = optionGroups
                        .filter((x) => (s.groups ? s.groups.includes(x.detailedGrouping.marketSet) : x.detailedGrouping.marketSet === s.id))
                        .filter((x) => (s.id === AngstromTagIdValues.Sgp ? x.isBetBuilder : true))
                        .filter((x) => !!x.detailedGrouping.tags)
                        .map((x) => x.detailedGrouping.tags!);
                    const availableTags = uniqWith(optTags, isEqual);

                    marketGroupItems?.forEach((groupItem) => {
                        const tags = [...groupItem.tags];
                        if (tags.length > 0 && this.tagsExist(tags, availableTags)) {
                            this.buildLowerLevelItems(children, tags, s.groups);
                        }
                    });
                }

                s.children = children;
            }
        });

        return sets.filter((x) => (x.id === AngstromTagIdValues.Sgp ? !!x.children?.length : true));
    }

    private buildPrimaryLevelItems(optionSets: EventOptionSet[]): EventSitemapSet[] {
        const sets: EventSitemapSet[] = [];
        const groupedSets = groupBy(
            optionSets.filter((x) => !!x.marketGroupTag?.key),
            (os) => os.marketGroupTag!.key,
        );
        for (const tag in groupedSets) {
            const current = groupedSets[tag];
            const set: EventSitemapSet = {
                id: tag,
                name: current[0].marketGroupTag!.value || current[0].marketGroupTag!.key,
                groups: current.map((c) => c.id),
                children: [],
            };

            sets.push(set);
        }

        optionSets
            .filter((x) => !x.marketGroupTag || !x.marketGroupTag.key)
            .forEach((o) =>
                sets.push({
                    ...o,
                    groups: [o.id],
                    children: [],
                } as EventSitemapSet),
            );

        return sets;
    }

    private buildLowerLevelItems(children: EventSitemapSet[], tags: Tag[], groups?: number[]): void {
        const keyExists = (key: string) => children.find((el) => el.id === key);
        const append = (tag: { key: string; value: string }) => {
            children.push({
                id: tag.key,
                name: tag.value || tag.key.toString(),
                children: [],
                groups,
            });
        };

        const currentTag = tags[0];
        if (!keyExists(currentTag.key)) {
            append(currentTag);
        }

        tags.shift();
        if (tags.length !== 0) {
            const nextLevelItem = children.find((el) => el.id === currentTag.key)!;
            this.buildLowerLevelItems(nextLevelItem.children!, tags, groups);
        }
    }

    private tagsExist(tags: Tag[], optionGrouping: Tag[][]): boolean {
        return optionGrouping.some((optTags) => {
            if (optTags.length !== tags.length) {
                return false;
            }

            return tags.every((tag, index) => tag.key === optTags[index].key);
        });
    }
}
