import { DateProviderService } from './date-provider.service';
import { Time, TimeOfDay } from './date-time.models';

export abstract class DateCheckerService {
    static isDateReached(date: Date | string): boolean {
        const dateValue = date instanceof Date ? date : new Date(date);

        return DateProviderService.getDate() >= dateValue;
    }

    static isNowTimeOfDay(timeOfDay: TimeOfDay): boolean {
        const now = DateProviderService.getDate();

        const from = DateCheckerService.toDate(now, timeOfDay.from);
        const to = DateCheckerService.toDate(now, timeOfDay.to);

        return now >= from && now < to;
    }

    private static toDate(date: Date, time: Time): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.hours, time.minutes, 0, 0);
    }
}
