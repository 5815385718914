import { ImageProfile } from '@cds/betting-offer';
import { Omit } from '@frontend/sports/common/base-utils';

import { EventParticipantImage } from '../event-model/model/event.model';

export enum FavouriteType {
    League = 'L',
    Participant = 'P',
    ParticipantV2 = 'P2',
    Fixture = 'F',
    FixtureV2 = 'F2',
}

export interface FavouritesDto {
    sport: FavouritesSport;
    name: string;
    lang: string;
    type: FavouriteType;
    itemId?: string; // itemId might not be there if we're not adding favourites by id
    region?: FavouritesRegion;
    imageProfile?: ImageProfile;
    order?: number;
    upcomingOrder?: number;
    participantImage?: EventParticipantImage;
}

export interface FavouritesSport {
    id: number;
    name: string;
}

export interface FavouritesRegion {
    id: number;
    name: string;
}

export interface FavouritesViewModel extends Omit<FavouritesDto, 'itemId'> {
    key: string;
    id: string;
    itemId: string;
    count: number;
}

export class FavouritesResponse {
    favourites: FavouritesViewModel[];
    isDefault: boolean;
}

export interface FavouritesError {
    message: string;
    severity: string;
}

export interface FavouritesSettings {
    isResponsive: boolean;
    source: string;
    hasRemoveBtn: boolean;
    showRight: boolean;
    showRemove?: boolean;
    swipeCountRemove: boolean;
}
