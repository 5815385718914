import { Injectable } from '@angular/core';

import { Utils, isDefined } from '@frontend/sports/common/base-utils';
import { CalendarConfig, CalendarData, PrettyUrlsConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { SportsTimeFilterRestriction } from '@frontend/sports/types/components/calendar';
import { IntlService, LoadingIndicatorService } from '@frontend/vanilla/core';
import { now, orderBy, sumBy, values } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BettingOfferApi } from '../cds/betting-offer-api.service';
import { DateProviderService } from '../common/date-provider.service';
import { UrlParam } from '../navigation-core/url-builder';
import { CalendarUrlParam, UrlHelperService } from '../navigation-core/url-helper.service';
import { CalendarIntervalService } from './calendar-interval.service';
import { CountIntervalService, IntervalCount, IntervalFilter, IntervalLink, SportIntervalCounts } from './count-interval.service';

export enum CalendarLayout {
    MiniCarousel = 'MiniCarousel',
    Stacked = 'Stacked',
    TilesCarousel = 'TilesCarousel',
}

export enum CalendarPage {
    Featured = 'Featured',
    Competitions = 'Competitions',
    SportsCalendar = 'SportsCalendar',
    LiveCalendar = 'LiveCalendar',
    AtozSports = 'AtozSports',
}

@Injectable({ providedIn: 'root' })
export class CalendarService {
    constructor(
        private bettingService: BettingOfferApi,
        private countIntervalService: CountIntervalService,
        private indicatorService: LoadingIndicatorService,
        private intlService: IntlService,
        private calendarIntervalService: CalendarIntervalService,
        private urlConfig: PrettyUrlsConfig,
        private utils: Utils,
        private urlHelper: UrlHelperService,
        public sitecore: Sitecore,
        private calendarData: CalendarData,
        private calendarConfig: CalendarConfig,
    ) {}

    getInterval(name?: string): IntervalFilter | undefined {
        return values(this.getCalendarIntervals())
            .filter((current) => current.name === name)
            .pop();
    }

    isInterval(name?: string): boolean {
        const { translations } = this.urlConfig;

        return [
            translations.today,
            translations.tomorrow,
            translations.after2days,
            translations.after3days,
            translations.in30minutes,
            translations.in60minutes,
            translations.in180minutes,
            translations.next2days,
            translations.next3days,
            translations.next5days,
            translations.midWeek,
            translations.thisWeekend,
        ].some((current) => current === name);
    }

    getTimeInterval(name?: string): { from?: string; to?: string } | undefined {
        const current = this.getInterval(name);

        if (current) {
            return {
                from: this.utils.toUtcIsoDatetimeString(current.from),
                to: this.utils.toUtcIsoDatetimeString(current.to),
            };
        }

        return;
    }

    /**
     * Returns a date that is relative to the current date by the number of daysFromCurrentDate. It is formatted with a given format or 'shortDate'.
     *
     * @param [daysFromCurrentDate=0]
     * @param [format]
     * @returns
     *
     * @memberOf Calendar
     */
    getDate(daysFromCurrentDate: number = 0, format?: string): string {
        let date = DateProviderService.getDate();
        format = format || 'shortDate';

        if (daysFromCurrentDate > 0) {
            date = new Date(date.setDate(date.getDate() + daysFromCurrentDate));
        }

        return this.intlService.formatDate(date, format) || '';
    }

    getCalendarCounts(sportId?: string): Observable<SportIntervalCounts> {
        const indicator = this.indicatorService.start();
        try {
            const intervals = this.getCalendarIntervals();

            return this.countIntervalService.getCountsForSport(intervals, sportId);
        } finally {
            indicator.done();
        }
    }

    isCalendarAvailable(sportId: string): Observable<boolean> {
        const intervals = this.getCalendarIntervals();
        const params = this.countIntervalService.buildRequestParameters(
            {
                calendar: {
                    from: intervals.today.from,
                    to: intervals.after3days.to,
                    title: 'calendar',
                    name: 'calendar',
                    key: 'calendar',
                },
            },
            sportId,
        );

        return this.bettingService.getBatchCounts(params).pipe(
            map((result) => {
                const tags = result ? result.calendar : [];

                return !!sumBy(tags, (tag) => tag.preMatch + tag.live);
            }),
        );
    }

    getCalendarIntervals(): Record<string, IntervalFilter> {
        return this.calendarIntervalService.getCalendarIntervals();
    }

    getCalendarLinks(pageName: CalendarPage, sport?: UrlParam, activeLink?: string): IntervalLink[] {
        const time = now();
        const intervals = this.getCalendarIntervals();
        const intervalsPage = this.getSitecoreIntervals(intervals, pageName, sport);
        const links = intervalsPage.map((key) => this.getUrl(key, intervals[key], sport, activeLink)).filter(isDefined);

        return orderBy(links, [(link) => (!link.to || link.to.getTime() < time ? time : link.to)], ['asc']);
    }

    private getSitecoreIntervals(intervals: { [key: string]: IntervalFilter }, pageName: CalendarPage, sport?: UrlParam): string[] {
        switch (pageName) {
            case CalendarPage.Featured:
                return this.getPageIntervals(intervals, this.calendarData.calendarItems.featurePage, sport);

            case CalendarPage.Competitions:
                return this.getPageIntervals(intervals, this.calendarData.calendarItems.competitionPage, sport);

            case CalendarPage.AtozSports:
                return this.getIntervalForAllSports(this.calendarData.calendarItems.atoZPage);
            default:
                return [];
        }
    }

    private getIntervalForAllSports(intervals: string[]): string[] {
        const keyList: string[] = [];

        intervals.forEach((detail) => {
            keyList.push(detail);
        });

        return keyList;
    }

    private getPageIntervals(intervals: { [key: string]: IntervalFilter }, sportDetails: SportsTimeFilterRestriction[], sport?: UrlParam): string[] {
        const keyList: string[] = [];

        sportDetails.forEach((detail) => {
            let intervalKey: string | undefined = '';
            if (detail.allSports) {
                intervalKey = intervals[detail.timeFilter] ? detail.timeFilter : undefined;
            } else if (detail.sports.find((k) => k.name.toLowerCase() === sport?.name.toLowerCase())) {
                intervalKey = intervals[detail.timeFilter] ? detail.timeFilter : undefined;
            }
            if (intervalKey) {
                keyList.push(intervalKey);
            }
        });

        return keyList;
    }

    getUrl(
        key: string,
        interval: IntervalFilter | IntervalCount,
        sport: UrlParam | undefined,
        activeLink?: string,
        region?: UrlParam,
        league?: UrlParam,
    ): IntervalLink | undefined {
        let url: string;

        if (key === 'live') {
            return;
        } else {
            if (sport) {
                url = region ? this.urlHelper.getSportUrl(sport, interval.name, region, league) : this.urlHelper.getSportUrl(sport, interval.name);
            } else {
                url = this.urlHelper.getCalendarUrl(interval.name);
            }

            interval.date = this.intlService.formatDate(interval.from!, this.calendarConfig.topMenuDateFormat) || '';
            interval.title = this.getIntervalTitle(key, interval);
        }

        interval.icon = this.sitecore.globalMessages.SportsCalendarIcon;
        interval.active = interval.name === activeLink;

        return { ...interval, url };
    }

    getIntervalTitle(key: string, interval: IntervalFilter): string {
        switch (key) {
            case CalendarUrlParam.In30minutes:
                return this.sitecore.globalMessages.in30Minutes;
            case CalendarUrlParam.In60minutes:
                return this.sitecore.globalMessages.in60Minutes;
            case CalendarUrlParam.In180minutes:
                return this.sitecore.globalMessages.in180Minutes;
            case CalendarUrlParam.After2days:
                return this.sitecore.globalMessages.After2Days;
            case CalendarUrlParam.After3days:
                return this.sitecore.globalMessages.After3Days;
            default:
                return interval.title;
        }
    }
}
