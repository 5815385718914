import { Injectable } from '@angular/core';

import { FixtureType } from '@cds/betting-offer';
import { TagCount, TagType } from '@cds/betting-offer/tags';
import { BatchRequest, FixtureCountsRequest, FixtureState } from '@cds/query-objects';
import { CountItem, Utils, sportModelMethods } from '@frontend/sports/common/base-utils';
import { PrettyUrlsConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { filter, first, keys, mapValues, pickBy, sumBy, values } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BettingOfferApi } from '../cds/betting-offer-api.service';
import { DateProviderService } from '../common/date-provider.service';

export type CalendarIntervalKey =
    | 'now'
    | 'live'
    | 'in30minutes'
    | 'in60minutes'
    | 'in180minutes'
    | 'today'
    | 'tomorrow'
    | 'next16hours'
    | 'next2days'
    | 'next3days'
    | 'next5days'
    | 'after2days'
    | 'after3days'
    | 'midWeek'
    | 'thisWeekend'
    | 'all'
    | 'calendar';

export enum Days {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}
export interface IntervalFilter {
    title: string;
    name: string;
    from?: Date;
    to?: Date;
    live?: boolean;
    active?: boolean;
    date?: string;
    icon?: string;
    trackingSource?: string;
    key: CalendarIntervalKey;
}

export interface IntervalCount extends IntervalFilter {
    count: number;
}

export interface IntervalLink extends IntervalFilter {
    url: string;
}

export interface IntervalCountsMap {
    [key: string]: IntervalCount | undefined;
}

export interface SportIntervalCounts {
    intervals: IntervalCountsMap;
    sport?: CountItem;
}

@Injectable({ providedIn: 'root' })
export class CountIntervalService {
    constructor(
        private utils: Utils,
        private bettingService: BettingOfferApi,
        private urlConfig: PrettyUrlsConfig,
        private sitecore: Sitecore,
    ) {}

    intervalStartOfDay(name: string, from: number, to: number, title: string, key: CalendarIntervalKey, trackingSource?: string): IntervalFilter {
        const now = DateProviderService.getDate();
        const today = DateProviderService.getStartOfDay(now);
        const eTitle = trackingSource;
        const filterForDay = this.buildInterval(name, today, from, to, title, key);
        filterForDay.trackingSource = eTitle;

        return filterForDay;
    }

    intervalStartingNow(name: string, from: number, to: number, title: string, key: CalendarIntervalKey, trackingSource?: string): IntervalFilter {
        const now = DateProviderService.getDate();
        const eTitle = trackingSource;
        const filterForTime = this.buildInterval(name, now, from, to, title, key);
        filterForTime.trackingSource = eTitle;

        return filterForTime;
    }

    getCounts(intervals: { [key: string]: IntervalFilter }): Observable<IntervalCountsMap> {
        return this.getCountsForSport(intervals).pipe(map((response) => response.intervals));
    }

    getCountsForSport(intervals: { [key: string]: IntervalFilter }, sportId?: string): Observable<SportIntervalCounts> {
        /**
         * Whenever app requests ALL counts, live counts can be derived from them
         */
        const canCountLive = intervals['all'] && intervals['live'];
        if (canCountLive) {
            delete intervals['live'];
        }
        const params = this.buildRequestParameters(intervals, sportId);

        return this.bettingService.getBatchCounts(params).pipe(
            map((countResponse) => {
                if (!countResponse) {
                    return { intervals: {}, sport: undefined };
                }

                const counts = mapValues(countResponse, (tagCounts, key) => {
                    return {
                        ...intervals[key],
                        count: sumBy(tagCounts, (c) => c.live + (key === 'live' ? 0 : c.preMatch)),
                    } as IntervalCount;
                });

                if (canCountLive) {
                    counts['live'] = {
                        name: this.urlConfig.translations.live,
                        title: this.sitecore.globalMessages.NavLive,
                        live: true,
                        count: sumBy(countResponse['all'], (c) => c.live),
                        key: 'live',
                    };
                }

                const countsWithValue = pickBy(counts, (c) => c.count > 0) as { [key: string]: IntervalCount };
                const sport = sportId ? this.getSportItem(+sportId, countResponse) : undefined;

                return {
                    intervals: countsWithValue,
                    sport,
                };
            }),
        );
    }

    buildRequestParameters(intervals: { [key: string]: IntervalFilter }, sportId?: string): BatchRequest<FixtureCountsRequest>[] {
        return keys(intervals).map((key) => ({
            batchId: key,
            request: {
                fixtureTypes: FixtureType.Standard,
                state: FixtureState.Latest,
                tagTypes: TagType.Sport,
                extendedTags: TagType.Sport,
                from: this.utils.toUtcIsoDatetimeString(intervals[key].from),
                to: this.utils.toUtcIsoDatetimeString(intervals[key].to),
                sportIds: key === 'all' ? undefined : sportId,
            },
        }));
    }

    private buildInterval(name: string, start: Date, fromOffset: number, toOffset: number, title: string, key: CalendarIntervalKey): IntervalFilter {
        return {
            name,
            from: this.utils.getDateForOffset(start, fromOffset),
            to: this.utils.getDateForOffset(start, toOffset),
            title,
            key,
        };
    }

    private getSportItem(sportId: number, tagCountMap: { [key: string]: TagCount[] }): CountItem | undefined {
        /**
         * Filer by sport id is needed because when requesting ALL interval it includes all the sports
         * and we would like to filter it to match sportId; for any other interval response will contain single sport tag
         */
        const sportTag = filter(first(values(tagCountMap)), (tagCount) => tagCount.tag.id === sportId);

        return first(sportModelMethods.fromTag(sportTag));
    }
}
