import { Injectable } from '@angular/core';

import { FixtureBase, FixtureType, Option, OptionStatus, ParticipantStatus, ParticipantType } from '@cds/betting-offer';
import { FixtureAddons, FixturePricingState } from '@cds/betting-offer/add-ons';
import { DayOfRaceDate } from '@cds/betting-offer/domain-specific/horse-racing';
import { RacingConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { IntlService } from '@frontend/vanilla/core';
import { values } from 'lodash-es';

import { EventOptionGroup } from '../../event-model/model/event.model';
import { getDayOfRace } from '../helpers';
import {
    HorseRacingTimeFormFixture,
    OptionMarketsFixture,
    ParticipantsFixture,
    WinGameFixture,
    WinGameFixturePreview,
} from '../model/win-game-fixture';

export interface PricingStateFixture extends Pick<FixtureBase, 'fixtureType'> {
    addons: Pick<FixtureAddons, 'pricingState'>;
}

export function updateStatus(fixture: WinGameFixture, sitecore: any): string {
    if (fixture.addons.raceSummary) {
        const raceStatus = Object.getOwnPropertyNames(sitecore.horseRacing.raceStatuses).find(
            (o) => o.toUpperCase() === fixture.addons.raceSummary.toUpperCase().split(' ').join(''),
        );

        return raceStatus && sitecore.horseRacing.raceStatuses[raceStatus]
            ? sitecore.horseRacing.raceStatuses[raceStatus]
            : fixture.addons.raceSummary;
    } else {
        return '';
    }
}

@Injectable({
    providedIn: 'root',
})
export class HorsesFixtureHelper {
    constructor(
        private sitecore: Sitecore,
        private intlService: IntlService,
        private racingConfig: RacingConfig,
    ) {}

    static isTradable(fixture: PricingStateFixture): boolean {
        return (
            fixture.fixtureType !== FixtureType.DayOfRace ||
            fixture.addons.pricingState === FixturePricingState.None ||
            fixture.addons.pricingState === FixturePricingState.Priced
        );
    }

    dayOfRaceType(fixture: WinGameFixturePreview): DayOfRaceDate {
        if (!fixture) {
            return DayOfRaceDate.Today;
        }
        const now = new Date();
        const startTime = new Date(fixture.startDate);

        return now.getDate() === startTime.getDate() && now.getMonth() === startTime.getMonth() && now.getFullYear() === startTime.getFullYear()
            ? DayOfRaceDate.Today
            : DayOfRaceDate.Tomorrow;
    }

    getOptions(options: Option[]): Option[] {
        return options.filter((option) => option.status !== OptionStatus.Hidden);
    }

    filterRaceSummary<T extends WinGameFixturePreview>(fixture: T): T {
        return {
            ...fixture,
            addons: {
                ...fixture.addons,
                raceSummary: this.getRaceSummary(fixture.addons?.raceSummary, fixture.addons?.isResulted || fixture.addons?.isRaceOver),
            },
        };
    }

    private getRaceSummary(key: string, resulted?: boolean): string {
        const hasExcludedRaceSummary = values(this.sitecore.horseRacing.excludedRaceSummary).some((r) => r === key);

        return hasExcludedRaceSummary || resulted ? '' : this.sitecore.horseRacing.raceSummary[key]?.toLowerCase() || key?.toLowerCase();
    }

    getRunnersCount(fixture: HorseRacingTimeFormFixture): number {
        return fixture.participants.filter(
            (fp) =>
                fp.properties!.type !== ParticipantType.Favourite &&
                fp.status !== ParticipantStatus.NonRunner &&
                fp.status !== ParticipantStatus.Withdrawn,
        ).length;
    }

    filterSelectedMarketOptionGroup(fixture: WinGameFixture, selectedMarketId: number): EventOptionGroup[] {
        return fixture.optionGroups.filter((optionGroup) => optionGroup.detailedGrouping.marketSet === selectedMarketId);
    }

    updateStatus(fixture: WinGameFixture): string {
        if (fixture.addons.raceSummary) {
            const raceStatus = Object.getOwnPropertyNames(this.sitecore.horseRacing.raceStatuses).find(
                (o) => o.toUpperCase() === fixture.addons.raceSummary.toUpperCase().replaceAll(' ', ''),
            );

            return raceStatus ? this.sitecore.horseRacing.raceStatuses[raceStatus] : '';
        } else {
            return '';
        }
    }

    checkIfBOGApplicable(fixture: WinGameFixture | OptionMarketsFixture | ParticipantsFixture): boolean | undefined {
        const day = getDayOfRace(fixture.startDate);
        if (day === DayOfRaceDate.Tomorrow || this.intlService.formatDate(fixture.startDate, 'HH:mm') <= this.racingConfig.bogTodayThresholdTime) {
            return false;
        }

        return fixture.addons.bestOddsGuarantee;
    }
}
