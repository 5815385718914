import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';

@HooksWireup()
@Directive({
    selector: '[msRemoveHref]',
})
export class RemoveHrefDirective extends OnDestroyCleanup implements OnChanges {
    @Input('msRemoveHref') removeHref: boolean;

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.removeHref) {
            this.renderer.removeAttribute(this.element.nativeElement, 'href');
        }
    }
}
