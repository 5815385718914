import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { EpcotModule } from './epcot-config.service';
import { EpcotConfigService } from './epcot-config.service';

export interface IEpcotConfigOptions {
    showInEpcot: boolean;
    module?: EpcotModule;
}

@Directive({
    selector: '[msShowInEpcot]',
    standalone: true,
})
export class ShowInEpcotDirective implements OnInit {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private epcotConfigService: EpcotConfigService,
    ) {}

    @Input() msShowInEpcot: IEpcotConfigOptions;

    ngOnInit(): void {
        if (this.msShowInEpcot.showInEpcot === this.epcotConfigService.isEnabled(this.msShowInEpcot.module)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
