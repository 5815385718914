import { Inject, Injectable } from '@angular/core';

import { MarqueeIndexedFixtureView } from '@cds/betting-offer/domain-specific';
import { MarqueeRequest } from '@cds/query-objects';
import { Observable } from 'rxjs';

import { BaseCdsApi } from './cds-api.service';
import { BaseCdsApiFactory, CDS_API_FACTORY } from './cds-api.service.factory';

@Injectable({ providedIn: 'root' })
export class MarqueeApi {
    private cdsApi: BaseCdsApi;

    constructor(@Inject(CDS_API_FACTORY) cdsApiServiceFactory: BaseCdsApiFactory) {
        this.cdsApi = cdsApiServiceFactory({ endpoint: '/marquee' });
    }

    getMarquees(request: MarqueeRequest): Observable<MarqueeIndexedFixtureView[] | undefined> {
        return this.cdsApi.post<MarqueeIndexedFixtureView[]>(`/fixtures`, request);
    }
}
