import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

export interface BreadcrumbSlot {
    component: any;
    inputs?: any;
    required?: boolean;
}

@Injectable({ providedIn: 'root' })
export class BreadcrumbsSlotService {
    private _componentRegistered: BehaviorSubject<BreadcrumbSlot | undefined>;

    constructor() {
        this._componentRegistered = new BehaviorSubject<BreadcrumbSlot | undefined>(undefined);
    }

    setSlot(slot: BreadcrumbSlot): void {
        this._componentRegistered.next(slot);
    }

    clearSlot(): void {
        this._componentRegistered.next(undefined);
    }

    get slotRegistered$(): Observable<BreadcrumbSlot | undefined> {
        return this._componentRegistered.asObservable();
    }

    get registeredComponent(): BreadcrumbSlot | undefined {
        return this._componentRegistered.getValue();
    }
}
