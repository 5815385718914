export const SYSTEMS = [
    { id: 'systembankersingles', picks: -1, bets: -1, group: 1, basis: [1] },
    { id: 'system2of3', picks: 3, bets: 3, group: 2, basis: [2] },
    { id: 'patent', picks: 3, bets: 7, group: 9, basis: [1, 2, 3] },
    { id: 'trixie', picks: 3, bets: 4, group: 8, basis: [2, 3] },
    { id: 'system3of4', picks: 4, bets: 4, group: 3, basis: [3] },
    { id: 'system2of4', picks: 4, bets: 6, group: 2, basis: [2] },
    { id: 'lucky15', picks: 4, bets: 15, group: 9, basis: [1, 2, 3, 4] },
    { id: 'yankee', picks: 4, bets: 11, group: 8, basis: [2, 3, 4] },
    { id: 'system4of5', picks: 5, bets: 5, group: 4, basis: [4] },
    { id: 'system3of5', picks: 5, bets: 10, group: 3, basis: [3] },
    { id: 'system2of5', picks: 5, bets: 10, group: 2, basis: [2] },
    { id: 'lucky31', picks: 5, bets: 31, group: 9, basis: [1, 2, 3, 4, 5] },
    { id: 'canadian', picks: 5, bets: 26, group: 8, basis: [2, 3, 4, 5] },
    { id: 'system5of6', picks: 6, bets: 6, group: 5, basis: [5] },
    { id: 'system4of6', picks: 6, bets: 15, group: 4, basis: [4] },
    { id: 'system3of6', picks: 6, bets: 20, group: 3, basis: [3] },
    { id: 'system2of6', picks: 6, bets: 15, group: 2, basis: [2] },
    { id: 'lucky63', picks: 6, bets: 63, group: 9, basis: [1, 2, 3, 4, 5, 6] },
    { id: 'heinz', picks: 6, bets: 57, group: 8, basis: [2, 3, 4, 5, 6] },
    { id: 'system6of7', picks: 7, bets: 7, group: 6, basis: [6] },
    { id: 'system5of7', picks: 7, bets: 21, group: 5, basis: [5] },
    { id: 'system4of7', picks: 7, bets: 35, group: 4, basis: [4] },
    { id: 'system3of7', picks: 7, bets: 35, group: 3, basis: [3] },
    { id: 'system2of7', picks: 7, bets: 21, group: 2, basis: [2] },
    { id: 'superheinz', picks: 7, bets: 120, group: 8, basis: [2, 3, 4, 5, 6, 7] },
    { id: 'system7of8', picks: 8, bets: 8, group: 7, basis: [7] },
    { id: 'system6of8', picks: 8, bets: 28, group: 6, basis: [6] },
    { id: 'system5of8', picks: 8, bets: 56, group: 5, basis: [5] },
    { id: 'system4of8', picks: 8, bets: 70, group: 4, basis: [4] },
    { id: 'system3of8', picks: 8, bets: 56, group: 3, basis: [3] },
    { id: 'system2of8', picks: 8, bets: 28, group: 2, basis: [2] },
    { id: 'goliath', picks: 8, bets: 247, group: 8, basis: [2, 3, 4, 5, 6, 7, 8] },
];
