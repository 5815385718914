import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StakeConfig } from '@frontend/sports/common/client-config-data-access';

import { DynamicFontResizerComponent } from '../common/dynamic-font-resizer.component';
import { ShowInEpcotDirective } from '../common/epcot-config.directive';
import { MoneyModule } from '../money/money.module';
import { UserService } from '../user/services/user.service';
import { IncrementalStakesComponent } from './incremental-stakes.component';
import { NumpadValidationParams } from './model';
import { NumpadErrorComponent } from './numpad-error.component';
import { NumpadHostComponent } from './numpad-host.component';
import { NumpadKeysComponent } from './numpad-keys.component';
import { NumpadScrollDirective } from './numpad-scroll.directive';
import { NumpadComponent } from './numpad.component';
import { StakeFieldComponent } from './stake-field.component';
import { StakeFontResizerDirective } from './stake-font-resizer.directive';
import { StakeInputComponent } from './stake-input.component';
import { StakeWrapperComponent } from './stake-wrapper.component';
import { StakeComponent } from './stake.component';
import { ValidateStakeInputDirective } from './validate-stake-input.directive';

export function validationParamsFactory(stakeConfig: StakeConfig, userService: UserService): NumpadValidationParams {
    const currency = userService.currency.toLowerCase();

    return {
        maxLeadingDigits: stakeConfig.maxLeadingDigits[currency] ? stakeConfig.maxLeadingDigits[currency] : stakeConfig.maxLeadingDigits.default,
        maxTrailingDigits: 2,
    };
}

@NgModule({
    imports: [CommonModule, MoneyModule, ShowInEpcotDirective, DynamicFontResizerComponent],
    declarations: [
        IncrementalStakesComponent,
        NumpadKeysComponent,
        NumpadComponent,
        NumpadScrollDirective,
        StakeFontResizerDirective,
        StakeComponent,
        StakeFieldComponent,
        NumpadHostComponent,
        StakeInputComponent,
        ValidateStakeInputDirective,
        NumpadErrorComponent,
        StakeWrapperComponent,
    ],
    exports: [
        IncrementalStakesComponent,
        NumpadKeysComponent,
        NumpadComponent,
        StakeComponent,
        StakeFieldComponent,
        NumpadHostComponent,
        NumpadScrollDirective,
        StakeFontResizerDirective,
        StakeInputComponent,
        NumpadErrorComponent,
        StakeWrapperComponent,
    ],
})
export class NumpadModule {}
