import { getCurrencySymbol } from '@angular/common';
import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { CrmPromotionReplaceParametersService } from 'packages/sports/web/app/src/crm-promotion/crm-promotion-replace-parameters.service';
import { BetAndGetTokenData, IRewardTokenData, TokenFilter } from 'packages/sports/web/app/src/tokens-base/token-base.models';
import { UserService } from 'packages/sports/web/app/src/user/services/user.service';

import { OddsDisplayFormat } from '../../../core/odds/odds-display-format';
import { BetAndGetToken, FreeBetToken, IRewardToken, OddsBoostToken, RiskFreeToken, TokenOddsData } from '../reward-tokens.model';
import { RewardTokensFormattersService } from './reward-tokens-formatters.service';
import { isBetAndGetToken, isFreebetToken, isOddsBoostToken, isRiskFreeToken } from './reward-tokens.utils';

@Injectable({ providedIn: 'root' })
export class RewardTokensFactory {
    currencySymbol: string;
    currencyCode: string;

    constructor(
        public sitecore: Sitecore,
        private replaceParametersService: CrmPromotionReplaceParametersService,
        private user: UserService,
        private tokenFormatterService: RewardTokensFormattersService,
    ) {}

    toModelList(tokensData: IRewardTokenData[]): IRewardToken[] {
        this.currencySymbol = getCurrencySymbol(this.user.currency, 'narrow');
        this.currencyCode = this.user.currency;
        const tokens = tokensData.map((t) => {
            let token: OddsBoostToken | RiskFreeToken | FreeBetToken | BetAndGetToken;

            if (isOddsBoostToken(t)) {
                token = this.mapBase(t) as OddsBoostToken;
                token.boostFactor = t.boostFactor;
                token.maximumStake = t.maximumStake;
                token.maxWinningsBoost = t.maxWinningsBoost;
                token.minimumStake = t.minimumStake;
                token.oddsBoostFormula = t.oddsBoostFormula;
            }
            if (isRiskFreeToken(t)) {
                token = this.mapBase(t) as RiskFreeToken;
                token.maximumPayout = t.maximumPayout;
                token.minimumStake = t.minimumStake;
                token.riskFreePercentage = t.riskFreePercentage;
            }
            if (isFreebetToken(t)) {
                token = this.mapBase(t) as FreeBetToken;
                token.amount = t.amount;
            }

            if (isBetAndGetToken(t)) {
                const betAndGetToken = t as BetAndGetTokenData;
                token = this.mapBase(t) as BetAndGetToken;
                token.payoutValue = betAndGetToken.payoutValue;
                token.rewardTriggerType = betAndGetToken.rewardTriggerType;
                token.percentagePayout = betAndGetToken.percentagePayout;
                token.maximumPayout = betAndGetToken.maximumPayout;
                token.minimumPayout = betAndGetToken.minimumPayout;
                token.betStatus = betAndGetToken.betStatus;
                token.minimumStake = betAndGetToken.minimumStake;
                token.buildABet = betAndGetToken.buildABet;
                token.subType = betAndGetToken.subType;
                token.isEarlyPayoutEnabled = betAndGetToken.isEarlyCashOut;
            }

            return token!;
        });

        return tokens;
    }

    //  Update the value of SelectionLevelOdds and OverAllOdds based on user oddsFormat. We will not get this from CRM.
    private updateReplacebleParams(token: IRewardTokenData) {
        if (token.replaceableParameters) {
            const selectionOddsKey = token.replaceableParameters['#TOKEN_SELECTION_LEVEL_MIN_ODDS#'];
            const totalOddsKey = token.replaceableParameters['#TOKEN_MIN_OVERALL_ODDS#'];
            const userOddsFormat = this.user.oddsFormat;
            const oddsFormat = userOddsFormat ? userOddsFormat : OddsDisplayFormat.EU;

            if (selectionOddsKey) {
                selectionOddsKey.value = this.getFormatedOdds(oddsFormat as OddsDisplayFormat, token.selectionLevelMinimumOdds);
            }

            if (totalOddsKey) {
                totalOddsKey.value = this.getFormatedOdds(oddsFormat as OddsDisplayFormat, token.minimumOdds);
            }
        }
    }

    private getFormatedOdds(oddsFormat: OddsDisplayFormat, tokenOdds: TokenOddsData | undefined): string {
        if (!tokenOdds) {
            return '';
        }

        switch (oddsFormat) {
            case OddsDisplayFormat.EU:
                return tokenOdds.european.toString();
            case OddsDisplayFormat.US:
                return tokenOdds.american.toString();
            case OddsDisplayFormat.UK:
                return tokenOdds.britishNumerator.toString() + '/' + tokenOdds.britishDenominator.toString();
        }
    }

    getTokenCustomDetails(token: IRewardTokenData): [string, string] {
        const title = token.customTitle
            ? this.replaceParametersService.replaceParameters(
                  this.tokenFormatterService.replaceCurrency(token.customTitle, this.currencySymbol, this.currencyCode),
                  token.replaceableParameters,
                  true,
                  true,
                  true,
              )
            : '';
        const description = token.customDescription
            ? this.replaceParametersService.replaceParameters(
                  this.tokenFormatterService.replaceCurrency(token.customDescription, this.currencySymbol, this.currencyCode),
                  token.replaceableParameters,
                  false,
                  false,
                  true,
              )
            : '';

        return [title, description];
    }

    private mapBase(t: IRewardTokenData): IRewardToken {
        const token = <IRewardToken>{};
        this.updateReplacebleParams(t);
        const [title, description] = this.getTokenCustomDetails(t);
        token.id = t.id;
        token.startDate = new Date(t.startDateUtc);
        token.expiryDate = new Date(t.expiryDateUtc);
        token.cmsItemId = t.cmsItemId;
        token.userTokenId = t.userTokenId;
        token.minimumLegs = t.minimumLegs;
        token.wageringMultiplier = t.wageringMultiplier;
        token.payoutType = t.payoutType;
        token.tokenType = t.tokenType;
        token.filter = this.mapFilter(t.filter);
        token.rewardTokenType = t.rewardTokenType;
        token.payoutExpiry = t.payoutExpiry;
        token.minOdds = t.minOdds;
        token.selectionLevelMinimumOdds = t.selectionLevelMinimumOdds;
        token.replaceableParameters = t.replaceableParameters;
        token.minimumOdds = t.minimumOdds;
        token.isBetBuilder = t.isBetBuilder;
        token.isSingleAndComboSelected = t.isSingleAndComboSelected;
        token.maximumOdds = t.maximumOdds;
        token.selectionLevelMaximumOdds = t.selectionLevelMaximumOdds;
        token.customMarketTypes = t.customMarketTypes;
        token.isIncrementalPayout = t.isIncrementalPayout;
        token.incrementalBetAmount = t.incrementalBetAmount;
        token.incrementalPayoutNoOfTimes = t.incrementalPayoutNoOfTimes;
        token.rewardTargetType = t.rewardTargetType;

        token.customTitle = title;
        token.customDescription = description;
        token.url = t.url;

        return token;
    }

    private mapFilter(filter: TokenFilter) {
        const newFilter: TokenFilter = { ...filter };

        newFilter.marketParameters = newFilter.marketParameters ?? [];
        newFilter.marketTemplates = newFilter.marketTemplates ?? [];
        newFilter.markets = newFilter.markets ?? [];
        newFilter.options = newFilter.options ?? [];

        return newFilter;
    }
}
