import { Injectable } from '@angular/core';

import { BottomNavService, UrlService } from '@frontend/vanilla/core';
import { MarketUrlParam } from 'packages/sports/web/app/src/navigation-core/url-helper.service';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuickBetDrawerVisibilityService {
    private readonly visibilitySubject = new BehaviorSubject(true);

    constructor(
        private bottomNavService: BottomNavService,
        private urlService: UrlService,
    ) {}

    private enabled$ = this.bottomNavService.whenReady.pipe(
        switchMap(() =>
            this.bottomNavService.isEnabled().pipe(
                map(
                    (bottomNavEnabled) =>
                        bottomNavEnabled &&
                        (this.urlService.current().search.get('market') === MarketUrlParam.BetBuilder ||
                            this.urlService.current().search.get('market') === 'SGP' ||
                            this.urlService.current().search.get('market')?.includes('SGP')), //market=SGP:Teams
                ),
            ),
        ),
    );

    displayDrawer$ = combineLatest([this.visibilitySubject, this.enabled$]).pipe(map(([isVisible, isEnabled]) => isEnabled && isVisible));

    hideDrawer(): void {
        this.visibilitySubject.next(false);
    }

    showDrawer(): void {
        this.visibilitySubject.next(true);
    }
}
