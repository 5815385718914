import { Inject, Injectable } from '@angular/core';

import { OfferCategory, TemplateCategory } from '@cds/betting-offer';
import { FixtureViewGroupingConfiguration } from '@cds/betting-offer/grouping/fixture-view';
import { GridViewGroupingConfiguration } from '@cds/betting-offer/grouping/grid-view';
import { SportConstant, usePreloaderData } from '@frontend/sports/common/base-utils';
import { GridConfig, MarketGroupingConfig } from '@frontend/sports/common/client-config-data-access';
import { Observable } from 'rxjs';

import { BaseCdsApi } from './cds-api.service';
import { BaseCdsApiFactory, CDS_API_FACTORY } from './cds-api.service.factory';

export interface FixtureContextRequest {
    category?: string;
    sportId?: number;
    regionIds?: number[];
    competitionIds?: number[];
    virtualCompetitionIds?: number;
    virtualCompetitionGroupIds?: number;
}

@Injectable({ providedIn: 'root' })
export class OfferGroupingApi {
    private cdsApi: BaseCdsApi;

    constructor(
        @Inject(CDS_API_FACTORY) apiFactory: BaseCdsApiFactory,
        private marketGroupingConfig: MarketGroupingConfig,
        private gridConfig: GridConfig,
    ) {
        this.cdsApi = apiFactory({ endpoint: '/offer-grouping' });
    }

    getFixtureGrouping(sportId: number): Observable<FixtureViewGroupingConfiguration | undefined> {
        return this.cdsApi.get<FixtureViewGroupingConfiguration>(
            this.marketGroupingConfig.regionalisedGroupingEnabled ? '/fixture-view/regional' : '/fixture-view',
            {
                sportId,
            },
        );
    }

    getFixtureGroupingBatch(sportIds: number[]): Observable<FixtureViewGroupingConfiguration[] | undefined> {
        return this.cdsApi.get<FixtureViewGroupingConfiguration[]>(
            this.marketGroupingConfig.regionalisedGroupingEnabled ? '/fixture-view/batch/regional' : '/fixture-view/batch',
            {
                sportIds: sportIds.join(),
            },
        );
    }

    @usePreloaderData({
        preloader: (<any>window).MS2JS?.GridGroupLoader,
    })
    getGridGrouping(): Observable<GridViewGroupingConfiguration[] | undefined> {
        return this.cdsApi.get<GridViewGroupingConfiguration[]>(
            this.gridConfig.isRegionalisedGridEnabled ? '/grid-view/all/regional' : '/grid-view/all',
        );
    }

    getFixturesContext(request: FixtureContextRequest): Observable<TemplateCategory[] | undefined> {
        const regionIds: number[] = [];
        const tournamentIds: number[] = [];

        if (request.regionIds) {
            if (request.sportId === SportConstant.Tennis) {
                tournamentIds.push(...request.regionIds);
            } else {
                regionIds.push(...request.regionIds);
            }
        }

        return this.cdsApi.get<TemplateCategory[]>(`/non-gridable-categories`, {
            category: request.category || OfferCategory.NonGridable,
            sportId: request.sportId,
            regionIds: regionIds.join(','),
            tournamentIds: tournamentIds.join(','),
            competitionIds: (request.competitionIds || []).join(','),
            virtualCompetitionIds: request.virtualCompetitionIds,
            virtualCompetitionGroupIds: request.virtualCompetitionGroupIds,
        });
    }
}
