import { OfferSource } from '@cds';
import { Fixture, FixtureViewType, HybridFixtureData, HybridFixtureStatus, Participant, ParticipantType } from '@cds/betting-offer';
import { Competition } from '@cds/betting-offer/tags';
import { clone, includes } from 'lodash-es';

import { EventParticipant, EventParticipantType, LeagueModel, SportModel } from '../model/event.model';

export function mapLeague(competition: Competition, stageId?: number, groupId?: number): LeagueModel {
    return competition
        ? {
              id: competition.virtualCompetitionId || competition.id,
              name: competition.name.short || competition.name.value,
              sign: competition.name.short ? competition.name.shortSign : competition.name.sign,
              parentLeagueId: competition.parentLeagueId,
              groupId,
              stageId,
              realCompetitionId: competition.virtualCompetitionId ? competition.id : undefined,
              imageProfile: competition.imageProfile,
          }
        : {
              id: 0,
              name: '',
          };
}

export function mapSport(fixture: Fixture): SportModel {
    return {
        id: fixture.sport.id,
        name: fixture.sport.name.value,
        virtual: fixture.isVirtual,
        sign: fixture.sport.name.sign,
        isEsport: fixture.sport.isEsport,
    };
}

export function mapParticipants(participants: Participant[], fixtureViewType: FixtureViewType, hybridData: HybridFixtureData): EventParticipant[] {
    if (hybridData?.status === HybridFixtureStatus.Ok) {
        participants = participants.filter((p) => p.source === OfferSource.V1);
    }

    return mapToEventParticipants(participants, fixtureViewType);
}

export function mapToEventParticipants(participants: Participant[], fixtureViewType: FixtureViewType): EventParticipant[] {
    if (participants.length === 2 && participants[0].properties?.type === ParticipantType.AwayTeam) {
        participants.reverse();
    }

    const eventParticipants = participants.map<EventParticipant>((participant) => {
        let name = participant.name.value;
        let country: string | undefined;
        const countryCount = name.split('(').length - 1;
        if (countryCount >= 2) {
            const regExp = /\(([^)]+)\)/g;
            const matches = name.match(regExp);
            const countries: string[] = [];
            matches &&
                matches.forEach((countryInfo) => {
                    countries.push(countryInfo.substring(1, countryInfo.length - 1));
                });
            country = countries.join('/');
            name = name
                .replace(/\\*\([^)]*\)\\*/g, '')
                .trim()
                .replace(/ \//g, '/');
        } else {
            const openIndex = name.indexOf('(');
            const closeIndex = name.indexOf(')');
            if (openIndex + closeIndex > -2) {
                country = name.slice(openIndex + 1, closeIndex);
                name = name.slice(0, openIndex - 1);
            }
        }

        return {
            id: participant.participantId,
            fixtureParticipantId: participant.id,
            shortName: participant.name.short,
            name,
            country,
            image: clone(participant.image),
        };
    });

    // trying to add Home/Away info to participants
    if (eventParticipants.length === 2 && (fixtureViewType === FixtureViewType.American || fixtureViewType === FixtureViewType.European)) {
        const homeTeam = fixtureViewType === FixtureViewType.European ? 0 : 1;
        const awayTeam = fixtureViewType === FixtureViewType.European ? 1 : 0;

        eventParticipants[homeTeam].type = EventParticipantType.Home;
        eventParticipants[awayTeam].type = EventParticipantType.Away;
    }

    return eventParticipants;
}

export function isPlayer(participant: Participant): boolean {
    const participantTypesPlayer = [ParticipantType.Player, ParticipantType.AwayPlayer, ParticipantType.HomePlayer];

    return includes(participantTypesPlayer, participant.properties?.type);
}
