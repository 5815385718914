import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { WidgetLayoutResponse } from '@frontend/sports/types/components/widget';
import { Observable } from 'rxjs';

import { WidgetContextService } from './widget-context.service';

@Injectable({ providedIn: 'root' })
export class WidgetResolver {
    constructor(private context: WidgetContextService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<WidgetLayoutResponse> {
        return this.context.getLayout(this.context.getContext({ snapshot: route }));
    }

    preResolve(route: ActivatedRouteSnapshot) {
        this.resolve(route).subscribe();
    }
}
