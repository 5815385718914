import { BehaviorSubject, Observable } from 'rxjs';

import { CustomLoadingIndicatorHandlerWrapper, ILoadingIndicatorHandler, ILoadingIndicatorShutdown } from './custom-loading-indicator-handler';

export interface ICustomLoadingIndicatorVisibilityService {
    readonly visible: Observable<boolean>;
}

export class CustomLoadingIndicatorSwitchService implements ICustomLoadingIndicatorVisibilityService, ILoadingIndicatorShutdown {
    private visibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private showCount = 0;
    private handler = new CustomLoadingIndicatorHandlerWrapper(this);

    readonly visible: Observable<boolean> = this.visibleSubject.asObservable();

    start(): ILoadingIndicatorHandler {
        if (this.showCount === 0) {
            this.showCount++;
            this.visibleSubject.next(true);
        }

        return this.handler;
    }

    shutdown(): void {
        if (this.showCount === 0) {
            return;
        }

        this.showCount--;
        if (this.showCount === 0) {
            this.visibleSubject.next(false);
        }
    }

    destroy(): void {
        this.visibleSubject.complete();
    }
}
