"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientConnector = void 0;
var tslib_1 = require("tslib");
var signalr_1 = require("@microsoft/signalr");
var models_1 = require("./models");
var utils_1 = require("./utils");
var subscription_store_1 = require("./subscription-store");
var logger_1 = require("./logger");
var ClientConnector = /** @class */function () {
  function ClientConnector(settings, onMessageReceived, onStatusChange) {
    this.settings = settings;
    this.onMessageReceived = onMessageReceived;
    this.onStatusChange = onStatusChange;
    this.validateSettings(settings);
    this.subscriptionStore = new subscription_store_1.default();
    this.logger = new logger_1.ConsoleLogger(settings.logLevel);
    this.connectionStatus = models_1.ConnectionStatus.Disconnected;
    this.isManuallyStopped = false;
    this.lastStartTimestamp = null;
    this.retryAttempts = 0;
    this.maxRetries = settings.maxRetries || ClientConnector.DEFAULT_MAX_CONNECTION_ATTEMPTS;
    this.retryDelayMs = settings.retryDelayMs || ClientConnector.DEFAULT_CONNECTION_RETRY_DELAY;
  }
  ClientConnector.prototype.start = function () {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function () {
      var connectionOptions, logLevel, url, error_1;
      var _this = this;
      return (0, tslib_1.__generator)(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (!(this.connectionStatus !== models_1.ConnectionStatus.Disconnected)) return [3 /*break*/, 2];
            return [4 /*yield*/, this.stop()];
          case 1:
            _a.sent();
            _a.label = 2;
          case 2:
            connectionOptions = {
              skipNegotiation: !!this.settings.skipNegotiation,
              transport: this.settings.prefferedTransportType || signalr_1.HttpTransportType.None
            };
            logLevel = this.settings.logLevel === undefined ? signalr_1.LogLevel.None : this.settings.logLevel;
            url = this.buildConnectionUrl();
            this.connection = new signalr_1.HubConnectionBuilder().withUrl(url, connectionOptions).configureLogging(logLevel).build();
            this.connection.on("receive" /* Receive */, function (message) {
              return _this.handleMessage(message);
            });
            this.connection.onclose(function () {
              _this.updateStatus(models_1.ConnectionStatus.Disconnected);
              _this.retry();
            });
            this.isManuallyStopped = false;
            _a.label = 3;
          case 3:
            _a.trys.push([3, 6,, 7]);
            this.updateStatus(models_1.ConnectionStatus.Connecting);
            return [4 /*yield*/, this.connection.start()];
          case 4:
            _a.sent();
            this.updateStatus(models_1.ConnectionStatus.Connected);
            this.retryAttempts = 0;
            return [4 /*yield*/, this.renewSubscriptions()];
          case 5:
            _a.sent();
            return [3 /*break*/, 7];
          case 6:
            error_1 = _a.sent();
            this.updateStatus(models_1.ConnectionStatus.Disconnected);
            this.retry();
            return [3 /*break*/, 7];
          case 7:
            return [2 /*return*/];
        }
      });
    });
  };
  ClientConnector.prototype.stop = function () {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function () {
      return (0, tslib_1.__generator)(this, function (_a) {
        switch (_a.label) {
          case 0:
            this.isManuallyStopped = true;
            clearTimeout(this.reconnectTimeout);
            if (!this.connection) return [3 /*break*/, 2];
            this.connection.off("receive" /* Receive */);
            this.subscriptionStore.clear();
            return [4 /*yield*/, this.connection.stop()];
          case 1:
            _a.sent();
            this.onMessageReceived = null;
            this.onStatusChange = null;
            this.retryAttempts = 0;
            _a.label = 2;
          case 2:
            return [2 /*return*/];
        }
      });
    });
  };
  ClientConnector.prototype.subscribe = function (request) {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function () {
      var subscribeRequest, _i, _a, topic, added;
      return (0, tslib_1.__generator)(this, function (_b) {
        switch (_b.label) {
          case 0:
            subscribeRequest = {
              topics: []
            };
            for (_i = 0, _a = request.topics; _i < _a.length; _i++) {
              topic = _a[_i];
              added = this.subscriptionStore.add(topic);
              if (added.isSingle()) {
                subscribeRequest.topics.push(added.topic);
              }
            }
            if (!subscribeRequest.topics.length) return [3 /*break*/, 2];
            return [4 /*yield*/, this.invoke("Subscribe" /* Subscribe */, subscribeRequest)];
          case 1:
            _b.sent();
            _b.label = 2;
          case 2:
            return [2 /*return*/];
        }
      });
    });
  };
  ClientConnector.prototype.cancelSubscription = function (request) {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function () {
      var cancelRequest, _i, _a, topic, removed;
      return (0, tslib_1.__generator)(this, function (_b) {
        switch (_b.label) {
          case 0:
            cancelRequest = {
              topics: []
            };
            for (_i = 0, _a = request.topics; _i < _a.length; _i++) {
              topic = _a[_i];
              removed = this.subscriptionStore.remove(topic);
              if (removed && removed.isEmpty()) {
                cancelRequest.topics.push(removed.topic);
              }
            }
            if (!cancelRequest.topics.length) return [3 /*break*/, 2];
            return [4 /*yield*/, this.send("Unsubscribe" /* Unsubscribe */, cancelRequest)];
          case 1:
            _b.sent();
            _b.label = 2;
          case 2:
            return [2 /*return*/];
        }
      });
    });
  };
  ClientConnector.prototype.getStartTime = function () {
    return this.lastStartTimestamp;
  };
  ClientConnector.prototype.getStatus = function () {
    return this.connectionStatus;
  };
  ClientConnector.prototype.buildConnectionUrl = function () {
    var params = utils_1.Utils.buildQueryParameters({
      lang: this.settings.lang,
      country: this.settings.country,
      "x-bwin-accessId": this.settings.accessId,
      appUpdates: this.settings.appUpdates,
      shopTier: this.settings.shopTier
    });
    return "".concat(this.settings.url, "?").concat(params);
  };
  ClientConnector.prototype.retry = function () {
    var _this = this;
    if (this.isManuallyStopped) {
      return;
    }
    this.retryAttempts++;
    if (this.retryAttempts > this.maxRetries) {
      return;
    }
    this.reconnectTimeout = window.setTimeout(function () {
      return (0, tslib_1.__awaiter)(_this, void 0, void 0, function () {
        return (0, tslib_1.__generator)(this, function (_a) {
          switch (_a.label) {
            case 0:
              this.logger.log(signalr_1.LogLevel.Information, "Retrying to connect #".concat(this.retryAttempts, "/").concat(this.maxRetries));
              return [4 /*yield*/, this.start()];
            case 1:
              _a.sent();
              return [2 /*return*/];
          }
        });
      });
    }, this.retryDelayMs);
  };
  ClientConnector.prototype.updateStatus = function (status) {
    this.connectionStatus = status;
    this.lastStartTimestamp = status === models_1.ConnectionStatus.Connected ? new Date() : null;
    this.logger.log(signalr_1.LogLevel.Information, "Status changed to ".concat(models_1.ConnectionStatus[status]));
    if (this.onStatusChange) {
      this.onStatusChange(status);
    }
  };
  ClientConnector.prototype.renewSubscriptions = function () {
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function () {
      var request;
      return (0, tslib_1.__generator)(this, function (_a) {
        switch (_a.label) {
          case 0:
            if (this.connectionStatus !== models_1.ConnectionStatus.Connected) {
              return [2 /*return*/];
            }
            request = {
              topics: this.subscriptionStore.getAll().map(function (s) {
                return s.topic;
              })
            };
            if (!request.topics.length) return [3 /*break*/, 2];
            return [4 /*yield*/, this.send("Subscribe" /* Subscribe */, request)];
          case 1:
            _a.sent();
            _a.label = 2;
          case 2:
            return [2 /*return*/];
        }
      });
    });
  };
  ClientConnector.prototype.handleMessage = function (message) {
    this.logger.log(signalr_1.LogLevel.Trace, "received message", message);
    if (this.onMessageReceived) {
      this.onMessageReceived(message);
    }
  };
  ClientConnector.prototype.send = function (methodName) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function () {
      var _a;
      return (0, tslib_1.__generator)(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (this.connectionStatus !== models_1.ConnectionStatus.Connected) {
              return [2 /*return*/];
            }
            return [4 /*yield*/, (_a = this.connection).send.apply(_a, (0, tslib_1.__spreadArray)([methodName], args, false))];
          case 1:
            _b.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  ClientConnector.prototype.invoke = function (methodName) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      args[_i - 1] = arguments[_i];
    }
    return (0, tslib_1.__awaiter)(this, void 0, void 0, function () {
      var _a;
      return (0, tslib_1.__generator)(this, function (_b) {
        switch (_b.label) {
          case 0:
            if (this.connectionStatus !== models_1.ConnectionStatus.Connected) {
              return [2 /*return*/];
            }
            return [4 /*yield*/, (_a = this.connection).invoke.apply(_a, (0, tslib_1.__spreadArray)([methodName], args, false))];
          case 1:
            _b.sent();
            return [2 /*return*/];
        }
      });
    });
  };
  ClientConnector.prototype.validateSettings = function (settings) {
    if (!settings.accessId) {
      throw new Error("Missing access id");
    }
    if (!settings.lang) {
      throw new Error("Missing language");
    }
    if (!settings.country) {
      throw new Error("Missing country");
    }
    if (!settings.url) {
      throw new Error("Missing url");
    }
  };
  ClientConnector.DEFAULT_MAX_CONNECTION_ATTEMPTS = 10;
  ClientConnector.DEFAULT_CONNECTION_RETRY_DELAY = 3000;
  return ClientConnector;
}();
exports.ClientConnector = ClientConnector;
