import { pprops } from '@frontend/sports/common/base-utils';
import { createAction, props } from '@ngrx/store';

import { IUiState } from './ui-manager.state';
import { UiStates } from './ui-states.enum';

export interface IUiManagerStateChangedPayload {
    added: UiStates[];
    removed: UiStates[];
    current: UiStates[];
}

export class UiManagerActions {
    private static readonly SET_STATE = '@ui-manager/SET_STATE';
    private static readonly ADD_STATE = '@ui-manager/ADD_STATE';
    private static readonly REMOVE_STATE = '@ui-manager/REMOVE_STATE';
    private static readonly REMOVE_MULTI_STATES = '@ui-manager/REMOVE_MULTI_STATES';
    private static readonly TOGGLE_STATE = '@ui-manager/TOGGLE_STATE';
    private static readonly POPULATE_FROM_STORAGE = '@ui-manager/POPULATE_FROM_STORAGE';
    private static readonly STATE_CHANGED = '@ui-manager/STATE_CHANGED';

    /**
     * Set one state.
     */
    static setState = createAction(UiManagerActions.SET_STATE, props<{ state: UiStates }>());

    /**
     * Add one ui state.
     */
    static addState = createAction(UiManagerActions.ADD_STATE, props<{ state: UiStates }>());

    /**
     * Removes a ui state
     */
    static removeState = createAction(UiManagerActions.REMOVE_STATE, props<{ state: UiStates }>());

    /**
     * Removes multiple ui states at once.
     */
    static removeMultiStates = createAction(UiManagerActions.REMOVE_MULTI_STATES, props<{ states: UiStates[] }>());

    /**
     * toggle a ui state.
     */
    static toggleState = createAction(UiManagerActions.TOGGLE_STATE, props<{ state: UiStates }>());

    /**
     * Populate ui state from saved state.
     */
    static populateFromStorage = createAction(UiManagerActions.POPULATE_FROM_STORAGE, props<{ state: IUiState }>());

    /**
     * Fires this action after a change in the UI states, the payload is the diff.
     */
    static stateChanged = createAction(UiManagerActions.STATE_CHANGED, pprops<IUiManagerStateChangedPayload>());
}
