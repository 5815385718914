import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { BetslipType } from '../../../../core/betslip-type';
import { BetslipPick } from '../../../../core/picks/betslip-pick';
import {
    getBetBuilderSlipIdFromString,
    getComboSlipId,
    getEditBetSlipId,
    getSingleSlipId,
    getSingleSlipIdFromString,
    getSystemSlipId,
    getTeaserSlipId,
} from '../../../bet-generation/services/slip.utils';
import { IBetslipTypeState } from '../../../types/state';
import { BetslipError, ErrorOrigin } from '../../errors/betslip-error';
import { ResultError } from '../../errors/result/result-error';
import {
    BetslipTypeErrors,
    BetslipTypePickErrors,
    IBetslipErrorsState,
    IBetslipPickErrors,
    SlipErrors,
    emptyBetslipTypeErrors,
    emptyPickErrors,
} from '../../state';

export function updateKeyBasedErrorCollection<TKey extends keyof any>(
    currentCollection: Record<TKey, BetslipError[]>,
    newCollection: Record<TKey, BetslipError[]>,
    defaultCollection: Record<TKey, BetslipError[]>,
    oldKeys: TKey[],
    newKeys: TKey[],
): { errors: Record<TKey, BetslipError[]>; hasChanged: boolean } {
    let hasChanged = false;

    const result = { ...defaultCollection };
    const clientTypeErrors = { ...defaultCollection };

    // Iterate through all keys (all slip types) for current errors
    for (const key of oldKeys) {
        const currentErrors = currentCollection[key];

        const clientResult = [];
        const serverResult = [];

        // Split into client and non-client errors
        for (const error of currentErrors) {
            if (error.hasClientValidation) {
                clientResult.push(error);

                hasChanged = hasChanged || !newKeys.includes(key);
                continue;
            }

            serverResult.push(error);
        }

        if (clientResult.length) {
            // Prepare client errors for comparison
            clientTypeErrors[key] = clientResult;
        }

        if (serverResult.length) {
            // Keep all non-client errors
            result[key] = serverResult;
        }
    }

    // Iterate through all keys (all slip types) for new errors
    for (const key of newKeys) {
        const clientErrors = clientTypeErrors[key];
        const newErrors = newCollection[key];

        // Make sure we keep result errors from before
        const existingResultErrors = result[key];

        if (!newErrors.length) {
            result[key] = existingResultErrors ? existingResultErrors : [];
            hasChanged = hasChanged || !oldKeys.includes(key) || !!clientErrors?.length;

            continue;
        }

        // Check if we have current errors for this key
        if (!clientErrors || !clientErrors.length) {
            // If we don't have current errors, just take the new ones
            result[key] = existingResultErrors ? [...existingResultErrors, ...newErrors] : newErrors;
            hasChanged = true;

            continue;
        }

        // If we have current errors for this slip, update the errors
        const resultErrors = errorCollectionUpdater(clientErrors, newErrors);

        result[key] = existingResultErrors ? [...existingResultErrors, ...resultErrors] : resultErrors;

        // If the errors have changed before or the  result errors do not equal the current erros, set hasChanged to true
        hasChanged = hasChanged || resultErrors !== clientErrors;
    }

    return {
        errors: result,
        hasChanged,
    };
}

/**
 * A betslip errors reducer helper. Add/Replace/Deletes errors from current errors for which we have a validator
 *
 * @param currentErrors
 * @param newErrors - It is mandatory all new errors to be with hasClientValidation === true.
 */
export const errorCollectionUpdater = <TError extends BetslipError = BetslipError>(currentErrors: TError[], newErrors: TError[]): TError[] => {
    // Get all errors that are not validated on client.
    const result: TError[] = [];
    const clientErrors: TError[] = [];
    for (const error of currentErrors) {
        if (error.hasClientValidation) {
            clientErrors.push(error);
        } else {
            result.push(error);
        }
    }

    let hasNewError = false;

    // Go foreach of new errors.
    for (const newError of newErrors) {
        // Try to check if already this error exists.
        const sameError = clientErrors.find((be) => be.equals(newError));

        // If error is already present do not update it.
        if (sameError) {
            // Take the same error just change the origin.
            const updatedError = sameError.copy();
            updatedError.origin = ErrorOrigin.BetSlip;
            result.push(updatedError);
        } else {
            hasNewError = true;
            result.push(newError);
        }
    }
    if (result.length === currentErrors.length && !hasNewError) {
        return currentErrors;
    }

    return result;
};

export function extractPickErrors(
    pickErrors: BetslipTypePickErrors,
    betslipTypeErrors: BetslipTypeErrors,
): [BetslipTypePickErrors, BetslipTypeErrors] {
    const extractedPickErrors: BetslipTypePickErrors = {
        SINGLE: { ...pickErrors[BetslipType.Single] },
        COMBO: { ...pickErrors[BetslipType.Combo] },
        SYSTEM: { ...pickErrors[BetslipType.System] },
        TEASER: { ...pickErrors[BetslipType.Teaser] },
        EDIT_MY_BET: { ...pickErrors[BetslipType.EditBet] },
        BET_BUILDER: { ...pickErrors[BetslipType.BetBuilder] },
    };

    const filteredTypeErrors: BetslipTypeErrors = emptyBetslipTypeErrors();

    for (const type of [BetslipType.Single, BetslipType.Combo, BetslipType.System, BetslipType.Teaser, BetslipType.EditBet, BetslipType.BetBuilder]) {
        const typeErrors = betslipTypeErrors[type];
        const typePickErrors = extractedPickErrors[type];

        for (const error of typeErrors) {
            if (error instanceof ResultError && !!error.pickId) {
                typePickErrors[error.pickId] = typePickErrors[error.pickId] || [];

                typePickErrors[error.pickId].push(error);
                continue;
            }

            filteredTypeErrors[type].push(error);
        }
    }

    return [extractedPickErrors, filteredTypeErrors];
}

export const getTypedPickErrors = function (pickErrors: BetslipTypePickErrors): [BetslipType, IBetslipPickErrors][] {
    return Object.entries(pickErrors).map(([key, errors]) => [key as BetslipType, errors]);
};

const getTypedBetslipErrors = function (slipErrors: BetslipTypeErrors): [BetslipType, BetslipError[]][] {
    return Object.entries(slipErrors).map(([key, errors]) => [key as BetslipType, errors]);
};

export const filterErrorState = (
    state: IBetslipErrorsState,
    betslipErrorsPredicate: (error: BetslipError) => boolean,
    betslipTypeErrorsPredicate: (error: BetslipError) => boolean,
    slipErrorsPredicate: (error: BetslipError) => boolean,
    pickErrorsPredicate: (error: ResultError) => boolean,
): IBetslipErrorsState => {
    const betslipErrors = state.betslipErrors.filter(betslipErrorsPredicate);

    const pickErrors: BetslipTypePickErrors = emptyPickErrors();
    const betslipTypeErrors: BetslipTypeErrors = emptyBetslipTypeErrors();
    const slipErrors: Record<string, BetslipError[]> = {};

    for (const [type, errors] of getTypedPickErrors(state.pickErrors)) {
        const pickIds = Object.keys(errors);

        for (const pickId of pickIds) {
            pickErrors[type][pickId] = errors[pickId].filter(pickErrorsPredicate);
        }
    }

    for (const [type, errors] of getTypedBetslipErrors(state.betslipTypeErrors)) {
        betslipTypeErrors[type] = errors.filter(betslipTypeErrorsPredicate);
    }

    for (const [key, errors] of Object.entries(state.slipErrors)) {
        slipErrors[key] = errors.filter(slipErrorsPredicate);
    }

    return {
        pickErrors,
        betslipTypeErrors,
        betslipErrors,
        slipErrors,
    };
};

// Caution: This modifies the original errors which is fine for reducers since they handle immutability.
// This should be used with caution elsewhere.
export const modifyErrorState = (
    state: IBetslipErrorsState,
    betslipErrorsMap: (error: BetslipError) => [BetslipError, boolean],
    betslipTypeErrorsMap: (error: BetslipError) => [BetslipError, boolean],
    slipErrorsMap: (error: BetslipError) => [BetslipError, boolean],
    pickErrorsMap: (error: ResultError) => [ResultError, boolean],
): IBetslipErrorsState & { isModified: boolean } => {
    const betslipErrorsMapping = state.betslipErrors.map(betslipErrorsMap);

    let isModified = betslipErrorsMapping.some(([_, modified]) => modified);

    const betslipErrors = betslipErrorsMapping.map(([e]) => e);

    const pickErrors: BetslipTypePickErrors = emptyPickErrors();
    const betslipTypeErrors: BetslipTypeErrors = emptyBetslipTypeErrors();
    const slipErrors: Record<string, BetslipError[]> = {};

    for (const [type, errors] of getTypedPickErrors(state.pickErrors)) {
        const pickIds = Object.keys(errors);

        for (const pickId of pickIds) {
            const mappedErrors = errors[pickId].map(pickErrorsMap);

            isModified = isModified || mappedErrors.some(([_, modified]) => modified);

            pickErrors[type][pickId] = mappedErrors.map(([e]) => e);
        }
    }

    for (const [type, errors] of getTypedBetslipErrors(state.betslipTypeErrors)) {
        const mappedErrors = errors.map(betslipTypeErrorsMap);

        isModified = isModified || mappedErrors.some(([_, modified]) => modified);

        betslipTypeErrors[type] = mappedErrors.map(([e]) => e);
    }

    for (const [key, errors] of Object.entries(state.slipErrors)) {
        const mappedErrors = errors.map(slipErrorsMap);

        isModified = isModified || mappedErrors.some(([_, modified]) => modified);

        slipErrors[key] = mappedErrors.map(([e]) => e);
    }

    return {
        pickErrors,
        betslipTypeErrors,
        betslipErrors,
        slipErrors,
        isModified,
    };
};

export function getSlipErrorsForType(type: BetslipType, slipErrors: SlipErrors, typesState: IBetslipTypeState, isLinear: boolean): BetslipError[] {
    switch (type) {
        case BetslipType.Combo:
            return slipErrors[getComboSlipId()] ?? [];

        case BetslipType.Teaser:
            return slipErrors[getTeaserSlipId()] ?? [];

        case BetslipType.System:
            return isLinear
                ? Object.values(typesState.systemBet.linearTypeStakes).flatMap((systemType) => slipErrors[getSystemSlipId(systemType.slipType)] ?? [])
                : typesState.systemBet.systemInfo
                  ? slipErrors[getSystemSlipId(typesState.systemBet.systemInfo)] ?? []
                  : [];

        case BetslipType.Single:
            return Object.keys(typesState.singleBet.picks).flatMap((pick) => slipErrors[getSingleSlipIdFromString(pick)] ?? []);

        case BetslipType.EditBet:
            return slipErrors[getEditBetSlipId()] ?? [];

        case BetslipType.BetBuilder:
            return Object.keys(typesState.betBuilder.picks).flatMap((pick) => slipErrors[getBetBuilderSlipIdFromString(pick)] ?? []);
    }
}

export function checkSlipErrorsForSinglePicks(slipErrors: SlipErrors, picks: BetslipPick[], predicate: (error: BetslipError) => boolean): boolean {
    return picks.some((pick) => slipErrors[getSingleSlipId(pick.id)]?.some(predicate));
}

export function hasOddsChangedError(pickErrors: BetslipTypePickErrors): boolean {
    return Object.values(pickErrors).some((p) =>
        Object.values(p).some((errors) => errors.some((error) => error.type === PlacementErrorType.OddsChanged)),
    );
}

export const getAllPickErrorTypes = function (typePickErrors: BetslipTypePickErrors): Set<string> {
    const types = Object.values(typePickErrors).flatMap((typeErrors) =>
        Object.values(typeErrors).flatMap((errors) => errors.map((error) => error.type)),
    );

    return new Set(types);
};

export const hasAnyErrors = function (state: IBetslipErrorsState): boolean {
    return (
        state.betslipErrors.length > 0 ||
        Object.values(state.slipErrors).some((s) => s.length > 0) ||
        Object.values(state.betslipTypeErrors).some((s) => s.length > 0) ||
        Object.values(state.pickErrors).some((p) => Object.values(p).some((e) => e.length > 0))
    );
};

export const getErrorsAcrossTypesForPicks = function (errors: BetslipTypePickErrors): IBetslipPickErrors {
    return Object.values(errors).reduce((a, c) => {
        const pickErrors = Object.entries(c);

        for (const [key, value] of pickErrors) {
            a[key] = a[key] ? [...a[key], ...value] : [...value];
        }

        return a;
    }, {} as IBetslipPickErrors);
};
