import { Injectable } from '@angular/core';

import { StringDictionary, isDefined } from '@frontend/sports/common/base-utils';
import { flatMap, keyBy, keys, now } from 'lodash-es';

import { GridModel } from './grid.model';

export interface EventCollapsedState {
    until: Date;
    collapsed: boolean;
    collapsedChildren: string[];
}

export interface GroupCollapsedState {
    collapsed: boolean;
}

export interface ColumnActiveState {
    index: number;
    group: string;
    extended: boolean;
}

export interface GridStoreState {
    groups: number[];
    events: StringDictionary<EventCollapsedState>;
    columns: ColumnActiveState[];
}

@Injectable({ providedIn: 'root' })
export class GridStoreService {
    private state = new Map<string, GridStoreState>();

    set(grid: GridModel, group?: number): void {
        const actualState = this.get(grid.id);

        const events = this.getEventsState(grid);
        const columns = this.getColumnState(grid);
        const groups = actualState ? actualState.groups : [];

        if (group) {
            const groupIndex = groups.indexOf(group);

            if (groupIndex === -1) {
                groups.push(group);
            } else {
                groups.splice(groupIndex, 1);
            }
        }

        if (columns.length || groups.length || keys(events).length) {
            this.state.set(grid.id, { events, columns, groups });
        } else {
            this.state.delete(grid.id);
        }
    }

    get(id: string): GridStoreState | undefined {
        const expiry = now();

        for (const state of this.state.values()) {
            for (const eventKey in state.events) {
                const event = state.events[eventKey];

                if (event.until.getTime() < expiry) {
                    delete state.events[eventKey];
                }
            }
        }

        return this.state.get(id);
    }

    private getColumnState(grid: GridModel): ColumnActiveState[] {
        return grid.columns
            .map((column, index) => {
                const activeIndex = column.groups.findIndex((group) => group.active);

                if (activeIndex === -1 || activeIndex === index) {
                    return;
                }
                const active = column.groups[activeIndex];

                return {
                    index,
                    group: active.id,
                    extended: active.extended,
                };
            })
            .filter(isDefined);
    }

    private getEventsState(grid: GridModel): StringDictionary<EventCollapsedState> {
        const state: StringDictionary<EventCollapsedState> = {};
        const until = now();

        const events = flatMap(grid.groups, (group) => group.events);
        const eventsMap = keyBy(events, (event) => event.id);

        for (const id in grid.collapsed.events) {
            const event = eventsMap[id];
            const eventState = grid.collapsed.events[id];

            if (!event || event.cutOffDate.getTime() <= until) {
                continue;
            }

            if (eventState.collapsed || eventState.collapsedChildren.length) {
                state[id] = {
                    until: event.cutOffDate,
                    collapsed: eventState.collapsed,
                    collapsedChildren: eventState.collapsedChildren,
                };
            }
        }

        return state;
    }
}
