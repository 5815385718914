import { Injectable, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { Nullable } from '@frontend/sports/common/base-utils';
import { ArcUserConfig, OddsFormatSettings, SportsUserConfig, SportsUserSettings } from '@frontend/sports/common/client-config-data-access';
// eslint-disable-next-line no-restricted-imports
import { IProfileConfiguration } from '@frontend/sports/types/components/a-r-c';
import { OddsDisplayFormat } from '@frontend/sports/types/models/user-settings';
import {
    UserAutologout24HoursEvent,
    UserAutologoutEvent,
    UserEvent,
    UserLoginEvent,
    UserLogoutEvent,
    UserSessionExpiredEvent,
    UserService as VanillaUserService,
} from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import UserActions from '../actions';
import { EarlyPayoutAcceptance } from '../model/early-payout-acceptance';
import { IConfigSettings, IUserBettingSettings } from '../model/user.models';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private readonly accountCategoryKey = 'http://api.bwin.com/v3/user/accountcategoryId';
    private sportsSettingsSignal: Signal<SportsUserSettings>;

    private readonly store = inject(Store);
    private readonly oddsFormatSettings = inject(OddsFormatSettings);

    get isQuickDepositEnabled(): boolean {
        return this.sportsUser.isQuickDepositEnabled;
    }

    get columnSize(): number {
        return this.sportsUser.columnSize || 0;
    }

    get bettingSettings(): Readonly<IUserBettingSettings> {
        return this.sportsSettingsSignal().bettingSettings;
    }

    get oddsFormat(): Readonly<OddsDisplayFormat> {
        return this.oddsFormatSettings.generalOddsFormat;
    }

    get configSettings(): Readonly<IConfigSettings> {
        return this.sportsSettingsSignal().configSettings;
    }

    get jurisdiction(): string {
        return this.sportsUser.jurisdiction;
    }

    get clientIP(): string {
        return this.sportsUser.clientIP;
    }

    get isEarlyPayoutAllowed(): boolean {
        return this.sportsUser.isEarlyPayoutAllowed;
    }

    get events(): Observable<UserEvent> {
        return this.vUser.events;
    }

    get isAuthenticated(): boolean {
        return this.vUser.isAuthenticated;
    }

    get currency(): string {
        return this.vUser.currency;
    }

    get ssoToken(): string | null {
        return this.vUser.ssoToken;
    }

    get geoCountry(): string {
        return this.vUser.geoCountry || '';
    }

    get country(): string {
        return this.vUser.country || '';
    }

    get username(): string | null {
        return this.vUser.username;
    }

    get workflowType(): number {
        return this.vUser.workflowType;
    }

    get id(): string | null {
        return this.vUser.id;
    }

    get accountId(): string | null {
        return this.vUser.accountId;
    }

    get displayName(): string {
        return this.vUser.displayName;
    }

    get isEligibleForNewCustomerOffer(): boolean {
        return this.sportsUser.isEligibleForNewCustomerOffer;
    }

    get arcPlayBreak(): boolean {
        return this.arcUser.arcPlayBreak;
    }

    get arcProfileDetails(): Readonly<IProfileConfiguration> {
        return this.arcUser.arcProfileDetails;
    }

    get associatedAccount(): string | undefined {
        return this.sportsUser.associatedAccount;
    }

    get isTurnoverTaxApplied(): boolean {
        return this.configSettings.stakeTaxRate !== 0 && this.configSettings.taxCalculation === TaxCalculation.TurnoverTax;
    }

    constructor(
        private vUser: VanillaUserService,
        private sportsUserSettings: SportsUserSettings,
        private sportsUser: SportsUserConfig,
        private arcUser: ArcUserConfig,
    ) {
        this.sportsSettingsSignal = toSignal(
            this.sportsUserSettings.whenReady.pipe(
                map(() => {
                    this.store.dispatch(
                        UserActions.updateState({
                            settings: this.sportsUserSettings.bettingSettings,
                            config: this.sportsUserSettings.configSettings,
                            oddsFormat: this.oddsFormat,
                        }),
                    );
                    return this.sportsUserSettings;
                }),
            ),
            {
                initialValue: {} as SportsUserSettings,
            },
        );
    }

    get earlyPayoutAcceptance(): Nullable<EarlyPayoutAcceptance.Any | EarlyPayoutAcceptance.Higher> {
        if (!this.bettingSettings) {
            return null;
        }

        if (
            this.bettingSettings.earlyPayoutAcceptanceMode !== EarlyPayoutAcceptance.Any &&
            this.bettingSettings.earlyPayoutAcceptanceMode !== EarlyPayoutAcceptance.Higher
        ) {
            return null;
        }

        return this.bettingSettings.earlyPayoutAcceptanceMode === EarlyPayoutAcceptance.Any
            ? EarlyPayoutAcceptance.Any
            : EarlyPayoutAcceptance.Higher;
    }

    get isAnonymous(): boolean {
        const accountType = this.vUser.claims.get('accbusinessphase');

        return !accountType || accountType === 'anonymous';
    }

    readonly onUserLogin$ = this.vUser.events.pipe(filter((e) => e instanceof UserLoginEvent));
    readonly onUserLogout$ = this.vUser.events.pipe(filter((e) => e instanceof UserLogoutEvent));
    readonly onAuthenticationChange$ = this.vUser.events.pipe(
        filter(
            (e) =>
                e instanceof UserLoginEvent ||
                e instanceof UserLogoutEvent ||
                e instanceof UserAutologoutEvent ||
                e instanceof UserSessionExpiredEvent ||
                e instanceof UserAutologout24HoursEvent,
        ),
    );

    accountCategoryId(): Nullable<string> {
        return this.vUser.claims.get(this.accountCategoryKey);
    }

    getStateOrProvince(): Nullable<string> {
        return this.vUser.claims.get('stateorprovince');
    }

    getIsStakeLimitSet(): boolean {
        return this.vUser.claims.get('isSportsLimitSet')?.toLowerCase() === 'true';
    }
}

export enum TaxCalculation {
    Default = 'Default',
    TurnoverTax = 'TurnoverTax',
}
