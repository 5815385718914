<a *ngIf="!href; else hrefTemplate" class="ms-active-highlight" [msAutomation]="attribute || ''" (click)="clicked()">
    <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
</a>

<ng-template #hrefTemplate>
    <a class="ms-active-highlight" [msAutomation]="attribute || ''" [href]="href" [msQuicklink]="href" (click)="clicked()">
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
    </a>
</ng-template>

<ng-template #itemTemplate>
    <div class="icon">
        <span *ngIf="icon" class="base-icon"> <span [class]="icon"></span> </span>
        <ng-content select="[item-icon]"></ng-content>
    </div>
    <div class="title">
        <span *ngIf="title">{{ title }}</span>
        <ng-content select="[item-title]"></ng-content>
        <ng-content select="ms-promotion-badge"></ng-content>
    </div>
    <div class="count" *ngIf="count">{{ count }}</div>
    <div class="arrow" *ngIf="arrow"><span class="theme-right"></span></div>
    <ng-content select="[esports-header-content]"></ng-content>
</ng-template>
