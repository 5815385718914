import { InjectionToken, Injector, TemplateRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { ElementProvider } from '@frontend/sports/common/base-utils';

import { ButtonConfig } from '../../common/button-config.models';
import { ScrollFreezeServiceTypes } from '../../common/scroll-freeze.service';
import { IDialogTracking } from '../../tracking/tracking.models';

export interface ModalDialogOptions<T> {
    data?: T;
    settings?: DialogSettings;
}

export interface DialogSettings {
    isModalDialog?: boolean;
    isPopup?: boolean;
    showDialogHeader?: boolean;
    hideCloseButton?: boolean;
    title?: string;
    fit?: boolean;
    cssClass?: string;
    showPageHeader?: boolean;
    showBottomNav?: boolean;
    showBackNav?: boolean;
    animation?: DialogAnimation;
    closeAnimation?: DialogAnimation;
    tracking?: IDialogTracking;
    container?: HTMLElement;
    inColumn?: boolean;
    scrollFreezeType?: ScrollFreezeServiceTypes;
    backdropClass?: string;
    afterResulted?: Function;
    beforeDismiss?: () => boolean | Promise<boolean>;
    scrollTargetQuerySelector?: string;
    closeOnChildPopup?: boolean;
    injector?: Injector;
    epcotShowBalance?: boolean;
    headerTemplate?: TemplateRef<any>;
    showCloseBtnText?: boolean;
    closePrevious?: boolean;
    needsAuthentication?: boolean;
}

export enum DialogAnimation {
    SlideInFromBottom = 'SlideInFromBottom',
    SlideInFromRight = 'SlideInFromRight',
    SlideOutFromTop = 'SlideOutFromTop',
}

export interface ContentData {
    type?: DialogType;
    title?: string;
    firstParagraph?: string | SafeHtml;
    secondParagraph?: string | SafeHtml;
    buttons?: DialogButton[];
    showCloseLink?: boolean;
}

export enum DialogType {
    Warning = 'warning',
    Info = 'info',
    Action = 'action',
}

export interface DialogButton {
    text: string;
    type: ButtonType;
    buttonConfig?: ButtonConfig;
    cssClass?: string;
}

export enum DialogClosedBy {
    Button,
    CloseLink,
}

export enum ButtonType {
    Ok,
    Cancel,
}

export const MODAL_DIALOG_CONTAINER = new InjectionToken<ElementProvider>('ms-modal-dialog-container');
