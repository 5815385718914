import { Injectable } from '@angular/core';

import { LocalStoreService } from '@frontend/vanilla/core';
import { firstValueFrom } from 'rxjs';

import { Promotion } from '../crm-offer-data/crm-offer.model';
import { CampaignDetails } from '../crm-offer-data/crm-offer.server.model';
import { ApiService } from '../http/api.service';
import { ModalRef } from '../modal/common/modal-ref';
import { ModalDialogService } from '../modal/dialog/modal-dialog.service';
import { TrackingSource } from '../tracking/tracking.models';
import { BannerMoreInfoComponent } from './banner-more-info.component';
import { CrmPromotionFactory } from './crm-promotion.factory';

@Injectable({ providedIn: 'root' })
export class BannerService {
    private dialog: ModalRef;
    storageKey = 'promoBanner';

    constructor(
        private crmPromotionFactory: CrmPromotionFactory,
        private apiService: ApiService,
        private localStorage: LocalStoreService,
        private modalDialogService: ModalDialogService,
    ) {}

    async loadBannerDetails(promotion: Promotion): Promise<void> {
        if (promotion.details) {
            return;
        }

        await firstValueFrom(this.apiService.get<CampaignDetails>('crmPromotions/getBannerDetails', { cmsItemId: promotion.cmsItemId })).then(
            (result: any) => {
                result && this.crmPromotionFactory.setBannerDetails(promotion, result);
            },
        );
    }

    async openDetailsPage(promotion: Promotion, tracking: TrackingSource | undefined, callback: () => void): Promise<void> {
        await this.loadBannerDetails(promotion);
        if (!promotion.details) {
            return;
        }

        this.localStorage.set(this.storageKey, promotion.id);
        this.dialog = this.modalDialogService.openDialog(BannerMoreInfoComponent, {
            data: {
                promotion,
                tracking,
            },
            settings: {
                title: promotion.details.title,
                cssClass: 'crm-promotion-more-info',
                isModalDialog: false,
                fit: false,
                showDialogHeader: true,
            },
        });
        this.dialog.result.finally(() => {
            this.localStorage.set(this.storageKey, undefined);
            callback();
        });
    }

    get detailsPopupRef(): ModalRef | undefined {
        return this.dialog;
    }
}
