import { Injectable } from '@angular/core';

import { Fixture, FixtureStage, Game, OptionMarket } from '@cds/betting-offer';
import { BetBuilderOptionGroupInfo } from '@cds/betting-offer/domain-specific/bet-builder';
import { ScoreboardSlim } from '@cds/scoreboards/v1';
import { isDefined } from '@frontend/sports/common/base-utils';
import { SportsConfig } from '@frontend/sports/common/client-config-data-access';
import { remove, sortBy } from 'lodash-es';

import { sortOptions } from '../helpers/option-sorting';
import { EventModel } from '../model/event.model';
import { Tv2MarketParameters } from '../model/tv2.model';
import { CommonFixtureFactory } from './common-fixture.factory';
import { FixtureMarket, FixtureMarketFactory } from './fixture-market.factory';
import { ScoreboardFactory } from './scoreboard-factory.service';

type Market = Game | OptionMarket;

@Injectable({ providedIn: 'root' })
export class FixtureFactory {
    constructor(
        private fixtureFactory: CommonFixtureFactory,
        private marketFactory: FixtureMarketFactory,
        private scoreboardFactory: ScoreboardFactory,
        private sportConfig: SportsConfig,
    ) {}

    create(fixture: Fixture, sourceId?: string, precreatedOptionGroupInfo?: BetBuilderOptionGroupInfo[]): EventModel {
        const event = this.fixtureFactory.create(fixture, sourceId, precreatedOptionGroupInfo);
        const optionGroups = this.getOrderedMarkets(fixture).map((optionGroup) => this.marketFactory.create(optionGroup));

        event.optionGroups = optionGroups;
        event.gamesCount = optionGroups.length;
        event.fixtureScoreboard = fixture.scoreboard as ScoreboardSlim;
        event.imgEventId = fixture.addons.imgEventId;

        return event;
    }

    update(model: EventModel, openForBetting: boolean, stage: FixtureStage): void {
        this.fixtureFactory.update(model, openForBetting, stage);
    }

    deleteOptionGroup(event: EventModel, optionGroupId: string): void {
        remove(event.optionGroups, (g) => g.id === optionGroupId);
        event.gamesCount = event.optionGroups.length;
    }

    updateOptionGroup(event: EventModel, fixtureMarket: FixtureMarket): void {
        const updated = this.marketFactory.create(fixtureMarket);
        const index = event.optionGroups.findIndex((optionGroup) => optionGroup.id === updated.id);

        if (index === -1) {
            event.optionGroups.push(updated);
        } else {
            event.optionGroups[index] = updated;
            event.gamesCount = event.optionGroups.length;
        }

        updated.options = sortOptions(updated.options, updated.resultSorting);
    }

    updateScoreboard(event: EventModel, serverScoreboard: ScoreboardSlim): void {
        event.scoreboard = this.scoreboardFactory.createScoreboard(serverScoreboard, event);
    }

    private getOrderedMarkets(fixture: Fixture): Market[] {
        const markets: Market[] = [];

        if (fixture.games) {
            markets.push(...fixture.games);
        }

        if (fixture.optionMarkets) {
            if (fixture.isVirtual) {
                const order = this.sportConfig.virtuals.marketTypeOrder[fixture.sport.id] || [];

                markets.push(...sortBy(fixture.optionMarkets, (market, index) => this.getOrder(order, market, index)));
            } else {
                markets.push(...fixture.optionMarkets);
            }
        }

        return markets.filter(isDefined);
    }

    private getOrder(order: string[], market: OptionMarket, index: number): number {
        const marketType = market.parameters.find((param) => param.key === Tv2MarketParameters.MarketType);

        if (marketType) {
            const orderIndex = order.indexOf(marketType.value);

            if (orderIndex !== -1) {
                return orderIndex;
            }
        }

        return order.length + index;
    }
}
