import { Injectable, Optional } from '@angular/core';

import { Group as DefaultGroup, GridViewGroupingConfiguration, SixPackGroup } from '@cds/betting-offer/grouping/grid-view';
import { GridConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { now, reverse, sortBy, times } from 'lodash-es';

import { EpcotConfigService, EpcotModule } from '../common/epcot-config.service';
import { SubscriptionTopic } from '../event-subscription/base-subscription.service';
import { Column, GridGrouping, GridLayout, GridSorting, Group, GroupBadge } from './grid.model';

export interface GridOption {
    activeGrouping?: number | string;
    marketGrouping?: boolean;
    moreGrouping?: boolean;
    grouping?: GridGrouping;
    disableGroupSorting?: boolean;
    sorting?: GridSorting;
    collapsedThreshold?: number;
    subscriptionTopic?: SubscriptionTopic;
    groupOrder?: string[];
    badges?: Record<string, GroupBadge>;
    includeRetailGroups?: boolean;
    excludeDefaultMainMarket?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ObservableGridProvider {
    constructor(
        protected sitecore: Sitecore,
        @Optional() protected epcotConfigService?: EpcotConfigService,
        @Optional() protected gridConfig?: GridConfig,
    ) {}

    getColumns(config?: GridViewGroupingConfiguration, layout?: GridLayout, options?: GridOption): Column[] {
        let source: (DefaultGroup | SixPackGroup)[] = [];

        const { groups, sixPackGroups } = config || {
            groups: new Array<DefaultGroup>(),
            sixPackGroups: new Array<SixPackGroup>(),
        };

        if (layout === GridLayout.SixPack) {
            source = this.applyGroupOrder(sixPackGroups, options);
        } else {
            source = this.applyGroupOrder(groups, options);
        }

        const maxColumns = this.epcotConfigService?.isEnabled(EpcotModule.Grid) && this.gridConfig?.is2way3wayEnabled ? 2 : 4;

        const count = Math.min(maxColumns, source.length);

        if (count === 0) {
            return options?.excludeDefaultMainMarket ? [] : [this.getBaseColumn()];
        }

        return times(count).map((current, index) => this.getColumn(source, groups, index, options));
    }

    protected getColumn(source: (DefaultGroup | SixPackGroup)[], groups: DefaultGroup[], active: number, options?: GridOption): Column {
        let mapped: Group[];

        if (source.length === 0) {
            mapped = [this.getBaseGroup()];
        } else {
            mapped = source
                .filter((s) => !s.isFallbackGroup)
                .map((group, index) => this.getGroup(group, groups, index === active, options && options.badges));
        }

        return {
            id: (now() + active).toString(32),
            groups: mapped,
            enabled: true,
            more: !!options?.moreGrouping,
        };
    }

    private getGroup(group: DefaultGroup | SixPackGroup, groups: DefaultGroup[], active: boolean, badges?: Record<string, GroupBadge>): Group {
        return {
            id: group.id,
            name: group.name,
            options: group.optionNames,
            balancedMarket: group.balancedMarketsEnabled,
            marketAttribute: group.showAttribute,
            childGroups: 'marketGroupIds' in group ? group.marketGroupIds : [],
            active,
            extended: false,
            visible: true,
            optionsToShow: group.optionsToShow,
            badge: badges && badges[group.id],
            isFallbackGroup: group.isFallbackGroup,
            fallbackGridGroupIds: group.fallbackGridGroupIds,
        };
    }

    protected getBaseColumn(): Column {
        const group = this.getBaseGroup();

        return {
            id: 'main',
            groups: [group],
            enabled: true,
            more: false,
        };
    }

    protected getBaseGroup(): Group {
        return {
            id: 'main',
            name: this.sitecore.eventGrid.MarketSwitcher_MainMarket,
            active: true,
            extended: false,
            visible: true,
            isFallbackGroup: false,
            fallbackGridGroupIds: [],
        };
    }

    protected applyGroupOrder(source: (DefaultGroup | SixPackGroup)[], options?: GridOption): (DefaultGroup | SixPackGroup)[] {
        if (!options?.groupOrder?.length) {
            return source;
        }

        reverse([...options.groupOrder]).forEach((grid) => {
            source = sortBy(source, [
                (group: DefaultGroup | SixPackGroup) => {
                    return grid !== group.id;
                },
            ]);
        });

        return source;
    }
}
