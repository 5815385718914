<div class="crm-promo-marquee-overlay"></div>
<div class="banner-wrapper">
    <img *ngIf="promotion?.banner?.heroImageUrl as img" class="hero-image" width="1080" height="380" [priority]="listIndex === 0" [ngSrc]="img" />

    <ms-promotion-badge></ms-promotion-badge>

    <div class="content-wrapper">
        <div class="titles">
            <div class="headline font-family-tertiary">{{ promotion.banner?.title }}</div>
            <div class="subline" [innerHtml]="promotion.banner?.description"></div>
        </div>
        <ms-promotion-status-button-wrapper [promotion]="promotion" [tracking]="trackingSourceData"> </ms-promotion-status-button-wrapper>
    </div>
</div>

<div *ngIf="promotion.banner?.keyTerms" class="terms" [innerHtml]="promotion.banner?.keyTerms"></div>
