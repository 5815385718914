import { OfferSource } from '@cds';
import { FixtureStage, FixtureType, FixtureViewType, Parameter, ParticipantMarketType, ParticipantStatus, ParticipantType } from '@cds/betting-offer';
import { SourceOfPick } from 'packages/sports/web/app/src/betslip-base/models/pick-models';
import { MarketSubType, MarketType } from 'packages/sports/web/app/src/cds/market-parameter.models';
import { EventParticipant } from 'packages/sports/web/app/src/event-model/model/event.model';
import { NumpadAction } from 'packages/sports/web/app/src/numpad/model';

import { BetslipGroupInformation } from '../groups/betslip-group-information';
import { Odds } from '../odds/odds';
import { CombinationPrevention } from './combo-prevention';
import { GroupLegState } from './group-leg-state';
import { MinimumCombination } from './minimum-combination';
import { SignedName } from './signed-name.model';
import {
    BetslipV2HorseRaceOptionMarketPick,
    BetslipV2HorseRaceWinParticipantPick,
    BetslipV2HorseRaceXCastPick,
} from './sport-specific/betslip-v2-horse-race-picks';

export enum PickType {
    V1Pick,
    V2ParticipantPick,
    V2OptionMarketPick,
    BetBuilderPick,
    GroupedPicks,
    V2OptionMarketXcastPick,
}

export enum PickSubType {
    None /* Dummy value for base classes. */,
    V1Pick,
    HorseWinPick /* V2ParticipantPick */,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    HorseXCastPick /* V2ParticipantPick */,
    HorseOptionMarketPick /* V2 Horse Option Market Pick */,
    GolfWinPick /* V2ParticipantPick */,
    BetBuilderPick,
    StandardV2Pick /* V2 Option Market (Soccer, ... ) */,
    UnknownPick,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    HorseOptionMarketXCastRacePick /*  V2 Horse Option Market XCastPick*/,
    GroupedPicks,
}

export enum ParticipantPickType {
    Win = 1,
    Forecast = 2,
    CombinationForecast = 3,
    Tricast = 4,
    CombinationTricast = 5,
    Freeform = 6,
    Derived = 7,
    None = 8,
}

export enum HorseRacingPreFix {
    Win = 'win',
    Forecast = 'fc',
    CombinationForecast = 'cfc',
    Tricast = 'tc',
    CombinationTricast = 'ctc',
}

export enum ResultStatus {
    Open = 1,
    Lost = 2,
    Won = 3,
    Canceled = 5,
    Unknown = 0,
}

export enum PriceType {
    Fixed = 1,
    StartingPrice = 2,
    NoPrice = 3,
}

export interface IBasePrice {
    type: PriceType;
    nativeOdds: Odds;
}

export interface IPrice extends IBasePrice {
    id: number;
    isVisible: boolean;
    marketId: number;
}

export enum PickBettingState {
    // Pick open for betting
    Open,
    // Currently locked
    Locked,
    // Pick closed
    Closed,
}

export interface IBetslipPickStorage {
    id: string;
    priceHistory: IPrice[];
    priceType: PriceType;
    isNewCustomerOffer: boolean;
    acceptedPrice: {
        price: IPrice | null;
        isManuallyAccepted: boolean;
    };
    market: IBetslipPickMarket | null;
    pickSubType: PickSubType;
    subscriptionContext: string[];
    tracking: IPickTracking;
    boostedPriceDetails: IBoostedPrice | undefined;
    liveAlert?: boolean;
    groupInfo?: BetslipGroupInformation;
    parentLinkedEventId?: string;
}

export enum PickOddsState {
    Open,
    Locked,
    Closed,
}

export interface IAcceptedPrice {
    price: IPrice | null; // Accepted price will be null when current price is undefined.
    /**
     *  When price is accepted automatically from user odd acceptance isManuallyAccepted is false.
     *  When price is accepted manually from user when clicks on the button is true
     */
    isManuallyAccepted: boolean;
}

export interface IBoostedPrice {
    isPriceBoosted?: boolean;
    boostedPrice: IPrice | undefined;
    boostedPriceId?: number;
}

export interface IPickTracking {
    source: string;
    sourceOfBetSuffix?: string;
    affiliate?: boolean;
    numpadAction?: NumpadAction;
    additional?: { [key: string]: string };
    sourceOfPick?: SourceOfPick;
    betDetails?: string;
    betCalender?: string;
    controllerTabName?: string;
    playerName?: string;
    betType?: string;
    gameDetails?: string;
    moduleType?: string;
    contentPosition?: string;
    variant?: string;
    sheetviewSuffix?: string;
}

export interface IBetslipPickMarket {
    id: number;
    isVisible: boolean;
    gridGroups?: string[];
    marketType?: MarketType | ParticipantMarketType;
    marketSubType?: MarketSubType;
    isBetBuilderEnabled: boolean;
}

export interface BetslipV1PickSport {
    id: number;
    name: SignedName;
}

export interface BetslipV1PickTeaserData {
    crossSportIds: number[];
    crossLeagueIds: number[];
}

export interface BetslipV1PickLeague {
    id: number;
    name: SignedName;
    parentLeagueId: number | null;
}

export interface BetslipV1PickRegion {
    id: number;
    name: SignedName;
}

export class BetslipV1PickEvent {
    id: string;
    name: SignedName;
    groupId: number | null;
    eventDate: Date;
    cutOffDate: Date;
    isLive: boolean;
    isPublished: boolean;
    participants?: EventParticipant[];
    viewType?: FixtureViewType;
}

export interface IBetslipV1PickEventStorage {
    id: string;
    name: SignedName;
    groupId: number | null;
    eventDate: string;
    cutOffDate: string;
    isLive: boolean;
    isPublished: boolean;
    participants?: EventParticipant[];
    viewType?: FixtureViewType;
}

export interface BetslipV1PickMarket extends IBetslipPickMarket {
    name: SignedName;
    templateId: number;
    comboPrevention: CombinationPrevention;
    minimumCombo: MinimumCombination;
    isClosed: boolean;
    templateCategoryId?: number;
}

export interface BetslipV1PickOption {
    id: number;
    name: SignedName;
    price: IPrice;
}

export interface IBetslipV1PickDto {
    sport: BetslipV1PickSport;
    league: BetslipV1PickLeague;
    region: BetslipV1PickRegion;
    event: BetslipV1PickEvent;
    market: BetslipV1PickMarket;
    option: BetslipV1PickOption;
}

export interface IBetslipV1PickStorage extends IBetslipPickStorage {
    sport: BetslipV1PickSport;
    league: BetslipV1PickLeague;
    region: BetslipV1PickRegion;
    event: IBetslipV1PickEventStorage;
    market: BetslipV1PickMarket;
    option: BetslipV1PickOption;
    teaser: BetslipV1PickTeaserData;
}

export interface IBetslipV2PickStorage extends IBetslipPickStorage {
    fixture: IBetslipV2PickFixtureStorage;
}

export interface IBetslipV2PickFixture {
    fixtureId: string;
    sportId: number;
    sportName: SignedName;
    eventDate: Date;
    cutOffDate: Date;
    fixtureType: FixtureType;
    stage: FixtureStage;
    name: SignedName;
    competitionGroupId: number | null;
    virtual: boolean;
    league: {
        id: number;
        name: SignedName;
        realCompetitionId?: number;
    } | null;
    region: {
        id: number;
        name: SignedName;
    } | null;
    linkedEventIds: number[];
    participants?: EventParticipant[];
    viewType?: FixtureViewType;
}

export interface IBetslipV2PickFixtureStorage extends Omit<IBetslipV2PickFixture, 'eventDate' | 'cutOffDate'> {
    eventDate: string;
    cutOffDate: string;
}

export interface IBetslipV2HorseRacePickFixture extends IBetslipV2PickFixture {
    bestOddsGuarantee: boolean;
}

export interface IBetslipV2HorseRacePickFixtureStorage extends Omit<IBetslipV2HorseRacePickFixture, 'eventDate' | 'cutOffDate'> {
    eventDate: string;
    cutOffDate: string;
}

export interface IBetslipV2GolfPickFixture extends IBetslipV2PickFixture {
    fixtureGroup: {
        id: number;
        name: SignedName;
    };
}

export interface IBetslipV2GolfPickFixtureStorage extends Omit<IBetslipV2GolfPickFixture, 'eventDate' | 'cutOffDate'> {
    eventDate: string;
    cutOffDate: string;
}

export interface IBetslipV2ParticipantPickStorage extends IBetslipV2PickStorage {
    market: IBetslipV2PickParticipantMarket;
    participants: IBetslipV2PickParticipant[];
    pickSubType: PickSubType;
    betType: string;
    betTypeAltTranslation: string;
}

export interface IBetslipV2PickParticipantMarket extends IBetslipPickMarket {
    marketType: ParticipantMarketType;
}

export interface IPlaceTerms {
    denominator: number;
    numerator: number;
    places: number;
}

export interface IBetslipV2WinParticipantPickStorage extends IBetslipV2ParticipantPickStorage {
    market: IBetslipV2PickWinParticipantMarket;
}

export interface IBetslipV2PickWinParticipantMarket extends IBetslipV2PickParticipantMarket {
    isEachWay: boolean;
    placeTerms: IPlaceTerms | null;
    isStartingPriceAvailable: boolean;
}

export interface IBetslipV2PickParticipant {
    /**
     * Fixture participant id, participant id in the fixture
     */
    fixtureParticipantId: number;
    /**
     * Participant id in the master data. e.g. horse id number.
     */
    participantId: number;
    name: SignedName;
    type: ParticipantType;
    status: ParticipantStatus;
    position: number;
    prices: IPrice[];
    drawNumber?: number;
}

export interface IBetslipV2OptionMarket extends IBetslipPickMarket {
    name: SignedName;
    comboPrevention: CombinationPrevention;
    minimumCombo: MinimumCombination;
    parameters?: Parameter[];
    isEachWay: boolean;
    placeTerms: IPlaceTerms | null;
    isStartingPriceAvailable?: boolean;
    isFixedPriceAvailable?: boolean;
    isClosed: boolean;
}

export interface IBetslipV2Option {
    id: number;
    name: SignedName;
    isVisible: boolean;
    prices: IPrice[];
    boostedPrice?: IPrice;
    position?: number;
    isDraw?: boolean;
}

export interface ISportcastOption {
    longId: string;
    name: string;
}

export interface IBetslipV2OptionMarketPickStorage extends IBetslipV2PickStorage {
    market: IBetslipV2OptionMarket;
    isFavouritePick?: boolean;
    option: IBetslipV2Option;
    pickSubType: PickSubType;
    pickType?: ParticipantPickType;
    isFromNewCustomerOffer: boolean;
    siblingOption?: IBetslipV2Option;
}

export interface IBetslipV2OptionMarketXCastPickStorage extends IBetslipV2PickStorage {
    market: IBetslipV2OptionMarket;
    options: IBetslipV2Option[];
    pickSubType: PickSubType;
    betType: string;
    betTypeAltTranslation: string;
}

export interface IBetslipV2GolfPickStorage extends Omit<IBetslipV2PickStorage, 'fixture'> {
    fixture: IBetslipV2GolfPickFixtureStorage;
}

export interface IBetslipV2GolfWinParticipantPickStorage
    extends Omit<IBetslipV2WinParticipantPickStorage, 'fixture'>,
        Omit<IBetslipV2GolfPickStorage, 'market'> {
    // fixture type comes from IBetslipV2GolfPickStorage
    // market type comes from IBetslipV2WinParticipantPickStorage
}

export interface GolfPick {
    fixture: IBetslipV2GolfPickFixture;
    eventName: SignedName;
    marketName: SignedName;
}

export interface IBetslipV2HorseRacePickStorage extends Omit<IBetslipV2PickStorage, 'fixture'> {
    fixture: IBetslipV2HorseRacePickFixtureStorage;
}

export interface IBetslipV2HorseRaceParticipantPickStorage
    extends Omit<IBetslipV2ParticipantPickStorage, 'fixture' | 'participants'>,
        Omit<IBetslipV2HorseRacePickStorage, 'market'> {
    participants: IBetslipV2PickParticipant[];
    _marketName: string;
}

export interface IBetslipV2HorseRaceWinParticipantPickStorage
    extends Omit<IBetslipV2WinParticipantPickStorage, 'fixture' | 'participants'>,
        Omit<IBetslipV2HorseRaceParticipantPickStorage, 'market'> {}

export interface IBetslipV2HorseRaceOptionMarketPickStorage
    extends Omit<IBetslipV2OptionMarketPickStorage, 'fixture'>,
        Omit<IBetslipV2HorseRacePickStorage, 'market'> {}

export interface IBetslipV2HorseRaceOptionMarketXCastPickStorage
    extends Omit<IBetslipV2OptionMarketXCastPickStorage, 'fixture'>,
        Omit<IBetslipV2HorseRacePickStorage, 'market'> {}

export interface HorseRacePick {
    fixture: IBetslipV2HorseRacePickFixture;
    leagueId: number;
    leagueName: SignedName;
    regionId: number;
    regionName: SignedName;
}

export interface HorseRaceParticipantPick extends HorseRacePick {
    participants: IBetslipV2PickParticipant[];
    setMarketName(value: string, altTranslation?: string): void;
}

export type HorseRaceParticipantPickType = BetslipV2HorseRaceWinParticipantPick | BetslipV2HorseRaceXCastPick;
export type HorseRacePickType = HorseRaceParticipantPickType | BetslipV2HorseRaceOptionMarketPick;

export interface IBetslipBetBuilderPickStorage extends IBetslipV2OptionMarketPickStorage {
    eventId: string;
    event: IEventData;
    sportcastOptions: ISportcastOption[];
    sportcastId?: number;
    betgeniusId?: number;
    betSlipUid?: string;
    useV2key?: boolean;
    longIds?: string[];
}

export interface IEventData {
    league: {
        id: number;
        name: SignedName;
        parentLeagueId?: number;
    };
    event: {
        id: string;
        name: SignedName;
        date: Date;
        isLive?: boolean;
        groupId?: number;
        participants?: EventParticipant[];
        viewType?: FixtureViewType;
    };
    region: {
        id: number;
        name: SignedName;
    };
    source: OfferSource;
    betBuilderTradingV2FixtureId?: string;
}

export interface BetslipGroupPickStorage extends IBetslipPickStorage {
    price: IPrice;
    picks: IBetslipPickStorage[];
    SGPId: string;
    legsState: Record<string, GroupLegState>;
    isSuspended: boolean;
}

export enum PickUpdateType {
    None = 0,
    Unknown = 1,
    /* eslint-disable no-bitwise */
    MarketVisibility = 1 << 1,
    // eslint-disable-next-line no-bitwise
    PriceVisibility = 1 << 2,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Odds = 1 << 3,
    /* eslint-enable no-bitwise */
}
