import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { template, templateSettings } from 'lodash-es';

@Injectable({ providedIn: 'root' })
@Pipe({
    name: 'msInterpolate',
    standalone: true,
})
export class StringInterpolationPipe implements PipeTransform {
    transform(value: string, params: object | undefined): string {
        if (value == null || value === '') {
            return '';
        }

        templateSettings.interpolate = /{{([\s\S]+?)}}/g;
        const interpolate = template(value);
        try {
            return interpolate(params);
        } catch {
            return value;
        }
    }
}
