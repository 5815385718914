import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FontResizerComponent } from '../common/font-resizer.component';
import { PriceboostCountSignpostComponent } from './Components/priceboost-count-signpost.component';

@NgModule({
    imports: [CommonModule, FontResizerComponent],
    declarations: [PriceboostCountSignpostComponent],
    exports: [PriceboostCountSignpostComponent],
})
export class SharedPriceboostModule {}
