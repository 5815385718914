<div class="grid-context" *ngIf="title">
    <ms-favourite-league-toggle
        *ngIf="showFavoriteToggle && league"
        [name]="league?.name"
        [id]="league?.id"
        [sport]="sport?.id"></ms-favourite-league-toggle>
    <div *ngIf="useColoredIcon && !isTournament" class="colored-icon">
        <ms-colored-icon [iconClass]="iconClass"></ms-colored-icon>
    </div>
    <div class="league-heading-info">
        <h1 class="league-heading-title" [ngClass]="{ 'tournament-heading-title': isTournament }">{{ title }}</h1>
        <ms-competition-logo
            [sportId]="sport?.id"
            [leagueId]="league!.id"
            [imageProfile]="league?.imageProfile"
            *ngIf="sport && league"></ms-competition-logo>
    </div>
    <ng-container [ngTemplateOutlet]="offerBadgeTemplate || defaultOfferBadgeTemplate"></ng-container>
</div>
<ng-content></ng-content>
<ms-tab-bar *ngIf="navigation?.length > 1" [tabs]="navigation" (select)="changed($event)"></ms-tab-bar>

<ng-template #defaultOfferBadgeTemplate>
    <ms-reward-token-eligible-tag *ngIf="sport && league" [sport]="sport?.id" [leagues]="[league?.id, league?.parentLeagueId]">
    </ms-reward-token-eligible-tag>
</ng-template>
