@if (!href || popup) {
    <ng-container *ngTemplateOutlet="itemTemplate" />
} @else {
    <a class="ms-active-highlight" [msAutomation]="attribute || ''" [href]="href" [msQuicklink]="href" (click)="clicked()">
        <ng-container *ngTemplateOutlet="itemTemplate" />
    </a>
}

<ng-template #itemTemplate>
    <div class="icon">
        @if (icon) {
            <span class="base-icon"> <span [class]="icon"></span> </span>
        }
        <ng-content select="[item-coloured-icon]" />
        <ng-content select="[item-icon]" />
    </div>
    @if (isMiniCarouselLayout) {
        <div class="mini-carousel-layout-container">
            <div class="league-detail">
                @if (leagueIcon) {
                    <span class="league-icon"><span [class]="leagueIcon"></span></span>
                }
                @if (leagueTitle) {
                    <span class="league-title">{{ leagueTitle }}</span>
                }
            </div>
            <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
        </div>
    } @else {
        <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
    }
</ng-template>

<ng-template #titleTemplate>
    <div class="title" [ngClass]="{ 'title-with-badges': badgesEnabled, 'no-title': !title }">
        @if (title) {
            <span>{{ title }}</span>
        }
        <ng-content select="[item-title]" />
        @if (badgesEnabled) {
            <ms-promotion-badge />
        }
        @if (showLiveBadge) {
            <div class="badge badge-danger badge-dot">{{ sitecore.event.Live }}</div>
        }
    </div>
    @if (count) {
        <div class="count">{{ count }}</div>
    }
    @if (arrow) {
        <div class="arrow"><span class="theme-right"></span></div>
    }
</ng-template>
