import { createFeatureSelector } from '@ngrx/store';

import { IMediaRootState } from '../media/media.state';
import { GridModel } from './grid.model';

export interface IGridState {
    [key: string]: GridModel;
}

export const gridInitialState: IGridState = {};

export const gridStateKey = 'grid';

export interface IGridRootState extends IMediaRootState {
    grid: IGridState;
}

export const gridStateSelector = createFeatureSelector<IGridState>(gridStateKey);
