import { OptionMarket, ParticipantOption, ParticipantPriceStatus, ParticipantPriceType, Price } from '@cds/betting-offer';

import { emptyOdds } from './odds/odds';
import { OddsOperations } from './odds/operations';
import { IPrice, PriceType } from './picks/pick-models';

export function participantOptionPriceToBetslipPrice(option: ParticipantOption): IPrice {
    return {
        id: option.price.id,
        isVisible:
            option.participantPriceStatus === ParticipantPriceStatus.Visible &&
            (option.priceType !== ParticipantPriceType.Fixed || OddsOperations.isOddsValid(OddsOperations.createOdds(option.price))),
        marketId: option.marketId,
        nativeOdds: option.priceType === ParticipantPriceType.Fixed ? OddsOperations.createOdds(option.price) : emptyOdds,
        type: PriceType[option.priceType],
    };
}

export function optionPriceToBetslipPrice(market: OptionMarket, price: Price): IPrice {
    const option = market.options?.filter((o) => o.price.id === price.id)[0];

    return {
        id: price.id,
        isVisible: true,
        marketId: market.id,
        nativeOdds: OddsOperations.createOdds(price),
        type: option && option.participantPriceType ? PriceType[option.participantPriceType] : PriceType.Fixed,
    };
}
