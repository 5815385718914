import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { NumpadService } from './numpad.service';

@Component({
    selector: 'ms-numpad-keys',
    templateUrl: './numpad-keys.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumpadKeysComponent {
    readonly separator: string;

    constructor(
        public sitecore: Sitecore,
        public numpadService: NumpadService,
        @Inject(LOCALE_ID) locale: string,
    ) {
        this.separator = getLocaleNumberSymbol(locale, NumberSymbol.CurrencyDecimal);
    }

    tap(key: string): void {
        this.numpadService.appendCharacter(key);
    }

    remove(): void {
        this.numpadService.removeLastCharacter();
    }

    ok(): void {
        this.numpadService.confirm();
    }
}
