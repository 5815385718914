import { NgIf } from '@angular/common';
import { NgModule } from '@angular/core';

import { CompetitionLogoComponent } from './components/competition-logo.component';

@NgModule({
    imports: [NgIf],
    declarations: [CompetitionLogoComponent],
    exports: [CompetitionLogoComponent],
})
export class CompetitionLogosModule {}
