import { hasValue } from '@frontend/sports/common/base-utils';
import { Widget, WidgetType } from '@frontend/sports/types/components/widget';
import { ComposableWidgetPayload } from '@frontend/sports/types/components/widget/types';
import { flatMap, uniqBy } from 'lodash-es';

export function widgetsToWidgetTypes(widgets: Widget<unknown>[]) {
    let widgetTypes = widgets.map((widget) => widget.type);
    const composableWidget = widgets.find((widget) => widget.type === WidgetType.Composable);
    if (composableWidget) {
        const composableWidgetPayload = (composableWidget.payload ?? {}) as ComposableWidgetPayload;
        const childWidgets = uniqBy(
            flatMap(composableWidgetPayload.items, (item) => item.children),
            (child) => child.type,
        ).map((child) => child.type);
        widgetTypes = [...childWidgets, ...widgetTypes];
    }
    return widgetTypes.filter(hasValue);
}
