import { BetPlacementErrorIcon } from '../bet-placement-error-icon';
import { BetslipError } from '../betslip-error';
import { ClientErrorType } from '../client-error-type';
import { PreCheckErrorMixin } from '../pre-check/pre-check-error';

export class RewardTokensMaxStakeError extends PreCheckErrorMixin(BetslipError) {
    constructor(maximumStake: number) {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensMaxStakeError;
        this.hasClientValidation = true;
        this.maximumStake = maximumStake;
    }

    readonly maximumStake: number;
}
