import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { hasValue } from '@frontend/sports/common/base-utils';
import { ImagesConfig } from '@frontend/sports/common/client-config-data-access';
import { UtilsService } from '@frontend/vanilla/core';

import { EpcotConfigService } from '../common/epcot-config.service';
import { EventParticipantImage } from '../event-model/model/event.model';
import { MarqueeConstants } from './marquee.constants';

export enum ImageType {
    Logo = 'Logo',
    Jersey = 'Jersey',
    Fallback = 'Fallback',
}

export enum ImageRootPath {
    Live = 'live',
    Prod = 'prod',
}

export interface Image {
    type: ImageType;
    url: string;
}

@Component({
    selector: 'ms-participant-image',
    templateUrl: 'participant-image.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantImageComponent implements OnChanges {
    private static FALLBACK_IMAGE = 'fallback';
    private static FALLBACK_IMAGE_EPCOT = 'fallback_epcot';
    private readonly participantImageUrl: string;
    private readonly participantLogoUrl: string;

    @Input() participantImage?: EventParticipantImage;
    @Input() sportId: number;
    @Input() shouldRotate = false;
    @Input() showFallback = true;
    @Input() showLogoColor = false;
    @Output() logoLoaded = new EventEmitter<boolean>();

    loaded = false;
    imageType = ImageType;
    imageClass = '';
    image?: Image;
    rotateImage = false;
    fallbackColor = false;
    logoColor?: string;
    showBackgroundCircle = false;

    private isEpcotEnabled = false;
    private images: Image[];

    constructor(
        private imagesConfig: ImagesConfig,
        private utilsService: UtilsService,
        private marqueeConstants: MarqueeConstants,
        private epcotConfigService: EpcotConfigService,
    ) {
        this.participantImageUrl = this.imagesConfig.participantImageUrl;
        this.participantLogoUrl = this.imagesConfig.participantLogoUrl;
        this.isEpcotEnabled = this.epcotConfigService.isEnabled();
    }

    ngOnChanges(): void {
        this.settingImage();
        this.showBackgroundCircle = this.showLogoColor && this.imagesConfig.isLogoBackgroundEnabled;
        this.fallbackColor = this.getFallbackColor();
        this.logoColor = this.getLogoColor();
    }

    private settingImage() {
        this.loaded = false;

        this.images = [];

        this.images.push({ type: ImageType.Logo, url: this.getImageUrl(ImageType.Logo) });
        this.images.push({ type: ImageType.Jersey, url: this.getImageUrl(ImageType.Jersey) });

        if (this.showFallback) {
            this.images.push({ type: ImageType.Fallback, url: this.getImageUrl(ImageType.Fallback) });
        }

        this.images = this.images.filter((image) => hasValue(image.url));

        this.image = this.images.shift();

        this.rotateImage =
            this.shouldRotate &&
            this.marqueeConstants.sportsToReversePlayer2Image.some((s) => s === this.sportId) &&
            this.image?.type !== ImageType.Logo;
    }

    onImageFailedLoaded(): void {
        this.image = this.images.shift();
        this.logoLoaded.emit(false);
    }

    private getImageUrl(imageType: ImageType): string {
        let participantImageName;
        let participantUrl = this.participantImageUrl;

        switch (imageType) {
            case ImageType.Logo:
                participantImageName = this.participantImage && this.participantImage.logo;
                participantUrl = this.participantLogoUrl;
                break;
            case ImageType.Jersey:
                participantImageName = this.participantImage && this.participantImage.jersey;
                break;
            case ImageType.Fallback:
                participantImageName = this.showFallback
                    ? this.isEpcotEnabled
                        ? ParticipantImageComponent.FALLBACK_IMAGE_EPCOT
                        : ParticipantImageComponent.FALLBACK_IMAGE
                    : '';
                break;
        }

        const rootpath = this.participantImage?.isParticipantProfile ? ImageRootPath.Prod : ImageRootPath.Live;
        const participantImageProfileName = participantImageName?.split('.')[0];

        return participantImageName ? this.utilsService.format(participantUrl, rootpath, this.sportId, participantImageProfileName) : '';
    }

    private getLogoColor(): string | undefined {
        return this.showBackgroundCircle && this.participantImage?.isParticipantProfile
            ? this.participantImage?.logoColor
                ? '#' + this.participantImage?.logoColor
                : undefined
            : undefined;
    }

    private getFallbackColor(): boolean {
        return !!(this.showBackgroundCircle && this.participantImage?.isParticipantProfile && !this.participantImage?.logoColor);
    }
}
