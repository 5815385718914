import { TimeoutError, TimeoutInfo } from 'rxjs';

export class CustomTimeoutError implements TimeoutError {
    constructor(info: TimeoutInfo<unknown, unknown>) {
        this.name = 'CustomTimeoutError';
        this.info = info;
    }

    info: TimeoutInfo<unknown, unknown> | null;
    name: string;
    message: string;
    stack?: string | undefined;
}
