import { NgIf } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';

import { OddsConfig } from '@frontend/sports/common/client-config-data-access';

import { FontResizerBaseService, FontResizerSettings } from './font-resizer-base.service';

@Component({
    selector: 'ms-font-resizer',
    templateUrl: 'font-resizer.html',
    standalone: true,
    imports: [NgIf],
})
export class FontResizerComponent implements OnChanges, OnInit {
    // added to give a small space to text and borders
    private fontSizeThreshold = 0.03;
    private oldLength = 0;

    @Input() value: string;
    @Input() maxChars: number;
    @Input() defaultSize: number;
    @Input() shouldGetExtSettings?: boolean;
    @Input() shouldSkipCustomStyling?: boolean = false;
    @Output() fontChange = new EventEmitter<number>();

    @HostBinding('style.font-size.em') fontSize?: number;

    private settings?: FontResizerSettings;
    shouldUseCustomStyle: boolean;

    constructor(
        @Optional() private fontResizerService: FontResizerBaseService | null,
        private oddsConfig: OddsConfig,
    ) {}

    ngOnInit(): void {
        this.shouldUseCustomStyle = this.oddsConfig.display.useCustomOddsFontStyle && !this.shouldSkipCustomStyling;
    }

    ngOnChanges(changes: SimpleChanges): void {
        const valueChange = changes['value'];

        if (!valueChange) {
            return;
        }

        if (valueChange.previousValue === valueChange.currentValue || this.shouldUseCustomStyle) {
            return;
        }

        if (this.shouldGetExtSettings) {
            this.settings = this.fontResizerService?.getSettings(valueChange.currentValue.length);
        }
        if (this.settings?.threshold !== undefined) {
            this.fontSizeThreshold = this.settings.threshold;
        }

        this.calculateSize(valueChange.currentValue);
    }

    private calculateSize(value: string): void {
        if (!value) {
            this.oldLength = 0;

            return;
        }

        const valueLength = value.length;
        if (valueLength === this.oldLength) {
            return;
        }

        this.oldLength = valueLength;

        const newFontSize = this.getFontSize(valueLength);
        if (this.fontSize === newFontSize) {
            return;
        }

        this.fontSize = newFontSize;
        this.fontChange.emit(newFontSize);
    }

    private getFontSize(valueLength: number): number {
        const newFontSize =
            valueLength > this.getMaxChars() && !this.settings?.skipResize
                ? +((this.defaultSize * this.getMaxChars()) / valueLength - this.fontSizeThreshold).toFixed(2)
                : this.defaultSize;

        return Math.max(newFontSize, this.settings?.minimumFontSize || newFontSize);
    }

    private getMaxChars(): number {
        return this.maxChars - 1;
    }
}
