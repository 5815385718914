import { Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { HooksWireup, OnRouteResolve } from '@frontend/sports/common/base-utils';
import { ScrollingSizeConfig } from '@frontend/sports/common/client-config-data-access';
import { Widget, WidgetLayoutResponse, WidgetLayoutTemplate, WidgetLocation, WidgetPage } from '@frontend/sports/types/components/widget';
import { MediaQueryService } from '@frontend/vanilla/core';
import { kebabCase } from 'lodash-es';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AutomationService } from '../../automation/automation.service';
import { HideHeaderService } from '../../common/hide-header.service';
import { ScrollContainerService } from '../../common/scroll-container.service';
import ModuleLoaderService from '../../deferred/module-loader.service';
import { WidgetLayoutHook } from './widget-layout-hook';
import { WidgetLoaderService } from './widget-loader.service';
import { WidgetRightColumnService } from './widget-right-column.service';
import { WidgetTrackingService } from './widget-tracking.service';
import { MEDIUM_LAYOUT_BREAKPOINT, forSlot } from './widget.constants';
import { widgetsToWidgetTypes } from './widgets-to-widget-types.util';

@HooksWireup()
@Component({
    selector: 'ms-widget-layout',
    templateUrl: './widget-layout.component.html',
})
export class WidgetLayoutComponent extends OnRouteResolve<WidgetLayoutResponse> implements OnDestroy, OnInit {
    @HostBinding('class') className: string;

    page: WidgetPage;
    layoutTemplate: WidgetLayoutTemplate;

    top: Widget<unknown>[] = [];
    left: Widget<unknown>[] = [];
    center: Widget<unknown>[] = [];

    leftVisible = true;
    private scrollSubscription?: Subscription;

    constructor(
        route: ActivatedRoute,
        private media: MediaQueryService,
        private column: WidgetRightColumnService,
        private elementRef: ElementRef,
        private automationService: AutomationService,
        private widgetTrackingService: WidgetTrackingService,
        private moduleLoader: ModuleLoaderService,
        private scrollContainer: ScrollContainerService,
        private hideHeaderService: HideHeaderService,
        private scrollingSizeConfig: ScrollingSizeConfig,
        private widgetLoaderService: WidgetLoaderService,
        @Inject(WidgetLayoutHook) private hooks: WidgetLayoutHook[],
    ) {
        super(route);

        media
            .observe()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.setLeftVisibility());

        this.setClass();
    }

    ngOnInit(): void {
        if (this.scrollingSizeConfig.hideHeaderOnScroll.includes(this.page)) {
            this.scrollSubscription = this.scrollContainer.scroll.subscribe(() => {
                this.hideHeaderService.onScroll();
            });
        }
    }

    ngOnDestroy(): void {
        this.scrollSubscription?.unsubscribe();
        this.widgetLoaderService.cleanWidgetsLoaded();
        this.column.resetContent();
        this.destroyHooks();
        this.hideHeaderService.removeHideHeader();
    }

    onRouteResolved(model: WidgetLayoutResponse): void {
        if (this.page !== model.page) {
            this.destroyHooks();
        }

        this.page = model.page;

        this.layoutTemplate = model.template;
        this.resolveHooks();
        this.widgetLoaderService.cleanWidgetsLoaded();

        //on demand lazy load widgets using vanilla event strategy approach.
        const widgetTypes = widgetsToWidgetTypes(model.widgets);
        this.moduleLoader.lazyLoadFeatures(widgetTypes);

        this.top = forSlot(model, WidgetLocation.Top);
        this.left = forSlot(model, WidgetLocation.Left);
        this.center = forSlot(model, WidgetLocation.Center);

        this.column.setContent(forSlot(model, WidgetLocation.Right), this.page, this.layoutTemplate);
        this.automationService.addAttr(this.elementRef, { widgetPage: model.page, widgetPageId: model.id });

        this.setLeftVisibility();
        this.setClass(model.page);
        this.widgetTrackingService.trackWidgetsLoaded(model);
    }

    private setLeftVisibility(): void {
        this.leftVisible = this.media.isActive(MEDIUM_LAYOUT_BREAKPOINT) && this.left.length > 0;
    }

    private setClass(page?: string): void {
        this.className = ['widget-layout', 'row', kebabCase(page)].filter((current) => current).join(' ');
    }

    private resolveHooks(): void {
        this.getHooks().forEach((hook) => hook.onResolve());
    }

    private destroyHooks(): void {
        this.getHooks().forEach((hook) => hook.onDestroy());
    }

    private getHooks(): WidgetLayoutHook[] {
        return this.hooks.filter((hook) => hook.target === this.page);
    }
}
