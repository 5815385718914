import { Injectable } from '@angular/core';
import { ActivationEnd } from '@angular/router';

import { RouteTag, ofType } from '@frontend/sports/common/base-utils';
import { CDNCachingConfig, WidgetConfig } from '@frontend/sports/common/client-config-data-access';
import { Widget, WidgetContext, WidgetPage } from '@frontend/sports/types/components/widget';
import { EMPTY, Observable, Subscription, interval, map, noop } from 'rxjs';

import { RouterEventsService } from '../../navigation-core/router-events.service';
import { WidgetComponent } from './widget-registry.service';

export abstract class WidgetRefreshTrigger {
    constructor(private config: WidgetConfig) {}

    asObservable(): Observable<void> {
        const time = this.config.pageRefreshInterval
            .filter(({ page }) => page === this.getPage())
            .map((page) => page.interval)
            .pop();

        if (time) {
            return interval(time * 1000).pipe(map(noop));
        }

        return EMPTY;
    }

    abstract getPage(): WidgetPage;
}

export abstract class WidgetRefreshProcessor {
    abstract refresh(contexts: Partial<WidgetContext>[]): Observable<Widget<unknown>[]>;
}

@Injectable({
    providedIn: 'root',
})
export class WidgetRefreshService {
    private components = new Map<WidgetComponent<unknown>, undefined>();
    private triggers = new Map<RouteTag, WidgetRefreshTrigger>();

    private refresh?: Subscription;

    constructor(
        router: RouterEventsService,
        private processor: WidgetRefreshProcessor,
        private cdnCacheConfig: CDNCachingConfig,
    ) {
        router.currentActivationEnd.pipe(ofType(ActivationEnd)).subscribe((route) => this.registerRefresh(route.snapshot.data.tag));
    }

    deregisterComponent(widgets: WidgetComponent<unknown>[]): void {
        for (const widget of widgets) {
            this.components.delete(widget);
        }
    }

    registerComponent(widgets: WidgetComponent<unknown>[]): void {
        for (const widget of widgets) {
            this.components.set(widget, undefined);
        }
    }

    registerTrigger(tag: RouteTag, trigger: WidgetRefreshTrigger): void {
        this.triggers.set(tag, trigger);
    }

    private registerRefresh(tag: RouteTag): void {
        const trigger = this.triggers.get(tag);

        this.refresh?.unsubscribe();
        this.refresh = trigger?.asObservable().subscribe(() => {
            const components = [...this.components.keys()].filter((component) => component.config.refreshable);
            const contexts = components
                .filter((component) => !this.cdnCacheConfig.widgets.includes(component.config.type))
                .map((component) => component.getContext());

            if (contexts.length) {
                this.processor.refresh(contexts).subscribe((widgets) => {
                    for (const widget of widgets) {
                        const component = components.find((current) => current.config.id === widget.id);

                        if (component) {
                            component.onResolve(widget, undefined, true, true);
                        }
                    }
                });
            }
            components
                .filter((component) => this.cdnCacheConfig.widgets.includes(component.config.type) && component.config.refreshable)
                .forEach((component) => component.onResolve(component.config, undefined, true, true));
        });
    }
}
