import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { CrmConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { LocalStoreService } from '@frontend/vanilla/core';
import { takeUntil } from 'rxjs/operators';

import { WindowEventService } from '../common/browser/window-event.service';
import { OfferStatus, Promotion, TrackingSource } from '../crm-offer-data/crm-offer.model';
import { TrackingSource as TrackingSourceData } from '../tracking/tracking.models';
import { UserService } from '../user/services/user.service';
import { BannerService } from './banner.service';
import { OfferStatusService } from './offer-status.service';
import { CTALocation, CrmPromotionTracking } from './tracking.service';

@HooksWireup()
@Component({
    selector: 'ms-crm-promotion-banner',
    templateUrl: 'banner.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CrmPromotionTracking], // additionally registering dedicated crm promo tracking service, to make sure modular-tracking.service overrides tracking.service
})
export class BannerComponent extends OnDestroyCleanup implements OnInit, OnChanges, AfterContentInit {
    overlayActiveBreakpointPx = 358;
    translations: { [key: string]: string } = {};
    optInInvisible = false;
    private optInRedirect = false;

    @Input() promotion: Promotion;
    @Input() listIndex = 0;
    @Input() isDynamicSize = false;
    @Input() trackingSource = TrackingSource.Teaser;

    @HostBinding('style.background-image') backgroundImage: SafeStyle;
    @HostBinding('class.overlay-active') overlayActive: boolean;
    @HostBinding('class.large-dynamic-banner') largeDynamicBannerClass: boolean;

    private largeBannerWidthPx = 608;
    trackingSourceText: string;

    constructor(
        private elementRef: ElementRef,
        public sitecore: Sitecore,
        private tracking: CrmPromotionTracking,
        private changeDetectorRef: ChangeDetectorRef,
        private bannerService: BannerService,
        private windowEvent: WindowEventService,
        private localStorage: LocalStoreService,
        private crmConfig: CrmConfig,
        private m2User: UserService,
        private offerStatusService: OfferStatusService,
    ) {
        super();
        this.optInRedirect = this.crmConfig.promotions.optInRedirectBlackListedCountries.indexOf(this.m2User.country) > -1;
        this.optInInvisible = this.crmConfig.promotions.optInInvisibleBlackListedCountries.indexOf(this.m2User.country) > -1;
    }

    get trackingSourceData(): TrackingSourceData {
        return {
            source: this.trackingSourceText,
            position: this.listIndex,
        };
    }

    async ngOnInit(): Promise<void> {
        this.trackingSourceText = `${this.trackingSource}_${this.listIndex + 1}`;
        this.tracking.trackViewed(this.promotion, this.trackingSourceText);

        const storedId = this.localStorage.get(this.bannerService.storageKey);

        if (storedId !== this.promotion.id) {
            return;
        }

        if (this.optInRedirect || this.optInInvisible) {
            await this.bannerService.openDetailsPage(this.promotion, this.trackingSourceData, () => {
                this.changeDetectorRef.detectChanges();
            });
        }

        this.localStorage.set(this.bannerService.storageKey, undefined);
        if (this.promotion?.status !== OfferStatus.NoOptIn) {
            this.setOfferStatus();
        }
    }

    @HostListener('click')
    async onclick(): Promise<void> {
        this.trackClick(CTALocation.Banner);
        await this.bannerService.openDetailsPage(this.promotion, this.trackingSourceData, () => {
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnChanges(): void {
        if (this.promotion && this.promotion.banner && this.promotion.banner.backgroundImageUrl) {
            this.backgroundImage = `url(${this.promotion.banner.backgroundImageUrl})`;
        }
    }

    ngAfterContentInit(): void {
        // TODO Roxin - this will reflow content .. a few times
        this.overlayActive = this.elementRef.nativeElement.offsetWidth < this.overlayActiveBreakpointPx;

        this.isDynamicSize && this.setDynamicSizeBanner();
    }

    private setDynamicSizeBanner(): void {
        this.windowEvent.resize
            .asObservable()
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.setClasses());

        this.setClasses();
    }

    private setOfferStatus(): void {
        this.trackClick(CTALocation.OptIn);

        this.offerStatusService.setOfferStatus(this.promotion).subscribe((response) => {
            this.promotion.status = response;
            this.tracking.trackOptinResult({ promotion: this.promotion, trackingSource: this.trackingSourceText, status: response });
            this.changeDetectorRef.detectChanges();
        });
    }

    private setClasses(): void {
        this.largeDynamicBannerClass = this.elementRef.nativeElement.offsetWidth > this.largeBannerWidthPx;
    }

    private trackClick(ctaLocation: CTALocation): void {
        this.tracking.trackCTA({
            promotion: this.promotion,
            trackingSource: this.trackingSourceText,
            ctaLocation,
            position: this.listIndex,
        });
    }
}
