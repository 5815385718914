/**
 * @stable
 */
export enum RtmsType {
    OVERLAY = 'OVERLAY',
    TOASTER = 'TOASTER',
    BALANCE_UPDATE = 'BALANCE_UPDATE',
    AUTO_LOGOUT_EVENT = 'AUTO_LOGOUT_EVENT',
    COIN_BAL_UPDATE_EVENT = 'COIN_BAL_UPDATE_EVENT',
    KYC_VERIFIED_EVENT = 'KYC_VERIFIED_EVENT',
    KYC_VERIFIED_EVENT_RMP = 'KYC_VERIFIED_EVENT_RMP',
    KYC_REFRESH_TRIGGER_EVENT = 'KYC_REFRESH_TRIGGER_EVENT',
    DE_ANNUAL_KYC_VERIFIED_EVENT = 'DE_ANNUAL_KYC_VERIFIED_EVENT',
    RCPU_SESS_EXPIRY_EVENT = 'RCPU_SESS_EXPIRY_EVENT',
    RCPU_ACTION_ACK = 'RCPU_ACTION_ACK',
    PLAYERINBOX_UPDATE = 'PLAYERINBOX_UPDATE',
    SESSION_LIMIT_NOTIFICATION_EVENT = 'SESSION_LIMIT_NOTIFICATION_EVENT',
    SESSION_LIMITS_PRE_LOGOUT_NOTIFICATION = 'SESSION_LIMITS_PRE_LOGOUT_NOTIFICATION',
    GREECE_TAX_SESSION_LOGOUT = 'GREECE_TAX_SESSION_LOGOUT',
    LOSS_LIMIT_WARN = 'LOSS_LIMIT_WARN',
    LOSS_LIMIT_REACHED = 'LOSS_LIMIT_REACHED',
    UPDATE_CRM_FLAG_EVENT = 'UPDATE_CRM_FLAG_EVENT',
    BIG_WIN_ALERT = 'BIG_WIN_ALERT',
    GAMPROTECT_ACCOUNT_MATCH = 'GAMPROTECT_ACCOUNT_MATCH',
    GAMSTOP_ACCOUNT_MATCH = 'GAMSTOP_ACCOUNT_MATCH',
    COIN_FAV_GOAL_TOASTER_EVENT = 'COIN_FAV_GOAL_TOASTER_EVENT',

    // Play break
    PLAY_BREAK_GRACE_PERIOD_EVENT = 'PLAY_BREAK_GRACE_PERIOD_EVENT',
    PLAY_BREAK_START_EVENT = 'PLAY_BREAK_START_EVENT',
    PLAY_BREAK_END_EVENT = 'PLAY_BREAK_END_EVENT',
    LONG_SESSION_INTERACTION_EVENT = 'LONG_SESSION_INTERACTION_EVENT',
    LONG_SESSION_24H_INTERACTION_EVENT = 'LONG_SESSION_24H_INTERACTION_EVENT',

    AUTO_LOGOUT_EVENT_WITH_POPUP = 'AUTO_LOGOUT_EVENT_WITH_POPUP',
    CASHIER_DEPOSIT_RECOVERY = 'CASHIER_DEPOSIT_RECOVERY',

    LUGAS_SESSION_ACTIVATION_EVENT = 'LUGAS_SESSION_ACTIVATION_EVENT',
    LUGAS_SESSION_DEACTIVATION_EVENT = 'LUGAS_SESSION_DEACTIVATION_EVENT',
    RTMS_GLOBAL_JP_WIN_INFO_EVENT = 'RTMS_GLOBAL_JP_WIN_INFO_EVENT',
}
