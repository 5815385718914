import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import {
    FixtureStage,
    HybridFixtureStatus,
    OptionMarket,
    Participant,
    ParticipantProperties,
    ParticipantType,
    Translation,
} from '@cds/betting-offer';
import { ScoreboardSlim } from '@cds/scoreboards/v1';
import { clone, includes } from 'lodash-es';

import {
    EnhancedFixtureViewGroupingConfiguration,
    EventModel,
    EventOptionGroup,
    EventParticipantType,
    ExtendedDisplayType,
    SetTab,
} from '../model/event.model';
import { CommonFixtureFactory } from './common-fixture.factory';
import { DetailedFixtureMarket, DetailedFixtureMarketFactory } from './detailed-fixture-market.factory';
import { ScoreboardFactory } from './scoreboard-factory.service';

@Injectable({ providedIn: 'root' })
export class DetailedUpdateFactory {
    constructor(
        private marketFactory: DetailedFixtureMarketFactory,
        private commonFactory: CommonFixtureFactory,
        private scoreboardFactory: ScoreboardFactory,
    ) {}

    update(model: EventModel, openForBetting: boolean, stage: FixtureStage): void {
        this.commonFactory.update(model, openForBetting, stage);
    }

    updateScoreboard(fixtureScoreboard: ScoreboardSlim, model: EventModel): void {
        model.scoreboard = this.scoreboardFactory.createScoreboard(fixtureScoreboard, model);
    }

    updateOptionGroup(
        event: EventModel,
        fixtureMarket: DetailedFixtureMarket,
        fixtureId: string,
        grouping: EnhancedFixtureViewGroupingConfiguration,
    ): void {
        const overrideParticipantNameMarkets =
            (event.offerSource === OfferSource.V2 ||
                event.hybridFixtureData?.status === HybridFixtureStatus.Ok ||
                event.linkedFixture?.offerSource === OfferSource.V2) &&
            this.commonFactory.shouldOverrideParticipantNames(fixtureMarket as OptionMarket, grouping.sportId);
        const participants = this.mapParticipants(event, overrideParticipantNameMarkets);
        const updates = this.marketFactory.create(fixtureMarket, fixtureId, event.online, grouping, participants, event.isPriceBoosted);
        const id = fixtureMarket.id.toString();

        event.optionGroups = this.updateMarkets(event.optionGroups, updates, event.online, event.isPriceBoosted);

        if (overrideParticipantNameMarkets || event.isPriceBoosted) {
            event.optionGroups = event.optionGroups.filter((opt) => opt.options.length);
        }
        event.marketsDictionary[id] = event.optionGroups.filter((optionGroup) => optionGroup.id === id || includes(optionGroup.groupedMarkets, id));
    }

    private mapParticipants(event: EventModel, overrideParticipantNameMarkets: boolean): Participant[] | undefined {
        if (!overrideParticipantNameMarkets) {
            return;
        }

        const players = event.players.map(
            (p) =>
                <Participant>{
                    id: p.fixtureParticipantId,
                    image: clone(p.image),
                    name: <Translation>{ short: p.shortName, value: p.name },
                    participantId: p.id,
                    properties: <ParticipantProperties>{ team: p.teamId },
                },
        );

        if (event.linkedFixture || event.hybridFixtureData) {
            const participants = event.participants
                .filter((x) => x.type === EventParticipantType.Away || x.type === EventParticipantType.Home)
                .map(
                    (p) =>
                        <Participant>{
                            id: p.id,
                            image: clone(p.image),
                            name: <Translation>{ short: p.shortName, value: p.name },
                            participantId: p.id,
                            properties: <ParticipantProperties>{
                                team: p.teamId,
                                type: p.type === EventParticipantType.Home ? ParticipantType.HomeTeam : ParticipantType.AwayTeam,
                            },
                        },
                );

            return [...players, ...participants];
        }

        return players;
    }

    private updateMarkets(
        currentOptionGroups: EventOptionGroup[],
        updateOptionGroups: EventOptionGroup[],
        fixtureOnline: boolean,
        isPriceBoosted?: boolean,
    ): EventOptionGroup[] {
        updateOptionGroups.forEach((update) => {
            const indexes = currentOptionGroups.reduce(
                (acc: number[], curr: EventOptionGroup, i: number) =>
                    curr.id === update.id &&
                    curr.detailedGrouping.marketSet === update.detailedGrouping.marketSet &&
                    (curr.detailedGrouping.marketSet !== SetTab.Other
                        ? curr.detailedGrouping.marketOrder === update.detailedGrouping.marketOrder
                        : true)
                        ? [...acc, i]
                        : acc,
                [],
            );

            if (!indexes.length) {
                currentOptionGroups.push(update);
            } else {
                indexes.forEach((index) => {
                    currentOptionGroups[index] = this.marketFactory.update(currentOptionGroups[index], update, fixtureOnline, isPriceBoosted);

                    if (!currentOptionGroups[index].options.length) {
                        currentOptionGroups.splice(index, 1);
                    }
                });

                this.updateAutomatedPriceBoostedMarket(currentOptionGroups, update, fixtureOnline);
            }
        });

        return [...currentOptionGroups];
    }

    private updateAutomatedPriceBoostedMarket(
        currentOptionGroups: EventOptionGroup[],
        updateOptionGroup: EventOptionGroup,
        fixtureOnline: boolean,
    ): void {
        const automatedPriceBoostIndex = currentOptionGroups.findIndex(
            (og) => og.id === updateOptionGroup.id && og.detailedGrouping.displayType === ExtendedDisplayType.AutomatedPriceBoost,
        );

        if (automatedPriceBoostIndex > -1) {
            const currentDetailedGrouping = clone(currentOptionGroups[automatedPriceBoostIndex].detailedGrouping);
            currentOptionGroups[automatedPriceBoostIndex] = this.marketFactory.update(
                currentOptionGroups[automatedPriceBoostIndex],
                updateOptionGroup,
                fixtureOnline,
                true,
            );
            currentOptionGroups[automatedPriceBoostIndex].detailedGrouping = currentDetailedGrouping;
        }
    }
}
