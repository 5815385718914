import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ItemComponent } from '../../common/item/item.component';
import { TrustPipe } from '../../common/trust.pipe';
import { CompetitionLogosModule } from '../../competition-logos/competition-logos.module';
import { SharedPriceboostModule } from '../../shared-priceboost/shared-priceboost.module';
import { TabBarModule } from '../../tab-bar/tab-bar.module';
import { WidgetHeaderComponent } from './widget-header.component';
import { WidgetTabBarComponent } from './widget-tab-bar.component';

@NgModule({
    imports: [CommonModule, TabBarModule, CompetitionLogosModule, SharedPriceboostModule, ItemComponent, TrustPipe],
    exports: [WidgetTabBarComponent, WidgetHeaderComponent],
    declarations: [WidgetTabBarComponent, WidgetHeaderComponent],
})
export class WidgetCommonModule {}
