import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

import { AccaBoostConfig } from '@frontend/sports/common/client-config-data-access';
import { UserEvent, UserLoginEvent } from '@frontend/vanilla/core';
import { AccaBoostToken } from 'packages/sports/common/betslip/modules/reward-tokens/reward-tokens.model';
import { EMPTY, Observable, ReplaySubject, Subject, concat, merge, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { UserService } from '../user/services/user.service';
import { AccaBoostTokenLoaderService } from './acca-boost-token-loader.service';

export const ACCA_BOOST_REFRESH_TRIGGER = new InjectionToken<AccaBoostRefreshTrigger>('ACCA_BOOST_REFRESH_TRIGGER');

export interface AccaBoostRefreshTrigger {
    getTrigger$(): Observable<void>;
}

@Injectable({ providedIn: 'root' })
export class AccaBoostTokenProviderService {
    private readonly tokens = new ReplaySubject<AccaBoostToken[]>(1);
    private readonly manualRefresh$ = new Subject<void>();

    readonly tokens$: Observable<AccaBoostToken[]> = this.tokens.asObservable();

    constructor(
        private userService: UserService,
        private accaBoostConfig: AccaBoostConfig,
        private loaderService: AccaBoostTokenLoaderService,
        @Optional() @Inject(ACCA_BOOST_REFRESH_TRIGGER) private autoRefreshTrigger: AccaBoostRefreshTrigger,
    ) {
        if (this.accaBoostConfig.isEnabled) {
            this.startGettingToken();
        } else {
            this.tokens.next([]);
        }
    }

    refresh(): void {
        this.manualRefresh$.next();
    }

    private startGettingToken(): void {
        const canShowTokens$ = this.userService.onAuthenticationChange$.pipe(
            map((event: UserEvent) => {
                return event instanceof UserLoginEvent || this.accaBoostConfig.allowUnAuthenticatedUsers;
            }),
            startWith(this.userService.isAuthenticated || this.accaBoostConfig.allowUnAuthenticatedUsers),
        );

        canShowTokens$
            .pipe(
                switchMap((canShowTokens) => {
                    const loadOnRefresh$ = merge(this.manualRefresh$, this.autoRefreshTrigger?.getTrigger$() || EMPTY).pipe(
                        switchMap(() => this.loadData()),
                    );

                    // when authenticated, load data and then subscribe to refreshing
                    return canShowTokens ? concat(this.loadData(), loadOnRefresh$) : of([]);
                }),
            )
            .subscribe((data: AccaBoostToken[]) => {
                this.tokens.next(data);
            });
    }

    private loadData(): Observable<AccaBoostToken[]> {
        return this.loaderService.loadTokens().pipe(map((data) => data.sort(this.byPriority)));
    }

    private byPriority = (first: AccaBoostToken, second: AccaBoostToken): number => first.priority - second.priority;
}
