import { ActivatedRouteSnapshot, Params } from '@angular/router';

export function getAllParams(route: ActivatedRouteSnapshot | undefined): Params {
    let params = { ...route?.params };
    let parentRoute = route?.parent;

    while (parentRoute) {
        params = Object.assign(params, parentRoute.params);
        parentRoute = parentRoute.parent;
    }

    return params;
}
