import { Inject, Injectable } from '@angular/core';

import { Deferred } from '@frontend/sports/common/base-utils';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';

import { EarlyPayoutDispatcherEvent } from '../../my-bets/models/early-payout.model';
import { EarlyPayoutCallResponse, EarlyPayoutFailedResponse, EarlyPayoutSuccessResponse } from '../models/early-payout';
import { EarlyPayoutStates } from '../models/early-payout-types';
import { EarlyPayout, MyBetsBetslipBase } from '../models/my-bets-viewmodels';
import { earlyPayoutServiceEditBetToken, earlyPayoutServiceMyBetsToken } from './early-payout-injection-tokens';
import { EarlyPayoutService } from './early-payout.service';

@Injectable()
export class EarlyPayoutSubmitService {
    private betslipPayoutStatuses: { [id: string]: EarlyPayoutStates } = {};
    private betslipsWithExecutedPayoutOperation: { [id: string]: MyBetsBetslipBase } = {};

    constructor(
        private epService: EarlyPayoutService,
        private dispatcher: DispatcherService,
    ) {}

    submitPayout(betslip: MyBetsBetslipBase, context: string, betTypeInfo?: string): Promise<EarlyPayoutCallResponse> {
        const deferred = new Deferred<EarlyPayoutCallResponse>();
        const betslipId = betslip.betslipRealId;

        this.betslipPayoutStatuses[betslipId] = EarlyPayoutStates.Preparing;

        this.epService
            .place(betslip, context, betTypeInfo)
            .then((response: EarlyPayoutCallResponse) => {
                if (response.error) {
                    delete this.betslipPayoutStatuses[betslipId];

                    this.dispatcher.dispatch(EarlyPayoutDispatcherEvent.CashOut.EarlyPayoutFailed, <EarlyPayoutFailedResponse>{
                        error: response.error,
                        possibleAmountWithoutStake: response.payoutWithoutStake,
                        betslipId,
                    });

                    return;
                }

                this.betslipPayoutStatuses[betslipId] = EarlyPayoutStates.PaidOut;
                const earlyPayout = this.betslipsWithExecutedPayoutOperation[betslipId]?.earlyPayout;
                if (earlyPayout !== undefined) {
                    earlyPayout.state = EarlyPayoutStates.PaidOut;
                }

                this.dispatcher.dispatch(EarlyPayoutDispatcherEvent.CashOut.EarlyPayoutSuccess, <EarlyPayoutSuccessResponse>{
                    payout: response.payout,
                    payoutWithoutStake: response.payoutWithoutStake,
                    betslipId,
                });
                deferred.resolve(response);
            })
            .catch((error) => {
                delete this.betslipPayoutStatuses[betslipId];

                betslip.earlyPayout = betslip.earlyPayout || new EarlyPayout();
                betslip.earlyPayout.state = EarlyPayoutStates.Disabled;
                if (error.error) {
                    betslip.earlyPayout.error = error.error;
                }

                this.dispatcher.dispatch(EarlyPayoutDispatcherEvent.CashOut.EarlyPayoutFailed, <EarlyPayoutFailedResponse>{
                    error: error.error,
                    betslipId,
                });
            })
            .finally(() => {
                delete this.betslipsWithExecutedPayoutOperation[betslipId];
            });

        return deferred.promise;
    }

    adjustPayoutStatus(betslip: MyBetsBetslipBase): void {
        const betslipId = betslip.betslipRealId;
        const earlyPayoutState = this.betslipPayoutStatuses[betslipId];
        const earlyPayout = betslip.earlyPayout;

        if (earlyPayout !== undefined && earlyPayoutState !== undefined) {
            earlyPayout.state = earlyPayoutState;

            if (earlyPayoutState === EarlyPayoutStates.Preparing) {
                this.betslipsWithExecutedPayoutOperation[betslipId] = betslip;
            }
        }
    }
}

export class EarlyPayoutSubmitServiceFactory {
    constructor(
        private earlyPayoutService: EarlyPayoutService,
        private dispatcher: DispatcherService,
    ) {}

    create(): EarlyPayoutSubmitService {
        return new EarlyPayoutSubmitService(this.earlyPayoutService, this.dispatcher);
    }
}

@Injectable({ providedIn: 'root' })
export class EarlyPayoutSubmitServiceMyBetsFactory extends EarlyPayoutSubmitServiceFactory {
    constructor(@Inject(earlyPayoutServiceMyBetsToken) earlyPayoutService: EarlyPayoutService, dispatcher: DispatcherService) {
        super(earlyPayoutService, dispatcher);
    }
}

@Injectable({ providedIn: 'root' })
export class EarlyPayoutSubmitServiceEditBetFactory extends EarlyPayoutSubmitServiceFactory {
    constructor(@Inject(earlyPayoutServiceEditBetToken) earlyPayoutService: EarlyPayoutService, dispatcher: DispatcherService) {
        super(earlyPayoutService, dispatcher);
    }
}
