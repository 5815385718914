import { EventDetailsColumnType } from '../event-model/helpers/event-media-helper.service';
import { EventModel } from '../event-model/model/event.model';
import { TrackingVideoSource } from '../tracking/tracking-video-source';

export interface MediaSetEventBase {
    tab: EventDetailsColumnType | null;
    source: MediaEventSource;
    trackingVideoSource: TrackingVideoSource;
}

export interface MediaSetEvent extends MediaSetEventBase {
    event: EventModel;
}

export interface MediaSetEventId extends MediaSetEventBase {
    eventId: string;
}

export interface MediaSetEventInternal extends MediaSetEvent {
    hasVideo: boolean;
    hasAnimation: boolean;
    hasStats: boolean;
    showStatsCenterButton?: boolean;
}

export enum MediaEventSource {
    Default = 0,
    Grid = 1,
    EventDetails = 2,
    EventSwitcher = 3,
    Scoreboard = 4,
}

export enum MediaModuleContextPage {
    Default = 0,
    EventDetails = 1,
}

export interface EventSwitcherItem {
    id: number;
    name: string;
    children?: EventSwitcherEventItem[];
}

export interface EventSwitcherEventItem {
    id: string;
    name: string;
}

export interface MediaPersistentState {
    video: MediaTabState & { pinned: boolean };
    animation: MediaTabState;
    statistics: MediaTabState;
    activeTab: EventDetailsColumnType;
}

export interface RestoreStatePayload extends MediaPersistentState {
    events: { [key: number]: MediaSetEventInternal };
}

export interface SetMediaWidgetContextPayload {
    page: MediaModuleContextPage;
    eventId: string | null;
}
export interface MediaTab {
    tabId: number;
    trackingSource: TrackingVideoSource;
}

export interface MediaTabState {
    eventId: string | null;
}

export enum FeedType {
    Twitch = 'Twitch',
    Beter = 'Beter',
}
