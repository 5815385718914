<div class="navigation-wrapper" *ngIf="layout.current.headerNavigation && !layout.current.headerTopNavigationHidden">
    <nav
        *ngIf="viewModel$ | async as viewModel"
        id="sports-nav"
        [class.compact]="viewModel.isDesktopNavCompact"
        [class.separator]="viewModel.showSeparator"
        [ngClass]="isEpcotEnabled ? 'with-pills' : 'default'">
        <ms-main-items
            [items]="navigation.topNavigation$ | async"
            [compactItems]="viewModel.isDesktopNavCompact"
            [isEpcotEnabled]="isEpcotEnabled"></ms-main-items>
    </nav>
</div>
<div class="az-menu-container"></div>
