import { Injectable, Optional } from '@angular/core';

import { FixturePage, FixtureType, OfferCategory } from '@cds/betting-offer';
import { FixtureRequest, FixtureState, OfferMapping, SortByCriteria } from '@cds/query-objects';
import { CountItem, SportConstant, sportModelMethods } from '@frontend/sports/common/base-utils';
import { GridConfig } from '@frontend/sports/common/client-config-data-access';
import { toString } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarService } from '../../../calendar/calendar.service';
import { BettingOfferApi } from '../../../cds/betting-offer-api.service';
import { SportsCacheService } from '../../../client-caching/sports-cache.service';
import { GridSorting } from '../../../grid-base/grid.model';
import { MarketOfferUrlParam } from '../../../navigation-core/url-helper.service';
import { CompetitionRoute } from '../../../navigation/navigation.models';
import { StatisticsConfigService } from '../../../statistics/statistics-config.service';

@Injectable({ providedIn: 'root' })
export class EventListService {
    constructor(
        protected bettingContent: BettingOfferApi,
        protected calendarService: CalendarService,
        protected gridConfig: GridConfig,
        protected bettingCache: SportsCacheService,
        @Optional() protected statisticsConfigService?: StatisticsConfigService,
    ) {}

    getSportList(start?: string, end?: string): Observable<CountItem[]> {
        const cached = !start && !end;

        const source = cached
            ? this.bettingCache.getSportList()
            : this.bettingContent.getSportList({ from: start, to: end }).pipe(map((sports) => sportModelMethods.fromTag(sports)));

        return source;
    }

    getFixtureList(
        route: CompetitionRoute,
        sorting?: GridSorting,
        skip?: number,
        take?: number,
        interval: { from?: string; to?: string } = {},
    ): Observable<FixturePage | undefined> {
        skip = skip || 0;
        take = take || this.gridConfig.initialSize;

        const fixtureRequest = this.createFixtureRequest(route);
        const dateInterval = this.calendarService.getTimeInterval(route.context) || interval;

        if (!fixtureRequest.dynamicOfferCategories && (dateInterval.from || dateInterval.to || route.tournament)) {
            fixtureRequest.fixtureCategories = [
                OfferCategory.Gridable,
                OfferCategory.NonGridable,
                OfferCategory.Other,
                OfferCategory.Specials,
                OfferCategory.Outrights,
            ].join(',');

            fixtureRequest.offerCategories =
                route.marketOffer === MarketOfferUrlParam.Specials || route.marketOffer === MarketOfferUrlParam.Outrights
                    ? [OfferCategory.Outrights, OfferCategory.Specials].join(',')
                    : OfferCategory.Gridable;
        }

        fixtureRequest.skip = skip;
        fixtureRequest.take = take;
        fixtureRequest.sortBy = sorting === GridSorting.Time ? SortByCriteria.StartDate : SortByCriteria.Tags;
        fixtureRequest.from = dateInterval.from;
        fixtureRequest.to = dateInterval.to;

        return this.bettingContent.getFixtureList(fixtureRequest);
    }

    createFixtureRequest(route: CompetitionRoute): FixtureRequest {
        let offerCategories: string | undefined;
        let offerTemplates: string | undefined;
        let fixtureCategories: string | undefined;
        let isPriceBoost = false;
        const hasSubcategories = route.outrightCategory || route.specialCategory;

        if (!route.marketTemplate) {
            if (route.marketOffer === MarketOfferUrlParam.PriceBoost) {
                isPriceBoost = true;
            } else if (route.marketOffer === MarketOfferUrlParam.Outrights) {
                offerCategories = OfferCategory.Outrights;
                fixtureCategories = !hasSubcategories ? OfferCategory.Outrights : undefined;
            } else if (route.marketOffer === MarketOfferUrlParam.Specials) {
                offerCategories = OfferCategory.Specials;
                fixtureCategories = !hasSubcategories ? OfferCategory.Specials : undefined;
            } else {
                if (route.marketOffer === MarketOfferUrlParam.NonGridable) {
                    offerCategories = OfferCategory.NonGridable;
                } else {
                    offerCategories = OfferCategory.Gridable;
                }

                fixtureCategories = [OfferCategory.Gridable, OfferCategory.NonGridable, OfferCategory.Other].join(',');
            }
        } else {
            offerTemplates = route.marketTemplate.toString();
        }

        return {
            fixtureTypes: FixtureType.Standard,
            state: FixtureState.Latest,
            offerMapping: OfferMapping.Filtered,
            offerCategories: route.dynamicOfferCategory ? undefined : offerCategories,
            dynamicOfferCategories: route.dynamicOfferCategory,
            outrightGroups: route.outrightCategory,
            specialGroups: route.specialCategory,
            fixtureCategories: route.dynamicOfferCategory ? undefined : fixtureCategories,
            sportIds: toString(route.sport),
            regionIds: route.sport === SportConstant.Tennis ? undefined : toString(route.region),
            tournamentIds: route.sport === SportConstant.Tennis ? toString(route.region) : undefined,
            competitionIds: route.isVirtual ? undefined : toString(route.league),
            conferenceIds: route.isVirtual ? undefined : toString(route.conference),
            virtualCompetitionIds: route.isVirtual ? toString(route.league) : undefined,
            virtualCompetitionGroupIds: (route.isVirtual && toString(route.virtualCompetitionGroup)) || undefined,
            gameTemplateIds: offerTemplates,
            isPriceBoost,
            statisticsModes: this.statisticsConfigService?.getStatisticsModesBySportId(route.sport),
        };
    }
}
