// eslint-disable-next-line no-restricted-imports
import { InternalFeaturesConfig } from '@frontend/sports/common/client-config-data-access';
import { RemoteLogger } from '@frontend/vanilla/core';

import { SportsRemoteLogger } from './sports-remote-logger.service';

export class DebugLogger {
    private readonly sportsLogger: SportsRemoteLogger;

    constructor(
        loggerName: string,
        remoteLogger: RemoteLogger,
        private internalFeaturesConfig: InternalFeaturesConfig,
    ) {
        this.sportsLogger = new SportsRemoteLogger(loggerName, remoteLogger);
    }

    error(message: string): void;
    error(error: Error, additionalMessage?: string): void;
    error(errorOrMessage: any, additionalMessage?: string): void {
        if (this.internalFeaturesConfig.isBetplacementLoggingEnabled) {
            this.sportsLogger.error(errorOrMessage, additionalMessage);
        }
    }

    warning(message: string, includeStack: boolean = false): void {
        if (this.internalFeaturesConfig.isBetplacementLoggingEnabled) {
            const stack = includeStack ? `\n ${new Error().stack}` : '';
            this.sportsLogger.warning(`${message}${stack}`);
        }
    }
}
