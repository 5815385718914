<ms-scroll-adapter
    *ngIf="scrollEnabled; else tabTemplate"
    [msScrollSelectedInView]="active && active!.id"
    [scrollSize]="scrollSize"
    [stretch]="stretch"
    [buttonSize]="buttonSize"
    class="ps__child--consume"
    (select)="track($event)">
    <ng-container *ngTemplateOutlet="tabTemplate"></ng-container>
</ms-scroll-adapter>

<ng-template #tabTemplate>
    <ul [ngClass]="containerClass" *ngIf="tabs?.length">
        <li *ngFor="let tab of tabs; trackBy: trackTab" [ngClass]="tabClass(tab)" [attr.data-menu-item-id]="tab.id" (click)="selected($event, tab)">
            <a
                [href]="tab.url"
                [msQuicklink]="tab.url"
                [class.link-without-count]="!tab.count"
                *ngIf="shouldNavigateByUrl(tab); else staticTemplate"
                [msAutomation]="tab.attribute"
                [ngStyle]="{
                    backgroundColor:
                        !tab.active && tab.overrideStyles && tab.overrideStyles.inactiveStyles
                            ? tab.overrideStyles.inactiveStyles.backgroundColor
                            : '',
                    borderColor:
                        !tab.active && tab.overrideStyles && tab.overrideStyles.inactiveStyles ? tab.overrideStyles.inactiveStyles.borderColor : ''
                }">
                <ng-container *ngTemplateOutlet="linkTemplate; context: { $implicit: tab }"></ng-container>
            </a>
            <ng-template #staticTemplate>
                <a
                    (click)="$event.preventDefault()"
                    [class.link-without-count]="!tab.count"
                    [msAutomation]="tab.attribute"
                    [ngStyle]="{
                        backgroundColor:
                            !tab.active && tab.overrideStyles && tab.overrideStyles.inactiveStyles
                                ? tab.overrideStyles.inactiveStyles.backgroundColor
                                : '',
                        borderColor:
                            !tab.active && tab.overrideStyles && tab.overrideStyles.inactiveStyles
                                ? tab.overrideStyles.inactiveStyles.borderColor
                                : ''
                    }">
                    <ng-container *ngTemplateOutlet="linkTemplate; context: { $implicit: tab }"></ng-container>
                </a>
            </ng-template>
        </li>
    </ul>
</ng-template>

<ng-template #linkTemplate let-tab>
    <span *ngFor="let badge of tab.badges" [ngClass]="badge.class">
        <span [ngClass]="badge.icon">{{ badge.title }}</span></span
    >

    <ng-container
        *ngIf="tab.icon"
        [ngTemplateOutlet]="iconTemplate || defaultIconTemplate"
        [ngTemplateOutletContext]="{ $implicit: tab }"></ng-container>

    <ng-template #defaultIconTemplate let-tab>
        <span class="icon" [ngClass]="tab.icon"></span>
    </ng-template>

    <span
        *ngIf="tabFormatted(tab.title); else rawTemplate"
        class="title"
        [class.title-without-count]="!tab.count"
        [innerHTML]="tab.title"
        [ngStyle]="{
            color: !tab.active && tab.overrideStyles && tab.overrideStyles.inactiveStyles ? tab.overrideStyles.inactiveStyles.color : ''
        }"></span>
    <ng-template #rawTemplate>
        <span
            class="title"
            [class.title-without-count]="!tab.count"
            [ngStyle]="{
                color: !tab.active && tab.overrideStyles && tab.overrideStyles.inactiveStyles ? tab.overrideStyles.inactiveStyles.color : ''
            }"
            >{{ tab.title }}</span
        >
    </ng-template>

    <ng-container
        *ngIf="tab.showSignPost; else countTemplate"
        [ngTemplateOutlet]="signPostTemplate"
        [ngTemplateOutletContext]="{ $implicit: tab }"></ng-container>

    <ng-template #countTemplate>
        <ng-container
            *ngIf="tab.count && tab.openCountVisible; else defaultCountTemplate"
            [ngTemplateOutlet]="openCountTemplate"
            [ngTemplateOutletContext]="{ $implicit: tab }"></ng-container>
    </ng-template>

    <ng-template #defaultCountTemplate>
        <span class="count" [ngClass]="{ 'count-padding': !tab.showSignPost }" *ngIf="tab.count">
            {{ tab.count }}
        </span>
    </ng-template>
</ng-template>
