<vn-dynamic-layout-slot [slot]="SlotName.Banner" />
<vn-dynamic-layout-slot [slot]="SlotName.Messages" />
<vn-dynamic-layout-slot [slot]="SlotName.Header" [vnElementKey]="VanillaElements.HEADER_SLOT" />
<vn-dynamic-layout-slot [slot]="SlotName.Menu" />
<vn-dynamic-layout-slot [slot]="SlotName.Main" [vnElementKey]="VanillaElements.MAIN_SLOT" />
<vn-dynamic-layout-slot [slot]="SlotName.App" />
<vn-dynamic-layout-slot [slot]="SlotName.Footer" />

<vn-loading-indicator />

<vn-dynamic-layout-slot [slot]="SlotName.Bottom" />
