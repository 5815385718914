import { Injector } from '@angular/core';
import { ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
// @ts-ignore
import { equalPath } from '@angular/router/esm2022/src/url_tree';
// @ts-ignore
import { shallowEqual } from '@angular/router/esm2022/src/utils/collection';
// @ts-ignore
import { getClosestRouteInjector } from '@angular/router/esm2022/src/utils/config';
// @ts-ignore
import { getTokenOrFunctionIdentity } from '@angular/router/esm2022/src/utils/preactivation';

export function getToken<T = any>(token: any, snapshot: ActivatedRouteSnapshot, moduleInjector: Injector): T {
    const injector = getClosestRouteInjector(snapshot) || moduleInjector;

    return getTokenOrFunctionIdentity(token, injector);
}

export function paramsOrQueryParamsChange(from: ActivatedRouteSnapshot, to: ActivatedRouteSnapshot): boolean {
    const popupChanged = from.queryParamMap.has('popup') !== to.queryParamMap.has('popup') && to.queryParamMap.has('popup');

    if (popupChanged) {
        return false;
    }

    // same as: https://github.com/angular/angular/blob/master/packages/router/src/utils/preactivation.ts#L164
    return !equalParamsAndUrlSegments(from, to) || !shallowEqual(from.queryParams, to.queryParams);
}

export function equalParamsAndUrlSegments(f: ActivatedRouteSnapshot, t: ActivatedRouteSnapshot): boolean {
    const equalUrlParams = shallowEqual(f.params, t.params) && equalSegments(f.url, t.url);
    const parentsMismatch = !f.parent !== !t.parent;

    return equalUrlParams && !parentsMismatch && (!f.parent || equalParamsAndUrlSegments(f.parent, t.parent!));
}

function equalSegments(aSegments: UrlSegment[], bSegments: UrlSegment[]): boolean {
    return equalPath(aSegments, bSegments) && aSegments.every((a, i) => shallowEqual(a.parameters, bSegments[i].parameters));
}
