import { Injectable } from '@angular/core';

import { ExpiringCache } from '@frontend/sports/common/base-utils';
import { MyBetsConfig } from '@frontend/sports/common/client-config-data-access';
import { OpenBetsSummary } from '@frontend/sports/types/models/my-bets';
import { firstValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiService } from '../http/api.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';

@Injectable({ providedIn: 'root' })
export class SummaryApiService {
    private readonly logger: SportsRemoteLogger;
    private mybetsStatsCache: ExpiringCache;

    constructor(
        private apiService: ApiService,
        loggerFactory: LoggerFactory,
        private myBetsConfig: MyBetsConfig,
    ) {
        this.logger = loggerFactory.getLogger('MyBetsApiService');

        const timeout = (this.myBetsConfig.cacheTimeout || 100) / 1000;
        this.mybetsStatsCache = new ExpiringCache(timeout);
    }

    async getOpenBetsCount(): Promise<OpenBetsSummary> {
        const source = this.apiService.get<OpenBetsSummary>('mybets/stats').pipe(
            catchError((error) => {
                this.logger.error(error, 'Get OpenBetsCount response failed.');

                return of({
                    openEvents: {},
                    openBetsCount: 0,
                    liveBetsCount: 0,
                    betNumbers: [],
                    isMigrationSummary: false,
                    hasError: false,
                });
            }),
        );

        return firstValueFrom(this.mybetsStatsCache.getOrCreate(`mybets-stats`, source));
    }
}
