import { Injectable } from '@angular/core';

import { NumberDictionary, SportConstant } from '@frontend/sports/common/base-utils';

@Injectable({ providedIn: 'root' })
export class MarqueeConstants {
    setBasedSports: NumberDictionary<boolean> = {
        [SportConstant.Tennis]: true,
        [SportConstant.TableTennis]: true,
        [SportConstant.Volleyball]: false,
        [SportConstant.Beachvolleyball]: false,
        [SportConstant.Badminton]: true,
    };

    sportsToReversePlayer2Image: number[] = [SportConstant.Icehockey, SportConstant.AmericanFootball, SportConstant.Baseball];
    sportsToTruncateNameInOneRow: number[] = [
        SportConstant.Badminton,
        SportConstant.Boxing,
        SportConstant.CombatSports,
        SportConstant.Fencing,
        SportConstant.Darts,
        SportConstant.Pool,
        SportConstant.Snooker,
        SportConstant.Squash,
        SportConstant.TableTennis,
        SportConstant.Tennis,
    ];
}
