import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IRootState } from '../store/root.state';
import { UiStates } from './ui-states.enum';

export interface IUiState {
    states: UiStates[];
}

export const defaultUiState: IUiState = {
    states: [UiStates.Home],
};

export const uiFeatureKey = 'ui';

export interface IUiRootState extends IRootState {
    ui: IUiState;
}

export const uiStateSelector = createFeatureSelector<IUiState>('ui');

export const isSheetViewOpen = createSelector(uiStateSelector, (ui: IUiState) => ui.states.some((state) => state === UiStates.SheetViewOpened));
