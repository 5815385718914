import { Injectable } from '@angular/core';

import { SPORTS_WITH_MAPPED_STATS } from '@frontend/sports/common/base-utils';
import { ImgConfigurationData, MediaConfig } from '@frontend/sports/common/client-config-data-access';

import { DateProviderService } from '../../common/date-provider.service';
import { EventModel } from '../model/event.model';
import { AnimationProviderService } from './animation-provider.service';

export enum EventDetailsColumnType {
    Video = 0,
    Animation = 1,
    Stats = 2,
}

@Injectable({ providedIn: 'root' })
export class EventMediaHelperService {
    constructor(
        private animationProvider: AnimationProviderService,
        private mediaConfig: MediaConfig,
        private imgConfig: ImgConfigurationData,
    ) {}

    hasVideo(event: EventModel): boolean {
        return !!event.stream && !!event.stream.id;
    }

    hasLiveVideo(event: EventModel): boolean {
        return event.live && this.hasVideo(event);
    }

    hasAnimationOrSimulation(event: EventModel): boolean {
        return this.isRunningBallSupported(event) || this.isAnimationSupported(event);
    }

    hasAnimation(event: EventModel): boolean {
        return this.isAnimationSupported(event);
    }

    hasBetradarAnimation(event: EventModel): boolean {
        return this.hasBetradarAnimationSupported(event);
    }

    hasStats(event: EventModel): boolean {
        const currentEventStats = event.scoreboard.visible && event.scoreboard.started;

        const hasStats = Boolean(
            (!!event.statsId && this.mediaConfig.showStatsCenterButton) ||
                (SPORTS_WITH_MAPPED_STATS.indexOf(event.sport.id) > -1 && (event.hasFixtureStats || event.hasLeagueStats || currentEventStats)),
        );

        return hasStats;
    }

    private isAnimationSupported(event: EventModel): boolean {
        return this.hasAnimationSupported(event) || this.hasBetradarAnimationSupported(event) || this.hasImgAnimationSupported(event);
    }

    private isRunningBallSupported(event: EventModel): boolean {
        return !!(event.live && event.runningBallDataId && event.participants && event.participants.length);
    }

    private hasAnimationSupported(event: EventModel): boolean {
        return !!(event.live && this.animationProvider.sportAnimations[event.sport.id] && event.participants && event.participants.length);
    }

    private hasBetradarAnimationSupported(event: EventModel): boolean {
        return !!(
            event.live &&
            event.betRadarVisualizationId &&
            event.betRadarCoverageLevel &&
            event.betRadarCoverageLevel >= this.animationProvider.betRadarSupportedCoverageLevel[event.sport.id] &&
            event.participants &&
            event.participants.length &&
            this.animationProvider.betRadarSupportedAnimations[event.sport.id]
        );
    }

    private hasImgAnimationSupported(event: EventModel): boolean {
        const animationShowTime = DateProviderService.addHours(DateProviderService.getNow(), this.imgConfig.hoursToShowImgAnimation);
        const showPrematchAnimation =
            animationShowTime.getTime() >= event.startDate.getTime() && DateProviderService.getNow().getTime() <= event.startDate.getTime();

        return !!((showPrematchAnimation || event.live) && event.imgEventId);
    }
}
