import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { ISimpleChanges, VirtualCompetitionDisplayMode, VirtualCompetitionGroupParticipant } from '@frontend/sports/common/base-utils';
import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { ScrollAdapterComponent } from '../../common/scroll-adapter/scroll-adapter.component';
import { TimerService } from '../../common/timer.service';
import { ScrollSelectedInViewDirective } from '../../directives/scroll-selected-in-view/scroll-selected-in-view.directive';
import { FavouritesModule } from '../../favourites/favourites.module';
import { RedirectHelperService } from '../../navigation-core/redirect-helper.service';
import { NOT_APPLICABLE, trackingConstants } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';
import { TournamentGroupsParticipantsComponent } from './tournament-groups-participants.component';
import { VirtualCompetitionModel } from './virtual-competition.service';

export interface TournamentGroup {
    id: number;
    favouriteId: number;
    title: string;
    active: boolean;
    url?: string;
    isFavourite: boolean;
    canBeFavourited?: boolean;
    favouriteName: string;
    participants: VirtualCompetitionGroupParticipant[];
}

@Component({
    selector: 'ms-tournament-groups',
    templateUrl: 'tournament-groups.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, ScrollAdapterComponent, ScrollSelectedInViewDirective, TournamentGroupsParticipantsComponent, FavouritesModule],
})
export class TournamentGroupsComponent implements OnInit, OnChanges {
    @Input() model: VirtualCompetitionModel;

    @HostBinding('class') className = 'tournament-groups';
    @ViewChild(ScrollAdapterComponent, { static: true }) scrollAdapter: ScrollAdapterComponent;
    active: TournamentGroup | undefined;
    VirtualCompetitionDisplayMode = VirtualCompetitionDisplayMode;

    constructor(
        private timer: TimerService,
        public sitecore: Sitecore,
        public redirectHelperService: RedirectHelperService,
        private trackingService: TrackingService,
    ) {}

    ngOnInit(): void {
        this.active = this.model.groups && this.model.groups.find((group) => group.active);
    }

    ngOnChanges(changes: ISimpleChanges<TournamentGroupsComponent>): void {
        this.timer.setTimeout(() => this.scrollAdapter.setArrows());
    }

    selected(event: Event, active: TournamentGroup): void {
        event.preventDefault();

        if (this.model.groups.find((group) => group.id === active.id)) {
            this.model.groups.forEach((group) => (group.active = group.id === active.id));
            this.active = active;

            this.trackingService.track('Event.Clicks', {
                [trackingConstants.COMPONENT_CATEGORY_EVENT]: 'Tournaments Groups',
                [trackingConstants.COMPONENT_LABEL_EVENT]: `Tournaments Group: ${active.title}`,
                [trackingConstants.COMPONENT_ACTION_EVENT]: 'Group - Clicked',
                [trackingConstants.COMPONENT_POSITION_EVENT]: NOT_APPLICABLE,
                [trackingConstants.COMPONENT_LOCATION_EVENT]: 'Tournaments Page',
                [trackingConstants.COMPONENT_EVENT_DETAILS]: 'Visitor Clicks on a Tournaments Group',
                [trackingConstants.COMPONENT_URL_CLICKED]: active.url || NOT_APPLICABLE,
            });

            this.redirectHelperService.goToPage(active.url, true);
        }
    }

    trackTabsBy(index: number, group: TournamentGroup): number {
        return group.id;
    }

    getGroupMenuItemId(item: TournamentGroup | undefined): string {
        const itemIndex = !item ? -1 : this.model.groups.findIndex((group) => group.id === item.id);
        const itemId = !item ? 0 : item.id;

        return `${itemId}#${itemIndex}`;
    }
}
