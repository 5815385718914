import { Injectable } from '@angular/core';

import { Fixture } from '@cds/betting-offer';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { OfferGroupingService } from '../../cds/offer-grouping.service';
import { DetailedFixtureFactory } from '../../event-model/mappers/detailed-fixture.factory';
import { FixtureFactory } from '../../event-model/mappers/fixture.factory';
import { EventModel } from '../../event-model/model/event.model';
import { GridFavouriteService } from '../grid-favourite.service';
import { GridEvent } from '../grid.model';
import { GridBaseFactory } from './grid-base.factory';
import { GridFixtureFactory } from './grid-fixture.factory';

export type GridSourceEvent = EventModel | Fixture;

export interface EventOption {
    marketGrouping?: boolean;
    isPriceBoosted?: boolean;
    excludePriceboostedMarketGrouping?: boolean;
}

export interface BaseFactory {
    canCreate(events: GridSourceEvent[]): boolean;
    create(events: GridSourceEvent[], options?: EventOption): Observable<GridEvent[]>;
}

@Injectable({ providedIn: 'root' })
export class EventFactory {
    private factories: BaseFactory[] = [new GridFixtureFactory(this.fixtureFactory, this.detailedFactory, this.grouping), new GridBaseFactory()];
    constructor(
        private favouriteService: GridFavouriteService,
        private fixtureFactory: FixtureFactory,
        private detailedFactory: DetailedFixtureFactory,
        private grouping: OfferGroupingService,
    ) {}

    create(events: GridSourceEvent[] | undefined, options?: EventOption): Observable<GridEvent[]> {
        if (!events || !events.length) {
            return of([]);
        }

        for (const factory of this.factories) {
            if (factory.canCreate(events)) {
                return factory.create(events, options).pipe(tap((mapped) => this.setFavourited(mapped)));
            }
        }

        throw new Error('No market factory found for market');
    }

    private setFavourited(events: GridEvent[]): void {
        events.forEach((current) => (current.favourited = this.favouriteService.isFavourited(current)));
    }
}
