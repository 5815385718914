import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[msInfoyTooltip]',
})
export class InfoTooltipDirective implements OnInit {
    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngOnInit(): void {
        this.setElementPosition();
    }

    private setElementPosition(): void {
        const parent = <HTMLElement>this.renderer.parentNode(this.element.nativeElement);

        if (parent) {
            const leftAlign = parent.offsetLeft;
            this.renderer.setStyle(this.element.nativeElement, 'left', leftAlign + 'px');
        }
    }
}
