import { Injectable } from '@angular/core';

import { Page } from '../../client-config/page.client-config';
import { NativeAppService } from '../../native-app/native-app.service';
import { DslRecorderService } from '../dsl-recorder.service';
import { DslRecordable, DslValuesProvider } from '../dsl.models';

@Injectable()
export class AppDslValuesProvider implements DslValuesProvider {
    constructor(
        private dslRecorderService: DslRecorderService,
        private service: NativeAppService,
        private page: Page,
    ) {}

    getProviders(): { [provider: string]: DslRecordable } {
        return {
            App: this.dslRecorderService
                .createRecordable('app')
                .createSimpleProperty(this.service, 'context', 'Context')
                .createProperty({ name: 'Theme', get: () => this.page.theme }),
        };
    }
}
