import { Injectable } from '@angular/core';

import { CountItem, LeagueItem, Omit, RegionItem, SportConstant, isDefined, isVirtualCompetitionGroupItem } from '@frontend/sports/common/base-utils';
import { StatisticsConfig } from '@frontend/sports/common/client-config-data-access';
import { flatten } from 'lodash-es';

import { LeagueGroup } from '../../grid-base/grid.model';

export interface GetStandingsParams {
    sport: CountItem;
    selectedVirtualGroupId?: number;
    expandedGroups?: number;
}

@Injectable({ providedIn: 'root' })
export class StandingsService {
    constructor(private statisticsConfig: StatisticsConfig) {}

    getStandingsGroups(params: GetStandingsParams): LeagueGroup[] {
        const { sport, selectedVirtualGroupId, expandedGroups = this.statisticsConfig.expandedLeagueTablesCount } = params;

        if (sport.id !== SportConstant.Soccer) {
            return [];
        }

        const leagueGroups = !selectedVirtualGroupId
            ? this.getStandingsForCompetitions(sport)
            : this.getStandingsForVirtualGroups(sport, selectedVirtualGroupId);

        leagueGroups.forEach((leagueGroup, index) => (leagueGroup.collapsed = index >= expandedGroups));

        return leagueGroups;
    }

    private getStandingsForCompetitions(sport: CountItem): LeagueGroup[] {
        const leagueGroups = flatten(
            (<RegionItem[]>sport.children).map((region) =>
                flatten(
                    (<LeagueItem[]>region.children).map((leagueItem) =>
                        leagueItem.isVirtual ? this.mapVirtualGroups(leagueItem, region) : this.mapCompetition(leagueItem, region),
                    ),
                ),
            ),
        );

        return leagueGroups;
    }

    private getStandingsForVirtualGroups(sport: CountItem, selectedVirtualGroupId: number): LeagueGroup[] {
        const leagueGroups = flatten(
            (<RegionItem[]>sport.children).map((region) =>
                flatten((<LeagueItem[]>region.children).map((leagueItem) => this.mapVirtualGroups(leagueItem, region, selectedVirtualGroupId))),
            ),
        );

        return leagueGroups;
    }

    private mapCompetition(competition: LeagueItem, region: RegionItem): LeagueGroup[] {
        if (!competition.standings) {
            return [];
        }

        return [{ ...this.toLeagueGroup(competition, region), id: competition.id, name: competition.name, canBeFavourited: true }];
    }

    private mapVirtualGroups(competition: LeagueItem, region: RegionItem, selectedVirtualGroup?: number): LeagueGroup[] {
        if (!competition.isVirtual || !competition.children) {
            return [];
        }

        const leagueGroups = competition.children
            .filter(isVirtualCompetitionGroupItem)
            .filter((virtualGroup) => virtualGroup.standings && (!isDefined(selectedVirtualGroup) || virtualGroup.id === selectedVirtualGroup))
            .map<LeagueGroup>((virtualGroup) => ({
                ...this.toLeagueGroup(virtualGroup, region),
                id: virtualGroup.siblings[0] || virtualGroup.groupIds[0] || virtualGroup.stageIds[0],
                name: `${competition.name} - ${virtualGroup.name}`,
                virtualCompetitionId: virtualGroup.parentId,
                virtualCompetitionGroupId: virtualGroup.id,
                canBeFavourited: !(virtualGroup.stageIds.length || virtualGroup.groupIds.length),
            }));

        return leagueGroups;
    }

    private toLeagueGroup(
        leagueItem: LeagueItem,
        region: RegionItem,
    ): Omit<LeagueGroup, 'id' | 'name' | 'virtualCompetitionGroupId' | 'canBeFavourited'> {
        return {
            count: leagueItem.counts.preMatch + leagueItem.counts.live,
            events: [],
            collapsed: false,
            collapsible: true,
            deferred: false,
            siblings: [leagueItem.id],
            region,
        };
    }
}
