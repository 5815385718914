import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { Store } from '@ngrx/store';
import { uniq, values } from 'lodash-es';
import { IBetslipRootState } from 'packages/sports/common/betslip/base/store/state';
import { BetslipIntegrationService } from 'packages/sports/common/betslip/integration/betslip-integration.service';
import { IRewardToken } from 'packages/sports/common/betslip/modules/reward-tokens/reward-tokens.model';
import { selectRewardTokens } from 'packages/sports/common/betslip/modules/reward-tokens/selectors';
import { getNonAccaTokens, isAccaBoostToken } from 'packages/sports/common/betslip/modules/reward-tokens/services/reward-tokens.utils';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { RewardTokenType } from '../../tokens-base/token-base.models';

@Component({
    selector: 'ms-reward-token-eligible-tag',
    templateUrl: 'reward-token-eligible-tag.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AsyncPipe, NgIf, NgClass],
})
export class RewardTokenEligibleTagComponent {
    @Input() set leagues(value: number[]) {
        this.leagues$.next(value);
    }

    @Input() set sport(value: number) {
        this.sport$.next(value);
    }

    private sport$ = new BehaviorSubject<number | null>(null);
    private leagues$ = new BehaviorSubject<number[]>([]);

    private readonly badgeModels = {
        [RewardTokenType.OddsBoost]: {
            badgeText: this.sitecore.crmTokens.messages.OddsBoost,
            cssClass: 'reward-token-eligible-tag--odds-boost',
        },
        [RewardTokenType.RiskFreeBet]: {
            badgeText: this.sitecore.crmTokens.messages.RiskFree,
            cssClass: 'reward-token-eligible-tag--risk-free',
        },
        [RewardTokenType.FreeBet]: {
            badgeText: this.sitecore.crmTokens.messages.Freebet,
            cssClass: 'reward-token-eligible-tag--freebet',
        },
        [RewardTokenType.AccaBoost]: {
            badgeText: this.sitecore.accaBoost.AccaBoost,
            cssClass: 'reward-token-eligible-tag--acca-boost',
        },
    };

    private readonly betslipInitialized$ = this.betslipIntegrationService.betslipInitialized$();
    private readonly tokens$ = this.store.select(selectRewardTokens).pipe(
        map((state) => {
            const allTokens = values(state);
            const nonAccaBoostTokens = getNonAccaTokens(allTokens);

            // if there are more than one acca boost token, ignore them for showing the eligibility tag
            if (allTokens.length - nonAccaBoostTokens.length > 1) {
                return nonAccaBoostTokens;
            }

            return allTokens;
        }),
    );

    private isEligible(token: IRewardToken, leagueIds: number[], sportId: number | null): boolean {
        if (isAccaBoostToken(token)) {
            const competitions = (sportId && token.filter.sportsConfigs[sportId]?.competitions) || {};

            return leagueIds.some((c) => competitions[c]);
        }

        return token.filter.competitions.some((c) => leagueIds.includes(c.id));
    }

    readonly tokenModel$ = this.betslipInitialized$.pipe(
        concatMap(() => {
            return combineLatest([this.tokens$, this.sport$, this.leagues$]);
        }),
        map(([tokens, sportId, leagueIds]) => {
            const eligibleTokens = tokens.filter(
                (t) => Object.keys(this.badgeModels).includes(t.rewardTokenType) && this.isEligible(t, leagueIds, sportId),
            );

            const tokenTypes = uniq(eligibleTokens.map((t) => t.rewardTokenType));

            if (tokenTypes.length === 0) {
                return null;
            }

            if (tokenTypes.length === 1) {
                return this.badgeModels[tokenTypes[0]];
            }

            return { badgeText: this.sitecore.crmTokens.messages.Rewards, cssClass: 'reward-token-eligible-tag--rewards' };
        }),
    );

    constructor(
        public sitecore: Sitecore,
        private store: Store<IBetslipRootState>,
        private betslipIntegrationService: BetslipIntegrationService,
    ) {}
}
