export enum Tv2MarketParameters {
    MarketType = 'MarketType',
    Happening = 'Happening',
    Period = 'Period',
    FixtureParticipant = 'FixtureParticipant',
    Handicap = 'Handicap',
    DecimalHandicap = 'DecimalHandicap',
    Places = 'Places',
    Position = 'Position',
    IntegerValue = 'IntegerValue',
    DecimalValue = 'DecimalValue',
    RangeValue = 'RangeValue',
    TemplateId = 'TemplateId', // hybrid mode only
    CategoryId = 'CategoryId', // hybrid mode only
    SubPeriodNumber = 'SubPeriodNumber',
    FixtureParticipantList = 'FixtureParticipantList',
    Lengths = 'Lengths',
    MarketSubType = 'MarketSubType',
    SubPeriod = 'SubPeriod',
}

export enum MarketTypes {
    HeadToHead = 'HeadToHead',
    TwoWayHandicap = '2wayHandicap',
    Scorer = 'Scorer',
    MultiFPOverUnder = 'MultiFPOver/Under',
    HappeningToOccurV2 = 'HappeningToOccurV2',
    HappeningToOccurAndTeamToWin = 'HappeningToOccurAndTeamToWin',
    DynamicCorrectScore = 'DynamicCorrectScore',
    Spread = 'SPREAD',
    CorrectScore = 'CORRECT_SCORE',
    BetBuilder = 'BetBuilder',
    OverUnder = 'OVER_UNDER',
    TotalsOverUnder = 'TOTALS_OVER_UNDER',
    AutomatedPriceBoost = 'AutomatedPriceBoost',
}

export enum HappeningValue {
    PassingYards = 'PassingYards',
    TouchdownPass = 'TouchdownPass',
    Points = 'Points',
}
