export enum ParticipantPickType {
    Win = 1,
    Forecast = 2,
    CombinationForecast = 3,
    Tricast = 4,
    CombinationTricast = 5,
    Freeform = 6,
}

export enum SourceOfPick {
    Upsell = 'Upsell',
    OutrightsGrid = 'OutrightsGrid',
    Other = 'Other',
    BetStationMarquee = 'BetStationMarquee',
    EventDetails = 'EventDetails',
    LineSwitcher = 'LineSwitcher',
    MultiGenerator = 'MultiGenerator',
    WorldCupHub = 'WorldCupHub',
    EmbeddedBetBuilder = 'EmbeddedBetBuilder',
    OverlayBetBuilder = 'OverlayBetBuilder',
    TeamGrouping = 'TeamGrouping',
}
