import { Injectable } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { TopNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { ReplaySubject, Subject, combineLatest, first, switchMap, takeUntil } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { TopMenuContentItem } from './navigation.models';
import { FixedNavigationItemsProvider } from './providers/fixed-navigation-items.provider';
import { LastSelectedSportProvider } from './providers/last-selected-sport.provider';
import { SportNavigationItemsProvider } from './providers/sport-navigation-items.provider';
import { TopNavigationItemsProvider } from './providers/top-navigation-items.provider';

@HooksWireup()
@Injectable({ providedIn: 'root' })
export class NavigationService extends OnDestroyCleanup {
    private readonly _topNavigation$ = new ReplaySubject<TopMenuContentItem[]>(1);
    readonly topNavigation$ = this._topNavigation$.asObservable();

    private separatorMarker = 'before-separator';

    private readonly init$ = new Subject<void>();

    constructor(
        private navigationConfig: TopNavigationConfig,
        private trackingService: TrackingService,
        private topItemsProvider: TopNavigationItemsProvider,
        private fixedItemsProvider: FixedNavigationItemsProvider,
        private sportsItemsProvider: SportNavigationItemsProvider,
        private lastSportProvider: LastSelectedSportProvider,
    ) {
        super();

        combineLatest([this.init$, this.navigationConfig.whenReady])
            .pipe(
                first(),
                switchMap(() =>
                    combineLatest([
                        this.topItemsProvider.navigationItems$.pipe(distinctUntilLengthChanged()),
                        this.fixedItemsProvider.navigationItems$.pipe(distinctUntilLengthChanged()),
                        this.sportsItemsProvider.sportItems$.pipe(distinctUntilLengthChanged()),
                        this.lastSportProvider.lastSport$.pipe(distinctUntilChanged((prev, next) => next?.name === prev?.name)),
                    ]),
                ),
                map(([topNavigationItems, fixedItems, sportsItems, selectedSport]) =>
                    this.buildNavigation(topNavigationItems, fixedItems, sportsItems, selectedSport),
                ),
                takeUntil(this.destroyed$),
            )
            .subscribe((items) => this._topNavigation$.next(items));
    }

    init() {
        this.init$.next();
    }

    tracking(trackingPagename?: string, preFix?: string): void {
        if (!trackingPagename) {
            return;
        }
        const value = this.getTrackingValue(trackingPagename, preFix);

        this.trackingService.track(trackingConstants.EVENT_NAVIGATION_MENUS, {
            [trackingConstants.PAGE_NAVIGATION_MENUS]: value,
        });
    }

    private buildNavigation(
        mainItems: TopMenuContentItem[],
        fixedItems: TopMenuContentItem[],
        sportsItems: TopMenuContentItem[],
        sport?: TopMenuContentItem,
    ): TopMenuContentItem[] {
        const isV2Navigation = !!this.navigationConfig.topNavigationItems?.length;
        if (isV2Navigation) {
            const allItems = [...mainItems, ...fixedItems];
            const { sportsIndex, selectedSportIndex } = this.recalculateIndexes(allItems);

            if (sportsIndex >= 0) {
                const beforeSports = allItems.slice(0, sportsIndex);
                const afterSports = allItems.slice(sportsIndex, allItems.length);

                const topNavigationItems = [...beforeSports, ...sportsItems, ...afterSports];

                this.addSelectedSport(topNavigationItems, isV2Navigation, sport, selectedSportIndex);

                return topNavigationItems;
            } else {
                this.addSelectedSport(allItems, isV2Navigation, sport, selectedSportIndex);

                return allItems;
            }
        } else {
            const topNavigationItems = [...mainItems, ...sportsItems, ...fixedItems];

            this.addSelectedSport(topNavigationItems, isV2Navigation, sport);

            return topNavigationItems;
        }
    }

    private recalculateIndexes(items: TopMenuContentItem[]): { sportsIndex: number; selectedSportIndex: number } {
        const sportsIndex = this.navigationConfig.sportsConfig.topSports.index;
        const selectedSportIndex = this.navigationConfig.sportsConfig.selectedSport.index;
        const allConfigItems = [...this.navigationConfig.topNavigationItems, ...this.navigationConfig.fixedItems];

        let sportsIndexUpdated = sportsIndex;
        let selectedSportIndexUpdated = selectedSportIndex;

        allConfigItems.forEach((item, index) => {
            if ((item.condition || !item.activeName) && !items.some((i) => i.name === item.activeName)) {
                if (index < sportsIndex) {
                    sportsIndexUpdated--;
                }

                if (index < selectedSportIndex) {
                    selectedSportIndexUpdated--;
                }
            }
        });

        return { sportsIndex: sportsIndexUpdated, selectedSportIndex: selectedSportIndexUpdated };
    }

    private addSelectedSport(items: TopMenuContentItem[], isV2Navigation: boolean, sport?: TopMenuContentItem, index = -1): void {
        if (sport && !items.some((item) => item.name === sport.name)) {
            if (isV2Navigation && index >= 0) {
                items.splice(index, 0, ...[sport]);
            } else if (!isV2Navigation) {
                // find the item with separator's index in afterSports array and insert selected sport right before it
                const selectedSportIndex = items.findIndex((i) => i.class.indexOf(this.separatorMarker) > -1);

                items.splice(selectedSportIndex, 0, ...[sport]);
            }
        }
    }

    private getTrackingValue(trackingPagename: string, preFix?: string): string {
        return preFix ? `${preFix.toString()}_${trackingPagename}` : trackingPagename;
    }
}

export function distinctUntilLengthChanged<T>() {
    return distinctUntilChanged<T[]>((prev, next) => prev.length === next.length);
}
