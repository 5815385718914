import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { CountItem, HooksWireup } from '@frontend/sports/common/base-utils';
import { CompetitionHeaderPayload } from '@frontend/sports/types/components/widget/types';
import { Observable, ReplaySubject, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { SportsCacheService } from '../../client-caching/sports-cache.service';
import { CompetitionRouteService } from '../../competition-list/competition-route.service';
import { CompetitionRoute } from '../../navigation/navigation.models';
import { WidgetComponent } from '../../widget/core/widget-registry.service';
import { PruneHelperService } from './competitions/prune-helper.service';
import { VirtualCompetitionModel, VirtualCompetitionService } from './virtual-competition.service';

type ViewModel = CompetitionHeaderPayload & {
    sport: CountItem;
    isFavouritesEnabled: boolean;
    virtualCompetition: VirtualCompetitionModel | null;
};

@HooksWireup()
@Component({
    selector: 'ms-competition-header-widget',
    templateUrl: 'competition-header-widget.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionHeaderWidgetComponent extends WidgetComponent<CompetitionHeaderPayload> {
    private readonly payload$ = new ReplaySubject<CompetitionHeaderPayload>(1);
    readonly vm$: Observable<ViewModel | null>;

    @HostBinding('class') className = 'competition-header-widget event-list';

    constructor(
        private competitionRouteService: CompetitionRouteService,
        private virtualCompetitionService: VirtualCompetitionService,
        private pruneService: PruneHelperService,
        private bettingCache: SportsCacheService,
    ) {
        super();

        this.vm$ = this.payload$.pipe(
            switchMap((payload) => {
                const route = this.competitionRouteService.params();

                if (!route.sport || !route.league) {
                    return of(null);
                }

                return this.bettingCache.getSport(route.sport).pipe(switchMap((sport) => this.getModel$(sport, route, payload)));
            }),
        );
    }

    private getModel$(sport: CountItem | undefined, route: CompetitionRoute, payload: CompetitionHeaderPayload): Observable<ViewModel | null> {
        const prunnedSportItem = this.pruneService.prune(sport, route);
        const virtualCompetitionItem = this.virtualCompetitionService.getCompetition(sport, route);

        const source$ =
            !prunnedSportItem || !virtualCompetitionItem
                ? of(null)
                : this.virtualCompetitionService.subscribe$(prunnedSportItem, virtualCompetitionItem);

        return source$.pipe(
            map((virtualCompetitionModel) => {
                return {
                    sport: prunnedSportItem!,
                    title: payload.title,
                    // non-virtual competitions and tv2 virtual competitions with one competition assigned, can have favorites enabled
                    isFavouritesEnabled: !virtualCompetitionModel || !!virtualCompetitionModel.realCompetitionId,
                    virtualCompetition: virtualCompetitionModel,
                };
            }),
            tap((_) => this.setWidgetLoaded()),
        );
    }

    protected override onData(payload: CompetitionHeaderPayload): void {
        this.payload$.next(payload);
    }
}
