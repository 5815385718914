import { Fixture } from '@cds/betting-offer';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { OfferGroupingService } from '../../cds/offer-grouping.service';
import { DetailedFixtureFactory } from '../../event-model/mappers/detailed-fixture.factory';
import { FixtureFactory } from '../../event-model/mappers/fixture.factory';
import { GridEvent } from '../grid.model';
import { BaseFactory, EventOption, GridSourceEvent } from './event.factory';

export class GridFixtureFactory implements BaseFactory {
    constructor(
        private fixtureFactory: FixtureFactory,
        private detailedFactory: DetailedFixtureFactory,
        private grouping: OfferGroupingService,
    ) {}

    canCreate(events: GridSourceEvent[]): boolean {
        return 'games' in events[0] || 'optionMarkets' in events[0];
    }

    create(events: GridSourceEvent[], options?: EventOption): Observable<GridEvent[]> {
        const fixtures = events as Fixture[];

        if (!options || !options.marketGrouping) {
            return of(fixtures.map((fixture) => this.fixtureFactory.create(fixture)));
        }

        return this.grouping.getFixtureGrouping(fixtures[0].sport.id, 'any').pipe(
            map((groupingConfiguration) =>
                fixtures.map((fixture) => {
                    if (!groupingConfiguration) {
                        throw new Error('Could not map events without grouping');
                    }

                    return this.detailedFactory.create({
                        fixture,
                        splitFixtures: [],
                        grouping: groupingConfiguration,
                        isPriceBoosted: options.isPriceBoosted,
                        excludePriceboostedMarketGrouping: options.excludePriceboostedMarketGrouping,
                    });
                }),
            ),
        );
    }
}
