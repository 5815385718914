import { Injectable } from '@angular/core';

import { CountItem } from '@frontend/sports/common/base-utils';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { SportsCacheService } from '../../client-caching/sports-cache.service';

@Injectable({ providedIn: 'root' })
export class BaseNavigationItemsProvider {
    readonly hasLiveItems$: Observable<boolean>;

    constructor(protected sportsCache: SportsCacheService) {
        this.hasLiveItems$ = this.sportsCache.getSportList().pipe(
            map((items: CountItem[]) => {
                return items.some((item) => item.counts.live);
            }),
            startWith(false),
        );
    }
}
