import { Injectable } from '@angular/core';

import { SitemapSaveStateService } from './sitemap-save-state.service';
import { SitemapItemBaseModel, SitemapStateModel } from './sitemap.models';

@Injectable({
    providedIn: 'root',
})
export class SitemapStateService {
    private navigationPath: SitemapStateModel;

    constructor(private saveStateService: SitemapSaveStateService) {
        this.navigationPath = {
            currentState: this.saveStateService.sitemap.currentState || [],
            siblings: this.saveStateService.sitemap.siblings || [],
        };
    }

    toggleSitemapItem(item: SitemapItemBaseModel): void {
        this.actionWithSaveState(() => {
            if (item.active) {
                this.activateSitemapItem(item);
            } else {
                this.deactivateSitemapItem(item);
            }
        });
    }

    setPath(newPath: SitemapItemBaseModel[]): void {
        this.actionWithSaveState(() => {
            this.navigationPath.currentState = newPath;
        });
    }

    setSiblings(siblings: SitemapItemBaseModel[]): void {
        this.actionWithSaveState(() => {
            this.navigationPath.siblings = siblings;
        });
    }

    getItem(id: string, level: number): SitemapItemBaseModel | undefined {
        return this.navigationPath.currentState.find((n) => n.id === id && n.level === level);
    }

    get active(): SitemapItemBaseModel | undefined {
        return this.navigationPath.currentState.find((itm) => itm.active);
    }

    get drawerItem(): SitemapItemBaseModel | undefined {
        return this.navigationPath.currentState.find((n) => n.isInDrawer);
    }

    get navigation(): SitemapStateModel {
        return this.navigationPath;
    }

    get siblings(): SitemapItemBaseModel[] {
        return this.navigationPath.siblings;
    }

    private activateSitemapItem(item: SitemapItemBaseModel): void {
        const activeDrawerItem = this.navigationPath.currentState.find((n) => item.activeDrawerItem?.id && n.id === item.activeDrawerItem?.id);
        if (activeDrawerItem) {
            this.navigationPath.currentState = this.navigationPath.currentState.filter((itm) => itm.level <= activeDrawerItem.level);
            activeDrawerItem.active = true;

            return;
        }

        this.navigationPath.currentState = this.navigationPath.currentState.filter((itm) => itm.level < item.level);
        this.navigationPath.currentState.push({ ...item });
        this.navigationPath.currentState.filter((n) => n.id !== item.id && n.level !== item.level).forEach((n) => (n.active = false));
        this.navigationPath.siblings.filter((n) => n.id !== item.id).forEach((n) => (n.active = false));
    }

    private deactivateSitemapItem(item: SitemapItemBaseModel): void {
        if (item.level === 1) {
            this.navigationPath.currentState = [];

            return;
        }

        this.navigationPath.currentState = this.navigationPath.currentState.filter((itm) => itm.level < item.level);
        const newActiveItem = this.navigationPath.currentState.find((itm) => itm.level === item.level - 1);
        if (newActiveItem) {
            this.navigationPath.currentState.forEach((n) => (n.active = false));
            newActiveItem.active = true;
        }
    }

    private actionWithSaveState(action: () => void): void {
        action();
        this.saveStateService.sitemap = this.navigationPath;
    }
}
