import { Injectable } from '@angular/core';

import { ScrollContainerService } from './scroll-container.service';

@Injectable({
    providedIn: 'root',
})
export class HideHeaderService {
    scrollLastPosition = 0;

    constructor(private scrollContainer: ScrollContainerService) {}

    onScroll() {
        if (this.scrollContainer.scrollTop > 5 && this.scrollContainer.scrollTop >= this.scrollLastPosition) {
            const headerElement = window.document.querySelector('.slot-header_bottom_items') as HTMLElement;
            const scrollHeight = headerElement.offsetTop;
            const scrollTop = (Math.abs(scrollHeight) * -1).toString();
            document.querySelector('.slot-header')?.setAttribute('style', `top:${scrollTop}px`);
        } else {
            document.querySelector('.slot-header')?.removeAttribute('style');
        }
        this.scrollLastPosition = this.scrollContainer.scrollTop;
    }

    removeHideHeader() {
        document.querySelector('.slot-header')?.removeAttribute('style');
    }
}
