import { BetPlacementErrorIcon } from '../../bet-placement-error-icon';
import { BetslipError } from '../../betslip-error';
import { ClientErrorType } from '../../client-error-type';

export class RewardTokensMinStakeError extends BetslipError {
    constructor(minimumStake: number) {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensMinStakeError;
        this.hasClientValidation = true;
        this.minimumStake = minimumStake;
    }

    readonly minimumStake: number;
}
