<div class="numpad-keys" [ngClass]="{ 'hide-confirm': !numpadService.keypadOptions.showConfirm }">
    <button (click)="tap('1')" class="one" tabindex="-1">1</button> <button (click)="tap('4')" class="four" tabindex="-1">4</button>
    <button (click)="tap('7')" class="seven" tabindex="-1">7</button> <button (click)="tap('0')" class="zero" tabindex="-1">0</button>
    <button (click)="tap('2')" class="two" tabindex="-1">2</button> <button (click)="tap('5')" class="five" tabindex="-1">5</button>
    <button (click)="tap('8')" class="eight" tabindex="-1">8</button> <button (click)="tap('3')" class="three" tabindex="-1">3</button>
    <button (click)="tap('6')" class="six" tabindex="-1">6</button> <button (click)="tap('9')" class="nine" tabindex="-1">9</button>
    <button (click)="tap(separator)" class="separator" tabindex="-1">{{ separator }}</button>
    <button (click)="remove()" class="delete" tabindex="-1">
        <i class="theme-delete-i"></i>
    </button>
    <button (click)="ok()" class="ok" *ngIf="numpadService.keypadOptions.showConfirm" tabindex="-1">
        <span>{{ sitecore.betslip.numpad.Ok }}</span>
    </button>
</div>
