import { Injectable } from '@angular/core';

import { SlipState } from '@bpos/v1/my-bets';

import { MoneyService } from '../../money/money.service';
import { EarlyPayoutPossibility, EarlyPayoutsWithBetslip } from '../models/early-payout';
import { EarlyPayoutErrorTypes, EarlyPayoutStates } from '../models/early-payout-types';
import { EarlyPayout, MyBetsBetslipBase } from '../models/my-bets-viewmodels';

@Injectable({ providedIn: 'root' })
export class EarlyPayoutHelpers {
    // Extend the set with all supported bet not allowed reasons
    private readonly betNotAllowedReasons = new Set<string>(['livesgp']);

    constructor(private moneyService: MoneyService) {}

    isEarlyPayoutDisabled(betslip: MyBetsBetslipBase): boolean {
        let result = false;
        if (
            !betslip ||
            !betslip.earlyPayout ||
            betslip.earlyPayout.state === EarlyPayoutStates.Done ||
            betslip.earlyPayout.state === EarlyPayoutStates.PaidOut ||
            betslip.earlyPayout.state === EarlyPayoutStates.PermanentlyDisabled ||
            betslip.earlyPayout.state === EarlyPayoutStates.Completed
        ) {
            result = true;
        }

        return result;
    }

    isEarlyPayoutPermanentlyDisabled(ep: EarlyPayoutPossibility): boolean {
        return (
            !ep.earlyPayoutPossible &&
            this.getEarlyPayoutErrorTypeLowerCase(ep.earlyPayoutError?.type, ep.earlyPayoutError?.reason) ===
                EarlyPayoutErrorTypes.BetNotAllowedForCashout.toLowerCase()
        );
    }

    mapDisabledEarlyPayouts(betslips: MyBetsBetslipBase[]): void {
        betslips
            .filter((betslip) => betslip && !betslip.isCachedOut && !betslip.earlyPayout)
            .forEach((betslip) => {
                betslip.earlyPayout = betslip.earlyPayout || new EarlyPayout();
                betslip.earlyPayout.state = EarlyPayoutStates.Disabled;
            });
    }

    mapPermanentlyDisabledEarlyPayouts(earlyPayouts: EarlyPayoutPossibility[], potentialEPbetslips: MyBetsBetslipBase[]): void {
        earlyPayouts
            .filter((earlyPayout: EarlyPayoutPossibility) => this.isEarlyPayoutPermanentlyDisabled(earlyPayout))
            .forEach((earlyPayout: EarlyPayoutPossibility) => {
                potentialEPbetslips
                    .filter((betslip: MyBetsBetslipBase) => betslip.betslipRealId === earlyPayout.betNumber && betslip.state === SlipState.Open)
                    .forEach((betslip: MyBetsBetslipBase) => {
                        betslip.earlyPayout = betslip.earlyPayout || new EarlyPayout();
                        betslip.earlyPayout.state = EarlyPayoutStates.PermanentlyDisabled;
                    });
            });
    }

    mapHiddenEarlyPayouts(betslips: MyBetsBetslipBase[]): void {
        betslips.forEach((betslip) => {
            betslip.hideEP = betslip.earlyPayout && betslip.earlyPayout.state === EarlyPayoutStates.PermanentlyDisabled;
        });
    }

    mapEarlyPayoutsWithBetslip(potentialEPbetslips: MyBetsBetslipBase[], betsEpEligible: EarlyPayoutPossibility[]): EarlyPayoutsWithBetslip[] {
        return betsEpEligible
            .map((ep) => {
                return {
                    earlyPayout: ep,
                    betslip: potentialEPbetslips.find((betslip) => betslip.betslipRealId === ep.betNumber)!,
                };
            })
            .filter((match) => match.earlyPayout && match.betslip);
    }

    // For Italy labels money.service floors the amount instead of rounding it
    // For FreeBet cashouts there might be inconsistent amounts shown if cashouts are enabled on Italy
    getValueWithoutFreeBetStake(stake: number, earlyPayoutValue: number | undefined, isFreeBet: boolean): number | undefined {
        return isFreeBet && earlyPayoutValue ? this.moneyService.roundMoney(earlyPayoutValue - stake) : undefined;
    }

    // For Italy labels money.service floors the amount instead of rounding it
    // For FreeBet cashouts there might be inconsistent amounts shown if cashouts are enabled on Italy
    getValueWithFreeBetStake(stake: number, earlyPayoutValue: number, isFreeBet: boolean): number | undefined {
        return isFreeBet && earlyPayoutValue ? this.moneyService.roundMoney(earlyPayoutValue + stake) : undefined;
    }

    getEarlyPayoutErrorTypeLowerCase(type?: string, reason?: string): string | undefined {
        if (type && reason && this.isLiveSgpError(type, reason)) {
            return `${type}${reason}`.toLowerCase();
        }

        return type?.toLowerCase();
    }

    isLiveSgpError(type?: string, reason?: string): boolean {
        const typeToLowerCase = type?.toLowerCase();
        const reasonToLowerCase = reason?.toLowerCase();

        return (
            typeToLowerCase === EarlyPayoutErrorTypes.BetNotAllowedForCashout.toLowerCase() &&
            !!reasonToLowerCase &&
            this.betNotAllowedReasons.has(reasonToLowerCase)
        );
    }
}
