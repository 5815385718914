import { AfterContentInit, ContentChildren, DestroyRef, Directive, ElementRef, QueryList, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { DeviceService } from '@frontend/vanilla/core';
import { auditTime } from 'rxjs/operators';

import { TimerService } from '../common/timer.service';

@Directive({
    selector: '[msScrollTo]',
})
export class ScrollToDirective implements AfterContentInit {
    @ContentChildren('msScrollToItem') items: QueryList<any>;

    element: HTMLElement;

    private readonly destroyRef = inject(DestroyRef);

    constructor(
        private elm: ElementRef,
        private deviceService: DeviceService,
        private timer: TimerService,
    ) {
        this.element = this.elm.nativeElement;
        this.deviceService.orientation.pipe(takeUntilDestroyed()).subscribe(this.changeScroll);
    }

    ngAfterContentInit(): void {
        if (this.items.length) {
            this.changeScroll();
        }

        this.items.changes.pipe(auditTime(150), takeUntilDestroyed(this.destroyRef)).subscribe(this.changeScroll);
    }

    private changeScroll = (): void => {
        this.timer.setAnimationFrame(() => {
            if (this.element.scrollWidth > this.element.offsetWidth) {
                this.element.scrollLeft = this.element.scrollWidth;
            }
        });
    };
}
