import { BetPlacementErrorIcon } from '../bet-placement-error-icon';
import { BetslipError } from '../betslip-error';
import { ClientErrorType } from '../client-error-type';

export class RewardTokensMaxTotalOddsError extends BetslipError {
    readonly maxOdds: string;

    constructor(maxOdds: string) {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensMaxTotalOddsError;
        this.hasClientValidation = true;
        this.maxOdds = maxOdds;
    }
}
