import { Injectable, Type } from '@angular/core';

import { StylesService } from '@frontend/vanilla/core';

import { EventDetailsModule } from '../event-details/event-details.module';
import { EventModel } from '../event-model/model/event.model';
import { LoggerFactory } from '../logging/logger-factory.service';
import { ComponentLoaderService, ComponentProxy } from './component-loader.service';
import ModuleLoaderService from './module-loader.service';

export interface EventSwitcherEventViewBinding {
    event: EventModel;
}

@Injectable({ providedIn: 'root' })
export class EventSwitcherComponentLoaderService extends ComponentLoaderService<EventDetailsModule> {
    moduleName = 'EventDetailsModule';
    componentProxy: ComponentProxy<EventSwitcherEventViewBinding>;

    constructor(moduleLoader: ModuleLoaderService, loggerFactory: LoggerFactory, stylesService: StylesService) {
        super(moduleLoader, loggerFactory, stylesService);
        this.componentProxy = this.getComponentProxy<EventSwitcherEventViewBinding>((module) => module.eventSwitcherComponent);
    }

    getEventSwitcherComponent(): ComponentProxy<EventSwitcherEventViewBinding> {
        return this.componentProxy;
    }

    getModule(): Promise<Type<EventDetailsModule>> {
        return import(/* webpackChunkName: "ms-event-details" */ '../event-details/event-details.module').then((m) => m.EventDetailsModule);
    }
}
