import { EventEmitter } from '@angular/core';

import { BetBuilderProvider } from '@cds/betting-offer/add-ons';

import { EventMarketsTabBarItem } from '../event-details-common/header/dropdown-tab-bar/dropdown-tab-bar.models';
import { SitemapEDPTrackingModel } from './event-details-sitemap-tracking.service';

export interface EventDetailsSitemapBinding {
    items: EventMarketsTabBarItem[];
    showGoHome: boolean;
    trackingData: SitemapEDPTrackingModel;
    eventFinished: boolean;
    betBuilderProvider: BetBuilderProvider | null;
    toggleSitemapItem: EventEmitter<EventMarketsTabBarItem>;
}

export interface EventDetailsSitemapModel {
    key: string;
    children?: EventDetailsSitemapModel[];
}

export enum AngstromTagIdValues {
    Sgp = 'SGP',
    Popular = 'Popular',
}
