import { createSelector } from '@ngrx/store';

import { PickId } from '../../core/picks/pick-id';
import { PickOddsState } from '../../core/picks/pick-models';
import { betslipTypeStateSelector, selectSystemBetPicks } from '../types/selectors';
import { ISystemBetPickState, ISystemBetTypeStake } from './state';

export const systemBetStateSelector = createSelector(betslipTypeStateSelector, (state) => state.systemBet);
export const systemBetPicksSelector = createSelector(systemBetStateSelector, (s) => s.picks);
export const systemBetSystemInfoSelector = createSelector(systemBetStateSelector, (s) => s.systemInfo);
export const systemBetBankerEnabledSelector = createSelector(systemBetStateSelector, (s) => s.isBankerActive);
export const systemBetBankerSelector = createSelector(systemBetStateSelector, (s) => ({
    isBankerActive: s.isBankerActive,
    hasBanker: Object.values(s.picks).some((p) => p.isBanker),
}));
export const systemBetBankerCount = createSelector(systemBetStateSelector, (s) =>
    s.isBankerActive ? Object.values(s.picks).filter((p) => p.isSelected && !p.isLocked && p.isBanker).length : 0,
);

export const systemBetBarStateSelector = createSelector(systemBetStateSelector, (systemBetState) => {
    return { isBankerActive: systemBetState.isBankerActive, selectedSlipType: systemBetState.systemInfo?.key };
});

export const systemBetPickSelectorFactory = (pickId: PickId) =>
    createSelector(systemBetPicksSelector, (picks) => {
        return picks[pickId.toString()];
    });

export const systemBetTypeStakeSelectorFactory = (typeKey: string) =>
    createSelector(systemBetStateSelector, (state): ISystemBetTypeStake | null => {
        const typeStakes = state.linearTypeStakes ?? {};

        return typeStakes[typeKey] ?? null;
    });

export const systemBetLinearSlipTypeStakesSelector = createSelector(systemBetStateSelector, (s) => {
    return Object.values(s.linearTypeStakes).map((typeStake) => typeStake);
});

export const systemBetPickOddStateSelector = createSelector(systemBetStateSelector, selectSystemBetPicks, (systemBetState, pickList) => {
    const picks: { [pickId: string]: ISystemBetPickState & { isOpen: boolean } } = {};

    pickList.forEach((pick) => {
        picks[pick.id.toString()] = {
            ...systemBetState.picks[pick.id.toString()],
            isOpen: pick.oddsState === PickOddsState.Open,
        };
    });

    return picks;
});

export const systemBetStateSelectorPickOddStateSelector = createSelector(
    systemBetStateSelector,
    systemBetPickOddStateSelector,
    (systemBetState, picks) => {
        return { ...systemBetState, picks };
    },
);

export const selectIsSystemBetExpanded = createSelector(systemBetStateSelector, (state) => state.isLinearExpanded);
