import { Inject, InjectionToken, ModuleWithProviders, NgModule, Type } from '@angular/core';

import { StateSaver, _STATE_STORAGE_PERSIST_SERVICE, _STATE_STORAGE_PERSIST_SERVICES } from './state-storage.model';

@NgModule({})
export class StateStorageFeatureModule {
    constructor(
        @Inject(_STATE_STORAGE_PERSIST_SERVICES) root: Map<string, StateSaver<any, any>>,
        @Inject(_STATE_STORAGE_PERSIST_SERVICE) statePersistServices: { feature: string; stateSaver: StateSaver<any, any> }[],
    ) {
        for (const service of statePersistServices) {
            console.log('Register persist service for feature: ' + service.feature);
            root.set(service.feature, service.stateSaver);
        }
    }
}

@NgModule({})
export class StateStorageModule {
    static forFeature(
        feature: string,
        stateSaver: InjectionToken<StateSaver<any, any>> | Type<StateSaver<any, any>>,
    ): ModuleWithProviders<StateStorageFeatureModule> {
        return {
            ngModule: StateStorageFeatureModule,
            providers: [
                {
                    provide: _STATE_STORAGE_PERSIST_SERVICE,
                    multi: true,
                    useFactory: createStateSaver(feature),
                    deps: [stateSaver],
                },
            ],
        };
    }
}

function createStateSaver(feature: string): (stateSaver: StateSaver<any, any>) => { feature: string; stateSaver: StateSaver<any, any> } {
    return (stateSaver: StateSaver<any, any>) => {
        return {
            feature,
            stateSaver,
        };
    };
}
