import { Injectable } from '@angular/core';

import { hasValue } from '@frontend/sports/common/base-utils';
import { Widget } from '@frontend/sports/types/components/widget';
import { isEmpty, isNil } from 'lodash-es';
import { IPickTracking } from 'packages/sports/common/betslip/core/picks/pick-models';

import { PickSourceProvider } from '../../option-pick/pick-source.provider';
import { trackingConstants } from '../../tracking/tracking.models';
import { ModularConfigAccessorService } from './modular-config-accessor.service';

@Injectable()
export class ModularPickSourceService extends PickSourceProvider {
    constructor(private configAccessor: ModularConfigAccessorService) {
        super();
    }

    override getTracking(): { [key: string]: string } {
        return this.getBaseTracking();
    }

    override get(
        source: string,
        contentPosition?: number,
        isFallbackMarketEnabled?: boolean,
        marqueeName?: string,
        sitecoreTemplateId?: string,
        marqueeType?: string,
        trackingOptions?: { [key: string]: string },
        isAutomatedMarquee?: boolean,
    ): IPickTracking {
        const config = this.configAccessor.getWidget();
        const page = this.configAccessor.getPage();

        if (!config) {
            return super.get(source);
        }

        const baseTracking = {
            source: this.buildPath(page, config.templateName, source).toLowerCase(),
            additional: this.getBaseTracking(config),
        };

        if (!isNil(contentPosition)) {
            baseTracking.additional[trackingConstants.COMPONENT_CONTENT_POSITION] = contentPosition.toString();
        }

        if (!isNil(isAutomatedMarquee)) {
            baseTracking.additional[trackingConstants.MARQUEE_CONTENT_LOGIC] = isAutomatedMarquee ? 'default - automated' : 'default';
        } else if (!isNil(isFallbackMarketEnabled)) {
            baseTracking.additional[trackingConstants.MARQUEE_CONTENT_LOGIC] = isFallbackMarketEnabled ? 'fallback' : 'default';
        }

        if (!isNil(marqueeName)) {
            baseTracking.additional[trackingConstants.MARQUEE_NAME] = marqueeName;
        }

        if (!isNil(sitecoreTemplateId)) {
            baseTracking.additional[trackingConstants.SITECORE_TEMPLATE_ID] = sitecoreTemplateId;
        }

        if (!isNil(marqueeType)) {
            baseTracking.additional[trackingConstants.MARQUEE_TYPE] = marqueeType;
        }

        return baseTracking;
    }

    private getBaseTracking(config?: Readonly<Widget<unknown>>): { [key: string]: string } {
        config = config || this.configAccessor.getWidget();
        const parentWidget = this.configAccessor.getParentWidget();

        let baseTracking = {};

        if (config) {
            baseTracking = Object.assign(baseTracking, config.trackingData, {
                [trackingConstants.COMPONENT_MODULE_NAME]: config.type,
                [trackingConstants.COMPONENT_MODULE_POSITION]: `${config.location}|${config.order}`,
                [trackingConstants.COMPONENT_MODULE_CUSTOM_NAME]: config.templateName,
                [trackingConstants.COMPONENT_MODULE_SOURCE]: isNil(parentWidget) ? 'standard module' : `composable|${parentWidget.templateName}`,
            });
        }

        const layoutTemplate = this.configAccessor.getLayoutTemplate();
        const page = this.configAccessor.getPage();
        if (layoutTemplate) {
            baseTracking = Object.assign(baseTracking, {
                [trackingConstants.COMPONENT_PAGE_LAYOUT]: this.buildPath(layoutTemplate.folder, page, layoutTemplate.name),
            });
        }

        return !isEmpty(baseTracking) ? baseTracking : super.getTracking();
    }

    private buildPath(...params: unknown[]): string {
        return params.filter(hasValue).join('/');
    }
}
