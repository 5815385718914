import { Inject, Injectable } from '@angular/core';

import { BetslipUpsellFixtures } from '@cds/betting-offer/domain-specific/betslip-upsell';
import { BetslipUpsellFixtureRequest } from '@cds/query-objects';
import { Observable } from 'rxjs';

import { BaseCdsApi } from './cds-api.service';
import { BaseCdsApiFactory, CDS_API_FACTORY } from './cds-api.service.factory';

@Injectable({ providedIn: 'root' })
export class BetslipUpsellApi {
    private cdsApi: BaseCdsApi;

    constructor(@Inject(CDS_API_FACTORY) cdsApiServiceFactory: BaseCdsApiFactory) {
        this.cdsApi = cdsApiServiceFactory({ endpoint: '/betslipupsell' });
    }

    getBetslipUpsellData(request: BetslipUpsellFixtureRequest): Observable<BetslipUpsellFixtures | undefined> {
        return this.cdsApi.get<BetslipUpsellFixtures>(`/fixtures`, request);
    }
}
