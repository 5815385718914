import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { CrmPromotionsBanners } from '@frontend/sports/types/components/sitecore';

import { OfferStatus, Promotion } from '../crm-offer-data/crm-offer.model';

@Component({
    selector: 'ms-crm-promotion-status-button',
    templateUrl: './promotion-status-button.component.html',
    standalone: true,
})
export class PromotionStatusButtonComponent {
    translations: CrmPromotionsBanners;
    offersStatusEnum = OfferStatus;

    @Input() promotion: Promotion;
    @Output() buttonClick = new EventEmitter<Event>();

    constructor(sitecore: Sitecore) {
        this.translations = sitecore.crmPromotionsBanners;
    }

    get offerStatus(): OfferStatus {
        return this.promotion.status;
    }

    onButtonClick(event: Event): void {
        this.buttonClick.emit(event);
    }
}
