import { Injectable } from '@angular/core';

import { Fixture, FixtureStage, FixtureType, OfferCategory } from '@cds/betting-offer';
import { BatchRequest, FixtureRequest, FixtureState, OfferMapping, ScoreboardMode } from '@cds/query-objects';
import { ScoreboardSlim } from '@cds/scoreboards/v1';
import { StringDictionary, isDefined, objectEntries } from '@frontend/sports/common/base-utils';
import { FavouritesConfig } from '@frontend/sports/common/client-config-data-access';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { MediaQueryService } from '@frontend/vanilla/core';
import { groupBy, isEmpty, pickBy, sortBy } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { BatchResponse, BettingOfferApi } from '../cds/betting-offer-api.service';
import { mainLiveEventsSwitchEvent } from '../cds/cds-dispatcher-events';
import { CdsMainLiveService } from '../cds/cds-main-live.service';
import { FixtureList } from '../event-list-shared/sport/competitions/competition.models';
import { FixtureFactory } from '../event-model/mappers/fixture.factory';
import { PairGamePeriod } from '../event-model/model/event.model';
import { StatisticsConfigService } from '../statistics/statistics-config.service';
import { UserService } from '../user/services/user.service';
import { FavouritesService } from './favourites.service';
import { isFixtureFavourite } from './helpers';
import { LiveEventsSubscriptionService } from './live-events-subscription.service';

@Injectable({ providedIn: 'root' })
export class LiveFavouriteFixturesService {
    private fixtures$ = new BehaviorSubject<Fixture[]>([]);

    get change(): Observable<Fixture[]> {
        return this.fixtures$;
    }

    get fixtures(): Fixture[] {
        return this.fixtures$.getValue();
    }

    get liveFixtures(): Fixture[] {
        return this.fixtures.filter((fixture) => fixture.stage === FixtureStage.Live);
    }

    constructor(
        private userService: UserService,
        private favouritesConfig: FavouritesConfig,
        private bettingOfferApi: BettingOfferApi,
        private favouriteService: FavouritesService,
        private subscriptionsService: LiveEventsSubscriptionService,
        private fixtureFactory: FixtureFactory,
        private media: MediaQueryService,
        private dispatcher: DispatcherService,
        private mainLiveService: CdsMainLiveService,
        private statisticsConfigService: StatisticsConfigService,
    ) {
        const get = () => this.refresh(OfferMapping.None);

        this.dispatcher
            .on(mainLiveEventsSwitchEvent)
            .pipe(
                map((payload) => ({ events: payload.events, favourites: this.favouriteService.getFixtureFavourites() })),
                filter((payload) => !isEmpty(payload.favourites) && this.favouritesConfig.isLiveFavouritesEnabled),
                tap((payload) => this.favouriteService.updateLiveIds(payload.events)),
                map((payload) => pickBy(payload.events, (_, key) => payload.favourites.includes(key)) as StringDictionary<string>),
                tap((transientFavourites) => this.unsubscribe(Object.keys(transientFavourites))),
                mergeMap((transientFavourites) => this.getFixtures$(Object.values(transientFavourites))),
                takeUntil(this.userService.onUserLogout$),
            )
            .subscribe((fixtures) => {
                const expiredFixtures = this.fixtures.filter(this.isFinished).map((f) => f.id);
                this.removeExpired(expiredFixtures);
                this.subscribeToPush(fixtures);

                const favoriteFixtures = this.favouriteService.getFixtureFavourites();
                const existingFavorites = this.fixtures.filter((existing) => favoriteFixtures.includes(existing.id));
                existingFavorites.push(...fixtures);

                this.fixtures$.next(existingFavorites);
            });

        this.userService.onUserLogin$.subscribe(get);

        this.subscriptionsService.updatedEvents$.subscribe((updatedEvents) => {
            const finishedFavourites = updatedEvents
                .filter((event) => event.scoreboard.isFinished)
                .map((event) =>
                    this.favouriteService.favouritesList.find((favourite) => isFixtureFavourite(favourite) && favourite.itemId === event.id),
                )
                .filter(isDefined);

            this.subscriptionsService.unsubscribe(finishedFavourites.map((f) => f.itemId));
            this.favouriteService.remove(finishedFavourites).subscribe();
        });

        get();
    }

    isFavourited(id: string): boolean {
        return this.liveFixtures.some((f) => f.id === id);
    }

    isSwipeFavouriteEnabled(): boolean {
        return this.favouritesConfig.isFavouriteSwipeEnabled && this.media.isActive('lt-md');
    }

    isLiveFavouriteEnabled(): boolean {
        return this.favouritesConfig.isLiveFavouritesEnabled && !this.media.isActive('lt-md');
    }

    refresh(offerMapping?: OfferMapping, offerCategories?: OfferCategory): void {
        const fixtureFavsIds = this.favouritedListToRefresh();

        if (!this.favouritesConfig.isLiveFavouritesEnabled || !fixtureFavsIds.length) {
            this.fixtures$.next([]);

            return;
        }

        this.getFixturesById(fixtureFavsIds, offerMapping, offerCategories).subscribe((batchFixtures: BatchResponse | undefined) => {
            if (!batchFixtures) {
                this.fixtures$.next([]);

                return;
            }

            const expiredFixtureIds = this.getExpiredFixtures(batchFixtures);
            const mainLiveTransitionIds = this.getMainLiveTransitions(batchFixtures);
            const fixtureList = this.getFixtures(batchFixtures);

            this.favouriteService.updateLiveIds(mainLiveTransitionIds);
            this.removeExpired(expiredFixtureIds);
            this.subscribeToPush(fixtureList);
            this.unsubscribe(Array.from(Object.keys(mainLiveTransitionIds)));

            this.fixtures$.next(fixtureList);
        });
    }

    getFixtureList(groupByCompetition: boolean = false): FixtureList[] {
        const groupedFixtures = groupBy(this.fixtures, (favourite) =>
            groupByCompetition ? favourite.competition.parentLeagueId || favourite.competition.id : favourite.sport.id,
        );

        const result = objectEntries(groupedFixtures).map((entry) => ({
            fixtures: entry.value.sort((a, b) => (a.startDate > b.startDate ? 1 : -1)),
            totalCount: entry.value.length,
        }));

        return sortBy(result, (current) => current.fixtures[0].sport.id);
    }

    updateFixtureList(): void {
        const current = this.favouritedList();
        const filtered = this.fixtures.filter((fixture) => current.some((id) => fixture.id === id));

        this.fixtures$.next(filtered);
    }

    private getFixtures$(fixtures: string[]): Observable<Fixture[]> {
        const request: FixtureRequest = {
            fixtureIds: Object.values(fixtures).join(','),
            offerMapping: OfferMapping.Filtered,
            state: FixtureState.Latest,
        };

        return this.mainLiveService.getFixtures$(request);
    }

    private isFinished(fixture: Fixture | undefined): boolean {
        if (!fixture) {
            return true;
        }

        const scoreboardResponse = fixture.scoreboard as ScoreboardSlim;

        return scoreboardResponse?.periodId === PairGamePeriod.Finished;
    }

    private subscribeToPush(list: Fixture[]): void {
        list.filter((fixture) => !this.subscriptionsService.isSubscribed(fixture.id))
            .map((fixture) => this.fixtureFactory.create(fixture))
            .forEach((event) => this.subscriptionsService.subscribe(event));
    }

    private unsubscribe(fixtureIds: string[]): void {
        this.subscriptionsService.unsubscribe(fixtureIds);
    }

    private removeExpired(expiredFixtures: string[]): void {
        const expiredFavourites = this.favouriteService.favouritesList.filter((favourite) =>
            expiredFixtures.some((exId) => favourite.itemId === exId),
        );

        this.favouriteService.remove(expiredFavourites).subscribe();
    }

    private favouritedList(): string[] {
        return this.favouriteService.favouritesList.filter((f) => isFixtureFavourite(f) && f.count > 0).map(({ itemId }) => itemId);
    }

    private favouritedListToRefresh(): string[] {
        return this.favouriteService.favouritesList.filter(isFixtureFavourite).map(({ itemId }) => itemId);
    }

    private getExpiredFixtures(batchFixtures: BatchResponse): string[] {
        const expiredFixtures: string[] = [];

        objectEntries(batchFixtures).forEach((batchEntry) => {
            if (!batchEntry.value.fixtures.length) {
                expiredFixtures.push(batchEntry.key);
            } else {
                const fixture = batchEntry.value.fixtures[0];

                if (fixture.scoreboard) {
                    const scoreboardResponse = fixture.scoreboard as ScoreboardSlim;
                    const isFinished = scoreboardResponse.periodId === 255;
                    if (isFinished) {
                        expiredFixtures.push(batchEntry.key);
                    }
                }
            }
        });

        return expiredFixtures;
    }

    private getMainLiveTransitions(batchFixtures: BatchResponse): StringDictionary<string> {
        const mainLiveTransitionIds: StringDictionary<string> = {};

        objectEntries(batchFixtures)
            .filter((entry) => entry.value.fixtures.length && entry.key !== entry.value.fixtures[0].id)
            .forEach((entry) => (mainLiveTransitionIds[entry.key] = entry.value.fixtures[0].id));

        return mainLiveTransitionIds;
    }

    private getFixtures(batchFixtures: BatchResponse): Fixture[] {
        return objectEntries(batchFixtures)
            .filter((entry) => entry.value.fixtures.length)
            .map((entry) => entry.value.fixtures[0]);
    }

    private getFixturesById(
        fixtureIds: string[],
        offerMapping?: OfferMapping,
        offerCategories?: OfferCategory,
    ): Observable<BatchResponse | undefined> {
        const request: BatchRequest<FixtureRequest>[] = fixtureIds.map((fixtureId) => ({
            batchId: fixtureId,
            request: {
                fixtureIds: fixtureId,
                fixtureTypes: FixtureType.Standard,
                state: FixtureState.Latest,
                offerMapping,
                offerCategories,
                scoreboardMode: ScoreboardMode.Slim,
                excludeLocationFilter: true,
                statisticsModes: this.statisticsConfigService.getStatisticsModesBySportId(undefined),
            },
        }));

        return this.bettingOfferApi.getBatchFixtureList(request);
    }
}
