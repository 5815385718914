import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable, ReplaySubject, throwError } from 'rxjs';
import { mergeMap, retryWhen } from 'rxjs/operators';

import { Logger } from '../../logging/logger';
import { UrlService } from '../../navigation/url.service';
import { RememberMeLoginService } from '../remember-me-login.service';
import { RememberMeService } from '../remember-me.service';

export function rememberMeLoginHttpInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
    const rememberMeService = inject(RememberMeService);
    const rememberMeLoginService = inject(RememberMeLoginService);
    const urlService = inject(UrlService);
    const log = inject(Logger);
    let loginCall: ReplaySubject<HttpErrorResponse | null> | null = null;

    if (!urlService.parse(req.url).isSameTopDomain || !rememberMeService.tokenExists()) {
        return next(req);
    }

    return next(req).pipe(
        retryWhen((errors: Observable<any>) =>
            errors.pipe(
                mergeMap((originalError: HttpErrorResponse, errorIndex: number) => {
                    if (originalError.status === 401 && errorIndex === 0) {
                        if (loginCall === null) {
                            loginCall = new ReplaySubject<HttpErrorResponse | null>();

                            rememberMeLoginService.loginWithToken().subscribe(
                                () => {
                                    log.infoRemote(`LOGIN_INFO Login by remember-me token on session expiration was successful. ${req.url}`);
                                    loginCall?.next(null);
                                },
                                (loginError) => {
                                    log.errorRemote(
                                        'Failed login by remember-me token on session expiration. User gets unauthenticated.',
                                        loginError,
                                    );
                                    loginCall?.error(originalError);
                                },
                                () => {
                                    loginCall?.complete();
                                    loginCall = null;
                                },
                            );
                        }

                        return loginCall.asObservable();
                    }

                    return throwError(() => originalError);
                }),
            ),
        ),
    );
}
