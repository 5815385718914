import { Injectable, InjectionToken } from '@angular/core';

import { FixtureViewGroupingConfiguration, OfferGroup } from '@cds/betting-offer/grouping/fixture-view';
import { GridViewGroupingConfiguration } from '@cds/betting-offer/grouping/grid-view';
import { ExpiringCache } from '@frontend/sports/common/base-utils';
import { LocalStoreService } from '@frontend/vanilla/core';
import { difference, isEqual, now, startsWith } from 'lodash-es';
import { Observable, combineLatest, filter, forkJoin, iif, of } from 'rxjs';
import { defaultIfEmpty, map, switchMap, tap } from 'rxjs/operators';

import { ExpiringCacheFactory } from '../common/expiring-cache.factory';
import { EnhancedFixtureViewGroupingConfiguration, EventDetailsTags, EventModel } from '../event-model/model/event.model';
import { ApiBaseSettingsProvider } from './api-settings.service';
import { OfferGroupingApi } from './offer-grouping-api.service';

export const LOCAL_STORAGE = new InjectionToken<Storage>('Local Storage', {
    factory: () => localStorage,
});

@Injectable({ providedIn: 'root' })
export class OfferGroupingService {
    private readonly sportGrouping = 'grouping-sport';
    private readonly gridGrouping = 'grouping-grid';
    private readonly groupingLanguage = 'grouping-lang';

    private readonly cache: ExpiringCache;
    private cacheNull: any = { null: null };
    private readonly lang: string;
    private invalidGridTimestamp: number | null = null;
    private readonly invalidGridTtl: number = 30 * 1000;

    constructor(
        private api: OfferGroupingApi,
        private storage: LocalStoreService,
        private apiBaseSettings: ApiBaseSettingsProvider,
        private expiringCacheFactory: ExpiringCacheFactory,
    ) {
        this.cache = this.expiringCacheFactory.create(600);
        this.lang = this.apiBaseSettings.getSettings().lang;
        const groupingStorageKeys = this.getGroupingStorageKeys();
        if (this.isValidGroupingLang) {
            this.initCacheFromStorage(groupingStorageKeys);
        } else {
            this.clearStorageGroupingData(groupingStorageKeys);
        }
    }

    getFixtureGrouping(sportId: number, groupingVersion?: string | 'any'): Observable<EnhancedFixtureViewGroupingConfiguration | undefined> {
        const key = `${this.sportGrouping}-${sportId}`;

        return this.cache.get<EnhancedFixtureViewGroupingConfiguration>(key).pipe(
            switchMap((cacheConfiguration) => {
                if (this.isValidGroupingId(cacheConfiguration, groupingVersion)) {
                    return of(cacheConfiguration);
                }

                return this.getFixtureGroupingFromApi(sportId, key, groupingVersion);
            }),
        );
    }

    getFixtureGroupingForEventModel(eventModel: EventModel): Observable<EnhancedFixtureViewGroupingConfiguration> {
        return this.getFixtureGrouping(eventModel.sport.id, 'any').pipe(
            map((grouping) => {
                if (!grouping) {
                    throw new Error(`Unable to load fixture grouping for the fixture id: ${eventModel.id}`);
                }

                return grouping;
            }),
        );
    }

    getGridGrouping(sportId: number): Observable<GridViewGroupingConfiguration | undefined> {
        const key = this.gridGrouping;
        const source = this.api.getGridGrouping().pipe(tap((current) => this.set(key, current)));

        return this.cache.getOrCreate(key, source).pipe(
            switchMap((value) => {
                // Drop invalid cache entry after its TTL is reached.
                if (this.invalidGridTimestamp !== null && this.invalidGridTimestamp + this.invalidGridTtl < now()) {
                    this.cache.remove(key);
                    this.invalidGridTimestamp = null;

                    return this.getGridGrouping(sportId);
                }
                if (!value && this.invalidGridTimestamp === null) {
                    this.invalidGridTimestamp = now();
                }

                return value ? of(value.find((group) => group.sportId === sportId)) : of(undefined);
            }),
        );
    }

    private getFixtureGroupingFromApi(
        sportId: number,
        key: string,
        groupingVersion?: string | 'any',
    ): Observable<EnhancedFixtureViewGroupingConfiguration | undefined> {
        return this.api.getFixtureGrouping(sportId).pipe(
            switchMap((grouping) => {
                if (this.isValidGroupingId(grouping, groupingVersion)) {
                    return this.cache.create(key, of(this.enhanceGrouping(grouping!)));
                }

                return of(grouping ? this.enhanceGrouping(grouping) : grouping);
            }),
            tap((grouping) => {
                if (this.isValidGroupingId(grouping, groupingVersion)) {
                    this.set(key, grouping);
                }
            }),
        );
    }

    private enhanceGrouping(grouping: FixtureViewGroupingConfiguration): EnhancedFixtureViewGroupingConfiguration {
        const apbGroups: OfferGroup[] = [];
        const preCreatedGroups: OfferGroup[] = [];

        for (const group of grouping.marketGroups) {
            const offerGroup: OfferGroup = this.createOfferGroup(group, EventDetailsTags.PriceBoost);
            const offerGroupBAB: OfferGroup = this.createOfferGroup(group, EventDetailsTags.PreCreatedBab);

            if (offerGroup.marketGroupItems.length > 0 || offerGroup.tv1MarketGroupItems.length > 0) {
                apbGroups.push(offerGroup);
            }

            if (offerGroupBAB.tv1MarketGroupItems.length > 0 || offerGroupBAB.marketGroupItems.length > 0) {
                preCreatedGroups.push(offerGroupBAB);
            }
        }

        return {
            ...grouping,
            preCreatedGroups,
            apbGroups,
        };
    }

    private createOfferGroup(group: OfferGroup, tag: string): OfferGroup {
        const offerGroup: OfferGroup = {
            tv1MarketGroupItems: group.tv1MarketGroupItems.filter((item) => item.tags.some((x) => x.key === tag)),
            marketGroupItems: group.marketGroupItems.filter((item) => item.tags.some((x) => x.key === tag)),
            groupId: group.groupId,
            id: group.id,
            name: group.name,
            tag: group.tag,
        };

        return offerGroup;
    }

    getFixtureGroupingForSports(
        sportIds: number[],
        groupingVersion?: string | 'any',
    ): Observable<EnhancedFixtureViewGroupingConfiguration[] | undefined> {
        return forkJoin(
            sportIds.map((id) =>
                this.cache.get<EnhancedFixtureViewGroupingConfiguration>(`${this.sportGrouping}-${id}`).pipe(
                    filter((c) => this.isValidGroupingId(c, groupingVersion)),
                    map((c) => c!),
                ),
            ),
        ).pipe(
            // in case none of them are cached yet, continue with empty
            defaultIfEmpty([]),
            switchMap((validConfigs) => {
                const missingConfigSportIds = difference(
                    sportIds,
                    validConfigs.map((c) => c.sportId),
                );

                return combineLatest([
                    of(validConfigs),
                    iif(() => !!missingConfigSportIds.length, this.fetchAndCacheFixtureGroupings(missingConfigSportIds, groupingVersion), of([])),
                ]);
            }),
            map(([cachedConfigs, fetchedConfigs]) => [...cachedConfigs, ...fetchedConfigs]),
        );
    }

    private fetchAndCacheFixtureGroupings(
        sportIds: number[],
        groupingVersion?: string | 'any',
    ): Observable<EnhancedFixtureViewGroupingConfiguration[]> {
        return this.api.getFixtureGroupingBatch(sportIds).pipe(
            switchMap((groupings) =>
                forkJoin(
                    groupings?.map((g) => {
                        if (this.isValidGroupingId(g, groupingVersion)) {
                            return this.cache.create(`${this.sportGrouping}-${g.sportId}`, of(this.enhanceGrouping(g!)));
                        }

                        return of(g ? this.enhanceGrouping(g) : g);
                    }) ?? [],
                ),
            ),
            tap((fetchGroupings) =>
                fetchGroupings.forEach((fg) => {
                    if (this.isValidGroupingId(fg, groupingVersion)) {
                        this.set(`${this.sportGrouping}-${fg.sportId}`, fg);
                    }
                }),
            ),
        );
    }

    private isValidGroupingId(groupingConfiguration: FixtureViewGroupingConfiguration | undefined, groupingVersion?: string | 'any'): boolean {
        return !!(groupingConfiguration && (groupingVersion === 'any' || groupingVersion === groupingConfiguration.version));
    }

    private set<T>(key: string, value: T | undefined | null): void {
        this.storage.set(key, value || this.cacheNull);
    }

    private get<T = any>(key: string): T | undefined {
        const value = this.storage.get(key);

        if (isEqual(value, this.cacheNull)) {
            return undefined;
        }

        return value as T;
    }

    private remove(key: string): void {
        this.storage.remove(key);
    }

    private getGroupingStorageKeys(): string[] {
        const keyPrefixes = [this.gridGrouping, this.sportGrouping];

        return this.storage.keys().filter((key) => keyPrefixes.some((current) => startsWith(key, current)));
    }

    private get isValidGroupingLang(): boolean {
        return this.get(this.groupingLanguage) === this.lang;
    }

    private clearStorageGroupingData(groupingStorageKeys: string[]): void {
        groupingStorageKeys.forEach((key) => this.remove(key));
        this.set(this.groupingLanguage, this.lang);
    }

    private initCacheFromStorage(groupingStorageKeys: string[]): void {
        groupingStorageKeys.forEach((key) => {
            this.cache.getOrCreate(key, of(this.get(key)));
        });
    }
}
