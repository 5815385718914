import { Injectable } from '@angular/core';

import { Nullable } from '@frontend/sports/common/base-utils';
import { Observable, Subject } from 'rxjs';

import { NumpadHostOptions } from './model';

@Injectable({ providedIn: 'root' })
export class NumpadDisplayService {
    private _open = new Subject<NumpadHostOptions>();
    private _close = new Subject<void>();
    private _isOpen = false;
    private _openedNumpad: Nullable<string> = null;

    get open$(): Observable<NumpadHostOptions> {
        return this._open.asObservable();
    }

    get close$(): Observable<void> {
        return this._close.asObservable();
    }

    open(options: NumpadHostOptions): void {
        this._openedNumpad = options.id;
        this._isOpen = true;

        this._open.next(options);
    }

    close(): void {
        if (!this._isOpen) {
            return;
        }

        this._openedNumpad = null;
        this._isOpen = false;

        this._close.next();
    }

    isOpen(id?: string): boolean {
        if (!id) {
            return this._isOpen;
        }

        return this._isOpen && id === this._openedNumpad;
    }

    tryClose(id: string): void {
        if (this.isOpen(id)) {
            this.close();
        }
    }
}
