import { Injectable } from '@angular/core';

import {
    CountItem,
    ItemType,
    LeagueItem,
    VirtualCompetitionDisplayMode,
    VirtualCompetitionGroupItem,
    VirtualCompetitionImageType,
    children,
    isDefined,
} from '@frontend/sports/common/base-utils';
import { SitecoreEventDetailsConfig } from '@frontend/sports/common/client-config-data-access';
import { Observable, merge, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { getItemTypeForSport } from '../../competition-list/competition-list.models';
import { CompetitionRouteService } from '../../competition-list/competition-route.service';
import { FavouriteType } from '../../favourites/favourites.model';
import { FavouriteChangeType, FavouritesService } from '../../favourites/favourites.service';
import { SportUrlParam, UrlHelperService } from '../../navigation-core/url-helper.service';
import { CompetitionRoute } from '../../navigation/navigation.models';
import { TournamentGroup } from './tournament-groups.component';

export interface VirtualCompetitionModel {
    groups: TournamentGroup[];
    sportId: number;
    displayMode: VirtualCompetitionDisplayMode;
    imageType: VirtualCompetitionImageType;
    id: number;
    realCompetitionId?: number;
}

@Injectable({ providedIn: 'root' })
export class VirtualCompetitionService {
    constructor(
        private urlHelperService: UrlHelperService,
        private favouritesService: FavouritesService,
        private sitecoreEventDetails: SitecoreEventDetailsConfig,
        private routeService: CompetitionRouteService,
    ) {}

    subscribe$(
        sport: CountItem,
        virtualCompetition: LeagueItem,
        urlFactory?: (group?: VirtualCompetitionGroupItem) => string,
    ): Observable<VirtualCompetitionModel | null> {
        return this.sitecoreEventDetails.whenReady.pipe(
            switchMap(() =>
                merge(
                    of(undefined),
                    this.favouritesService.change.pipe(
                        filter((change) => change.changeType === FavouriteChangeType.Add || change.changeType === FavouriteChangeType.Remove),
                    ),
                ).pipe(map(() => this.buildVirtualCompetition(sport, virtualCompetition, urlFactory))),
            ),
        );
    }

    getCompetition(sport: CountItem | undefined, route: CompetitionRoute, findVirtual = true): LeagueItem | undefined {
        if (!sport) {
            return;
        }

        const region = sport.children.find(
            (item) => (item.type === ItemType.Region || item.type === ItemType.Tournament) && item.id === route.region,
        );
        if (region) {
            const leagueList = region.children as LeagueItem[];

            return leagueList.find(
                (item: LeagueItem) => item.type === ItemType.Competition && item.id === route.league && item.isVirtual === findVirtual,
            );
        }

        return;
    }

    private buildVirtualCompetition(
        sport: CountItem,
        virtualCompetition: LeagueItem,
        urlFactory?: (group?: VirtualCompetitionGroupItem) => string,
    ): VirtualCompetitionModel | null {
        const virtualCompetitionGroups = this.buildTournamentGroups(sport, virtualCompetition, urlFactory);
        if (virtualCompetitionGroups.length === 0) {
            return null;
        }

        return {
            groups: virtualCompetitionGroups,
            sportId: sport.id,
            displayMode: virtualCompetition.displayMode!,
            imageType: virtualCompetition.imageType!,
            id: virtualCompetition.id,
            realCompetitionId: virtualCompetition.realCompetitionId,
        };
    }

    private buildTournamentGroups(
        sport: CountItem,
        virtualCompetition: LeagueItem,
        urlFactory?: (group?: VirtualCompetitionGroupItem) => string,
    ): TournamentGroup[] {
        const route = this.routeService.params();
        if (!(route.isVirtual && virtualCompetition.children.length > 0)) {
            return [];
        }

        const regionType = getItemTypeForSport(sport.id);
        const region = children(sport, regionType).find((item) => item.id === route.region);

        if (!region) {
            return [];
        }

        const league = children(sport, ItemType.Competition).pop() as LeagueItem;

        const allGroups = [
            <TournamentGroup>{
                id: 0,
                title: this.sitecoreEventDetails.eventDetails.AllMarketsTemplate,
                active: route.virtualCompetitionGroup === undefined,
                url: urlFactory?.() ?? this.urlHelperService.getSportUrl(sport, SportUrlParam.Betting, region, league, undefined, route.marketOffer),
                canBeFavourited: false,
            },
        ];
        const virtualGroups = virtualCompetition.children as VirtualCompetitionGroupItem[];
        let tournamentGroups = virtualGroups.map<TournamentGroup>((group) => {
            const isFavourite = group.siblings.length
                ? isDefined(this.favouritesService.get(group.siblings[0].toString(), sport.id, FavouriteType.League))
                : false;

            return {
                id: group.id,
                favouriteId: group.siblings[0],
                title: group.name,
                active: group.id === route.virtualCompetitionGroup,
                isFavourite,
                favouriteName: `${league.name} - ${group.name}`,
                url: urlFactory?.(group) ?? this.urlHelperService.getSportUrl(sport, SportUrlParam.Betting, region, league, group, route.marketOffer),
                participants: group.participants,
                canBeFavourited: !(group.stageIds.length || group.groupIds.length),
            };
        });

        const tournamentGroupsFavourite = <TournamentGroup[]>this.favouritesService.favouritesList
            .filter((f) => f.type === FavouriteType.League)
            .map((f) => tournamentGroups.find((g) => g.canBeFavourited && g.favouriteId?.toString() === f.itemId))
            .filter((f) => isDefined(f));
        tournamentGroups = tournamentGroupsFavourite.concat(tournamentGroups.filter((f) => !f.isFavourite));

        return allGroups.concat(tournamentGroups);
    }
}
