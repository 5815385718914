<div
    class="stake-field-container"
    [ngClass]="{ disabled: disabled, selected: selected }"
    (click)="!disabled && onClick()"
    *ngIf="this.numpadService.state$ | async as state">
    <i class="theme-error-i"></i>
    <span *ngIf="adornmentStart" class="stake-field-adornment stake-field-adornment--start">{{ adornmentStart }}</span>
    <div class="stake-field-scroll-container">
        <span class="stake-field-value" [ngClass]="{ 'empty-stake': state.stake.isEmpty }">
            <!-- 
				is there any difference ? why not use msCurrency for both anyway ? 
				can you please explain
			-->
            {{ getStake(state) }}
        </span>
    </div>
</div>
