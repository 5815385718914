import { Injectable } from '@angular/core';

import { uniqBy } from 'lodash-es';

import { DetailedGroupingFactory } from '../event-model/mappers/detailed-grouping.factory';
import { EventOptionGroup } from '../event-model/model/event.model';
import { Group } from './grid.model';

@Injectable({ providedIn: 'root' })
export class CompactEventService {
    constructor(private groupingFactory: DetailedGroupingFactory) {}

    prepareEventOptionGroups(eventOptionGroups: EventOptionGroup[], groups?: Group[]): EventOptionGroup[] {
        let optionGroups = this.distinctOptionGroups(eventOptionGroups);
        optionGroups = this.groupingFactory.groupMarkets(optionGroups);

        if (groups) {
            const activeGroups = groups.filter((group) => group.active);

            return optionGroups.filter((optionGroup) => activeGroups.find((activeGroup) => optionGroup.hasGroup(activeGroup.id)));
        } else {
            return optionGroups;
        }
    }

    private distinctOptionGroups(optionGroups: EventOptionGroup[]): EventOptionGroup[] {
        return uniqBy(optionGroups, (optionGroup) => optionGroup.id);
    }
}
