import { Inject, Injectable } from '@angular/core';

import { ISavedState, StateSaver, _STATE_STORAGE_PERSIST_SERVICES } from './state-storage.model';

@Injectable({ providedIn: 'root' })
export default class StateConverterService {
    constructor(@Inject(_STATE_STORAGE_PERSIST_SERVICES) private statePersistServices: Map<string, StateSaver<any, any>>) {}

    convert(state: any): ISavedState {
        return Object.entries(state)
            .map(([key, value]) => ({ feature: key, state: value }))
            .reduce((aggregate, current) => {
                const service = this.statePersistServices.get(current.feature);
                if (service) {
                    aggregate[current.feature] = service.save(current.state);
                }

                return aggregate;
            }, {} as ISavedState);
    }
}
