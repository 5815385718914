import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import {
    DetailedGrouping,
    DisplayType,
    Fixture,
    Game,
    GroupingInfo,
    Option,
    OptionMarket,
    OptionMarketStatus,
    OptionStatus,
    OrderType,
    Parameter,
    ParameterType,
    Participant,
    Translation,
} from '@cds/betting-offer';
import { BettingInsightsModel } from '@cds/betting-offer/betting-insights';
import { SplitFixture } from '@cds/betting-offer/domain-specific';
import { BetBuilderFixture } from '@cds/betting-offer/domain-specific/bet-builder';
import { ScoreboardSlim } from '@cds/scoreboards/v1';
import { PlayerStats } from '@cds/statistics/player';
import { SportConstant, getSourceEventId } from '@frontend/sports/common/base-utils';
import { BetBuilderConfig, EventDetailsConfig, PriceBoostConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { NativePrice } from '@frontend/sports/common/odds-lib';
import { NativeAppService } from '@frontend/vanilla/core';
import { cloneDeep, flatten } from 'lodash-es';

import {
    EnhancedFixtureViewGroupingConfiguration,
    EventDetailsTags,
    EventModel,
    EventOptionGroup,
    ExtendedDisplayType,
    PRECREATED_BAB_EMPTY_THEME_ID,
} from '../model/event.model';
import { Tv2MarketParameters } from '../model/tv2.model';
import { CommonFixtureFactory } from './common-fixture.factory';
import { DetailedFixtureMarket, DetailedFixtureMarketFactory } from './detailed-fixture-market.factory';
import { DetailedGroupingFactory } from './detailed-grouping.factory';
import { EventSitemapGroupingFactory } from './event-sitemap-grouping.factory';
import { LinkedFixtureService } from './linked-fixture.service';

export interface EventDetailsParameters {
    fixture: Fixture;
    splitFixtures: SplitFixture[];
    linkedFixture?: Fixture;
    grouping: EnhancedFixtureViewGroupingConfiguration;
    precreated?: BetBuilderFixture;
    isPriceBoosted?: boolean;
    loadEventSitemap?: boolean;
    bettingInsights?: BettingInsightsModel;
    excludePriceboostedMarketGrouping?: boolean;
}

export interface MarketGroupDetails {
    groupId: string;
    groupItemId: string | undefined;
}

@Injectable({ providedIn: 'root' })
export class DetailedCreateFactory {
    constructor(
        private fixtureFactory: CommonFixtureFactory,
        private marketFactory: DetailedFixtureMarketFactory,
        private groupingFactory: DetailedGroupingFactory,
        private eventSitemapGroupingFactory: EventSitemapGroupingFactory,
        private eventDetailsConfig: EventDetailsConfig,
        public sitecore: Sitecore,
        public betBuilderConfig: BetBuilderConfig,
        public linkedFixtureService: LinkedFixtureService,
        private nativeAppService: NativeAppService,
        private priceBoostConfig: PriceBoostConfig,
    ) {}

    create(eventParameters: EventDetailsParameters): EventModel {
        const event = this.fixtureFactory.create(eventParameters.fixture);
        const optionGroups = this.createOptionGroups(
            eventParameters.fixture,
            eventParameters.splitFixtures,
            eventParameters.linkedFixture,
            eventParameters.grouping,
            eventParameters.precreated,
            eventParameters.isPriceBoosted,
            eventParameters.excludePriceboostedMarketGrouping,
        );
        const betBuilderId = event.getBetBuilderIdByStage();
        const optionSets = this.groupingFactory.getOptionSets(optionGroups, eventParameters.grouping, eventParameters.fixture.source, betBuilderId);

        this.setParticipantsShirtColor(event, eventParameters.fixture);

        event.marketsDictionary = this.createMarketsDictionary(optionGroups);
        event.splitFixtureIds = (eventParameters.splitFixtures && eventParameters.splitFixtures.map((splitFixture) => splitFixture.id)) || [];
        event.linkedFixture = eventParameters.linkedFixture && this.fixtureFactory.create(eventParameters.linkedFixture);
        event.fixtureScoreboard = eventParameters.fixture.scoreboard as ScoreboardSlim;
        event.optionGroups = optionGroups;
        event.optionSets = optionSets;
        event.gamesCount = optionGroups.length;
        event.isPriceBoosted = eventParameters.isPriceBoosted;
        event.bettingInsights = eventParameters.bettingInsights;
        event.playerStats = this.getPlayerStats(eventParameters.fixture, eventParameters.splitFixtures);

        if (eventParameters.fixture.tv2LinkedFixture) {
            event.winGameLinkedFixture = eventParameters.fixture.tv2LinkedFixture;
            event.optionGroups = this.linkedFixtureService.updateLinkedGroups(event);
        }

        if (!!eventParameters.loadEventSitemap && this.eventDetailsConfig.enableSitemapNavigation) {
            event.sitemapSets = this.eventSitemapGroupingFactory.getSitemapSet(optionSets, eventParameters.grouping, optionGroups);
        }

        if (eventParameters.precreated?.precreatedGroupedInfo) {
            event.precreatedOptionGroups = eventParameters.precreated?.precreatedGroupedInfo;
        }

        return event;
    }

    createOptionGroups(
        fixture: Fixture,
        splitFixtures: SplitFixture[],
        linkedFixture: Fixture | undefined,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        precreated?: BetBuilderFixture,
        isPriceBoosted?: boolean,
        excludePriceboostedMarketGrouping?: boolean,
    ): EventOptionGroup[] {
        const result: EventOptionGroup[] = [];
        const mapper = (id: string, source?: DetailedFixtureMarket[], participants?: Participant[], isPartOfSgpGroup?: boolean) => {
            if (!source) {
                return [];
            }

            return flatten(
                source.map((optionGroup) => {
                    const longIds = precreated?.precreatedMarketsInfo.find((marketInfo) => marketInfo.id === optionGroup.id)?.selections;

                    return this.marketFactory.create(
                        optionGroup,
                        id,
                        fixture.isOpenForBetting,
                        grouping,
                        participants,
                        isPriceBoosted,
                        linkedFixture,
                        longIds,
                        isPartOfSgpGroup,
                        excludePriceboostedMarketGrouping,
                    );
                }),
            );
        };

        result.push(...mapper(fixture.id, fixture.games));
        result.push(...mapper(fixture.id, fixture.optionMarkets, fixture.participants));

        if ((this.priceBoostConfig.isEnabled || this.priceBoostConfig.isEnabledAPB) && grouping.apbGroups && fixture.priceBoostCount > 0) {
            const priceBoostMarketGroup = this.priceBoostConfig.showAsTab
                ? grouping.apbGroups.filter((p) => p.tag?.key === EventDetailsTags.PriceBoost)
                : grouping.apbGroups.filter(
                      (group) =>
                          group.tag?.key !== EventDetailsTags.PriceBoost &&
                          group.marketGroupItems.some((item) => item.tags.some((tag) => tag.key === EventDetailsTags.PriceBoost)),
                  );

            if (priceBoostMarketGroup.length > 0) {
                const filteredData = priceBoostMarketGroup.map((data) => {
                    const mappedData = {
                        groupId: data.groupId,
                        groupItemId: data.marketGroupItems.find((marketGroupItem) =>
                            marketGroupItem.tags.some((tag) => tag.key === EventDetailsTags.PriceBoost),
                        )?.id,
                    };

                    return mappedData;
                });

                if (filteredData.length > 0) {
                    const automatedPriceBoostMarkets = this.getAutomatedPriceBoostedMarkets(filteredData, fixture.optionMarkets);
                    result.push(...mapper(fixture.id, automatedPriceBoostMarkets));
                }
            }
        }

        if (
            this.betBuilderConfig.isBetBuilderEnabledForSport?.includes(fixture.sport.id) &&
            (this.nativeAppService.isTerminal || this.betBuilderConfig.themedTabs[fixture.sport.id])
        ) {
            const markets =
                precreated?.precreatedMarketsInfo.length && grouping.preCreatedGroups
                    ? this.getBetBuilderMarketsToBeAppended(fixture, grouping, precreated)
                    : fixture.optionMarkets;

            if (precreated?.sourceFixture?.id) {
                const precreatedFixtureId = getSourceEventId(precreated?.sourceFixture.id);
                result.push(...mapper(precreatedFixtureId, markets));
            }

            if (precreated?.precreatedGroupedInfo && grouping.preCreatedGroups) {
                const precreatedOptionGroups = this.getBetBuilderOptionGroupMarketsToBeAppended(fixture, grouping, precreated);

                result.push(...mapper(fixture.id, markets, undefined));
                result.push(...mapper(fixture.id, precreatedOptionGroups, undefined, true));
            }
        }

        splitFixtures.forEach((split) => {
            result.push(...mapper(split.id, split.games));
        });

        if (linkedFixture) {
            const linkedOptionGroups = mapper(linkedFixture.id, linkedFixture.optionMarkets, linkedFixture.participants);
            result.push(...linkedOptionGroups);
        }

        return result;
    }

    private getAutomatedPriceBoostedMarkets(marketGroupItemsCollection: MarketGroupDetails[], optionMarkets: OptionMarket[]): OptionMarket[] {
        const boostedOptionMarkets: OptionMarket[] = [];

        marketGroupItemsCollection.map((data) => {
            if (data.groupItemId) {
                const parameters: Parameter[] = [
                    {
                        key: Tv2MarketParameters.MarketType,
                        type: ParameterType.String,
                        value: ExtendedDisplayType.AutomatedPriceBoost,
                    },
                ];
                const detailsGroups: DetailedGrouping = {
                    displayType: DisplayType.Regular,
                    orderType: OrderType.None,
                    marketHelpPath: '',
                    marketGroupId: data.groupId,
                    marketGroupItemId: data.groupItemId,
                    contextInfo: '',
                };
                optionMarkets.reduce((res: OptionMarket[], optionMarket) => {
                    const boostedOptions = optionMarket.options.filter(
                        (option) => option.boostedPrice && NativePrice.fromNativePrice(option.boostedPrice).greaterThan(1),
                    );

                    if (boostedOptions.length) {
                        const boostedOptionMarket = cloneDeep(optionMarket);
                        boostedOptionMarket.options = cloneDeep(boostedOptions);
                        boostedOptionMarket.grouping = {
                            detailed: [detailsGroups],
                            gridGroups: [],
                        };
                        boostedOptionMarket.parameters = parameters;
                        res.push(boostedOptionMarket);
                    }

                    return res;
                }, boostedOptionMarkets);
            }
        });

        return boostedOptionMarkets;
    }

    private getPlayerStats(fixture: Fixture, splitFixtures: SplitFixture[]): PlayerStats[] {
        if (fixture.playerStats?.some((x) => x.source === OfferSource.V1)) {
            return fixture.playerStats;
        }

        const playerStats = fixture.playerStats ?? [];
        const splitWithStats = splitFixtures.find((split) => !!split.playerStats);

        if (splitWithStats) {
            playerStats.push(splitWithStats.playerStats);
        }

        return playerStats;
    }

    private getBetBuilderMarketsToBeAppended(
        fixture: Fixture,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        precreated?: BetBuilderFixture,
    ): OptionMarket[] {
        const precreatedModel = cloneDeep(precreated);
        if (precreatedModel?.precreatedMarketsInfo.length) {
            let optionMarkets: OptionMarket[] | undefined = [];
            optionMarkets = precreatedModel?.sourceFixture
                ? precreatedModel?.sourceFixture.optionMarkets.filter((market) => {
                      return precreatedModel.precreatedMarketsInfo.some((f) => {
                          return f.id === market.id;
                      });
                  })
                : [];
            const emptyMarketTabs = grouping.marketTabs.filter((x) => x.name === '');
            optionMarkets?.forEach((precreatedBAB) => {
                const themedName = precreatedModel?.precreatedMarketsInfo.find((x) => x.id === precreatedBAB.id);
                let marketTabId;
                if (!this.nativeAppService.isTerminal) {
                    marketTabId = grouping.marketTabs.find((x) => x.name?.toUpperCase() === themedName?.themedTab?.toUpperCase());
                }
                if (marketTabId === undefined || marketTabId.name === '') {
                    marketTabId = emptyMarketTabs[0];
                    marketTabId.id = PRECREATED_BAB_EMPTY_THEME_ID;
                }
                const parameters: Parameter[] = [];
                const parameter: Parameter = {
                    key: Tv2MarketParameters.MarketType,
                    type: ParameterType.String,
                    value: ExtendedDisplayType.BetBuilder,
                };
                parameters.push(parameter);
                const detailedGrouping: DetailedGrouping[] = [];
                const preCreatedMarketGroups = !this.betBuilderConfig.enablePrecreatedBetBuilderTab
                    ? grouping.preCreatedGroups.filter((g) => g.tag?.key !== EventDetailsTags.PreCreatedBab)
                    : grouping.preCreatedGroups;

                for (const group of preCreatedMarketGroups) {
                    const items = fixture.source === OfferSource.V2 ? group.marketGroupItems : group.tv1MarketGroupItems;
                    for (const marketGroupItem of items) {
                        detailedGrouping.push({
                            displayType: DisplayType.Regular,
                            orderType: OrderType.None,
                            marketTabId: marketTabId?.id,
                            name: this.sitecore.betBuilder.messages!.PrecreatedBuildABetTitle,
                            marketHelpPath: this.sitecore.betBuilder.messages!.PrecreatedBuildABetTitleHelpText,
                            marketGroupId: group.groupId,
                            contextInfo: '',
                            marketGroupItemId: marketGroupItem.id,
                        });
                    }
                }
                const groupingInfo: GroupingInfo = {
                    detailed: detailedGrouping,
                    gridGroups: [],
                };
                precreatedBAB.grouping = groupingInfo;
                const market = precreatedModel.precreatedMarketsInfo.find((x) => x.id === precreatedBAB.id);
                const marketName: Translation = { sign: market!.themedTab, value: market!.themedTab };
                precreatedBAB.name = marketName;
                precreatedBAB.id = market!.id;
                precreatedBAB.parameters = parameters;
                precreatedBAB.status = OptionMarketStatus.Visible;
                precreatedBAB.options = this.mapOptions(precreatedBAB, precreated);
                fixture.optionMarkets.push(precreatedBAB);
            });
        }

        return fixture.optionMarkets;
    }

    private getBetBuilderOptionGroupMarketsToBeAppended(
        fixture: Fixture,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        precreated?: BetBuilderFixture,
    ): DetailedFixtureMarket[] {
        const precreatedModel = cloneDeep(precreated);
        const emptyMarketTab = grouping.marketTabs.find((x) => x.name === '');
        const markets: DetailedFixtureMarket[] = [];

        if (precreatedModel?.precreatedGroupedInfo.length) {
            const preCreatedMarketGroups = !this.betBuilderConfig.enablePrecreatedBetBuilderTab
                ? grouping.preCreatedGroups.filter((g) => g.tag?.key !== EventDetailsTags.PreCreatedBab)
                : grouping.preCreatedGroups;
            precreatedModel?.precreatedGroupedInfo.forEach((group) =>
                group.options.forEach((option) => {
                    const precreatedBAB = fixture.games.length
                        ? fixture.games.find((market) => market.id === option.marketId)
                        : fixture.optionMarkets.find((market) => market.id === option.marketId);
                    if (!precreatedBAB) {
                        return;
                    }
                    const precreatedBABModel = cloneDeep(precreatedBAB);
                    const detailedGrouping: DetailedGrouping[] = [];
                    for (const preCreatedGroup of preCreatedMarketGroups) {
                        const items = fixture.source === OfferSource.V2 ? preCreatedGroup.marketGroupItems : preCreatedGroup.tv1MarketGroupItems;
                        for (const marketGroupItem of items) {
                            detailedGrouping.push({
                                displayType: DisplayType.Regular,
                                orderType: OrderType.None,
                                marketTabId: emptyMarketTab?.id,
                                name: this.sitecore.betBuilder.messages!.PrecreatedBuildABetTitle,
                                marketHelpPath: this.sitecore.betBuilder.messages!.PrecreatedBuildABetTitleHelpText,
                                marketGroupId: preCreatedGroup.groupId,
                                contextInfo: '',
                                marketGroupItemId: marketGroupItem.id,
                            });
                        }
                    }
                    const groupingInfo: GroupingInfo = {
                        detailed: detailedGrouping,

                        gridGroups: [],
                    };
                    precreatedBABModel.grouping = groupingInfo;
                    // precreatedBABModel is TV1 Game
                    if ('visibility' in precreatedBABModel) {
                        const foundOption = precreatedBABModel.results.find((o) => o.id === option.id);

                        if (foundOption) {
                            const foundMarket = markets.find((market) => market.id === precreatedBABModel.id) as Game | undefined;
                            if (foundMarket) {
                                foundMarket.results.push(foundOption);
                            } else {
                                precreatedBABModel.results = [foundOption];
                                markets.push(precreatedBABModel);
                            }
                        }
                    }
                    // precreatedBABModel is TV2 OptionMarket
                    if ('status' in precreatedBABModel) {
                        const parameters: Parameter[] = [
                            {
                                key: Tv2MarketParameters.MarketType,
                                type: ParameterType.String,
                                value: ExtendedDisplayType.BetBuilder,
                            },
                        ];
                        precreatedBABModel.parameters = parameters;
                        const foundOption = precreatedBABModel.options.find((o) => o.id === option.id);

                        if (foundOption) {
                            const foundMarket = markets.find((market) => market.id === precreatedBABModel.id) as OptionMarket | undefined;
                            if (foundMarket) {
                                foundMarket.options.push(foundOption);
                            } else {
                                precreatedBABModel.options = [foundOption];
                                markets.push(precreatedBABModel);
                            }
                        }
                    }
                }),
            );
        }

        return markets;
    }

    private mapOptions(precreatedBAB: OptionMarket, precreated: BetBuilderFixture | undefined): Option[] {
        return precreatedBAB.options.map((option) => {
            const currentMarketName = precreated?.sourceFixture.optionMarkets.find((x) => x.id === precreatedBAB.id)!.name;
            const optionMarketName: Translation = { sign: currentMarketName!.sign, value: currentMarketName!.value };

            return {
                ...option,
                price: option.price,
                name: optionMarketName,
                status: OptionStatus.Visible,
                sourceName: option.sourceName,
                id: option.id,
            };
        });
    }

    private createMarketsDictionary(optionGroups: EventOptionGroup[]): Record<string, EventOptionGroup[]> {
        return optionGroups.reduce<Record<string, EventOptionGroup[]>>((marketsDictionary, group) => {
            const ids = [group.id, ...(group.groupedMarkets || [])];

            ids.forEach((id) => {
                if (!marketsDictionary[id]) {
                    marketsDictionary[id] = [];
                }

                marketsDictionary[id].push(group);
            });

            return marketsDictionary;
        }, {});
    }

    private setParticipantsShirtColor(eventModel: EventModel, fixture: Fixture): void {
        const animationSports = [SportConstant.Basketball, SportConstant.Tennis, SportConstant.Icehockey];
        const fallbackColors: string[] = this.eventDetailsConfig.teamIndicatorFallbackColors.slice(0, 2);
        const isColorsAvailable = fixture.scoreboard && fixture.scoreboard['playerInfo'] && fixture.scoreboard['playerInfo'].colorsAvailable;

        const areTeamColorsSame =
            isColorsAvailable &&
            fixture.scoreboard['playerInfo'].players[`01`].shirtColor === fixture.scoreboard['playerInfo'].players[`02`].shirtColor;

        eventModel.participants.forEach((participant, index) => {
            participant.shirtColor =
                isColorsAvailable && !areTeamColorsSame
                    ? fixture.scoreboard['playerInfo'].players[`0${index + 1}`].shirtColor
                    : animationSports.indexOf(eventModel.sport.id) > -1 || areTeamColorsSame
                      ? fallbackColors[index]
                      : null;
        });
    }
}
