import { Injectable, InjectionToken, inject } from '@angular/core';

import { LDContext } from 'launchdarkly-js-client-sdk';
import { firstValueFrom } from 'rxjs';

import { CookieName } from '../browser/cookie/cookie.models';
import { CookieService } from '../browser/cookie/cookie.service';
import { DeviceService } from '../browser/device/device.service';
import { GeolocationService } from '../lazy/service-providers/geolocation.service';
import { UserService } from '../user/user.service';

// TODO: Move to features/launch-darkly once DTP-37432 is implemented
/**
 * @whatItDoes Providers contexts for LaunchDarkly.
 *
 * @howToUse
 *
 * ```
 * @Injectable
 * export class CustomLaunchDarklyContextProvider implements LaunchDarklyContextProvider {
 *     constructor() {
 *     }
 *
 *     getProviders(): LDContext {
 *         return {            
                device: {
                    key: osName ?? 'device-os',
                    kind: 'device',
                    anonymous: false,                
                },              
        };
 *     }
 * }
 *
 * @provide
 *{ provide: LAUNCH_DARKLY_CONTEXT_PROVIDER, useClass: CustomLaunchDarklyContextProvider }
 * ```
 *
 * @stable
 */
export interface LaunchDarklyContextProvider {
    getProviders(): Promise<LDContext>;
}

/**
 * @stable
 */
export const LAUNCH_DARKLY_CONTEXT_PROVIDER = new InjectionToken<LaunchDarklyContextProvider>('launch-darkly-context-provider');

@Injectable()
export class LaunchDarklyDefaultContextProvider implements LaunchDarklyContextProvider {
    deviceService = inject(DeviceService);
    cookieService = inject(CookieService);
    userService = inject(UserService);
    geolocationService = inject(GeolocationService);

    async getProviders(): Promise<LDContext> {
        const { osName, isAndroid, isChrome, isMobile, isMobilePhone, isNexus, isRobot, isTablet, isTouch, isiOS } = this.deviceService;
        const trackerId = this.cookieService.get(CookieName.TrackerId);
        const vnSession = this.cookieService.get(CookieName.VnSession);
        await firstValueFrom(this.geolocationService.whenReady);
        const geolocation = this.geolocationService.currentPosition?.mappedLocation;

        let context = {
            kind: 'multi',
            device: {
                key: osName || 'device-os',
                isAndroid: isAndroid,
                isChrome: isChrome,
                isMobile: isMobile,
                isMobilePhone: isMobilePhone,
                isNexus: isNexus,
                isRobot: isRobot,
                isTablet: isTablet,
                isTouch: isTouch,
                isiOS: isiOS,
            },
            vnSession: { key: vnSession },
        };

        if (this.userService.id) {
            Object.assign(context, {
                user: {
                    key: this.userService.id,
                    name: this.userService.displayName,
                },
            });
        }

        if (trackerId) {
            Object.assign(context, {
                trackerId: {
                    key: trackerId,
                },
            });
        }

        if (geolocation?.stateClient) {
            Object.assign(context, {
                state: {
                    key: geolocation?.stateClient,
                },
            });
        }

        if (geolocation?.countryClient) {
            Object.assign(context, {
                country: {
                    key: geolocation?.countryClient,
                },
            });
        }

        if (this.userService.geoCountry) {
            Object.assign(context, {
                geoCountry: {
                    key: this.userService.geoCountry,
                },
            });
        }

        return context;
    }
}
