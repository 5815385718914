import { Injectable } from '@angular/core';

import { EventModel, EventOptionGroup } from '../model/event.model';

@Injectable({ providedIn: 'root' })
export class DetailedDeleteFactory {
    deleteOptionGroup(event: EventModel, optionGroupId: number): void {
        const groupsWithSubgroup =
            event.marketsDictionary &&
            event.marketsDictionary[optionGroupId] &&
            event.marketsDictionary[optionGroupId].filter((group) => group.detailedGrouping && group.detailedGrouping.marketGroup !== undefined);

        groupsWithSubgroup &&
            groupsWithSubgroup.forEach((group) => {
                this.deleteOptions(group, optionGroupId);
                if (group.groupedMarkets && !group.groupedMarkets.length) {
                    delete group.groupedMarkets;
                }
            });

        delete event.marketsDictionary[optionGroupId];
        event.optionGroups = event.optionGroups.filter((m) => m.id !== optionGroupId.toString() && m.id !== '');
    }

    private deleteOptions(optionGroup: EventOptionGroup, deleteId: number): void {
        optionGroup.options = optionGroup.options.filter((option) => option.optionGroupId !== deleteId);

        if (optionGroup.id === deleteId.toString() && optionGroup.groupedMarkets && optionGroup.groupedMarkets.length && optionGroup.options.length) {
            optionGroup.id = optionGroup.groupedMarkets.shift() || '';
        } else {
            optionGroup.groupedMarkets =
                optionGroup && optionGroup.groupedMarkets && optionGroup.groupedMarkets.filter((id) => id !== deleteId.toString());
        }
    }
}
