import { FixtureViewType, TotalsPrefix } from '@cds/betting-offer';
import { SportConstant } from '@frontend/sports/common/base-utils';
import { EventParticipant } from 'packages/sports/web/app/src/event-model/model/event.model';

import { BetslipGroupInformation } from '../groups/betslip-group-information';
import { OddsOperations } from '../odds/operations';
import { PickId, pickIdFactory } from './pick-id';
import {
    IAcceptedPrice,
    IBetslipPickMarket,
    IBetslipPickStorage,
    IBetslipV2Option,
    IBoostedPrice,
    IPickTracking,
    IPrice,
    PickOddsState,
    PickSubType,
    PriceType,
    ResultStatus,
} from './pick-models';
import { SignedName } from './signed-name.model';

export abstract class BetslipPick {
    id: PickId;

    // is the pick in the betting offer
    isAvailable = true;

    /**
     * Pick result status. It can be set to something else after trying to do bet placement and the pick is resulted.
     * When result status is !== of OPEN then pick is treated as closed.
     */
    resultStatus: ResultStatus = ResultStatus.Open;

    priceType: PriceType;

    /* Accepted odds from the customer, when current price type is Fixed when price is not fixed accepted Odds are undefined. */
    acceptedPrice: IAcceptedPrice;

    boostedPriceDetails: IBoostedPrice | undefined;

    market: IBetslipPickMarket;

    priceHistory: IPrice[];

    subscriptionContext: string[] = [];

    tracking: IPickTracking;

    contextualInfo?: string;

    liveAlert?: boolean;

    parentLinkedEventId?: string;

    siblingOption?: IBetslipV2Option;

    /**
     * Indicates that the pick is part of the new customer offer (hidden market)
     */
    isNewCustomerOfferPick: boolean;

    highlighted?: boolean;

    totalsPrefix?: TotalsPrefix;
    protected _groupInfo?: BetslipGroupInformation;

    get groupInfo() {
        return this._groupInfo;
    }

    protected constructor() {
        this.priceHistory = [];
    }

    protected abstract isPriceVisible(): boolean;
    abstract isMarketVisible(): boolean;

    private isVisible(): boolean {
        return this.isAvailable && this.isPriceVisible() && this.isMarketVisible();
    }

    protected isOpen(): boolean {
        return this.resultStatus === ResultStatus.Open;
    }

    get currentPrice(): IPrice | undefined {
        return this.prices[0];
    }

    get oddsState(): PickOddsState {
        if (!this.isOpen()) {
            return PickOddsState.Closed;
        }

        if (!this.isVisible()) {
            return PickOddsState.Locked;
        }

        return PickOddsState.Open;
    }

    abstract get isLive(): boolean;

    abstract copy(): BetslipPick;

    // abstract updateFromMessage(m: MessageEnvelope): void;

    abstract get prices(): IPrice[];

    abstract get eventName(): SignedName;

    abstract get marketName(): SignedName;

    abstract get optionName(): SignedName;

    abstract get sportId(): SportConstant;

    abstract get isVirtual(): boolean;

    abstract get eventDate(): Date;

    abstract get regionName(): SignedName;

    abstract get competitionName(): SignedName;

    abstract get competitionId(): number;

    abstract get sportName(): SignedName;

    abstract get leagueName(): SignedName;

    abstract get partitionId(): number | undefined;

    abstract get eventParticipants(): EventParticipant[] | undefined;

    abstract get eventViewType(): FixtureViewType | undefined;

    betCount(): number {
        return 1;
    }

    /**
     * toJSON func should do a deep copy of the object to storage, we need that because when we do copy we get toJSON result and init pick from it.,
     */
    toJSON(): IBetslipPickStorage {
        return {
            id: this.id.toString(),
            priceHistory: this.priceHistory,
            priceType: this.priceType,
            acceptedPrice: this.acceptedPrice,
            isNewCustomerOffer: this.isNewCustomerOfferPick,
            market: this.market
                ? {
                      id: this.market.id,
                      isVisible: this.market.isVisible,
                      isBetBuilderEnabled: this.market.isBetBuilderEnabled,
                  }
                : null,
            subscriptionContext: this.subscriptionContext,
            pickSubType: PickSubType.None,
            tracking: this.tracking,
            boostedPriceDetails: this.boostedPriceDetails,
            liveAlert: this.liveAlert,
            groupInfo: this.groupInfo,
            parentLinkedEventId: this.parentLinkedEventId ?? this.id.parentLinkedEventId,
        };
    }

    protected initPropertiesFromJSON(value: IBetslipPickStorage): void {
        this.id = pickIdFactory(value.id);
        this.priceHistory = value.priceHistory;
        this.priceType = value.priceType;
        this.acceptedPrice = value.acceptedPrice;
        this.isNewCustomerOfferPick = value.isNewCustomerOffer;
        this.subscriptionContext = Array.isArray(value.subscriptionContext) ? value.subscriptionContext : [value.subscriptionContext];
        if (value.market) {
            this.market = {
                id: value.market.id,
                isVisible: value.market.isVisible,
                isBetBuilderEnabled: value.market.isBetBuilderEnabled,
            };
        }
        this.tracking = value.tracking ?? {};
        this.boostedPriceDetails = value.boostedPriceDetails;
        this.liveAlert = value.liveAlert;
        this._groupInfo = value.groupInfo;

        this.parentLinkedEventId = value.parentLinkedEventId;
    }

    getOddsAcceptance(): boolean {
        if (this.currentPrice?.nativeOdds === this.acceptedPrice.price?.nativeOdds) {
            return true;
        } else if (!this.currentPrice?.nativeOdds || !this.acceptedPrice.price?.nativeOdds) {
            return false;
        }

        return OddsOperations.equal(this.currentPrice?.nativeOdds, this.acceptedPrice.price?.nativeOdds);
    }

    setGroupInfo(groupInfo: BetslipGroupInformation) {
        this._groupInfo = groupInfo;
    }
}
