import { Injectable } from '@angular/core';

import { CountItem, sportModelMethods, takeTop } from '@frontend/sports/common/base-utils';
import { TournamentsDataConfig } from '@frontend/sports/common/client-config-data-access';
import { Tournament } from '@frontend/sports/types/components/tournaments';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { BettingOfferApi } from '../../cds/betting-offer-api.service';
import { SportsCacheService } from '../../client-caching/sports-cache.service';

export interface CompetitionOverview {
    tournaments?: Tournament[];
    sport?: CountItem;
}

export interface ReorderItem {
    id: number;
    location: number;
}

// TODO:REVIEW-USAGE
@Injectable({ providedIn: 'root' })
export class CompetitionListService {
    constructor(
        private bettingContent: BettingOfferApi,
        private bettingCache: SportsCacheService,
        private tournamentsData: TournamentsDataConfig,
    ) {}

    getSportTree(
        sportId: number,
        start?: string,
        end?: string,
        withTournaments?: boolean,
        competitionId?: number,
        conferenceId?: number,
    ): Observable<CompetitionOverview | undefined> {
        const cached = !start && !end;
        const source = cached
            ? this.bettingCache.getSport(sportId, competitionId, conferenceId)
            : this.bettingContent.getSport(sportId, false, { from: start, to: end }).pipe(
                  switchMap((sport) =>
                      combineLatest([of(sport), this.bettingCache.getConference(conferenceId, competitionId, { from: start, to: end })]),
                  ),
                  map(([sports, conference]) => sportModelMethods.fromTag((sports || []).concat(conference || [])).pop()),
              );

        return source.pipe(
            map((sport) => {
                if (!sport) {
                    return;
                }

                return {
                    sport,
                    tournaments: withTournaments ? this.getValidTournaments(sportId) : undefined,
                };
            }),
        );
    }

    getSportList(top: number, ...reorderings: ReorderItem[]): Observable<CountItem[]> {
        return this.bettingCache.getSportList().pipe(map((result) => this.getReorderedSportList(result, top, reorderings)));
    }

    getLiveSportList(top: number, ...reorderings: ReorderItem[]): Observable<CountItem[]> {
        return this.bettingCache.getLiveSportList().pipe(map((result) => this.getReorderedSportList(result, top, reorderings)));
    }

    getReorderedSportList(sports: CountItem[], top: number, reorderings: ReorderItem[]): CountItem[] {
        const topSports = takeTop(sports);
        let locationShiftUp = 0;

        for (const { id, location } of reorderings) {
            if (!location) {
                continue;
            }

            const existing = sports.find((current) => current.id === id);

            if (existing) {
                const existingLocation = topSports.indexOf(existing);

                if (existingLocation >= 0) {
                    topSports.splice(existingLocation, 1);
                }

                topSports.splice(location - locationShiftUp - 1, 0, existing);
            } else {
                locationShiftUp++;
            }
        }

        return topSports.slice(0, top);
    }

    getValidTournaments(sportId: number): Tournament[] {
        return this.tournamentsData.tournaments.filter((tournament) => this.filterTournament(tournament, sportId));
    }

    private filterTournament(tournament: Tournament, sportId: number): boolean {
        return Boolean(
            tournament.navigation &&
                tournament.navigation.filter &&
                tournament.navigation.filter.visible &&
                tournament.leaguesSection &&
                tournament.leaguesSection.sport &&
                tournament.leaguesSection.sport.id.toString() === sportId.toString(),
        );
    }
}
