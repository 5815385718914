import { Injectable } from '@angular/core';

import { HtmlNode, MediaQueryService } from '@frontend/vanilla/core';
import { keys, toLower } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
import { distinctUntilKeyChanged, filter, skip } from 'rxjs/operators';

import { AdaptiveLayoutService, AdaptiveLayoutState } from './adaptive-layout.service';
import { ColumnMeasurement } from './column-measurement';
import { FooterMeasurement } from './footer-measurement';
import { ColumnComponentRegistration, ColumnComponentRemoval, ColumnSize, ColumnSizeData, Layout, LayoutColumn, TaskScheduler } from './layout.model';

@Injectable({ providedIn: 'root' })
export class ColumnLayoutService {
    private deviceLayout: Layout;
    private measurement: ColumnMeasurement;
    private footerMeasurement: FooterMeasurement;

    private _componentRegistered = new Subject<ColumnComponentRegistration>();
    private _componentUpdated = new Subject<ColumnComponentRegistration>();
    private _componentRemoved = new Subject<ColumnComponentRemoval>();
    private _layoutChanged = new Subject<void>();

    readonly componentUpdated$ = this._componentUpdated.asObservable();

    constructor(
        private layoutService: AdaptiveLayoutService,
        private mediaService: MediaQueryService,
        private htmlNode: HtmlNode,
    ) {
        this.mediaService
            .observe()
            .pipe(
                skip(1),
                filter(() => !!this.measurement),
            )
            .subscribe(() => this.resetMeasurement());
        this.footerMeasurement = new FooterMeasurement();
    }

    initializeLayout(deviceLayout: Layout): void {
        if (this.deviceLayout) {
            throw new Error('ColumnLayout has been already initialized!');
        }

        this.measurement = new ColumnMeasurement(deviceLayout);
        this.resetMeasurement();

        this.deviceLayout = deviceLayout;
        this.layoutService.stateChange$.pipe(distinctUntilKeyChanged('columns')).subscribe(this.mediaChangeHandler);

        this.deviceLayout.registeredComponents().forEach((c) => this._componentRegistered.next(c));
    }

    get layout(): Layout {
        return this.deviceLayout;
    }

    get componentRegistered(): Observable<ColumnComponentRegistration> {
        return this._componentRegistered.asObservable();
    }

    get componentRemoved(): Observable<ColumnComponentRemoval> {
        return this._componentRemoved.asObservable();
    }

    get layoutChanged(): Observable<void> {
        return this._layoutChanged.asObservable();
    }

    register(data: ColumnComponentRegistration): void {
        if (this.deviceLayout.registerComponent(data)) {
            this.columnVisibilityHandler();
            this._componentRegistered.next(data);
        } else {
            this._componentUpdated.next(data);
        }
    }

    remove(component: any, location: LayoutColumn): void {
        this.deviceLayout.removeComponent(component, location);
        this.columnVisibilityHandler();
        this._componentRemoved.next({ component, location });
    }

    resetMeasurement(): void {
        const scheduler = new TaskScheduler();

        this.measurement.reset();
        this.footerMeasurement.reset();

        this.measurement.measureLayout(scheduler);
        this.footerMeasurement.measureFooter(scheduler);

        scheduler.flush();
    }

    get rightColumnSizeChanged(): Observable<ColumnSizeData> {
        return this.measurement.columnSizeChanged.pipe(
            filter((c) => c.column === LayoutColumn.Right && c.width > 0),
            distinctUntilKeyChanged('width'),
        );
    }

    get currentRightColumnSize(): ColumnSizeData {
        const measurement = this.measurement.layoutSize[LayoutColumn.Right];

        return {
            column: LayoutColumn.Right,
            size: measurement.currentSize,
            width: measurement.width,
        };
    }

    get centerColumnSizeChanged(): Observable<ColumnSizeData> {
        return this.measurement.columnSizeChanged.pipe(
            filter((c) => c.column === LayoutColumn.Center && c.width > 0),
            distinctUntilKeyChanged('width'),
        );
    }

    get currentCenterColumnSize(): ColumnSizeData {
        const measurement = this.measurement.layoutSize[LayoutColumn.Center];

        return {
            column: LayoutColumn.Center,
            size: measurement.currentSize,
            width: measurement.width,
        };
    }

    get currentFooterSize(): number {
        return this.footerMeasurement.footerSize;
    }

    setColumnSize(size: ColumnSize, column: LayoutColumn): void {
        if (column === LayoutColumn.Center || column === LayoutColumn.Left) {
            throw new Error('Column sizing can not be applied to the Center or Left column');
        }

        if (!this.layout.columnVisible(column)) {
            return;
        }

        this.applyColumnSize(size, column);

        this.measurement.updateColumnSize({ column, size });
        this.resetMeasurement();
    }

    private applyColumnSize(size: ColumnSize, column: LayoutColumn): void {
        const getClassName = (columnSize: ColumnSize) => `content-${toLower(column)}-${toLower(columnSize)}`;

        const className = getClassName(size);
        const classList = keys(ColumnSize)
            .map((key) => ColumnSize[key])
            .map((key) => getClassName(key));

        classList.forEach((current) => this.htmlNode.setCssClass(current, false));

        if (size !== ColumnSize.Default) {
            this.htmlNode.setCssClass(className, true);
        }
    }

    private mediaChangeHandler = (state: AdaptiveLayoutState): void => {
        const layoutRefreshed = this.deviceLayout.refreshLayout(state.columns);

        if (layoutRefreshed) {
            this.columnVisibilityHandler();
            this._layoutChanged.next();
        }
    };

    private columnVisibilityHandler(): void {
        this.htmlNode.setCssClass('content-left', this.deviceLayout.isLeftVisible);
        this.htmlNode.setCssClass('content-right', this.deviceLayout.isRightVisible);
    }
}
