import { OddsAcceptanceMode } from '@bpos';
import { createAction, props } from '@ngrx/store';

import BetslipActions from '../../base/store/actions';

export default class SettingsActions {
    static readonly ACTION_SCHEMA = `${BetslipActions.ACTION_SCHEMA}/settings`;

    private static readonly REQUEST_SET_ODD_ACCEPTANCE = `${SettingsActions.ACTION_SCHEMA}/REQUEST_SET_ODD_ACCEPTANCE`;
    private static readonly SET_ODD_ACCEPTANCE = `${SettingsActions.ACTION_SCHEMA}/SET_ODD_ACCEPTANCE`;

    private static readonly REQUEST_SET_APP_NOTIFY = `${SettingsActions.ACTION_SCHEMA}/REQUEST_SET_APP_NOTIFY`;
    private static readonly SET_APP_NOTIFY = `${SettingsActions.ACTION_SCHEMA}/SET_APP_NOTIFY`;

    private static readonly REQUEST_SET_EMAIL_NOTIFY = `${SettingsActions.ACTION_SCHEMA}/REQUEST_SET_EMAIL_NOTIFY`;
    private static readonly SET_EMAIL_NOTIFY = `${SettingsActions.ACTION_SCHEMA}/SET_EMAIL_NOTIFY`;
    private static readonly UPDATE_NOTIFICATION_SETTINGS = `${SettingsActions.ACTION_SCHEMA}/UPDATE_NOTIFICATION_SETTINGS`;

    /**
     * We can set odds acceptance if user is authenticated if not then we redirect to login.
     */
    static requestSetOddAcceptance = createAction(SettingsActions.REQUEST_SET_ODD_ACCEPTANCE, props<{ acceptance: OddsAcceptanceMode }>());
    static setOddAcceptance = createAction(SettingsActions.SET_ODD_ACCEPTANCE, props<{ acceptance: OddsAcceptanceMode }>());

    static requestSetAppNotify = createAction(SettingsActions.REQUEST_SET_APP_NOTIFY, props<{ value: boolean }>());
    static setAppNotify = createAction(SettingsActions.SET_APP_NOTIFY, props<{ value: boolean }>());

    static requestSetEmailNotify = createAction(SettingsActions.REQUEST_SET_EMAIL_NOTIFY, props<{ value: boolean }>());
    static setEmailNotify = createAction(SettingsActions.SET_EMAIL_NOTIFY, props<{ value: boolean }>());

    static updateNotificationSettings = createAction(
        SettingsActions.UPDATE_NOTIFICATION_SETTINGS,
        props<{ oddsAcceptance?: OddsAcceptanceMode; emailNotify?: boolean; appNotify?: boolean }>(),
    );
}
