import { BetSlipType, OddsBoostFormula } from '@bpos';
import { ReplaceableParameter } from '@bpos/v1/sports-promo/campaigns';
import { RewardTargetType } from '@bpos/v1/sports-promo/tokens';
import { NumberDictionary, StringDictionary } from '@frontend/sports/common/base-utils';
import {
    BetStatus,
    BuildABet,
    PayoutType,
    RewardTokenType,
    RewardTriggerType,
    TokenFilter,
} from 'packages/sports/web/app/src/tokens-base/token-base.models';

import { BetslipType } from '../../core/betslip-type';
import { Odds } from '../../core/odds/odds';
import { PickId } from '../../core/picks/pick-id';
import { IRewardTokenEligibility } from './state';

export const ACCA_BOOST_ID = 'AccaBoost';

export interface IRewardToken {
    id: string;
    startDate: Date;
    expiryDate: Date;
    cmsItemId: string;
    userTokenId: string;
    minimumLegs?: number;
    wageringMultiplier?: number;
    payoutType: PayoutType;
    tokenType: string;
    filter: TokenFilter;
    rewardTokenType: RewardTokenType;
    payoutExpiry?: number;
    minOdds?: number;
    customTitle?: string;
    maximumStake?: number;
    selectionLevelMinimumOdds?: TokenOddsData;
    selectionLevelMaximumOdds?: TokenOddsData;
    replaceableParameters?: StringDictionary<ReplaceableParameter>;
    minimumOdds?: TokenOddsData;
    maximumOdds?: TokenOddsData;
    customDescription?: string;
    isBetBuilder?: boolean;
    isSingleAndComboSelected?: boolean;
    url?: string;
    customMarketTypes?: string;
    rewardTargetType?: RewardTargetType;
    isIncrementalPayout?: boolean;
    incrementalBetAmount?: number;
    incrementalPayoutNoOfTimes?: number;
}

export interface OddsBoostToken extends IRewardToken {
    boostFactor: number;
    maxWinningsBoost?: number;
    minimumStake?: number;
    oddsBoostFormula: OddsBoostFormula;
}

export interface RiskFreeToken extends IRewardToken {
    maximumPayout: number;
    minimumStake?: number;
    riskFreePercentage: number;
}

export interface FreeBetToken extends IRewardToken {
    amount: number;
}

export interface BetAndGetToken extends IRewardToken {
    percentagePayout: number | undefined;
    payoutValue: number;
    rewardTriggerType: RewardTriggerType;
    maximumPayout?: number;
    minimumPayout?: number;
    betStatus: BetStatus;
    minimumStake: number;
    subType: string;
    isEarlyPayoutEnabled?: boolean;
    buildABet?: BuildABet;
}

export interface TokenOddsData {
    european: number;
    britishNumerator: number;
    britishDenominator: number;
    american: number;
}

export interface BetStationFreeBetToken extends IRewardToken {
    amount: number;
    currency: string;
    marketTemplateIds: Array<number>;
    marketParameters: Array<MarketParameter>;
}

export interface AccaBoostToken extends IRewardToken {
    boostLadder: AccaBoostLadderItem[];
    maxReward: number;
    priority: number;
    filter: AccaBoostFilter;
    countryCodes: string[];
}
export interface EdsToken extends IRewardToken {
    priority?: number;
    replaceableParameters: Record<string, ReplaceableParameter>;
    filter: EdsFilter;
}

export interface AccaBoostLadderItem {
    numberOfLegs: number;
    ratio: number;
}
export interface EdsFilter extends TokenFilter {
    marketIds: Array<number>;
    minSelections?: number;
    maxSelections?: number;
    minStake?: number;
    maxStake?: number;
    currency?: string;
    overallMinOdds?: Odds;
    overallMaxOdds?: Odds;
    selectionMinOdds?: Odds;
    selectionMaxOdds?: Odds;
}

export interface AccaBoostFilter extends TokenFilter {
    sportsConfigs: Record<number, AccaBoostSportConfig>;
    minOddsPerLeg: AccaBoostTokenMinOdds;
}

export interface AccaBoostSportConfig {
    gridGroups: StringDictionary<string>;
    competitions: StringDictionary<number>;
}

export interface AccaBoostTokenMinOdds {
    european: number;
    britishNumerator: number;
    britishDenominator: number;
    american: number;
}

export interface BestOddsGuaranteedToken extends IRewardToken {
    filter: BestOddsGuaranteedFilter;
}

export interface BestOddsGuaranteedFilter extends TokenFilter {
    slipTypes: BetSlipType[];
    sportsFilter: NumberDictionary<BestOddsGuaranteedSportsFilter>;
}

export interface BestOddsGuaranteedSportsFilter {
    regions: number[];
    competitions: number[];
    meetings: number[];
}

export enum AvailableTokensType {
    FreeBet = 'FreeBet',
    RiskFreeBet = 'RiskFreeBet',
    OddsBoost = 'OddsBoost',
    BetAndGet = 'BetAndGet',
    AccaBoost = 'AccaBoost',
    MultipleRewards = 'MultipleRewards',
}

export enum CriteriaType {
    Sports = 'Sports',
    Competitions = 'Competitions',
    Meetings = 'Meetings',
    Fixtures = 'Fixtures',
    GameType = 'GameType',
    OptionId = 'OptionId',
    TemplateId = 'TemplateId',
    MarketParameter = 'MarketParameter',
    MarketId = 'MarketId',
    Forbidden = 'Forbidden',
    EachWaySelected = 'EachWaySelected',
    StartDate = 'StartDate',
    ExpiryDate = 'ExpiryDate',
    ParticipantBetSelected = 'ParticipantBetSelected',
    BetBuilderBetSelected = 'BetBuilderBetSelected',
    MinOdds = 'MinOdds',
    MaxOdds = 'MaxOdds',
    SelectionMaxOdds = 'SelectionMaxOdds',
    Market = 'Market',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    SlipType = 'SlipType',
    MinimumLegs = 'MinimumLegs',
    MaximumStake = 'MaximumStake',
    MinimumStake = 'MinimumStake',
    DayOfWeek = 'DayOfWeek',
    TimeOfDay = 'TimeOfDay',
    BestOddsFlag = 'BestOddsFlag',
    MultiSingleBet = 'MultiSingleBet',
    SelectionOdds = 'SelectionOdds',
    TotalOdds = 'TotalOdds',
    SelectionsCount = 'SelectionsCount',
    Stake = 'Stake',
    Currency = 'Currency',
    BetBuilder = 'BetBuilder',
    SystemBet = 'SystemBet',
    TeaserReward = 'TeaserReward',
    EachWay = 'EachWay',
    ComboPrevention = 'ComboPrevention',
}

export interface IOnboardingViewData {
    title: string;
    content: string;
    icon: string | undefined;
    isNew: boolean;
}

export interface IRewardTokenSelection {
    tokenId: string;
    pickId?: PickId;
    betslipType?: BetslipType;
    trackingSource?: { [key: string]: string };
}

export interface IRewardTokenDeSelection {
    rewardTokenType?: RewardTokenType;
}

export interface IRewardTokenInfo {
    token: IRewardToken;
    selected: boolean;
}

export interface MarketParameter {
    marketType: string;
    period: string;
    happening: string;
}

export enum RewardBannerTrackingConstants {
    Load = 'load',
    Expand = 'expand',
    Collapse = 'collapse',
    Click = 'click',
    BetRewardsBanner = 'bet rewards banner',
    RewardsBanner = 'rewards banner',
    RewardDetails = 'reward details',
    PromoHub = 'promo hub',
}

export interface BetAndGetEdsToken {
    payoutType: PayoutType;
    triggerType: RewardTriggerType;
    payoutDetails: PayoutDetails;
    maximumPayout?: number;
    rewardTargetType?: RewardTargetType;
}

export interface PayoutDetails extends IncrementalPayoutDetails {
    value: number;
    percentage: number | undefined;
    maxValue?: number;
}

export interface PromoTokenPlaceBetSignPostingInfo extends IncrementalPayoutDetails {
    rewardTargetType?: RewardTargetType;
}

export interface IncrementalPayoutDetails {
    noOfSlabs?: number;
    slabAmount?: number;
}

// TODO: @Andriy explore ways of having this from bpos-v4 typings
export enum RewardTokenAdditionalInfoKeys {
    BoostPercentage = 'boostPercentage',
    MaxTokenStake = 'maxTokenStake',
    MaxWinningsBoost = 'maxWinningsBoost',
    UserExpectedWinningsBoost = 'userExpectedWinningsBoost',
    RiskFreeMaxCompensation = 'riskFreeMaxCompensation',
    RiskFreePercentage = 'riskFreePercentage',
    AccaBoostRatio = 'accaBoostRatio',
    AccaBoostFormulaType = 'formulaType',
    EdsCampaignPromotionType = 'edsCampaignPromotionType',
    BetIndex = 'betIndex',
    IsDigitalV2 = 'isDigitalV2',
    OddsBoostFormulaType = 'oddsBoostFormula',
    PlaceBetSignPostingData = 'placeBetSignPostingData',
    PlaceBetSignPostingVersion = 'placeBetSignPostingVersion',
}

export interface EdsTokenWithEligiblePicks {
    token: EdsToken;
    eligiblePicksIds: string[];
}

export interface BogTokenWithEligibility {
    token: BestOddsGuaranteedToken;
    eligibility: IRewardTokenEligibility | undefined;
}

export interface RewardTokenContext {
    betslipType: BetslipType | undefined;
    pickId?: PickId;
}

export interface RewardTokenSelectionContext {
    betslipType: BetslipType | undefined;
    pickId?: string;
}

export interface SelectedRewardTokenState {
    tokenId: string;
    token: IRewardToken | null;
    tokenEligibility: IRewardTokenEligibility | null;
}

export interface AcquisitionContext {
    acquisitionNotificationShown: boolean;
    autoApplyNotificationShown: boolean;
    deselectedByUser: boolean;
    rewardAutoApplied: boolean;
}

export enum AcquisitionContextType {
    BannerTooltip = 'BannerTooltip',
    AutoApplyTooltip = 'AutoApplyTooltip',
    AutoApply = 'AutoApply',
    DeselectedByUser = 'DeselectedByUser',
}
