import { Injectable } from '@angular/core';

import { LoadingIndicatorOptions, LoadingIndicatorService } from '@frontend/vanilla/core';

import { ILoadingIndicatorHandler, VanillaLoadingIndicatorHandlerWrapper } from './custom-loading-indicator-handler';
import { CustomLoadingIndicatorSwitchService, ICustomLoadingIndicatorVisibilityService } from './custom-loading-indicator-switch.service';

/**
 * Loading indicator management service
 */
export abstract class CustomLoadingIndicatorService {
    /**
     * Starts loading indicator
     *
     * @param token if token is passed it will try to show loading indicator from component @see {@link LoadingIndicatorComponent}
     * otherwise it will show the global one (in middle of the page).
     * @param options Extra options for the loading indicator
     * @return a handler that may be used to stop the loading indicator
     */
    abstract start(token?: string | null, options?: LoadingIndicatorOptions): ILoadingIndicatorHandler;
}

@Injectable({ providedIn: 'root' })
export class CustomLoadingIndicatorExtendedService extends CustomLoadingIndicatorService {
    private readonly loadingIndicatorSwitchServices: Map<string, CustomLoadingIndicatorSwitchService> = new Map<
        string,
        CustomLoadingIndicatorSwitchService
    >();

    constructor(private loadingIndicatorService: LoadingIndicatorService) {
        super();
    }

    registerLoadingIndicator(token: string): ICustomLoadingIndicatorVisibilityService {
        const hasTokenRegistered = this.loadingIndicatorSwitchServices.has(token);
        if (hasTokenRegistered) {
            this.unregisterLoadingIndicator(token);
        }

        const customLoadingIndicatorSwitchService = new CustomLoadingIndicatorSwitchService();
        this.loadingIndicatorSwitchServices.set(token, customLoadingIndicatorSwitchService);

        return customLoadingIndicatorSwitchService;
    }

    unregisterLoadingIndicator(token: string): void {
        const switchService = this.loadingIndicatorSwitchServices[token];
        if (switchService) {
            switchService.destroy();

            this.loadingIndicatorSwitchServices.delete(token);
        }
    }

    start(token?: string, options?: LoadingIndicatorOptions): ILoadingIndicatorHandler {
        if (!token) {
            return new VanillaLoadingIndicatorHandlerWrapper(this.loadingIndicatorService.start(options));
        }

        const switchService = this.loadingIndicatorSwitchServices.get(token);
        if (!switchService) {
            return new VanillaLoadingIndicatorHandlerWrapper(this.loadingIndicatorService.start(options));
        }

        return switchService.start();
    }
}
