import { CashoutErrorType } from '@bpos/v1/cashout';
import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { BetslipType } from '../../../core/betslip-type';
import { BetPlacementErrorIcon } from './bet-placement-error-icon';
import { ClientErrorType } from './client-error-type';

export enum ErrorOrigin {
    // Error comes from the server
    BetPlacement = 'BetPlacement',
    // Error is created on the client.
    BetSlip = 'BetSlip',
}

export enum CleanStrategy {
    // Is the error is cleaned on every action.
    Always = 'Always',
    // Is the error cleaned on manual action ( Errors from BPS, Some Warnings. )
    Manually = 'Manually',
}

export interface BetslipErrorRestrictions {
    betslipType: BetslipType;
    slipTypeKey?: string;
}

export interface BetslipErrorSaved {
    // Error type string name of the error.
    type: PlacementErrorType | CashoutErrorType | ClientErrorType;
    // Error icon to display.
    icon: BetPlacementErrorIcon;
    // Priority of the error. Lower is bigger. We display only one error, so errors with lower priority will be displayed first.
    priority: number;
    // The source of the error.
    origin: ErrorOrigin;
    // The strategy to remove the error.
    cleanStrategy: CleanStrategy;
    // Does the error have client validation ?
    hasClientValidation: boolean;
    // Should we display a message to the user for this error.
    isHidden: boolean;
    // The error code of the error.
    code: string;
    // Is this error restricted to a betslip type or slip type?
    restrictions?: BetslipErrorRestrictions;
}

export class BetslipError implements BetslipErrorSaved {
    // Error type string name of the error.
    type: PlacementErrorType | CashoutErrorType | ClientErrorType;
    // Error icon to display.
    icon: BetPlacementErrorIcon = BetPlacementErrorIcon.Error;
    // Priority of the error. Lower is bigger. We display only one error, so errors with lower priority will be displayed first.
    priority = 0;
    // The source of the error.
    origin: ErrorOrigin = ErrorOrigin.BetSlip;
    // The strategy to remove the error.
    cleanStrategy: CleanStrategy = CleanStrategy.Always;
    // Does the error have client validation ?
    hasClientValidation = false;
    // Should we display a message to the user for this error.
    isHidden = false;
    // The error code of the error.
    code: string;

    slipId: string;

    sportId: number | null;

    /**
     * Is one error equals to another.
     *
     * @param error
     */
    equals(error: BetslipError): boolean {
        // Return false always.
        return false;
    }

    copy(): this {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }
}
