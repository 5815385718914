import { Injectable } from '@angular/core';

import { ExpiringCache } from '@frontend/sports/common/base-utils';
import { AccaBoostConfig } from '@frontend/sports/common/client-config-data-access';
import { AccaBoostToken } from 'packages/sports/common/betslip/modules/reward-tokens/reward-tokens.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ExpiringCacheFactory } from '../common/expiring-cache.factory';
import { ApiService } from '../http/api.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { AccaBoostSitecoreData, AccaBoostTokenData, AccaBoostTokensResponse } from './acca-boost-data.model';
import { AccaBoostTokenFactoryService } from './acca-boost-token-factory.service';

@Injectable({ providedIn: 'root' })
export class AccaBoostTokenLoaderService {
    private readonly tokenUrl = 'crmOffers/GetAccaBoost';
    private readonly configUrl = 'crmOffers/GetAccaBoostContent';
    private readonly configCache: ExpiringCache;
    private readonly logger: SportsRemoteLogger;

    constructor(
        private apiService: ApiService,
        private accaBoostConfig: AccaBoostConfig,
        private factoryService: AccaBoostTokenFactoryService,
        cacheFactory: ExpiringCacheFactory,
        loggerFactory: LoggerFactory,
    ) {
        this.configCache = cacheFactory.create(accaBoostConfig.sitecoreTokenDataCacheTime * 60);

        this.logger = loggerFactory.getLogger('AccaBoostTokenLoaderService');
    }

    // TODO: @Andriy this should not reference betslip project directly?
    loadTokens(): Observable<AccaBoostToken[]> {
        const source = this.accaBoostConfig.isEnabled ? this.fetchTokens() : of(undefined);

        return source.pipe(map((data) => (data ? this.mapTokens(data.tokens) : [])));
    }

    loadTokenData(sitecorePath: string): Observable<AccaBoostSitecoreData> {
        const source = this.apiService.get<AccaBoostSitecoreData>(this.configUrl, {
            sitecorePath,
        });

        return this.configCache.getOrCreate(sitecorePath, source);
    }

    private mapTokens(data: AccaBoostTokenData[]): AccaBoostToken[] {
        return data.map((token) => this.factoryService.map(token));
    }

    private fetchTokens(): Observable<AccaBoostTokensResponse | undefined> {
        return this.apiService.get<AccaBoostTokensResponse>(this.tokenUrl).pipe(
            catchError((err) => {
                this.logger.error(err, `AccaBoostTokenLoaderService - failed to get acca boost tokens.`);

                return of(undefined);
            }),
        );
    }
}
