import { Component, EventEmitter, HostBinding, Input, NgZone, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild, inject } from '@angular/core';

import { DeviceService } from '@frontend/vanilla/core';
import { RxStrategyProvider } from '@rx-angular/cdk/render-strategies';

import { ScrollAdapterButtonSize, ScrollAdapterComponent } from '../common/scroll-adapter/scroll-adapter.component';
import { TimerService } from '../common/timer.service';
import { RedirectHelperService } from '../navigation-core/redirect-helper.service';
import { TabBarEvent, TabBarItem, TabBarMode } from './tab-bar.models';

@Component({
    selector: 'ms-tab-bar',
    templateUrl: 'tab-bar.html',
})
export class TabBarComponent implements OnChanges {
    @Input() tabs: TabBarItem[];
    @Input() scrollSize = 200;
    @Input() scrollEnabled = true;
    @Input() stretch = false;
    @Input() mode: TabBarMode = TabBarMode.Tab;
    @Input() class = '';
    @Input() iconTemplate: TemplateRef<any>;
    @Input() openCountTemplate: TemplateRef<any>;
    @Input() buttonSize = ScrollAdapterButtonSize.Small;
    @Input() isSportsPill = false;
    @Output() select = new EventEmitter<TabBarEvent>();
    @Input() signPostTemplate: TemplateRef<any>;
    @Input() isNewFixture = false;
    @ViewChild(ScrollAdapterComponent) scrollAdapter: ScrollAdapterComponent;
    @Output() arrowSelect = new EventEmitter<'left' | 'right'>();

    @HostBinding('class')
    get componentClass(): string {
        return `${this.hostClass} ${this.class}`.trim();
    }

    get hostClass(): string {
        return `${this.mode}-bar`;
    }

    get containerClass(): string {
        return `${this.mode}-bar-container`;
    }

    get itemClass(): string {
        return this.isSportsPill ? `sports-pill` : `${this.mode}-bar-item`;
    }

    get active(): TabBarItem | undefined {
        return this.tabs && this.tabs.filter((tab) => tab.active).pop();
    }

    private ngZone = inject(NgZone);

    constructor(
        private timer: TimerService,
        private redirectHelperService: RedirectHelperService,
        private deviceService: DeviceService,
        private strategy: RxStrategyProvider,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.scrollEnabled && changes.tabs && !changes.tabs.firstChange) {
            this.timer.setTimeout(() => this.scrollAdapter.setArrows());
        }
        if (this.isNewFixture) {
            this.scrollAdapter?.resetArrows();
        }
    }

    selected(event: Event, selected: TabBarItem): void {
        event.preventDefault();

        const canBeSelected = !selected.disabled && !selected.active;
        if (canBeSelected) {
            this.tabs.forEach((tab) => (tab.active = tab.id === selected.id));

            this.strategy
                .schedule(
                    () => {
                        this.select.emit({ ...event, active: selected });

                        const shouldNavigate = selected.url && !this.shouldNavigateByUrl(selected);
                        if (shouldNavigate) {
                            this.redirectHelperService.goToPage(selected.url, selected.ignoreBrowserHistory);
                        }
                    },
                    { scope: this, patchZone: this.ngZone },
                )
                .subscribe();
        }
    }

    shouldNavigateByUrl(tab: TabBarItem): boolean {
        return Boolean(tab.url && (this.deviceService.isRobot || !tab.ignoreBrowserHistory));
    }

    trackTab(index: number, tab: TabBarItem): number {
        return tab.id;
    }

    tabClass(tab: TabBarItem): string {
        let classes = this.itemClass;

        if (tab.classes) {
            classes += ` ${tab.classes}`;
        }

        if (tab.active) {
            classes += ' active';
        }

        if (tab.disabled) {
            classes += ' disabled';
        }

        return classes;
    }

    tabFormatted(text: string): boolean {
        return text?.includes('<');
    }

    track(event: any) {
        this.arrowSelect.emit(event);
    }
}
