import { Injectable } from '@angular/core';

import { FixtureType } from '@cds/betting-offer';
import { TennisSurface } from '@cds/betting-offer/add-ons';
import { IScoreboard } from '@cds/scoreboards';
import { ScoreboardSlim } from '@cds/scoreboards/v1';
import { Message as CdsMessage, Counter, CricketInning, DoubleCounter, Participant, PenaltyDoubleCounter } from '@cds/scoreboards/v1/shared';
import {
    AmericanFootball,
    AmericanFootballSlim,
    Baseball,
    Basketball,
    Cricket,
    Darts,
    DartsScoreboardSlim,
    FormulaOne,
    GeneralTournamentGameStateType,
    GeneralTournamentSlim,
    IceHockey,
    Snooker,
    SnookerScoreboardSlim,
    Soccer,
    SoccerSlim,
    Tennis,
    TennisScoreboardSlim,
} from '@cds/scoreboards/v1/sports';
import {
    AmericanFootballPeriodInfo,
    AmericanFootballQuarters,
    BasketballHalves,
    BasketballPeriodInfo,
    BasketballQuarters,
    FootballPeriodInfo,
    IceHockeyPeriods,
    SportConstant,
} from '@frontend/sports/common/base-utils';
import { values } from 'lodash-es';

import { DateFormatterService } from '../helpers/date-formatter.service';
import { TennisPointsVisibilityService } from '../helpers/tennis-points-visibility.service';
import { BaseEventModel, EventModel } from '../model/event.model';
import {
    AmericanFootballStatistics,
    BaseStatisticsEntry,
    BaseballStatistics,
    BasketballStatistics,
    BasketballStatisticsEntry,
    CricketInningScore,
    CricketInnings,
    CricketInningsData,
    CricketScore,
    DartsGameScore,
    DartsMatchType,
    EventScore,
    EventScoreboard,
    EventStatistics,
    FootballScore,
    FootballStats,
    FootballStatsEntry,
    GeneralTournamentType,
    IcehockeyPeriodGameScore,
    IcehockeyStatisticsEntry,
    KeyValuePairScoreboard,
    LeaderboardGameScore,
    MatchType,
    Message,
    PairGameScore,
    PairScore,
    ParticipantPeriodScore,
    PenaltyRound,
    PenaltyStatus,
    PeriodGameScore,
    PeriodScore,
    ScoreboardType,
    SetGameScore,
    SetScore,
    SnookerScore,
    SnookerStats,
    SportType,
    TeamType,
    TennisScore,
    TennisStats,
    TennisStatsEntry,
    TournamentScore,
    Turn,
    toWeather,
} from '../model/scoreboard.model';
import { AmericanFootballScoreboardFactory } from './american-football-scoreboard.factory';
import { BaseballScoreboardFactory } from './baseball-scoreboard.factory';
import { MessageParser } from './message-parser.service';

type PeriodGamesScoreboardFull = AmericanFootballSlim | Basketball | IceHockey;

@Injectable({ providedIn: 'root' })
export class ScoreboardFactory {
    // prettier-ignore
    private readonly SPORT_TYPE_LOOKUP = {
		Football: [4, 70], // soccer, futsal
		Tennis: [5, 87], // tennis, padel
		Badminton: [44],
		LeaderboardGame: [6, 9, 64, 40],
		Snooker: [33],
		Darts: [34],
		Baseball: [23],
		SpecialGame: [24],
		SetGame: [18, 50, 56, 63],
		Tournament: [
			10, 13, 14, 15, 17, 26, 29, 30, 37, 39, 41, 42, 43, 46, 48, 51, 54, 55, 57, 59, 60, 61, 62,
			65, 66, 69, 72, 76, 79, 80, 81, 82, 83, 84, 86, 89, 91, 92, 94, 95, 96, 98, 100
		],
		PeriodGame: [7, 11, 12],
		Cricket: [22]
	};

    private readonly SCOREBOARD_TYPE_LOOKUP = {
        SetGame: ScoreboardType.SetGame,
        Darts: ScoreboardType.Darts,
        Tennis: ScoreboardType.SetGame,
        Badminton: ScoreboardType.SetGame,
        Tournament: ScoreboardType.TournamentGame,
        LeaderboardGame: ScoreboardType.LeaderboardGame,
        SpecialGame: ScoreboardType.SpecialGame,
        Baseball: ScoreboardType.BaseballGame,
        PeriodGame: ScoreboardType.PeriodGame,
        Snooker: ScoreboardType.Snooker,
        Cricket: ScoreboardType.Cricket,
    };

    private readonly sportsScoreboardServices = {
        [SportConstant.AmericanFootball]: this.americanFootballScoreboardFactory,
        [SportConstant.Baseball]: this.baseballScoreboardFactory,
    };

    constructor(
        private messageParser: MessageParser,
        private tennisPointsVisibilityService: TennisPointsVisibilityService,
        private dateFormatterService: DateFormatterService,
        private baseballScoreboardFactory: BaseballScoreboardFactory,
        private americanFootballScoreboardFactory: AmericanFootballScoreboardFactory,
    ) {}

    //this is a legacy method please use the service if you need to format the date
    showDate(event: EventModel): string {
        return this.dateFormatterService.formatDate(event.startDate);
    }

    createScoreboard<T extends BaseEventModel>(scoreboardResponse: ScoreboardSlim | undefined, model: T): EventScoreboard {
        const scoreboard = new EventScoreboard();

        if (!scoreboardResponse) {
            scoreboard.type =
                model.fixtureType === FixtureType.Tournament ? ScoreboardType.TournamentGame : this.getDefaultScoreboardType(model.sport.id);

            return scoreboard;
        }

        scoreboard.id = scoreboardResponse.id;
        scoreboard.visible = true;
        scoreboard.period = {
            name: scoreboardResponse.period,
            id: scoreboardResponse.periodId,
        };

        scoreboard.timeIndicator = scoreboardResponse.indicator;
        scoreboard.timer = scoreboardResponse.timer;
        scoreboard.bookieTicker = (scoreboardResponse as any).bookieTicker || '';
        scoreboard.isGeneralTournament = this.isGeneralTournament(scoreboardResponse);
        scoreboard.started = scoreboardResponse.started;
        scoreboard.type = this.getScoreboardType(model.sport.id, scoreboardResponse);
        scoreboard.sportType = this.getSportType(model.sport.id, scoreboardResponse);
        scoreboard.isFinished = Boolean(scoreboardResponse.period && scoreboard.period.id === 255);
        scoreboard.isSuspended = this.getSuspended(model.sport.id, scoreboardResponse.periodId);

        if (scoreboard.started && scoreboardResponse.timer) {
            scoreboard.time = scoreboardResponse.timer.base;
            scoreboard.gameTimeInSeconds = scoreboardResponse.timer.seconds;

            // TODO: check custom timer sport-id array
            const standardTimerSports = [4, 22, 24, 38, 71];
            if (standardTimerSports.indexOf(model.sport.id) >= 0) {
                scoreboard.time = this.formatGameTimer(scoreboard.gameTimeInSeconds);
            }
        }

        scoreboard.messages = this.createMessages(scoreboardResponse as any, model.sport.id);
        scoreboard.statistics = this.getSportStats(scoreboardResponse, model.sport.id);
        scoreboard.score = this.getSportScore(scoreboardResponse, model.sport.id, model.league.id);
        scoreboard.colors = this.getColors(scoreboardResponse as Soccer);
        if (scoreboard.type === ScoreboardType.Snooker) {
            scoreboard.bestOf = this.getBestOfSnooker(scoreboardResponse as SnookerScoreboardSlim);
        } else if (scoreboard.type === ScoreboardType.Darts) {
            scoreboard.bestOfLegs = (scoreboardResponse as DartsScoreboardSlim).bestOfLegs;
            scoreboard.bestOfSets = (scoreboardResponse as DartsScoreboardSlim).bestOfSets;
        }

        return scoreboard;
    }

    private getDefaultScoreboardType(sportId: number): ScoreboardType {
        const sportType = this.getDefaultSportType(sportId);

        return this.SCOREBOARD_TYPE_LOOKUP[sportType] || ScoreboardType.PairGame;
    }

    private getDefaultSportType(sportId: number): SportType {
        const defaultSportType = SportType.PairGame;

        const sportType = Object.keys(this.SPORT_TYPE_LOOKUP).reduce((acc, curr) => {
            const sports = this.SPORT_TYPE_LOOKUP[curr] as number[];
            const sportFound = sports.indexOf(sportId) >= 0;

            return sportFound ? curr : acc;
        }, defaultSportType);

        return sportType as SportType;
    }

    private isGeneralTournament(scoreboardResponse: ScoreboardSlim): boolean {
        return !!(
            (scoreboardResponse as GeneralTournamentSlim).isGeneralTournament &&
            scoreboardResponse.sportId !== SportConstant.Skialpine &&
            scoreboardResponse.sportId !== SportConstant.Motorbikes &&
            scoreboardResponse.sportId !== SportConstant.Biathlon
        );
    }

    private getScoreboardType(sportId: number, scoreboardResponse: ScoreboardSlim): ScoreboardType {
        const isGeneralTournament = this.isGeneralTournament(scoreboardResponse);
        if (isGeneralTournament) {
            return ScoreboardType.TournamentGame;
        }
        const sportScoreboardType = this.getDefaultScoreboardType(sportId);
        if (sportScoreboardType === ScoreboardType.TournamentGame) {
            // If isGeneralTournament is Falsy, but sports is defined and Tournament game then it isn't a tournament
            // We fallback to the other most common scoreboard which is PairGame.
            return ScoreboardType.PairGame;
        }

        return sportScoreboardType;
    }

    private getSportType(sportId: number, scoreboardResponse: ScoreboardSlim): SportType {
        const isGeneralTournament = this.isGeneralTournament(scoreboardResponse);
        if (isGeneralTournament) {
            return SportType.Tournament;
        }
        const sportType = this.getDefaultSportType(sportId);

        return sportType === SportType.Tournament ? SportType.PairGame : sportType;
    }

    private getBestOfSnooker(scoreboard: SnookerScoreboardSlim): number {
        return parseInt(scoreboard.bestOf);
    }

    private getColors(scoreboard: Soccer): string[] | undefined {
        if (!(scoreboard.playerInfo && scoreboard.playerInfo.colorsAvailable && scoreboard.playerInfo.players)) {
            return;
        }

        return Object.keys(scoreboard.playerInfo.players).map((key, index) => scoreboard.playerInfo.players[key].shirtColor);
    }

    private createMessages(scoreboard: IScoreboard, sportId: number): Message[] | undefined {
        const messages = <{ [key: string]: CdsMessage }>(<any>scoreboard).messages;
        if (!messages) {
            return undefined;
        }

        const messageList: Message[] = Object.keys(messages).map((key) => ({
            time: messages[key].timer,
            text: messages[key].content,
            order: messages[key].order,
            type: messages[key].messageType,
            teamType: messages[key].teamType !== undefined ? TeamType[messages[key].teamType!] : undefined,
            icon: null,
        }));

        return this.messageParser.parseMessages(messageList, sportId, true).sort((a, b) => b.order - a.order);
    }

    private formatGameTimer(totalSeconds: number): string {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    private getSportScore(scores: ScoreboardSlim, sportId: number, leagueId: number): EventScore {
        const sportType = this.getSportType(sportId, scores);

        switch (sportType) {
            case SportType.Football:
                return this.getFootballScore(scores, scores.periodId as number);
            case SportType.Tennis:
                return this.getTennisScore(scores, leagueId);
            case SportType.Baseball:
                return this.getBaseballScore(scores);
            case SportType.Badminton:
            case SportType.SetGame:
                return this.getSetGameScore(scores);
            case SportType.Darts:
                return this.getDartsScore(scores as Darts);
            case SportType.Snooker:
                return this.getSnookerScore(scores);
            case SportType.Tournament:
                return this.getGeneralTournamentScore(scores as GeneralTournamentSlim, sportId);
            case SportType.LeaderboardGame:
                if (sportId === SportConstant.Motorbikes) {
                    return this.getGeneralTournamentScore(scores as GeneralTournamentSlim, sportId);
                } else {
                    return this.getLeaderboardGameScore(scores as FormulaOne);
                }
            case SportType.PeriodGame:
                if (sportId === SportConstant.Icehockey) {
                    return this.getPeriodGameScore(sportId, scores as PeriodGamesScoreboardFull, new IcehockeyPeriodGameScore());
                } else {
                    return this.getPeriodGameScore(sportId, scores as PeriodGamesScoreboardFull, new PeriodGameScore());
                }
            case SportType.SpecialGame:
                return this.getPairGameScore(scores, sportId);
            case SportType.Cricket:
                return this.getCricketScore(scores as Cricket);
        }

        return this.getPairGameScore(scores, sportId);
    }

    private getSportStats(scoreboard: ScoreboardSlim, sportId: number): EventStatistics | undefined {
        const sportType = this.getSportType(sportId, scoreboard);

        if (sportType === SportType.Tennis) {
            return this.getTennisStats(scoreboard as Tennis, scoreboard.periodId as number);
        } else if (sportType === SportType.Football) {
            return this.getFootballStats(scoreboard as Soccer, scoreboard.periodId as number);
        } else if (sportId === SportConstant.Basketball) {
            return this.getBasketBallStats(scoreboard as Basketball, scoreboard.periodId as number);
        } else if (sportId === SportConstant.Icehockey) {
            return this.getIcehockeyStats(scoreboard as IceHockey, scoreboard.periodId as number);
        } else if (sportId === SportConstant.AmericanFootball) {
            return this.getAmericanFootballStatistics(scoreboard as AmericanFootball, scoreboard.periodId as number);
        } else if (sportId === SportConstant.Baseball) {
            return this.getBaseballStatistics(scoreboard as Baseball, scoreboard.periodId as number);
        } else if (sportId === SportConstant.Snooker) {
            return this.getSnookerStats(scoreboard as Snooker, scoreboard.periodId as number);
        }

        return;
    }

    private getDartsScore(scoreboard: Darts): DartsGameScore {
        const score = new DartsGameScore();
        const periodId = scoreboard.periodId || 255;
        const dartsMaxSet = 255;

        if (scoreboard.score) {
            const participantsScore = scoreboard.score.split(':');
            score.matchType = DartsMatchType[scoreboard.matchType];
            score.participant1 = parseInt(participantsScore[0], 10);
            score.participant2 = parseInt(participantsScore[1], 10);
            score.currentlyServing = scoreboard.turn ? Turn[scoreboard.turn] : 0;

            if (score.matchType === DartsMatchType.LegOnly) {
                if (scoreboard.hundredEighties && !!scoreboard.hundredEighties.player1 && !!scoreboard.hundredEighties.player2) {
                    score.hundredEighties = {
                        participant1: scoreboard.hundredEighties.player1[dartsMaxSet],
                        participant2: scoreboard.hundredEighties.player2[dartsMaxSet],
                    };
                }
                if (scoreboard.legs && !!scoreboard.legs.player1 && !!scoreboard.legs.player2) {
                    score.legs = {
                        participant1: scoreboard.legs.player1[dartsMaxSet],
                        participant2: scoreboard.legs.player2[dartsMaxSet],
                    };
                }
            } else {
                if (scoreboard.hundredEighties && !!scoreboard.hundredEighties.player1 && !!scoreboard.hundredEighties.player2) {
                    score.hundredEighties = {
                        participant1: scoreboard.hundredEighties.player1[dartsMaxSet],
                        participant2: scoreboard.hundredEighties.player2[dartsMaxSet],
                    };
                }
                if (scoreboard.legs && !!scoreboard.legs.player1 && !!scoreboard.legs.player2) {
                    score.legs = {
                        participant1: scoreboard.legs.player1[periodId],
                        participant2: scoreboard.legs.player2[periodId],
                    };
                }
            }
        }

        return score;
    }

    private getFootballScore(scoreboard: ScoreboardSlim, periodId: number): FootballScore {
        const score = new FootballScore();
        const finished = periodId === FootballPeriodInfo.Finish;
        score.regularTimeOver = periodId <= FootballPeriodInfo.RegularTimeOver;

        if (scoreboard.score && scoreboard.score.indexOf(':') !== -1) {
            const participantsScore = scoreboard.score.split(':');
            score.participant1 = parseInt(participantsScore[0], 10);
            score.participant2 = parseInt(participantsScore[1], 10);
        }

        const detailed = (scoreboard as SoccerSlim).scoreDetailed;

        if (!detailed) {
            return score;
        }

        if (periodId === FootballPeriodInfo.SecondHalf) {
            score.firstHalfScore = {
                participant1: detailed.player1[FootballPeriodInfo.FirstHalf] || 0,
                participant2: detailed.player2[FootballPeriodInfo.FirstHalf] || 0,
            };
        }

        if (periodId === FootballPeriodInfo.PenaltyShootout) {
            score.participant1 = detailed.player1[254];
            score.participant2 = detailed.player2[254];

            const penalties = (scoreboard as SoccerSlim).penaltiesControl;
            score.penaltyShootOutScore = this.getPenaltyScore(penalties);
        }

        if (periodId >= FootballPeriodInfo.RegularTimeOver) {
            const extraTimeScore = !!detailed.player1[FootballPeriodInfo.RegularTimeOver] && !!detailed.player2[FootballPeriodInfo.RegularTimeOver];

            score.finishedAfterRegularTime = finished && extraTimeScore;
            if (!finished || score.finishedAfterRegularTime) {
                score.regularTime = {
                    participant1: detailed.player1[255] || 0,
                    participant2: detailed.player2[255] || 0,
                };
                score.overTime = {
                    participant1: detailed.player1[253] || 0,
                    participant2: detailed.player2[253] || 0,
                };
            }
        }

        return score;
    }

    private getPenaltyScore(counter: PenaltyDoubleCounter): PairScore<PenaltyRound[]> | undefined {
        if (!counter) {
            return;
        }

        const valueMapper = (value: number): PenaltyStatus =>
            value === 0 ? PenaltyStatus.Scored : value === 1 ? PenaltyStatus.Missed : PenaltyStatus.Unset;

        const penaltyMapper = (team: { [key: string]: Counter }): PenaltyRound[] =>
            values(team)
                .sort((first, second) => first.periodId - second.periodId)
                .map((penalty) => ({
                    round: penalty.periodId,
                    status: valueMapper(penalty.value),
                }));

        return {
            participant1: penaltyMapper(counter.team1),
            participant2: penaltyMapper(counter.team2),
        };
    }

    private getTennisScore(scoreboard: ScoreboardSlim, leagueId: number): TennisScore {
        const result = new TennisScore();
        result.participant1 = { sets: [] as SetScore[] };
        result.participant2 = { sets: [] as SetScore[] };

        const tennisScoreboard = <Tennis>scoreboard;

        const setCount = this.getTennisSetCount(MatchType[tennisScoreboard.matchType], scoreboard.sets);
        result.setCount = setCount;
        if (scoreboard.sets) {
            for (let i = 0; i < setCount; i++) {
                const isCurrentSet = i + 1 === scoreboard.periodId;
                const partcipant1Score = scoreboard.sets[0][i] === '-' ? '-' : parseInt(scoreboard.sets[0][i], 10);
                const partcipant2Score = scoreboard.sets[1][i] === '-' ? '-' : parseInt(scoreboard.sets[1][i], 10);
                const partcipant1TiebBreakScore = tennisScoreboard.tieBreaksValues ? tennisScoreboard.tieBreaksValues.player1[i] : undefined;
                const partcipant2TiebBreakScore = tennisScoreboard.tieBreaksValues ? tennisScoreboard.tieBreaksValues.player2[i] : undefined;
                result.participant1.sets.push({
                    points: partcipant1Score,
                    tieBreakPoints: partcipant1TiebBreakScore,
                    current: isCurrentSet,
                    isFutureSet: result.setCount === 7 && partcipant1Score === '-',
                    setWon: !isCurrentSet && partcipant1Score > partcipant2Score,
                });
                result.participant2.sets.push({
                    points: partcipant2Score,
                    tieBreakPoints: partcipant2TiebBreakScore,
                    isFutureSet: result.setCount === 7 && partcipant2Score === '-',
                    current: isCurrentSet,
                    setWon: !isCurrentSet && partcipant2Score > partcipant1Score,
                });
            }
        }

        if (scoreboard.points && this.tennisPointsVisibilityService.canDisplayPoints(leagueId)) {
            result.participant1.points = scoreboard.points[0];
            result.participant2.points = scoreboard.points[1];
        }

        result.currentlyServing = scoreboard.turn ? Turn[scoreboard.turn] : Turn.None;
        result.surfaceType = tennisScoreboard.surface || TennisSurface.Grass;

        if (tennisScoreboard.winPoints) {
            result.winPoints = {
                participant1: tennisScoreboard.winPoints.player1,
                participant2: tennisScoreboard.winPoints.player2,
            };
        }

        const setScore = this.getSetScore(result, scoreboard.periodId as number, true);
        result.participant1WonSets = setScore.participant1;
        result.participant2WonSets = setScore.participant2;
        result.matchType = MatchType[tennisScoreboard.matchType];
        result.period = scoreboard.period;
        result.gameMode = (scoreboard as TennisScoreboardSlim).gameMode;

        return result;
    }

    private getBaseballScore(scoreboard: ScoreboardSlim): SetGameScore {
        const score = new SetGameScore();
        score.participant1 = { sets: [] as SetScore[] };
        score.participant2 = { sets: [] as SetScore[] };

        const fullScoreboard = (scoreboard as Baseball).scoreDetailed;

        if (fullScoreboard) {
            // Parse full scoreboard for detailed events
            const keys = Object.keys(fullScoreboard.player1);
            keys.slice(0, keys.length - 1).forEach((key, index) => {
                if (index % 2 === 0) {
                    score.participant1.sets.push({ points: fullScoreboard.player1[key], current: false, setWon: false });
                    score.participant2.sets.push({ points: fullScoreboard.player2[key], current: false, setWon: false });
                } else {
                    score.participant1.sets[score.participant1.sets.length - 1].points =
                        parseInt(score.participant1.sets[score.participant1.sets.length - 1].points.toString(), 10) + fullScoreboard.player1[key];
                    score.participant2.sets[score.participant2.sets.length - 1].points =
                        parseInt(score.participant2.sets[score.participant2.sets.length - 1].points.toString(), 10) + fullScoreboard.player2[key];
                }
            });

            if (scoreboard.score) {
                const participantsScore = scoreboard.score.split(':');
                score.participant1.points = participantsScore[0];
                score.participant2.points = participantsScore[1];
            }
        } else {
            if (scoreboard.currentPeriodScore) {
                score.participant1.sets.push({ points: scoreboard.currentPeriodScore.team01, current: false, setWon: false });
                score.participant2.sets.push({ points: scoreboard.currentPeriodScore.team02, current: false, setWon: false });
            }

            // Parse scoreboard slim for grid events
            if (scoreboard.score) {
                const participantsScore = scoreboard.score.split(':');
                score.participant1.r = parseInt(participantsScore[0], 10);
                score.participant2.r = parseInt(participantsScore[1], 10);

                score.participant1.sets.push({ points: score.participant1.r, current: false, setWon: false });
                score.participant2.sets.push({ points: score.participant2.r, current: false, setWon: false });
            }
        }

        score.currentlyServing = scoreboard.turn ? Turn[scoreboard.turn] : 0;
        score.slimScoreboard = true;

        return score;
    }

    private getSetGameScore(scoreboard: ScoreboardSlim): SetGameScore {
        const score = new SetGameScore();
        score.participant1 = { sets: [] as SetScore[] };
        score.participant2 = { sets: [] as SetScore[] };

        if (scoreboard.sets) {
            for (let i = 0; i < scoreboard.sets[0].length; i++) {
                const partcipant1Score = scoreboard.sets[0][i] === '-' ? '-' : parseInt(scoreboard.sets[0][i], 10);
                const partcipant2Score = scoreboard.sets[1][i] === '-' ? '-' : parseInt(scoreboard.sets[1][i], 10);
                const isCurrentSet = this.isCurrentSet(scoreboard.sportId, i, scoreboard.periodId);
                score.participant1.sets.push({
                    points: partcipant1Score,
                    current: isCurrentSet,
                    setWon: !isCurrentSet && partcipant1Score > partcipant2Score,
                });
                score.participant2.sets.push({
                    points: partcipant2Score,
                    current: isCurrentSet,
                    setWon: !isCurrentSet && partcipant2Score > partcipant1Score,
                });
            }
        }

        if (scoreboard.points) {
            score.participant1.points = scoreboard.points[0];
            score.participant2.points = scoreboard.points[1];
        }

        score.currentlyServing = scoreboard.turn ? Turn[scoreboard.turn] : 0;
        score.setCount = score.participant1.sets.length;
        score.period = scoreboard.period;

        const setScore = this.getSetScore(score, scoreboard.periodId as number, false);
        score.participant1WonSets = setScore.participant1;
        score.participant2WonSets = setScore.participant2;
        score.gameMode = (scoreboard as TennisScoreboardSlim).gameMode;

        return score;
    }

    private getPeriodGameScore(
        sportId: number,
        scoreboard: PeriodGamesScoreboardFull,
        score: PeriodGameScore | IcehockeyPeriodGameScore,
    ): PeriodGameScore {
        if (!scoreboard.score) {
            return score;
        }

        let overtimePeriod = 0;
        let scorePeriods: number[] = [];
        switch (sportId) {
            case SportConstant.AmericanFootball:
                scorePeriods = [
                    AmericanFootballQuarters.Quarter1,
                    AmericanFootballQuarters.Quarter2,
                    AmericanFootballQuarters.Quarter3,
                    AmericanFootballQuarters.Quarter4,
                ];
                overtimePeriod = AmericanFootballQuarters.Overtime;
                break;
            case SportConstant.Basketball:
                if ((scoreboard as Basketball).matchType === 1) {
                    scorePeriods = [BasketballHalves.Firsthalf, BasketballHalves.Secondhalf];
                } else {
                    scorePeriods = [
                        BasketballQuarters.Quarter1,
                        BasketballQuarters.Quarter2,
                        BasketballQuarters.Quarter3,
                        BasketballQuarters.Quarter4,
                    ];
                }
                overtimePeriod = BasketballQuarters.Overtime;
                break;
            case SportConstant.Icehockey:
                scorePeriods = [IceHockeyPeriods.Period1, IceHockeyPeriods.Period2, IceHockeyPeriods.Period3];
                overtimePeriod = IceHockeyPeriods.Overtime;
                const penalties = (scoreboard as IceHockey).penaltiesControl;
                (score as IcehockeyPeriodGameScore).penaltyShootOutScore =
                    (scoreboard as IceHockey).periodId === 9 ? this.getPenaltyScore(penalties) : undefined;
                break;
        }

        const totalScore = scoreboard.score.split(':').map((value) => parseInt(value, 10));

        const participantScoreParser = (playerId: 1 | 2, periodId: number, currentPeriodId?: number): PeriodScore => ({
            points: scoreboard.totalPoints[`player${playerId}`][periodId] != null ? scoreboard.totalPoints[`player${playerId}`][periodId] : '-',
            current: currentPeriodId === periodId,
        });

        const participantParser = (playerId: 1 | 2, currentPeriodId?: number): ParticipantPeriodScore => ({
            periods: scoreboard.totalPoints ? scorePeriods.map((periodId) => participantScoreParser(playerId, periodId, currentPeriodId)) : [],
            overTimeScore: scoreboard.totalPoints ? scoreboard.totalPoints[`player${playerId}`][overtimePeriod] : undefined,
            total: totalScore[playerId - 1],
        });

        score.participant1 = participantParser(1, scoreboard.periodId);
        score.participant2 = participantParser(2, scoreboard.periodId);

        score.turn = scoreboard.turn ? +Turn[scoreboard.turn] : undefined;

        if (sportId === SportConstant.Basketball) {
            const _scoreboard = <Basketball>scoreboard;
            score.matchType = _scoreboard.matchType;
            score.playerInfo = _scoreboard.playerInfo;
        } else if (sportId === SportConstant.Icehockey) {
            const icehockeyScore = <IcehockeyPeriodGameScore>score;
            const _scoreboard = <IceHockey>scoreboard;

            score.playerInfo = _scoreboard.playerInfo;
            icehockeyScore.penalties = <PairScore<number>>{
                participant1: this.getPenalityAmount(_scoreboard, 0),
                participant2: this.getPenalityAmount(_scoreboard, 1),
            };

            return icehockeyScore;
        }

        return score;
    }

    private getSnookerScore(scoreboard: ScoreboardSlim): SnookerScore {
        const score = new SnookerScore();

        if (scoreboard.score) {
            const participantsScore = scoreboard.score.split(':');

            score.participant1 = scoreboard.currentPeriodScore.team01;
            score.participant2 = scoreboard.currentPeriodScore.team02;
            score.currentlyServing = scoreboard.turn ? Turn[scoreboard.turn] : 0;
            score.frames = {
                participant1: parseInt(participantsScore[0], 10),
                participant2: parseInt(participantsScore[1], 10),
            };
        }

        return score;
    }

    private getPairGameScore(scoreboard: ScoreboardSlim, sportId: number): PairGameScore {
        const score = new PairGameScore();

        if (scoreboard.score) {
            const participantsScore = scoreboard.score.split(':');

            score.participant1 = parseInt(participantsScore[0], 10);
            score.participant2 = parseInt(participantsScore[1], 10);
            score.currentlyServing = scoreboard.turn ? Turn[scoreboard.turn] : 0;
            score.hasServing = sportId === SportConstant.Bowls || sportId === SportConstant.Darts;
        }
        score.period = scoreboard.period;

        return score;
    }

    private getCricketScore(scoreboard: Cricket): CricketScore {
        const score = new CricketScore();

        if (scoreboard) {
            score.currentlyServing = scoreboard.turn ? Turn[scoreboard.turn] : 0;
            score.balls = scoreboard.balls;
            score.overs = scoreboard.overs;
            score.runs = scoreboard.runs;
            score.wickets = scoreboard.wickets;
            score.firstInnings = this.getCricketInnings(scoreboard, score, CricketInnings.FirstInning);
            score.secondInnings = this.getCricketInnings(scoreboard, score, CricketInnings.SecondInning);
            score.superOver = this.getCricketInnings(scoreboard, score, CricketInnings.FirstSuperOver);
        }

        return score;
    }

    private hasSecondInningDataAvailable(eventScoreboard: Cricket): boolean {
        return eventScoreboard.periodId === CricketInnings.ThirdInning || !!eventScoreboard.statistics[CricketInnings.ThirdInning];
    }

    private hasSuperOverDataAvailable(eventScoreboard: Cricket): boolean {
        return eventScoreboard.periodId === CricketInnings.FirstSuperOver || !!eventScoreboard.statistics[CricketInnings.FirstSuperOver];
    }

    private getCricketInningsData(inningId: number, team: number, scoreboard: Cricket, score: CricketScore): CricketInningScore {
        if (team === score.currentlyServing && scoreboard.periodId && parseInt(((scoreboard.periodId + 1) / 2).toString()) === inningId) {
            return { runs: score.runs, wickets: score.wickets, overs: score.overs, balls: score.balls, isScoringTeam: true };
        }

        const stats: CricketInning[] = scoreboard.statistics ? Object.values(scoreboard.statistics) : [];

        const filteredStats = stats.length
            ? stats.filter((s) => s && Turn[s.team] === team).filter((s) => parseInt(((s?.inningId + 1) / 2).toString()) === inningId)
            : [];

        return { runs: filteredStats && filteredStats[0]?.runs, wickets: filteredStats && filteredStats[0]?.wickets };
    }

    private getCricketInnings(eventScoreboard: Cricket, cricketScore: CricketScore, inningId: CricketInnings): CricketInningsData | undefined {
        switch (inningId) {
            case CricketInnings.FirstInning:
                if (eventScoreboard.periodId! > 0) {
                    return {
                        team1: this.getCricketInningsData(1, 1, eventScoreboard, cricketScore),
                        team2: this.getCricketInningsData(1, 2, eventScoreboard, cricketScore),
                    };
                }
                break;
            case CricketInnings.SecondInning:
                if (
                    eventScoreboard.periodId! > 2 &&
                    this.hasSecondInningDataAvailable(eventScoreboard) &&
                    !this.hasSuperOverDataAvailable(eventScoreboard)
                ) {
                    return {
                        team1: this.getCricketInningsData(2, 1, eventScoreboard, cricketScore),
                        team2: this.getCricketInningsData(2, 2, eventScoreboard, cricketScore),
                    };
                }
                break;
            case CricketInnings.FirstSuperOver:
                if (eventScoreboard.periodId! > 4 && this.hasSuperOverDataAvailable(eventScoreboard)) {
                    return {
                        team1: this.getCricketInningsData(3, 1, eventScoreboard, cricketScore),
                        team2: this.getCricketInningsData(3, 2, eventScoreboard, cricketScore),
                    };
                }
                break;
        }

        return;
    }

    private getLeaderboardGameScore(scoreboard: FormulaOne): LeaderboardGameScore {
        const result = new LeaderboardGameScore();

        if (scoreboard) {
            result.type = 'Lap';
            result.final = scoreboard.lapCounter && scoreboard.lapCounter.final ? scoreboard.lapCounter.final : 0;
            result.current = scoreboard.lapCounter && scoreboard.lapCounter.current ? scoreboard.lapCounter.current : 0;
            result.periodId = scoreboard.currentPeriod;
            result.period = scoreboard.period;
            result.safetyCar = scoreboard.safetyCar || false;
            result.preRace = scoreboard.currentPeriod !== 12;
            result.weather = toWeather(scoreboard.weather);
            if (scoreboard.participants && scoreboard.participants.items) {
                result.participants = this.getParticipantList(scoreboard.participants.items);
                result.participantsDetails = this.getParticipantListKeyValue(scoreboard.participants.items);
            }
        }

        return result;
    }

    private getGeneralTournamentScore(scoreboard: GeneralTournamentSlim, sportId: number): TournamentScore {
        const score = new TournamentScore();

        if (scoreboard.gameType !== undefined) {
            const gameTypes: { [key: string]: GeneralTournamentType } = {
                [GeneralTournamentGameStateType.Kilometers]: 'km',
                [GeneralTournamentGameStateType.Stages]: 'Stage',
                [GeneralTournamentGameStateType.Laps]: 'Lap',
            };

            score.type = gameTypes[scoreboard.gameType];
            score.final = scoreboard.finalState;
            score.current = scoreboard.currentState;
        }
        if (scoreboard.participants && scoreboard.participants.items && sportId === SportConstant.Motorbikes) {
            score.participants = this.getParticipantList(scoreboard.participants.items);
            score.participantsDetails = this.getParticipantListKeyValue(scoreboard.participants.items);
        }

        return score;
    }

    getSetScore(score: SetGameScore, periodId: number, tennisGame: boolean): PairScore<number> {
        if (!score.participant1) {
            return {} as PairScore<number>;
        }

        let sets1 = score.participant1.sets;
        let sets2 = score.participant2.sets;
        const delta = sets1.findIndex((set) => set.points === '-');

        sets1 = sets1.slice(0, delta > -1 ? delta - 1 : sets1.length);
        sets2 = sets2.slice(0, delta > -1 ? delta - 1 : sets2.length);

        const setScore = {
            participant1: sets1.reduce((setsWon, setPoints, index) => {
                if (setPoints.points > sets2[index].points && !sets2[index].current) {
                    if (tennisGame) {
                        if (+setPoints.points > 6 || (+setPoints.points >= 6 && +sets2[index].points <= 4)) {
                            setsWon++;
                        }
                    } else {
                        setsWon++;
                    }
                }

                return setsWon;
            }, 0),
            participant2: sets2.reduce((setsWon, setPoints, index) => {
                if (setPoints.points > sets1[index].points && !sets1[index].current) {
                    if (tennisGame) {
                        if (+setPoints.points > 6 || (+setPoints.points >= 6 && +sets1[index].points <= 4)) {
                            setsWon++;
                        }
                    } else {
                        setsWon++;
                    }
                }

                return setsWon;
            }, 0),
        };

        return setScore;
    }

    private getFootballStats(scoreboard: Soccer, periodId: number): FootballStats | undefined {
        const stats: FootballStats = {
            goals: this.getFootballStatisticsEntry(scoreboard.scoreDetailed, periodId, 1),
            redcards: this.getFootballStatisticsEntry(scoreboard.redCards, periodId),
            yellowcards: this.getFootballStatisticsEntry(scoreboard.yellowCards, periodId),
            penalties: this.getFootballStatisticsEntry(scoreboard.penalties, periodId),
            corners: this.getFootballStatisticsEntry(scoreboard.corners, periodId),
            substitutions: this.getFootballStatisticsEntry(scoreboard.substitutions, periodId),
        };

        if (!stats.goals && !stats.redcards && !stats.yellowcards && !stats.penalties && !stats.corners && !stats.substitutions) {
            return undefined;
        }

        return stats;
    }

    /**
     * Returns single statistics entry e.g. corners, red cards, yellow cards etc
     *
     * @param responseStats Statistics counter from cds
     * @param periodId Current period id
     * @param offset Offset is used for different goal structure
     */
    private getFootballStatisticsEntry(responseStats: DoubleCounter, periodId: number, offset: number = 0): FootballStatsEntry | undefined {
        if (periodId < 1 || !responseStats || !responseStats.player1 || Object.keys(responseStats.player1).length < 3) {
            return undefined;
        }
        const result: FootballStatsEntry = {
            totalPoints: {
                participant1: responseStats.player1[255],
                participant2: responseStats.player2[255],
            },
        };

        const numberOfPeriods = Object.keys(responseStats.player1).length;

        result.firstHalf = {
            participant1: responseStats.player1[1],
            participant2: responseStats.player2[1],
        };

        if (periodId >= 3 && numberOfPeriods > 4) {
            result.secondHalf = {
                participant1: responseStats.player1[3],
                participant2: responseStats.player2[3],
            };
            result.regularTime = {
                // 254, result after regular time, 255 in case of goals
                participant1: responseStats.player1[254 + offset],
                participant2: responseStats.player2[254 + offset],
            };
        }

        if (periodId >= 5 && numberOfPeriods > 5) {
            // overtime
            result.extraTime = {
                // 253, overtime result only
                participant1: responseStats.player1[253],
                participant2: responseStats.player2[253],
            };
        }

        return result;
    }

    private getIcehockeyStats(scoreboard: IceHockey, periodId: number): IcehockeyStatisticsEntry | undefined {
        if (!scoreboard) {
            return undefined;
        }
        if (!scoreboard.penaltyPoints && !scoreboard.penaltyShots && !scoreboard.penaltyTotalTime) {
            return undefined;
        }

        return {
            penaltyPoints: this.getBaseStatisticsEntry(scoreboard.penaltyPoints, periodId),
            penaltyShots: this.getBaseStatisticsEntry(scoreboard.penaltyShots, periodId),
            penaltyTotalTime: this.getBaseStatisticsEntry(scoreboard.penaltyTotalTime, periodId),
        };
    }

    private getAmericanFootballStatistics(scoreboard: AmericanFootball, periodId: number): AmericanFootballStatistics | undefined {
        if (!scoreboard) {
            return undefined;
        }
        if (
            !scoreboard.turnovers &&
            !scoreboard.penalties &&
            !scoreboard.touchdowns &&
            !scoreboard.fieldGoals &&
            !scoreboard.safeties &&
            !scoreboard.onePointConversions &&
            !scoreboard.twoPointsConversions
        ) {
            return undefined;
        }

        return {
            turnovers: this.getBaseStatisticsEntry(scoreboard.turnovers, periodId),
            penalties: this.getBaseStatisticsEntry(scoreboard.penalties, periodId),
            touchdowns: this.getBaseStatisticsEntry(scoreboard.touchdowns, periodId),
            fieldGoals: this.getBaseStatisticsEntry(scoreboard.fieldGoals, periodId),
            safeties: this.getBaseStatisticsEntry(scoreboard.safeties, periodId),
            onePointConversions: this.getBaseStatisticsEntry(scoreboard.onePointConversions, periodId),
            twoPointsConversions: this.getBaseStatisticsEntry(scoreboard.twoPointsConversions, periodId),
            activeDown: scoreboard.activeDown,
            yardsDistance: scoreboard.yardsDistance,
            yardsToNextDown: scoreboard.yardsToNextDown,
            timeoutsLeft: scoreboard.timeoutsLeft,
            timeValue: scoreboard.timeValue,
        };
    }

    private getSnookerStats(scoreboard: Snooker, periodId: number): SnookerStats | undefined {
        if (!scoreboard) {
            return undefined;
        }
        if (
            !scoreboard.redBalls &&
            !scoreboard.blackBalls &&
            !scoreboard.pinkBalls &&
            !scoreboard.greenBalls &&
            !scoreboard.blueBalls &&
            !scoreboard.brownBalls &&
            !scoreboard.yellowBalls &&
            !scoreboard.fouls &&
            !scoreboard.frames &&
            !scoreboard.century100Breaks &&
            !scoreboard.breaks50
        ) {
            return undefined;
        }

        return {
            redBalls: this.getBaseStatisticsEntry(scoreboard.redBalls, periodId),
            blackBalls: this.getBaseStatisticsEntry(scoreboard.blackBalls, periodId),
            pinkBalls: this.getBaseStatisticsEntry(scoreboard.pinkBalls, periodId),
            greenBalls: this.getBaseStatisticsEntry(scoreboard.greenBalls, periodId),
            blueBalls: this.getBaseStatisticsEntry(scoreboard.blueBalls, periodId),
            brownBalls: this.getBaseStatisticsEntry(scoreboard.brownBalls, periodId),
            yellowBalls: this.getBaseStatisticsEntry(scoreboard.yellowBalls, periodId),
            fouls: this.getBaseStatisticsEntry(scoreboard.fouls, periodId),
            frames: this.getBaseStatisticsEntry(scoreboard.frames, periodId),
            century100Breaks: this.getBaseStatisticsEntry(scoreboard.century100Breaks, periodId),
            breaks50: this.getBaseStatisticsEntry(scoreboard.breaks50, periodId),
        };
    }

    private getBasketBallStats(scoreboard: Basketball, periodId: number): BasketballStatistics | undefined {
        if (!scoreboard) {
            return undefined;
        }
        if (
            !scoreboard.threePointers &&
            !scoreboard.twoPointers &&
            !scoreboard.freeThrowsScored &&
            !scoreboard.fouls &&
            !scoreboard.rebounds &&
            !scoreboard.assists &&
            !scoreboard.freeThrowsTotals
        ) {
            return undefined;
        }

        return {
            threePointers: this.getBasketBallStatisticsEntry(scoreboard.threePointers, periodId),
            twoPointers: this.getBasketBallStatisticsEntry(scoreboard.twoPointers, periodId),
            freeThrows: this.getBasketBallStatisticsEntry(scoreboard.freeThrowsScored, periodId),
            freeThrowsTotal: this.getBasketBallStatisticsEntry(scoreboard.freeThrowsTotals, periodId),
            fouls: this.getBasketBallStatisticsEntry(scoreboard.fouls, periodId),
            rebounds: this.getBasketBallStatisticsEntry(scoreboard.rebounds, periodId),
            assists: this.getBasketBallStatisticsEntry(scoreboard.assists, periodId),
            points: this.getBasketBallStatisticsEntry(scoreboard.totalPoints, periodId),
            raceTo10: this.getBasketBallStatisticsEntry(scoreboard.raceTo10, periodId),
            raceTo20: this.getBasketBallStatisticsEntry(scoreboard.raceTo20, periodId),
        };
    }

    /**
     * Returns single statistics entry e.g. corners, red cards, yellow cards etc
     *
     * @param responseStats Statistics counter from cds
     * @param periodId Current period id
     * @param offset Offset is used for different goal structure
     */
    private getBasketBallStatisticsEntry(responseStats: DoubleCounter, periodId: number): BasketballStatisticsEntry | undefined {
        const result: BasketballStatisticsEntry = {};

        if (periodId < 1 || !responseStats || !responseStats.player1) {
            return undefined;
        }

        result.firstQuarter = {
            participant1: responseStats.player1[1],
            participant2: responseStats.player2[1],
        };

        result.regularTime = {
            participant1: responseStats.player1[255],
            participant2: responseStats.player2[255],
        };
        if (periodId >= 3) {
            result.secondQuarter = {
                participant1: responseStats.player1[3],
                participant2: responseStats.player2[3],
            };
        }

        if (periodId >= 5) {
            result.thirdQuarter = {
                participant1: responseStats.player1[5],
                participant2: responseStats.player2[5],
            };
        }

        if (periodId >= 7) {
            result.fourthQuarter = {
                participant1: responseStats.player1[7],
                participant2: responseStats.player2[7],
            };
        }

        return result;
    }

    private getBaseStatisticsEntry(responseStats: DoubleCounter, periodId: number, isOuts: boolean = false): BaseStatisticsEntry | undefined {
        const result: BaseStatisticsEntry = {};

        if (periodId < 1 || !responseStats || !responseStats.player1) {
            return undefined;
        }

        result.regularTime = {
            participant1: isOuts ? responseStats.player1[periodId] : responseStats.player1[255],
            participant2: isOuts ? responseStats.player2[periodId] : responseStats.player2[255],
        };

        return result;
    }

    private getBaseballStatistics(scoreboard: Baseball, periodId: number): BaseballStatistics | undefined {
        if (!scoreboard) {
            return undefined;
        }
        if (!scoreboard.homeRun && !scoreboard.scoreDetailed) {
            return undefined;
        }

        return {
            grandSlams: this.getBaseStatisticsEntry(scoreboard.grandSlams, periodId),
            homeRun: this.getBaseStatisticsEntry(scoreboard.homeRun, periodId),
            outs: this.getBaseStatisticsEntry(scoreboard.outs, periodId, true),
            scoreDetailed: this.getBaseStatisticsEntry(scoreboard.scoreDetailed, periodId),
            balls: scoreboard.balls,
            strikes: scoreboard.strikes,
            bases: scoreboard.bases,
        };
    }

    private getTennisStats(scoreboard: Tennis, periodId: number): TennisStats | undefined {
        if (!scoreboard) {
            return undefined;
        }
        if (
            !scoreboard.aces &&
            !scoreboard.breaks &&
            !scoreboard.doubleFaults &&
            !scoreboard.winPointsPercentage &&
            !scoreboard.raceTo15 &&
            !scoreboard.raceTo20
        ) {
            return undefined;
        }

        return {
            aces: this.getTennisStatisticsEntry(scoreboard.aces, periodId),
            doubleFaults: this.getTennisStatisticsEntry(scoreboard.doubleFaults, periodId),
            breaks: this.getTennisStatisticsEntry(scoreboard.breaks, periodId),
            winPointsPercentage: this.getTennisStatisticsEntry(scoreboard.winPointsPercentage, periodId),
            raceTo15: this.getTennisStatisticsEntry(scoreboard.raceTo15, periodId),
            raceTo20: this.getTennisStatisticsEntry(scoreboard.raceTo20, periodId),
        };
    }

    /**
     * Returns single statistics entry e.g. corners, red cards, yellow cards etc
     *
     * @param responseStats Statistics counter from cds
     * @param periodId Current period id
     * @param offset Offset is used for different goal structure
     */
    private getTennisStatisticsEntry(responseStats: DoubleCounter, periodId: number): TennisStatsEntry | undefined {
        const result: TennisStatsEntry = { regularTime: { participant1: 0, participant2: 0 } };

        if (periodId < 1 || !responseStats || !responseStats.player1) {
            return undefined;
        }

        result.firstSet = {
            participant1: responseStats.player1[1],
            participant2: responseStats.player2[1],
        };
        result.regularTime = {
            participant1: responseStats.player1[255],
            participant2: responseStats.player2[255],
        };

        if (periodId >= 2) {
            result.secondSet = {
                participant1: responseStats.player1[2],
                participant2: responseStats.player2[2],
            };
        }

        if (periodId >= 3) {
            result.thirdSet = {
                participant1: responseStats.player1[3],
                participant2: responseStats.player2[3],
            };
        }

        if (periodId >= 4) {
            result.fourthSet = {
                participant1: responseStats.player1[4],
                participant2: responseStats.player2[4],
            };
        }

        if (periodId >= 5) {
            result.fifthSet = {
                participant1: responseStats.player1[5],
                participant2: responseStats.player2[5],
            };
        }

        return result;
    }

    private getSuspended(sport: number, period?: number): boolean | undefined {
        const map = {
            [SportConstant.AmericanFootball]: AmericanFootballPeriodInfo,
            [SportConstant.Basketball]: BasketballPeriodInfo,
        };

        const sports = [
            SportConstant.Cricket,
            SportConstant.Boxing,
            SportConstant.Rugby,
            SportConstant.RugbyLeague,
            SportConstant.RugbyUnion,
            SportConstant.Soccer,
            SportConstant.Baseball,
        ];
        const setSports = [SportConstant.Volleyball, SportConstant.TableTennis, SportConstant.Tennis];

        if ((sports.includes(sport) && period === 256) || (setSports.includes(sport) && period === 6)) {
            return true;
        }

        if (!period || !map[sport]) {
            return undefined;
        }

        return period in map[sport];
    }

    private getTennisSetCount(matchType?: number, sets?: any[]): number {
        if (!matchType) {
            if (sets && sets.length) {
                return sets[0].length;
            }

            return 3;
        }

        if (matchType === MatchType.BestOfThree || matchType === MatchType.BestOfThreeChampionsTieBreak) {
            return 3;
        }

        return 5;
    }

    private getPenalityAmount(scoreboard: IceHockey, index: number): number {
        let penaltyAmount = 0;
        if (
            scoreboard.penalties &&
            scoreboard.penalties.length > 0 &&
            scoreboard.penalties[index] &&
            scoreboard.penalties[index].values &&
            scoreboard.penalties[index].values.length > 0
        ) {
            penaltyAmount = scoreboard.penalties[index].values.length;
        } else {
            penaltyAmount = 0;
        }

        return penaltyAmount;
    }

    private isCurrentSet(sportId: number, index: number, periodId?: number): boolean {
        return (
            (sportId === SportConstant.Volleyball ||
                sportId === SportConstant.Squash ||
                sportId === SportConstant.TableTennis ||
                sportId === SportConstant.Beachvolleyball ||
                sportId === SportConstant.Badminton) &&
            2 * index + 1 === periodId
        );
    }

    getParticipantListKeyValue(list: { [key: number]: Participant }): KeyValuePairScoreboard[] {
        const participantsInfo: KeyValuePairScoreboard[] = [];
        const items: Participant[] = [];
        let name: string;
        let country: string;
        let participants: string[];
        for (let index = 0; index < Object.keys(list).length; index++) {
            items.push(list[index]);
        }
        items.sort((x, y) => x.rank - y.rank);
        items.forEach((participant) => {
            if (participant) {
                participants = participant.name.split('(');
                if (Object.keys(participants).length > 0) {
                    name = participants[0];
                    if (participants[1]) {
                        country = participants[1].slice(0, -1);
                    }
                    participantsInfo.push({ name, country });
                }
            }
        });

        return participantsInfo;
    }

    getParticipantList(list: { [key: number]: Participant }): string[] {
        const participantList: string[] = [];
        const items: Participant[] = [];
        let participants: string[];
        for (let index = 0; index < Object.keys(list).length; index++) {
            items.push(list[index]);
        }
        items.sort((x, y) => x.rank - y.rank);
        items.forEach((participant) => {
            if (participant) {
                participants = participant.name.split('(');
                participantList.push(participants[0]);
            }
        });

        return participantList;
    }

    getSportsLiveEventInfo(sportId: number, scoreboard?: EventScoreboard): string | undefined {
        return this.sportsScoreboardServices[sportId]?.getLiveEventInfo(scoreboard) ?? '';
    }
}
