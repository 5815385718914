import { Directive, ElementRef, OnDestroy, computed, effect, inject, input } from '@angular/core';
import { Router } from '@angular/router';

import { UrlService } from '@frontend/vanilla/core';

import { QuicklinkObserver } from './quicklink-observer.service';

@Directive({ selector: '[msQuicklink]', standalone: true })
export class QuicklinkDirective implements OnDestroy {
    readonly #observer = inject(QuicklinkObserver);
    readonly #router = inject(Router);
    readonly #urlService = inject(UrlService);

    path = input.required<string>({ alias: 'msQuicklink' });
    urlTree = computed(() => {
        if (!this.path()) {
            return null;
        }

        const parsedUrl = this.#urlService.parse(this.path());
        return this.#router.createUrlTree([parsedUrl.url()]);
    });

    element = inject<ElementRef<HTMLElement>>(ElementRef).nativeElement;

    #register = effect(() => {
        this.#observer.unregister(this);
        if (this.urlTree()) {
            this.#observer.register(this);
        }
    });

    ngOnDestroy() {
        this.#observer.unregister(this);
        this.#register.destroy();
    }
}
