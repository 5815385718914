import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { BetslipType } from '../../../../core/betslip-type';
import { SlipId } from '../../../bet-generation/models';
import { BetPlacementError } from '../../errors/bet-placement-error';
import { StakeHintInfo } from '../../models/stake-validation.models';
import { BetslipTypePickErrors } from '../../state';

const ignoreNewStakeHintType = [PlacementErrorType.UnderMinimumOddsBoostReward, PlacementErrorType.UnderMinimumRiskFreeCompensation];

export function getStakeLimitErrorInfo(errors: { pickErrors: BetslipTypePickErrors; slipErrors: Record<SlipId, BetPlacementError[]> }): {
    betslipStakeHintInfo: { newStakeHint: number; type: PlacementErrorType } | null;
    pickStakeHintInfos: { newStakeHint: number; pickId: string; type: PlacementErrorType }[];
} {
    const betslipStakeLimitExceededError = Object.values(errors.slipErrors)
        .flatMap((e) => e)
        .find((betslipError) => shouldUpdateStake(betslipError));

    const pickStakeLimitExceededErrors = Object.values(errors.pickErrors)
        .flatMap((e) => Object.values(e))
        .flatMap((resultErrors) => resultErrors)
        .filter((e) => shouldUpdateStake(e));

    if (!betslipStakeLimitExceededError && pickStakeLimitExceededErrors.length === 0) {
        return { betslipStakeHintInfo: null, pickStakeHintInfos: [] };
    }

    if (betslipStakeLimitExceededError) {
        return {
            betslipStakeHintInfo: { newStakeHint: betslipStakeLimitExceededError.newStakeHint!, type: betslipStakeLimitExceededError.type },
            pickStakeHintInfos: [],
        };
    }

    return {
        betslipStakeHintInfo: null,
        pickStakeHintInfos: pickStakeLimitExceededErrors.map((pickError) => ({
            type: pickError.type,
            pickId: pickError.pickId,
            newStakeHint: pickError.newStakeHint!,
        })),
    };
}

export function getStakeHints(errors: { pickErrors: BetslipTypePickErrors; slipErrors: Record<SlipId, BetPlacementError[]> }): StakeHintInfo {
    const slipStakeHintInfos = Object.entries(errors.slipErrors).flatMap(([key, slipErrors]) =>
        slipErrors.filter(shouldUpdateStake).map((error) => ({ newStakeHint: error.newStakeHint!, slipId: key, type: error.type })),
    );

    // This is only relevant for multi-singles
    const pickStakeHintInfos = Object.entries(errors.pickErrors[BetslipType.Single])
        .flatMap(([pickId, e]) => e.filter((error) => shouldUpdateStake(error)).map((error) => ({ pickId, error })))
        .map(({ pickId, error }) => ({
            type: error.type,
            // we cannot use error.pickId here
            // after bet placement we are also mapping normal betslip errors to ResultError and therefore error.pickId could be undefined
            pickId,
            newStakeHint: error.newStakeHint!,
        }));

    return {
        slipStakeHintInfos,
        pickStakeHintInfos,
    };
}

export function shouldUpdateStake(error: BetPlacementError): boolean {
    return isNewStakeHintError(error) && !ignoreNewStakeHintType.includes(error.type);
}

export function isNewStakeHintError(error: BetPlacementError): boolean {
    return typeof error.newStakeHint === 'number' && error.newStakeHint > 0;
}
