import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { mapValues } from 'lodash-es';
import { BehaviorSubject, Observable } from 'rxjs';

import { CalendarService } from '../calendar/calendar.service';
import { BreadcrumbModel } from './breadcrumb.models';
import { BreadcrumbRouteVisibilityService } from './breadcrumbs-visibility.service';

export interface Breadcrumbs {
    breadcrumbs: BreadcrumbModel[];
    visible: boolean;
}

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService {
    LEAGUE_TYPES = {
        league: [4, 7, 11, 12, 16, 18, 21, 22, 23, 27, 28, 31, 32, 36, 49, 52, 60, 61, 62, 70, 71, 88],
        tournament: [5, 13, 24, 33, 34, 35, 38, 44, 45, 47, 48, 50, 53, 56, 58, 63, 67, 68, 69, 74, 75, 76, 77, 87, 90, 93],
        race: [6, 10, 14, 15, 29, 30, 37, 39, 40, 41, 42, 43, 46, 51, 54, 55, 91],
        competition: [9, 17, 26, 57, 59, 64, 65, 66, 72, 73, 78, 89, 80, 81, 82, 83, 84, 85, 86, 89, 92, 94, 95, 96, 100],
    };

    // View details
    types = {
        AZ: 'AZ',
        REGION: 'REGION',
        SPORT: 'SPORT',
        LEAGUE: 'LEAGUE',
        TOPLEAGUE: 'TOPLEAGUE',
        FILTER: 'FILTER',
    };

    private _breadcrumbs$ = new BehaviorSubject<Breadcrumbs>({ breadcrumbs: [], visible: false });

    constructor(
        private sitecore: Sitecore,
        private calendarService: CalendarService,
        private breadcrumbVisibility: BreadcrumbRouteVisibilityService,
    ) {
        this.breadcrumbVisibility.visibility$.subscribe(this.onBreadcrumbVisibilityChange);
    }

    get breadcrumbs$(): Observable<Breadcrumbs> {
        return this._breadcrumbs$.asObservable();
    }

    setBreadcrumbs(breadcrumbs: BreadcrumbModel[]): void {
        this._breadcrumbs$.next({ breadcrumbs, visible: this.breadcrumbVisibility.isVisible(breadcrumbs) });
    }

    private onBreadcrumbVisibilityChange = (): void => {
        const breadcrumbs = this._breadcrumbs$.value.breadcrumbs;
        this.setBreadcrumbs(breadcrumbs);
    };

    getTitle(type: string, id: string = ''): string {
        const sitecoreMessages = this.sitecore.globalMessages;

        switch (type) {
            case this.types.TOPLEAGUE:
                const topLeagueTypeToMessage = {
                    tournament: sitecoreMessages['TopTournaments'],
                    race: sitecoreMessages['TopRaces'],
                    competition: sitecoreMessages['TopCompetitions'],
                };

                return topLeagueTypeToMessage[this.getLeagueType(id)] || sitecoreMessages['TopLeagues'];

            case this.types.FILTER:
                return this.getFilterTitle(id);
            case this.types.REGION:
                return !id ? this.sitecore.globalMessages.Competitions : '';
            case this.types.LEAGUE:
                const leagueTypeToMessage = {
                    tournament: sitecoreMessages['Tournaments'],
                    race: sitecoreMessages['Races'],
                    competition: sitecoreMessages['Competitions'],
                };

                return leagueTypeToMessage[this.getLeagueType(id)] || sitecoreMessages['Leagues'];
            case this.types.AZ:
                return sitecoreMessages['NavAllSports'];
            default:
                return '';
        }
    }

    getIcon(type: string, id?: string): string {
        switch (type) {
            case this.types.AZ:
                return Number(id) === 5 ? 'sports-tournament' : 'sports-globe';
            case this.types.REGION:
                return id ? `country-icon c${id}` : 'theme-competitions-all';
            case this.types.SPORT:
                return `sports-${id}`;
            case this.types.LEAGUE:
                const iconForLeagueType = {
                    tournament: 'sports-tournament',
                    competition: 'sports-competition',
                };

                return (id && iconForLeagueType[this.getLeagueType(id)]) || 'sports-league';
            case this.types.FILTER:
                return this.getFilterById(id);
            default:
                return '';
        }
    }

    private getFilterById(id?: string): string {
        id = id || '';

        const filterMap = {
            'all': 'sports-globe',
            'calendar': 'sports-calendar',
            'live': 'theme-live',
            'live-calendar': 'theme-live',
            'tournaments': 'sports-tournament',
        };

        const calendarMap = mapValues(this.calendarService.getCalendarIntervals(), () => 'sports-soon');

        return filterMap[id] || calendarMap[id] || '';
    }

    private getLeagueType(sportId: string): string {
        for (const key in this.LEAGUE_TYPES) {
            if (this.LEAGUE_TYPES[key].indexOf(Number(sportId)) !== -1) {
                return key;
            }
        }

        return '';
    }

    private getFilterTitle(id: string): string {
        const interval = this.calendarService.getInterval(id);

        if (interval) {
            return interval.title;
        }

        const filterTitle = {
            'all': this.sitecore.globalMessages.Competitions,
            'calendar': this.sitecore.globalMessages.Calendar,
            'live': this.sitecore.globalMessages.NavLive,
            'live-calendar': this.sitecore.globalMessages.NavLive,
            'tournaments': this.sitecore.globalMessages.Tournaments,
        };

        return filterTitle[id] || '';
    }
}
