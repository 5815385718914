import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { CrmConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { CrmPromotionsBanners } from '@frontend/sports/types/components/sitecore';
import { WindowRef } from '@frontend/vanilla/core';

import { Promotion } from '../crm-offer-data/crm-offer.model';
import { TrackingSource } from '../tracking/tracking.models';
import { UserService } from '../user/services/user.service';
import { BannerService } from './banner.service';
import { OfferStatusService } from './offer-status.service';
import { PromotionStatusButtonComponent } from './promotion-status-button.component';
import { CTALocation, CrmPromotionTracking } from './tracking.service';

@Component({
    selector: 'ms-promotion-status-button-wrapper',
    templateUrl: 'promotion-status-button-wrapper.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PromotionStatusButtonComponent],
})
export class PromotionStatusButtonWrapperComponent {
    translations: CrmPromotionsBanners;

    @Input() promotion: Promotion;
    @Input() tracking?: TrackingSource;
    @Output() detailsPopupChange = new EventEmitter<boolean>();
    @Output() optInButtonClicked = new EventEmitter<boolean>();

    @HostBinding('class') className = 'info-panel';

    private optInRedirect = false;
    optInInvisible = false;

    constructor(
        private windowRef: WindowRef,
        private trackingService: CrmPromotionTracking,
        private crmConfig: CrmConfig,
        private m2User: UserService,
        public sitecore: Sitecore,
        private offerStatusService: OfferStatusService,
        private changeDetectorRef: ChangeDetectorRef,
        private bannerService: BannerService,
    ) {
        this.translations = sitecore.crmPromotionsBanners;

        const { optInRedirectBlackListedCountries, optInInvisibleBlackListedCountries } = this.crmConfig.promotions;
        this.optInRedirect = optInRedirectBlackListedCountries.includes(this.m2User.country);
        this.optInInvisible = optInInvisibleBlackListedCountries.includes(this.m2User.country);
    }

    async onButtonClick(event: Event): Promise<void> {
        event.stopPropagation();

        if (this.optInRedirect) {
            this.detailsPopupChange.emit(true);
            await this.bannerService.openDetailsPage(this.promotion, this.tracking, () => {
                this.detailsPopupChange.emit(false);
                this.changeDetectorRef.detectChanges();
            });

            return;
        }

        this.setOfferStatus();
        this.optInButtonClicked.emit(true);
    }

    private setOfferStatus(): void {
        this.trackClick(CTALocation.OptIn);

        this.offerStatusService.setOfferStatus(this.promotion).subscribe((response) => {
            this.promotion.status = response;
            this.trackingService.trackOptinResult({ promotion: this.promotion, trackingSource: this.tracking?.source, status: response });
            this.changeDetectorRef.detectChanges();
        });
    }

    async moreInfoClick(event?: Event): Promise<void> {
        event && event.stopPropagation();

        this.trackClick(CTALocation.MoreDetails);

        const moreInfoLink = this.promotion.banner && this.promotion.banner.moreInfo;
        if (moreInfoLink) {
            this.windowRef.nativeWindow.open(moreInfoLink, '_blank');

            return;
        }
        this.detailsPopupChange.emit(true);
        await this.bannerService.openDetailsPage(this.promotion, this.tracking, () => {
            this.detailsPopupChange.emit(false);
            this.changeDetectorRef.detectChanges();
        });
    }

    closeDialog(): void {
        this.bannerService.detailsPopupRef?.close();
    }

    private trackClick(ctaLocation: CTALocation): void {
        this.trackingService.trackCTA({
            promotion: this.promotion,
            trackingSource: this.tracking?.source,
            ctaLocation,
            position: this.tracking?.position,
        });
    }
}
