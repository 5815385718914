import { FixtureViewType } from '@cds/betting-offer';
import { EventParticipant } from 'packages/sports/web/app/src/event-model/model/event.model';

import { BetslipPick } from '../betslip-pick';
import { BetslipV2WinParticipantPick } from '../betslip-v2-win-participant-pick';
import { GolfPick, IBetslipV2GolfPickFixture, IBetslipV2GolfWinParticipantPickStorage, IBetslipV2PickFixture, PickSubType } from '../pick-models';
import { SignedName } from '../signed-name.model';

export class BetslipV2GolfWinParticipantPick extends BetslipV2WinParticipantPick implements GolfPick {
    override fixture: IBetslipV2GolfPickFixture;
    override get eventParticipants(): EventParticipant[] | undefined {
        return undefined;
    }

    override get eventViewType(): FixtureViewType | undefined {
        return undefined;
    }

    static override isPick(pick: BetslipPick): pick is BetslipV2GolfWinParticipantPick {
        return pick instanceof BetslipV2GolfWinParticipantPick;
    }

    constructor() {
        super();
    }

    static fromJSON(value: IBetslipV2GolfWinParticipantPickStorage): BetslipV2GolfWinParticipantPick {
        const pick = new BetslipV2GolfWinParticipantPick();
        pick.initPropertiesFromJSON(value);

        return pick;
    }

    protected override initPropertiesFromJSON(value: IBetslipV2GolfWinParticipantPickStorage): void {
        super.initPropertiesFromJSON(value);
        this.fixture = {
            ...(this.fixture as IBetslipV2PickFixture),
            fixtureGroup: value.fixture.fixtureGroup,
        };
    }

    override toJSON(): IBetslipV2GolfWinParticipantPickStorage {
        const base = super.toJSON();

        return {
            ...base,
            fixture: {
                ...base.fixture,
                fixtureGroup: {
                    id: this.fixture.fixtureGroup.id,
                    name: {
                        name: this.fixture.fixtureGroup.name.name,
                        signature: this.fixture.fixtureGroup.name.signature,
                    },
                },
            },
            pickSubType: PickSubType.GolfWinPick,
        };
    }

    copy(): BetslipV2GolfWinParticipantPick {
        const storage = this.toJSON();

        return BetslipV2GolfWinParticipantPick.fromJSON(storage);
    }

    override get eventName(): SignedName {
        return this.fixture.fixtureGroup.name;
    }

    get marketName(): SignedName {
        return this.fixture.name;
    }
}
