import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { BetPlacementErrorIcon } from '../bet-placement-error-icon';
import { UserError } from './user-error';

export class OverallMaxWinPerUser extends UserError {
    constructor(comboWin: number | undefined, maxComboWin: number | undefined) {
        super();
        this.comboWin = comboWin == null ? null : comboWin;
        this.maxComboWin = maxComboWin == null ? null : maxComboWin;
        this.icon = BetPlacementErrorIcon.Warning;
        this.type = PlacementErrorType.OverallMaxWinPerUserExceeded;
    }

    comboWin: number | null;
    maxComboWin: number | null;
}
