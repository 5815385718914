export class EllapsedTimer {
    private startedAt: number = Date.now();

    private constructor() {}

    get ellapsedMilliseconds(): number {
        return Date.now() - this.startedAt;
    }

    static start(): EllapsedTimer {
        return new EllapsedTimer();
    }
}
