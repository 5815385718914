import { EdsPromoTokenType } from '@bpos';
import { EdsPromotionToken } from '@bpos/v1/my-bets';
import { RewardTargetType } from '@bpos/v1/sports-promo/tokens';
import { FixturePricingState } from '@cds/betting-offer/add-ons';
import { Nullable, hasValue, isDefined } from '@frontend/sports/common/base-utils';
import { Decimal } from 'decimal.js';
import { orderBy } from 'lodash-es';
import { BestOddsFixtureLikeInput } from 'packages/sports/web/app/src/best-odds-guarantee/models/models';
import { BuildABet, IRewardTokenData, PayoutType, RewardTokenType } from 'packages/sports/web/app/src/tokens-base/token-base.models';

import { BetslipType } from '../../../core/betslip-type';
import { CalculatedOdds, Odds, emptyCalculatedOdds, emptyOdds } from '../../../core/odds/odds';
import { OddsOperations } from '../../../core/odds/operations';
import { BetslipPick } from '../../../core/picks/betslip-pick';
import { BetBuilderPickId, GroupPickId } from '../../../core/picks/pick-id';
import { PickOddsState, PriceType } from '../../../core/picks/pick-models';
import {
    BetslipV2HorseRaceOptionMarketPick,
    BetslipV2HorseRaceWinParticipantPick,
    BetslipV2HorseRaceXCastPick,
} from '../../../core/picks/sport-specific/betslip-v2-horse-race-picks';
import { filterPicksForType } from '../../picks/services/linear-betslip-pick.utils';
import { ISlipSummary } from '../../summary/models';
import { IBetslipTypeState } from '../../types/state';
import { isErrorComboPrevention } from '../../validation/services/utils/betslip-errors-utils';
import { BetslipTypePickErrors } from '../../validation/state';
import {
    AccaBoostLadderItem,
    AccaBoostToken,
    AvailableTokensType,
    BetAndGetToken,
    CriteriaType,
    FreeBetToken,
    IRewardToken,
    OddsBoostToken,
    RewardTokenContext,
    RiskFreeToken,
    TokenOddsData,
} from '../reward-tokens.model';
import { IRewardTokenEligibility, IRewardTokenEligibilityState, TokensRecord } from '../state';

export function isFreebetToken(token?: IRewardToken | IRewardTokenData | null): token is FreeBetToken {
    return !!token && token.rewardTokenType === RewardTokenType.FreeBet;
}

export function isOddsBoostToken(token: IRewardToken | IRewardTokenData | null): token is OddsBoostToken {
    return !!token && token.rewardTokenType === RewardTokenType.OddsBoost;
}

export function isRiskFreeToken(token: IRewardToken | IRewardTokenData | null): token is RiskFreeToken {
    return !!token && token.rewardTokenType === RewardTokenType.RiskFreeBet;
}

export function isBetAndGetToken(token: IRewardToken | IRewardTokenData | null): token is BetAndGetToken {
    return !!token && token.rewardTokenType === RewardTokenType.BetAndGet;
}

export function isAccaBoostToken(token?: Nullable<IRewardToken>): token is AccaBoostToken {
    return !!token && token.rewardTokenType === RewardTokenType.AccaBoost;
}

export function getAcquisitionRewardToken(tokens: IRewardToken[]): IRewardToken | undefined {
    return tokens.find((token) => isAcquisitionRewardToken(token));
}

const isAcquisitionRewardToken = (token: IRewardToken): boolean => token.rewardTargetType === RewardTargetType.WelcomeOffer;

export function getPayoutExpiryInDays(token: IRewardToken): string {
    if (!token.payoutExpiry) {
        return '1';
    }

    const expiry = token.payoutExpiry / 24;

    return (expiry <= 1 ? 1 : Math.round(expiry)).toString();
}

export function getTokensType(tokens: IRewardToken[]): AvailableTokensType {
    if (tokens.every((token) => isFreebetToken(token))) {
        return AvailableTokensType.FreeBet;
    }
    if (tokens.every((token) => isOddsBoostToken(token))) {
        return AvailableTokensType.OddsBoost;
    }
    if (tokens.every((token) => isRiskFreeToken(token))) {
        return AvailableTokensType.RiskFreeBet;
    }
    if (tokens.every((token) => isAccaBoostToken(token))) {
        return AvailableTokensType.AccaBoost;
    }

    if (tokens.every((token) => isBetAndGetToken(token))) {
        return AvailableTokensType.BetAndGet;
    }

    return AvailableTokensType.MultipleRewards;
}

export function getNonAccaTokens(tokens: IRewardToken[]): IRewardToken[] {
    return tokens.filter((token) => !isAccaBoostToken(token));
}

export function getSortedNonAccaTokens(tokens: IRewardToken[]): IRewardToken[] {
    return orderBy(getNonAccaTokens(tokens), (token) => isAcquisitionRewardToken(token), 'desc');
}

export function findAccaBoostLadderItem(token: AccaBoostToken, count: number): AccaBoostLadderItem {
    return token.boostLadder.reduce((previous, current) => {
        return current.numberOfLegs <= count ? current : previous;
    });
}

export function getAccaBoostRatio(token: AccaBoostToken, count: number): Decimal {
    return new Decimal(findAccaBoostLadderItem(token, count).ratio);
}

export function findMinimumLegsAccaTokens(eligibilityState: IRewardTokenEligibilityState, tokens: TokensRecord): AccaBoostToken[] {
    const accaTokens = Object.values(tokens).filter(isAccaBoostToken);

    return accaTokens.sort(byAccaPriority).filter((token) => {
        const eligibility = eligibilityState.comboBet.find((item) => item.tokenId === token.userTokenId);

        return !!eligibility && eligibility.invalidHardCriteria === CriteriaType.MinimumLegs;
    });
}

export function findMinimumOddsAccaTokens(eligibilityState: IRewardTokenEligibilityState, tokens: TokensRecord): AccaBoostToken | undefined {
    const accaTokens = Object.values(tokens).filter(isAccaBoostToken);

    return accaTokens.sort(byAccaPriority).find((token) => {
        const eligibility = eligibilityState.comboBet.find((item) => item.tokenId === token.userTokenId);

        return !!eligibility && eligibility.invalidHardCriteria === CriteriaType.MinOdds;
    });
}

export const byAccaPriority = (first: AccaBoostToken, second: AccaBoostToken): number => first.priority - second.priority;

export function isValidAccaBoostCombo(
    typePickErrors: BetslipTypePickErrors,
    pickList: BetslipPick[],
    types: IBetslipTypeState,
    isLinear: boolean,
): boolean {
    const type = types.base.currentSelectedType;

    const isValidTab = (type === BetslipType.Combo && !types.comboBet.isEachWay) || type === BetslipType.Single;

    if (!type || !isValidTab) {
        return false;
    }

    const selectedPicks = filterPicksForType(types, type, pickList, {
        isLocked: isLinear ? false : undefined,
        isSelected: true,
    });

    if (selectedPicks.length < 2) {
        return false;
    }

    const allValidPicks = selectedPicks.every(({ oddsState }) => oddsState === PickOddsState.Open);

    if (!allValidPicks) {
        return false;
    }

    const pickErrors = typePickErrors[type];

    return !Object.values(pickErrors)
        .flatMap((errors) => errors)
        .some((e) => isErrorComboPrevention(e));
}

export function calcTotalOdds(picks: BetslipPick[], roundPriceValues: boolean = false): CalculatedOdds {
    const totalOdds = picks.reduce(
        (odds: CalculatedOdds, pick: BetslipPick) => {
            if (
                (OddsOperations.isOddsEmpty(odds),
                !pick.currentPrice || pick.priceType !== PriceType.Fixed || OddsOperations.isOddsEmpty(pick.currentPrice.nativeOdds))
            ) {
                odds = emptyCalculatedOdds;
            } else {
                odds = OddsOperations.multiply([odds, OddsOperations.toCalculatedOdds(pick.currentPrice!.nativeOdds)], roundPriceValues);
            }

            return odds;
        },
        OddsOperations.fromDecimalValue(new Decimal(1)),
    );

    return totalOdds;
}

export function calcTotalOddsForOddsBoost(picks: BetslipPick[], roundPriceValues: boolean = false): CalculatedOdds {
    const oddsArray = picks.filter((p) => p.currentPrice?.nativeOdds).map((p) => OddsOperations.toCalculatedOdds(p.currentPrice!.nativeOdds));

    return OddsOperations.multiply(oddsArray, roundPriceValues);
}

export function calculateWinningsBoost(slipSummary: ISlipSummary): Decimal {
    const boostedWinnings = slipSummary.rewardTokenModifications?.oddsBoost?.boostedWinnings;
    const possibleWinnings = slipSummary.possibleWinnings;

    return boostedWinnings && possibleWinnings ? boostedWinnings.minus(possibleWinnings) : new Decimal(0);
}

export function convertToBestOddsFixtureLikeInput(
    pick: BetslipV2HorseRaceOptionMarketPick | BetslipV2HorseRaceWinParticipantPick | BetslipV2HorseRaceXCastPick,
): BestOddsFixtureLikeInput {
    const horseFixture = pick.fixture;
    const fixture: BestOddsFixtureLikeInput = {
        startDate: pick.fixture.eventDate.toISOString(),
        fixtureType: pick.fixture.fixtureType,
        sport: { id: horseFixture.sportId },
        meeting: hasValue(horseFixture.league) ? { id: horseFixture.league.id } : undefined,
        region: hasValue(horseFixture.region) ? { id: horseFixture.region.id } : undefined,
        competition: hasValue(horseFixture.league) ? { id: horseFixture.league.id } : undefined,
        addons: {
            pricingState: FixturePricingState.None,
            bestOddsGuarantee:
                horseFixture.bestOddsGuarantee && pick.currentPrice?.type !== PriceType.StartingPrice && !BetslipV2HorseRaceXCastPick.isPick(pick),
        },
    };

    return fixture;
}

export function getAllEligibleTokens(eligibilityState: IRewardTokenEligibilityState, tokens: TokensRecord): IRewardToken[] {
    const ids: string[] = [];

    Object.values(eligibilityState.singleBet)
        .flatMap((e) => e)
        .forEach((eligibility) => {
            if (!eligibility.isEligible || ids.includes(eligibility.tokenId)) {
                return;
            }

            ids.push(eligibility.tokenId);
        });

    Object.values(eligibilityState.betBuilder)
        .flatMap((e) => e)
        .forEach((eligibility) => {
            if (!eligibility.isEligible || ids.includes(eligibility.tokenId)) {
                return;
            }

            ids.push(eligibility.tokenId);
        });

    eligibilityState.comboBet.forEach((eligibility) => {
        if (!eligibility.isEligible || ids.includes(eligibility.tokenId)) {
            return;
        }

        ids.push(eligibility.tokenId);
    });

    eligibilityState.teaserBet.forEach((eligibility) => {
        if (!eligibility.isEligible || ids.includes(eligibility.tokenId)) {
            return;
        }

        ids.push(eligibility.tokenId);
    });

    return ids.map((id) => tokens[id]).filter(isDefined);
}

export function isBetBuilderExclusiveToken(token: IRewardToken | BetAndGetToken) {
    return (
        (token.isBetBuilder && !token.filter.betSlipType && !token.isSingleAndComboSelected) ||
        ('buildABet' in token && token.buildABet === BuildABet.Yes)
    );
}
export function isBetBuilderToken(token: IRewardToken | BetAndGetToken) {
    return token.isBetBuilder || ('buildABet' in token && token?.buildABet !== BuildABet.No);
}

// This is only used for quick-bet-builder-drawer, therefore we do not need
export function getTokenContextForBetBuilderPickId(
    pickId: BetBuilderPickId | GroupPickId,
    isSportcastAsComboEnabled: boolean,
    isLinear: boolean,
): RewardTokenContext {
    if (isLinear) {
        return {
            betslipType: BetslipType.BetBuilder,
            pickId,
        };
    }

    const isComboBetContext = GroupPickId.isId(pickId) || isSportcastAsComboEnabled;

    return {
        betslipType: isComboBetContext ? BetslipType.Combo : BetslipType.Single,
        pickId: isComboBetContext ? undefined : pickId,
    };
}

export function mapOdds(oddsPerLeg: TokenOddsData | undefined): Odds {
    if (!oddsPerLeg) {
        return emptyOdds;
    }

    const { european, britishNumerator, britishDenominator, american } = oddsPerLeg;

    return OddsOperations.createOdds({
        odds: european,
        numerator: britishNumerator,
        denominator: britishDenominator,
        americanOdds: american,
    });
}

export function parseMarketType(token: IRewardToken): string[] {
    const marketTypes = token.customMarketTypes ? token.customMarketTypes.split(',') ?? [] : token.filter.marketParameters.map((mt) => mt.marketType);

    return marketTypes;
}

export function isIncrementalPayoutEligible(token: IRewardToken, stake?: number): boolean {
    return (
        token.payoutType === PayoutType.FreeBet &&
        !!token.isIncrementalPayout &&
        !!token.incrementalBetAmount &&
        !!token.incrementalPayoutNoOfTimes &&
        !!stake &&
        stake >= token.incrementalBetAmount &&
        token.incrementalPayoutNoOfTimes > 1
    );
}

export function isEdsPromoTokenBetAndGet(edsPromoTokens: EdsPromotionToken[] | undefined): boolean {
    return edsPromoTokens?.length === 1 && edsPromoTokens[0].tokenType === EdsPromoTokenType.BetAndGet;
}

export function isAcquisitionEligible(token: IRewardTokenEligibility, acquisitionUserTokenId: string): boolean {
    return token.isEligible && !!Object.values(token.softCriteriasValidity).every(Boolean) && acquisitionUserTokenId === token.tokenId;
}
