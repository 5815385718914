import { Injectable, inject } from '@angular/core';

import { PersonalizedMarqueeConfig } from '@frontend/sports/common/client-config-data-access';
import { MarqueePayload } from '@frontend/sports/types/components/widget/types';
import { Observable, map } from 'rxjs';

import { CookieProvider } from '../cookie-provider/cookie-provider.service';
import { CrmPromotionProviderService } from '../crm-promotion/crm-promotion-provider.service';
import { MarqueeComponentTile, Tile } from './marquee-tile.model';
import { MarqueeTileService } from './marquee-tile.service';

@Injectable({ providedIn: 'root' })
export class MarqueeWidgetDataService {
    private readonly cookieProvider = inject(CookieProvider);
    private readonly personalizedMarqueeConfig = inject(PersonalizedMarqueeConfig);
    private readonly promoService = inject(CrmPromotionProviderService);
    private readonly tileService = inject(MarqueeTileService);
    private readonly personalizedMarqueeCookieName = 'ShowPersonalizedMarquee';

    combineWithPromotions(payload: MarqueePayload | undefined, tiles: Tile[]): Observable<MarqueeComponentTile[]> {
        return this.promoService.promotions$.pipe(
            map((promotions) => {
                if (payload && !this.isNotPersonalizedMarquee(payload)) {
                    return this.tileService.combineWithPromotions(tiles, promotions);
                }

                return tiles;
            }),
        );
    }

    isNotPersonalizedMarquee(data: MarqueePayload): boolean {
        return (
            data.isPersonalizedMarquee &&
            (!this.cookieProvider.getCookie(this.personalizedMarqueeCookieName) ||
                !(data.tiles?.length >= this.personalizedMarqueeConfig.minEventsToShow))
        );
    }
}
