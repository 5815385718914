import { Injectable } from '@angular/core';

import { InternalFeaturesConfig } from '@frontend/sports/common/client-config-data-access';
import { BalanceProperties } from '@frontend/vanilla/core';

import { LoggerFactory } from './logger-factory.service';
import { SportsRemoteLogger } from './sports-remote-logger.service';

type BalanceWithTime = { accountBalance: number; time: string } | null;

@Injectable({ providedIn: 'root' })
export class AccountBalanceLoggerService {
    private readonly logger: SportsRemoteLogger;
    //The last balance property emission from Vanilla balance service
    private lastBalanceProperty: (BalanceProperties & { time: string }) | { balanceInfo: null; time: string } | null;
    //The last balance value that was validated in the betslip
    private lastValidatedBalance: BalanceWithTime;
    //The balance amount that was validated before the last successful bet placement
    private balancePropertiesPostLastPlacement: BalanceWithTime;
    //The amount generated from our formula calculation
    private lastWalletBalanceAmount: BalanceWithTime;
    // a flag to ensure balance properties updates that are happening as bet placement is ongoing should be ignored
    private isPlacing = false;

    /**
     * This service will try to collect relevant balance data and log them when an insufficient funds error is returned.
     *
     * @param loggerFactory
     */
    constructor(
        loggerFactory: LoggerFactory,
        private internalFeatureConfig: InternalFeaturesConfig,
    ) {
        this.logger = loggerFactory.getLogger('AccountBalanceLogger');
    }

    freezeLogWhilePlacement() {
        this.isPlacing = true;
    }

    enableLogs() {
        this.reset();
    }

    setBalanceInfo(balanceInfo: BalanceProperties | null) {
        if (this.isPlacing) {
            return;
        }
        if (balanceInfo) {
            this.lastBalanceProperty = {
                ...balanceInfo,
                time: this.getCurrentFormattedDate(),
            };
        } else {
            this.lastBalanceProperty = {
                balanceInfo: null,
                time: this.getCurrentFormattedDate(),
            };
        }
    }

    setBalanceInfoPostSuccessfulBetPlacement() {
        this.balancePropertiesPostLastPlacement = this.lastValidatedBalance;
    }

    setLastValidatedBalance(accountBalance: number) {
        if (this.isPlacing) {
            return;
        }
        this.lastValidatedBalance = this.buildBalanceAndTimeObject(accountBalance);
    }

    setLastWalletBalance(accountBalance: number) {
        if (this.isPlacing) {
            return;
        }
        this.lastWalletBalanceAmount = this.buildBalanceAndTimeObject(accountBalance);
    }

    log(requestId: string) {
        if (!this.internalFeatureConfig.isBalanceLoggingEnabled) {
            return;
        }
        this.logger.error(this.getStringifiedLogObject(requestId));
        this.reset();
    }

    private buildBalanceAndTimeObject(accountBalance: number) {
        return {
            accountBalance,
            time: this.getCurrentFormattedDate(),
        };
    }

    private getStringifiedLogObject(requestId: string) {
        return JSON.stringify({
            message: 'Unexpected Insufficient Funds Error',
            requestId,
            lastBalanceProperty: this.lastBalanceProperty,
            lastValidatedBalance: this.lastValidatedBalance,
            balancePropertiesPostLastPlacement: this.balancePropertiesPostLastPlacement,
            lastWalletBalanceAmount: this.lastWalletBalanceAmount,
        });
    }

    private reset() {
        this.lastBalanceProperty = null;
        this.lastValidatedBalance = null;
        this.lastWalletBalanceAmount = null;
        this.isPlacing = false;
    }

    private getCurrentFormattedDate() {
        return new Date().toISOString();
    }
}
