@switch (offerStatus) {
    @case (offersStatusEnum.OptIn) {
        <div class="btn prominent btn-primary" (click)="onButtonClick($event)">
            {{ promotion.banner?.callToAction || translations.OptInButton }}
        </div>
    }
    @case (offersStatusEnum.OptedIn) {
        <div class="success theme-success-i offer-button">
            {{ translations.OptedInButton }}
        </div>
    }
    @case (offersStatusEnum.Expired) {
        <div class="info theme-info-i offer-button">
            {{ translations.ExpiredButton }}
        </div>
    }
    @case (offersStatusEnum.Error) {
        <div class="danger theme-error-i offer-button" (click)="onButtonClick($event)">
            {{ translations.ErrorButton }}
        </div>
    }
}
