import { ElementRef, Injectable, Optional } from '@angular/core';

import { HooksWireup, OnDestroyCleanup, objectEntries } from '@frontend/sports/common/base-utils';
import { MediaQueryService } from '@frontend/vanilla/core';
import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { ResizeObserverService } from '../common/resize-observer.service';
import { GridBreakpointSize, GridLayout } from '../grid-base/grid.model';

interface ColumnsBreakpoint {
    query: string;
    min?: number;
    max?: number;
    columns: number;
}

interface GridBreakpoint {
    [breakpoint: string]: number;
}

export interface GridBreakpointOptions {
    calculateGridWidth: (containerWidth: number) => number;
}

const DEFAULT_GRID_BREAKPOINTS_OPTIONS: GridBreakpointOptions = {
    calculateGridWidth: (containerWidth: number) => containerWidth,
};

const DEFAULT_GRID_BREAKPOINTS: GridBreakpoint = {
    'xs': 1,
    'sm': 2,
    'md': 3,
    'gt-md': 4,
};

const SIX_PACK_GRID_BREAKPOINTS: GridBreakpoint = {
    'lt-md': 1,
    'gt-sm': 2,
};

@HooksWireup()
@Injectable()
export class GridBreakpointService extends OnDestroyCleanup {
    private static DEFAULT_BREAKPOINTS: ColumnsBreakpoint[];
    private static SIX_PACK_BREAKPOINTS: ColumnsBreakpoint[];

    private get defaultBreakpoints(): ColumnsBreakpoint[] {
        return (
            GridBreakpointService.DEFAULT_BREAKPOINTS ??
            (GridBreakpointService.DEFAULT_BREAKPOINTS = this.parseGridBreakpoints(DEFAULT_GRID_BREAKPOINTS))
        );
    }

    private get sixPackBreakpoints(): ColumnsBreakpoint[] {
        return (
            GridBreakpointService.SIX_PACK_BREAKPOINTS ??
            (GridBreakpointService.SIX_PACK_BREAKPOINTS = this.parseGridBreakpoints(SIX_PACK_GRID_BREAKPOINTS))
        );
    }

    constructor(
        private mediaObserver: MediaQueryService,
        private resizeObserver: ResizeObserverService,
        @Optional() private container?: ElementRef,
    ) {
        super();
    }

    forGrid(
        element: ElementRef,
        layout: GridLayout,
        options: GridBreakpointOptions = DEFAULT_GRID_BREAKPOINTS_OPTIONS,
    ): Observable<GridBreakpointSize> {
        const containerWidth = this.container
            ? this.resizeObserver.observe(this.container).pipe(
                  map((changes) => changes.contentRect.width),
                  distinctUntilChanged(),
                  map((width) => options.calculateGridWidth(width)),
              )
            : this.mediaObserver.observe().pipe(map(() => element.nativeElement.clientWidth));

        return containerWidth.pipe(
            map((width) => {
                const columnsBreakpoints = layout === GridLayout.SixPack ? this.sixPackBreakpoints : this.defaultBreakpoints;

                return {
                    default: this.matchColumnsBreakpoint(this.defaultBreakpoints, width),
                    columns: this.matchColumnsBreakpoint(columnsBreakpoints, width),
                };
            }),
            distinctUntilChanged(isEqual),
            takeUntil(this.destroyed$),
        );
    }

    private matchColumnsBreakpoint(source: ColumnsBreakpoint[], width?: number): number {
        const matcher = width
            ? (item: ColumnsBreakpoint) => (item.min === undefined || item.min <= width) && (item.max === undefined || item.max >= width)
            : (item: ColumnsBreakpoint) => this.mediaObserver.isActive(item.query);

        for (const current of source) {
            if (matcher(current)) {
                return current.columns;
            }
        }

        return 1;
    }

    private parseGridBreakpoints(source: GridBreakpoint): ColumnsBreakpoint[] {
        return objectEntries(this.mediaObserver.breakpoints)
            .filter((point) => point.key in source)
            .map(({ key, value }) => this.parseColumnsBreakpoint(key, value, source))
            .filter((point) => point.min || point.max)
            .sort((first, second) => second.columns - first.columns);
    }

    private parseColumnsBreakpoint(name: string, query: string, source: GridBreakpoint): ColumnsBreakpoint {
        const regex = /(min|max)-width\:\s*(\d+)px/gi;
        let match: RegExpExecArray | null;

        const result: ColumnsBreakpoint = { query: name, columns: source[name] };

        while ((match = regex.exec(query))) {
            result[match[1]] = parseInt(match[2]);
        }

        return result;
    }
}
