import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';

import { TitleIconOptions } from '@frontend/sports/types/components/content';

import { WidgetTabBarItem } from './widget-tab-bar.component';

@Component({
    selector: 'ms-widget-header',
    templateUrl: './widget-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetHeaderComponent implements OnChanges {
    @HostBinding('class') className = 'widget-header grid-outer-title';

    @Input() title: string;
    @Input() link?: string;
    @Input() linkText?: string;
    @Input() titleIcon: string;
    @Input() titleData: WidgetTabBarItem;

    @Output() linkClick = new EventEmitter<boolean>();

    titleLogo: boolean;

    ngOnChanges(): void {
        this.titleLogo = this.titleIcon === TitleIconOptions.CompetitionLogo;
    }

    linkClicked(): void {
        this.linkClick.emit(true);
    }
}
