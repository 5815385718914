import { Injectable, Type } from '@angular/core';

import { ComponentSlotInfo, DynamicLayoutService, MultiSlot, SingleSlot, SlotType } from '@frontend/vanilla/core';
import { isNumber, max } from 'lodash-es';
import { Subject } from 'rxjs';
import { distinctUntilKeyChanged } from 'rxjs/operators';

import { AdaptiveLayoutFlag, AdaptiveLayoutService, AdaptiveLayoutState } from './adaptive-layout.service';

export enum Slot {
    Header = 'header',
    Footer = 'footer',
    FooterInner = 'inner-footer',
    Navigation = 'header_bottom_items',
    NavigationInner = 'inner-navigation',
}

export interface SlotComponentOptions {
    attributes?: any;
    order?: number;
    fixed?: boolean;
}

@Injectable({ providedIn: 'root' })
export class SlotLayoutService {
    private state?: AdaptiveLayoutFlag;
    private components = new Map<Type<any>, SlotComponentOptions>();
    private slotsAreSwapped = false;
    private footerLoaded = false;
    swapDone: { [key: string]: boolean } = {};

    private innerFooterLoadedSubj = new Subject();
    innerFooterLoaded$ = this.innerFooterLoadedSubj.asObservable();

    constructor(
        private dynamicLayoutService: DynamicLayoutService,
        private adaptiveLayoutService: AdaptiveLayoutService,
    ) {}

    setup(): void {
        this.registerSlots();

        this.adaptiveLayoutService.stateChange$.pipe(distinctUntilKeyChanged('headerSubNavigation')).subscribe(this.headerLayoutChanged.bind(this));

        this.footerLoaded = true;
        this.swapSlots();
    }

    swapComponents(): void {
        this.swapSlots();
    }

    restore(): void {
        this.restoreSlots();
    }

    ensureSwappedSlots(): void {
        if (!this.slotsAreSwapped) {
            this.swapSlots();
        }
    }

    register(component: Type<any>, options: SlotComponentOptions = {}): void {
        options.order = this.getComponentOrder(options);
        this.components.set(component, options);

        this.addToSlot(component, options);
        this.reorderComponents();
    }

    override(component: Type<any>, options: SlotComponentOptions): void {
        const currentOptions = this.components.get(component);
        if (!currentOptions) {
            throw new Error(`could not find component ${component} to override`);
        }

        const newOptions = { ...currentOptions, ...options };
        newOptions.order = this.getComponentOrder(newOptions);

        this.remove(component);

        this.components.set(component, newOptions);

        this.addToSlot(component, options);
        this.reorderComponents();
    }

    getConfig(component: Type<any>): SlotComponentOptions | undefined {
        return this.components.get(component);
    }

    remove(component: Type<any>): void {
        const options = this.components.get(component);
        if (!options) {
            throw new Error(`could not find component ${component} to remove`);
        }

        this.components.delete(component);

        if (options.fixed) {
            this.fixedSlot.remove(component);
        } else {
            this.currentSlot.remove(component);
        }
    }

    private addToSlot(component: Type<any>, options: SlotComponentOptions): void {
        if (options.fixed) {
            this.fixedSlot.add(component, options.attributes);
        } else {
            this.currentSlot.add(component, options.attributes);
        }
    }

    private getComponentOrder(options: SlotComponentOptions): number {
        if (!options.order) {
            const componentsOrder: number[] = [];
            this.components.forEach((config) => componentsOrder.push(config.order || 0));

            return max(componentsOrder) || 0;
        }

        return options.order;
    }

    private get currentSlot(): MultiSlot {
        const slotName = this.state && this.state.headerSubNavigation ? Slot.Navigation : Slot.NavigationInner;

        return this.dynamicLayoutService.getSlot<MultiSlot>(slotName, SlotType.Multi);
    }

    private get fixedSlot(): MultiSlot {
        return this.dynamicLayoutService.getSlot<MultiSlot>(Slot.Navigation, SlotType.Multi);
    }

    private headerLayoutChanged(current: AdaptiveLayoutState): void {
        const slotName = current.headerSubNavigation ? Slot.Navigation : Slot.NavigationInner;

        if (slotName === this.currentSlot.slotName) {
            return;
        }

        this.swapMultiSlot(this.currentSlot.slotName, slotName, (componentInfo) => {
            const registered = this.components.get(componentInfo.component);

            return registered && !registered.fixed && isNumber(registered.order);
        });

        this.reorderComponents();
        this.state = current;
    }

    private reorderComponents(): void {
        const sortRank = (componentInfo: ComponentSlotInfo) => {
            const component = this.components.get(componentInfo.component);

            return (component && component.order) || 0;
        };

        const sortComponents = (slotName: string) =>
            this.dynamicLayoutService.getSlot<MultiSlot>(slotName, SlotType.Multi).components.sort((firstSlot, secondSlot) => {
                return sortRank(firstSlot) - sortRank(secondSlot);
            });

        sortComponents(Slot.Navigation);
        sortComponents(Slot.NavigationInner);
    }

    private registerSlots(): void {
        const slots = this.dynamicLayoutService.getSlots().map((slot) => slot.slotName);
        const registerSlot = (name: string, type: SlotType) => {
            if (slots.indexOf(name) === -1) {
                this.dynamicLayoutService.registerSlot(name, type);
            }
        };

        registerSlot(Slot.FooterInner, SlotType.Single);
        registerSlot(Slot.NavigationInner, SlotType.Multi);
    }

    private swapSlots(): void {
        if (!this.footerLoaded) {
            return;
        }

        this.swapSingleSlot(Slot.Footer, Slot.FooterInner);
        this.slotsAreSwapped = true;
    }

    private restoreSlots(): void {
        if (!this.footerLoaded) {
            return;
        }

        this.swapSingleSlot(Slot.FooterInner, Slot.Footer);
        this.slotsAreSwapped = false;
    }

    private swapSingleSlot(source: string, destination: string): void {
        const currentAppSlot = this.dynamicLayoutService.getSlot<SingleSlot>(source, SlotType.Single);

        const componentInfo = currentAppSlot.component;
        if (componentInfo) {
            this.dynamicLayoutService.setComponent(destination, componentInfo.component, componentInfo.attr);
            this.dynamicLayoutService.removeComponent(source, componentInfo.component);
            this.swapDone[source] = true;
            this.innerFooterLoadedSubj.next(undefined);
            this.innerFooterLoadedSubj.complete();
        } else {
            //in case currentAppSlot  didn't have component, add an interval to retry the swap after 200ms
            //this was done to fix any issue with slot swapping that could be caused due to events race conditions
            if (!this.swapDone[source]) {
                setTimeout(() => {
                    this.swapSingleSlot(source, destination);
                }, 200);
            }
        }
    }

    private swapMultiSlot(source: string, destination: string, predicate?: (componentInfo: ComponentSlotInfo) => boolean | undefined): void {
        const currentAppSlot = this.dynamicLayoutService.getSlot<MultiSlot>(source, SlotType.Multi);

        currentAppSlot.components.forEach((componentInfo) => {
            if (predicate && !predicate(componentInfo)) {
                return;
            }

            this.dynamicLayoutService.addComponent(destination, componentInfo.component, componentInfo.attr);
            this.dynamicLayoutService.removeComponent(source, componentInfo.component);
        });
    }
}
