import { Injectable } from '@angular/core';

import { CookieName, CookieService, PERMANENT_COOKIE_EXPIRATION, WindowRef } from '@frontend/vanilla/core';

const DisabledCookieValue: string = '0';
const EnabledCookieValueOldDesktop: string = '1';
const EnabledCookieValue: string = '2';

/**
 * @whatItDoes Allows toggling of dark mode and check if feature is enabled.
 *
 * @stable
 */
@Injectable({
    providedIn: 'root',
})
export class DarkModeService {
    private _isEnabled = this.getIsEnabled();

    get isEnabled(): boolean {
        return this._isEnabled;
    }

    constructor(
        private cookieService: CookieService,
        private windowRef: WindowRef,
    ) {}

    toggle() {
        this.writeCookie(this._isEnabled ? DisabledCookieValue : EnabledCookieValue);
        this._isEnabled = this.getIsEnabled();
        this.windowRef.nativeWindow.location.reload();
    }

    private writeCookie(value: string) {
        this.cookieService.putRaw(CookieName.DarkMode, value, { expires: PERMANENT_COOKIE_EXPIRATION });
    }

    private getIsEnabled(): boolean {
        const value = this.cookieService.get(CookieName.DarkMode);

        return value === EnabledCookieValue || value === EnabledCookieValueOldDesktop;
    }
}
