import { BetPlacementErrorIcon } from '../bet-placement-error-icon';
import { BetslipError } from '../betslip-error';
import { ClientErrorType } from '../client-error-type';

export class RewardTokensSelectionLevelMaxOddsError extends BetslipError {
    readonly selectionLevelMaxOdds: string;

    constructor(maxOdds: string) {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensSelectionMaxOddsError;
        this.hasClientValidation = true;
        this.selectionLevelMaxOdds = maxOdds;
    }
}
