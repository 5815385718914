import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { SeoLoggerService } from './seo-logger.service';

@Injectable({ providedIn: 'root' })
export class SeoRedirectGuard {
    constructor(
        private router: Router,
        private logger: SeoLoggerService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree {
        let node = route.parent;
        const segments: string[] = [];

        while (node) {
            segments.unshift(...node.url.map((segment) => segment.path));
            node = node.parent;
        }

        const url = `/${segments.join('/')}`;

        /**
         * we are logging the source url which triggered this guard and the resulting redirect url for
         * ex /en/sports/non-existent-route/non-exising-segment will be redirected to parent route /
         * so the logger will log
         *
         * { source: "/en/sports/non-existent-route/non-exising-segment", url: "/" }
         * this switing to parent route automaitcally also resolves the issues of url will wrong culture/area
         * like /en/wrong-area or /wrong-culture as the redirect to the parent / actually redirect to the propper
         * default /culture/area
         */

        this.logger.log({ source: state.url, url });

        return this.router.parseUrl(url);
    }
}
