<ms-tab-bar
    [tabs]="tabs"
    [mode]="mode"
    (select)="select.next($event.active)"
    [iconTemplate]="iconTemplate"
    [stretch]="stretch"
    [signPostTemplate]="signPostTemplate"></ms-tab-bar>

<ng-template #iconTemplate let-tab>
    <ms-competition-logo
        *ngIf="tab.display === display.Logo; else staticTemplate"
        class="icon"
        [sportId]="tab.sportId"
        [leagueId]="tab.itemId"
        [fallbackIcon]="tab.icon"
        [isLogoOverrideEnabled]="true"
        [imageProfile]="tab.context?.imageProfile">
    </ms-competition-logo>
    <ng-template #staticTemplate>
        <span class="icon" [ngClass]="tab.icon"></span>
    </ng-template>
</ng-template>

<ng-template #signPostTemplate let-tab>
    <span class="count count-padding-signpost" *ngIf="tab.count">
        <ms-priceboost-count-signpost [count]="tab.count"></ms-priceboost-count-signpost>
    </span>
</ng-template>
