import { Injectable } from '@angular/core';

import { NewCustomerBettingOfferConfig } from '@frontend/sports/common/client-config-data-access';

@Injectable({
    providedIn: 'root',
})
export class CustomerOfferService {
    constructor(private customerOfferConfig: NewCustomerBettingOfferConfig) {}

    isNewCustomerOffer(leagueId: number | undefined): boolean {
        return leagueId !== undefined && leagueId === this.customerOfferConfig.competitionId;
    }
}
