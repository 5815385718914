import { NgModule } from '@angular/core';

import { ClassAutoRemoveDirective } from './class-auto-remove.directive';
import { ColumnWidthDirective } from './column-width.directive';
import { ComponentHostDirective } from './component-host.directive';
import { HeightToBottomDirective } from './height-to-bottom.directive';
import { InfoTooltipDirective } from './info-tooltip.directive';
import { OutsideInteractionDirective } from './outside-interaction.directive';
import { ScrollIosHackDirective } from './scroll-ios-hack/scroll-ios-hack.directive';
import { ScrollSelectedInViewDirective } from './scroll-selected-in-view/scroll-selected-in-view.directive';
import { ScrollToDirective } from './scroll-to.directive';
import { ScrolledToBottomDirective } from './scrolled-to-bottom.directive';
import { StopEventPropagationDirective } from './stop-event-propagation.directive';
import { ToolbarButtonsHeightDirective } from './toolbar-buttons-height.directive';

@NgModule({
    imports: [ScrollSelectedInViewDirective],
    declarations: [
        ClassAutoRemoveDirective,
        ColumnWidthDirective,
        ComponentHostDirective,
        HeightToBottomDirective,
        InfoTooltipDirective,
        OutsideInteractionDirective,
        ScrolledToBottomDirective,
        ScrollIosHackDirective,
        ScrollToDirective,
        StopEventPropagationDirective,
        ToolbarButtonsHeightDirective,
    ],
    exports: [
        ClassAutoRemoveDirective,
        ColumnWidthDirective,
        ComponentHostDirective,
        HeightToBottomDirective,
        InfoTooltipDirective,
        OutsideInteractionDirective,
        ScrolledToBottomDirective,
        ScrollIosHackDirective,
        ScrollToDirective,
        StopEventPropagationDirective,
        ToolbarButtonsHeightDirective,
        ScrollSelectedInViewDirective,
    ],
    // needs to be fixed after module reordering
})
export class DirectivesModule {}
