import { Injectable } from '@angular/core';

import { StylesService } from '@frontend/vanilla/core';
import { Observable, forkJoin, from } from 'rxjs';
import { map } from 'rxjs/operators';

import ModuleLoaderService from '../deferred/module-loader.service';
import { SitemapModule } from '../sitemap/sitemap.module';

@Injectable({ providedIn: 'root' })
export class SitemapModuleLoaderService {
    private readonly styleSheet = 'sitemap';

    constructor(
        private moduleLoader: ModuleLoaderService,
        private stylesService: StylesService,
    ) {}

    loadAndInitModule(): Observable<SitemapModule> {
        const moduleLoader = this.moduleLoader.loadModule<SitemapModule>('SitemapModule', () =>
            import(/* webpackChunkName: "ms-sitemap" */ '../sitemap/sitemap.module').then((m) => m.SitemapModule),
        );
        const stylesLoader = from(this.stylesService.load(this.styleSheet));

        return forkJoin([moduleLoader, stylesLoader]).pipe(map(([ref, _]) => ref.instance));
    }
}
