import { createFeatureSelector, createSelector } from '@ngrx/store';

import { EventDetailsColumnType } from '../event-model/helpers/event-media-helper.service';
import { EventModel } from '../event-model/model/event.model';
import { EventSwitcherItem, MediaModuleContextPage, MediaTabState } from '../media-api/model';
import { IRootState } from '../store/root.state';
import { TrackingVideoSource } from '../tracking/tracking-video-source';

export const mediaInitialState: MediaState = {
    active: false,
    video: {
        pinned: false,
        autoplay: false,
        playing: false,
        dropdownEvents: [],
        eventId: null,
        unpinInfoVisible: false,
        pinEnabled: false,
        trackingSource: TrackingVideoSource.Default,
    },
    activeTab: EventDetailsColumnType.Video,
    animation: { eventId: null },
    statistics: { eventId: null },
    eventInfos: {},
    context: { page: MediaModuleContextPage.Default, eventId: null },
    isCollapsed: false,
    mediaExpandState: {
        isLocationAllowsExpand: false,
        isExpanded: false,
        restoreExpandState: false,
        isDefaultExpanded: false,
    },
};

export const mediaFeatureKey = 'media';

export interface IMediaRootState extends IRootState {
    media: MediaState;
}

export const mediaStateSelector = createFeatureSelector<MediaState>(mediaFeatureKey);

export const mediaExpandStateSelector = createSelector(mediaStateSelector, (state: MediaState) => state.mediaExpandState);

export const mediaEventInfoSelector = (eventId: string) => createSelector(mediaStateSelector, (state: MediaState) => state.eventInfos[eventId]);

export const mediaActiveSelector = createSelector(mediaStateSelector, (state: MediaState) => state.active);

export const mediaExpandedStateSelector = createSelector(mediaStateSelector, (state: MediaState) => state.mediaExpandState);

interface VideoState extends MediaTabState {
    pinned: boolean;
    autoplay: boolean;
    playing: boolean;
    dropdownEvents: EventSwitcherItem[];
    unpinInfoVisible: boolean;
    pinEnabled: boolean;
    trackingSource: TrackingVideoSource;
}

export interface MediaModuleContext {
    page: MediaModuleContextPage;
    eventId: string | null;
}

export interface EventInfo {
    event: EventModel;
    hasVideo: boolean;
    hasAnimation: boolean;
    hasStats: boolean;
}

export interface MediaState {
    active: boolean;
    activeTab: EventDetailsColumnType;
    video: VideoState;
    animation: MediaTabState;
    statistics: MediaTabState;
    eventInfos: { [eventId: string]: EventInfo };
    context: MediaModuleContext;
    isCollapsed: boolean;
    mediaExpandState: MediaExpandState;
}

export interface MediaExpandState {
    isExpanded: boolean;
    isLocationAllowsExpand: boolean;
    restoreExpandState: boolean;
    isDefaultExpanded: boolean;
}

export interface EventsSelector {
    events: EventSwitcherItem[];
}
