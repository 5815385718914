import { Injectable } from '@angular/core';

import { WindowRef } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { EllapsedTimer } from './ellapsed-timer';

@Injectable({ providedIn: 'root' })
export class OnlineDetectionService {
    private window: Window;
    private offlineTimer?: EllapsedTimer;
    private online$: BehaviorSubject<{ isOnline: boolean; offlineDuration?: number }>;

    constructor(windowRef: WindowRef) {
        this.window = windowRef.nativeWindow;
        this.online$ = new BehaviorSubject({ isOnline: this.window.navigator.onLine });

        this.window.addEventListener('online', () => this.updateOnlineStatus());
        this.window.addEventListener('offline', () => this.updateOnlineStatus());
    }

    onlineChange(): Observable<{ isOnline: boolean; offlineDuration?: number }> {
        return this.online$.asObservable();
    }

    private updateOnlineStatus = () => {
        const isOnline = this.window.navigator.onLine;

        const offlineDuration = isOnline && this.offlineTimer ? this.offlineTimer.ellapsedMilliseconds : undefined;
        this.offlineTimer = isOnline ? undefined : EllapsedTimer.start();

        this.online$.next({ isOnline, offlineDuration });
    };
}
