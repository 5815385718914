import { PickId } from '../../../../core/picks/pick-id';
import { BetslipError } from '../betslip-error';
import { ClientErrorType } from '../client-error-type';
import { PreCheckErrorMixin } from '../pre-check/pre-check-error';
import { ResultError } from '../result/result-error';

export abstract class GroupPickError extends BetslipError {
    protected constructor() {
        super();
        this.hasClientValidation = true;
    }
}

export class GroupLinearPickError extends PreCheckErrorMixin(ResultError) {
    constructor(pickId: string) {
        super(pickId);
    }
}
export class GroupMinSelectionsError extends GroupPickError {
    constructor() {
        super();
    }
}

export class GroupMaxSelectionsError extends GroupPickError {
    constructor() {
        super();
        this.type = ClientErrorType.BetBuilderMaxSelectionsPerPickError;
    }
}

export class GroupMaxSelectionsPreCheckError extends GroupLinearPickError {
    constructor(pickId: string) {
        super(pickId);
    }
}

export class GroupPickHasLiveLegsPreCheckError extends GroupLinearPickError {
    constructor(pickId: string) {
        super(pickId);
    }
}

export class GroupMinSelectionsPreCheckError extends GroupLinearPickError {
    constructor(pickId: string) {
        super(pickId);
    }
}

export class GroupPickInvalidPricePreCheckError extends GroupLinearPickError {
    constructor(pickId: string) {
        super(pickId);
    }
}

export class GroupWithUncombinableLegsError extends GroupPickError {
    constructor(
        public legIds: PickId[],
        public invalidPickIds: PickId[],
    ) {
        super();
    }
}

export class GroupWithUncombinableLegsPreCheckError extends GroupLinearPickError {
    constructor(pickId: string) {
        super(pickId);
    }
}

export class GroupWithClosedLegsError extends ResultError {
    constructor(
        public override pickId: string,
        public legIds: PickId[],
    ) {
        super(pickId);
        this.hasClientValidation = true;
        this.isHidden = true;
    }
}

export class GroupPickInvalidPrice extends GroupPickError {
    constructor(public pickId: string) {
        super();
        this.hasClientValidation = true;
        this.isHidden = true;
    }
}

export class GroupWithSuspendedLegsError extends ResultError {
    constructor(
        public override pickId: string,
        public legIds: PickId[],
    ) {
        super(pickId);
        this.hasClientValidation = true;
    }
}

export class GroupPickComboBetInvalidPrice extends GroupPickError {
    constructor(public invalidPickIds: PickId[]) {
        super();
        this.isHidden = true;
    }
}

export class GroupPickHasLiveLegsError extends GroupPickError {
    constructor(public invalidPickIds: PickId[]) {
        super();
    }
}
