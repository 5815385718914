import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingIndicatorComponent } from './loading-indicator.component';

@NgModule({
    exports: [LoadingIndicatorComponent],
    imports: [CommonModule],
    declarations: [LoadingIndicatorComponent],
})
export class LoadingIndicatorModule {}
