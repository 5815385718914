import { NumberSymbol, getLocaleNumberSymbol } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { MoneyService } from '../money/money.service';
import { NUMPAD_VALIDATION_PARAMETERS_TOKEN, NumpadValidationParams } from './model';

@Injectable({ providedIn: 'root' })
export class NumpadUtilsService {
    protected readonly localeSeparator: string;
    protected readonly zeroRegex = new RegExp(`^(0+)(\\d+)`);
    protected readonly validateRegex: RegExp;
    protected readonly splitRegex = new RegExp(`^-?(\\d*)([.,])?(\\d*)$`);

    constructor(
        @Inject(LOCALE_ID) locale: string,
        @Inject(NUMPAD_VALIDATION_PARAMETERS_TOKEN) private validationParameters: NumpadValidationParams,
        private currency: MoneyService,
    ) {
        this.localeSeparator = getLocaleNumberSymbol(locale, NumberSymbol.CurrencyDecimal);

        this.validateRegex = new RegExp(
            `^\\d{0,${this.validationParameters.maxLeadingDigits}}([.,](\\d{0,${this.validationParameters.maxTrailingDigits}})?)?$`,
        );
    }

    isValidInput(input: string): boolean {
        return (this.isSeparator(input) || this.validateRegex.test(input)) && !this.zeroRegex.test(input);
    }

    normalizeInput(input: string): string {
        const matches = input.match(this.splitRegex);
        if (!matches || matches.length !== 4 || matches[0] === '') {
            return '';
        }

        let output = matches[1];

        if (this.zeroRegex.test(output)) {
            output = output.replace(this.zeroRegex, '$2');
        }

        if (matches[2]) {
            output += this.localeSeparator;
        }

        if (this.isSeparator(output)) {
            output = '0' + this.localeSeparator;
        }

        if (matches[3]) {
            output += matches[3].substr(0, this.validationParameters.maxTrailingDigits);
        }

        return output;
    }

    isSeparator(input: string): boolean {
        return /^[.,]$/.test(input);
    }

    hasSeparator(input: string): boolean {
        return !!input && /^\d*[.,]\d*$/.test(input);
    }

    numberOfSeparators(input: string): number {
        return (input && (input.match(/[.,\s]/g) || []).length) || 0;
    }

    addLocaleSeparator(input: string): string {
        return (input || '').replace(/[.,]/g, this.localeSeparator);
    }

    /**
     * Returns the number string formatted with added decimals if the number is a decimal number or alwaysAddDecimals is true.
     * Returns the unformatted input if it is 0 or an empty string.
     */
    toFormattedValue(input: string, alwaysAddDecimals: boolean = false): string {
        if (!this.isValidNumberString(input)) {
            return input;
        }

        input = this.addDecimals(input, alwaysAddDecimals);

        return this.addLocaleSeparator(input);
    }

    /**
     * Returns the number string formatted with thousands separators.
     * Returns an empty string if the input is 0 or an empty string.
     */
    addThousandsSeparators(input: string): string {
        if (!this.isValidNumberString(input)) {
            return '';
        }

        const returnValue = this.currency.transform(input, false, this.validationParameters.maxTrailingDigits);

        if (this.hasSeparator(input)) {
            return returnValue;
        }

        const replace = `[,.]0{1,${this.validationParameters.maxTrailingDigits}}$`;
        const regex = new RegExp(replace, 'g');

        return returnValue.replace(regex, '');
    }

    private addDecimals(input: string, alwaysAddDecimals: boolean = false): string {
        if (this.isValidNumberString(input) && (alwaysAddDecimals || this.hasSeparator(input))) {
            return Number(input).toFixed(this.validationParameters.maxTrailingDigits);
        }

        return input;
    }

    private isValidNumberString(input: string) {
        return input !== '0' && !this.isEmptyOrWhiteSpaceString(input);
    }

    private isEmptyOrWhiteSpaceString(input: string) {
        return input.trim() === '';
    }
}
