"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConnectionStatus = void 0;
var ConnectionStatus;
(function (ConnectionStatus) {
  ConnectionStatus[ConnectionStatus["Disconnected"] = 0] = "Disconnected";
  ConnectionStatus[ConnectionStatus["Connecting"] = 1] = "Connecting";
  ConnectionStatus[ConnectionStatus["Connected"] = 2] = "Connected";
})(ConnectionStatus = exports.ConnectionStatus || (exports.ConnectionStatus = {}));
