/**
 * @description Provides supported CCB event type names
 *
 * @stable
 */
export enum NativeEventType {
    APPFOREGROUND = 'APP_FOREGRND',
    APPINITIALIZED = 'APP_INITIALIZED',
    BALANCE_TRANSFER = 'BALANCE_TRANSFER',
    CCBINITIALIZED = 'CCB_INITIALIZED',
    DEVICEDETAILS = 'DEVICE_DETAILS',
    enableOSPrimer = 'enableOSPrimer',
    FEATURE_LOADED = 'FEATURE_LOADED',
    FINGERPRINT = 'FINGERPRINT',
    FINGERPRINTSCANNER = 'FINGERPRINT_SCANNER',
    GAMING_DECLARATION_ACCEPTED = 'GAMING_DECLARATION_ACCEPTED',
    GEO_LOCATION_POSITION = 'GEO_LOCATION_POSITION',
    GET_APPLICATION_SETTINGS = 'GET_APPLICATION_SETTINGS',
    GET_GEO_LOCATION_POSITION = 'GET_GEO_LOCATION_POSITION',
    GETBALANCE = 'GET_BALANCE',
    INBOXCLOSED = 'INBOX_CLOSED',
    IsLoggedIn = 'IS_LOGGED_IN',
    LANGUAGEUPDATED = 'LanguageUpdated',
    LOCATION = 'Location',
    LOGIN_USERNAME_PREFILL = 'LOGIN_USERNAME_PREFILL',
    LOGINFAILED = 'LOGIN_FAILED',
    LOGINPREFILL = 'LOGIN_PREFILL',
    LOGINSCREENACTIVE = 'Login_Screen_Active',
    LOGOUT = 'LOGOUT',
    MENU_ITEM_NAVIGATION = 'MENU_ITEM_NAVIGATION',
    MENUCLOSED = 'MENU_CLOSED',
    NAVIGATETO = 'NAVIGATE_TO',
    NotificationCount = 'NOTIFICATION_COUNT',
    OPEN_CHAT = 'OPEN_CHAT',
    OPEN_LOGIN_SCREEN = 'OPEN_LOGIN_SCREEN',
    OPENLOGINDIALOG = 'OPEN_LOGIN_DIALOG',
    OPENREGISTRATIONSCREEN = 'openRegistrationScreen',
    OSPrimerSelected = 'OSPrimerSelected',
    PAGECLOSED = 'PAGE_CLOSED',
    POSTLOGIN = 'POST_LOGIN',
    PRELOGIN = 'PRE_LOGIN',
    REMEMBER_ME_CLOSE = 'REMEMBER_ME_CLOSE',
    REMOVECOOKIE = 'REMOVE_COOKIE',
    SENSITIVEPAGE = 'SensitivePage',
    SESSION_NOTIFICATION = 'SESSION_NOTIFICATION',
    SESSION_NOTIFICATION_CONTINUE = 'SESSION_NOTIFICATION_CONTINUE',
    SET_APPLICATION_SETTINGS = 'SET_APPLICATION_SETTINGS',
    SSO_LOGIN = 'SSO_LOGIN',
    TRACKDEVICEIDFA = 'TRACK_DEVICE_IDFA',
    UPDATE_APPLICATION_SETTINGS = 'UPDATE_APPLICATION_SETTINGS',
    UPDATEBALANCE = 'UPDATE_BALANCE',
    USERBALANCE = 'UserBalance',
    LOGIN = 'LOGIN',

    //Betstation
    BARCODESCANNED = 'BARCODESCANNED',
    NFCCARDSCANNED = 'NFCCARDSCANNED',
    DEPOSIT_LIMIT_EXCEEDED = 'DEPOSIT_LIMIT_EXCEEDED',
    EVENT_CAPTURE = 'EVENT_CAPTURE',
    NFC_SCAN_FAILED = 'NFC_SCAN_FAILED',
    DEVICE_FAILURE = 'DEVICE_FAILURE',
    DEVICE_FIXED = 'DEVICE_FIXED',
    RESET_TERMINAL = 'RESET_TERMINAL',
    SCAN_VT_COMPLETE = 'SCAN_VT_COMPLETE',
    SCAN_VT_ERROR = 'SCAN_VT_ERROR',
}

/**
 * @whatItDoes Represents an CCB event sent from or to a native app
 *
 * @stable
 */
export interface NativeEvent {
    eventName: string;
    parameters?: { [key: string]: any };
    id?: string;
}

export interface NativeWindow {
    messageToZendesk: (event: NativeEvent) => void;
    messageToNative: (event: NativeEvent) => void;
    external: {
        NativeDispatch: (event: string, params: string) => void;
    };
    webkit?: {
        messageHandlers?: {
            observer?: {
                postMessage: (event: string, params: string) => void;
            };
        };
    };
}
