import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { HooksWireup, ISimpleChange, ISimpleChanges, Nullable, OnDestroyCleanup, Optional } from '@frontend/sports/common/base-utils';
import { BetslipConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { Observable, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { NumpadOperation, NumpadSource, StakeChangedEvent } from './model';
import { NumpadService } from './numpad.service';
import { StakeInputMode, StakeInputModeStrategy } from './stake-input-mode.strategy';
import { StakeInputBehavior } from './stake-input.component';

@HooksWireup()
@Component({
    selector: 'ms-stake',
    templateUrl: './stake.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StakeComponent extends OnDestroyCleanup implements OnChanges, OnInit {
    @Input() showConfirm = true;
    @Input() showIncrementalStakes = true;
    @Input() stake: Nullable<string>;
    @Input() keypadId: string;
    @Input() errors: Observable<boolean> = of(false);
    @Input() disabled = false;
    @Input() emptyCharacter: Optional<string>;
    @Input() focusOnLoad = false;
    @Input() numpadSource: NumpadSource;
    @Input() adornmentStart?: string;

    @Output() stakeChanged = new EventEmitter<StakeChangedEvent>();
    @Output() numpadOpen = new EventEmitter<void>();
    @Output() stakeConfirm = new EventEmitter<void>();

    private originalEmptyCharacter: Optional<string>;
    stakeNumpadMode = StakeInputMode;

    constructor(
        private numpadService: NumpadService,
        private sitecore: Sitecore,
        public modeStrategy: StakeInputModeStrategy,
        private betslipConfig: BetslipConfig,
    ) {
        super();
    }

    getStakeContainerClass$: Observable<string> = this.numpadService.state$.pipe(
        switchMap((state) => {
            return this.errors.pipe(
                map((hasErrors) => {
                    const result: string[] = [];

                    if (state.isOpened) {
                        result.push(`editable`);
                    }
                    if (hasErrors) {
                        result.push(`has-error`);
                    }

                    return result.join(' ');
                }),
            );
        }),
    );

    ngOnInit(): void {
        const options = {
            showConfirm: this.showConfirm,
            showIncrementalStakes: this.showIncrementalStakes,
            id: this.keypadId,
            focusOnLoad: this.focusOnLoad,
            numpadSource: this.numpadSource,
        };
        this.numpadService.initialize(this.stake, options);

        this.numpadService.stake$.pipe(takeUntil(this.destroyed$)).subscribe((stake) => {
            this.stakeChanged.emit(stake);
        });

        this.originalEmptyCharacter = this.emptyCharacter = this.emptyCharacter || this.sitecore.betslip.messages.Stake;

        this.numpadService.state$.pipe(takeUntil(this.destroyed$)).subscribe((newState) => {
            if (newState.operation === NumpadOperation.Close && newState.confirmed) {
                this.stakeConfirm.emit();
            }

            if (newState.operation === NumpadOperation.Open) {
                this.numpadOpen.emit();
            }

            if (newState.isOpened) {
                this.emptyCharacter = '';
            } else {
                this.emptyCharacter = this.originalEmptyCharacter;
            }
        });
    }

    ngOnChanges(changes: ISimpleChanges<StakeComponent>): void {
        if (changes.stake) {
            this.handleStakeChange(changes.stake);
        }
    }

    openKeypad(): void {
        this.numpadService.open();
    }

    get stakeInputBehavior(): StakeInputBehavior {
        return this.betslipConfig.isLinearKeypadEnabled ? StakeInputBehavior.OnlyTyping : StakeInputBehavior.TypingAndNumpad;
    }

    private handleStakeChange(stakeChange: ISimpleChange<Nullable<string>>): void {
        if (!stakeChange.firstChange) {
            this.numpadService.updateStake(stakeChange.currentValue);
        }
    }
}
