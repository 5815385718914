/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

import { DomainEvent } from './dispatcher.models';

export type Action<T> = (input: T) => void;

interface DispatcherSubscription {
    observable: Observable<any>;
}

/* eslint-enable  @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

@Injectable({ providedIn: 'root' })
export class DispatcherService implements OnDestroy {
    protected listeners = new Map<string, DispatcherSubscription>();
    private subject = new Subject<{ event: string; data: any }>();

    // dispatch listeners
    dispatch<T>(event: DomainEvent<T> | string, data?: T): void {
        const key = this.getKey<T>(event);

        const subscription = this.listeners.get(key);
        if (!subscription) {
            // No need to dispatch something if no listeners
            return;
        }

        this.subject.next({ event: key, data });
    }

    /**
     * @inheritdoc
     */
    on<T>(event: DomainEvent<T> | string): Observable<T> {
        const subscription = this.getOrCreateSubscription<T>(event);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return subscription.observable;
    }

    ngOnDestroy(): void {
        this.subject.complete();
    }

    private getKey<T>(evt: DomainEvent<T> | string): string {
        return this.isDomainEvent(evt) ? evt.key : evt;
    }

    private getOrCreateSubscription<T>(event: DomainEvent<T> | string): DispatcherSubscription {
        const key = this.getKey<T>(event);
        if (!this.listeners.has(key)) {
            this.listeners.set(key, {
                observable: this.subject.asObservable().pipe(
                    filter((de) => de.event === key),
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                    map((_) => _.data),
                    share(),
                ),
            });
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-type-assertion
        return this.listeners.get(key)!;
    }

    private isDomainEvent<T>(evt: DomainEvent<T> | string): evt is DomainEvent<T> {
        return !!(evt as DomainEvent<T>).key;
    }
}
