import { BetPlacementErrorIcon } from '../bet-placement-error-icon';
import { BetslipError } from '../betslip-error';
import { ClientErrorType } from '../client-error-type';
import { PreCheckErrorMixin } from '../pre-check/pre-check-error';

export class RewardTokensForbiddenError extends PreCheckErrorMixin(BetslipError) {
    constructor() {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensForbiddenError;
        this.hasClientValidation = true;
    }
}
