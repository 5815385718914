import { Injectable } from '@angular/core';

import { Sitecore, TopNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { NavigationBarItem } from '@frontend/sports/types/models/navigation-bar';
import { DslService } from '@frontend/vanilla/core';
import produce from 'immer';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';

import { SportsCacheService } from '../../client-caching/sports-cache.service';
import { AdaptiveLayoutService } from '../../layout/adaptive-layout.service';
import { UrlHelperService } from '../../navigation-core/url-helper.service';
import { NavigationItemsMapper } from '../navigation-items-mapper';
import { TopMenuContentItem } from '../navigation.models';
import { BaseNavigationItemsProvider } from './base-navigation-items.provider';

@Injectable({ providedIn: 'root' })
export class TopNavigationItemsProvider extends BaseNavigationItemsProvider {
    readonly navigationItems$: Observable<TopMenuContentItem[]>;

    constructor(
        private navigationConfig: TopNavigationConfig,
        private dslService: DslService,
        private mapper: NavigationItemsMapper,
        protected override sportsCache: SportsCacheService,
        private urlHelper: UrlHelperService,
        private sitecore: Sitecore,
        private layoutService: AdaptiveLayoutService,
    ) {
        super(sportsCache);

        const siteCoreReady$ = this.sitecore.whenReady;

        const evaluatedItems$ = this.navigationConfig.whenReady.pipe(
            map(() =>
                this.navigationConfig.topNavigationItems?.length ? this.navigationConfig.topNavigationItems : this.navigationConfig.beforeSports,
            ),
            switchMap((data) => this.dslService.evaluateContent(data)),
        );

        const liveSportCount$ = this.getLiveSportsList();

        this.navigationItems$ = combineLatest([evaluatedItems$, this.hasLiveItems$, liveSportCount$, siteCoreReady$]).pipe(
            map(([items, keepLiveItem, liveSportCount]) => {
                const liveCountText = this.formatLiveCounts(liveSportCount);
                const options = {
                    keepLiveItem,
                    isFixedItem: false,
                };

                const liveItem = items.filter((item) => item.activeName === 'live');
                if (this.isLiveButtonBadgeVisible(liveItem) && liveSportCount > 0) {
                    liveItem[0].badgeText = liveCountText;
                }
                const isV2Navigation = !!this.navigationConfig.topNavigationItems?.length;

                const itemsToReturn = isV2Navigation
                    ? this.mapper.mapItems(items, liveCountText, options)
                    : this.getFallbackItems(items, liveCountText, keepLiveItem);

                return produce([], () => itemsToReturn);
            }),
            startWith([]),
        );
    }

    private getFallbackItems(items: NavigationBarItem[], sportsCountText: string, keepLiveItem: boolean): TopMenuContentItem[] {
        const home = this.getHomeMenuItem();
        const beforeSports = this.mapper.mapItems(items, sportsCountText, { keepLiveItem });

        return home ? [home, ...beforeSports] : [...beforeSports];
    }

    // TODO: should be removed when only V2 is used on prod.
    getHomeMenuItem(): TopMenuContentItem | undefined {
        if (this.layoutService.current.bottomNavigation) {
            return;
        }

        return <TopMenuContentItem>{
            name: 'home',
            text: this.sitecore.globalMessages.NavHome,
            url: this.urlHelper.getHomeUrl(),
            class: 'sports-icon theme-home circle-overlay',
            trackEvent: this.mapper.getTrackingData('home'),
            parameters: {},
            resources: {},
        };
    }

    getLiveSportsList(): Observable<number> {
        return this.sportsCache.getSportList().pipe(
            map((sportList) => {
                const liveCount = sportList.map((s) => s.counts.live).reduce((acc, count) => acc + count, 0);

                return liveCount;
            }),
        );
    }

    formatLiveCounts(liveCount: number) {
        let counts = '';
        if (liveCount > 99) {
            counts = '99+';
        } else if (liveCount > 0) counts = liveCount.toString();

        return counts;
    }

    isLiveButtonBadgeVisible(liveItem: NavigationBarItem[]) {
        if (liveItem?.length && liveItem[0]?.showBadge) return true;

        return false;
    }
}
