import { isDefined } from '@frontend/sports/common/base-utils';
import { isNil } from 'lodash-es';

import { BetslipType } from '../../../core/betslip-type';
import { BetslipPick } from '../../../core/picks/betslip-pick';
import { BetslipUnknownPick } from '../../../core/picks/betslip-unknown-pick';
import { PickId } from '../../../core/picks/pick-id';
import { PickOddsState } from '../../../core/picks/pick-models';
import { flattenPicks } from '../../../core/utils';
import {
    hasBetBuilderBetPickStake,
    hasComboBetStake,
    hasSingleBetPickStake,
    hasSystemBetStake,
    hasTeaserBetStake,
} from '../../betplacement/services/stake/linear-stake-utils';
import { TokensRecord } from '../../reward-tokens/state';
import { IBetslipTypeState } from '../../types/state';

interface IPickTypeState {
    isLocked: boolean;
    isSelected: boolean;
}

export function getSelectedPlaceablePicks(types: IBetslipTypeState, picksList: BetslipPick[], tokens: TokensRecord): BetslipPick[] {
    let picks: BetslipPick[] = [];

    const singleState = types.singleBet;
    const comboState = types.comboBet;
    const systemState = types.systemBet;
    const teaserState = types.teaserBet;
    const betBuilderState = types.betBuilder;

    const singlePicks = flattenPicks(picksList).filter((p) => {
        const typePick = singleState.picks[p.id.toString()];

        return typePick && hasSingleBetPickStake(typePick, tokens) && typePick.isSelected;
    });

    picks = [...singlePicks];

    const betBuilderPicks = picksList.filter((p) => {
        const typePick = betBuilderState.picks[p.id.toString()];

        return typePick && hasBetBuilderBetPickStake(typePick, tokens) && typePick.isSelected;
    });

    picks = [...picks, ...betBuilderPicks];

    if (hasComboBetStake(types.comboBet, tokens)) {
        const multiPicks = picksList.filter((p) => {
            const typePick = comboState.picks[p.id.toString()];

            return typePick.isSelected && picks.every((s) => !s.id.isEqual(p.id));
        });

        picks = [...picks, ...multiPicks];
    }

    if (hasSystemBetStake(types.systemBet)) {
        const systemPicks = flattenPicks(picksList).filter((p) => {
            const typePick = systemState.picks[p.id.toString()];

            return typePick && typePick.isSelected && picks.every((s) => !s.id.isEqual(p.id));
        });

        picks = [...picks, ...systemPicks];
    }

    if (hasTeaserBetStake(types.teaserBet, tokens)) {
        const teaserPicks = picksList.filter((p) => {
            const typePick = teaserState.picks[p.id.toString()];

            return typePick?.isSelected && picks.every((s) => !s.id.isEqual(p.id));
        });

        picks = [...picks, ...teaserPicks];
    }

    return flattenPicks(picks);
}

export function getSelectedLockedPicksForAllTypes(types: IBetslipTypeState, picksList: BetslipPick[], isLocked: boolean): BetslipPick[] {
    const flattenedPicksMap = flattenPicks(picksList).reduce<{ [pickId: string]: BetslipPick }>((acc, curr) => {
        acc[curr.id.toString()] = curr;

        return acc;
    }, {});

    const lockedPicks: { [pickId: string]: BetslipPick } = {};
    const updateLockedPicksForTypeState = (typeState: { [pickId: string]: IPickTypeState }): void => {
        Object.entries(typeState)
            .filter(([, typePick]) => typePick.isSelected && typePick.isLocked === isLocked)
            .forEach(([pickId]) => {
                lockedPicks[pickId] = flattenedPicksMap[pickId];
            });
    };

    updateLockedPicksForTypeState(types.singleBet.picks);
    updateLockedPicksForTypeState(types.comboBet.picks);
    updateLockedPicksForTypeState(types.systemBet.picks);
    updateLockedPicksForTypeState(types.betBuilder.picks);
    updateLockedPicksForTypeState(types.teaserBet.picks);

    return Object.values(lockedPicks).filter(isDefined);
}

export function hasSelectedLockedPick(types: IBetslipTypeState, picksList: BetslipPick[], isLocked: boolean): boolean {
    return picksList.some((pick) => {
        const single = types.singleBet.picks[pick.id.toString()];
        const combo = types.comboBet.picks[pick.id.toString()];
        const system = types.systemBet.picks[pick.id.toString()];
        const teaser = types.teaserBet.picks[pick.id.toString()];

        if (!single || !combo || !system) {
            return false;
        }

        return (
            (single.isSelected && single.isLocked === isLocked) ||
            (combo.isSelected && combo.isLocked === isLocked) ||
            (system.isSelected && system.isLocked === isLocked) ||
            (teaser?.isSelected && teaser?.isLocked === isLocked)
        );
    });
}

export function hasUnacceptedPick(types: IBetslipTypeState, picksList: BetslipPick[]): boolean {
    return picksList.some((pick) => {
        if (BetslipUnknownPick.isPick(pick)) {
            return false;
        }

        const single = types.singleBet.picks[pick.id.toString()];
        const combo = types.comboBet.picks[pick.id.toString()];
        const system = types.systemBet.picks[pick.id.toString()];

        if (!single || !combo || !system) {
            return false;
        }

        switch (pick.oddsState) {
            case PickOddsState.Closed:
                return true;

            case PickOddsState.Open:
                return single.isLocked || combo.isLocked || system.isLocked;

            case PickOddsState.Locked:
                return !single.isLocked || !combo.isLocked || !system.isLocked;
        }
    });
}

export function isPickSelectedLocked(pickId: string, types: IBetslipTypeState, picksList: BetslipPick[], isLocked: boolean): boolean {
    const pick = picksList.find((p) => p.id.toString() === pickId);

    if (!pick) {
        return false;
    }

    const single = types.singleBet.picks[pickId];
    const combo = types.comboBet.picks[pickId];
    const system = types.systemBet.picks[pickId];
    const teaser = types.teaserBet.picks[pickId];

    return (
        (single.isSelected && single.isLocked === isLocked) ||
        (combo.isSelected && combo.isLocked === isLocked) ||
        (system.isSelected && system.isLocked === isLocked) ||
        (teaser?.isSelected && teaser?.isLocked === isLocked)
    );
}

export function filterPicksForType(
    types: IBetslipTypeState,
    betslipType: BetslipType,
    picksList: BetslipPick[],
    filter: { isLocked?: boolean; isSelected?: boolean },
): BetslipPick[] {
    let picks: { [id: string]: IPickTypeState } = {};
    switch (betslipType) {
        case BetslipType.Combo:
            picks = types.comboBet.picks;
            break;
        case BetslipType.System:
            picks = types.systemBet.picks;
            break;
        case BetslipType.Teaser:
            picks = types.teaserBet.picks;
            break;
        case BetslipType.BetBuilder:
            picks = types.betBuilder.picks;
            break;
        default:
            picks = types.singleBet.picks;
            break;
    }

    return filterTypePicks(picks, picksList, filter);
}

export function filterTypePicks(
    typePicks: { [id: string]: IPickTypeState },
    picksList: BetslipPick[],
    filter: { isLocked?: boolean; isSelected?: boolean },
): BetslipPick[] {
    return picksList.filter((pick) => {
        const pickState = typePicks[pick.id.toString()];
        if (!pickState) {
            return false;
        }

        const lockedFilterMatched = isNil(filter.isLocked) ? true : pickState.isLocked === filter.isLocked;
        const selectedFilterMatched = isNil(filter.isSelected) ? true : pickState.isSelected === filter.isSelected;

        return lockedFilterMatched && selectedFilterMatched;
    });
}

export function isPickLocked(pickId: PickId, types: IBetslipTypeState): boolean {
    return (
        types.singleBet.picks[pickId.toString()]?.isLocked ||
        types.comboBet.picks[pickId.toString()]?.isLocked ||
        types.systemBet.picks[pickId.toString()]?.isLocked ||
        types.teaserBet.picks[pickId.toString()]?.isLocked
    );
}
