import { Type } from '@angular/core';

import { uniq } from 'lodash-es';

export type Task = () => void;
export class TaskScheduler {
    private tasks: Task[] = [];

    schedule(task: Task): void {
        this.tasks.push(task);
    }

    flush(): void {
        requestAnimationFrame(() => {
            this.tasks.forEach((task) => task());
            this.tasks = [];
        });
    }
}

export interface ColumnComponentRegistration {
    component: Type<any>;
    inputs?: any;
    location: LayoutColumn;
}

export interface ColumnComponentRemoval {
    component: any;
    location: LayoutColumn;
}

export enum LayoutColumn {
    Left = 'Left',
    Center = 'Center',
    Right = 'Right',
}

export enum ColumnSize {
    Default = 'default',
    Small = 'sm',
    Large = 'lg',
    ExtraLarge = 'xl',
}

export enum RightColumnSize {
    Default,
    Large,
    ExtraLarge,
}

export abstract class Layout {
    private availableColumns = [...this.orderedColumns];
    private contentColumns: LayoutColumn[] = [];
    private readonly _registeredComponents: Map<any, ColumnComponentRegistration> = new Map<any, ColumnComponentRegistration>();

    constructor(maxColumnsCount: number, defaultComponents?: ColumnComponentRegistration[]) {
        this.refreshLayout(maxColumnsCount);

        if (defaultComponents) {
            defaultComponents.forEach((c) => this.registerComponent(c));
        }
    }

    /**
     * Possible columns for layout ordered from most important to least important.
     * This is how when the resolution changes we decide on which columns to show.
     *
     * @readonly
     * @protected
     * @abstract
     * @type {LayoutColumn[]}
     * @memberof Layout
     */
    protected get orderedColumns(): LayoutColumn[] {
        return [];
    }

    get isLeftVisible(): boolean {
        return this.columnVisible(LayoutColumn.Left);
    }

    get isRightVisible(): boolean {
        return this.columnAvailable(LayoutColumn.Right);
    }

    get shouldHaveLeftColumn(): boolean {
        return this.columnAvailable(LayoutColumn.Left);
    }

    get shouldHaveRightColumn(): boolean {
        return this.columnAvailable(LayoutColumn.Right);
    }

    get shouldHaveThreeColumns(): boolean {
        return this.shouldHaveLeftColumn && this.shouldHaveRightColumn;
    }

    get maxColumnCount(): number {
        return this.availableColumns.length;
    }

    get visibleColumns(): LayoutColumn[] {
        const columns: LayoutColumn[] = [LayoutColumn.Center];
        if (this.isLeftVisible) {
            columns.push(LayoutColumn.Left);
        }
        if (this.isRightVisible) {
            columns.push(LayoutColumn.Right);
        }

        return columns;
    }

    columnVisible(column: LayoutColumn): boolean {
        return this.columnAvailable(column) && this.contentColumns.some((current) => current === column);
    }

    registeredComponents(): ColumnComponentRegistration[] {
        return Array.from(this._registeredComponents.values());
    }

    registerComponent(data: ColumnComponentRegistration): boolean {
        if (this._registeredComponents.has(data.component)) {
            return false;
        }

        this._registeredComponents.set(data.component, { ...data });
        this.checkColumnsForComponents();

        return true;
    }

    removeComponent(component: any, location: LayoutColumn): void {
        this._registeredComponents.delete(component);
        this.checkColumnsForComponents();
    }

    refreshLayout(visibleColumns: number): boolean {
        const columnNumberDifferent = this.availableColumns.length !== visibleColumns;
        this.availableColumns = this.orderedColumns.slice(0, visibleColumns);

        return columnNumberDifferent;
    }

    private columnAvailable(column: LayoutColumn): boolean {
        return this.availableColumns.some((current) => current === column);
    }

    private checkColumnsForComponents(): void {
        const componentsArray = this.registeredComponents().map((component) => component.location);
        this.contentColumns = uniq(componentsArray);
    }
}

export class FixedLayout extends Layout {
    protected override get orderedColumns(): LayoutColumn[] {
        return [LayoutColumn.Center];
    }
}

export interface ColumnSizeData {
    column: LayoutColumn;
    size: ColumnSize;
    width: number;
}

export class ResponsiveLayout extends Layout {
    protected override get orderedColumns(): LayoutColumn[] {
        return [LayoutColumn.Center, LayoutColumn.Left, LayoutColumn.Right];
    }
}
