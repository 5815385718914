import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';

import { PlainLinkComponent } from '@frontend/vanilla/core';

import { AutomationModule } from '../automation/automation.module';
import { ScrollAdapterComponent } from '../common/scroll-adapter/scroll-adapter.component';
import { CompetitionLogosModule } from '../competition-logos/competition-logos.module';
import { QuicklinkDirective } from '../quicklink/quicklink.directive';
import { BadgeComponent } from './badge.component';
import { BannerMoreInfoComponent } from './banner-more-info.component';
import { BannerComponent } from './banner.component';
import { PromoItemListComponent } from './promo-item-list.component';
import { PromoItemComponent } from './promo-item.component';
import { PromotionStatusButtonWrapperComponent } from './promotion-status-button-wrapper.component';
import { PromotionStatusButtonComponent } from './promotion-status-button.component';
import { SimpleBadgeComponent } from './simple-badge.component';

@NgModule({
    imports: [
        AutomationModule,
        CommonModule,
        CompetitionLogosModule,
        NgOptimizedImage,
        PlainLinkComponent,
        NgOptimizedImage,
        ScrollAdapterComponent,
        BadgeComponent,
        PromotionStatusButtonComponent,
        PromotionStatusButtonWrapperComponent,
        PromoItemComponent,
        QuicklinkDirective,
    ],
    declarations: [BannerComponent, BannerMoreInfoComponent, PromoItemListComponent, SimpleBadgeComponent],
    exports: [
        BannerComponent,
        SimpleBadgeComponent,
        BannerMoreInfoComponent,
        PromoItemComponent,
        PromoItemListComponent,
        PromotionStatusButtonComponent,
        PromotionStatusButtonWrapperComponent,
    ],
})
export class CrmPromotionModule {}
