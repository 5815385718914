import { ActivatedRoute } from '@angular/router';

import { takeUntil } from 'rxjs/operators';

import { OnDestroyCleanup } from './on-destroy-cleanup';

export abstract class OnRouteResolve<TModel> extends OnDestroyCleanup {
    constructor(protected route: ActivatedRoute) {
        super();
    }

    preNgOnInit(): void {
        this.route.data.pipe(takeUntil(this.destroyed$)).subscribe(({ model }) => this.onRouteResolved(model));
    }

    abstract onRouteResolved(model: TModel): void;
}
