import { ComponentRef, Injectable, ViewRef } from '@angular/core';

import { Deferred } from '@frontend/sports/common/base-utils';
import { ReplaySubject } from 'rxjs';

import { ModalBackdropComponent } from './modal-backdrop.component';
import { ModalWindowComponent, PopupClosingResult } from './modal-window.component';

export class ContentRef<T = any> {
    constructor(
        public nodes: any[],
        public viewRef?: ViewRef,
        public componentRef?: ComponentRef<T>,
    ) {}
}

/**
 * A reference to an active (currently opened) modal. Instances of this class
 * can be injected into components passed as modal content.
 */
@Injectable()
export class ActiveModal {
    /**
     * Can be used to close a modal, passing an optional result.
     */
    close(result?: any): void {}

    /**
     * Can be used to dismiss a modal, passing an optional reason.
     */
    dismiss(reason?: any): void {}
}

/**
 * A reference to a newly opened modal.
 */
export class ModalRef<T = any> {
    private static readonly SPACE = ' ';
    private updateData$ = new ReplaySubject<{}>(1);

    readonly newData$ = this.updateData$.asObservable();

    /**
     * The instance of component used as modal's content.
     * Undefined when a TemplateRef is used as modal's content.
     */
    get componentInstance(): any {
        if (this._contentRef.componentRef) {
            return this._contentRef.componentRef.instance;
        }
    }

    constructor(
        private _windowCmptRef: ComponentRef<ModalWindowComponent>,
        private _contentRef: ContentRef<T>,
        private _backdropCmptRef?: ComponentRef<ModalBackdropComponent>,
        private _beforeDismiss?: Function,
    ) {
        _windowCmptRef.instance.dismiss.subscribe(this.dismiss.bind(this));

        this.deferred = new Deferred();
        this.deferred.promise.then(null, () => {});
    }

    updateTitle(title: string): void {
        const windowComponentInstance = this._windowCmptRef?.instance;

        if (windowComponentInstance) {
            windowComponentInstance.title = title;
        }
    }

    updateData(data: {}): void {
        this.updateData$.next(data);
    }

    private deferred: Deferred<any>;

    /**
     * A promise that is resolved when a modal is closed and rejected when a modal is dismissed.
     */
    get result(): Promise<any> {
        return this.deferred.promise;
    }

    /**
     * Append a class name to class list.
     *
     * @param currentClasses
     */
    private appendClass(currentClasses: string, newClass: string): string {
        const classList = currentClasses.split(ModalRef.SPACE);
        classList.push(newClass);

        return classList.join(ModalRef.SPACE);
    }

    /**
     * Add a css class to the modal-window root
     */
    addWindowClass(windowClass: string): this {
        const windowComponentInstance = this._windowCmptRef && this._windowCmptRef.instance;
        if (windowComponentInstance) {
            windowComponentInstance.windowClass = this.appendClass(windowComponentInstance.windowClass, windowClass);
        }

        return this;
    }

    /**
     * Add a new class to the backdrop element.
     *
     * @param newClass the class to add.
     */
    addBackdropClass(newClass: string): this {
        const backdrop = this._backdropCmptRef && this._backdropCmptRef.instance;
        if (backdrop) {
            backdrop.backdropClass = this.appendClass(backdrop.backdropClass, newClass);
        }

        return this;
    }

    /**
     * Can be used to close a modal, passing an optional result.
     */
    close(result?: PopupClosingResult): void {
        if (this._windowCmptRef) {
            this.deferred.resolve(result);
            this._removeModalElements();
        }
    }

    private _dismiss(reason?: any): void {
        this.deferred.reject(reason);
        this._removeModalElements();
    }

    /**
     * Can be used to dismiss a modal, passing an optional reason.
     */
    dismiss(reason?: any): void {
        if (this._windowCmptRef) {
            if (!this._beforeDismiss) {
                this._dismiss(reason);
            } else {
                const dismiss = this._beforeDismiss();
                if (dismiss && dismiss.then) {
                    dismiss.then(
                        (result: any) => {
                            if (result !== false) {
                                this._dismiss(reason);
                            }
                        },
                        () => {},
                    );
                } else if (dismiss !== false) {
                    this._dismiss(reason);
                }
            }
        }
    }

    private _removeModalElements(): void {
        const windowNativeEl = this._windowCmptRef.location.nativeElement;
        windowNativeEl.parentNode.removeChild(windowNativeEl);
        this._windowCmptRef.destroy();

        if (this._backdropCmptRef) {
            const backdropNativeEl = this._backdropCmptRef.location.nativeElement;
            backdropNativeEl.parentNode.removeChild(backdropNativeEl);
            this._backdropCmptRef.destroy();
        }

        if (this._contentRef && this._contentRef.viewRef) {
            this._contentRef.viewRef.destroy();
        }

        this._windowCmptRef = null!;
        this._backdropCmptRef = null!;
        this._contentRef = null!;
    }
}
