import { GameType } from '@bpos/v1/sports-promo';

import { BetPlacementErrorIcon } from '../bet-placement-error-icon';
import { BetslipError } from '../betslip-error';
import { ClientErrorType } from '../client-error-type';
import { PreCheckErrorMixin } from '../pre-check/pre-check-error';

export class RewardTokensGameTypeError extends PreCheckErrorMixin(BetslipError) {
    constructor(gameType: GameType) {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensGameTypeError;
        this.hasClientValidation = true;
        this.gameType = gameType;
    }

    readonly gameType: GameType;
}
