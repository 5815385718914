<div class="title-icon" *ngIf="titleIcon && titleData?.icon">
    <ms-competition-logo
        *ngIf="titleLogo && titleData.id; else staticTemplate"
        class="icon"
        [sportId]="titleData.sportId"
        [leagueId]="titleData.id"
        [fallbackIcon]="titleData.icon">
    </ms-competition-logo>
    <ng-template #staticTemplate>
        <span class="icon" [ngClass]="titleData?.icon"></span>
    </ng-template>
</div>
<div class="title" *ngIf="title" [title]="title">{{ title }}</div>
<div class="globalLink" *ngIf="link && linkText">
    <ms-item [href]="link" [tracking]="undefined" (click)="linkClicked()"><span item-title [innerHtml]="linkText | msTrust: 'html'"></span> </ms-item>
</div>
<ng-content></ng-content>
