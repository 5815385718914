import { Injectable } from '@angular/core';

import { CustomTimeoutError, HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { AccountBalanceConfig, BetslipConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { BalanceProperties } from '@frontend/vanilla/core';
import { BalancePropertiesService } from '@frontend/vanilla/features/balance-properties';
import { BonusBalance, BonusBalanceService } from '@frontend/vanilla/features/bonus-balance';
import { KycStatus, KycStatusService } from '@frontend/vanilla/shared/kyc';
import { Store } from '@ngrx/store';
import {
    BehaviorSubject,
    Observable,
    catchError,
    combineLatest,
    filter,
    retry,
    skip,
    startWith,
    take,
    takeUntil,
    tap,
    throwError,
    timeout,
} from 'rxjs';

import { EpcotConfigService, EpcotModule } from '../common/epcot-config.service';
import { AccountBalanceLoggerService } from '../logging/account-balance-logger.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import UserActions from '../user/actions';
import { UserService } from '../user/services/user.service';
import { IUserRootState } from '../user/state';
import { WalletItem, WalletPersistenceType, WalletType } from './account-balance.models';
import { WalletStoreService } from './wallet-store.service';

@HooksWireup()
@Injectable({ providedIn: 'root' })
export class AccountBalanceService extends OnDestroyCleanup {
    private balanceProperties$ = new BehaviorSubject<BalanceProperties | null>(null);
    private _availableWallets = new BehaviorSubject<WalletItem[]>([]);
    private _selectedWallet$ = new BehaviorSubject<WalletType>(WalletType.Any);
    private readonly logger: SportsRemoteLogger;
    private kycStatus: KycStatus | null;
    private bonusAmount = 0;
    private isWalletSet = false;

    constructor(
        private balanceService: BalancePropertiesService,
        private bonusBalanceService: BonusBalanceService,
        loggerFactory: LoggerFactory,
        private userService: UserService,
        private siteCore: Sitecore,
        private betslipConfig: BetslipConfig,
        private walletStoreService: WalletStoreService,
        private kycStatusService: KycStatusService,
        private epcotConfig: EpcotConfigService,
        private store: Store<IUserRootState>,
        private globalBalanceLogger: AccountBalanceLoggerService,
        private accountBalanceConfig: AccountBalanceConfig,
    ) {
        super();
        this.balanceService.balanceProperties
            .pipe(takeUntil(this.destroyed$))
            .subscribe((balanceProperties) => this.balanceProperties$.next(balanceProperties));

        combineLatest([
            this.balanceService.balanceProperties.pipe(
                startWith(null),
                tap((balance) => {
                    this.globalBalanceLogger.setBalanceInfo(balance);
                }),
            ),
            this.bonusBalanceService.bonusBalance.pipe(startWith(null)),
            this.kycStatusService.kycStatus.pipe(startWith(null)),
            this.userService.onUserLogin$.pipe(startWith(null)),
            this.selectedWallet$,
        ])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(([balanceProperties, bonus, kycStatus]) => {
                this.buildAvailableWalletsForBonus(bonus?.BETTING || bonus?.SPORTSBOOK || 0);
                this.setNewKycStatus(kycStatus);
                this.buildAvailableWallets(balanceProperties);
            });
        this.logger = loggerFactory.getLogger('AccountBalanceService');
    }

    private setNewKycStatus(status: KycStatus | null): void {
        this.kycStatus = status;
    }

    // TODO: @ecpot port chanes
    private buildAvailableWalletsForBonus(bonus: number) {
        this.bonusAmount = bonus;
    }

    /**
     * @param expectBalanceChange: use this parameter if an explicit change in balance properties is expected
     * @returns an observable that will emit once the balance values are updated
     * the observable will emit only once.
     */
    refresh(expectBalanceChange: boolean = true, balanceBeforeRefresh?: number): Observable<unknown> {
        const oldBalance = balanceBeforeRefresh ? balanceBeforeRefresh : this.balanceProperties$.value?.accountBalance;
        this.balanceService.refresh();
        // this.bonusBalanceService.refresh();
        //enable balance logs on refresh.
        this.globalBalanceLogger.enableLogs();

        return this.currentBalanceObservable.pipe(
            //this is neccessary because currentBalanceObservable is a behavior subject
            skip(1),
            filter((data) => !!data && (!expectBalanceChange || data.accountBalance !== oldBalance)),
            take(1),
            //throw a timeout exception after 100 seconds
            timeout({
                each: this.accountBalanceConfig.timeout,
                with: (info) => throwError(() => new CustomTimeoutError(info)),
            }),
            catchError((err: Error) => {
                //try to retrigger balance refresh if the error was a timeout error
                //(is this even a good idea ?)
                if (err instanceof CustomTimeoutError) this.balanceService.refresh();
                throw err;
            }),
            //retry in case of failure
            retry({ count: this.accountBalanceConfig.retry, delay: this.accountBalanceConfig.retryDelay }),
            catchError((err: Error) => {
                //if you end up here, try tracing propagated errors and make sure that tolerateBalanceIndifference is passed correctly (false only when an explicit change is expected)
                this.logger.error(`Balance Refresh Failed ${err.name}`);
                //we add a catch here to set wallets to 0 to prevent unwanted behavior in case the balance refresh wasn't successful or didn't finish
                this.setWallets([]);
                //propagate error
                throw err;
            }),
        );
    }

    get availableWallets(): Observable<WalletItem[]> {
        return this._availableWallets.asObservable();
    }

    get currentBalanceAmount(): number {
        return (this.selectedWallet && this.selectedWallet.amount) || 0;
    }

    get currentBalanceObservable(): Observable<BalanceProperties | null> {
        return this.balanceService.balanceProperties;
    }

    get currentBalanceProperties(): BalanceProperties | null {
        return this.balanceProperties$.value;
    }

    get currentBonusBalanceObservable(): Observable<BonusBalance> {
        return this.bonusBalanceService.bonusBalance;
    }

    get selectedWallet(): WalletItem | undefined {
        return this._availableWallets.getValue().find((b) => b.selected);
    }

    get selectedWallet$(): Observable<WalletType> {
        return this._selectedWallet$.asObservable();
    }

    get availableBalance(): Observable<BalanceProperties | null> {
        return this.balanceService.balanceProperties;
    }

    selectWallet(walletType: WalletType, persistanceType?: WalletPersistenceType): void {
        const isPermanentWallet =
            persistanceType === undefined ? this.walletStoreService.isPermanentWallet : persistanceType === WalletPersistenceType.Permanent;

        this.walletStoreService.setSelectedWallet(walletType, isPermanentWallet);
        this._selectedWallet$.next(walletType);
    }

    private getDefaultWallet(balanceProperties: BalanceProperties): WalletType {
        const userWalletType = this.walletStoreService.selectedWallet;

        if (userWalletType) {
            return userWalletType;
        }

        const mainBalance = this.calculateMainBalance(balanceProperties);
        const paypalBalance = this.calculatePaypalBalance(balanceProperties);

        return paypalBalance > mainBalance ? WalletType.PayPal : WalletType.Main;
    }

    private calculateMainBalance(balanceProperties: BalanceProperties): number {
        return this.betslipConfig.paypalWalletUseNewFormula
            ? this.calcualateMainWithNewFormula(balanceProperties)
            : this.calculateMainWithOldFormula(balanceProperties);
    }

    private calculatePaypalBalance(balanceProperties: BalanceProperties): number {
        return this.betslipConfig.paypalWalletUseNewFormula
            ? balanceProperties.payPalRestrictedBalance + balanceProperties.sportsExclusiveBalance
            : balanceProperties.payPalBalance;
    }

    private isAllowedCountry = (userCountry: string): boolean =>
        !!this.betslipConfig.payPalWalletSelectionEnabledForCountries.find((countryCode) => userCountry === countryCode);

    private buildAvailableWallets(balanceProperties: BalanceProperties | null): void {
        if (!this.userService.isAuthenticated || !balanceProperties) {
            this.setWallets([]);
            if (this.isWalletSet) {
                this.isWalletSet = false;
                this.store.dispatch(UserActions.setWalletLoaded({ walletLoaded: this.isWalletSet }));
            }

            return;
        }

        if (this.isAllowedCountry(this.userService.country)) {
            const walletType = this.getDefaultWallet(balanceProperties);

            const wallets = [
                {
                    type: WalletType.PayPal,
                    amount: this.calculatePaypalBalance(balanceProperties),
                    selected: walletType === WalletType.PayPal,
                    title: this.siteCore.betslip.wallet.PayPalBalance,
                    shortTitle: this.siteCore.betslip.wallet.PayPal,
                    icon: this.betslipConfig.paypalWalletIconClass.paypal,
                },
                {
                    type: WalletType.Main,
                    amount: this.calculateMainBalance(balanceProperties),
                    selected: walletType === WalletType.Main,
                    title: this.siteCore.betslip.wallet.MainBalance,
                    shortTitle: this.siteCore.betslip.wallet.Main,
                    icon: this.betslipConfig.paypalWalletIconClass.main,
                },
            ];
            this.setWallets(wallets);
        } else {
            this.setWallets([
                {
                    type: WalletType.Any,
                    amount: balanceProperties.availableBalance + (this.bonusAmount || 0),
                    selected: true,
                    title: '',
                    shortTitle: '',
                    icon: '',
                },
            ]);
        }
        if (!this.isWalletSet) {
            this.isWalletSet = true;
            this.store.dispatch(UserActions.setWalletLoaded({ walletLoaded: this.isWalletSet }));
        }
    }

    setWallets(wallets: WalletItem[]) {
        const selectedWalletAmount = wallets.find((t) => t.selected)?.amount ?? 0;
        this.globalBalanceLogger.setLastWalletBalance(selectedWalletAmount);
        this._availableWallets.next(wallets);
    }

    private calcualateMainWithNewFormula(balanceProperties: BalanceProperties): number {
        let balance =
            balanceProperties.sportsDepositBalance +
            balanceProperties.sportsWinningsBalance +
            balanceProperties.allWinningsBalance +
            balanceProperties.cashoutRestrictedBalance -
            balanceProperties.payPalRestrictedBalance;

        if (this.kycStatus && this.kycStatus.kycVerified) {
            balance += balanceProperties.pokerWinningsBalance + balanceProperties.slotsWinningsBalance + balanceProperties.sportsRestrictedBalance;
        }

        return balance;
    }

    private calculateMainWithOldFormula(balanceProperties: BalanceProperties): number {
        return balanceProperties.cashoutRestrictedBalance + balanceProperties.cashoutableBalance - balanceProperties.payPalBalance;
    }

    showBalanceOnQuickBet(): boolean {
        return this.userService.isAuthenticated && this.betslipConfig.showBalanceOnQuickBet;
    }

    showBalanceAndDepositOnBetslip(): boolean {
        return (
            this.userService.isAuthenticated &&
            this.betslipConfig.showBalanceAndDepositOnBetslip &&
            this.epcotConfig.isEnabled(EpcotModule.Betslip) &&
            !this.betslipConfig.isBalanceHeaderEnabled
        );
    }
}
