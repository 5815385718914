import { inject } from '@angular/core';

import { EventsService, TimerService, VanillaEventNames } from '@frontend/vanilla/core';
import { filter, take } from 'rxjs';

import { TaskScheduler } from './layout.model';

export class FooterMeasurement {
    private height: number;
    private tryCount = 0;
    private maxTryCount = 3;
    private callbackTimeout = 2500;
    get footerSize(): number {
        return this.height;
    }

    eventService = inject(EventsService);
    timer = inject(TimerService);

    measureFooter(scheduler: TaskScheduler): void {
        scheduler.schedule(() =>
            this.eventService.allEvents
                .pipe(
                    filter((event) => event.eventName === VanillaEventNames.FooterLoaded),
                    take(1),
                )
                .subscribe(() => this.calculateFooter()),
        );
    }

    private calculateFooter(): void {
        const footer = document.querySelector<HTMLElement>('vn-footer,vn-responsive-footer');
        if (footer == null) {
            this.retry();
            return;
        }

        const height = footer.getBoundingClientRect().height;
        if (height) {
            this.height = height;
        } else {
            this.retry();
        }
    }

    private retry() {
        if (this.maxTryCount > this.tryCount) {
            this.timer.scheduleIdleCallback(() => this.calculateFooter(), this.callbackTimeout);
            this.tryCount++;
        }
    }

    reset(): void {
        this.height = 0;
    }
}
