import { FixturePage } from '@cds/betting-offer';
import { CountItem, LeagueItem } from '@frontend/sports/common/base-utils';

import { IntervalLink } from '../../../calendar/count-interval.service';
import { GridSorting } from '../../../grid-base/grid.model';
import { Tile } from '../../../highlights-marquee/marquee-tile.model';
import { CompetitionRoute } from '../../../navigation/navigation.models';
import type { ShowcaseModuleData } from '../../../showcase/showcase.model';
import { TabBarItem } from '../../../tab-bar/tab-bar.models';
import { TeamPagesViewModel } from '../../../teampages-core/team-pages.model';

export interface FixtureList extends FixturePage {
    sport?: CountItem;
    params?: CompetitionRoute;
    tabs?: TabBarItem[];
    sorting?: GridSorting;
    virtualCompetition?: LeagueItem;
    showcaseData?: ShowcaseModuleData;
    competitionTitle?: string;
    marqueeData?: Tile[];
    teampagesViewModel?: TeamPagesViewModel;
    calendarIntervals?: IntervalLink[];
    calendarPills?: TabBarItem[];
    sportsOverview?: CountItem;
    calendarLiveCounts?: CountItem;
}

export enum FixtureTab {
    Matches = 1,
    Outrights = 2,
    Standings = 3,
    Specials = 4,
    Teams = 5,
    Priceboost = 6,
}
