import { createSelector } from '@ngrx/store';
import { selectTokensStateContext } from 'packages/sports/common/betslip/modules/reward-tokens/selectors';
import { getSelectedAndEligibleAccaBoost } from 'packages/sports/common/betslip/modules/reward-tokens/services/linear-reward-tokens.utils';
import { betslipCurrentTypeSelector } from 'packages/sports/common/betslip/modules/types/base/selectors';

import { BetslipType } from '../betslip-base/models/betslip-type.enum';

export const accaBoostTokenSelector = createSelector(selectTokensStateContext, ({ eligibilityState, tokens, types }) => {
    return getSelectedAndEligibleAccaBoost(eligibilityState, tokens, types);
});

export const accaBoostTokenForCurrentTypeSelector = createSelector(accaBoostTokenSelector, betslipCurrentTypeSelector, (accaBoost, type) => {
    return type === BetslipType.Combo ? accaBoost : null;
});

export const isAccaBoostTokenSelector = createSelector(accaBoostTokenSelector, (token) => !!token);
