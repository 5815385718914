import { IncorrectBetCount } from '../general/incorrect-bet-count';
import { ErrorDetailsSpecifier, PreCheckErrorMixin } from './pre-check-error';

export class IncorrectOptionsCountPreCheckError extends PreCheckErrorMixin(IncorrectBetCount) {
    constructor(
        expected: number,
        received: number,
        public errorDetails: ErrorDetailsSpecifier | null,
    ) {
        super(expected, received);
    }

    override readonly expectedBetCount: number;
    override readonly receivedBetCount: number;
}
