import { DayOfRaceDate } from '@cds/betting-offer/domain-specific/horse-racing';

import { RacingUrlParam } from '../navigation-core/url-helper.service';

export function getDayOfRace(dateAsString: string): DayOfRaceDate {
    const now = new Date();
    const tomorrowNow = new Date();
    tomorrowNow.setDate(tomorrowNow.getDate() + 1);
    const date = new Date(dateAsString);
    if (now.getUTCFullYear() === date.getUTCFullYear() && now.getUTCMonth() === date.getUTCMonth() && now.getUTCDate() === date.getUTCDate()) {
        // now utc date is equal to given date utc.
        return DayOfRaceDate.Today;
    }
    if (
        tomorrowNow.getUTCFullYear() === date.getUTCFullYear() &&
        tomorrowNow.getUTCMonth() === date.getUTCMonth() &&
        tomorrowNow.getUTCDate() === date.getUTCDate()
    ) {
        // now utc date is equal to given date utc.
        return DayOfRaceDate.Tomorrow;
    }

    return DayOfRaceDate.Today;
}

export function convertDayOfRaceToUrlParam(dayOfRace: DayOfRaceDate): RacingUrlParam {
    switch (dayOfRace) {
        case DayOfRaceDate.Today:
            return RacingUrlParam.Today;
        case DayOfRaceDate.Tomorrow:
            return RacingUrlParam.Tomorrow;
    }
}
