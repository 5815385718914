import { Injectable } from '@angular/core';

import { MarqueeTilesConfig } from '@frontend/sports/common/client-config-data-access';
import { Observable, combineLatest, of } from 'rxjs';
import { first, map, mergeMap } from 'rxjs/operators';

import { MarqueeApi } from '../cds/marquee-api.service';
import { IMarqueeRequestInfo, MarqueeRequestBuilderService } from './marquee-request-builder.service';
import { MarqueeResponseMapperService } from './marquee-response-mapper.service';
import { CompetitonTileRequestModel, Tile } from './marquee-tile.model';

@Injectable({ providedIn: 'root' })
export class MarqueeService {
    private configReady: Observable<void>;

    constructor(
        private marqueeRequestBuilderService: MarqueeRequestBuilderService,
        private marqueeResponseMapperService: MarqueeResponseMapperService,
        private marqueeApi: MarqueeApi,
        marqueeTilesConfig: MarqueeTilesConfig,
    ) {
        this.configReady = marqueeTilesConfig.whenReady.pipe(first());
    }

    loadMarquees(fixtureId: string): Observable<Tile[]> {
        return combineLatest([this.marqueeRequestBuilderService.buildForFixture(fixtureId), this.configReady]).pipe(
            mergeMap(([marqueeRequest]) => this.callApi(marqueeRequest)),
        );
    }

    loadMarqueesFor(competitonTileRequestModel: CompetitonTileRequestModel): Observable<Tile[]> {
        const marqueeRequest$ = this.marqueeRequestBuilderService.buildForCompetition(
            competitonTileRequestModel.competitionIds,
            competitonTileRequestModel.sportId,
            competitonTileRequestModel.type,
        );

        return combineLatest([marqueeRequest$, this.configReady]).pipe(
            mergeMap(([marqueeRequest]) => {
                const dateInterval = competitonTileRequestModel.interval || {};

                marqueeRequest.request.from = dateInterval.from;
                marqueeRequest.request.to = dateInterval.to;

                return this.callApi(marqueeRequest);
            }),
        );
    }

    loadTeamPagesMarquees(fixtureIds: string[]): Observable<Tile[]> {
        return this.configReady.pipe(
            mergeMap(() => {
                const marqueeRequest = this.marqueeRequestBuilderService.buildForTeamPagesFixture(fixtureIds);
                if (marqueeRequest) {
                    return this.callApi(marqueeRequest);
                }

                return of([]);
            }),
        );
    }

    private callApi(marqueeRequest: IMarqueeRequestInfo): Observable<Tile[]> {
        return this.marqueeApi
            .getMarquees(marqueeRequest.request)
            .pipe(map((responseMarquee) => this.marqueeResponseMapperService.mapFixtures(responseMarquee || [], marqueeRequest.tiles)));
    }
}
