import { Decimal } from 'decimal.js';

import { decimalOddsToAmerican, roundDecimalToAmerican } from '../odds/converters';
import { NativeFraction } from './native-fraction';

export class INativePrice {
    americanOdds?: number;
    denominator?: number;
    numerator?: number;
    odds?: number;
}

export class INativePriceCalculated extends INativePrice {
    americanOddsCalculated?: number;
}

export class NativePrice {
    get odds(): Decimal {
        return this.decimals;
    }

    get numerator(): Decimal {
        return this.getFraction().numerator;
    }

    get denominator(): Decimal {
        return this.getFraction().denominator;
    }

    get fraction(): Decimal {
        return this.getFraction().getValue();
    }

    get americanOdds(): Decimal {
        return new Decimal(this.getAmericanOdds());
    }

    get americanOddsCalculation(): number | undefined {
        return this.usOddsCalculated;
    }

    private decimals: Decimal;
    private nativeFraction?: NativeFraction;
    private usOdds?: number;
    private usOddsCalculated?: number;
    private isUsOddsRounding: boolean;

    // creators
    static empty(): NativePrice {
        return NativePrice.fromNativePrice({
            americanOdds: 0,
            denominator: 0,
            numerator: 0,
            odds: 0,
        });
    }

    static default(): NativePrice {
        return NativePrice.fromNativePrice({
            americanOdds: 0,
            denominator: 1,
            numerator: 0,
            odds: 1,
        });
    }

    static fromNativePrice(input: INativePriceCalculated): NativePrice {
        return this.fromNativePriceElement({
            odds: input.odds || 0,
            numerator: input.numerator || 0,
            denominator: input.denominator || 0,
            americanOdds: input.americanOdds || 0,
            americanOddsCalculated: input.americanOddsCalculated,
        });
    }

    static fromNativePriceElement(input: INativePriceCalculated, usOddsRounding: boolean = false): NativePrice {
        const price = new NativePrice();
        price.isUsOddsRounding = usOddsRounding;
        price.decimals = this.getInitialDecimals(input);

        if (input.numerator != null && input.denominator != null) {
            price.nativeFraction = new NativeFraction(input.numerator, input.denominator);
        }

        if (input.americanOdds != null) {
            price.usOdds = input.americanOdds;
        }

        if (input.americanOddsCalculated != null) {
            price.usOddsCalculated = input.americanOddsCalculated;
        }

        return price;
    }

    static fromDecimals(value: number): NativePrice {
        return NativePrice.fromNativePriceElement({
            odds: value,
            numerator: new Decimal(value).minus(1).toNumber(),
            denominator: 1,
        });
    }

    static fromDecimalsElement(value: number, usOddsRounding: boolean = false): NativePrice {
        return NativePrice.fromNativePriceElement({ odds: value }, usOddsRounding);
    }

    // static methods
    static americanOddsToDecimalOdds(usOdds: Decimal): Decimal {
        return usOdds.greaterThan(0) ? usOdds.dividedBy(100).add(1) : new Decimal(-100).dividedBy(usOdds).add(1);
    }

    static floorDecimalToAmerican(value: Decimal): number {
        return NativePrice.decimalToAmerican(value).floor().toNumber();
    }

    private static decimalToAmerican(value: Decimal): Decimal {
        return decimalOddsToAmerican(value);
    }

    private static getInitialDecimals(input: INativePrice): Decimal {
        if (input.odds != null) {
            return new Decimal(input.odds);
        }
        if (input.numerator != null && input.denominator != null) {
            return new Decimal(input.numerator / input.denominator + 1);
        }
        if (input.americanOdds != null) {
            return this.americanOddsToDecimalOdds(new Decimal(input.americanOdds));
        }

        return new Decimal(0);
    }

    // comparison
    lessThan(other: NativePrice | number): boolean {
        return this.odds.lessThan(this.toCompare(other));
    }

    lessThanOrEqualTo(other: NativePrice | number): boolean {
        return this.odds.lessThanOrEqualTo(this.toCompare(other));
    }

    greaterThan(other: NativePrice | number): boolean {
        return this.odds.greaterThan(this.toCompare(other));
    }

    greaterThanOrEqualTo(other: NativePrice | number): boolean {
        return this.odds.greaterThanOrEqualTo(this.toCompare(other));
    }

    equal(other: NativePrice | number): boolean {
        return this.odds.equals(this.toCompare(other));
    }

    isEmpty(): boolean {
        return this.decimals.equals(0);
    }

    private toCompare(other: NativePrice | number): number {
        return typeof other === 'number' ? other : other.odds.toNumber();
    }

    // helpers
    clone(): NativePrice {
        return NativePrice.fromNativePrice({
            americanOdds: this.americanOdds.toNumber(),
            denominator: this.denominator.toNumber(),
            numerator: this.numerator.toNumber(),
            odds: this.odds.toNumber(),
        });
    }

    cloneNativePriceElement(): NativePrice {
        return NativePrice.fromNativePriceElement({
            americanOdds: this.americanOdds.toNumber(),
            denominator: this.denominator.toNumber(),
            numerator: this.numerator.toNumber(),
            odds: this.odds.toNumber(),
        });
    }

    toJSON(): INativePrice {
        return {
            americanOdds: this.americanOdds.toNumber(),
            denominator: this.denominator.toNumber(),
            numerator: this.numerator.toNumber(),
            odds: this.odds.toNumber(),
        };
    }

    roundDecimalToAmerican(value: Decimal): number {
        return roundDecimalToAmerican(value, this.isUsOddsRounding);
    }

    toString(): string {
        return `eu: ${this.odds.toNumber()}, uk: { numerator: ${this.numerator.toNumber()}, denominator: ${this.denominator.toNumber()} }, us: ${this.americanOdds.toNumber()}`;
    }

    private getAmericanOdds(): number {
        if (this.usOdds != null) {
            return this.usOdds;
        }

        if (this.odds.equals(0)) {
            this.usOdds = 0;

            return this.usOdds;
        }

        this.usOdds = this.roundDecimalToAmerican(this.decimals);

        return this.usOdds;
    }

    private getFraction(): NativeFraction {
        return this.nativeFraction || NativeFraction.toFraction(this.decimals);
    }
}
