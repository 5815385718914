import { InjectionToken } from '@angular/core';

export interface GridConfig {
    hasBetsCountButton: boolean;
    hasExtendedScoreboard: boolean;
    hasMarketSwitcherGroupTitle: boolean;
    isMarketGroupToggable: boolean;
    useColoredIcon: boolean;
    useRegionCode: boolean;
    hasExtendedOutcome: boolean;
    isAllBetsButtonVisible: boolean;
    isScrollAdapterButtonSizeLarge: boolean;
    isOptionGroupLinkable: boolean;
    isEventNameALink: boolean;
    hidePromotionBadgeInGrid: boolean;
    areEmptyEventStatisticsVisible: boolean;
}

export const GRID_CONFIG = new InjectionToken<GridConfig>('ms-grid');
