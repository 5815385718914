import { getCurrencySymbol } from '@angular/common';
import { Injectable } from '@angular/core';

import { ParameterType, ReplaceableParameter } from '@bpos/v1/sports-promo/campaigns';
import { uniq } from 'lodash-es';

import { MoneyService } from '../money/money.service';
import { UserService } from '../user/services/user.service';

@Injectable({ providedIn: 'root' })
export class CrmPromotionReplaceParametersService {
    constructor(
        private moneyService: MoneyService,
        private m2User: UserService,
    ) {}

    replaceParameters(
        text: string,
        replaceableParameters: { [key: string]: ReplaceableParameter },
        useCurrencySymbol: boolean = false,
        showCurrencySymbol: boolean = true,
        hideEmptyFraction: boolean = false,
    ): string {
        if (!text) {
            return text;
        }

        const keys = uniq(text.match(/#([a-zA-Z0-9_]*?)#/g) || []);

        keys.forEach((key) => {
            if (!replaceableParameters[key]) {
                return;
            }
            const value = replaceableParameters[key].value;

            switch (replaceableParameters[key].type) {
                case ParameterType.Text: {
                    text = this.replace(text, key, value);
                    break;
                }
                case ParameterType.Date: {
                    text = this.replace(text, key, new Date(value).toLocaleString());
                    break;
                }
                case ParameterType.Money: {
                    let formattedValue: string;
                    if (useCurrencySymbol) {
                        formattedValue = this.moneyService.transform(+value, false, 2, hideEmptyFraction);
                        const symbol = getCurrencySymbol(this.m2User.currency, 'narrow');
                        formattedValue = `${symbol}${formattedValue}`;
                    } else {
                        formattedValue = this.moneyService.transform(+value, showCurrencySymbol, 2, hideEmptyFraction);
                    }

                    text = this.replace(text, key, formattedValue);
                    break;
                }
                case ParameterType.Number: {
                    text = this.replace(text, key, value);
                    break;
                }
                case ParameterType.Percentage: {
                    text = this.replace(text, key, value + '%');
                    break;
                }
            }
        });

        return text;
    }

    private replace(text: string, key: string, value: string): string {
        return text.replace(new RegExp(key, 'g'), value);
    }
}
