import { Injectable, Type } from '@angular/core';

import { SitecoreMyBetsConfig } from '@frontend/sports/common/client-config-data-access';
import { StylesService } from '@frontend/vanilla/core';

import { EventModel } from '../event-model/model/event.model';
import { LoggerFactory } from '../logging/logger-factory.service';
import { MyBetsModule } from '../my-bets';
import { ComponentLoaderService, ComponentProxy } from './component-loader.service';
import ModuleLoaderService from './module-loader.service';

export interface EventViewBinding {
    event: EventModel;
}

@Injectable({ providedIn: 'root' })
export class MyBetsComponentLoaderService extends ComponentLoaderService<MyBetsModule> {
    moduleName = 'MyBetsModule';

    constructor(
        moduleLoader: ModuleLoaderService,
        loggerFactory: LoggerFactory,
        stylesService: StylesService,
        private sitecoreMyBets: SitecoreMyBetsConfig,
    ) {
        super(moduleLoader, loggerFactory, stylesService);
    }

    getColumnViewComponent(): ComponentProxy {
        return this.getComponentProxy((module) => module.columnComponent, 'my-bets', this.sitecoreMyBets);
    }

    getEventViewComponent(): ComponentProxy<EventViewBinding> {
        return this.getComponentProxy<EventViewBinding>((module) => module.eventComponent, 'my-bets', this.sitecoreMyBets);
    }

    getModule(): Promise<Type<MyBetsModule>> {
        return import(/* webpackChunkName: "ms-my-bets" */ '../my-bets').then((m) => m.MyBetsModule);
    }
}
