import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { BetslipError } from '../betslip-error';
import { PreCheckErrorMixin } from './pre-check-error';

export class NoBetbuilderSystemPreCheckError extends PreCheckErrorMixin(BetslipError) {
    constructor() {
        super();
        this.type = PlacementErrorType.NoBetbuilderSystemAllowed;
    }
}
