import { BetPlacementErrorIcon } from '../../bet-placement-error-icon';
import { BetslipError } from '../../betslip-error';
import { ClientErrorType } from '../../client-error-type';

export class RewardTokensSelectionLevelOddsError extends BetslipError {
    constructor(selectionLevelMinimumOdds: string) {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensSelectionLevelOddsError;
        this.hasClientValidation = true;
        this.selectionLevelMinimumOdds = selectionLevelMinimumOdds;
    }

    readonly selectionLevelMinimumOdds: string;
}
