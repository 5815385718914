import { SportConstant, getPartition } from '@frontend/sports/common/base-utils';

import { BetslipPick } from './betslip-pick';
import { V2PickId } from './pick-id';
import { IBetslipV2PickFixture, IBetslipV2PickStorage, PriceType } from './pick-models';
import { SignedName } from './signed-name.model';

export abstract class BetslipV2Pick extends BetslipPick {
    override id: V2PickId;
    fixture: IBetslipV2PickFixture;

    static isPick(pick: BetslipPick): pick is BetslipV2Pick {
        return pick instanceof BetslipV2Pick;
    }

    protected constructor() {
        super();
        this.priceType = PriceType.Fixed;
    }

    isMarketVisible(): boolean {
        return this.market.isVisible;
    }

    protected isPriceVisible(): boolean {
        return true;
    }

    protected override initPropertiesFromJSON(value: IBetslipV2PickStorage): void {
        super.initPropertiesFromJSON(value);
        this.fixture = {
            ...value.fixture,
            eventDate: new Date(value.fixture.eventDate),
            cutOffDate: new Date(value.fixture.cutOffDate),
            league: value.fixture.league,
            region: value.fixture.region,
        };
    }

    get leagueId(): number {
        if (this.fixture.league !== null) {
            return this.fixture.league.id;
        }

        return 0;
    }

    get leagueName(): SignedName {
        return (
            this.fixture.league?.name || {
                name: '',
                signature: '',
            }
        );
    }

    get regionId(): number {
        if (this.fixture.region !== null) {
            return this.fixture.region.id;
        }

        return 0;
    }

    get regionName(): SignedName {
        return (
            this.fixture.region?.name || {
                name: '',
                signature: '',
            }
        );
    }

    override isOpen(): boolean {
        if (!super.isOpen()) {
            return false;
        }

        return this.fixture.cutOffDate > new Date();
    }

    get eventName(): SignedName {
        return this.fixture.name;
    }

    get sportId(): SportConstant {
        return this.fixture.sportId;
    }

    get partitionId(): number | undefined {
        return getPartition(this.fixture.fixtureId);
    }

    get sportName(): SignedName {
        return this.fixture.sportName;
    }

    get eventDate(): Date {
        return this.fixture.eventDate;
    }

    get competitionName(): SignedName {
        return this.leagueName;
    }

    get competitionId(): number {
        return this.leagueId;
    }

    abstract override get isLive(): boolean;

    get isVirtual(): boolean {
        return this.fixture.virtual;
    }

    override toJSON(): IBetslipV2PickStorage {
        const base = super.toJSON();

        return {
            ...base,
            fixture: {
                ...this.fixture,
                eventDate: this.fixture.eventDate.toISOString(),
                cutOffDate: this.fixture.cutOffDate.toISOString(),
                league: this.fixture.league && {
                    id: this.fixture.league.id,
                    name: { ...this.fixture.league.name },
                    realCompetitionId: this.fixture.league.realCompetitionId,
                },
                region: this.fixture.region && {
                    id: this.fixture.region.id,
                    name: { ...this.fixture.region.name },
                },
            },
        };
    }

    setMarketInvisible(): void {
        this.market.isVisible = false;
    }
}
