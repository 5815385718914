import { DOCUMENT, NgIf } from '@angular/common';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
} from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { Store } from '@ngrx/store';
import { PopupActions } from 'packages/sports/common/betslip/modules/popup/actions';

import { AutomationModule } from '../../automation/automation.module';
import { ShowInEpcotDirective } from '../../common/epcot-config.directive';
import { EpcotConfigService } from '../../common/epcot-config.service';
import { TimerService } from '../../common/timer.service';
import { MultiBuilderDispatcherEvents } from '../../multi-builder/multi-builder.model';
import { ModalDismissReasons } from './modal-dismiss-reasons';

export enum PopupAction {
    Open = 'open',
    ManualClose = 'manualClose',
    CloseByNavigation = 'closeByNavigation',
    CloseByMybets = 'CloseByMybets',
    CloseByNextPopup = 'CloseByNextPopup',
}

export interface PopupClosingResult {
    closeAction: PopupAction;
}

@Component({
    selector: 'ms-modal-window',
    templateUrl: 'modal-window.html',
    standalone: true,
    imports: [NgIf, AutomationModule, ShowInEpcotDirective],
})
export class ModalWindowComponent implements OnInit, AfterViewInit, OnDestroy {
    @HostBinding('attr.aria-labelledby')
    @Input()
    ariaLabelledBy: string;

    /**
     * Provide a template to be rendered inside the modal-header.
     *
     * @type {TemplateRef<any>}
     * @memberof ModalOptions
     */
    @Input()
    headerTemplate: TemplateRef<any>;

    slideOutFromTopToButtom = 'modal-slide-out-to-bottom';
    slideOutToRight = 'modal-slide-out-to-right';

    @Input() backdrop: boolean | string = true;
    @Input() centered: string;
    @Input() keyboard = true;
    @Input() size: string;
    @Input() windowClass: string;
    @Input() showDialogHeader?: boolean;
    @Input() title?: string;
    @Input() hideCloseButton?: boolean;
    @Input() showBackNav?: boolean;
    @Input() closePrevious?: boolean;

    @Output() dismiss = new EventEmitter<{ closeAction: PopupAction.ManualClose }>();

    @HostBinding('attr.role') role = 'dialog';
    @HostBinding('attr.tabindex') tabindex = '-1';

    @HostBinding()
    get class(): string {
        return 'modal ' + (this.windowClass ? ' ' + this.windowClass : '');
    }

    showCloseBtnText?: boolean;

    constructor(
        public sitecore: Sitecore,
        @Inject(DOCUMENT) private document: Document,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private timerService: TimerService,
        private dispatcherService: DispatcherService,
        private epcotConfigService: EpcotConfigService,
        private store: Store,
    ) {}

    close(): void {
        this.renderer.addClass(this.elementRef.nativeElement, this.slideOutFromTopToButtom);
        if (this.closePrevious) {
            this.dispatcherService.dispatch(MultiBuilderDispatcherEvents.ClosePreviousPopup);
        }

        this.dismissPopup();

        this.store.dispatch(PopupActions.manualClose());
    }

    goBack(): void {
        this.renderer.addClass(this.elementRef.nativeElement, this.slideOutToRight);
        this.dismissPopup();
    }

    private dismissPopup() {
        if (this.epcotConfigService.isShowMyBetsPopup) {
            this.timerService.setTimeoutOutsideAngular(() => {
                this.dismiss.emit({ closeAction: PopupAction.ManualClose });
            }, 500);
        } else {
            this.dismiss.emit({ closeAction: PopupAction.ManualClose });
        }
    }

    @HostListener('mousedown', ['$event'])
    @HostListener('touch', ['$event'])
    backdropClick(event: Event): void {
        if (this.backdrop === true && this.elementRef.nativeElement === event.target) {
            this.dismissWindow(ModalDismissReasons.BackdropClick);
        }
    }

    //Prevent any scroll event within modal container to bubble up to body (preventing unwanted double scroll appearing)
    @HostListener('scroll', ['$event'])
    @HostListener('touchmove', ['$event'])
    preventScrolPropogation(event: Event) {
        event.stopPropagation();
    }

    @HostListener('keyup.esc', ['$event'])
    escKey(event: Event): void {
        if (this.keyboard && !event.defaultPrevented) {
            this.dismissWindow(ModalDismissReasons.Esc);
        }
    }

    private dismissWindow(reason: any): void {
        if (this.epcotConfigService.isShowMyBetsPopup) {
            this.renderer.addClass(this.elementRef.nativeElement, this.slideOutFromTopToButtom);
            this.timerService.setTimeoutOutsideAngular(() => {
                this.dismiss.emit(reason);
            }, 500);
        } else {
            this.dismiss.emit(reason);
        }
    }

    ngOnInit(): void {
        this.renderer.addClass(this.document.body, 'modal-open');
    }

    ngAfterViewInit(): void {
        this.timerService.setTimeoutOutsideAngular(() => {
            if (!this.elementRef.nativeElement.contains(document.activeElement)) {
                this.elementRef.nativeElement['focus'].apply(this.elementRef.nativeElement, []);
            }
        });
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(this.document.body, 'modal-open');
    }
}
