<div (click)="goBack()" class="breadcrumb-back">
    <span class="breadcrumb-theme-left"><i class="theme-left"></i></span>
</div>
<div class="breadcrumb-items" msScrollIosHack msScrollTo *ngIf="items.length">
    <div #msScrollToItem class="breadcrumb-item" *ngFor="let crumb of items" [ngClass]="crumb.classes">
        <a [href]="crumb.url" (click)="selected(crumb)" *ngIf="crumb.url; else staticTemplate">
            <ng-container *ngTemplateOutlet="linkTemplate; context: { $implicit: crumb }"></ng-container>
        </a>
        <ng-template #staticTemplate>
            <a> <ng-container *ngTemplateOutlet="linkTemplate; context: { $implicit: crumb }"></ng-container> </a>
        </ng-template>
    </div>
</div>

<ng-container #breadcrumbSlot></ng-container>

<ng-template #linkTemplate let-crumb>
    <ms-competition-logo
        [sportId]="crumb.sportId"
        [leagueId]="crumb.leagueId"
        *ngIf="crumb.sportId && crumb.leagueId"
        [fallbackIcon]="crumb.fallbackIcon"
        [isWithRightImage]="crumb.isWithRightImage"
        [imageProfile]="crumb.imageProfile"></ms-competition-logo>
    <span class="sports-icon {{ crumb.icon }}" *ngIf="crumb.icon && !crumb.sportId && !crumb.leagueId"></span>
    <span class="breadcrumb-title">{{ crumb.title }}</span>
</ng-template>
