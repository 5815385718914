import { OddsDisplayFormat } from '@frontend/sports/types/models/user-settings';
import { createAction, props } from '@ngrx/store';

import { IConfigSettings, IUserBettingSettings } from './model/user.models';
import { IUserState } from './state';

export default class UserActions {
    static readonly ACTION_SCHEMA = '@sports_user/';

    private static readonly INIT_STATE = `${UserActions.ACTION_SCHEMA}INIT_STATE`;
    private static readonly USER_LOGGED_IN = `${UserActions.ACTION_SCHEMA}USER_LOGGED_IN`;
    private static readonly USER_LOGGED_OUT = `${UserActions.ACTION_SCHEMA}USER_LOGGED_OUT`;
    private static readonly UPDATE_STATE = `${UserActions.ACTION_SCHEMA}UPDATE_STATE`;
    private static readonly SET_WALLET_LOADED = `${UserActions.ACTION_SCHEMA}SET_WALLET_LOADED`;

    static initState = createAction(UserActions.INIT_STATE, props<{ state: IUserState }>());
    static setWalletLoaded = createAction(UserActions.SET_WALLET_LOADED, props<{ walletLoaded: boolean }>());
    static userLoggedIn = createAction(UserActions.USER_LOGGED_IN);
    static userLoggedOut = createAction(UserActions.USER_LOGGED_OUT);
    static updateState = createAction(
        UserActions.UPDATE_STATE,
        props<{
            settings: IUserBettingSettings;
            config: IConfigSettings;
            oddsFormat?: OddsDisplayFormat;
        }>(),
    );
}
