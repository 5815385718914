import { Injectable } from '@angular/core';

import { INativePrice, INativePriceCalculated, NativePrice } from '@frontend/sports/common/odds-lib';
import { OddsDisplayFormat } from '@frontend/sports/types/models/user-settings';
import { Decimal } from 'decimal.js';

@Injectable({ providedIn: 'root' })
export class OddsFormatCalculatorService {
    static add(price: NativePrice, otherPrice: NativePrice, oddsFormat: OddsDisplayFormat): NativePrice {
        const resultPrice: INativePrice = {};

        switch (oddsFormat) {
            case OddsDisplayFormat.UK:
                resultPrice.numerator = price.fraction.add(otherPrice.fraction).minus(1).toNumber();
                resultPrice.denominator = 1;
                break;
            case OddsDisplayFormat.US:
                const americanDecimal = NativePrice.americanOddsToDecimalOdds(price.americanOdds);
                const otherAmericanDecimal = NativePrice.americanOddsToDecimalOdds(otherPrice.americanOdds);

                resultPrice.americanOdds = price.roundDecimalToAmerican(americanDecimal.add(otherAmericanDecimal));
                break;
            default:
                resultPrice.odds = price.odds.add(otherPrice.odds).toNumber();
        }

        return NativePrice.fromNativePriceElement(resultPrice);
    }

    static multiplyDecimal(price: Decimal, multiplier: Decimal, round: boolean, decimals = 2) {
        return round ? price.times(multiplier).toDecimalPlaces(decimals, Decimal.ROUND_HALF_UP) : price.times(multiplier);
    }

    static multiply(price: NativePrice, multiplier: NativePrice | number, oddsFormat: OddsDisplayFormat, roundPriceValues = false): NativePrice {
        const resultPrice: INativePriceCalculated = {};
        const decimalMultiplier = this.getMultiplier(multiplier, oddsFormat);

        switch (oddsFormat) {
            case OddsDisplayFormat.UK:
                resultPrice.numerator = this.multiplyDecimal(price.fraction, decimalMultiplier, roundPriceValues).minus(1).toNumber();

                resultPrice.denominator = 1;
                break;
            case OddsDisplayFormat.US:
                const americanDecimal = NativePrice.americanOddsToDecimalOdds(price.americanOdds);

                resultPrice.americanOdds = price.roundDecimalToAmerican(this.multiplyDecimal(americanDecimal, decimalMultiplier, roundPriceValues));
                resultPrice.americanOddsCalculated = this.multiplyDecimal(
                    new Decimal(price.americanOddsCalculation || americanDecimal),
                    new Decimal(this.getAmericanOddsCalculation(multiplier) || decimalMultiplier),
                    roundPriceValues,
                ).toNumber();
                break;
            default:
                resultPrice.odds = this.multiplyDecimal(price.odds, decimalMultiplier, roundPriceValues).toNumber();
        }

        return NativePrice.fromNativePriceElement(resultPrice);
    }

    private static getMultiplier(multiplier: NativePrice | number, oddsFormat: OddsDisplayFormat): Decimal {
        if (typeof multiplier === 'number') {
            return new Decimal(multiplier);
        }

        switch (oddsFormat) {
            case OddsDisplayFormat.UK:
                return multiplier.fraction;
            case OddsDisplayFormat.US:
                return NativePrice.americanOddsToDecimalOdds(multiplier.americanOdds);
            default:
                return multiplier.odds;
        }
    }

    private static getAmericanOddsCalculation(multiplier: NativePrice | number): number | undefined {
        return typeof multiplier === 'number' ? undefined : multiplier.americanOddsCalculation;
    }
}
