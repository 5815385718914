<ng-container *ngIf="promotion.details">
    <div *ngIf="promotion.details.backgroundImageUrl" class="image">
        <img [ngSrc]="promotion.details.backgroundImageUrl" fill />
        <img *ngIf="promotion.details.heroImageUrl" [ngSrc]="promotion.details.heroImageUrl" fill />
    </div>
    <div class="description" [innerHTML]="promotion.details.description"></div>

    <ms-crm-promotion-status-button
        class="status-button"
        [promotion]="promotion"
        (buttonClick)="onButtonClick($event)"></ms-crm-promotion-status-button>

    <div class="bcms-toggle-tnc">
        <div class="bcms-toggle-tnc-caption dh" [ngClass]="{ 'tnc-visible': tncVisible }" (click)="toggleTnc()">
            <span [innerHTML]="translation.TermsAndConditions"></span>
            <span class="toggle-tnc-icon theme-down" ng-reflect-klass="theme-down toggle-tnc-icon"></span>
        </div>
        <div class="bcms-toggle-tnc-details pc-txt txt-xs" *ngIf="tncVisible" [innerHTML]="promotion.details.termsAndConditions"></div>
    </div>
</ng-container>
