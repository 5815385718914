import { BetslipV2OptionMarketPick } from './betslip-v2-option-market-pick';
import { IBetslipV2OptionMarketPickStorage, PickSubType } from './pick-models';

export class BetslipV2StandardPick extends BetslipV2OptionMarketPick {
    static fromJSON(value: IBetslipV2OptionMarketPickStorage): BetslipV2OptionMarketPick {
        const pick = new BetslipV2StandardPick();
        pick.initPropertiesFromJSON(value);

        return pick;
    }

    override toJSON(): IBetslipV2OptionMarketPickStorage {
        const base = super.toJSON();

        return {
            ...base,
            pickSubType: PickSubType.StandardV2Pick,
        };
    }

    copy(): BetslipV2OptionMarketPick {
        const storage = this.toJSON();

        return BetslipV2StandardPick.fromJSON(storage);
    }
}
