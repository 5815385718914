import { Injectable, Type } from '@angular/core';

import { StylesService } from '@frontend/vanilla/core';

import { LoggerFactory } from '../logging/logger-factory.service';
import { TeamPagesViewModel } from '../teampages-core/team-pages.model';
import { TeamPagesModule } from '../teampages/team-pages.module';
import { ComponentLoaderService, ComponentProxy } from './component-loader.service';
import ModuleLoaderService from './module-loader.service';

export interface TeamPagesCompetitionViewBinding {
    teampagesViewModel: TeamPagesViewModel;
    hasCompetitions: boolean;
}

@Injectable({ providedIn: 'root' })
export class TeamPagesComponentLoaderService extends ComponentLoaderService<TeamPagesModule> {
    moduleName = 'TeamPagesModule';

    constructor(moduleLoader: ModuleLoaderService, loggerFactory: LoggerFactory, stylesService: StylesService) {
        super(moduleLoader, loggerFactory, stylesService);
    }

    getTeamPagesComponent(): ComponentProxy<TeamPagesCompetitionViewBinding> {
        return this.getComponentProxy((module) => module.competitionTeamPagesComponent, 'team-pages');
    }

    getModule(): Promise<Type<TeamPagesModule>> {
        return import(/* webpackChunkName: "ms-teampages" */ '../teampages/team-pages.module').then((m) => m.TeamPagesModule);
    }
}
