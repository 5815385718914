import { Inject, Injectable } from '@angular/core';

import { AccaBoostTokenProviderService } from 'packages/sports/web/app/src/acca-boost-base/acca-boost-token-provider.service';
import { IRewardTokenData } from 'packages/sports/web/app/src/tokens-base/token-base.models';
import { ReplaySubject, combineLatest, map } from 'rxjs';

import { ICrmOfferLoaderService } from '../../../integration/services/crm-offer-loader-service';
import { CRM_OFFER_LOADER_SERVICE_INJECTION_TOKEN } from '../../../integration/sports-injection-services';
import { IRewardToken } from '../reward-tokens.model';
import { RewardTokensFactory } from './reward-tokens.factory';

@Injectable({ providedIn: 'root' })
export class RewardTokensDataService {
    private readonly tokens$$ = new ReplaySubject<IRewardToken[]>(1);
    readonly tokens$ = this.tokens$$.asObservable();

    constructor(
        @Inject(CRM_OFFER_LOADER_SERVICE_INJECTION_TOKEN) private crmOfferLoaderService: ICrmOfferLoaderService,
        private rewardTokensFactory: RewardTokensFactory,
        private accaBoostTokenProvider: AccaBoostTokenProviderService,
    ) {}

    init(): void {
        const crmTokens$ = this.crmOfferLoaderService.tokens$.pipe(map(this.mapCrmTokens));
        const accaBoostTokens$ = this.accaBoostTokenProvider.tokens$;
        combineLatest([crmTokens$, accaBoostTokens$])
            .pipe(
                map(([crmTokens, accaBoostTokens]) => {
                    return [...crmTokens, ...accaBoostTokens];
                }),
            )
            .subscribe((tokens) => this.tokens$$.next(tokens));
    }

    refreshCrmOffer(): void {
        this.crmOfferLoaderService.refresh();
    }

    refreshAccaOffer(): void {
        this.accaBoostTokenProvider.refresh();
    }

    private mapCrmTokens = (crmTokensData: IRewardTokenData[]): IRewardToken[] => {
        return this.rewardTokensFactory.toModelList(crmTokensData);
    };
}
