import { CurrencyPipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';

import { Nullable } from '@frontend/sports/common/base-utils';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { UiManagerActions } from '../ui-manager/ui-manager.actions';
import { IUiRootState } from '../ui-manager/ui-manager.state';
import { UiStates } from '../ui-manager/ui-states.enum';
import { IncrementalStakesService } from './incremental-stakes.service';
import {
    ISelectionProvider,
    KeypadOptions,
    NumpadAction,
    NumpadHostOptions,
    NumpadOperation,
    NumpadState,
    StakeChangedEvent,
    StakeModel,
    defaultNumpadState,
} from './model';
import { NumpadDisplayService } from './numpad-display.service';
import { NumpadTrackingService } from './numpad-tracking.service';
import { NumpadUtilsService } from './numpad-utils.service';
import { StakeUpdateService } from './stake-update.service';

@Injectable()
export class NumpadService implements OnDestroy {
    private serviceDestroyed$ = new Subject<void>();
    private state = new BehaviorSubject<NumpadState>(cloneDeep(defaultNumpadState));
    private stake = new Subject<StakeChangedEvent>();
    private selectionProvider: ISelectionProvider;

    keypadOptions: KeypadOptions;

    constructor(
        private numpadDisplayService: NumpadDisplayService,
        private updateService: StakeUpdateService,
        private utils: NumpadUtilsService,
        private numpadTrackingService: NumpadTrackingService,
        //todo please remove this pipe, it is out of place.
        private currencypipe: CurrencyPipe,
        private incrementalStakesService: IncrementalStakesService,
        private store: Store<IUiRootState>,
    ) {}

    ngOnDestroy(): void {
        this.serviceDestroyed$.next();
        this.serviceDestroyed$.complete();
    }

    get state$(): Observable<NumpadState> {
        return this.state.asObservable();
    }

    get stake$(): Observable<StakeChangedEvent> {
        return this.stake.asObservable();
    }

    get currentState(): NumpadState {
        return this.state.value;
    }

    registerSelectionProvider(selectionProvider: ISelectionProvider): void {
        this.selectionProvider = selectionProvider;
    }

    confirm(): void {
        if (this.isDynamicNumpad) {
            this.currentState.confirmed = true;

            this.numpadDisplayService.close();
        }
    }

    open(): void {
        if (this.currentState.isOpened) {
            return;
        }

        this.openNumpad();
    }

    private shouldSkipUpdate(newValue: Nullable<string>): boolean {
        return Number(this.currentState.stake.value) === Number(newValue || '');
    }

    updateStake(value: Nullable<string>, skipNumberCheck?: boolean): void {
        if (!skipNumberCheck && this.shouldSkipUpdate(value)) {
            return;
        }

        const state: Partial<NumpadState> = {
            stake: this.toStakeModel(value!),
        };
        this.updateState(state);
    }

    initialize(value: Nullable<string>, options: KeypadOptions): void {
        this.keypadOptions = options;

        const state: Partial<NumpadState> = {
            stake: this.toStakeModel(value!),
            isOpened: this.keypadOptions.focusOnLoad,
        };
        this.updateState(state);

        if (this.isDynamicNumpad) {
            this.subscribe();
        }
    }

    type(input: string): void {
        const value = this.utils.normalizeInput(input);

        if (!this.shouldUpdateStake(value)) {
            return;
        }

        const state: Partial<NumpadState> = {
            stake: this.toStakeModel(value, NumpadOperation.Type),
            operation: NumpadOperation.Type,
        };
        this.updateState(state, true);
    }

    appendCharacter(character: string): void {
        if (!this.selectionProvider) {
            return;
        }

        const currentValue = this.currentState.stake.formatted;
        const { start, end } = this.selectionProvider.getSelection(currentValue);
        const { value, position } = this.updateService.append(currentValue, character, start, end);

        const state: Partial<NumpadState> = {
            stake: this.toStakeModel(value, NumpadOperation.Append),
            selectionStart: position,
            operation: NumpadOperation.Append,
        };

        const emitNewStake = value !== this.currentState.stake.formatted;
        this.updateState(state, emitNewStake);
    }

    increment(input: number): void {
        const value = this.updateService.increment(this.currentState.stake.value, input);

        if (!this.shouldUpdateStake(value)) {
            return;
        }

        const state: Partial<NumpadState> = {
            stake: this.toStakeModel(value, NumpadOperation.Increment, input),
            operation: NumpadOperation.Increment,
        };
        this.updateState(state, true);
    }

    removeLastCharacter(): void {
        this.store.dispatch(UiManagerActions.removeState({ state: UiStates.MarketSubTypeTooltipShown }));

        if (!this.selectionProvider) {
            return;
        }

        const currentValue = this.currentState.stake.formatted;
        const { start, end } = this.selectionProvider.getSelection(currentValue);
        const { value, position } = this.updateService.remove(currentValue, start, end);

        if (!this.shouldUpdateStake(value)) {
            return;
        }

        const state: Partial<NumpadState> = {
            stake: this.toStakeModel(value, NumpadOperation.Remove),
            selectionStart: position,
            operation: NumpadOperation.Remove,
        };
        this.updateState(state, true);
    }

    private get isDynamicNumpad(): boolean {
        return !!this.keypadOptions.id;
    }

    private shouldUpdateStake(value: string): boolean {
        return this.utils.isValidInput(value) && value !== this.currentState.stake.formatted;
    }

    private subscribe(): void {
        const onOtherNumpadOpen = this.numpadDisplayService.open$.pipe(filter((options) => this.otherNumpadWasOpened(options)));

        merge(this.numpadDisplayService.close$, onOtherNumpadOpen)
            .pipe(
                filter(() => this.currentState.isOpened),
                takeUntil(this.serviceDestroyed$),
            )
            .subscribe(() => this.closeNumpad());
    }

    private otherNumpadWasOpened(options: NumpadHostOptions): boolean {
        return this.keypadOptions.id !== options.id || this !== options.numpadService;
    }

    private openNumpad(): void {
        this.numpadDisplayService.open({
            id: this.keypadOptions.id,
            numpadService: this,
        });

        const state: Partial<NumpadState> = {
            stake: this.toStakeModel(this.currentState.stake.value, NumpadOperation.Open),
            isOpened: true,
            confirmed: false,
            operation: NumpadOperation.Open,
        };
        this.updateState(state);
    }

    private closeNumpad(): void {
        const state: Partial<NumpadState> = {
            stake: this.toStakeModel(this.currentState.stake.value),
            isOpened: false,
            operation: NumpadOperation.Close,
        };

        if (state.stake && state.stake.value + '0' === state.stake.formatted) {
            state.stake.value = state.stake.formatted;

            this.updateState(state, true);
        }
        this.updateState(state);
    }

    private toStakeModel(input: string, operation?: NumpadOperation, numpadInput?: number): StakeModel {
        //TODO formatted vs currencyFormatted ? no clear separation of usage. please justify or at least add some comments.
        // Ideally currencyFormatted should be removed, espiceally if the end goal is using currency pipe, let your view handle it. Don't delegate this to the service.
        // Also currency pipe is encapsulating this part of the logic, and it should remain this way.
        let formatted = '';
        let currencyFormatted = '';
        let isEmpty: boolean;
        let position: number | undefined;

        const value = (input || '').replace(',', '.');
        switch (operation) {
            case NumpadOperation.Append:
            case NumpadOperation.Remove:
            case NumpadOperation.Type:
            case NumpadOperation.Increment:
                formatted = currencyFormatted = this.utils.addLocaleSeparator(value);
                isEmpty = this.isZero(value) && value.length === 0;
                position = this.incrementalStakesService.getValues()?.indexOf(numpadInput!) + 1;
                break;
            case NumpadOperation.Open:
                formatted = currencyFormatted = this.utils.toFormattedValue(value);
                isEmpty = this.isZero(value) && value.length === 0;
                break;
            default:
                const preFormatted = this.utils.toFormattedValue(value, true);
                formatted = this.utils.addThousandsSeparators(preFormatted);
                //TODO I doubt that the operation have an influence on the transformation on display.
                currencyFormatted = this.currencypipe.transform(value) || '';
                isEmpty = this.isZero(value);
        }

        return {
            value,
            formatted,
            currencyFormatted,
            isEmpty,
            numpadInput,
            position,
        };
    }

    private updateState(newState: Partial<NumpadState>, emitNewStake: boolean = false): void {
        delete this.currentState.selectionStart;
        delete this.currentState.operation;

        this.store.dispatch(UiManagerActions.removeState({ state: UiStates.MarketSubTypeTooltipShown }));

        this.state.next({
            ...this.currentState,
            ...newState,
        });

        if (emitNewStake) {
            this.stake.next({
                stake: this.currentState.stake.value,
                action: this.mapToAction(this.currentState.operation),
            });
        }

        this.track(this.currentState);
    }

    private mapToAction(operation: NumpadOperation | undefined): NumpadAction {
        if (operation === NumpadOperation.Type) {
            return NumpadAction.KeypadTyped;
        }

        if (operation === NumpadOperation.Increment) {
            return NumpadAction.IncrementalStakeUsed;
        }

        if (operation === NumpadOperation.Append || operation === NumpadOperation.Remove) {
            return NumpadAction.KeypadUsed;
        }

        return NumpadAction.None;
    }

    private track(state: NumpadState): void {
        const trackingSource = this.keypadOptions?.numpadSource;

        if (!trackingSource) {
            return;
        }

        if (state.operation === NumpadOperation.Close && state.confirmed) {
            this.numpadTrackingService.trackConfirmation(trackingSource);
        }

        if (state.operation === NumpadOperation.Remove) {
            this.numpadTrackingService.trackDeleteButton(trackingSource);
        }

        if (state.operation === NumpadOperation.Open) {
            this.numpadTrackingService.trackOpening(trackingSource);
        }

        if (state.operation === NumpadOperation.Increment) {
            this.numpadTrackingService.trackIncrement(state.stake, trackingSource);
        }
    }

    private isZero(value: Nullable<string>): boolean {
        return Number(value) === 0;
    }
}
