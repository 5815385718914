import { Decimal } from 'decimal.js';

import { Fraction } from './fraction';

export interface Odds {
    decimals: number;
    fractional: { numerator: number; denominator: number };
    moneyline: number;
}

export interface CalculatedOdds {
    decimals: Decimal;
    fractional: Fraction;
    moneyline: Decimal;
}

export const emptyOdds: Odds = Object.freeze({
    decimals: 0,
    fractional: { numerator: 0, denominator: 0 },
    moneyline: 0,
});
export const emptyCalculatedOdds: CalculatedOdds = {
    decimals: new Decimal(0),
    fractional: Fraction.empty(),
    moneyline: new Decimal(0),
};
