import { Injectable, Optional } from '@angular/core';

import { WidgetPage } from '@frontend/sports/types/components/widget';

import { OfferStatus, Promotion } from '../crm-offer-data/crm-offer.model';
import { NOT_APPLICABLE, TrackData, trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { ModularConfigAccessorService } from '../widget/core/modular-config-accessor.service';

export enum CTALocation {
    OptIn = 'OptIn',
    MoreDetails = 'MoreDetails',
    Banner = 'Banner',
}

export interface CtaTrackingData {
    promotion: Promotion;
    trackingSource?: string;
    ctaLocation: CTALocation;
    position?: number;
}

@Injectable({ providedIn: 'root' })
export class CrmPromotionTracking {
    private ctaTrackingLocations = {
        [CTALocation.OptIn]: 'OptIn',
        [CTALocation.MoreDetails]: 'More Details',
        [CTALocation.Banner]: 'Banner',
    };

    private failedOptinText = {
        [OfferStatus.NoOptIn]: ':???:',
        [OfferStatus.Expired]: 'Promotion Expired',
        [OfferStatus.Error]: 'OptIn Call Failed',
        [OfferStatus.OptedIn]: 'Opted In',
    };

    constructor(
        private trackingService: TrackingService,
        @Optional() private configAccessor: ModularConfigAccessorService,
    ) {}

    trackViewed(promotion: Promotion, trackingSource?: string): void {
        this.trackingService.track(trackingConstants.EVENT_CONTENT_VIEW, {
            ...this.baseTracking(promotion, trackingSource),
            [trackingConstants.COMPONENT_ACTION_EVENT]: 'Load',
        });
    }

    trackCTA(data: CtaTrackingData): void {
        let url = NOT_APPLICABLE;

        if (data.ctaLocation === CTALocation.MoreDetails) {
            url = (data.promotion.banner && data.promotion.banner.moreInfo) || NOT_APPLICABLE;
        }

        this.trackingService.track(trackingConstants.EVENT_CLICKS, {
            ...this.baseTracking(data.promotion, data.trackingSource),
            [trackingConstants.COMPONENT_ACTION_EVENT]: 'Click',
            [trackingConstants.COMPONENT_EVENT_DETAILS]: this.getEventDetails(data.promotion),
            [trackingConstants.COMPONENT_POSITION_EVENT]: this.ctaTrackingLocations[data.ctaLocation] ?? NOT_APPLICABLE,
            [trackingConstants.COMPONENT_URL_CLICKED]: url,
            [trackingConstants.COMPONENT_CONTENT_POSITION]: data.position?.toString() ?? NOT_APPLICABLE,
        });
    }

    trackOptinResult(data: { promotion: Promotion; trackingSource?: string; status: OfferStatus }): void {
        if (data.status === OfferStatus.OptedIn) {
            return;
        }

        this.trackingService.track(trackingConstants.EVENT_CLICKS, {
            ...this.baseTracking(data.promotion, data.trackingSource),
            [trackingConstants.COMPONENT_ACTION_EVENT]: 'Error - Load',
            [trackingConstants.COMPONENT_EVENT_DETAILS]: this.failedOptinText[data.status] ?? 'Unknown Error',
        });
    }

    private baseTracking(promotion: Promotion, tracingSource?: string): Partial<TrackData> {
        return {
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: 'Sports Teaser Banners',
            [trackingConstants.COMPONENT_LABEL_EVENT]: this.getLabelEvent(promotion),
            [trackingConstants.COMPONENT_POSITION_EVENT]: tracingSource ?? NOT_APPLICABLE,
            [trackingConstants.COMPONENT_LOCATION_EVENT]: this.getLocationEvent(),
            [trackingConstants.COMPONENT_URL_CLICKED]: NOT_APPLICABLE,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: NOT_APPLICABLE,
        };
    }

    private getLabelEvent(promotion: Promotion): string {
        return ['SPORTS', promotion.id, promotion.banner && promotion.banner.title].filter((x) => x).join('|');
    }

    private getEventDetails(promotion: Promotion): string {
        return promotion.banner?.title ?? 'CTA';
    }

    private getLocationEvent(): string {
        const page = this.configAccessor?.getPage();
        switch (page) {
            case WidgetPage.EventDetailsPage:
                return 'event details page';
            default:
                return NOT_APPLICABLE;
        }
    }
}
