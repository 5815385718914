import { Injectable, Injector, Type } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd } from '@angular/router';

import { LayoutNavigationConfig, SitemapSitecore } from '@frontend/sports/common/client-config-data-access';
import { OnAppInit } from '@frontend/vanilla/core';
import { Subject, forkJoin } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { TopNavigationVersion } from '../navigation-core/navigation-core.models';
import { RouterEventsService } from '../navigation-core/router-events.service';
import { getToken } from '../router/router.exports';
import { SitemapStateMapper } from './sitemap-state-mapper';
import { SitemapStateService } from './sitemap-state.service';
import { SitemapItemBaseModel } from './sitemap.models';
import { SitemapResolve } from './sitemap.resolver';
import { SitemapService } from './sitemap.service';

@Injectable({
    providedIn: 'root',
})
export class SitemapBootstrapService implements OnAppInit {
    resolversMap: { [key: string]: Type<SitemapResolve<any>> };
    private validSubscription$ = new Subject<void>();
    private resolver?: SitemapResolve<any>;
    private lastResolverType?: any;
    private readonly logger: SportsRemoteLogger;
    private isV3Navigation: boolean;
    private sitemapConfiguration: SitemapItemBaseModel[] = [];
    private competitionFallback: SitemapItemBaseModel[] = [];
    private emptySitemap = { items: [] };
    private lastActivationEnd?: ActivationEnd;
    private configIsLoaded?: boolean;
    private lastActivatedRouteSnapshot?: ActivatedRouteSnapshot;

    constructor(
        private injector: Injector,
        private routerEvents: RouterEventsService,
        loggerFactory: LoggerFactory,
        private sitemapService: SitemapService,
        private layoutNavConfig: LayoutNavigationConfig,
        private stateMapper: SitemapStateMapper,
        private stateService: SitemapStateService,
        private sitecore: SitemapSitecore,
    ) {
        this.isV3Navigation = this.layoutNavConfig.topNavigationVersion === TopNavigationVersion.V3;

        if (this.isV3Navigation) {
            this.logger = loggerFactory.getLogger('SitemapBootstrapService');
            const messages = this.sitecore.whenReady.pipe(first());
            const service = this.sitemapService.getSiteMapConfiguration().pipe(first());
            forkJoin([service, messages]).subscribe(([config, _]) => {
                this.sitemapConfiguration = config.data;
                this.competitionFallback = config.competitionFallback;
                this.configIsLoaded = true;
                if (this.lastActivationEnd) {
                    this.activationEnded(this.lastActivationEnd);
                }
            });
        }
    }

    onAppInit(): void {
        if (this.isV3Navigation) {
            this.routerEvents.currentActivationEnd.subscribe((event) => this.activationEnded(event));
        }
    }

    private activationEnded(event: ActivationEnd | undefined): void {
        if (!event) {
            return;
        }

        this.lastActivationEnd = event;

        if (!this.configIsLoaded) {
            return;
        }

        const resolverType = this.findFirstSitemapResolver(event.snapshot);
        if (!resolverType) {
            this.renewSubscription();
            this.lastResolverType = null;
            this.sitemapService.setSitemap(this.emptySitemap);

            return;
        }

        if (resolverType !== this.lastResolverType) {
            this.renewSubscription();

            this.resolver = getToken<SitemapResolve<any>>(resolverType, event.snapshot, this.injector);

            if (!this.resolver) {
                this.sitemapService.setSitemap(this.emptySitemap);
                this.logger.error(`Could not resolve Sitemap resolver: ${resolverType} - route: ${event}`);

                return;
            }

            this.lastResolverType = resolverType;
        }

        if (this.lastActivatedRouteSnapshot !== event.snapshot) {
            this.lastActivatedRouteSnapshot = event.snapshot;
            const model = event.snapshot.data.model;
            const resolveModel = {
                routeSnapshot: event.snapshot,
                model,
                state: this.stateService.navigation,
                config: this.sitemapConfiguration,
                competitionFallback: this.competitionFallback,
            };
            this.resolver
                ?.resolve(resolveModel, this.stateMapper)
                .pipe(takeUntil(this.validSubscription$))
                .subscribe((sitemap) => this.sitemapService.setSitemap(sitemap));
        }
    }

    private renewSubscription(): void {
        this.validSubscription$.next(undefined);
        this.validSubscription$.complete();
        this.validSubscription$ = new Subject<void>();
    }

    private findFirstSitemapResolver(snapshot: ActivatedRouteSnapshot): Type<SitemapResolve<any>> | undefined {
        let crtSnapshot: ActivatedRouteSnapshot | null = snapshot;

        while (crtSnapshot && !crtSnapshot.data?.sitemap) {
            crtSnapshot = crtSnapshot.parent;
        }

        const resolverTypeName = crtSnapshot?.data?.sitemap;
        if (!resolverTypeName || !this.resolversMap) {
            return undefined;
        }

        return this.resolversMap[resolverTypeName];
    }
}
