import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationHelper {
    private firstLoadFlag = 2;

    constructor(private router: Router) {
        this.router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.navigationEnded(event);
            }
        });
    }

    private navigationEnded(event: NavigationEnd): void {
        if (this.firstLoadFlag) {
            this.firstLoadFlag--;
        }

        const isAddBetsRoute = event && event.url.toLowerCase().indexOf('addbets') > -1;

        if (isAddBetsRoute && this.firstLoadFlag) {
            // we're initializing the app on the addBets route and might open the betslip popup
            // don't allow back;
            this.firstLoadFlag = 2;
        }

        if (event && event.url.indexOf('labelhost') > -1) {
            this.firstLoadFlag = 2;
        }
    }

    forceHomePage(): boolean {
        return this.firstLoadFlag === 1;
    }

    allowBack(): boolean {
        return !this.firstLoadFlag;
    }
}
