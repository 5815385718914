import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';

import { NumpadService } from './numpad.service';

@Component({
    selector: 'ms-numpad',
    templateUrl: './numpad.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumpadComponent {
    constructor(public numpadService: NumpadService) {}

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();
    }
}
