import { Odds as BposOdds, OddsFormat } from '@bpos';
import { Odds as BposCommonOdds } from '@bpos/common/bet-placement';
import { Decimal } from 'decimal.js';
import { isArray } from 'lodash-es';
import { OddsFormatCalculatorService } from 'packages/sports/web/app/src/native-odds/odds-format-calculator.service';

import { Fraction } from './fraction';
import { CalculatedOdds, Odds, emptyCalculatedOdds, emptyOdds } from './odds';
import { OddsConverter } from './odds-converter';
import { OddsDisplayFormat } from './odds-display-format';

export class OddsOperations {
    static readonly OddsOne = OddsOperations.fromDecimalValue(new Decimal(1));

    static createOdds(price: { odds?: number; numerator?: number; denominator?: number; americanOdds?: number }): Odds {
        if (!price.odds && !price.numerator && !price.denominator && !price.americanOdds) {
            return emptyOdds;
        }

        return {
            decimals: price.odds || 0,
            fractional:
                price.numerator != null && price.denominator != null
                    ? { numerator: price.numerator, denominator: price.denominator }
                    : { numerator: 0, denominator: 0 },
            moneyline: price.americanOdds || 0,
        };
    }

    static createOdds2(odds: BposCommonOdds, oddsFormat: OddsFormat): Odds {
        switch (oddsFormat) {
            case OddsFormat.European: {
                return OddsOperations.createOdds({ odds: odds.european });
            }
            case OddsFormat.British: {
                return OddsOperations.createOdds({ numerator: odds.british.numerator, denominator: odds.british.denominator });
            }
            case OddsFormat.American: {
                return OddsOperations.createOdds({ americanOdds: odds.american });
            }
            default:
                return emptyOdds;
        }
    }

    static createOdds3(odds: BposOdds | undefined): Odds {
        switch (odds?.oddsFormat) {
            case OddsFormat.European: {
                return OddsOperations.createOdds({ odds: odds.european });
            }
            case OddsFormat.British: {
                return OddsOperations.createOdds({ numerator: odds.british.numerator, denominator: odds.british.denominator });
            }
            case OddsFormat.American: {
                return OddsOperations.createOdds({ americanOdds: odds.american });
            }
            default:
                return emptyOdds;
        }
    }

    static isOddsEmpty(odds: Odds | CalculatedOdds): boolean {
        return odds === emptyOdds || odds === emptyCalculatedOdds;
    }

    static isOddsValid(odds: Odds | CalculatedOdds): boolean {
        if (OddsOperations.isOddsEmpty(odds)) {
            return false;
        }

        return (
            OddsOperations.isDecimalOddsValid(odds.decimals) ||
            OddsOperations.isFractionalOddsValid(odds.fractional) ||
            OddsOperations.isMoneyLineOddsValid(odds.moneyline)
        );
    }

    static isDecimalOddsValid(decimals: number | Decimal): boolean {
        return typeof decimals === 'number' ? decimals > 1 : decimals.greaterThan(1);
    }

    static isFractionalOddsValid(fractional: { numerator: number; denominator: number } | Fraction): boolean {
        return OddsConverter.fractionToDecimal(Fraction.create(fractional)).greaterThan(1);
    }

    static isMoneyLineOddsValid(moneyline: number | Decimal): boolean {
        if (typeof moneyline === 'number') {
            return moneyline >= 100 || (moneyline < -100 && moneyline >= -100000); // I think -100000 is quite smaller number we don't have so small odds
        }

        return moneyline.greaterThanOrEqualTo(100) || (moneyline.lessThan(-100) && moneyline.greaterThan(-100000));
    }

    static sum(odds: CalculatedOdds[]): CalculatedOdds {
        let decimals = new Decimal(0);
        const initialValue = new Fraction(0, 1);
        let fractional = initialValue;
        let moneyline = new Decimal(0);
        for (const odd of odds) {
            if (OddsOperations.isOddsEmpty(odd)) {
                return emptyCalculatedOdds;
            }

            decimals = decimals.add(odd.decimals);
            fractional = fractional === initialValue ? odd.fractional : fractional.add(1).add(odd.fractional.add(1)).subtract(1);
            moneyline = moneyline.add(OddsConverter.usToDecimal(odd.moneyline, 10));
        }

        return {
            decimals,
            fractional,
            moneyline: OddsConverter.decimalToUS(moneyline),
        };
    }

    static getTotalOdds(odds: CalculatedOdds[] | CalculatedOdds, round: boolean): CalculatedOdds {
        return OddsOperations.multiply(isArray(odds) ? odds : [odds, this.OddsOne], round);
    }

    static multiply(odds: CalculatedOdds[], round: boolean): CalculatedOdds {
        let decimals = new Decimal(1);
        let fractional = new Fraction(0, 1);
        let moneyline = new Decimal(1);
        for (const odd of odds) {
            if (OddsOperations.isOddsEmpty(odd)) {
                return emptyCalculatedOdds;
            }

            decimals = decimals.times(odd.decimals);
            fractional = fractional.add(1).multiply(odd.fractional.add(1)).subtract(1);
            moneyline = moneyline.times(OddsConverter.usToDecimal(odd.moneyline, 10));
        }

        return {
            decimals: OddsFormatCalculatorService.multiplyDecimal(decimals, new Decimal(1), round),
            fractional,
            moneyline: OddsConverter.decimalToUS(moneyline),
        };
    }

    static equal(first: Odds, second: Odds, oddsFormat: OddsFormat = OddsFormat.None): boolean {
        switch (oddsFormat) {
            case OddsFormat.British:
                return Fraction.fromJSON(first.fractional).equal(Fraction.fromJSON(second.fractional));
            case OddsFormat.European:
                return first.decimals === second.decimals;
            case OddsFormat.American:
                return first.moneyline === second.moneyline;
            default:
                return (
                    first.decimals === second.decimals &&
                    Fraction.fromJSON(first.fractional).equal(Fraction.fromJSON(second.fractional)) &&
                    first.moneyline === second.moneyline
                );
        }
    }

    static equal2(first: CalculatedOdds, second: CalculatedOdds): boolean {
        return first.decimals.equals(second.decimals) && first.fractional.equal(second.fractional) && first.moneyline.equals(second.moneyline);
    }

    static toCalculatedOdds(nativeOdds: Odds | undefined): CalculatedOdds {
        if (!nativeOdds) {
            return emptyCalculatedOdds;
        }

        const calculatedOdds = {
            decimals: nativeOdds.decimals ? new Decimal(nativeOdds.decimals) : new Decimal(0),
            fractional: nativeOdds.fractional ? Fraction.fromJSON(nativeOdds.fractional) : Fraction.empty(),
            moneyline: nativeOdds.moneyline ? new Decimal(nativeOdds.moneyline) : new Decimal(0),
        };

        //Return emptyCalculatedOdds if the calculated odds is equal to it to make future comparison accurate
        //e.g. isOddsEmpty should return true in this case
        if (this.equal2(calculatedOdds, emptyCalculatedOdds)) {
            return emptyCalculatedOdds;
        }

        return calculatedOdds;
    }

    static toDecimalValue(odds: CalculatedOdds, format?: OddsDisplayFormat): Decimal {
        switch (format) {
            case OddsDisplayFormat.UK:
                return OddsConverter.fractionToDecimal(odds.fractional);
            case OddsDisplayFormat.US:
                return OddsConverter.usToDecimal(odds.moneyline, 10);
            default:
                return odds.decimals;
        }
    }

    static fromDecimalValue(decimals: Decimal): CalculatedOdds {
        return {
            decimals,
            fractional: OddsConverter.decimalToFraction(decimals),
            moneyline: OddsConverter.decimalToUS(decimals),
        };
    }

    static fromDecimalsToOdds(value: number): Odds {
        return OddsOperations.createOdds({
            odds: value,
            numerator: new Decimal(value).minus(1).toNumber(),
            denominator: 1,
        });
    }

    static convertOddsFormat(oddsFormat: OddsFormat): OddsDisplayFormat {
        switch (oddsFormat) {
            case OddsFormat.American:
                return OddsDisplayFormat.US;
            case OddsFormat.British:
                return OddsDisplayFormat.UK;
            case OddsFormat.European:
                return OddsDisplayFormat.EU;
            case OddsFormat.None:
                return OddsDisplayFormat.EU;
        }
    }

    static convertToBposOddsFormat(oddsFormat: OddsDisplayFormat = OddsDisplayFormat.EU): OddsFormat {
        switch (oddsFormat) {
            case OddsDisplayFormat.EU:
                return OddsFormat.European;
            case OddsDisplayFormat.UK:
                return OddsFormat.British;
            case OddsDisplayFormat.US:
                return OddsFormat.American;
        }
    }

    static toOdds(nativeOdds: CalculatedOdds): Odds {
        return {
            decimals: nativeOdds.decimals ? nativeOdds.decimals.toNumber() : 0,

            fractional: nativeOdds.fractional
                ? { numerator: nativeOdds.fractional.numerator.toNumber(), denominator: nativeOdds.fractional.denominator.toNumber() }
                : { numerator: 0, denominator: 0 },

            moneyline: nativeOdds.moneyline ? nativeOdds.moneyline.toNumber() : 0,
        };
    }

    static lessThan(first: CalculatedOdds, second: CalculatedOdds, oddsFormat: OddsFormat = OddsFormat.None): boolean {
        switch (oddsFormat) {
            case OddsFormat.British:
                return first.fractional.lessThan(second.fractional);
            case OddsFormat.American:
                return first.moneyline.lessThan(second.moneyline);
            default:
                return first.decimals.lessThan(second.decimals);
        }
    }

    static greaterThan(first: CalculatedOdds, second: CalculatedOdds, oddsFormat: OddsFormat = OddsFormat.None): boolean {
        switch (oddsFormat) {
            case OddsFormat.British:
                return first.fractional.greaterThan(second.fractional);
            case OddsFormat.American:
                return first.moneyline.greaterThan(second.moneyline);
            default:
                return first.decimals.greaterThan(second.decimals);
        }
    }
}
