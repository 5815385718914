import { createAction } from '@ngrx/store';

import BetslipActions from '../../base/store/actions';

export default class DigitalSettingsActions {
    static readonly ACTION_SCHEMA = `${BetslipActions.ACTION_SCHEMA}/digital/settings`;

    private static readonly OPEN_SETTINGS = `${DigitalSettingsActions.ACTION_SCHEMA}/OPEN_SETTINGS`;
    private static readonly CLOSE_SETTINGS = `${DigitalSettingsActions.ACTION_SCHEMA}/CLOSE_SETTINGS`;

    static openSettings = createAction(DigitalSettingsActions.OPEN_SETTINGS);
    static closeSettings = createAction(DigitalSettingsActions.CLOSE_SETTINGS);
}
