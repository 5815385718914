import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[msColumnWidth]',
})
export class ColumnWidthDirective implements OnInit {
    constructor(private element: ElementRef) {}

    @Input('msColumnWidth') columnSelector = '';

    ngOnInit(): void {
        this.setElementWidth();
    }

    setElementWidth(): void {
        const column = this.getColumnElement();
        if (!column) {
            throw new Error('could not find .column parent element');
        }

        this.element.nativeElement.style.width = column.getBoundingClientRect().width + 'px';
    }

    private getColumnElement(): HTMLElement | null {
        return document.querySelector(`.${this.columnSelector}`);
    }
}
