import { Injectable } from '@angular/core';

import { MessageEnvelope, SubscriptionRequest } from '@cds/push';
import { BettingOfferConfig, ConnectionConfig } from '@frontend/sports/common/client-config-data-access';
import { ClientConnector, ConnectionStatus, ConnectorSettings } from 'cds-client';
import { Observable, Subject } from 'rxjs';

import { ApiBaseSettingsProvider } from './api-settings.service';

@Injectable({ providedIn: 'root' })
export class CdsPushProvider {
    private connection: ClientConnector;
    private _messageReceived$ = new Subject<MessageEnvelope>();
    private providerOptions: ConnectorSettings;

    get messageReceived$(): Observable<MessageEnvelope> {
        return this._messageReceived$.asObservable();
    }

    constructor(
        private connectionConfig: ConnectionConfig,
        apiBaseSettings: ApiBaseSettingsProvider,
        bettingOfferConfig: BettingOfferConfig,
    ) {
        const baseSettings = apiBaseSettings.getSettings();
        this.providerOptions = {
            url: this.connectionConfig.cdsPushUrl,
            accessId: baseSettings.accessId,
            lang: baseSettings.lang,
            country: baseSettings.userCountry,
            maxRetries: this.connectionConfig.cdsMaxRetries,
            skipNegotiation: this.connectionConfig.cdsSkipNegotiation,
            prefferedTransportType: this.connectionConfig.cdsPrefferedTransportType,
            appUpdates: bettingOfferConfig.isMainToLiveTransitionEnabled,
        };
        this.start();
    }

    private async start(): Promise<void> {
        this.connection = new ClientConnector(this.providerOptions, this.onMessageReceived, this.onStatusChanged);
        await this.connection.start();
    }

    async subscribe(subscriptionRequest: SubscriptionRequest): Promise<void> {
        await this.connection.subscribe(subscriptionRequest);
    }

    async cancelSubscription(request: SubscriptionRequest): Promise<void> {
        await this.connection.cancelSubscription(request);
    }

    async restart(): Promise<void> {
        await this.connection.stop();
        await this.start();
    }

    get isConnected(): boolean {
        return this.connection.getStatus() === ConnectionStatus.Connected;
    }

    get isDisconnected(): boolean {
        return this.connection.getStatus() === ConnectionStatus.Disconnected;
    }

    private onMessageReceived = (message: MessageEnvelope) => {
        this._messageReceived$.next(message);
    };

    private onStatusChanged = (status: ConnectionStatus) => {};
}
