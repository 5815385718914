import { Injectable } from '@angular/core';

import { floor, keyBy, map, padStart, round } from 'lodash-es';

type JoinKey = string | number;

@Injectable({
    providedIn: 'root',
})
export class Utils {
    combine<T>(array: T[], func: (value: T, index: number, array: T[]) => any): T[] {
        return array.map(func).reduce((a, b) => a.concat(b));
    }

    max(array: any[], attr?: string): number {
        return Math.max.apply(null, attr ? map(array, attr) : array);
    }

    closest(array: number[], num: number): number {
        return array.reduce((a, b) => (Math.abs(a - num) < Math.abs(b - num) ? a : b));
    }

    round(val: number, decimals?: number): number {
        if (decimals === undefined) {
            decimals = 2;
        }

        return round(val, decimals);
    }

    roundUpward(val: number, decimals?: number): number {
        const pow = Math.pow(10, decimals || 2);
        const roundedNumber = Math.round(val * pow) / pow;

        return val > roundedNumber ? Math.round((roundedNumber + 1 / pow) * pow) / pow : roundedNumber;
    }

    floor(val: number, decimals?: number): number {
        if (decimals === undefined) {
            decimals = 2;
        }

        return floor(val, decimals);
    }

    generateGuid(): string {
        let d = Date.now();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); // use high-precision timer if available
        }

        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
            // eslint-disable-next-line no-bitwise
            const r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);

            // eslint-disable-next-line no-bitwise
            return (char === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
    }

    trim(val: string): string {
        if (!val) {
            return val;
        }

        return val.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    }

    /**
     * P(n, r) = n! / (n - r)!
     * where n - set size; r - objects count
     *
     * https://www.wolframalpha.com/input/?i=p(10,+3)
     */
    permutations(n: number, r: number): number {
        if (n < 0) {
            throw new Error('n-param should be either positive or equal to zero.');
        }

        if (r < 0) {
            throw new Error('r-param should be either positive or equal to zero.');
        }

        if (n < r) {
            return 0;
        }

        const fact = [1]; // reduce on empty array throws

        for (let divident = n; divident > n - r; divident--) {
            fact.push(divident);
        }

        return fact.reduce((curr, next) => curr * next);
    }

    /**
     * Returns property value of an object by doing a case-insensitve key search
     *
     * @template T T
     * @param propName case-insensitive key
     * @param obj
     * @returns
     * @memberof Utils
     */
    getProp<T>(propName?: string, obj?: object): T | undefined {
        if (!propName || !obj) {
            return undefined;
        }

        const key = Object.keys(obj).filter((current) => current.toLowerCase() === propName.toLowerCase())[0];
        if (key) {
            return obj[key];
        }

        return undefined;
    }

    timeSpanToMS(timeSpan: string): number {
        const tt = timeSpan.split(':');

        return (parseInt(tt[0]) * 3600 + parseInt(tt[1]) * 60 + parseInt(tt[2])) * 1000;
    }

    toCent(value: number): number {
        return Math.round(value * 100);
    }

    serializeError(error: Error): string {
        return JSON.stringify(error, ['message', 'arguments', 'type', 'name']);
    }

    getIsoStringWithZone(date: Date): string {
        if (!date) {
            return '';
        }
        let timezoneOffSet = date.getTimezoneOffset();
        const negativeOffset = timezoneOffSet < 0;
        timezoneOffSet = Math.abs(timezoneOffSet);
        const offSetHrs = Math.floor(timezoneOffSet / 60);
        const offSetMin = Math.abs(timezoneOffSet % 60);
        let timezoneStandard = '';

        if (timezoneOffSet === 0) {
            timezoneStandard = 'Z';
        } else if (negativeOffset) {
            timezoneStandard = `+${this.padValue(offSetHrs)}:${this.padValue(offSetMin)}`;
        } else {
            timezoneStandard = `-${this.padValue(offSetHrs)}:${this.padValue(offSetMin)}`;
        }

        return date.toISOString().substring(0, 19) + timezoneStandard;
    }

    toUtcIsoDatetimeString(date: Date): string;
    toUtcIsoDatetimeString(date: Date | undefined): string | undefined;
    toUtcIsoDatetimeString(date: Date | undefined): string | undefined {
        return date?.toISOString();
    }

    getDateForOffset(date: Date, minuteOffset?: number): Date {
        const local = new Date(date);
        minuteOffset = minuteOffset || 0;
        local.setMinutes(local.getMinutes() + minuteOffset);

        return local;
    }

    join<TIn1, TIn2, TOut>(
        leftCollection: TIn1[],
        rightCollection: TIn2[],
        leftKeySelector: (l: TIn1) => JoinKey,
        rightKeySelector: (r: TIn2) => JoinKey,
        mapper: (l: TIn1, r: TIn2) => TOut,
    ): TOut[] {
        const leftHash = keyBy(leftCollection, leftKeySelector);
        const result = [];

        for (const right of rightCollection) {
            const rightKey = rightKeySelector(right);
            if (!rightKey) {
                continue;
            }

            const leftItem = leftHash[rightKey.toString()];
            if (!leftItem) {
                continue;
            }

            result.push(mapper(leftItem, right));
        }

        return result;
    }

    random(upperLimit: number): number {
        return Math.floor(Math.random() * upperLimit);
    }

    private padValue(val: number): string {
        return padStart(val.toString(), 2, '0');
    }
}
