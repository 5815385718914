import { Injectable } from '@angular/core';

import { TagCount, TagType } from '@cds/betting-offer/tags';
import { StringDictionary } from '@frontend/sports/common/base-utils';
import { FavouritesConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { Page } from '@frontend/vanilla/core';
import produce from 'immer';
import { differenceBy, maxBy } from 'lodash-es';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap } from 'rxjs/operators';

import { LeagueTreeItem } from '../competition-list/competition-list.models';
import { EventParticipantImage } from '../event-model/model/event.model';
import { ApiService } from '../http/api.service';
import { ToasterService } from '../modal/toaster/toaster.service';
import { UserService } from '../user/services/user.service';
import { FavouritesFixturesService } from './favourites-fixtures.service';
import { FavouritesModelFactory } from './favourites-model.factory';
import { FavouriteType, FavouritesDto, FavouritesResponse, FavouritesViewModel } from './favourites.model';
import { isFixtureFavourite } from './helpers';

export enum FavouriteChangeType {
    Add = 'Add',
    Remove = 'Remove',
    Get = 'Get',
    Save = 'Save',
}

export interface FavouriteChange {
    favourites: FavouritesViewModel[];
    changeType: FavouriteChangeType;
}

@Injectable({ providedIn: 'root' })
export class FavouritesService {
    private favourites: FavouritesViewModel[] = [];
    private cached: Observable<FavouritesViewModel[]>;

    private updating = false;
    private default: boolean;

    private eventSubject = new BehaviorSubject<FavouriteChange>({ favourites: [], changeType: FavouriteChangeType.Get });

    constructor(
        private apiService: ApiService,
        private userService: UserService,
        private page: Page,
        private favouritesConfig: FavouritesConfig,
        private sitecore: Sitecore,
        private favouritesData: FavouritesFixturesService,
        private favouritesFactory: FavouritesModelFactory,
        private toaster: ToasterService,
    ) {
        const get = () => this.getList(true).subscribe();

        this.userService.onUserLogin$.subscribe(get);

        get();
    }

    get change(): Observable<FavouriteChange> {
        return this.eventSubject;
    }

    get favouritesList(): FavouritesViewModel[] {
        return this.favourites;
    }

    get isDefault(): boolean {
        return this.default;
    }

    get isFull(): boolean {
        return this.favourites.length >= this.favouritesConfig.maxFavourites;
    }

    notify(message: string, iconClass?: string): void {
        this.toaster.open(message, { duration: this.favouritesConfig.toastMessageTimeOut, icon: iconClass });
    }

    add(
        itemId: string,
        sportId: number,
        type: FavouriteType,
        name: string,
        participantImage?: EventParticipantImage,
    ): Observable<FavouritesViewModel | undefined> {
        if (this.updating) {
            return throwError('UPDATING');
        }

        if (!this.userService.isAuthenticated) {
            return throwError(this.sitecore.favouritesMessages.NotAuthenticated);
        }

        if (this.isFull) {
            return throwError(this.sitecore.favouritesMessages.TooManyFavourites.replace('{{maxCount}}', `(${this.favouritesConfig.maxFavourites})`));
        }

        const favourite = this.get(itemId, sportId, type);

        if (favourite) {
            return of(favourite);
        }

        const favForSport = this.favourites.find((f) => f.sport.id === sportId && f.upcomingOrder !== 0);
        const maxUpcomingOrder = maxBy(this.favourites.map((f) => f.upcomingOrder || 0));
        const maxOrder = maxBy(this.favourites.map((f) => f.order || 0));
        const newFavourite = this.favouritesFactory.toModel({
            sport: { id: sportId, name: '' },
            itemId,
            name,
            lang: this.page.culture,
            type,
            order: maxOrder ? maxOrder + 1 : 0,
            upcomingOrder: (favForSport && favForSport.upcomingOrder) || (maxUpcomingOrder ? maxUpcomingOrder + 1 : 0),
            participantImage,
        });

        const newFavourites = produce(this.favourites, (favs) => {
            favs.push(newFavourite);
        });

        const request = newFavourites.map((current) => this.favouritesFactory.toDto(current));

        return this.update(request).pipe(
            switchMap(() => this.loadCounts(newFavourites, true)),
            map((favourites) => {
                this.set(favourites);
                this.eventSubject.next({ favourites: this.favourites, changeType: FavouriteChangeType.Add });

                return this.get(itemId, sportId, type);
            }),
            catchError(() => {
                return throwError(this.sitecore.favouritesMessages.AddError);
            }),
        );
    }

    save(newFavourites: FavouritesViewModel[]): Observable<FavouritesViewModel[] | undefined> {
        if (this.updating) {
            return throwError('UPDATING');
        }

        if (!this.userService.isAuthenticated) {
            return throwError(this.sitecore.favouritesMessages.NotAuthenticated);
        }

        const request = newFavourites.map((current) => this.favouritesFactory.toDto(current));

        return this.update(request).pipe(
            switchMap(() => this.loadCounts(newFavourites, true)),
            map((favourites) => {
                this.set(favourites);
                this.eventSubject.next({ favourites: this.favourites, changeType: FavouriteChangeType.Save });

                return favourites;
            }),
            catchError(() => {
                return throwError(this.sitecore.favouritesDashboard.Save);
            }),
        );
    }

    getFixtureFavourites(): string[] {
        return this.favourites.filter(isFixtureFavourite).map((fixture) => fixture.itemId);
    }

    updateLiveIds(items: StringDictionary<string>): void {
        const newFavourites = produce(this.favourites, (favs) => {
            favs.filter(isFixtureFavourite).forEach((fixtureFav) => {
                fixtureFav.itemId = items[fixtureFav.itemId] || fixtureFav.itemId;
            });
        });

        this.favourites = newFavourites;
    }

    remove(item: FavouritesViewModel | FavouritesViewModel[]): Observable<void> {
        const itemArray = Array.isArray(item) ? item : [item];
        if (this.updating) {
            return throwError('UPDATING');
        }

        if (!this.userService.isAuthenticated) {
            return throwError(this.sitecore.favouritesMessages.NotAuthenticated);
        }

        if (!itemArray.length) {
            return of();
        }

        const diff = differenceBy(this.favourites, itemArray, (current) => `${current.itemId}-${current.type}`);
        const request = diff.map((current) => this.favouritesFactory.toDto(current));

        return this.update(request).pipe(
            map(() => {
                this.set(diff);
                this.eventSubject.next({ favourites: this.favourites, changeType: FavouriteChangeType.Remove });
            }),
        );
    }

    get(itemId: string, sportId: number, type: FavouriteType): FavouritesViewModel | undefined {
        return this.favourites.find((favourite) => favourite.sport.id === sportId && favourite.itemId === itemId && favourite.type === type);
    }

    getList(refresh?: boolean): Observable<FavouritesViewModel[]> {
        if (!this.cached || refresh) {
            this.default = !this.userService.isAuthenticated;

            this.cached = this.apiService.get<FavouritesResponse>('favourites').pipe(
                switchMap((result) => {
                    this.default = result.isDefault;
                    this.favourites = result.favourites.map((current) => this.favouritesFactory.toModel(current));

                    return this.loadCounts(this.favourites);
                }),
                map((favourites) => {
                    this.favourites = favourites;
                    this.eventSubject.next({ favourites: this.favourites, changeType: FavouriteChangeType.Get });

                    return this.favourites;
                }),
                shareReplay(),
            );
        }

        return this.cached;
    }

    private loadCounts(favourites: FavouritesViewModel[], loadAdditionalData: boolean = false): Observable<FavouritesViewModel[]> {
        return this.favouritesData.getCounts(favourites).pipe(
            map((result) => {
                if (!result) {
                    return favourites;
                }

                return produce(favourites, (favDraft) => {
                    favDraft.forEach((f) => {
                        const tags = result[f.id];
                        if (!tags || !tags.length) {
                            return;
                        }

                        const tag = this.findTag(tags, TagType.Tournament) || this.findTag(tags, TagType.Region) || tags[0];
                        const sportTag = this.findTag(tags, TagType.Sport);
                        const competitionTag = this.findTag(tags, TagType.Competition);
                        // if it's a participant, the correct count is on the Sport tag otherwise any tag should have the correct count
                        const countTag =
                            f.type === FavouriteType.Participant || f.type === FavouriteType.ParticipantV2 ? sportTag || tags[0] : tags[0];

                        if (loadAdditionalData) {
                            if (f.type === FavouriteType.League && !f.region) {
                                f.region = {
                                    id: tag.tag.id,
                                    name: tag.tag.name.value,
                                };
                                if (competitionTag) {
                                    f.imageProfile = (competitionTag?.tag as unknown as LeagueTreeItem)?.imageProfile;
                                }
                            }
                            if (sportTag && !f.sport.name) {
                                f.sport.name = sportTag.tag.name.value;
                            }
                        }
                        f.count = countTag.live + countTag.preMatch;
                    });
                });
            }),
        );
    }

    private update(favourites: FavouritesDto[]): Observable<void> {
        this.updating = true;

        return this.apiService.post<void>('favourites', { Favourites: favourites }).pipe(finalize(() => (this.updating = false)));
    }

    private set(favourites: FavouritesViewModel[]): void {
        this.favourites = favourites;
        this.cached = of(this.favourites).pipe(shareReplay());
    }

    private findTag(tags: TagCount[], type: TagType): TagCount | undefined {
        return tags.find((t) => t.tag.type === type);
    }
}
