import { Directive, ElementRef, Input } from '@angular/core';

import { AutomationService } from './automation.service';

/**
 * Adds a data-automation-selector attribute to html element.
 * the input data should be passed always through binding.
 * Example:
 * <div [msAutomation]="'element_x'" ></div>
 * <div [msAutomation]="somevarible" ></div>
 * <div [msAutomation]="{ 'key0': somevariable0, 'key1': somevariable1 }" ></div>
 */
@Directive({
    selector: '[msAutomation]',
})
export class AutomationDirective {
    @Input('msAutomation')
    set data(value: string | { [key: string]: any }) {
        if (!this.service.isActive) {
            return;
        }

        this.service.addAttr(this.elementRef, value);
    }

    constructor(
        private service: AutomationService,
        private elementRef: ElementRef,
    ) {
        if (!service.isActive) {
            // Add a safe check is somebody did not used a binding for the data.
            const elm = elementRef.nativeElement as Element;
            const hasAttribute = elm.hasAttribute('msAutomation');
            if (hasAttribute) {
                elm.removeAttribute('msAutomation');
            }
        }
    }
}
