import { Injectable } from '@angular/core';

import { ImageProfile } from '@cds/betting-offer';
import { GridConfig, ImagesConfig } from '@frontend/sports/common/client-config-data-access';
import { UtilsService } from '@frontend/vanilla/core';
import { DarkModeService } from '@frontend/vanilla/shared/dark-mode';
import { keyBy } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class CompetitionLogosService {
    private competitionLogos = keyBy(this.imagesConfig.competitionLogos, (l) => l.sportId);
    private overriddenCompetitionLogo = keyBy(this.gridConfig.overriddenCompetitionLogo, (l) => l.sportId);

    constructor(
        private imagesConfig: ImagesConfig,
        private gridConfig: GridConfig,
        private utilsService: UtilsService,
        private darkModeService: DarkModeService,
    ) {}

    getLogoUrl(sportId?: number, leagueId?: number, isLogoOverrideEnabled?: boolean, imageRootPath?: string): string {
        let competitionImageName;
        const overridenCompetitionImageName = this.getCompetitionImageName(sportId, leagueId, isLogoOverrideEnabled);
        if (isLogoOverrideEnabled && overridenCompetitionImageName !== '') {
            //for few competitions need to display overridden icon instead of logo for lgv/composable
            competitionImageName = overridenCompetitionImageName;
        } else {
            competitionImageName = this.getCompetitionImageName(sportId, leagueId);
        }

        return competitionImageName
            ? this.utilsService.format(this.imagesConfig.competitionLogoUrl, imageRootPath, sportId, competitionImageName)
            : '';
    }

    getImageProfileLogoUrl(sportId?: number, imageProfile?: ImageProfile, imageRootPath?: string) {
        return imageProfile?.logo
            ? this.utilsService.format(this.imagesConfig.competitionLogoUrl, imageRootPath, sportId, imageProfile?.logo?.split('.')[0])
            : '';
    }

    hasLogo(sportId?: number, leagueId?: number): boolean {
        return !!this.getCompetitionImageName(sportId, leagueId);
    }

    private getCompetitionImageName(sportId?: number, leagueId?: number, isLogoOverrideEnabled?: boolean): string {
        if (!sportId || !leagueId) {
            return '';
        }

        const sport = isLogoOverrideEnabled ? this.overriddenCompetitionLogo[sportId] : this.competitionLogos[sportId];

        if (!sport) {
            return '';
        }

        const league = sport.leagues.find((l) => l.ids.indexOf(leagueId) > -1);

        if (league?.imageDark && this.darkModeService.isEnabled) {
            return league.imageDark;
        }

        if (league?.image) {
            return league.image;
        }

        return '';
    }
}
