"use strict";

var _a;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConsoleLogger = void 0;
var tslib_1 = require("tslib");
var signalr_1 = require("@microsoft/signalr");
/** @private */
var ConsoleLogger = /** @class */function () {
  function ConsoleLogger(minimumLogLevel) {
    this.messageStyle = "background: #222; color: #bada55";
    this.minimumLogLevel = minimumLogLevel;
  }
  ConsoleLogger.prototype.log = function (logLevel, message) {
    var _a;
    var optionalParams = [];
    for (var _i = 2; _i < arguments.length; _i++) {
      optionalParams[_i - 2] = arguments[_i];
    }
    if (logLevel >= this.minimumLogLevel) {
      var args = (0, tslib_1.__spreadArray)(["".concat(this.heading(logLevel), ": %c ").concat(message), this.messageStyle], optionalParams, true);
      (_a = ConsoleLogger.logCategory)[logLevel].apply(_a, args);
    }
  };
  ConsoleLogger.prototype.heading = function (logLevel) {
    return "[".concat(new Date().toISOString(), "] [cds-client] ").concat(signalr_1.LogLevel[logLevel]);
  };
  ConsoleLogger.logCategory = (_a = {}, _a[signalr_1.LogLevel.Critical] = console.error, _a[signalr_1.LogLevel.Error] = console.error, _a[signalr_1.LogLevel.Warning] = console.warn, _a[signalr_1.LogLevel.Information] = console.info, _a[signalr_1.LogLevel.Debug] = console.log, _a[signalr_1.LogLevel.Trace] = console.log, _a[signalr_1.LogLevel.None] = function () {}, _a);
  return ConsoleLogger;
}();
exports.ConsoleLogger = ConsoleLogger;
