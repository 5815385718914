export enum ClientErrorType {
    NotLoggedInError = 'NotLoggedInError',
    UndoCashOutFailedError = 'UndoCashOutFailedError',
    RewardTokensMaxStakeError = 'RewardTokensMaxStakeError',
    RewardTokensMinLegsError = 'RewardTokensMinLegsError',
    RewardTokensTradingEntityMismatchError = 'RewardTokensTradingEntityMismatchError',
    RewardTokensForbiddenError = 'RewardTokensForbiddenError',
    RewardTokensGameTypeError = 'RewardTokensGameTypeError',
    RewardTokensSlipTypeError = 'RewardTokensSlipTypeError',
    RewardTokensMinStakeError = 'RewardTokensMinStakeError',
    RewardTokensTotalOddsError = 'RewardTokensTotalOddsError',
    RewardTokensSelectionLevelOddsError = 'RewardTokensSelectionLevelOddsError',
    RewardTokensBetBuilderError = 'RewardTokensBetBuilderError',
    BetBuilderMaxSelectionsPerPickError = 'BetBuilderMaxSelectionsPerPickError',
    RewardTokensMaxTotalOddsError = 'RewardTokensMaxTotalOddsError',
    RewardTokensSelectionMaxOddsError = 'RewardTokensSelectionMaxOddsError',
}
