import { IUiRootState } from 'packages/sports/web/app/src/ui-manager/ui-manager.state';

import { BetslipState } from '../../core/betslip-state';
import { IBetslipSourcePageState } from '../../model/edit-mybet/edit-bet-init';
import { IAffiliateState } from '../../modules/affiliates/state/affiliate.state';
import { IBetslipPlaceBetState, IBetslipPlaceButtonState } from '../../modules/betplacement/state';
import { IHiddenMarketState } from '../../modules/hidden-market/hidden-market.state';
import { ILineSwitcherState } from '../../modules/line-switcher/state';
import { IBetslipNotificationState } from '../../modules/notifications/state';
import { OddsPreferencePopupState } from '../../modules/odds-preference-popup/odds-preference-state.model';
import { IBetslipOverAskState } from '../../modules/over-ask/state';
import { IBetslipPickState } from '../../modules/picks/state';
import { IQuickBetState } from '../../modules/quick-bet/quick-bet.state';
import { IQuickDepositState } from '../../modules/quick-deposit/quick-deposit.state';
import { IBetslipResultState } from '../../modules/result/state';
import { IRewardTokensState } from '../../modules/reward-tokens/state';
import { ISettingsState } from '../../modules/settings/state';
import { IBetslipTypeState } from '../../modules/types/state';
import { IBetslipErrorsState } from '../../modules/validation/state';
import { BetslipDisplayMode } from '../models/betslip-display-mode.enum';

export interface IBetslipState {
    base: IBetslipBaseState;
    picks: IBetslipPickState;
    types: IBetslipTypeState;
    errors: IBetslipErrorsState;
    rewardTokens: IRewardTokensState;
    placeBet: IBetslipPlaceBetState;
    result: IBetslipResultState;
    settings: ISettingsState;
    placeButton: IBetslipPlaceButtonState;
    overAskState: IBetslipOverAskState;
    lineSwitcher: ILineSwitcherState;
    notifications: IBetslipNotificationState;
    affiliateState: IAffiliateState;
    quickDeposit: IQuickDepositState;
    quickBet: IQuickBetState;
    oddsPreferencePopupState: OddsPreferencePopupState;
    hiddenMarket: IHiddenMarketState;
    sourcePageState: IBetslipSourcePageState;
}

export interface IBetslipBaseState {
    state: BetslipState;
    isBetslipKeypadOpened: boolean;
    displayMode: BetslipDisplayMode;
    linearModeCheckboxesEnabled: boolean;
    isSportcastAsComboEnabled: boolean;
}

export const defaultBetslipBaseState: IBetslipBaseState = {
    state: BetslipState.Edit,
    isBetslipKeypadOpened: false,
    displayMode: BetslipDisplayMode.Tabbed,
    linearModeCheckboxesEnabled: true,
    isSportcastAsComboEnabled: false,
};

export const betslipFeatureKey = 'betslip';

export interface IBetslipRootState extends IUiRootState {
    betslip: IBetslipState;
}
