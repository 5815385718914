import { Injectable } from '@angular/core';

import { LanguageSwitcherUrlsProvider, ParsedUrl } from '@frontend/vanilla/core';
import { toPairs } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '../http/api.service';
import { UrlResponse } from './url.model';

@Injectable()
export class PrettyUrlLanguageSwitcherProvider implements LanguageSwitcherUrlsProvider {
    constructor(private api: ApiService) {}

    getUrls(url: ParsedUrl, languages: string[]): Observable<Map<string, string>> {
        return this.api
            .get<UrlResponse>('url', {
                url: url.url(),
                sourceLanguage: url.culture,
                targetLanguages: languages.join(','),
            })
            .pipe(map((result) => new Map<string, string>(toPairs(result.data))));
    }
}
