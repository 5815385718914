import { Injectable } from '@angular/core';

import { ConnectionConfig } from '@frontend/sports/common/client-config-data-access';

import { CdsPushService, CdsSubscription, GridSubscription } from '../cds/cds-push.service';
import { FixtureFactory } from '../event-model/mappers/fixture.factory';
import { EventModel } from '../event-model/model/event.model';
import { MessageCallback } from './base-subscription.service';
import { EventSubscriptionService } from './event-subscription.service';

/**
 * This service should be used for subscribing grid event for push. It automatically
 * subscribes to scoreboard slim and all grid configured markets including V1 and V2
 * games and option markets
 *
 * @export
 * @class GridSubscriptionService
 * @extends {BaseSubscriptionService}
 */
@Injectable({ providedIn: 'root' })
export class GridSubscriptionService extends EventSubscriptionService {
    constructor(fixtureFactory: FixtureFactory, push: CdsPushService, connection: ConnectionConfig) {
        super(fixtureFactory, push, connection);
    }

    protected override getContextSubscriptions(event: EventModel, callback: MessageCallback): CdsSubscription[] {
        return [new GridSubscription(event.offerContext, event.id, this.connection.culture, callback)];
    }
}
