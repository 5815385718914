import { Injectable } from '@angular/core';

import { ItemType, isItemType } from '@frontend/sports/common/base-utils';
import { TopNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { SessionStoreService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, combineLatest, map } from 'rxjs';

import { SportsCacheService } from '../../client-caching/sports-cache.service';
import { NavigationItemsMapper } from '../navigation-items-mapper';
import { NavigationRouterService } from '../navigation-router.service';
import { TopMenuContentItem } from '../navigation.models';

@Injectable({ providedIn: 'root' })
export class LastSelectedSportProvider {
    private readonly lastSport = new BehaviorSubject<TopMenuContentItem | undefined>(undefined);
    private readonly storeKey: string = 'navigation-last-sport';

    lastSport$: Observable<TopMenuContentItem | undefined> = this.lastSport.asObservable();

    constructor(
        private sessionStore: SessionStoreService,
        private navigationRouter: NavigationRouterService,
        private sportService: SportsCacheService,
        private mapper: NavigationItemsMapper,
        private navigationConfig: TopNavigationConfig,
    ) {
        if (this.lastSelectedSport) {
            this.mapSport(this.lastSelectedSport);
        }

        this.navigationRouter.selectedSport$.subscribe((sportId) => this.mapSport(sportId));
    }

    private set sport(sport: number) {
        this.sessionStore.set(this.storeKey, sport);
    }

    private get lastSelectedSport(): number | undefined {
        return this.sessionStore.get<number>(this.storeKey) || undefined;
    }

    private mapSport(sportId: number): void {
        this.sport = sportId;

        combineLatest([this.sportService.getSportList(), this.navigationConfig.whenReady])
            .pipe(
                map(([sports]) => {
                    const sportItem = sports.find((sport) => sport.id === sportId);

                    if (isItemType(sportItem, ItemType.Sport)) {
                        const hasSeparator = this.navigationConfig.sportsConfig?.selectedSport.useBeforeSeparator;

                        const currentSport = this.mapper.buildSportMenuItem({
                            id: sportId,
                            name: sportItem.name,
                            separator: hasSeparator,
                            isVirtual: sportItem.isVirtual,
                        });
                        this.lastSport.next(currentSport);
                    }
                }),
            )
            .subscribe();
    }
}
