import { Injectable } from '@angular/core';

export abstract class MarketSubTypeLogoStrategy {
    abstract get shouldShowLogo(): boolean;
}

@Injectable()
export class MarketSubTypeLogoHideStrategy extends MarketSubTypeLogoStrategy {
    get shouldShowLogo(): boolean {
        return false;
    }
}
@Injectable()
export class MarketSubTypeLogoShowStrategy extends MarketSubTypeLogoStrategy {
    get shouldShowLogo(): boolean {
        return true;
    }
}
