<div
    [ngClass]="{
        'image-profile': participantImage?.isParticipantProfile && showBackgroundCircle,
        'player-shirt': !participantImage?.isParticipantProfile || !showBackgroundCircle,
        'fallback-color': fallbackColor
    }"
    [class.rotate-image]="rotateImage && image?.type !== imageType.Logo"
    [class.image-loaded]="loaded && image?.type !== imageType.Fallback"
    [style.background-color]="logoColor">
    <img *ngIf="image" [ngSrc]="image.url" disableOptimizedSrcset width="32" height="32" [class]="imageClass" (error)="onImageFailedLoaded()" />
</div>
