export enum MarketType {
    ThreeWay = 'ThreeWay',
    ThreeWayWithNumber = '3way',
    OverUnder = 'OverUnder',
    DoubleChance = 'DoubleChance',
    DrawNoBet = 'DrawNoBet',
    Handicap = 'Handicap',
    CorrectScore = 'CorrectScore',
    DynamicCorrectScore = 'DynamicCorrectScore',
    GoalBand = 'GoalBand',
    GoalMethod = 'GoalMethod',
    OddEven = 'OddEven',
    HomeAwayNoBet = 'HomeAwayNoBet',
    WinningDifference = 'WinningDifference',
    XthHappening = 'XthHappening',
    Scorer = 'Scorer',
    ScorerWithAnyOther = 'ScorerWithAnyOther',
    ScorerWithoutNoScoreOption = 'ScorerWithoutNoScoreOption',
    OverAndUnder = 'Over/Under',
}
export enum PeriodType {
    RegularTime = 'RegularTime',
    FirstHalf = 'FirstHalf',
    SecondHalf = 'SecondHalf',
    ExtraTime = 'ExtraTime',
}

export enum HappeningType {
    Goal = 'Goal',
    Foul = 'Foul',
    FreeKick = 'FreeKick',
    Corner = 'Corner',
    ThrowIn = 'ThrowIn',
    GoalKick = 'GoalKick',
    Penalty = 'Penalty',
    Offside = 'Offside',
    Kickoff = 'Kickoff',
    YellowCard = 'YellowCard',
    RedCard = 'RedCard',
    Substitution = 'Substitution',
    PassingYards = 'PassingYards',
    RushingYards = 'RushingYards',
    ReceivingYards = 'ReceivingYards',
    RushingReceivingYards = 'RushingReceivingYards',
}

export enum MarketSubType {
    TwoUp3wayPricing = '2Up3wayPricing',
}
