<span class="{{ fallbackIcon }}" *ngIf="!logoLoaded && fallbackIcon"></span>
<div
    *ngIf="competitionLogoUrl"
    [ngClass]="{ 'logo-circle': showBackgroundCircle, 'fallback-color': fallbackColor }"
    [style.background-color]="logoColor">
    <img
        [src]="competitionLogoUrl"
        class="competition-logo"
        (load)="loadLogo()"
        (error)="onLoadingError()"
        [class.invisible]="!logoLoaded"
        [hidden]="loadingError" />
</div>
