import { Type } from '@angular/core';
import { Data, ResolveFn } from '@angular/router';

export class RouteContext<TModel> implements Data {
    model: TModel;
}

export function use<
    TResolver extends {
        resolve: ResolveFn<TModel>;
    },
    TComponent,
    TModel,
>(resolver: Type<TResolver>): RouteContext<Type<TResolver>> {
    return {
        model: resolver,
    };
}
