import { Directive, Input, OnInit } from '@angular/core';

import { HooksWireup, Nullable, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { WindowRef } from '@frontend/vanilla/core';
import { takeUntil } from 'rxjs/operators';

import { ScrollContainerService } from '../common/scroll-container.service';
import { SmoothScroll } from '../common/smoothScroll.service';
import { NumpadHostOptions } from './model';
import { NumpadDisplayService } from './numpad-display.service';
import { NumpadAnimationConfig, NumpadHostComponent } from './numpad-host.component';
import { NumpadScrollService } from './numpad-scroll.service';

@HooksWireup()
@Directive({
    selector: '[msNumpadScroll]',
})
export class NumpadScrollDirective extends OnDestroyCleanup implements OnInit {
    @Input('msNumpadScroll') scrollEnabled = true;

    private currentNumpadRect: Nullable<ClientRect> = null;
    private closingNumpadRect: Nullable<ClientRect> = null;
    private scrollContainerRect: Nullable<ClientRect> = null;
    private pickRect: Nullable<ClientRect> = null;

    constructor(
        private numpadHostComponent: NumpadHostComponent,
        private numpadDisplayService: NumpadDisplayService,
        private numpadScrollService: NumpadScrollService,
        private scrollContainerService: ScrollContainerService,
        private smoothScrollService: SmoothScroll,
        private windowRef: WindowRef,
    ) {
        super();
    }

    ngOnInit(): void {
        if (!this.scrollEnabled) {
            return;
        }
        this.numpadDisplayService.open$.pipe(takeUntil(this.destroyed$)).subscribe((options: NumpadHostOptions) => {
            if (this.numpadHostComponent.id === options.id) {
                this.scrollIntoView();
            }
        });
    }

    private scrollIntoView(): void {
        this.initRects();
        if (this.shouldScrollDown()) {
            this.scrollDown();
        }

        if (this.shouldScrollUp()) {
            this.scrollUp();
        }
    }

    private initRects(): void {
        this.currentNumpadRect = this.numpadHostComponent.elementRef.nativeElement.getBoundingClientRect();
        this.closingNumpadRect = this.numpadScrollService.getClosingNumpadRect();
        this.scrollContainerRect = this.scrollContainerService.container.getBoundingClientRect();
        const pickElement = this.numpadHostComponent.elementRef.nativeElement.parentElement;
        this.pickRect = pickElement.getBoundingClientRect();
    }

    private shouldScrollDown(): boolean {
        return this.deltaDown > 0;
    }

    private scrollDown(): void {
        setTimeout(() => {
            this.scrollTo(this.deltaY);
        }, 200);
    }

    private shouldScrollUp(): boolean {
        return this.deltaUp > 0;
    }

    private scrollUp(): void {
        setTimeout(() => {
            this.scrollTo(-this.deltaY);
        }, 200);
    }

    private scrollTo(delta: number): void {
        this.smoothScrollService.scrollTopTo(this.scrollContainerService.container, this.scrollContainerService.container.scrollTop + delta, 150);
    }

    get deltaY(): number {
        if (this.shouldScrollUp()) {
            return this.deltaUp;
        }
        if (this.shouldScrollDown()) {
            return this.deltaDown;
        }

        return 0;
    }

    private get closingNumpadHeight(): number {
        return this.closingNumpadRect!.height;
    }

    private get openingNumpadHeight(): number {
        return NumpadAnimationConfig.maxHeight;
    }

    get deltaDown(): number {
        const windowOverflow = this.scrollContainerRect!.bottom - this.windowRef.nativeWindow.innerHeight;
        let delta = this.currentNumpadRect!.top - this.scrollContainerRect!.bottom + this.openingNumpadHeight;
        if (windowOverflow > 0) {
            delta += windowOverflow;
        }
        if (!this.isClosingNumpadAbove) {
            return delta;
        }

        return delta - this.closingNumpadHeight;
    }

    private get deltaUp(): number {
        const delta = this.scrollContainerRect!.top - this.pickRect!.top;
        if (!this.isClosingNumpadAbove) {
            return delta;
        }

        return delta + this.closingNumpadHeight;
    }

    private get isClosingNumpadAbove(): boolean {
        return !!this.closingNumpadRect && this.closingNumpadRect.top < this.currentNumpadRect!.top;
    }
}
