import { Store } from '@ngrx/store';
import { isArray, isEqual, keys } from 'lodash-es';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { EventDetailsColumnType } from '../event-model/helpers/event-media-helper.service';
import { EventModel } from '../event-model/model/event.model';
import { EventFactory, EventOption, GridSourceEvent } from '../grid-base/factories/event.factory';
import { GridActions } from './grid.actions';
import { GridEvent, GridGrouping, GridMedia, GridModel } from './grid.model';
import { IGridRootState } from './grid.state';

export interface GridEventMapper {
    canMap(grid: GridModel, events: GridSourceEvent[]): boolean;

    map(grid: GridModel, events: GridSourceEvent[]): Observable<EventModel[]>;
}

export interface GridEventToReplace {
    preMatchGridEvent: GridEvent;
    liveGridSourceEvent: GridSourceEvent;
}

export class ObservableGrid extends Observable<GridModel> {
    private id: string;
    private initialized = false;

    private subject = new ReplaySubject<GridModel>(1);
    private subscription: Subscription;

    constructor(
        private grid: GridModel,
        private store: Store<IGridRootState>,
        private factory: EventFactory,
        source: Observable<GridModel>,
    ) {
        super();

        this.id = grid.id;
        // eslint-disable-next-line import/no-deprecated
        this.source = this.subject.asObservable();

        this.subscription = source
            .pipe(
                tap((current) => this.init(current)),
                switchMap(() =>
                    store.pipe(
                        map(this.getModel),
                        distinctUntilChanged(this.compareModel),
                        filter((value) => typeof value !== 'undefined'),
                    ),
                ),
                tap((current) => {
                    this.grid = current!;
                    this.subject.next(current!);
                }),
            )
            .subscribe();
    }

    getState(): GridModel {
        return this.grid;
    }

    destroy(): void {
        this.subject.complete();
        this.subscription?.unsubscribe();
        this.store.dispatch(GridActions.destroy({ gridId: this.id }));
    }

    addEvents(events: GridSourceEvent | GridSourceEvent[], options?: EventOption, grouping?: GridGrouping): void {
        const eventsList = isArray(events) ? events : [events];

        this.factory.create(eventsList, options).subscribe((eventsMapped) => {
            this.store.dispatch(GridActions.addEvents({ payload: { id: this.id, events: eventsMapped, grouping } }));
        });
    }

    removeEvents(eventIds: string[]): void {
        this.store.dispatch(GridActions.removeEvents({ payload: { id: this.id, eventIds } }));
    }

    replaceEvent(targetFixtureId: string, newFixture: GridSourceEvent, options?: EventOption): void {
        this.factory
            .create([newFixture], options)
            .pipe(take(1))
            .subscribe((gridEvents) => {
                if (gridEvents.length === 1) {
                    this.store.dispatch(GridActions.replaceEvent({ payload: { id: this.id, targetFixtureId, newFixture: gridEvents[0] } }));
                }
            });
    }

    replaceEvents(events: GridEventToReplace[], options?: EventOption): void {
        const eventDictionary = events.reduce(
            (previous, current) => ({
                ...previous,
                [current.liveGridSourceEvent.id]: current.preMatchGridEvent,
            }),
            {},
        );
        this.factory
            .create(
                events.map((event) => event.liveGridSourceEvent),
                options,
            )
            .pipe(
                take(1),
                map((gridEvents) =>
                    gridEvents.map((gridEvent) => ({
                        currentEvent: eventDictionary[gridEvent.id],
                        newEvent: gridEvent,
                    })),
                ),
            )
            .subscribe((gridEvents) => this.store.dispatch(GridActions.replaceEvents({ payload: { id: this.id, events: gridEvents } })));
    }

    private init(grid: GridModel): void {
        if (this.initialized) {
            return;
        }

        this.initialized = true;
        this.store.dispatch(GridActions.init({ payload: grid }));
    }

    private getModel = (state: IGridRootState) => {
        const grid = state.grid[this.id];
        if (!grid) {
            return;
        }

        if (!state.media) {
            return grid;
        }

        const mediaTab = state.media.activeTab;
        const media: GridMedia = {
            enabled: state.media.active,
            videoEvent: (mediaTab === EventDetailsColumnType.Video && state.media.video.eventId) || '',
            animationEvent: (mediaTab === EventDetailsColumnType.Animation && state.media.animation.eventId) || '',
            statsEvent: (mediaTab === EventDetailsColumnType.Stats && state.media.statistics.eventId) || '',
        };

        if (isEqual(this.grid.media, media)) {
            return { ...grid, media: this.grid.media };
        }

        return { ...grid, media };
    };

    private compareModel = (current: GridModel | undefined, previous: GridModel | undefined) => {
        if (typeof current === 'undefined' && typeof previous === 'undefined') {
            return true;
        }
        if (typeof current === 'undefined' || typeof previous === 'undefined') {
            return false;
        }
        const currentKeys = keys(current);
        const previousKeys = keys(previous);

        if (currentKeys.length !== previousKeys.length) {
            return false;
        }

        for (const key of previousKeys) {
            if (current[key] !== previous[key]) {
                return false;
            }
        }

        return true;
    };
}
