import { PrettyUrlsConfig } from '@frontend/sports/common/client-config-data-access';
import { keys, merge, trim } from 'lodash-es';

export interface UrlParam {
    id: string | number;
    prefix?: string;
    name: string;
}

export type UrlSegmentParam = number | string | UrlParam;

export class SegmentNormalizer {
    constructor(
        protected urlConfig: PrettyUrlsConfig,
        protected caseSensitive: boolean = false,
    ) {}

    normalize(segment: UrlSegmentParam): string {
        if (!segment) {
            return '';
        }

        let token: string | number;
        let tokenNormalizer = () => token.toString();

        if (typeof segment === 'string' || typeof segment === 'number') {
            token = segment;
        } else {
            token = segment.name;
            tokenNormalizer = () => trim(`${token}-${segment.prefix || ''}${segment.id}`, '-').replace('--', '-');
        }

        let flags = 'gim';
        if ('unicode' in RegExp.prototype) {
            // Check if unicode is supported.
            flags += 'u';
        }

        const normalization = new RegExp(this.urlConfig.tokenSanitizer, flags);
        // it does not do pole dancing
        const stripper = new RegExp(' ', flags);

        token = token.toString().replace(normalization, ' ').trim().replace(stripper, '-').substring(0, this.urlConfig.maxTokenLength);

        if (!this.caseSensitive) {
            token = token.toLocaleLowerCase();
        }

        return tokenNormalizer();
    }
}

export class UrlBuilder {
    private params: string[] = [];
    private query: any = {};

    constructor(private normalizer: SegmentNormalizer) {}

    appendIf(param?: UrlSegmentParam): UrlBuilder {
        if (param) {
            return this.append(param);
        }

        return this;
    }

    append(param: UrlSegmentParam): UrlBuilder {
        this.params.push(this.normalizer.normalize(param));

        return this;
    }

    appendQuery(query: any): UrlBuilder {
        this.query = merge(this.query, query || {});

        return this;
    }

    toString(): string {
        let queryParams = keys(this.query)
            .filter((key) => this.query[key] !== null && this.query[key] !== undefined)
            .map((key) => `${key}=${this.query[key]}`)
            .join('&');

        if (queryParams) {
            queryParams = `?${queryParams}`;
        }

        return `/${this.params.join('/')}${queryParams}`;
    }
}
