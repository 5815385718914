import { ComponentRef, NgModuleRef, Type, ViewContainerRef } from '@angular/core';

import { LazyClientConfigBase, StylesService } from '@frontend/vanilla/core';
import { assign } from 'lodash-es';
import { Observable, Subject, firstValueFrom, from, isObservable, of } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';

import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import ModuleLoaderService from './module-loader.service';

export interface ComponentProxy<T = never> {
    /**
     * Will emit at most once.
     *
     * @param {ViewContainerRef} container
     * @returns {Observable<void>}
     * @memberof ComponentProxy
     */
    create(container: ViewContainerRef): Observable<void>;
    destroy(): void;
    update(data: Partial<T>): void;
    subscribeToOutputs(events: ComponentOutput<T>): void;
}

type ComponentLoadingInfo<T> = () => Promise<{ module: NgModuleRef<any>; type: Type<T> }>;

type ComponentOutput<T> = {
    readonly [P in keyof Partial<T>]: Function;
};

class ComponentProxyRef<T> implements ComponentProxy<T> {
    data: Partial<T> = {};
    component?: ComponentRef<T>;

    private destroy$ = new Subject<void>();
    private outputs?: ComponentOutput<T>;

    constructor(
        private componentLoadingInfo: ComponentLoadingInfo<T>,
        private logger: SportsRemoteLogger,
    ) {}

    create(container: ViewContainerRef): Observable<void> {
        return from(this.componentLoadingInfo()).pipe(
            tap((compLoadingInfo) => {
                container.clear();

                const component = container.createComponent(compLoadingInfo.type, { ngModuleRef: compLoadingInfo.module });

                this.component = component;

                if (this.data) {
                    this.update(this.data);
                }

                if (this.outputs) {
                    this.subscribeToOutputs(this.outputs);
                    this.outputs = undefined;
                }
            }),
            map(() => {}),
            catchError((e) => {
                this.logger.error(e);

                return of();
            }),
            takeUntil(this.destroy$),
        );
    }

    update(data: Partial<T>): void {
        this.data = assign(this.data, data);

        if (this.component) {
            for (const key in this.data) {
                this.component.setInput(key, this.data[key]);
            }

            this.component.changeDetectorRef.detectChanges();
        }
    }

    subscribeToOutputs(events: ComponentOutput<T>): void {
        if (!this.component) {
            this.outputs = events;

            return;
        }

        const keys = Object.keys(events);
        for (const key of keys) {
            const output = this.component.instance[key];
            if (isObservable(output)) {
                output.pipe(takeUntil(this.destroy$)).subscribe(events[key]);
            }
        }
    }

    destroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

export abstract class ComponentLoaderService<T> {
    private logger: SportsRemoteLogger;

    constructor(
        private moduleLoaderService: ModuleLoaderService,
        private loggerFactory: LoggerFactory,
        private stylesService: StylesService,
    ) {
        this.logger = this.loggerFactory.getLogger('ComponentLoaderService');
    }

    abstract readonly moduleName: string;
    abstract getModule(): Promise<Type<T>>;

    protected getComponentProxy<TComponent>(
        typeSelector: (module: T) => Type<any>,
        styleSheet?: string,
        lazyConfig?: LazyClientConfigBase,
    ): ComponentProxyRef<TComponent> {
        const logger = this.loggerFactory.getLogger('ComponentLoaderService');

        const componentLoadingInfo = async () => {
            try {
                const stylesPromise = styleSheet ? this.stylesService.load(styleSheet) : Promise.resolve();
                const lazyConfigPromise = lazyConfig ? firstValueFrom(lazyConfig.whenReady) : Promise.resolve();
                const [module] = await Promise.all([
                    firstValueFrom(this.moduleLoaderService.loadModule<T>(this.moduleName, this.getModule)),
                    stylesPromise,
                    lazyConfigPromise,
                ]);
                const type = typeSelector(module.instance);

                return { module, type };
            } catch (e) {
                this.logger.error(e);
                throw e;
            }
        };

        return new ComponentProxyRef(componentLoadingInfo, logger);
    }
}
