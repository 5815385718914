import { Injectable } from '@angular/core';

import { WindowRef } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { EllapsedTimer } from './ellapsed-timer';

@Injectable({
    providedIn: 'root',
})
export class PageVisibilityService {
    private documentProperties: { eventName: string; hiddenPropertyName: string };
    private document: Document;
    private visibilityStream: BehaviorSubject<{ isVisible: boolean; pageHiddenDuration?: number }>;
    private notificationTimeout: any;
    private pageHiddenTimer?: EllapsedTimer;

    constructor(private windowRef: WindowRef) {
        this.document = this.windowRef.nativeWindow.document;
        this.documentProperties = this.getDocumentProperties();
        this.document.addEventListener(this.documentProperties.eventName, this.handleVisibilityChange, false);
        this.visibilityStream = new BehaviorSubject<{ isVisible: boolean; pageHiddenDuration?: number }>({
            isVisible: this.isVisible,
        });
    }

    visibilityChange(): Observable<{ isVisible: boolean; pageHiddenDuration?: number }> {
        return this.visibilityStream.asObservable();
    }

    private handleVisibilityChange = (): void => {
        const isVisible = this.isVisible;

        const pageHiddenDuration = isVisible && this.pageHiddenTimer ? this.pageHiddenTimer.ellapsedMilliseconds : undefined;
        this.pageHiddenTimer = isVisible ? undefined : EllapsedTimer.start();

        clearTimeout(this.notificationTimeout);
        // wait a bit before launching the event - let things settel down after the page is visible again
        this.notificationTimeout = setTimeout(() => this.visibilityStream.next({ isVisible, pageHiddenDuration }), 150);
    };

    private get isVisible(): boolean {
        return !this.document[this.documentProperties.hiddenPropertyName];
    }

    private getDocumentProperties(): { eventName: string; hiddenPropertyName: string } {
        let hidden = '';
        let visibilityChange = '';

        if (typeof this.document.hidden !== 'undefined') {
            // Opera 12.10 and Firefox 18 and later support
            hidden = 'hidden';
            visibilityChange = 'visibilitychange';
        } else if (typeof (<any>this.document).msHidden !== 'undefined') {
            hidden = 'msHidden';
            visibilityChange = 'msvisibilitychange';
        } else if (typeof (<any>this.document).webkitHidden !== 'undefined') {
            hidden = 'webkitHidden';
            visibilityChange = 'webkitvisibilitychange';
        }

        return { eventName: visibilityChange, hiddenPropertyName: hidden };
    }
}
