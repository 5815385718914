import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { OfferSource } from '@cds';
import { ISimpleChanges } from '@frontend/sports/common/base-utils';

import { EventParticipant, EventParticipantImage } from '../../event-model/model/event.model';
import { TrackingSource, trackingConstants } from '../../tracking/tracking.models';
import { FavouriteType } from '../favourites.model';
import { FavouriteToggleComponent, FavouriteToggleTracking } from './favourite-toggle.component';

@Component({
    selector: 'ms-favourite-participant-toggle',
    template: `
        <ms-favourite-toggle
            [id]="participant.id?.toString()"
            [name]="name"
            [sport]="sport"
            [type]="type"
            [static]="static"
            [participantImage]="participantImage"
            [tracking]="favouriteTracking"
            (toggle)="toggle.emit($event)" />
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FavouriteToggleComponent],
})
export class FavouriteParticipantToggleComponent implements OnChanges {
    @Input() participant: EventParticipant;
    @Input() sport: number;
    @Input() source: OfferSource;
    @Input() static: boolean;
    @Input() tracking: TrackingSource;
    @Input() participantImage?: EventParticipantImage;
    @Output() toggle = new EventEmitter<boolean>();

    name: string;
    type: FavouriteType;

    get favouriteTracking(): FavouriteToggleTracking {
        return {
            name: this.getName(),
            additional: {
                [trackingConstants.SPORT_ID]: this.sport.toString(),
            },
            ...this.tracking,
        };
    }

    ngOnChanges(changes: ISimpleChanges<FavouriteParticipantToggleComponent>): void {
        if (changes.participant) {
            const previous = changes.participant.previousValue ? changes.participant.previousValue.id : undefined;
            const current = changes.participant.currentValue ? changes.participant.currentValue.id : undefined;

            if (previous !== current) {
                this.name = this.getName();
            }
        }

        if (changes.source) {
            this.type = this.getType();
        }
    }

    private getType(): FavouriteType {
        if (!this.source || this.source === OfferSource.V1) {
            return FavouriteType.Participant;
        }

        return FavouriteType.ParticipantV2;
    }

    private getName(): string {
        return this.participant.country ? `${this.participant.name} (${this.participant.country})` : this.participant.name;
    }
}
