import { MessageEnvelope } from '@cds/push';
import { createAction, props } from '@ngrx/store';

import { BetslipState } from '../../core/betslip-state';
import { BetslipDisplayMode } from '../models/betslip-display-mode.enum';

export default class BetslipActions {
    static readonly ACTION_SCHEMA = '@betslip';

    private static readonly SET_STATE = `${BetslipActions.ACTION_SCHEMA}/SET_STATE`;
    private static readonly EVENT_UPDATE = `${BetslipActions.ACTION_SCHEMA}/EVENT_UPDATE`;
    private static readonly FIXTURE_UPDATE = `${BetslipActions.ACTION_SCHEMA}/FIXTURE_UPDATE`;
    private static readonly TOGGLE_BETSLIP_DISPLAY_MODE = `${BetslipActions.ACTION_SCHEMA}/TOGGLE_BETSLIP_DISPLAY_MODE`;

    static setState = createAction(BetslipActions.SET_STATE, props<{ state: BetslipState }>());
    static eventUpdate = createAction(BetslipActions.EVENT_UPDATE, props<{ message: MessageEnvelope }>());
    static fixtureUpdate = createAction(BetslipActions.FIXTURE_UPDATE, props<{ message: MessageEnvelope }>());
    static betBuilderGroupUpdate = createAction(`${BetslipActions.ACTION_SCHEMA}/BETBUILDER_GROUP_UPDATE`, props<{ message: MessageEnvelope }>());
    static toggleBetslipDisplayMode = createAction(BetslipActions.TOGGLE_BETSLIP_DISPLAY_MODE, props<{ mode: BetslipDisplayMode }>());
}
