import { FixtureViewType, Visibility } from '@cds/betting-offer';
import { MessageEnvelope, MessageType } from '@cds/push';
import { GameUpdateCommand } from '@cds/push/fixture-commands';
import { SportConstant } from '@frontend/sports/common/base-utils';
import { GameDeleteMessageEnvelope, GameUpdateMessageEnvelope } from 'packages/sports/web/app/src/cds/cds-push.models';
import { EventParticipant } from 'packages/sports/web/app/src/event-model/model/event.model';

import { BetslipPickChangeInfo, BetslipPickChangeType } from '../../modules/validation/picks/betslip-pick-change-info';
import { OddsOperations } from '../odds/operations';
import { BetslipPick } from './betslip-pick';
import { toComboPrevention } from './combo-prevention';
import { toMinimumCombo } from './minimum-combination';
import { V1PickId } from './pick-id';
import {
    BetslipV1PickEvent,
    BetslipV1PickLeague,
    BetslipV1PickMarket,
    BetslipV1PickOption,
    BetslipV1PickRegion,
    BetslipV1PickSport,
    BetslipV1PickTeaserData,
    IBetslipV1PickDto,
    IBetslipV1PickStorage,
    IPrice,
    PickSubType,
    PriceType,
} from './pick-models';
import { SignedName } from './signed-name.model';

function isGameDeleteEnvelope(envelope: MessageEnvelope): envelope is GameDeleteMessageEnvelope {
    return envelope.messageType === MessageType.GameDelete;
}

function isGameUpdateEnvelope(envelope: MessageEnvelope): envelope is GameUpdateMessageEnvelope {
    return !!(envelope.messageType === MessageType.GameUpdate && (envelope.payload as GameUpdateCommand).game);
}

export class BetslipV1Pick extends BetslipPick implements IBetslipV1PickDto {
    override get eventParticipants(): EventParticipant[] | undefined {
        return this.event.participants;
    }

    override get eventViewType(): FixtureViewType | undefined {
        return this.event.viewType;
    }

    override id: V1PickId;
    sport: BetslipV1PickSport;
    league: BetslipV1PickLeague;
    region: BetslipV1PickRegion;
    event: BetslipV1PickEvent;
    override market: BetslipV1PickMarket;
    option: BetslipV1PickOption;
    teaser: BetslipV1PickTeaserData;

    static fromJSON(value: IBetslipV1PickStorage): BetslipV1Pick {
        const pick = new BetslipV1Pick();
        pick.initPropertiesFromJSON(value);

        return pick;
    }

    static isPick(pick: BetslipPick): pick is BetslipV1Pick {
        return pick instanceof BetslipV1Pick;
    }

    constructor() {
        super();
        this.priceType = PriceType.Fixed;
    }

    protected override initPropertiesFromJSON(value: IBetslipV1PickStorage): void {
        super.initPropertiesFromJSON(value);
        this.event = {
            ...value.event,
            eventDate: new Date(value.event.eventDate),
            cutOffDate: new Date(value.event.cutOffDate),
        };
        this.league = value.league;
        this.region = value.region;
        this.sport = value.sport;
        this.market = {
            ...value.market,
        };
        this.option = {
            ...value.option,
        };
        this.teaser = {
            ...value.teaser,
        };
    }

    get eventName(): SignedName {
        return this.event.name;
    }

    get marketName(): SignedName {
        return this.market.name;
    }

    get optionName(): SignedName {
        return this.option.name;
    }

    get optionId(): number {
        return this.option.id;
    }

    get sportId(): SportConstant {
        return this.sport.id;
    }

    get partitionId(): number | undefined {
        return undefined;
    }

    get isVirtual(): boolean {
        return false;
    }

    get eventDate(): Date {
        return this.event.eventDate;
    }

    get regionName(): SignedName {
        return this.region.name;
    }

    get competitionName(): SignedName {
        return this.league.name;
    }

    get competitionId(): number {
        return this.league.id;
    }

    get sportName(): SignedName {
        return this.sport.name;
    }

    get leagueName(): SignedName {
        return this.league.name;
    }

    isPriceVisible(): boolean {
        return !!this.currentPrice && this.currentPrice.isVisible;
    }

    isMarketVisible(): boolean {
        return this.market.isVisible;
    }

    setMarketInvisible(): void {
        this.market.isVisible = false;
    }

    /**
     * Is pick open.
     * Pick is open when:
     * 1. Is result status is OPEN.
     * 2. Is cutoff date is in future.
     */
    override isOpen(): boolean {
        if (!super.isOpen() || this.market.isClosed) {
            return false;
        }

        return this.event.cutOffDate > new Date();
    }

    override toJSON(): IBetslipV1PickStorage {
        const base = super.toJSON();

        return {
            ...base,
            id: this.id.toString(),
            pickSubType: PickSubType.V1Pick,
            event: {
                id: this.event.id,
                name: { ...this.event.name },
                groupId: this.event.groupId,
                isLive: this.event.isLive,
                isPublished: this.event.isPublished,
                eventDate: this.event.eventDate.toISOString(),
                cutOffDate: this.event.cutOffDate.toISOString(),
                participants: this.event.participants,
                viewType: this.event.viewType,
            },
            league: {
                ...this.league,
            },
            region: {
                ...this.region,
            },
            sport: {
                ...this.sport,
            },
            market: {
                ...this.market,
            },
            option: {
                ...this.option,
            },
            teaser: {
                ...this.teaser,
            },
        };
    }

    copy(): BetslipV1Pick {
        const storage = this.toJSON();

        return BetslipV1Pick.fromJSON(storage);
    }

    updateFromMessage(m: MessageEnvelope): BetslipPickChangeInfo {
        const result: BetslipPickChangeInfo = {};

        if (isGameDeleteEnvelope(m) && m.payload.gameId === this.market.id) {
            this.market = { ...this.market, isVisible: false, isClosed: true };

            return result;
        }
        if (isGameUpdateEnvelope(m) && m.payload.game.id === this.market.id && m.payload.game.results.some((r) => r.id === this.option.id)) {
            const cdsGame = m.payload.game;
            this.market = {
                ...this.market,
                isVisible: cdsGame.visibility === Visibility.Visible,
                isClosed: cdsGame.visibility === Visibility.Hidden,
                comboPrevention: toComboPrevention(cdsGame.combo1),
                minimumCombo: toMinimumCombo(cdsGame.combo2),
            };

            const option = cdsGame.results.find((o) => o.id === this.option.id)!;
            const newPrice = {
                ...this.currentPrice!,
                nativeOdds: OddsOperations.createOdds(option),
                isVisible: option.visibility === Visibility.Visible && option.odds > 1,
            };
            if (!OddsOperations.equal(newPrice.nativeOdds, this.currentPrice!.nativeOdds) || newPrice.isVisible !== this.currentPrice!.isVisible) {
                this.priceHistory.push({ ...this.currentPrice! });
                if (!OddsOperations.equal(newPrice.nativeOdds, this.currentPrice!.nativeOdds)) {
                    result.changeType = BetslipPickChangeType.OddsChange;
                } else if (newPrice.isVisible !== this.currentPrice!.isVisible) {
                    result.changeType = BetslipPickChangeType.VisibilityChange;
                }
            }
            this.option.price = newPrice;
        }

        return result;
    }

    get isLive(): boolean {
        return this.event.isLive;
    }

    get prices(): IPrice[] {
        return [this.option.price];
    }
}
