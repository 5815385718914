import { Inject, Injectable } from '@angular/core';

import { AppConfig } from '@frontend/sports/common/client-config-data-access';
import { DeviceService, NativeAppService } from '@frontend/vanilla/core';
import { Subject, merge } from 'rxjs';
import { filter, throttleTime } from 'rxjs/operators';

import { CdsPushProvider } from '../cds/cds-push.provider';
import { CdsPushService } from '../cds/cds-push.service';
import { trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { SportsClientConfigRefreshService } from '../user/services/sports-client-config-refresh.service';
import { UserService } from '../user/services/user.service';
import { FreshDataProvider, FreshDataType } from './fresh-data-provider.service';
import { OnlineDetectionService } from './online-detection.service';
import { PageVisibilityService } from './page-visibility.service';

@Injectable({ providedIn: 'root' })
export class FreshDataService {
    private reloadNeededStream = new Subject<FreshDataType>();
    private isOnline = false;
    private isVisible = true;

    constructor(
        pageVisibilityService: PageVisibilityService,
        onlineDetection: OnlineDetectionService,
        private pushProvider: CdsPushProvider,
        private appConfig: AppConfig,
        private ms2Tracking: TrackingService,
        deviceService: DeviceService,
        nativeApp: NativeAppService,
        userService: UserService,
        @Inject(FreshDataProvider) freshDataProviders: FreshDataProvider[],
        clientConfigRefresh: SportsClientConfigRefreshService,
        private cdsPushService: CdsPushService,
    ) {
        pageVisibilityService
            .visibilityChange()
            .pipe(filter(() => deviceService.isMobile))
            .subscribe(this.visibilityChange);

        onlineDetection.onlineChange().subscribe(this.onlineChange);

        nativeApp.eventsFromNative.pipe(filter((e) => e.eventName === 'APP_FOREGRND')).subscribe(() => this.requestReload(FreshDataType.DataAndPush));

        merge(...freshDataProviders.map((_) => _.isRefreshNeeded())).subscribe((type) => this.requestReload(type));

        merge(userService.onAuthenticationChange$, clientConfigRefresh.locationDependantDataNeeded$).subscribe(() => {
            this.requestReload(FreshDataType.Data);
        });
    }

    readonly reloadNeeded = this.reloadNeededStream.pipe(throttleTime(150));

    private visibilityChange = ({ isVisible, pageHiddenDuration }: { isVisible: boolean; pageHiddenDuration?: number }) => {
        this.isVisible = isVisible;
        if (!this.isVisible) {
            return;
        }

        const pageHiddenForTooLong =
            (pageHiddenDuration || 0) > this.appConfig.pageHiddenReloadThreshold && this.appConfig.pageHiddenReloadThreshold > 0;

        if (this.isOnline && (this.pushProvider.isDisconnected || pageHiddenForTooLong)) {
            if (pageHiddenForTooLong) {
                this.ms2Tracking.track(trackingConstants.EVENT_RELOAD_HIDDEN, {
                    [trackingConstants.PAGE_RELOAD_AFTER_HIDDEN]: pageHiddenDuration,
                });
            }
            this.requestReload(FreshDataType.DataAndPush);
        }
    };

    private onlineChange = ({ isOnline, offlineDuration }: { isOnline: boolean; offlineDuration?: number }) => {
        this.isOnline = isOnline;

        if (!isOnline) {
            return;
        }

        const offlineForTooLong =
            (offlineDuration || 0) > this.appConfig.offlineDurationReloadThreshold && this.appConfig.offlineDurationReloadThreshold > 0;

        if (this.isVisible && (offlineForTooLong || this.pushProvider.isDisconnected)) {
            if (offlineForTooLong) {
                this.ms2Tracking.track(trackingConstants.EVENT_RELOAD_OFFLINE, {
                    [trackingConstants.PAGE_RELOAD_AFTER_OFFLINE]: offlineDuration,
                });
            }
            this.requestReload(FreshDataType.DataAndPush);
        }
    };

    private async requestReload(type: FreshDataType): Promise<void> {
        if (type === FreshDataType.DataAndPush) {
            await this.cdsPushService.restart();
        }

        this.reloadNeededStream.next(type);
    }
}
