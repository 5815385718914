import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { BetPlacementError } from '../bet-placement-error';

export class IncorrectBetCount extends BetPlacementError {
    constructor(expected?: number, received?: number) {
        super();
        this.expectedBetCount = typeof expected === 'undefined' ? null : expected;
        this.receivedBetCount = typeof received === 'undefined' ? null : received;
        this.hasClientValidation = true;
        this.type = PlacementErrorType.IncorrectOptionCount;
    }

    readonly expectedBetCount: number | null;
    readonly receivedBetCount: number | null;
}
