import { Injectable } from '@angular/core';

/* eslint-disable no-restricted-imports */
import { TrackingService as VanillaTrackingService } from '@frontend/vanilla/core';
import { isEmpty } from 'lodash-es';

import { PickSourceProvider } from '../../option-pick/pick-source.provider';
import { TrackData, TrackUpdate } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';

@Injectable()
export class ModularTrackingService extends TrackingService {
    constructor(
        private pickSourceProvider: PickSourceProvider,
        vanillaTracking: VanillaTrackingService,
    ) {
        super(vanillaTracking);
    }

    override track(event: string, data?: Partial<TrackData>, options?: any): void {
        super.track(event, this.updateData(data), options);
    }

    override update(data: Partial<TrackUpdate>): void {
        super.update(this.updateData(data));
    }

    override triggerEventWithCleaning(event?: string, data?: Partial<TrackData>, options?: any): void {
        super.triggerEventWithCleaning(event, this.updateData(data), options);
    }

    private updateData<T>(trackingObject: T): T {
        const baseTracking = this.pickSourceProvider.getTracking();

        return isEmpty(baseTracking)
            ? trackingObject
            : {
                  ...trackingObject,
                  ...baseTracking,
              };
    }
}
