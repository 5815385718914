import { BetslipType } from '../../../../core/betslip-type';
import { IBetBuilderBetState } from '../../../bet-builder/models';
import { IComboBetState } from '../../../combo-bet/state';
import { isFreebetToken } from '../../../reward-tokens/services/reward-tokens.utils';
import { IRewardTokensState } from '../../../reward-tokens/state';
import { ISingleBetState } from '../../../single-bet/state';
import { SystemKey } from '../../../system-bet/models';
import { SYSTEMS } from '../../../system-bet/services/system-bet.constants';
import { ISystemBetState } from '../../../system-bet/state';
import { ITeaserBetState } from '../../../teaser-bet/state';
import { IBetslipTypeState } from '../../../types/state';

export interface TotalStake {
    stake: number | null;
    freeBetAmount: number | null;
}

function getStakeWithEachWay({ isEachWay, stake }: { isEachWay: boolean; stake: number | null }): number | null {
    if (!stake) {
        return null;
    }

    return isEachWay ? stake * 2 : stake;
}

export function isFreeBetStake(stakes: TotalStake[]): boolean {
    const valueStakes = stakes.filter((stake) => !!stake.stake || !!stake.freeBetAmount);

    return valueStakes.length === 1 && !!valueStakes[0]?.freeBetAmount;
}

export function getTotalStakesForCurrentType(typeState: IBetslipTypeState, tokenState: IRewardTokensState): TotalStake[] {
    const { currentSelectedType } = typeState.base;
    switch (currentSelectedType) {
        case BetslipType.Single:
            return getSingleBetStakes(typeState.singleBet, tokenState);
        case BetslipType.Combo:
            return getComboStake(typeState.comboBet, tokenState);
        case BetslipType.System:
            return getSystemStake(typeState.systemBet);
        default:
            return [];
    }
}

export function getSingleBetStakes(singleState: ISingleBetState, tokensState: IRewardTokensState, filterLockedPicks: boolean = false): TotalStake[] {
    return Object.values(singleState.picks)
        .filter((p) => p.isSelected && (!p.isLocked || !filterLockedPicks))
        .map((p) => ({
            stake: getStakeWithEachWay(p),
            freeBetAmount: getFreeBetAmount(p.rewardTokenId, tokensState),
        }));
}

export function getComboStake(comboState: IComboBetState, tokensState: IRewardTokensState): TotalStake[] {
    return [
        {
            stake: getStakeWithEachWay(comboState),
            freeBetAmount: getFreeBetAmount(comboState.rewardTokenId, tokensState),
        },
    ];
}

export function getTeaserStake(teaserState: ITeaserBetState, tokensState: IRewardTokensState): TotalStake[] {
    return [
        {
            stake: teaserState.stake,
            freeBetAmount: getFreeBetAmount(teaserState.rewardTokenId, tokensState),
        },
    ];
}

export function getBetBuilderStakes(betBuilderState: IBetBuilderBetState, tokensState: IRewardTokensState): TotalStake[] {
    return Object.values(betBuilderState.picks)
        .filter((p) => p.isSelected)
        .map((p) => ({
            stake: p.stake,
            freeBetAmount: getFreeBetAmount(p.rewardTokenId, tokensState),
        }));
}

export function getFreeBetAmount(rewardTokenId: string | null, tokenState: IRewardTokensState) {
    const token = rewardTokenId ? tokenState.tokens[rewardTokenId] : null;
    const freeBetAmount = isFreebetToken(token) ? token.amount : null;

    return freeBetAmount;
}

/**
 * Sums multiple total stakes into a single total stake.
 * If both freebet and regular stake are set for a total stake, only the freebet value will be summed up.
 * This is done to ensure that adding freeBetAmount and stake leads to the correct total stake value that the bets would be placed with.
 * @param stakes The stakes to sum up.
 * @returns The total stake that the current bets will be placed with.
 */
export function combineTotalStakes(stakes: TotalStake[]): TotalStake {
    return stakes.reduce(
        (acc, curr) => {
            const freeBetAmount = curr.freeBetAmount === null ? acc.freeBetAmount : (acc.freeBetAmount ?? 0) + curr.freeBetAmount;
            const stake = curr.stake === null || curr.freeBetAmount ? acc.stake : (acc.stake ?? 0) + curr.stake;

            return {
                freeBetAmount,
                stake,
            };
        },
        <TotalStake>{
            freeBetAmount: null,
            stake: null,
        },
    );
}

function getSystemStake(systemState: ISystemBetState): TotalStake[] {
    const typeKey = systemState.systemInfo?.key;

    if (!typeKey) {
        return [];
    }

    const stake = systemState.stake;

    const betCount =
        typeKey === SystemKey.BankerSingles
            ? Object.values(systemState.picks).filter((p) => p.isSelected && !p.isBanker).length
            : SYSTEMS.find((s) => s.id === typeKey.toLowerCase())?.bets ?? null;

    return [
        {
            stake: betCount && stake ? stake * betCount : null,
            freeBetAmount: null,
        },
    ];
}
