import { Nullable, Optional, isDefined } from '@frontend/sports/common/base-utils';
import { BestOddsFixtureLikeInput } from 'packages/sports/web/app/src/best-odds-guarantee/models/models';
import { IRewardTokenData, RewardTokenType } from 'packages/sports/web/app/src/tokens-base/token-base.models';

import { ParticipantPickType } from '../../../core/picks/pick-models';
import {
    BetslipV2HorseRaceOptionMarketPick,
    BetslipV2HorseRaceWinParticipantPick,
    BetslipV2HorseRaceXCastPick,
} from '../../../core/picks/sport-specific/betslip-v2-horse-race-picks';
import { IBetslipTypeState } from '../../types/state';
import {
    BestOddsGuaranteedToken,
    BetStationFreeBetToken,
    EdsToken,
    EdsTokenWithEligiblePicks,
    IRewardToken,
    RewardTokenContext,
} from '../reward-tokens.model';
import { IBetstationTokenState, IBetstationTokensState, IEdsTokenState, IRewardTokensState, TokensRecord } from '../state';
import { getSelectedTokenForContext } from './linear-reward-tokens.utils';
import { convertToBestOddsFixtureLikeInput } from './reward-tokens.utils';
import { TokenValidationInput } from './validators/criteria-validator-base.service';

export function isBetStationFreebetToken(token: IRewardToken | IRewardTokenData | null): token is BetStationFreeBetToken {
    return !!token && token.rewardTokenType === RewardTokenType.BetStationFreebet;
}

export function isBestOddsGuaranteedToken(token: IRewardToken | IRewardTokenData | null): token is BestOddsGuaranteedToken {
    return !!token && token.rewardTokenType === RewardTokenType.BestOddsGuaranteed;
}

export function isEdsToken(token: Optional<IRewardToken>): token is EdsToken {
    return !!token && token.rewardTokenType === RewardTokenType.Eds;
}

export function getBestOddsGuaranteedTokensContext(state: IRewardTokensState): { valid: boolean; token: BestOddsGuaranteedToken | undefined } {
    //It is guaranteed in SPC service that only one BOG token will be returned
    const token = Object.values(state.tokens).find(isBestOddsGuaranteedToken);

    const eligibility = state.betstationTokens.bogTokenState.eligibility;

    return {
        valid: !!eligibility?.isEligible,
        token,
    };
}

export function toBestOddsLikeFixtures(context: TokenValidationInput): Nullable<BestOddsFixtureLikeInput & { pickId: string }>[] {
    const picks = context.allPicks;

    return picks.map((pick) => {
        if (
            (BetslipV2HorseRaceOptionMarketPick.isPick(pick) &&
                pick.pickType === ParticipantPickType.Win &&
                (pick.isStartingPriceAvailable() || pick.isFixedPriceAvailable())) ||
            BetslipV2HorseRaceWinParticipantPick.isPick(pick) ||
            BetslipV2HorseRaceXCastPick.isPick(pick)
        ) {
            const fixture = convertToBestOddsFixtureLikeInput(pick);

            return { ...fixture, pickId: pick.id.toString() };
        } else {
            return null;
        }
    });
}

export function getSelectedBogToken(bogTokenState: IBetstationTokenState, tokens: Record<string, IRewardToken>): BestOddsGuaranteedToken | null {
    const token = bogTokenState.selectedTokenId ? tokens[bogTokenState.selectedTokenId] : null;

    return isBestOddsGuaranteedToken(token) ? token : null;
}

export function getSelectedBetStationFreeBetToken(
    extendedTokens: IBetstationTokensState,
    tokens: Record<string, IRewardToken>,
): BetStationFreeBetToken | null {
    const tokenId = extendedTokens.betStationFreeBet.selectedTokenId;

    if (!tokenId) {
        return null;
    }

    const token = tokens[tokenId];

    return isBetStationFreebetToken(token) ? token : null;
}

export function getSelectedBetStationTokenForContext(
    context: RewardTokenContext,
    types: IBetslipTypeState,
    betstationTokensState: IBetstationTokensState,
    tokens: TokensRecord,
): IRewardToken | null {
    const tokenId = betstationTokensState.betStationFreeBet.selectedTokenId;

    if (tokenId) {
        const token = tokens[tokenId];

        return isBetStationFreebetToken(token) ? token : null;
    }

    return getSelectedTokenForContext(context, tokens, types);
}

export function getSelectedEdsTokensWithEligiblePicksIds(state: IEdsTokenState, tokens: Record<string, IRewardToken>): EdsTokenWithEligiblePicks[] {
    return state.selectedTokenIds
        ?.map((id) => {
            const token = tokens[id];

            return isEdsToken(token)
                ? {
                      token,
                      eligiblePicksIds: state.eligibilities?.find((e) => e.tokenId === id)?.eligiblePicksIds || [],
                  }
                : undefined;
        })
        .filter(isDefined);
}
