import { createFeatureSelector, createSelector } from '@ngrx/store';

import { BetslipState } from '../../core/betslip-state';
import { BetslipDisplayMode } from '../models/betslip-display-mode.enum';
import { IBetslipState, betslipFeatureKey } from './state';

export const betslipSelector = createFeatureSelector<IBetslipState>(betslipFeatureKey);

export const betslipBaseSelector = createSelector(betslipSelector, (b) => b.base);
export const betslipBaseStateSelector = createSelector(betslipSelector, (b) => b.base.state);
export const betslipDisplayModeSelector = createSelector(betslipSelector, (b) => b.base.displayMode);
export const selectIsLinearBetslip = createSelector(betslipDisplayModeSelector, (b) => b === BetslipDisplayMode.Linear);
export const betslipIsLinearCheckboxesEnabledSelector = createSelector(betslipSelector, (b) => b.base.linearModeCheckboxesEnabled);

export const selectIsPlacingBet = createSelector(betslipSelector, (b) => b.base.state === BetslipState.Placing);

export const betslipStageSelector = createSelector(betslipBaseSelector, (baseState) => baseState.state);
