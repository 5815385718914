import { Injectable, Injector, ViewContainerRef, inject } from '@angular/core';

import { Widget, WidgetLocation } from '@frontend/sports/types/components/widget';
import { Observable, Subject, combineLatest, filter, iif, map, of, shareReplay, take, takeUntil } from 'rxjs';

import { WidgetLoaderService } from './widget-loader.service';
import { WidgetRegistryService } from './widget-registry.service';
import { WidgetSkeletonData } from './widget-skeleton.model';

@Injectable({ providedIn: 'root' })
export class WidgetSkeletonRenderer {
    private readonly componentRegistry = inject(WidgetRegistryService);
    private readonly widgetLoaderService = inject(WidgetLoaderService);

    private readonly widgetSkeletons = new Map<string, () => void>();

    maybeRenderSkeleton({
        widget,
        index,
        injector: parentInjector,
        allWidgets,
        containerRef,
    }: {
        widget: Widget<unknown>;
        index: number;
        injector: Injector;
        allWidgets: Widget<unknown>[];
        containerRef: ViewContainerRef;
    }): void {
        const skeletonConfig = this.componentRegistry.widgetSkeletons[widget.type];
        // if we have a skeleton configured and the slot is empty
        if (skeletonConfig && !this.widgetSkeletons.get(widget.id)) {
            const widgetsBefore = allWidgets.filter((w) => w.location === widget.location && w.order < widget.order);
            const topWidgetsLoaded$ = iif(
                () => widget.location === WidgetLocation.Left || widget.location === WidgetLocation.Center,
                this.widgetLoaderService.allTopLocationWidgetsLoaded$.pipe(filter(Boolean)),
                of(true),
            );
            let widgetsBeforeLoaded$: Observable<boolean> = topWidgetsLoaded$;
            if (widgetsBefore.length > 0) {
                widgetsBeforeLoaded$ = combineLatest([
                    this.widgetLoaderService.widgetLoadedDetails$(widget.location).pipe(
                        map((widgetDetails) => {
                            const sortedOrder = widgetDetails.find(({ widgetId }) => widgetId === widget.id)!.order;

                            return widgetDetails.filter((widgetData) => widgetData.order < sortedOrder).every((widgetData) => widgetData.isLoaded);
                        }),
                    ),
                    topWidgetsLoaded$,
                ]).pipe(
                    map(([widgetsBeforeLoaded, topWidgetsLoaded]) => widgetsBeforeLoaded && topWidgetsLoaded),
                    shareReplay({ refCount: true, bufferSize: 1 }),
                );
            }
            const dispose = new Subject<void>();
            const injector: Injector = Injector.create({
                parent: parentInjector,
                providers: [
                    {
                        provide: WidgetSkeletonData,
                        useValue: {
                            widget,
                            widgetsBeforeLoaded$,
                            config: skeletonConfig.config,
                        },
                    },
                ],
            });
            const loader = containerRef.createComponent(skeletonConfig.component, { index, injector });
            loader.changeDetectorRef.detectChanges();
            loader.instance.showSkeleton$.pipe(filter(Boolean), take(1), takeUntil(dispose)).subscribe(() => {
                this.widgetLoaderService.setwidgetLoaded(`${widget.location}-${widget.id}`);
            });
            this.widgetSkeletons.set(widget.id, () => {
                dispose.next();
                containerRef.remove(containerRef.indexOf(loader.hostView));
                this.widgetSkeletons.delete(widget.id);
            });
        }
    }

    removeSkeleton(id: string) {
        this.widgetSkeletons.get(id)?.();
    }
}
