import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { CalendarService } from './calendar.service';
import { SportIntervalCounts } from './count-interval.service';

@Injectable({ providedIn: 'root' })
export class CalendarResolver {
    constructor(private service: CalendarService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<SportIntervalCounts> {
        return this.service.getCalendarCounts(route.params.sport);
    }

    preResolve(route: ActivatedRouteSnapshot) {
        this.resolve(route).subscribe();
    }
}
