import { Injectable } from '@angular/core';

import { isNil } from 'lodash-es';
import { IPickTracking } from 'packages/sports/common/betslip/core/picks/pick-models';

import { trackingConstants } from '../tracking/tracking.models';

@Injectable({ providedIn: 'root' })
export class PickSourceProvider {
    getTracking(): { [key: string]: string } {
        return {};
    }

    get(
        source: string,
        contentPosition?: number,
        isFallbackMarketEnabled?: boolean,
        marqueeName?: string,
        sitecoreTemplateId?: string,
        marqueeType?: string,
        trackingOptions?: { [key: string]: string },
        isAutomatedMarquee?: boolean,
        isInSheetView?: boolean,
    ): IPickTracking {
        const baseTracking: IPickTracking = {
            source,
            additional: trackingOptions || {},
        };
        if (!isNil(contentPosition)) {
            baseTracking[trackingConstants.COMPONENT_CONTENT_POSITION] = contentPosition.toString();
        }

        if (!isNil(isAutomatedMarquee)) {
            baseTracking.additional![trackingConstants.MARQUEE_CONTENT_LOGIC] = isAutomatedMarquee ? 'default - automated' : 'default';
        }

        if (isInSheetView) {
            baseTracking.additional![trackingConstants.COMPONENT_MODULE_NAME] = trackingConstants.SEE_ALL_OVERLAY;
            baseTracking.sheetviewSuffix = `/${trackingConstants.SEE_ALL_OVERLAY}`;
        }

        return baseTracking;
    }
}
