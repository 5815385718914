import { Injectable } from '@angular/core';

import { ExpiringCache } from '@frontend/sports/common/base-utils';

@Injectable({ providedIn: 'root' })
export class ExpiringCacheFactory {
    create(cacheTime: number): ExpiringCache {
        return new ExpiringCache(cacheTime);
    }
}
