import { Component } from '@angular/core';

import { NumpadService } from './numpad.service';

@Component({
    selector: 'ms-stake-wrapper',
    template: '<ng-content></ng-content>',
    providers: [NumpadService],
})
export class StakeWrapperComponent {}
