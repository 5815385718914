<ng-container *ngIf="sportsPopupMode && !isEpcotEnabled; else scrollAdapterTemplate">
    <ng-container *ngTemplateOutlet="topNavigation"> </ng-container>
</ng-container>

<ng-template #scrollAdapterTemplate>
    <ms-scroll-adapter [scrollSize]="scrollSize" [scrollItems]="filteredItems" (select)="onScrolled()">
        <ng-container *ngTemplateOutlet="topNavigation"> </ng-container>
    </ms-scroll-adapter>
</ng-template>

<ng-template #topNavigation>
    <div class="main-items" [class.scrolled-pills]="scrolled && isEpcotEnabled">
        <vn-menu-item
            *ngFor="let item of filteredItems; trackBy: trackItem"
            [item]="item"
            [msQuicklink]="item.url"
            [section]="tracking.NavTop"
            [ngClass]="{ 'before-separator': item.hasSeparator, 'sports-pill': isEpcotEnabled }"
            [class.active]="isActive(tracking.NavTop, item.name)"
            displayMode="icon"
            badgeClass="badge-size-sm badge-offset"
            badgePosition="icon"
            linkClass="top-nav-link"
            textClass="text-truncate ui-icon-text"
            iconClass="{{ item.isSpecial ? 'special' : '' }} ui-icon ui-icon-size-lg"
            (click)="itemClick($event, item)"></vn-menu-item>
    </div>
</ng-template>
