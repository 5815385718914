import { OfferSource } from '@cds';
import {
    DisplayType,
    Game,
    GroupingInfo,
    OptionMarket,
    OrderType,
    Parameter,
    ParameterType,
    Participant,
    ParticipantType,
    Translation,
} from '@cds/betting-offer';
import { interpolateSingleBracesString } from '@frontend/sports/common/base-utils';
import { BetBuilderConfig } from '@frontend/sports/common/client-config-data-access';
import { groupBy, isEmpty, keys, orderBy, padStart } from 'lodash-es';

import { HappeningType, MarketType } from '../../cds/market-parameter.models';
import { EnhancedFixtureViewGroupingConfiguration, EventOption, EventOptionGroup, ExtendedDisplayType, SortOrder } from '../model/event.model';
import { HappeningValue, MarketTypes, Tv2MarketParameters } from '../model/tv2.model';

export abstract class CommonMarketFactory {
    private readonly OVER_UNDER_MATCHER = /(.+ )(\d+([\.,]\d+)?)$/;
    constructor(private betbuilderConfig: BetBuilderConfig) {}

    protected getGridGroups(grouping: GroupingInfo, categoryId?: number): string[] {
        let groups: string[] = [];
        if (grouping) {
            if (!isEmpty(grouping.gridGroups)) {
                groups = grouping.gridGroups;
            }
        }
        if (!groups.length && categoryId !== undefined) {
            groups = [categoryId.toString()];
        }

        return groups;
    }

    protected patch(
        market: EventOptionGroup,
        originalMarket: Game | OptionMarket,
        participants?: Participant[],
        excludePriceboostedMarketGrouping?: boolean,
    ): void {
        if ('parameters' in originalMarket) {
            const marketType = originalMarket.parameters.find((param) => param.key === Tv2MarketParameters.MarketType);

            // market type returned for virtual fixtures and we mock the params
            if (marketType) {
                switch (marketType.value) {
                    case MarketTypes.Spread:
                        return this.patchSpread(market);
                    case MarketTypes.CorrectScore:
                        return this.patchCorrectScore(market);
                    case MarketTypes.BetBuilder:
                        return this.patchPreCreatedBetBuilder(market);
                    case MarketTypes.OverUnder:
                    case MarketTypes.TotalsOverUnder:
                        return this.patchOverUnder(market);
                    case MarketTypes.HeadToHead:
                    case MarketTypes.TwoWayHandicap:
                        return this.patchHeadToHead(market, participants);
                    case MarketTypes.AutomatedPriceBoost:
                        return this.patchAutomatedPriceBoost(market, excludePriceboostedMarketGrouping);
                }
            } else if (market.source === OfferSource.V2 && this.isOverUnder(market)) {
                return this.patchOverUnder(market);
            }
        }

        if (market.isPartOfSgpGroup) {
            this.patchPreCreatedBetBuilder(market);
        }
    }

    private getTranslated_Value(translation: Translation | undefined): string {
        return translation?.short || translation?.value || '';
    }

    private patchAutomatedPriceBoost(market: EventOptionGroup, excludePriceboostedMarketGrouping?: boolean): void {
        market.detailedGrouping = {
            displayType: ExtendedDisplayType.AutomatedPriceBoost,
            orderType: OrderType.None,
            marketSet: market.detailedGrouping?.marketSet ?? 0,
            marketOrder: market.detailedGrouping?.marketOrder ?? 0,
            marketGroupId: market.detailedGrouping?.marketGroupId ?? '',
            marketGroupItemId: market.detailedGrouping?.marketGroupItemId ?? '',
            marketGroup: !excludePriceboostedMarketGrouping ? Number.MAX_VALUE : market.detailedGrouping?.marketGroup,
        };
        market.options.forEach((option) => {
            option.showBoostedPrice = true;
            option.optionMarketName = market.name;
        });
        market.balanced = true;
    }

    protected patchDetailedGrouping(
        market: EventOptionGroup,
        originalMarket: Game | OptionMarket,
        grouping: EnhancedFixtureViewGroupingConfiguration,
        participants: Participant[] | undefined,
        correctScoreOptionsSortOrder: string | undefined,
    ) {
        if ('parameters' in originalMarket) {
            const marketType = originalMarket.parameters.find((param) => param.key === Tv2MarketParameters.MarketType);
            const happening = originalMarket.parameters.find((param) => param.key === Tv2MarketParameters.Happening);
            const subPeriodNumber = originalMarket.parameters.find((param) => param.key === Tv2MarketParameters.SubPeriodNumber);

            if (
                marketType &&
                happening &&
                (marketType.value === MarketTypes.HeadToHead || marketType.value === MarketTypes.TwoWayHandicap) &&
                market.detailedGrouping
            ) {
                this.patchHeadToHeadMarketName(market, happening, grouping);
                if (participants) {
                    market.options = this.patchParticipantIdToOption(market, participants);
                }
            } else if (marketType?.value === MarketTypes.Scorer && participants) {
                market.options = this.patchParticipantIdToOption(market, participants);
            } else if (marketType?.value === MarketTypes.MultiFPOverUnder && participants) {
                this.patchPlayerCombined(market, originalMarket, participants);
            } else if (
                market.detailedGrouping?.displayType === DisplayType.PlayerMilestone ||
                market.detailedGrouping?.displayType === DisplayType.PlayerMilestoneOverUnder
            ) {
                this.patchPlayerMilestone(market, originalMarket, marketType, happening, participants, grouping);
            } else if (market.detailedGrouping?.displayType === DisplayType.PlayerOutcome) {
                this.patchPlayerOutcome(market, originalMarket, marketType, happening, subPeriodNumber);
            } else if (marketType?.value === MarketTypes.DynamicCorrectScore && happening?.value === HappeningValue.Points && participants) {
                this.patchCorrectScoreOptions(market, participants, correctScoreOptionsSortOrder);
            } else if (marketType && !!subPeriodNumber?.value) {
                market.subPeriodNumber = subPeriodNumber?.value;
            }
        }
    }

    private patchPlayerOutcome(
        market: EventOptionGroup,
        originalMarket: OptionMarket,
        marketType: Parameter | undefined,
        happening: Parameter | undefined,
        subPeriodNumber: Parameter | undefined,
    ): void {
        market.options = orderBy(market.options, (option) => option.id);
        const subPeriod = originalMarket.parameters.find((param) => param.key === Tv2MarketParameters.SubPeriod);
        if (subPeriod && subPeriodNumber && marketType?.value === 'Over/Under' && happening?.value === 'Pitch') {
            market.subPeriodNumber = subPeriod.value + subPeriodNumber.value;
        }
    }

    private patchCorrectScoreOptions(market: EventOptionGroup, participants: Participant[], correctScoreOptionsSortOrder: string | undefined): void {
        const homeTeam = this.getTranslated_Value(participants.find((p) => p.properties?.type === ParticipantType.HomeTeam)?.name);
        const awayTeam = this.getTranslated_Value(participants.find((p) => p.properties?.type === ParticipantType.AwayTeam)?.name);
        if (!homeTeam || !awayTeam) {
            return;
        }
        if (
            !!correctScoreOptionsSortOrder &&
            (correctScoreOptionsSortOrder === SortOrder.Ascending || correctScoreOptionsSortOrder === SortOrder.Descending)
        ) {
            let correctScoreOptions: { option: EventOption; rawScore: string }[] = [];
            correctScoreOptions = market.options.map((option) => {
                const optionNameValues = option?.name?.split(':');
                option.name = `${homeTeam} ${optionNameValues[0]} - ${awayTeam} ${optionNameValues[1]}`;
                const rawScore = `${padStart(optionNameValues[0], 3, '0')}:${padStart(optionNameValues[1], 3, '0')}`;

                return { option, rawScore };
            });
            correctScoreOptions = orderBy(correctScoreOptions, (option) => option.rawScore, correctScoreOptionsSortOrder);
            market.options = correctScoreOptions.map((correctScoreOption) => correctScoreOption.option);
        } else {
            market.options = market.options.map((option) => {
                const optionNameValues = option?.name?.split(':');
                option.name = `${homeTeam} ${optionNameValues[0]} - ${awayTeam} ${optionNameValues[1]}`;

                return option;
            });
        }
    }

    private patchPlayerMilestone(
        market: EventOptionGroup,
        originalMarket: OptionMarket,
        marketType: Parameter | undefined,
        happening: Parameter | undefined,
        participants: Participant[] | undefined,
        grouping: EnhancedFixtureViewGroupingConfiguration,
    ): void {
        const participantId = originalMarket.parameters.find((param) => param.key === Tv2MarketParameters.FixtureParticipant)?.value;
        const happeningIndex = grouping?.masterSorting['happening']?.findIndex((s) => s === happening?.value);
        market.subPeriodNumber = happeningIndex?.toString();
        market.options = orderBy(market.options, (option) => option.id);
        if (participantId) {
            const participant = participants?.find((p) => p.id === +participantId);
            market.options = market.options.map((option) => {
                option.player1Id = participant?.participantId;

                return option;
            });
        }
        if (marketType && (marketType.value === MarketTypes.HappeningToOccurV2 || marketType.value === MarketTypes.HappeningToOccurAndTeamToWin)) {
            market.detailedGrouping.marketGroup = happeningIndex;
        }
    }

    private patchPlayerCombined(market: EventOptionGroup, originalMarket: OptionMarket, participants: Participant[]): void {
        const participantsList = originalMarket.parameters.find((param) => param.key === Tv2MarketParameters.FixtureParticipantList);
        market.options = orderBy(market.options, (option) => option.id);
        if (!participantsList) return;
        const list = participantsList.value.split(',');
        let player1Id: number | undefined;
        let player2Id: number | undefined;
        if (list.length === 2) {
            player1Id = participants.find((p) => p.id === +list[0])?.participantId;
            player2Id = participants.find((p) => p.id === +list[1])?.participantId;
        }
        market.options = market.options.map((option, index) => {
            const name = participants.find((p) => p.id === +list[index])?.name;
            option.name = this.getTranslated_Value(name) || option.name;
            option.player1Id = player1Id;
            option.player2Id = player2Id;

            return option;
        });
    }

    private patchHeadToHeadMarketName(market: EventOptionGroup, happening: Parameter, grouping: EnhancedFixtureViewGroupingConfiguration): void {
        const happeningIndex = grouping?.masterSorting['happening']?.findIndex((s) => s === happening?.value);
        market.detailedGrouping.marketGroup = happeningIndex;

        if (
            market.detailedGrouping.displayType === DisplayType.Regular &&
            (happening.value === HappeningValue.PassingYards || happening.value === HappeningValue.TouchdownPass)
        ) {
            market.participantIds = undefined;
        }

        if (market.detailedGrouping.name) {
            const interpolatedName = interpolateSingleBracesString(market.detailedGrouping.name, {
                Player1: market.player1!,
                Player2: market.player2!,
            });
            market.detailedGrouping.name = interpolatedName;
            market.name = interpolatedName;
        }
    }

    private patchParticipantIdToOption(market: EventOptionGroup, participants: Participant[]): EventOption[] {
        return market.options.map((option) => {
            option.player1Id = participants.find((p) => p.id === option.optionPlayerId)?.participantId;

            return option;
        });
    }

    private patchHeadToHead(market: EventOptionGroup, participants?: Participant[]): void {
        const participantIds: number[] = [];
        market.options = orderBy(market.options, (option) => option.id);
        market.options.forEach((option, index) => {
            const playerName = participants?.find((p) => p.id === option.optionPlayerId)?.name.value || option.name;
            if (index === 0) {
                market.player1 = playerName;
            } else {
                market.player2 = playerName;
            }
            option.marketAttribute = market.name;
            participantIds.push(option.optionPlayerId!);
        });

        market.participantIds = participantIds.reduce((current, next) => current + next.toString(), '_');
    }

    private patchSpread(market: EventOptionGroup): void {
        market.balanced = true;
    }

    private patchCorrectScore(market: EventOptionGroup): void {
        const happening = { key: Tv2MarketParameters.Happening, type: ParameterType.String, value: HappeningType.Goal };
        const marketType = { key: Tv2MarketParameters.MarketType, type: ParameterType.String, value: MarketType.CorrectScore };

        this.addParameterToMarket(market, happening);
        this.addParameterToMarket(market, marketType);

        market.detailedGrouping = {
            displayType: DisplayType.CorrectScore,
            orderType: OrderType.None,
            marketSet: 0,
            marketOrder: 0,
            marketGroupId: market.detailedGrouping?.marketGroupId || '',
            marketGroupItemId: market.detailedGrouping?.marketGroupItemId || '',
        };
    }

    private addParameterToMarket(market: EventOptionGroup, parameter: Parameter): void {
        const existingParameter = market.parameters?.find((param) => param.key === parameter.key);

        if (existingParameter) {
            existingParameter.value = parameter.value;
        } else {
            market.parameters?.push(parameter);
        }
    }

    private patchPreCreatedBetBuilder(market: EventOptionGroup): void {
        if (market.detailedGrouping?.marketTabId) {
            market.detailedGrouping = {
                periodGroup: market.detailedGrouping.periodGroup,
                marketOrder: market.detailedGrouping.marketOrder,
                marketGroup: market.detailedGrouping.marketGroup,
                displayType: ExtendedDisplayType.BetBuilder,
                orderType: OrderType.None,
                marketSet: this.betbuilderConfig.enablePrecreatedBetBuilderTab ? market.detailedGrouping.marketSet : 0,
                name: market.detailedGrouping.name,
                marketHelpPath: market.detailedGrouping.marketHelpPath,
                marketGroupId: market.detailedGrouping.marketGroupId,
                marketGroupItemId: market.detailedGrouping.marketGroupItemId,
                marketTabId: market.detailedGrouping.marketTabId,
                precreatedGroupId: market.detailedGrouping.precreatedGroupId,
            };
        }
    }

    private patchOverUnder(market: EventOptionGroup): void {
        const marketType = { key: Tv2MarketParameters.MarketType, type: ParameterType.String, value: MarketType.OverUnder };

        this.addParameterToMarket(market, marketType);

        market.parameterTranslations = market.parameterTranslations || {};
        market.detailedGrouping = {
            displayType: DisplayType.OverUnder,
            orderType: market.detailedGrouping?.orderType ?? OrderType.None,
            marketSet: 0,
            marketOrder: 0,
            marketGroupId: market.detailedGrouping?.marketGroupId || '',
            marketGroupItemId: market.detailedGrouping?.marketGroupItemId || '',
        };

        market.options.forEach((option, index) => {
            const match = option.name.match(this.OVER_UNDER_MATCHER);

            if (match) {
                if (index === 0) {
                    market.parameterTranslations!.columnOver = match[1].trim();
                }

                if (index === 1) {
                    market.parameterTranslations!.columnUnder = match[1].trim();
                }

                option.marketAttribute = match[2];
            }
        });
    }

    private isOverUnder(market: EventOptionGroup): boolean {
        const attributes: string[] = [];

        for (const option of market.options) {
            const match = option.name.match(this.OVER_UNDER_MATCHER);

            if (!match) {
                return false;
            }

            attributes.push(match[2]);
        }

        if (attributes.length) {
            const pairs = groupBy(attributes, (group) => group);

            return keys(pairs).every((attribute) => pairs[attribute].length === 2);
        }

        return false;
    }
}
