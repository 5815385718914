import { createSelector } from '@ngrx/store';

import { betslipSelector } from '../../base/store/selectors';
import { BetBuilderPickId } from '../../core/picks/pick-id';
import { flattenPicks, getLegsCount, getPicksCount, isBetBuilderPick } from '../../core/utils';
import { isPickEachWayCapable } from './services/betslip-pick-helpers';

export const betslipPickStateSelector = createSelector(betslipSelector, (state) => state.picks);
export const betslipPicksListSelector = createSelector(betslipPickStateSelector, (state) => state.pickList);
export const selectBetslipFlattenedPicksList = createSelector(betslipPicksListSelector, (pickList) => flattenPicks(pickList));
export const betslipPicksListCountSelector = createSelector(betslipPicksListSelector, (pickList) => getPicksCount(pickList));
export const betslipPicksListCountSelectorIncludeBetbuilderLegs = createSelector(betslipPicksListSelector, (pickList) => getLegsCount(pickList));
export const betslipPickIdsSelector = createSelector(betslipPickStateSelector, (state) => state.pickList.flatMap((p) => p.id));
export const betslipPicksUiSelector = createSelector(betslipPickStateSelector, (state) => state.ui);

export const pickWithErrorSelector = createSelector(betslipPickStateSelector, (state) => state.lastPickError);

export const emptyBetslipPicksListSelector = createSelector(betslipPicksListSelector, (state) => !state.length);
export const selectHasEachWayPick = createSelector(betslipPicksListSelector, (picks) => picks.some(isPickEachWayCapable));

export const selectBetBuilderPicksInfo = createSelector(betslipPicksListSelector, betslipPicksListCountSelector, (picks, betslipPicksCount) => {
    const betBuilderPicks = picks.filter((pick) => BetBuilderPickId.isId(pick.id));

    return {
        hasOnlyBetBuilderPicks: betslipPicksCount === betBuilderPicks.length,
        betBuilderPicksCount: betBuilderPicks.length,
        nonBetBuilderPicksCount: betslipPicksCount - betBuilderPicks.length,
        picks: betBuilderPicks,
    };
});

export const selectNonBetBuilderPicks = createSelector(selectBetslipFlattenedPicksList, (picks) => picks.filter((pick) => !isBetBuilderPick(pick)));

export const selectBetslipNonBetBuilderPicksCount = createSelector(selectNonBetBuilderPicks, (pickList) => pickList.length);
