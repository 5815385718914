import { Injectable, OnDestroy } from '@angular/core';

import { MediaQueryService } from '@frontend/vanilla/core';
import { Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export enum StakeInputMode {
    Field = 1,
    Typing = 2,
}

export abstract class StakeInputModeStrategy {
    abstract get inputMode(): Observable<StakeInputMode>;
}

@Injectable()
export class FieldStakeInputModeStrategy extends StakeInputModeStrategy {
    get inputMode(): Observable<StakeInputMode> {
        return of(StakeInputMode.Field);
    }
}

@Injectable()
export class TypingStakeInputModeStrategy extends StakeInputModeStrategy {
    get inputMode(): Observable<StakeInputMode> {
        return of(StakeInputMode.Typing);
    }
}

@Injectable()
export class DynamicInputModeStrategy extends StakeInputModeStrategy implements OnDestroy {
    private subscription: Subscription;
    private subject$: ReplaySubject<StakeInputMode> = new ReplaySubject<StakeInputMode>();

    constructor(private mediaService: MediaQueryService) {
        super();

        this.subscription = this.mediaService
            .observe()
            .pipe(
                map(() => (this.mediaService.isActive('gt-md') ? StakeInputMode.Typing : StakeInputMode.Field)),
                distinctUntilChanged(),
            )
            .subscribe(this.subject$);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    get inputMode(): Observable<StakeInputMode> {
        return this.subject$.asObservable();
    }
}
