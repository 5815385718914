import { Injectable } from '@angular/core';

import { MarqueeTilesConfig } from '@frontend/sports/common/client-config-data-access';
import { MarqueeTile, MarqueeType } from '@frontend/sports/types/components/content';
import { Observable, first, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InternalCompetitionTileService {
    constructor(public marqueeTilesConfig: MarqueeTilesConfig) {}

    getCompetitionTiles(sportId: number): Observable<MarqueeTile[]> {
        return this.marqueeTilesConfig.whenReady.pipe(
            first(),
            map(() =>
                this.marqueeTilesConfig.tiles.filter(
                    (t) => t.showOnCompetitionPage && (t.sportId === sportId || this.checkRegionSportId(t, sportId)),
                ),
            ),
        );
    }

    private checkRegionSportId(tile: MarqueeTile, sportId: number): boolean {
        return (!tile.fixtureId || tile.marqueeType === MarqueeType.BuildABet) && tile.regionSportId === sportId;
    }
}
