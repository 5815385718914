import { Deferred, Utils } from '@frontend/sports/common/base-utils';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TimerService } from '../../common/timer.service';
import { ApiService } from '../../http/api.service';
import { EPSDetailedLogger } from '../../logging/eps-detailed-logger.service';
import { LoggerFactory } from '../../logging/logger-factory.service';
import { SportsRemoteLogger } from '../../logging/sports-remote-logger.service';
import { RedirectHelperService } from '../../navigation-core/redirect-helper.service';
import { TrackData, trackingConstants } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';
import {
    EarlyPayoutByBetNumberResponse,
    EarlyPayoutCallResponse,
    EarlyPayoutEnhancedBet,
    EarlyPayoutRequest,
    EarlyPayoutResponse,
    PayoutStatus,
    PossibleEarlyPayoutResponse,
} from '../models/early-payout';
import { EarlyPayoutSubscriptionSource } from '../models/early-payout-subscription-source';
import { EarlyPayoutConst, PayoutError } from '../models/early-payout-types';
import { MyBetsBetslipBase } from '../models/my-bets-viewmodels';
import { EarlyPayoutSubscriptionMapperService } from './early-payout-subscription-mapper.service';
import { EarlyPayoutHelpers } from './my-bets-earlypayout-helpers';

class TrackingData {
    containsLiveBets: boolean;
    context: string;
    isFreeBet: boolean;
    stake: number;
    betType: string;
}

export class EarlyPayoutService {
    private firstCall: boolean;
    private readonly logger: SportsRemoteLogger;

    constructor(
        private apiService: ApiService,
        loggerFactory: LoggerFactory,
        private tracker: TrackingService,
        private utils: Utils,
        private redirectService: RedirectHelperService,
        private source: EarlyPayoutSubscriptionSource,
        private timerService: TimerService,
        private epsLogger: EPSDetailedLogger,
        private epsMapper: EarlyPayoutSubscriptionMapperService,
        private earlyPayoutHelpers: EarlyPayoutHelpers,
    ) {
        this.firstCall = true;
        this.logger = loggerFactory.getLogger('EarlyPayoutService');
    }

    async checkEarlyPayoutAndSubscribe(betNumbers: string[], cancelationToken: Deferred<void>): Promise<PossibleEarlyPayoutResponse> {
        return firstValueFrom(
            this.apiService
                .get<PossibleEarlyPayoutResponse>('CashoutCheckAndSubscribe', { betNumbers, source: this.source }, { cancelationToken })
                .pipe(
                    catchError((err) => {
                        this.logger.error(err, 'Possible EarlyPayout response failed.');

                        return of({ earlyPayouts: [] });
                    }),
                    map((result) => {
                        if (result?.earlyPayouts?.length) {
                            result.earlyPayouts.forEach((ep) => this.epsLogger.logCheckReponse(ep));
                        }

                        return result || { earlyPayouts: [] };
                    }),
                ),
        );
    }

    async enhanceEarlyPayout(betNumbers: string[], cancelationToken: Deferred<void>): Promise<EarlyPayoutEnhancedBet[]> {
        return firstValueFrom(
            await this.apiService
                .get<EarlyPayoutByBetNumberResponse>('autoCashout/GetByBetNumbers', { betNumbers: betNumbers.join() }, { cancelationToken })
                .pipe(
                    catchError((err) => {
                        this.logger.error(err, 'EarlyPayout ehancement response failed.');

                        return of({ data: [] });
                    }),
                    map((result) => {
                        return result.data || [];
                    }),
                ),
        );
    }

    place = async (betslip: MyBetsBetslipBase, context: string, betTypeInfo?: string): Promise<EarlyPayoutCallResponse> => {
        if (!betslip.earlyPayout || !betslip.earlyPayout.acceptMode) {
            this.logger.error(JSON.stringify(betslip), `AcceptanceMode is empty.`);

            return Promise.resolve(<EarlyPayoutCallResponse>{
                payout: 0,
                error: <PayoutError>{ type: EarlyPayoutConst.EpGeneralError },
            });
        }

        // additional tracking values
        const trackingData: TrackingData = {
            containsLiveBets: betslip.containsLiveBets,
            context,
            isFreeBet: betslip.isFreeBet,
            stake: betslip.stake.value,
            betType: betTypeInfo ?? (betslip.sgpType ? betslip.sgpType.toLowerCase() : betslip.betType.toLowerCase()),
        };

        const request: EarlyPayoutRequest = <EarlyPayoutRequest>{
            bet: {
                acceptanceMode: betslip.earlyPayout.acceptMode || EarlyPayoutConst.ModeHigher,
                betNumber: betslip.betslipRealId,
                key: 1,
                expectedPayout: betslip.earlyPayout.userExpectedValue,
            },
        };

        return await firstValueFrom(this.apiService.post<EarlyPayoutResponse>('cashout', request))
            .then((response) => this.queue(response, trackingData))
            .catch((reason) => this.errorPayout(reason, betslip.betslipRealId, trackingData));
    };

    private checkEarlyPayoutStatus = async (requestId: string, betslipId: string, trackingData: TrackingData): Promise<EarlyPayoutCallResponse> => {
        return await firstValueFrom(this.apiService.get<EarlyPayoutResponse>('cashout', { requestId }))
            .then((response) => this.queue(response, trackingData, betslipId))
            .catch((reason) => this.errorPayout(reason, betslipId, trackingData));
    };

    private queue = (response: EarlyPayoutResponse, trackingData: TrackingData, betslipId?: string): Promise<EarlyPayoutCallResponse> => {
        const payoutStatus = response.payoutStatus;
        const currentBetslipId = betslipId || response.betslipId;

        return new Promise<EarlyPayoutCallResponse>((resolve) => {
            switch (payoutStatus.type) {
                case EarlyPayoutConst.StatusPending:
                    const callDelay = this.firstCall ? payoutStatus.pendingQueryStatusRetryDelay : payoutStatus.pendingOverdueRetryDelay;
                    this.timerService.setTimeout(async () => {
                        this.firstCall = false;
                        const nextCheck = await this.checkEarlyPayoutStatus(response.requestId, currentBetslipId, trackingData);

                        return resolve(nextCheck);
                    }, callDelay);
                    break;
                case EarlyPayoutConst.StatusSucceded:
                    return resolve(this.doneSuccess(payoutStatus, currentBetslipId, trackingData));
                default:
                    return resolve(this.doneFail(payoutStatus, currentBetslipId, trackingData));
            }
        });
    };

    private errorPayout = (reason: { status: number }, betslipId: string, trackingData: TrackingData): EarlyPayoutCallResponse => {
        if (reason.status === 403 || reason.status === 401) {
            this.redirectService.goToLogin({
                appendReferrer: true,
                referrerNeedsLoggedInUser: true,
            });
        }
        const reasonText = JSON.stringify(reason);
        this.logger.error(`EarlyPayout Unknown System Error. Reason: ${reasonText}`);

        this.tracker.track(trackingConstants.EVENT_EARLYPAYOUT, <any>{
            ...this.getBaseTrackingData(false, betslipId, trackingData.betType, trackingData.isFreeBet),
            [trackingConstants.COMPONENT_LOCATION_EVENT]:
                (trackingData.containsLiveBets ? 'live/earlypayout_UnknownSystem' : 'main/earlypayout_UnknownSystem') + trackingData.context,
            error: reasonText,
        });

        return <EarlyPayoutCallResponse>{ payout: 0, error: <PayoutError>{ type: EarlyPayoutConst.EpGeneralError } };
    };

    private doneSuccess(payoutStatus: PayoutStatus, betslipId: string, trackingData: TrackingData): EarlyPayoutCallResponse {
        if (!payoutStatus || !payoutStatus.actualPayout) {
            return <EarlyPayoutCallResponse>{};
        }

        const payoutWithoutStake = this.earlyPayoutHelpers.getValueWithoutFreeBetStake(
            trackingData.stake,
            payoutStatus.actualPayout,
            trackingData.isFreeBet,
        );
        this.tracker.track(trackingConstants.EVENT_EARLYPAYOUT, {
            ...this.getBaseTrackingData(true, betslipId, trackingData.betType, trackingData.isFreeBet),
            earlyPayoutAmount: this.utils.toCent(payoutWithoutStake || payoutStatus.actualPayout),
            [trackingConstants.COMPONENT_LOCATION_EVENT]:
                (trackingData.containsLiveBets ? 'live/earlypayout' : 'main/earlypayout') + trackingData.context,
        });

        return <EarlyPayoutCallResponse>{ payout: payoutStatus.actualPayout, payoutWithoutStake };
    }

    private doneFail(payoutStatus: PayoutStatus, betslipId: string, trackingData: TrackingData): EarlyPayoutCallResponse {
        const response: EarlyPayoutCallResponse = <EarlyPayoutCallResponse>{};
        const payoutError = payoutStatus.error;

        payoutError.type = this.epsMapper.convertOptionToPayoutErrorType(payoutError);

        response.error = payoutError || <PayoutError>{ type: EarlyPayoutConst.EpGeneralError };
        response.payout = payoutError?.possibleAmount || 0;

        let trackingAmount = response.payout;
        const payoutWithoutStake = this.earlyPayoutHelpers.getValueWithoutFreeBetStake(trackingData.stake, response.payout, trackingData.isFreeBet);
        if (payoutWithoutStake !== undefined) {
            response.payoutWithoutStake = payoutWithoutStake > 0 ? payoutWithoutStake : 0;
            trackingAmount = response.payoutWithoutStake;
        }

        const pageName = trackingData.containsLiveBets ? `live/earlypayout_${payoutError.type}` : `main/earlypayout_${payoutError.type}`;
        this.tracker.track(trackingConstants.EVENT_EARLYPAYOUT, {
            ...this.getBaseTrackingData(false, betslipId, trackingData.betType, trackingData.isFreeBet),
            earlyPayoutAmount: this.utils.toCent(trackingAmount),
            [trackingConstants.COMPONENT_LOCATION_EVENT]: pageName + trackingData.context,
        });

        return response;
    }

    private getBaseTrackingData(success: boolean, betslipId: string, betType: string, isFreeBet: boolean): Partial<TrackData> {
        return {
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: 'early payout',
            [trackingConstants.COMPONENT_LABEL_EVENT]: success ? 'success' : 'failure',
            [trackingConstants.COMPONENT_ACTION_EVENT]: 'attempt',
            [trackingConstants.COMPONENT_POSITION_EVENT]: betType,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: isFreeBet ? 'freebet' : 'real money bet',
            earlyPayoutSlipID: betslipId,
        };
    }
}
