import { isDefined } from '@frontend/sports/common/base-utils';
import { sortBy } from 'lodash-es';

import { EventOption, ResultSorting } from '../model/event.model';

export function sortOptions(options: EventOption[], ...resultSorting: (ResultSorting | undefined)[]): EventOption[] {
    const sorting = resultSorting
        .filter(isDefined)
        .map<((o: EventOption) => number) | keyof EventOption | undefined>((s) => {
            switch (s) {
                case ResultSorting.Odds:
                    return priceSorting;
                case ResultSorting.Alphabet:
                    return 'name';
                case ResultSorting.Default:
                    return 'id';
                case ResultSorting.Order:
                    return 'order';
            }
        })
        .filter(isDefined);

    return sortBy(options, ...sorting);
}

function priceSorting(option: EventOption): number {
    return option.nativePrice.odds.toNumber();
}
