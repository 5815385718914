import { Component, HostBinding, Inject, Input, OnChanges, Optional, SimpleChanges, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

import { CountItem, ItemType, LeagueItem, children, interpolateDoubleBracesString } from '@frontend/sports/common/base-utils';
import { PrettyUrlsConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { lowerCase } from 'lodash-es';

import { CalendarService } from '../../calendar/calendar.service';
import { getItemTypeForSport } from '../../competition-list/competition-list.models';
import { CompetitionRouteService } from '../../competition-list/competition-route.service';
import { LeagueModel } from '../../event-model/model/event.model';
import { GRID_CONFIG, GridConfig } from '../../grid-config/models';
import { TabBarEvent, TabBarItem } from '../../tab-bar/tab-bar.models';
import { trackingConstants } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';
import { FixtureTab } from './competitions/competition.models';

@Component({
    selector: 'ms-fixture-list-header',
    templateUrl: 'fixture-list-header.html',
})
export class FixtureListHeaderComponent implements OnChanges {
    @Input() sport?: CountItem;
    @Input() navigation?: TabBarItem[];
    @Input() showFavoriteToggle? = true;
    @Input() competitionTitle: string;
    @Input() headerTitle = '';
    @Input() offerBadgeTemplate?: TemplateRef<any>;

    @HostBinding('class') className = 'fixture-list-header';
    @HostBinding('class.active') get active(): boolean {
        return !!this.title || (!!this.navigation && this.navigation.length > 0);
    }

    @HostBinding('class.calendar') isCalendarPage = false;

    title?: string;
    league?: LeagueModel;
    region?: CountItem;
    useColoredIcon = false;
    regionType: ItemType.Region | ItemType.Tournament;

    constructor(
        public sitecore: Sitecore,
        private route: CompetitionRouteService,
        private router: Router,
        private urlConfig: PrettyUrlsConfig,
        private calendar: CalendarService,
        private trackingService: TrackingService,
        @Optional() @Inject(GRID_CONFIG) private gridConfig: GridConfig | null,
    ) {
        if (this.gridConfig) {
            this.useColoredIcon = this.gridConfig.useColoredIcon;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.sport) {
            this.league = undefined;
            this.title = undefined;

            const route = this.route.params();
            const interval = this.calendar.getInterval(route.context);

            if (
                (route.context !== this.urlConfig.translations.betting &&
                    route.context !== this.urlConfig.translations.standings &&
                    route.context !== this.urlConfig.translations.bets &&
                    route.context !== this.urlConfig.translations.conferences &&
                    !interval) ||
                !changes.sport ||
                !changes.sport.currentValue ||
                !this.sport
            ) {
                return;
            }

            if (interval) {
                this.title = this.headerTitle || interval.title;
                this.isCalendarPage = true;

                return;
            }

            this.isCalendarPage = false;

            this.regionType = getItemTypeForSport(this.sport.id);
            this.region = children(this.sport, this.regionType).find((item) => item.id === route.region);

            let league: LeagueItem | undefined;
            if (!route.league) {
                if (!this.region) {
                    return;
                } else {
                    this.title = `${this.sitecore.globalMessages.All} ${this.region.name}`;
                }
            } else {
                league = children(this.sport, ItemType.Competition).pop() as LeagueItem;
                this.league = {
                    id: league.realCompetitionId || league.id,
                    name: league.name,
                    parentLeagueId: league.siblings[0],
                    imageProfile: league.imageProfile,
                };

                if (route.conference && !this.competitionTitle) {
                    const conference = children(this.sport, ItemType.Conference).pop();
                    this.title = interpolateDoubleBracesString(this.sitecore.seo.ConferenceHeadingTitle, {
                        conference: conference?.name ?? '',
                    });

                    return;
                }

                this.title =
                    this.competitionTitle ||
                    interpolateDoubleBracesString(this.sitecore.seo.LeagueHeadingTitle, {
                        league: league.name,
                    });
            }
        }
    }

    changed(event: TabBarEvent): void {
        const url = this.route.path();

        this.router
            .navigate([url], {
                replaceUrl: true,
                queryParams: {
                    tab: lowerCase(FixtureTab[event.active.id]),
                },
            })
            .then(() => {
                const route = this.route.params();
                this.trackingService.track(trackingConstants.EVENT_NAVIGATION_MENUS, {
                    [trackingConstants.PAGE_NAVIGATION_MENUS]: `CompetitionPage_${route.marketOffer}_${event.active.id}`,
                });
            });
    }

    get iconClass(): string {
        if (this.region) {
            return !this.isTournament ? `country-icon c${this.region.id}` : 'sports-tournament';
        }

        return '';
    }

    get isTournament(): boolean {
        return this.regionType === ItemType.Tournament;
    }
}
