import { Injectable } from '@angular/core';

import { Option, OptionMarket, Price } from '@cds/betting-offer';
import { BetBuilderFixture, BetBuilderMarketInfo } from '@cds/betting-offer/domain-specific/bet-builder';
import { BetBuilderConfig } from '@frontend/sports/common/client-config-data-access';
import { NativePrice } from '@frontend/sports/common/odds-lib';

import { BetBuilderMarket, BetBuilderOption, BetBuilderPrecreatedModel, OptionPrice } from '../model/bet-builder.model';

@Injectable({ providedIn: 'root' })
export class BetBuilderMapperService {
    constructor(private config: BetBuilderConfig) {}

    mapFixture(fixture?: BetBuilderFixture): BetBuilderPrecreatedModel | undefined {
        if (!fixture || !fixture.sourceFixture) {
            return;
        }

        const markets = this.mapMarkets(fixture);
        const fixtureId = fixture.sourceFixture.id;

        return {
            fixtureId,
            hasMarkets: fixture.sourceFixture.optionMarkets.length !== 0,
            ambassadorsMarkets: markets.filter((m) => m.ambassador && m.visibleOnBetBuilderTab),
            promotedMarkets: markets.filter((m) => !m.ambassador && m.visibleOnBetBuilderTab),
            themedTabMarkets: markets.filter((m) => m.themedTab),
        };
    }

    private mapMarkets(response: BetBuilderFixture): BetBuilderMarket[] {
        if (!response.sourceFixture) {
            return [];
        }
        let markets = response.sourceFixture.optionMarkets.map((market) => this.mapGeneralMarket(market));
        markets = this.mapPrecreatedMarketLongId(response.precreatedMarketsInfo, markets);

        return this.mapAmbassador(response.precreatedMarketsInfo, markets);
    }

    private mapPrecreatedMarketLongId(precreatedMarketsInfo: BetBuilderMarketInfo[], markets: BetBuilderMarket[]): BetBuilderMarket[] {
        return markets.map((market) => {
            market.longIds = precreatedMarketsInfo.find((marketInfo) => marketInfo.id === market.id)!.selections;

            return market;
        });
    }

    private mapAmbassador(precreatedMarketsInfo: BetBuilderMarketInfo[], markets: BetBuilderMarket[]): BetBuilderMarket[] {
        precreatedMarketsInfo.forEach((marketInfo) => {
            markets.find((market) => market.id === marketInfo.id)!.visibleOnBetBuilderTab = marketInfo.visibleOnBetBuilderTab;
            if (!marketInfo.ambassadorKey) {
                return;
            }

            const ambassador = this.config.ambassadors.find((a) => a.key === marketInfo.ambassadorKey);
            if (!ambassador) {
                return;
            }
            markets.find((market) => market.id === marketInfo.id)!.ambassador = {
                image: ambassador.image,
                name: ambassador.name,
                singleAmbassadorTitle: ambassador.singleAmbassadorTitle,
            };
        });

        return markets;
    }

    private mapGeneralMarket(responseMarket: OptionMarket): BetBuilderMarket {
        return {
            id: responseMarket.id,
            name: responseMarket.name.value,
            nameSign: responseMarket.name.sign,
            option: this.mapOption(responseMarket.options[0]),
        };
    }

    private mapOption(responseOption: Option): BetBuilderOption {
        return {
            id: responseOption.id,
            optionPrice: this.mapOptionPrice(responseOption.price),
        };
    }

    private mapOptionPrice(optionPrice: Price): OptionPrice {
        return {
            id: optionPrice.id,
            nativePrice: NativePrice.fromNativePrice(optionPrice),
        };
    }
}
