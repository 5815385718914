import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, ViewChild, ViewContainerRef } from '@angular/core';
import { UrlSerializer, createUrlTreeFromSnapshot } from '@angular/router';

import { HooksWireup, OnDestroyCleanup, RouteTag, isDefined } from '@frontend/sports/common/base-utils';
import { LayoutNavigationConfig, LiveSectionConfig, TopNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { MenuItemsService } from '@frontend/vanilla/core';
import { isEqual, omit } from 'lodash-es';
import { filter, takeUntil } from 'rxjs/operators';

import { EpcotConfigService } from '../common/epcot-config.service';
import { CompetitionLogosModule } from '../competition-logos/competition-logos.module';
import { DirectivesModule } from '../directives/directives.module';
import { AdaptiveLayoutService } from '../layout/adaptive-layout.service';
import { NavigationCoreModule } from '../navigation-core/navigation-core.module';
import { RedirectHelperService } from '../navigation-core/redirect-helper.service';
import { RouterEventsService } from '../navigation-core/router-events.service';
import { UrlHelperService } from '../navigation-core/url-helper.service';
import { TopNavigationItemsProvider } from '../navigation/providers/top-navigation-items.provider';
import { TrackNavArea, trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { BreadcrumbModel } from './breadcrumb.models';
import { BreadcrumbSlot, BreadcrumbsSlotService } from './breadcrumbs-slot.service';
import { Breadcrumbs, BreadcrumbsService } from './breadcrumbs.service';

@HooksWireup()
@Component({
    selector: 'ms-breadcrumbs',
    templateUrl: 'breadcrumbs.html',
    standalone: true,
    imports: [NavigationCoreModule, DirectivesModule, CompetitionLogosModule, NgTemplateOutlet, NgIf, NgFor, NgClass],
})
export class BreadcrumbsComponent extends OnDestroyCleanup implements AfterViewInit {
    @HostBinding('class') className = 'breadcrumb';
    @HostBinding('class.hidden') hidden = true;

    @ViewChild('breadcrumbSlot', { read: ViewContainerRef, static: true })
    slotRef?: ViewContainerRef;

    items: BreadcrumbModel[] = [];
    hideMidSectionBreadCrumbItems: boolean;

    private routeHistory: { lastNonEdpUrl?: string; currentTag?: RouteTag } = {};

    constructor(
        private urlHelper: UrlHelperService,
        private redirectHelper: RedirectHelperService,
        private breadcrumbsSlot: BreadcrumbsSlotService,
        private changeDetector: ChangeDetectorRef,
        private trackingService: TrackingService,
        private layoutNavigationConfig: LayoutNavigationConfig,
        private menuService: MenuItemsService,
        private liveConfig: LiveSectionConfig,
        private topNavigationItemsProvider: TopNavigationItemsProvider,
        private topNavigationConfig: TopNavigationConfig,
        breadcrumbService: BreadcrumbsService,
        adaptiveLayout: AdaptiveLayoutService,
        routerEvents: RouterEventsService,
        urlSerializer: UrlSerializer,
        epcotConfig: EpcotConfigService,
    ) {
        super();

        breadcrumbsSlot.slotRegistered$.pipe(takeUntil(this.destroyed$)).subscribe(this.initSlotComponent);
        adaptiveLayout.stateChange$.pipe(takeUntil(this.destroyed$)).subscribe((state) => (this.hidden = state && !state.breadcrumbNavigation));
        this.hideMidSectionBreadCrumbItems = this.layoutNavigationConfig.hideMidSectionBreadCrumbItems;
        breadcrumbService.breadcrumbs$.pipe(takeUntil(this.destroyed$)).subscribe(this.breadcrumbChanged);

        const isEpcotToggleVisible = epcotConfig.isEnabled() && (adaptiveLayout.current.headerSubNavigation || adaptiveLayout.current.columns < 3);
        if (isEpcotToggleVisible) {
            routerEvents.currentActivationEnd.pipe(filter(isDefined), takeUntil(this.destroyed$)).subscribe((e) => {
                this.routeHistory.currentTag = e.snapshot.data?.tag;

                if (e.snapshot.data?.tag !== RouteTag.EventDetails) {
                    this.routeHistory.lastNonEdpUrl = urlSerializer.serialize(
                        createUrlTreeFromSnapshot(e.snapshot, [], omit(e.snapshot.queryParams, 'popup')),
                    );
                }
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.breadcrumbsSlot.registeredComponent) {
            this.initSlotComponent(this.breadcrumbsSlot.registeredComponent);
        } else {
            this.slotRef!.clear();
        }

        this.topNavigationConfig.whenReady.subscribe(() => {
            if (this.liveConfig.showLiveBadgeCount.includes('BOTTOM')) {
                this.setLiveCounts();
            }
        });
    }

    goBack(): void {
        this.trackingService.track(trackingConstants.EVENT_NAVIGATION_MENUS, {
            [trackingConstants.PAGE_NAVIGATION_MENUS]: `${TrackNavArea.NavBreadcrumb}_Back`,
        });
        this.redirectHelper.goBack(this.routeHistory.currentTag === RouteTag.EventDetails ? this.routeHistory.lastNonEdpUrl : undefined);
    }

    private breadcrumbChanged = (model: Breadcrumbs) => {
        if (!isEqual(this.items, model.breadcrumbs)) {
            this.items = model.breadcrumbs.map((crumb) => this.mapCrumb(crumb));
            if (this.hideMidSectionBreadCrumbItems && this.items.length > 2) {
                this.items.splice(1, this.items.length - 2);
            }
        }
    };

    selected(active: BreadcrumbModel): void {
        if (active.ignoreBrowserHistory && active.url) {
            this.redirectHelper.goToPage(active.url, active.ignoreBrowserHistory);
        }
    }

    private mapCrumb(crumb: BreadcrumbModel): BreadcrumbModel {
        return {
            ...crumb,
            url: this.urlHelper.getAbsolute(this.urlHelper.getUrl(crumb.url)),
        };
    }

    private initSlotComponent = (data?: BreadcrumbSlot): void => {
        if (!this.slotRef) {
            return;
        }

        this.slotRef.clear();

        if (!data) {
            return;
        }

        const component = this.slotRef.createComponent<any>(data.component);

        if (data.inputs) {
            Object.keys(data.inputs).forEach((key) => (component.instance[key] = data.inputs[key]));
        }

        this.changeDetector.detectChanges();
    };

    private setBadgeBottom(itemName: string, badgeText: string | undefined, cssClass?: string): void {
        this.menuService.setCounter(TrackNavArea.NavBottom, itemName, badgeText, cssClass);
    }

    private refreshLiveCount() {
        this.topNavigationItemsProvider.getLiveSportsList().subscribe((liveCount) => {
            const elements = document.getElementsByClassName('live-counts-refresh');
            if (elements.length > 0) {
                Array.from(elements).forEach((element) => {
                    element.innerHTML = this.topNavigationItemsProvider.formatLiveCounts(liveCount);
                });
            }
        });
    }

    private setLiveCounts() {
        this.topNavigationItemsProvider.getLiveSportsList().subscribe((liveCount) => {
            const count = this.topNavigationItemsProvider.formatLiveCounts(liveCount);
            const className = this.getClassName(count);
            this.setBadgeBottom('live', count, `live-counts-refresh bottomnav-new-badge-live-count ${className}`);
        });
        this.refreshLiveCountTimer();
    }

    private getClassName(count: string): string {
        switch (count.length) {
            case 2:
                return 'livebadge-fontsize-sm';
            case 1:
                return 'livebadge-fontsize-md';
            case 0:
                return 'livebadge-fontsize-lg';
            default:
                return 'livebadge-fontsize-default';
        }
    }

    private refreshLiveCountTimer() {
        setInterval(() => {
            this.refreshLiveCount();
        }, 60000);
    }
}
