import { Injectable } from '@angular/core';

import { SportConstant } from '@frontend/sports/common/base-utils';
import { ImgConfigurationData, MediaConfig } from '@frontend/sports/common/client-config-data-access';
import { NativeAppService } from '@frontend/vanilla/core';

import { DateProviderService } from '../common/date-provider.service';
import { AnimationProviderService } from '../event-model/helpers/animation-provider.service';
import { EventModel } from '../event-model/model/event.model';
import { getBackgroundColor } from '../event-model/model/scoreboard.model';

@Injectable({ providedIn: 'root' })
export class EventDetailsHelperService {
    sportTypes: { [key: number]: string } = {
        4: 'soccer',
        5: 'tennis',
        7: 'basketball',
    };

    constructor(
        private mediaConfig: MediaConfig,
        private animationProvider: AnimationProviderService,
        private imgConfig: ImgConfigurationData,
        private nativeApp: NativeAppService,
    ) {}

    hasStats(event: EventModel | null): boolean {
        const isLiveStatsAvailable = !!(event && event.scoreboard.started && event.scoreboard.statistics);
        const isPreMatchStatsAvailable = !!(event && (event.hasFixtureStats || event.hasLeagueStats));
        const areParticipantsAvailable = !!(event && event.participants && event.participants.length);
        const isLeagueTableOnlyAvailable = !!(event && !event.hasFixtureStats && event.hasLeagueStats);
        const isGenericBetRadarIdAvailable = !!(event && event.statsId && this.mediaConfig.showStatsCenterButton);

        return !!(
            (event &&
                (this.sportTypes[event.sport.id] || this.nativeApp.isTerminal) &&
                (isLeagueTableOnlyAvailable || areParticipantsAvailable) &&
                (isLiveStatsAvailable || isPreMatchStatsAvailable)) ||
            isGenericBetRadarIdAvailable
        );
    }

    hasAnimation(event: EventModel | null): boolean {
        return !!(
            event &&
            event.live &&
            !!this.animationProvider.sportAnimations[event.sport.id] &&
            event.participants &&
            !!event.participants.length
        );
    }

    hasBetRadarMappingID(event: EventModel | null): boolean {
        return !!(event && event.statsId && event.sport.id === SportConstant.Soccer);
    }

    hasBetRadarVisualization(event: EventModel | null): boolean {
        const result = !!(
            event &&
            event.betRadarVisualizationId &&
            event.betRadarCoverageLevel &&
            event.betRadarCoverageLevel >= this.animationProvider.betRadarSupportedCoverageLevel[event.sport.id] &&
            event.live &&
            this.animationProvider.betRadarSupportedAnimations[event.sport.id] &&
            event.participants &&
            !!event.participants.length
        );

        return result;
    }

    hasImgAnimationVisualization(event: EventModel): boolean {
        const animationShowTime = DateProviderService.addHours(DateProviderService.getNow(), this.imgConfig.hoursToShowImgAnimation);
        const showPrematchAnimation =
            animationShowTime.getTime() >= event.startDate.getTime() && DateProviderService.getNow().getTime() <= event.startDate.getTime();

        return !!((showPrematchAnimation || event.live) && event.imgEventId);
    }

    hasBetRadarStickyAddonVisualization(event: EventModel | null): boolean {
        return !!(event && this.hasBetRadarVisualization(event) && this.animationProvider.betRadarStickySupportedAnimations[event.sport.id]);
    }

    getBackgroundColor(event: EventModel, useSolid: boolean): string | undefined {
        return getBackgroundColor(event, useSolid);
    }
}
