import { Observable, of } from 'rxjs';

import { GridEvent } from '../grid.model';
import { BaseFactory, GridSourceEvent } from './event.factory';

export class GridBaseFactory implements BaseFactory {
    canCreate(events: GridSourceEvent[]): boolean {
        return 'optionGroups' in events[0];
    }

    create(events: GridSourceEvent[]): Observable<GridEvent[]> {
        return of(events as GridEvent[]);
    }
}
