import { FixtureViewType } from '@cds/betting-offer';
import { SportConstant } from '@frontend/sports/common/base-utils';
import { EventParticipant } from 'packages/sports/web/app/src/event-model/model/event.model';

import { loadPickFromJSON } from '../../modules/picks/services/betslip-pick-storage-loader';
import { emptyIPrice, resolveClientLegId } from '../group-pick-helpers';
import { BetslipGroupInformation } from '../groups/betslip-group-information';
import { BetslipPick } from './betslip-pick';
import { BetslipV1Pick } from './betslip-v1-pick';
import { BetslipV2Pick } from './betslip-v2-pick';
import { GroupLegState, defaultLegState } from './group-leg-state';
import { GroupPickId, PickId } from './pick-id';
import { BetslipGroupPickStorage, IPrice, PickOddsState, PickSubType, PriceType } from './pick-models';
import { SignedName } from './signed-name.model';

interface GroupPickInputs {
    sgpId: string;
    price: IPrice | null;
    legsInfo: { clientLegId: string; isVisible: boolean; isClosed: boolean }[];
    isSuspended: boolean;
}

export abstract class BaseBetslipGroupPick extends BetslipPick {
    override id: GroupPickId;
    protected legs: BetslipPick[];
    //unique value provided for the group combination by the provider that can be used by clients for subscribing to price updates
    protected _sgpId: string | null;
    protected _legsState: Record<string, GroupLegState> = {};
    protected price: IPrice;
    isSuspended: boolean; // the whole combination can be suspended
    protected hasEmptyPrice: boolean;

    override get groupInfo(): BetslipGroupInformation {
        return this._groupInfo!;
    }

    static isPick(pick: BetslipPick): pick is BaseBetslipGroupPick {
        return pick instanceof BaseBetslipGroupPick;
    }

    get sgpId(): string | null {
        return this._sgpId;
    }

    getLegs(): BetslipPick[] {
        return this.legs;
    }

    get legsState(): Record<string, GroupLegState> {
        return this._legsState;
    }

    override get eventParticipants(): EventParticipant[] | undefined {
        return this.legs[0].eventParticipants;
    }

    override get eventViewType(): FixtureViewType | undefined {
        return this.legs[0].eventViewType;
    }

    hasPick(id: PickId): boolean {
        return this.legs.some((p) => p.id.toString() === id.toString());
    }

    legIsNoncombinable(pickId: PickId): boolean {
        const { isNoncombinable } = this._legsState[pickId.toString()];

        return isNoncombinable;
    }

    getOddsStateForLegId(legId: PickId): PickOddsState {
        const leg = this.legs.find((l) => l.id === legId);

        if (!leg) return PickOddsState.Closed;

        return this.getOddsStateForLeg(leg);
    }

    private getOddsStateForLeg(leg: BetslipPick): PickOddsState {
        const legOddsState = leg.oddsState;
        const { isClosed, isVisible } = this._legsState[leg.id.toString()];

        if (isClosed || legOddsState === PickOddsState.Closed) {
            return PickOddsState.Closed;
        }

        if (!isVisible || legOddsState === PickOddsState.Locked) {
            return PickOddsState.Locked;
        }

        return PickOddsState.Open;
    }

    protected hasNonOpenLegs(): boolean {
        return this.legs.some((leg) => this.getOddsStateForLeg(leg) !== PickOddsState.Open);
    }

    groupHasUncombinableLeg(): boolean {
        return Object.values(this._legsState).some((t) => t.isNoncombinable);
    }

    protected isPriceVisible(): boolean {
        return true;
    }

    isMarketVisible(): boolean {
        return true;
    }

    get isLive(): boolean {
        return this.legs.some((leg) => leg.isLive);
    }

    get prices(): IPrice[] {
        return [this.price];
    }

    //Overriding this because we want to show an invalid price in case there are any nonOpen legs in a group.
    //The price returned from the price call can still be a valid one.
    override get currentPrice(): IPrice | undefined {
        return this.hasEmptyPrice ? emptyIPrice : this.prices[0];
    }

    //We still want to run our price validators on the 'actual' price
    get actualGroupPrice(): IPrice | undefined {
        return this.prices[0];
    }

    get eventName(): SignedName {
        //all picks within the group belong to the same event, so it's a safe assumption
        return this.legs[0]!.eventName;
    }

    get marketName(): SignedName {
        //all picks within the group belong to the same event, so it's a safe assumption
        throw new Error(`Grouped picks market name getter shouldn't be invoked!`);
    }

    get optionName(): SignedName {
        throw new Error(`Grouped picks option name getter shouldn't be invoked!`);
    }

    get sportId(): SportConstant {
        return this.legs[0]!.sportId;
    }

    //Commented this particular code as AngstromSGP is not PROD-ready, It will be enabled,So GroupPick will not be initiated
    // get partitionId(): number | null {
    //     //TO CHECK: whether all picks within the group belong to the same event, so it's a safe assumption
    //     const fixtureId = this.tryGetFixtureId;
    //     if (this.tryGetFixtureId === '') {
    //         return null;
    //     } else {
    //         return getPartition(fixtureId);
    //     }
    // }

    get partitionId(): number | undefined {
        throw new Error('Method not implemented.');
    }

    get isVirtual(): boolean {
        return this.legs.reduce((acc, curr) => acc && curr.isVirtual, true);
    }

    get eventDate(): Date {
        //all picks within the group belong to the same event, so it's a safe assumption
        return this.legs[0]!.eventDate;
    }

    get regionName(): SignedName {
        //all picks within the group belong to the same event, so it's a safe assumption
        return this.legs[0]!.regionName;
    }

    get competitionName(): SignedName {
        //all picks within the group belong to the same event, so it's a safe assumption
        return this.legs[0]!.competitionName;
    }

    get competitionId(): number {
        //all picks within the group belong to the same event, so it's a safe assumption
        return this.legs[0]!.competitionId;
    }

    get sportName(): SignedName {
        //all picks within the group belong to the same event, so it's a safe assumption
        return this.legs[0]!.sportName;
    }

    get leagueName(): SignedName {
        //all picks within the group belong to the same event, so it's a safe assumption
        return this.legs[0]!.leagueName;
    }

    get tryGetEventId(): string {
        const v1PickWithinTheGroup = this.legs.find((p) => p instanceof BetslipV1Pick);
        if (v1PickWithinTheGroup) {
            return (v1PickWithinTheGroup as BetslipV1Pick).event.id;
        }

        return '';
    }

    get tryGetFixtureId(): string {
        const v2PickWithinTheGroup = this.legs.find((p) => p instanceof BetslipV2Pick);

        if (v2PickWithinTheGroup) {
            return (v2PickWithinTheGroup as BetslipV2Pick).fixture.fixtureId;
        }

        return '';
    }

    get eventId(): string {
        return this.tryGetEventId || this.tryGetFixtureId;
    }

    protected override initPropertiesFromJSON(value: BetslipGroupPickStorage): void {
        super.initPropertiesFromJSON(value);
        this.price = value.price;
        this._sgpId = value.SGPId;
        this._legsState = value.legsState;
    }

    protected initLegState(legInfos: { clientLegId: string; isVisible: boolean; isClosed: boolean }[]) {
        this._legsState = this.legs.reduce<Record<string, GroupLegState>>((acc, leg) => {
            const clientLegId = resolveClientLegId(leg);
            const legInfo = legInfos.find((li) => li.clientLegId === clientLegId) ?? defaultLegState();
            acc[leg.id.toString()] = {
                clientLegId, // in case the defaultLegState was used we don't have a clientLegId
                isVisible: legInfo.isVisible,
                isClosed: legInfo.isClosed,
                isNoncombinable: !leg.market.isBetBuilderEnabled,
            };

            return acc;
        }, {});
    }

    legExist(id: PickId): boolean {
        return !!this.legs.find((t) => t.id.toString() === id.toString());
    }

    abstract addPick(addPickInput: GroupPickInputs & { pick: BetslipPick }): BaseBetslipGroupPick;
    abstract addPicks(addPicksInput: GroupPickInputs & { picks: BetslipPick[] }): BaseBetslipGroupPick;
    abstract removePicks(removePickInput: GroupPickInputs & { ids: PickId[] }): BetslipPick;
    abstract updatePicks(picks: BetslipPick[]): BaseBetslipGroupPick;

    setPrice(price: IPrice): void {
        this.price = price;
    }

    override isOpen(): boolean {
        return super.isOpen();
    }
}

export class BetslipGroupPick extends BaseBetslipGroupPick {
    constructor(input: GroupPickInputs & { groupInfo: BetslipGroupInformation; picks: BetslipPick[] }) {
        super();
        if (!input.groupInfo?.groupId) {
            throw new Error(`Can't init a group with empty group info!`);
        }
        this.priceType = PriceType.Fixed;
        this.id = new GroupPickId(input.groupInfo);
        this.setGroupInfo(input.groupInfo);
        this._sgpId = input.sgpId;
        this.legs = input.picks;
        if (input.price) {
            this.price = input.price;
        }
        this.acceptedPrice = { price: input.price, isManuallyAccepted: true };
        this.initLegState(input.legsInfo);
        this.subscriptionContext = input.sgpId ? [input.sgpId] : [];
        this.isSuspended = input.isSuspended;
        this.hasEmptyPrice = this.hasNonOpenLegs();
    }

    addPick(addPickInput: GroupPickInputs & { pick: BetslipPick }): BaseBetslipGroupPick {
        if (!!addPickInput.pick.groupInfo && addPickInput.pick.groupInfo.groupId !== this.groupInfo.groupId) {
            throw new Error(`Adding pick to a group failed, can't add a pick to a group with different groupId`);
        }

        if (this.legExist(addPickInput.pick.id)) {
            throw new Error(`Leg already exists in the group`);
        }
        const group = this.toJSON();

        const { sgpId, price, isSuspended, legsInfo } = addPickInput;

        return new BetslipGroupPick({
            groupInfo: this._groupInfo!,
            sgpId,
            price,
            isSuspended,
            legsInfo,
            picks: [...group.picks.map((t) => loadPickFromJSON(t)), addPickInput.pick],
        });
    }

    addPicks(addPicksInput: GroupPickInputs & { picks: BetslipPick[] }): BaseBetslipGroupPick {
        if (!!addPicksInput.picks[0].groupInfo && addPicksInput.picks[0].groupInfo.groupId !== this.groupInfo.groupId) {
            throw new Error(`Adding pick to a group failed, can't add a pick to a group with different group fixture`);
        }

        if (addPicksInput.picks.some((pick) => this.legExist(pick.id))) {
            throw new Error(`Leg already exists in the group`);
        }
        const group = this.toJSON();

        const { sgpId, price, isSuspended, legsInfo } = addPicksInput;

        return new BetslipGroupPick({
            groupInfo: this._groupInfo!,
            picks: [...group.picks.map((t) => loadPickFromJSON(t)), ...addPicksInput.picks],
            sgpId,
            price,
            legsInfo,
            isSuspended,
        });
    }

    removePicks(removePicksInput: GroupPickInputs & { ids: PickId[] }): BetslipPick {
        if (!removePicksInput.ids.every((inputPick) => this.legs.find((p) => p.id.toString() === inputPick.toString()))) {
            throw new Error(`Removing picks failed, at least one pick doesn't belong to the specified group`);
        }
        //if only 1 pick remains in the group, break the group down, and return the remaining pick as a single pick
        if (this.legs.length === removePicksInput.ids.length + 1) {
            const remainingLeg = this.legs.find((l) => !removePicksInput.ids.map((pid) => pid.toString()).includes(l.id.toString()));

            if (!remainingLeg) {
                throw new Error(`Removing picks failed, remaining leg was not found`);
            }

            return remainingLeg;
        }

        const group = this.toJSON();
        group.picks = group.picks.filter((p) => !removePicksInput.ids.some((inputPick) => p.id.toString() === inputPick.toString()));
        const { sgpId, price, isSuspended, legsInfo } = removePicksInput;

        return new BetslipGroupPick({
            groupInfo: group.groupInfo!,
            picks: group.picks.map((t) => loadPickFromJSON(t)),
            sgpId,
            price,
            legsInfo,
            isSuspended,
        });
    }

    updatePicks(picks: BetslipPick[]): BaseBetslipGroupPick {
        return new BetslipGroupPick({
            groupInfo: this._groupInfo!,
            sgpId: this._sgpId!,
            picks,
            price: this.price,
            legsInfo: Object.values(this._legsState),
            isSuspended: this.isSuspended,
        });
    }

    copy(): BetslipGroupPick {
        const copy = this.toJSON();

        return BetslipGroupPick.fromJSON(copy);
    }

    static fromJSON(value: BetslipGroupPickStorage): BetslipGroupPick {
        if (!value.groupInfo) {
            throw new Error(`Failed instantiating group from storage value, missing group info!`);
        }
        const pick = new BetslipGroupPick({
            groupInfo: value.groupInfo,
            sgpId: value.SGPId,
            picks: value.picks.map((t) => loadPickFromJSON(t)),
            price: value.price,
            legsInfo: Object.values(value.legsState).map((legState) => ({
                clientLegId: legState.clientLegId,
                isVisible: legState.isVisible,
                isClosed: legState.isClosed,
            })),
            isSuspended: value.isSuspended,
        });
        pick.initPropertiesFromJSON(value);

        return pick;
    }

    override toJSON(): BetslipGroupPickStorage {
        const base = super.toJSON();

        return {
            ...base,
            picks: this.legs.map((p) => p.toJSON()),
            price: this.price,
            SGPId: this._sgpId ?? '',
            legsState: this._legsState,
            pickSubType: PickSubType.GroupedPicks,
            isSuspended: this.isSuspended,
        };
    }
}
