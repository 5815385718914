import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { CookieName } from '../browser/cookie/cookie.models';
import { CookieService } from '../browser/cookie/cookie.service';
import { Page } from '../client-config/page.client-config';
import { ApiOptions } from '../http/http.models';
import { SharedFeaturesApiService } from '../http/shared-features-api.service';
import { RememberMeConfig } from './remember-me.client-config';

/**
 * @stable
 */
@Injectable({
    providedIn: 'root',
})
export class RememberMeService {
    constructor(
        private rememberMeConfig: RememberMeConfig,
        private cookieService: CookieService,
        private apiService: SharedFeaturesApiService,
        private page: Page,
    ) {}

    tokenExists(): boolean {
        return this.rememberMeConfig.isEnabled ? this.indicatorTokenExists() : false;
    }

    indicatorTokenExists(): boolean {
        return !!this.cookieService.get(CookieName.RmI);
    }

    setupTokenAfterLogin(): Observable<void> {
        if (!this.rememberMeConfig.isEnabled && !this.rememberMeConfig.legacyTokenExtractionIframeUrl) {
            return of();
        }

        return this.request({ method: 'PUT' });
    }

    login(data: any): Observable<any> {
        return this.request({ method: 'POST', data });
    }

    logout(): Observable<void> {
        return this.request({ method: 'DELETE' });
    }

    private request(options: ApiOptions): Observable<any> {
        const url = `auth/rememberme?culture=${this.page.lang}`;
        options.prefix = this.rememberMeConfig.apiHost.replace(/\/+$/, ''); // Avoid slash duplication
        options.withCredentials = true;
        options.showSpinner = false;

        return this.apiService.request(url, options);
    }
}
