import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

import { ISimpleChanges, Optional, isDefined } from '@frontend/sports/common/base-utils';

import { StakeValidationResult } from '../betslip-base/stake-validation/stake-validation.models';
import { TrustPipe } from '../common/trust.pipe';

@Component({
    selector: 'ms-numpad-error',
    templateUrl: './numpad-error.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumpadErrorComponent implements OnChanges {
    @Input() error: Optional<StakeValidationResult>;

    message: SafeHtml | null = null;

    @HostBinding('class.clickable') get clickable(): boolean {
        return !!(this.error && isDefined(this.error.callback));
    }

    constructor(private trustPipe: TrustPipe) {}

    ngOnChanges(changes: ISimpleChanges<NumpadErrorComponent>): void {
        this.message = this.error ? this.trustAsHtml(this.error.message) : null;
    }

    onClick(): void {
        if (this.error && this.error.callback) {
            this.error.callback();
        }
    }

    private trustAsHtml(message: any): SafeHtml {
        return this.trustPipe.transform(message, 'html');
    }
}
