import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'ms-component-loader',
    template: '<div></div>',
    standalone: true,
})
export class ComponentLoaderComponent {
    @HostBinding('class') className = 'component-loader';
}
