import { Component, Inject, InjectionToken, Input, OnDestroy, OnInit } from '@angular/core';

import { CommonMessages } from '@frontend/vanilla/core';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

import { CustomLoadingIndicatorExtendedService } from './custom-loading-indicator.service';

export const LOADING_INDICATOR = new InjectionToken<string>('ms-loading-indicator');

/**
 * Used to create loading indicators on custom place on the page.
 * Use it along with @see {@link CustomLoadingIndicatorService} use the same token as the one this component is registered
 */
@Component({
    selector: 'ms-loading-indicator',
    templateUrl: 'loading-indicator.html',
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {
    isVisible$: Observable<boolean>;
    @Input() delay = 0;
    constructor(
        private commonMessages: CommonMessages,
        private customLoadingIndicatorService: CustomLoadingIndicatorExtendedService,
        @Inject(LOADING_INDICATOR) private token: string,
    ) {}

    get fallbackMessage(): string {
        return this.commonMessages['PleaseWait'];
    }

    ngOnDestroy(): void {
        this.customLoadingIndicatorService.unregisterLoadingIndicator(this.token);
    }

    ngOnInit(): void {
        const visibilityService = this.customLoadingIndicatorService.registerLoadingIndicator(this.token);
        this.isVisible$ = visibilityService.visible.pipe(delay(this.delay));
    }
}
