import { Injectable } from '@angular/core';

import { SportConstant } from '@frontend/sports/common/base-utils';
import { MarqueeConfig, MarqueeTilesConfig } from '@frontend/sports/common/client-config-data-access';
import { MarqueeTile, MarqueeType } from '@frontend/sports/types/components/content';
import { NativeAppService } from '@frontend/vanilla/core';
import { Observable, map, of } from 'rxjs';

import { Promotion } from '../crm-offer-data/crm-offer.model';
import { InternalCompetitionTileService } from './marquee-competitiontiles.service';
import { CompetitionPageType, Tile, VisibilityOnMarquee } from './marquee-tile.model';

@Injectable({ providedIn: 'root' })
export class MarqueeTileService {
    constructor(
        private marqueeConfig: MarqueeConfig,
        private marqueeTilesConfig: MarqueeTilesConfig,
        private nativeAppService: NativeAppService,
        private internalCompetitionTileService: InternalCompetitionTileService,
    ) {}

    /**
     * Return the sitecore configuration for a specific fixture it is mapped to
     *
     * @param [fixtureId]
     * @returns
     * @memberof MarqueeTileService
     */
    getForFixture(fixtureId: string): Observable<MarqueeTile[]> {
        return this.filterForFixture(fixtureId).pipe(map((tiles) => tiles.slice(0, this.marqueeConfig.maxRequestedEvents)));
    }

    hasForFixture(fixtureId: string): Observable<boolean> {
        return this.filterForFixture(fixtureId).pipe(map((tiles) => tiles.length > 0));
    }

    getForTeamPagesFixture(fixtureIds: string[]): MarqueeTile[] {
        return this.filterForTeamPagesFixture(fixtureIds).slice(0, this.marqueeConfig.maxRequestedEvents);
    }

    getForPriceBoostFixture(): MarqueeTile[] {
        return this.filterForPriceBoostFixture().slice(0, this.marqueeConfig.maxRequestedEvents);
    }

    getForCompetition(ids: number[], sportId: number, type: CompetitionPageType): Observable<MarqueeTile[]> {
        if (sportId === SportConstant.Tennis && type === CompetitionPageType.Region && !this.nativeAppService.isTerminal) {
            return of([]);
        }

        const competitionTiles = this.internalCompetitionTileService.getCompetitionTiles(sportId);

        return competitionTiles.pipe(
            map((tiles) => {
                const tileResult = tiles.filter((t) => {
                    if ((t.competitionIds && t.competitionIds?.length > 0) || (t.regionIds && t.regionIds?.length > 0)) {
                        return type === CompetitionPageType.Competition
                            ? t.competitionIds?.some((cId) => ids.some((id) => id === cId))
                            : t.regionIds?.some((rId) => ids.some((id) => id === rId));
                    }
                    if (t.fixtureId) {
                        return ids.some((id) => (type === CompetitionPageType.Competition ? id === t.competitionId : id === t.regionId));
                    }

                    return false;
                });

                return this.filterTilesResult(tileResult);
            }),
        );
    }

    combineWithPromotions(marquees: Tile[] | undefined, promotions: Promotion[]): (Tile | Promotion)[] {
        const promoVisibility = [VisibilityOnMarquee.MarqueeOnly, VisibilityOnMarquee.All];

        if (!marquees || !promotions.length) {
            return marquees || [];
        }

        const combined: (Tile | Promotion)[] = [];

        marquees.forEach((m) => {
            const promos = this.getCombinablePromotions(m, promotions);
            promos.length && combined.push(promos[0]);

            if (promoVisibility.includes(m.crmPromotionVisibility)) {
                combined.push(m);
            }
        });

        return combined;
    }

    private getCombinablePromotions(marquee: Tile, promotions: Promotion[]): Promotion[] {
        if (marquee.crmPromotionVisibility !== VisibilityOnMarquee.MarqueeOnly && marquee.crmPromotionId) {
            return promotions.filter((p) => p.id === marquee.crmPromotionId) || [];
        }

        return [];
    }

    private filterTilesResult(tiles: MarqueeTile[]): MarqueeTile[] {
        return tiles.filter((t) => this.filterDisabledSportsMarquee(t.sportId, t.marqueeType)).slice(0, this.marqueeConfig.maxRequestedEvents);
    }

    private filterForFixture(fixtureId: string): Observable<MarqueeTile[]> {
        return this.marqueeTilesConfig.whenReady.pipe(
            map(() =>
                this.marqueeTilesConfig.tiles.filter(
                    (t) =>
                        t.showOnEventPage &&
                        t.eventIds?.some((fid) => fid === fixtureId) &&
                        this.filterDisabledSportsMarquee(t.sportId, t.marqueeType),
                ),
            ),
        );
    }

    private filterForTeamPagesFixture(fixtureIds: string[]): MarqueeTile[] {
        return this.marqueeTilesConfig.tiles.filter(
            (t) => fixtureIds.some((fixtureId) => fixtureId === t.fixtureId) && this.filterDisabledSportsMarquee(t.sportId, t.marqueeType),
        );
    }

    private filterForPriceBoostFixture(): MarqueeTile[] {
        return this.marqueeTilesConfig.tiles.filter((t) => this.filterDisabledSportsMarquee(t.sportId, t.marqueeType) && t.isPriceBoostMarquee);
    }

    private filterDisabledSportsMarquee(sportId: number, marqueeType: MarqueeType): boolean {
        return this.marqueeConfig.disabledSportIds.indexOf(sportId) < 0 || marqueeType === MarqueeType.Special;
    }
}
