"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var subscription_1 = require("./subscription");
var utils_1 = require("./utils");
var SubscriptionStore = /** @class */function () {
  function SubscriptionStore() {
    this.subscriptions = {};
  }
  SubscriptionStore.prototype.add = function (topic) {
    var subscription = this.subscriptions[topic];
    if (!this.subscriptions[topic]) {
      subscription = new subscription_1.default(topic);
      this.subscriptions[subscription.topic] = subscription;
    }
    subscription.increment();
    return subscription;
  };
  SubscriptionStore.prototype.remove = function (topic) {
    var subscription = this.subscriptions[topic];
    if (subscription) {
      subscription.decrement();
      if (subscription.isEmpty()) {
        delete this.subscriptions[subscription.topic];
      }
    }
    return subscription;
  };
  SubscriptionStore.prototype.getAll = function () {
    return utils_1.Utils.values(this.subscriptions);
  };
  SubscriptionStore.prototype.clear = function () {
    this.subscriptions = {};
  };
  return SubscriptionStore;
}();
exports.default = SubscriptionStore;
