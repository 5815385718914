import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { takeUntil } from 'rxjs/operators';

import { UserService } from '../user/services/user.service';
import { IncrementalStakesService } from './incremental-stakes.service';
import { NumpadUtilsService } from './numpad-utils.service';
import { NumpadService } from './numpad.service';

interface StakeModel {
    value: number;
    title: string;
}

@HooksWireup()
@Component({
    selector: 'ms-incremental-stakes',
    templateUrl: './incremental-stakes.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncrementalStakesComponent extends OnDestroyCleanup implements OnInit {
    vm: { increaseStakes: StakeModel[] };

    constructor(
        private incrementalStakesService: IncrementalStakesService,
        private numpadService: NumpadService,
        private utils: NumpadUtilsService,
        private userService: UserService,
        private changeDetector: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit(): void {
        this.updateVm();

        this.userService.onUserLogin$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.updateVm());
    }

    private updateVm(): void {
        this.vm = { increaseStakes: this.incrementalStakesService.getValues().map((v) => this.mapValue(v)) };
        this.changeDetector.markForCheck();
    }

    addToStake(stakeToAdd: number): void {
        this.numpadService.increment(stakeToAdd);
    }

    private mapValue(value: number): StakeModel {
        return {
            value,
            title: `+${this.utils.toFormattedValue(String(value))}`,
        };
    }
}
