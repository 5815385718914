import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { maxBy } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';

import { CharsetSizeService } from '../common/charset-size.service';
import { ColumnLayoutService } from '../layout/column-layout.service';

@HooksWireup()
@Directive({
    selector: '[msTabBarFontResize]',
})
export class TabBarFontResizeDirective extends OnDestroyCleanup implements AfterViewInit {
    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
        private columnlayoutService: ColumnLayoutService,
        private charsetSizeService: CharsetSizeService,
    ) {
        super();
    }

    @Input() tabBarDefaultFontSize: number;
    @Input() tabBarDefaultStringLength: number;

    private charSize: number;
    private fontSizeOffset = 1.5;

    ngAfterViewInit(): void {
        const container = this.element.nativeElement.querySelector('.tab-bar-container');
        if (!container) {
            throw new Error('Could not find .tab-bar-container in ms-tab-bar');
        }
        this.charSize = this.charsetSizeService.getAverageSize();
        this.columnlayoutService.rightColumnSizeChanged.pipe(takeUntil(this.destroyed$)).subscribe(() => this.setFontSize());
    }

    setFontSize(): void {
        const titleElements = this.element.nativeElement.querySelectorAll('.title');
        let newFontSize = this.tabBarDefaultFontSize;

        if (!titleElements.length) {
            return;
        }

        const defaultWidth = this.tabBarDefaultStringLength * this.charSize;
        const longestElement = maxBy(titleElements, (el: HTMLElement) => el.innerHTML.length)!;
        const maxWidth = longestElement.innerHTML.length * this.charSize;

        if (maxWidth <= defaultWidth) {
            return;
        }

        const container = this.element.nativeElement.querySelector('.tab-bar-container');
        const containerWidth = container.offsetWidth;
        const defaultRatio = defaultWidth / containerWidth / titleElements.length;
        const currentRatio = maxWidth / containerWidth / titleElements.length;
        newFontSize = (1 - (currentRatio - defaultRatio)) * this.tabBarDefaultFontSize - this.fontSizeOffset;
        newFontSize = Math.min(newFontSize, this.tabBarDefaultFontSize);
        const finalFontSize = newFontSize.toFixed(2) + 'px';
        for (const titleEl of titleElements) {
            this.renderer.setStyle(titleEl, 'font-size', finalFontSize);
        }
    }
}
