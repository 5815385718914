import { Inject, Injectable } from '@angular/core';

import { ParticipantImage } from '@cds/betting-offer';
import { MasterDataInfoResponse } from '@cds/betting-offer/domain-specific';
import { MasterDataInfoRequest } from '@cds/query-objects';
import { ExpiringCache, NumberDictionary, StringDictionary } from '@frontend/sports/common/base-utils';
import { CompetitionDetail } from '@frontend/sports/types/components/team-pages';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExpiringCacheFactory } from '../common/expiring-cache.factory';
import { BaseCdsApi } from './cds-api.service';
import { BaseCdsApiFactory, CDS_API_FACTORY } from './cds-api.service.factory';

@Injectable({ providedIn: 'root' })
export class MasterdataApiService {
    private teamsByComepetitionCache: ExpiringCache;
    private cdsApi: BaseCdsApi;

    constructor(@Inject(CDS_API_FACTORY) cdsApiServiceFactory: BaseCdsApiFactory, cacheFactory: ExpiringCacheFactory) {
        this.cdsApi = cdsApiServiceFactory({ endpoint: '/masterdata' });
        this.teamsByComepetitionCache = cacheFactory.create(600);
    }

    getCompetitionsAndTeamsCount(competitions: CompetitionDetail[]): Observable<CompetitionDetail[]> {
        return this.getCompetitionCount(competitions).pipe(
            map((response) => {
                if (!response) {
                    return [];
                }

                return competitions.filter((c) => response[c.competitionId]);
            }),
        );
    }

    getCompetitionCount(competitions: CompetitionDetail[]): Observable<StringDictionary<number>[] | undefined> {
        const compIds = competitions.map((s) => s.competitionId).join(',');
        const source = this.cdsApi.get<StringDictionary<number>[]>('/competitions/participants/counts/v2', { competitionIds: compIds });

        return this.teamsByComepetitionCache.getOrCreate(`team-pages-competitions-${compIds}`, source);
    }

    getImagesInfo(sportId: number, participantIds: number[]): Observable<NumberDictionary<ParticipantImage>[] | undefined> {
        const participants = participantIds.map((s) => s).join(',');
        const source = this.cdsApi.get<NumberDictionary<ParticipantImage>[]>('/info/participants', { sportId, participantIds: participants });

        return this.teamsByComepetitionCache.getOrCreate(`team-pages-participants-${participantIds}`, source);
    }

    getVirtualCompetitionInfo(request: MasterDataInfoRequest): Observable<MasterDataInfoResponse | undefined> {
        return this.cdsApi.get<MasterDataInfoResponse>('/info/virtual-competition', request);
    }
}
