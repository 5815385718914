import { Injectable } from '@angular/core';

import { NativeAppService, WindowRef } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class MobileHelper {
    private nativeWindow: Window;

    readonly isAndroidNative: boolean;
    readonly isSafariIos: boolean;
    readonly isAndroidWrapper: boolean;

    constructor(
        window: WindowRef,
        private nativeAppService: NativeAppService,
    ) {
        this.nativeWindow = window.nativeWindow;

        this.isAndroidNative = this.isAndroidNativeInternal();
        this.isSafariIos = this.isSafariIosInternal();
        this.isAndroidWrapper = this.isAndroidNative && this.nativeAppService.isNativeWrapper;
    }

    private isAndroidNativeInternal(): boolean {
        const ua = this.nativeWindow.navigator.userAgent;
        if (!ua) {
            return false;
        }

        return this.valueContainsAll(ua, ['Mozilla/5.0', 'Android ', 'AppleWebKit']) && this.valueContainsAny(ua, ['Version', 'Samsung']);
    }

    private isSafariIosInternal(): boolean {
        const ua = this.nativeWindow.navigator.userAgent;
        if (!ua) {
            return false;
        }

        return this.valueContainsAll(ua, ['(iPhone)', 'Safari', 'iPhone']) && !/CriOS/g.test(ua);
    }

    private valueContainsAll(text: string, valuesToCheck: string[]): boolean {
        return valuesToCheck.every((v) => text.indexOf(v) > -1);
    }

    private valueContainsAny(text: string, valuesToCheck: string[]): boolean {
        return valuesToCheck.some((v) => text.indexOf(v) > -1);
    }
}
