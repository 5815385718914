import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { DetailedGrouping, DisplayType, Game, OptionMarket } from '@cds/betting-offer';
import { isEmpty } from 'lodash-es';

export abstract class MarketDisplayFallbackService {
    abstract shouldForceRegularDisplayTypeTV1(detailedGrouping: DetailedGrouping, game: Game): boolean;
    abstract shouldForceRegularDisplayTypeTV2(detailedGrouping: DetailedGrouping, optionMarket: OptionMarket): boolean;
}

@Injectable({
    providedIn: 'root',
})
export class SportsMarketGroupingFallbackService extends MarketDisplayFallbackService {
    // For few displaytypes we need mandatory conditions to be met. If those conditions are not met we are converting these display types to regular.
    // To avoid the breakage of UI we are converting the new display types to regular type.
    shouldForceRegularDisplayTypeTV1(detailedGrouping: DetailedGrouping, game: Game): boolean {
        switch (detailedGrouping.displayType) {
            case DisplayType.YesNo:
                return isEmpty(game.player1);
            default:
                return false;
        }
    }

    shouldForceRegularDisplayTypeTV2(detailedGrouping: DetailedGrouping, optionMarket: OptionMarket): boolean {
        switch (detailedGrouping.displayType) {
            case DisplayType.YesNo:
                return optionMarket.source === OfferSource.V1 ? isEmpty(optionMarket.player1) : !optionMarket.fixtureParticipantId;
            default:
                return false;
        }
    }
}
