import { decimalOddsToAmerican } from '@frontend/sports/common/odds-lib';
import { Decimal } from 'decimal.js';

import { Fraction } from './fraction';
import type { CalculatedOdds, Odds } from './odds';
import { OddsDisplayFormat } from './odds-display-format';

/**
 * There are 3 main types of odds
 * 1. Decimals or European odds - a win based Odds * Stake = Total Possible payout
 * 2. Fractional or UK odds - a profit based Odds * Stake = Possible Profit ( Possible payout - Stake )
 * 3. Moneyline or US odds - a money based odds. Can have possitive or negative values.
 *      When positive defines how much money will be the profit if the stake is 100.
 *      When negative defines what should be the stake to have 100 profit
 */
export class OddsConverter {
    /**
     * Converting decimal to fration odds becomes by extracting 1 ( remove stake from the win) and convert to fraction
     *
     * @param decimals Decimal odds
     */
    static decimalToFraction(decimals: Decimal): Fraction {
        return Fraction.toFraction(decimals.minus(1)).simplify();
    }

    /**
     * Convert decimal to US by removing 1 ( to get the profit ) and if the result is:
     * - Greater or equal than 1 then multiply by 100
     * - Less than 1 then -100 / value
     *
     * @param decimals
     * @param precision
     */
    static decimalToUS(decimals: Decimal, precision: number = 10): Decimal {
        return decimalOddsToAmerican(decimals.toDecimalPlaces(precision));
    }

    /**
     * Convert fractional odds to decimal is by adding 1 ( add the stake ) and convert to decimal value
     *
     * @param fractionOdds
     */
    static fractionToDecimal(fractionOdds: Fraction): Decimal {
        return fractionOdds.add(1).getValue();
    }

    /**
     * Convert fractional to moneyline:
     * When fractional greater or equal to 1: Multiply by 100 and convert to decimals
     * When fractional less than 1 then we divide -100 to the fraction and convert to decimals
     *
     * @param fractionOdds
     * @param precision
     */
    static fractionToUS(fractionOdds: Fraction, precision: number = 2): Decimal {
        const us = fractionOdds.greaterOrEqualThan(1)
            ? fractionOdds.multiply(100).getValue()
            : new Fraction(100, 1).divide(fractionOdds).multiply(-1).getValue();

        return us.toDecimalPlaces(precision);
    }

    static usToDecimal(usOdds: Decimal, precision: number = 2): Decimal {
        return usOdds.lessThan(0)
            ? new Decimal(1).minus(new Decimal(100).dividedBy(usOdds).toDecimalPlaces(precision))
            : usOdds.dividedBy(100).add(1);
    }

    static usToFraction(usOdds: Decimal, precision: number = 2): Fraction {
        return usOdds.lessThan(0)
            ? Fraction.toFraction(new Decimal(-100).dividedBy(usOdds).toDecimalPlaces(precision))
            : Fraction.toFraction(usOdds.dividedBy(100));
    }

    /**
     * Convert decimals odds to each way odds the result odds are win based in a fraction form
     *
     * @param decimals Decimal odds
     * @param eachWay Place terms
     */
    static decimalToEachWayWin(decimals: Decimal, eachWay: Fraction): Fraction {
        return OddsConverter.decimalToFraction(decimals).multiply(eachWay).add(1).simplify();
    }

    /**
     * Convert fractional odds to win based in fraction form
     *
     * @param fraction
     * @param eachWay
     */
    static fractionToEachWayWin(fraction: Fraction, eachWay: Fraction): Fraction {
        return fraction.multiply(eachWay).add(1).simplify();
    }

    /**
     * Convert US odds to win based to Fraction form
     *
     * @param us
     * @param eachWay
     */
    static usToEachWayWin(us: Decimal, eachWay: Fraction): Fraction {
        return OddsConverter.usToFraction(us).multiply(eachWay).add(1).simplify();
    }

    /**
     * Convert decimal odds to the decimal value of the EW
     *
     * @param decimals
     * @param eachWay
     */
    static decimalToEachWay(decimals: Decimal, eachWay: Fraction): Decimal {
        return OddsConverter.decimalToFraction(decimals).multiply(eachWay).add(1).getValue();
    }

    /**
     * Convert decimal odds to the fractional value of the EW
     *
     * @param fracion
     * @param eachWay
     */
    static fractionToEachWay(fraction: Fraction, eachWay: Fraction): Fraction {
        return fraction.multiply(eachWay).simplify();
    }

    /**
     * Convert us odds to US value of each way
     */
    static usToEachWay(us: Decimal, eachWay: Fraction): Decimal {
        const fraction = OddsConverter.usToFraction(us, 7).multiply(eachWay).simplify();

        return OddsConverter.fractionToUS(fraction);
    }

    static convertOdds(
        targetFormat: OddsDisplayFormat,
        sourceFormat: OddsDisplayFormat,
        odds: Odds | CalculatedOdds,
    ): Partial<Odds> | Partial<CalculatedOdds> {
        if (targetFormat === sourceFormat) {
            return odds;
        }
        switch (targetFormat) {
            case OddsDisplayFormat.EU: {
                if (sourceFormat === OddsDisplayFormat.UK) {
                    return {
                        decimals: OddsConverter.fractionToDecimal(Fraction.create(odds.fractional)).toNumber(),
                    };
                }
                if (sourceFormat === OddsDisplayFormat.US) {
                    return {
                        decimals: OddsConverter.usToDecimal(new Decimal(odds.moneyline)).toNumber(),
                    };
                }
                break;
            }
            case OddsDisplayFormat.UK: {
                if (sourceFormat === OddsDisplayFormat.EU) {
                    return {
                        fractional: OddsConverter.decimalToFraction(new Decimal(odds.decimals)).simplify().toJSON(),
                    };
                }
                if (sourceFormat === OddsDisplayFormat.US) {
                    return {
                        fractional: OddsConverter.usToFraction(new Decimal(odds.moneyline)).simplify().toJSON(),
                    };
                }
                break;
            }
            case OddsDisplayFormat.US: {
                if (sourceFormat === OddsDisplayFormat.EU) {
                    return {
                        moneyline: OddsConverter.decimalToUS(new Decimal(odds.decimals)).toNumber(),
                    };
                }
                if (sourceFormat === OddsDisplayFormat.UK) {
                    return {
                        moneyline: OddsConverter.fractionToUS(Fraction.create(odds.fractional)).toNumber(),
                    };
                }
            }
        }
        throw new Error('Invalid odds formats');
    }
}
