import {
    Participant,
    ParticipantMarketStatus,
    ParticipantOption,
    ParticipantPriceStatus,
    ParticipantPriceType,
    ParticipantStatus,
} from '@cds/betting-offer';

import { emptyOdds } from '../odds/odds';
import { OddsOperations } from '../odds/operations';
import { participantOptionPriceToBetslipPrice } from '../price';
import { BetslipPick } from './betslip-pick';
import { BetslipV2Pick } from './betslip-v2-pick';
import { V2ParticipantPickId } from './pick-id';
import { IBetslipV2ParticipantPickStorage, IBetslipV2PickParticipant, IBetslipV2PickParticipantMarket, IPrice } from './pick-models';
import { SignedName } from './signed-name.model';

/**
 * General Participant pick
 */
export abstract class BetslipV2ParticipantPick extends BetslipV2Pick {
    override id: V2ParticipantPickId;
    override market: IBetslipV2PickParticipantMarket;
    participants: IBetslipV2PickParticipant[];
    betType: string;
    betTypeAltTranslation: string;

    static override isPick(pick: BetslipPick): pick is BetslipV2ParticipantPick {
        return pick instanceof BetslipV2ParticipantPick;
    }

    protected constructor() {
        super();
    }

    protected override initPropertiesFromJSON(value: IBetslipV2ParticipantPickStorage): void {
        super.initPropertiesFromJSON(value);
        this.market = value.market;
        this.participants = value.participants.map((p) => ({
            ...p,
            prices: p.prices.map((pr) => ({ ...pr })),
        }));
        this.betType = value.betType;
        this.betTypeAltTranslation = value.betTypeAltTranslation;
    }

    protected updateFromPrice(thisPrice: IPrice, fixtureOption?: ParticipantOption): void {
        if (!fixtureOption) {
            this.priceHistory.push({ ...thisPrice });
            thisPrice.isVisible = false;
        } else {
            const newPrice = {
                isVisible:
                    fixtureOption.marketStatus === ParticipantMarketStatus.Visible &&
                    fixtureOption.participantPriceStatus === ParticipantPriceStatus.Visible,
                nativeOdds: emptyOdds,
            };
            if (fixtureOption.priceType === ParticipantPriceType.Fixed) {
                // Set odds only when priceType is fixed.
                newPrice.nativeOdds = OddsOperations.createOdds(fixtureOption.price);
                newPrice.isVisible = newPrice.isVisible && OddsOperations.isOddsValid(newPrice.nativeOdds);
            }
            if (!OddsOperations.equal(newPrice.nativeOdds, thisPrice.nativeOdds) || thisPrice.isVisible !== newPrice.isVisible) {
                this.priceHistory.push({ ...thisPrice });
            }
            thisPrice.isVisible = newPrice.isVisible;
            thisPrice.nativeOdds = newPrice.nativeOdds;
        }
    }

    protected updateFromParticipant(thisParticipant: IBetslipV2PickParticipant, fixtureParticipant: Participant): void {
        thisParticipant.status = fixtureParticipant.status || ParticipantStatus.NotDefined;

        const participantOptions = fixtureParticipant.options.filter((o) => o.marketId === this.market.id);
        this.updateFromParticipantOptions(participantOptions);

        for (const price of thisParticipant.prices) {
            const fixtureOption = participantOptions.find((o) => o.price.id === price.id);
            this.updateFromPrice(price, fixtureOption);
        }
        // prices to add if any new for current market id.
        const missingOptions = participantOptions.filter((o) => thisParticipant.prices.every((p) => p.id !== o.price.id));
        for (const option of missingOptions) {
            this.addPriceFromOption(thisParticipant, option);
        }
    }

    protected addPriceFromOption(participant: IBetslipV2PickParticipant, option: ParticipantOption): void {
        const price = participantOptionPriceToBetslipPrice(option);
        participant.prices.push(price);
    }

    protected updateFromParticipantOptions(options: ParticipantOption[]): void {
        const currentMarketOptions = options.filter((o) => o.marketType === this.market.marketType);
        this.market.isVisible =
            !!currentMarketOptions.length && currentMarketOptions.every((o) => o.marketStatus === ParticipantMarketStatus.Visible);
    }

    override isPriceVisible(): boolean {
        return !this.participants.some((p) => p.status === ParticipantStatus.Hidden) && !!this.currentPrice && this.currentPrice.isVisible;
    }

    override isMarketVisible(): boolean {
        return this.market.isVisible;
    }

    getPrices(): IPrice[] {
        const prices: IPrice[] = [];
        for (const participant of this.participants) {
            const price = participant.prices.find((pr) => pr.type === this.priceType);
            if (price) {
                prices.push(price);
            }
        }

        return prices;
    }

    /*	updateFromMessage(message: MessageEnvelope): void {
		if (
			isParticipantUpdateEnvelope(message) &&
			message.payload.fixtureId === this.fixture.fixtureId &&
			this.participants.some(p => p.fixtureParticipantId === message.payload.participant.id)
		) {
			const command = message.payload;
			const pickParticipant = this.participants.find(p => p.fixtureParticipantId === message.payload.participant.id)!;
			this.updateFromParticipant(pickParticipant, command.participant);
		}
	}*/

    get isLive(): boolean {
        return false;
    }

    override toJSON(): IBetslipV2ParticipantPickStorage {
        const base = super.toJSON();

        return {
            ...base,
            id: this.id.toString(),
            market: {
                id: this.market.id,
                isVisible: this.market.isVisible,
                marketType: this.market.marketType,
                isBetBuilderEnabled: false,
            },
            participants: this.participants.map((p) => ({
                ...p,
            })),
            betType: this.betType,
            betTypeAltTranslation: this.betTypeAltTranslation,
        };
    }

    get optionName(): SignedName {
        const optionsName = this.participants
            .sort((a, b) => a.position - b.position)
            .map((p) => p.name.name)
            .join(', ');

        return {
            name: optionsName,
            signature: '',
        };
    }

    get prices(): IPrice[] {
        return this.participants.flatMap((p) => p.prices.filter((pr) => pr.marketId === this.market.id));
    }

    abstract override copy(): BetslipV2ParticipantPick;
}
