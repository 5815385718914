import { Injectable } from '@angular/core';

/**
 * @description
 * A wrapper for `window` object to make it indexable so properties can be accessed using brackets notation without typescript error.
 */
export interface CustomWindow extends Window {
    [key: string]: any;
}

function getWindow(): CustomWindow {
    return window;
}

export enum WindowEvent {
    AnimationEnd = 'animationend',
    BeforeUnload = 'beforeunload',
    Blur = 'blur',
    Change = 'change',
    Click = 'click',
    Error = 'error',
    Focus = 'focus',
    GtmLoad = 'gtm.load',
    Load = 'load',
    Message = 'message',
    Offline = 'offline',
    Online = 'online',
    PageHide = 'pagehide',
    ReadyStateChange = 'readystatechange',
    Scroll = 'scroll',
    SocialCookieDropped = 'socialcookiedropped',
    Storage = 'storage',
    TouchMove = 'touchmove',
    TouchStart = 'touchstart',
    TransitionEnd = 'transitionend',
    VanillaGtmLoaded = 'vanilla_gtm_loaded',
    VisibilityChange = 'visibilitychange',
}

/**
 * @whatItDoes Wraps browser `window`.
 *
 * @howToUse
 *
 * ```
 * // logs current url
 * ```
 *
 * @description
 *
 * This service functions similarly as `$window` in AngularJS. Allows for easy mocking of `window`.
 *
 * @stable
 */
@Injectable({
    providedIn: 'root',
})
export class WindowRef {
    get nativeWindow(): CustomWindow {
        return getWindow();
    }
}
