import { Injectable } from '@angular/core';

import { CountItem, ItemFilter, ItemType } from '@frontend/sports/common/base-utils';
import { cloneDeep, isArray, keys, some, sumBy } from 'lodash-es';

import { getItemTypeForSport } from '../../../competition-list/competition-list.models';
import { CompetitionRoute } from '../../../navigation/navigation.models';

@Injectable({ providedIn: 'root' })
export class PruneHelperService {
    /**
     * Returns a tree of items traversed recursively and applied filters on each level. All items
     * and its children with 0 counts are removed from the tree
     *
     * @param items list of source items
     * @param filters list of filters (item type and item id) to be applied on the collection
     */
    prune(sport: CountItem | undefined, route: CompetitionRoute): CountItem | undefined {
        if (!sport) {
            return;
        }

        const filters = [{ type: ItemType.Sport, id: sport.id }];

        if (route.league) {
            const leagues = isArray(route.league) ? route.league : [route.league];
            leagues.forEach((league) => filters.push({ type: ItemType.Competition, id: league }));
        }

        if (route.virtualCompetitionGroup) {
            filters.push({
                type: ItemType.VirtualCompetitionGroup,
                id: route.virtualCompetitionGroup,
            });
        }

        if (route.conference) {
            filters.push({
                type: ItemType.Conference,
                id: route.conference,
            });
        }

        if (route.region) {
            filters.push({
                type: getItemTypeForSport(sport.id),
                id: route.region,
            });
        }

        const clonedSport = [cloneDeep(sport)];

        return this.pruneRecursively(clonedSport, filters).pop();
    }

    private pruneRecursively(items: CountItem[], filters: ItemFilter[]): CountItem[] {
        if (!items.length) {
            return items;
        }

        const typeFilters = filters.filter((current) => current.type === items[0].type);

        if (typeFilters.length) {
            items = items.filter((item) => typeFilters.some((filter) => item.id === filter.id));
        }

        return items
            .map((currentItem) => {
                if (!currentItem.children.length) {
                    return currentItem;
                }

                currentItem.children = this.pruneRecursively(currentItem.children, filters);
                // if there are no VirtualCompetitionGroup filters, then for VirtualCompetition items there is no need
                // to sum up the count of VirtualCompetitionGroups because we get the count already calculated from backend
                if (filters.some((filter) => filter.type === ItemType.VirtualCompetitionGroup) || !this.isVirtualCompetition(currentItem)) {
                    keys(currentItem.counts).forEach((key) => {
                        currentItem.counts[key] = sumBy(currentItem.children, (child) => child.counts[key] || 0);
                    });
                    keys(currentItem.meta).forEach((key) => {
                        currentItem.meta[key] = !!currentItem.meta[key] || some(currentItem.children, (child) => child.meta[key]);
                    });
                }

                return currentItem;
            })
            .filter((item) => item.counts.preMatch + item.counts.live);
    }

    private isVirtualCompetition(countItem: CountItem): boolean {
        return countItem.children.length > 0 && countItem.children.every((child) => child.type === ItemType.VirtualCompetitionGroup);
    }
}
