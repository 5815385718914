export interface Category {
    id: string;
    groupId?: number;
    idType: Type;
}

export class Subscriptions {
    [eventId: number]: Subscription;
}

export class Subscription {
    action: Action;
    sportId: number;
    event: Category;
    league: Category;
    scores: number[];
    tracking?: string;
}

export interface NativeAlertsResponse {
    subscription: Subscription[];
}

export interface AlertEvent {
    sportId: number;
    eventId: string;
    leagueId: number;
    eventGroupId?: number;
    leagueGroupId?: number;
    title: string;
    live: boolean;
    selected?: boolean;
}

export interface EventViewModel {
    subscription: Subscription;
    title: string;
}

export interface AlertViewModel {
    info: AlertMessage;
    isSelected?: boolean;
}

export interface AlertMessage {
    ids: number[];
    key: string;
    name: string;
    isTop: boolean;
}

export enum Action {
    Subscribe = 0,
    Unsubscribe = 1,
    Exclude = 2,
}

export enum Type {
    NonLive = 0,
    Live,
}

export enum Source {
    Mybets = 'mybets',
    Mybet = 'mybet',
    Betslip = 'betslipconfirmation',
    Event = 'event',
    Scoreboard = 'scoreboard',
}

export interface AlertPreferences {
    [sportId: number]: number[];
}

export enum NativeEventType {
    LiveAlertScreenNavigation = 'LiveAlertScreenNavigation',
    BetScreenNavigation = 'BetScreenNavigation',
    OpenFreeBets = 'OpenFreeBets',
    MyBetsNavigation = 'MyBetsNavigation',
    IsNotificationEnabled = 'isNotificationEnabled',
}

export enum Mode {
    Single,
    Multi,
}

export enum Page {
    List,
    Detail,
}

export enum NotificationEnabled {
    Yes = 'Yes',
    No = 'No',
}
