import { InjectionToken } from '@angular/core';

import { ConnectionConfig, CustomerDnaBettingOfferConfig } from '@frontend/sports/common/client-config-data-access';

import { CustomerDnaProfileProviderService } from '../customer-dna/customer-dna-profile-provider.service';
import { ApiService } from '../http/api.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { ApiBaseSettingsProvider } from './api-settings.service';
import { BaseCdsApi, CdsApiOptions } from './cds-api.service';

export type BaseCdsApiFactory = (options: CdsApiOptions) => BaseCdsApi;

const cdsFactory =
    (
        apiService: ApiService,
        loggerFactory: LoggerFactory,
        connectionConfig: ConnectionConfig,
        apiSettings: ApiBaseSettingsProvider,
        customerDnaProfileService: CustomerDnaProfileProviderService,
        customerDnaBettingOfferConfig: CustomerDnaBettingOfferConfig,
    ): BaseCdsApiFactory =>
    (options: CdsApiOptions) =>
        new BaseCdsApi(apiService, loggerFactory, connectionConfig, apiSettings, customerDnaProfileService, customerDnaBettingOfferConfig).configure(
            options,
        );

export const CDS_API_FACTORY = new InjectionToken<BaseCdsApiFactory>('CDS_API_FACTORY');

export const CDS_API_PROVIDER = {
    provide: CDS_API_FACTORY,
    useFactory: cdsFactory,
    deps: [ApiService, LoggerFactory, ConnectionConfig, ApiBaseSettingsProvider, CustomerDnaProfileProviderService, CustomerDnaBettingOfferConfig],
};
