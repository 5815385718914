import { ComponentType, OverlayContainer } from '@angular/cdk/overlay';
import { ApplicationRef, ComponentRef, EnvironmentInjector, Injectable, Injector, StaticProvider, createComponent } from '@angular/core';

import { TimerService } from '../../common/timer.service';
import { ModalOptions } from './modal';
import { ModalBackdropComponent } from './modal-backdrop.component';
import { ActiveModal, ContentRef, ModalRef } from './modal-ref';
import { ModalWindowComponent } from './modal-window.component';

@Injectable({ providedIn: 'root' })
export class ModalStack {
    private attributes: Extract<keyof ModalOptions, string>[] = [
        'ariaLabelledBy',
        'backdrop',
        'centered',
        'keyboard',
        'size',
        'windowClass',
        'showDialogHeader',
        'title',
        'hideCloseButton',
        'epcotShowBalance',
        'headerTemplate',
        'showCloseBtnText',
        'showBackNav',
        'closePrevious',
    ];

    private backdropAttributes = ['backdropClass'];

    constructor(
        private timerService: TimerService,
        private applicationRef: ApplicationRef,
        private injector: EnvironmentInjector,
        private containerService: OverlayContainer,
    ) {}

    open(contentInjector: Injector, content: ComponentType<unknown>, options: ModalOptions): ModalRef {
        const containerEl = options.container || this.containerService.getContainerElement();

        if (!containerEl) {
            throw new Error(`The specified modal container "${options.container || 'body'}" was not found in the DOM.`);
        }

        if (options.centerVerticalToViewport) {
            options.windowClass += ' modal-vertical-align';
        }

        const activeModal = new ActiveModal();
        const contentRef = this._getContentRef(options.injector || contentInjector, content, activeModal);

        const backdropCmptRef = options.backdrop !== false ? this._attachBackdrop(containerEl) : undefined;
        const windowCmptRef = this._attachWindowComponent(containerEl, contentRef);
        const modalRef = new ModalRef(windowCmptRef, contentRef, backdropCmptRef, options.beforeDismiss);

        activeModal.close = (result: any) => {
            if (!options.closeAnimation) {
                modalRef.close(result);

                return;
            }
            if (options.closeAnimation && backdropCmptRef?.instance?.class?.includes('close-animation')) {
                modalRef.addBackdropClass('hide-backdrop');
            }
            windowCmptRef.instance.windowClass += ` ${options.closeAnimation}`;
            this.timerService.setTimeout(() => {
                modalRef.close(result);
            }, 350);
        };
        activeModal.dismiss = (reason: any) => {
            modalRef.dismiss(reason);
        };

        this._applyWindowOptions(windowCmptRef.instance, options);
        if (options.container && options.container === containerEl && options.centerVerticalToViewport) {
            this._applyVerticalCentering(windowCmptRef, containerEl);
        }

        if (backdropCmptRef && backdropCmptRef.instance) {
            this._applyBackdropOptions(backdropCmptRef.instance, options);
        }

        return modalRef;
    }

    private _attachBackdrop(containerEl: HTMLElement): ComponentRef<ModalBackdropComponent> {
        const backdropCmptRef = createComponent(ModalBackdropComponent, { environmentInjector: this.injector });
        this.applicationRef.attachView(backdropCmptRef.hostView);
        containerEl.appendChild(backdropCmptRef.location.nativeElement);

        return backdropCmptRef;
    }

    private _attachWindowComponent(containerEl: HTMLElement, contentRef: ContentRef): ComponentRef<ModalWindowComponent> {
        const windowCmptRef = createComponent(ModalWindowComponent, { environmentInjector: this.injector, projectableNodes: contentRef.nodes });
        this.applicationRef.attachView(windowCmptRef.hostView);
        containerEl.appendChild(windowCmptRef.location.nativeElement);

        return windowCmptRef;
    }

    private _applyWindowOptions(windowInstance: ModalWindowComponent, options: ModalOptions): void {
        this.attributes.forEach((optionName: string) => {
            if (options.hasOwnProperty(optionName)) {
                windowInstance[optionName] = options[optionName];
            }
        });
    }

    private _applyVerticalCentering(windowCmptRef: ComponentRef<ModalWindowComponent>, containerEl: HTMLElement): void {
        const domEl = windowCmptRef.location.nativeElement as HTMLElement;
        const windowHeight = window.innerHeight;

        let recalculateTimes = 0; // the modal content can be async so let's try a few times to position the modal.

        const repositionModal = () => {
            const containerTopOffset = containerEl.getBoundingClientRect().top;
            const newHeight = domEl.clientHeight;
            const newTop = (windowHeight - containerTopOffset) / 2 - newHeight / 2;
            domEl.style.top = `${newTop}px`;
            recalculateTimes++;

            if (recalculateTimes >= 3 || newHeight >= 100) {
                // if the height is greater than 100 most likely the content is loaded
                return;
            }

            setTimeout(repositionModal, recalculateTimes * 10);
        };

        setTimeout(repositionModal, recalculateTimes * 10);
    }

    private _applyBackdropOptions(backdropInstance: ModalBackdropComponent, options: ModalOptions): void {
        this.backdropAttributes.forEach((optionName: string) => {
            if (options[optionName]) {
                backdropInstance[optionName] = options[optionName];
            }
        });
    }

    private _getContentRef(contentInjector: Injector, content: ComponentType<unknown>, context: ActiveModal): ContentRef {
        return this._createFromComponent(contentInjector, content, context);
    }

    private _createFromComponent(contentInjector: Injector, content: ComponentType<unknown>, context: ActiveModal): ContentRef {
        const providers: StaticProvider[] = [{ provide: ActiveModal, useValue: context }];

        const modalContentInjector = Injector.create({
            providers,
            parent: contentInjector,
        });

        const componentRef = createComponent(content, { environmentInjector: this.injector, elementInjector: modalContentInjector });
        this.applicationRef.attachView(componentRef.hostView);

        return new ContentRef([[componentRef.location.nativeElement]], componentRef.hostView, componentRef);
    }
}
