import { Decimal } from 'decimal.js';

export class Fraction {
    private static numberEps = 0.0000001;
    numerator: Decimal;
    denominator: Decimal;
    isSimplified = false;

    constructor(n: number | string | Decimal, d: number | string | Decimal = 1, isSimplified: boolean = false) {
        this.numerator = new Decimal(n);
        this.denominator = new Decimal(d);
        this.isSimplified = this.denominator.equals(1) || isSimplified;
    }

    static create(val: { numerator: number; denominator: number } | Fraction): Fraction {
        if (val instanceof Fraction) {
            return val.copy();
        }

        return Fraction.fromJSON(val);
    }

    static fromJSON(val: { numerator: number; denominator: number }): Fraction {
        return new Fraction(val.numerator, val.denominator);
    }

    static toCommonDenominator(
        first: Fraction,
        second: Fraction,
    ): {
        firstNumerator: Decimal;
        secondNumerator: Decimal;
        denominator: Decimal;
    } {
        if (first.denominator.equals(second.denominator)) {
            return {
                firstNumerator: first.numerator,
                secondNumerator: second.numerator,
                denominator: first.denominator,
            };
        } else {
            const firstNumerator = first.numerator.times(second.denominator);
            const secondNumerator = second.numerator.times(first.denominator);
            const resultDenominator = first.denominator.times(second.denominator);

            return {
                firstNumerator,
                secondNumerator,
                denominator: resultDenominator,
            };
        }
    }

    private static isNumber(value: Fraction | Decimal | number): value is number {
        return typeof value === 'number';
    }

    private static toInt(value: number): number {
        if (Math.ceil(value) - value < Fraction.numberEps) {
            // When value is like 1.99999999 => 2
            // 0.2+0.7+0.1 = 0.99999999 => 1
            return Math.ceil(value);
        }
        if (value - Math.floor(value) < Fraction.numberEps) {
            // When value is like 2.00000001 => 2
            return Math.floor(value);
        }

        return NaN;
    }

    private static numberToFraction(value: number): Fraction {
        const intVal = Fraction.toInt(value);
        if (!isNaN(intVal)) {
            return new Fraction(intVal);
        }
        const strValue = value.toString(10);
        const dotIndex = strValue.indexOf('.');
        const decimalLength = strValue.substring(dotIndex + 1).length;
        const denominator = Math.pow(10, decimalLength);
        const numerator = +strValue.replace('.', '');

        return new Fraction(numerator, denominator).simplify();
    }

    private static getCommonDivisor(a: Decimal, b: Decimal): Decimal {
        let temp: Decimal;
        while (!b.equals(0)) {
            temp = b;
            b = a.modulo(b);
            a = temp;
        }

        return a;
    }

    static empty(): Fraction {
        return new Fraction(0, 0, true);
    }

    static toFraction(value: Decimal | number | null): Fraction {
        if (value == null) {
            return Fraction.empty();
        }
        if (Fraction.isNumber(value)) {
            // number path.
            return Fraction.numberToFraction(value);
        } else {
            if (value.lessThanOrEqualTo(0)) {
                return Fraction.empty();
            }
            const dp = value.decimalPlaces();
            const denominator = Math.pow(10, dp);

            return new Fraction(value.times(denominator), denominator).simplify();
        }
    }

    static fromString(value: string): Fraction {
        if (value.indexOf('/') === -1) {
            return Fraction.empty();
        }
        const values = value.split('/');
        const numerator = new Decimal(values[0]);
        const denominator = new Decimal(values[1]);

        return new Fraction(numerator, denominator);
    }

    add(other: Fraction | number): Fraction {
        if (this.isEmpty()) {
            return Fraction.empty();
        }
        if (Fraction.isNumber(other)) {
            const intVal = Fraction.toInt(other);
            if (!isNaN(intVal)) {
                // Go to fast path (no common denominator) where if we have an integer value.
                // We multiply the value with the denominator and add to the numerator
                // 2/3 + 2 = 2/3 + 6 / 3 = (2 + 6) / 3
                return new Fraction(this.numerator.add(this.denominator.times(intVal)), this.denominator);
            }
            other = Fraction.numberToFraction(other);
        }
        if (other.isEmpty()) {
            return Fraction.empty();
        }
        const convert = Fraction.toCommonDenominator(this, other);

        return new Fraction(convert.firstNumerator.plus(convert.secondNumerator), convert.denominator);
    }

    subtract(other: Fraction | number): Fraction {
        if (this.isEmpty()) {
            return Fraction.empty();
        }
        if (Fraction.isNumber(other)) {
            const intVal = Fraction.toInt(other);
            if (!isNaN(intVal)) {
                // Go to fast path (no common denominator) where if we have an integer value.
                // We multiply the value with the denominator and subtract from the numerator
                // 2/3 - 2 = 2/3 - 6 / 3 = (2 - 6) / 3
                return new Fraction(this.numerator.minus(this.denominator.times(intVal)), this.denominator);
            }
            other = Fraction.numberToFraction(other);
        }
        if (other.isEmpty()) {
            return Fraction.empty();
        }
        const convert = Fraction.toCommonDenominator(this, other);

        return new Fraction(convert.firstNumerator.minus(convert.secondNumerator), convert.denominator);
    }

    multiply(other: Fraction | number): Fraction {
        if (this.isEmpty()) {
            return Fraction.empty();
        }
        if (Fraction.isNumber(other)) {
            other = Fraction.numberToFraction(other);
        }
        if (other.isEmpty()) {
            return Fraction.empty();
        }

        return new Fraction(this.numerator.times(other.numerator), this.denominator.times(other.denominator));
    }

    divide(other: Fraction | number): Fraction {
        if (this.isEmpty()) {
            return Fraction.empty();
        }
        if (Fraction.isNumber(other)) {
            other = Fraction.numberToFraction(other);
        }
        if (other.isEmpty()) {
            return Fraction.empty();
        }

        return new Fraction(this.numerator.times(other.denominator), this.denominator.times(other.numerator));
    }

    getValue(): Decimal {
        if (this.isEmpty()) {
            return new Decimal(0);
        }
        const n = new Decimal(this.numerator);
        const d = new Decimal(this.denominator);

        return n.dividedBy(d);
    }

    toString(): string {
        return `${this.numerator}/${this.denominator}`;
    }

    simplify(): Fraction {
        if (this.isSimplified) {
            return this;
        } else {
            const greatestCommonDivisor = Fraction.getCommonDivisor(this.numerator, this.denominator);

            return new Fraction(this.numerator.dividedBy(greatestCommonDivisor), this.denominator.dividedBy(greatestCommonDivisor), true);
        }
    }

    /**
     *  Compares fraction to another fraction or number
     *
     *  @returns -1 if lower, 0 if equal, 1 if greater
     */
    compare(other: Fraction | number): number {
        const o = Fraction.isNumber(other) ? Fraction.toFraction(other) : other;
        const eq = Fraction.toCommonDenominator(this, o);

        return eq.firstNumerator.lessThan(eq.secondNumerator) ? -1 : eq.firstNumerator.greaterThan(eq.secondNumerator) ? 1 : 0;
    }

    lessThan(other: Fraction | number): boolean {
        return this.compare(other) === -1;
    }

    greaterThan(other: Fraction | number): boolean {
        return this.compare(other) === 1;
    }

    lessOrEqualThan(other: Fraction | number): boolean {
        return this.compare(other) <= 0;
    }

    greaterOrEqualThan(other: Fraction | number): boolean {
        return this.compare(other) >= 0;
    }

    equal(other: Fraction | number): boolean {
        return this.compare(other) === 0;
    }

    isEmpty(): boolean {
        return this.denominator.equals(0);
    }

    toJSON(): { numerator: number; denominator: number } {
        return {
            numerator: this.numerator.toNumber(),
            denominator: this.denominator.toNumber(),
        };
    }

    copy(): Fraction {
        return new Fraction(this.numerator, this.denominator, this.isSimplified);
    }
}
