import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { CurrencyConfig } from '@frontend/sports/common/client-config-data-access';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { UiManagerActions } from '../ui-manager/ui-manager.actions';
import { IUiRootState } from '../ui-manager/ui-manager.state';
import { UiStates } from '../ui-manager/ui-states.enum';
import { ISelectionProvider, NumpadOperation, NumpadState } from './model';
import { NumpadService } from './numpad.service';

@HooksWireup()
@Component({
    selector: 'ms-stake-field',
    templateUrl: './stake-field.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./stake-field.component.scss'],
})
export class StakeFieldComponent extends OnDestroyCleanup implements OnInit, ISelectionProvider {
    @Input() disabled = false;
    @Input() emptyCharacter: string;
    @Input() adornmentStart?: string;
    selected = false;

    constructor(
        public numpadService: NumpadService,
        private currencyConfig: CurrencyConfig,
        private store: Store<IUiRootState>,
    ) {
        super();
    }

    @HostListener('click', ['$event'])
    stopPropagation(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();

        this.store.dispatch(UiManagerActions.removeState({ state: UiStates.MarketSubTypeTooltipShown }));
    }

    ngOnInit(): void {
        this.numpadService.registerSelectionProvider(this);
        this.numpadService.state$.pipe(takeUntil(this.destroyed$)).subscribe((state) => this.stateChange(state));
    }

    onClick(): void {
        if (this.numpadService.currentState.isOpened) {
            this.selected = !this.selected;
        } else {
            this.numpadService.open();
        }
    }

    private stateChange(state: NumpadState): void {
        this.selected = state.operation === NumpadOperation.Open;
    }

    getSelection(currentValue: string): { start: number; end: number } {
        return {
            start: this.selected ? 0 : currentValue.length,
            end: currentValue.length,
        };
    }

    getStake(state: NumpadState): string {
        return state.stake.isEmpty
            ? this.emptyCharacter
            : this.currencyConfig.showStakeCurrency
              ? state.stake.currencyFormatted
              : state.stake.formatted;
    }
}
