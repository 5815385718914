import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

import { Nullable } from '@frontend/sports/common/base-utils';

@Directive({
    selector: '[msHeightToBottom]',
})
export class HeightToBottomDirective implements OnInit {
    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        this.setElementHeight();
    }

    @HostListener('window:resize')
    setElementHeight(): void {
        const pageHeight = window.innerHeight;
        const bottomNav = window.document.querySelector('nav.bottom-nav') as Nullable<HTMLElement>;
        const bottomNavOffsetHeight = bottomNav?.offsetHeight || 50;

        const elementOffsetTop = this.element.nativeElement.offsetTop;
        const mainHeader = window.document.querySelector('header.header') as Nullable<HTMLElement>;
        const mainHeaderHeight = mainHeader?.offsetHeight || 0;

        const elementTop = elementOffsetTop > 0 ? elementOffsetTop : mainHeaderHeight;
        this.element.nativeElement.style.height = pageHeight - bottomNavOffsetHeight - elementTop + 'px';
    }
}
