import { Injectable } from '@angular/core';

import { NOT_APPLICABLE, trackingConstants } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';
import { FavouriteType } from '../favourites.model';
import { FavouriteToggleTracking } from './favourite-toggle.component';

@Injectable({
    providedIn: 'root',
})
export class FavouriteToggleTrackingService {
    favouriteTypeMap = {
        [FavouriteType.League]: 'League',
        [FavouriteType.Fixture]: 'Event',
        [FavouriteType.FixtureV2]: 'Event',
        [FavouriteType.Participant]: 'Player/Team',
        [FavouriteType.ParticipantV2]: 'Player/Team',
    };

    constructor(private trackingService: TrackingService) {}

    trackToggle(isToggled: boolean, favouriteType: FavouriteType, tracking: FavouriteToggleTracking): void {
        const baseTracking = {
            [trackingConstants.COMPONENT_ACTION_EVENT]: `${isToggled ? 'Add' : 'Remove'} - Favourites`,
            [trackingConstants.COMPONENT_POSITION_EVENT]: tracking.source ?? NOT_APPLICABLE,
            [trackingConstants.COMPONENT_LOCATION_EVENT]: NOT_APPLICABLE,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: tracking.name ?? NOT_APPLICABLE,
            [trackingConstants.COMPONENT_CONTENT_POSITION]: tracking.position?.toString() ?? NOT_APPLICABLE,
        };

        const commonTracking = {
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: 'Sports Favourites',
            [trackingConstants.COMPONENT_URL_CLICKED]: NOT_APPLICABLE,
            [trackingConstants.COMPONENT_LABEL_EVENT]: this.favouriteTypeMap[favouriteType] ?? NOT_APPLICABLE,
        };

        this.trackingService.track(trackingConstants.EVENT_FAVOURITES, {
            ...commonTracking,
            ...baseTracking,
            ...tracking?.additional,
        });

        this.trackingService.track(trackingConstants.EVENT_CONTENT_VIEW, {
            ...commonTracking,
            [trackingConstants.COMPONENT_ACTION_EVENT]: 'load',
            [trackingConstants.COMPONENT_POSITION_EVENT]: 'toaster',
            [trackingConstants.COMPONENT_LOCATION_EVENT]: `item ${isToggled ? 'added' : 'removed'}`,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: 'favourites toaster',
        });
    }
}
