import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';

import { Subject, debounceTime } from 'rxjs';

@Component({
    selector: 'ms-dynamic-font-resizer',
    template: '<span #resizerContainer style>{{value}}</span>',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class DynamicFontResizerComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() value: string;
    @Input() defaultSize: number;

    @HostBinding('style.font-size.em') fontSize?: number;
    @ViewChild('resizerContainer') private resizerContainer: ElementRef;

    private resizeObserver: ResizeObserver;
    private parentResized$: Subject<number>;
    private initialWidth: number;
    private initialFontSize: number;

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        this.parentResized$ = new Subject();

        this.resizeObserver = new ResizeObserver((entries) => this.handleResize(entries));

        this.parentResized$.pipe(debounceTime(10)).subscribe((parent) => this.changeFontSize(parent));
    }

    ngOnInit(): void {
        this.fontSize = this.defaultSize;
    }

    ngOnDestroy(): void {
        this.resizeObserver.disconnect();
        this.parentResized$.complete();
    }

    ngAfterViewInit(): void {
        const nativeTextContent = this.resizerContainer?.nativeElement;

        if (!nativeTextContent) {
            return;
        }

        const parentElement = nativeTextContent.parentElement?.parentElement;

        if (!parentElement) {
            return;
        }

        this.resizeObserver.observe(parentElement);
        this.initialFontSize = this.fontSize ?? 0;
        this.initialWidth = this.resizerContainer?.nativeElement.getBoundingClientRect().width;
    }

    changeFontSize(parentWidth: number): void {
        const currentFontSize = this.fontSize ?? this.defaultSize;
        const textLength = this.value.length;
        const initialWidth = this.initialWidth;
        const initialFontSize = this.initialFontSize;

        const newFontSize = this.calculateFontSizeFromBounds(parentWidth, initialWidth, textLength, initialFontSize, this.defaultSize);

        if (newFontSize === currentFontSize) {
            return;
        }

        this.fontSize = newFontSize;
        this.changeDetectorRef.markForCheck();
    }

    private calculateFontSizeFromBounds(
        parentWidth: number,
        initialWidth: number,
        textLength: number,
        initialFontSize: number,
        defaultFontSize: number,
    ): number {
        const targetFontSize = this.calculateRelationalFontSize(parentWidth, initialWidth, textLength, initialFontSize);

        if (Number.isNaN(targetFontSize) || !Number.isFinite(targetFontSize) || targetFontSize > defaultFontSize) {
            return defaultFontSize;
        }

        return Math.floor(targetFontSize * 100) / 100;
    }

    private calculateRelationalFontSize(targetTextWidth: number, initialTextWidth: number, textLength: number, initialFontSize: number): number {
        // Calculating font size in relation to character width with following formular:
        // targetSpacePerChar : targetFontSize = spacePerChar : currentFontSize
        // targetFontSize = currentFontSize * targetSpacePerChar / spacePerChar

        const initialSpacePerChar = initialTextWidth / textLength;
        const targetSpacePerChar = targetTextWidth / textLength;

        return (initialFontSize * targetSpacePerChar) / initialSpacePerChar;
    }

    private handleResize(entries: ResizeObserverEntry[]): void {
        if (entries.length === 0) {
            return;
        }

        const parent = entries[0];

        if (!parent) {
            return;
        }

        this.parentResized$.next(parent.contentRect.width);
    }
}
