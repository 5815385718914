import { Injectable } from '@angular/core';

import { usePreloaderData } from '@frontend/sports/common/base-utils';
import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from '../http/api.service';
import { SiteMapResponse, SitemapModel } from './sitemap.models';

@Injectable({
    providedIn: 'root',
})
export class SitemapService {
    private _sitemap$ = new BehaviorSubject<SitemapModel>({ items: [] });

    constructor(private api: ApiService) {}

    get sitemap$(): Observable<SitemapModel> {
        return this._sitemap$.asObservable();
    }

    @usePreloaderData({
        preloader: (<any>window).MS2JS?.SiteMapLoader,
    })
    getSiteMapConfiguration(): Observable<SiteMapResponse> {
        return this.api.get<SiteMapResponse>('sitemap');
    }

    setSitemap(sitemap: SitemapModel): void {
        this._sitemap$.next(sitemap);
    }
}
