import { Injectable } from '@angular/core';

import { last, orderBy } from 'lodash-es';

import { SitemapStateService } from './sitemap-state.service';
import { SitemapItemBaseModel, SitemapItemModel, SitemapItemType } from './sitemap.models';

@Injectable({
    providedIn: 'root',
})
export class SitemapStateMapper {
    constructor(private stateService: SitemapStateService) {}

    syncState(items: SitemapItemModel[]): void {
        const activeFromState = this.stateService.active;
        const activeFromItems = last(
            orderBy(
                items
                    .filter((itm) => itm.activeDrawerItem)
                    .flatMap((itm) => itm.activeDrawerItem)
                    .concat(items.filter((itm) => itm.active)),
                (itm) => itm!.level,
            ),
        );

        if (!activeFromState && !activeFromItems) {
            return;
        }
        if (!activeFromState || activeFromState?.id !== activeFromItems?.id || activeFromState?.type !== activeFromItems?.type) {
            const newNavPath = this.getNavigationPath(items);
            this.stateService.setPath(newNavPath);
        }
    }

    saveSiblingsInState(items: SitemapItemModel[]): void {
        const lastLevelItem = items[items.length - 1].level;
        const siblings = items.filter((itm) => itm.level === lastLevelItem);
        this.stateService.setSiblings(siblings);
    }

    getSiblingsFromState(items: SitemapItemModel[]): void {
        const siblings = this.stateService.siblings;
        const activeEl = items.find((itm) => !!itm.active)!;
        const specificTypes = [SitemapItemType.Coupons, SitemapItemType.Tournament];
        const activeItemIndex = siblings.findIndex(
            (item) => item.id === activeEl.id || (specificTypes.find((t) => t === activeEl.type) && item.type === activeEl.type),
        );

        // when element is not part of the visible sitemap, we do not keep siblings
        if (activeItemIndex === -1) {
            return;
        }

        if (items.length === 1) {
            items.splice(0, 1, ...siblings);
            items[activeItemIndex] = activeEl;
        } else {
            const ind = items.findIndex((itm) => itm.level === activeEl.level);
            items[ind - 1].showSeparator = true;
            items.splice(ind, items.length, ...siblings);
            items[ind + activeItemIndex] = activeEl;
        }
    }

    private getNavigationPath(items: SitemapItemModel[]): SitemapItemBaseModel[] {
        const newPath: SitemapItemBaseModel[] = [];
        const defaultActive = items.find((itm) => itm.active);
        const tryPushDrawerItem = (item: SitemapItemModel) => {
            if (item.activeDrawerItem) {
                newPath.push(item.activeDrawerItem);
            }
        };
        if (defaultActive) {
            for (let l = 1; l < defaultActive.level; l++) {
                const item = items.find((itm) => itm.level === l);
                if (item) {
                    newPath.push({ ...item });
                    tryPushDrawerItem(item);
                }
            }
            newPath.push({ ...defaultActive });
            if (defaultActive.level === 1) {
                tryPushDrawerItem(defaultActive);
            }
            const lastIndx = newPath.length - 1;
            newPath.forEach((p, indx) => (p.active = indx === lastIndx));
        }

        return newPath;
    }
}
