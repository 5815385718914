import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { PlainLinkComponent } from '@frontend/vanilla/core';

import { AutomationModule } from '../../automation/automation.module';
import { QuicklinkDirective } from '../../quicklink/quicklink.directive';
import { trackingConstants } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';

@Component({
    selector: 'ms-item',
    templateUrl: 'item.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AutomationModule, NgTemplateOutlet, PlainLinkComponent, QuicklinkDirective],
})
export class ItemComponent {
    @Input() title?: string;
    @Input() count?: number;
    @Input() icon?: string;
    @Input() href?: string;
    @Input() regionIds?: number[];
    @Input() tracking = 'M2_quicklinks';
    @Input() arrow = true;
    @Input() attribute?: string;
    @HostBinding('class') className = 'list-item';
    @Output() itemClicked = new EventEmitter<void>();
    constructor(private trackerService: TrackingService) {}

    clicked(): void {
        if (this.tracking) {
            this.trackerService.update({ [trackingConstants.PAGE_REFERRING_ACTION]: this.tracking });

            if (this.href?.indexOf('http') === 0) {
                this.trackerService.triggerEventWithCleaning();
            }
        }
        this.itemClicked.emit();
    }
}
