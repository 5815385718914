import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, Type } from '@angular/core';

import { DeviceService, SessionStoreService } from '@frontend/vanilla/core';
import { extend } from 'lodash-es';

import { ScrollFreezeService, ScrollFreezeServiceTypes } from '../../common/scroll-freeze.service';
import { EventBridgeService } from '../../event-bridge/event-bridge.service';
import { Modal } from '../common/modal';
import { ModalRef } from '../common/modal-ref';
import { MessageDialogComponent } from './message-dialog.component';
import { ModalDialogComponent } from './modal-dialog.component';
import { ContentData, DialogAnimation, DialogSettings, ModalDialogOptions } from './modal-dialog.model';

@Injectable({ providedIn: 'root' })
export class ModalDialogService {
    static get DefaultSettings(): DialogSettings {
        return <DialogSettings>{
            isModalDialog: true,
            showDialogHeader: false,
            showPageHeader: false,
            fit: true,
            landscapeExpand: false,
            hideCloseButton: false,
        };
    }

    private openModalsCountKey = 'openedSportsModalDialogsCount';
    private animationClasses = {};

    constructor(
        private modal: Modal,
        private scrollFreeze: ScrollFreezeService,
        private eventBridge: EventBridgeService,
        private deviceService: DeviceService,
        private sessionStorage: SessionStoreService,
    ) {
        this.animationClasses[DialogAnimation.SlideInFromBottom] = 'modal-slide-from-bottom';
        this.animationClasses[DialogAnimation.SlideInFromRight] = 'modal-slide-from-right';
        this.animationClasses[DialogAnimation.SlideOutFromTop] = 'modal-slide-out-to-bottom';
    }

    private get openedModalsCount(): number {
        return this.sessionStorage.get<number>(this.openModalsCountKey) || 0;
    }

    private increaseModalsCount(): void {
        this.sessionStorage.set<number>(this.openModalsCountKey, this.openedModalsCount + 1);
    }

    private decreaseModalsCount(): void {
        this.sessionStorage.set<number>(this.openModalsCountKey, this.openedModalsCount - 1);
    }

    isModalDialogOpen(): boolean {
        return this.openedModalsCount > 0;
    }

    openPopup<TComponent>(component: Type<TComponent>, options?: ModalDialogOptions<Partial<TComponent>>): ModalRef {
        options = options || {};
        options.settings = options.settings || {};
        options.settings.isPopup = true;

        return this.openDialog(component, options);
    }

    openDialog<TComponent>(component: Type<TComponent>, options?: ModalDialogOptions<Partial<TComponent> | void>): ModalRef {
        options = options || {};
        const defaultSettings = extend({}, ModalDialogService.DefaultSettings, { fit: !options.settings?.isPopup });
        const modalRef = this.openModal(ModalDialogComponent, defaultSettings, options.settings);

        const dialogInstance = modalRef.componentInstance as ModalDialogComponent;
        dialogInstance.contentComponent = component;
        dialogInstance.inputs = options.data;
        dialogInstance.modalRef = modalRef;
        dialogInstance.dialogSettings = extend({}, ModalDialogService.DefaultSettings, options.settings);

        return modalRef;
    }

    openMessage(contentData: ContentData, settings?: DialogSettings): ModalRef {
        const defaultSettings = extend({}, ModalDialogService.DefaultSettings, { showDialogHeader: false });
        const modalRef = this.openModal(MessageDialogComponent, defaultSettings, settings);

        const dialogInstance = modalRef.componentInstance as MessageDialogComponent;
        dialogInstance.contentData = contentData;

        return modalRef;
    }

    private openModal<TComponent extends ComponentType<unknown>>(
        component: TComponent,
        defaultSettings: DialogSettings,
        settings?: DialogSettings,
    ): ModalRef {
        const copySettings = extend({}, defaultSettings, settings);
        const modalCss = this.buildModalCss(copySettings, settings?.isPopup);
        const modalBackdropCss = this.buildModalBackdropCss(copySettings, settings?.isPopup);

        if (copySettings.closeAnimation) {
            copySettings.closeAnimation = this.animationClasses[copySettings.closeAnimation];
        }

        const modalRef = this.modal.open(component, {
            windowClass: modalCss,
            backdropClass: modalBackdropCss,
            backdrop: copySettings.isModalDialog ? 'static' : true,
            keyboard: !copySettings.isModalDialog,
            container: copySettings.container,
            centerVerticalToViewport: !!copySettings.container,
            beforeDismiss: copySettings.beforeDismiss,
            showDialogHeader: copySettings.showDialogHeader,
            hideCloseButton: copySettings.hideCloseButton,
            title: copySettings.title,
            closeAnimation: copySettings.closeAnimation,
            injector: copySettings.injector,
            epcotShowBalance: copySettings.epcotShowBalance,
            headerTemplate: copySettings.headerTemplate,
            showCloseBtnText: copySettings.showCloseBtnText,
            showBackNav: copySettings.showBackNav,
            closePrevious: copySettings.closePrevious,
        });

        if (!this.openedModalsCount) {
            this.eventBridge.notifyOpenOverlay();
        }

        this.increaseModalsCount();
        if (copySettings.scrollFreezeType !== ScrollFreezeServiceTypes.None && this.deviceService.isMobile) {
            this.scrollFreeze.freezeScroll(copySettings.scrollFreezeType);
        }

        modalRef.result.finally(() => {
            if (copySettings.scrollFreezeType !== ScrollFreezeServiceTypes.None && this.deviceService.isMobile) {
                this.scrollFreeze.releaseScroll(copySettings.scrollFreezeType);
            }
            this.decreaseModalsCount();

            if (!this.openedModalsCount) {
                this.eventBridge.notifyCloseOverlay();
            }

            copySettings.afterResulted && copySettings.afterResulted();
        });

        return modalRef;
    }

    private buildModalBackdropCss(settings: DialogSettings, isPopup?: boolean): string {
        const cssClasses = [];

        if (!isPopup) {
            cssClasses.push('modal-dialog-backdrop');
        }

        if (settings.showPageHeader) {
            cssClasses.push('modal-under-header');
        }

        if (settings.showBottomNav) {
            cssClasses.push('modal-above-footer');
        }

        if (settings.inColumn) {
            cssClasses.push('modal-dialog-backdrop-in-column');
        }

        if (settings.closeAnimation) {
            cssClasses.push('close-animation');
        }

        if (settings.backdropClass) {
            cssClasses.push(settings.backdropClass);
        }

        return cssClasses.filter((c) => c).join(' ');
    }

    private buildModalCss(settings: DialogSettings, isPopup?: boolean): string {
        const cssClasses = [settings.cssClass];

        if (!isPopup) {
            cssClasses.push('modal-dialog-wrapper');
        }

        if (settings.fit) {
            cssClasses.push('modal-fit');
        }

        if (settings.showPageHeader) {
            cssClasses.push('modal-under-header');
        }

        if (settings.showBottomNav) {
            cssClasses.push('modal-above-footer');
        }

        if (settings.animation) {
            cssClasses.push(this.animationClasses[settings.animation]);
        }

        if (settings.inColumn) {
            cssClasses.push('modal-dialog-in-column');
        }

        return cssClasses.filter((c) => c).join(' ');
    }
}
