import { Injectable } from '@angular/core';

import { OnAppInit } from '../bootstrap/bootstrapper.service';
import { EmbeddableComponentsService } from '../dynamic-layout/embeddable-components.service';
import { MenuActionComponent } from './menu-action.component';
import { PlainLinkComponent } from './plain-link.component';

@Injectable()
export class PlainLinkBootstrapService implements OnAppInit {
    constructor(private embeddableComponentsService: EmbeddableComponentsService) {}

    onAppInit() {
        this.embeddableComponentsService.registerEmbeddableComponent(PlainLinkComponent, 5);
        this.embeddableComponentsService.registerEmbeddableComponent(MenuActionComponent, 5);
    }
}
