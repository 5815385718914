import { Component, ElementRef, Input, NgZone, OnChanges, inject } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { ScrollingSizeConfig, TopNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { MenuAction, MenuActionsService, MenuItemsService } from '@frontend/vanilla/core';
import { RxStrategyProvider } from '@rx-angular/cdk/render-strategies';
import { cloneDeep, sumBy } from 'lodash-es';
import { distinctUntilChanged, map, takeUntil } from 'rxjs';

import { ArcRestrictionService } from '../../arc/arc-restriction.service';
import { CharsetSizeService } from '../../common/charset-size.service';
import { ResizeObserverService } from '../../common/resize-observer.service';
import { EventBridgeService } from '../../event-bridge/event-bridge.service';
import { AdaptiveLayoutService } from '../../layout/adaptive-layout.service';
import { PopupManager, PopupTypes } from '../../popup/popup-manager.service';
import { TrackNavArea } from '../../tracking/tracking.models';
import { NavigationRouterService } from '../navigation-router.service';
import { SizedNavigationItem, TopMenuContentItem } from '../navigation.models';

enum PopupType {
    Sports = 'sports',
    ProductMenu = 'product-menu',
}

@HooksWireup()
@Component({
    selector: 'ms-main-items',
    templateUrl: 'main-items.html',
})
export class MainItemsComponent extends OnDestroyCleanup implements OnChanges {
    @Input() items: TopMenuContentItem[];
    @Input() compactItems: boolean;
    @Input() isEpcotEnabled: boolean;

    tracking = TrackNavArea;
    scrollSize: number;
    filteredItems: TopMenuContentItem[];
    liveCount = '0';
    scrolled: boolean;

    private menuWidth: number;
    private menuMargin = 24;
    private itemSeparator = 24;
    private itemIcon = 21;
    private itemMargin = 24;
    private itemCompact = 65;
    private isV2Navigation: boolean;

    private ngZone = inject(NgZone);

    private get sportsPopupOpen(): boolean {
        return this.popupManager.isPopupOpen(PopupType.Sports);
    }

    hideGamingOverlayByArc: boolean;

    constructor(
        private layoutService: AdaptiveLayoutService,
        private navigationRouter: NavigationRouterService,
        private scrollingConfig: ScrollingSizeConfig,
        private popupManager: PopupManager,
        private eventBridgeService: EventBridgeService,
        private menuActionService: MenuActionsService,
        private resizeObserver: ResizeObserverService,
        private elementRef: ElementRef<HTMLElement>,
        private charsetService: CharsetSizeService,
        public navigationConfig: TopNavigationConfig,
        private menuItemService: MenuItemsService,
        private arcRestrictionService: ArcRestrictionService,
        private strategy: RxStrategyProvider,
    ) {
        super();

        this.isV2Navigation = !!this.navigationConfig.topNavigationItems?.length;
        this.scrollSize = this.scrollingConfig.topNavScrollSize || 200;
        this.resizeObserver
            .observe(this.elementRef)
            .pipe(
                map((change) => change.contentRect.width),
                distinctUntilChanged(),
                takeUntil(this.destroyed$),
            )
            .subscribe((width) => {
                this.menuWidth = width;
                this.fit();
            });
        this.arcRestrictionService.gamingCrossSellEnabled$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
            this.hideGamingOverlayByArc = state;
        });
    }

    ngOnChanges(): void {
        this.fit();
    }

    itemClick(event: Event, item: TopMenuContentItem): void {
        if (item.popup && this.isActive(this.tracking.NavTop, item.name)) {
            this.popupManager.close();

            return;
        }

        if (item.popup) {
            event.preventDefault();
            this.strategy
                .schedule(
                    () => {
                        switch (item.popup) {
                            case PopupType.Sports:
                                this.openSportsPopup(item);
                                break;
                            case PopupType.ProductMenu:
                                this.menuActionService.invoke(MenuAction.TOGGLE_PRODUCT_MENU, '', [
                                    undefined,
                                    undefined,
                                    { 'animate-from': 'bottom' },
                                ]);
                                break;
                            default:
                                this.popupManager.open(<PopupTypes>item.popup);
                        }
                    },
                    { scope: this, patchZone: this.ngZone },
                )
                .subscribe();
        }

        this.eventBridgeService.navigationClick(TrackNavArea.NavTop, item.ccbKey);
    }

    private openSportsPopup(item: TopMenuContentItem): void {
        if (this.sportsPopupMode) {
            this.popupManager.open(<PopupTypes>item.popup);
            this.navigationRouter.setNavigation(item.name);
        } else {
            this.switchPopup();
        }
    }

    trackItem(index: number, item: TopMenuContentItem): string {
        return item.name;
    }

    isActive(selection: string, itemName: string): boolean {
        return this.menuItemService.isActive(selection, itemName);
    }

    onScrolled(): void {
        this.scrolled = true;
    }

    get sportsPopupMode(): boolean {
        return this.layoutService.current.showDesktopAZSports;
    }

    private fit(): void {
        if (!this.items) {
            return;
        }

        this.trySwitchPopup();

        this.filteredItems = cloneDeep(this.items);

        if (this.sportsPopupMode && this.menuWidth) {
            const sizes = this.filteredItems.map((item) => ({ item, width: this.getItemWidth(item) }));
            let sports = sizes.filter((current) => current.item.isSport && current.item.name !== this.navigationRouter.activeNavigation);
            const total = this.menuMargin + sumBy(sizes, (s) => s.width);

            if (this.isV2Navigation) {
                sports = sizes.filter((current) => !current.item.isFixed);
            }

            this.filterNavigation(sports, total);
        }

        this.scrolled = false;
    }

    private filterNavigation(navItems: SizedNavigationItem[], total: number): void {
        while (total > this.menuWidth && navItems.length) {
            const lastItem = navItems.pop();

            if (lastItem) {
                total -= lastItem.width;
                this.filteredItems.splice(this.filteredItems.indexOf(lastItem.item), 1);
            }
        }
    }

    private trySwitchPopup(): void {
        if (!this.sportsPopupMode && this.sportsPopupOpen) {
            this.switchPopup();
        }
    }

    private switchPopup(): void {
        this.menuActionService.invoke(MenuAction.TOGGLE_PRODUCT_MENU, '');
        this.popupManager.closeAll();
    }

    private getItemWidth(item: TopMenuContentItem): number {
        if (this.compactItems) {
            return this.itemCompact;
        }

        return (
            this.itemIcon +
            this.itemMargin +
            this.charsetService.getAverageSize() * item.text.length +
            (item.class?.includes('separator') ? this.itemSeparator : 0)
        );
    }
}
