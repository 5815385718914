import { Injectable } from '@angular/core';

import { GameType } from '@bpos/v1/sports-promo';
import { CampaignCallToAction, CampaignFilter, ReplaceableParameter } from '@bpos/v1/sports-promo/campaigns';
import { sortBy } from 'lodash-es';

import { Banner, CallToAction, Details, Filter, OfferStatus, Promotion, SubPromotion } from '../crm-offer-data/crm-offer.model';
import { Campaign, CampaignDetails } from '../crm-offer-data/crm-offer.server.model';
import { CrmPromotionReplaceParametersService } from './crm-promotion-replace-parameters.service';

@Injectable({ providedIn: 'root' })
export class CrmPromotionFactory {
    constructor(private replaceParametersService: CrmPromotionReplaceParametersService) {}

    toModelList(serverCampaigns: Campaign[]): Promotion[] {
        const promos = serverCampaigns.map((c) => {
            const promo = <Promotion>{};
            promo.id = c.id;
            promo.type = c.type;
            promo.subType = c.subType;
            promo.startDate = c.startDateUtc ? new Date(c.startDateUtc) : undefined;
            promo.expiryDate = c.expiryDateUtc ? new Date(c.expiryDateUtc) : undefined;
            promo.cmsItemId = c.cmsItemId;
            promo.priority = c.priority;
            promo.replaceableParameters = c.replaceableParameters;
            promo.callToAction = c.callToAction && CallToAction[c.callToAction];
            promo.status = this.mapCallToAction(c.callToAction);

            const subCampaigns = c.subCampaigns || [];
            promo.subPromotions = subCampaigns.map(
                (sc) =>
                    <SubPromotion>{
                        ...sc,
                        startDate: sc.startDateUtc && new Date(sc.startDateUtc),
                        endDate: sc.endDateUtc && new Date(sc.endDateUtc),
                    },
            );

            const mainCampaignFilter = this.createFilter(c.filter);
            const allFilters = [mainCampaignFilter].concat(subCampaigns.map((sc) => this.createFilter(sc.filter))).filter((f) => !f.isEmpty);
            promo.allFilters = allFilters;

            promo.noFilters = allFilters.every((value) => this.noSportFilters(value));
            promo.gameType = (c.filter && c.filter.gameType) || GameType.Mixed;

            promo.banner = c.banner && this.replaceBannerParameters(c.banner, c.replaceableParameters);

            return promo;
        });

        return this.sortPromotions(promos);
    }

    sortPromotions(promotions: Promotion[]): Promotion[] {
        return sortBy(
            promotions,
            (optionGroup) => optionGroup.priority,
            (promos) => promos.expiryDate,
        );
    }

    setBannerDetails(promotion: Promotion, details: CampaignDetails): void {
        if (!promotion.banner) {
            return;
        }

        promotion.details = { ...details };
        promotion.details = this.replaceDetailsParameters(promotion.details, promotion.replaceableParameters);

        promotion.details.backgroundImageUrl = promotion.details.backgroundImageUrl || promotion.banner.backgroundImageUrl;
        promotion.details.heroImageUrl = promotion.details.heroImageUrl || promotion.banner.heroImageUrl;
    }

    private replaceBannerParameters(banner: Banner, replaceableParameters: { [key: string]: ReplaceableParameter }): Banner {
        banner.title = this.replaceParametersService.replaceParameters(banner.title, replaceableParameters);
        banner.description = this.replaceParametersService.replaceParameters(banner.description, replaceableParameters);
        banner.callToAction = this.replaceParametersService.replaceParameters(banner.callToAction, replaceableParameters);
        banner.keyTerms = this.replaceParametersService.replaceParameters(banner.keyTerms, replaceableParameters);

        return banner;
    }

    private replaceDetailsParameters(details: Details, replaceableParameters: { [key: string]: ReplaceableParameter }): Details {
        details.title = this.replaceParametersService.replaceParameters(details.title, replaceableParameters);
        details.description = this.replaceParametersService.replaceParameters(details.description, replaceableParameters);
        details.callToAction = this.replaceParametersService.replaceParameters(details.callToAction, replaceableParameters);
        details.termsAndConditions = this.replaceParametersService.replaceParameters(details.termsAndConditions, replaceableParameters);

        return details;
    }

    private mapCallToAction(cta?: CampaignCallToAction): OfferStatus {
        if (!cta) {
            return OfferStatus.NoOptIn;
        }

        switch (cta) {
            case CampaignCallToAction.OptIn:
                return OfferStatus.OptIn;
            case CampaignCallToAction.OptedIn:
                return OfferStatus.OptedIn;
            case CampaignCallToAction.NoOptIn:
                return OfferStatus.NoOptIn;
            default:
                return OfferStatus.NoOptIn;
        }
    }

    private noSportFilters(filter: Filter): boolean {
        return (
            !filter.sportIds.length &&
            !filter.competitionIds.length &&
            !filter.meetingIds.length &&
            !filter.fixtureIds.length &&
            !filter.marketTemplateIds.length &&
            !filter.marketIds.length &&
            !filter.optionIds.length
        );
    }

    private createFilter(filter?: CampaignFilter): Filter {
        if (!filter) {
            return this.emptyFilter();
        }

        const mappedFilter: Filter = {
            sportIds: [...(filter.sportIds || [])],
            competitionIds: [...(filter.competitionIds || [])],
            meetingIds: [...(filter.meetingIds || [])],
            fixtureIds: [...(filter.fixtureIds || [])],
            marketTemplateIds: [...(filter.marketTemplateIds || []).map((tId) => tId.toString())],
            marketIds: [...(filter.marketIds || [])],
            optionIds: [...(filter.optionIds || [])],
            minSelections: filter.minSelections,
            maxSelections: filter.maxSelections,
            currency: filter.currency,
            overallMinOdds: filter.overallMinOdds,
            overallMaxOdds: filter.overallMaxOdds,
            selectionMinOdds: filter.selectionMinOdds,
            selectionMaxOdds: filter.selectionMaxOdds,
            isEmpty: false,
            minStake: filter.minStake,
            maxStake: filter.maxStake,
            betSlipType: filter.betSlipType,
            gameType: filter.gameType,
        };

        mappedFilter.isEmpty =
            this.noSportFilters(mappedFilter) &&
            !mappedFilter.currency &&
            !mappedFilter.minStake &&
            !mappedFilter.maxStake &&
            !mappedFilter.minSelections &&
            !mappedFilter.maxSelections &&
            !mappedFilter.overallMinOdds &&
            !mappedFilter.overallMaxOdds &&
            !mappedFilter.selectionMinOdds &&
            !mappedFilter.selectionMaxOdds &&
            !mappedFilter.betSlipType &&
            !mappedFilter.gameType;

        return mappedFilter;
    }

    private emptyFilter(): Filter {
        return {
            sportIds: [],
            competitionIds: [],
            meetingIds: [],
            fixtureIds: [],
            marketTemplateIds: [],
            marketIds: [],
            optionIds: [],
            isEmpty: true,
        };
    }
}
