import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, DestroyRef, ElementRef, OnInit, Type, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ReplaySubject, combineLatest, isObservable } from 'rxjs';
import { first } from 'rxjs/operators';

import { ScrollContainerService } from '../../common/scroll-container.service';
import { ComponentHostDirective } from '../../directives/component-host.directive';
import { DirectivesModule } from '../../directives/directives.module';
import { TrackingType, trackingConstants } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';
import { ModalRef } from '../common/modal-ref';
import { DialogSettings } from './modal-dialog.model';

@Component({
    selector: 'ms-modal-dialog',
    templateUrl: 'modal-dialog.html',
    providers: [ScrollContainerService],
    standalone: true,
    imports: [DirectivesModule],
})
export class ModalDialogComponent implements OnInit, AfterViewInit {
    private destroyRef = inject(DestroyRef);

    @ViewChild(ComponentHostDirective, { static: true }) componentHost: ComponentHostDirective<any>;
    dialogSettings: DialogSettings = {};

    contentComponent: Type<any>;
    inputs: any;
    modalRef: ModalRef;

    private componentLoaded$ = new ReplaySubject<ComponentRef<any>>();

    constructor(
        private trackingService: TrackingService,
        private scrollContainer: ScrollContainerService,
        private _elRef: ElementRef,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.mountComponent();
        if (this.modalRef) {
            combineLatest([this.componentLoaded$, this.modalRef.newData$])
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(([componentRef, data]) => {
                    for (const [key, value] of Object.entries(data)) {
                        componentRef.setInput(key, value);
                    }
                    this.cdr.detectChanges();
                });
        }
    }

    ngAfterViewInit(): void {
        if (this.dialogSettings.tracking) {
            switch (this.dialogSettings.tracking.type) {
                case TrackingType.PageName:
                    this.trackingService.track(this.dialogSettings.tracking.name, this.dialogSettings.tracking.data || {});
                    break;
                case TrackingType.Referring:
                    this.trackingService.update({ [trackingConstants.PAGE_REFERRING_ACTION]: this.dialogSettings.tracking.name });
                    break;
            }
        }

        if (this.dialogSettings.scrollTargetQuerySelector) {
            this.componentLoaded$.pipe(first()).subscribe(() => {
                this.scrollContainer.setTarget(this._elRef.nativeElement.querySelector(this.dialogSettings.scrollTargetQuerySelector));
            });
        }
    }

    private mountComponent = () => {
        const componentRef = this.componentHost.createComponent(this.contentComponent);
        if (this.inputs) {
            Object.keys(this.inputs).forEach((key) => (componentRef.instance[key] = this.inputs[key]));
        }

        if (componentRef.instance.componentLoaded && isObservable(componentRef.instance.componentLoaded)) {
            componentRef.instance.componentLoaded.pipe(first()).subscribe(() => this.componentLoaded$.next(componentRef));
        } else {
            this.componentLoaded$.next(componentRef);
        }
    };
}
