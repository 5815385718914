import { Injectable } from '@angular/core';

import ModuleLoaderService from 'packages/sports/web/app/src/deferred/module-loader.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import type { BetslipDigitalModule } from '../digital/betslip-digital.module';
import { BetslipModule } from './betslip-module-loader.service';

@Injectable()
export class BetslipDigitalModuleLoaderService {
    constructor(private moduleLoader: ModuleLoaderService) {}

    loadBetslipModule(): Observable<BetslipModule> {
        return this.moduleLoader
            .loadModule<BetslipDigitalModule>('BetslipDigitalModule', () =>
                import(/* webpackChunkName: "betslip-digital" */ '../digital/betslip-digital.module').then((module) => module.BetslipDigitalModule),
            )
            .pipe(map((ref) => ref.instance));
    }
}
