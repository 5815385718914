import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { Fixture } from '@cds/betting-offer';
import { MarqueeIndexedFixtureView } from '@cds/betting-offer/domain-specific';
import { BetBuilderMarketInfo, BetBuilderOptionGroupInfo } from '@cds/betting-offer/domain-specific/bet-builder';
import { MarqueeConfig } from '@frontend/sports/common/client-config-data-access';
import { MarketType, MarqueeTile, MarqueeType } from '@frontend/sports/types/components/content';
import { MapMarketToGameTemplateIds } from '@frontend/sports/types/components/highlights-marquee';

import { FixtureFactory } from '../event-model/mappers/fixture.factory';
import { EventOptionGroup, EventOptionGroupType } from '../event-model/model/event.model';
import { Tv2MarketParameters } from '../event-model/model/tv2.model';
import { MarqueeTileFactory } from './marquee-tile.factory';
import { Tile, isTile } from './marquee-tile.model';

@Injectable({ providedIn: 'root' })
export class MarqueeResponseMapperService {
    constructor(
        private fixtureFactory: FixtureFactory,
        private marqueeTileFactory: MarqueeTileFactory,
        private marqueeConfig: MarqueeConfig,
    ) {}

    mapFixtures(fixtures?: MarqueeIndexedFixtureView[], inputTiles?: MarqueeTile[]): Tile[] {
        const tileWithoutFixtures = inputTiles?.some((tile) => !tile.fixtureId);

        if (!fixtures?.length && !tileWithoutFixtures) {
            return [];
        }

        const tiles: Tile[] = [];

        for (const [currentIndex, sitecoreTile] of inputTiles?.entries() ?? []) {
            let tile: Tile | null = null;

            if (sitecoreTile.fixtureId) {
                const correspondingFixture = fixtures?.find((f) => f.requestIndex === currentIndex);

                if (!correspondingFixture) {
                    continue;
                }

                tile = this.createTile(
                    correspondingFixture.fixture,
                    sitecoreTile,
                    correspondingFixture.precreatedMarketsInfo,
                    correspondingFixture.precreatedOptionGroupInfo,
                );
            } else {
                tile = sitecoreTile.marqueeType === MarqueeType.Special ? this.marqueeTileFactory.createMarqueeTile(sitecoreTile) : null;
            }

            if (tile && isTile(tile)) {
                const isRegular = tile.marqueeType === MarqueeType.Regular;
                const isAvailable = tile.event?.gamesCount !== 0;

                if (isRegular && !isAvailable) {
                    continue;
                }

                if (tile.event?.offerSource === OfferSource.V2) {
                    const nonGridableOptions = this.marqueeConfig.mapMarketToGameTemplateIds.find(
                        (i) => i.sportId === tile!.sportId && i.market === tile!.marketType,
                    );

                    if (nonGridableOptions?.marketFilterParams) {
                        const tv2OptionGroup = this.getTV2OptionGroups(tile, nonGridableOptions);
                        if (tv2OptionGroup?.length === 0) continue;
                        tile.event.optionGroups = tv2OptionGroup;
                    }
                }

                tile.index = currentIndex;
                tile.source = sitecoreTile;
                tile.trackingDetail = sitecoreTile.trackingDetail;
                tiles.push(tile);

                if (tiles.length === this.marqueeConfig.maxTiles) {
                    break;
                }
            }
        }

        return tiles;
    }

    createTile(
        fixture: Fixture,
        tileInfo: MarqueeTile,
        precreatedMarketsInfo?: BetBuilderMarketInfo[],
        precreatedOptionGroupInfo?: BetBuilderOptionGroupInfo[],
    ): Tile | null {
        const modelEvent = this.fixtureFactory.create(
            fixture,
            tileInfo.marqueeType === MarqueeType.BuildABet ? tileInfo.fixtureId : undefined,
            precreatedOptionGroupInfo,
        );

        if (tileInfo.marketType === MarketType.DynamicMarket) {
            modelEvent.optionGroups.forEach((og) => (og.type = EventOptionGroupType.DynamicMarket));
        }
        if (tileInfo.marketType === MarketType.SixPack) {
            modelEvent.optionGroups.forEach((og) => (og.type = EventOptionGroupType.SixPack));
        }
        const tile =
            tileInfo.marqueeType === MarqueeType.BuildABet
                ? this.marqueeTileFactory.createBetBuilderTile(modelEvent, tileInfo, fixture.id, precreatedMarketsInfo)
                : this.marqueeTileFactory.createMarqueeTile(tileInfo, modelEvent);
        if (tile) {
            tile.source = tileInfo;
        }

        return tile;
    }

    private getTV2OptionGroups(tile: Tile, nonGridableOptions: MapMarketToGameTemplateIds): EventOptionGroup[] {
        return tile.event.optionGroups.filter(
            (optionGroup) =>
                nonGridableOptions.marketFilterParams?.every(
                    (param) => optionGroup.parameters?.find((p) => p.key === param.key)?.value === param.value,
                ) ||
                tile.event.optionGroups.find(
                    (param) => nonGridableOptions.marketType === param.parameters?.find((p) => p.key === Tv2MarketParameters.MarketType)?.value,
                ),
        );
    }
}
