import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { BetPlacementError } from '../bet-placement-error';

export class UserError extends BetPlacementError {
    constructor() {
        super();
        this.type = PlacementErrorType.UserError;
    }
}
