import { FixtureViewType } from '@cds/betting-offer';
import { EventParticipant } from 'packages/sports/web/app/src/event-model/model/event.model';

import { BetslipPick } from './betslip-pick';
import { BetslipV2OptionMarketPick } from './betslip-v2-option-market-pick';
import { BetBuilderPickId } from './pick-id';
import { IBetslipBetBuilderPickStorage, IEventData, PickSubType } from './pick-models';
import { SignedName } from './signed-name.model';

export interface SportcastOption {
    name: string;
    longId: string;
}

export class BetslipBetBuilderPick extends BetslipV2OptionMarketPick {
    override get eventParticipants(): EventParticipant[] | undefined {
        return this.event.event.participants;
    }

    override get eventViewType(): FixtureViewType | undefined {
        return this.event.event.viewType;
    }

    override id: BetBuilderPickId;
    eventId: string;
    event: IEventData;
    sportcastOptions: SportcastOption[];
    sportcastId?: number;
    betgeniusId?: number;
    betSlipUid?: string;
    useV2Key?: boolean;
    longIds?: string[];

    static override isPick(pick: BetslipPick): pick is BetslipBetBuilderPick {
        return pick instanceof BetslipBetBuilderPick;
    }

    static isEntainUiBetBuilderPick(pick: BetslipPick): pick is BetslipBetBuilderPick & boolean {
        return pick instanceof BetslipBetBuilderPick && !!pick.sportcastOptions?.length;
    }

    getLegCount(): number {
        return this.sportcastOptions?.length ? this.sportcastOptions.length : this.market.name.name.split(';').length;
    }

    static fromJSON(value: IBetslipBetBuilderPickStorage): BetslipBetBuilderPick {
        const pick = new BetslipBetBuilderPick();
        pick.initPropertiesFromJSON(value);

        return pick;
    }

    protected override initPropertiesFromJSON(value: IBetslipBetBuilderPickStorage): void {
        super.initPropertiesFromJSON(value);
        this.eventId = value.eventId;
        this.event = value.event && {
            ...value.event,
            event: {
                ...value.event.event,
                date: new Date(value.event.event.date),
            },
        };
        this.sportcastOptions = value.sportcastOptions;
        this.betgeniusId = value.betgeniusId;
        this.sportcastId = value.sportcastId;
        this.betSlipUid = value.betSlipUid;
        this.useV2Key = value.useV2key;
        this.longIds = value.longIds;
    }

    getLegs(): string[] {
        return this.market.name.name.split(';');
    }

    override betCount(): number {
        return 1;
    }

    override toJSON(): IBetslipBetBuilderPickStorage {
        const base = super.toJSON();

        return {
            ...base,
            eventId: this.eventId,
            event: this.event,
            pickSubType: PickSubType.BetBuilderPick,
            sportcastOptions: this.sportcastOptions,
            betgeniusId: this.betgeniusId,
            sportcastId: this.sportcastId,
            betSlipUid: this.betSlipUid,
            useV2key: this.useV2Key,
            longIds: this.longIds,
        };
    }

    copy(): BetslipBetBuilderPick {
        const copy = this.toJSON();

        return BetslipBetBuilderPick.fromJSON(copy);
    }

    override get regionName(): SignedName {
        return this.event.region.name;
    }

    override get leagueName(): SignedName {
        return this.event.league.name;
    }

    override get eventDate(): Date {
        return this.event.event.date;
    }
}
