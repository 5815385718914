import { createSelector } from '@ngrx/store';
import { pickBy } from 'lodash-es';

import { PickId } from '../../core/picks/pick-id';
import { IStake } from '../stake/models';
import { isSameStakeAppliedToAllSingles } from '../stake/utils';
import { betslipTypeStateSelector, selectIsSingleBetSelected } from '../types/selectors';
import { ISingleBetPickState } from './state';

export const singleBetStateSelector = createSelector(betslipTypeStateSelector, (typeState) => typeState.singleBet);
export const singleBetPicksSelector = createSelector(singleBetStateSelector, (s) => s.picks);

export const isMultiSingleBetSelector = createSelector(
    singleBetPicksSelector,
    (singleBetState) => Object.values(singleBetState).filter((pick) => pick.isSelected).length > 1,
);

export const singleBetPickSelectorFactory = (pickId: PickId) =>
    createSelector(singleBetPicksSelector, (picks): ISingleBetPickState | undefined => {
        return picks[pickId.toString()];
    });

export const selectSingleBetPickStakeFactory = (pickId: PickId) =>
    createSelector(singleBetPickSelectorFactory(pickId), (pick): IStake | null => {
        return pick ? { actualStake: pick.actualStake, stake: pick.stake } : null;
    });

export const singleBetSelectedPicksSelector = createSelector(singleBetPicksSelector, (picks) => pickBy(picks, (pick) => pick.isSelected));

export const singleBetPicksGeneralStakeSelector = createSelector(singleBetPicksSelector, (picks) => {
    const singleStates = Object.values(picks);

    if (singleStates.length < 1) {
        return null;
    }

    const firstState = singleStates[0];

    const stake = firstState.stake;

    if (singleStates.every((singleState) => singleState.stake === stake)) {
        return { stake, actualStake: firstState.actualStake };
    }

    return { stake: null, actualStake: null };
});

export const selectSingleBetAllSameStake = createSelector(selectIsSingleBetSelected, singleBetPicksSelector, (isSingleBet, picks) => {
    return isSingleBet && isSameStakeAppliedToAllSingles(picks);
});

export const selectSingleBetSinglePickState = createSelector(singleBetSelectedPicksSelector, (selectedPicks) => {
    const states = Object.values(selectedPicks);

    return states.length === 1 ? states[0] : null;
});

export const selectSingleBetSinglePickId = createSelector(singleBetSelectedPicksSelector, (selectedPicks) => {
    const states = Object.keys(selectedPicks);

    return states.length === 1 ? states[0] : null;
});
