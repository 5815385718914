import { Observable, Subject } from 'rxjs';

import { TimerService } from '../core';
import { LoadingIndicatorOptions } from './loading-indicator.models';

/**
 * Handler for closing loading indicator returned by {@link LoadingIndicatorService}.
 *
 * @stable
 */
export class LoadingIndicatorHandler {
    get show(): Observable<boolean> {
        return this.showEvents;
    }

    private readonly showEvents = new Subject<boolean>();
    private inProgress: boolean;
    private readonly timeoutId: NodeJS.Timeout;
    /**
     * @internal
     */
    constructor(private timerService: TimerService, options: LoadingIndicatorOptions) {
        this.inProgress = true;

        if (!options.disabled) {
            this.timeoutId = this.timerService.setTimeout(() => this.showEvents.next(true), options.delay);
        }
    }

    done() {
        if (!this.inProgress) {
            throw new Error('This instance of the progress indicator has been hidden already.');
        }

        this.inProgress = false;
        this.timerService.clearTimeout(this.timeoutId);
        this.showEvents.next(false);
    }
}
