import { BetPlacementError, BetPlacementErrorSaved } from '../bet-placement-error';

export interface ResultErrorSaved extends BetPlacementErrorSaved {
    pickId: string;
}

export class ResultError extends BetPlacementError implements ResultErrorSaved {
    constructor(readonly pickId: string) {
        super();
    }
}
