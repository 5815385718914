import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { PosError } from '../../../base/utils/pos-errors';
import { BetslipError, BetslipErrorSaved } from './betslip-error';

export interface BetPlacementErrorSaved extends BetslipErrorSaved {
    type: PlacementErrorType;
    errorMessage: string;
    newStakeHint?: number;
}

export class BetPlacementError extends BetslipError implements BetPlacementErrorSaved {
    constructor() {
        super();
    }

    override type: PlacementErrorType;
    errorMessage: string;
    platformError: PosError;
    newStakeHint?: number;
}
