import { Route, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

import { isEmpty } from 'lodash-es';

import { regexMatcher } from './regex.matcher';

const NO_MATCH: UrlMatchResult = null as any;

export function nameIdentifierMatcher(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult {
    if (!segments.length || !route.data || !route.data.matcherParam) {
        return NO_MATCH;
    }

    const segmentsToParse = segments.slice(0, 1);
    const posParams = parsePosParams(segmentsToParse, [route.data.matcherParam]);

    return isEmpty(posParams) ? NO_MATCH : { consumed: segmentsToParse, posParams };
}

export function multiNameIdentifierExactMatcher(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult {
    if (!segments.length || !route.data || !route.data.matcherParams || segments.length !== route.data.matcherParams.length) {
        return NO_MATCH;
    }

    const posParams = parsePosParams(segments, route.data.matcherParams);

    return isEmpty(posParams) ? NO_MATCH : { consumed: segments, posParams };
}

export function multiNameIdentifierMatcher(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult {
    if (!segments.length || !route.data || !route.data.matcherParams || segments.length > route.data.matcherParams.length) {
        return NO_MATCH;
    }

    const posParams = parsePosParams(segments, route.data.matcherParams);

    return isEmpty(posParams) ? NO_MATCH : { consumed: segments, posParams };
}

export function multiNameIdentifierMatcher_WithRegexMatcher(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult {
    if (
        !segments.length ||
        !route.data ||
        !route.data.matcherParams ||
        segments.length > route.data.matcherParams.length ||
        !route.data.matcherPattern
    ) {
        return NO_MATCH;
    }

    const regexResult = regexMatcher(segmentGroup.segments, segmentGroup, route);

    if (regexResult === NO_MATCH) {
        return regexResult;
    }

    const posParams = parsePosParams(segments, route.data.matcherParams);

    return isEmpty(posParams)
        ? NO_MATCH
        : { consumed: [...regexResult.consumed, ...segments], posParams: { ...posParams, ...regexResult.posParams } };
}

export function multiNameIdentifierMatcher_WithChildren(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult {
    if (!segments.length || !route.data || !route.data.matcherParams || segments.length < route.data.matcherParams.length) {
        return NO_MATCH;
    }

    let parentSegments = segments;
    if (segments.length > route.data.matcherParams.length) {
        parentSegments = segments.slice(0, route.data.matcherParams.length);
    }

    const posParams = parsePosParams(parentSegments, route.data.matcherParams);

    return isEmpty(posParams) ? NO_MATCH : { consumed: parentSegments, posParams };
}

function parsePosParams(segments: UrlSegment[], matcherParams: string[]): { [name: string]: UrlSegment } {
    const posParams: { [name: string]: UrlSegment } = {};
    for (let i = 0; i < segments.length; i++) {
        const nameIdentifierPair = parseSegment(segments[i]);
        const paramName = matcherParams[i];

        if (nameIdentifierPair.id) {
            posParams[`${paramName}`] = new UrlSegment(nameIdentifierPair.id, {});

            if (nameIdentifierPair.name) {
                posParams[`${paramName}Name`] = new UrlSegment(nameIdentifierPair.name, {});
            }
        }
    }

    return posParams;
}

function parseSegment(segment: UrlSegment): { id: string; name: string } {
    const valuesRegex = new RegExp('((.*)-)?((\\d+:)?\\d+)$', 'gmi');
    const valuesMatch: string[] = valuesRegex.exec(segment.path) || [];

    const name = valuesMatch[2];
    const id = valuesMatch[3];

    return { id, name };
}
