import { Injectable, OnDestroy } from '@angular/core';

import { Fixture } from '@cds/betting-offer';
import { GridViewGroupingConfiguration } from '@cds/betting-offer/grouping/grid-view';
import { isDefined } from '@frontend/sports/common/base-utils';
import { GridConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { CouponTypes } from '@frontend/sports/common/coupons-lib';
import { Store } from '@ngrx/store';
import { isNumber, mapValues } from 'lodash-es';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { OfferGroupingService } from '../cds/offer-grouping.service';
import { EventModel, SportModel } from '../event-model/model/event.model';
import { SubscriptionTopic } from '../event-subscription/base-subscription.service';
import { EventFactory } from '../grid-base/factories/event.factory';
import { ColumnActiveState, GridStoreService } from './grid-store.service';
import { IGridGroup } from './grid.actions';
import { Column, GridGrouping, GridLayout, GridModel, Group } from './grid.model';
import { addEvents, setActiveGroupState, setGroupCollapseThreshold, setStateCollapse } from './grid.reducer';
import { IGridRootState } from './grid.state';
import { ObservableGrid } from './observable-grid';
import { ObservableGridCustomColumnProvider } from './observable-grid-custom-column-provider';
import { GridOption, ObservableGridProvider } from './observable-grid.provider';

@Injectable({ providedIn: 'root' })
export class ObservableGridFactory implements OnDestroy {
    private grids: ObservableGrid[] = [];

    constructor(
        private eventFactory: EventFactory,
        private gridConfig: GridConfig,
        private sitecore: Sitecore,
        private store: Store<IGridRootState>,
        private gridStore: GridStoreService,
        private observableGridProvider: ObservableGridProvider,
        private grouping: OfferGroupingService,
        private observableGridCustomColumnProvider: ObservableGridCustomColumnProvider,
    ) {}

    ngOnDestroy(): void {
        this.destroy();
    }

    destroy(): void {
        this.grids.forEach((grid) => grid.destroy());
        this.grids = [];
    }

    /**
     * Creates a grid model with the provided options and events
     *
     * @param id - is used to identify the grid, so we can wire up the storing of active groups on each market column and the collapse state of several items on the grid
     * @param events - list of the events, can not be empty
     * @param [options={}] - options which are used to build the grid
     * @param [options.activeGrouping] - number specifying which non gridable market group is selected, is being used aso for market template
     * @param [options.marketGrouping] - forwarded to the event mapper, used for non gridable events to apply the event details market grouping
     * @param [options.moreGrouping] - indicates weather the grid should render the more in the market groups dropdown
     * @param [options.grouping] - specifies the grouping approach that will be used for the events
     * @param [options.sorting] - specifies what is the initial sorting applied
     * @param [options.collapsedThreshold] - a number which is being used to collapse the groups after the event count surpasses the threshold
     * @param [options.subscriptionTopic] - specifies what topic should the grid subscribe the events to
     * @returns
     * @memberof ObservableGridFactory
     */
    from(id: string, events: EventModel[], options: GridOption = {}): ObservableGrid {
        if (!events || !events.length) {
            throw new Error('Can not map undefined events');
        }

        const grid = this.getBase(id, options, events[0].sport);

        return this.getGrid(grid, options, of(events));
    }

    /**
     * Creates a grid model with the provided options and fixtures
     *
     * @param id - is used to identify the grid, so we can wire up the storing of active groups on each market column and the collapse state of several items on the grid
     * @param fixtures - list of the fixtures, can not be empty
     * @param [options={}] - options which are used to build the grid
     * @param [options.activeGrouping] - number specifying which non gridable market group is selected, is being used aso for market template
     * @param [options.marketGrouping] - forwarded to the event mapper, used for non gridable events to apply the event details market grouping
     * @param [options.moreGrouping] - indicates weather the grid should render the more in the market groups dropdown
     * @param [options.grouping] - specifies the grouping approach that will be used for the events
     * @param [options.sorting] - specifies what is the initial sorting applied
     * @param [options.collapsedThreshold] - a number which is being used to collapse the groups after the event count surpasses the threshold
     * @param [options.subscriptionTopic] - specifies what topic should the grid subscribe the events to
     * @returns
     * @memberof ObservableGridFactory
     */
    fromResponse(
        id: string,
        fixtures?: Fixture[],
        options: GridOption = {},
        isPriceBoosted: boolean = false,
        couponType?: CouponTypes,
        excludePriceboostedMarketGrouping = false,
    ): ObservableGrid {
        if (!fixtures || !fixtures.length) {
            throw new Error('Can not map undefined fixtures');
        }

        const sport = fixtures[0].sport;
        const events = this.eventFactory.create(fixtures, {
            marketGrouping: options.marketGrouping,
            isPriceBoosted,
            excludePriceboostedMarketGrouping,
        });
        const grid = this.getBase(
            id,
            options,
            {
                id: sport.id,
                name: sport.name.value,
                virtual: fixtures[0].isVirtual,
                isEsport: sport.isEsport,
            },
            !!couponType,
        );

        return this.getGrid(grid, options, events, couponType);
    }

    private getGrid(grid: GridModel, options: GridOption, events: Observable<EventModel[]>, couponType?: CouponTypes): ObservableGrid {
        grid = this.setConfig(grid, options);

        const source = this.getSource(grid, options, events, couponType);

        const result = new ObservableGrid(grid, this.store, this.eventFactory, source);
        this.grids.push(result);

        return result;
    }

    private getSource(grid: GridModel, options: GridOption, events: Observable<EventModel[]>, couponType?: CouponTypes): Observable<GridModel> {
        if (couponType) {
            return events.pipe(map((mapped) => this.setConfig(grid, options, undefined, mapped, couponType)));
        }

        return forkJoin([this.grouping.getGridGrouping(grid.sport.id), events]).pipe(
            map(([config, mapped]) => this.setConfig(grid, options, config, mapped)),
        );
    }

    private setConfig(
        grid: GridModel,
        options: GridOption,
        config?: GridViewGroupingConfiguration,
        events?: EventModel[],
        couponType?: CouponTypes,
    ): GridModel {
        const state = this.gridStore.get(grid.id) || { columns: [], groups: [], events: {} };
        const eventState = mapValues(state.events, (event) => ({
            collapsed: event.collapsed,
            collapsedChildren: event.collapsedChildren,
        }));

        const layout = this.getLayout(config);
        const columns = couponType
            ? this.observableGridCustomColumnProvider.getCustomColumns(couponType, options, events)
            : this.observableGridProvider.getColumns(config, layout, options);
        const active = this.getActiveColumns(columns, state.columns);

        if (options && options.activeGrouping !== null && options.activeGrouping !== undefined) {
            const placeholder = this.getPlaceholderGroup(options.activeGrouping);

            columns.forEach((column, index) => {
                column.enabled = index === 0;

                if (column.enabled) {
                    column.groups.push(placeholder);
                    column.groups.forEach((group) => (group.active = group === placeholder));
                }
            });
        }

        grid = setStateCollapse({ ...grid, layout, columns }, state.groups, eventState);

        if (events?.length) {
            // for state sanity we clean up unneeded properties
            // mainly for event data
            if (!couponType) {
                events.forEach((event) => {
                    //no
                    //@ts-expect-error The operand of a 'delete' operator must be option
                    delete event.fixtureScoreboard;
                    delete event.splitFixtureIds;
                });
            }
            grid = addEvents(grid, events);

            if (options && options.collapsedThreshold) {
                grid = setGroupCollapseThreshold(grid, options.collapsedThreshold);
            }
        }

        if (config || couponType) {
            return setActiveGroupState(grid, active);
        }

        return grid;
    }

    private getBase(id: string, options: GridOption, sport: SportModel, isCustomGrid?: boolean): GridModel {
        let topic = options.subscriptionTopic === undefined ? SubscriptionTopic.Grid : options.subscriptionTopic;

        if (isNumber(options.activeGrouping) || isCustomGrid) {
            // when it is a number we expect to show non gridable markets
            topic = SubscriptionTopic.NonGridable;
        }

        return {
            id,
            grouping: options.grouping === undefined ? GridGrouping.League : options.grouping,
            sorting: options.sorting,
            columns: [],
            sport,
            groups: [],
            groupingThreshold: options.collapsedThreshold,
            layout: GridLayout.Default,
            media: { enabled: false, videoEvent: undefined, animationEvent: undefined, statsEvent: undefined },
            collapsed: {
                groups: [],
                events: {},
            },
            topic,
            disableGroupSorting: options.disableGroupSorting,
        };
    }

    private getLayout(config?: GridViewGroupingConfiguration, layout?: GridLayout): GridLayout {
        // exclude six pack as we need to check that it is available or not
        if (layout && layout !== GridLayout.SixPack) {
            return layout;
        }

        if (config) {
            if (!this.gridConfig.isSixPackLayoutEnabled || !config.sixPackGroups) {
                return GridLayout.Default;
            }

            if (config.sixPackGroups.length > 0 && config.sixPackGroups.some((group) => group.marketGroupIds.length > 0)) {
                return GridLayout.SixPack;
            }
        }

        return GridLayout.Default;
    }

    private getActiveColumns(columns: Column[], state: ColumnActiveState[]): IGridGroup[] {
        return columns
            .map((column, index) => {
                const columnState = state.find((current) => current.index === index);

                if (columnState) {
                    return { columnId: column.id, groupId: columnState.group };
                }

                return;
            })
            .filter(isDefined);
    }

    private getPlaceholderGroup(id: number | string): Group {
        return {
            id: id.toString(),
            name: this.sitecore.eventGrid.MarketSwitcher_More,
            active: false,
            extended: true,
            visible: false,
            isFallbackGroup: false,
            fallbackGridGroupIds: [],
        };
    }
}
