import { BetSlipType, Odds } from '@bpos';
import { GameType } from '@bpos/v1/sports-promo';
import { ReplaceableParameter } from '@bpos/v1/sports-promo/campaigns';

import { CampaignBanner, CampaignDetails } from './crm-offer.server.model';

// offer status
export interface OfferStatusRequest {
    id: string;
    type: string;
    source: string;
}
export interface OfferStatusResponse {
    status: string;
}
export enum OfferStatus {
    OptIn = 'OptIn',
    NoOptIn = 'NoOptIn',
    OptedIn = 'OptedIn',
    Expired = 'Expired',
    Error = 'Error',
}

export enum OfferStatusType {
    Promo = 'Promos',
    Eds = 'Eds',
}

// client entities
export interface Promotion {
    id: string;
    type: string;
    subType?: string;
    startDate?: Date;
    expiryDate?: Date;
    cmsItemId: string;
    priority?: number;
    callToAction?: CallToAction;
    status: OfferStatus;
    banner?: Banner;
    details?: Details;
    allFilters: Filter[];
    subPromotions: SubPromotion[];
    replaceableParameters: Record<string, ReplaceableParameter>;

    /**
     * Indicates that there's no Promotion filter but might have SubPromotion filters
     *
     * @type {boolean}
     * @memberof Promotion
     */
    noFilters: boolean;
    gameType: GameType;
}
export interface SubPromotion {
    id: string;
    order: number;
    startDate?: Date;
    endDate?: Date;
}
export enum CallToAction {
    OptIn = 'OptIn',
    NoOptIn = 'NoOptIn',
    OptedIn = 'OptedIn',
}
export enum TrackingSource {
    Teaser = 'Teaser',
    ShowCase = 'ShowCase',
}
export interface Filter {
    betSlipType?: BetSlipType;
    sportIds: number[];
    competitionIds: number[];
    meetingIds: number[];
    fixtureIds: string[];
    marketTemplateIds: string[];
    marketIds: number[];
    optionIds: number[];
    isEmpty: boolean;
    minSelections?: number;
    maxSelections?: number;
    minStake?: number;
    maxStake?: number;
    currency?: string;
    overallMinOdds?: Odds;
    overallMaxOdds?: Odds;
    selectionMinOdds?: Odds;
    selectionMaxOdds?: Odds;
    gameType?: GameType;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Banner extends CampaignBanner {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Details extends CampaignDetails {}
