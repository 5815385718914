import { Injectable } from '@angular/core';

import { CountItem } from '@frontend/sports/common/base-utils';
import { LiveSectionConfig } from '@frontend/sports/common/client-config-data-access';
import { NavigationBarItem } from '@frontend/sports/types/models/navigation-bar';
import { MenuItemsService } from '@frontend/vanilla/core';

import { AdaptiveLayoutService } from '../layout/adaptive-layout.service';
import { LiveUrlParam, UrlHelperService } from '../navigation-core/url-helper.service';
import { TrackNavArea, trackingConstants } from '../tracking/tracking.models';
import { TopMenuContentItem } from './navigation.models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LiveSportListModel extends BaseLiveSportsModel {}

export interface BaseLiveSportsModel {
    sports: CountItem[];
    tab: LiveUrlParam;
    videoEventCount: number;
}

export interface NavigationMappingOptions {
    keepLiveItem?: boolean;
    isFixedItem?: boolean;
}

export interface NavigationSportItem {
    id: number;
    name: string;
    separator?: boolean;
    isVirtual?: boolean;
}

@Injectable({ providedIn: 'root' })
export class NavigationItemsMapper {
    private topNavItemsToHideInMobile: string[] = ['all', 'mybets'];
    liveCounts = 0;
    constructor(
        private layoutService: AdaptiveLayoutService,
        private urlHelper: UrlHelperService,
        private liveConfig: LiveSectionConfig,
        private menuService: MenuItemsService,
    ) {}

    mapItems(items: NavigationBarItem[], sportsCountText: string, options?: NavigationMappingOptions): TopMenuContentItem[] {
        return items
            .filter((item) => item?.link)
            .filter((item) => !this.layoutService.current.bottomNavigation || !this.topNavItemsToHideInMobile.some((s) => s === item.activeName))
            .map((item) => {
                const pageNameFromUrl = this.urlHelper.getLastSegment(item.link);
                const pageName = item.trackingPagename || pageNameFromUrl;
                const badgeTextlength = sportsCountText.length;
                const className = this.getClassName(badgeTextlength);
                const topMenuItem = <TopMenuContentItem>{
                    name: item.activeName || pageNameFromUrl,
                    text: item.title,
                    url: item.isPopup ? '' : this.urlHelper.getUrl(item.link),
                    popup: item.isPopup ? item.link : '',
                    class: `sports-icon ${item.icon}`,
                    isSpecial: item.isSpecial,
                    trackEvent: this.getTrackingData(pageName),
                    parameters: this.getTooltipParams(item),
                    isSport: item.isSport,
                    resources: {},
                    ccbKey: item.ccbKey,
                    isFixed: options?.isFixedItem,
                    hasSeparator: item.icon?.indexOf('before-separator') >= 0,
                };

                if (item.iconSrc) {
                    topMenuItem.image = {
                        src: item.iconSrc,
                    };
                }
                if (item.showBadge && item.activeName !== 'live') {
                    this.setBadge(item.activeName || pageNameFromUrl, item.badgeText, 'topnav-new-badge');
                }
                if (this.liveConfig.showLiveBadgeCount.includes('BOTTOM') && sportsCountText) {
                    this.setBadgeBottom('live', sportsCountText, 'live-counts-refresh bottomnav-new-badge-live-count ' + className);
                }
                if (this.liveConfig.showLiveBadgeCount.includes('TOP') && sportsCountText && item.activeName === 'live') {
                    this.setBadge(
                        item.activeName || pageNameFromUrl,
                        sportsCountText,
                        'live-counts-refresh topnav-new-badge-live-count ' + className,
                    );
                }

                return topMenuItem;
            });
    }

    buildSportMenuItem(sport: NavigationSportItem): TopMenuContentItem {
        const sportId = sport.id.toString();
        const hasSeparator = sport.separator;

        return <TopMenuContentItem>{
            name: sportId,
            text: sport.name,
            url: sport?.isVirtual ? this.urlHelper.getVirtualSportUrl(sport) : this.urlHelper.getSportUrl(sport),
            class: `sports-icon sports-${sportId}`,
            trackEvent: this.getTrackingData(sportId),
            parameters: {},
            resources: {},
            isSport: true,
            hasSeparator,
        };
    }

    getTrackingData(pageName: string): { eventName: string; data: any } {
        return {
            eventName: trackingConstants.EVENT_NAVIGATION_MENUS,
            data: { [trackingConstants.PAGE_NAVIGATION_MENUS]: this.getTrackingValue(pageName, TrackNavArea.NavTop) },
        };
    }

    private getTrackingValue(trackingPagename: string, preFix?: string): string {
        return preFix ? `${preFix.toString()}_${trackingPagename}` : trackingPagename;
    }

    private getTooltipParams(item: NavigationBarItem): { [key: string]: string } {
        if (item.tooltip && item.tooltipClass) {
            return {
                'tooltip': item.tooltip,
                'tooltip-class': item.tooltipClass,
            };
        }

        return {};
    }

    private setBadge(itemName: string, badgeText: string | undefined, cssClass?: string): void {
        this.menuService.setCounter(TrackNavArea.NavTop, itemName, badgeText, cssClass);
    }

    private setBadgeBottom(itemName: string, badgeText: string | undefined, cssClass?: string): void {
        this.menuService.setCounter(TrackNavArea.NavBottom, itemName, badgeText, cssClass);
    }

    private getClassName(count: number): string {
        switch (count) {
            case 2:
                return 'livebadge-fontsize-sm';
            case 1:
                return 'livebadge-fontsize-md';
            case 0:
                return 'livebadge-fontsize-lg';
            default:
                return 'livebadge-fontsize-default';
        }
    }
}
