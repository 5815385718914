import { Injectable } from '@angular/core';

import { Sitecore } from '@frontend/sports/common/client-config-data-access';
import { IntlService } from '@frontend/vanilla/core';

@Injectable({ providedIn: 'root' })
export class DateFormatterService {
    private readonly MINUTE = 60 * 1000;
    private readonly HOUR = 60 * this.MINUTE;
    private readonly DAY = 24 * this.HOUR;

    private _formatMap: { [key: string]: { title: string; format: string[] } };

    constructor(
        private sitecore: Sitecore,
        private intlService: IntlService,
    ) {}

    get formatMap() {
        if (!this._formatMap) {
            this._formatMap = {
                today: { title: this.sitecore.globalMessages.Today, format: ['shortTime'] },
                tomorrow: { title: this.sitecore.globalMessages.Tomorrow, format: ['shortTime'] },
                other: { title: this.sitecore.globalMessages.TimerOther, format: ['shortDate'] },
            };
        }

        return this._formatMap;
    }

    formatDate(startDate: Date): string {
        const formatterKey = this.getFormatKey(startDate);
        const formatter = this.formatMap[formatterKey];

        return formatter.title.replace('{date}', formatter.format.map((f) => this.intlService.formatDate(startDate, f)).join(' ') || '');
    }

    formatDateWithTime(startDate: Date): string {
        return this.formatDate(startDate) + ' ' + this.intlService.formatDate(startDate, 'shortTime');
    }

    private getFormatKey(eventDate: Date): string {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        const tomorrow = new Date(today.getTime() + this.DAY);

        if (eventDate < today) {
            return 'today';
        }
        if (eventDate < tomorrow) {
            return 'tomorrow';
        }

        return 'other';
    }
}
