import { DomainEvent } from '@frontend/sports/common/dispatcher-utils';

export class UserSettingsResponse {
    oddsFormatLimitReached: boolean;
    succeeded?: boolean;
}
export interface IUserBettingSettings {
    defaultStake?: number;
    defaultStakeProposalReviewDate?: string;
    oddsAcceptanceMode?: string;
    betslipEmailNotification?: boolean;
    betslipAppNotification?: boolean;
    earlyPayoutAcceptanceMode?: any;
    keypadIncrementalStakes: number[];
    betslipSkipConfirmation?: boolean;
    freeBetsActivated?: boolean;
    earlyPayoutDeactivated?: boolean;
    quickBetEnabled?: boolean;
    isDefaultStakeUserDefined?: boolean;
    stakeChangeConsent?: number;
}
export interface IConfigSettings {
    minimumStake: number;
    minimumStakeSystem: number;
    minimumStakeUnit: number;
    minimumStakeSystemUnit: number;
    winningsTaxRate: number;
    allowedV2OfferSports: number[];
    oddsBoostForbiddenSportIds: number[];
    oddsBoostForbiddenGameTemplateIds: number[];
    oddsBoostForbiddenMarketTypes: string[];
    riskFreeForbiddenSportIds: number[];
    riskFreeForbiddenGameTemplateIds: number[];
    riskFreeForbiddenMarketTypes: string[];
    customerDnaProfileId?: string;
    gamblingControlMaxStake: number;
    winningsTaxThreshold: number;
    stakeTaxRate: number;
    minimumGrossStakeUnit: number;
    minimumGrossStakeSystemUnit: number;
    taxCalculation: string;
}

export const UserEvents = {
    /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
    ClaimsChanged: 'CLAIMS_CHANGED',
    M2UserClaimsChanged: 'm2-user-claimsChanged',
    UserSettingsChanged: new DomainEvent<IUserBettingSettings>('USER_SETTINGS_CHANGED'),
    ClientConfigRefreshed: new DomainEvent<void>('CLIENT_CONFIG_REFRESHED'),
};
