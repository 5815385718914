import { createAction, props } from '@ngrx/store';

import BetslipActions from '../../base/store/actions';
import { BetslipType } from '../../core/betslip-type';
import { LinearTypes } from '../../core/linear-types';
import { PickId } from '../../core/picks/pick-id';
import { SystemSlipType } from '../bet-generation/slip-type';
import { IBetslipTypeState } from './state';

export class BetslipTypesActions {
    static readonly ACTION_SCHEMA = `${BetslipActions.ACTION_SCHEMA}/types`;

    private static readonly SET_EACH_WAY = `${BetslipTypesActions.ACTION_SCHEMA}/SET_EACH_WAY`;
    private static readonly POPULATE_FROM_STORAGE = `${BetslipTypesActions.ACTION_SCHEMA}/POPULATE_FROM_STORAGE`;
    private static readonly SET_ACTIVE_TYPES = `${BetslipTypesActions.ACTION_SCHEMA}/SET_ACTIVE_TYPES`;
    private static readonly SET_LINEAR_TYPES = `${BetslipTypesActions.ACTION_SCHEMA}/SET_LINEAR_TYPES`;
    private static readonly SET_SELECTED_TYPE = `${BetslipTypesActions.ACTION_SCHEMA}/SET_SELECTED_TYPE`;
    private static readonly TRIGGER_AUTO_COMBO_SWITCH = `${BetslipTypesActions.ACTION_SCHEMA}/TRIGGER_AUTO_COMBO_SWITCH`;
    private static readonly SET_ORIGINAL_BASE = `${BetslipTypesActions.ACTION_SCHEMA}/SET_ORIGINAL_BASE`;
    private static readonly SET_FROM_ORIGINAL_BASE = `${BetslipTypesActions.ACTION_SCHEMA}/SET_FROM_ORIGINAL_BASE`;
    private static readonly SET_DEFAULT_ORIGINAL_BASE = `${BetslipTypesActions.ACTION_SCHEMA}/SET_DEFAULT_ORIGINAL_BASE`;
    private static readonly SET_SELECTED_BY_USER = `${BetslipTypesActions.ACTION_SCHEMA}/SET_SELECTED_BY_USER`;
    private static readonly CHECK_DEFAULT_STAKE = `${BetslipTypesActions.ACTION_SCHEMA}/CHECK_DEFAULT_STAKE`;

    private static readonly SET_STAKE = `${BetslipTypesActions.ACTION_SCHEMA}/SET_STAKE`;

    private static readonly SET_DEFAULT_STAKE = `${BetslipTypesActions.ACTION_SCHEMA}/SET_DEFAULT_STAKE`;

    /**
     * Sets each way state to all betslip types
     *
     * @param isEachWay isEachWay value.
     */
    static setEachWay = createAction(BetslipTypesActions.SET_EACH_WAY, props<{ isEachWay: boolean; pickIds?: PickId[] }>());

    /**
     * @description Invoked from the epic when startPopulateStore is executed (application init / page refresh).
     * Reducer will update the state with values from the session storage.
     * @param IBetslipTypeState betslip type state
     */
    static populateFromStorage = createAction(BetslipTypesActions.POPULATE_FROM_STORAGE, props<{ state: IBetslipTypeState }>());

    static setActiveTypes = createAction(BetslipTypesActions.SET_ACTIVE_TYPES, props<{ betslipTypes: BetslipType[] }>());

    static setSelectedType = createAction(
        BetslipTypesActions.SET_SELECTED_TYPE,
        // Previous type is a bit of a hack to fix a regression defect, there needs to be a proper implementation for it
        // The defect is related to handleToggleExistingBetslipSelectionsEffect because we are firing set selected type even when the type isnt changing.
        props<{ betslipType: BetslipType | null; systemInfo: SystemSlipType | null; selectedByUser: boolean; previousType?: BetslipType | null }>(),
    );

    static triggerAutoComboSwitch = createAction(BetslipTypesActions.TRIGGER_AUTO_COMBO_SWITCH);

    static setLinearTypes = createAction(BetslipTypesActions.SET_LINEAR_TYPES, props<{ types: LinearTypes }>());

    static setOriginalBase = createAction(BetslipTypesActions.SET_ORIGINAL_BASE);

    static setFromOriginalBase = createAction(BetslipTypesActions.SET_FROM_ORIGINAL_BASE);

    static setDefaultOriginalBase = createAction(BetslipTypesActions.SET_DEFAULT_ORIGINAL_BASE);
    static checkDefaultStake = createAction(
        BetslipTypesActions.CHECK_DEFAULT_STAKE,
        props<{ isValidComboBet: boolean; hasBetBuilderPicks: boolean; isQuickBetBuilder: boolean }>(),
    );

    static setSelectedByUser = createAction(BetslipTypesActions.SET_SELECTED_BY_USER, props<{ isSelectedByUser: boolean }>());

    static setStake = createAction(BetslipTypesActions.SET_STAKE, props<{ stake: number | null; silent?: boolean; stakeStr?: string }>());

    static setDefaultStake = createAction(BetslipTypesActions.SET_DEFAULT_STAKE, props<{ defaultStake?: number }>());
}
