import { FixtureType } from '@cds/betting-offer';
import { TennisSurface } from '@cds/betting-offer/add-ons';
import { Timer } from '@cds/scoreboards/v1/shared';
import { Formula1Weather } from '@cds/scoreboards/v1/sports';
import {
    AmericanFootballQuarters,
    BasketballQuarters,
    FootballPeriodInfo,
    IceHockeyPeriods,
    SportConstant,
} from '@frontend/sports/common/base-utils';
import { ceil, times, values } from 'lodash-es';

import { EventModel } from './event.model';

export type EventScore =
    | PairGameScore
    | SetGameScore
    | DartsGameScore
    | FootballScore
    | TennisScore
    | TournamentScore
    | LeaderboardGameScore
    | SnookerScore
    | IcehockeyPeriodGameScore
    | PeriodGameScore
    | CricketScore;

export type EventStatistics =
    | FootballStats
    | TennisStats
    | BasketballStatistics
    | IcehockeyStatisticsEntry
    | AmericanFootballStatistics
    | BaseballStatistics
    | SnookerStats;

export type GeneralTournamentType = 'km' | 'Stage' | 'Lap';

export enum Turn {
    None = 0,
    Player01 = 1,
    Player02 = 2,
}

export enum Weather {
    Sunny,
    Cloudy,
    LightRain,
    HeavyRain,
}

export enum DartsMatchType {
    LegOnly = 0,
    SetsAndLegs = 1,
}

export enum TeamType {
    Both = 2,
    Team01 = 0,
    Team02 = 1,
}

export enum MatchType {
    BestOfFive = 1,
    BestOfFiveChampionsTieBreak = 3,
    BestOfThree = 0,
    BestOfThreeChampionsTieBreak = 2,
}

export interface IconInfo {
    name?: string;
    color: string;
    highlight?: boolean;
}

export interface Message {
    time: string;
    text: string;
    type: number;
    icon: IconInfo | null;
    order: number;
    teamType?: TeamType;
}

export enum ComboPreventionMinimum {
    Combo2 = 2,
    Combo3 = 3,
    Combo4 = 4,
    Combo5 = 5,
    Combo6 = 6,
    Single = 1,
}

export enum ComboPreventionType {
    AllCombo = 1,
    AllLiveEvents = 8,
    NoCombo = 2,
    NoCompetitionCombo = 10,
    NoEventCombo = 6,
    NoEventSameLeagueCombo = 7,
    NoFixtureCombo = 11,
    NoGroupCombo = 12,
    NoLeagueCombo = 4,
    NoSportCombo = 3,
    OtherLiveEvents = 9,
}

export function toWeather(weather: Formula1Weather | null | undefined): Weather | null {
    if (weather == null) {
        return null;
    }

    switch (weather) {
        case Formula1Weather.Sunny:
            return Weather.Sunny;
        case Formula1Weather.Cloudy:
            return Weather.Cloudy;
        case Formula1Weather.LightRain:
            return Weather.LightRain;
        case Formula1Weather.HeavyRain:
            return Weather.HeavyRain;
    }
}

export function isBespokeScoreboard(event: EventModel): boolean {
    const scoreboardType = getScoreboardType(event);

    return (
        [
            ScoreboardType.BaseballGame,
            ScoreboardType.PairGame,
            ScoreboardType.PeriodGame,
            ScoreboardType.Cricket,
            ScoreboardType.PreMatchPairGame,
        ].indexOf(scoreboardType) > -1
    );
}

export function isBespokeSport(sportId: SportConstant, isEsport?: boolean): boolean {
    return (
        [
            SportConstant.Soccer,
            SportConstant.Baseball,
            SportConstant.AmericanFootball,
            ScoreboardType.Cricket,
            SportConstant.Basketball,
            SportConstant.Icehockey,
        ].indexOf(sportId) > -1 || !!isEsport
    );
}

export function getScoreboardType(event: EventModel): ScoreboardType {
    if (event.scoreboard.started) {
        if (event.fixtureType === FixtureType.Tournament) {
            return ScoreboardType.TournamentGame;
        }
        if (event.participants.length !== 2 && event.scoreboard.type !== ScoreboardType.LeaderboardGame) {
            return ScoreboardType.TournamentGame;
        }

        return event.scoreboard.type;
    } else {
        if (
            event.fixtureType === FixtureType.Tournament ||
            event.participants.length !== 2 ||
            event.scoreboard.type === ScoreboardType.LeaderboardGame
        ) {
            return ScoreboardType.PreMatchTournament;
        }

        return ScoreboardType.PreMatchPairGame;
    }
}

export enum ScoreboardType {
    PairGame = 'PairGame',
    SetGame = 'SetGame',
    BaseballGame = 'BaseballGame',
    TournamentGame = 'TournamentGame',
    LeaderboardGame = 'LeaderboardGame',
    PeriodGame = 'PeriodGame',
    Darts = 'Darts',
    Snooker = 'Snooker',
    SpecialGame = 'SpecialGame',
    Cricket = 'Cricket',
    PreMatchPairGame = 'PreMatchPairGame',
    PreMatchTournament = 'PreMatchTournament',
}

export enum SportType {
    PairGame = 'PairGame',
    Football = 'Football',
    Tennis = 'Tennis',
    Badminton = 'Badminton',
    LeaderboardGame = 'LeaderboardGame',
    Snooker = 'Snooker',
    Baseball = 'Baseball',
    SetGame = 'SetGame',
    Tournament = 'Tournament',
    PeriodGame = 'PeriodGame',
    Darts = 'Darts',
    SpecialGame = 'SpecialGame',
    Cricket = 'Cricket',
}

export class EventScoreboard {
    id: string;
    score?: EventScore;
    started: boolean;
    type: ScoreboardType;
    sportType: SportType;
    setScore?: string;
    bestOfSets?: number;
    bestOfLegs?: number;
    matchType?: number;
    period?: { id?: number; name: string };
    messages?: Message[] | undefined;
    time?: string;
    timeIndicator?: string;
    visible?: boolean;
    bookieTicker?: string;
    gameTimeInSeconds?: number;
    isGeneralTournament?: boolean;
    isFinished?: boolean;
    isSuspended?: boolean;
    statistics?: EventStatistics;
    colors?: string[];
    bestOf: number;
    timer?: Timer;

    get timerVisible(): boolean {
        if (this.timer && this.timer.visible) {
            return true;
        }

        return this.timeIndicator !== undefined;
    }

    get timerRunning(): boolean {
        return !!this.timer && this.timer.running;
    }

    getScore<T extends EventScore>(): T | undefined {
        return this.score as T;
    }

    getStats<T extends EventStatistics>(): T {
        return this.statistics as T;
    }
}

export interface PairScore<T> {
    participant1: T;
    participant2: T;
}

export interface ParticipantSetScore {
    sets: SetScore[];
    points?: string;
    r?: number;
}

export interface ParticipantDartsScore {
    sets: DartsScore[];
    points?: string;
}

export class PairGameScore implements PairScore<number> {
    participant1: number;
    participant2: number;
    hasServing: boolean;
    period: string;
    currentlyServing: number; // TODO : should have type 'Turn'
}

export class PeriodGameScore implements PairScore<ParticipantPeriodScore> {
    participant1: ParticipantPeriodScore;
    participant2: ParticipantPeriodScore;
    playerInfo: any;
    turn?: Turn;
    matchType: number;
}

export class IcehockeyPeriodGameScore extends PeriodGameScore {
    penalties: PairScore<number>;
    penaltyShootOutScore?: PairScore<PenaltyRound[]>;
}

export interface ParticipantPeriodScore {
    periods: PeriodScore[];
    overTimeScore: number | undefined;
    total: number;
}

export interface PeriodScore {
    points: number | string;
    current: boolean;
}

export interface SetScore {
    points: number | string;
    current: boolean;
    tieBreakPoints?: string | null;
    setWon: boolean;
    isFutureSet?: boolean;
}

export interface DartsScore {
    points: number;
    current: boolean;
}

export class SetGameScore implements PairScore<ParticipantSetScore> {
    participant1: ParticipantSetScore;
    participant2: ParticipantSetScore;
    participant1WonSets: number;
    participant2WonSets: number;
    currentlyServing: Turn;
    slimScoreboard?: boolean;
    setCount: number;
    period: string;
    gameMode: string;

    get setScore(): string {
        if (this.participant1WonSets >= 0 && this.participant2WonSets >= 0) {
            return `${this.participant1WonSets}:${this.participant2WonSets}`;
        }

        return '';
    }

    get setPoint(): string {
        const index = this.participant1WonSets + this.participant2WonSets;
        if (
            this.participant1.sets &&
            this.participant1.sets.length > 0 &&
            this.participant2.sets &&
            this.participant2.sets.length > 0 &&
            index < this.participant1.sets.length
        ) {
            return `${this.participant1.sets[index].points}:${this.participant2.sets[index].points}`;
        }

        return '';
    }
}

export class DartsGameScore implements PairScore<number> {
    participant1: number;
    participant2: number;
    hundredEighties: PairScore<number>;
    legs: PairScore<number>;
    currentlyServing: number;
    bestOfLegs: number;
    slimScoreboard?: boolean;
    setCount: number;
    period: string;
    score: string;
    matchType: DartsMatchType;
}

export class FootballScore implements PairScore<number> {
    participant1: number;
    participant2: number;
    finishedAfterRegularTime?: boolean;
    regularTimeOver?: boolean;
    regularTime?: PairScore<number>;
    firstHalfScore?: PairScore<number>;
    overTime?: PairScore<number>;
    penaltyShootOutScore?: PairScore<PenaltyRound[]>;
}

export interface PenaltyRound {
    round: number;
    status: PenaltyStatus;
}

export enum PenaltyStatus {
    Unset = 'Unset',
    Scored = 'Scored',
    Missed = 'Missed',
}

export interface FootballStatsEntry extends BaseStatisticsEntry {
    firstHalf?: PairScore<number>;
    secondHalf?: PairScore<number>;
    extraTime?: PairScore<number>;
    totalPoints?: PairScore<number>;
}

export interface FootballStats {
    goals?: FootballStatsEntry;
    redcards?: FootballStatsEntry;
    yellowcards?: FootballStatsEntry;
    penalties?: FootballStatsEntry;
    corners?: FootballStatsEntry;
    substitutions?: FootballStatsEntry;
}
export class TennisScore extends SetGameScore {
    surfaceType: TennisSurface;
    winPoints: PairScore<number>;
    matchType: number;

    override get setScore(): string {
        if (this.participant1WonSets >= 0 && this.participant2WonSets >= 0) {
            return `${this.participant1WonSets}:${this.participant2WonSets}`;
        }

        return '';
    }
}

export class TournamentScore {
    type: GeneralTournamentType;
    final?: number;
    current?: number;
    participants: string[];
    participantsDetails: KeyValuePairScoreboard[];
}
export interface KeyValuePairScoreboard {
    [key: string]: string;
}
export class LeaderboardGameScore extends TournamentScore {
    preRace: boolean;
    periodId: number;
    weather: Weather | null = null;
    safetyCar: boolean;
    period: string;
    override participants: string[];
    override participantsDetails: KeyValuePairScoreboard[] = [];
}

export class SnookerScore implements PairScore<number> {
    participant1: number;
    participant2: number;
    frames: PairScore<number>;
    currentlyServing: number;
    score: number;
}

export class CricketScore implements PairScore<number> {
    participant1: number;
    participant2: number;
    currentlyServing: number;
    balls: number;
    overs: number;
    runs: number;
    wickets: number;
    firstInnings?: CricketInningsData;
    secondInnings?: CricketInningsData;
    superOver?: CricketInningsData;
}

export interface TeamShootout {
    penalties: PenaltyStatus[];
    points: number;
}

export interface CricketInningScore {
    runs: number;
    wickets: number;
    overs?: number;
    balls?: number;
    isScoringTeam?: boolean;
}

export interface CricketInningsData {
    team1: CricketInningScore;
    team2: CricketInningScore;
}

export enum CricketInnings {
    FirstInning = 1,
    SecondInning = 2,
    ThirdInning = 3,
    FourthInning = 4,
    FirstSuperOver = 5,
    SecondSuperOver = 6,
}

export interface BasePosition {
    hasFirstBase: boolean;
    hasSecondBase: boolean;
    hasThirdBase: boolean;
    order: string;
}

export const enum OrderType {
    Team01 = 'Team01',
    Team02 = 'Team02',
}

export interface BasketballStatisticsEntry extends BaseStatisticsEntry {
    firstQuarter?: PairScore<number>;
    secondQuarter?: PairScore<number>;
    thirdQuarter?: PairScore<number>;
    fourthQuarter?: PairScore<number>;
    points?: PairScore<number>;
}
export interface BasketballStatistics {
    points?: BasketballStatisticsEntry;
    threePointers?: BasketballStatisticsEntry;
    twoPointers?: BasketballStatisticsEntry;
    freeThrows?: BasketballStatisticsEntry;
    freeThrowsTotal?: BasketballStatisticsEntry;
    fouls?: BasketballStatisticsEntry;
    rebounds?: BasketballStatisticsEntry;
    assists?: BasketballStatisticsEntry;
    raceTo20?: BasketballStatisticsEntry;
    raceTo10?: BasketballStatisticsEntry;
}

export interface IcehockeyStatisticsEntry {
    penaltyTotalTime?: BaseStatisticsEntry;
    penaltyPoints?: BaseStatisticsEntry;
    penaltyShots?: BaseStatisticsEntry;
}

export interface SnookerStats {
    redBalls?: BaseStatisticsEntry;
    blackBalls?: BaseStatisticsEntry;
    pinkBalls?: BaseStatisticsEntry;
    greenBalls?: BaseStatisticsEntry;
    blueBalls?: BaseStatisticsEntry;
    brownBalls?: BaseStatisticsEntry;
    yellowBalls?: BaseStatisticsEntry;
    fouls?: BaseStatisticsEntry;
    frames?: BaseStatisticsEntry;
    century100Breaks?: BaseStatisticsEntry;
    breaks50?: BaseStatisticsEntry;
    bestOf?: BaseStatisticsEntry;
}
export interface BaseStatisticsEntry {
    regularTime?: PairScore<number>;
}

export interface AmericanFootballStatistics {
    turnovers?: BaseStatisticsEntry;
    penalties?: BaseStatisticsEntry;
    touchdowns?: BaseStatisticsEntry;
    fieldGoals?: BaseStatisticsEntry;
    safeties?: BaseStatisticsEntry;
    onePointConversions?: BaseStatisticsEntry;
    twoPointsConversions?: BaseStatisticsEntry;
    activeDown?: string;
    yardsDistance: number;
    yardsToNextDown: number;
    timeoutsLeft: TeamTimeoutsLeft[];
    timeValue: string;
}

export interface TennisStatsEntry {
    firstSet?: PairScore<number>;
    secondSet?: PairScore<number>;
    thirdSet?: PairScore<number>;
    fourthSet?: PairScore<number>;
    fifthSet?: PairScore<number>;
    regularTime: PairScore<number>;
}

export interface TennisStats {
    aces?: TennisStatsEntry;
    doubleFaults?: TennisStatsEntry;
    breaks?: TennisStatsEntry;
    tieBreaks?: TennisStatsEntry;
    winPointsPercentage?: TennisStatsEntry;
    raceTo15?: TennisStatsEntry;
    raceTo20?: TennisStatsEntry;
}

export interface BaseballStatistics {
    grandSlams?: BaseStatisticsEntry;
    homeRun?: BaseStatisticsEntry;
    outs?: BaseStatisticsEntry;
    scoreDetailed?: BaseStatisticsEntry;
    bases?: BasePosition[];
    balls?: number;
    strikes?: number;
}
export interface TeamTimeoutsLeft {
    firstHalf: number;
    overtime: number;
    secondHalf: number;
    teamOrder: string;
}

export enum SpecialChar {
    Comma = ',',
    Greater = '>',
    Less = '<',
    Hyphen = '-',
    Slash = '/',
}

export interface PeriodGameLiveScoreboardViewModel {
    score: PeriodGameScore;
    periodScore: ParticipantPeriodScore[];
    showPenalties: boolean;
    penalties: TeamShootout[];
    isSuspended?: boolean;
    showOvertime?: boolean;
    isOvertimeActive?: boolean;
    icon?: string;
}

export interface PairGameScoreboardViewModel {
    score: PairGameScore;
    showPenalties: boolean;
    penalties: TeamShootout[];
    regularTime?: PairScore<number> | undefined;
    overTime?: PairScore<number> | undefined;
    redCards?: number[] | undefined;
    yellowCards?: number[] | undefined;
    firstHalfScore?: PairScore<number> | undefined;
    isSuspended?: boolean;
    isSecondHalf?: boolean;
}

export function getSoccerPenalties(event: EventModel, isSoccer: boolean): TeamShootout[] {
    const visible = 5;
    const score = event.scoreboard.score as FootballScore;
    if (isSoccer && score.penaltyShootOutScore !== undefined) {
        const teams = values(score.penaltyShootOutScore);
        const offset = ceil(teams[0].length / visible - 1) * visible;

        return teams.map((team, i) => ({
            points: team.filter((penalty) => penalty.status === PenaltyStatus.Scored).length,
            penalties: times(visible).map((_penalty, index) => (team[index + offset] ? team[index + offset].status : PenaltyStatus.Unset)),
        }));
    }

    return [];
}

export function getIcehockeyPenalties(event: EventModel, visibleCount: number): TeamShootout[] {
    const score = event.scoreboard.getScore<IcehockeyPeriodGameScore>();
    if (score && score.penaltyShootOutScore) {
        const teams = values(score.penaltyShootOutScore);
        const offset = ceil(teams[0].length / visibleCount - 1) * visibleCount;

        return teams.map((team, i) => ({
            points: team.filter((penalty) => penalty.status === PenaltyStatus.Scored).length,
            penalties: times(visibleCount).map((_penalty, index) => (team[index + offset] ? team[index + offset].status : PenaltyStatus.Unset)),
        }));
    }

    return [];
}

export function getOvertime(event: EventModel): boolean {
    const score = event.scoreboard.getScore<PeriodGameScore>()!;
    if (!score) {
        return false;
    }
    let overTimePeriod = 0;
    switch (event.sport.id) {
        case SportConstant.Icehockey:
            overTimePeriod = IceHockeyPeriods.Overtime;
            break;
        case SportConstant.AmericanFootball:
            overTimePeriod = AmericanFootballQuarters.Overtime;
            break;
        case SportConstant.Basketball:
            overTimePeriod = BasketballQuarters.Overtime;
            break;
    }

    return !!(event.scoreboard.period?.id === overTimePeriod || score.participant1.overTimeScore || score.participant2.overTimeScore);
}

export function regularTime(event: EventModel, isSoccer: boolean): PairScore<number> | undefined {
    if (event.scoreboard.period && event.scoreboard.period.id) {
        const score = event.scoreboard.getScore<FootballScore>()!;

        if (isSoccer && (!!score.finishedAfterRegularTime || !!score.regularTimeOver)) {
            return score.regularTime;
        }
    }

    return;
}

export function firstHalfScore(event: EventModel, isSoccer: boolean): PairScore<number> | undefined {
    if (isSoccer && event.scoreboard.period && event.scoreboard.period.id === FootballPeriodInfo.SecondHalf) {
        const score = event.scoreboard.getScore<FootballScore>()!;

        return score.firstHalfScore;
    }

    return;
}

export function getOverTime(event: EventModel, isSoccer: boolean): PairScore<number> | undefined {
    if (event.scoreboard.period && event.scoreboard.period.id) {
        const score = event.scoreboard.getScore<FootballScore>()!;

        if (isSoccer && (!!score.finishedAfterRegularTime || !!score.regularTimeOver)) {
            return score.overTime;
        }
    }

    return;
}

export function getRedCards(event: EventModel, isSoccer: boolean, isNewEDPScoreboard: boolean): number[] | undefined {
    if (isSoccer && event.scoreboard.statistics) {
        const redCards = (event.scoreboard.statistics as FootballStats).redcards;
        if (isNewEDPScoreboard) {
            return redCards && redCards.totalPoints
                ? redCards.regularTime
                    ? [redCards.regularTime.participant1, redCards.regularTime.participant2]
                    : [redCards.totalPoints.participant1, redCards.totalPoints.participant2]
                : undefined;
        }

        return redCards && redCards.totalPoints ? [redCards.totalPoints.participant1, redCards.totalPoints.participant2] : undefined;
    }

    return;
}

export function getYellowCards(event: EventModel, isSoccer: boolean): number[] | undefined {
    if (isSoccer && event.scoreboard.statistics) {
        const yellowCards = (event.scoreboard.statistics as FootballStats).yellowcards;

        return yellowCards && yellowCards.totalPoints ? [yellowCards.totalPoints.participant1, yellowCards.totalPoints.participant2] : undefined;
    }

    return;
}

export function getBackgroundColor(event: EventModel, useSolid: boolean): string {
    const color = getBackgroundColorInternal(event);

    return color && useSolid ? `${color}-solid` : color;
}

export function showPenalties(score: IcehockeyPeriodGameScore | FootballScore): boolean {
    return score && score.penaltyShootOutScore !== undefined;
}

export function getPairGameScore(event: EventModel, isNewScoreboard: boolean = false): PairGameScoreboardViewModel {
    const isSoccer = event.sport.id === SportConstant.Soccer;
    const score = event.scoreboard.getScore<PairGameScore>()!;

    return {
        score,
        showPenalties: showPenalties(score),
        penalties: getSoccerPenalties(event, isSoccer),
        regularTime: regularTime(event, isSoccer),
        overTime: getOverTime(event, isSoccer),
        redCards: getRedCards(event, isSoccer, isNewScoreboard),
        yellowCards: getYellowCards(event, isSoccer),
        firstHalfScore: firstHalfScore(event, isSoccer),
        isSuspended: event.scoreboard.period && event.scoreboard.period.id === 256,
        isSecondHalf: isSoccer && event.scoreboard.period && event.scoreboard.period.id === FootballPeriodInfo.SecondHalf,
    };
}

function getBackgroundColorInternal(event: EventModel): string {
    let color;
    const score = event.scoreboard.score;

    if (score && score instanceof TennisScore) {
        const colorMap = {
            [TennisSurface.Clay]: 'brown',
            [TennisSurface.Grass]: 'grass',
            [TennisSurface.HardCourt]: 'waterblue',
            [TennisSurface.IndoorCourt]: 'waterblue',
        };

        color = colorMap[score.surfaceType];
    } else {
        color = getSportBackgroundColor(event.sport.id);
    }

    return color;
}

function getSportBackgroundColor(sportId: number): string {
    let color = 'black';
    const bgColors = {
        grass: [4, 11, 13, 22, 23, 31, 32, 33, 35, 36, 47, 48, 53, 58, 80, 88],
        gray: [9, 12, 27, 64, 65, 68, 69, 72, 81, 82, 83, 86, 94, 95, 96],
        umber: [7, 17, 18, 42, 43, 44, 50, 63, 73, 75, 77, 85, 89, 91, 93, 97],
        waterblue: [16, 24, 28, 38, 49, 51, 52, 54, 55, 56, 59, 66, 70, 92],
        road: [6, 10, 39, 40, 41],
        generic: [5, 25, 26, 29, 30, 34, 37, 45, 46, 57, 60, 61, 62, 67, 71, 74, 76, 78, 79, 84, 87, 90, 100],
        purple: [105, 106, 107, 108],
    };
    for (const key in bgColors) {
        if (bgColors[key].indexOf(sportId) > -1) {
            color = key;
            break;
        }
    }

    return color;
}

export function getScorePoints(setPoint?: string): string | undefined {
    return setPoint?.includes('ADV') ? 'AD' : setPoint;
}
