<ng-container *ngIf="items?.length">
    <ms-scroll-adapter *ngIf="isCarouselLayout; else promoItemTemplate" [scrollItems]="items" [buttonSize]="36">
        @if (isMiniCarouselLayout) {
            <ul>
                <li class="mini-carousel-layout">
                    <ng-container *ngTemplateOutlet="promoItemTemplate"></ng-container>
                </li>
            </ul>
        } @else {
            <ng-container *ngTemplateOutlet="promoItemTemplate"></ng-container>
        }
    </ms-scroll-adapter>
</ng-container>
<ng-template #promoItemTemplate>
    <ms-promo-item
        *ngFor="let item of items; trackBy: itemIndexTrackBy"
        [layout]="layout"
        [title]="item.title"
        [icon]="item.icon"
        [arrow]="arrow"
        [href]="item.href"
        [popup]="item.popup"
        [leagueTitle]="item.leagueTitle"
        [leagueIcon]="item.leagueIcon"
        [ngClass]="itemClass"
        [class.us-logo]="hasCompetitionLogo(item.sportId, item.leagueId)"
        [class.has-bgcolor]="hasBgcolorForTilesOrGridView(item)"
        [ngStyle]="hasBgcolorForTilesOrGridView(item) ? { 'background-color': item.bgColor, 'border-color': item.bgColor } : {}"
        [tracking]="item.tracking || 'M2_quicklinks'"
        (click)="clicked(item)"
        [badgesEnabled]="areBadgesEnabled(item)">
        <ms-competition-logo
            item-icon
            *ngIf="hasCompetitionLogo(item.sportId, item.leagueId)"
            [sportId]="item.sportId"
            [leagueId]="item.leagueId"
            [fallbackIcon]="item.icon"
            [ngClass]="{ 'expand-logo': !item.title }">
        </ms-competition-logo>
        <img item-coloured-icon [src]="item.colouredIcon" *ngIf="item.colouredIcon" class="coloured-icon" />
    </ms-promo-item>
</ng-template>
