import { Decimal } from 'decimal.js';
import { round } from 'lodash-es';

/**
 * @param value odds value in decimal (EU odds)
 * @returns Decimal value of american odds (moneyline).
 */
export function decimalOddsToAmerican(value: Decimal): Decimal {
    const PRECISION = 10;

    const roundedValue = round(value.toNumber(), PRECISION);
    const decimal = roundedValue >= 2 ? value.minus(1).times(100) : new Decimal(-100).dividedBy(value.minus(1));

    return new Decimal(round(decimal.toNumber(), PRECISION));
}

/**
 * @param american odds
 * @param isUsOddsRounding explicit rounding
 * @returns Rounded value of american odds
 */

export function roundAmericanOdds(american: Decimal, isUsOddsRounding: boolean = false) {
    return isUsOddsRounding ? american.toNearest(5).toNumber() : american.floor().toNumber();
}

/**
 * @param value EU odds to be converted to US odds
 * @param isUsOddsRounding explicit rounding
 * @returns number us odds value in number
 */
export function roundDecimalToAmerican(value: Decimal, isUsOddsRounding: boolean = false): number {
    const american = decimalOddsToAmerican(value);

    return roundAmericanOdds(american, isUsOddsRounding);
}
