import { EventEmitter, Injectable, Type } from '@angular/core';

import { MarqueeBackgroundContainerConfig } from '@frontend/sports/types/components/content';
import { MarqueeLink } from '@frontend/sports/types/components/widget/types';
import { StylesService } from '@frontend/vanilla/core';

import { Promotion, TrackingSource } from '../crm-offer-data/crm-offer.model';
import { EventModel, EventOptionGroup } from '../event-model/model/event.model';
import { type HighlightsMarqueePage } from '../highlights-marquee/highlights-marquee-page.enum';
import type { HighlightsMarqueeComponent } from '../highlights-marquee/highlights-marquee.component';
import { type HighlightsMarqueeModule } from '../highlights-marquee/highlights-marquee.module';
import type { FixtureTile, ScoreDetails, TileOrPromotion } from '../highlights-marquee/marquee-tile.model';
import { LoggerFactory } from '../logging/logger-factory.service';
import { TrackingSource as TrackingSourceData } from '../tracking/tracking.models';
import { ComponentLoaderService, ComponentProxy } from './component-loader.service';
import ModuleLoaderService from './module-loader.service';

export interface HighLightsMarqueeBinding {
    tiles: TileOrPromotion[];
    promotionTiles: Promotion[];
    preventNavigation: boolean;
    showLoader: boolean;
    scrollingSpeed: { [key: string]: string };
    promotionTrackingSource: TrackingSource;
    page?: HighlightsMarqueePage;
    backgroundContainerConfig: MarqueeBackgroundContainerConfig;
    globalLinkConfig: MarqueeLink;
    allowedSportsWithLogo: number[];
}

export interface OptionGroupBinding {
    event: EventModel;
    optionGroup: EventOptionGroup;
    isOneWaySpecial: boolean;
    optionNames?: string[];
    showDropDown: boolean;
    hasImage: boolean;
    tracking: TrackingSourceData;
    disableOdds: boolean;
    isPriceBoosted: boolean;
}
export interface BetNowButtonBinding {
    event: EventModel;
}
export interface OddIncrementDecrementBinding {
    position: number;
    participantScore: number;
    participantLiveScore: number;
    changeScoreEvent: EventEmitter<ScoreDetails>;
}

export interface SpecialMarqueeBadgeBinding {
    tile: FixtureTile;
    showIcon?: boolean;
}

@Injectable({ providedIn: 'root' })
export class HighlightsMarqueeComponentLoaderService extends ComponentLoaderService<HighlightsMarqueeModule> {
    moduleName = 'HighlightsMarqueeModule';

    constructor(moduleLoader: ModuleLoaderService, loggerFactory: LoggerFactory, stylesService: StylesService) {
        super(moduleLoader, loggerFactory, stylesService);
    }

    getHighlightsMarqueeComponent(): ComponentProxy<HighlightsMarqueeComponent> {
        return this.getComponentProxy((module) => module.highlightsMarqueeComponent);
    }

    getMarqueeOptionGroupComponent(): ComponentProxy {
        return this.getComponentProxy((module) => module.marqueeOptionGroupComponent);
    }

    getBetNowButtonComponent(): ComponentProxy {
        return this.getComponentProxy((module) => module.betNowButtonComponent);
    }

    getSpecialMarqueeBadgeComponent(): ComponentProxy {
        return this.getComponentProxy((module) => module.specialMarqueeBadgeComponent);
    }

    getModule(): Promise<Type<HighlightsMarqueeModule>> {
        return import(/* webpackChunkName: "ms-highlights-marquee" */ '../highlights-marquee/highlights-marquee.module').then(
            (m) => m.HighlightsMarqueeModule,
        );
    }
}
