import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges } from '@angular/core';

import { ImageProfile } from '@cds/betting-offer';
import { ISimpleChanges } from '@frontend/sports/common/base-utils';
import { ImagesConfig } from '@frontend/sports/common/client-config-data-access';

import { ImageRootPath } from '../../participant-image/participant-image.component';
import { CompetitionLogosService } from '../competition-logos.service';

@Component({
    selector: 'ms-competition-logo',
    templateUrl: './competition-logo.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionLogoComponent implements OnChanges {
    @Input() sportId: number;
    @Input() leagueId: number | string;
    @Input() fallbackIcon?: string;
    @Input() isWithRightImage?: boolean;
    @Input() isLogoOverrideEnabled?: boolean;
    @Input() imageProfile?: ImageProfile;
    @Input() showLogoCircle = false;

    @HostBinding('class') get hostImage(): string {
        const cls = ['competition-logo-container'];
        if (this.isWithRightImage) {
            cls.push('right-image');
        }

        return cls.join(' ');
    }

    competitionLogoUrl: string;
    logoLoaded = false;
    loadingError = false;
    showBackgroundCircle = false;
    fallbackColor = false;
    logoColor?: string;

    constructor(
        private competitionLogosService: CompetitionLogosService,
        private changeDetectorRef: ChangeDetectorRef,
        private imagesConfig: ImagesConfig,
    ) {}

    ngOnChanges(changes: ISimpleChanges<CompetitionLogoComponent>): void {
        this.showBackgroundCircle =
            this.showLogoCircle && !!this.imageProfile && this.imagesConfig.isLogoBackgroundEnabled && this.imagesConfig.isCompetitionImageProfile;
        const { sportId, leagueId, imageProfile } = changes;
        if (
            (sportId && sportId.currentValue !== sportId.previousValue) ||
            (leagueId && leagueId.currentValue !== leagueId.previousValue) ||
            (imageProfile && imageProfile.currentValue !== imageProfile.previousValue)
        ) {
            this.updateCompetitionLogoUrl(this.imageProfile);
            this.changeDetectorRef.markForCheck();
        }
        this.fallbackColor = this.getFallbackColor();
        this.logoColor = this.getLogoColor();
    }

    loadLogo(): void {
        this.logoLoaded = true;
        this.changeDetectorRef.detectChanges();
    }

    private updateCompetitionLogoUrl(imageProfile?: ImageProfile): void {
        const updatedLogoUrl =
            imageProfile && this.imagesConfig.isCompetitionImageProfile
                ? this.competitionLogosService.getImageProfileLogoUrl(this.sportId, this.imageProfile, ImageRootPath.Prod)
                : this.competitionLogosService.getLogoUrl(this.sportId, +this.leagueId, this.isLogoOverrideEnabled, ImageRootPath.Live);
        if (updatedLogoUrl !== this.competitionLogoUrl) {
            this.logoLoaded = false;
            this.competitionLogoUrl = updatedLogoUrl;
        }
    }

    private getLogoColor(): string | undefined {
        return this.showBackgroundCircle ? (this.imageProfile?.logoColor ? '#' + this.imageProfile?.logoColor : undefined) : undefined;
    }

    private getFallbackColor(): boolean {
        return !!(this.showBackgroundCircle && !this.imageProfile?.logoColor);
    }

    onLoadingError() {
        this.loadingError = true;
        this.changeDetectorRef.detectChanges();
    }
}
