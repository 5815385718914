/*
 * Copyright 2024 DeviceAtlas Limited. All rights reserved.
 * http://deviceatlas.com
 */
var DeviceAtlas = DeviceAtlas || {
  cookieExpiryDays: 365,
  cookieDomain: document.documentElement.getAttribute("data-domain")
};
DeviceAtlas = function (e) {
  var t = {};
  function r(n) {
    if (t[n]) return t[n].exports;
    var o = t[n] = {
      i: n,
      l: !1,
      exports: {}
    };
    return e[n].call(o.exports, o, o.exports, r), o.l = !0, o.exports;
  }
  return r.m = e, r.c = t, r.d = function (e, t, n) {
    r.o(e, t) || Object.defineProperty(e, t, {
      enumerable: !0,
      get: n
    });
  }, r.r = function (e) {
    "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
      value: "Module"
    }), Object.defineProperty(e, "__esModule", {
      value: !0
    });
  }, r.t = function (e, t) {
    if (1 & t && (e = r(e)), 8 & t) return e;
    if (4 & t && "object" == typeof e && e && e.__esModule) return e;
    var n = Object.create(null);
    if (r.r(n), Object.defineProperty(n, "default", {
      enumerable: !0,
      value: e
    }), 2 & t && "string" != typeof e) for (var o in e) r.d(n, o, function (t) {
      return e[t];
    }.bind(null, o));
    return n;
  }, r.n = function (e) {
    var t = e && e.__esModule ? function () {
      return e.default;
    } : function () {
      return e;
    };
    return r.d(t, "a", t), t;
  }, r.o = function (e, t) {
    return Object.prototype.hasOwnProperty.call(e, t);
  }, r.p = "", r(r.s = 15);
}([function (e, t) {
  e.exports = {
    criticalProperties: ["deviceAspectRatio", "devicePixelRatio", "js.webGlRenderer", "screenWidthHeight", "rendererRef", "audioRef", "js.deviceMotion", "html.video.ap4x", "html.video.av1"]
  };
}, function (e, t, r) {
  "use strict";

  t.a = function (e, t) {
    t = t || 5381;
    for (var r = 0; r < e.length; r++) t = 33 * t ^ e.charCodeAt(r);
    return String(t >>> 0);
  };
}, function (e, t, r) {
  "use strict";

  t.a = function (e, t) {
    if (void 0 !== window.matchMedia) for (var r in t) {
      if (window.matchMedia("(" + e + ":" + t[r] + ")").matches) return t[r] + "";
    }
  };
}, function (e, t, r) {
  "use strict";

  t.a = function (e) {
    if (document.createElement) try {
      var t = document.createElement("video");
      if (t.canPlayType) switch (t.canPlayType(e)) {
        case "":
          return !1;
        case "probably":
          return !0;
      }
    } catch (e) {}
  };
}, function (e, t, r) {
  "use strict";

  t.a = function () {
    return !!window.CanvasRenderingContext2D;
  };
}, function (e, t, r) {
  "use strict";

  r.r(t), t.default = function () {
    try {
      return window.screen.width + "/" + window.screen.height;
    } catch (e) {}
  };
}, function (e, t, r) {
  var n = {
    "./audioRef.js": 7,
    "./deviceAspectRatio.js": 8,
    "./devicePixelRatio.js": 9,
    "./html.video.ap4x.js": 10,
    "./html.video.av1.js": 11,
    "./js.deviceMotion.js": 12,
    "./js.webGlRenderer.js": 13,
    "./rendererRef.js": 14,
    "./screenWidthHeight.js": 5
  };
  function o(e) {
    var t = i(e);
    return r(t);
  }
  function i(e) {
    if (!r.o(n, e)) {
      var t = new Error("Cannot find module '" + e + "'");
      throw t.code = "MODULE_NOT_FOUND", t;
    }
    return n[e];
  }
  o.keys = function () {
    return Object.keys(n);
  }, o.resolve = i, e.exports = o, o.id = 6;
}, function (e, t, r) {
  "use strict";

  r.r(t);
  var n = r(1);
  t.default = function (e, t) {
    if (void 0 !== e) return t(e);
    var r = null;
    try {
      if (!(r = new (window.OfflineAudioContext || window.webkitOfflineAudioContext)(1, 44100, 44100))) return t();
      var o = r.createOscillator();
      o.type = "triangle", o.frequency.value = 1e4;
      var i = r.createDynamicsCompressor();
      i.threshold && (i.threshold.value = -50), i.knee && (i.knee.value = 40), i.ratio && (i.ratio.value = 12), i.reduction && (i.reduction.value = -20), i.attack && (i.attack.value = 0), i.release && (i.release.value = 0.25), o.connect(i), i.connect(r.destination), o.start(0), r.startRendering(), r.oncomplete = function (e) {
        for (var r = !1, o = 0; o < e.renderedBuffer.length; o++) r = Object(n.a)(e.renderedBuffer.getChannelData(0)[o].toString(), r);
        r && t(r), i.disconnect();
      };
    } catch (e) {
      t();
    }
  };
}, function (e, t, r) {
  "use strict";

  r.r(t);
  var n = r(2),
    o = r(5);
  t.default = function (e) {
    if (void 0 !== e) return e;
    var t = ["2/3", "40/71", "3/4", "16/9", "16/10", "375/667", "9/16", "10/16", "375/812", "207/448"],
      r = Object(o.default)();
    return r && t.push(r), Object(n.a)("device-aspect-ratio", t);
  };
}, function (e, t, r) {
  "use strict";

  r.r(t), t.default = function () {
    var e = 1;
    try {
      e = window.devicePixelRatio;
    } catch (e) {}
    return e + "";
  };
}, function (e, t, r) {
  "use strict";

  r.r(t);
  var n = r(3);
  t.default = function (e) {
    return void 0 !== e ? e : Object(n.a)('video/quicktime; codecs="ap4x"');
  };
}, function (e, t, r) {
  "use strict";

  r.r(t);
  var n = r(3);
  t.default = function (e) {
    return void 0 !== e ? e : Object(n.a)('video/mp4; codecs="av01.0.05M.08"');
  };
}, function (e, t, r) {
  "use strict";

  r.r(t), t.default = function () {
    return !!window.DeviceMotionEvent;
  };
}, function (e, t, r) {
  "use strict";

  r.r(t);
  var n = r(4);
  t.default = function (e) {
    if (void 0 !== e) return e;
    if (Object(n.a)()) {
      var t = document.createElement("canvas"),
        r = t.getContext("webgl") || t.getContext("experimental-webgl");
      if (r) {
        var o = r.getExtension("WEBGL_debug_renderer_info");
        if (o) {
          var i = r.getParameter(o.UNMASKED_RENDERER_WEBGL);
          if (i) return i;
        }
      }
    }
  };
}, function (e, t, r) {
  "use strict";

  r.r(t);
  var n = r(4),
    o = r(2),
    i = r(1);
  t.default = function (e) {
    if (void 0 !== e) return e;
    if (Object(n.a)()) {
      var t = document.createElement("canvas"),
        r = t.getContext("2d"),
        a = "DeviceAtlas";
      return r.textBaseline = "top", r.font = "16px 'Arial'", r.textBaseline = "alphabetic", r.rotate(0.05), r.fillStyle = "#f60", r.fillRect(125, 1, 62, 20), r.fillStyle = "#069", r.fillText(a, 2, 15), r.fillStyle = "rgba(102, 200, 0, 0.7)", r.fillText(a, 4, 17), r.shadowBlur = 10, r.shadowColor = "blue", r.rotate(-0.15), r.fillRect(-20, 25, 234, 5), ("p3" === Object(o.a)("color-gamut", ["p3"]) ? "1" : "0") + Object(i.a)(t.toDataURL());
    }
  };
}, function (e, t, r) {
  "use strict";

  r.r(t), r.d(t, "cacheProperty", function () {
    return U;
  });
  var n = r(0),
    o = r.n(n),
    i = function () {
      return void 0 === navigator.cookieEnabled && "string" == typeof document.cookie || !!navigator.cookieEnabled;
    },
    a = function () {
      try {
        return !!localStorage.getItem;
      } catch (e) {
        return !1;
      }
    },
    c = "._.".length,
    u = function e(t, r, n) {
      for (var o in void 0 === r && (r = !1), void 0 === n && (n = {}), t) {
        var i = r ? r + "." + o : o,
          a = t[o],
          c = typeof t[o];
        "object" !== c || Array.isArray(a) ? "function" !== c && "undefined" !== c && (n[i] = a) : e(a, i, n);
      }
      return n;
    },
    f = function (e, t, o) {
      void 0 === t && (t = function (e) {
        void 0 === e && (e = ["audioRef", "deviceAspectRatio", "devicePixelRatio", "html.video.ap4x", "html.video.av1", "js.deviceMotion", "js.webGlRenderer", "rendererRef", "screenWidthHeight"]);
        var t = {};
        e.map(function (e) {
          t[e] = r(6)("./" + e + ".js").default;
        });
        var o = function () {};
        return n.criticalProperties.map(function (e) {
          t[e] = t[e] || o;
        }), t;
      }()), void 0 === o && (o = !1);
      var i = function (r) {
        var n,
          i = e[r];
        if (i || !t.hasOwnProperty(r)) return 1;
        var a = t[r],
          c = a.length <= 1;
        try {
          n = a(i, function (e) {
            B.cacheProperty(r, e, i);
          }, e);
        } catch (e) {
          B.debug(e);
        }
        (o || c) && B.cacheProperty(r, n, i);
      };
      for (var a in t) i(a);
    },
    s = function (e, t, r, n, o) {
      var c;
      switch (e) {
        case "cookie":
          c = function (e) {
            if (!i()) return "";
            for (var t = e + "=", r = document.cookie.split(";"), n = 0; n < r.length; n++) {
              for (var o = r[n]; " " === o.charAt(0);) o = o.substring(1);
              if (0 === o.indexOf(t)) {
                var a = o.substring(t.length, o.length);
                '"' === a.charAt(0) && (a = a.substr(1));
                var c = a.length - 1;
                return '"' === a[c] && (a = a.substring(0, c)), a;
              }
            }
            return "";
          }(n);
          break;
        case "local-storage":
          c = function (e) {
            if (!a()) return "";
            var t = window.localStorage.getItem(e);
            if (!t) return "";
            var r = "";
            try {
              r = JSON.parse(t);
            } catch (t) {
              return localStorage.removeItem(e), "";
            }
            return new Date().getTime() > r.expiry ? (localStorage.removeItem(e), "") : r.value;
          }(t);
          break;
        default:
          return;
      }
      return function (e, t) {
        if (!e || "" === e) return t;
        for (var r = decodeURIComponent(e).split("|"), n = 0; n < r.length; n++) {
          var o = r[n],
            i = o.indexOf(":"),
            a = o.substr(0, 1),
            c = o.substr(1, i - 1);
          if ("timeTook" !== c) {
            var u = o.substring(i + 1);
            switch (a) {
              case "i":
                t[c] = 1 * u;
                break;
              case "b":
                t[c] = !!(1 * u);
                break;
              default:
                t[c] = u;
            }
          }
        }
        return t;
      }(c, o);
    },
    l = new Date().getTime(),
    d = window.DeviceAtlas || {},
    v = "function" == typeof d.debug ? d.debug : d.debug && console && console.log ? console.log : function () {},
    p = d.properties || {},
    g = d.cacheKey || "DAPROPS",
    h = d.cookieName || "DAPROPS",
    y = d.cacheType || "cookie",
    m = d.criticalProperties || o.a.criticalProperties,
    b = d.waitMilliSecondsToBulkSendProperties || 3e3,
    w = d.cookieExpiryDays || 1,
    k = d.cacheExpiryDays || 1,
    x = d.dontStopPropertyUpdateEvents || !1,
    P = d.encodeCookieValue || !0,
    j = d.cookieDomain || null,
    O = d.cookiePath || "/",
    D = "function" == typeof d.onPropertiesUpdate ? d.onPropertiesUpdate : function () {},
    S = "bug-DA-6298-add-option-to-encode-cs-string-SNAPSHOT",
    R = !1,
    A = function (e, t) {
      R && !x || (D(e, t), R = !0);
    },
    E = [],
    T = !1,
    C = function (e, t) {
      (function (e, t) {
        if (!Array.isArray(e) || !Array.isArray(t)) return !1;
        for (var r = 0; r < e.length; r++) if (!~t.indexOf(e[r])) return !1;
        return !0;
      })(m, E) && (T && (clearTimeout(T), T = !1), A(e, t));
    },
    _ = function () {
      return new Date().getTime() - l + "ms";
    },
    M = function () {
      return function (e) {
        var t,
          r,
          n = "";
        for (var o in e) e.hasOwnProperty(o) && "timeTook" !== o && "S" !== o && "E" !== o && (n += (t = "boolean" == typeof (r = e[o]) ? "b" : "number" == typeof r && r % 1 == 0 ? "i" : "s") + o + ":" + ("b" === t ? e[o] + 0 : e[o]) + "|");
        return "bS:0|" + n + "bE:0";
      }(N);
    },
    N = {
      csVersion: S
    },
    U = function (e, t, r) {
      if (E.push(e), void 0 !== t && null != t && t !== r) switch ("object" == typeof t ? function (e, t, r) {
        var n = u(t, e),
          o = void 0;
        for (var i in n) if (void 0 !== n[i]) {
          var a = i.indexOf("._.");
          r[~a ? i.substring(a + c) : i] = n[i], o = o || !!n[i];
        }
      }(e, t, N) : N[e] = t, y) {
        case "cookie":
          !function (e, t) {
            var r = t.cookieName,
              n = t.cookieExpiryDays,
              o = t.cookieDomain,
              a = t.cookiePath,
              c = t.encodeCookieValue;
            if (!i()) return !1;
            var u = new Date();
            u.setDate(u.getDate() + n);
            var f = "";
            null != o && (f = ";domain=" + o), c && (e = encodeURIComponent(e)), document.cookie = r + '="' + e.replace(/;/g, "~") + '";expires=' + u.toUTCString() + ";path=" + a + f;
          }(M(), {
            cookieName: h,
            cookieDomain: j,
            cookieExpiryDays: w,
            cookiePath: O,
            encodeCookieValue: P
          });
          break;
        case "local-storage":
          !function (e, t, r) {
            if (!a()) return !1;
            var n = new Date().getTime() + 864e5 * r;
            window.localStorage.setItem(t, JSON.stringify({
              value: e,
              expiry: n
            }));
          }(M(), g, k);
      }
      N.timeTook = _(), C(N, M());
    },
    B = t.default = {
      cookieName: h,
      cookieDomain: j,
      cookieExpiryDays: w,
      cookiePath: O,
      onPropertiesUpdate: D,
      csVersion: S,
      getPropertiesAsString: M,
      getProperties: function () {
        return N;
      },
      properties: N,
      debug: v,
      cacheProperty: U
    };
  s(y, g, 0, h, N), E.push.apply(E, Object.keys(N)), f(N), f(N, p, !0), T = setTimeout(function () {
    N.timeTook = _(), A(N, M());
  }, b);
}]).default;