import { Injectable } from '@angular/core';

import { CountItem, takeTop } from '@frontend/sports/common/base-utils';
import { TopNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { Observable } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

import { SportsCacheService } from '../../client-caching/sports-cache.service';
import { NavigationItemsMapper } from '../navigation-items-mapper';
import { TopMenuContentItem } from '../navigation.models';
import { BaseNavigationItemsProvider } from './base-navigation-items.provider';

@Injectable({ providedIn: 'root' })
export class SportNavigationItemsProvider extends BaseNavigationItemsProvider {
    readonly sportItems$: Observable<TopMenuContentItem[]>;

    constructor(
        private navigationConfig: TopNavigationConfig,
        private mapper: NavigationItemsMapper,
        protected override sportsCache: SportsCacheService,
    ) {
        super(sportsCache);

        this.sportItems$ = this.sportsCache.getSportList().pipe(
            map((items) => this.mapItems(items)),
            startWith([]),
            shareReplay(),
        );
    }

    private mapItems(items: CountItem[]): TopMenuContentItem[] {
        const isV2Navigation = !!this.navigationConfig.topNavigationItems?.length;
        const maxSports = isV2Navigation ? this.navigationConfig.sportsConfig.topSportsCount : this.navigationConfig.maxSports;

        if (maxSports < 1) {
            return [];
        }

        if (!isV2Navigation) {
            const beforeSports = this.navigationConfig.beforeSports.map((sport) => sport.activeName);
            items = items.filter((item) => !beforeSports.includes(item.id.toString()));
        }

        const topSports = takeTop(items, maxSports);

        return topSports.map((item, index) =>
            this.mapper.buildSportMenuItem({
                id: item.id,
                name: item.name,
                separator: this.hasSeparator(index, isV2Navigation),
                isVirtual: item.isVirtual,
            }),
        );
    }

    private hasSeparator(index: number, isV2Navigation: boolean) {
        const isFirstItem = index === 0;

        return isV2Navigation ? this.navigationConfig.sportsConfig?.topSports.useBeforeSeparator && isFirstItem : isFirstItem;
    }
}
