import { Attribute, Directive, ElementRef, Input, NgZone, OnInit } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { DeviceService } from '@frontend/vanilla/core';
import { takeUntil } from 'rxjs/operators';

import { SmoothScroll } from '../../common/smoothScroll.service';
import { TimerService } from '../../common/timer.service';

interface ViewportPosition {
    scrollLeft: number;
    width: number;
}

type Mode = 'center' | 'next-item';

@HooksWireup()
@Directive({
    selector: '[msScrollSelectedInView]',
    standalone: true,
})
export class ScrollSelectedInViewDirective extends OnDestroyCleanup implements OnInit {
    private lastSelectedItem?: string;

    constructor(
        private element: ElementRef,
        private smoothScroll: SmoothScroll,
        private timerService: TimerService,
        private deviceService: DeviceService,
        private ngZone: NgZone,
        @Attribute('mode') private mode: Mode,
    ) {
        super();
        this.mode = this.mode || 'center';
    }

    @Input('msScrollSelectedInView')
    set target(value: string) {
        this.ngZone.runOutsideAngular(() => {
            this.timerService.setTimeout(() => this.selectedItemChangedHandler(value), 250);
        });
    }

    ngOnInit(): void {
        this.ngZone.runOutsideAngular(() => {
            this.deviceService.orientation.pipe(takeUntil(this.destroyed$)).subscribe(this.onOrientationChange);
        });
    }

    private selectedItemChangedHandler(value: string): void {
        if ((this.deviceService.isMobile && this.lastSelectedItem === value) || (!this.deviceService.isMobile && !value)) {
            return;
        }

        this.lastSelectedItem = value;

        const scrollableContainer = this.element.nativeElement.children[0];
        if (!scrollableContainer) {
            return;
        }

        const child = scrollableContainer.querySelector(`[data-menu-item-id="${value}"]`);
        if (!child) {
            return;
        }

        const viewportPosition: ViewportPosition = {
            scrollLeft: scrollableContainer.scrollLeft,
            width: scrollableContainer.offsetWidth,
        };

        let scrollLeft = 0;
        if (this.mode === 'center') {
            scrollLeft = this.centerScroller(child, viewportPosition);
        } else {
            scrollLeft = this.nextItemScroller(child, viewportPosition);
        }

        this.smoothScroll.scrollLeftTo(scrollableContainer, scrollLeft, 350);
    }

    private centerScroller(child: HTMLElement, viewportPosition: ViewportPosition): number {
        const childPosition = this.elementViewportPosition(child, viewportPosition);
        const viewportMiddle = viewportPosition.width / 2;

        const childPositionMiddle = childPosition.scrollLeft + childPosition.width / 2;
        const scrollLeft = viewportPosition.scrollLeft + (childPositionMiddle - viewportMiddle);

        return Math.max(0, scrollLeft);
    }

    private nextItemScroller(child: HTMLElement, viewportPosition: ViewportPosition): number {
        const dontScroll = viewportPosition.scrollLeft;

        const nextElement = child.nextElementSibling;
        if (nextElement) {
            const nextPosition = this.elementViewportPosition(<HTMLElement>nextElement, viewportPosition);
            const nextScrollLeft = nextPosition.scrollLeft + nextPosition.width - viewportPosition.width;

            if (nextScrollLeft > 0) {
                return nextScrollLeft + viewportPosition.scrollLeft;
            }
        } else {
            return child.offsetLeft;
        }

        const prevElement = child.previousElementSibling;
        if (prevElement) {
            const prevPosition = this.elementViewportPosition(<HTMLElement>prevElement, viewportPosition);
            const prevScrollLeft = prevPosition.scrollLeft;

            if (prevScrollLeft < 0) {
                return prevScrollLeft + viewportPosition.scrollLeft;
            }
        } else {
            return 0;
        }

        return dontScroll;
    }

    private elementViewportPosition(element: HTMLElement, viewportPosition: ViewportPosition): ViewportPosition {
        return {
            scrollLeft: element.offsetLeft - viewportPosition.scrollLeft,
            width: element.offsetWidth,
        };
    }

    private onOrientationChange = () => {
        if (this.lastSelectedItem) {
            this.selectedItemChangedHandler(this.lastSelectedItem);
        }
    };
}
