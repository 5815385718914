import { pprops } from '@frontend/sports/common/base-utils';
import { createAction, props } from '@ngrx/store';

import { MediaSetEvent, MediaSetEventId, SetMediaWidgetContextPayload } from './model';

export class MediaApiActions {
    private static readonly SET_EVENT = '@media/SET_EVENT';
    private static readonly SET_EVENT_ID = '@media/SET_EVENT_ID';
    private static readonly TOGGLE_PLAYING = '@media/TOGGLE_PLAYING';
    private static readonly SET_MEDIA_WIDGET_CONTEXT = '@media/SET_MEDIA_WIDGET_CONTEXT';

    static readonly togglePlaying = createAction(MediaApiActions.TOGGLE_PLAYING, props<{ value: boolean }>());
    static readonly setMediaWidgetContext = createAction(MediaApiActions.SET_MEDIA_WIDGET_CONTEXT, pprops<SetMediaWidgetContextPayload>());
    static readonly setEvent = createAction(MediaApiActions.SET_EVENT, pprops<MediaSetEvent>());
    static readonly setEventId = createAction(MediaApiActions.SET_EVENT_ID, pprops<MediaSetEventId>());
}
