import { Inject, Injectable } from '@angular/core';

import { GameType } from '@bpos/v1/sports-promo';
import { SportConstant, interpolateDoubleBracesString } from '@frontend/sports/common/base-utils';
import { CRMTokenImages } from '@frontend/sports/common/client-config-data-access';
import { DeviceService, IntlService, LocalStoreService, NavigationService, OnAppInit } from '@frontend/vanilla/core';
import { DateProviderService } from 'packages/sports/web/app/src/common/date-provider.service';
import { ScrollContainerService } from 'packages/sports/web/app/src/common/scroll-container.service';
import { RedirectHelperService } from 'packages/sports/web/app/src/navigation-core/redirect-helper.service';
import { SportUrlParam, UrlHelperService } from 'packages/sports/web/app/src/navigation-core/url-helper.service';
import { RewardTokenImages, RewardTokenType } from 'packages/sports/web/app/src/tokens-base/token-base.models';
import { NOT_APPLICABLE, trackingConstants } from 'packages/sports/web/app/src/tracking/tracking.models';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';

import { ICrmConfig } from '../../../integration/config/crm-config';
import ISitecore from '../../../integration/services/sitecore.service';
import { ITrackingService } from '../../../integration/services/tracking.service';
import IUserService from '../../../integration/services/user.service';
import {
    CRM_CONFIG_INJECTION_TOKEN,
    SITECORE_INJECTION_TOKEN,
    TRACKING_SERVICE_INJECTION_TOKEN,
    USER_SERVICE_INJECTION_TOKEN,
} from '../../../integration/sports-injection-services';
import { IRewardToken, RewardBannerTrackingConstants } from '../reward-tokens.model';

@Injectable({
    providedIn: 'root',
})
export class RewardTokensBannerService implements OnAppInit {
    private readonly betRewardBannerDismissKey = 'IsBetRewardBannerDismissed';
    private readonly MINUTE = 60 * 1000;
    private readonly HOUR = 60 * this.MINUTE;
    private readonly DAY = 24 * this.HOUR;
    private _recentlyClickedToken$ = new BehaviorSubject<string | null>(null);
    readonly recentlyClickedToken$ = this._recentlyClickedToken$.asObservable();
    private _isBannerExpaned = new Subject<boolean>();
    private isBannerExpanded = false;
    isRewardDrawerEnabled = this.tokensConfig.tokens.isRewardDrawerEnabled;

    constructor(
        @Inject(SITECORE_INJECTION_TOKEN) public sitecore: ISitecore,
        @Inject(USER_SERVICE_INJECTION_TOKEN) private userService: IUserService,
        private localStoreService: LocalStoreService,
        @Inject(TRACKING_SERVICE_INJECTION_TOKEN) private trackingService: ITrackingService,
        private redirectHelperService: RedirectHelperService,
        private scrollContainer: ScrollContainerService,
        private navigationService: NavigationService,
        private urlHelperService: UrlHelperService,
        private deviceService: DeviceService,
        private crmTokenimage: CRMTokenImages,
        private intlService: IntlService,
        @Inject(CRM_CONFIG_INJECTION_TOKEN) private tokensConfig: ICrmConfig,
    ) {}

    onAppInit(): void {
        if (this.userService.isAuthenticated || !this.isBetRewardBannerDismissed()) {
            return;
        }

        this.userService.onUserLogin$.pipe(take(1)).subscribe(() => {
            this.setBetRewardBannerStorageKey(false);
        });
    }

    setBetRewardBannerStorageKey(isBetRewardBannerDismissed: boolean): void {
        this.localStoreService.set(this.betRewardBannerDismissKey, isBetRewardBannerDismissed);
    }

    isBetRewardBannerDismissed(): boolean {
        return this.localStoreService.get(this.betRewardBannerDismissKey) ?? false;
    }

    trackBetRewardBanner(
        action: RewardBannerTrackingConstants,
        baseTracking: { [key: string]: string },
        eventDetail: string,
        positionEvent?: string,
        url?: string,
        page?: string,
    ): void {
        this.trackingService.track(
            action === RewardBannerTrackingConstants.Load ? trackingConstants.EVENT_CONTENT_VIEW : trackingConstants.EVENT_TRACKING,
            {
                [trackingConstants.COMPONENT_CATEGORY_EVENT]: RewardBannerTrackingConstants.PromoHub,
                [trackingConstants.COMPONENT_LABEL_EVENT]: RewardBannerTrackingConstants.BetRewardsBanner,
                [trackingConstants.COMPONENT_ACTION_EVENT]: action,
                [trackingConstants.COMPONENT_POSITION_EVENT]: positionEvent ?? NOT_APPLICABLE,
                [trackingConstants.COMPONENT_LOCATION_EVENT]:
                    action === RewardBannerTrackingConstants.Click ? RewardBannerTrackingConstants.BetRewardsBanner : page ?? NOT_APPLICABLE,
                [trackingConstants.COMPONENT_EVENT_DETAILS]: eventDetail,
                [trackingConstants.COMPONENT_URL_CLICKED]: url ?? NOT_APPLICABLE,
                ...baseTracking,
            },
        );
    }

    betNowRedirect(token: IRewardToken): void {
        this.setRecentlyClicked(null);

        if (this.isRewardDrawerEnabled && token.url) {
            this.redirectHelperService.goToPage(token.url);
        } else {
            const fixturesCount = token.filter?.fixtures?.length;
            if (fixturesCount === 1) {
                // For racing fixture filter cretira there is no meeting details, so navigate to racing sport lobby page
                if ([SportConstant.Horses, SportConstant.Greyhounds].includes(token.filter.sports[0].id)) {
                    this.redirectToSport(token);
                } else {
                    this.redirectHelperService.goToEvent(token.filter.fixtures[0]);
                }
            } else if (token.filter?.competitions?.length === 1) {
                const competitionUrl = this.urlHelperService.getSportUrl(
                    token.filter.sports[0],
                    SportUrlParam.Betting,
                    { id: '', name: '' },
                    { id: token.filter.competitions[0].id, name: '' },
                );
                this.redirectHelperService.goToPage(competitionUrl);
            } else if (token.filter?.meetings?.length === 1) {
                const param = {
                    id: token.filter.meetings[0].id,
                    name: '',
                };
                this.redirectHelperService.goToRacing(token.filter.sports[0].id, param);
            } else if (token.filter?.gameType === GameType.InPlay) {
                this.redirectToLiveSport(token);
            } else {
                this.redirectToSport(token);
            }
        }
    }

    private homeRedirection(): void {
        const currentUrl = this.navigationService.location.path();
        const scrollHeight = document.querySelector('.cards-module')?.scrollHeight;
        if (currentUrl === this.urlHelperService.getHomeUrl() && this.deviceService.isMobile && !this.isRewardDrawerEnabled) {
            this.scrollContainer.scrollTo(0, 0);
            this.scrollContainer.scrollToDelta(0, scrollHeight);
        } else if (this.deviceService.isMobile && this.isRewardDrawerEnabled) {
            this.setRecentlyClicked(null);
            this.toggleBannerExpansion();
            if (currentUrl === this.urlHelperService.getHomeUrl()) {
                this.scrollOnBetNow();
            }
        } else {
            this.redirectHelperService.goHome();
        }
    }

    private redirectToLiveSport(token: IRewardToken): void {
        const liveSportsCount = token.filter.sports?.length;
        if (liveSportsCount === 1) {
            this.redirectHelperService.goToLiveSport({ sport: token.filter.sports[0] });
        } else {
            this.redirectHelperService.goToLive();
        }
    }

    private redirectToSport(token: IRewardToken): void {
        const sportsCount = token.filter?.sports?.length;
        const currentUrl = this.navigationService.location.path();
        const scrollHeight = document.querySelector('.cards-module')?.scrollHeight;

        if (sportsCount === 1) {
            if (currentUrl === this.urlHelperService.getSportUrl(token.filter.sports[0]) && this.deviceService.isMobile) {
                this.scrollContainer.scrollTo(0, 0);
                this.scrollContainer.scrollToDelta(0, scrollHeight);
            } else {
                this.redirectHelperService.goToSport(token.filter.sports[0]);
            }
        } else {
            this.setRecentlyClicked(token.userTokenId);
            this.homeRedirection();
        }
    }

    setTokenInfoUrl(promoHubUrlTokenType: string, userTokenId: string): void {
        const promoBaseUrl = this.sitecore.crmTokens.links.promotiondetails.url;
        const promoBaseUrlSeparator = promoBaseUrl.endsWith('/') ? '' : '/';
        const tokenInfoUrl = `${promoBaseUrl}${promoBaseUrlSeparator}${promoHubUrlTokenType}/${userTokenId}`;
        this.redirectHelperService.goToPage(tokenInfoUrl);
    }

    getIconUrl(isRewardBannerView?: boolean): { [id: string]: string } {
        const isDarkMode = this.tokensConfig.tokens.enableDarkModeForRewardDrawer && isRewardBannerView;
        const rewardsIcons = {} as { [id: string]: string };

        rewardsIcons[RewardTokenType.FreeBet] = this.crmTokenimage.crmTokenImages.find(
            (x) => x.name === (isDarkMode ? RewardTokenImages.FreeBetDark : RewardTokenImages.FreeBet),
        )?.src!;
        rewardsIcons[RewardTokenType.OddsBoost] = this.crmTokenimage.crmTokenImages.find(
            (x) => x.name === (isDarkMode ? RewardTokenImages.OddsBoostDark : RewardTokenImages.OddsBoost),
        )?.src!;
        rewardsIcons[RewardTokenType.RiskFreeBet] = this.crmTokenimage.crmTokenImages.find(
            (x) => x.name === (isDarkMode ? RewardTokenImages.RiskFreeBetDark : RewardTokenImages.RiskFreeBet),
        )?.src!;
        rewardsIcons[RewardTokenType.BetAndGet] = this.crmTokenimage.crmTokenImages.find(
            (x) => x.name === (isDarkMode ? RewardTokenImages.BetAndGetDark : RewardTokenImages.BetAndGet),
        )?.src!;

        return rewardsIcons;
    }

    getTokenExpires(expiryDate: Date): string {
        const now = DateProviderService.getDate();
        const today = DateProviderService.buildDate(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        const tomorrow = DateProviderService.getDate(today.getTime() + this.DAY);
        const nowDate = DateProviderService.buildDate(now.getFullYear(), now.getMonth(), now.getDate());
        const expirDate = DateProviderService.buildDate(expiryDate.getFullYear(), expiryDate.getMonth(), expiryDate.getDate());
        const diffDay = expirDate.getTime() - nowDate.getTime();
        const diff = expiryDate.getTime() - now.getTime();
        const diffHours = diff / this.HOUR;
        if (this.isRewardDrawerEnabled) {
            return this.getRewardDrawerTokenExpires(diff, today, expiryDate, diffDay, tomorrow);
        }

        return this.getDefaultTokenExpires(diffHours, diffDay);
    }

    private getRewardDrawerTokenExpires(diff: number, today: Date, expiryDate: Date, diffDay: number, tomorrow: Date): string {
        if (diff < 0) {
            return this.sitecore.crmTokens.cards.TokenExpiredTemplate;
        }

        if (expiryDate < today) {
            return interpolateDoubleBracesString(this.sitecore.crmTokens.cards.TokenExpireTodayTemplate, {
                todayTime: this.intlService.formatDate(expiryDate, 'shortTime'),
            });
        }

        if (expiryDate < tomorrow) {
            return interpolateDoubleBracesString(this.sitecore.crmTokens.cards.TokenExpireTomorrowTemplate, {
                tomorrowTime: this.intlService.formatDate(expiryDate, 'shortTime'),
            });
        }

        if (diffDay <= this.DAY * 6) {
            return interpolateDoubleBracesString(this.sitecore.crmTokens.cards.TokenExpireDaysTemplate, {
                dayCount: diffDay / this.DAY,
            });
        }

        return this.intlService.formatDate(expiryDate, 'shortDate');
    }

    private getDefaultTokenExpires(diffHours: number, diffDay: number): string {
        if (diffHours < 0) {
            return this.sitecore.crmTokens.cards.TokenExpiredTemplate;
        }
        if (diffHours < 1) {
            return this.sitecore.crmTokens.cards.TokenExpireLessThanHourTemplate;
        }
        if (diffHours < 24) {
            return interpolateDoubleBracesString(this.sitecore.crmTokens.cards.TokenExpireHoursTemplate, {
                hoursCount: Math.floor(diffHours) + 1,
            });
        }

        return interpolateDoubleBracesString(this.sitecore.crmTokens.cards.TokenExpireDaysTemplate, {
            dayCount: diffDay / this.DAY,
        });
    }

    setRecentlyClicked(userTokenId: string | null): void {
        if (this.isRewardDrawerEnabled) {
            this._recentlyClickedToken$.next(userTokenId);
        }
    }

    get isBannerExpanded$(): Observable<boolean> {
        return this._isBannerExpaned.asObservable();
    }

    toggleBannerExpansion(): void {
        this.isBannerExpanded = !this.isBannerExpanded;
        this._isBannerExpaned.next(this.isBannerExpanded);
    }

    private scrollOnBetNow(): void {
        const marqueeHeight = document.querySelector('ms-marquee-widget:last-of-type')?.getBoundingClientRect().bottom ?? 0;
        const fixedHeaderHeight =
            document.querySelector('ms-sitemap')?.scrollHeight ?? document.querySelector('vn-header')?.getBoundingClientRect().bottom ?? 0;
        this.scrollContainer.scrollToDelta(0, marqueeHeight - fixedHeaderHeight);
    }
}
