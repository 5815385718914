export enum SportConstant {
    AmericanFootball = 11,
    Archery = 58,
    Athletics = 17,
    AustralianRulesFootball = 36,
    Badminton = 44,
    Bandy = 27,
    Baseball = 23,
    Basketball = 7,
    BeachHandball = 97,
    BeachSoccer = 93,
    Beachvolleyball = 63,
    Biathlon = 64,
    Bobsleigh = 65,
    Bowls = 35,
    Boxing = 24,
    Canoeing = 66,
    Chess = 67,
    CombatSports = 45,
    Cricket = 22,
    CrossCountrySkiing = 94,
    Curling = 68,
    Cycling = 10,
    Darts = 34,
    Diving = 92,
    Entertainment = 60,
    Equestrian = 46,
    Esports = 100,
    Fencing = 78,
    FigureSkating = 69,
    Financials = 62,
    Fistball = 47,
    Formula1 = 6,
    FreestyleSkiing = 86,
    Futsal = 70,
    GaelicSports = 48,
    Golf = 13,
    Greyhounds = 37,
    Gymnastics = 59,
    Handball = 16,
    Hockey = 49,
    Horses = 29,
    Icehockey = 12,
    IndoorSoccer = 71,
    Innebandy = 28,
    Lacrosse = 88,
    Luge = 72,
    ModernPentathlon = 98,
    Motocross = 91,
    Motorbikes = 40,
    MotorRacing = 41,
    Motorsport = 15,
    Nascar = 39,
    Netball = 73,
    NordicCombined = 96,
    OlympicsSpecials = 26,
    Padel = 87,
    Pelota = 74,
    PetanqueLyonnaiseBowls = 75,
    Poker = 76,
    Politics = 61,
    Pool = 38,
    Rally = 43,
    RinkHockey = 77,
    Rowing = 55,
    Rugby = 21,
    RugbyLeague = 31,
    RugbyUnion = 32,
    Sailing = 54,
    Schwingen = 89,
    Shooting = 79,
    ShowJumping = 80,
    Skeleton = 81,
    SkiJumping = 95,
    Skialpine = 9,
    Skinordic = 14,
    Snooker = 33,
    Snowboarding = 82,
    Soccer = 4,
    SoftBall = 53,
    Specials = 25,
    SpeedSkating = 83,
    Speedway = 42,
    Squash = 50,
    Swimming = 51,
    TableSoccer = 90,
    TableTennis = 56,
    Tennis = 5,
    TenPinBowling = 85,
    Triathlon = 84,
    Trotting = 30,
    Volleyball = 18,
    WaterPolo = 52,
    WeightLifting = 57,
    Wrestling = 99,
    VirtualFootball = 101,
    VirtualTennis = 102,
    VirtualBasketball = 103,
    VirtualAmericanFootball = 104,
    CounterStrike2 = 105,
    LeagueOfLegends = 106,
    Dota2 = 107,
    Esoccer = 108,
    Surfing = 109,
    EsportsGroup = -1,
}

export enum RegionConstant {
    World = 6,
}

export enum BasketballPeriodInfo {
    IntermissionQ1 = 2,
    IntermissionQ2 = 4,
    IntermissionQ3 = 6,
    IntermissionQ4 = 8,
    RegularTimeOver = 9,
    OverTimeIntermission = 11,
    Finished = 255,
}

export enum AmericanFootballPeriodInfo {
    IntermissionQ1 = 2,
    IntermissionQ2 = 4,
    IntermissionQ3 = 6,
    RegularTimeOver = 8,
    Finished = 255,
}

export enum IceHockeyPeriodInfo {
    IntermissionPeriod1 = 2,
    IntermissionPeriod2 = 4,
    RegularTimeOver = 6,
    OvertimeOver = 8,
    PenaltyShootout = 9,
    Finished = 255,
}

export enum TennisPeriodInfo {
    Suspended = 6,
    Finished = 255,
}

export enum TableTennisPeriodInfo {
    ThirdSetIntermission = 6,
}

export enum FootballPeriodInfo {
    NotStarted = 0,
    FirstHalf = 1,
    SecondHalf = 3,
    RegularTimeOver = 5,
    PenaltyShootout = 9,
    Finish = 255,
    Suspended = 256,
}

export enum BasketballQuarters {
    Quarter1 = 1,
    Quarter2 = 3,
    Quarter3 = 5,
    Quarter4 = 7,
    Overtime = 10,
}

export enum BasketballHalves {
    Firsthalf = 1,
    Secondhalf = 3,
    Overtime = 10,
}

export enum AmericanFootballQuarters {
    Quarter1 = 1,
    Quarter2 = 3,
    Quarter3 = 5,
    Quarter4 = 7,
    Overtime = 9,
    FirstIntermission = 2,
    Halftime = 4,
    ThirdIntermission = 6,
    RegularTimeOver = 8,
}

export enum IceHockeyPeriods {
    Period1 = 1,
    Period2 = 3,
    Period3 = 5,
    Overtime = 7,
}

export enum DartsLegsPeriodInfo {
    NotStarted = 0,
    TieBreak = 51,
    Finished = 255,
}

export enum DartsSetsAndLegsPeriodInfo {
    NotStarted = 0,
    TieBreak = 14,
    Finished = 255,
}

export const SPORTS_WITHOUT_AVAILABLE_REGION: number[] = [
    9, 17, 44, 64, 24, 67, 45, 94, 10, 34, 60, 6, 48, 13, 29, 96, 61, 95, 33, 25, 51, 56, 5, 52,
];

export const SPORTS_WITH_MAPPED_STATS: number[] = [4, 5, 7];

export enum FeatureModules {
    TopEvents = 'topevents',
    EventDetails = 'eventdetails',
    None = 'none',
}
