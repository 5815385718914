import { ceil } from 'lodash-es';

import { DayOfWeek } from './date-time.models';

export abstract class DateProviderService {
    static getNow(): Date {
        return new Date();
    }

    static getDate(value?: number | string | Date): Date {
        if (typeof value === 'undefined') {
            return DateProviderService.getNow();
        }

        // @ts-ignore ?!?
        return new Date(value);
    }

    static buildDate(year: number, month: number, date?: number, hours?: number, minutes?: number, seconds?: number, ms?: number): Date {
        const count = arguments.length;
        switch (count) {
            case 2:
                return new Date(year, month);
            case 3:
                return new Date(year, month, date);
            case 4:
                return new Date(year, month, date, hours);
            case 5:
                return new Date(year, month, date, hours, minutes);
            case 6:
                return new Date(year, month, date, hours, minutes, seconds);
        }
        if (count >= 7) {
            return new Date(year, month, date, hours, minutes, seconds, ms);
        }
        throw new Error('Wrong number of arguments passed');
    }

    static getStartOfDay(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
    }

    static getEndOfDay(date: Date, daysToBeAdded: number = 0): Date {
        const endDate = new Date(date);
        endDate.setDate(date.getDate() + daysToBeAdded);

        return new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);
    }

    static addHours(date: Date, noOfHours: number = 0): Date {
        return DateProviderService.getDate(date.setHours(date.getHours() + noOfHours));
    }

    static subtractHours(date: Date, noOfHours: number = 0): Date {
        return DateProviderService.getDate(date.setHours(date.getHours() - noOfHours));
    }

    /**
     * @return Returns the numeric value corresponding to the current time - the number of milliseconds elapsed since January 1, 1970 00:00:00 UTC,
     * with leap seconds ignored.
     */
    static now(): number {
        return DateProviderService.getNow().valueOf();
    }

    static getEndOfMonth(date?: Date): Date {
        if (!date) {
            date = DateProviderService.getNow();
        }

        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }

    static getDaysTo(targetDate: Date): number {
        const differenceInTime = targetDate.getTime() - DateProviderService.getNow().getTime();

        return ceil(differenceInTime / (1000 * 3600 * 24));
    }

    /**
     * calculates milliseconds left for the match from current time
     *
     * @returns time in seconds if second parameter is passed true else milliseconds
     */
    static getTimeDifference(date: Date, convertToSeconds?: boolean): number {
        const diff = date.valueOf() - DateProviderService.now();

        return convertToSeconds ? Math.floor(diff / 1000) : diff;
    }

    static checkTimer(date: Date, thresholdSeconds: number): boolean {
        return DateProviderService.getTimeDifference(date, true) <= thresholdSeconds;
    }

    static getDayOfWeek(): DayOfWeek {
        const now = DateProviderService.getDate();

        return DateProviderService.toDayOfWeek(now.getDay());
    }

    static toDayOfWeek(index: number): DayOfWeek {
        switch (index) {
            case 0:
                return DayOfWeek.Sunday;
            case 1:
                return DayOfWeek.Monday;
            case 2:
                return DayOfWeek.Tuesday;
            case 3:
                return DayOfWeek.Wednesday;
            case 4:
                return DayOfWeek.Thursday;
            case 5:
                return DayOfWeek.Friday;
            case 6:
                return DayOfWeek.Saturday;

            default:
                throw new Error(`Invalid day index: ${index}`);
        }
    }

    static daysFromNow(days: number): Date {
        const now = DateProviderService.getDate();

        return DateProviderService.buildDate(now.getFullYear(), now.getMonth(), now.getDate() + days);
    }
}
