import { Injectable } from '@angular/core';

import { MessageEnvelope, MessageType } from '@cds/push';
import { StringDictionary } from '@frontend/sports/common/base-utils';
import { flatten, values } from 'lodash-es';
import { Observable, Subject, map } from 'rxjs';

import { CampaignSubscription, CdsPushService, CdsSubscription } from '../cds/cds-push.service';
import { Promotion } from '../crm-offer-data/crm-offer.model';
import { OffersResponse } from '../crm-offer-data/crm-offer.server.model';
import { ApiService } from '../http/api.service';
import { UserService } from '../user/services/user.service';
import { CrmPromotionFactory } from './crm-promotion.factory';

enum OfferStatus {
    Inactive = 'INACTIVE',
    Edited = 'EDITED',
}

@Injectable({ providedIn: 'root' })
export class CdsSubscriptionService {
    private _promotions$: Subject<Promotion[]> = new Subject<Promotion[]>();
    readonly promotions$ = this._promotions$.asObservable();

    private subscribedPromotions: Promotion[];
    private pushSubscriptions: StringDictionary<CdsSubscription[]> = {};

    private campaignCallback = (message: MessageEnvelope) => this.campaignUpdateCallback(message);

    constructor(
        private cdsPushService: CdsPushService,
        private userService: UserService,
        private apiService: ApiService,
        private crmPromotionFactory: CrmPromotionFactory,
    ) {
        this.userService.onUserLogout$.subscribe(() => this.unsubscribe());
    }

    subscribeToPush(promotions: Promotion[]): Observable<Promotion[]> {
        this.initialize(promotions);

        return this._promotions$.asObservable();
    }

    unsubscribe(): void {
        this.cdsPushService.unsubscribe(this.getAllSubscriptions());
        this.subscribedPromotions = [];
        this.pushSubscriptions = {};
    }

    private initialize(promotions: Promotion[]): void {
        this.subscribedPromotions = promotions;
        this.cdsPushService.subscribe(this.getSubscriptions(promotions));
    }

    private getSubscriptions(promotions: Promotion[]): CdsSubscription[] {
        promotions.forEach((promo) => {
            this.pushSubscriptions[promo.id] = [
                new CampaignSubscription({ campaignId: promo.id }, this.campaignCallback),
                new CampaignSubscription({ campaignId: promo.id, userAccountId: this.userService.accountId! }, this.campaignCallback),
            ];
        });

        return this.getAllSubscriptions();
    }

    private campaignUpdateCallback = async (pushData: MessageEnvelope) => {
        if (!this.pushSubscriptions[pushData.payload.offerId]) {
            return;
        }
        const promoId = pushData.payload.offerId;

        switch (pushData.messageType) {
            case MessageType.PromoCampaignUpdate: {
                if (pushData.payload.status === OfferStatus.Inactive) {
                    this.removePromotion(promoId);
                } else {
                    this.refreshPromotions();
                }
                break;
            }
            case MessageType.PromoCampaignStatusUpdate: {
                this.removePromotion(promoId);
                break;
            }
            case MessageType.PromoUserCampaignStatusUpdate: {
                this.playerUpdate(pushData);
                break;
            }
        }
    };

    private removePromotion(promoId: string): void {
        this.subscribedPromotions = this.subscribedPromotions.filter((promo) => promo.id !== promoId);

        this.cdsPushService.unsubscribe(this.pushSubscriptions[promoId]);
        delete this.pushSubscriptions[promoId];

        this.push();
    }

    private refreshPromotions(): void {
        this.apiService
            .get<OffersResponse>('crmOffers')
            .pipe(map((result) => this.crmPromotionFactory.toModelList(result.campaigns)))
            .subscribe((data) => {
                this.initialize(data);
                this.push();
            });
    }

    private playerUpdate(pushData: MessageEnvelope): void {
        this.subscribedPromotions
            .filter((promo) => promo.id === pushData.payload.offerId)
            .forEach((promo) => (promo.callToAction = pushData.payload.cta));
        this.push();
    }

    private push(): void {
        this._promotions$.next(this.subscribedPromotions);
    }

    private getAllSubscriptions(): CdsSubscription[] {
        return flatten(values(this.pushSubscriptions));
    }
}
