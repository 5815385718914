import { Injectable } from '@angular/core';

import { PrettyUrlsConfig, Sitecore } from '@frontend/sports/common/client-config-data-access';
import { IntlService } from '@frontend/vanilla/core';

import { DateProviderService } from '../common/date-provider.service';
import { CalendarFilterTracking } from '../tracking/tracking.models';
import { CountIntervalService, IntervalFilter } from './count-interval.service';

@Injectable({ providedIn: 'root' })
export class CalendarIntervalService {
    readonly dayOffset = 24 * 60;

    constructor(
        protected countIntervalService: CountIntervalService,
        private intlService: IntlService,
        protected sitecore: Sitecore,
        protected urlConfig: PrettyUrlsConfig,
    ) {}

    getCalendarIntervals(): Record<string, IntervalFilter> {
        const { translations } = this.urlConfig;
        const { globalMessages } = this.sitecore;

        return {
            live: { name: translations.live, title: globalMessages.NavLive, live: true, trackingSource: CalendarFilterTracking.Live, key: 'live' },
            today: this.countIntervalService.intervalStartOfDay(
                translations.today,
                0,
                this.dayOffset,
                globalMessages.NavToday,
                'today',
                CalendarFilterTracking.Today,
            ),
            tomorrow: this.countIntervalService.intervalStartOfDay(
                translations.tomorrow,
                this.dayOffset,
                this.dayOffset * 2,
                globalMessages.Tomorrow,
                'tomorrow',
                CalendarFilterTracking.Tomorrow,
            ),
            after2days: this.countIntervalService.intervalStartOfDay(
                translations.after2days,
                this.dayOffset * 2,
                this.dayOffset * 3,
                this.getDate(2),
                'after2days',
                CalendarFilterTracking.In2Days,
            ),
            after3days: this.countIntervalService.intervalStartOfDay(
                translations.after3days,
                this.dayOffset * 3,
                this.dayOffset * 4,
                this.getDate(3),
                'after3days',
                CalendarFilterTracking.In3Days,
            ),
            in30minutes: this.countIntervalService.intervalStartingNow(
                translations.in30minutes,
                0,
                30,
                `${globalMessages.StartingIn} 30'`,
                'in30minutes',
                CalendarFilterTracking.In30minutes,
            ),
            in60minutes: this.countIntervalService.intervalStartingNow(
                translations.in60minutes,
                0,
                60,
                `${globalMessages.StartingIn} 60'`,
                'in60minutes',
                CalendarFilterTracking.In60Minutes,
            ),
            in180minutes: this.countIntervalService.intervalStartingNow(
                translations.in180minutes,
                0,
                180,
                `${globalMessages.StartingIn} 180'`,
                'in180minutes',
                CalendarFilterTracking.In3Hours,
            ),
        };
    }

    /**
     * Returns a date that is relative to the current date by the number of daysFromCurrentDate. It is formatted with a given format or 'shortDate'.
     *
     * @param [daysFromCurrentDate=0]
     * @param [format]
     * @returns
     *
     * @memberOf Calendar
     */
    getDate(daysFromCurrentDate: number = 0, format?: string): string {
        let date = DateProviderService.getDate();
        format = format || 'shortDate';

        if (daysFromCurrentDate > 0) {
            date = new Date(date.setDate(date.getDate() + daysFromCurrentDate));
        }

        return this.intlService.formatDate(date, format) || '';
    }
}
