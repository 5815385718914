import { Injectable } from '@angular/core';

import { StakeConfig } from '@frontend/sports/common/client-config-data-access';

import { UserService } from '../user/services/user.service';

@Injectable({ providedIn: 'root' })
export class IncrementalStakesService {
    constructor(
        private stakeConfig: StakeConfig,
        private userService: UserService,
    ) {}

    getValues(): number[] {
        const settings = this.userService.bettingSettings.keypadIncrementalStakes;
        if (settings?.length) {
            return settings;
        }

        return this.getDefaultValues();
    }

    getDefaultValues(): number[] {
        const currency = this.userService.currency.toLowerCase();

        return this.stakeConfig.addToStakeValues[currency] || this.stakeConfig.addToStakeValues.default;
    }
}
