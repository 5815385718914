import { Injectable } from '@angular/core';

import { Fixture } from '@cds/betting-offer';
import { HooksWireup, OnDestroyCleanup, Utils } from '@frontend/sports/common/base-utils';
import { WindowRef } from '@frontend/vanilla/core';
import { concat, uniqBy } from 'lodash-es';
import { Subject, debounceTime, takeUntil } from 'rxjs';

import { BreadcrumbModel } from '../breadcrumbs/breadcrumb.models';
import { EventModel } from '../event-model/model/event.model';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { UrlHelperService } from '../navigation-core/url-helper.service';
import { SchemaMarkupType } from './schema-markup-enum';
import {
    SeoBreadcrumbSchema,
    SeoItemSchema,
    SeoLdJsonSchemaContainer,
    SeoListItemSchema,
    SeoMarkupConstants,
    SeoPlaceSchema,
    SeoPostalAddressSchema,
    SeoSportsEventSchema,
} from './schema-markup.models';

@HooksWireup()
@Injectable({ providedIn: 'root' })
export class SchemaMarkupService extends OnDestroyCleanup {
    private readonly logger: SportsRemoteLogger;
    private events: Array<EventModel | Fixture>;
    private moduleEvents$ = new Subject<void>();

    constructor(
        loggerFactory: LoggerFactory,
        private utils: Utils,
        private urlHelper: UrlHelperService,
        private windowRef: WindowRef,
    ) {
        super();
        this.logger = loggerFactory.getLogger('SchemaMarkupService');
        this.events = [];
        this.moduleEvents$.pipe(debounceTime(100), takeUntil(this.destroyed$)).subscribe((_) => {
            this.addEventSchemaMarkup(this.events);
        });
    }

    private get document(): Document {
        return this.windowRef.nativeWindow.document;
    }

    private addGenericSchemaMarkup(schemaMarkupType: SchemaMarkupType): HTMLScriptElement {
        const scriptElement = this.document.createElement('script');

        scriptElement.type = `application/ld+json`;
        scriptElement.id = `${SeoMarkupConstants.ldJsonPrefix}${schemaMarkupType}`;

        return scriptElement;
    }

    addBreadcrumbSchemaMarkup(breadcrumbs: BreadcrumbModel[]): void {
        if (breadcrumbs && breadcrumbs.length > 0) {
            const scriptElement = this.addGenericSchemaMarkup(SchemaMarkupType.BreadcrumbList);
            scriptElement.text = this.generateBreadcrumbSchema(breadcrumbs);
            if (scriptElement.text) {
                this.addSchemaMarkupScriptToBody(SchemaMarkupType.BreadcrumbList, scriptElement);
            }
        }
    }

    cleanEvents(): void {
        this.events = [];
    }

    addEventSchemaMarkup(events: Array<EventModel | Fixture>): void {
        this.events = events;
        if (events && events.length > 0) {
            const scriptElement = this.addGenericSchemaMarkup(SchemaMarkupType.SportsEvent);
            if (this.isFixutreModel(events)) {
                scriptElement.text = this.generateFixtureSchema(events as Fixture[]);
            } else {
                scriptElement.text = this.generateEventSchema(events as EventModel[]);
            }
            this.addSchemaMarkupScriptToBody(SchemaMarkupType.SportsEvent, scriptElement);
        }
    }

    updateModuleEvents(fixtures: Array<EventModel | Fixture>) {
        this.events = uniqBy(concat(this.events, fixtures), (f) => f.id);
        this.moduleEvents$.next();
    }

    private isFixutreModel(model: Array<EventModel | Fixture>): boolean {
        return model && model.some((event) => event && event['context'] && event['source']);
    }

    private generateBreadcrumbSchema(breadcrumbs: BreadcrumbModel[]): string {
        const listItemSchema: SeoListItemSchema[] = breadcrumbs
            .filter((crumb) => crumb.url)
            .map((crumb, index) => {
                return new SeoListItemSchema(new SeoItemSchema(this.urlHelper.getAbsolute(this.urlHelper.getUrl(crumb.url))), crumb.title, index);
            });

        if (listItemSchema.length > 0) {
            const breadCrumbsSchema = new SeoBreadcrumbSchema(listItemSchema);

            return this.generateLdJson(breadCrumbsSchema);
        }

        return '';
    }

    private generateLdJson(schema: Array<SeoSportsEventSchema> | SeoBreadcrumbSchema): string {
        const ldJson = new SeoLdJsonSchemaContainer(schema);

        return JSON.stringify(ldJson);
    }

    private generateFixtureSchema(events: Fixture[]): string {
        const eventsSchema = events.map((fixture) =>
            this.generateSportEventSchemaObject(
                fixture.name.value,
                this.utils.getIsoStringWithZone(new Date(fixture.startDate)),
                fixture.id,
                fixture.competition.name.value,
                fixture.region.name.value,
                fixture.region.code,
            ),
        );

        return this.generateLdJson(eventsSchema);
    }

    private generateEventSchema(events: EventModel[]): string {
        const eventsSchema = events.map((event) =>
            this.generateSportEventSchemaObject(
                event.name,
                this.utils.getIsoStringWithZone(new Date(event.startDate)),
                event.id,
                event.league.name,
                event.region.name,
                event.region.code || '',
            ),
        );

        return this.generateLdJson(eventsSchema);
    }

    private generateSportEventSchemaObject(
        name: string,
        startDate: string,
        id: string,
        leagueName: string,
        regionName: string,
        regionCode: string,
    ): SeoSportsEventSchema {
        const isRegion = this.getRegion(regionCode);

        return new SeoSportsEventSchema(
            name,
            startDate,
            this.urlHelper.getAbsolute(this.urlHelper.getEventUrl({ id, name })),
            new SeoPlaceSchema(leagueName, new SeoPostalAddressSchema(leagueName, regionName, isRegion)),
        );
    }

    private getRegion(regionCode: string): boolean {
        let isRegion = false;
        if (regionCode && regionCode.toUpperCase() !== SeoMarkupConstants.regionWorldCode) {
            // if region is WORLD then region should be league name
            isRegion = true;
        }

        return isRegion;
    }

    private addSchemaMarkupScriptToBody(schemaType: SchemaMarkupType, script: HTMLScriptElement): void {
        try {
            this.removeSchemaMarkupScriptFromBody(schemaType);
            this.document.body.appendChild(script);
        } catch (error) {
            this.logger.error(error, 'Error adding Schema Markup script to view');
        }
    }

    removeSchemaMarkupScriptFromBody(schemaType: SchemaMarkupType): void {
        try {
            const ldJson = this.document.getElementById(`${SeoMarkupConstants.ldJsonPrefix}${schemaType}`);
            if (ldJson) {
                ldJson.remove();
            }
        } catch (error) {
            this.logger.error(error, 'Error Removing Schema Markup script from Body');
        }
    }
}
