import { Injectable } from '@angular/core';

import { LocalStoreService, SessionStoreService } from '@frontend/vanilla/core';

import { WalletType } from './account-balance.models';

@Injectable({ providedIn: 'root' })
export class WalletStoreService {
    private walletTypeKey = 'walletType';
    private walletSelectorShownKey = 'walletSelectorIsShown';

    constructor(
        private localStoreService: LocalStoreService,
        private sessionStoreService: SessionStoreService,
    ) {}

    setSelectedWallet(walletType: WalletType, persistPermanently?: boolean): void {
        if (persistPermanently) {
            this.sessionStoreService.remove(this.walletTypeKey);
            this.localStoreService.set(this.walletTypeKey, walletType);
        } else {
            this.sessionStoreService.set(this.walletTypeKey, walletType);
        }
    }

    get isPermanentWallet(): boolean {
        return !!this.localStoreService.get(this.walletTypeKey);
    }

    get selectedWallet(): WalletType | null {
        return this.localStoreService.get(this.walletTypeKey) || this.sessionStoreService.get(this.walletTypeKey);
    }

    set walletSelectorIsShown(newValue: boolean) {
        this.sessionStoreService.set(this.walletSelectorShownKey, newValue);
    }

    get walletSelectorIsShown(): boolean {
        return !!this.sessionStoreService.get(this.walletSelectorShownKey);
    }
}
