import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { OddsAcceptanceMode } from '@bpos';
import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { UrlService } from '@frontend/vanilla/core';
import { Store, createSelector } from '@ngrx/store';
import { isAccaBoostTokenSelector } from 'packages/sports/web/app/src/acca-boost-base/acca-boost-utils';
import { AdaptiveLayoutService } from 'packages/sports/web/app/src/layout/adaptive-layout.service';
import { MarketUrlParam } from 'packages/sports/web/app/src/navigation-core/url-helper.service';
import StoragePersister from 'packages/sports/web/app/src/store-persist/storage-persister';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, mergeMap, startWith, switchMap, take, tap } from 'rxjs/operators';

import { IBetslipRootState, IBetslipState, betslipFeatureKey } from '../base/store/state';
import { BetslipState } from '../core/betslip-state';
import { BetslipType } from '../core/betslip-type';
import { BetslipHost, ExternalBetslipActions, PickAddPayload } from '../core/external-betslip-actions';
import { BetslipBetBuilderPick } from '../core/picks/betslip-bet-builder-pick';
import { BetBuilderPickId, PickId } from '../core/picks/pick-id';
import { PickType, PriceType } from '../core/picks/pick-models';
import { flattenGroupPicksIfAny } from '../core/utils';
import { BetslipBarVisibilityService } from '../modules/betslip-bar/services/betslip-bar-visibility.service';
import { hasPlacedFirstBetSelector } from '../modules/hidden-market/hidden-market.selectors';
import { IRewardToken } from '../modules/reward-tokens/reward-tokens.model';
import { selectRewardTokensList } from '../modules/reward-tokens/selectors';
import SettingsActions from '../modules/settings/actions';
import { EditBetActions } from './../modules/edit-bet/actions';
import { BetslipModule, BetslipModuleLoaderService } from './betslip-module-loader.service';
import { BETSLIP_MODULE_LOADER_SERVICE } from './sports-injection-services';

@Injectable({ providedIn: 'root' })
export class BetslipIntegrationService {
    private betslipHost?: BetslipHost;

    private betslipPickSelectedSelectorFactory = (
        module: BetslipModule,
        pickId: PickId,
        priceType?: PriceType,
        longId?: string,
        siblingPickId?: PickId,
    ) =>
        createSelector(
            module.exported.selectors.betslipTypeCurrentTypeSelector,
            module.exported.selectors.betslipPicksListSelector,
            module.exported.selectors.editBetPicksListSelector,
            module.exported.selectors.editBetAddedPicksListSelector,
            module.exported.selectors.editBetPickStateSelector,
            (type, picks, editBetPicks, editBetAddedPicks, pickstate) => {
                if (type === BetslipType.EditBet) {
                    const editBetPick =
                        editBetPicks.find((p) => p.id.toString() === pickId.toString() && pickstate[pickId.toString()].isRemoved === false) ||
                        editBetAddedPicks.find((p) => p.id.toString() === pickId.toString());

                    return !!editBetPick && (editBetPick.priceType === priceType || priceType === undefined);
                }

                if (pickId.getPickType() === PickType.BetBuilderPick && longId) {
                    const bbPickId = pickId as BetBuilderPickId;

                    return picks.some(
                        (p) =>
                            BetslipBetBuilderPick.isEntainUiBetBuilderPick(p) &&
                            p.eventId === bbPickId.eventId &&
                            p.sportcastOptions.map((so) => so.longId).some((id) => id === longId),
                    );
                }

                const pick = flattenGroupPicksIfAny(picks).find((p) => p.id.isEqual(pickId));
                const siblingPick = siblingPickId && picks.find((t) => t.id.isEqual(siblingPickId));

                return (!!pick && (pick.priceType === priceType || priceType === undefined)) || !!siblingPick;
            },
        );

    private locationUrl$: Observable<string | undefined>;

    constructor(
        private store: Store<IBetslipRootState>,
        private dispatcher: DispatcherService,
        private storagePersister: StoragePersister,
        private adaptiveLayoutService: AdaptiveLayoutService,
        private urlService: UrlService,
        private location: Location,
        @Inject(BETSLIP_MODULE_LOADER_SERVICE) private betslipModuleLoader: BetslipModuleLoaderService,
        private betslipBarVisibility: BetslipBarVisibilityService,
    ) {
        const locationUrl = new Subject<string | undefined>();

        this.locationUrl$ = locationUrl.pipe(distinctUntilChanged());

        this.location.onUrlChange((url) => locationUrl.next(url));
        this.betslipInitialized$().subscribe();
    }

    betslipInitialized$(): Observable<BetslipHost> {
        if (this.betslipHost) {
            return of(this.betslipHost);
        }

        return this.dispatcher.on<string>('MODULE_LOADED').pipe(
            filter((value) => value === 'BetslipBetStationModule' || value === 'BetslipDigitalModule'),
            take(1),
            map((value) => (value === 'BetslipDigitalModule' ? BetslipHost.Digital : BetslipHost.BetStation)),
            tap((host) => {
                this.betslipHost = host;
            }),
        );
    }

    isEditBetActive$(betslipId: string): Observable<boolean> {
        return this.betslipInitialized$().pipe(
            mergeMap(() => this.betslipModuleLoader.loadBetslipModule()),
            mergeMap((module) =>
                this.store.select(module.exported.selectors.betslipTypeStateSelector).pipe(
                    map((state) => {
                        return state.base.currentSelectedType === BetslipType.EditBet && state.editBet.betslipId === betslipId;
                    }),
                ),
            ),
        );
    }

    isAccaBoostToken$(): Observable<boolean> {
        return this.store.select(isAccaBoostTokenSelector);
    }

    betslipState$(): Observable<BetslipState> {
        return this.betslipInitialized$().pipe(
            mergeMap(() => this.betslipModuleLoader.loadBetslipModule()),
            mergeMap((module) => this.store.select(module.exported.selectors.betslipBaseStateSelector)),
        );
    }

    /**
     * Indicates whether the user has placed a bet in the current application run.
     * Used to determine whether to show hidden markets.
     */
    hasPlacedFirstBet$(): Observable<boolean> {
        return this.store.select(hasPlacedFirstBetSelector);
    }

    rewardTokens$(): Observable<IRewardToken[]> {
        return this.store.select(selectRewardTokensList);
    }

    requestExitEditBet(): void {
        this.store.dispatch(EditBetActions.requestDiscard());
    }

    /**
     * Define when to show betstation betslip:
     * 1. Not initialized ? Check if we have something in the savedState and if we have picks there load it.
     * 2. Initialized ? Check current betslip count.
     */
    shouldShowBetslipInBetStation$(): Observable<boolean> {
        const betslipPicksCountWatcher$ = this.betslipPicksCount$().pipe(map((count) => !!count));
        if (!this.betslipHost) {
            // Betslip not loaded, check storage if has some picks then load it.
            const savedState = this.storagePersister.load();
            if (savedState && betslipFeatureKey in savedState) {
                const betslipState = savedState[betslipFeatureKey] as IBetslipState;
                if (betslipState?.picks?.pickList?.length) {
                    return betslipPicksCountWatcher$.pipe(startWith(true));
                }
            }
        }

        // if betslip is loaded or no picks in the storage then check picks count.
        return betslipPicksCountWatcher$;
    }

    /**
     * Define when to show QuickBet:
     * Show Quick Bet if the layout's quick bet active is set to true.
     */
    shouldShowQuickBet$(): Observable<boolean> {
        return this.adaptiveLayoutService.stateChange$.pipe(
            distinctUntilKeyChanged('quickBetActive'),
            map((t) => t.quickBetActive),
        );
    }

    /**
     * Define when to show Betslip returns messages:
     * 1. when bottom navigation is visible and
     * 2. when the user is not in the Bet Builder tab
     */
    shouldShowBetslipReturnsMessage$(): Observable<boolean> {
        const isInBetBuilderTab$ = this.betslipInitialized$().pipe(
            switchMap(() => {
                return this.locationUrl$.pipe(
                    map((url) => url && this.urlService.parse(url).search.get('market') === MarketUrlParam.BetBuilder),
                    startWith(this.urlService.current().search.get('market') === MarketUrlParam.BetBuilder),
                );
            }),
        );

        const hasBottomNavigation$ = this.adaptiveLayoutService.stateChange$.pipe(map((state) => !!state.bottomNavigation));

        return combineLatest([isInBetBuilderTab$, hasBottomNavigation$]).pipe(
            map(([isInBetBuilderTab, hasBottomNavigation]) => hasBottomNavigation && !isInBetBuilderTab),
            distinctUntilChanged(),
        );
    }

    /**
     * Define when to show QuickBetBuilder in Entain UI:
     * Show Quick Bet Builder Drawer if the layout's quick bet active is set to true.
     */
    shouldShowQuickBetBuilder$(): Observable<boolean> {
        return this.adaptiveLayoutService.stateChange$.pipe(
            distinctUntilKeyChanged('quickBetBuilderActive'),
            map((t) => t.quickBetBuilderActive),
        );
    }

    betslipPicksCount$(): Observable<number> {
        return this.betslipInitialized$().pipe(
            mergeMap((_) => this.betslipModuleLoader.loadBetslipModule()),
            mergeMap((module) => this.store.select(module.exported.selectors.betslipPickIdsSelector).pipe(map((picks) => picks.length))),
        );
    }

    betslipPickIds$(): Observable<any[]> {
        return this.betslipInitialized$().pipe(
            mergeMap((_) => this.betslipModuleLoader.loadBetslipModule()),
            mergeMap((module) => this.store.select(module.exported.selectors.betslipPickIdsSelector).pipe(map((picks) => picks))),
        );
    }

    pickSelected$(pickId: PickId, priceType?: PriceType, longId?: string, siblingPickId?: PickId): Observable<boolean> {
        return this.betslipInitialized$().pipe(
            mergeMap((_) => this.betslipModuleLoader.loadBetslipModule()),
            mergeMap((module) => this.store.select(this.betslipPickSelectedSelectorFactory(module, pickId, priceType, longId, siblingPickId))),
        );
    }

    get betslipBarEnabled(): boolean {
        return this.betslipBarVisibility.enabled;
    }

    addMultiplePicks(payload: { picks: PickAddPayload[]; stake?: number }): void {
        this.store.dispatch(ExternalBetslipActions.addMultiplePicks(payload));
    }

    addPick(payload: PickAddPayload): void {
        this.store.dispatch(ExternalBetslipActions.addPick(payload));
    }

    removeMultiplePicks(payload: { pickIds: PickId[] }): void {
        this.store.dispatch(ExternalBetslipActions.removeMultiplePicks(payload));
    }

    setOddsAcceptance(oddsAcceptance: string = OddsAcceptanceMode.No): void {
        if (!this.betslipHost) {
            // If betslip is not initialized, don't do anything when we init betslip we will read what is the current odds acceptance.
            return;
        }
        this.store.dispatch(SettingsActions.setOddAcceptance({ acceptance: oddsAcceptance as OddsAcceptanceMode }));
    }
}
