import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { OfferStatus, OfferStatusRequest, OfferStatusResponse, OfferStatusType, Promotion } from '../crm-offer-data/crm-offer.model';
import { ApiService } from '../http/api.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';

@Injectable({ providedIn: 'root' })
export class OfferStatusService {
    private optinResponseToStatus = {
        OFFERED: OfferStatus.OptIn,
        NOT_OFFERED: OfferStatus.NoOptIn,
        EXPIRED: OfferStatus.Expired,
        OPTED_IN: OfferStatus.OptedIn,
    };

    private readonly logger: SportsRemoteLogger;

    constructor(
        private api: ApiService,
        loggerFactory: LoggerFactory,
    ) {
        this.logger = loggerFactory.getLogger('OfferStatusService');
    }

    setOfferStatus(promotion: Promotion): Observable<OfferStatus> {
        const request: OfferStatusRequest = {
            id: promotion.id,
            type: OfferStatusType[promotion.type],
            source: 'SPORTS',
        };

        return this.api.post<OfferStatusResponse>('crmPromotions/offerStatus', request).pipe(
            map((response) => this.optinResponseToStatus[response.status] || OfferStatus.Error),
            catchError((error) => {
                this.logger.error(error, 'OfferStatusService');

                return of(OfferStatus.Error);
            }),
        );
    }
}
