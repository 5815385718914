export enum WalletType {
    Any = 'Any',
    PayPal = 'PayPal',
    Main = 'Main',
}

export interface WalletItem {
    type: WalletType;
    amount: number;
    selected: boolean;
    title: string;
    shortTitle: string;
    icon: string;
}

export enum WalletPersistenceType {
    Temporary = 'Temporary',
    Permanent = 'Permanent',
}

export enum Tracking {
    Wallets = 'Wallets',
    PayPalWallet = 'PayPal Wallet',
    Desktop = 'Desktop V6',
    Mobile = 'Mobile V6',
    NotApplicable = 'not applicable',
    SportsSiteSection = 'Sports',
    DialogSelectorLabelEvent = 'PayPal - Choose Wallet Interceptor',
    Betslip = 'Betslip',
}
