import { Injectable } from '@angular/core';

import { RacingConfig } from '@frontend/sports/common/client-config-data-access';
import { hasBestOddsGuaranteeFlag } from 'packages/sports/common/betslip/modules/reward-tokens/validators';
import { Observable, of } from 'rxjs';

import { BestOddsFixtureLikeInput } from '../models/models';
import { BestOddsGuaranteedService } from './best-odds-guaranteed.service';

/**
 * BogVersion allows selecting which BOG implementation is used on the label. At the moment two values are possible:
 * Classic (old one)
 * TokenBased (new one)
 *
 * This service implements old behavior of `BestOddsGuaranteedService`
 */
@Injectable({ providedIn: 'root' })
export class ClassicBestOddsGuaranteedService extends BestOddsGuaranteedService {
    constructor(private racingConfig: RacingConfig) {
        super();
    }

    isFixtureEligible(fixture: BestOddsFixtureLikeInput): Observable<boolean> {
        return of(!!this.racingConfig.isBOGEnabled && hasBestOddsGuaranteeFlag(fixture));
    }
}
