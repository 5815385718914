import { Injectable } from '@angular/core';

import { assign, clone, isMatch } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ColumnSize } from './layout.model';

export interface AdaptiveLayoutFlag {
    columns?: number;
    headerNavigation?: boolean;
    bottomNavigation?: boolean;
    headerSubNavigation?: boolean;
    subNavigationHiddenOnSmallScreen?: boolean;
    shouldHideSubNavigation?: boolean;
    topNavigationHiddenOnSmallScreen?: boolean;
    headerTabNavigation?: boolean;
    breadcrumbNavigation?: boolean;
    subNavigation?: boolean;
    stickyHeader?: boolean;
    cardContent?: boolean;
    htmlClass?: string;
    fixedContent?: boolean;
    quickBetActive?: boolean;
    quickBetBuilderActive?: boolean;
    rightColumnSize?: ColumnSize;
    shouldKeepBreadcrumbs?: boolean;
}

export class AdaptiveLayoutState {
    constructor(public flags: AdaptiveLayoutFlag) {}

    get htmlClasses(): string[] {
        const classes: string[] = [];

        if (this.flags.htmlClass) {
            classes.push(this.flags.htmlClass);
        }

        if (this.flags.cardContent) {
            classes.push('card-content');
        }

        if (this.flags.fixedContent) {
            classes.push('fixed-content');
        }

        if (this.flags.subNavigation) {
            classes.push('header-sub-navigation');
        }

        if (this.flags.stickyHeader) {
            classes.push('header-sticky');
        }

        return classes;
    }

    get cardContent(): boolean | undefined {
        return this.flags.cardContent;
    }

    get columns(): number {
        const fixedLayout = 1;

        if (this.flags.fixedContent) {
            return fixedLayout;
        }

        return this.flags.columns || fixedLayout;
    }

    get headerNavigation(): boolean | undefined {
        return this.flags.headerNavigation && (!this.flags.breadcrumbNavigation || this.flags.columns === 3);
    }

    get headerSubNavigation(): boolean | undefined {
        return this.flags.headerSubNavigation;
    }

    get headerSubNavigationHidden(): boolean | undefined {
        return !this.flags.subNavigation || (this.flags.shouldHideSubNavigation && this.flags.subNavigationHiddenOnSmallScreen);
    }

    get headerTopNavigationHidden(): boolean | undefined {
        return !this.flags.headerNavigation || (this.flags.topNavigationHiddenOnSmallScreen && this.bottomNavigation);
    }

    get headerTabNavigation(): boolean | undefined {
        return this.flags.headerTabNavigation;
    }

    get breadcrumbNavigation(): boolean | undefined {
        return this.flags.breadcrumbNavigation;
    }

    get bottomNavigation(): boolean {
        return !!this.flags && !!this.flags.columns && this.flags.columns < 3 && !!this.flags.bottomNavigation;
    }

    get hasRightColumn(): boolean {
        return this.columns === 3;
    }

    get quickBetActive(): boolean {
        return !!this.flags.quickBetActive && this.quickBetCanBeShown;
    }

    get quickBetBuilderActive(): boolean {
        return !!this.flags.quickBetBuilderActive;
    }

    get quickBetCanBeShown(): boolean {
        return this.columns < 3 || !!this.flags.quickBetBuilderActive;
    }

    get rightColumnSize(): ColumnSize {
        return this.flags.rightColumnSize || ColumnSize.Default;
    }

    get showDesktopAZSports(): boolean {
        return this.columns > 2;
    }
}

export interface AdaptiveLayoutProvider {
    asObservable(): Observable<AdaptiveLayoutFlag>;
}

@Injectable({ providedIn: 'root' })
export class AdaptiveLayoutService {
    private state = new AdaptiveLayoutState({});
    private stateChange = new BehaviorSubject<AdaptiveLayoutState>(this.state);
    private providerListChange = new Subject<void>();

    private currentProviders: AdaptiveLayoutProvider[] = [];

    readonly stateChange$ = this.stateChange.asObservable();

    get current(): AdaptiveLayoutState {
        return this.state;
    }

    register(...providers: AdaptiveLayoutProvider[]): void {
        this.currentProviders.push(...providers);
        this.providerListChange.next();

        const observables = this.currentProviders.map((provider) => provider.asObservable());

        merge(...observables)
            .pipe(takeUntil(this.providerListChange))
            .subscribe((flags) => {
                if (isMatch(this.state.flags, flags)) {
                    return;
                }

                const stateFlags = assign({}, this.state.flags, flags);
                this.state = new AdaptiveLayoutState(stateFlags);

                this.stateChange.next(clone(this.state));
            });
    }
}
