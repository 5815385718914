import { BetslipType } from '../../core/betslip-type';
import { SlipId } from '../bet-generation/models';
import { BetslipError } from './errors/betslip-error';
import { ResultError } from './errors/result/result-error';

export interface IBetslipPickErrors {
    [pickId: string]: ResultError[];
}

export type BetslipTypePickErrors = Record<BetslipType, IBetslipPickErrors>;
export type BetslipTypeErrors = Record<BetslipType, BetslipError[]>;
export type SlipErrors = Record<SlipId, BetslipError[]>;

// New error state necessary to support linear betslip
// Try to avoid using the state directly and instead use one of the many selectors or create a new selector
export interface IBetslipErrorsState {
    pickErrors: BetslipTypePickErrors;
    slipErrors: SlipErrors;
    betslipTypeErrors: BetslipTypeErrors;
    betslipErrors: BetslipError[];
}

export interface IBetslipLegacyErrorsState {
    pickErrors: IBetslipPickErrors;
    betslipErrors: BetslipError[];
}

export const defaultErrorState: IBetslipErrorsState = {
    pickErrors: {
        SINGLE: {},
        COMBO: {},
        SYSTEM: {},
        TEASER: {},
        EDIT_MY_BET: {},
        BET_BUILDER: {},
    },
    slipErrors: {},
    betslipTypeErrors: {
        SINGLE: [],
        COMBO: [],
        SYSTEM: [],
        TEASER: [],
        EDIT_MY_BET: [],
        BET_BUILDER: [],
    },
    betslipErrors: [],
};

export const emptyPickErrors = (): BetslipTypePickErrors => ({
    SINGLE: {},
    COMBO: {},
    SYSTEM: {},
    TEASER: {},
    EDIT_MY_BET: {},
    BET_BUILDER: {},
});

export const emptyBetslipTypeErrors = (): BetslipTypeErrors => ({
    SINGLE: [],
    COMBO: [],
    SYSTEM: [],
    TEASER: [],
    EDIT_MY_BET: [],
    BET_BUILDER: [],
});
