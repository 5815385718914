import { Fixture } from '@cds/betting-offer';
import { pprops } from '@frontend/sports/common/base-utils';
import { CouponTypes } from '@frontend/sports/common/coupons-lib';
import { createAction, props } from '@ngrx/store';

import { SubscriptionTopic } from '../event-subscription/base-subscription.service';
import { GridEvent, GridGrouping, GridModel, GridSorting, Group, GroupBadge } from './grid.model';

export interface IGrid {
    id: string;
}

export interface IGridGroup {
    columnId: string;
    groupId: string;
}

export interface IGridAddEvent extends IGrid {
    events: GridEvent | GridEvent[];
    grouping?: GridGrouping;
}

export interface IGridAddCouponEvent extends IGrid {
    events: Fixture[];
    couponType: CouponTypes;
}

export interface IGridSetEvent extends IGridAddEvent {
    topic?: SubscriptionTopic;
}

export interface IGridUpdateEvent extends IGrid {
    event: GridEvent;
}

export interface IGridReplaceEvent extends IGrid {
    targetFixtureId: string; // Event to be removed
    newFixture: GridEvent; // Event to be inserted
}

export interface IGridEventsToReplace {
    currentEvent: GridEvent;
    newEvent?: GridEvent;
}

export interface IGridReplaceEvents extends IGrid {
    events: IGridEventsToReplace[];
}

export interface IGridCollapseEvent extends IGrid {
    eventId: string;
    optionGroupId?: string;
}

export interface IGridSetActiveGroup extends IGrid, IGridGroup {}

export interface IGridSetActiveGroupType extends IGridSetActiveGroup {
    extended: boolean;
}

export interface IGridSetGroupCollapse extends IGrid {
    groupId: number;
}

export interface IGridAddGroups extends IGrid {
    groups: Group[];
}

export interface IGridUpdateGroupsBadges extends IGrid {
    badges: Record<string, GroupBadge>;
}

export interface IGridChangeSorting extends IGrid {
    sorting: GridSorting;
}

export interface IGridRemoveEvent extends IGrid {
    eventIds: string[];
}

export const GRID_ACTION_SCHEMA = '@grid';

export class GridActions {
    static ADD_EVENTS = `${GRID_ACTION_SCHEMA}/ADD_EVENTS`;
    static SET_EVENTS = `${GRID_ACTION_SCHEMA}/SET_EVENTS`;
    static UPDATE_EVENTS = `${GRID_ACTION_SCHEMA}/UPDATE_EVENTS`;
    static REPLACE_EVENTS = `${GRID_ACTION_SCHEMA}/REPLACE_EVENTS`;
    static REMOVE_EVENTS = `${GRID_ACTION_SCHEMA}/REMOVE_EVENTS`;
    static REPLACE_EVENT = `${GRID_ACTION_SCHEMA}/REPLACE_EVENT`;
    static COLLAPSE_EVENTS = `${GRID_ACTION_SCHEMA}/COLLAPSE_EVENTS`;
    static DESTROY = `${GRID_ACTION_SCHEMA}/DESTROY`;
    static INIT = `${GRID_ACTION_SCHEMA}/INIT`;
    static SET_ACTIVE_GROUP = `${GRID_ACTION_SCHEMA}/SET_ACTIVE_GROUP`;
    static SET_ACTIVE_GROUP_TYPE = `${GRID_ACTION_SCHEMA}/SET_ACTIVE_GROUP_TYPE`;
    static SET_STATE_COLLAPSE = `${GRID_ACTION_SCHEMA}/SET_STATE_COLLAPSE`;
    static SET_GROUP_COLLAPSE = `${GRID_ACTION_SCHEMA}/SET_GROUP_COLLAPSE`;
    static SET_MORE_COLLAPSE = `${GRID_ACTION_SCHEMA}/SET_MORE_COLLAPSE`;
    static ADD_GROUPS = `${GRID_ACTION_SCHEMA}/ADD_GROUPS`;
    static UPDATE_GROUPS_BADGES = `${GRID_ACTION_SCHEMA}/UPDATE_GROUPS_BADGES`;
    static CHANGE_SORTING = `${GRID_ACTION_SCHEMA}/CHANGE_SORTING`;
    static ADD_COUPON_EVENTS = `${GRID_ACTION_SCHEMA}/ADD_COUPON_EVENTS`;

    static addEvents = createAction(GridActions.ADD_EVENTS, pprops<IGridAddEvent>());
    static setEvents = createAction(GridActions.SET_EVENTS, pprops<IGridSetEvent>());
    static updateEvents = createAction(GridActions.UPDATE_EVENTS, pprops<IGridUpdateEvent>());
    static replaceEvents = createAction(GridActions.REPLACE_EVENTS, pprops<IGridReplaceEvents>());
    static removeEvents = createAction(GridActions.REMOVE_EVENTS, pprops<IGridRemoveEvent>());
    static replaceEvent = createAction(GridActions.REPLACE_EVENT, pprops<IGridReplaceEvent>());
    static collapseEvents = createAction(GridActions.COLLAPSE_EVENTS, pprops<IGridCollapseEvent>());
    static destroy = createAction(GridActions.DESTROY, props<{ gridId: string }>());
    static init = createAction(GridActions.INIT, pprops<GridModel>());
    static setActiveGroup = createAction(GridActions.SET_ACTIVE_GROUP, pprops<IGridSetActiveGroup>());
    static setActiveGroupType = createAction(GridActions.SET_ACTIVE_GROUP_TYPE, pprops<IGridSetActiveGroupType>());
    static setGroupCollapse = createAction(GridActions.SET_GROUP_COLLAPSE, pprops<IGridSetGroupCollapse>());
    static setMoreCollapse = createAction(GridActions.SET_MORE_COLLAPSE, props<{ gridId: string }>());
    static addGroups = createAction(GridActions.ADD_GROUPS, pprops<IGridAddGroups>());
    static updateGroupsBadges = createAction(GridActions.UPDATE_GROUPS_BADGES, pprops<IGridUpdateGroupsBadges>());
    static changeSorting = createAction(GridActions.CHANGE_SORTING, pprops<IGridChangeSorting>());
    static addCouponEvents = createAction(GridActions.ADD_COUPON_EVENTS, pprops<IGridAddCouponEvent>());
}
