import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PerformanceService {
    private _measurements = new Map<number, boolean>();

    isLowTierDevice(benchmarkThreshold = 3): boolean {
        const measurement = this._measurements.get(benchmarkThreshold);

        if (measurement !== undefined) {
            return measurement;
        }

        const isSlow = isLowTierDevice(benchmarkThreshold);
        this._measurements.set(benchmarkThreshold, isSlow);

        return isSlow;
    }
}

/**
 * Runs simple benchmark to detect device performance
 *
 * devices with execution time >3ms considered as slow
 *
 * @param benchmarkThreshold - configurable threshold for execution time in ms
 */
export function isLowTierDevice(benchmarkThreshold = 3): boolean {
    return benchmark() > benchmarkThreshold;
}

function benchmark() {
    const start = performance.now();
    calculatePrimes(20000);

    return performance.now() - start;
}

function calculatePrimes(max: number) {
    const primes: number[] = [];

    for (let i = 1; i < max; i++) {
        if (isPrime(i)) primes.push(i);
    }

    return primes;
}

function isPrime(num: number) {
    for (let i = 2, s = Math.sqrt(num); i <= s; i++) {
        if (num % i === 0) {
            return false;
        }
    }

    return num > 1;
}
