import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { Injectable, LOCALE_ID, inject } from '@angular/core';

import { BetslipConfig, CurrencyConfig } from '@frontend/sports/common/client-config-data-access';
import { IntlService } from '@frontend/vanilla/core';
import { Decimal } from 'decimal.js';
import { trim } from 'lodash-es';

import { UserService } from '../user/services/user.service';
import { CurrencyPipeInput } from './model';

@Injectable({ providedIn: 'root' })
export class MoneyService {
    private readonly userService = inject(UserService);
    private readonly currencyConfig = inject(CurrencyConfig);
    private readonly currencyPipe = new CurrencyPipe(inject(LOCALE_ID));

    private readonly intlService = inject(IntlService);
    private readonly betslipConfig = inject(BetslipConfig);

    roundMoney(value: number | Decimal, digits: number = 2): number {
        if (typeof value === 'number') {
            value = new Decimal(value);
        }
        if (value.decimalPlaces() <= digits) {
            // No need to round.
            return value.toNumber();
        }
        const roundDigits = Math.pow(10, digits);

        return (
            this.betslipConfig.roundValues ? value.times(roundDigits).round().div(roundDigits) : value.times(roundDigits).floor().div(roundDigits)
        ).toNumber();
    }

    /* this is named transform to comply to the `ICurrencyPipe` interface from betstation */
    transform(
        input: CurrencyPipeInput,
        symbol: boolean = false,
        fractionSize: number = 2,
        hideEmptyFraction: boolean = false,
        locale?: string,
        currencyCode?: string,
        formatCurrency: boolean = true,
    ): string {
        if (!currencyCode) {
            currencyCode = this.userService.currency;
        }

        const prependCurrencySymbol = this.currencyConfig.currencySymbolDisplayList.includes(currencyCode);
        if (input == null) {
            const currencySymbol = getCurrencySymbol(currencyCode, 'narrow');
            if (prependCurrencySymbol) return symbol ? `${currencySymbol}-.--` : '-.--';
            else return symbol ? `-.-- ${currencyCode}` : '-.--';
        }

        if (typeof input === 'string') {
            const inputAsNumber = Number(input.replace(',', '.'));
            if (isNaN(inputAsNumber)) {
                return input;
            } else {
                input = inputAsNumber;
            }
        }

        let currencyFormat: string | undefined;
        if (hideEmptyFraction && Number(input) === Math.floor(Number(input))) {
            fractionSize = 0;
            currencyFormat = '1.0-0';
        } else {
            currencyFormat = this.currencyConfig.clientCurrencyFormat || undefined;
        }

        input = this.roundMoney(input, fractionSize);
        const formattedValue = trim(this.currencyPipe.transform(input, currencyCode, '', currencyFormat, locale) || '');
        if (prependCurrencySymbol) {
            if (!formatCurrency) {
                return symbol ? this.intlService.formatCurrency(input, currencyCode, currencyFormat) : formattedValue;
            }

            return symbol ? this.intlService.formatCurrency(input, currencyCode) : formattedValue;
        } else return symbol ? `${formattedValue} ${currencyCode}` : formattedValue;
    }
}
