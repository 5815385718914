import { ImageProfile } from '@cds/betting-offer';
import { CompetitionStatistics } from '@cds/statistics/competition';
import { StringDictionary } from '@frontend/sports/common/base-utils';

import { EventModel, EventOptionGroup, RegionModel, SportModel } from '../event-model/model/event.model';
import { SubscriptionTopic } from '../event-subscription/base-subscription.service';
import { MarketUrlParam } from '../navigation-core/url-helper.service';

export interface Column {
    id: string;
    groups: Group[];
    enabled: boolean;
    more: boolean;
}

export interface OptionsToShow {
    v1?: number[];
    v2?: number[];
}

export interface Group {
    id: string;
    name: string;
    active: boolean;
    extended: boolean;
    visible: boolean;
    balancedMarket?: boolean;
    marketAttribute?: boolean;
    options?: string[];
    childGroups?: string[];
    optionsToShow?: OptionsToShow;
    badge?: GroupBadge;
    scoreBoardPeriodId?: number;
    fallbackGridGroupIds?: string[];
    isFallbackGroup: boolean;
}

export enum GridLayout {
    Default,
    SixPack,
    Hybrid,
}

export interface GridBreakpointSize {
    columns: number;
    default: number;
}

export enum GridGrouping {
    None,
    Date,
    League,
    HomeForm,
    AwayForm,
    OverallForm,
}

export interface GroupBadge {
    text: string;
    cssClass: string;
}

export enum GridSorting {
    Competition,
    Time,
    HomeForm,
    AwayForm,
    OverallForm,
}

export interface CollapsedState {
    collapsed: boolean;
    collapsedChildren: string[];
}

export interface GridEvent extends EventModel {
    favourited?: boolean;
}

export interface EventGroup {
    id: number;
    name: string;
    count: number;
    events: GridEvent[];
    collapsed: boolean;
    collapsible: boolean;
    deferred: boolean;
}

export interface EventLinkConfig {
    extraParams?: MarketUrlParam;
    emptyText: string;
}

export interface LeagueGroup extends EventGroup {
    siblings: number[];
    region: RegionModel;
    canBeFavourited: boolean;
    statistics?: CompetitionStatistics | undefined;
    virtualCompetitionId?: number | undefined;
    virtualCompetitionGroupId?: number | undefined;
    imageProfile?: ImageProfile;
}

export interface StandingsData {
    groups: LeagueGroup[];
    virtualCompetitionId?: number | undefined;
}

export interface DateGroup extends EventGroup {
    date: Date;
}

export interface FormGroup extends EventGroup {
    winCount: number;
}

export interface GridMedia {
    videoEvent?: string;
    animationEvent?: string;
    statsEvent?: string;
    enabled: boolean;
}

export interface GridCollapsedModel {
    groups: number[];
    events: StringDictionary<CollapsedState>;
}

export interface GridModel {
    columns: Column[];
    grouping: GridGrouping;
    disableGroupSorting?: boolean;
    groupingThreshold?: number;
    groups: (EventGroup | LeagueGroup)[];
    id: string;
    layout: GridLayout;
    sport: SportModel;
    media: GridMedia;
    topic: SubscriptionTopic;
    sorting?: GridSorting;
    collapsed: GridCollapsedModel;
}

export interface FallbackGroup {
    optionGroup: EventOptionGroup | undefined;
    fallbackMarketName: string;
    showNoGoalText: boolean;
    isFallbackMarket: boolean;
}
