import { Injectable } from '@angular/core';

import { FixtureStage } from '@cds/betting-offer';
import { ScoreboardSlim } from '@cds/scoreboards/v1';
import { EventDetailsConfig } from '@frontend/sports/common/client-config-data-access';

import { EnhancedFixtureViewGroupingConfiguration, EventModel } from '../model/event.model';
import { DetailedCreateFactory, EventDetailsParameters } from './detailed-create.factory';
import { DetailedDeleteFactory } from './detailed-delete.factory';
import { DetailedFixtureMarket } from './detailed-fixture-market.factory';
import { DetailedGroupingFactory } from './detailed-grouping.factory';
import { DetailedUpdateFactory } from './detailed-update.factory';
import { EventSitemapGroupingFactory } from './event-sitemap-grouping.factory';

@Injectable({ providedIn: 'root' })
export class DetailedFixtureFactory {
    constructor(
        private groupingFactory: DetailedGroupingFactory,
        private createFactory: DetailedCreateFactory,
        private updateFactory: DetailedUpdateFactory,
        private deleteFactory: DetailedDeleteFactory,
        private eventDetailsConfig: EventDetailsConfig,
        private eventSitemapGroupingFactory: EventSitemapGroupingFactory,
    ) {}

    create(eventParameters: EventDetailsParameters): EventModel {
        return this.createFactory.create(eventParameters);
    }

    update(model: EventModel, openForBetting: boolean, stage: FixtureStage): void {
        this.updateFactory.update(model, openForBetting, stage);
    }

    updateScoreboard(fixtureScoreboard: ScoreboardSlim, model: EventModel): void {
        this.updateFactory.updateScoreboard(fixtureScoreboard, model);
    }

    updateOptionGroup(
        event: EventModel,
        fixtureMarket: DetailedFixtureMarket,
        fixtureId: string,
        grouping: EnhancedFixtureViewGroupingConfiguration,
    ): void {
        this.updateFactory.updateOptionGroup(event, fixtureMarket, fixtureId, grouping);
        this.refreshModel(event, grouping);
    }

    deleteOptionGroup(event: EventModel, optionGroupId: number, grouping: EnhancedFixtureViewGroupingConfiguration): void {
        this.deleteFactory.deleteOptionGroup(event, optionGroupId);
        this.refreshModel(event, grouping);
    }

    private refreshModel(event: EventModel, grouping: EnhancedFixtureViewGroupingConfiguration): void {
        const betBuilderId = event.getBetBuilderIdByStage();

        event.optionSets = this.groupingFactory.getOptionSets(event.optionGroups, grouping, event.offerSource, betBuilderId);
        event.gamesCount = event.optionGroups.length;

        if (this.eventDetailsConfig.enableSitemapNavigation) {
            event.sitemapSets = this.eventSitemapGroupingFactory.getSitemapSet(event.optionSets, grouping, event.optionGroups);
        }
    }
}
