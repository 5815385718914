import { Injectable, NgZone, inject } from '@angular/core';

import { AppConfig } from '@frontend/sports/common/client-config-data-access';
import { OnAppInit } from '@frontend/vanilla/core';
import { Observable, ReplaySubject, Subject, debounceTime, mergeMap, race, take, tap, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RunAfterLcpService implements OnAppInit {
    private readonly ngZone = inject(NgZone);
    private readonly appConfig = inject(AppConfig);

    private readonly afterLcpCallbacks$ = new Subject<() => void>();
    private readonly lcp$$ = new ReplaySubject<void>(1);
    readonly lcp$ = this.lcp$$.asObservable();

    constructor() {
        this.afterLcpCallbacks$.pipe(mergeMap((cb) => this.lcp$.pipe(tap(() => cb())))).subscribe();
    }

    onAppInit(): void | Promise<any> {
        this.ngZone.runOutsideAngular(() => {
            const lcp$ = new Observable<void>((observer) => {
                const performanceObserver = new PerformanceObserver(() => {
                    observer.next();
                });
                performanceObserver.observe({ type: 'largest-contentful-paint', buffered: true });

                return () => {
                    performanceObserver.disconnect();
                };
            });
            race(lcp$.pipe(debounceTime(1000)), timer(this.appConfig.lcpFallbackTimeout))
                .pipe(take(1))
                .subscribe(() => this.lcp$$.next());
        });
    }

    runAfterLcp(fn: () => void): void {
        this.afterLcpCallbacks$.next(fn);
    }
}
