import { BasePrice } from '@cds/betting-offer';
import { LegInfo, ResultState, SuspensionState } from '@cds/betting-offer/domain-specific/bet-builder';

import { emptyOdds } from './odds/odds';
import { OddsOperations } from './odds/operations';
import { BetslipPick } from './picks/betslip-pick';
import { BetslipV1Pick } from './picks/betslip-v1-pick';
import { BetslipV2OptionMarketPick } from './picks/betslip-v2-option-market-pick';
import { IPrice, PriceType } from './picks/pick-models';

export const mapLegInfoToClientLegInfo = (legInformation: LegInfo[]): { clientLegId: string; isVisible: boolean; isClosed: boolean }[] => {
    return legInformation?.map((legInfo) => mapLegVisibility(legInfo)) ?? [];
};

export const mapLegVisibility = (legInformation: LegInfo): { clientLegId: string; isVisible: boolean; isClosed: boolean } => ({
    isVisible: !isSuspended(legInformation.suspensionState),
    isClosed: legInformation.resultState !== ResultState.Open,
    clientLegId: legInformation.clientLegId,
});

export const isSuspended = (suspensionState?: SuspensionState): boolean => suspensionState !== SuspensionState.MarketUnsuspended;

export const buildIPriceFromBasePrice = (price: BasePrice): IPrice => {
    try {
        return {
            nativeOdds: OddsOperations.createOdds(price),
            type: PriceType.Fixed,
            id: 1,
            isVisible: true,
            marketId: 1,
        };
    } catch {
        return buildIPriceFromBasePrice(emptyPrice());
    }
};

export const emptyPrice = (): BasePrice => {
    return {
        americanOdds: emptyOdds.moneyline,
        odds: emptyOdds.decimals,
        denominator: emptyOdds.fractional.denominator,
        numerator: emptyOdds.fractional.numerator,
    };
};

export const emptyIPrice = buildIPriceFromBasePrice(emptyPrice());

export const resolveClientLegId = (leg: BetslipPick) => {
    if (BetslipV1Pick.isPick(leg)) {
        return `1:${leg.event.id}:${leg.market.id}:${leg.optionId}`;
    }
    if (BetslipV2OptionMarketPick.isPick(leg)) {
        return `${leg.fixture.fixtureId}:${leg.market.id}:${leg.optionId}`;
    }

    return '';
};
