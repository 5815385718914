import { Injectable } from '@angular/core';

import { formatString } from '@frontend/sports/common/base-utils';
import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { BaseballStatistics, EventScoreboard, SetGameScore } from '../model/scoreboard.model';

@Injectable({ providedIn: 'root' })
export class BaseballScoreboardFactory {
    constructor(private sitecore: Sitecore) {}

    getBallsStrikesOuts(baseballStats?: BaseballStatistics, score?: SetGameScore): string {
        return formatString(
            this.sitecore.eventScoreboard.BallsStrikesOuts,
            baseballStats?.balls || 0,
            baseballStats?.strikes || 0,
            (score?.currentlyServing === 1 ? baseballStats?.outs?.regularTime?.participant1 : baseballStats?.outs?.regularTime?.participant2) || 0,
        );
    }

    getLiveEventInfo(scoreboard?: EventScoreboard): string {
        const baseballStats = scoreboard?.statistics as BaseballStatistics;
        const score = scoreboard?.getScore<SetGameScore>()!;

        return this.getBallsStrikesOuts(baseballStats, score);
    }

    getBaseIcon(baseballStats: BaseballStatistics, team: string): string | undefined {
        let baseRunnerPositionClass = '';
        const baseClass = 'base';

        const baseTeam = baseballStats.bases?.find((bt) => bt.order === team);
        if (!baseTeam) {
            return;
        }
        if (baseTeam.hasFirstBase && baseTeam.hasSecondBase) {
            baseRunnerPositionClass = baseTeam.hasThirdBase ? BaseballBaseIcon.FirstSecondThirdBase : BaseballBaseIcon.FirstSecondBase;
        }
        if (baseTeam.hasFirstBase && !baseTeam.hasSecondBase) {
            baseRunnerPositionClass = baseTeam.hasThirdBase ? BaseballBaseIcon.FirstThirdBase : BaseballBaseIcon.FirstBase;
        }
        if (!baseTeam.hasFirstBase && baseTeam.hasSecondBase) {
            baseRunnerPositionClass = baseTeam.hasThirdBase ? BaseballBaseIcon.SecondThirdBase : BaseballBaseIcon.SecondBase;
        }
        if (!baseTeam.hasFirstBase && !baseTeam.hasSecondBase && baseTeam.hasThirdBase) {
            baseRunnerPositionClass = BaseballBaseIcon.ThirdBase;
        }

        return baseRunnerPositionClass ? `${baseClass} ${baseRunnerPositionClass}` : baseClass;
    }
}

enum BaseballBaseIcon {
    FirstBase = 'base-b1',
    SecondBase = 'base-b2',
    ThirdBase = 'base-b3',
    FirstSecondBase = 'base-b1b2',
    FirstThirdBase = 'base-b1b3',
    SecondThirdBase = 'base-b2b3',
    FirstSecondThirdBase = 'base-b1b2b3',
}
