import { Action, ActionCreator, Creator, MemoizedSelector, Store, props } from '@ngrx/store';
import { take } from 'rxjs/operators';

export interface IPayloadAction<TPayload extends object> extends Action {
    payload: TPayload;
}
export type IAction<TPayload extends object> = TPayload & Action;

export function isAction<TResult extends Action>(
    action: Action,
    actionFunc: ActionCreator<string, Creator<any[], TResult>>,
): action is TResult & boolean {
    return actionFunc.type === action.type;
}

export const noopAction: Action = { type: 'NOOP_ACTION' };

export const pprops = <TPayload>() => props<{ payload: TPayload }>();

export const optionalProps =
    <TPayload>() =>
    (prop: TPayload | {} = {}) =>
        prop;

/**
 * This is a hack function use as latest option. Better try using withLatestFrom or combineLatest
 */
export const getStoreValue = function <TState>(store: Store<TState>): TState {
    let state: TState;
    store.pipe(take(1)).subscribe((val) => (state = val));

    return state!;
};

// Unwraps the payload type of a redux action
export type PayloadTypeFor<T extends (...args: any) => any> = Omit<ReturnType<T>, 'type'>;

// Unwraps the return type of a ngrx selector
export type SelectorReturnTypeFor<T> = T extends MemoizedSelector<any, infer R, any> ? R : never;
