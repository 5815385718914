import { Injectable } from '@angular/core';

import { DisplayType, Fixture, FixtureStage, FixtureViewType, OfferCategory, OptionMarket, Participant } from '@cds/betting-offer';
import { BetBuilderProvider, StreamProvider } from '@cds/betting-offer/add-ons';
import { Rank } from '@cds/betting-offer/add-ons/statistics';
import { BetBuilderOptionGroupInfo } from '@cds/betting-offer/domain-specific/bet-builder';
import { ScoreboardSlim } from '@cds/scoreboards/v1';
import { SportConstant } from '@frontend/sports/common/base-utils';
import {
    BetBuilderConfig,
    ImgConfigurationData,
    MarketGroupingConfig,
    MediaConfig,
    OverrideStatisticsConfig,
} from '@frontend/sports/common/client-config-data-access';
import { MarketTypeWithDisplayName } from '@frontend/sports/types/configuration';
import { UrlService } from '@frontend/vanilla/core';
import { clone } from 'lodash-es';

import { FeedType } from '../../media-api/model';
import { isPlayer, mapLeague, mapParticipants, mapSport, mapToEventParticipants } from '../helpers/event-mapper';
import { BetBuilderInfo, EventModel, EventParticipant, EventParticipantType, EventStream, RegionModel, getProvider } from '../model/event.model';
import { Tv2MarketParameters } from '../model/tv2.model';
import { ScoreboardFactory } from './scoreboard-factory.service';

@Injectable({ providedIn: 'root' })
export class CommonFixtureFactory {
    constructor(
        private mediaConfig: MediaConfig,
        private imgConfig: ImgConfigurationData,
        private scoreboardFactory: ScoreboardFactory,
        private marketGroupingConfig: MarketGroupingConfig,
        private overrideStatisticsConfig: OverrideStatisticsConfig,
        private betBuilderConfig: BetBuilderConfig,
        private urlService: UrlService,
    ) {}

    create(fixture: Fixture, sourceId?: string, precreatedOptionGroupInfo?: BetBuilderOptionGroupInfo[]): EventModel {
        const model = new EventModel();

        if (sourceId) {
            model.id = sourceId;
        } else {
            model.id = fixture.id;
        }

        model.name = fixture.name.short || fixture.name.value;
        model.nameSign = fixture.name.short ? fixture.name.shortSign : fixture.name.sign;
        model.description = fixture.description?.value;
        model.sport = mapSport(fixture);
        model.region = this.mapRegion(fixture);

        model.league = mapLeague(fixture.competition, fixture.tradingStage?.id || fixture.group?.parentId, fixture.group?.id);

        model.startDate = new Date(fixture.startDate);
        model.cutOffDate = new Date(fixture.cutOffDate);
        model.live = fixture.stage === FixtureStage.Live;
        model.groupId = fixture.groupId;
        model.liveAlert = fixture.liveAlert;
        model.betBuilderFixtureId = fixture.addons?.betBuilderFixture;
        model.sportcastId = fixture.addons?.sportcastId;
        model.betBuilderTradingV2FixtureId = fixture.addons?.betBuilderTradingV2FixtureId;
        model.online = fixture.isOpenForBetting;

        model.runningBallDataId = this.setMatchSimulation(fixture);
        model.participants = mapParticipants(
            fixture.participants.filter((p) => !isPlayer(p)),
            fixture.viewType === FixtureViewType.NotSet ? FixtureViewType.European : fixture.viewType,
            fixture.hybridFixtureData,
        );
        model.teams = this.mapTeams(
            fixture.participants.filter((p) => !isPlayer(p)),
            fixture.viewType === FixtureViewType.NotSet ? FixtureViewType.European : fixture.viewType,
        );
        model.players = this.mapPlayers(fixture.participants.filter((p) => isPlayer(p)));

        model.gamesCount = this.getMarketsCount(fixture);

        model.offerSource = fixture.source;
        model.offerContext = Array.isArray(fixture.contexts) ? fixture.contexts : [fixture.context];
        model.fixtureType = fixture.fixtureType;
        model.hasLeagueStats = fixture.competition && fixture.competition.statistics!;
        model.competitionGroupId = fixture.group?.id;
        model.rank = this.getRanks(fixture.sport.id, fixture.competition.id, model.participants);

        if (fixture.addons) {
            model.surface = fixture.addons.surface;
            model.statsId = fixture.addons.betRadar!;
            model.hasFixtureStats = fixture.addons.statistics!;
            model.provider = getProvider(fixture.addons);
            model.fixtureForm = fixture.addons.fixtureStatistics?.fixtureForm;
            model.seasonStandings = fixture.addons.fixtureStatistics?.seasonStandings;
            model.startingPitcher = fixture.addons.fixtureStatistics?.startingPitcher;
            model.stream = this.getStream(fixture);

            if (fixture.addons.imgEventId) {
                model.imgEventId = this.setImgAnimation(fixture);
            }
            if (fixture.addons.liveMatchTracker) {
                model.betRadarVisualizationId = fixture.addons.liveMatchTracker.sportradarId;
                model.betRadarCoverageLevel = fixture.addons.liveMatchTracker.coverageLevel;
            }
        }
        model.viewType = fixture.viewType;
        model.scoreboard = this.scoreboardFactory.createScoreboard(fixture.scoreboard as ScoreboardSlim, model);
        model.totalMarketsCount = fixture.totalMarketsCount;
        model.priceBoostCount = fixture.priceBoostCount;
        model.hybridFixtureData = fixture.hybridFixtureData;
        model.tv2RelatedFixtureId = fixture.tv2RelatedFixtureId;
        model.offerCategories = this.getOfferCategories(fixture);
        model.betBuilderInfo = this.mapBetBuilderInfo(fixture, model);

        if (precreatedOptionGroupInfo && precreatedOptionGroupInfo.length) {
            model.precreatedOptionGroups = precreatedOptionGroupInfo;
        }

        return model;
    }

    update(model: EventModel, openForBetting: boolean, stage: FixtureStage): void {
        model.live = stage === FixtureStage.Live;

        if (model.online === openForBetting) {
            return;
        }

        model.online = openForBetting;
        model.optionGroups.forEach((optionGroup) => {
            optionGroup.options.forEach((option) => (option.online = openForBetting && option.visible));
            optionGroup.online = openForBetting && optionGroup.visible;
        });
    }

    updateScoreboard(serverScoreboard: ScoreboardSlim, model: EventModel): void {
        model.scoreboard = this.scoreboardFactory.createScoreboard(serverScoreboard, model);
    }

    private getMarketsCount(fixture: Fixture): number {
        let count = (fixture.games && fixture.games.length) || 0;
        count += (fixture.optionMarkets && fixture.optionMarkets.length) || 0;

        return count;
    }

    private getOfferCategories(fixture: Fixture): OfferCategory[] {
        return [
            ...new Set(
                fixture.games
                    .map((game) => game.category)
                    .concat(
                        fixture.optionMarkets.reduce((result, o) => {
                            if (o.templateCategory) {
                                result.push(o.templateCategory.category);
                            }

                            return result;
                        }, [] as OfferCategory[]),
                    ),
            ),
        ];
    }

    private setImgAnimation(fixture: Fixture): number | undefined {
        return fixture.sport.id === SportConstant.Golf &&
            this.imgConfig.isEnabled &&
            !this.imgConfig.blacklistedCompetitions.includes(fixture.competition.id)
            ? fixture.addons.imgEventId
            : undefined;
    }

    private setMatchSimulation(fixture: Fixture): string | undefined {
        if (
            !this.mediaConfig.isMatchSimulationEnabled ||
            !this.mediaConfig.sportsWithMatchSimulation ||
            !this.mediaConfig.sportsWithMatchSimulation.length
        ) {
            return undefined;
        }

        return this.mediaConfig.sportsWithMatchSimulation.indexOf(fixture.sport.id) > -1 ? fixture.addons && fixture.addons.runningBall : undefined;
    }

    private mapRegion(fixture: Fixture): RegionModel {
        const source = fixture.tournament || fixture.region;
        const region = new RegionModel();

        if (source) {
            region.id = source.id;
            region.code = fixture.tournament ? undefined : fixture.region.code;
            region.name = source.name.short || source.name.value;
            region.sign = source.name.short ? source.name.shortSign : source.name.sign;

            if (fixture.region) {
                region.originalRegion = {
                    id: fixture.region.id,
                    code: fixture.region.code,
                    name: fixture.region.name.short || fixture.region.name.value,
                    sign: fixture.region.name.short ? fixture.region.name.shortSign : fixture.region.name.sign,
                };
            }
        }

        return region;
    }

    private mapTeams(participants: Participant[], fixtureViewType: FixtureViewType): EventParticipant[] {
        //teams not used currently - mapping can be changed as needed
        return mapToEventParticipants(participants, fixtureViewType);
    }

    private mapPlayers(playerParticipants: Participant[]): EventParticipant[] {
        const eventParticipants = playerParticipants.map<EventParticipant>((participant) => {
            const name = participant.name.value;

            return {
                id: participant.participantId,
                fixtureParticipantId: participant.id,
                code: participant.name.short,
                name,
                image: clone(participant.image),
                teamId: participant.properties!.team,
            };
        });

        return eventParticipants;
    }

    shouldOverrideParticipantNames(optionMarket: OptionMarket, sportId: number): boolean {
        const marketType = optionMarket.parameters?.find((x) => x.key === Tv2MarketParameters.MarketType)?.value;
        const displayTypes = optionMarket.grouping?.detailed?.map((g) => g.displayType);

        return !!(
            marketType &&
            this.marketGroupingConfig.overrideParticipantNameOptionMarkets.find(
                (config) =>
                    config.sportId === sportId &&
                    config.types.some((type) => type.marketType === marketType && this.isOverrideParticipantNamebyDisplayType(type, displayTypes)),
            )
        );
    }

    private isOverrideParticipantNamebyDisplayType(marketConfig: MarketTypeWithDisplayName, displayTypes: DisplayType[]): boolean {
        if (marketConfig.displayType && displayTypes) {
            return displayTypes.some((displayType) => displayType === marketConfig.displayType);
        }

        return true;
    }

    private getRanks(sportId: number, leagueId: number, participants: EventParticipant[]): Rank | undefined {
        const rankings = this.overrideStatisticsConfig.teamRankings;
        const [homeTeamParticipantId, awayTeamParticipantId] = this.getParticipantId(participants);
        const rankingsOnLeague = rankings?.[sportId]?.[leagueId];
        const homeTeamRank = homeTeamParticipantId ? rankingsOnLeague?.[homeTeamParticipantId] : undefined;
        const awayTeamRank = awayTeamParticipantId ? rankingsOnLeague?.[awayTeamParticipantId] : undefined;

        return homeTeamRank || awayTeamRank ? { homeTeam: homeTeamRank, awayTeam: awayTeamRank } : undefined;
    }

    private getParticipantId(participants: EventParticipant[]): [number | undefined, number | undefined] {
        const homeParticipantId = participants.find((participant) => participant.type === EventParticipantType.Home)?.id;
        const awayParticipantId = participants.find((participant) => participant.type === EventParticipantType.Away)?.id;

        return [homeParticipantId, awayParticipantId];
    }

    private mapBetBuilderInfo(fixture: Fixture, eventModel: EventModel): BetBuilderInfo | null {
        if (fixture.stage === FixtureStage.Live) {
            // NOTE: Configuration can be extended to allow SGP for InPlay via Dynacon.
            return null;
        }

        if (
            !this.betBuilderConfig.isBetBuilderEnabled ||
            !this.betBuilderConfig.isBetBuilderEnabledForSport?.includes(eventModel.sport.id) ||
            fixture.addons.betBuilderProvider === BetBuilderProvider.None
        ) {
            return null;
        }

        return { betBuilderId: fixture.addons?.betBuilderId, betBuilderProvider: fixture.addons.betBuilderProvider };
    }

    private getStream(fixture: Fixture): EventStream | undefined {
        let stream: EventStream | undefined;
        const videoStream = fixture.addons.videoStream;

        if (this.mediaConfig.showVideoStreaming && !this.mediaConfig.restrictedSportIds.find((id) => id === fixture.sport.id) && videoStream) {
            const { providerStreamId, provider, embedPath, feedType } = videoStream;
            stream = { id: providerStreamId, provider, feedType: FeedType[feedType as keyof typeof FeedType] };

            if (provider === StreamProvider.Unikrn && embedPath) {
                if (feedType === FeedType.Twitch) {
                    stream.twitchChannel = this.urlService.parse(embedPath).search.get('channel') ?? undefined;
                } else {
                    stream.streamUrl = embedPath;
                }
            }
        }

        return stream;
    }
}
