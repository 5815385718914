@if (!adaptiveLayout.current.headerSubNavigationHidden) {
    <ms-tab-bar [iconTemplate]="iconTemplate" class="sub-navigation" [tabs]="tabs" (select)="selected($event)" />
}
<ng-template #iconTemplate let-tab>
    @if (tab.imageHtml) {
        <span [innerHTML]="tab.imageHtml" class="icon"></span>
    } @else {
        <span class="icon" [ngClass]="tab.icon"></span>
    }
</ng-template>
