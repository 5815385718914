/* eslint-disable prefer-rest-params */

export function urljoin(...params: any[]): string {
    let input = arguments;

    if (typeof arguments[0] === 'object') {
        // new syntax with array and options
        input = arguments[0];
    }

    const joined = [].slice.call(input, 0).join('/');

    return normalize(joined);
}

export function encodeParams(param: any): string | null {
    if (!param) {
        return null;
    }

    const encoded = encodeObject(param);

    if (!encoded) {
        return null;
    }

    return encoded.filter((part) => part).join('&');
}

function normalize(str: string): string {
    // make sure protocol is followed by two slashes
    str = str.replace(/:\//g, '://');

    // remove consecutive slashes
    str = str.replace(/([^:\s])\/+/g, '$1/');

    // remove trailing slash before parameters or hash
    str = str.replace(/\/(\?|&|#[^!])/g, '$1');

    // replace ? in parameters with &
    str = str.replace(/(\?.+)\?/g, '$1&');

    return str;
}

function encodeObject(param: any): Array<string> | null {
    if (Array.isArray(param)) {
        if (param.length === 0) {
            return null;
        }

        return param
            .map((current, index) => {
                const result = encodeObject(current);

                return Array.isArray(result) ? result.map((x) => `[${index}].${x}`) : [`[${index}]=${current}`];
            })
            .reduce((current, next) => current.concat(next));
    }

    if (param instanceof Object) {
        const keys = Object.keys(param);

        const propertiesToEncode = (key: string): boolean =>
            param[key] !== null && param[key] !== undefined && (Array.isArray(param[key]) ? param[key].length !== 0 : param[key] !== '');

        const encodeProperty = (key: string): string[] => {
            const encoded = encodeObject(param[key]);
            const join = Array.isArray(param[key]) ? '' : '.';

            if (!encoded) {
                return [`${key}=${encodeURIComponent(param[key])}`];
            }

            return encoded.map((pair) => `${key}${join}${pair}`);
        };

        const encodedValues = keys
            .filter(propertiesToEncode)
            .map(encodeProperty)
            .reduce((flatArray, next) => flatArray.concat(next), []);

        if (encodedValues.length) {
            return encodedValues;
        }
    }

    return null;
}
