import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, OnInit } from '@angular/core';

import { PriceBoostConfig } from '@frontend/sports/common/client-config-data-access';
import IEpcotConfigService from 'packages/sports/common/betslip/integration/services/epcot-config.service';
import { EPCOT_CONFIG_INJECTION_TOKEN } from 'packages/sports/common/betslip/integration/sports-injection-services';

@Component({
    selector: 'ms-priceboost-count-signpost',
    templateUrl: './priceboost-count-signpost.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceboostCountSignpostComponent implements OnInit {
    @HostBinding('class') className = 'count-signpost-container';
    @HostBinding('class.large-signpost') largeSignpost = false;
    @HostBinding('class.count-signpost') countSignpost: boolean;
    @HostBinding('class.epcot-signpost')
    get shouldApplyClass(): boolean {
        return this.epcotConfigService.isEnabled();
    }

    @Input() count: string;
    @Input() isLargeSignPost = false;
    showPriceBoostCount = false;
    constructor(
        public priceBoostConfig: PriceBoostConfig,
        @Inject(EPCOT_CONFIG_INJECTION_TOKEN) private epcotConfigService: IEpcotConfigService,
    ) {}

    ngOnInit(): void {
        this.showPriceBoostCount = this.priceBoostConfig.showCount || this.isLargeSignPost;
        this.largeSignpost = this.isLargeSignPost;
        this.countSignpost = !this.isLargeSignPost;
    }
}
