export enum EarlyPayoutStates {
    Allowed = 'EARLY_PAYOUT_ALLOWED',
    ModeHigher = 'EARLY_PAYOUT_MODE_HIGHER',
    Confirm = 'EARLY_PAYOUT_CONFIRM',
    Preparing = 'EARLY_PAYOUT_PREPARING',
    Done = 'EARLY_PAYOUT_DONE',
    PaidOut = 'EARLY_PAYOUT_PAIDOUT',
    Disabled = 'EARLY_PAYOUT_DISSABLED',
    PermanentlyDisabled = 'EARLY_PAYOUT_PERMANENTLY_DISABLED',
    Completed = 'EARLY_PAYOUT_COMPLETED',
}

export enum EarlyPayoutMode {
    Any = 'AcceptAnyChanges',
    Higher = 'AcceptHigherPayoutAmount',
}

export enum EarlyPayoutConst {
    StatusSucceded = 'PayoutSucceeded',
    StatusPending = 'PayoutPending',
    StatusFailed = 'PayoutFailed',
    EpGeneralError = 'EarlyPayoutNotPossible',
    ModeAny = 'AcceptAnyChanges',
    ModeHigher = 'AcceptHigherPayoutAmount',
    AcGenericError = 'AutoCashoutNotPossible',
}

export interface PayoutErrorPick {
    id: number;
    state: EarlyPayoutPickState;
}

export interface PayoutError {
    type: string;
    reason?: string;
    errorCode?: string;
    possibleAmount?: number;
    expectedAmount?: number;
    possiblePayout?: number;
    possibleWin?: number;
    minimumAmount?: number;
    passedExpectedPayoutAmount?: number;
    oddsKey?: number;
    optionId?: number;
    cutOffDate?: Date;
    picks?: PayoutErrorPick[];
    errorMessage?: string;
}

export interface IEarlyPayoutData {
    value: number;
    error?: PayoutError;
}

export interface PayoutPushError {
    name: string;
    error: {
        Title: string;
        Message: string;
    };
}

// Obsolete: use BPOSv4 contracts
export enum EarlyPayoutErrors {
    AutoCashoutNotAllowed = 'AutoCashoutNotAllowed',
    BetLost = 'BetLost',
    BetNotAllowedForEarlyPayout = 'BetNotAllowedForEarlyPayout', // TODO: remove BPOSv4/EPSv4 released on prod
    BetNotFound = 'BetNotFound',
    ChangedBetSlipState = 'ChangedBetSlipState',
    DuplicatedAutoCashout = 'DuplicatedAutoCashout',
    DuplicatedEarlyPayout = 'DuplicatedEarlyPayout',
    EarlyPayoutAmountChanged = 'EarlyPayoutAmountChanged', // TODO: remove BPOSv4/EPSv4 released on prod
    EarlyPayoutNotAllowed = 'EarlyPayoutNotAllowed', // TODO: remove BPOSv4/EPSv4 released on prod
    EarlyPayoutNotFound = 'EarlyPayoutNotFound', // TODO: remove BPOSv4/EPSv4 released on prod
    GeoLocationError = 'GeoLocationError',
    GeoLocationErrorBlockedByClientSoftware = 'GeoLocationErrorBlockedByClientSoftware',
    GeoLocationErrorCloseToBoundary = 'GeoLocationErrorCloseToBoundary',
    GeoLocationErrorDeviceRooted = 'GeoLocationErrorDeviceRooted',
    GeoLocationErrorFraudJumper = 'GeoLocationErrorFraudJumper',
    GeoLocationErrorFraudPotential = 'GeoLocationErrorFraudPotential',
    GeoLocationErrorMinSources = 'GeoLocationErrorMinSources',
    GeoLocationErrorMinSourcesMobile = 'GeoLocationErrorMinSourcesMobile',
    GeoLocationErrorNotAccurateLocation = 'GeoLocationErrorNotAccurateLocation',
    GeoLocationErrorOutOfPermittedArea = 'GeoLocationErrorOutOfPermittedArea',
    GeoLocationErrorProxy = 'GeoLocationErrorProxy',
    GeoLocationErrorRemoteDesktop = 'GeoLocationErrorRemoteDesktop',
    GeoLocationErrorSoftwareOutdated = 'GeoLocationErrorSoftwareOutdated',
    GeoLocationErrorVpnDetection = 'GeoLocationErrorVpnDetection',
    GeoLocationFailed = 'GeoLocationFailed',
    SessionTokenInvalid = 'SessionTokenInvalid',
    OverMaxEarlyPayoutAmount = 'OverMaxEarlyPayoutAmount', // TODO: remove BPOSv4/EPSv4 released on prod
    ResultError = 'ResultError',
    UnderMinimumEarlyPayoutAmount = 'UnderMinimumEarlyPayoutAmount', // TODO: remove BPOSv4/EPSv4 released on prod
    CutOffDateReached = 'CutOffDateReached', // TODO: remove BPOSv4/EPSv4 released on prod
    OptionInvisible = 'OptionInvisible', // TODO: remove BPOSv4/EPSv4 released on prod
    InconsistentMonetaryValue = 'InconsistentMonetaryValue',
    UnderMinOddsKey = 'UnderMinOddsKey',
    ResultNotFound = 'ResultNotFound',
    TechnicalError = 'TechnicalError',
    ChangedOptionStatus = 'ChangedOptionStatus', // TODO: remove BPOSv4/EPSv4 released on prod
    UnderMinCashoutAmount = 'UnderMinCashoutAmount',
    OverMaxCashoutAmount = 'OverMaxCashoutAmount',
    BetNotAllowedForCashout = 'BetNotAllowedForCashout',
    CashoutAmountChanged = 'CashoutAmountChanged',
    CashoutNotAllowed = 'CashoutNotAllowed',
    CashoutNotFound = 'CashoutNotFound',
    BetFromDifferentUser = 'BetFromDifferentUser',
}

export enum EarlyPayoutOptionErrors {
    OptionLockSingle = 'OptionLockSingle',
    OptionLockCombo = 'OptionLockCombo',
    OptionHiddenSingle = 'OptionHiddenSingle',
    OptionHiddenCombo = 'OptionHiddenCombo',
    OptionCutOffDateReached = 'OptionCutOffDateReached',
    OptionChangedStatus = 'OptionChangedStatus',
    OptionNotFound = 'OptionNotFound',
    OptionMarketTemplateNotAllowed = 'OptionMarketTemplateNotAllowed',
}

export enum EarlyPayoutPickState {
    Cancelled = 'Cancelled',
    ChangedStatus = 'ChangedStatus',
    CutOffDateReached = 'CutOffDateReached',
    Eligible = 'Eligible',
    Hidden = 'Hidden',
    Locked = 'Locked',
    Lost = 'Lost',
    MarketTemplateNotAllowed = 'MarketTemplateNotAllowed',
    NotFound = 'NotFound',
    Unknown = 'Unknown',
    Won = 'Won',
}

// BPOSv4 contracts
export enum EarlyPayoutErrorTypes {
    BetNotFound = 'BetNotFound',
    BetNotAllowedForCashout = 'BetNotAllowedForCashout',
    ChangedBetSlipState = 'ChangedBetSlipState',
    ChangedOptionStatus = 'ChangedOptionStatus',
    DuplicatedCashout = 'DuplicatedCashout',
    CashoutAmountChanged = 'CashoutAmountChanged',
    CashoutNotAllowed = 'CashoutNotAllowed',
    OverMaxCashoutAmount = 'OverMaxCashoutAmount',
    TechnicalError = 'TechnicalError',
    UnderMinCashoutAmount = 'UnderMinCashoutAmount',
    InconsistentMonetaryValue = 'InconsistentMonetaryValue',
    UnderMinOddsKey = 'UnderMinOddsKey',
    BetLost = 'BetLost',
    CashoutNotFound = 'CashoutNotFound',
    AutoCashoutNotAllowed = 'AutoCashoutNotAllowed',
    DuplicatedAutoCashout = 'DuplicatedAutoCashout',
    RewardAwarded = 'RewardAwarded',
    CashoutAmountLowerOrEqualToFreeBet = 'CashoutAmountLowerOrEqualToFreeBet',
}
