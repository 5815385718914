import { createAction } from '@ngrx/store';

import BetslipActions from '../../base/store/actions';

export class PopupActions {
    static readonly ACTION_SCHEMA = `${BetslipActions.ACTION_SCHEMA}/result`;

    private static readonly MANUAL_CLOSE = `${PopupActions.ACTION_SCHEMA}/MANUAL_CLOSE`;

    static manualClose = createAction(PopupActions.MANUAL_CLOSE);
}
