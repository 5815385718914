import { ImageProfile } from '@cds/betting-offer';
import {
    VirtualCompetitionDisplayMode as CDSVirtualCompetitionDisplayMode,
    VirtualCompetitionImageType as CDSVirualCompetitionImageType,
    Competition,
    Conference,
    ExtendedTagStats,
    MarketGroupCount,
    Region,
    Sport,
    Tag,
    TagCount,
    TagParticipant,
    TagType,
    VirtualCompetition,
    VirtualCompetitionGroup,
    VirtualSport,
} from '@cds/betting-offer/tags';
import { flatten, forIn, groupBy, isArray, min, sortBy } from 'lodash-es';

import { SportConstant } from './app.constants';

export interface CountItem {
    id: number;
    name: string;
    type: ItemType;
    counts: ItemCount;
    meta: ItemMeta;
    parentId?: number;
    order?: number;
    prefix?: string;
    children: CountItem[];
    icon?: string;
    isVirtual?: boolean;
    isEsport?: boolean;
}

export enum ItemType {
    // no real clue how to fix, as it should not happen at all
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Sport = 'Sport',
    // no real clue how to fix, as it should not happen at all
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Region = 'Region',
    Tournament = 'Tournament',
    // no real clue how to fix, as it should not happen at all
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Competition = 'Competition',
    // no real clue how to fix, as it should not happen at all
    // eslint-disable-next-line @typescript-eslint/no-shadow
    VirtualCompetitionGroup = 'VirtualCompetitionGroup',
    // no real clue how to fix, as it should not happen at all
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Conference = 'Conference',
    // no real clue how to fix, as it should not happen at all
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Meeting = 'Meeting',
}

export interface ItemMeta {
    calendar?: boolean;
    coupons?: boolean;
    teamPages?: boolean;
    calendarIntervals?: string[];
    betBuilder?: boolean;
}

export interface ItemCount {
    live: number;
    preMatch: number;
    gridable: number;
    nonGridable: number;
    outrights: number;
    outrightsByGroups: Record<string, MarketGroupCount>;
    specials: number;
    specialsByGroups: Record<string, MarketGroupCount>;
    other: number;
    antepost: number;
    liveStreaming: number;
    priceBoost?: number;
    dynamicCategories?: { [key: string]: number };
}

export interface ItemFilter {
    type: ItemType;
    id: number;
}

export interface RegionItem extends CountItem {
    code: string;
}

export interface LeagueItem extends CountItem {
    siblings: number[];
    standings: boolean;
    isVirtual: boolean;
    realCompetitionId?: number;
    displayMode?: VirtualCompetitionDisplayMode;
    imageType?: VirtualCompetitionImageType;
    compoundId?: string;
    imageProfile?: ImageProfile;
}

export interface VirtualCompetitionGroupItem extends LeagueItem {
    participants: VirtualCompetitionGroupParticipant[];
    groupOrder: number;
    groupIds: number[];
    stageIds: number[];
    parentId: number | undefined;
}

export interface SportItem extends CountItem {
    isVirtual: boolean;
}

export enum VirtualCompetitionDisplayMode {
    Pills = 'Pills',
    Cards = 'Cards',
}

export enum VirtualCompetitionImageType {
    Default = 'Default',
    Flags = 'Flags',
}

export const VirtualCompetitionPrefix = '0:';

export interface VirtualCompetitionGroupParticipant {
    code: string;
    participantId?: number;
}

const virtualCompetitionDisplayModeMap = new Map<CDSVirtualCompetitionDisplayMode, VirtualCompetitionDisplayMode>([
    [CDSVirtualCompetitionDisplayMode.Pills, VirtualCompetitionDisplayMode.Pills],
    [CDSVirtualCompetitionDisplayMode.Cards, VirtualCompetitionDisplayMode.Cards],
]);

/**
 * Returns the n items that have order, also orders the result
 *
 * @param items list of source items
 * @param top optional number of elements to return, if not specified will return all available items
 */
export function takeTop<T extends CountItem = CountItem>(items: T[], top?: number): T[] {
    return items
        .filter((item) => item.order !== undefined)
        .sort((first, second) => first.order! - second.order!)
        .slice(0, top || items.length);
}

/**
 * Calculates the event count for the specified tag
 *
 * @param item the count item to calculate the event count
 * @param interval a flag indicating weather you want to calculate the count for rendering it on calendar pages
 */
export function count(item?: CountItem, interval?: boolean): number {
    if (!item || !item.counts) {
        return 0;
    }

    if (interval) {
        return item.counts.preMatch + item.counts.live;
    }

    const stateCount = item.counts.preMatch + item.counts.live;
    const typeCount = item.counts.gridable + item.counts.nonGridable + item.counts.other;
    const totalCount = min([stateCount, typeCount]) || 0;

    return totalCount + item.counts.specials + item.counts.outrights;
}

/* eslint-disable no-redeclare */
export function isItemType(item: CountItem | undefined, type: ItemType.VirtualCompetitionGroup): item is VirtualCompetitionGroupItem;
export function isItemType(item: CountItem | undefined, type: ItemType.Competition): item is LeagueItem;
export function isItemType(item: CountItem | undefined, type: ItemType.Region): item is RegionItem;
export function isItemType(item: CountItem | undefined, type: ItemType.Sport): item is SportItem;
export function isItemType(item: CountItem | undefined, type: ItemType): boolean;
export function isItemType(item: CountItem | undefined, type: ItemType): boolean {
    return !!item && item.type === type;
}
/* eslint-enable */

/**
 * Returns all children and sub children of the current item(s) of a specific type
 *
 * @param items source list of items
 * @param type the item type you are looking for
 */
/* eslint-disable no-redeclare */
export function children(items: CountItem | CountItem[], type: ItemType.VirtualCompetitionGroup): VirtualCompetitionGroupItem[];
export function children(items: CountItem | CountItem[], type: ItemType.Competition): LeagueItem[];
export function children(items: CountItem | CountItem[], type: ItemType.Region): RegionItem[];
export function children(items: CountItem | CountItem[], type: ItemType.Sport): SportItem[];
export function children(items: CountItem | CountItem[], type: ItemType): CountItem[];
export function children(items: CountItem | CountItem[], type: ItemType): CountItem[] {
    let list = isArray(items) ? items : [items];

    // in case there is Sport containing both Competitions and VirtualCompetitions
    // and some Competitions have Conferences
    // and some VirtualCompetitions have VirtualCompetitionGroups
    // check if every item at the current level is not the type we are looking for before continue traversing further down
    while (list.length && list.every((child) => child.type !== type)) {
        list = flatten(list.map((child) => child.children));
    }

    // return only the children which we are looking for
    return list.filter((child) => child.type === type);
}
/* eslint-enable */

/**
 * Builds up the tree from a flat source of items returned by the api
 *
 * @param tagCounts source list of items
 */
const fromTag = (tagCounts?: TagCount[]) => {
    const result: CountItem[] = [];
    const grouped = groupBy(tagCounts || [], (tagCount: TagCount) => tagCount.tag.type);
    const values = {};
    const sports: TagCount[] = [];

    for (const type of [TagType.Sport, TagType.VirtualSport]) {
        if (grouped[type]) {
            sports.push(...grouped[type]);
        }
    }

    if (!grouped || !sports.length) {
        return result;
    }

    forIn(grouped, (value, key) => {
        values[key] = groupBy(value, (countTag) => {
            if (isCompetitionTag(countTag.tag)) {
                const competition = countTag.tag;
                const sportId = competition.sportId;
                const parentId = sportId === SportConstant.Tennis ? competition.parentTournamentId || countTag.tag.parentId : countTag.tag.parentId;

                return `${parentId}:${sportId}`;
            } else if (isVirtualCompetitionTag(countTag.tag)) {
                const competition = countTag.tag;
                const parentId = competition.tournamentId || competition.parentId || countTag.tag.parentId;
                const sportId = competition.sportId;

                return `${parentId}:${sportId}`;
            } else if (isConferenceTag(countTag.tag)) {
                const conference = countTag.tag;

                return `${conference.competitionId}`;
            }

            return `${countTag.tag.parentId}`;
        });
    });

    sports.forEach((sportTag) => {
        const sport = mapNode(sportTag);
        const order = childrenPath(sport.id);

        mapChildren(order, sport, sportTag, values);
        result.push(sport);
    });

    return sortTags(result);
};

export function isVirtualCompetitionGroupItem(tag: CountItem): tag is VirtualCompetitionGroupItem {
    return tag.type === ItemType.VirtualCompetitionGroup;
}

export function isCompetitionItem(tag: CountItem): tag is LeagueItem {
    return tag.type === ItemType.Competition && !(<LeagueItem>tag).isVirtual;
}

export function isVirtualCompetitionItem(tag: CountItem): tag is LeagueItem {
    return tag.type === ItemType.Competition && (<LeagueItem>tag).isVirtual;
}

export function isAntepost(sport: CountItem): boolean {
    return sport.counts.antepost !== 0 && sport.counts.antepost === sport.counts.live + sport.counts.preMatch;
}

function childrenPath(sport: number): string[][] {
    const base = [[TagType.Sport, TagType.VirtualSport]];

    if (sport === SportConstant.Tennis) {
        base.push([TagType.Tournament]);
    } else {
        base.push([TagType.Region]);
    }

    base.push([TagType.Competition, TagType.VirtualCompetition]);
    base.push([TagType.Conference, TagType.VirtualCompetitionGroup]);

    return base;
}

function mapChildren(
    typeOrder: string[][],
    current: CountItem,
    currentTag: TagCount,
    grouped: {
        [key: string]: {
            [parent: string]: TagCount[];
        };
    },
): void {
    const currentType = typeOrder.findIndex((type) => type.indexOf(current.type.toString()) !== -1);
    const childTypes = typeOrder[currentType + 1];

    if (!childTypes) {
        return;
    }

    current.children = childTypes
        .map((childType) => {
            if (!childType || !grouped[childType]) {
                return [];
            }

            let currentChildren: TagCount[];

            if (current.type === ItemType.Region || current.type === ItemType.Tournament) {
                currentChildren = grouped[childType][`${current.id}:${currentTag.tag.parentId}`] || [];
            } else {
                // if tag.type === Competition take VirtualCompetitionId if exists
                // if it doesn't exist fallback to Competition compoundId and not the numeric id
                const parentId = isCompetitionTag(currentTag.tag) ? currentTag.tag.virtualCompetitionId || currentTag.tag.compoundId : current.id;

                currentChildren = grouped[childType][parentId] || [];
            }

            return currentChildren.map((childTag) => {
                const child = mapNode(childTag);
                mapChildren(typeOrder, child, childTag, grouped);

                return child;
            });
        })
        .reduce((total, currentChildren) => [...total, ...currentChildren]);
}

export function mapNode(countTag: TagCount): CountItem {
    const typeMap = new Map<TagType, ItemType>([
        [TagType.Sport, ItemType.Sport],
        [TagType.Region, ItemType.Region],
        [TagType.Tournament, ItemType.Tournament],
        [TagType.Competition, ItemType.Competition],
        [TagType.VirtualSport, ItemType.Sport],
        [TagType.VirtualCompetition, ItemType.Competition],
        [TagType.VirtualCompetitionGroup, ItemType.VirtualCompetitionGroup],
        [TagType.Conference, ItemType.Conference],
    ]);

    const type = typeMap.get(countTag.tag.type);

    if (!type) {
        throw new Error(`Unhandled tag type ${countTag.tag.type}`);
    }

    const base: CountItem = {
        id: countTag.tag.id,
        name: countTag.tag.name.value,
        type,
        counts: mapCount(countTag),
        meta: mapMeta(countTag),
        order: countTag.rank,
        children: [] as CountItem[],
    };

    switch (countTag.tag.type) {
        case TagType.Sport:
            return mapSport(countTag.tag as Sport, base);
        case TagType.Region:
            return mapRegion(countTag.tag as Region, base);
        case TagType.Competition:
            return mapCompetition(countTag.tag as Competition, base);
        case TagType.VirtualSport:
            return mapVirtualSport(countTag.tag as VirtualSport, base);
        case TagType.VirtualCompetition:
            return mapVirtualLeague(countTag.tag as VirtualCompetition, base);
        case TagType.VirtualCompetitionGroup:
            return mapVirtualCompetitionGroup(countTag.tag as VirtualCompetitionGroup, base);
        default:
            return base;
    }
}

function mapSport(tag: Sport, base: CountItem): CountItem {
    return {
        ...base,
        isEsport: tag.isEsport,
    };
}

function mapRegion(tag: Region, base: CountItem): RegionItem {
    return {
        ...base,
        code: tag.code,
    };
}

function mapCompetition(tag: Competition, base: CountItem): LeagueItem {
    const id = tag.parentLeagueId || tag.id;
    const siblings = [];

    if (tag.parentLeagueId) {
        siblings.push(tag.parentLeagueId);
    }

    if (tag.parentLeagueId !== tag.id) {
        siblings.push(tag.id);
    }

    if (tag.virtualCompetitionId) {
        return {
            ...base,
            id: tag.virtualCompetitionId,
            prefix: VirtualCompetitionPrefix,
            siblings,
            standings: !!tag.statistics,
            isVirtual: true,
            parentId: tag.parentId,
            displayMode: virtualCompetitionDisplayModeMap.get(tag.displayMode!), // when virtualCompetitionId is defined displayMode also has value
            realCompetitionId: id,
            imageProfile: tag.imageProfile,
        };
    } else {
        return {
            ...base,
            id,
            siblings,
            standings: !!tag.statistics,
            isVirtual: false,
            parentId: tag.parentId,
            compoundId: tag.compoundId,
            imageProfile: tag.imageProfile,
        };
    }
}

function mapVirtualSport(tag: VirtualSport, base: CountItem): SportItem {
    return {
        ...base,
        isVirtual: true,
    };
}

function mapVirtualLeague(tag: VirtualCompetition, base: CountItem): LeagueItem {
    return {
        ...base,
        id: tag.id,
        prefix: VirtualCompetitionPrefix,
        siblings: tag.competitionIds,
        standings: !!tag.statistics,
        icon: 'theme-competitions',
        isVirtual: true,
        parentId: tag.parentId,
        imageType: mapImageType(tag.imageType),
        displayMode: virtualCompetitionDisplayModeMap.get(tag.displayMode),
    };
}

function mapVirtualCompetitionGroup(tag: VirtualCompetitionGroup, base: CountItem): VirtualCompetitionGroupItem {
    return {
        ...base,
        id: tag.id,
        siblings: tag.competitionIds,
        standings: !!tag.statistics,
        isVirtual: true,
        participants: mapParticipant(tag.participants),
        groupOrder: tag.order,
        groupIds: tag.groupIds,
        stageIds: tag.stageIds,
        parentId: tag.parentId,
    };
}

function mapMeta(countTag: TagCount): ItemMeta {
    const extended: Pick<ExtendedTagStats, 'coupons' | 'betBuilder'> = countTag.extended || {};

    return {
        calendar: countTag.preMatch > 0,
        coupons: extended.coupons,
        betBuilder: extended.betBuilder,
    };
}

function mapCount(countTag: TagCount): ItemCount {
    const extended: Partial<ExtendedTagStats> = countTag.extended || {};

    return {
        live: countTag.live || 0,
        preMatch: countTag.preMatch || 0,
        gridable: extended.gridable || 0,
        nonGridable: extended.nonGridable || 0,
        outrights: extended.outrights || 0,
        outrightsByGroups: extended.outrightsByGroup || {},
        specials: extended.specials || 0,
        specialsByGroups: extended.specialsByGroup || {},
        other: extended.other || 0,
        antepost: countTag.antepost || 0,
        liveStreaming: countTag.liveStreaming || 0,
        priceBoost: extended.priceBoost || 0,
        dynamicCategories: countTag.dynamicCategories,
    };
}

function mapParticipant(tagParticipants: TagParticipant[] | undefined): VirtualCompetitionGroupParticipant[] {
    if (!tagParticipants) {
        return [];
    }

    const result = tagParticipants.map((tagParticipant) => {
        return {
            code: tagParticipant.code,
            participantId: tagParticipant.participantId,
        } as VirtualCompetitionGroupParticipant;
    });

    return result;
}

function mapImageType(imageType: CDSVirualCompetitionImageType): VirtualCompetitionImageType {
    if (imageType === CDSVirualCompetitionImageType.Flags) {
        return VirtualCompetitionImageType.Flags;
    }

    return VirtualCompetitionImageType.Default;
}

function sortTags(countItems: CountItem[]): CountItem[] {
    countItems.forEach((item) => {
        if (item.children && item.children.length) {
            item.children = sortTags(item.children);
        }
    });

    return sortBy(countItems, ['groupOrder', 'order', 'name']);
}

function isVirtualCompetitionTag(tag: Tag): tag is VirtualCompetition {
    return tag.type === TagType.VirtualCompetition;
}

function isCompetitionTag(tag: Tag): tag is Competition {
    return tag.type === TagType.Competition;
}

function isConferenceTag(tag: Tag): tag is Conference {
    return tag.type === TagType.Conference;
}

// TODO - probably we should export all methods like this, as otherwise it's impossible to spy on
export const sportModelMethods = {
    fromTag,
};
