import { Injectable } from '@angular/core';

import { CompetitionBatchRequest } from '@cds/query-objects';
import { CompetitionStatistics } from '@cds/statistics/competition';
import { FixtureStatistics } from '@cds/statistics/fixture';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { StatisticsApi } from '../cds/statistics-api.service';
import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';
import { StatisticsData } from './statistics.models';

@Injectable({
    providedIn: 'root',
})
export class StatisticsApiService {
    private readonly logger: SportsRemoteLogger;

    constructor(
        loggerFactory: LoggerFactory,
        private cdsStatsService: StatisticsApi,
    ) {
        this.logger = loggerFactory.getLogger('StatisticsApiService');
    }

    getStats(eventId: string, leagueId: number, sportId: number, stageId?: number, groupId?: number): Observable<StatisticsData> {
        const leagueStatistics$ = this.cdsStatsService.getCompetitionStatistics(leagueId, sportId, stageId, groupId);
        const eventStatistics$ = this.cdsStatsService.getFixtureStatistics(eventId, sportId);

        return forkJoin([leagueStatistics$, eventStatistics$]).pipe(
            map(([leagueStatistics, eventStatistics]) => {
                return { eventStats: eventStatistics, leagueStats: leagueStatistics!.rows };
            }),
            catchError((error: any) => {
                this.logError('getStats', error);

                return of({ eventStats: undefined, leagueStats: [] });
            }),
        );
    }

    getFixtureStatistics(fixtureIds: string[], sportId: number): Observable<FixtureStatistics[]> {
        return this.cdsStatsService.getFixtureStatisticsBatch(fixtureIds.join(','), sportId).pipe(
            map((value) => value ?? []),
            catchError((error: any) => {
                this.logError('getFixtureStatistics', error);

                return of([]);
            }),
        );
    }

    getCompetitionsStatistics(competitionIds: number[], sportId: number): Observable<CompetitionStatistics[]> {
        return this.cdsStatsService.getCompetitionStatisticsBatch(competitionIds.join(','), sportId).pipe(
            map((value) => value ?? []),
            catchError((error: any) => {
                this.logError('getCompetitionsStatistics', error);

                return of([]);
            }),
        );
    }

    getVirtualCompetitionsStatistics(
        sportId: number,
        virtualCompetitionId: number,
        virtualCompetitionGroupIds: number[],
    ): Observable<CompetitionStatistics[]> {
        return this.cdsStatsService.getVirtualCompetitionStatistics(sportId, virtualCompetitionId, virtualCompetitionGroupIds.join(',')).pipe(
            map((value) => value ?? []),
            catchError((error: any) => {
                this.logError('getVirtualCompetitionStatistics', error);

                return of([]);
            }),
        );
    }

    getCompetitionsBatchStatistics(competitionBatchRequest: CompetitionBatchRequest): Observable<CompetitionStatistics[]> {
        return this.cdsStatsService.getCompetitionStatisticsBatchV2(competitionBatchRequest).pipe(
            map((value) => value ?? []),
            catchError((error: any) => {
                this.logError('competition-batch/v2', error);

                return of([]);
            }),
        );
    }

    private logError(methodName: string, error: any): void {
        this.logger.error(error, `Failed to load statistics from ${methodName}`);
    }
}
