export interface GroupLegState {
    clientLegId: string;
    isNoncombinable: boolean;
    isClosed: boolean;
    isVisible: boolean;
}

export function defaultLegState(): GroupLegState {
    return {
        clientLegId: '',
        isNoncombinable: false,
        isClosed: false,
        isVisible: true,
    };
}
