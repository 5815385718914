import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivationEnd } from '@angular/router';

import { RouteTag, getAllParams } from '@frontend/sports/common/base-utils';
import { BetslipBarConfig, LayoutNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { HeaderService, MenuItemsService, MenuSection } from '@frontend/vanilla/core';
import { toInteger } from 'lodash-es';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RouterEventsService } from '../navigation-core/router-events.service';
import { PopupManager } from '../popup/popup-manager.service';
import { TrackNavArea } from '../tracking/tracking.models';

@Injectable({ providedIn: 'root' })
export class NavigationRouterService {
    private lastActiveItemName: string | null;
    private navigationState$ = new Subject<string | null>();
    private lastSelectedSport$ = new Subject<number>();
    private _activeNavigation?: string;
    private selectedEventId?: string;

    constructor(
        private eventRouter: RouterEventsService,
        private menuService: MenuItemsService,
        private popupManager: PopupManager,
        private headerService: HeaderService,
        private layoutNavigationConfig: LayoutNavigationConfig,
        public betslipBarConfig: BetslipBarConfig,
    ) {
        this.eventRouter.currentActivationEnd
            .pipe(
                filter((e) => !!e),
                takeUntilDestroyed(),
            )
            .subscribe((event) => this.handleRouterEvent(event));
        this.popupManager.setLastActiveItem.pipe(takeUntilDestroyed()).subscribe(() => this.restoreLastActiveItem());

        combineLatest([this.navigationState$, this.headerService.whenReady])
            .pipe(takeUntilDestroyed())
            .subscribe(([navigationState, _]) => this.headerService.highlightProduct(navigationState));
    }

    setNavigation(active: string | null): void {
        const activeValue = toInteger(active);

        if (active && activeValue) {
            this.lastSelectedSport$.next(activeValue);
        }

        if (active !== 'all') {
            this.lastActiveItemName = active;
        }

        this.setActiveItem(active);

        if (active === 'live' || active === 'virtual') {
            this.navigationState$.next(active);
        } else {
            this.navigationState$.next(null);
        }
    }

    restoreLastActiveItem(): void {
        this.setActiveItem(this.lastActiveItemName);
    }

    resetNavigation(): void {
        this.setActiveItem(null);
    }

    get activeNavigation(): string | undefined {
        return this._activeNavigation;
    }

    get selectedSport$(): Observable<number> {
        return this.lastSelectedSport$.asObservable();
    }

    get getSelectedEventId(): string | undefined {
        return this.selectedEventId;
    }

    private setActiveItem(itemName: string | null): void {
        this.menuService.setActive(TrackNavArea.NavTop, itemName);
        let bottomNavItem: string | null = null;

        if (itemName) {
            bottomNavItem =
                this.layoutNavigationConfig.bottomNavItemsMapping[itemName] ||
                this.layoutNavigationConfig.bottomNavItemsMapping['fallback'] ||
                itemName;
        }

        this.menuService.setActive(MenuSection.BottomNav, bottomNavItem);
        this._activeNavigation = itemName ?? undefined;
    }

    private handleRouterEvent(event: ActivationEnd | undefined): void {
        const params = getAllParams(event?.snapshot);

        let eventId: string = params.sportId || params.sport;

        if (event?.snapshot?.data?.tag === RouteTag.EsportsLobby) {
            eventId = event?.snapshot.data?.tag;
        }

        const popupQueryParams = event?.snapshot.queryParams?.['popup'];
        const isAzMenuOpened = popupQueryParams === 'sports';
        if (!isAzMenuOpened && eventId) {
            this.setNavigation(eventId);
        }
        this.selectedEventId = params.event;
    }
}
