import { Injectable } from '@angular/core';

import { bufferWithSize } from '@frontend/sports/common/base-utils';
import { Observable, Subject, asyncScheduler, noop } from 'rxjs';
import { debounceTime, distinct, filter, map, throttleTime } from 'rxjs/operators';

import { FreshDataService } from '../data-refresh/fresh-data.service';
import { EventModel } from '../event-model/model/event.model';
import { EventCallback, EventSubscription } from '../event-subscription/base-subscription.service';
import { EventSubscriptionService } from '../event-subscription/event-subscription.service';

@Injectable({ providedIn: 'root' })
export class LiveEventsSubscriptionService {
    private subscriptions = new Map<string, EventSubscription<EventModel>>();
    private eventsStream$ = new Subject<EventModel>();
    readonly updatedEvents$: Observable<EventModel[]>;

    constructor(
        private freshdataService: FreshDataService,
        private eventSubscriptionService: EventSubscriptionService,
    ) {
        const updateQueueInMilliseconds = 400;
        const maxUpdateQueueCount = 10;

        // buffer updates and notify at once for performance reasons
        const flush$ = this.eventsStream$.pipe(map(noop), throttleTime(updateQueueInMilliseconds, asyncScheduler));
        const debounceTimer = this.eventsStream$.pipe(debounceTime(updateQueueInMilliseconds));

        this.updatedEvents$ = this.eventsStream$.pipe(
            distinct((event) => event.id, flush$),
            bufferWithSize(maxUpdateQueueCount, debounceTimer),
            filter((events) => events && events.length > 0),
        );

        this.freshdataService.reloadNeeded.subscribe(() => this.unsubscribeAll());
    }

    isSubscribed(eventId: string): boolean {
        return this.subscriptions.has(eventId);
    }

    subscribe(model: EventModel): void {
        if (!this.subscriptions.get(model.id)) {
            const subscription = this.subscribeEvent(model);
            if (subscription) {
                this.subscriptions.set(model.id, subscription);
            }
        }
    }

    unsubscribe(fixtureId: string | string[]): void {
        const ids = Array.isArray(fixtureId) ? fixtureId : [fixtureId];
        this.unsubscribeEvent(ids);
    }

    unsubscribeAll(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
        this.subscriptions.clear();
    }

    private unsubscribeEvent(ids: string[]): void {
        ids.forEach((id) => {
            const subscription = this.subscriptions.get(id);
            if (subscription) {
                subscription.unsubscribe();
                this.subscriptions.delete(id);
            }
        });
    }

    private subscribeEvent(event: EventModel): EventSubscription<EventModel> {
        const callback: EventCallback<EventModel> = (data) => {
            const subscription = this.subscriptions.get(data.id);
            if (subscription) {
                this.eventsStream$.next(data);
            }
        };

        return this.eventSubscriptionService.subscribe([{ event, callback }]);
    }
}
