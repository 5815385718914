import { ComponentType } from '@angular/cdk/overlay';
import { Injectable, Injector, TemplateRef } from '@angular/core';

import { ModalRef } from './modal-ref';
import { ModalStack } from './modal-stack';

/**
 * Represent options available when opening new modal windows.
 */
export interface ModalOptions {
    /**
     * Sets the aria attribute aria-labelledby to a modal window.
     *
     * @since 2.2.0
     */
    ariaLabelledBy?: string;

    /**
     * Whether a backdrop element should be created for a given modal (true by default).
     * Alternatively, specify 'static' for a backdrop which does not close the modal on click.
     */
    backdrop?: boolean | 'static';

    /**
     * Function called when a modal will be dismissed.
     * If this function returns false, the promise is resolved with false or the promise is rejected, the modal is not
     * dismissed.
     */
    beforeDismiss?: () => boolean | Promise<boolean>;

    /**
     * To center the modal vertically (false by default).
     *
     * @since 1.1.0
     */
    centered?: boolean;

    /**
     * An element to which to attach newly opened modal windows.
     */
    container?: HTMLElement;

    /**
     * Injector to use for modal content.
     */
    injector?: Injector;

    /**
     * Whether to close the modal when escape key is pressed (true by default).
     */
    keyboard?: boolean;

    /**
     * Size of a new modal window.
     */
    size?: 'sm' | 'lg';

    /**
     * Custom class to append to the modal window
     */
    windowClass?: string;

    /**
     * Custom class to append to the modal backdrop
     *
     * @since 1.1.0
     */
    backdropClass?: string;

    /**
     * Force the modal window to center vertically relative to the viewport (for scrollable containers).
     * Assumes positon absolute for the modal. Can be used only when `container` is set.
     *
     * @type {boolean}
     * @memberof ModalOptions
     */
    centerVerticalToViewport?: boolean;

    showDialogHeader?: boolean;

    title?: string;

    hideCloseButton?: boolean;

    closeAnimation?: string;

    epcotShowBalance?: boolean;

    showBackNav?: boolean;
    /**
     * Provide a template to be rendered inside the modal-header.
     *
     * @type {TemplateRef<any>}
     * @memberof ModalOptions
     */
    headerTemplate?: TemplateRef<any>;

    showCloseBtnText?: boolean;
    /**
     * To close the previosly opened modal window
     */
    closePrevious?: boolean;
}

/**
 * A service to open modal windows. Creating a modal is straightforward: create a template and pass it as an argument to
 * the "open" method!
 */
@Injectable({ providedIn: 'root' })
export class Modal {
    constructor(
        private _injector: Injector,
        private _modalStack: ModalStack,
    ) {}

    /**
     * Opens a new modal window with the specified content and using supplied options. Content can be provided
     * as a TemplateRef or a component type. If you pass a component type as content than instances of those
     * components can be injected with an instance of the ActiveModal class. You can use methods on the
     * ActiveModal class to close / dismiss modals from "inside" of a component.
     */
    open(content: ComponentType<unknown>, options: ModalOptions = {}): ModalRef {
        return this._modalStack.open(this._injector, content, options);
    }
}
