import { Injectable } from '@angular/core';

import { BetslipBetBuilderPick } from '../../../core/picks/betslip-bet-builder-pick';
import { BetslipGroupPick } from '../../../core/picks/betslip-group-pick';
import { BetslipPick } from '../../../core/picks/betslip-pick';
import { BetslipV1Pick } from '../../../core/picks/betslip-v1-pick';
import { BetslipV2StandardPick } from '../../../core/picks/betslip-v2-standard-pick';
import {
    BetslipGroupPickStorage,
    IBetslipBetBuilderPickStorage,
    IBetslipPickStorage,
    IBetslipV1PickStorage,
    IBetslipV2GolfWinParticipantPickStorage,
    IBetslipV2HorseRaceOptionMarketPickStorage,
    IBetslipV2HorseRaceOptionMarketXCastPickStorage,
    IBetslipV2HorseRaceParticipantPickStorage,
    IBetslipV2HorseRaceWinParticipantPickStorage,
    IBetslipV2OptionMarketPickStorage,
    PickSubType,
} from '../../../core/picks/pick-models';
import { BetslipV2GolfWinParticipantPick } from '../../../core/picks/sport-specific/betslip-v2-golf-picks';
import {
    BetslipV2HorseRaceOptionMarketPick,
    BetslipV2HorseRaceWinParticipantPick,
    BetslipV2HorseRaceXCastPick,
    BetslipV2OptionMarketXCastRacePick,
} from '../../../core/picks/sport-specific/betslip-v2-horse-race-picks';

export function loadPickFromJSON(value: IBetslipPickStorage) {
    switch (value.pickSubType) {
        case PickSubType.V1Pick: {
            return BetslipV1Pick.fromJSON(value as IBetslipV1PickStorage);
        }
        case PickSubType.HorseWinPick: {
            return BetslipV2HorseRaceWinParticipantPick.fromJSON(value as IBetslipV2HorseRaceWinParticipantPickStorage);
        }
        case PickSubType.HorseXCastPick: {
            return BetslipV2HorseRaceXCastPick.fromJSON(value as IBetslipV2HorseRaceParticipantPickStorage);
        }
        case PickSubType.HorseOptionMarketPick: {
            return BetslipV2HorseRaceOptionMarketPick.fromJSON(value as IBetslipV2HorseRaceOptionMarketPickStorage);
        }
        case PickSubType.GolfWinPick: {
            return BetslipV2GolfWinParticipantPick.fromJSON(value as IBetslipV2GolfWinParticipantPickStorage);
        }
        case PickSubType.BetBuilderPick: {
            return BetslipBetBuilderPick.fromJSON(value as IBetslipBetBuilderPickStorage);
        }
        case PickSubType.StandardV2Pick: {
            return BetslipV2StandardPick.fromJSON(value as IBetslipV2OptionMarketPickStorage);
        }
        case PickSubType.GroupedPicks: {
            return BetslipGroupPick.fromJSON(value as BetslipGroupPickStorage);
        }
        case PickSubType.HorseOptionMarketXCastRacePick: {
            return BetslipV2OptionMarketXCastRacePick.fromJSON(value as IBetslipV2HorseRaceOptionMarketXCastPickStorage);
        }

        default: {
            throw new Error('Unknown pick sub type');
        }
    }
}
@Injectable({ providedIn: 'root' })
export class BetslipPickStorageLoader {
    loadPick(value: IBetslipPickStorage): BetslipPick {
        return loadPickFromJSON(value);
    }
}
