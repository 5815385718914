import { Injectable } from '@angular/core';

import { ErrorKind, PickState, PickWithState } from '@cds/push/cashout-commands';

import { EarlyPayoutBetslipType } from '../models/early-payout';
import { EarlyPayoutErrorTypes, EarlyPayoutOptionErrors, EarlyPayoutPickState, PayoutError, PayoutErrorPick } from '../models/early-payout-types';

@Injectable({ providedIn: 'root' })
export class EarlyPayoutSubscriptionMapperService {
    mapToEarlyPayoutError(error?: PayoutError): PayoutError | undefined {
        if (!error) {
            return;
        }

        return {
            ...error,
            type: this.convertOptionToPayoutErrorType(error),
        };
    }

    convertPicksToPickStatus(picks?: PickWithState[]): PayoutErrorPick[] {
        if (!picks) {
            return [];
        }

        return picks.map((pick) => ({
            id: pick.id,
            state: this.convertEarlyPayoutPushPickStateToEarlyPayoutErrorPick(pick.pickState),
        }));
    }

    convertPushUpdateErrorKindToEarlyPayoutError(error: ErrorKind, picks: PickWithState[]): string {
        switch (error) {
            case ErrorKind.BetLost:
                return EarlyPayoutErrorTypes.BetLost;
            case ErrorKind.BetNotFound:
                return EarlyPayoutErrorTypes.BetNotFound;
            case ErrorKind.CashoutNotFound:
                return EarlyPayoutErrorTypes.CashoutNotFound;
            case ErrorKind.ChangedBetSlipState:
                return EarlyPayoutErrorTypes.ChangedBetSlipState;
            case ErrorKind.DuplicatedCashout:
                return EarlyPayoutErrorTypes.DuplicatedCashout;
            case ErrorKind.OverMaxCashoutAmount:
                return EarlyPayoutErrorTypes.OverMaxCashoutAmount;
            case ErrorKind.UnderMinCashoutAmount:
                return EarlyPayoutErrorTypes.UnderMinCashoutAmount;
            case ErrorKind.BetNotAllowedForCashout:
                return EarlyPayoutErrorTypes.BetNotAllowedForCashout;
            case ErrorKind.Option:
                return this.convertPushUpdateOptionErrorToPayoutErrorType(this.convertPicksToPickStatus(picks));
            case ErrorKind.UnderMinOddsKey:
                return EarlyPayoutErrorTypes.UnderMinOddsKey;
            case ErrorKind.CashoutNotAllowed:
                return EarlyPayoutErrorTypes.CashoutNotAllowed;
            case ErrorKind.InconsistentMonetaryValue:
                return EarlyPayoutErrorTypes.InconsistentMonetaryValue;
            case ErrorKind.AutoCashoutNotAllowed:
                return EarlyPayoutErrorTypes.AutoCashoutNotAllowed;
            case ErrorKind.DuplicatedAutoCashout:
                return EarlyPayoutErrorTypes.DuplicatedAutoCashout;
            case ErrorKind.CashoutAmountLowerOrEqualToFreeBet:
                return EarlyPayoutErrorTypes.CashoutAmountLowerOrEqualToFreeBet;
            default:
                return EarlyPayoutErrorTypes.TechnicalError;
        }
    }

    convertOptionToPayoutErrorType(error: PayoutError): string {
        if (error.type === EarlyPayoutErrorTypes.ChangedOptionStatus) {
            return this.convertPushUpdateOptionErrorToPayoutErrorType(error.picks);
        }

        return error.type;
    }

    private convertEarlyPayoutPushPickStateToEarlyPayoutErrorPick(pickState: PickState): EarlyPayoutPickState {
        switch (pickState) {
            case PickState.Won:
                return EarlyPayoutPickState.Won;
            case PickState.Lost:
                return EarlyPayoutPickState.Lost;
            case PickState.Cancelled:
                return EarlyPayoutPickState.Cancelled;
            case PickState.ChangedStatus:
                return EarlyPayoutPickState.ChangedStatus;
            case PickState.CutOffDateReached:
                return EarlyPayoutPickState.CutOffDateReached;
            case PickState.Eligible:
                return EarlyPayoutPickState.Eligible;
            case PickState.Hidden:
                return EarlyPayoutPickState.Hidden;
            case PickState.Locked:
                return EarlyPayoutPickState.Locked;
            case PickState.MarketTemplateNotAllowed:
                return EarlyPayoutPickState.MarketTemplateNotAllowed;
            case PickState.NotFound:
                return EarlyPayoutPickState.NotFound;
            default:
                return EarlyPayoutPickState.Unknown;
        }
    }

    private convertPushUpdateOptionErrorToPayoutErrorType(picks?: PayoutErrorPick[]): string {
        if (!picks) {
            return EarlyPayoutErrorTypes.TechnicalError;
        }

        const betslipType = picks.length === 1 ? EarlyPayoutBetslipType.Single : EarlyPayoutBetslipType.Combo;
        const pickStates = this.getPickstatesSortedByVisiblePriorityDesc();

        let highestPriorityPickStateIndex = 0;
        let highestPriorityPickState = '';
        picks.forEach((pick) => {
            if (pickStates[pick.state] && pickStates[pick.state] > highestPriorityPickStateIndex) {
                highestPriorityPickStateIndex = pickStates[pick.state];
                highestPriorityPickState = pick.state.toString();
            }
        });

        switch (highestPriorityPickState) {
            case EarlyPayoutPickState.MarketTemplateNotAllowed:
                return EarlyPayoutOptionErrors.OptionMarketTemplateNotAllowed;
            case EarlyPayoutPickState.CutOffDateReached:
                return EarlyPayoutOptionErrors.OptionCutOffDateReached;
            case EarlyPayoutPickState.ChangedStatus:
                return EarlyPayoutOptionErrors.OptionChangedStatus;
            case EarlyPayoutPickState.NotFound:
                return EarlyPayoutOptionErrors.OptionNotFound;
            case EarlyPayoutPickState.Hidden: {
                if (betslipType === EarlyPayoutBetslipType.Single) {
                    return EarlyPayoutOptionErrors.OptionHiddenSingle;
                }

                return EarlyPayoutOptionErrors.OptionHiddenCombo;
            }
            case EarlyPayoutPickState.Locked: {
                if (betslipType === EarlyPayoutBetslipType.Single) {
                    return EarlyPayoutOptionErrors.OptionLockSingle;
                }

                return EarlyPayoutOptionErrors.OptionLockCombo;
            }
            default:
                return EarlyPayoutErrorTypes.TechnicalError;
        }
    }

    private getPickstatesSortedByVisiblePriorityDesc(): { [state: string]: number } {
        return {
            [EarlyPayoutPickState.Locked]: 1,
            [EarlyPayoutPickState.Hidden]: 2,
            [EarlyPayoutPickState.ChangedStatus]: 3,
            [EarlyPayoutPickState.NotFound]: 4,
            [EarlyPayoutPickState.CutOffDateReached]: 5,
            [EarlyPayoutPickState.MarketTemplateNotAllowed]: 6,
        };
    }
}
