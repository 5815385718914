"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Utils = void 0;
var Utils = /** @class */function () {
  function Utils() {}
  Utils.values = function (source) {
    return Object.keys(source).map(function (key) {
      return source[key];
    });
  };
  Utils.buildQueryParameters = function (request) {
    var queryParams = Object.keys(request).map(function (key) {
      return Utils.buildQueryParam(key, request[key]);
    }).filter(function (param) {
      return !!param;
    }).join('&');
    return queryParams;
  };
  Utils.buildQueryParam = function (param, value) {
    return value == null || value == undefined || value === '' ? '' : "".concat(param, "=").concat(value);
  };
  return Utils;
}();
exports.Utils = Utils;
