import { BestOddsFixtureLikeInput } from 'packages/sports/web/app/src/best-odds-guarantee/models/models';
import { DateCheckerService } from 'packages/sports/web/app/src/common/date-checker.service';
import { DateProviderService } from 'packages/sports/web/app/src/common/date-provider.service';
import { HorsesFixtureHelper } from 'packages/sports/web/app/src/horse-racing-shared/services/horses-fixture-helper.service';

import { BestOddsGuaranteedToken, IRewardToken } from './reward-tokens.model';

// TODO: @Andriy this file is copied from packages/sports/web/app/src/shared-token-validators; only one should stay

export function isValidStartDate(token: IRewardToken): boolean {
    return DateCheckerService.isDateReached(token.startDate);
}

export function isValidExpiryDate(token: IRewardToken): boolean {
    return !DateCheckerService.isDateReached(token.expiryDate);
}

export function isValidDayOfWeek(token: IRewardToken): boolean {
    if (!token.filter.schedule?.daysOfTheWeek?.length) {
        return true;
    }

    const day = DateProviderService.getDayOfWeek();

    return token.filter.schedule.daysOfTheWeek.some((tokenDay) => tokenDay === day);
}

export function isValidTimeOfDay(token: IRewardToken): boolean {
    if (!token.filter.schedule?.timesOfTheDay?.length) {
        return true;
    }

    return token.filter.schedule.timesOfTheDay.some((timeOfDay) => DateCheckerService.isNowTimeOfDay(timeOfDay));
}

export function isValidSport(token: BestOddsGuaranteedToken, fixture: BestOddsFixtureLikeInput): boolean {
    if (!Object.keys(token.filter.sportsFilter).length) {
        return true;
    }

    const sport = token.filter.sportsFilter[fixture.sport.id];

    return (
        !!sport &&
        hasRestrictedId(sport.regions, fixture.region?.id) &&
        hasRestrictedId(sport.meetings, fixture.meeting?.id) &&
        hasRestrictedId(sport.competitions, fixture.competition?.id)
    );
}

export function hasBestOddsGuaranteeFlag(fixture: BestOddsFixtureLikeInput): boolean {
    const cdsUnresulted = HorsesFixtureHelper.isTradable(fixture);
    const cdsFixtureStartDate = new Date(fixture.startDate);
    const cdsNotStarted = cdsFixtureStartDate.getTime() > Date.now();

    return !!(fixture.addons.bestOddsGuarantee && cdsUnresulted && cdsNotStarted);
}

function hasRestrictedId(filterIds: number[], contextId?: number): boolean {
    return !filterIds?.length || (!!contextId && filterIds.some((filterId) => filterId === contextId));
}
