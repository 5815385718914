import { Injectable } from '@angular/core';

import { LocalStoreService, NativeAppService, SessionStoreService } from '@frontend/vanilla/core';

import { ISavedState } from './state-storage.model';

@Injectable({ providedIn: 'root' })
export default class StoragePersister {
    private readonly STORE_KEY = 'redux-store-v2';

    constructor(
        private session: SessionStoreService,
        private local: LocalStoreService,
        private nativeAppService: NativeAppService,
    ) {}

    /**
     * Apply the new saved state over the current saved and save it.
     * This will allow features to save state individually.
     *
     * @param state update on state
     */
    save(state: Partial<ISavedState>): void {
        if (!this.nativeAppService.isTerminal) {
            this.local.set(this.STORE_KEY, state);

            return;
        }
        this.session.set(this.STORE_KEY, state);
    }

    load(): ISavedState | undefined {
        const savedState = !this.nativeAppService.isTerminal
            ? this.local.get<ISavedState>(this.STORE_KEY)
            : this.session.get<ISavedState>(this.STORE_KEY);

        return savedState ? savedState : undefined;
    }
}
