import { FixtureStage, FixtureType, FixtureViewType } from '@cds/betting-offer';
import { cloneDeep } from 'lodash-es';
import { EventParticipant } from 'packages/sports/web/app/src/event-model/model/event.model';

import { BetslipPick } from '../betslip-pick';
import { BetslipV2OptionMarketPick } from '../betslip-v2-option-market-pick';
import { BetslipV2ParticipantPick } from '../betslip-v2-participant-pick';
import { BetslipV2Pick } from '../betslip-v2-pick';
import { BetslipV2WinParticipantPick } from '../betslip-v2-win-participant-pick';
import { V2OptionMarketXCastPickId } from '../pick-id';
import {
    HorseRaceParticipantPick,
    HorseRacePick,
    IBetslipV2HorseRaceOptionMarketPickStorage,
    IBetslipV2HorseRaceOptionMarketXCastPickStorage,
    IBetslipV2HorseRaceParticipantPickStorage,
    IBetslipV2HorseRacePickFixture,
    IBetslipV2HorseRacePickFixtureStorage,
    IBetslipV2HorseRacePickStorage,
    IBetslipV2HorseRaceWinParticipantPickStorage,
    IBetslipV2Option,
    IBetslipV2OptionMarket,
    IBetslipV2OptionMarketPickStorage,
    IBetslipV2ParticipantPickStorage,
    IBetslipV2PickFixture,
    IBetslipV2PickFixtureStorage,
    IBetslipV2PickParticipant,
    IPrice,
    ParticipantPickType,
    PickSubType,
    PriceType,
} from '../pick-models';
import { SignedName } from '../signed-name.model';

export class RacePickProvider {
    static toJSON(pick: HorseRacePick, baseStorage: IBetslipV2PickFixtureStorage): { fixture: IBetslipV2HorseRacePickFixtureStorage } {
        return {
            fixture: {
                ...baseStorage,
                bestOddsGuarantee: pick.fixture.bestOddsGuarantee,
            },
        };
    }

    static fromJSON(pick: HorseRacePick, fixture: IBetslipV2PickFixture, storage: { fixture: IBetslipV2HorseRacePickFixtureStorage }): void {
        pick.fixture = {
            ...fixture,
            bestOddsGuarantee: storage.fixture.bestOddsGuarantee,
        };
    }

    static eventName(pick: HorseRacePick): SignedName {
        if (pick.fixture.fixtureType === FixtureType.DayOfRace) {
            return pick.leagueName;
        }

        return pick.fixture.name;
    }
}

/**
 * Horse racing Win participant pick
 */
export class BetslipV2HorseRaceWinParticipantPick extends BetslipV2WinParticipantPick implements HorseRaceParticipantPick {
    override get eventParticipants(): EventParticipant[] | undefined {
        return undefined;
    }

    override get eventViewType(): FixtureViewType | undefined {
        return undefined;
    }

    private _marketName: string;
    private _marketNameAltTranslation?: string;

    override fixture: IBetslipV2HorseRacePickFixture;
    override participants: IBetslipV2PickParticipant[];

    static fromJSON(value: IBetslipV2HorseRaceWinParticipantPickStorage): BetslipV2HorseRaceWinParticipantPick {
        const pick = new BetslipV2HorseRaceWinParticipantPick();
        pick.initPropertiesFromJSON(value);

        return pick;
    }

    static override isPick(pick: BetslipPick): pick is BetslipV2HorseRaceWinParticipantPick {
        return pick instanceof BetslipV2HorseRaceWinParticipantPick;
    }

    constructor() {
        super();
    }

    protected override initPropertiesFromJSON(value: IBetslipV2HorseRaceWinParticipantPickStorage): void {
        super.initPropertiesFromJSON(value);
        RacePickProvider.fromJSON(this, this.fixture, value);
        this.fromJSON(this, value);
    }

    setMarketName(value: string, altTranslation?: string): void {
        this._marketName = value;
        if (altTranslation) {
            this._marketNameAltTranslation = altTranslation;
        }
    }

    override toJSON(): IBetslipV2HorseRaceWinParticipantPickStorage {
        const base = super.toJSON();

        return {
            ...base,
            ...RacePickProvider.toJSON(this, base.fixture),
            ...this.toParticipantsJSON(this),
            pickSubType: PickSubType.HorseWinPick,
        };
    }

    private fromJSON(
        pick: HorseRaceParticipantPick,
        json: { participants: IBetslipV2PickParticipant[]; _marketName: string; _marketNameAltTranslation?: string },
    ): void {
        this._marketName = json._marketName;
        pick.participants = json.participants.map((p) => ({
            ...p,
            prices: p.prices,
        }));

        if (json._marketNameAltTranslation) {
            this._marketNameAltTranslation = json._marketNameAltTranslation;
        }
    }

    private toParticipantsJSON(pick: HorseRaceParticipantPick): {
        participants: IBetslipV2PickParticipant[];
        _marketName: string;
        _marketNameAltTranslation?: string;
    } {
        return {
            participants: pick.participants.map((p) => ({
                ...p,
                prices: p.prices,
            })),
            _marketName: this._marketName,
            ...(this._marketNameAltTranslation && { _marketNameAltTranslation: this._marketNameAltTranslation }),
        };
    }

    override setMarketInvisible(): void {
        super.setMarketInvisible();
        this.market.isStartingPriceAvailable = false;
    }

    copy(): BetslipV2HorseRaceWinParticipantPick {
        const storage = this.toJSON();

        return BetslipV2HorseRaceWinParticipantPick.fromJSON(storage);
    }

    override get participant(): IBetslipV2PickParticipant {
        return this.participants[0];
    }

    override get eventName(): SignedName {
        return RacePickProvider.eventName(this);
    }

    get marketName(): SignedName {
        return {
            name: this._marketName,
            signature: '',
            nameAlternate: this._marketNameAltTranslation,
        };
    }
}

/**
 * Horse racing XCast (Forecast, Tricast) pick
 */
export class BetslipV2HorseRaceXCastPick extends BetslipV2ParticipantPick implements HorseRaceParticipantPick {
    private _marketName: string;
    private _marketNameAltTranslation?: string;
    override fixture: IBetslipV2HorseRacePickFixture;
    override participants: IBetslipV2PickParticipant[];

    override get eventParticipants(): EventParticipant[] | undefined {
        return undefined;
    }

    override get eventViewType(): FixtureViewType | undefined {
        return undefined;
    }

    static override isPick(pick: BetslipPick): pick is BetslipV2HorseRaceXCastPick {
        return pick instanceof BetslipV2HorseRaceXCastPick;
    }

    static fromJSON(value: IBetslipV2HorseRaceParticipantPickStorage): BetslipV2HorseRaceXCastPick {
        const pick = new BetslipV2HorseRaceXCastPick();
        pick.initPropertiesFromJSON(value);

        return pick;
    }

    constructor() {
        super();
        this.priceType = PriceType.NoPrice;
    }

    protected override initPropertiesFromJSON(value: IBetslipV2HorseRaceParticipantPickStorage): void {
        super.initPropertiesFromJSON(value);
        RacePickProvider.fromJSON(this, this.fixture, value);
        this.fromJSON(this, value);
    }

    private getPermutations(pos: number): number {
        let res = 1;
        for (let i = this.participants.length; i > this.participants.length - pos; i--) {
            res *= i;
        }

        return res;
    }

    override betCount(): number {
        switch (this.id.pickType) {
            case ParticipantPickType.CombinationForecast:
                return this.getPermutations(2);
            case ParticipantPickType.CombinationTricast:
                return this.getPermutations(3);
            case ParticipantPickType.Forecast:
            case ParticipantPickType.Tricast:
                return 1;
            default:
                return 1;
        }
    }

    setMarketName(value: string, altTranslation?: string): void {
        this._marketName = value;
        if (altTranslation) {
            this._marketNameAltTranslation = altTranslation;
        }
    }

    override toJSON(): IBetslipV2HorseRaceParticipantPickStorage {
        const base = super.toJSON() as IBetslipV2ParticipantPickStorage & IBetslipV2HorseRacePickStorage;

        return {
            ...base,
            ...RacePickProvider.toJSON(this, base.fixture),
            ...this.toParticipantsJSON(this),
            pickSubType: PickSubType.HorseXCastPick,
        };
    }

    copy(): BetslipV2HorseRaceXCastPick {
        const storage = this.toJSON();

        return BetslipV2HorseRaceXCastPick.fromJSON(storage);
    }

    override get eventName(): SignedName {
        return RacePickProvider.eventName(this);
    }

    get marketName(): SignedName {
        return {
            name: this._marketName,
            signature: '',
            nameAlternate: this._marketNameAltTranslation,
        };
    }

    private fromJSON(
        pick: HorseRaceParticipantPick,
        json: { participants: IBetslipV2PickParticipant[]; _marketName: string; _marketNameAltTranslation?: string },
    ): void {
        this._marketName = json._marketName;
        pick.participants = json.participants.map((p) => ({
            ...p,
            prices: p.prices,
        }));

        if (json._marketNameAltTranslation) {
            this._marketNameAltTranslation = json._marketNameAltTranslation;
        }
    }

    private toParticipantsJSON(pick: HorseRaceParticipantPick): {
        participants: IBetslipV2PickParticipant[];
        _marketName: string;
        _marketNameAltTranslation?: string;
    } {
        return {
            participants: pick.participants.map((p) => ({
                ...p,
                prices: p.prices,
            })),
            _marketName: this._marketName,
            ...(this._marketNameAltTranslation && { _marketNameAltTranslation: this._marketNameAltTranslation }),
        };
    }
}

/**
 * Horse racing Option Market Pick
 */
export class BetslipV2HorseRaceOptionMarketPick extends BetslipV2OptionMarketPick implements HorseRacePick {
    override fixture: IBetslipV2HorseRacePickFixture;
    betType: string;
    betTypeAltTranslation: string;

    static fromJSON(value: IBetslipV2HorseRaceOptionMarketPickStorage): BetslipV2HorseRaceOptionMarketPick {
        const pick = new BetslipV2HorseRaceOptionMarketPick();
        pick.initPropertiesFromJSON(value);

        return pick;
    }

    constructor() {
        super();
    }

    protected override initPropertiesFromJSON(value: IBetslipV2HorseRaceOptionMarketPickStorage): void {
        super.initPropertiesFromJSON(value);
        RacePickProvider.fromJSON(this, this.fixture, value);
    }

    override toJSON(): IBetslipV2HorseRaceOptionMarketPickStorage {
        const base = super.toJSON() as IBetslipV2OptionMarketPickStorage & IBetslipV2HorseRacePickStorage;

        return {
            ...base,
            ...RacePickProvider.toJSON(this, base.fixture),
            pickSubType: PickSubType.HorseOptionMarketPick,
        };
    }

    copy(): BetslipV2HorseRaceOptionMarketPick {
        const storage = this.toJSON();

        return BetslipV2HorseRaceOptionMarketPick.fromJSON(storage);
    }

    override get eventName(): SignedName {
        return this.leagueName;
    }

    override get competitionName(): SignedName {
        return this.leagueName;
    }

    static override isPick(pick: BetslipPick): pick is BetslipV2HorseRaceOptionMarketPick {
        return pick instanceof BetslipV2HorseRaceOptionMarketPick;
    }

    isStartingPriceAvailable(): boolean {
        return this.market.isStartingPriceAvailable!;
    }

    isFixedPriceAvailable(): boolean {
        return this.market.isVisible && this.market.isFixedPriceAvailable!;
    }
}

export class BetslipV2OptionMarketXCastRacePick extends BetslipV2Pick {
    options: IBetslipV2Option[];
    override market: IBetslipV2OptionMarket;
    override id: V2OptionMarketXCastPickId;
    override fixture: IBetslipV2HorseRacePickFixture;
    betType: string;
    betTypeAltTranslation: string;
    override get eventParticipants(): EventParticipant[] | undefined {
        return undefined;
    }

    override get eventViewType(): FixtureViewType | undefined {
        return undefined;
    }

    get marketName(): SignedName {
        return this.market.name;
    }

    get isLive(): boolean {
        return this.fixture.stage === FixtureStage.Live;
    }

    get optionName(): SignedName {
        const optionsName = this.options
            .sort((a, b) => a.position! - b.position!)
            .map((p) => p.name.name)
            .join(', ');

        return {
            name: optionsName,
            signature: '',
        };
    }

    private getPermutations(pos: number): number {
        let res = 1;
        for (let i = this.options.length; i > this.options.length - pos; i--) {
            res *= i;
        }

        return res;
    }

    override betCount(): number {
        switch (this.id.pickType) {
            case ParticipantPickType.CombinationForecast:
                return this.getPermutations(2);
            case ParticipantPickType.CombinationTricast:
                return this.getPermutations(3);
            case ParticipantPickType.Forecast:
            case ParticipantPickType.Tricast:
                return 1;
            default:
                return 1;
        }
    }

    get selectedOptions(): IBetslipV2Option[] {
        return this.options;
    }

    static override isPick(pick: BetslipPick): pick is BetslipV2OptionMarketXCastRacePick {
        return pick instanceof BetslipV2OptionMarketXCastRacePick;
    }

    static fromJSON(value: IBetslipV2HorseRaceOptionMarketXCastPickStorage): BetslipV2OptionMarketXCastRacePick {
        const pick = new BetslipV2OptionMarketXCastRacePick();
        pick.initPropertiesFromJSON(value);

        return pick;
    }

    constructor() {
        super();
        this.priceType = PriceType.NoPrice;
    }

    protected override initPropertiesFromJSON(value: IBetslipV2HorseRaceOptionMarketXCastPickStorage): void {
        super.initPropertiesFromJSON(value);
        RacePickProvider.fromJSON(this, this.fixture, value);
        this.market = value.market;
        this.betType = value.betType;
        this.betTypeAltTranslation = value.betTypeAltTranslation;

        this.options = value.options.map((o) => ({
            ...o,
            prices: cloneDeep(o.prices),
        }));
    }

    override toJSON(): IBetslipV2HorseRaceOptionMarketXCastPickStorage {
        const base = super.toJSON();

        return {
            ...base,
            ...RacePickProvider.toJSON(this, base.fixture),
            pickSubType: PickSubType.HorseOptionMarketXCastRacePick,
            options: this.options,
            market: this.market,
            betType: this.betType,
            betTypeAltTranslation: this.betTypeAltTranslation,
        };
    }

    copy(): BetslipV2OptionMarketXCastRacePick {
        const storage = this.toJSON();

        return BetslipV2OptionMarketXCastRacePick.fromJSON(storage);
    }

    override get eventName(): SignedName {
        return this.leagueName;
    }

    override get competitionName(): SignedName {
        return this.leagueName;
    }

    get prices(): IPrice[] {
        return this.options[0].prices.filter((pr) => pr.marketId === this.market.id);
    }
}
