import { Money } from '@bpos';
import { Nullable } from '@frontend/sports/common/base-utils';

import { PayoutError } from './early-payout-types';
import { EarlyPayout, MyBetsBetslipBase } from './my-bets-viewmodels';

export interface EarlyPayoutSubscription {
    betNumber: string;
}

export interface PayoutStatus {
    type: string;
    expectedPayout?: number;
    actualPayout?: number;
    queryStatusRetryDelay?: number;
    pendingQueryStatusRetryDelay?: number;
    overdueRetryDelay: string;
    pendingOverdueRetryDelay?: number;
    error: PayoutError;
}

export interface EarlyPayoutCallResponse {
    payout: number;
    /**
		Has Value only for FreeBets. 
		Used only to show to the customer and in Web tracking.
     */
    payoutWithoutStake?: number;
    error?: PayoutError;
}

export interface EarlyPayoutSuccessResponse {
    payout: number;
    /**
		Has Value only for FreeBets. 
		Used only to show to the customer and in Web tracking.
     */
    payoutWithoutStake?: number;
    betslipId: string;
}

export interface EarlyPayoutFailedResponse {
    error: Nullable<PayoutError>;
    /**
		Has Value only for FreeBets. 
		Used only to show to the customer and in Web tracking.
     */
    possibleAmountWithoutStake?: number;
    betslipId: string;
}

export interface PossibleEarlyPayoutResponse {
    earlyPayouts: EarlyPayoutPossibility[];
}

export interface IEarlyPayoutPicksResult {
    optionId: number;
    state: EarlyPayoutResultedPickState;
}

export enum EarlyPayoutResultedPickState {
    Won = 0,
    Lost = 1,
    Cancelled = 2,
}

export enum EarlyPayoutBetslipType {
    Single = 'Single',
    Combo = 'Combo',
}

export interface EarlyPayoutPossibility {
    betNumber: string;
    earlyPayoutPossible: boolean;
    earlyPayoutValue: number;
    earlyPayoutError?: PayoutError;
    editBetPossible?: boolean;
    autoCashOutValue?: number;
    autoCashoutNotificationValue?: number;
    resultedPicks?: IEarlyPayoutPicksResult[]; // Used in Editbet
}

export interface BetRequest {
    key: number;
    betNumber: string;
    acceptanceMode: string;
    expectedPayout: number;
}

export interface IEarlyPayoutParams {
    betslipId: string;
    earlyPayoutStake: number | null;
}

export interface EarlyPayoutRequest {
    bet: BetRequest;
}

export interface EarlyPayoutResponse {
    requestId: string;
    betslipId: string;
    payoutStatus: PayoutStatus;
}

export enum EditBetStatus {
    Succeeded = 'Succeeded',
    Failed = 'Failed',
    Pending = 'Pending',
}

export interface EditBetPlacementStatus {
    status: EditBetStatus;
    delayInSeconds?: number;
}

export interface PossibleEarlyPayoutByBetNumberResponse {
    earlyPayoutPossibilities: EarlyPayoutPossibility[];
}

export interface EarlyPayoutValueSubscription {
    betNumber: string;
}

export interface SubscribeRequest {
    subscriptions: EarlyPayoutValueSubscription[];
    calculateEarlyPayout: boolean;
}

export interface EarlyPayoutsWithBetslip {
    earlyPayout: EarlyPayoutPossibility;
    betslip: MyBetsBetslipBase;
}

export interface AutoCashoutResponse {
    betNumber?: string;
    setAutoCashoutErrors: PayoutError[];
    possible?: boolean;
}

export interface CashoutSettingsBetInfo {
    betslipId: string;
    earlyPayout?: EarlyPayout;
    possibleWinnings: Money;
    grossPossibleWinnings: Money;
    isFreeBet: boolean;
    stake: Money;
    betType: string;
    containsLiveBets: boolean;
}

export enum CashoutSettingsType {
    Auto = 1,
    Notify = 2,
}

export interface SetAutoCashoutRequest {
    betNumber: string;
    autoCashoutValue: number;
    autoCashoutType: CashoutSettingsType;
}

export interface RemoveAutoCashoutRequest {
    betNumber: string;
    autoCashoutType: CashoutSettingsType;
}

export enum AutoCashoutState {
    Available = 0,
    Processing = 1,
    Disabled = 2,
}

export interface AutocashoutValidation {
    valid: boolean;
    errorMessage: string;
}

export interface AutoCashoutTracking {
    setType: CashoutSettingsType;
    betNumber: string;
    eventName: string;
    trackingName: string;
    value?: number;
    betType: string;
    isFreeBet: boolean;
    labelEvent: string;
    containsLiveBets: boolean;
}

export interface IAutoCashoutBetslipNumbers {
    betslipId: string;
    betslipRealId: string;
}

export interface EarlyPayoutEnhancedBet {
    betNumber: string;
    autoCashoutValue: number;
    autoCashoutNotificationValue: number;
}

export interface EarlyPayoutByBetNumberResponse {
    data: EarlyPayoutEnhancedBet[];
}
