import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { BetPlacementErrorIcon } from '../bet-placement-error-icon';
import { StakeError } from '../stake-error';

export class UnderMinimumStake extends StakeError {
    constructor() {
        super();
        this.icon = BetPlacementErrorIcon.Warning;
        this.hasClientValidation = true;
        this.type = PlacementErrorType.StakeBelowMinimumLimit;
    }
}
