import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Params } from '@angular/router';

import { OfferSource } from '@cds';
import { ParticipantImage } from '@cds/betting-offer';
import { CountItem, NumberDictionary, getAllParams, toDictionary } from '@frontend/sports/common/base-utils';
import { Sitecore, TeamPagesConfig, TeamPagesModuleConfig } from '@frontend/sports/common/client-config-data-access';
import { CompetitionDetail, ParticipantDetail } from '@frontend/sports/types/components/team-pages';
import { LocalStoreService } from '@frontend/vanilla/core';
import { isNumber, orderBy, sortBy } from 'lodash-es';
import { Observable, Subject, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { MasterdataApiService } from '../cds/cds-masterdata-api.service';
import { CompetitionListService } from '../competition-list/services/competition-list.service';
import { EventParticipant, EventParticipantImage } from '../event-model/model/event.model';
import { FavouritesSport, FavouritesViewModel } from '../favourites/favourites.model';
import { FavouritesService } from '../favourites/favourites.service';
import { LiveFavouriteFixturesService } from '../favourites/live-favourites-fixtures.service';
import { Tile } from '../highlights-marquee/marquee-tile.model';
import { TabBarItem } from '../tab-bar/tab-bar.models';
import { UserService } from '../user/services/user.service';
import { TeamPagesApiService } from './team-pages-api.service';
import { TeamPagesCoreGridService } from './team-pages-core-grid.service';
import { TeamPagesCoreMarqueeService } from './team-pages-core-marquee.service';
import {
    FavouriteType,
    Team,
    TeamMenuItem,
    TeamPageCompetition,
    TeamPagesDetailsModel,
    TeamPagesGridModel,
    TeamPagesNavigationViewModel,
    TeamPagesViewModel,
} from './team-pages.model';

@Injectable({ providedIn: 'root' })
export class TeamPagesCoreService {
    private blackListCompetitions: NumberDictionary<number>;
    private backgroundColor: { [x: number]: string };
    private backgroundImage: { [x: number]: string };
    private seeMoreStorageKey = 'seeMoreCompetition';
    private readonly v2Prefix = '2_';
    private readonly v1Prefix = '';
    private readonly homeTeamSuffix = '_1';
    private readonly logoSuffix = '_logo';
    private selectedTeaminfo = new Subject<Team>();
    constructor(
        private teamPagesConfig: TeamPagesConfig,
        private favouritesService: FavouritesService,
        private userService: UserService,
        private teamPagesData: TeamPagesModuleConfig,
        public sitecore: Sitecore,
        private liveFavouritesService: LiveFavouriteFixturesService,
        private teamPagesCoreMarqueeService: TeamPagesCoreMarqueeService,
        private api: TeamPagesApiService,
        private m2LocalStore: LocalStoreService,
        private teamPagesCoreGridService: TeamPagesCoreGridService,
        private competitionListService: CompetitionListService,
        private cdsMasterApi: MasterdataApiService,
    ) {
        this.blackListCompetitions = this.getBlackListCompetitions();
    }

    get blackListCompetitionsIds(): NumberDictionary<number> {
        return this.blackListCompetitions;
    }

    isTeamPagesEnabledForSport(sportId: number): boolean {
        return this.teamPagesConfig.isEnabled && this.teamPagesConfig.whitelistedSports.includes(sportId);
    }

    get selectedTeam(): Observable<Team> {
        return this.selectedTeaminfo.asObservable();
    }

    selectedTeamChanged(team: Team): void {
        this.selectedTeaminfo.next(team);
    }

    getModelByCompetition(
        route: ActivatedRouteSnapshot,
        isNavigationViewModel: boolean = false,
        compoundId?: string | undefined,
    ): Observable<TeamPagesViewModel | undefined> {
        const { sport, competition, team, league } = getAllParams(route);
        // if no competitions or leauge we need to show myteams which has favourite teams
        if (!competition && !league) {
            const response = this.getFavouriteTeams(Number(sport));
            let favouriteTeams: FavouritesViewModel[] = [];
            let teams: Team[] = [];
            if (response?.length) {
                favouriteTeams = response.filter(
                    (f) => f.sport.id.toString() === sport && (f.type === FavouriteType.Participant || f.type === FavouriteType.ParticipantV2),
                );
                teams = this.mapToTeamsFromFavouritesResponse(favouriteTeams, team?.toString());
                if (teams.length === 0) {
                    return of(undefined);
                }
            }

            return this.getTeamPageViewModel(teams, route, isNavigationViewModel);
        }

        return this.api.getTeamsByCompetitionId(compoundId || competition, Number(sport)).pipe(
            switchMap((teams) => {
                if (teams.length === 0) {
                    return of(undefined);
                }

                return this.getTeamPageViewModel(teams, route, isNavigationViewModel);
            }),
        );
    }

    set seeMoreCompetition(comp: TeamPageCompetition) {
        this.m2LocalStore.set(this.seeMoreStorageKey, comp);
    }

    getTeamNavigationViewModel(route: ActivatedRouteSnapshot): Observable<TeamPagesNavigationViewModel | undefined> {
        return this.getModelByCompetition(route, true).pipe(
            map((model) => {
                if (!model) {
                    return undefined;
                } else {
                    return model.teamPagesNavigationViewModel;
                }
            }),
        );
    }

    getParticipantImageInfo(sportId: number, favouritesList: Team[]): Observable<NumberDictionary<ParticipantImage>[] | undefined> {
        return this.cdsMasterApi.getImagesInfo(
            sportId,
            favouritesList?.map((x) => x.id),
        );
    }

    getCompetitionName(sportId: number, competitionId: string): string {
        const siteCoreSportDetails = this.teamPagesData.teamPageItems.sportDetails;
        const competitionsForSport = siteCoreSportDetails.find((sport) => sport.sportId === sportId);
        const competition = competitionsForSport?.competitionDetails.find((c) => c.competitionId === competitionId);

        return competition?.competitionName ?? '';
    }

    isFavouriteTeam(teamId: number): boolean {
        return this.favouritesService.favouritesList.some((fav) => fav.itemId === teamId.toString());
    }

    getImageType(
        sportId: number,
        paarticipantId: number,
        offersource?: OfferSource | FavouriteType,
        imageProfile?: EventParticipantImage,
    ): EventParticipantImage {
        if (!this.teamPagesConfig.imageProfileEnabledSportIds.includes(sportId)) {
            if (this.teamPagesConfig.sportsToDisplayLogos.includes(sportId)) {
                return offersource === OfferSource.V2 || offersource === FavouriteType.ParticipantV2
                    ? {
                          jersey: this.getImage(paarticipantId, this.v2Prefix, this.homeTeamSuffix),
                          logo: this.getImage(paarticipantId, this.v2Prefix, this.logoSuffix),
                          isParticipantProfile: false,
                      }
                    : {
                          jersey: this.getImage(paarticipantId, this.v1Prefix, this.homeTeamSuffix),
                          logo: this.getImage(paarticipantId, this.v1Prefix, this.logoSuffix),
                          isParticipantProfile: false,
                      };
            } else {
                return offersource === OfferSource.V2 || offersource === FavouriteType.ParticipantV2
                    ? { jersey: this.getImage(paarticipantId, this.v2Prefix, this.homeTeamSuffix), isParticipantProfile: false }
                    : { jersey: this.getImage(paarticipantId, this.v1Prefix, this.homeTeamSuffix), isParticipantProfile: false };
            }
        } else {
            return { jersey: imageProfile?.jersey, logo: imageProfile?.logo, isParticipantProfile: imageProfile?.isParticipantProfile ?? true };
        }
    }

    getImage(paarticipantId: number, prefix: string, sugffix: string): string {
        return prefix + paarticipantId + sugffix;
    }

    getBackgroundColor(sportId?: number): string {
        if (!sportId) {
            return '';
        }

        if (!this.backgroundColor) {
            this.backgroundColor = Object.assign(
                {},
                ...this.teamPagesData.backgroundImages.backgrounds.map((img) => ({ [img.sportId]: img.innerColor })),
            );
        }

        return this.backgroundColor[sportId];
    }

    getBackgroundImageUrl(sportId?: number): string {
        if (!sportId) {
            return '';
        }

        if (!this.backgroundImage) {
            this.backgroundImage = Object.assign({}, ...this.teamPagesData.backgroundImages.backgrounds.map((img) => ({ [img.sportId]: img.src })));
        }

        return this.backgroundImage[sportId];
    }

    getPopularTeams(sportId?: number): ParticipantDetail[] {
        return this.teamPagesData.teamPageItems.popularTeamsDetails?.filter((team) => team.sportId === sportId) || [];
    }

    mapTeamData(team: {
        id: number;
        name: string;
        sport: FavouritesSport;
        competitionId?: string;
        isSelected?: boolean;
        offerSource?: OfferSource | number;
        favType?: FavouriteType;
        imageProfile?: EventParticipantImage;
    }): Team {
        let offerSource: OfferSource | undefined;

        if (isNumber(team.offerSource)) {
            offerSource = team.offerSource === 1 ? OfferSource.V1 : OfferSource.V2;
        } else {
            offerSource = team.offerSource;
        }

        return <Team>{
            participantImage: this.getImageType(team.sport.id, team.id, offerSource, team.imageProfile),
            sportIcon: 'sports-' + team.sport.id,
            name: team.name,
            id: team.id,
            competitionId: team.competitionId,
            sport: team.sport,
            isSelected: team.isSelected || false,
            favouriteParticipant: {
                id: team.id,
                name: team.name,
                image: team.imageProfile,
            },
            offerSource,
            isFavourited: this.isFavouriteTeam(team.id) || false,
            teamType: team.favType,
        };
    }

    getParticipantImage(image: string, logo: string): EventParticipantImage {
        return <EventParticipantImage>{
            jersey: image,
            logo,
            isParticipantProfile: false,
        };
    }

    getFavouriteTeams(sportId: number): FavouritesViewModel[] {
        if (this.userService.isAuthenticated) {
            const favouritesList = this.favouritesService.favouritesList.filter(
                (team) => team.sport.id === sportId && (team.type === FavouriteType.Participant || team.type === FavouriteType.ParticipantV2),
            );

            // show latest favourited item
            return favouritesList.reverse();
        }

        return [];
    }

    getAllCompetitions(sportId: number): Observable<CompetitionDetail[]> {
        return this.teamPagesData.whenReady.pipe(
            first(),
            switchMap(() => {
                const siteCoreSportDetails = this.teamPagesData.teamPageItems.sportDetails;
                const competitionsForSport = siteCoreSportDetails?.find((sport) => sport.sportId === sportId)?.competitionDetails;
                if (competitionsForSport?.length) {
                    const competitions = competitionsForSport.map((comp) => ({
                        competitionId: comp.competitionId,
                        competitionName: comp.competitionName,
                    }));

                    return this.api.getCompetitionsAndTeamsCount(competitions, sportId);
                }

                return of([]);
            }),
        );
    }

    private getBlackListCompetitions(): NumberDictionary<number> {
        return toDictionary(
            this.teamPagesConfig.blacklistedCompetitions,
            (id) => Number(id.split(':')[1]),
            (_, index) => index,
        );
    }

    private mapToTeamsFromFavouritesResponse(favouriteTeams: FavouritesViewModel[], selectedTeam?: string): Team[] {
        if (!favouriteTeams || favouriteTeams.length === 0) {
            return [];
        }
        const teams: Team[] = [];
        favouriteTeams.map((favTeam) =>
            teams.push({
                id: Number(favTeam.itemId),
                isFavourited: true,
                name: favTeam.name,
                isSelected: favTeam.itemId === selectedTeam,
                sport: favTeam.sport,
                participantImage: this.getImageType(favTeam.sport.id, Number(favTeam.itemId), favTeam.type, favTeam.participantImage),
                offerSource: favTeam.type === FavouriteType.Participant ? OfferSource.V1 : OfferSource.V2,
                teamType: favTeam.type,
                favouriteParticipant: <EventParticipant>{
                    id: Number(favTeam.itemId),
                    name: favTeam.name,
                    image: favTeam.imageProfile,
                },
            }),
        );

        return teams;
    }

    private getAllChildParams(route: ActivatedRouteSnapshot): Params {
        let params = { ...route.params };
        let childRoute = route.firstChild;
        while (childRoute) {
            params = Object.assign(params, childRoute.params);
            childRoute = childRoute.parent;
        }

        return params;
    }

    getTeamPageViewModel(teams: Team[], route: ActivatedRouteSnapshot, isNavigationViewModel: boolean = false): Observable<TeamPagesViewModel> {
        const teamPagesViewModel = {} as TeamPagesViewModel;
        const { sport, sportName, competition, competitionName, team, teamName } = getAllParams(route);
        const routeParams = {
            sport: Number(sport),
            sportName,
            competition,
            competitionName,
            team,
            teamName,
            data: route.data,
        };
        teamPagesViewModel.teamPagesNavigationViewModel = <TeamPagesNavigationViewModel>{};
        if (teams.length) {
            teams = teams.map((teamdata) => ({
                ...teamdata,
                isFavourited: this.isFavouriteTeam(teamdata.id),
                teamType: teamdata.teamType,
                offerSource: teamdata.offerSource,
                participantImage: teamdata.participantImage,
            }));

            if (routeParams.competition || route.params.league) {
                teams = orderBy(teams, (t) => t.name?.toLowerCase());
                const favTeamIds = this.getFavouriteTeams(Number(sport));
                const favTeamOrder = new Map(favTeamIds.map((val, index) => [val.itemId, index]));
                teams = sortBy(teams, (t) => favTeamOrder.get(t.id.toString()) ?? 9999);
            }
            const selectedTeam = teams.find((teamdata) => teamdata.id === Number(routeParams.team));
            teamPagesViewModel.teamPagesNavigationViewModel.selectedTeam = {
                id: selectedTeam ? selectedTeam.id : this.getAllChildParams(route).team || teams[0].id,
                isFavourited: false,
                isSelected: true,
                name: selectedTeam ? selectedTeam.name : this.getAllChildParams(route).teamName || teams[0].name,
                sport: { id: routeParams.sport, name: routeParams.sportName },
                participantImage: this.getImageType(
                    routeParams.sport,
                    routeParams.team || 0,
                    selectedTeam?.offerSource,
                    selectedTeam?.participantImage,
                ),
                offerSource: selectedTeam?.offerSource,
                competitionId: competition,
            };
        }
        if (isNavigationViewModel) {
            const favourites = this.getAllTeams(teams, teamPagesViewModel.teamPagesNavigationViewModel.selectedTeam);

            return this.getModel(routeParams, favourites, isNavigationViewModel);
        }

        return this.teamPagesCoreGridService.getGridModel(teamPagesViewModel.teamPagesNavigationViewModel.selectedTeam).pipe(
            switchMap((model) =>
                this.teamPagesCoreMarqueeService
                    .getFixturesMarquees(teamPagesViewModel.teamPagesNavigationViewModel.selectedTeam, model.fixtureIds)
                    .pipe(
                        switchMap((fixturemarquees) => {
                            const favourites = this.getAllTeams(teams, teamPagesViewModel.teamPagesNavigationViewModel.selectedTeam);

                            return this.getModel(routeParams, favourites, false, model, fixturemarquees);
                        }),
                    ),
            ),
        );
    }

    private getModel(
        routeParams: { sport: number; sportName: string; competition: string; competitionName: string; team: number; teamName: string; data?: Data },
        teams: Team[],
        isOnlyNavigationViewModel: boolean = false,
        teampagesGridModel?: TeamPagesGridModel,
        fixtureMarquees?: Tile[],
    ): Observable<TeamPagesViewModel> {
        const team = teams.find((t) => t.isSelected);
        const selectedTeam = {
            id: team?.id ?? 0,
            isFavourited: true,
            isSelected: true,
            name: team?.name ?? '',
            sport: { id: routeParams.sport, name: routeParams.sportName },
            participantImage: this.getImageType(routeParams.sport, team?.id || 0, team?.offerSource, team?.participantImage),
            sportIcon: '',
            offerSource: team?.offerSource,
            competitionId: routeParams.competition,
        };
        const favouritesEnabled = this.liveFavouritesService.isLiveFavouriteEnabled() || this.liveFavouritesService.isSwipeFavouriteEnabled();
        const hasFavouritedTeams = teams.some((t) => t.isFavourited);

        return this.mapToCompetitionTabs(favouritesEnabled, hasFavouritedTeams, routeParams).pipe(
            map((tabs) => {
                const teamPagesNavigationViewModel = <TeamPagesNavigationViewModel>{
                    teams,
                    competitionTabs: isOnlyNavigationViewModel ? tabs : [],
                    currentSport: <FavouritesSport>{ id: Number(routeParams.sport), name: routeParams.sportName },
                    selectedTeam,
                    hasFavouritedTeams,
                    popularTeams: this.getPopularTeams(Number(routeParams.sport)),
                };
                if (isOnlyNavigationViewModel) {
                    return <TeamPagesViewModel>{
                        teamPagesNavigationViewModel,
                        teamPagesDetailsModel: {},
                    };
                }
                const teamPagesDetailsModel = <TeamPagesDetailsModel>{
                    teampagesGridViewModel: {
                        teamPagesGridModel: teampagesGridModel,
                        selectedTeam,
                    },
                    bannerViewModel: {
                        backgroundImage: this.getBackgroundImageUrl(routeParams.sport),
                        team: selectedTeam,
                    },
                    marqueeTiles: fixtureMarquees,
                    sportCounts: this.getSportsCounts(routeParams?.sport),
                };
                const teamPagesViewModel = <TeamPagesViewModel>{
                    teamPagesNavigationViewModel,
                    teamPagesDetailsModel,
                };

                return teamPagesViewModel;
            }),
        );
    }

    private getSportsCounts(sportId: number) {
        let sportsCounts: CountItem | undefined;
        this.competitionListService.getSportTree(sportId).subscribe((result) => {
            sportsCounts = result?.sport;
        });

        return sportsCounts;
    }

    private mapToCompetitionTabs(
        favouritesEnabled: boolean,
        hasFavouriteTeams: boolean,
        routeParams: { sport: number; sportName: string; competition: string; competitionName: string; team: number; teamName: string; data?: Data },
    ): Observable<TabBarItem<string>[]> {
        return this.getAllCompetitions(Number(routeParams.sport)).pipe(
            map((competitions) => {
                if (!competitions || competitions.length === 0) {
                    return [];
                }
                const selectedCompetitionId = routeParams.competition || competitions[0].competitionId;
                const tabs = competitions.map((comp) => ({
                    id: comp.competitionId,
                    title: comp.competitionName,
                    active: false,
                }));
                if (favouritesEnabled && this.userService.isAuthenticated) {
                    tabs.splice(0, 0, {
                        title: this.sitecore.globalMessages.MyTeams,
                        active: this.userService.isAuthenticated && !routeParams.competition,
                        id: TeamMenuItem.MyTeams,
                    });
                }
                if (!tabs[0].active) {
                    tabs.forEach((t) => (t.active = t.id === selectedCompetitionId));
                }
                const anyActiveTab = tabs.some((item) => item.active);
                tabs.push({
                    title: this.sitecore.globalMessages.SeeMore,
                    active: !anyActiveTab,
                    id: TeamMenuItem.Showmore,
                });

                return tabs;
            }),
        );
    }

    private getAllTeams(teams: Team[], selectedTeam: Team): Team[] {
        if (!teams) {
            return [];
        }

        return teams.map((team) =>
            this.mapTeamData({
                id: team.id,
                name: team.name,
                sport: { id: selectedTeam.sport!.id, name: selectedTeam.sport!.name },
                competitionId: team.competitionId,
                isSelected: team.id === Number(selectedTeam.id),
                offerSource: team.offerSource,
                favType: team.teamType,
                imageProfile: team.participantImage,
            }),
        );
    }
}
