import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { LaunchDarklyService } from '@frontend/vanilla/features/launch-darkly';
import { noop } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EpcotConfigService, EpcotModule } from '../../common/epcot-config.service';
import { ToasterComponent } from './toaster.component';

export interface ToasterConfig {
    icon?: string;
    duration?: number;
    panelClass?: string;
}

export interface ToasterModel {
    icon?: string;
    message: string;
    dismiss: () => void;
}

export interface Toaster {
    dismissed: Observable<void>;
    opened: Observable<void>;
    dismiss(): void;
}

@Injectable({ providedIn: 'root' })
export class ToasterService {
    private isEpcotEnabled: boolean;
    private lightToasterTextClass: string | undefined;
    readonly ldFeatureFlagKey = 'EnableLightToasterText';
    constructor(
        private snackBar: MatSnackBar,
        private epcotConfigService: EpcotConfigService,
        private launchDarklyService: LaunchDarklyService,
    ) {
        this.isEpcotEnabled = this.epcotConfigService.isEnabled(EpcotModule.Favorites);
        // TODO: This open subscriber in root service is a bad practice.
        // Below code will be removed as soon as product has a proper use case for launch darkly.
        this.launchDarklyService.getFeatureFlagValue(this.ldFeatureFlagKey).subscribe((enableLightToasterText: boolean) => {
            this.lightToasterTextClass = enableLightToasterText ? 'light-toaster-text' : undefined;
        });
    }

    open(message: string, { icon, duration, panelClass }: ToasterConfig = {}): Toaster {
        const toasterPanelClass = !this.isEpcotEnabled && EpcotModule.Favorites ? 'row-toaster-panel' : 'toaster-panel';
        const panelClasses = [toasterPanelClass];
        if (panelClass) {
            panelClasses.push(...panelClass.split(' '));
        }
        if (this.lightToasterTextClass) {
            panelClasses.push(this.lightToasterTextClass);
        }
        const toaster = this.snackBar.openFromComponent(ToasterComponent, {
            data: {
                icon,
                message,
                dismiss: () => toaster.dismiss(),
            },
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            panelClass: panelClasses,
            duration,
        });

        return {
            dismiss: () => toaster.dismiss(),
            dismissed: toaster.afterDismissed().pipe(map(noop)),
            opened: toaster.afterOpened(),
        };
    }

    dismiss(): void {
        this.snackBar.dismiss();
    }
}
