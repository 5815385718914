import { Injectable } from '@angular/core';

import { CrmPromotionReplaceParametersService } from 'packages/sports/web/app/src/crm-promotion/crm-promotion-replace-parameters.service';

import { IRewardToken } from '../reward-tokens.model';

@Injectable({ providedIn: 'root' })
export class RewardTokensFormattersService {
    private currencySymbolReplacebleParam = '_{CurrencySymbol}_';
    private currencyCodeReplacebleParam = '_{Currency}_';

    constructor(private replaceParametersService: CrmPromotionReplaceParametersService) {}

    replaceCurrency(title: string, currencySymbol: string = '', currencyCode: string = ''): string {
        return title
            .replace(new RegExp(this.currencySymbolReplacebleParam, 'g'), currencySymbol)
            .replace(new RegExp(this.currencyCodeReplacebleParam, 'g'), currencyCode);
    }

    getTokenQuickViewReplaceValue(token: IRewardToken, replace: string | undefined): string {
        return replace
            ? this.replaceParametersService.replaceParameters(this.replaceCurrency(replace), token.replaceableParameters!, false, false, true)
            : '';
    }
}
