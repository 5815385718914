import { Observable, Subject, interval, of } from 'rxjs';
import { finalize, map, takeWhile } from 'rxjs/operators';

export interface PreloaderService {
    loading: () => boolean;
    error: () => boolean;
    data: () => any;
    reset: () => void;
}

interface WaitUntilConfig {
    timeout: number;
    condition: () => boolean;
}

type PreloaderFunction = (target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => TypedPropertyDescriptor<any>;
/**
 *
 * 	When the page loads, some data is loaded before the angular up is started.
 *  This checks if the data is present and if so it does not call the backend again but resolves with the pre-loaded data.
 *
 * @export
 * @param
 */
export function usePreloaderData(config: {
    preloader: PreloaderService;
    responseMapper?: (data: any) => any;
    condition?: (param1: any) => boolean;
}): PreloaderFunction {
    return function (target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>): TypedPropertyDescriptor<any> {
        descriptor.value = getDecoratedFunction(descriptor.value);

        return descriptor;
    };

    function getDecoratedFunction(originalFunction: () => void): any {
        return function (this: any, ...args: any[]): any {
            const self = this;

            if (config.condition && !config.condition(args[0])) {
                return originalFunction.apply(self, args);
            }

            config.responseMapper = config.responseMapper || ((data) => data);

            if (config.preloader && config.preloader.error()) {
                config.preloader.reset();

                return originalFunction.apply(self, args);
            }

            if (config.preloader && config.preloader.loading()) {
                return waitUntil({
                    condition: () => !config.preloader.loading(),
                    timeout: 10000,
                }).pipe(
                    map((status) => {
                        if (status === 'success' && !config.preloader.error()) {
                            config = config || <any>{};
                            config.responseMapper =
                                config.responseMapper ||
                                function (input: any): any {
                                    return input;
                                };

                            return config.responseMapper(config.preloader.data());
                        }

                        return originalFunction.apply(self, args);
                    }),
                    finalize(() => config.preloader.reset()),
                );
            }

            if (config.preloader && config.preloader.data()) {
                const data = config.preloader.data();
                config.preloader.reset();

                return of(config.responseMapper(data));
            }

            return originalFunction.apply(self, args);
        };
    }

    function waitUntil(options: WaitUntilConfig): Observable<'timeout' | 'success'> {
        let totalWaited = 0;
        const waiting = new Subject<'timeout' | 'success'>();

        interval(100)
            .pipe(
                takeWhile((_) => {
                    totalWaited += 100;

                    if (options.condition()) {
                        waiting.next('success');
                        waiting.complete();

                        return false;
                    }

                    if (totalWaited > options.timeout) {
                        if (options.condition()) {
                            waiting.next('success');
                            waiting.complete();

                            return false;
                        }

                        waiting.next('timeout');
                        waiting.complete();

                        return false;
                    }

                    return true;
                }),
            )
            .subscribe();

        return waiting.asObservable();
    }
}
