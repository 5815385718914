import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { EpcotConfig } from '@frontend/sports/common/client-config-data-access';
import { LinkListLayout } from '@frontend/sports/types/components/widget/types';
import { keys } from 'lodash-es';

import { ListItem } from '../common/item/item.model';
import { CompetitionLogosService } from '../competition-logos/competition-logos.service';
import { PopupManager, PopupTypes } from '../popup/popup-manager.service';
import { QuickLinkItem } from '../quick-links/quick-links.models';

@Component({
    selector: 'ms-promo-item-list',
    templateUrl: 'promo-item-list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoItemListComponent {
    @Input() tracking?: string;
    @Input() arrow = true;
    @Input() items: ListItem[] | QuickLinkItem[];
    @Input() itemClass = '';
    @Input() promotedCompetitionIdsMap = {};
    @Input() promotedSportIdsMap = {};
    @Input() layout?: LinkListLayout;
    @Output() itemClick = new EventEmitter<ListItem>();
    private isEpcotEnabled: boolean;
    @HostBinding('class.mini-carousel-with-subheader') get isMiniCarouselLayout(): boolean {
        return this.layout === LinkListLayout.MiniCarousel && this.isEpcotEnabled;
    }

    constructor(
        private competitionLogoService: CompetitionLogosService,
        private popupManager: PopupManager,
        private epcotConfig: EpcotConfig,
    ) {
        this.isEpcotEnabled = this.epcotConfig.isEnabled;
    }

    clicked(item: ListItem | QuickLinkItem): void {
        this.itemClick.emit(item);

        if ('popup' in item && !!item.popup) {
            this.popupManager.toggle(<PopupTypes>item.popup);
        }
    }

    hasCompetitionLogo(sportId: number, leagueId: number): boolean {
        return this.competitionLogoService.hasLogo(sportId, leagueId);
    }

    hasBgcolorForTilesOrGridView(item: ListItem): boolean {
        return !!(item.bgColor && this.layout && [LinkListLayout.TilesCarousel, LinkListLayout.Grid].includes(this.layout));
    }

    areBadgesEnabled(item: ListItem): boolean {
        if (!item.attributes) {
            return false;
        }

        const sportIds = item.attributes['promotedSportId']?.toString().split(',') || [];
        const leagueIds = item.attributes['promotedLeagueId']?.toString().split(',') || [];

        return (
            keys(this.promotedSportIdsMap).some((key) => sportIds.includes(key)) ||
            keys(this.promotedCompetitionIdsMap).some((key) => leagueIds.includes(key))
        );
    }

    itemIndexTrackBy(index: any, item: any): string {
        return index;
    }

    get isCarouselLayout(): boolean {
        return !!this.layout && [LinkListLayout.MiniCarousel, LinkListLayout.TilesCarousel].includes(this.layout);
    }
}
