import { Injectable } from '@angular/core';

import { Widget, WidgetLayoutTemplate, WidgetPage } from '@frontend/sports/types/components/widget';

@Injectable()
export class ModularConfigAccessorService {
    private widget?: Widget<unknown>;
    private parent?: Widget<unknown>;
    private page?: WidgetPage;
    private layoutTemplate?: WidgetLayoutTemplate;

    setPage(page: WidgetPage | undefined): void {
        this.page = page;
    }

    getPage(): WidgetPage | undefined {
        return this.page;
    }

    setWidget(widget: Widget<unknown>): void {
        this.widget = widget;
    }

    getWidget(): Readonly<Widget<unknown>> | undefined {
        return this.widget;
    }

    setParentWidget(widget: Widget<unknown> | undefined): void {
        this.parent = widget;
    }

    getParentWidget(): Readonly<Widget<unknown>> | undefined {
        return this.parent;
    }

    setLayoutTemplate(layoutTemplate: WidgetLayoutTemplate | undefined): void {
        this.layoutTemplate = layoutTemplate;
    }

    getLayoutTemplate(): WidgetLayoutTemplate | undefined {
        return this.layoutTemplate;
    }
}
