export interface Time {
    hours: number;
    minutes: number;
}

export interface TimeOfDay {
    from: Time;
    to: Time;
}

export enum DayOfWeek {
    Sunday = 'Sunday',
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
}
