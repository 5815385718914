import { Injectable } from '@angular/core';

import { EventBridgeService } from '../event-bridge/event-bridge.service';

enum PopupAction {
    Open = 'open',
    Close = 'close',
}

@Injectable({ providedIn: 'root' })
export class SensitivePageTrackerService {
    constructor(private eventBridgeService: EventBridgeService) {}

    dispatchSensitivePagesEvent(currentPopup: string | null, oldPopup?: string): void {
        if (oldPopup) {
            this.popupClosedNotification(oldPopup);
        }
        if (currentPopup) {
            this.popupOpenedNotification(currentPopup);
        }
    }

    private popupClosedNotification(popupName: string): void {
        this.popupNotification(this.isPopupSensitive(popupName, PopupAction.Close), PopupAction.Close, popupName);
    }

    private popupOpenedNotification(popupName: string): void {
        this.popupNotification(this.isPopupSensitive(popupName, PopupAction.Open), PopupAction.Open, popupName);
    }

    private isPopupSensitive(popupName: string, action: PopupAction): string {
        return (popupName === 'betslip' && action === PopupAction.Open).toString();
    }

    private popupNotification(popupSensitivity: string, popupAction: PopupAction, popupId: string): void {
        this.eventBridgeService.notifySensitivePage({
            isSensitiveForPushNotifications: popupSensitivity,
            isSensitiveForSliderGames: popupSensitivity,
            action: popupAction,
            id: popupId,
            type: 'popup',
        });
    }
}
