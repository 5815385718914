/// <summary>
/// System bet type, <see cref="BetSlipType"/>
/// </summary>
export enum SystemType {
    // Singles = N combinations
    SystemSingle = 0,
    // Combo = 1 combination
    SystemCombo = 1,

    /// <summary>
    /// 3 combo bets 2/3
    /// </summary>
    System2Of3 = 2, // Make it equal to 2 so to unify it with BetSlipType

    /// <summary>
    /// 3 combo bets 2/3 + 1 combo bet 3/3 + 3 single bets
    /// </summary>
    SystemPatent,

    /// <summary>
    /// 3 combo bets 2/3 + 1 combo bet 3/3
    /// </summary>
    SystemTrixie,

    /// <summary>
    /// 6 combo bets 2/4
    /// </summary>
    System2Of4,

    /// <summary>
    /// 4 combo bets 3/4
    /// </summary>
    System3Of4,

    /// <summary>
    /// 6 combo bets 2/4 + 4 combo bets 3/4 + 1 combo bet 4/4
    /// </summary>
    SystemYankee,

    /// <summary>
    /// 6 combo bets 2/4 + 4 combo bets 3/4 + 1 combo bet 4/4 + 4 single bets
    /// </summary>
    SystemLucky15,

    /// <summary>
    /// 10 combo bets 2/5
    /// </summary>
    System2Of5,

    /// <summary>
    /// 10 combo bets 3/5
    /// </summary>
    System3Of5,

    /// <summary>
    /// 5 combo bets 4/5
    /// </summary>
    System4Of5,

    /// <summary>
    /// 10 combo bets 2/5 + 10 combo bets 3/5 + 5 combo bets 4/5 + 1 combo bet 5/5
    /// </summary>
    SystemCanadian,

    /// <summary>
    /// 10 combo bets 2/5 + 10 combo bets 3/5 + 5 combo bets 4/5 + 1 combo bet 5/5 + 5 single bets
    /// </summary>
    SystemLucky31,

    /// <summary>
    /// 15 combo bets 2/6
    /// </summary>
    System2Of6,

    /// <summary>
    /// 20 combo bets 3/6
    /// </summary>
    System3Of6,

    /// <summary>
    /// 15 combo bets 4/6
    /// </summary>
    System4Of6,

    /// <summary>
    /// 6 combo bets 5/6
    /// </summary>
    System5Of6,

    /// <summary>
    /// 15 combo bets 2/6 + 20 combo bets 3/6 + 15 combo bets 4/6 + 6 combo bets 5/6 + 1 combo bet 6/6
    /// </summary>
    SystemHeinz,

    /// <summary>
    /// 15 combo bets 2/6 + 20 combo bets 3/6 + 15 combo bets 4/6 + 6 combo bets 5/6 + 1 combo bet 6/6 + 6 single bets
    /// </summary>
    SystemLucky63,

    /// <summary>
    /// 21 combo bets 2/7
    /// </summary>
    System2Of7,

    /// <summary>
    /// 35 combo bets 3/7
    /// </summary>
    System3Of7,

    /// <summary>
    /// 35 combo bets 4/7
    /// </summary>
    System4Of7,

    /// <summary>
    /// 21 combo bets 5/7
    /// </summary>
    System5Of7,

    /// <summary>
    /// 7 combo bets 6/7
    /// </summary>
    System6Of7,

    /// <summary>
    /// 21 combo bets 2/7 + 35 combo bets 3/7 + 35 combo bets 4/7 + 21 combo bets 5/7 + 7 combo bets 6/7 + 1 combo bet 7/7
    /// </summary>
    SystemSuperHeinz,

    /// <summary>
    /// 28 combo bets 2/8
    /// </summary>
    System2Of8,

    /// <summary>
    /// 56 combo bets 3/8
    /// </summary>
    System3Of8,

    /// <summary>
    /// 70 combo bets 4/8
    /// </summary>
    System4Of8,

    /// <summary>
    /// 56 combo bets 5/8
    /// </summary>
    System5Of8,

    /// <summary>
    /// 28 combo bets 6/8
    /// </summary>
    System6Of8,

    /// <summary>
    /// 8 combo bets 7/8
    /// </summary>
    System7Of8,

    /// <summary>
    /// 28 combo bets 2/8 + 56 combo bets 3/8 + 70 combo bets 4/8 + 56 combo bets 5/8 + 28 combo bets 6/8 + 8 combo bets 7/8 + 1 combo bet 8/8
    /// </summary>
    SystemGoliath,

    /// <summary>
    /// Banker bets + single bets.
    /// </summary>
    SystemBankerSingles,
    /// <summary>
    /// Multi single bets, used in betstation to place multiple bets as atomic transaction.
    /// </summary>
    SystemSingles,
}

export enum SlipTypeKeys {
    Single = 'single',
    Combo = 'combo',
    BetBuilder = 'betbuilder',
}
