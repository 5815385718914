import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { CommonMessages } from '../client-config/common-messages.client-config';
import { Page } from '../client-config/page.client-config';
import { LoadingIndicatorService } from './loading-indicator.service';

/**
 * @stable
 */
@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'vn-loading-indicator',
    templateUrl: 'loading-indicator.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent {
    content: SafeHtml;

    constructor(public loadingIndicatorService: LoadingIndicatorService, public commonMessages: CommonMessages, page: Page, sanitizer: DomSanitizer) {
        this.content = sanitizer.bypassSecurityTrustHtml(page.loadingIndicator.spinnerContent);
    }
}
