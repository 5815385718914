import { Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';

import { Nullable } from '@frontend/sports/common/base-utils';

import { TimerService } from '../common/timer.service';

@Directive({
    selector: '[msClassAutoRemove]',
})
export class ClassAutoRemoveDirective implements OnDestroy {
    @Input('msClassAutoRemove') className = '';
    @Input() msClassAutoRemoveTime = 1000; // Default value: 1 sec.
    @Output() msClassAutoRemoveIsClassVisibleChange = new EventEmitter<boolean>();

    private removeTimer: Nullable<() => void> = null;
    private _isClassVisible: boolean;

    @Input('msClassAutoRemoveIsClassVisible')
    get isClassVisible(): boolean {
        return this._isClassVisible;
    }

    set isClassVisible(value: boolean) {
        this.applyChanges(value);
    }

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private timerService: TimerService,
    ) {}

    ngOnDestroy(): void {
        this.clearTimer();
    }

    private applyChanges(value: boolean): void {
        if (value && !this.elementHasClass()) {
            this.addClass();
            this.startTimer();
        } else if (!value && this.elementHasClass()) {
            this.removeClass();
            this.clearTimer();
        }
    }

    private addClass(): void {
        this.renderer.addClass(this.elementRef.nativeElement, this.className);
        this._isClassVisible = true;
        this.msClassAutoRemoveIsClassVisibleChange.emit(true);
    }

    private removeClass(): void {
        this.renderer.removeClass(this.elementRef.nativeElement, this.className);
        this._isClassVisible = false;
        this.msClassAutoRemoveIsClassVisibleChange.emit(false);
    }

    private startTimer(): void {
        this.removeTimer = this.timerService.setTimeout(() => {
            this.removeClass();
            this.removeTimer = null;
        }, this.msClassAutoRemoveTime);
    }

    private clearTimer(): void {
        if (this.removeTimer) {
            this.removeTimer = null;
        }
    }

    private elementHasClass(): boolean {
        return this.elementRef.nativeElement.classList.contains(this.className);
    }
}
