import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';

import { PerformanceProfilingConfig } from '@frontend/sports/common/client-config-data-access';
import { OnAppInit } from '@frontend/vanilla/core';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PerformanceProfilingService implements OnAppInit {
    private readonly markNameTTI = 'vn_tti';

    private lastTrackedUrl = '';

    constructor(
        private router: Router,
        private performanceConfig: PerformanceProfilingConfig,
    ) {}

    onAppInit(): void | Promise<any> {
        setTimeout(() => {
            this.register();
        });
    }

    register(): void {
        try {
            const profilingEnabled = this.performanceConfig.isPerformanceProfilingEnabled;
            const browserSupport = performance && performance.measure;

            if (profilingEnabled && !!browserSupport) {
                this.router.events.pipe(filter((event) => event instanceof ActivationStart)).subscribe(this.navigationStartCallback);
            }
        } catch {}
    }

    markTTI(): void {
        if (!this.performanceConfig.isPerformanceProfilingEnabled) {
            return;
        }

        this.markEnd(this.markNameTTI);
    }

    private markEnd(markName: string): void {
        try {
            // markNameTTI is tracked from the grid. if there are more grids on the page we want to only track once'
            if (markName === this.markNameTTI && this.lastTrackedUrl !== this.router.url) {
                performance.mark(`${markName}-end`);
                const navigationStartMark = performance.getEntriesByName(`${markName}-start`)[0];
                if (navigationStartMark) {
                    performance.measure(markName, `${markName}-start`, `${markName}-end`);
                }
                if (markName === this.markNameTTI) {
                    this.lastTrackedUrl = this.router.url;
                }
            }
        } catch {}
    }

    private navigationStartCallback = () => {
        this.markStart();
    };

    private markStart(): void {
        try {
            performance.clearMarks(`${this.markNameTTI}-start`);
            performance.clearMarks(`${this.markNameTTI}-end`);
            performance.clearMeasures(this.markNameTTI);

            performance.mark(`${this.markNameTTI}-start`);
        } catch {}
    }
}
