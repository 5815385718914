import { NgForOf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'ms-colored-icon',
    templateUrl: 'colored-icon.html',
    standalone: true,
    imports: [NgForOf],
})
export class ColoredIconComponent {
    numberOfPathSpans = 25;
    @Input() iconClass: string;

    @HostBinding('class') get className(): string {
        return this.iconClass.includes('sports') ? `colored-${this.iconClass}` : this.iconClass;
    }

    count = [...Array(this.numberOfPathSpans).keys()].map((i) => i + 1);
}
