import { Injectable, Injector, NgModuleRef, Type, createNgModule } from '@angular/core';

import { DispatcherService } from '@frontend/sports/common/dispatcher-utils';
import { WidgetType } from '@frontend/sports/types/components/widget';
import { EventsService } from '@frontend/vanilla/core';
import { uniq } from 'lodash-es';
import { Observable, from, of } from 'rxjs';
import { mergeMap, share, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export default class ModuleLoaderService {
    readonly lazyFeatures: string[] = [
        WidgetType.LinkList,
        WidgetType.Marquee,
        WidgetType.NextToGo,
        WidgetType.TopEvents,
        WidgetType.TabbedGrid,
        WidgetType.OutrightsGrid,
        WidgetType.Composable,
        WidgetType.ShowCase,
        WidgetType.CompetitionPriceBoosts,
        WidgetType.Media,
        WidgetType.MiniGames,
        WidgetType.SportTree,
        WidgetType.BetStationMarquee,
        WidgetType.BetStationTopScreenSportsBanner,
        WidgetType.BetStationLiveTicker,
        WidgetType.PopularMultiBets,
        WidgetType.PanicButton,
        WidgetType.TimeFilters,
        WidgetType.HiddenMarket,
        WidgetType.Favourites,
        WidgetType.BetColumn,
        WidgetType.BetGenerator,
        WidgetType.CompetitionStandings,
        WidgetType.PromoContainer,
        WidgetType.StoryContent,
        WidgetType.TopItems,
    ];

    private loadedModules: Map<string, Observable<NgModuleRef<any>> | NgModuleRef<any>>;
    private loadedFeatures = new Map<string, boolean>();

    constructor(
        private injector: Injector,
        private dispatcher: DispatcherService,
        private eventsService: EventsService,
    ) {
        this.loadedModules = new Map<string, Observable<NgModuleRef<any>> | NgModuleRef<any>>();
    }

    loadModule<T>(moduleName: string, moduleLoader: () => Promise<Type<T>>): Observable<NgModuleRef<T>> {
        const loadedModule = this.loadedModules.get(moduleName);
        if (loadedModule) {
            if (loadedModule instanceof Observable) {
                return loadedModule;
            }

            return of(loadedModule);
        }
        const observable = from(moduleLoader()).pipe(
            mergeMap((moduleType) => of(createNgModule(moduleType, this.injector))),
            tap((ref) => {
                this.loadedModules.set(moduleName, ref);
                this.dispatcher.dispatch('MODULE_LOADED', moduleName);
            }),
            share(),
        );
        this.loadedModules.set(moduleName, observable);

        return observable;
    }

    lazyLoadFeatures(eventName: string | string[]) {
        const events = uniq(Array.isArray(eventName) ? eventName : [eventName]);
        events
            .filter((event) => this.lazyFeatures.includes(event) && !this.loadedFeatures.has(event))
            .forEach((event) => {
                this.loadedFeatures.set(event, true);
                this.eventsService.raise({ eventName: event });
            });
    }
}
