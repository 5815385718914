import { PlaceBetRequest } from '@bpos/v2/bet-placement';
import { MessageEnvelope } from '@cds/push';
import { Nullable, pprops } from '@frontend/sports/common/base-utils';
import { createAction, props } from '@ngrx/store';
import { IEarlyPayoutData } from 'packages/sports/web/app/src/my-bets-base/models/early-payout-types';
import { IEarlyPayoutResultedPicks, MyBetsBetSlip } from 'packages/sports/web/app/src/my-bets-base/models/my-bets-viewmodels';
import { NumpadAction } from 'packages/sports/web/app/src/numpad/model';

import BetslipActions from '../../base/store/actions';
import { IBetslipState } from '../../base/store/state';
import { BetslipPick } from '../../core/picks/betslip-pick';
import { PickId, V1PickId, V2OptionMarketPickId, V2OptionMarketXCastPickId, V2ParticipantPickId } from '../../core/picks/pick-id';
import { ResultStatus } from '../../core/picks/pick-models';
import { EditMyBetPicksPayload, IEditMyBetInitPayload } from '../../model/edit-mybet/edit-bet-init';
import { IBetPicks } from '../betplacement/models';
import { IEditMyBetPickState } from './state';

export interface IFillEditMyBetPayload {
    pickState: { [pos: number]: { pick: BetslipPick; state: IEditMyBetPickState } };
    sourceBetslip: MyBetsBetSlip;
}

export type IEditBetUpdatedEarlyPayout = IEarlyPayoutData & IEarlyPayoutResultedPicks;

export interface IEditBetUpdatedPayload {
    earlyPayout: Nullable<IEditBetUpdatedEarlyPayout>;
    v1FixtureMessages: MessageEnvelope[]; // We can combine them in one collection at some point of time.
    v2FixtureMessages: MessageEnvelope[];
}

export interface IPlaceBetPayload {
    requestId: string;
}

export interface IPlaceBetResponsePayload {
    guid: string;
    requestPicks: IBetPicks;
    state: IBetslipState;
    timeout: Date;
    placeBetRequest: PlaceBetRequest;
}

export interface IEditBetPickResultsPayload {
    pickId: PickId;
    newState: ResultStatus;
    oldState: ResultStatus;
}

export interface ISwapPickPayload {
    oldPick: BetslipPick;
    newPick: BetslipPick;
    highlightNewPick: boolean;
}

export class EditBetActions {
    static readonly ACTION_SCHEMA = `${BetslipActions.ACTION_SCHEMA}/edit-bet`;

    private static readonly INIT = `${EditBetActions.ACTION_SCHEMA}/INIT`;
    private static readonly SET_DEFAULT = `${EditBetActions.ACTION_SCHEMA}/SET_DEFAULT`;
    private static readonly FILL = `${EditBetActions.ACTION_SCHEMA}/FILL`;
    private static readonly SWAP_EDIT_BET_PICK = `${EditBetActions.ACTION_SCHEMA}/SWAP_EDIT_BET_PICK`;

    private static readonly START_ADDING_STAKE = `${EditBetActions.ACTION_SCHEMA}/START_ADDING_STAKE`;
    private static readonly CONFIRM_ADDING_STAKE = `${EditBetActions.ACTION_SCHEMA}/CONFIRM_ADDING_STAKE`;
    private static readonly CANCEL_ADDING_STAKE = `${EditBetActions.ACTION_SCHEMA}/CANCEL_ADDING_STAKE`;
    private static readonly SET_ADDED_STAKE = `${EditBetActions.ACTION_SCHEMA}/SET_ADDED_STAKE`;

    private static readonly DEPOSIT = `${EditBetActions.ACTION_SCHEMA}/DEPOSIT`;
    private static readonly MAKE_DEPOSIT = `${EditBetActions.ACTION_SCHEMA}/MAKE_DEPOSIT`;

    private static readonly ADD_PICK = `${EditBetActions.ACTION_SCHEMA}/ADD_PICK`;
    private static readonly REMOVE_PICK = `${EditBetActions.ACTION_SCHEMA}/REMOVE_PICK`;
    private static readonly DELETE_PICK = `${EditBetActions.ACTION_SCHEMA}/DELETE_PICK`;
    private static readonly UNDO_REMOVE_PICK = `${EditBetActions.ACTION_SCHEMA}/UNDO_REMOVE_PICK`;
    private static readonly START_UNDOING_REMOVE_PICK = `${EditBetActions.ACTION_SCHEMA}/START_UNDOING_REMOVE_PICK`;
    private static readonly REQUEST_DISCARD = `${EditBetActions.ACTION_SCHEMA}/REQUEST_DISCARD`;
    private static readonly CANCEL_DISCARD_REQUEST = `${EditBetActions.ACTION_SCHEMA}/CANCEL_DISCARD_REQUEST`;
    private static readonly EXIT_EDIT_BET = `${EditBetActions.ACTION_SCHEMA}/EXIT_EDIT_BET`;
    private static readonly SWITCH_TO_ADD_SELECTION_MODE = `${EditBetActions.ACTION_SCHEMA}/SWITCH_TO_ADD_SELECTION_MODE`;
    private static readonly TOGGLE_PICKS_CONTAINER = `${EditBetActions.ACTION_SCHEMA}/TOGGLE_PICKS_CONTAINER`;
    private static readonly CANCEL_ADD_SELECTION_MODE = `${EditBetActions.ACTION_SCHEMA}/CANCEL_ADD_SELECTION_MODE`;
    private static readonly CONFIRM_ADD_SELECTION_MODE = `${EditBetActions.ACTION_SCHEMA}/CONFIRM_ADD_SELECTION_MODE`;
    private static readonly START_ADDING_PICK_TO_CONTAINER = `${EditBetActions.ACTION_SCHEMA}/START_ADDING_PICK_TO_CONTAINER`;
    private static readonly ADD_PICK_TO_CONTAINER = `${EditBetActions.ACTION_SCHEMA}/ADD_PICK_TO_CONTAINER`;
    private static readonly REMOVE_PICK_FROM_CONTAINER = `${EditBetActions.ACTION_SCHEMA}/REMOVE_PICK_FROM_CONTAINER`;
    private static readonly SET_FIXED_PRICE = `${EditBetActions.ACTION_SCHEMA}/SET_FIXED_PRICE`;
    private static readonly SET_STARTING_PRICE = `${EditBetActions.ACTION_SCHEMA}/SET_STARTING_PRICE`;
    private static readonly SWITCH_TO_SWAP_SELECTION_MODE = `${EditBetActions.ACTION_SCHEMA}/SWITCH_TO_SWAP_SELECTION_MODE`;
    private static readonly START_ADDING_PICK_TO_SWAP = `${EditBetActions.ACTION_SCHEMA}/START_ADDING_PICK_TO_SWAP`;
    private static readonly ADD_PICK_TO_SWAP = `${EditBetActions.ACTION_SCHEMA}/ADD_PICK_TO_SWAP`;
    private static readonly CANCEL_SWAP_SELECTION_MODE = `${EditBetActions.ACTION_SCHEMA}/CANCEL_SWAP_SELECTION_MODE`;
    private static readonly UPDATE_PICK_TO_SWAP = `${EditBetActions.ACTION_SCHEMA}/UPDATE_PICK_TO_SWAP`;
    private static readonly HANDLE_SWAP_ERROR = `${EditBetActions.ACTION_SCHEMA}/HANDLE_SWAP_ERROR`;
    private static readonly PLACE_BET = `${EditBetActions.ACTION_SCHEMA}/PLACE_BET`;
    private static readonly EARLY_PAYOUT_UPDATED = `${EditBetActions.ACTION_SCHEMA}/EARLY_PAYOUT_UPDATED`;
    private static readonly EDIT_BET_UPDATED = `${EditBetActions.ACTION_SCHEMA}/EDIT_BET_UPDATED`;
    private static readonly GET_PLACE_BET_STATUS = `${EditBetActions.ACTION_SCHEMA}/GET_PLACE_BET_STATUS`;
    private static readonly ACCEPT_CURRENT_EARLY_PAYOUT_VALUE = `${EditBetActions.ACTION_SCHEMA}/ACCEPT_CURRENT_EARLY_PAYOUT_VALUE`;
    private static readonly DISABLE_EARLY_PAYOUT = `${EditBetActions.ACTION_SCHEMA}/DISABLE_EARLY_PAYOUT`;
    private static readonly APPLY_EDIT_BET_PICK_RESULTS = `${EditBetActions.ACTION_SCHEMA}/APPLY_EDIT_BET_PICK_RESULTS`;
    private static readonly START_EDIT_MY_BET = `${EditBetActions.ACTION_SCHEMA}/START_EDIT_MY_BET`;
    private static readonly SUBSCRIBE_TO_EARLY_PAYOUT = `${EditBetActions.ACTION_SCHEMA}/SUBSCRIBE_TO_EARLY_PAYOUT`;
    private static readonly UNSUBSCRIBE_FROM_EARLY_PAYOUT = `${EditBetActions.ACTION_SCHEMA}/UNSUBSCRIBE_FROM_EARLY_PAYOUT`;

    /**
     * Init Edit Bet
     */
    static init = createAction(EditBetActions.INIT, pprops<IEditMyBetInitPayload>());

    /**
     * Add new pick to the slip.
     */
    static addPick = createAction(EditBetActions.ADD_PICK, props<{ pick: BetslipPick }>());

    /**
     * Fill Edit bet with data
     */
    static fillEditMyBet = createAction(EditBetActions.FILL, pprops<IFillEditMyBetPayload>());

    /**
     * Swap a pick in edit bet.
     */
    static swapPick = createAction(EditBetActions.SWAP_EDIT_BET_PICK, pprops<ISwapPickPayload>());

    /**
     * Start adding top-up stake. Shows the Stake keypad.
     */
    static startAddingStake = createAction(EditBetActions.START_ADDING_STAKE);

    /**
     * Notify action when user stopped changing top-up stake. On numpad OK button click.
     * null is canceling of adding stake.
     */
    static confirmAddingStake = createAction(EditBetActions.CONFIRM_ADDING_STAKE, props<{ stake: number }>());

    /**
     * Close stake numpad - do nothing.
     */
    static cancelAddingStake = createAction(EditBetActions.CANCEL_ADDING_STAKE);

    /**
     * Start adding funds through quick-deposit
     */
    static deposit = createAction(EditBetActions.DEPOSIT);

    /**
     * Set current entered stake.
     */
    static setEditMyBetAddedStake = createAction(EditBetActions.SET_ADDED_STAKE, pprops<{ stake: number; action?: NumpadAction }>());

    /**
     * Ask for confirmation to leave EMB and redirect to deposit page or open quickDeposit
     */
    static makeDeposit = createAction(EditBetActions.MAKE_DEPOSIT);

    /**
     * Removes pick from edit bet (marks it as not used)
     */
    static removePick = createAction(EditBetActions.REMOVE_PICK, props<{ pickId: string }>());

    /**
     * Deletes pick from edit bet (marks it as not used)
     */
    static deletePick = createAction(EditBetActions.DELETE_PICK, props<{ pickId: PickId }>());

    /**
     * Start process of Undo remove pick. Checks if the betslip is not full.
     */
    static startUndoingRemovePick = createAction(EditBetActions.START_UNDOING_REMOVE_PICK, props<{ pickId: string }>());

    /**
     * Undo remove (enable back) the pick in edit bet.
     */
    static undoRemovePick = createAction(EditBetActions.UNDO_REMOVE_PICK, props<{ pickId: string }>());

    /**
     * User tries to exit edit bet (Clicks on Cancel button).
     */
    static requestDiscard = createAction(EditBetActions.REQUEST_DISCARD);

    /**
     * User cancels his/her request to edit bet. Decides to continue editing
     */
    static cancelDiscardRequest = createAction(EditBetActions.CANCEL_DISCARD_REQUEST);

    /**
     * User exits edit bet mode.
     */
    static exitEditBet = createAction(EditBetActions.EXIT_EDIT_BET);

    /**
     * Set default edit bet
     */
    static setDefault = createAction(EditBetActions.SET_DEFAULT);

    /**
     * User start adding picks to edit bet.
     */
    static switchToAddSelectionMode = createAction(EditBetActions.SWITCH_TO_ADD_SELECTION_MODE);

    /**
     * Toggles the visibility of edit bet picks staging area. Show/Hides the pick list.
     */
    static togglePicksContainer = createAction(EditBetActions.TOGGLE_PICKS_CONTAINER);

    // /**
    //  * Cancel adding new picks to edit bet.
    //  */
    // static cancelAddSelectionMode = createAction(EditBetActions.CANCEL_ADD_SELECTION_MODE, pprops<IPickState>());
    /**
     * Cancel adding new picks to edit bet.
     */
    static cancelAddSelectionMode = createAction(EditBetActions.CANCEL_ADD_SELECTION_MODE, pprops<BetslipPick[]>());

    /**
     * Adds the staging picks to edit bet.
     */
    static confirmAddSelectionMode = createAction(EditBetActions.CONFIRM_ADD_SELECTION_MODE, pprops<{ picks: BetslipPick[] }>());

    /**
     * Start adding pick to staging area. Creates request to BCD to resolve pick data.
     */
    static startAddingPickToContainer = createAction(
        EditBetActions.START_ADDING_PICK_TO_CONTAINER,
        props<{ pickId: V1PickId | V2OptionMarketPickId | V2ParticipantPickId | V2OptionMarketXCastPickId }>(),
    );

    /**
     * Add pick to edit bet staging area. After we have bcd data.
     */
    static addPickToContainer = createAction(EditBetActions.ADD_PICK_TO_CONTAINER, props<{ pick: BetslipPick }>());

    /**
     * Remove pick from staging area.
     */
    static removeFromContainer = createAction(EditBetActions.REMOVE_PICK_FROM_CONTAINER, props<{ pickId: string }>());

    /**
     * Set fixed price for pick
     */
    static setFixedPrice = createAction(EditBetActions.SET_FIXED_PRICE, props<{ pickId: PickId }>());

    /**
     * Set starting price for pick
     */
    static setStartingPrice = createAction(EditBetActions.SET_STARTING_PRICE, props<{ pickId: PickId }>());

    /**
     * Start swapping pick. Shows swap pick dialog.
     */
    static switchToSwapSelectionMode = createAction(EditBetActions.SWITCH_TO_SWAP_SELECTION_MODE, props<{ pick: BetslipPick }>());

    /**
     * Cancel swapping pick. Closes the swap pick dialog.
     */
    static cancelSwapSelectionMode = createAction(EditBetActions.CANCEL_SWAP_SELECTION_MODE);

    /**
     * Select pick to swap. Creates request to BCD to resolve pick data.
     */
    static startAddingPickToSwap = createAction(EditBetActions.START_ADDING_PICK_TO_SWAP, props<{ pick: BetslipPick }>());

    /**
     * Swap temporary pick.
     */
    static addPickToSwap = createAction(EditBetActions.ADD_PICK_TO_SWAP, props<{ pick: BetslipPick }>());

    static updatePickToSwap = createAction(EditBetActions.UPDATE_PICK_TO_SWAP, props<{ pick: BetslipPick }>());

    static handleSwapError = createAction(EditBetActions.HANDLE_SWAP_ERROR, props<{ error: string; pickId?: PickId }>());

    /**
     * Place edit bet.
     */
    static placeBet = createAction(EditBetActions.PLACE_BET, pprops<IPlaceBetPayload>());

    /**
     * Get current place edit bet status.
     */
    static getPlaceBetStatus = createAction(EditBetActions.GET_PLACE_BET_STATUS, pprops<IPlaceBetResponsePayload>());

    /**
     * Action fired when Early payout is updated. Has push message regarding Early payout
     */
    static earlyPayoutUpdated = createAction(EditBetActions.EARLY_PAYOUT_UPDATED, pprops<Nullable<IEditBetUpdatedEarlyPayout>>());

    /**
     * Action that modifies the Edit bet (Changes Odds, Pick visibility, Early payout value)
     */
    static editBetUpdated = createAction(EditBetActions.EDIT_BET_UPDATED, pprops<IEditBetUpdatedPayload>());

    /**
     * Accept current early payout value
     */
    static acceptCurrentEarlyPayoutValue = createAction(EditBetActions.ACCEPT_CURRENT_EARLY_PAYOUT_VALUE);

    /**
     * Disable early payout because of error
     */
    static disableEarlyPayout = createAction(EditBetActions.DISABLE_EARLY_PAYOUT);

    static applyEditBetPickResults = createAction(EditBetActions.APPLY_EDIT_BET_PICK_RESULTS, pprops<IEditBetPickResultsPayload>());

    /**
     * Subscribe to Early Payout
     */
    static subscribeToEarlyPayout = createAction(EditBetActions.SUBSCRIBE_TO_EARLY_PAYOUT);

    /**
     * Unsubscribe from Early payout.
     */
    static unsubscribeFromEarlyPayout = createAction(EditBetActions.UNSUBSCRIBE_FROM_EARLY_PAYOUT);

    /**
     * Action called when bet editing is started
     */
    static startEditMyBet = createAction(EditBetActions.START_EDIT_MY_BET, props<{ payload: EditMyBetPicksPayload }>());
}

// @ts-ignore
window.editBetAction = EditBetActions;
