import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { DisplayType } from '@cds/betting-offer';
import { BetBuilderOptionGroupInfo } from '@cds/betting-offer/domain-specific/bet-builder';
import { OfferGroup } from '@cds/betting-offer/grouping/fixture-view';
import { clone, findIndex, isEqual, sortBy, toNumber, uniq, uniqWith } from 'lodash-es';

import { AngstromTagIdValues } from '../../event-details-sitemap/event-details-sitemap.models';
import {
    EnhancedFixtureViewGroupingConfiguration,
    EventOptionGroup,
    EventOptionGroupType,
    EventOptionSet,
    ExtendedDisplayType,
} from '../model/event.model';
import { Tv2MarketParameters } from '../model/tv2.model';

interface GroupingFactory {
    canProcess(source: OfferSource | undefined): boolean;
    getMarketGroupsConfig(grouping: EnhancedFixtureViewGroupingConfiguration): OfferGroup[];
}
export enum PreCreatedBuildABet {
    PreCreated = 'BAB',
}
class GameMarketGroupingFactory implements GroupingFactory {
    canProcess(source: OfferSource | undefined): boolean {
        return source === OfferSource.V1;
    }

    getMarketGroupsConfig(grouping: EnhancedFixtureViewGroupingConfiguration): OfferGroup[] {
        return grouping.marketGroups;
    }
}

class OptionMarketGroupingFactory implements GroupingFactory {
    canProcess(source: OfferSource | undefined): boolean {
        return source === OfferSource.V2;
    }

    getMarketGroupsConfig(grouping: EnhancedFixtureViewGroupingConfiguration): OfferGroup[] {
        return grouping.marketGroups;
    }
}

@Injectable({ providedIn: 'root' })
export class DetailedGroupingFactory {
    private factories = [new GameMarketGroupingFactory(), new OptionMarketGroupingFactory()];

    getOptionSets(
        optionGroups: EventOptionGroup[],
        grouping: EnhancedFixtureViewGroupingConfiguration,
        source: OfferSource | undefined,
        betBuilderId?: string | null,
    ): EventOptionSet[] {
        const optionSets: EventOptionSet[] = [];
        const marketGroups: OfferGroup[] = this.getMarketGroupsConfig(grouping, source);
        const isSgpGroup = (group: OfferGroup) => group.tag?.key === AngstromTagIdValues.Sgp;

        marketGroups.forEach((marketGroup) => {
            const selectedOptionGroups = optionGroups
                .filter((optionGroup) => optionGroup.detailedGrouping.marketSet === marketGroup.id)
                .filter((optionGroup) => (isSgpGroup(marketGroup) ? optionGroup.isBetBuilder : true));

            if (!selectedOptionGroups.length) {
                return;
            }

            const availableTags = selectedOptionGroups.flatMap((gr) => gr.detailedGrouping.tags || []);
            const tags = availableTags ? uniqWith(availableTags, isEqual) : [];
            optionSets.push({
                id: marketGroup.id,
                name: isSgpGroup(marketGroup) ? marketGroup.tag?.value ?? marketGroup.name : marketGroup.name,
                count: selectedOptionGroups.length,
                storageId: marketGroup.groupId,
                tags,
                marketGroupTag: marketGroup.tag,
                isPrecreated: selectedOptionGroups.every((og) => og.detailedGrouping?.displayType === ExtendedDisplayType.BetBuilder),
            });
        });

        if (!betBuilderId) {
            const sgpIndex = findIndex(optionSets, (e) => e.marketGroupTag?.key === AngstromTagIdValues.Sgp && e.name === e.marketGroupTag?.value);
            if (sgpIndex !== -1) {
                optionSets.splice(sgpIndex, 1);
            }
        }

        return this.fakeMarketGroupingIfNotFound(optionGroups, optionSets);
    }

    groupMarkets(optionGroups: EventOptionGroup[], restrictPeriodGrouping?: boolean, restrictSorting?: boolean): EventOptionGroup[] {
        const groupedMarkets: EventOptionGroup[] = [];

        const preSortedOptionGroups: EventOptionGroup[] = [];
        if (!restrictSorting) {
            preSortedOptionGroups.push(...this.sortMarkets(optionGroups));
        } else {
            preSortedOptionGroups.push(...optionGroups);
        }

        const marketSetIds = uniq(preSortedOptionGroups.map((optionGroup) => optionGroup.detailedGrouping.marketSet));
        marketSetIds.forEach((setId) => {
            const marketGroupMarkets = preSortedOptionGroups.filter((optionGroup) => optionGroup.detailedGrouping.marketSet === setId);
            const groupedMarketGroupMarkets: EventOptionGroup[] = [];

            groupedMarketGroupMarkets.push(
                ...marketGroupMarkets.filter(
                    (market) => market.detailedGrouping.marketGroup === undefined && market.detailedGrouping.periodGroup === undefined,
                ),
            );
            if (restrictPeriodGrouping) {
                groupedMarketGroupMarkets.push(
                    ...marketGroupMarkets.filter(
                        (market) => market.detailedGrouping.marketGroup === undefined && market.detailedGrouping.periodGroup !== undefined,
                    ),
                );
            } else {
                groupedMarketGroupMarkets.push(
                    ...this.groupMarketsByPeriods(
                        marketGroupMarkets.filter(
                            (market) => market.detailedGrouping.marketGroup === undefined && market.detailedGrouping.periodGroup !== undefined,
                        ),
                    ),
                );
            }
            groupedMarketGroupMarkets.push(
                ...this.groupMarketsBySubGroups(marketGroupMarkets.filter((market) => market.detailedGrouping.marketGroup !== undefined)),
            );
            if (!restrictSorting) {
                groupedMarkets.push(...this.sortMarkets(groupedMarketGroupMarkets));
            } else {
                groupedMarkets.push(...groupedMarketGroupMarkets);
            }
        });

        return groupedMarkets;
    }

    transformEventGroupsPerPrecreatedGroup(
        optionGroups: EventOptionGroup[],
        precreatedOptionGroups: BetBuilderOptionGroupInfo[],
    ): EventOptionGroup[] {
        const transformedOptionGroups: EventOptionGroup[] = [];
        const marketSetIds = uniq(optionGroups.map((optionGroup) => optionGroup.detailedGrouping.marketSet));
        marketSetIds.forEach((setId) => {
            const optionGroupsBySetId = optionGroups.filter((optionGroup) => optionGroup.detailedGrouping.marketSet === setId);

            // we are transforming the event groups so that we end up with one event group per precreated group, with several options in it
            precreatedOptionGroups.forEach((precreatedGroup) => {
                const eventOptionGroups = optionGroupsBySetId.filter((market) =>
                    precreatedGroup.options.some((o) => o.marketId.toString() === market.id),
                );

                if (eventOptionGroups.length > 0) {
                    const newGroupWithAllOptions = this.groupOptions(eventOptionGroups);
                    newGroupWithAllOptions.id = precreatedGroup.groupId;
                    transformedOptionGroups.push(newGroupWithAllOptions);
                }
            });
        });

        return transformedOptionGroups;
    }

    createSubGroup(markets: EventOptionGroup[]): EventOptionGroup {
        const subGroupMarkets = this.sortSubGroupMarkets(markets);

        return this.groupOptions(subGroupMarkets);
    }

    private fakeMarketGroupingIfNotFound(optionGroups: EventOptionGroup[], optionSets: EventOptionSet[]): EventOptionSet[] {
        if (!optionSets.length && optionGroups.length) {
            optionGroups.forEach((g) => (g.detailedGrouping.marketSet = 10000));

            optionSets.push({
                id: 10000,
                name: 'All',
                count: optionGroups.length,
                storageId: '',
            });
        }

        return optionSets;
    }

    private groupMarketsBySubGroups(markets: EventOptionGroup[]): EventOptionGroup[] {
        const groupedMarkets: EventOptionGroup[] = [];
        const subGroupIds = uniq(markets.map((market) => this.getSubGroupId(market)));

        subGroupIds.forEach((subGroupId) => {
            const subGroupMarkets = markets.filter((market) => this.getSubGroupId(market) === subGroupId);
            const periodOptionGroup = this.getPeriodOptionGroup(subGroupMarkets);
            if (periodOptionGroup) {
                groupedMarkets.push(periodOptionGroup);
            } else {
                groupedMarkets.push(this.createSubGroup(subGroupMarkets));
            }
        });

        return groupedMarkets;
    }

    private getSubGroupId(market: EventOptionGroup): string | number {
        if (market.subPeriodNumber) {
            return `${market.detailedGrouping.marketOrder}sub-period${market.subPeriodNumber}`;
        }

        if (market.participantIds) {
            if (market.detailedGrouping.displayType === DisplayType.SixPack) {
                return `${market.parameters?.find((p) => p.key === Tv2MarketParameters.Happening)?.value}${market.detailedGrouping.displayType}${market.participantIds}`;
            }

            return `${market.detailedGrouping.marketOrder}${market.participantIds}`;
        }

        return market.detailedGrouping.marketOrder;
    }

    protected getPeriodOptionGroup(markets: EventOptionGroup[]): EventOptionGroup | undefined {
        if (!markets) {
            return;
        }
        const periodMarkets = this.createEventOptionGroupByPeriodIdDictionary(markets);
        const periodKeys = sortBy(
            Object.keys(periodMarkets).map((x) => {
                return toNumber(x);
            }),
        );

        const periodGroupedMarkets: EventOptionGroup[] = [];
        if (periodKeys.length > 1) {
            periodKeys.forEach((k) => {
                periodGroupedMarkets.push(this.createSubGroup(periodMarkets[k]));
            });

            return this.createPeriodOptionGroup(periodGroupedMarkets[0], periodGroupedMarkets);
        } else if (periodKeys.length === 1) {
            periodGroupedMarkets[0] = this.groupOptions(periodMarkets[periodKeys[0]]);

            return this.singlePeriodOptionGroup(periodGroupedMarkets[0]);
        }

        return;
    }

    protected createEventOptionGroupByPeriodIdDictionary(optionGroups: EventOptionGroup[]): Record<number, EventOptionGroup[]> {
        const res: EventOptionGroup[][] = [];
        for (const optionGroup of optionGroups) {
            if (typeof optionGroup.detailedGrouping.periodGroup === 'undefined') {
                continue;
            }
            res[optionGroup.detailedGrouping.periodGroup] = res[optionGroup.detailedGrouping.periodGroup] || [];
            res[optionGroup.detailedGrouping.periodGroup].push(optionGroup);
        }

        return res;
    }

    private createEventOptionGroupByMarketOrderIdWithPeriodDictionary(optionGroups: EventOptionGroup[]): EventOptionGroup[][] {
        const res: EventOptionGroup[][] = [];
        for (const optionGroup of optionGroups) {
            if (typeof optionGroup.detailedGrouping.marketOrder === 'undefined' || typeof optionGroup.detailedGrouping.periodGroup === 'undefined') {
                continue;
            }
            res[optionGroup.detailedGrouping.marketOrder] = res[optionGroup.detailedGrouping.marketOrder] || [];
            res[optionGroup.detailedGrouping.marketOrder].push(optionGroup);
        }

        return res;
    }

    private groupMarketsByPeriods(markets: EventOptionGroup[]): EventOptionGroup[] {
        const groupedMarkets: EventOptionGroup[] = [];

        const marketOrderMarkets = this.createEventOptionGroupByMarketOrderIdWithPeriodDictionary(markets);

        marketOrderMarkets.forEach((k) => {
            const periodOptionGroup = this.getPeriodOptionGroup(k);
            if (periodOptionGroup) {
                groupedMarkets.push(periodOptionGroup);
            } else {
                if (k[0]) {
                    groupedMarkets.push(k[0]);
                }
            }
        });

        return groupedMarkets;
    }

    protected singlePeriodOptionGroup(periodGroup: EventOptionGroup): EventOptionGroup {
        periodGroup.id = this.getGroupId(periodGroup.detailedGrouping.displayType, periodGroup.id);
        periodGroup.name = this.getOptionGroupName(periodGroup);

        return periodGroup;
    }

    protected createPeriodOptionGroup(root: EventOptionGroup, subGroups: EventOptionGroup[]): EventOptionGroup {
        const periodGroup = clone(root);
        periodGroup.id = this.getGroupId(periodGroup.detailedGrouping.displayType, periodGroup.id);
        periodGroup.options = [];
        subGroups.forEach((s) => (s.type = EventOptionGroupType.Period));
        periodGroup.subGroups = subGroups;
        periodGroup.name = this.getOptionGroupName(periodGroup);

        if (
            periodGroup.detailedGrouping.displayType === DisplayType.PlayerMilestone ||
            periodGroup.detailedGrouping.displayType === DisplayType.PlayerMilestoneOverUnder
        ) {
            periodGroup.online = subGroups.some((x) => x.online);
            periodGroup.visible = subGroups.some((x) => x.visible);
        }

        return periodGroup;
    }

    protected groupOptions(markets: EventOptionGroup[]): EventOptionGroup {
        const firstMarket = markets.shift()!;
        const optionGroup = Object.assign(new EventOptionGroup(), { ...firstMarket, options: [...firstMarket.options] });
        optionGroup.id = this.getGroupId(optionGroup.detailedGrouping.displayType, optionGroup.id, 'group');
        optionGroup.originalId = firstMarket.id;

        if (!markets.length) {
            if (this.isPlayer_OptionGroup(optionGroup)) {
                optionGroup.name = this.getOptionGroupName(optionGroup);
            }

            return optionGroup;
        }

        optionGroup.groupedMarkets = markets.map((m) => m.id);

        markets.forEach((market) => {
            optionGroup.options.push(...market.options);
        });

        optionGroup.online = optionGroup.options.some((group) => group.online);
        optionGroup.name = this.getOptionGroupName(optionGroup);

        return optionGroup;
    }

    private sortSubGroupMarkets(markets: EventOptionGroup[]): EventOptionGroup[] {
        return markets.sort((first, second) => first.detailedGrouping.marketGroup! - second.detailedGrouping.marketGroup!);
    }

    private sortMarkets(markets: EventOptionGroup[]): EventOptionGroup[] {
        return markets
            .sort((first, second) => +first.id.slice(2) - +second.id.slice(2))
            .sort((first, second) => first.detailedGrouping.marketOrder - second.detailedGrouping.marketOrder);
    }

    private getMarketGroupsConfig(grouping: EnhancedFixtureViewGroupingConfiguration, source: OfferSource | undefined): OfferGroup[] {
        for (const factory of this.factories) {
            if (factory.canProcess(source)) {
                return factory.getMarketGroupsConfig(grouping);
            }
        }

        throw new Error('No grouping factory found for grouping configuration');
    }

    private getOptionGroupName(optionGroup: EventOptionGroup): string {
        if (optionGroup.detailedGrouping.periodGroup !== undefined && !optionGroup.subGroups) {
            switch (optionGroup.detailedGrouping.displayType) {
                case DisplayType.RaceTo:
                case DisplayType.BTTS:
                case DisplayType.Outrights:
                case DisplayType.TotalScore:
                case DisplayType.YesNo:
                case DisplayType.Spread:
                    return optionGroup.detailedGrouping.name || optionGroup.name;
                case DisplayType.Regular:
                case DisplayType.CorrectScore:
                case DisplayType.OverUnder:
                    return optionGroup.name;
                case DisplayType.PlayerProps:
                case DisplayType.SixPack:
                    return `${optionGroup.detailedGrouping.name || optionGroup.name} - ${optionGroup.periodName}`;
                case DisplayType.GoalScorer:
                default:
                    return optionGroup.detailedGrouping.name || optionGroup.name;
            }
        }
        if (optionGroup.detailedGrouping.name === PreCreatedBuildABet.PreCreated) {
            return optionGroup.name || optionGroup.detailedGrouping.name;
        } else {
            return optionGroup.detailedGrouping.name || optionGroup.name;
        }
    }

    private getGroupId(displayType: DisplayType | ExtendedDisplayType, groupId: string, groupType: string = 'period'): string {
        return `${groupType}-${displayType}-${groupId}`;
    }

    private isPlayer_OptionGroup(optionGroup: EventOptionGroup): boolean {
        const displayType = optionGroup.detailedGrouping.displayType;

        return (
            displayType === DisplayType.PlayerMilestone ||
            displayType === DisplayType.PlayerCombined ||
            displayType === DisplayType.PlayerStats ||
            displayType === DisplayType.PlayerMilestoneOverUnder
        );
    }
}
