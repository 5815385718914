import { Injectable } from '@angular/core';

import { FixturePage, FixtureType, OfferCategory } from '@cds/betting-offer';
import { CountsFixturesResponse } from '@cds/betting-offer/domain-specific';
import { TagType } from '@cds/betting-offer/tags';
import { CountsFixturesRequest, FixtureState, OfferMapping, SortByCriteria } from '@cds/query-objects';
import { toInteger } from 'lodash-es';
import { Observable, of } from 'rxjs';

import { BettingOfferApi } from '../cds/betting-offer-api.service';
import { Tile } from '../highlights-marquee/marquee-tile.model';
import { MarqueeService } from '../highlights-marquee/marquee.service';
import { StatisticsConfigService } from '../statistics/statistics-config.service';
import { FavouriteType, FixtureTab, Team } from './team-pages.model';

@Injectable({ providedIn: 'root' })
export class TeamPagesCoreMarqueeService {
    constructor(
        private bettingOffer: BettingOfferApi,
        private marqueeService: MarqueeService,
        private statisticsConfigService: StatisticsConfigService,
    ) {}

    getFixturesMarquees(selectedTeam: Team, marqueeFixtures?: string[]): Observable<Tile[]> {
        if (!selectedTeam) {
            return of([]);
        }
        if (marqueeFixtures?.length) {
            return this.loadFixtureMarquees(marqueeFixtures);
        }

        return of([]);
    }

    private loadFixtureMarquees(fixtureIds: string[]): Observable<Tile[]> {
        return this.marqueeService.loadTeamPagesMarquees(fixtureIds);
    }

    getFixtures(itemId: number, sportId: number, tabId: number): Observable<FixturePage | undefined> {
        const request: CountsFixturesRequest = this.buildCountsFixtureRequest(itemId.toString(), sportId.toString(), tabId);

        return this.bettingOffer.getFixtureList(request.fixtureRequest);
    }

    getFixtureCounts(itemId: number, sportId: number): Observable<CountsFixturesResponse | undefined> {
        const request: CountsFixturesRequest = this.buildCountsFixtureRequest(itemId.toString(), sportId.toString());

        return this.bettingOffer.getCountsFixtures(request);
    }

    buildCountsFixtureRequest(
        itemId: string,
        sportId: string,
        tabId?: number,
        favouriteType: FavouriteType = FavouriteType.Participant,
        take?: number,
        skip?: number,
    ): CountsFixturesRequest {
        const request: CountsFixturesRequest = {
            fixtureCountsRequest: {
                state: FixtureState.Latest,
                tagTypes: [TagType.Sport, TagType.Region, TagType.Tournament, TagType.Competition].join(','),
                extendedTags: [TagType.Sport, TagType.Region, TagType.Tournament, TagType.Competition].join(','),
                sortBy: SortByCriteria.Tags,
                sportIds: sportId,
            },
            fixtureRequest: {
                sportIds: sportId,
                excludeLocationFilter: true,
                fixtureCategories: this.getFixtureCategories(tabId),
                fixtureTypes: FixtureType.Standard,
                offerCategories: this.getOfferCategories(tabId),
                offerMapping: OfferMapping.Filtered,
                skip,
                sortBy: this.getSorting(tabId),
                state: FixtureState.Latest,
                take,
                statisticsModes: this.statisticsConfigService.getStatisticsModesBySportId(toInteger(sportId)),
            },
        };
        if (favouriteType === FavouriteType.League) {
            request.fixtureRequest.competitionIds = itemId;
            request.fixtureCountsRequest.competitionIds = itemId;
        }

        if (favouriteType === FavouriteType.Participant || favouriteType === FavouriteType.ParticipantV2) {
            request.fixtureRequest.fixtureParticipantIds = itemId;
            request.fixtureCountsRequest.fixtureParticipantIds = itemId;
        }

        return request;
    }

    private getSorting(tabId?: number): SortByCriteria {
        return tabId && (tabId === FixtureTab.Outrights || tabId === FixtureTab.Specials)
            ? SortByCriteria.QueryCompetitionIds
            : SortByCriteria.StartDate;
    }

    private getOfferCategories(tabId?: number): OfferCategory {
        if (tabId === FixtureTab.Outrights) {
            return OfferCategory.Outrights;
        }
        if (tabId === FixtureTab.Specials) {
            return OfferCategory.Specials;
        }

        return OfferCategory.Gridable;
    }

    private getFixtureCategories(tabId?: number): string {
        if (tabId === FixtureTab.Outrights) {
            return OfferCategory.Outrights;
        }
        if (tabId === FixtureTab.Specials) {
            return OfferCategory.Specials;
        }

        return [OfferCategory.Gridable, OfferCategory.NonGridable, OfferCategory.Other].join(',');
    }
}
