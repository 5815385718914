import { BetSlipType } from '@bpos';
import { GameType } from '@bpos/v1/sports-promo';
import { ReplaceableParameter } from '@bpos/v1/sports-promo/campaigns';
import { RewardTargetType } from '@bpos/v1/sports-promo/tokens';
import { StringDictionary } from '@frontend/sports/common/base-utils';

import { DayOfWeek as DayOfWeekEnum, TimeOfDay as TimeOfDayFilter } from '../common/date-time.models';

export enum CampaignSlipType {
    Single = 'Single',
    Combo = 'Combo',
    System = 'System',
}

export enum PayoutType {
    Bonus = 'Bonus',
    BonusNoWager = 'BonusNoWager',
    FreeBet = 'FreeBet',
    Cash = 'Cash',
    OddsBoostToken = 'OddsBoostToken',
    RiskFreeToken = 'RiskFreeToken',
}

export enum SignPostPayoutType {
    Bonus = 'BONUS',
    BonusNoWager = 'BONUSNOWAGER',
    FreeBet = 'FREEBET',
    Cash = 'CASH',
    OddsBoostToken = 'ODDS_BOOST',
    RiskFreeToken = 'RISK_FREE_BET',
}

export enum RewardTokenImages {
    RewardTokenBlackReceived = 'RewardTokenBlackReceived',
    RewardTokenTimer = 'RewardTokenTimer',
    RewardTokenTimerDark = 'RewardTokenTimerDark',
    FreeBet = 'FreeBet',
    RiskFreeBet = 'RiskFreeBet',
    OddsBoost = 'OddsBoost',
    BetAndGet = 'BetAndGet',
    FreeBetDark = 'FreeBetDark',
    RiskFreeBetDark = 'RiskFreeBetDark',
    OddsBoostDark = 'OddsBoostDark',
    BetAndGetDark = 'BetAndGetDark',
    RewardTokenTimerThemeDark = 'RewardTokenTimerThemeDark',
    RewardTokenTimerBlack = 'RewardTokenTimerBlack',
}

export enum RewardTriggerType {
    BetPlacement = 'BetPlacement',
    BetSettlement = 'BetSettlement',
}

export enum BuildABet {
    None = 'None',
    Any = 'Any',
    Yes = 'Yes',
    No = 'No',
}

export enum BetStatus {
    Any = 'Any',
    Win = 'Win',
    Loss = 'Loss',
}

export enum RewardTokenType {
    FreeBet = 'FreeBet',
    RiskFreeBet = 'RiskFreeBet',
    OddsBoost = 'OddsBoost',
    AccaBoost = 'AccaBoost',
    BetStationFreebet = 'BetStationFreebet',
    BestOddsGuaranteed = 'BestOddsGuaranteed',
    Eds = 'Eds',
    BetAndGet = 'BetAndGet',
}

export enum BetAndGetTokenType {
    BetAndGet = 'BET_AND_GET',
    BetMainGetLive = 'BET_MAIN_GET_LIVE',
    MatchedStake = 'MATCHED_STAKE',
    CustomToken = 'CUSTOM_TOKEN',
}

export interface ICardDetailsQualify {
    SelectionLevelMinimumOddsDetails?: string;
    MinimumOverallOdds?: string;
    MinimumLegs?: string;
    BetOn?: string;
    BetTypeDetails?: string;
    RewardIssuedOn?: string;
    IssuedOn?: string;
    TokenType?: string;
}

export enum SignPostingOfferType {
    Eds = 'EDS',
    Promo = 'PROMO',
    BetAndGet = 'BET_AND_GET',
    RiskFree = 'RISK_FREE',
}

export interface IRewardTokenData {
    id: string;
    startDateUtc: string;
    expiryDateUtc: string;
    cmsItemId: string;
    userTokenId: string;
    minimumLegs?: number;
    wageringMultiplier?: number;
    payoutType: PayoutType;
    tokenType: string;
    filter: TokenFilter;
    rewardTokenType: RewardTokenType;
    payoutExpiry?: number;
    minOdds?: number;
    customTitle?: string;
    selectionLevelMinimumOdds?: TokenOddsData;
    replaceableParameters: StringDictionary<ReplaceableParameter>;
    minimumOdds?: TokenOddsData;
    isBetBuilder: boolean;
    isSingleAndComboSelected?: boolean;
    customDescription?: string;
    url?: string;
    maximumOdds?: TokenOddsData;
    selectionLevelMaximumOdds?: TokenOddsData;
    customMarketTypes?: string;
    isIncrementalPayout?: boolean;
    incrementalBetAmount?: number;
    incrementalPayoutNoOfTimes?: number;
    rewardTargetType?: RewardTargetType;
}

export interface OddsBoostTokenData extends IRewardTokenData {
    boostFactor: number;
    maximumStake?: number;
    minimumStake?: number;
    maxWinningsBoost?: number;
}

export interface RiskFreeTokenData extends IRewardTokenData {
    maximumPayout: number;
    riskFreePercentage: number;
    minimumStake?: number;
}

export interface FreeBetTokenData extends IRewardTokenData {
    amount: number;
}

export interface BetAndGetTokenData extends IRewardTokenData {
    customTitle: string;
    rewardTriggerType: RewardTriggerType;
    minimumStake: number;
    buildABet: BuildABet;
    payoutValue: number;
    betStatus: BetStatus;
    percentagePayout: number | undefined;
    maximumPayout?: number;
    minimumPayout?: number;
    subType: string;
    isEarlyCashOut?: boolean;
}

export interface TokenOddsData {
    european: number;
    britishNumerator: number;
    britishDenominator: number;
    american: number;
}

export interface TokenFilter {
    gameType?: GameType;
    betSlipType?: BetSlipType;
    sports: Sport[];
    competitions: Competition[];
    meetings: Meeting[];
    fixtures: Fixture[];
    schedule?: ScheduleFilter;
    markets: Market[];
    options: Option[];
    marketTemplates: MarketTemplate[];
    marketParameters: MarketParameter[];
}

export interface ScheduleFilter {
    daysOfTheWeek: DayOfWeekEnum[];
    timesOfTheDay: TimeOfDayFilter[];
}

export interface Sport {
    id: number;
    name: string;
}

export interface Competition {
    id: number;
    name: string;
}

export interface Meeting {
    id: number;
    name: string;
}

export interface Fixture {
    id: string;
    name: string;
}

export interface Market {
    id: number;
    name: string;
}

export interface Option {
    id: number;
    name: string;
}

export interface MarketTemplate {
    id: number;
    name: string;
}

export interface MarketParameter {
    happening: string;
    marketType: string;
    period: string;
}

export enum BetAndGetSubTypes {
    BetAndGetPercentageCash = 'BET_AND_GET_PERCENTAGE_CASH',
    BetAndGetPercentageFreebet = 'BET_AND_GET_PERCENTAGE_FREEBET',
    BetAndGetPercentageBonus = 'BET_AND_GET_PERCENTAGE_BONUS',
    BetMainGetLivePercentageCash = 'BET_MAIN_GET_LIVE_PERCENTAGE_CASH',
    BetMainGetLivePercentageFreebet = 'BET_MAIN_GET_LIVE_PERCENTAGE_FREEBET',
    BetMainGetLivePercentageBonus = 'BET_MAIN_GET_LIVE_PERCENTAGE_BONUS',
    MatchedStakePercentageCash = 'MATCHED_STAKE_PERCENTAGE_CASH',
    MatchedStakePercentageFreebet = 'MATCHED_STAKE_PERCENTAGE_FREEBET',
    MatchedStakePercentageBonus = 'MATCHED_STAKE_PERCENTAGE_BONUS',
    CustomTokenPercentageCash = 'CUSTOM_TOKEN_PERCENTAGE_CASH',
    CustomTokenPercentageFreebet = 'CUSTOM_TOKEN_PERCENTAGE_FREEBET',
    CustomTokenPercentageBonus = 'CUSTOM_TOKEN_PERCENTAGE_BONUS',
}
