import {
    Component,
    ComponentRef,
    ElementRef,
    HostBinding,
    Injectable,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    Optional,
    SkipSelf,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { Widget, WidgetLayoutTemplate, WidgetLocation, WidgetPage } from '@frontend/sports/types/components/widget';
import { isNumber, sortBy } from 'lodash-es';
import { EMPTY, Observable, takeUntil } from 'rxjs';

import { GridBreakpointSize, GridLayout } from '../../grid-base/grid.model';
import { GridBreakpointOptions, GridBreakpointService } from '../../grid/grid-breakpoint.service';
import { LoggerFactory } from '../../logging/logger-factory.service';
import { SportsRemoteLogger } from '../../logging/sports-remote-logger.service';
import { Modal } from '../../modal/common/modal';
import { ModalDialogService } from '../../modal/dialog/modal-dialog.service';
import { PickSourceProvider } from '../../option-pick/pick-source.provider';
import { TrackingService } from '../../tracking/tracking.service';
import { ModularConfigAccessorService } from './modular-config-accessor.service';
import { ModularPickSourceService } from './modular-pick-source.service';
import { ModularTrackingService } from './modular-tracking.service';
import { WidgetData, WidgetLoaderService } from './widget-loader.service';
import { WidgetRefreshService } from './widget-refresh.service';
import { WidgetComponent, WidgetRegistryService } from './widget-registry.service';
import { WidgetSkeletonRenderer } from './widget-skeleton-renderer.service';

abstract class WidgetRef extends ComponentRef<WidgetComponent<unknown>> {}

interface ReusableWidgetRef {
    config: Widget<unknown>;
    configIndex?: number;
    component?: WidgetRef;
    componentIndex?: number;
}

interface ReusedWidgetRef extends ReusableWidgetRef {
    configIndex: number;
    component: WidgetRef;
    componentIndex: number;
}

@Injectable()
export class WidgetSlotGridBreakpointService implements Pick<GridBreakpointService, 'forGrid'> {
    private calculateGridWidth: (containerWidth: number) => number;

    constructor(@SkipSelf() @Optional() private gridBreakpoint?: GridBreakpointService) {
        this.forRatio(1);
    }

    forGrid(element: ElementRef<any>, layout: GridLayout, options?: GridBreakpointOptions): Observable<GridBreakpointSize> {
        if (!this.gridBreakpoint) {
            return EMPTY;
        }

        return this.gridBreakpoint.forGrid(element, layout, { ...options, calculateGridWidth: this.calculateGridWidth });
    }

    forRatio(ratio: number): void {
        if (ratio === 1) {
            this.calculateGridWidth = (containerWidth) => containerWidth;
        } else {
            this.calculateGridWidth = (containerWidth) => (containerWidth - 16) * ratio;
        }
    }
}

@HooksWireup()
@Component({
    selector: 'ms-widget-slot',
    template: '<ng-container #container></ng-container>',
    providers: [WidgetSlotGridBreakpointService, { provide: GridBreakpointService, useExisting: WidgetSlotGridBreakpointService }],
})
export class WidgetSlotComponent extends OnDestroyCleanup implements OnChanges, OnDestroy {
    @Input() widget: Widget<unknown>[];
    @Input() parent?: Widget<unknown>;
    @Input() page?: WidgetPage;
    @Input() ratio?: number;
    @Input() layoutTemplate?: WidgetLayoutTemplate;

    @HostBinding('class') className = 'widget-slot';
    @ViewChild('container', { read: ViewContainerRef, static: true }) containerRef: ViewContainerRef;

    private components: WidgetRef[] = [];

    private logger: SportsRemoteLogger;

    constructor(
        private componentRefresh: WidgetRefreshService,
        private componentRegistry: WidgetRegistryService,
        private gridBreakpoint: WidgetSlotGridBreakpointService,
        private injector: Injector,
        private widgetLoaderService: WidgetLoaderService,
        private widgetSkeletonRenderer: WidgetSkeletonRenderer,
        loggerFactory: LoggerFactory,
    ) {
        super();
        this.logger = loggerFactory.getLogger('WidgetSlotComponent');

        // onchanges is triggered 2 times on page load becasue of lazy widgets -> careful with lazyloading data in onResolve
        componentRegistry.lazyWidgets$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.createOrUpdateWidgetComponent(true));
    }

    ngOnChanges(): void {
        this.createOrUpdateWidgetComponent();
    }

    createOrUpdateWidgetComponent(isLazy: boolean = false) {
        this.gridBreakpoint.forRatio(this.ratio ?? 1);

        const reused: WidgetRef[] = [];

        const ordered: ReusableWidgetRef[] = sortBy(this.widget, (config) => config.order).map((config, index) => {
            const existing = this.components.findIndex((component) => component.instance.config.type === config.type && !reused.includes(component));

            if (existing === -1) {
                return { config };
            }

            reused.push(this.components[existing]);

            return {
                config,
                configIndex: index,
                component: this.components[existing],
                componentIndex: existing,
            };
        });

        this.normalizeReused(ordered, 'configIndex');
        this.normalizeReused(ordered, 'componentIndex');

        this.destroyUnused(reused);
        if (!isLazy) {
            ordered.forEach(({ config }, index) => {
                this.widgetLoaderService.setSlotWidgets(`${config.location}-${config.id}`, {
                    isLoaded: config.location === WidgetLocation.Right,
                    order: index,
                    slot: config.location,
                    widgetId: config.id,
                    parentWidgetId: this.parent?.id,
                } as WidgetData);
            });
        }

        ordered
            .filter((widget) => this.componentRegistry.get(widget.config.type) || this.componentRegistry.widgetSkeletons[widget.config.type])
            .forEach(({ config, component }, index) => {
                try {
                    if (component) {
                        this.updateComponent(config, component, !isLazy);

                        this.orderReused(ordered, index);
                    } else {
                        this.createComponent(config, index);
                    }
                } catch (error: any) {
                    this.log(error);
                }
            });

        this.componentRefresh.registerComponent(this.components.map((component) => component.instance));
    }

    ngOnDestroy(): void {
        this.componentRefresh.deregisterComponent(this.components.map((component) => component.instance));
    }

    private createComponent(config: Widget<unknown>, index: number): void {
        const registeredComponent = this.componentRegistry.get(config.type);
        if (!registeredComponent) {
            if (config.payload) {
                this.widgetSkeletonRenderer.maybeRenderSkeleton({
                    widget: config,
                    allWidgets: this.widget,
                    index,
                    containerRef: this.containerRef,
                    injector: this.injector,
                });
            }

            return;
        }

        const injector: Injector = Injector.create({
            providers: [
                { provide: ModularConfigAccessorService, useClass: ModularConfigAccessorService },
                { provide: PickSourceProvider, useClass: ModularPickSourceService },
                { provide: TrackingService, useClass: ModularTrackingService },
                { provide: Modal, useClass: Modal },
                { provide: ModalDialogService, useClass: ModalDialogService },
            ],
            parent: registeredComponent.injector ?? this.injector,
        });

        this.widgetSkeletonRenderer.removeSkeleton(config.id);
        const component = this.containerRef.createComponent(registeredComponent.componentType, { index, injector });
        this.updateComponent(config, component, true);
    }

    private isReused(reused: ReusableWidgetRef): reused is ReusedWidgetRef {
        return reused.component !== undefined;
    }

    private updateComponent(config: Widget<unknown>, widgetComponent: ComponentRef<WidgetComponent<unknown>>, shouldCallOnData: boolean): void {
        try {
            const widget = { ...config, order: this.components.length };
            const accessor = widgetComponent.injector.get(ModularConfigAccessorService);

            accessor.setWidget(widget);
            accessor.setPage(this.page);
            accessor.setLayoutTemplate(this.layoutTemplate);
            accessor.setParentWidget(this.parent);
            if (shouldCallOnData) {
                widgetComponent.instance.hidden = config.location !== WidgetLocation.Right;
            }
            widgetComponent.instance.onResolve(widget, this.parent, shouldCallOnData);
        } catch (error: any) {
            this.log(error);
            widgetComponent.instance.hasData = false;
            this.widgetLoaderService.setwidgetLoaded(`${config.location}-${config.id}`);
        }
        // this is important: update the widgets bindings
        widgetComponent.hostView.detectChanges();
        this.components.push(widgetComponent);
    }

    private orderReused(reused: ReusableWidgetRef[], index: number): void {
        const currentWidget = reused[index];

        if (!this.isReused(currentWidget) || currentWidget.configIndex === currentWidget.componentIndex) {
            return;
        }

        this.containerRef.move(currentWidget.component.hostView, index);
        currentWidget.componentIndex = currentWidget.configIndex;

        reused
            .filter(
                (current) =>
                    this.isReused(current) &&
                    current.configIndex > currentWidget.configIndex &&
                    current.componentIndex < currentWidget.componentIndex,
            )
            .forEach((current) => {
                if (this.isReused(current)) {
                    current.componentIndex++;
                }
            });
    }

    private destroyUnused(reused: ComponentRef<WidgetComponent<unknown>>[]): void {
        const unusedWidget = this.components.filter((component) => !reused.includes(component));

        this.componentRefresh.deregisterComponent(unusedWidget.map((current) => current.instance));
        this.components = [];

        unusedWidget.forEach((component) => component.destroy());
    }

    private normalizeReused(source: ReusableWidgetRef[], target: 'configIndex' | 'componentIndex'): void {
        sortBy(
            source.filter((config) => isNumber(config[target])),
            (config) => config[target],
        ).forEach((config, index) => {
            config[target] = index;
        });
    }

    private log(error: Error): void {
        console.error(error);
        this.logger.error(error);
    }
}
