import { Injectable } from '@angular/core';

import { firstValueFrom, tap } from 'rxjs';

import { OnAppInit } from '../bootstrap/bootstrapper.service';
import { WindowEvent, WindowRef } from '../browser/window/window-ref.service';
import { Logger } from '../logging/logger';
import { UserService } from '../user/user.service';
import { RememberMeLoginService } from './remember-me-login.service';
import { RememberMeService } from './remember-me.service';

@Injectable()
export class RememberMeBootstrapService implements OnAppInit {
    constructor(
        private user: UserService,
        private rememberMeService: RememberMeService,
        private rememberMeLoginService: RememberMeLoginService,
        private log: Logger,
        private windowRef: WindowRef,
    ) {}

    async onAppInit() {
        this.windowRef.nativeWindow.addEventListener(
            WindowEvent.Focus,
            () => {
                if (!this.user.isAuthenticated && this.rememberMeService.tokenExists()) {
                    this.log.infoRemote(
                        'RememberMe: Page reloaded as already logged in on another tab using the token to avoid current tab being left unauthenticated.',
                    );
                    this.windowRef.nativeWindow.location.reload();
                }
            },
            { once: true },
        );

        if (!this.user.username && this.rememberMeService.tokenExists()) {
            try {
                await firstValueFrom(
                    this.rememberMeLoginService
                        .loginWithToken()
                        .pipe(tap(() => this.log.infoRemote('RememberMe: Login with token on app init was successful.'))),
                );
            } catch (error) {
                this.log.errorRemote('RememberMe: Failed to login with token on app start. User is left unauthenticated.', error);
            }
        }
    }
}
