import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { BetPlacementError } from '../bet-placement-error';
import { CleanStrategy } from '../betslip-error';

export class TechnicalError extends BetPlacementError {
    constructor() {
        super();
        this.cleanStrategy = CleanStrategy.Manually;
        this.type = PlacementErrorType.TechnicalError;
    }

    reason: string;
}
