import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    OnChanges,
    Optional,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';

import { HooksWireup, ISimpleChanges, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { ScrollingSizeConfig } from '@frontend/sports/common/client-config-data-access';
import { DeviceService, MediaQueryService, NativeAppService } from '@frontend/vanilla/core';
import { fromEvent, merge } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';

import { CssSupportService } from '../browser/css-support.service';
import { SmoothScroll } from '../smoothScroll.service';
import { TimerService } from '../timer.service';
import { SCROLL_ADAPTER_CONFIG, ScrollAdapterConfig } from './scroll-adapter.model';
import { ScrollSpeed } from './scroll-speed.model';

export enum ScrollAdapterButtonSize {
    Small = 24,
    Large = 36,
}

export type SwipeEvents = 'swipeleft' | 'swiperight';

@HooksWireup()
@Component({
    selector: 'ms-scroll-adapter',
    templateUrl: 'scroll-adapter.html',
    standalone: true,
})
export class ScrollAdapterComponent extends OnDestroyCleanup implements OnChanges, AfterViewInit {
    @Input() scrollSize = 200;
    @Input() scrollSpeed?: ScrollSpeed;
    @Input() alwaysShowArrows = false;
    @Input() stretch = false;
    @Input() buttonSize = ScrollAdapterButtonSize.Small;
    /**
     * Can be used to reset the state of the scroll adapter by passing the reference of the list/object/object-count
     * rendered in the content
     */
    @Input() scrollItems?: any[];
    @Output() select = new EventEmitter<'left' | 'right'>();
    @Output() mobileSwipe = new EventEmitter<SwipeEvents>();

    @HostBinding('class') get className(): string {
        const cls = ['scroll-adapter'];
        if (this.buttonSize === ScrollAdapterButtonSize.Large) {
            cls.push('scroll-adapter--large-arrows');
        }

        return cls.join(' ');
    }

    @ViewChild('contentTemplate', { static: true }) content: ElementRef<HTMLElement>;
    @ViewChild('containerTemplate', { static: true }) container: ElementRef<HTMLElement>;
    @ViewChild('leftArrow', { static: true }) leftArrow: ElementRef<HTMLElement>;
    @ViewChild('rightArrow', { static: true }) rightArrow: ElementRef<HTMLElement>;

    canScrollLeft = false;
    canScrollRight = false;
    showArrows: boolean; // is touch

    private readonly hiddenArrowClass = 'scroll-adapter__arrow--hidden';
    private readonly disabledArrowClass = 'scroll-adapter__arrow--disabled';
    private readonly scrollableLeftClass = 'scroll-adapter__container--scrollable-left';
    private readonly scrollableRightClass = 'scroll-adapter__container--scrollable-right';

    private scrollDuration = 200;
    private hasScrolled = false;

    private get containerElement(): HTMLElement {
        return this.container.nativeElement;
    }

    private get contentElement(): HTMLElement {
        return this.content.nativeElement;
    }

    constructor(
        private deviceService: DeviceService,
        private smoothScroll: SmoothScroll,
        private renderer: Renderer2,
        private scrollSizeConfig: ScrollingSizeConfig,
        private mediaService: MediaQueryService,
        private timer: TimerService,
        public elementRef: ElementRef,
        private nativeAppService: NativeAppService,
        private cssSupportService: CssSupportService,
        @Inject(HAMMER_GESTURE_CONFIG) private hammerGestureConfig: HammerGestureConfig,
        @Optional() @Inject(SCROLL_ADAPTER_CONFIG) private scrollAdapterConfig: ScrollAdapterConfig | null,
    ) {
        super();
    }

    ngOnChanges(changes: ISimpleChanges<ScrollAdapterComponent>): void {
        this.showArrows = !this.deviceService.isMobile || this.nativeAppService.isTerminal || this.alwaysShowArrows;

        if (changes.scrollItems && !changes.scrollItems.firstChange) {
            this.resetArrows();
        }
    }

    updatedScrollPosition(): void {
        const child = this.contentElement.querySelector('.active') as HTMLElement;
        if (!child) {
            return;
        }

        if (this.containerElement) {
            this.containerElement.scrollLeft = child.offsetLeft - this.containerElement.clientLeft / 2 + child.clientLeft / 2;
        }
        this.setArrows();
    }

    ngAfterViewInit(): void {
        const scrollElement: HTMLElement | null = this.elementRef.nativeElement;
        if (scrollElement && this.cssSupportService.isTouchActionSupported()) {
            const hammer = this.hammerGestureConfig.buildHammer(scrollElement);

            merge(fromEvent(hammer, 'swiperight'), fromEvent(hammer, 'swipeleft'))
                .pipe(takeUntil(this.destroyed$))
                .subscribe((event: any) => this.groupsSwiped(event));
        }

        if (!this.showArrows) {
            return;
        }

        if (this.scrollAdapterConfig?.disableHorizontalScrollOnDesktop && !this.deviceService.isMobile) {
            this.containerElement.style.overflowX = 'hidden'; // make overflowX hidden so scrolling over will scroll column vertically
        }

        merge(fromEvent(this.containerElement, 'scroll'), fromEvent(window, 'resize'))
            .pipe(auditTime(30), takeUntil(this.destroyed$))
            .subscribe(() => this.setArrows());

        this.setArrows();
    }

    scrollLeft(): void {
        this.scroll(this.containerElement.scrollLeft - this.calculateScrollSize());
        this.select.emit('left');
    }

    scrollRight(): void {
        this.hasScrolled = true;
        this.scroll(this.containerElement.scrollLeft + this.calculateScrollSize());
        this.select.emit('right');
    }

    setArrows(): void {
        if (!this.showArrows) {
            return;
        }

        // animationFrame is needed as the method might be called before the content is rendered,
        // even though the ngOnChange was triggered, so the measurement is wrong for the
        // width/scroll position
        this.timer.setAnimationFrame(() => {
            const scrollLeft = this.containerElement.scrollLeft;
            const offsetWidth = this.containerElement.offsetWidth;
            const scrollWidth = this.containerElement.scrollWidth;

            if (scrollLeft > 0) {
                this.hasScrolled = true;
            }

            // If we have arrows visible and the content can fit in the container,
            // we do not need the arrows visible. This happens when the right/left column width changes with code.
            // If you resize the browser windows manually, this is not reproducible.
            if (
                this.containerElement.offsetWidth !== this.containerElement.scrollWidth &&
                this.containerElement.offsetWidth > this.contentElement.offsetWidth
            ) {
                this.canScrollRight = false;
                this.canScrollLeft = false;
            } else {
                this.canScrollRight = scrollLeft + offsetWidth < scrollWidth - 5;
                this.canScrollLeft = scrollLeft > 5;
            }

            this.updateUi();
        });
    }

    resetArrows(): void {
        // we reset the inner state of the scroll adapter and force update ui prior
        // to setting the arrows to avoid flickering
        this.hasScrolled = false;
        this.canScrollLeft = false;
        this.canScrollRight = false;

        this.containerElement.scrollLeft = 0;

        this.updateUi();
        this.setArrows();
    }

    groupsSwiped(event: HammerInput): void {
        this.mobileSwipe.emit(<SwipeEvents>event.type);
        event.srcEvent.stopPropagation();
    }

    private scroll(left: number): void {
        this.smoothScroll.scrollLeftTo(this.containerElement, left, this.scrollDuration);
    }

    private updateUi(): void {
        this.setClass(this.leftArrow, this.hiddenArrowClass, !this.leftArrowVisible());
        this.setClass(this.rightArrow, this.hiddenArrowClass, !this.rightArrowVisible());

        this.setClass(this.leftArrow, this.disabledArrowClass, !this.canScrollLeft);
        this.setClass(this.rightArrow, this.disabledArrowClass, !this.canScrollRight);

        this.setClass(this.container, this.scrollableLeftClass, this.leftArrowVisible());
        this.setClass(this.container, this.scrollableRightClass, this.rightArrowVisible());
    }

    private leftArrowVisible(): boolean {
        return this.hasScrolled && this.canScrollLeft;
    }

    private rightArrowVisible(): boolean {
        return this.canScrollRight;
    }

    /**
     * Add or removes a class from elements depending of direction
     *
     * @param el element to be modified
     * @param className class name
     * @param direction when true adds the class when false removes the class.
     */
    private setClass(el: ElementRef<HTMLElement>, className: string, direction: boolean): void {
        const hasClass = el.nativeElement.classList.contains(className);
        if (direction && !hasClass) {
            this.renderer.addClass(el.nativeElement, className);
        } else if (!direction && hasClass) {
            this.renderer.removeClass(el.nativeElement, className);
        }
    }

    private calculateScrollSize(): number {
        if (this.scrollSpeed) {
            const scrollPercentage = this.getScrollPercentage(this.scrollSpeed);

            if (scrollPercentage) {
                return this.containerElement.offsetWidth * scrollPercentage;
            }
        }

        return this.scrollSize;
    }

    private getScrollPercentage(scrollSpeed: ScrollSpeed): number | undefined {
        let activeBreakpoint = this.getActiveBreakpoint(scrollSpeed.breakpoints);

        if (!activeBreakpoint) {
            activeBreakpoint = scrollSpeed.default;
        }

        return this.scrollSizeConfig.scrollingSpeed[activeBreakpoint];
    }

    private getActiveBreakpoint(breakpoints: { [key: string]: string }): string | null {
        const keys = Object.keys(breakpoints);
        const activeBreakpointKey = keys.find((br) => this.mediaService.isActive(br));

        if (!activeBreakpointKey) {
            return null;
        }

        return breakpoints[activeBreakpointKey];
    }
}
