import { Type } from '@angular/core';

import { Observable, OperatorFunction } from 'rxjs';
import { filter } from 'rxjs/operators';

export type Nullable<T> = T | null;
export type Optional<T> = T | undefined;

export interface StringDictionary<TValue> {
    [key: string]: TValue;
}
export interface NumberDictionary<TValue> {
    [key: number]: TValue;
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type PropType<T, K extends keyof T> = T[K];

export type SimpleCallback = () => void;

export function isDefined<T>(obj: T | undefined): obj is T {
    return obj !== undefined;
}

export function hasValue<T>(obj: T | undefined | null): obj is T {
    return !!obj;
}

export function ofType<T>(type: Type<T>): OperatorFunction<any, T> {
    return (source$: Observable<any>) => source$.pipe(filter(typeCheck(type)));
}

export function filterDefined<T>(): OperatorFunction<null | undefined | T, T> {
    return (source$: Observable<null | undefined | T>) => source$.pipe(filter(hasValue));
}

function typeCheck<T>(type: Type<T>): (obj: any) => obj is T {
    return (obj: any): obj is T => obj instanceof type;
}

export function xor(first?: boolean, second?: boolean): boolean {
    return Boolean((first && !second) || (!first && second));
}

/**
 * @deprecated This function is deprecated and should not be used.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function exhaustiveCheckGuard<T>(arg: never): any {}
