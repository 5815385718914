import { Injectable } from '@angular/core';

import { Fixture } from '@cds/betting-offer';
import { BettingInsightsModel } from '@cds/betting-offer/betting-insights';
import { FixtureView, SplitFixture } from '@cds/betting-offer/domain-specific';
import { BetBuilderFixture } from '@cds/betting-offer/domain-specific/bet-builder';
import { StatisticsMode } from '@cds/query-objects';
import { firstValueFrom } from 'rxjs';

import { EnhancedFixtureViewGroupingConfiguration } from '../event-model/model/event.model';
import { BettingOfferApi } from './betting-offer-api.service';
import { OfferGroupingService } from './offer-grouping.service';

export interface FixtureViewModel {
    fixture: Fixture;
    splitFixtures: SplitFixture[];
    linkedFixture: Fixture | undefined;
    preCreatedOffer?: BetBuilderFixture;
    grouping: EnhancedFixtureViewGroupingConfiguration;
    bettingInsights?: BettingInsightsModel;
}

@Injectable({ providedIn: 'root' })
export class BettingOfferService {
    constructor(
        private bettingOfferApi: BettingOfferApi,
        private offerGroupingService: OfferGroupingService,
    ) {}

    async getFixtureViewModel(
        id: string,
        excludeMarkets: boolean,
        statsMode: string,
        includePrecreated: boolean,
        isBettingInsightsEnabled: boolean = false,
        includeRelatedFixtures?: boolean,
    ): Promise<FixtureViewModel | undefined> {
        const fixtureView = await this.bettingOfferApi.getFixtureView(
            id,
            statsMode,
            excludeMarkets,
            includePrecreated,
            isBettingInsightsEnabled,
            undefined,
            undefined,
            undefined,
            undefined,
            includeRelatedFixtures,
        );

        return this.createViewModel(fixtureView);
    }

    async getFixtureViewModelByGridGroupIds(
        id: string,
        gridGroupIds: string,
        includePrecreated: boolean = false,
        isBettingInsightsEnabled: boolean = false,
    ): Promise<FixtureViewModel | undefined> {
        const fixtureView = await this.bettingOfferApi.getFixtureView(
            id,
            StatisticsMode.None,
            false,
            includePrecreated,
            isBettingInsightsEnabled,
            gridGroupIds,
        );

        return this.createViewModel(fixtureView);
    }

    async getMainLiveFixtureViewModel(id: string): Promise<FixtureViewModel | undefined> {
        const fixtureView = await firstValueFrom(this.bettingOfferApi.getMainLiveFixtureView$(id));

        return this.createViewModel(fixtureView);
    }

    private async createViewModel(fixtureView?: FixtureView): Promise<FixtureViewModel | undefined> {
        if (!fixtureView || !fixtureView.fixture) {
            return;
        }
        const fixture = fixtureView.fixture;

        const grouping = await firstValueFrom(this.offerGroupingService.getFixtureGrouping(fixture.sport.id, fixtureView.groupingVersion));

        if (!grouping) {
            throw new Error('Unable to load fixture grouping');
        }

        return {
            fixture,
            splitFixtures: fixtureView.splitFixtures,
            preCreatedOffer: fixtureView.preCreatedOffer,
            grouping,
            linkedFixture: fixtureView.linkedFixture,
            bettingInsights: fixtureView.bettingInsights,
        };
    }
}
