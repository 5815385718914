import { StringDictionary } from '@frontend/sports/common/base-utils';
import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import { ceil, clone, differenceBy, find, findIndex, flatten, isArray, isNumber, keyBy, sortBy } from 'lodash-es';

import { EventModel, LeagueModel, RegionModel, SportModel } from '../event-model/model/event.model';
import { SubscriptionTopic } from '../event-subscription/base-subscription.service';
import { GridActions, IGridEventsToReplace, IGridGroup } from './grid.actions';
import {
    CollapsedState,
    DateGroup,
    EventGroup,
    FormGroup,
    GridEvent,
    GridGrouping,
    GridModel,
    GridSorting,
    Group,
    GroupBadge,
    LeagueGroup,
} from './grid.model';
import { IGridState, gridInitialState } from './grid.state';

const producer = <TAction extends { payload: { id: string } }>(
    state: IGridState,
    action: TAction,
    gridUpdate: (model: GridModel) => GridModel,
): IGridState => {
    const currentGrid = state[action.payload.id];

    return {
        ...state,
        [action.payload.id]: gridUpdate(currentGrid),
    };
};

export const gridReducer = createReducer(
    gridInitialState,
    on(GridActions.init, (state, action) => ({
        ...state,
        [action.payload.id]: action.payload,
    })),
    on(GridActions.addEvents, (state, action) => {
        return producer(state, action, (grid) => addEvents(grid, action.payload.events, { grouping: action.payload.grouping }));
    }),
    on(GridActions.setEvents, (state, action) => {
        return producer(state, action, (grid) =>
            addEvents(grid, action.payload.events, { set: true, topic: action.payload.topic, grouping: action.payload.grouping }),
        );
    }),
    on(GridActions.updateEvents, (state, action) => {
        return producer(state, action, (grid) => updateEvent(grid, action.payload.event));
    }),
    on(GridActions.replaceEvents, (state, action) => {
        return producer(state, action, (grid) => replaceEvents(grid, action.payload.events));
    }),
    on(GridActions.removeEvents, (state, action) => {
        return producer(state, action, (grid) => removeEvents(grid, action.payload.eventIds));
    }),
    on(GridActions.collapseEvents, (state, action) => {
        return producer(state, action, (grid) => collapseEvent(grid, action.payload.eventId, action.payload.optionGroupId));
    }),
    on(GridActions.setActiveGroup, (state, action) => {
        return producer(state, action, (grid) => setActiveGroup(grid, action.payload.columnId, action.payload.groupId));
    }),
    on(GridActions.setGroupCollapse, (state, action) => {
        return producer(state, action, (grid) => setGroupCollapse(grid, action.payload.groupId));
    }),
    on(GridActions.addGroups, (state, action) => {
        return producer(state, action, (grid) => addGroups(grid, action.payload.groups));
    }),
    on(GridActions.updateGroupsBadges, (state, action) => {
        return producer(state, action, (grid) => updateGroupsBadges(grid, action.payload.badges));
    }),
    on(GridActions.changeSorting, (state, action) => {
        return producer(state, action, (grid) => changeSorting(grid, action.payload.sorting));
    }),
    on(GridActions.replaceEvent, (state, action) => {
        return producer(state, action, (grid) => replaceEvent(grid, action.payload.targetFixtureId, action.payload.newFixture));
    }),
    on(GridActions.destroy, (state, action) => {
        const copy = { ...state };
        delete copy[action.gridId];

        return copy;
    }),
);

export function mapLeague(league: LeagueModel, region: RegionModel, sport: SportModel): LeagueGroup {
    const groupId = league.parentLeagueId || league.id;

    return {
        id: groupId,
        name: league.name,
        count: 0,
        events: [],
        siblings: [league.id],
        region,
        collapsed: false,
        collapsible: true,
        deferred: false,
        canBeFavourited: true,
        imageProfile: league.imageProfile,
    };
}

export function getLeague(grid: GridModel, event: EventModel): LeagueGroup | undefined | void {
    if (!grid.groups) {
        return error('Groupped grid model should define groups');
    }

    let league = find(grid.groups, (current) => current.id === event.league.parentLeagueId || current.id === event.league.id) as LeagueGroup;

    if (!league) {
        league = mapLeague(event.league, event.region, event.sport);
        grid.groups.push(league);
    }

    return league;
}

export function changeSorting(grid: GridModel, sorting?: GridSorting): GridModel {
    return produce(grid, (draft) => {
        if (draft) {
            draft.sorting = sorting;
        }
    });
}

export function mapForm(id: number, winCount: number): FormGroup {
    return {
        id,
        name: '',
        winCount,
        count: 0,
        events: [],
        collapsed: false,
        collapsible: true,
        deferred: false,
    };
}

export function mapDate(id: number, date: Date): DateGroup {
    return {
        id,
        name: date.toDateString(),
        date,
        count: 0,
        events: [],
        collapsed: false,
        collapsible: true,
        deferred: false,
    };
}

export function getDate(grid: GridModel, event: EventModel): DateGroup | undefined | void {
    if (!grid.groups) {
        return error('Groupped grid model should define groups');
    }

    // to make the id a bit more readable
    const day = 24 * 60 * 60 * 1000;
    const base = new Date(event.startDate);

    base.setHours(0);
    base.setMinutes(0);
    base.setSeconds(0);
    base.setMilliseconds(0);

    const id = ceil(base.getTime() / day);

    let group = find(grid.groups, (current) => current.id === id) as DateGroup;

    if (!group) {
        group = mapDate(id, base);
        grid.groups.push(group);
    }

    return group;
}

export function getForm(grid: GridModel, event: EventModel, grouping: GridGrouping): FormGroup | undefined | void {
    if (!grid.groups) {
        return error('Groupped grid model should define groups');
    }

    let id = 0;

    switch (grouping) {
        case GridGrouping.AwayForm:
            id = event.fixtureForm?.awayTeamAwayForm?.winCount ?? 0;
            break;
        case GridGrouping.OverallForm:
            const homeWins = event.fixtureForm?.overallHomeTeamForm?.winCount;
            const awayWins = event.fixtureForm?.overallAwayTeamForm?.winCount;
            id = Math.max(homeWins ?? 0, awayWins ?? 0);
            break;
        default:
            id = event.fixtureForm?.homeTeamHomeForm?.winCount ?? 0;
    }

    let group = find(grid.groups, (current) => current.id === id) as FormGroup;

    if (!group) {
        group = mapForm(id, id);
        grid.groups.push(group);
    }

    return group;
}

export function getGroup(grid: GridModel, entity: EventModel | number): EventGroup | undefined | void {
    if (!grid.groups) {
        return error('Groupped grid model should define groups');
    }

    if (grid.grouping === GridGrouping.None) {
        if (!grid.groups.length) {
            grid.groups.push({
                collapsed: false,
                collapsible: false,
                count: 0,
                deferred: false,
                events: [],
                id: 1,
                name: '',
            });
        }

        return grid.groups[0];
    }

    if (!entity) {
        return error('Entity should be defined to query group');
    }

    if (!isNumber(entity)) {
        switch (grid.grouping) {
            case GridGrouping.Date:
                return getDate(grid, entity);
            case GridGrouping.League:
                return getLeague(grid, entity);
            case GridGrouping.HomeForm:
            case GridGrouping.AwayForm:
            case GridGrouping.OverallForm:
                return getForm(grid, entity, grid.grouping);
        }
    }

    return find(grid.groups, (group) => group.id === entity);
}

export function setActiveGroup(grid: GridModel, columnId: string, groupId: string): GridModel {
    return produce(grid, (draft) => {
        const currentColumn = find(draft.columns, (current) => current.id === columnId);

        if (!currentColumn) {
            return error('Could not find specified column');
        }

        const currentGroup = find(currentColumn.groups, (current) => current.id === groupId);

        if (!currentGroup) {
            return error('Could not find specified group');
        }

        currentColumn.groups.forEach((current) => (current.active = current.id === currentGroup.id));

        if (currentGroup.extended) {
            draft.columns.forEach((column) => (column.enabled = column === currentColumn));
        } else {
            draft.columns.forEach((column) => (column.enabled = true));
        }
    });
}

export function setActiveGroupState(grid: GridModel, state: IGridGroup[]): GridModel {
    let result = grid;
    const groups = keyBy(result.columns[0]?.groups, (group) => group.id);
    const ordered = sortBy(state, (current) => groups[current.groupId] && groups[current.groupId].extended);

    for (const current of ordered) {
        result = setActiveGroup(result, current.columnId, current.groupId);
    }

    return result;
}

export function setStateCollapse(grid: GridModel, collapsedGroups: number[], collapsedEvents: StringDictionary<CollapsedState>): GridModel {
    return produce(grid, (draft) => {
        draft.collapsed.groups = collapsedGroups;
        draft.collapsed.events = collapsedEvents;
    });
}

export function setGroupCollapse(grid: GridModel, groupId: number): GridModel {
    return produce(grid, (draft) => {
        if (!draft.groups) {
            return error('Groupped grid model should define groups');
        }

        const existing = draft.groups.find((x) => x.id === groupId);

        if (!existing) {
            return error('Group not found');
        }

        if (!existing.collapsible) {
            return error('Group not collapsible');
        }

        existing.collapsed = !existing.collapsed;

        const position = draft.collapsed.groups.indexOf(existing.id);

        if (existing.collapsed) {
            if (position === -1) {
                draft.collapsed.groups.push(existing.id);
            }
        } else {
            if (position === -1) {
                draft.collapsed.groups.splice(existing.id, 1);
            }
        }
    });
}

export function setGroupCollapseThreshold(grid: GridModel, threshold: number): GridModel {
    return produce(grid, (draft) => {
        if (draft.grouping === GridGrouping.None) {
            return;
        }

        let total = 0;

        draft.groups.forEach((group, index) => {
            if (group.events.length === 0) {
                group.collapsed = true;
                group.deferred = true;
            } else if (threshold) {
                total += group.events.length;
                group.collapsed = index !== 0 && total > threshold;
            }
        });
    });
}

export function addGroups(grid: GridModel, groups: Group[]): GridModel {
    return produce(grid, (draft) =>
        draft.columns.forEach((column) =>
            groups.forEach((group) => {
                const existing = column.groups.find((current) => current.id === group.id && current.extended === group.extended);

                if (existing) {
                    existing.name = group.name;
                    existing.visible = true;
                } else {
                    column.groups.push(group);
                }
            }),
        ),
    );
}

export function updateGroupsBadges(grid: GridModel, badges: Record<string, GroupBadge>): GridModel {
    return produce(grid, (draft) =>
        draft.columns.forEach((column) =>
            column.groups.forEach((group) => {
                group.badge = badges[group.id];
            }),
        ),
    );
}

export function addLeague(grid: GridModel, league: LeagueGroup): GridModel {
    return produce(grid, (draft) => {
        if (!draft.groups) {
            return error('Groupped grid model should define groups');
        }

        const existing = draft.groups.find((x) => x.id === league.id) as LeagueGroup;

        if (existing) {
            if (existing.siblings.indexOf(league.siblings[0]) === -1) {
                existing.siblings.push(league.siblings[0]);
            }
        } else {
            draft.groups.push(league);
        }
    });
}

export function collapseEvent(grid: GridModel, eventId: string, optionGroupId?: string): GridModel {
    // the new version of this function was proposed by Ventsislav Mladenov
    const group = grid.groups.find((current) => current.events.some((currentEvent) => currentEvent.id === eventId));
    if (!group) {
        console.error('Expected to have group but not found');

        return grid;
    }
    const event = group.events.find((current) => current.id === eventId);
    if (!event) {
        console.error('Event not found');

        return grid;
    }
    const newGrid = {
        ...grid,
        collapsed: {
            ...grid.collapsed,
            events: {
                ...grid.collapsed.events,
                [event.id]: grid.collapsed.events[event.id]
                    ? {
                          collapsed: grid.collapsed.events[event.id].collapsed,
                          collapsedChildren: [...grid.collapsed.events[event.id].collapsedChildren],
                      }
                    : {
                          collapsed: false,
                          collapsedChildren: [],
                      },
            },
        },
    };
    const eventState = newGrid.collapsed.events[event.id];
    if (optionGroupId) {
        const index = eventState.collapsedChildren.indexOf(optionGroupId);
        if (index === -1) {
            eventState.collapsedChildren.push(optionGroupId);
        } else {
            eventState.collapsedChildren.splice(index, 1);
        }
    } else {
        eventState.collapsed = !eventState.collapsed;
    }

    return newGrid;
}

export function updateEvent(grid: GridModel, event: EventModel): GridModel {
    return replaceEvents(grid, { currentEvent: event });
}

export function replaceEvents(grid: GridModel, events: IGridEventsToReplace | IGridEventsToReplace[]): GridModel {
    return produce(grid, (draft) => {
        if (!draft) {
            return;
        }

        const eventsArray = isArray(events) ? events : [events];

        eventsArray.forEach((event) => {
            const group = getGroup(draft as GridModel, event.currentEvent);

            if (!group) {
                return error('Expected to have group but not found');
            }

            const index = findIndex(group.events, (current) => current.id === event.currentEvent.id);

            if (index < 0) {
                return error('Event not found', event.currentEvent);
            }

            group.events[index] = clone(event.newEvent || event.currentEvent);
        });
    });
}

export function getEvents(grid: GridModel): EventModel[] {
    return flatten(grid.groups.map((group) => group.events));
}

export function addEvents(
    grid: GridModel,
    events: EventModel | EventModel[],
    options: { set?: boolean; topic?: SubscriptionTopic; grouping?: GridGrouping } = {},
): GridModel {
    return produce(grid, (draft) => {
        if (!events || !draft) {
            return;
        }

        const collection = isArray(events) ? events : [events];

        if (!collection.length || !grid || !draft) {
            return;
        }

        if (!draft.groups || options.set) {
            draft.groups = [];
        }

        if (options.topic !== undefined) {
            draft.topic = options.topic;
        }

        if (options.grouping !== undefined) {
            draft.grouping = options.grouping;
        }

        collection.forEach((event) => {
            const group = getGroup(draft as GridModel, event);

            if (!group) {
                return error('Expected to have group but not found');
            }

            if (group.events.find((gridEvent) => gridEvent.id === event.id)) {
                return;
            }
            group.events.push(event);
            group.deferred = false;
            group.collapsed = draft.collapsed.groups.indexOf(group.id) !== -1;
        });

        if (!grid.disableGroupSorting) {
            draft.groups.forEach((group) => {
                group.events = sortBy(group.events, (event) => event.startDate);

                if (group.count < group.events.length) {
                    group.count = group.events.length;
                }
            });
        }

        if (!draft.groupingThreshold) {
            return;
        }

        let visible = flatten(draft.groups.map((group) => group.events)).length;
        const initial = (grid.groups || []).length === 0;

        differenceBy(draft.groups, grid.groups, (group) => group.id).forEach((group, index) => {
            if (!initial || index !== 0) {
                group.collapsed = visible >= draft.groupingThreshold!;
            }

            visible += group.events.length;
        });
    });
}

export function removeEvents(grid: GridModel, eventIds: string[]): GridModel {
    return produce(grid, (draft) => {
        draft.groups.forEach((group) => {
            if (group.events.some((e) => eventIds.some((id) => id === e.id))) {
                group.events = group.events.filter((e) => eventIds.every((id) => id !== e.id));
            }
        });
        draft.groups = draft.groups.filter((g) => !!g.events.length);
    });
}

export function replaceEvent(grid: GridModel, targetFixtureId: string, newFixture: GridEvent): GridModel {
    return produce(grid, (draft) => {
        draft.groups.forEach((group) => {
            if (group.events.some((e) => e.id === targetFixtureId)) {
                group.events = group.events.map((event) => (event.id === targetFixtureId ? newFixture : event));
            }
        });
    });
}

function error(message?: any, ...optionalParams: any[]): void {
    console.error(message, optionalParams);
}
