import { uniqBy } from 'lodash-es';

import { BetslipBetBuilderPick } from './picks/betslip-bet-builder-pick';
import { BaseBetslipGroupPick, BetslipGroupPick } from './picks/betslip-group-pick';
import { BetslipPick } from './picks/betslip-pick';
import { BetslipUnknownPick } from './picks/betslip-unknown-pick';
import { BetBuilderPickId, GroupPickId, PickId } from './picks/pick-id';

export function getPicksCount(picks: BetslipPick[]): number {
    return picks.flatMap((pick) => (pick instanceof BetslipGroupPick || BetslipUnknownPick.isPick(pick) ? pick.getLegs() : pick)).length;
}

export function isBetBuilderPick(pick: BetslipPick): pick is BetslipBetBuilderPick | BaseBetslipGroupPick {
    return BetslipBetBuilderPick.isPick(pick) || BaseBetslipGroupPick.isPick(pick);
}

export function isBetslipBetBuilderPick(pick: BetslipPick): pick is BetslipBetBuilderPick {
    return BetslipBetBuilderPick.isPick(pick);
}

export function isBaseBetslipGroupPick(pick: BetslipPick): pick is BaseBetslipGroupPick {
    return BaseBetslipGroupPick.isPick(pick);
}

export function isBetBuilderPickId(pickId: PickId): pickId is BetBuilderPickId | GroupPickId {
    return BetBuilderPickId.isId(pickId) || GroupPickId.isId(pickId);
}

export function getOptionNameFromBetslipPick(pick: BetslipPick) {
    return pick.marketName.name + ' - ' + pick.optionName.name;
}

export function getLegsCount(picks: BetslipPick[]): number {
    return picks.reduce((acc, curr) => {
        if (BetslipBetBuilderPick.isPick(curr)) {
            return acc + curr.getLegCount();
        }
        if (BaseBetslipGroupPick.isPick(curr)) {
            return acc + curr.getLegs().length;
        }

        return acc + 1;
    }, 0);
}

export function flattenGroupPicksIfAny(picks: BetslipPick[]): BetslipPick[] {
    return picks.flatMap((p) => (BaseBetslipGroupPick.isPick(p) || (BetslipUnknownPick.isPick(p) && p.legsCount > 0) ? p.getLegs() : p));
}

// Expands the legs for group picks. Keeps the groups in the list. Unique check added in case we're calling this method on a list multiple times
export function flattenPicks(picks: BetslipPick[]): BetslipPick[] {
    return uniqBy(
        picks.flatMap((p) => (BaseBetslipGroupPick.isPick(p) || (BetslipUnknownPick.isPick(p) && p.legsCount > 0) ? [p, ...p.getLegs()] : p)),
        (pick) => pick.id.toString(),
    );
}

// Checks if picks qualify as valid one pick combo. Currently, 2 cases are supported:
// - Group picks(e.g. Angstrom bet builders)
// - BetBuilder picks(eg. Sportcast) ONLY in case the feature is enabled
export const isValidOnePickCombo = (picks: BetslipPick[], isSportcastAsComboEnabled: boolean) =>
    picks.length === 1 && (BaseBetslipGroupPick.isPick(picks[0]) || (isSportcastAsComboEnabled && BetslipBetBuilderPick.isPick(picks[0])));

export function getNonBetBuilderPicks(picks: BetslipPick[]): BetslipPick[] {
    return picks.filter((p) => !isBetBuilderPick(p));
}
