import { Injectable } from '@angular/core';

import { CountItem, ItemType, children, isVirtualCompetitionGroupItem, isVirtualCompetitionItem } from '@frontend/sports/common/base-utils';

import { CompetitionRoute } from '../../../navigation/navigation.models';

@Injectable({
    providedIn: 'root',
})
export class CompetitionIdService {
    getCompetitionId(sport: CountItem | undefined, params: CompetitionRoute): number | undefined {
        const competitionId = Array.isArray(params.league) ? params.league[0] : params.league;
        if (!sport || !params.isVirtual) {
            return competitionId;
        }

        return this.getRealVirtualCompetitionId(sport, params.region, competitionId, params.virtualCompetitionGroup);
    }

    getRealVirtualCompetitionId(
        sport: CountItem,
        regionId: number | undefined,
        virtualCompetitionId: number | undefined,
        virtualGroupId: number | undefined,
    ): number | undefined {
        const region = sport.children.find((item) => (item.type === ItemType.Region || item.type === ItemType.Tournament) && item.id === regionId);

        if (region) {
            const virtualCompetition = region.children.filter(isVirtualCompetitionItem).find((item) => item.id === virtualCompetitionId);
            if (virtualCompetition) {
                const virtualGroup = virtualCompetition.children.filter(isVirtualCompetitionGroupItem).find((item) => item.id === virtualGroupId);

                return virtualGroup ? virtualGroup.siblings[0] || virtualCompetition.realCompetitionId : virtualCompetition.siblings[0];
            }
        }

        return;
    }

    getCompoundId(sport: CountItem | undefined, leagueId: number | number[] | undefined): string | undefined {
        if (leagueId && sport) {
            const compoundId = children(sport, ItemType.Competition).find((p) => p.id === leagueId)?.compoundId;

            return compoundId;
        }

        return;
    }
}
