import { BetPlacementErrorIcon } from '../../bet-placement-error-icon';
import { BetslipError } from '../../betslip-error';
import { ClientErrorType } from '../../client-error-type';

export class RewardTokensTotalOddsError extends BetslipError {
    constructor(minimumOdds: string) {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensTotalOddsError;
        this.hasClientValidation = true;
        this.minimumOdds = minimumOdds;
    }

    readonly minimumOdds: string;
}
