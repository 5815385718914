export enum ProfileActionName {
    RemoveOverAsk = 'RemoveOverAsk',
    RemoveQuickBet = 'RemoveQuickBet',
    RemoveGamingCrossSell = 'RemoveGamingCrossSell',
    PreBetSlipMessage = 'PreBetSlipMessage',
    PostBetSlipMessage = 'PostBetSlipMessage',
}

export enum FeatureLocation {
    QuickBet = 'QuickBet',
    PreBetSlipMessage = 'PreBetSlipMessage',
    PostBetSlipMessage = 'PostBetSlipMessage',
}

export interface IARCProfileConfiguration {
    profileId: string;
    profileActions: ProfileActionDetail[];
}
export interface ProfileActionDetail {
    name: string;
    frequency?: number;
}

export const ProfileMessageStorageKeys = {
    preBetMessageStorageKey: 'prebetLastMsgDisplayDate',
    postBetMessageStorageKey: 'postbetLastMsgDisplayDate',
    actionForMessagedisplayPre: 'actionForMessagedisplayPre',
    actionForMessagedisplayPost: 'actionForMessagedisplayPost',
    loginActionPrebet: 'prebetLogin',
    loginActionPostbet: 'postbetLogin',
    firstLoginActionPrebet: 'prebetFirstLogin',
    firstLoginActionPostbet: 'postbetFirstLogin',
};

export interface ArcRestrictionTrackingData {
    action?: string;
    position: string;
    source: string;
}

export enum ArcRestrictionTrackingConstants {
    CategoryEvent = 'gambling control',
    LabelEvent = 'safer gambling',
    SourceBetslip = 'betslip',
    SourceBetslipQuickBet = 'betslip - quick bet',
    SourceBetslipReceipt = 'betslip - bet receipt',
    SaferGamblingEventDetails = 'safer gambling info message',
    PlayBreakEventDetails = 'customer on break info message',
    FindOutMoreLinkPositionEvent = 'find out more - link',
    UrlClicked = 'not applicale',
}

export enum TrackingEvent {
    Click = 'Click',
    Load = 'Load',
    Collapse = 'Collapse',
    Expand = 'Expand',
}
