import { createSelector } from '@ngrx/store';

import { selectIsLinearBetslip } from '../../../base/store/selectors';
import { BetslipType } from '../../../core/betslip-type';
import { betslipTypeStateSelector } from '../selectors';

export const betslipTypesBaseSelector = createSelector(betslipTypeStateSelector, (b) => b.base);
export const betslipCurrentTypeSelector = createSelector(betslipTypesBaseSelector, (b) => b.currentSelectedType);
export const betslipActiveTypesSelector = createSelector(betslipTypesBaseSelector, (b) => b.activeTypes);
export const selectLinearTypes = createSelector(betslipTypesBaseSelector, (b) => b.linearTypes);

export const selectIsLinearSingleBetBuilder = createSelector(
    selectIsLinearBetslip,
    selectLinearTypes,
    (isLinear, linearTypes) => isLinear && !linearTypes[BetslipType.Combo] && linearTypes[BetslipType.BetBuilder],
);
