import { ActivatedRouteSnapshot, Params } from '@angular/router';

import { Tag } from '@cds/betting-offer/tags';

export interface SitemapModel {
    showGoHome?: boolean;
    items: SitemapItemModel[];
}
export interface SitemapItemBaseModel {
    id: string;
    title?: string;
    type: SitemapItemType;
    url?: string;
    href?: string; // used for seo
    active?: boolean;
    level: number;
    parentUrl?: string;
    isInDrawer?: boolean;
    hasLogo?: boolean;
    activeDrawerItem?: SitemapItemBaseModel;
    sportId?: number;
    competitionId?: number;
    conferenceId?: number;
    icon?: string;
    children?: SitemapItemBaseModel[];
    drawerItems?: SitemapItemBaseModel[];
    drawerSubTitle?: string;
    parentId?: string;
    showIcon?: boolean;
    isVirtual?: boolean;
    badge?: string;
    showActiveDrawerItemTitle?: boolean;
    tournamentId?: number;
    isCompetitionFallback?: boolean;
}
export interface SiteMapResponse {
    data: SitemapItemBaseModel[];
    competitionFallback: SitemapItemBaseModel[];
}
export interface SitemapItemModel extends SitemapItemBaseModel {
    showSeparator?: boolean;
    drawer?: SitemapDrawerModel;
    count?: number;
    scrollId?: string;
}

export interface SitemapDrawerModel {
    type: SitemapDrawerType;
    sportId?: number;
    competitionId?: number;
    title: string;
    subTitle?: string;
    items?: SitemapItemBaseModel[];
    regions?: SitemapRegion[];
}

export interface SitemapRegion {
    title: string;
    url?: string;
    urlText?: string;
    items?: SitemapItemBaseModel[];
}

export enum SitemapItemType {
    Sport = 'Sport',
    Competition = 'Competition',
    Live = 'Live',
    CustomLink = 'CustomLink',
    AllLeaguesOrTournaments = 'AllLeaguesOrTournaments',
    Region = 'Region',
    LiveVideo = 'LiveVideo',
    Coupons = 'Coupons',
    Calendar = 'Calendar',
    Featured = 'Featured',
    Standings = 'Standings',
    Outrights = 'Outrights',
    Specials = 'Specials',
    MultiBuilder = 'MultiBuilder',
    Favourites = 'Favourites',
    Tournament = 'Tournament',
    Conference = 'Conference',
    WorldCupHub = 'WorldCupHub',
    VirtualCompetition = 'VirtualCompetition',
    Esports = 'Esports',
    DynamicOfferCategory = 'DynamicOfferCategory',
    MultiSportsHub = 'MultiSportsHub',
}

export enum SitemapDrawerType {
    Popular = 'Popular',
    All = 'All',
    SelectConference = 'SelectConference',
}

export interface SitemapResolveModel<TModel> {
    routeSnapshot: ActivatedRouteSnapshot;
    model: TModel;
    state: SitemapStateModel;
    config: SitemapItemBaseModel[];
    competitionFallback: SitemapItemBaseModel[];
}

export const AllowedMarketGroupSitemapItems = [SitemapItemType.Outrights, SitemapItemType.Specials] as const;
export type AllowedMarketGroupSitemapTypes = (typeof AllowedMarketGroupSitemapItems)[number];
export interface SitemapHomeModel {
    sitemap: SitemapItemModel[];
    sportsWithCompetitionsOrTournaments: SitemapItemBaseModel[];
}

export interface SitemapTooltip {
    visible: boolean;
    message?: string;
}

export interface SitemapStateModel {
    currentState: SitemapItemBaseModel[];
    siblings: SitemapItemBaseModel[];
}
export interface VirtalResolverModel {
    event: Event;
    sports: SportDetails[];
    params: Params;
}
export interface SportDetails {
    sport: Tag;
    competitions: Competition[];
}
export interface Competition {
    competition: Tag;
}
