import { ElementRef, Injectable } from '@angular/core';

import { Nullable } from '@frontend/sports/common/base-utils';

@Injectable()
export class NumpadScrollService {
    closingNumpadRef: Nullable<ElementRef> = null;

    getClosingNumpadRect(): ClientRect | null {
        return this.closingNumpadRef && this.closingNumpadRef.nativeElement.getBoundingClientRect();
    }
}
