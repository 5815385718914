import {
    ɵComponentType as ComponentType,
    ɵDirectiveType as DirectiveType,
    InjectableType,
    ɵNG_COMP_DEF as NG_COMP_DEF,
    ɵNG_DIR_DEF as NG_DIR_DEF,
    ɵNG_PROV_DEF as NG_PROV_DEF,
} from '@angular/core';

import { capitalize } from 'lodash-es';

const WIREDUP: unique symbol = Symbol('__hooks__wiredup__');
const REGISTERED_WIREDUP: unique symbol = Symbol('__hooks__wiredup__registered__');

function markWiredUp(type: any): void {
    type.prototype[WIREDUP] = true;
}

function isProvider(type: any): type is InjectableType<unknown> {
    return type.hasOwnProperty(NG_PROV_DEF);
}

function isDirective(type: any): type is DirectiveType<unknown> | ComponentType<unknown> {
    return type.hasOwnProperty(NG_COMP_DEF) || type.hasOwnProperty(NG_DIR_DEF);
}

function wireUpProvider(type: InjectableType<unknown>): void {
    type.prototype.ngOnDestroy = wireUp(type.prototype.ngOnDestroy, 'destroy');

    markWiredUp(type);
}

function wireUpDirective(type: DirectiveType<unknown> | ComponentType<unknown>): void {
    type.prototype.ngOnDestroy = wireUp(type.prototype.ngOnDestroy, 'destroy');
    type.prototype.ngOnInit = wireUp(type.prototype.ngOnInit, 'init');
    markWiredUp(type);
}

function wireUp(hook: Function | null | undefined, target: 'destroy' | 'init'): Function {
    const pre = `preNgOn${capitalize(target)}`;
    const post = `postNgOn${capitalize(target)}`;

    return function (this: any): void {
        this[pre]?.();
        hook?.call(this);
        this[post]?.();
    };
}

export function HooksWireup(): (target: any) => void {
    return function (target: any): void {
        if (!target.prototype[REGISTERED_WIREDUP]) {
            throw new Error(
                `Decorator @HooksWireup used in the class ${target.name} should only be used when class extends a hook implementation like OnDestroyCleanup or OnRouteResolve.`,
            );
        }

        if (isProvider(target)) {
            wireUpProvider(target);
        } else if (isDirective(target)) {
            wireUpDirective(target);
        } else {
            throw new Error(
                `Cannot wireup class ${target.name} as it is not decorated with @Injectable, @Component or @Directive. Also check the order of the directives, as @HooksWireup should come prior to the other ones.`,
            );
        }
    };
}

export abstract class Hook {
    get [REGISTERED_WIREDUP](): boolean {
        return true;
    }

    constructor() {
        const prototype = Object.getPrototypeOf(this);

        if (!prototype[WIREDUP]) {
            throw new Error(`To make the custom hooks work correctly, the class ${prototype.constructor.name} should be decorated with @HooksWireup`);
        }
    }
}
