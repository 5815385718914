import { ActivatedRouteSnapshot } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { AdaptiveLayoutState } from '../layout/adaptive-layout.service';
import { SubNavigationItem } from './sub-navigation.models';

export abstract class SubNavigationResolve<TModel> {
    protected tabs$ = new BehaviorSubject<SubNavigationItem[]>([]);

    get subNavigation$(): Observable<SubNavigationItem[]> {
        return this.tabs$.asObservable();
    }

    abstract buildSubNavigation(layout: AdaptiveLayoutState, routeSnapshot: ActivatedRouteSnapshot, model: TModel): void;

    cleanup(): void {
        this.tabs$.next([]);
    }
}
