import { ElementRef, Injectable } from '@angular/core';

import ResizeObserver from 'resize-observer-polyfill';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ResizeObserverService {
    private changes$ = new Subject<ResizeObserverEntry[]>();
    private elementChanges = new WeakMap<ElementRef<HTMLElement>, Observable<ResizeObserverEntry>>();
    private observer = new ResizeObserver((entries: ResizeObserverEntry[]) => this.changes$.next(entries));

    observe(element: ElementRef<HTMLElement>): Observable<ResizeObserverEntry> {
        let elementChanges$ = this.elementChanges.get(element);
        if (!elementChanges$) {
            this.observer.observe(element.nativeElement);

            elementChanges$ = new Observable<ResizeObserverEntry>((observer) => {
                const inner = this.changes$.subscribe((changes) => {
                    const change = changes.find((e) => e.target === element.nativeElement);
                    if (change) {
                        observer.next(change);
                    }
                });

                return () => {
                    inner.unsubscribe();
                    this.observer.unobserve(element.nativeElement);
                    this.elementChanges.delete(element);
                };
            }).pipe(shareReplay({ bufferSize: 1, refCount: true }));

            this.elementChanges.set(element, elementChanges$);
        }

        return elementChanges$;
    }
}
