import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';

import { DsButton } from '@frontend/ui/button';

import { ActiveModal } from '../common/modal-ref';
import { ButtonType, ContentData, DialogButton } from './modal-dialog.model';

@Component({
    selector: 'ms-message-dialog',
    templateUrl: 'message-dialog.html',
    standalone: true,
    imports: [NgIf, NgFor, DsButton],
})
export class MessageDialogComponent {
    private _contentData: ContentData = {};

    get isDesignSystem(): boolean {
        return this._contentData.buttons?.every((b) => b.buttonConfig) ?? false;
    }

    constructor(private activeModal: ActiveModal) {}

    get contentData(): ContentData {
        return this._contentData;
    }

    set contentData(data: ContentData) {
        this._contentData = data;
    }

    get shouldCloseLinkBeDisplayed(): boolean {
        return Boolean(this._contentData.showCloseLink);
    }

    closeLinkClickHandler(): void {
        this.dismiss();
    }

    buttonClickHandler(activatedButton: DialogButton): void {
        this.close(activatedButton.type);
    }

    private close(result: ButtonType): void {
        this.activeModal.close(result);
    }

    private dismiss(): void {
        this.activeModal.dismiss();
    }
}
