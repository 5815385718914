import { Injectable } from '@angular/core';

import { GridConfig } from '@frontend/sports/common/client-config-data-access';
import Decimal from 'decimal.js';

import { HappeningType, MarketType, PeriodType } from '../../cds/market-parameter.models';
import { OfferGroupingService } from '../../cds/offer-grouping.service';
import { GridEvent, Group } from '../../grid-base/grid.model';
import { EventOptionGroup } from '../model/event.model';
import { Tv2MarketParameters } from '../model/tv2.model';

@Injectable({ providedIn: 'root' })
export class OptionGroupService {
    fallbackMarketsHeaderInfo: { [key: string]: string }[] = [];

    constructor(
        private gridConfig: GridConfig,
        private offerGroupingService: OfferGroupingService,
    ) {}

    get(optionGroups: EventOptionGroup[], groupId?: string | number): EventOptionGroup | undefined {
        if (!optionGroups) {
            return;
        }

        return optionGroups
            .filter((optionGroup) => (groupId !== undefined ? optionGroup.hasGroup(groupId) : optionGroup.hasAnyGroup()))
            .sort((first, second) => this.getOrder(first) - this.getOrder(second))
            .shift();
    }

    private getOrder(optionGroup: EventOptionGroup): number {
        return optionGroup.optionSpread == null ? Number.MAX_VALUE : optionGroup.optionSpread;
    }

    private hasNonBettableOdds(eventOptionGroup: EventOptionGroup | undefined): boolean {
        return !!eventOptionGroup?.options.some((element) => element.nativePrice.odds < new Decimal(this.gridConfig.fallbackMarketOddsThreshold));
    }

    private isBettableOptionGroup(optionGroup: EventOptionGroup | undefined): boolean {
        return !!(optionGroup && optionGroup.online && optionGroup.options?.length && optionGroup.options.some((opt) => opt.online));
    }

    private setFallbackGroupsNames(event: GridEvent, gridIds: string[]): void {
        this.offerGroupingService.getGridGrouping(event.sport.id).subscribe((gridGroupConfig) => {
            gridGroupConfig?.groups?.map((group) => {
                if (gridIds.includes(group.id)) {
                    this.fallbackMarketsHeaderInfo[group.id] = group.name;
                }
            });
        });
    }

    getFallbackOptionGroup(
        group: Group,
        event: GridEvent,
        optionGroup: EventOptionGroup | undefined,
    ): { optionGroup: EventOptionGroup | undefined; isFallbackMarket: boolean; fallbackMarketName: string; isNextGoalMarket: boolean } | undefined {
        let fallbackMarketName = '';

        const fallbackGridGroupIds = group?.fallbackGridGroupIds ?? [];

        if (!this.showFallbackMarkets(fallbackGridGroupIds, optionGroup, event)) {
            return;
        }

        this.setFallbackGroupsNames(event, fallbackGridGroupIds);

        const availableOptionGroup = fallbackGridGroupIds.find((gridId) => {
            optionGroup = this.get(event.optionGroups, gridId);
            return this.isBettableOptionGroup(optionGroup) && !this.hasNonBettableOdds(optionGroup);
        });

        if (availableOptionGroup) {
            fallbackMarketName = this.fallbackMarketsHeaderInfo[availableOptionGroup];
            return { optionGroup, isFallbackMarket: true, fallbackMarketName, isNextGoalMarket: this.isNextGoalMarket(optionGroup) };
        }

        return undefined;
    }

    private isNextGoalMarket(optionGroup: EventOptionGroup | undefined): boolean {
        if (optionGroup) {
            return (
                optionGroup.parameters?.find((p) => p.key === Tv2MarketParameters.MarketType)?.value === MarketType.XthHappening &&
                optionGroup.parameters?.find((p) => p.key === Tv2MarketParameters.Happening)?.value === HappeningType.Goal &&
                optionGroup.parameters?.find((p) => p.key === Tv2MarketParameters.Period)?.value === PeriodType.RegularTime
            );
        }
        return false;
    }

    private showFallbackMarkets(fallbackGridIds: string[], optionGroup: EventOptionGroup | undefined, event: GridEvent): boolean {
        return !!fallbackGridIds?.length && (!optionGroup || !this.isBettableOptionGroup(optionGroup) || this.hasNonBettableOdds(optionGroup));
    }
}
