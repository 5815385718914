import { isObservable, of, Subject } from 'rxjs';
import { map, switchAll, distinctUntilChanged } from 'rxjs/operators';

/**
 * This Observable factory creates an Observable out of a static value or an Observable.
 *
 * @param o - the value to coerce
 */
function coerceObservable(o) {
  return isObservable(o) ? o : of(o);
}

/**
 * This operator maps an Observable out of a static value or an Observable.
 *
 */
function coerceObservableWith() {
  return o$ => map(coerceObservable)(o$);
}

/**
 * This Observable factory creates an Observable out of a static value or an Observable.
 * It forwards only distinct values from distinct incoming Observables or values.
 * This comes in handy in any environment where you handle processing of incoming dynamic values and their state.
 *
 * Optionally you can pass a flatten strategy to get find grained control of the flattening process. E.g. mergeAll, switchAll
 *
 * @param o$ - The Observable to coerce and map to a Observable with distinct values
 * @param flattenOperator - determines the flattening strategy e.g. mergeAll, concatAll, exhaust, switchAll. default is switchAll
 */
function coerceDistinctObservable(o$, flattenOperator) {
  flattenOperator = flattenOperator || switchAll();
  return coerceObservable(o$).pipe(distinctUntilChanged(), flattenOperator, distinctUntilChanged());
}

/**
 * This operator takes an Observable of values ot Observables aof values and
 * It forwards only distinct values from distinct incoming Observables or values.
 * This comes in handy in any environment where you handle processing of incoming dynamic values and their state.
 *
 * Optionally you can pass a flatten strategy to get find grained control of the flattening process. E.g. mergeAll, switchAll
 *
 * @param flattenOperator - determines the flattening strategy e.g. mergeAll, concatAll, exhaust, switchAll. default is switchAll
 *
 */
function coerceDistinctWith(flattenOperator) {
  flattenOperator = flattenOperator || switchAll();
  return o$ => o$.pipe(coerceObservableWith(), distinctUntilChanged(), flattenOperator, distinctUntilChanged());
}

/**
 * A factory function returning an object to handle the process of merging Observable next notifications into one
 *   Observable. This API takes away the clumsy handling of static values and Observable, reduces the number of
 *   emissions by:
 * - only merging distinct Observables
 * - only emit distinct values of the merged result
 *
 * You can next a Observable of `U` multiple times and merge them into the Observable exposed under one optimized
 *   `values$`
 *
 */
function coerceAllFactory(subjectFactory, flattenOperator) {
  const observablesSubject = subjectFactory ? subjectFactory() : new Subject();
  flattenOperator = flattenOperator || switchAll();
  const values$ = observablesSubject.pipe(coerceDistinctWith(flattenOperator));
  return {
    next(observable) {
      observablesSubject.next(observable);
    },
    values$
  };
}

/**
 * Generated bundle index. Do not edit.
 */

export { coerceAllFactory, coerceDistinctObservable, coerceDistinctWith, coerceObservable, coerceObservableWith };
