import { BetPlacementErrorIcon } from '../bet-placement-error-icon';
import { BetslipError } from '../betslip-error';
import { ClientErrorType } from '../client-error-type';
import { PreCheckErrorMixin } from '../pre-check/pre-check-error';

export class RewardTokensMinLegsError extends PreCheckErrorMixin(BetslipError) {
    constructor(minimumLegs: number) {
        super();
        this.icon = BetPlacementErrorIcon.Error;
        this.type = ClientErrorType.RewardTokensMinLegsError;
        this.hasClientValidation = true;
        this.minimumLegs = minimumLegs;
    }

    readonly minimumLegs: number;
}
