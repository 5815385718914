import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { DisplayType, TeamGrouping } from '@cds/betting-offer';
import { StringDictionary } from '@frontend/sports/common/base-utils';
import { MarketGroupingConfig } from '@frontend/sports/common/client-config-data-access';
import { CouponTypes } from '@frontend/sports/common/coupons-lib';
import { groupBy, keys, orderBy, replace, sortBy, toNumber, uniqBy, values } from 'lodash-es';

import { MarketType, PeriodType } from '../cds/market-parameter.models';
import { CompetitionLogosService } from '../competition-logos/competition-logos.service';
import { sortOptions } from '../event-model/helpers/option-sorting';
import {
    EventModel,
    EventOption,
    EventOptionGroup,
    EventOptionGroupType,
    EventParticipant,
    ExtendedDisplayType,
    OddsSorting,
    ResultSorting,
    SortOrder,
} from '../event-model/model/event.model';
import { Tv2MarketParameters } from '../event-model/model/tv2.model';
import { CompactEventService } from '../grid-base/compact-event.service';
import { Group } from '../grid-base/grid.model';
import { BetBuilderMarket, BetBuilderOption, PrecreatedBetBuilderModel } from '../option-group/precreated-bet-builder.model';
import { TabBarItem } from '../tab-bar/tab-bar.models';
import { ITabIndex } from './option-group-tabindex-keeper.service';

export interface OptionGroup {
    attribute: string;
    options: EventOption[];
    optionGroupId: number;
    templateId: number | undefined;
    optionAttribute: number;
    player1Id?: number;
    player2Id?: number;
}

export interface EventOptionsGrouping {
    eventOptions: EventOption[];
    groupingProperty: keyof EventOption;
    sortingProperty: keyof EventOption;
    sortingOrder?: 'asc' | 'desc';
}

export interface PlayerStatsOptionGroup {
    attribute: string;
    participant: EventParticipant | undefined;
    options: (EventOption | undefined)[];
}

@Injectable({ providedIn: 'root' })
export class OptionGroupService {
    constructor(
        private compactEventService: CompactEventService,
        private competitionLogosService: CompetitionLogosService,
        private marketGroupingConfig: MarketGroupingConfig,
    ) {}

    getPeriodTabs(optionGroup: EventOptionGroup, tabIndexes: ITabIndex): TabBarItem[] {
        if (!optionGroup || !optionGroup.getSubGroups(EventOptionGroupType.Period)) {
            return [];
        }

        const activeTabIds: string[] = [];
        const tabs: TabBarItem[] = optionGroup.getSubGroups(EventOptionGroupType.Period).map((og) => {
            activeTabIds.push(og.id);

            return {
                id: og.detailedGrouping.periodGroup || 0,
                title: og.periodName || '',
                active: false,
                attribute: og.id.split('-').pop(),
            };
        });

        if (tabs.length > 0) {
            const defaultTabIndex = this.getMilestoneMarketDefaultTab(optionGroup, tabs);
            let currentActiveTabIndex = defaultTabIndex !== -1 ? defaultTabIndex : 0;
            if (tabIndexes.lastActiveTabIndex > -1) {
                currentActiveTabIndex = activeTabIds.indexOf(tabIndexes.lastActiveTabId);
                if (currentActiveTabIndex === -1) {
                    currentActiveTabIndex = tabIndexes.lastActiveTabIndex;
                    if (currentActiveTabIndex >= activeTabIds.length) {
                        currentActiveTabIndex = activeTabIds.length - 1;
                    }
                }
            }

            tabs[currentActiveTabIndex].active = true;
        }

        return tabs;
    }

    getPeriodGroupsFromEvents(couponType: CouponTypes, events?: EventModel[]): Group[] {
        if (!events) {
            return [];
        }
        let periodGroups: Group[] = [];

        events.forEach((event) => {
            if (couponType === CouponTypes.GoalScorer) {
                const optionGroups = event.optionGroups?.filter(
                    (og) =>
                        (og.parameters?.find((p) => p.key === Tv2MarketParameters.MarketType)?.value === MarketType.Scorer ||
                            og.parameters?.find((p) => p.key === Tv2MarketParameters.MarketType)?.value === MarketType.ScorerWithoutNoScoreOption) &&
                        og.options[0]?.optionColumnId !== undefined,
                );

                optionGroups.forEach((optionGroup) => {
                    if (!optionGroup) {
                        return;
                    }
                    periodGroups.push(this.getPeriodGroup(optionGroup));
                });
            } else if (couponType === CouponTypes.CorrectScore) {
                if (event.optionGroups.length) {
                    const optionGroup = this.compactEventService
                        .prepareEventOptionGroups(event.optionGroups)
                        .find((og) => og.parameters?.find((p) => p.key === Tv2MarketParameters.MarketType)?.value === MarketType.CorrectScore);
                    if (!optionGroup) {
                        return;
                    }
                    const subGroups = optionGroup.getSubGroups(EventOptionGroupType.Period);
                    if (subGroups.length) {
                        subGroups.forEach((og) => {
                            periodGroups.push(this.getPeriodGroup(og));
                        });
                    } else {
                        periodGroups.push(this.getPeriodGroup(optionGroup));
                    }
                }
            }
            periodGroups = uniqBy(periodGroups, 'id');
        });

        return sortBy(periodGroups, (periodGroup) => Number(periodGroup.id));
    }

    private getPeriodGroup(optionGroup: EventOptionGroup): Group {
        const optionGroupPeriod = optionGroup.parameters?.find((p) => p.key === Tv2MarketParameters.Period)?.value as PeriodType;
        const group: Group = {
            id: optionGroup.detailedGrouping?.periodGroup?.toString() || '0',
            name: optionGroup.periodName || '',
            active: false,
            extended: false,
            visible: true,
            scoreBoardPeriodId: this.getScoreboardPeriodId(optionGroupPeriod),
            isFallbackGroup: false,
            fallbackGridGroupIds: [],
        };

        return group;
    }

    private getScoreboardPeriodId(optionGroupPeriod: PeriodType | undefined): number | undefined {
        switch (optionGroupPeriod) {
            case PeriodType.FirstHalf:
                return 1;
            case PeriodType.SecondHalf:
                return 3;
            default:
                return undefined;
        }
    }

    getOptionGroups(
        eventOptionGroup: EventOptionGroup,
        groupFunction: (option: EventOption) => string | number | undefined,
        orderBasedOn?: (optionGroups: OptionGroup) => number | string,
        sortorder: SortOrder = SortOrder.Ascending,
    ): OptionGroup[] {
        const groups = groupBy(eventOptionGroup.options, groupFunction);
        const optionGroups = keys(groups).map((key) => ({
            attribute: key,
            options: groups[key],
            optionGroupId: groups[key][0].optionGroupId,
            templateId: groups[key][0].templateId,
            optionAttribute: toNumber(replace(groups[key][0].optionAttribute ?? groups[key][0].marketAttribute ?? '', ',', '.')),
            player1Id: groups[key][0].player1Id,
            player2Id: groups[key][0].player2Id,
        }));

        return orderBy(optionGroups, orderBasedOn ?? ((g) => toNumber(replace(g.attribute, ',', '.'))), sortorder);
    }

    private getDisplayTypeGrouping(displayTypeGrouping: EventOptionsGrouping): EventOption[][] {
        const eventOptions = orderBy(
            displayTypeGrouping.eventOptions,
            [displayTypeGrouping.sortingProperty],
            [displayTypeGrouping.sortingOrder ?? 'asc'],
        );
        const groupedData = groupBy(eventOptions, (group) => group[displayTypeGrouping.groupingProperty]);

        return values(groupedData);
    }

    getOptionGroupsFilteredBySpread(
        eventOptionGroup: EventOptionGroup,
        groupFunction: (option: EventOption) => string | number | undefined,
    ): OptionGroup[] {
        const groups = groupBy(eventOptionGroup.options, groupFunction);
        const optionGroups = keys(groups).map((key) => this.mapOptionGroup(groups, key));

        return sortBy(optionGroups, (group) => toNumber(replace(group.attribute, ',', '.')));
    }

    getScoreMarketGroups(eventOptions: EventOption[]): EventOption[][] {
        const groupingProperty: EventOptionsGrouping = {
            eventOptions,
            groupingProperty: 'marketAttribute',
            sortingProperty: 'marketAttribute',
        };

        return this.getDisplayTypeGrouping(groupingProperty);
    }

    getYesNoMarketGroups(eventOptions: EventOption[]): EventOption[][] {
        const groupingProperty: EventOptionsGrouping = {
            eventOptions,
            groupingProperty: 'player1',
            sortingProperty: 'player1',
        };

        return this.getDisplayTypeGrouping(groupingProperty);
    }

    private mapOptionGroup(groups: StringDictionary<EventOption[]>, key: string): OptionGroup {
        const optionsGroup = groups[key].length > 2 ? this.getOptionsSortedBySpread(groups[key]) : groups[key];

        return <OptionGroup>{
            attribute: key,
            options: optionsGroup,
            optionGroupId: optionsGroup[0].optionGroupId,
            templateId: optionsGroup[0].templateId,
        };
    }

    private getOptionsSortedBySpread(eventOptions: EventOption[]): EventOption[] {
        const groups = groupBy(eventOptions, (option) => option.optionGroupId);
        const optionGroupKey = keys(groups)
            .sort((first, second) => this.getOrder(groups[first][0]) - this.getOrder(groups[second][0]))
            .shift();

        return optionGroupKey ? groups[optionGroupKey] : eventOptions;
    }

    private getOrder(eventOption: EventOption): number {
        return !!eventOption.optionSpread || eventOption.optionSpread === 0 ? eventOption.optionSpread : Number.MAX_VALUE;
    }

    areImagesAvailable(event: EventModel): boolean {
        return event.participants.some((participant) => {
            if (!participant.image) {
                return false;
            }
            const isLogoAvailable =
                participant.image.logo &&
                (participant.image.isParticipantProfile || this.competitionLogosService.hasLogo(event.sport.id, event.league.id));
            const isJerseyAvailable = participant.image.jersey && !participant.image.logo;

            return !!(isJerseyAvailable || isLogoAvailable);
        });
    }

    hasLogo(sportId?: number | undefined, leagueId?: number | undefined): boolean {
        return this.competitionLogosService.hasLogo(sportId, leagueId);
    }

    isTeamGroupingEnabled(event: EventModel, optionGroup: EventOptionGroup, threshold: number) {
        return optionGroup.detailedGrouping.teamGrouping !== TeamGrouping.None && this.hasMinRequiredPlayers(event, optionGroup, threshold);
    }

    private hasMinRequiredPlayers(event: EventModel, optionGroup: EventOptionGroup, threshold: number): boolean {
        const homeTeamId = optionGroup.source === OfferSource.V2 && !!event.hybridFixtureData ? event.teams[3]?.id : event.participants[0]?.id;
        let options = this.getOptionGroupFilteredByTeam(event, optionGroup, homeTeamId).options;
        const shouldSkipUniqBy = this.shouldSkipUniqByFilter(optionGroup.detailedGrouping.displayType);

        if (this.hasOptionsGreaterThanEqualToThreshold(options, threshold, shouldSkipUniqBy)) {
            const awayTeamId = optionGroup.source === OfferSource.V2 && !!event.hybridFixtureData ? event.teams[2]?.id : event.participants[1].id;
            options = this.getOptionGroupFilteredByTeam(event, optionGroup, awayTeamId).options;

            return this.hasOptionsGreaterThanEqualToThreshold(options, threshold, shouldSkipUniqBy);
        }

        return false;
    }

    private hasOptionsGreaterThanEqualToThreshold(options: EventOption[], threshold: number, shouldSkipUniqBy: boolean): boolean {
        if (options.length) {
            if (!shouldSkipUniqBy) {
                // Get the uniq options as the multiple options will have same player1Id(player props) or playerId(player stats).
                options = uniqBy(options, (option) => option.player1Id || option.playerId);
            }

            return options.length >= threshold;
        }

        return false;
    }

    getOptionGroupFilteredByTeam(event: EventModel, optionGroup: EventOptionGroup, teamId: number | undefined): EventOptionGroup {
        const filteredOptionGroup = Object.assign({}, optionGroup);
        filteredOptionGroup.options = [];
        const players = event.players.filter((p) => p.teamId === teamId);
        players.forEach((p) => {
            filteredOptionGroup.options.push(
                ...optionGroup.options.filter(
                    (o) => o.player1Id === p.id || o.playerId === p.id || (p.fixtureParticipantId && p.fixtureParticipantId === o.playerId),
                ),
            );
        });

        return filteredOptionGroup;
    }

    getOptionGroupFilteredByOdds(optionGroup: EventOptionGroup, sortingOrder: OddsSorting): EventOptionGroup {
        const options = sortOptions(optionGroup.options, ResultSorting.Odds);
        // SortBy function in sortOptions will return the odds in AscendingOrder only.
        optionGroup.options = options;

        if (sortingOrder === OddsSorting.DescendingOrder) {
            optionGroup.options = options.reverse();
        }

        return optionGroup;
    }

    private shouldSkipUniqByFilter(displayType: DisplayType | ExtendedDisplayType): boolean {
        switch (displayType) {
            case DisplayType.GoalScorer:
                return true;
            default:
                return false;
        }
    }

    mapEventOptionGroup(fixtureId: string, optionGroup?: EventOptionGroup): PrecreatedBetBuilderModel | undefined {
        if (!optionGroup) {
            return;
        }

        const markets = this.mapEventOptionGroupToMarkets(optionGroup);

        return {
            fixtureId,
            hasMarkets: optionGroup.options.length !== 0,
            themedTabMarkets: markets.filter((m) => !m.ambassador),
        };
    }

    private mapEventOptionGroupToMarkets(optionGroup: EventOptionGroup): BetBuilderMarket[] {
        return optionGroup.options.map((market) => this.mapCommonMarketData(market, optionGroup.periodName));
    }

    private mapCommonMarketData(responseMarket: EventOption, themedTab?: string): BetBuilderMarket {
        return {
            id: responseMarket.optionGroupId,
            name: responseMarket.name,
            nameSign: responseMarket.signature.option,
            themedTab,
            option: this.mapOption(responseMarket),
            ...(responseMarket.longId?.length && { longIds: responseMarket.longId.split(';') }),
        };
    }

    private mapOption(responseOption: EventOption): BetBuilderOption {
        const option = {
            id: responseOption.id,
            optionPrice: this.mapOptionPrice(responseOption),
        };

        return option;
    }

    private mapOptionPrice(responseOption: EventOption): EventOption {
        const option = {
            id: Number(responseOption.priceId),
            marketAttribute: '',
            name: responseOption.name,
            nativePrice: responseOption.nativePrice,
            online: responseOption.online,
            optionGroupId: responseOption.optionGroupId,
            order: responseOption.order,
            originalEventId: responseOption.originalEventId,
            priceId: responseOption.priceId,
            signature: responseOption.signature,
            visible: responseOption.visible,
            isBetBuilder: responseOption.isBetBuilder,
        };

        return option;
    }

    private getMilestoneMarketDefaultTab(optionGroup: EventOptionGroup, tabs: TabBarItem[]): number {
        const marketType = optionGroup.parameters?.find((p) => p.key === Tv2MarketParameters.MarketType)?.value;
        const formattedMarketType = marketType && marketType?.charAt(0)?.toLowerCase() + marketType?.slice(1);
        const marketIdentifier = formattedMarketType + ':' + optionGroup.parameters?.find((p) => p.key === Tv2MarketParameters.Happening)?.value;
        const defaultTab = this.marketGroupingConfig.milestoneMarketsDefaultTab[marketIdentifier];

        return tabs.findIndex((tab) => tab.id === defaultTab);
    }

    getOptionHeaders(optionGroup: EventOption[]): (string | undefined)[] {
        const groups = groupBy(optionGroup, (option) => option.order);

        return keys(groups)
            .sort((first, next) => Number(first) - Number(next))
            .map((key) => (key === 'undefined' ? undefined : `${key}+`));
    }

    private getOptions(optionGroup: EventOption[], columns: (string | undefined)[], optionAttribute?: boolean): (EventOption | undefined)[] {
        const options: (EventOption | undefined)[] = [];
        for (const column of columns) {
            const option = optionGroup.find((o) => o.order === (column ? Number(column.replace('+', '')) : column));
            if (option && optionAttribute && column) {
                option.optionAttribute = `${column}`;
            }
            // Let undefined also include in options when the coulmn doesn't have the option/price and UI will show the option as empty.
            options.push(option);
        }

        return options;
    }

    getPlayerStatsOptionGroups(
        optionGroup: EventOptionGroup,
        event: EventModel,
        columns: (string | undefined)[],
        showHeaders: boolean,
        optionAttribute?: boolean,
    ): PlayerStatsOptionGroup[] {
        const groups = groupBy(optionGroup.options, (option) => option.name);
        const mappedGroups = keys(groups).map((key) => ({
            attribute: key,
            participant: event.participants.find(
                (e) => e.id === event.players.find((p) => p.fixtureParticipantId === groups[key][0].playerId)?.teamId,
            ),
            options: this.getOptions(groups[key], columns, optionAttribute),
        }));

        if (showHeaders) {
            mappedGroups.unshift({ attribute: '', participant: undefined, options: [] });
        }

        return sortBy(mappedGroups, (group: PlayerStatsOptionGroup) =>
            // Sort only the first column option price of each player and move empty to the top.
            group.options[0]?.nativePrice ? group.options[0].nativePrice.odds.toNumber() : 0,
        );
    }
}
