import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { HooksWireup, Nullable, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { takeUntil } from 'rxjs/operators';

import { StakeValidationResult } from '../betslip-base/stake-validation/stake-validation.models';
import { NumpadHostOptions } from './model';
import { NumpadDisplayService } from './numpad-display.service';
import { NumpadScrollService } from './numpad-scroll.service';
import { NumpadComponent } from './numpad.component';
import { NumpadService } from './numpad.service';

export class NumpadAnimationConfig {
    static maxHeight = 165;
    static duration = 300;
}

@HooksWireup()
@Component({
    selector: 'ms-numpad-host',
    templateUrl: './numpad-host.html',
    animations: [
        trigger('slideInOut', [
            state(
                'in',
                style({
                    'max-height': `${NumpadAnimationConfig.maxHeight}px`,
                }),
            ),
            state(
                'out',
                style({
                    'max-height': 0,
                }),
            ),
            transition('* => *', animate(`${NumpadAnimationConfig.duration}ms ease-in-out`)),
        ]),
    ],
    providers: [NumpadScrollService],
})
export class NumpadHostComponent extends OnDestroyCleanup implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() error: Nullable<StakeValidationResult>;

    @ViewChild('numpadhost', { read: ViewContainerRef, static: true })
    private host: ViewContainerRef;

    opened = false;
    private removeAfterTransition = false;

    constructor(
        private injector: Injector,
        private displayService: NumpadDisplayService,
        private changeDetector: ChangeDetectorRef,
        private scrollService: NumpadScrollService,
        public elementRef: ElementRef,
    ) {
        super();
    }

    ngOnInit(): void {
        this.displayService.open$.pipe(takeUntil(this.destroyed$)).subscribe((options) => this.closeOrOpen(options));
        this.displayService.close$.pipe(takeUntil(this.destroyed$)).subscribe(() => this.closeNumpad());
    }

    ngOnDestroy(): void {
        if (this.opened) {
            this.cleanHost();

            // Inform display service about destroying and closing keypad
            this.displayService.close();
        }
    }

    onTransitionEnd(): void {
        if (!this.removeAfterTransition) {
            return;
        }

        this.removeAfterTransition = false;
        this.scrollService.closingNumpadRef = null;

        if (this.opened) {
            return;
        }

        this.cleanHost();
    }

    private cleanHost(): void {
        this.host.remove();
    }

    private closeOrOpen(options: NumpadHostOptions): void {
        if (this.id === options.id) {
            if (this.opened) {
                this.cleanHost();
            }

            this.createNumpad(options.numpadService);
        } else {
            this.closeNumpad();
        }
    }

    private createNumpad(numpadService: NumpadService): void {
        this.createComponent(numpadService);

        this.opened = true;
        this.changeDetector.markForCheck();
    }

    private createComponent(numpadService: NumpadService): void {
        // When opening and closing the numpad quickly the numpad creation can happen before
        // the old numpad component is removed (onTransitionEnd).
        // This check ensurres that the previous numpad is definitely removed.
        if (this.host.length > 0) {
            this.cleanHost();
        }

        this.host.createComponent(NumpadComponent, { index: 0, injector: this.createCustomInjector(numpadService) });
    }

    private closeNumpad(): void {
        if (!this.opened) {
            return;
        }

        this.scrollService.closingNumpadRef = this.elementRef;
        this.opened = false;
        this.removeAfterTransition = true;
        this.changeDetector.markForCheck();
        setTimeout(this.onTransitionEnd.bind(this), NumpadAnimationConfig.duration);
    }

    private createCustomInjector(numpadService: NumpadService): Injector {
        return Injector.create({
            providers: [{ provide: NumpadService, useValue: numpadService }],
            parent: this.injector,
        });
    }
}
