import { Injectable } from '@angular/core';

import { BaseTranslationConfig } from '@frontend/sports/common/client-config-data-access';
import { Page } from '@frontend/vanilla/core';

import IAlternativeTranslationsService from './alternative-translations.service';

@Injectable()
export default class AlternativeTranslationsService implements IAlternativeTranslationsService {
    constructor(
        private page: Page,
        private baseTranslationConfig: BaseTranslationConfig,
    ) {}

    useBaseTranslations(): boolean {
        if (!this.baseTranslationConfig.isEnabled) {
            return false;
        }

        return this.baseTranslationConfig.defaultLanguage.toUpperCase() !== this.page.lang.toUpperCase();
    }
}
