import { Injectable } from '@angular/core';
import { ActivationEnd, ActivationStart, GuardsCheckEnd, NavigationEnd, Router, RoutesRecognized } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { buffer, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouterEventsService {
    private currentRouteActivationEnd = new BehaviorSubject<ActivationEnd | undefined>(undefined);
    private currentRouteActivationStart = new BehaviorSubject<ActivationStart | undefined>(undefined);
    private currentRouteRoutesRecognized = new BehaviorSubject<RoutesRecognized | undefined>(undefined);

    /**
     *
     * It filters all the ActivationEnd events that occured before NavigationEnd and takes the very first one
     * That's because angular will emit multiple ActivationEnd event for each child route until entire route tree is resolved
     * and NavigationEnd indicates the end as expected
     *
     * @readonly
     * @type {Observable<ActivationEnd>}
     * @memberof RouterEventsService
     */
    get currentActivationEnd(): Observable<ActivationEnd | undefined> {
        return this.currentRouteActivationEnd.asObservable();
    }

    /**
     *
     * It filters all the ActivationStart events that occured before GuardsCheckEnd and takes the very first one
     * That's because angular will emit multiple ActivationStart event for each child route until entire route tree is resolved
     * and GuardsCheckEnd indicates the end as expected
     *
     * @readonly
     * @type {Observable<ActivationStart>}
     * @memberof RouterEventsService
     */
    get currentActivationStart(): Observable<ActivationStart | undefined> {
        return this.currentRouteActivationStart.asObservable();
    }

    /**
     *
     * It filters all the NavigationStart
     *
     * @readonly
     * @type {Observable<ActivationStart>}
     * @memberof RouterEventsService
     */
    get currentRoutesRecognized(): Observable<RoutesRecognized | undefined> {
        return this.currentRouteRoutesRecognized.asObservable();
    }

    constructor(private router: Router) {
        this.initCurrentRouteActivationEnd();
        this.initCurrentRouteActivationStart();
        this.initCurrentRouteRoutesRecognized();
    }

    private initCurrentRouteActivationEnd(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof ActivationEnd),
                buffer(this.router.events.pipe(filter((e) => e instanceof NavigationEnd))),
                map((events) => events[0] as ActivationEnd),
                distinctUntilChanged(),
            )
            .subscribe(this.currentRouteActivationEnd);
    }

    private initCurrentRouteActivationStart(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof ActivationStart),
                buffer(this.router.events.pipe(filter((e) => e instanceof GuardsCheckEnd))),
                map((events) => events[events.length - 1] as ActivationStart),
                distinctUntilChanged(),
            )
            .subscribe(this.currentRouteActivationStart);
    }

    private initCurrentRouteRoutesRecognized(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof RoutesRecognized),
                map((event) => event as RoutesRecognized),
                distinctUntilChanged(),
            )
            .subscribe(this.currentRouteRoutesRecognized);
    }
}
