import { InjectionToken, Type } from '@angular/core';

import { Widget, WidgetType } from '@frontend/sports/types/components/widget';
import { Observable } from 'rxjs';

export interface WidgetSkeletonModel<T = unknown> {
    widget?: Widget<T>;
    showSkeleton$: Observable<boolean>;
}
export interface WidgetSkeletonConfig {
    visible: boolean;
    loaderClass?: string;
}
// so we can inject it
export class WidgetSkeletonData<T = unknown> {
    widget: Widget<T>;
    widgetsBeforeLoaded$: Observable<boolean>;
    config: (payload: T) => Observable<WidgetSkeletonConfig>;
}
export interface WidgetSkeletonDefinition<T> {
    config: (payload: T) => Observable<WidgetSkeletonConfig>;
    type: WidgetType;
    component: Type<WidgetSkeletonModel>;
}

export const WIDGET_SKELETON = new InjectionToken<WidgetSkeletonDefinition<unknown>>('ms-widget-skeleton');
