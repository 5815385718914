import { Injectable, Injector, Type } from '@angular/core';
import { ActivationEnd } from '@angular/router';

import { RouteTag } from '@frontend/sports/common/base-utils';
import { LayoutNavigationConfig } from '@frontend/sports/common/client-config-data-access';
import { OnAppInit } from '@frontend/vanilla/core';

import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { SlotComponentOptions, SlotLayoutService } from '../layout/slot-layout.service';
import { TopNavigationVersion } from '../navigation-core/navigation-core.models';
import { RouterEventsService } from '../navigation-core/router-events.service';
import { NavigationService } from '../navigation/navigation.service';
import { NavigationComponent } from '../navigation/top-navigation/navigation.component';
import { SubNavigationComponent } from '../sub-navigation/sub-navigation.component';
import { SitemapModuleLoaderService } from './sitemap-module-loader.service';

@Injectable({
    providedIn: 'root',
})
export class SlotLayoutRouteOverrideService implements OnAppInit {
    private initialSettings?: SlotComponentOptions;
    private component: Type<any>;

    constructor(
        private injector: Injector,
        private routerEvents: RouterEventsService,
        private slotLayout: SlotLayoutService,
        private layoutNavConfig: LayoutNavigationConfig,
        private sitemapModuleLoaderService: SitemapModuleLoaderService,
        private navService: NavigationService,
    ) {}

    onAppInit(): void | Promise<any> {
        this.slotLayout.setup();
        this.slotLayout.register(BreadcrumbsComponent, { order: 10 });

        if (this.layoutNavConfig.topNavigationVersion === TopNavigationVersion.V2) {
            this.navService.init();
            this.slotLayout.register(NavigationComponent, { order: 0, fixed: true });
            this.slotLayout.register(SubNavigationComponent, { order: 100, fixed: true });
            this.routerEvents.currentActivationEnd.subscribe((e) => this.routeChanged(e));
        } else if (this.layoutNavConfig.topNavigationVersion === TopNavigationVersion.V3) {
            this.sitemapModuleLoaderService.loadAndInitModule().subscribe((module) => {
                const initService = this.injector.get(module.sitemapBootstrapService);
                if (initService) {
                    initService.resolversMap = module.sitemapResolversMap;
                    initService.onAppInit();
                }
                this.slotLayout.register(module.sitemapComponent, { order: 0, fixed: true });
                this.slotLayout.register(SubNavigationComponent, { order: 100, fixed: true });
                this.routerEvents.currentActivationEnd.subscribe((e) => this.routeChanged(e));
            });
        }
    }

    private routeChanged(event?: ActivationEnd): void {
        if (!event) {
            return;
        }

        if (event.snapshot.data && event.snapshot.data.tag === RouteTag.HorseRaceDetails) {
            this.override(SubNavigationComponent, { fixed: false });
        } else {
            this.destroy();
        }
    }

    override(component: Type<any>, options: SlotComponentOptions): void {
        if (this.initialSettings) {
            return;
        }

        this.component = component;
        this.initialSettings = this.slotLayout.getConfig(component);

        if (!this.initialSettings) {
            throw new Error(`Could not override component ${component} - as it was not registered`);
        }

        this.slotLayout.override(component, options);
    }

    private destroy(): void {
        if (this.initialSettings) {
            this.slotLayout.override(this.component, this.initialSettings);
            this.initialSettings = undefined;
        }
    }
}
