import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[msStopEventPropagation]',
})
export class StopEventPropagationDirective implements OnInit, OnDestroy {
    @Input('msStopEventPropagation') eventsList: string;
    private eventHandlerRemovers: Array<() => void> = [];

    constructor(
        public element: ElementRef,
        public renderer: Renderer2,
    ) {}

    ngOnInit(): void {
        if (this.eventsList) {
            const events = this.eventsList.split(' ');
            events.forEach((event) => {
                const remover = this.renderer.listen(this.element.nativeElement, event, this.eventHandler);
                this.eventHandlerRemovers.push(remover);
            });
        }
    }

    ngOnDestroy(): void {
        this.eventHandlerRemovers.forEach((remover) => remover());
    }

    private eventHandler = (event: any) => {
        if (event.type === 'touchstart') {
            // HACK
            // stopping the propagation of a touchstart event causes problems with FastClick
            // because the property `targetElement` is not reset inside the FastClick library.
            // When that happens the first click on the interface is missed
            event.touchStartEventPropagationStopped = true;
        } else {
            event.stopPropagation();
        }
    };
}
