import { NgClass } from '@angular/common';
import { Component } from '@angular/core';

import { HooksWireup, OnDestroyCleanup } from '@frontend/sports/common/base-utils';
import { takeUntil } from 'rxjs/operators';

import { AdaptiveLayoutService } from '../layout/adaptive-layout.service';
import { UrlHelperService } from '../navigation-core/url-helper.service';
import { NavigationService } from '../navigation/navigation.service';
import { TabBarEvent } from '../tab-bar/tab-bar.models';
import { TabBarModule } from '../tab-bar/tab-bar.module';
import { TrackNavArea } from '../tracking/tracking.models';
import { SubNavigationItem } from './sub-navigation.models';
import { SubNavigationService } from './sub-navigation.service';

@HooksWireup()
@Component({
    selector: 'ms-sub-navigation',
    templateUrl: 'sub-navigation.html',
    imports: [NgClass, TabBarModule],
    standalone: true,
})
export class SubNavigationComponent extends OnDestroyCleanup {
    tabs: SubNavigationItem[] = [];
    constructor(
        private subnavigation: SubNavigationService,
        public adaptiveLayout: AdaptiveLayoutService,
        private urlHelperService: UrlHelperService,
        private navigationService: NavigationService,
    ) {
        super();
        this.subnavigation.tabs$.pipe(takeUntil(this.destroyed$)).subscribe(this.tabsChanged);
    }

    selected(event: TabBarEvent): void {
        if (!event.active.url) {
            return;
        }
        const url = this.urlHelperService.getLastSegment(event.active.url);
        this.navigationService.tracking(url, TrackNavArea.NavSub);
    }

    private tabsChanged = (newTabs: SubNavigationItem[]): void => {
        this.tabs.length = 0;
        this.tabs.push(...newTabs);
    };
}
