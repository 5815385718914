import { Observable } from 'rxjs';

export enum FreshDataType {
    DataAndPush,
    Data,
}

export abstract class FreshDataProvider {
    abstract isRefreshNeeded(): Observable<FreshDataType>;
}
