import { RewardTargetType } from '@bpos/v1/sports-promo/tokens';
import { createSelector } from '@ngrx/store';

import { betslipSelector, selectIsLinearBetslip } from '../../base/store/selectors';
import { BetslipType } from '../../core/betslip-type';
import { BetslipV2OptionMarketPick } from '../../core/picks/betslip-v2-option-market-pick';
import { PickId, pickIdFactory } from '../../core/picks/pick-id';
import { betslipPicksListSelector } from '../picks/selectors';
import {
    selectSingleBetSinglePickId,
    singleBetPickSelectorFactory,
    singleBetPicksSelector,
    singleBetSelectedPicksSelector,
} from '../single-bet/selectors';
import { betslipCurrentTypeSelector } from '../types/base/selectors';
import { betslipTypeStateSelector } from '../types/selectors';
import { selectHasComboPreventionForTypeFactory, selectHasMinSelectionsBetBuilderError } from '../validation/selectors';
import { RewardTokenContext } from './reward-tokens.model';
import {
    getBestOddsGuaranteedTokensContext,
    getSelectedBetStationFreeBetToken,
    getSelectedBogToken,
} from './services/betstation-reward-tokens.utils';
import {
    getAllAssignedTokens,
    getAllSelectedTokens,
    getEligibilityStateForContext,
    getSelectedTokenAndEligibilityForContext,
    getSelectedTokenForContext,
    getTokenSelectionContext,
} from './services/linear-reward-tokens.utils';
import {
    getAcquisitionRewardToken,
    getAllEligibleTokens,
    getNonAccaTokens,
    isAcquisitionEligible,
    isFreebetToken,
    isOddsBoostToken,
} from './services/reward-tokens.utils';

export const rewardTokensStateSelector = createSelector(betslipSelector, (state) => state.rewardTokens);
export const rewardTokensOnBoardingSelector = createSelector(rewardTokensStateSelector, (state) => state.onboardingState);

export const selectRewardTokens = createSelector(rewardTokensStateSelector, (state) => state.tokens);
export const selectRewardTokensList = createSelector(selectRewardTokens, (tokens) => Object.values(tokens));

export const selectTokensEligibilityState = createSelector(rewardTokensStateSelector, (state) => state.eligibilityState);

export const selectHasNewCustomerOfferPick = createSelector(betslipPicksListSelector, (pickList) =>
    pickList.some((pick) => pick.isNewCustomerOfferPick),
);

export const selectTokensStateContext = createSelector(
    selectTokensEligibilityState,
    selectRewardTokens,
    betslipTypeStateSelector,
    (eligibilityState, tokens, types) => ({
        eligibilityState,
        tokens,
        types,
    }),
);

export const selectAllEligibleTokens = createSelector(selectTokensEligibilityState, selectRewardTokens, (eligibilityState, tokens) =>
    getAllEligibleTokens(eligibilityState, tokens),
);

export const selectNonAccaTokens = createSelector(selectRewardTokensList, (tokens) => {
    return getNonAccaTokens(tokens);
});

export const selectTokenForContextFactory = (context: RewardTokenContext) => {
    return createSelector(selectTokensStateContext, ({ tokens, types }) => getSelectedTokenForContext(context, tokens, types));
};

export const selectTokenAndEligibilityFactory = (context: RewardTokenContext) => {
    return createSelector(selectTokensStateContext, ({ tokens, eligibilityState, types }) =>
        getSelectedTokenAndEligibilityForContext(context, eligibilityState, tokens, types),
    );
};

export const selectEligibleTokenFactory = (context: RewardTokenContext) => {
    return createSelector(selectTokenAndEligibilityFactory(context), ([token, eligibility]) => (eligibility?.isEligible ? token : null));
};

export const selectFreeBetTokenFactory = (context: RewardTokenContext) => {
    return createSelector(selectEligibleTokenFactory(context), (token) => (isFreebetToken(token) ? token : null));
};

export const selectOddsBoostTokenFactory = (context: RewardTokenContext) => {
    return createSelector(selectEligibleTokenFactory(context), (token) => (isOddsBoostToken(token) ? token : null));
};

export const selectAllSelectedTokens = createSelector(selectTokensStateContext, ({ tokens, types }) => getAllSelectedTokens(tokens, types));

export const selectAllAssignedTokens = createSelector(selectTokensStateContext, ({ tokens, types }) => getAllAssignedTokens(tokens, types));

export const selectEligibilityStateFactory = (context: RewardTokenContext) => {
    return createSelector(selectTokensEligibilityState, (eligibilityState) => getEligibilityStateForContext(context, eligibilityState));
};

export const selectAllTokensAndEligibilityStateFactory = (context: RewardTokenContext) => {
    return createSelector(selectRewardTokens, selectEligibilityStateFactory(context), (tokens, eligibilityState) => ({
        tokens,
        eligibilityState,
    }));
};

export const selectSelectedTokenForContext = (context: RewardTokenContext) => {
    return createSelector(selectRewardTokens, betslipTypeStateSelector, (tokens, types) => {
        return getSelectedTokenForContext(context, tokens, types);
    });
};

export const selectHasVisibleTokensForContext = (context: RewardTokenContext) => {
    return createSelector(
        selectAllSelectedTokens,
        selectTokenForContextFactory(context),
        selectEligibilityStateFactory(context),
        (allSelectedTokens, selectedToken, eligibilityState) => {
            return eligibilityState.some((eligibility) => {
                const currentSelected = selectedToken?.userTokenId === eligibility.tokenId;

                // this makes sure that already selected tokens in a different slip don't get shown
                return currentSelected || (eligibility.isEligible && allSelectedTokens.every((token) => token.userTokenId !== eligibility.tokenId));
            });
        },
    );
};

export const selectUnselectedSingleBetPickForAcquisitionToken = createSelector(
    singleBetPicksSelector,
    selectRewardTokensList,
    betslipCurrentTypeSelector,
    selectIsLinearBetslip,
    (singleBetPicks, tokens, currentSelectedType, isLinear) => {
        const acquisitionRewardToken = getAcquisitionRewardToken(tokens);

        if (!acquisitionRewardToken || (!isLinear && currentSelectedType !== BetslipType.Single)) {
            return;
        }

        const unselectedPicks = Object.entries(singleBetPicks)
            .filter(([, pick]) => !pick.isSelected && pick.rewardTokenId === acquisitionRewardToken.userTokenId)
            .map(([k]) => k);

        return unselectedPicks.length > 0 ? { pickId: unselectedPicks[0] } : undefined;
    },
);

export const selectSingleBetPickForAcquisitionToken = createSelector(
    singleBetSelectedPicksSelector,
    selectRewardTokensList,
    selectTokensEligibilityState,
    betslipCurrentTypeSelector,
    selectIsLinearBetslip,
    selectAllSelectedTokens,
    selectHasMinSelectionsBetBuilderError,
    (selectedPicks, tokens, eligibilityState, currentSelectedType, isLinear, allSelectedTokens, hasMinSelectionsBetBuilderError) => {
        const acquisitionRewardToken = getAcquisitionRewardToken(tokens);
        const isAcquisitionTokenSelected = allSelectedTokens.some((x) => x.rewardTargetType === RewardTargetType.WelcomeOffer);

        if (
            !acquisitionRewardToken ||
            (!isLinear && currentSelectedType !== BetslipType.Single) ||
            isAcquisitionTokenSelected ||
            hasMinSelectionsBetBuilderError
        ) {
            return;
        }

        const acquisitionEligiblePicks = Object.entries(eligibilityState.singleBet)
            .filter(
                ([pickId, eligibilityTokens]) =>
                    selectedPicks[pickId] &&
                    !selectedPicks[pickId].rewardTokenId &&
                    eligibilityTokens.some((x) => isAcquisitionEligible(x, acquisitionRewardToken.userTokenId)),
            )
            .map(([k]) => k);

        return acquisitionEligiblePicks.length > 0 ? { pickId: acquisitionEligiblePicks[0], tokenId: acquisitionRewardToken.userTokenId } : undefined;
    },
);

export const selectSingleBetStandardRewardTokensContext = createSelector(singleBetPicksSelector, (singleBetPicks) => {
    const selectedPicks = Object.entries(singleBetPicks)
        .filter(([, p]) => p.isSelected)
        .map(([k]) => k);

    return selectedPicks.length > 0
        ? {
              betslipType: BetslipType.Single,
              pickId: pickIdFactory(selectedPicks[0]),
          }
        : undefined;
});

export const selectHasEachWayOptionPick = createSelector(
    betslipPicksListSelector,
    betslipTypeStateSelector,
    (pickList, { base, singleBet, comboBet, systemBet }) => {
        if (!pickList.some(BetslipV2OptionMarketPick.isPick)) {
            return false;
        }

        switch (base.currentSelectedType) {
            case BetslipType.Single:
                return pickList.filter(BetslipV2OptionMarketPick.isPick).some((pick) => {
                    const pickState = singleBet.picks[pick.id.toString()];

                    return !!pickState && pickState.isEachWay && pickState.isSelected;
                });

            case BetslipType.Combo:
                return comboBet.isEachWay;
            case BetslipType.System:
                return systemBet.isEachWay;
            default:
                return false;
        }
    },
);

export const selectRewardTokensOnboardingContext = createSelector(
    selectIsLinearBetslip,
    selectSingleBetStandardRewardTokensContext,
    betslipCurrentTypeSelector,
    (isLinear, singleBetRewardTokensContext, currentType) => {
        if (isLinear) {
            return singleBetRewardTokensContext;
        }

        switch (currentType) {
            case BetslipType.Combo:
                return {
                    betslipType: BetslipType.Combo,
                };

            case BetslipType.Single:
                return singleBetRewardTokensContext;

            case BetslipType.Teaser:
                return {
                    betslipType: BetslipType.Teaser,
                };

            default:
                return undefined;
        }
    },
);

export const selectRewardTokensOnboardingEligibility = createSelector(
    selectTokensEligibilityState,
    selectRewardTokensOnboardingContext,
    (eligibility, context) => {
        return context ? getEligibilityStateForContext(context, eligibility) : [];
    },
);

export const selectRewardTokensOnboardingEligibileTokens = createSelector(
    selectRewardTokensOnboardingEligibility,
    selectRewardTokens,
    (eligibility, tokens) => {
        return eligibility.filter((e) => e.isEligible).map((e) => tokens[e.tokenId]);
    },
);

export const selectAcquisitionRewardToken = createSelector(selectRewardTokensList, (tokens) => getAcquisitionRewardToken(tokens));
export const selectRewardTokensOnboardingRewardSelectorVisible = createSelector(
    selectHasEachWayOptionPick,
    selectHasNewCustomerOfferPick,
    selectRewardTokensOnboardingEligibileTokens,
    (hasEachWayOption, hasNewCustomerOffer, tokens) => !hasEachWayOption && !hasNewCustomerOffer && tokens.length > 0,
);

export const selectRewardTokensOnboardingDisplayState = createSelector(
    rewardTokensOnBoardingSelector,
    selectRewardTokensOnboardingEligibileTokens,
    selectRewardTokensOnboardingRewardSelectorVisible,
    selectAcquisitionRewardToken,
    (onboarding, onboardingTokens, rewardsSelectorVisible, acquisitionRewardToken) => {
        return { isVisible: onboarding.visible && rewardsSelectorVisible && !acquisitionRewardToken, tokens: onboardingTokens };
    },
);

export const selectAcquisitionRewardOnboardingDisplayState = createSelector(
    rewardTokensOnBoardingSelector,
    selectRewardTokensOnboardingRewardSelectorVisible,
    (onboarding, rewardsSelectorVisible) => ({
        acquisitionHidden: onboarding.acquisitionHidden && rewardsSelectorVisible,
    }),
);

export const selectHasAcquisitionRewardOnboardingForContext = (context: RewardTokenContext) => {
    return createSelector(selectSelectedTokenForContext(context), selectAcquisitionRewardOnboardingDisplayState, (selectedToken, displayState) => {
        return selectedToken?.rewardTargetType === RewardTargetType.WelcomeOffer && !displayState.acquisitionHidden;
    });
};

export const selectBetstationTokensState = createSelector(rewardTokensStateSelector, (state) => state.betstationTokens);
export const selectEdsTokensState = createSelector(selectBetstationTokensState, (state) => state.edsState);
export const selectSelectedEdsTokens = createSelector(selectEdsTokensState, (state) => state.selectedTokenIds);
export const selectBogTokensState = createSelector(selectBetstationTokensState, (state) => state.bogTokenState);

export const selectSelectedBogToken = createSelector(selectBogTokensState, selectRewardTokens, (bogState, tokens) =>
    getSelectedBogToken(bogState, tokens),
);

export const selectHasBogTokenSelected = createSelector(selectBogTokensState, (bogState) => !!bogState.selectedTokenId);

export const selectBogTokenContext = createSelector(rewardTokensStateSelector, (state) => getBestOddsGuaranteedTokensContext(state));

export const selectHasBetstationFreeBetSelected = createSelector(
    selectBetstationTokensState,
    (extended) => !!extended.betStationFreeBet.selectedTokenId,
);

export const selectHasEdsTokenSelected = createSelector(selectEdsTokensState, (edsState) => edsState.selectedTokenIds.length > 0);

export const selectSelectedBetstationFreeBetToken = createSelector(selectBetstationTokensState, selectRewardTokens, (betstationState, tokens) =>
    getSelectedBetStationFreeBetToken(betstationState, tokens),
);

export const selectSingleBetPickRewardTokenFactory = (pickId: PickId) =>
    createSelector(singleBetPickSelectorFactory(pickId), selectRewardTokens, (pick, tokens) => {
        if (!pick) {
            return null;
        }

        return pick.rewardTokenId ? tokens[pick.rewardTokenId] : null;
    });

export const selectSelectedFreeBetTokenForContextFactory = (context: RewardTokenContext) =>
    createSelector(selectTokenForContextFactory(context), (token) => {
        return isFreebetToken(token) ? token : null;
    });

export const selectHasComboPreventionForContextFactory = (context: RewardTokenContext) => {
    return createSelector(
        selectHasComboPreventionForTypeFactory(context.betslipType!),
        (hasComboPrevention) => hasComboPrevention && context.betslipType !== BetslipType.Single,
    );
};

export const selectMultiSingleTokenRewardContext = createSelector(betslipCurrentTypeSelector, singleBetPicksSelector, (type, picks) => {
    if (type !== BetslipType.Single) {
        return undefined;
    }

    const selectedPicks = Object.entries(picks).filter(([_, pick]) => pick.isSelected);

    const picksWithTokens = selectedPicks.filter(([, pick]) => !!pick.rewardTokenId);
    const singleTokenSelected = picksWithTokens.length === 1;

    return singleTokenSelected
        ? {
              betslipType: BetslipType.Single,
              pickId: pickIdFactory(picksWithTokens[0][0]),
          }
        : undefined;
});

export const selectRecoverableTokens = createSelector(rewardTokensStateSelector, (tokens) => tokens.tokenRecoveryState);

export const selectCurrentEligibilityState = createSelector(
    selectTokensEligibilityState,
    betslipCurrentTypeSelector,
    selectSingleBetSinglePickId,
    (eligibilities, currentType, singleBetSinglePickId) => {
        switch (currentType) {
            case BetslipType.Single:
                if (!singleBetSinglePickId) {
                    return [];
                }

                return eligibilities.singleBet[singleBetSinglePickId] ?? [];

            case BetslipType.Combo:
                return eligibilities.comboBet;

            case BetslipType.Teaser:
                return eligibilities.teaserBet;

            default:
                return [];
        }
    },
);

export const selectAcquisitionSelectionTokenContext = createSelector(betslipTypeStateSelector, selectRewardTokensList, (types, tokens) => {
    const acquisitionRewardToken = getAcquisitionRewardToken(tokens);
    if (!acquisitionRewardToken) {
        return;
    }
    const acquisitionSelectionTokenContext = getTokenSelectionContext(acquisitionRewardToken.userTokenId, types);

    return { userTokenId: acquisitionRewardToken.userTokenId, ...acquisitionSelectionTokenContext };
});

export const selectShowRewardsNotEligibleMessage = createSelector(
    selectIsLinearBetslip,
    betslipCurrentTypeSelector,
    selectNonAccaTokens,
    (isLinear, betslipCurrentType, tokens) => !isLinear && tokens.length > 0 && betslipCurrentType === BetslipType.System,
);

export const selectHasEachWayOptionPickForContext = (context: RewardTokenContext) => {
    return createSelector(betslipPicksListSelector, betslipTypeStateSelector, (pickList, { singleBet, comboBet, systemBet }) => {
        if (!pickList.some(BetslipV2OptionMarketPick.isPick)) {
            return false;
        }

        switch (context.betslipType) {
            case BetslipType.Single:
                const pickId = context.pickId?.toString();

                if (!pickId) {
                    return false;
                }

                const pick = pickList.find((p) => p.id.toString() === pickId);

                const pickState = singleBet.picks[pickId];

                if (!pick || !pickState) {
                    return false;
                }

                return pick && pickState && BetslipV2OptionMarketPick.isPick(pick) && pickState.isEachWay && pickState.isSelected;
            case BetslipType.Combo:
                return comboBet.isEachWay;
            case BetslipType.System:
                return systemBet.isEachWay;
            default:
                return false;
        }
    });
};

export const selectIsRewardTokensSelectorVisible = (context: RewardTokenContext) => {
    return createSelector(
        selectHasEachWayOptionPickForContext(context),
        selectHasNewCustomerOfferPick,
        selectHasVisibleTokensForContext(context),
        selectHasComboPreventionForContextFactory(context),
        (eachWayOptionPick, newCustomerOffer, hasVisibleTokens, hasComboPrevention) => {
            return hasVisibleTokens && !newCustomerOffer && !eachWayOptionPick && !hasComboPrevention;
        },
    );
};

export const selectRewardsTokensSelectorState = (context: RewardTokenContext) =>
    createSelector(
        selectIsRewardTokensSelectorVisible(context),
        selectTokenAndEligibilityFactory(context),
        selectHasAcquisitionRewardOnboardingForContext(context),
        (isVisible, [selectedToken, eligibility], hasAcquisitionOnboarding) => ({
            isVisible,
            selectedToken,
            isTokenInvalid:
                !eligibility || !!eligibility.invalidHardCriteria || !!Object.values(eligibility.softCriteriasValidity).some((x) => x === false),
            hasAcquisitionOnboarding,
        }),
    );
