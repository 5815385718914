import { Injectable } from '@angular/core';

import { SessionStoreService } from '@frontend/vanilla/core';

import { SitemapStateModel } from './sitemap.models';

@Injectable({
    providedIn: 'root',
})
export class SitemapSaveStateService {
    private sessionKey = 'sitemap-state';

    constructor(private sessionStore: SessionStoreService) {}

    get sitemap() {
        return (this.sessionStore.get(this.sessionKey) as SitemapStateModel) ?? {};
    }

    set sitemap(items: SitemapStateModel) {
        this.sessionStore.set(this.sessionKey, items);
    }
}
