import { Observable, Subject } from 'rxjs';

import { Hook } from './hooks-wireup';

/**
 * @deprecated This class is deprecated and will be removed in future versions.
 * Use `takeUntilDestroyed` from angular instead.
 */
export abstract class OnDestroyCleanup extends Hook {
    private destroyed = new Subject<void>();

    protected get destroyed$(): Observable<unknown> {
        return this.destroyed.asObservable();
    }

    postNgOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
