import { Inject, Injectable } from '@angular/core';

import { RouteTag } from '@frontend/sports/common/base-utils';
import { DeviceService, HtmlNode } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { RouterEventsService } from 'packages/sports/web/app/src/navigation-core/router-events.service';
import { BehaviorSubject, combineLatest, map, startWith, tap } from 'rxjs';

import { IBetslipRootState } from '../../../base/store/state';
import { IBetslipBarConfig } from '../../../integration/config/betslip-bar-config';
import { BETSLIP_BAR_CONFIG_INJECTION_TOKEN, ROUTER_EVENTS_SERVICE_INJECTION_TOKEN } from '../../../integration/sports-injection-services';
import { betslipPicksListCountSelector } from '../../picks/selectors';

@Injectable({ providedIn: 'root' })
export class BetslipBarVisibilityService {
    private readonly pickCount$ = this.store.select(betslipPicksListCountSelector);
    private readonly hasPicks$ = this.pickCount$.pipe(
        startWith(0),
        map((count) => count > 0),
    );

    private readonly excludedRoutes$ = this.routerEventsService.currentActivationEnd.pipe(
        map((route) => route?.snapshot.data?.tag === RouteTag.MyBets),
    );

    private readonly visibilitySubject = new BehaviorSubject(true);
    private readonly BETSLIP_BAR_VISIBLE_CLASS = 'betslip-bar-visible';

    constructor(
        private store: Store<IBetslipRootState>,
        @Inject(ROUTER_EVENTS_SERVICE_INJECTION_TOKEN) private routerEventsService: RouterEventsService,
        @Inject(BETSLIP_BAR_CONFIG_INJECTION_TOKEN) private betslipBarConfig: IBetslipBarConfig,
        private htmlNodeService: HtmlNode,
        private deviceService: DeviceService,
    ) {}

    readonly componentVisible$ = combineLatest([this.visibilitySubject, this.excludedRoutes$, this.hasPicks$]).pipe(
        map(
            ([isVisible, onExcludedRoute, hasPicks]) =>
                this.enabled && isVisible && !onExcludedRoute && (!this.betslipBarConfig.hideOnEmptyBetslip || hasPicks),
        ),
        tap((visible) => {
            this.htmlNodeService.setCssClass(this.BETSLIP_BAR_VISIBLE_CLASS, visible);
        }),
    );

    hideComponent(): void {
        this.visibilitySubject.next(false);
    }

    showComponent(): void {
        this.visibilitySubject.next(true);
    }

    get enabled(): boolean {
        return this.betslipBarConfig.isEnabled && this.deviceService.isMobile;
    }
}
