import { Injectable } from '@angular/core';

import { hasValue } from '@frontend/sports/common/base-utils';
import { Widget, WidgetLayoutResponse, WidgetType } from '@frontend/sports/types/components/widget';
import { LinkListPayload } from '@frontend/sports/types/components/widget/types';

import { trackingConstants } from '../../tracking/tracking.models';
import { TrackingService } from '../../tracking/tracking.service';
import { NOT_APPLICABLE } from './../../tracking/tracking.models';

@Injectable({ providedIn: 'root' })
export class WidgetTrackingService {
    constructor(private trackingService: TrackingService) {}

    trackWidgetsLoaded({ widgets, template, page }: WidgetLayoutResponse): void {
        const linkListWidget = widgets.find((x) => x.type === WidgetType.LinkList) as Widget<LinkListPayload> | undefined;
        if (linkListWidget?.payload) {
            for (let x = 0; x < linkListWidget?.payload.links.length; x++) {
                widgets.push({
                    type: linkListWidget.type,
                    location: linkListWidget.location,
                    id: linkListWidget.id,
                    order: linkListWidget.order,
                    refreshable: linkListWidget.refreshable,
                    payload: [],
                    templateName: linkListWidget.templateName,
                    trackingData: linkListWidget.trackingData,
                    visible: linkListWidget.visible,
                    sitecoreId: linkListWidget?.payload.links[x].sitecoreId,
                    userSegmentGroups: linkListWidget?.payload.links[x].userSegmentGroups,
                });
            }
        }

        const widgetTrackingData = widgets.map((w) => {
            return {
                ...w.trackingData,
                [trackingConstants.COMPONENT_MODULE_NAME]: w.type,
                [trackingConstants.COMPONENT_MODULE_POSITION]: `${w.location}|${w.order}`,
                [trackingConstants.COMPONENT_PAGE_LAYOUT]: [template.folder, page, template.name].filter(hasValue).join('/'),
                [trackingConstants.COMPONENT_MODULE_CUSTOM_NAME]: w.templateName,
                [trackingConstants.COMPONENT_MODULE_SOURCE]: 'standard module',
                [trackingConstants.COMPONENT_SITECORE_ID]: w.sitecoreId,
                [trackingConstants.COMPONENT_USER_SEGMENTS]: w.userSegmentGroups || NOT_APPLICABLE,
            };
        });

        this.trackingService.track(trackingConstants.SHOW_MODULE, {
            [trackingConstants.SHOW_MODULE]: widgetTrackingData,
        });
    }
}
