import { FixtureStage, FixtureViewType } from '@cds/betting-offer';
import { cloneDeep } from 'lodash-es';
import { EventParticipant } from 'packages/sports/web/app/src/event-model/model/event.model';

import { BetslipPick } from './betslip-pick';
import { BetslipV2Pick } from './betslip-v2-pick';
import { V2OptionMarketPickId } from './pick-id';
import { IBetslipV2Option, IBetslipV2OptionMarket, IBetslipV2OptionMarketPickStorage, IPlaceTerms, IPrice, ParticipantPickType } from './pick-models';
import { SignedName } from './signed-name.model';

/**
 * General Option Market Pick
 */
export abstract class BetslipV2OptionMarketPick extends BetslipV2Pick {
    override market: IBetslipV2OptionMarket;
    option: IBetslipV2Option;
    options: IBetslipV2Option[];
    override id: V2OptionMarketPickId;
    isFromNewCustomerOffer: boolean;
    override siblingOption?: IBetslipV2Option;
    pickType?: ParticipantPickType;
    isFavouritePick?: boolean;

    constructor() {
        super();
    }

    override get eventParticipants(): EventParticipant[] | undefined {
        return this.fixture.participants;
    }

    override get eventViewType(): FixtureViewType | undefined {
        return this.fixture.viewType;
    }

    static override isPick(pick: BetslipPick): pick is BetslipV2OptionMarketPick {
        return pick instanceof BetslipV2OptionMarketPick;
    }

    protected override initPropertiesFromJSON(value: IBetslipV2OptionMarketPickStorage): void {
        super.initPropertiesFromJSON(value);
        this.market = value.market;
        this.isFavouritePick = value.isFavouritePick;
        this.isFromNewCustomerOffer = value.isFromNewCustomerOffer || false;

        this.option = {
            ...value.option,
            prices: cloneDeep(value.option.prices),
            isDraw: value.option.isDraw,
        };
        this.pickType = value.pickType;
        this.siblingOption = value.siblingOption;
    }

    get optionId(): number {
        return this.option.id;
    }

    get optionName(): SignedName {
        return this.option.name;
    }

    get marketName(): SignedName {
        return this.market.name;
    }

    get isLive(): boolean {
        return this.fixture.stage === FixtureStage.Live;
    }

    get isEachWay(): boolean {
        return this.market.isEachWay;
    }

    get placeTerms(): IPlaceTerms | null {
        return this.market.placeTerms;
    }

    get realCompetitionId(): number | undefined {
        return this.fixture.league?.realCompetitionId;
    }

    override isPriceVisible(): boolean {
        return this.option.isVisible && !!this.currentPrice && this.currentPrice.isVisible;
    }

    override isMarketVisible(): boolean {
        return this.market.isVisible;
    }

    override isOpen(): boolean {
        if (!super.isOpen() || this.market.isClosed) {
            return false;
        }

        return this.fixture.cutOffDate > new Date();
    }

    override toJSON(): IBetslipV2OptionMarketPickStorage {
        const base = super.toJSON();

        return {
            ...base,
            id: this.id.toString(),
            isFromNewCustomerOffer: this.isFromNewCustomerOffer,
            market: {
                ...this.market,
            },
            isFavouritePick: this.isFavouritePick,
            option: {
                ...this.option,
            },
            pickType: this.pickType,
            siblingOption: this.siblingOption!,
        };
    }

    get prices(): IPrice[] {
        return this.option.prices.filter((pr) => pr.marketId === this.market.id);
    }

    abstract override copy(): BetslipV2OptionMarketPick;
}
