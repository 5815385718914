import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TrackingSource, trackingConstants } from '../../tracking/tracking.models';
import { FavouriteType } from '../favourites.model';
import { FavouriteToggleComponent, FavouriteToggleTracking } from './favourite-toggle.component';

@Component({
    selector: 'ms-favourite-league-toggle',
    template: `
        <ms-favourite-toggle
            [id]="id.toString()"
            [name]="name"
            [sport]="sport"
            [type]="type"
            [static]="static"
            [tracking]="favouriteTracking"
            (toggle)="toggle.emit($event)"></ms-favourite-toggle>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FavouriteToggleComponent],
    standalone: true,
})
export class FavouriteLeagueToggleComponent {
    @Input() id: string | number;
    @Input() name: string;
    @Input() sport: number;
    @Input() static: boolean;
    @Input() tracking: TrackingSource;
    @Output() toggle = new EventEmitter<boolean>();

    type = FavouriteType.League;

    get favouriteTracking(): FavouriteToggleTracking {
        return {
            name: this.name,
            additional: {
                [trackingConstants.SPORT_ID]: this.sport.toString(),
                [trackingConstants.SPORT_LEAGUE_ID]: this.id.toString(),
            },
            ...this.tracking,
        };
    }
}
