import { Injectable } from '@angular/core';

import { WidgetConfig } from '@frontend/sports/common/client-config-data-access';
import { Widget, WidgetLayoutTemplate, WidgetPage } from '@frontend/sports/types/components/widget';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilKeyChanged, filter } from 'rxjs/operators';

import ModuleLoaderService from '../../deferred/module-loader.service';
import { AdaptiveLayoutService } from '../../layout/adaptive-layout.service';
import { widgetsToWidgetTypes } from './widgets-to-widget-types.util';

export interface WidgetRightColumnContent {
    widgets: Widget<unknown>[];
    page?: WidgetPage;
    layoutTemplate?: WidgetLayoutTemplate;
}

@Injectable({
    providedIn: 'root',
})
export class WidgetRightColumnService {
    private widgets = new ReplaySubject<WidgetRightColumnContent>(1);

    constructor(
        private config: WidgetConfig,
        private moduleLoader: ModuleLoaderService,
        layout: AdaptiveLayoutService,
    ) {
        layout.stateChange$
            .pipe(
                distinctUntilKeyChanged('hasRightColumn'),
                filter((current) => current.hasRightColumn),
            )
            .subscribe(() => this.resetContent());
        this.widgets.pipe(filter(({ widgets }) => widgets.length > 0)).subscribe(({ widgets }) => {
            const widgetTypes = widgetsToWidgetTypes(widgets);
            this.moduleLoader.lazyLoadFeatures(widgetTypes);
        });
        this.setContent([]);
    }

    setContent(widgets: Widget<unknown>[], page?: WidgetPage, layoutTemplate?: WidgetLayoutTemplate): void {
        if (!widgets.length) {
            widgets = this.config.defaultRightColumn;
        }

        this.widgets.next({
            widgets,
            page,
            layoutTemplate,
        });
    }

    resetContent(): void {
        this.setContent([]);
    }

    asObservable(): Observable<WidgetRightColumnContent> {
        return this.widgets.asObservable();
    }
}
