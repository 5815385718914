<div
    *ngIf="modeStrategy.inputMode | async as inputMode"
    class="stake-container"
    [ngClass]="getStakeContainerClass$ | async"
    [msStakeFontResizer]="{
        inputMode: inputMode,
        hasError: errors | async
    }">
    <ng-container [ngSwitch]="inputMode">
        <ms-stake-input
            class="stake-input"
            *ngSwitchCase="stakeNumpadMode.Typing"
            [emptyCharacter]="emptyCharacter"
            [disabled]="disabled"
            [adornmentStart]="adornmentStart"
            [behavior]="stakeInputBehavior"></ms-stake-input>
        <ms-stake-field
            class="stake-field"
            *ngSwitchDefault
            [emptyCharacter]="emptyCharacter"
            [disabled]="disabled"
            [adornmentStart]="adornmentStart">
        </ms-stake-field>
    </ng-container>
</div>
