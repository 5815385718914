import { Injectable } from '@angular/core';
import { ActivationEnd } from '@angular/router';

import { RouteTag } from '@frontend/sports/common/base-utils';
import { MediaQueryService } from '@frontend/vanilla/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RouterEventsService } from '../navigation-core/router-events.service';
import { BreadcrumbModel } from './breadcrumb.models';
import { BreadcrumbSlot, BreadcrumbsSlotService } from './breadcrumbs-slot.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbRouteVisibilityService {
    private _minimumItemsForRoute = 2;
    private largeScreen = false;
    private _visibility$ = new Subject<void>();
    private currentBreadcrumbSlot?: BreadcrumbSlot;

    constructor(
        routerEvents: RouterEventsService,
        private mediaService: MediaQueryService,
        breadcrumbSlot: BreadcrumbsSlotService,
    ) {
        routerEvents.currentActivationEnd.pipe(filter((e) => !!e)).subscribe((e: any) => this.onRouteChanged(e));
        breadcrumbSlot.slotRegistered$.subscribe((slot) => this.onSlotChanged(slot));
        mediaService.observe().subscribe(this.screenWidthChanged);
    }

    get visibility$(): Observable<void> {
        return this._visibility$.asObservable();
    }

    isVisible(breadcrumbs: BreadcrumbModel[]): boolean {
        const ignoreBreadcrumb = !(breadcrumbs.length === 1 && breadcrumbs[0].ignoreBreadcrumb === true);

        return (
            (breadcrumbs.length >= this.minimumItems || Boolean(this.currentBreadcrumbSlot && this.currentBreadcrumbSlot.required)) &&
            ignoreBreadcrumb
        );
    }

    /**
     * When the screen is small, we always show breadcrumbs if any are there.
     * Otherwise we show only if there are at least 2 items.
     *
     * @readonly
     * @private
     * @type {number}
     * @memberof BreadcrumbRouteVisibilityService
     */
    private get minimumItems(): number {
        return this.largeScreen ? this._minimumItemsForRoute : 1;
    }

    private onRouteChanged(event: ActivationEnd): void {
        const singleItemPages = [RouteTag.EventDetails, RouteTag.Live];
        this._minimumItemsForRoute = singleItemPages.some((s) => s === event.snapshot.data.tag) ? 1 : 2;
        this._visibility$.next();
    }

    private screenWidthChanged = (): void => {
        this.largeScreen = this.mediaService.isActive('gt-md');
        this._visibility$.next();
    };

    private onSlotChanged(slot?: BreadcrumbSlot): void {
        this.currentBreadcrumbSlot = slot;
        this._visibility$.next();
    }
}
