import { Injectable } from '@angular/core';

import { AmericanFootballQuarters } from '@frontend/sports/common/base-utils';
import { Sitecore } from '@frontend/sports/common/client-config-data-access';

import { AmericanFootballStatistics, EventScoreboard } from '../model/scoreboard.model';

@Injectable({ providedIn: 'root' })
export class AmericanFootballScoreboardFactory {
    constructor(private sitecore: Sitecore) {}

    getYardDistance(americanFootballStats?: AmericanFootballStatistics, id?: number): string {
        let yardDistance = '';
        if (
            americanFootballStats &&
            americanFootballStats.activeDown &&
            americanFootballStats.yardsDistance !== 0 &&
            americanFootballStats.yardsToNextDown !== 0 &&
            !(id === AmericanFootballQuarters.Halftime || id === AmericanFootballQuarters.RegularTimeOver)
        ) {
            yardDistance = `${americanFootballStats.activeDown} & ${americanFootballStats.yardsToNextDown}${this.getTriCode(americanFootballStats.yardsDistance)}`;
        }

        return yardDistance;
    }

    private getTriCode(yardsDistance: number): string {
        const separator = ',';
        if (yardsDistance === 50) {
            return `${separator} ${this.sitecore.eventScoreboard.On} ${yardsDistance}`;
        }
        if (yardsDistance > 50 && yardsDistance <= 100) {
            const distance = 100 - yardsDistance;

            return `${separator} ${this.sitecore.eventScoreboard.Own} ${distance}`;
        }
        if (yardsDistance < 50) {
            return `${separator} ${this.sitecore.eventScoreboard.Opp} ${yardsDistance}`;
        }

        return '';
    }

    getLiveEventInfo(scoreboard?: EventScoreboard): string {
        const americanFootballStats = scoreboard?.statistics as AmericanFootballStatistics;

        return this.getYardDistance(americanFootballStats, scoreboard?.period?.id);
    }
}
