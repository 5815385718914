import { Injectable, inject } from '@angular/core';

import { WidgetLocation } from '@frontend/sports/types/components/widget';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { LoggerFactory } from '../../logging/logger-factory.service';

export interface WidgetData {
    isLoaded: boolean;
    order: number;
    slot?: WidgetLocation;
    widgetId?: string;
    parentWidgetId?: string;
}

@Injectable({
    providedIn: 'root',
})
export class WidgetLoaderService {
    private widgetsLoaded = new Map<string, WidgetData>();
    private loggedWidgetsDetails = new Map<string, { [isLogged: string]: boolean }>();
    private allTopLocationWidgetsLoaded = new BehaviorSubject<boolean>(false);
    readonly allTopLocationWidgetsLoaded$ = this.allTopLocationWidgetsLoaded.asObservable();
    private logger = inject(LoggerFactory).getLogger('WidgetLoaderService');

    private widgetLoadedDetails = new Map<WidgetLocation, BehaviorSubject<WidgetData[]>>([
        [WidgetLocation.Top, new BehaviorSubject<WidgetData[]>([])],
        [WidgetLocation.Left, new BehaviorSubject<WidgetData[]>([])],
        [WidgetLocation.Center, new BehaviorSubject<WidgetData[]>([])],
        [WidgetLocation.TopLeft, new BehaviorSubject<WidgetData[]>([])],
    ]);

    private getWidgetsByLocation(location: WidgetLocation): WidgetData[] {
        return [...this.widgetsLoaded.values()].filter((x) => x.slot && x.slot === location);
    }

    setwidgetLoaded(widgetId: string): void {
        const widgetDetails = this.widgetsLoaded.get(widgetId)!;
        this.widgetsLoaded.set(widgetId, { ...widgetDetails, isLoaded: true });
        if (widgetDetails.slot === WidgetLocation.Top || !this.getWidgetsByLocation(WidgetLocation.Top).length) {
            this.allTopLocationWidgetsLoaded.next(this.getWidgetsByLocation(WidgetLocation.Top).every((x) => x.isLoaded));
        }
        this.widgetLoadedDetails.get(widgetDetails.slot!)?.next(this.getWidgetsByLocation(widgetDetails.slot!));
    }

    setSlotWidgets(widgetId: string, widgetData: WidgetData) {
        if (!this.widgetsLoaded.has(widgetId)) {
            this.loggedWidgetsDetails.set(widgetId, { isLogged: false });
            this.widgetsLoaded.set(widgetId, widgetData);
        } else {
            const loggedWidgetDetail = this.loggedWidgetsDetails.get(widgetId)!;
            const existingWidget = this.widgetsLoaded.get(widgetId)!;
            //Implemented an additional condition to prevent log warnings due to multiple invocations of setSlotWidgets
            if (
                !loggedWidgetDetail.isLogged &&
                (existingWidget.parentWidgetId !== widgetData.parentWidgetId || existingWidget.order !== widgetData.order)
            ) {
                this.logger.warning(`Widget ${widgetId} imported multiple times`);
                this.loggedWidgetsDetails.set(widgetId, { isLogged: true });
            }
        }
    }

    widgetLoadedDetails$(location: WidgetLocation): Observable<WidgetData[]> {
        return this.widgetLoadedDetails.get(location)?.asObservable() ?? of([]);
    }

    cleanWidgetsLoaded(): void {
        this.allTopLocationWidgetsLoaded.next(false);
        this.widgetLoadedDetails.forEach((v) => v.next([]));
        this.widgetsLoaded.clear();
        this.loggedWidgetsDetails.clear();
    }
}
