export function getCookieRaw(name: string): string | null {
    const pattern = RegExp(name + '=.[^;]*');
    const matched = document.cookie.match(pattern);
    if (matched) {
        const cookie = matched[0].split('=');
        return cookie[1] || null;
    }

    return null;
}
