import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { FixtureType } from '@cds/betting-offer';
import { TagCount, TagType } from '@cds/betting-offer/tags';
import { BatchRequest, FixtureCountsRequest, FixtureState } from '@cds/query-objects';
import { toString } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BettingOfferApi } from '../cds/betting-offer-api.service';
import { FavouriteType, FavouritesViewModel } from './favourites.model';
import { isFixtureFavourite } from './helpers';

@Injectable({ providedIn: 'root' })
export class FavouritesFixturesService {
    constructor(private bettingOffer: BettingOfferApi) {}

    getCounts(favourites: FavouritesViewModel[]): Observable<{ [key: string]: TagCount[] }> {
        if (!favourites.length) {
            return of({});
        }

        const participantFavourite = (fav: FavouritesViewModel): boolean =>
            fav.type === FavouriteType.Participant || fav.type === FavouriteType.ParticipantV2;

        const request: BatchRequest<FixtureCountsRequest>[] = favourites.map((favourite) => {
            const mapped: BatchRequest<FixtureCountsRequest> = {
                batchId: favourite.id,
                request: {
                    fixtureTypes: FixtureType.Standard,
                    offerSource: this.getOfferSource(favourite),
                    state: FixtureState.Latest,
                    tagTypes: toString([TagType.Sport, TagType.Region, TagType.Tournament, TagType.Competition]),
                    sportIds: toString(favourite.sport.id),
                    extendedTags: '',
                    fixtureParticipantIds: participantFavourite(favourite) ? toString(favourite.itemId) : '',
                    competitionIds: favourite.type === FavouriteType.League ? toString(favourite.itemId) : '',
                    fixtureIds: isFixtureFavourite(favourite) ? favourite.itemId : '',
                },
            };

            return mapped;
        });

        return this.bettingOffer.getBatchCounts(request).pipe(map((result) => result || {}));
    }

    private getOfferSource(favourite: FavouritesViewModel): OfferSource | undefined {
        if (favourite.type === FavouriteType.League) {
            return undefined;
        }

        if (favourite.type === FavouriteType.FixtureV2 || favourite.type === FavouriteType.ParticipantV2) {
            return OfferSource.V2;
        }

        return OfferSource.V1;
    }
}
