import { Injectable, NgZone } from '@angular/core';

import { WindowRef } from '@frontend/vanilla/core';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { auditTime } from 'rxjs/operators';

export class EventService {
    private subject: Subject<EventTarget> | undefined;
    private subscription: Subscription;

    constructor(
        private zone: NgZone,
        private target: EventTarget,
        private event: string,
    ) {}

    subscribe(next?: (target: EventTarget) => void, error?: (error: any) => void, complete?: () => void): Subscription {
        if (!this.subject) {
            this.setup();
        }

        return this.subject!.subscribe({ next, error, complete });
    }

    asObservable(): Observable<EventTarget> {
        if (!this.subject) {
            this.setup();
        }

        return this.subject!.asObservable();
    }

    private listener(): void {
        this.zone.run(() => {
            if (!this.subject || !this.subject.observed) {
                this.subscription.unsubscribe();
                this.subject = undefined;
            } else {
                this.subject.next(this.target);
            }
        });
    }

    private setup(): void {
        this.subject = new Subject<EventTarget>();

        this.zone.runOutsideAngular(() => {
            this.subscription = fromEvent<EventTarget>(this.target as any, this.event)
                .pipe(auditTime(150))
                .subscribe(() => this.listener());
        });
    }
}

@Injectable({ providedIn: 'root' })
export class WindowEventService {
    constructor(
        private zone: NgZone,
        private windowRef: WindowRef,
    ) {}

    scroll = new EventService(this.zone, this.windowRef.nativeWindow, 'scroll');
    resize = new EventService(this.zone, this.windowRef.nativeWindow, 'resize');
}
