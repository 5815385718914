import { Injectable } from '@angular/core';

import {
    ConnectionConfig,
    GeolocationConfig,
    MediaConfig,
    MocksConfig,
    NewCustomerBettingOfferConfig,
} from '@frontend/sports/common/client-config-data-access';
import { GeolocationService } from '@frontend/vanilla/core';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { UserService } from '../user/services/user.service';

export interface ApiBaseSettings {
    accessId: string;
    lang: string;
    country: string;
    userCountry: string;
    userLocation?: string;
    restrictedCompetitionId?: number;
    subdivision?: string;
    shopTier?: number;
}

@Injectable({ providedIn: 'root' })
export class ApiBaseSettingsProvider {
    constructor(
        private mocksConfig: MocksConfig,
        private m2User: UserService,
        private connectionConfig: ConnectionConfig,
        private geolocationService: GeolocationService,
        private newCustomerBettingOfferConfig: NewCustomerBettingOfferConfig,
        private mediaConfig: MediaConfig,
        private geolocationConfig: GeolocationConfig,
    ) {}

    getSettings(): ApiBaseSettings {
        const settings: ApiBaseSettings = {
            accessId: this.connectionConfig.publicAccessId || this.connectionConfig.pushAccessId,
            lang: this.connectionConfig.culture,
            country: this.getCountry(),
            userCountry: this.m2User.country,
            restrictedCompetitionId: this.getRestrictedCompetitionId(),
            shopTier: this.connectionConfig.shopTier,
        };

        if (this.mediaConfig.subdivisionRestrictedCountries.includes(this.m2User.country)) {
            const subdivisionCode = this.m2User.getStateOrProvince();
            if (subdivisionCode) {
                // Vanilla is not providing the subdivision in ISO format(eg. stateorprovince claim: 'NJ', ISO code for New Jersey is 'US-NJ')
                const isoSubdivisionCode = `${this.m2User.country}-${subdivisionCode}`;
                settings.subdivision = isoSubdivisionCode;
            }
        }

        return settings;
    }

    getSettingsWithGeolocation(): Observable<ApiBaseSettings> {
        if (!this.geolocationConfig.isGeolocationEnabled) {
            return of(this.getSettings());
        }

        return this.geolocationService.whenReady.pipe(
            first(),
            map(() => {
                const location = this.geolocationService.currentPosition?.mappedLocation?.locationId || '';

                return {
                    ...this.getSettings(),
                    userLocation: location,
                };
            }),
        );
    }

    private getCountry(): string {
        if (this.mocksConfig.isMockLiveStreamGeoCountryEnabled && this.mocksConfig.liveStreamGeoCountry) {
            return this.mocksConfig.liveStreamGeoCountry;
        }

        return this.m2User.geoCountry?.toUpperCase() || this.m2User.country?.toString();
    }

    private getRestrictedCompetitionId(): number | undefined {
        return this.m2User.isEligibleForNewCustomerOffer ? this.newCustomerBettingOfferConfig.competitionId : undefined;
    }
}
