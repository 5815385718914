import { PlacementErrorType } from '@frontend/sports/types/betslip';

import { BetPlacementErrorIcon } from '../bet-placement-error-icon';
import { StakeError } from '../stake-error';

export class NotEnoughMoney extends StakeError {
    constructor(
        readonly userMoney: number,
        public stake?: number,
    ) {
        super();
        this.icon = BetPlacementErrorIcon.Warning;
        this.priority = 1000; // Not Enough Money last error
        this.type = PlacementErrorType.InsufficientMoney;
    }
}
