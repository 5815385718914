import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { OfferSource } from '@cds';
import { ISimpleChanges } from '@frontend/sports/common/base-utils';

import { EventModel } from '../../event-model/model/event.model';
import { TrackingSource, trackingConstants } from '../../tracking/tracking.models';
import { FavouriteType } from '../favourites.model';
import { FavouriteToggleComponent, FavouriteToggleTracking } from './favourite-toggle.component';

@Component({
    selector: 'ms-favourite-event-toggle',
    template: `
        <ms-favourite-toggle
            [id]="event.id"
            [name]="event.name"
            [sport]="event.sport.id"
            [type]="type"
            [static]="static"
            [tracking]="favouriteTracking"
            (toggle)="toggle.emit($event)"></ms-favourite-toggle>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FavouriteToggleComponent],
    standalone: true,
})
export class FavouriteEventToggleComponent implements OnChanges {
    @Input() event: EventModel;
    @Input() static: boolean;
    @Input() tracking: TrackingSource;
    @Output() toggle = new EventEmitter<boolean>();

    type: FavouriteType;

    ngOnChanges(changes: ISimpleChanges<FavouriteEventToggleComponent>): void {
        if (changes.event) {
            const previous = changes.event.previousValue ? changes.event.previousValue.id : undefined;
            const current = changes.event.currentValue ? changes.event.currentValue.id : undefined;

            if (previous !== current) {
                this.type = this.getType();
            }
        }
    }

    get favouriteTracking(): FavouriteToggleTracking {
        return {
            name: this.event.name,
            additional: {
                [trackingConstants.SPORT_ID]: this.event.sport.id.toString(),
                [trackingConstants.SPORT_EVENT_ID]: this.event.id.toString(),
            },
            ...this.tracking,
        };
    }

    private getType(): FavouriteType {
        if (!this.event.offerSource || this.event.offerSource === OfferSource.V1) {
            return FavouriteType.Fixture;
        }

        return FavouriteType.FixtureV2;
    }
}
