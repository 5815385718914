import { DayOfRaceDate, TimeGroupedMeetings } from '@cds/betting-offer/domain-specific/horse-racing';
import { Meeting } from '@cds/betting-offer/tags';
import { DomainEvent } from '@frontend/sports/common/dispatcher-utils';

/* eslint-disable  @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
export class Events {
    static Grid = class {
        static EventsAddedToGrid = new DomainEvent<void>('EVENTS_ADDED_TO_GRID');
    };

    static Slideshow = class {
        static SlideInit = new DomainEvent<void>('SLIDE_INIT');
        static SlideSet = new DomainEvent<number>('SLIDE_SET');
    };

    static EventPush = class {
        static EventUpdated = new DomainEvent<any>('EVENT_UPDATED');
        static MarketUpdated = new DomainEvent<void>('MARKET_UPDATED');
        static EventLastUpdated = new DomainEvent<void>('EVENT_LASTUPDATED');
    };

    static PageRequiresNoAnimation = new DomainEvent<void>('PAGE_REQUIRES_NO_ANIMATION');
    static ClaimsChanged = new DomainEvent<any>('CLAIMS_CHANGED');

    static HorseRacing = class {
        static OpenMeetingSelector = new DomainEvent<{ meetings: TimeGroupedMeetings; dayOfRace: DayOfRaceDate; meeting: Meeting }>(
            'OPEN_HORSE_MEETING_SELECTOR',
        );

        static CloseMeetingSelector = new DomainEvent<any>('CLOSE_HORSE_MEETING_SELECTOR');
        static MeetingSelected = new DomainEvent<{ meeting: Meeting; dayOfRaceDate: DayOfRaceDate }>('MEETING_SELECTED');
        static FixtureSelectTopTwo = new DomainEvent<void>('FIXTURE_SELECT_TOPTWO');
        static FixtureSelectTopThree = new DomainEvent<void>('FIXTURE_SELECT_TOPTHREE');
    };

    // static BetFinder = class {
    //     static FieldFocus = new DomainEvent<void>('ON_BETFINDER_FIELD_FOCUS');
    //     static FieldBlur = new DomainEvent<void>('ON_BETFINDER_FIELD_BLUR');
    // };

    static BetslipPluginStateChanged = new DomainEvent<void>('BETSLIP_PLUGIN_STATE_CHANGED');
    static ClosePluginSwitcher = new DomainEvent<void>('CLOSE_PLUGIN_SWITCHER');

    static Favourites = class {
        static FavouritesChanged = new DomainEvent<string>('FAVOURITES_CHANGED');
    };

    static Popup = class {
        static PopupClosed = new DomainEvent<string>('POPUP_CLOSED');
        static BeforePopupDisplayed = new DomainEvent<string>('BEFORE_POPUP_DISPLAYED');
    };

    static UnauthenticatedCallEarlyPayoutSubscription = new DomainEvent<void>('UNAUTHENTICATED_EARLY_PAYOUT');
    static EarlyPayoutSuccess = new DomainEvent<void>('EARLY_PAYOUT_SUCCESS');
    static OverlayToggle = new DomainEvent<boolean>('OVERLAY_TOGGLE');
    static LeagueHeaderToggle = new DomainEvent<boolean>('LEAGUE_HEADER_TOGGLE');

    static RightSwipe = new DomainEvent<void>('RIGHT_SWIPE');
    static M2UserClaimsChanged = new DomainEvent<any>('m2-user-claimsChanged');
    static PageVisibilityChange = new DomainEvent<boolean>('PAGE_VISIBILITY_CHANGE');
    static OrientationChanged = new DomainEvent<any>('ORIENTATION_CHANGED');
}
/* eslint-enable  @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
