import { Route, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

import { keys } from 'lodash-es';

// uses regex expession defined on route data
// matcherPattern - regex to match
// matcherMapping - consumes current segment and maps params from regex matching with the specified result index
export function regexMatcher(segments: UrlSegment[], segmentGroup: UrlSegmentGroup, route: Route): UrlMatchResult {
    if (!route.data?.matcherPattern) {
        throw new Error('No regex defined on route');
    }

    if (segments.length === 0) {
        return null as any; // the angular matcher function is typed incorrectly and it can actually handle null
    }

    const regex = new RegExp(route.data.matcherPattern);
    const max = route.data.matcherMode === 'full' ? segments.length : 1;
    const url = segments
        .filter((segment, index) => index < max)
        .map((segment) => segment.path)
        .join('/');

    const match = regex.exec(url);
    const matcherMapping = route.data.matcherMapping;

    if (match) {
        if (!matcherMapping) {
            return { consumed: [], posParams: {} };
        }

        const posParams: { [name: string]: UrlSegment } = {};

        keys(matcherMapping).forEach((key) => {
            const index: number = matcherMapping[key];

            posParams[key] = new UrlSegment(match[index], {});
        });

        return { consumed: segments.slice(0, max), posParams };
    }

    return null as any;
}
