import { Injectable } from '@angular/core';

import { Fixture } from '@cds/betting-offer';
import { BettingOfferConfig } from '@frontend/sports/common/client-config-data-access';

import { EventModel, EventOptionGroup } from '../model/event.model';

/**
 * Processing option groups for which there is a linkeFixture and configured mapping
 * Mapping between templateId and MarketType done in Dynacon
 *
 * @updateLinkedGroups flag OptionGroup if both conditions are true(linkedFixture & mapping),
 * used to show linked golf EW instead of the flagged golf optionGroup
 */
@Injectable({ providedIn: 'root' })
export class LinkedFixtureService {
    constructor(private offerConfig: BettingOfferConfig) {}

    updateLinkedGroups(event: EventModel): EventOptionGroup[] {
        if (event.winGameLinkedFixture) {
            const fixtureMarketType = this.getMarketType(event.winGameLinkedFixture);

            event.optionGroups.forEach((og) => {
                const templateIdMapping = this.offerConfig.golfSiblingMapping[og.templateId];

                if (templateIdMapping === fixtureMarketType) {
                    og.linkedSibling = true;
                }
            });
        }

        return event.optionGroups;
    }

    private getMarketType(fixture: Fixture): string {
        const participant = fixture.participants.find((p) => p.options.length);

        return participant?.options[0].marketType ?? '';
    }
}
