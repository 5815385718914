import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { SubNavigationItem } from './sub-navigation.models';

@Injectable({ providedIn: 'root' })
export class SubNavigationService {
    private _tabs$ = new BehaviorSubject<SubNavigationItem[]>([]);

    get tabs$(): Observable<SubNavigationItem[]> {
        return this._tabs$.asObservable();
    }

    setTabs(items: SubNavigationItem[]): void {
        this._tabs$.next(items);
    }
}
