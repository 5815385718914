<div class="message-dialog">
    @if (shouldCloseLinkBeDisplayed) {
        <a (click)="closeLinkClickHandler()" class="theme-ex"></a>
    }
    <h3 id="title" class="{{ contentData.type }}">{{ contentData.title }}</h3>

    <div class="content">
        <p id="first_paragraph" [innerHTML]="contentData.firstParagraph"></p>
        <p id="second_paragraph" [innerHTML]="contentData.secondParagraph"></p>

        @if (isDesignSystem) {
            <div class="message-dialog__buttons">
                @for (button of contentData.buttons; track $index) {
                    <!-- TODO Buttons Design System: remove "ds" from the class once all buttons have been migrated -->
                    <button
                        class="buttons_message-dialog_ds-buttons"
                        [class]="{ 'single-button': contentData.buttons?.length === 1 }"
                        ds-button
                        [kind]="button.buttonConfig?.kind"
                        [variant]="button.buttonConfig?.variant"
                        [size]="button.buttonConfig?.size"
                        truncate="true"
                        (click)="buttonClickHandler(button)">
                        {{ button.text }}
                    </button>
                }
            </div>
        } @else {
            <div class="buttons">
                @for (button of contentData.buttons; track $index) {
                    <button class="btn {{ button.cssClass || 'continue btn-primary' }}" (click)="buttonClickHandler(button)">
                        {{ button.text }}
                    </button>
                }
            </div>
        }
    </div>
</div>
