import { Inject, Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { WorldCupHubConfig } from '@frontend/sports/common/client-config-data-access';
import { LANGUAGE_SWITCHER_URLS_PROVIDER, Page, UrlService } from '@frontend/vanilla/core';
import { Store } from '@ngrx/store';
import { IBetslipRootState } from 'packages/sports/common/betslip/base/store/state';
import { ExternalBetslipActions } from 'packages/sports/common/betslip/core/external-betslip-actions';
import { BetBuilderPickId, PickId, V1PickId, V2OptionMarketPickId } from 'packages/sports/common/betslip/core/picks/pick-id';
import { IPickTracking } from 'packages/sports/common/betslip/core/picks/pick-models';
import { Subscription } from 'rxjs';

import { SourceOfPick } from '../betslip-base/models/pick-models';
import { EventDetailsService } from '../event-details-common/event-details.service';
import { EventModel, EventOptionGroup } from '../event-model/model/event.model';
import { RedirectHelperService } from '../navigation-core/redirect-helper.service';
import { SportUrlParam, UrlHelperService } from '../navigation-core/url-helper.service';
import { PrettyUrlLanguageSwitcherProvider } from '../router/pretty-url-language-switcher.provider';
import { WorldCupHubEventModel } from './world-cup-hub.model';

@Injectable({ providedIn: 'root' })
export class WorldCupHubService {
    ssgUrlDetails: CultureUrl | undefined;
    urlSubscription: Subscription;
    optionGroup?: EventOptionGroup;
    private _url: string;
    private tracking: IPickTracking;
    //optionGroup?: EventOptionGroup;
    private pickPriceDetails = new Map<string, number>();

    constructor(
        private page: Page,
        private worldCupHubConfig: WorldCupHubConfig,
        private eventDetailsService: EventDetailsService,
        private store: Store<IBetslipRootState>,
        private redirectHelper: RedirectHelperService,
        private urlHelper: UrlHelperService,
        @Inject(LANGUAGE_SWITCHER_URLS_PROVIDER) private prettyUrlSwitcher: PrettyUrlLanguageSwitcherProvider,
        private urlService: UrlService,
    ) {
        this.ssgUrlDetails = this.worldCupHubConfig.wcEventHubURLs.filter((k) => k.culture === this.page.lang && k.isEnabled).shift();
        if (this.ssgUrlDetails) {
            this._url = this.ssgUrlDetails.ssgUrl;
            this._isEnabled = true;
        }
        this.tracking = { source: SourceOfPick.WorldCupHub.toString() };
    }

    private _isEnabled: boolean;

    get isEnabled(): boolean {
        return this._isEnabled;
    }

    get cultureUrlData(): CultureUrl | undefined {
        return this.ssgUrlDetails;
    }

    get ssgUrl(): string {
        return this._url;
    }

    addWCHubPicksToBetslip(wcEventDetails: WorldCupHubEventModel[]) {
        Promise.all(
            wcEventDetails.map(async (eventData) => {
                const eventModel = await this.getEventModel(eventData.fixtureId, eventData.marketId);
                if (eventModel?.id && this.optionGroup) {
                    const option = this.optionGroup.options.filter((optiondata) => optiondata.id === Number(eventData.optionId)).shift();
                    if (option) {
                        const priceid = Number(eventData.priceId) || Number(option?.priceId);
                        const pickId = this.getPickId(eventData, priceid);
                        switch (eventData.offerSource) {
                            case OfferSource.BetBuilder:
                                this.addBetBuilderPickToBetSlip(eventModel, pickId);
                                break;
                            case OfferSource.V1:
                                this.store.dispatch(ExternalBetslipActions.addPick({ pickId: pickId as V1PickId, tracking: this.tracking }));
                                break;
                            case OfferSource.V2:
                                this.store.dispatch(
                                    ExternalBetslipActions.addPick({ pickId: pickId as V2OptionMarketPickId, tracking: this.tracking }),
                                );
                                break;
                        }
                        this.pickPriceDetails.set(this.getKey(eventData), option?.priceId!);
                    }
                }
            }),
        );
    }

    removeWCHubPicksFromBetslip(pickDataArray: WorldCupHubEventModel[]) {
        pickDataArray.forEach((pickData) => {
            const key = this.getKey(pickData);
            const priceId = pickData.priceId ? Number(pickData.priceId) : this.pickPriceDetails.get(key) || 0;
            const pickId = this.getPickId(pickData, priceId);
            this.store.dispatch(ExternalBetslipActions.removePick({ pickId }));
            this.pickPriceDetails.delete(key);
        });
    }

    navigateToEvent(eventId: string, market?: string) {
        const eventUrl = this.urlHelper.getEventUrl({ id: eventId, name: '' });
        this.redirectToPage(eventUrl, market ? `market=${market}` : '');
    }

    navigateToCompetition(sportId: string, regionId: string, competitionId: string) {
        const competitionUrl = this.urlHelper.getSportUrl(
            { id: sportId, name: '' },
            SportUrlParam.Betting,
            { id: regionId, name: '' },
            { id: competitionId, name: '' },
        );
        this.redirectToPage(competitionUrl);
    }

    redirectToPage(url: string, query?: string, isPretty: boolean = true) {
        if (isPretty) {
            this.urlSubscription = this.prettyUrlSwitcher.getUrls(this.urlService.parse(url), [this.page.lang]).subscribe((result) => {
                const redirectUrl = result.get(this.page.lang) + (query ? `?${query}` : '');
                this.redirectHelper.goToPage(redirectUrl);
            });
        } else {
            this.redirectHelper.goToPage(url);
        }
    }

    destroy() {
        this.urlSubscription?.unsubscribe();
        this.pickPriceDetails.clear();
    }

    private getKey(pick: WorldCupHubEventModel): string {
        return `${pick.fixtureId}-${pick.marketId}-${pick.optionId}`;
    }

    private addBetBuilderPickToBetSlip(eventModel: EventModel, pickId: PickId) {
        this.store.dispatch(
            ExternalBetslipActions.addBetBuilderPick({
                pickId: pickId as BetBuilderPickId,
                tracking: this.tracking,
                eventData: {
                    event: {
                        id: eventModel.id,
                        date: new Date(eventModel.startDate),
                        name: eventModel.name,
                        isLive: eventModel.live,
                        groupId: eventModel.groupId,
                    },
                    league: {
                        id: eventModel.league.id,
                        name: eventModel.league.name,
                        parentLeagueId: eventModel.league.parentLeagueId,
                    },
                    region: {
                        id: eventModel.region.id,
                        name: eventModel.region.name,
                    },
                    offerSource: eventModel.offerSource,
                    betBuilderTradingV2FixtureId: eventModel.betBuilderTradingV2FixtureId,
                },
            }),
        );
    }

    private getPickId(pick: WorldCupHubEventModel, priceId: number): PickId {
        if (pick.offerSource === OfferSource.V1) {
            return new V1PickId(pick.fixtureId, parseInt(pick.marketId), parseInt(pick.optionId));
        } else if (pick.offerSource === OfferSource.V2) {
            return new V2OptionMarketPickId(pick.fixtureId, parseInt(pick.marketId), parseInt(pick.optionId), priceId);
        } else {
            //Expecting FixtureId with Partition from 3rd party, will not build compoundID here.
            return new BetBuilderPickId(pick.fixtureId, pick.fixtureId, parseInt(pick.marketId), parseInt(pick.optionId), priceId);
        }
    }

    private async getEventModel(fixtureId: string, marketId: string): Promise<EventModel | undefined> {
        const response = await this.eventDetailsService.getModel(fixtureId);
        if (response && response.model) {
            this.optionGroup = response.model.optionGroups.find((optionGroup) => optionGroup.id === marketId) || ({} as EventOptionGroup);

            return response.model;
        }

        return;
    }
}

export interface CultureUrl {
    culture: string;
    ssgUrl: string;
    isEnabled: boolean;
}
