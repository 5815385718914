import { ChangeDetectorRef, Component, HostBinding, Injectable, Injector, Type, inject } from '@angular/core';

import { HooksWireup, OnDestroyCleanup, bufferWithSize, toDictionary } from '@frontend/sports/common/base-utils';
import { WidgetConfig } from '@frontend/sports/common/client-config-data-access';
import { Widget, WidgetContext, WidgetLocation, WidgetType } from '@frontend/sports/types/components/widget';
import { NativeAppService } from '@frontend/vanilla/core';
import { omit } from 'lodash-es';
import { Subject, Subscription, combineLatest, iif, of } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil, timeout } from 'rxjs/operators';

import { LoggerFactory } from '../../logging/logger-factory.service';
import { WidgetData, WidgetLoaderService } from './widget-loader.service';
import { WIDGET_SKELETON, WidgetSkeletonDefinition } from './widget-skeleton.model';

export interface RegisteredWidget {
    componentType: Type<WidgetComponent<unknown>>;
    injector?: Injector;
}

@HooksWireup()
@Component({
    template: '',
})
export abstract class WidgetComponent<T> extends OnDestroyCleanup {
    // hidden will only be set to false after the ordering logic of widgets(CLS initiative) is executed
    @HostBinding('class.hidden') get hidden() {
        return this._hidden;
    }

    set hidden(value) {
        this._hidden = value;
        //this hack was added for unknown reasons,
        //without it, class binding isn't being reflected after marking the view for check although the value was updated
        setTimeout(() => this.cdRef.markForCheck(), 1);
    }

    _hidden = !inject(NativeAppService).isTerminal;
    hasData = true;
    private widgetAlreadyReady: boolean;
    private widgetLoaderService = inject(WidgetLoaderService);
    private widgetConfig: Widget<never>;
    private parentConfig?: Widget<never>;
    private widgetLoadedSubscription$: Subscription;
    private logger = inject(LoggerFactory).getLogger('WidgetComponent');
    private cdRef = inject(ChangeDetectorRef);
    private widgetClientConfig = inject(WidgetConfig);
    get parent(): Readonly<Widget<never>> | undefined {
        return this.parentConfig;
    }

    get config(): Readonly<Widget<never>> {
        return this.widgetConfig;
    }

    protected logEmptyData(): void {
        this.logger.warning(`Empty widget data configured for ${this.config.id}`);
    }

    onResolve(widget: Widget<T>, parent?: Widget<unknown>, shouldCallOnData: boolean = true, isWidgetRefresh: boolean = false): void {
        this.widgetConfig = omit(widget, 'payload') as Widget<never>;

        if (parent) {
            this.parentConfig = omit(parent, 'payload') as Widget<never>;
        }

        if (shouldCallOnData) {
            if (this.config.location !== WidgetLocation.Right && !isWidgetRefresh) {
                this.widgetAlreadyReady = false;
                this.subscribeWidgetLoaded();
            }

            this.onData?.(widget.payload, isWidgetRefresh);
            if (parent?.payload) {
                this.onParentData?.(parent.payload);
            }
        }
    }

    protected onData?(data?: T, isWidgetRefresh?: boolean): void;

    protected onParentData?(data: unknown): void;

    getContext(): Partial<WidgetContext> {
        return { widgetId: this.config.id };
    }

    protected setWidgetLoaded(): void {
        if (this.widgetAlreadyReady) {
            this.hidden = !this.hasData;

            return;
        }

        //Adding below check to avoid null config from widget constructor on initial load
        if (this.config) {
            this.widgetLoaderService.setwidgetLoaded(`${this.config.location}-${this.config.id}`);
        }
    }

    private subscribeWidgetLoaded(): void {
        this.widgetLoadedSubscription$?.unsubscribe();
        this.widgetLoadedSubscription$ = combineLatest([
            this.widgetLoaderService.widgetLoadedDetails$(this.widgetConfig.location),
            iif(
                () => [WidgetLocation.Left, WidgetLocation.Center].includes(this.config.location),
                this.widgetLoaderService.allTopLocationWidgetsLoaded$,
                of(true),
            ),
        ])
            .pipe(
                map(([widgetsData, topLocationWidgetsLoaded]) => topLocationWidgetsLoaded && this.checkWidgetReady(widgetsData)),
                filter(Boolean),
                take(1),
                timeout({
                    each: this.widgetClientConfig.orderedRenderingTimeout,
                    with: () => of(false),
                }),
                takeUntil(this.destroyed$),
            )
            .subscribe((loadedWithoutTimeout) => {
                if (!loadedWithoutTimeout) {
                    this.logger.warning(
                        `Widget ordered rendering timed out after ${this.widgetClientConfig.orderedRenderingTimeout}ms for ${this.config.id}`,
                    );
                }
                this.widgetAlreadyReady = true;
                this.hidden = !this.hasData;
                this.cdRef.markForCheck();
            });
    }

    private checkWidgetReady = (widgets: WidgetData[]): boolean => {
        return widgets.findIndex((x) => x.widgetId === this.config.id && x.isLoaded) > -1 && this.checkPreceedingWidgetsReady(widgets);
    };

    private checkPreceedingWidgetsReady = (widgets: WidgetData[]): boolean => {
        const currentWidgetOrder = widgets.find((widget) => widget.widgetId === this.config.id)!.order;
        const filteredWidgets = widgets.filter((x) => x.order <= currentWidgetOrder);

        return filteredWidgets.every((x) => x.isLoaded);
    };
}

@Injectable({
    providedIn: 'root',
})
export class WidgetRegistryService {
    // inject all candidates that got registered
    private readonly widgetSkeletonConfig = inject<WidgetSkeletonDefinition<unknown>[]>(WIDGET_SKELETON, { optional: true });
    // create a dictionary <WidgetType, Component> for faster access
    readonly widgetSkeletons = this.widgetSkeletonConfig
        ? toDictionary(
              this.widgetSkeletonConfig,
              ({ type }) => type.toString(),
              (v) => v,
          )
        : {};

    private readonly widgets = new Map<WidgetType, RegisteredWidget>();
    private readonly lazyWidgets = new Subject<WidgetType>();

    private readonly debounceTimer = this.lazyWidgets.pipe(debounceTime(2));
    readonly lazyWidgets$ = this.lazyWidgets.pipe(
        bufferWithSize(10, this.debounceTimer),
        filter((data) => data.length > 0),
    );

    register<T>(name: WidgetType, widget: Type<WidgetComponent<T>>): void {
        this.assertNotAlreadyRegistered(name);
        this.widgets.set(name, { componentType: widget });
    }

    registerLazy<T>(name: WidgetType, widget: Type<WidgetComponent<T>>, injector?: Injector): void {
        this.assertNotAlreadyRegistered(name);

        this.widgets.set(name, { componentType: widget, injector });
        this.lazyWidgets.next(name);
    }

    get(name: WidgetType): RegisteredWidget | null {
        return this.widgets.get(name) ?? null;
    }

    private assertNotAlreadyRegistered(type: WidgetType): void {
        if (this.widgets.has(type)) {
            throw new Error(`Widget with name ${type} is already registered`);
        }
    }
}
