import { CashoutErrorType } from '@bpos/v1/cashout';
import { isDefined } from '@frontend/sports/common/base-utils';
import { PlacementErrorType } from '@frontend/sports/types/betslip';
import { orderBy } from 'lodash-es';

import { BetslipType } from '../../../../core/betslip-type';
import { BaseBetslipGroupPick } from '../../../../core/picks/betslip-group-pick';
import { BetslipPick } from '../../../../core/picks/betslip-pick';
import { GroupEnabledPickIds } from '../../../../core/picks/pick-id';
import { PickOddsState } from '../../../../core/picks/pick-models';
import { StakeUpdateErrorModel } from '../../../betplacement/models';
import { hasUnacceptedPick } from '../../../picks/services/linear-betslip-pick.utils';
import { IBetslipTypeState } from '../../../types/state';
import { BetPlacementError } from '../../errors/bet-placement-error';
import { BetslipError, ErrorOrigin } from '../../errors/betslip-error';
import { ClientErrorType } from '../../errors/client-error-type';
import { RewardTokensBetBuilderError } from '../../errors/general/betandget/reward-tokens-bet-builder-error';
import { RewardTokensMinStakeError } from '../../errors/general/betandget/reward-tokens-min-stake-error';
import { RewardTokensSelectionLevelOddsError } from '../../errors/general/betandget/reward-tokens-selection-level-odds-error';
import { RewardTokensTotalOddsError } from '../../errors/general/betandget/reward-tokens-total-odds-error';
import {
    GroupLinearPickError,
    GroupPickComboBetInvalidPrice,
    GroupPickError,
    GroupPickHasLiveLegsError,
    GroupPickInvalidPricePreCheckError,
    GroupWithClosedLegsError,
    GroupWithSuspendedLegsError,
    GroupWithUncombinableLegsError,
} from '../../errors/general/group-pick-error';
import { RewardTokensForbiddenError } from '../../errors/general/reward-tokens-forbidden-error';
import { RewardTokensGameTypeError } from '../../errors/general/reward-tokens-game-type-error';
import { RewardTokensMaxStakeError } from '../../errors/general/reward-tokens-max-stake-error';
import { RewardTokensMaxTotalOddsError } from '../../errors/general/reward-tokens-max-total-odds-error';
import { RewardTokensMinLegsError } from '../../errors/general/reward-tokens-min-legs-error';
import { RewardTokensSelectionLevelMaxOddsError } from '../../errors/general/reward-tokens-selection-level-max-odds-error';
import { RewardTokensSlipTypeError } from '../../errors/general/reward-tokens-slip-type-error';
import { TechnicalError } from '../../errors/general/technical-error';
import { NotifyUserError } from '../../errors/notify-user-error';
import { IncorrectOptionsCountPreCheckError } from '../../errors/pre-check/incorrect-options-count-pre-check-error';
import { NoBetbuilderSystemPreCheckError } from '../../errors/pre-check/no-betbuilder-system-pre-check-error';
import { OddsChangedPreCheckError } from '../../errors/pre-check/odds-changed-pre-check-error';
import { OverMaximumStakePreCheckError } from '../../errors/pre-check/over-maximum-stake-pre-check-error';
import { StakeNotDividablePreCheckError } from '../../errors/pre-check/stake-not-dividable-pre-check-error';
import { UnderMinimumStakePreCheckError } from '../../errors/pre-check/under-minimum-stake-pre-check-error';
import { UnderMinimumWinningsPreCheckError } from '../../errors/pre-check/under-minimum-winnings-pre-check-error';
import { ResultError } from '../../errors/result/result-error';
import { NotEnoughMoney } from '../../errors/user/not-enough-money';
import { OverallMaxWinPerUser } from '../../errors/user/overall-max-win-per-user';
import { BetslipTypePickErrors, IBetslipErrorsState, IBetslipPickErrors, SlipErrors } from '../../state';
import { getSlipErrorsForType } from './betslip-errors-state-utils';
import { isNewStakeHintError } from './stake-limit-errors-info-utils';

export const enum OverallMaxWinPerUserHintType {
    Any = 'Any',
    WithHint = 'WithHint',
    WithoutHint = 'WithoutHint',
}

export const isPickStatusLocked = function (error: BetslipError): boolean {
    return (
        error.type === PlacementErrorType.OptionInvisible ||
        error.type === CashoutErrorType.ChangedOptionStatus ||
        error.type === PlacementErrorType.CutOffDateReached ||
        error.type === PlacementErrorType.InvalidOption ||
        error.type === PlacementErrorType.MissingInfo ||
        error.type === PlacementErrorType.NotPlayable ||
        error.type === PlacementErrorType.OpenDateNotReached ||
        error.type === PlacementErrorType.OptionError
    );
};

export const isPickStatusClosed = function (error: BetslipError): boolean {
    return error.type === PlacementErrorType.CutOffDateReached || error.type === PlacementErrorType.OpenDateNotReached;
};

export const isPickStatusOddsChanged = function (error: BetslipError): boolean {
    return error.type === PlacementErrorType.OddsChanged;
};

/**
 * Is the given error changes pick status and the user should accept changes.
 *
 * @param error
 */
export const isPickStatusChangeError = function (error: BetslipError): boolean {
    return isPickStatusLocked(error) || isPickStatusClosed(error) || isPickStatusOddsChanged(error) || isGroupPickOfferChange(error);
};

export const isTeaserBetPickStatusChangeError = function (error: BetslipError): boolean {
    return isPickStatusLocked(error) || isPickStatusClosed(error);
};

export const isImportantError = function (error: BetslipError): boolean {
    return !(isPickStatusChangeError(error) || isErrorComboPrevention(error));
};

export const isBetslipStatusInsufficientMoney = function (error: BetslipError): boolean {
    return error.type === PlacementErrorType.InsufficientMoney;
};

export const isNotifyUserChangeError = function (error: BetslipError): error is NotifyUserError {
    return 'userHasBeenNotified' in error;
};

export const isPlaceBlockingError = function (error: BetslipError): boolean {
    return (
        isErrorComboPrevention(error) ||
        error.type === PlacementErrorType.BlockedAsInsider ||
        error.type === PlacementErrorType.IncorrectOptionCount ||
        error.type === PlacementErrorType.IncorrectBankerCount ||
        error.type === PlacementErrorType.UnderMinimumCompulsoryOdds ||
        isNotDividableStakeErrorPreCheck(error)
    );
};

export const isUnderMinimumStakeErrorPreCheck = (error: BetslipError): error is UnderMinimumStakePreCheckError =>
    error.type === PlacementErrorType.StakeBelowMinimumLimit;

export const isUnderMinimumWinningsErrorPreCheck = (error: BetslipError): error is UnderMinimumWinningsPreCheckError =>
    error.type === PlacementErrorType.UnderMinimumWinnings;

export const isOverMaximumStakeError = (error: BetslipError): error is OverMaximumStakePreCheckError =>
    error.type === PlacementErrorType.OverMaxBetLimit;

export const isNotDividableStakeErrorPreCheck = (error: BetslipError): error is StakeNotDividablePreCheckError =>
    error.type === PlacementErrorType.NotWholeStake;

export const isPickStatusOddsChangedPreCheck = function (error: BetslipError): error is OddsChangedPreCheckError {
    return error.type === PlacementErrorType.OddsChanged;
};

export const isNoBetBuilderSystemPreCheck = function (error: BetslipError): error is NoBetbuilderSystemPreCheckError {
    return error instanceof NoBetbuilderSystemPreCheckError;
};

/**
 * This function help us to check when we need to show yellow indication that has not compatible picks.
 */
export const isErrorComboPrevention = function (error: BetslipError): boolean {
    return error.type === PlacementErrorType.ComboPrevention || error.type === PlacementErrorType.MinimumCombo;
};

export const isStakeError = function (error: BetslipError): boolean {
    return (
        error.type === PlacementErrorType.StakeBelowMinimumLimit ||
        error.type === PlacementErrorType.UnderMinimumWinnings ||
        error.type === PlacementErrorType.OverMaxBetLimit ||
        error.type === PlacementErrorType.NotWholeStake ||
        error.type === PlacementErrorType.InsufficientMoney
    );
};

export const shouldThisPlacementErrorLockEditBet = (error: BetslipError): boolean => {
    return error.type === PlacementErrorType.TechnicalError;
};

export const isIncorrectOptionsCountError = (error: BetslipError): error is IncorrectOptionsCountPreCheckError =>
    error instanceof IncorrectOptionsCountPreCheckError;

export const isRewardTokensSlipTypeError = (error: BetslipError): error is RewardTokensSlipTypeError => error instanceof RewardTokensSlipTypeError;

export const isRewardTokensMaxStakeError = (error: BetslipError): error is RewardTokensMaxStakeError => error instanceof RewardTokensMaxStakeError;

export const isRewardTokensError = (error: BetslipError): boolean =>
    [
        RewardTokensSlipTypeError,
        RewardTokensMaxStakeError,
        RewardTokensMinStakeError,
        RewardTokensMinLegsError,
        RewardTokensSelectionLevelOddsError,
        RewardTokensGameTypeError,
        RewardTokensForbiddenError,
        RewardTokensTotalOddsError,
        RewardTokensBetBuilderError,
        RewardTokensMaxTotalOddsError,
        RewardTokensSelectionLevelMaxOddsError,
    ].some((t) => error instanceof t);

export const isOverallMaxWinPerUserExceededError = (error: BetslipError, hint: OverallMaxWinPerUserHintType): error is OverallMaxWinPerUser => {
    if (error instanceof OverallMaxWinPerUser) {
        switch (hint) {
            case OverallMaxWinPerUserHintType.Any:
                return true;
            case OverallMaxWinPerUserHintType.WithHint:
                return !!error.newStakeHint && error.newStakeHint > 0;
            case OverallMaxWinPerUserHintType.WithoutHint:
                return error.newStakeHint === 0;
        }
    }

    return false;
};

export const hasCriticalBetslipAndTypeErrorForType = (errorState: IBetslipErrorsState, type: BetslipType): boolean =>
    errorState.betslipErrors.some(isCriticalError) || errorState.betslipTypeErrors[type].some(isCriticalError);

export const hasCriticalSlipErrorsForType = (
    errorState: IBetslipErrorsState,
    type: BetslipType,
    types: IBetslipTypeState,
    isLinear: boolean,
): boolean => getSlipErrorsForType(type, errorState.slipErrors, types, isLinear).some(isCriticalError);

export const hasCriticalErrorForSlip = (errorState: IBetslipErrorsState, slipId: string): boolean =>
    (errorState.slipErrors[slipId] ?? []).some(isCriticalError);

export const isCriticalError = (error: BetslipError): boolean => {
    return (
        !isStakeError(error) &&
        !(error.type === PlacementErrorType.NoMultiSinglesAllowed) &&
        !(error instanceof BetPlacementError && isNewStakeHintError(error)) &&
        !(error instanceof TechnicalError)
    );
};

export const hasInsufficientMoneyError = (errors: BetslipError[]): boolean => {
    return errors.some(isBetslipStatusInsufficientMoney);
};

export const getStakeUpdateErrors = (slipErrors: SlipErrors): StakeUpdateErrorModel[] => {
    return Object.entries(slipErrors).flatMap(([key, errors]) =>
        errors.filter((e) => isUnderMinimumStakeErrorPreCheck(e)).map((e) => ({ error: e as UnderMinimumStakePreCheckError, slipId: key })),
    );
};

/**
 * Push a new error in the errors collection
 *
 * @param currentErrors
 * @param newError
 */
export const errorCollectionPusher = <TError extends BetslipError = BetslipError>(currentErrors: TError[], newError: TError): TError[] => {
    if (currentErrors.some((e) => newError.equals(e))) {
        // Error already exists.
        return currentErrors;
    }

    return currentErrors.filter((e) => e.constructor !== newError.constructor).concat(newError);
};

export function getPlacementError(errors: BetslipError[]): BetslipError | null {
    const filteredErrors = filterBetPlacementErrors(errors);

    return filteredErrors[0] || null;
}

export function getPlacementErrorFromAllErrors(
    betslipErrors: BetslipError[],
    typePickErrors: BetslipTypePickErrors,
    slipErrors: SlipErrors,
): BetslipError | null {
    const allPickErrors = Object.values(typePickErrors).flatMap((pickErrors) => Object.values(pickErrors).flatMap((errors) => errors));

    const allSlipErrors = Object.values(slipErrors).flatMap((errors) => errors);

    return getPlacementError([...betslipErrors, ...allPickErrors, ...allSlipErrors]);
}

function filterBetPlacementErrors(errors: BetslipError[]): BetslipError[] {
    const filteredErrors = errors.filter((error) => !error.isHidden && error.origin === ErrorOrigin.BetPlacement);

    return orderBy(filteredErrors, (x) => x.priority);
}

const exitFreebetErrors: (PlacementErrorType | CashoutErrorType | ClientErrorType)[] = [
    PlacementErrorType.InvalidFreeBet,
    PlacementErrorType.FreeBetExpired,
    PlacementErrorType.FreeBetNotActive,
];

export function isExitFreeBetError(error: BetslipError | null): boolean {
    return !!error && exitFreebetErrors.includes(error.type);
}

export function isFinishedError(error: BetslipError): boolean {
    return error.code === 'COS' || error.code === 'CODR';
}

export function canShowPickErrorAsBetslipError(betslipErrors: BetslipError[], currentSelectedBetslipType: BetslipType | null): boolean {
    // For certain NewStakeHintErrors we receive them per pick from BPOS but in specific situations we need to show them for the entire betslip
    return currentSelectedBetslipType !== BetslipType.Single && betslipErrors.every((error) => isStakeError(error));
}

export function isNewStakeHintPlacementError(error: BetslipError): boolean {
    return error instanceof BetPlacementError && error.origin === ErrorOrigin.BetPlacement && typeof error.newStakeHint === 'number';
}

export const isNotEnoughMoney = (error: BetslipError): error is NotEnoughMoney => error instanceof NotEnoughMoney;

export function checkOfferChanged(pickErrors: ResultError[]): boolean {
    return pickErrors.some((e) => isPickStatusChangeError(e));
}

export function checkOfferChangedLinear(picks: BetslipPick[], typeState: IBetslipTypeState, pickErrors: ResultError[]): boolean {
    const oddsChanged = pickErrors.some((e) => isPickStatusOddsChanged(e));

    return oddsChanged || hasUnacceptedPick(typeState, picks);
}

export function isGroupLockedPickError(error: ResultError): boolean {
    return error instanceof GroupWithSuspendedLegsError;
}

export function isGroupOfferChangedError(error: BetslipError): boolean {
    return error instanceof ResultError && isGroupLockedPickError(error);
}

export function isBetPlacementBlockingError(error: BetslipError): boolean {
    return (
        ![
            PlacementErrorType.TechnicalError,
            PlacementErrorType.OverAskOfferError,
            PlacementErrorType.InsufficientMoney,
            PlacementErrorType.DoubleBetPrevention,
        ].includes(error.type as PlacementErrorType) && !isNewStakeHintError(error as BetPlacementError)
    );
}

export function pickErrorsHasBlockingErrors(pickErrors: IBetslipPickErrors): boolean {
    return Object.values(pickErrors).some((errors) => errors.some((err) => err instanceof GroupWithSuspendedLegsError));
}

export function getClosedLegIdsWithinValidGroups(picks: BetslipPick[], picksErrors: IBetslipPickErrors) {
    return (
        picks
            .filter(BaseBetslipGroupPick.isPick)
            //ignore closed picks within a group if the group is suspended
            .filter((g) => g.oddsState === PickOddsState.Open)
            .map((g) => picksErrors[g.id.toString()]?.find((error) => error instanceof GroupWithClosedLegsError) as GroupWithClosedLegsError)
            .filter(isDefined)
            .flatMap((g) => g.legIds as GroupEnabledPickIds[])
    );
}

export function getSuspendedLegIdsWithinValidGroups(picks: BetslipPick[], picksErrors: IBetslipPickErrors) {
    return (
        picks
            .filter(BaseBetslipGroupPick.isPick)
            //ignore suspended picks within a group if the group is suspended
            .filter((g) => g.oddsState === PickOddsState.Open)
            .map((g) => picksErrors[g.id.toString()]?.find((error) => error instanceof GroupWithSuspendedLegsError) as GroupWithSuspendedLegsError)
            .filter(isDefined)
            .flatMap((g) => g.legIds as GroupEnabledPickIds[])
    );
}

export function isGroupPickOfferChange(error: BetslipError) {
    return error instanceof GroupWithSuspendedLegsError || error instanceof GroupWithClosedLegsError;
}

/**
 * Checks if a pick should be displayed on the pick level in the betslip
 * @param error The error to check
 * @returns True if the error should be displayed on the pick level
 */
export function isDisplayablePickError(error: ResultError): boolean {
    return (
        !error.isHidden &&
        !(
            error.type === PlacementErrorType.ComboPrevention ||
            error.type === PlacementErrorType.CutOffDateReached ||
            error.type === PlacementErrorType.OptionInvisible ||
            error.type === PlacementErrorType.OddsChanged
        )
    );
}

export function isGroupPickError(error: BetslipError): error is GroupPickError {
    return error instanceof GroupPickError || error instanceof GroupLinearPickError;
}

export function isGroupPickInvalidPriceError(error: BetslipError): error is GroupPickComboBetInvalidPrice {
    return error instanceof GroupPickComboBetInvalidPrice || error instanceof GroupPickInvalidPricePreCheckError;
}

export function isGroupWithUncombinableLegsError(error: BetslipError): error is GroupWithUncombinableLegsError {
    return error instanceof GroupWithUncombinableLegsError;
}

export function isDrawerGroupError(
    error: BetslipError,
): error is GroupWithUncombinableLegsError | GroupPickComboBetInvalidPrice | GroupPickHasLiveLegsError {
    return isGroupWithUncombinableLegsError(error) || isGroupPickInvalidPriceError(error) || isGroupPickHasLiveLegsError(error);
}

export function isGroupPickHasLiveLegsError(error: BetslipError): error is GroupPickHasLiveLegsError {
    return error instanceof GroupPickHasLiveLegsError;
}

export function groupPickHasWarning(error: BetslipError): error is GroupPickHasLiveLegsError | GroupPickComboBetInvalidPrice {
    return isGroupPickInvalidPriceError(error) || isGroupPickHasLiveLegsError(error);
}
