import { Injectable } from '@angular/core';

import { OfferSource } from '@cds';
import { Utils, isDefined } from '@frontend/sports/common/base-utils';
import { UrlService } from '@frontend/vanilla/core';
import { capitalize, reverse, toInteger, toNumber } from 'lodash-es';

import { BetslipConstants, OptionParameters, OptionParams, OptionQuery, OptionRequest, PickUriParams } from './pick-uri.model';

@Injectable({ providedIn: 'root' })
export class PickUriService {
    constructor(
        private urlService: UrlService,
        private utils: Utils,
    ) {}

    generate(picksParam: OptionQuery[], uriParams: PickUriParams): string {
        const path = uriParams.addPage ? this.urlService.current().path() : '/';
        const url = this.urlService.parse(path);
        const options = this.mapParams(picksParam);

        if (!options.length) {
            return '';
        }

        url.search.append(OptionParameters.Options, options.join(','));

        if (uriParams.type) {
            url.search.append(OptionParameters.Type, uriParams.type);
        }

        if (uriParams.stake) {
            url.search.append(OptionParameters.Stake, uriParams.stake.toString());

            if (uriParams.currency) {
                url.search.append(OptionParameters.Currency, uriParams.currency);
            }
        }

        if (uriParams.userId && uriParams.betslipId) {
            url.search.append(OptionParameters.Refbet, `${uriParams.userId}|${uriParams.betslipId}`);
        }

        if (uriParams.source) {
            url.search.append(OptionParameters.Source, uriParams.source);
        }

        if (picksParam.every((pick) => pick.offerSource === OfferSource.BetBuilder.toString())) {
            url.search.append(OptionParameters.OfferSource, OfferSource.BetBuilder.toString());
        }

        return url.url();
    }

    parse(params: OptionParams, userCurrency: string): OptionRequest {
        const result: OptionRequest = { query: [], stake: null, type: BetslipConstants.Single.valueOf(), source: 'AffliateOffer' };

        if (!params.options) {
            return result;
        }

        const decodedUri = decodeURIComponent(params.options);

        result.query = decodedUri
            .split(',')
            .map((fixture: string) => {
                if (this.isSgp(fixture)) {
                    /** new url format for grouped picks (leg separator sign: '~' and also new param separator '_' due to sgpId format):
                     * TV1: {fixtureId}_{pickGroupId}_{gameId}.{resultId}_{isClassicBetBuilder}~{fixtureId}_{pickGroupId}_{gameId}.{resultId}_{isClassicBetBuilder}
                     * TV2: {fixtureId}_{pickGroupId}_{optionMarketId}_{optionId}_{isClassicBetBuilder}~{fixtureId}_{pickGroupId}_{optionMarketId}_{optionId}_{isClassicBetBuilder}
                     */
                    const legs = fixture.split('~');

                    const parsedLegs: OptionQuery = {
                        tv1Legs: [],
                        tv2Legs: [],
                    };

                    legs.forEach((leg) => {
                        const values = leg.split('_');

                        if (this.isTv2Pick(values[0])) {
                            parsedLegs.tv2Legs?.push({
                                fixtureId: values[0],
                                pickGroupId: values[1],
                                optionMarketId: +values[2],
                                optionId: +values[3],
                                isClassicBetBuilder: JSON.parse(values[4]),
                            });
                        } else {
                            parsedLegs.tv1Legs?.push({
                                fixtureId: values[0],
                                pickGroupId: values[1],
                                gameId: +values[2],
                                resultId: +values[3],
                                useLiveFallback: JSON.parse(values[4]),
                            });
                        }
                    });

                    return parsedLegs;
                }

                const values = this.extractOptionIds(fixture);

                if (!fixture.includes(':') || values.length === 1) {
                    return {
                        fixture: values[2],
                        optionGroup: values[1] ? toInteger(values[1]) : undefined,
                        option: toInteger(values[0]),
                    };
                } else {
                    // check if priceid is passed
                    if (values.length === 4) {
                        return {
                            fixture: values[3],
                            optionGroup: values[2] ? toInteger(values[2]) : undefined,
                            option: toInteger(values[1]),
                            priceId: toInteger(values[0]),
                        };
                    } else {
                        return {
                            fixture: values[2],
                            optionGroup: values[1] ? toInteger(values[1]) : undefined,
                            option: toInteger(values[0]),
                        };
                    }
                }
            })
            .filter(isDefined);

        result.stake = this.getStake(params, userCurrency);
        result.type = this.getBetslipType(params);
        result.source = params.source || result.source;
        result.offerSource = params.offerSource;

        return result;
    }

    private extractOptionIds(fixture: string): string[] {
        if (fixture.includes('--')) {
            const optionIdIndex = fixture.indexOf('--');
            const optionId = fixture.slice(optionIdIndex);
            const fixtureOptionGroup = fixture.slice(0, optionIdIndex).split('-');

            return [optionId.substring(1), fixtureOptionGroup[1], fixtureOptionGroup[0]];
        }

        const fixtureParts = fixture.split('-');

        if (fixtureParts[1] && !fixtureParts[0]) {
            return [fixture];
        }

        return reverse(fixtureParts);
    }

    private mapParams(params: OptionQuery[]): string[] {
        const options: string[] = [];

        params.forEach((pick) => {
            if (pick.tv1Legs?.length || pick.tv2Legs?.length) {
                const tv1Legs =
                    pick.tv1Legs?.map((leg) => `${leg.fixtureId}_${leg.pickGroupId}_${leg.gameId}_${leg.resultId}_${leg.useLiveFallback || false}`) ||
                    [];
                const tv2Legs =
                    pick.tv2Legs?.map(
                        (leg) => `${leg.fixtureId}_${leg.pickGroupId}_${leg.optionMarketId}_${leg.optionId}_${leg.isClassicBetBuilder}`,
                    ) || [];

                options.push([...tv1Legs, ...tv2Legs].join('~'));
            } else if (pick.priceId) {
                options.push(`${pick.fixture}-${pick.optionGroup}-${pick.option}-${pick.priceId}`);
            } else {
                options.push(`${pick.fixture}-${pick.optionGroup}-${pick.option}`);
            }
        });

        return options;
    }

    private getStake(params: OptionParams, usersCurrency: string): number | null {
        const currency = (params.currency || usersCurrency).toLowerCase();
        const userCurrency = usersCurrency.toLowerCase();

        let stake: number | null = null;
        const value = toNumber(params.stake);

        if (value <= 0) {
            return null;
        }

        if (userCurrency === currency.toLowerCase() && !isNaN(value)) {
            stake = this.utils.floor(value);

            if (stake > 10000000.0) {
                stake = null;
            }
        }

        return stake;
    }

    private getBetslipType(params: OptionParams): string | null {
        const normalized = capitalize(params.type);
        const allowed = [BetslipConstants.Combo.valueOf(), BetslipConstants.Single.valueOf(), BetslipConstants.System.valueOf()];

        if (allowed.indexOf(normalized) === -1) {
            return null;
        }

        return normalized;
    }

    private isSgp = (fixture: string): boolean => fixture.includes('_');

    private isTv2Pick = (value: string) => value.includes(':');
}
