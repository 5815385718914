import { Decimal } from 'decimal.js';

import { BetslipType } from '../../../../core/betslip-type';
import { UnderMinimumStake } from '../general/under-minimum-stake';
import { PreCheckErrorMixin } from './pre-check-error';
import { ITypeRestrictedPreCheckError } from './type-restricted-pre-check-error.interface';

export class UnderMinimumStakePreCheckError extends PreCheckErrorMixin(UnderMinimumStake) {
    constructor(
        readonly minimumStake: Decimal,
        readonly betStake: Decimal,
        readonly numberOfBetsInBet: number,
        readonly pickId: string,
        teaserId: number,
    ) {
        super();
        this.sportId = typeof teaserId === 'undefined' ? null : teaserId;
    }

    getMinimumStake = () => this.minimumStake;
}

export class BetslipTypeUnderMinimumStakePreCheckError extends UnderMinimumStakePreCheckError implements ITypeRestrictedPreCheckError {
    constructor(
        minimumStake: Decimal,
        betStake: Decimal,
        numberOfBetsInBet: number,
        pickId: string,
        teaserId: number,
        private errorBetslipType: BetslipType,
        private errorSlipTypeKey?: string,
    ) {
        super(minimumStake, betStake, numberOfBetsInBet, pickId, teaserId);
    }

    get betslipType(): BetslipType {
        return this.errorBetslipType;
    }

    get slipTypeKey(): string | undefined {
        return this.errorSlipTypeKey;
    }
}
