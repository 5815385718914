import { LoadingIndicatorHandler } from '@frontend/vanilla/core';

export interface ILoadingIndicatorHandler {
    done(): void;
}

export interface ILoadingIndicatorShutdown {
    shutdown(): void;
}

export class VanillaLoadingIndicatorHandlerWrapper implements ILoadingIndicatorHandler {
    constructor(private loadingIndicatorHandler: LoadingIndicatorHandler) {}

    done(): void {
        this.loadingIndicatorHandler.done();
    }
}

export class CustomLoadingIndicatorHandlerWrapper implements ILoadingIndicatorHandler {
    constructor(private loadingIndicatorShutdown: ILoadingIndicatorShutdown) {}

    done(): void {
        this.loadingIndicatorShutdown.shutdown();
    }
}
