import { NumberDictionary, StringDictionary } from './extended-types';

export const isObjectEqualShadowCopy = <T>(prev: T, next: T): boolean => {
    // eslint-disable-next-line eqeqeq
    if (prev === null && next === null) {
        // When both null then objects are equal
        return true;
    }

    // eslint-disable-next-line eqeqeq
    if (prev === null || next === null) {
        // When one is null then objects are unequal
        return false;
    }

    if (typeof prev === 'undefined' && typeof next === 'undefined') {
        // When both undefined then objects are equal
        return true;
    }

    if (typeof prev === 'undefined' || typeof next === 'undefined') {
        // When one is undefined then objects are unequal
        return false;
    }

    const prevKeys = Object.keys(prev);
    const nextKeys = Object.keys(next);
    if (prevKeys.length !== nextKeys.length) {
        // If number of keys is different: unequal
        return false;
    }
    for (const key of nextKeys) {
        if (prev[key] !== next[key]) {
            // One key is different: unequal
            return false;
        }
    }

    return true; // Keys are the same: equal
};

/**
 * @deprecated This class is deprecated and will be removed in future versions.
 * Use `orderBy` from lodash instead.
 */
export const orderBy = <TValue, TKey>(arr: TValue[], access: (val: TValue) => TKey): TValue[] => {
    return arr.sort((a: TValue, b: TValue) => {
        if (access(a) < access(b)) {
            return -1;
        }
        if (access(a) > access(b)) {
            return 1;
        }

        return 0;
    });
};

/**
 * @deprecated This class is deprecated and will be removed in future versions.
 * Use `values` from lodash instead.
 */
export const objectValues = <TValue>(obj: { [key: number]: TValue } | { [key: string]: TValue }): TValue[] => {
    // @ts-ignore ES2017 check
    if (Object.values) {
        // @ts-ignore ES2017 check
        return Object.values(obj);
    } else {
        const keys = Object.keys(obj);
        const res: TValue[] = [];
        for (const key of keys) {
            res.push(obj[key]);
        }

        return res;
    }
};

/**
 * @deprecated This class is deprecated and will be removed in future versions.
 * Use `entries` from lodash instead.
 * @example
 * _.map(_.entries(obj), ([key, value]) => ({ key, value }))
 */
export const objectEntries = <TValue>(obj: StringDictionary<TValue> | NumberDictionary<TValue>): { key: string; value: TValue }[] => {
    // @ts-ignore ES2017 check
    if (Object.entries) {
        // @ts-ignore ES2017 check
        return Object.entries(obj).map((e: [string, TValue]) => ({ key: e[0], value: e[1] }));
    } else {
        return Object.keys(obj).map((k) => ({ key: k, value: obj[k] }));
    }
};

/**
 * @deprecated This class is deprecated and will be removed in future versions.
 * Use `omit` and `omitBy` from lodash instead.
 */
export const removeObjectKey = <TKey extends string | number, TValue extends {}>(
    // @ts-ignore key
    obj: { [key: TKey]: TValue },
    key: TKey,
    // @ts-ignore key
): { [key: TKey]: TValue } => {
    const newObj = { ...obj };
    // @ts-ignore key
    //eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete newObj[key];

    return newObj;
};

export const mapMirror = function <TKey, TValue>(obj: Map<TKey, TValue>): Map<TValue, TKey> {
    const entries = obj.entries();
    const result = new Map<TValue, TKey>();
    for (const entry of entries) {
        result.set(entry[1], entry[0]);
    }

    return result;
};

export const calcDiff = function <T extends string | number>(prev: T[], next: T[]): { added: T[]; removed: T[] } {
    if ((!prev && !next) || (!prev.length && !next.length)) {
        return { removed: [], added: [] };
    }
    if (!prev || !prev.length) {
        return { removed: [], added: [...next] };
    }
    if (!next || !next.length) {
        return { removed: [...prev], added: [] };
    }

    const removed = new Map(prev.map((item) => [item, true]));
    const added = new Map(next.map((item) => [item, true]));
    for (const [item] of removed) {
        if (added.has(item)) {
            removed.set(item, false);
        }
    }
    for (const [item] of added) {
        if (removed.has(item)) {
            added.set(item, false);
        }
    }

    return {
        removed: Array.from(removed.entries())
            .filter((e) => !!e[1])
            .map((e) => e[0]),
        added: Array.from(added.entries())
            .filter((e) => !!e[1])
            .map((e) => e[0]),
    };
};

export const toDictionary = function <T, TKey extends string | number, TValue>(
    list: T[] | undefined,
    keySelector: (item: T) => TKey,
    valueSelector: (item: T, index: number) => TValue,
): StringDictionary<TValue> {
    const result: StringDictionary<TValue> = {};

    if (!list) {
        return {};
    }

    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        const key = keySelector(item);

        if (key in result) {
            console.warn('Item with the same key has already been added');
            continue;
        }

        result[key] = valueSelector(item, i);
    }

    return result;
};
