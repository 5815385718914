import { Injectable } from '@angular/core';

import { CountItem } from '@frontend/sports/common/base-utils';
import { GeolocationConfig } from '@frontend/sports/common/client-config-data-access';
import { WidgetContext, WidgetPage } from '@frontend/sports/types/components/widget';
import { GeolocationService } from '@frontend/vanilla/core';
import { Observable, forkJoin, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { SportsCacheService } from '../../client-caching/sports-cache.service';
import { CompetitionIdService } from '../../event-list-shared/sport/competitions/competition-id.service';

export interface WidgetContextEnhancement {
    location?: string;
    realCompetitionId?: number;
    compoundCompetitionId?: string;
}

interface EnhancedCompetitionId {
    realCompetitionId?: number;
    compoundCompetitionId?: string;
}

@Injectable({ providedIn: 'root' })
export class WidgetContextEnhanceService {
    constructor(
        private geolocation: GeolocationService,
        private geolocationConfig: GeolocationConfig,
        private bettingCache: SportsCacheService,
        private competitionIdService: CompetitionIdService,
    ) {}

    getEnhancedProperties(context: WidgetContext): Observable<WidgetContextEnhancement> {
        return forkJoin([this.getCompetitionIds$(context), this.getGeolocation$()]).pipe(
            map(([competitionIds, location]) => {
                return {
                    ...competitionIds,
                    location,
                };
            }),
        );
    }

    private getCompetitionIds$(context: WidgetContext): Observable<EnhancedCompetitionId | undefined> {
        if (context.page !== WidgetPage.CompetitionLobby) {
            return of(undefined);
        }

        return this.bettingCache.getSport(context.sportId!).pipe(
            map((sport) => {
                return sport
                    ? {
                          realCompetitionId: this.getRealVirtualCompetitionId(sport, context),
                          compoundCompetitionId: this.getCompoundCompetitionId(sport, context),
                      }
                    : undefined;
            }),
        );
    }

    private getRealVirtualCompetitionId(sport: CountItem, context: WidgetContext): number | undefined {
        if (!context.virtualCompetitionId) {
            return undefined;
        }

        return this.competitionIdService.getRealVirtualCompetitionId(
            sport,
            context.regionId || context.tournamentId,
            context.virtualCompetitionId,
            context.virtualCompetitionGroupId,
        );
    }

    private getCompoundCompetitionId(sport: CountItem, context: WidgetContext): string | undefined {
        if (context.virtualCompetitionId) {
            return undefined;
        }

        return this.competitionIdService.getCompoundId(sport, context.competitionId);
    }

    private getGeolocation$(): Observable<string | undefined> {
        if (!this.geolocationConfig.isGeolocationEnabled) {
            return of(undefined);
        }

        return this.geolocation.whenReady.pipe(
            first(),
            map(() => {
                return this.geolocation.currentPosition?.mappedLocation?.locationId || undefined;
            }),
        );
    }
}
