"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Subscription = /** @class */function () {
  function Subscription(topic) {
    this.topic = topic;
    if (!topic) {
      throw new Error('A valid topic name is required for creating subscription');
    }
    this.activationCount = 0;
  }
  Subscription.prototype.isSingle = function () {
    return this.activationCount === 1;
  };
  Subscription.prototype.isEmpty = function () {
    return this.activationCount === 0;
  };
  Subscription.prototype.increment = function () {
    this.activationCount++;
  };
  Subscription.prototype.decrement = function () {
    this.activationCount = Math.max(0, this.activationCount - 1);
  };
  return Subscription;
}();
exports.default = Subscription;
