import { Injectable } from '@angular/core';

import { SimpleCallback, Utils } from '@frontend/sports/common/base-utils';
import { Subscription } from 'rxjs';

import { TimerService } from './timer.service';

export interface TimerConfig {
    id: string;
    interval?: TickerInterval;
    onTick(): void;
}

export enum TickerInterval {
    Second = 'Second',
    Minute = 'Minute',
}

@Injectable({ providedIn: 'root' })
export class TickerService {
    private timers: { [id: string]: TimerConfig } = {};
    private second = 1000;
    private minute = this.second * 60;
    private secondsInterval?: SimpleCallback;
    private minutesInterval?: SimpleCallback;

    constructor(
        private timerService: TimerService,
        private util: Utils,
    ) {}

    subscribe(config: TimerConfig): void;
    subscribe(callback: () => void, interval?: TickerInterval): Subscription;
    subscribe(config: TimerConfig | (() => void), interval?: TickerInterval): Subscription | void {
        let timer: TimerConfig;

        if (config instanceof Function) {
            timer = {
                id: this.util.generateGuid(),
                onTick: config,
                interval: interval || TickerInterval.Second,
            };
        } else {
            timer = config;
            timer.interval = timer.interval || TickerInterval.Second;
        }

        this.timers[timer.id] = timer;
        this.runInterval();

        return new Subscription(() => this.unsubscribe(timer.id));
    }

    unsubscribe(id: string): void {
        this.unsubscribeInternal(id, this.timers);
    }

    private runInterval(): void {
        if (this.secondsInterval || this.minutesInterval) {
            return;
        }

        this.secondsInterval = this.timerService.setIntervalOutsideAngular(() => this.runIntervalType(TickerInterval.Second), this.second);
        this.minutesInterval = this.timerService.setIntervalOutsideAngular(() => this.runIntervalType(TickerInterval.Minute), this.minute);
    }

    private runIntervalType(interval: TickerInterval): void {
        const keysForInterval = Object.keys(this.timers).filter((id) => this.timers[id].interval === interval);

        if (!keysForInterval.length) {
            return;
        }

        keysForInterval.forEach((id) => this.timers[id].onTick());
    }

    private unsubscribeInternal(id: string, timers: { [id: number]: TimerConfig }): void {
        if (!timers[id]) {
            return;
        }

        delete timers[id];

        if (Object.keys(timers).length) {
            return;
        }

        if (this.secondsInterval) {
            this.secondsInterval();
            this.secondsInterval = undefined;
        }

        if (this.minutesInterval) {
            this.minutesInterval();
            this.minutesInterval = undefined;
        }
    }
}
