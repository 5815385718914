import { InjectionToken } from '@angular/core';

import { EarlyPayoutSubmitService } from './early-payout-submit.service';
import { EarlyPayoutSubscriptionService } from './early-payout-subscription.service';
import { EarlyPayoutService } from './early-payout.service';

export const earlyPayoutServiceMyBetsToken = new InjectionToken<EarlyPayoutService>('EARLY_PAYOUT_SERVICE_MY_BETS');
export const earlyPayoutServiceEditBetToken = new InjectionToken<EarlyPayoutService>('EARLY_PAYOUT_SERVICE_EDIT_BET');

export const earlyPayoutSubscriptionServiceMyBetsToken = new InjectionToken<EarlyPayoutSubscriptionService>(
    'EARLY_PAYOUT_SUBSCRIPTION_SERVICE_MY_BETS',
);
export const earlyPayoutSubscriptionServiceEditBetToken = new InjectionToken<EarlyPayoutSubscriptionService>(
    'EARLY_PAYOUT_SUBSCRIPTION_SERVICE_EDIT_BET',
);

export const earlyPayoutSubmitServiceMyBetsToken = new InjectionToken<EarlyPayoutSubmitService>('EARLY_PAYOUT_SUBMIT_SERVICE_MY_BETS');
export const earlyPayoutSubmitServiceEditBetToken = new InjectionToken<EarlyPayoutSubmitService>('EARLY_PAYOUT_SUBMIT_SERVICE_EDIT_BET');
