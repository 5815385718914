import { Injectable } from '@angular/core';

import { BestOddsGuaranteedToken } from 'packages/sports/common/betslip/modules/reward-tokens/reward-tokens.model';
import {
    hasBestOddsGuaranteeFlag,
    isValidDayOfWeek,
    isValidExpiryDate,
    isValidSport,
    isValidStartDate,
    isValidTimeOfDay,
} from 'packages/sports/common/betslip/modules/reward-tokens/validators';

import { BestOddsFixtureLikeInput } from '../models/models';

@Injectable({ providedIn: 'root' })
export class FixtureBestOddsGuaranteedTokenValidatorService {
    private validators: ((token: BestOddsGuaranteedToken, fixture: BestOddsFixtureLikeInput) => boolean)[] = [
        (token, fixture) => hasBestOddsGuaranteeFlag(fixture),
        isValidStartDate,
        isValidExpiryDate,
        isValidDayOfWeek,
        isValidTimeOfDay,
        isValidSport,
    ];

    validate(token: BestOddsGuaranteedToken, fixture: BestOddsFixtureLikeInput): boolean {
        return this.validators.every((validator) => validator(token, fixture));
    }
}
