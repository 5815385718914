import { Coupon, CouponPage } from '@cds/betting-offer/domain-specific/coupons';
import { CountItem } from '@frontend/sports/common/base-utils';
import { DomainEvent } from '@frontend/sports/common/dispatcher-utils';

import { SportModel } from '../event-model/model/event.model';
import { GridEvent } from '../grid-base/grid.model';
import { SubNavigationItem } from '../sub-navigation/sub-navigation.models';
import { TabBarItem } from '../tab-bar/tab-bar.models';

export interface MultiBuilderCrumb {
    id: number;
    title: string;
    description: string;
    active?: boolean;
}

export interface MultiBuilderCrumbList {
    coupons: MultiBuilderCrumb[];
    sport: SportModel;
    counts?: CountItem;
}

export interface MarketGroupItem extends TabBarItem<string> {
    showOptionName?: boolean;
    showColumnName?: boolean;
    sportId?: number;
}

export interface EventsByDay {
    day: string;
    fixtures: GridEvent[];
}

export interface MultiBuilderCoupon extends Coupon {
    order: number;
}

export interface MultibuilderResponse {
    subNavigationItems: SubNavigationItem[];
    fixtures: CouponPage | undefined;
}

/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */

export class MultiBuilderDispatcherEvents {
    static BetslipComboPreventionError = new DomainEvent<boolean>('BETSLIP_COMBOPREVENTIONERROR');
    static OnMultiBuilderPage = new DomainEvent<boolean>('ON_MULTIBUILDER_PAGE');
    static OnPopupClosed = new DomainEvent<string>('ON_POPUP_CLOSED');
    static ClosePreviousPopup = new DomainEvent<string>('ON_PREVIOUS_POPUP_CLOSE');
}
/* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

export enum MultiBuilderConstants {
    OnBoardingMessageKey = 'MultibuilderOnboardingMessage',
    SelectedMarketGroupKey = 'MarketGroup',
    MultibuilderCacheKey = 'MultibuilderCouponResponse',
}
