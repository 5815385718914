import { Injectable } from '@angular/core';

import { Page, WindowRef } from '@frontend/vanilla/core';

import { LoggerFactory } from '../logging/logger-factory.service';
import { SportsRemoteLogger } from '../logging/sports-remote-logger.service';

// noinspection SpellCheckingInspection
const charsets = {
    en: 'ABCDEFGHabcdefghijklmnopqrstuvwxyz',
    el: 'αβγδεζηθικλμνξοπρστυφχψως',
    bg: 'абвгдежзийклмнопрстуфхцчшщъьюя',
};

const decimals = '0123456789';
const separators = '. ,';

@Injectable({ providedIn: 'root' })
export class CharsetSizeService {
    private averageWidth?: number;
    private averageDecimalWidth: { [key: number]: number } = {};
    private averageSeparatorWidth: { [key: number]: number } = {};

    private DEFAULT_SIZE = 7.25;

    private readonly logger: SportsRemoteLogger;

    constructor(
        private window: WindowRef,
        private page: Page,
        loggerFactory: LoggerFactory,
    ) {
        this.logger = loggerFactory.getLogger('CharsetSizeService');
    }

    /**
     * @whatItDoes Returns the average character width for the current language.
     *
     * @description
     *
     * Returns the average character width for the current language(average width of the alphabet letter).
     * The font size is hardcoded to 14px. The font family is body's font family.
     *
     */
    getAverageSize(): number {
        return (this.averageWidth = this.averageWidth || this.getAverageSizeCurrentCharset());
    }

    getAverageDecimalSize(fontSize: number): number {
        return (this.averageDecimalWidth[fontSize] = this.averageDecimalWidth[fontSize] || this.getAverageDecimalSizeCurrentCharset(fontSize));
    }

    getAverageSeparatorSize(fontSize: number): number {
        return (this.averageSeparatorWidth[fontSize] = this.averageSeparatorWidth[fontSize] || this.getAverageSeparatorSizeCurrentCharset(fontSize));
    }

    private getAverageDecimalSizeCurrentCharset(fontSize: number): number {
        try {
            return this.getSampleCharsetWidth(decimals, fontSize) / decimals.length;
        } catch (e) {
            this.logger.warning(`Failed to compute the averageSize for decimals for fontSize : ${fontSize}`);

            return this.DEFAULT_SIZE;
        }
    }

    private getAverageSeparatorSizeCurrentCharset(fontSize: number): number {
        try {
            return this.getSampleCharsetWidth(separators, fontSize) / separators.length;
        } catch (e) {
            this.logger.warning(`Failed to compute the averageSize for separators for fontSize : ${fontSize}`);

            return this.DEFAULT_SIZE;
        }
    }

    private getAverageSizeCurrentCharset(): number {
        try {
            const charset = this.getCharsetForLang(this.page.lang);
            const charsetWidth = this.getSampleCharsetWidth(charset);

            return charsetWidth / charset.length;
        } catch (e) {
            this.logger.warning(`Failed to compute the averageSize for lang: '${this.page ? this.page.lang : '?'}'`);

            return this.DEFAULT_SIZE;
        }
    }

    private getSampleCharsetWidth(charset: string, fontSize: number = 14): number {
        let span: HTMLElement | undefined;
        const document = this.window.nativeWindow.document;
        try {
            const styleDec = this.window.nativeWindow.getComputedStyle(document.body);
            span = document.createElement('span');
            span.innerText = charset;
            span.style.fontSize = `${fontSize}px`;
            span.style.fontFamily = styleDec.fontFamily ? styleDec.fontFamily.replace(/['"]*/g, '').split(',')[0] : span.style.fontFamily;
            document.body.appendChild(span);

            return span.offsetWidth;
        } finally {
            if (span) {
                document.body.removeChild(span);
            }
        }
    }

    private mapCharset(lang: string): string {
        switch (lang) {
            case 'bg':
            case 'ru':
                return 'bg';
            case 'el':
                return 'el';
            default:
                return 'en';
        }
    }

    private getCharsetForLang(lang: string): string {
        return charsets[this.mapCharset(lang)];
    }
}
