import { Injectable } from '@angular/core';

import { Optional } from '@frontend/sports/common/base-utils';

import { NOT_APPLICABLE, TrackData, trackingConstants } from '../tracking/tracking.models';
import { TrackingService } from '../tracking/tracking.service';
import { NumpadAction, StakeModel, Tracking } from './model';

@Injectable({ providedIn: 'root' })
export class NumpadTrackingService {
    constructor(private trackingService: TrackingService) {}

    getBaseTrackData(): Partial<TrackData> {
        return {
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: 'Betting',
            [trackingConstants.COMPONENT_LABEL_EVENT]: 'In-Line KeyPad',
            [trackingConstants.COMPONENT_POSITION_EVENT]: 'In-Line',
            [trackingConstants.COMPONENT_EVENT_DETAILS]: Tracking.NotApplicable,
            [trackingConstants.COMPONENT_URL_CLICKED]: Tracking.NotApplicable,
        };
    }

    trackOpening(location: string): void {
        this.track({
            [trackingConstants.COMPONENT_LOCATION_EVENT]: location,
            [trackingConstants.COMPONENT_ACTION_EVENT]: Tracking.Opening,
        });
    }

    trackConfirmation(location: string): void {
        this.track({
            [trackingConstants.COMPONENT_LOCATION_EVENT]: location,
            [trackingConstants.COMPONENT_ACTION_EVENT]: Tracking.Confirmation,
        });
    }

    trackDeleteButton(location: string): void {
        this.track({
            [trackingConstants.COMPONENT_LOCATION_EVENT]: location,
            [trackingConstants.COMPONENT_ACTION_EVENT]: Tracking.Delete,
        });
    }

    trackIncrement(stake: StakeModel, location: string): void {
        const trackingData = {
            [trackingConstants.COMPONENT_CATEGORY_EVENT]: 'Betting',
            [trackingConstants.COMPONENT_LABEL_EVENT]: 'In-Line KeyPad',
            [trackingConstants.COMPONENT_ACTION_EVENT]: 'click',
            [trackingConstants.COMPONENT_POSITION_EVENT]: 'Fixed value button',
            [trackingConstants.COMPONENT_CONTENT_POSITION]: stake.position,
            [trackingConstants.COMPONENT_LOCATION_EVENT]: location,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: stake.numpadInput?.toString(),
            [trackingConstants.COMPONENT_URL_CLICKED]: NOT_APPLICABLE,
        };
        this.track(trackingData);
    }

    getStakeSelectedData(location: string, stake: Optional<string>, action: Optional<NumpadAction>): Partial<TrackData> {
        return {
            ...this.getBaseTrackData(),
            [trackingConstants.COMPONENT_ACTION_EVENT]: 'Stake Selected',
            [trackingConstants.COMPONENT_POSITION_EVENT]: this.mapAction(action),
            [trackingConstants.COMPONENT_LOCATION_EVENT]: location,
            [trackingConstants.COMPONENT_EVENT_DETAILS]: stake || '',
        };
    }

    mapAction(action: Optional<NumpadAction>): string {
        let result = 'Default stake';

        switch (action) {
            case NumpadAction.IncrementalStakeUsed:
                result = 'Incremental Stake Quick Stake';
                break;
            case NumpadAction.KeypadTyped:
                result = 'Keyboard typed';
                break;
            case NumpadAction.KeypadUsed:
                result = 'Keypad';
                break;
        }

        return result;
    }

    private track(additionalTrackData: Partial<TrackData>): void {
        const fullTrackData = { ...this.getBaseTrackData(), ...additionalTrackData };

        this.trackingService.track(trackingConstants.EVENT_TRACKING, fullTrackData);
    }
}
