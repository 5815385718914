import { Injectable } from '@angular/core';

import { NumberDictionary, SportConstant } from '@frontend/sports/common/base-utils';
import { AnimationConfig, BetradarVisualizationConfig } from '@frontend/sports/common/client-config-data-access';
import { keyBy } from 'lodash-es';

class SportAnimation {
    [key: number]: boolean;
}

@Injectable({ providedIn: 'root' })
export class AnimationProviderService {
    private readonly ms2Animations: SportAnimation;
    private readonly betRadarStickySupportedSports: NumberDictionary<number>;
    private readonly betRadarSupportedSports: NumberDictionary<number>;
    private readonly pitchLogoSupportedSports: NumberDictionary<number>;
    private readonly sideLineLogoSupportedSports: NumberDictionary<number>;

    constructor(
        animationConfig: AnimationConfig,
        private betradarVisualizationConfig: BetradarVisualizationConfig,
    ) {
        this.ms2Animations = {
            [SportConstant.Tennis]: animationConfig.isTennisAnimationEnabled,
            [SportConstant.Basketball]: animationConfig.isBasketballAnimationEnabled,
            [SportConstant.Icehockey]: animationConfig.isIceHockeyAnimationEnabled,
        };
        this.betRadarSupportedSports = keyBy(this.betradarVisualizationConfig.supportedSports, (sport) => sport);
        this.betRadarStickySupportedSports = keyBy(this.betradarVisualizationConfig.stickyAddonSupportedSports, (sport) => sport);
        this.pitchLogoSupportedSports = keyBy(this.betradarVisualizationConfig.pitchLogoEnabled, (sport) => sport);
        this.sideLineLogoSupportedSports = keyBy(this.betradarVisualizationConfig.sideLineLogoEnabled, (sport) => sport);
    }

    get sportAnimations(): SportAnimation {
        return this.ms2Animations;
    }

    get betRadarStickySupportedAnimations(): NumberDictionary<number> {
        return this.betRadarStickySupportedSports;
    }

    get betRadarSupportedAnimations(): NumberDictionary<number> {
        return this.betRadarSupportedSports;
    }

    get pitchLogoEnabledSports(): NumberDictionary<number> {
        return this.pitchLogoSupportedSports;
    }

    get sideLineLogoEnabledSports(): NumberDictionary<number> {
        return this.sideLineLogoSupportedSports;
    }

    get betRadarSupportedCoverageLevel(): { [sportId: number]: number } {
        return this.betradarVisualizationConfig.supportedCoverageLevel;
    }

    get teamInvertEnabledCompetitions(): { [sportId: number]: number[] } {
        return this.betradarVisualizationConfig.teamInvertCompetitions;
    }
}
