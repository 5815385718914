import { ComboPreventionType2 } from '@cds/betting-offer';

export enum MinimumCombination {
    /// <summary>
    /// No value was chosen for MinimumCombo.
    /// </summary>
    Default = 0,

    /// <summary>
    /// There must be at least one bet in the betslip (the current bet counts,
    /// so this effectively says that the current bet can be used on its own).
    /// </summary>
    Single = 1,

    /// <summary>
    /// There must be at least two bets in the betslip.
    /// </summary>
    Combo2 = 2,

    /// <summary>
    /// There must be at least three bets in the betslip.
    /// </summary>
    Combo3 = 3,

    /// <summary>
    /// There must be at least four bets in the betslip.
    /// </summary>
    Combo4 = 4,

    /// <summary>
    /// There must be at least five bets in the betslip.
    /// </summary>
    Combo5 = 5,

    /// <summary>
    /// There must be at least six bets in the betslip.
    /// </summary>
    Combo6 = 6,
}

export function toMinimumCombo(status: ComboPreventionType2 | number): MinimumCombination {
    switch (status) {
        case ComboPreventionType2.Combo2:
            return MinimumCombination.Combo2;
        case ComboPreventionType2.Combo3:
            return MinimumCombination.Combo3;
        case ComboPreventionType2.Combo4:
            return MinimumCombination.Combo4;
        case ComboPreventionType2.Combo5:
            return MinimumCombination.Combo5;
        case ComboPreventionType2.Combo6:
            return MinimumCombination.Combo6;
    }

    return MinimumCombination.Single;
}

export function toMinimumComboTV2(status?: number): MinimumCombination {
    if (!status) {
        return MinimumCombination.Single;
    }

    switch (status) {
        case 2:
            return MinimumCombination.Combo2;
        case 3:
            return MinimumCombination.Combo3;
        case 4:
            return MinimumCombination.Combo4;
        case 5:
            return MinimumCombination.Combo5;
        case 6:
            return MinimumCombination.Combo6;
    }

    return MinimumCombination.Single;
}
