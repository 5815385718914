import { PlacementErrorType } from '@frontend/sports/types/betslip';
import { createSelector } from '@ngrx/store';

import { betslipSelector, selectIsLinearBetslip } from '../../base/store/selectors';
import { BetslipType } from '../../core/betslip-type';
import { PickId } from '../../core/picks/pick-id';
import { SlipId } from '../bet-generation/models';
import { getBetBuilderSlipId, getSingleSlipId } from '../bet-generation/services/slip.utils';
import { LinearBetBuilderPickId } from '../linear-bet-builder/models';
import { selectBetBuilderPickStakeFactory } from '../linear-bet-builder/selectors';
import { betslipPicksListSelector, selectBetslipFlattenedPicksList } from '../picks/selectors';
import { selectSingleBetAllSameStake, selectSingleBetPickStakeFactory, singleBetPicksGeneralStakeSelector } from '../single-bet/selectors';
import { selectLinearStakeForSlip } from '../stake/selectors';
import { selectIsSystemBetExpanded } from '../system-bet/selectors';
import { betslipCurrentTypeSelector } from '../types/base/selectors';
import { betslipTypeStateSelector, editBetAddedPicksListSelector, editBetPicksListSelector, selectIsSingleBetSelected } from '../types/selectors';
import { BetslipError, ErrorOrigin } from './errors/betslip-error';
import { MinSelectionsBetbuilderError } from './errors/general/min-selections-betbuilder-error';
import { getSlipErrorsForType } from './services/utils/betslip-errors-state-utils';
import {
    canShowPickErrorAsBetslipError,
    checkOfferChanged,
    checkOfferChangedLinear,
    isBetslipStatusInsufficientMoney,
    isDisplayablePickError,
    isDrawerGroupError,
    isErrorComboPrevention,
    isGroupPickError,
    isGroupPickOfferChange,
    isNotEnoughMoney,
    isPickStatusChangeError,
    isRewardTokensError,
    isStakeError,
} from './services/utils/betslip-errors-utils';
import { BetslipTypePickErrors, SlipErrors } from './state';

// State

/** Selects the entire error state */
export const selectErrorsState = createSelector(betslipSelector, (state) => state.errors);

/** Selects the state for errors that effect the entire betslip */
export const selectBetslipErrors = createSelector(selectErrorsState, (state) => state.betslipErrors);

/** Selects the state for errors that affect individual picks*/
export const selectPickErrorsState = createSelector(selectErrorsState, (errorsState) => errorsState.pickErrors);

/** Selects the state for errors that affect individual slips (multi-singles, combo, system) */
export const selectSlipErrorsState = createSelector(selectErrorsState, (errorState) => errorState.slipErrors);

/** Selects the state for errors that affect individual betslip types (single, combo, system, teaser) */
export const selectTypeErrorsState = createSelector(selectErrorsState, (errorState) => errorState.betslipTypeErrors);

// Slip Errors

export const selectSlipErrorsFactory = (slipKey: SlipId) =>
    createSelector(selectSlipErrorsState, (slipErrors: SlipErrors): BetslipError[] | undefined => slipErrors[slipKey]);

export const selectSlipErrorsForTypeFactory = (activeType: BetslipType) =>
    createSelector(selectSlipErrorsState, betslipTypeStateSelector, selectIsLinearBetslip, (state, types, isLinear) => {
        return getSlipErrorsForType(activeType, state, types, isLinear);
    });

export const selectCurrentSlipErrors = createSelector(
    selectSlipErrorsState,
    betslipTypeStateSelector,
    selectIsLinearBetslip,
    (state, types, isLinear) => {
        const currentType = types.base.currentSelectedType;

        return !currentType ? [] : getSlipErrorsForType(currentType, state, types, isLinear);
    },
);

/**
 * Selects all slip errors for a multi single pick with the given PickId
 */
export const selectSingleSlipErrorsFactory = (pickId: PickId) =>
    createSelector(selectSlipErrorsState, (errorState) => errorState[getSingleSlipId(pickId)] ?? []);

export const selectBetBuilderSlipErrorsFactory = (pickId: PickId) =>
    createSelector(selectSlipErrorsState, (errorState) => errorState[getBetBuilderSlipId(pickId)] ?? []);

export const selectHasAnySlipErrors = createSelector(selectSlipErrorsState, (state) => Object.values(state).some((p) => p.length > 0));

// Betslip Type Errors

export const selectBetslipTypeErrorsFactory = (type: BetslipType) => createSelector(selectTypeErrorsState, (state) => state[type] ?? []);

export const selectCurrentBetslipTypeErrors = createSelector(selectTypeErrorsState, betslipCurrentTypeSelector, (state, type) =>
    type ? state[type] : [],
);

export const selectHasAnyTypeErrors = createSelector(selectTypeErrorsState, (state) => Object.values(state).some((p) => p.length > 0));

// Betslip Errors

export const selectAllBetslipErrorsForType = (type: BetslipType) =>
    createSelector(
        selectBetslipErrors,
        selectBetslipTypeErrorsFactory(type),
        selectSlipErrorsForTypeFactory(type),
        (betslipErrors, typeErrors, slipErrors) => {
            return [...betslipErrors, ...typeErrors, ...slipErrors];
        },
    );

export const selectAllCurrentBetslipErrors = createSelector(
    selectBetslipErrors,
    selectCurrentBetslipTypeErrors,
    selectCurrentSlipErrors,
    (betslipErrors, typeErrors, slipErrors) => {
        return [...betslipErrors, ...typeErrors, ...slipErrors];
    },
);

export const selectAllBetBuilderErrors = createSelector(
    selectBetslipErrors,
    selectSlipErrorsForTypeFactory(BetslipType.BetBuilder),
    selectBetslipTypeErrorsFactory(BetslipType.BetBuilder),
    (betslipErrors, typeErrors, slipErrors) => {
        return [...betslipErrors, ...typeErrors, ...slipErrors];
    },
);

export const selectHasAnyBetslipErrors = createSelector(selectBetslipErrors, (errors) => errors.length > 0);

// Pick Errors

export const selectPickErrorsForTypeFactory = (type: BetslipType) =>
    createSelector(selectErrorsState, (state) => {
        return state.pickErrors[type] ?? {};
    });

export const selectAllPickErrorsForTypeFactory = (type: BetslipType) =>
    createSelector(selectPickErrorsForTypeFactory(type), (pickErrors) => {
        return Object.values(pickErrors).flatMap((errors) => errors) ?? [];
    });

export const selectAllPickErrors = createSelector(selectPickErrorsState, (pickErrors) =>
    Object.values(pickErrors).flatMap((errors) => Object.values(errors).flatMap((e) => e)),
);

export const selectTypePickErrors = createSelector(selectErrorsState, (state) => {
    return state.pickErrors;
});

/**
 * Selects all errors for a pick with the given PickId for the currently selected betslip type (tabbed betslip)
 */
export const selectCurrentPickErrorsForPickFactory = (pickId: PickId) =>
    createSelector(selectPickErrorsState, betslipCurrentTypeSelector, (pickErrors, type) => {
        if (!type) {
            return [];
        }

        return pickErrors[type][pickId.toString()] ?? [];
    });

/** Selects all errors for a pick with the given PickId for the given betslip type */
export const selectTypePickErrorsForPickFactory = (pickId: PickId, type: BetslipType) =>
    createSelector(selectPickErrorsState, (pickErrors) => {
        return pickErrors[type][pickId.toString()] ?? [];
    });

export const selectAllPickErrorsForPickFactory = (pickId: PickId) =>
    createSelector(selectPickErrorsState, (pickErrors) => {
        const pickIdString = pickId.toString();

        return Object.values(pickErrors).flatMap((errors) => errors[pickIdString] ?? []);
    });

export const selectCurrentPicksErrors = createSelector(selectPickErrorsState, betslipCurrentTypeSelector, (pickErrors, type) =>
    type ? pickErrors[type] : {},
);

export const selectAllCurrentPickErrors = createSelector(selectCurrentPicksErrors, (currentPicks) =>
    Object.values(currentPicks).flatMap((picks) => picks),
);

export const selectCurrentPicksErrorsForAllPicks = createSelector(selectCurrentPicksErrors, (picksErrors) =>
    Object.values(picksErrors).flatMap((p) => p),
);

export const selectHasAnyPickErrors = createSelector(selectPickErrorsState, (pickErrors) =>
    Object.values(pickErrors).some((p) => Object.values(p).some((e) => e.length > 0)),
);

export const selectPicksWithCurrentErrors = createSelector(betslipPicksListSelector, selectCurrentPicksErrors, (pickList, pickErrors) =>
    pickList.map((pick) => ({
        pick,
        errors: pickErrors[pick.id.toString()] || [],
    })),
);

export const selectPicksWithAllErrors = createSelector(selectBetslipFlattenedPicksList, selectPickErrorsState, (pickList, pickErrors) =>
    pickList.map((pick) => ({
        pick,
        errors: Object.values(pickErrors).flatMap((errors) => errors[pick.id.toString()] ?? []),
    })),
);

export const selectEditBetPicksWithErrors = createSelector(
    editBetPicksListSelector,
    editBetAddedPicksListSelector,
    selectPickErrorsForTypeFactory(BetslipType.EditBet),
    (pickList, pickAddedList, pickErrors) =>
        [...pickList, ...pickAddedList].map((pick) => ({
            pick,
            errors: pickErrors[pick.id.toString()] || [],
        })),
);

// Specific selectors
export const selectAllSingleBetBetslipErrors = createSelector(selectAllBetslipErrorsForType(BetslipType.Single), (errors) => errors);

export const selectAllCurrentErrors = createSelector(selectAllCurrentBetslipErrors, selectAllCurrentPickErrors, (betslipErrors, pickErrors) => [
    ...betslipErrors,
    ...pickErrors,
]);

export const selectNotEnoughMoneyError = createSelector(selectBetslipErrors, (errors) => errors.find(isNotEnoughMoney));

export const selectCurrentVisibleBetslipErrorsByPriority = createSelector(selectAllCurrentBetslipErrors, (errors) =>
    errors.filter((e) => !e.isHidden).sort((a, b) => b.priority - a.priority),
);

export const selectHasCurrentComboPreventionError = createSelector(selectCurrentPicksErrorsForAllPicks, (errors) =>
    errors.some(isErrorComboPrevention),
);

export const selectHasComboPreventionForTypeFactory = (type: BetslipType) =>
    createSelector(selectAllPickErrorsForTypeFactory(type), (errors) => errors.some(isErrorComboPrevention));

export const selectHasInsufficientMoneyError = createSelector(selectBetslipErrors, (errors) => errors.some(isBetslipStatusInsufficientMoney));

export const selectHasNonPickErrors = createSelector(
    selectHasAnyBetslipErrors,
    selectHasAnyTypeErrors,
    selectHasAnySlipErrors,
    (hasBetslipErrors, hasTypeErrors, hasSlipErrors) => hasBetslipErrors || hasTypeErrors || hasSlipErrors,
);

export const selectHasAnyErrors = createSelector(
    selectHasNonPickErrors,
    selectHasAnyPickErrors,
    (hasNonPickErrors, hasPickErrors) => hasNonPickErrors || hasPickErrors,
);

export const selectHasMinSelectionsBetBuilderError = createSelector(selectBetslipErrors, (errors) =>
    errors.some((error) => error instanceof MinSelectionsBetbuilderError),
);

export const selectCurrentStakeError = createSelector(
    selectAllCurrentBetslipErrors,
    selectIsSingleBetSelected,
    selectSingleBetAllSameStake,
    (errors, isSingleBet, singleBetAllSameStake) =>
        isSingleBet && !singleBetAllSameStake
            ? errors.find((betslipError) => betslipError.type === PlacementErrorType.InsufficientMoney)
            : errors.find(isStakeError),
);

export const selectCanShowPickErrorAsBetslipError = createSelector(selectAllCurrentBetslipErrors, betslipCurrentTypeSelector, (betslipErrors, type) =>
    canShowPickErrorAsBetslipError(betslipErrors, type),
);

export const selectHasOfferChanged = createSelector(
    selectAllPickErrors,
    betslipPicksListSelector,
    selectIsLinearBetslip,
    betslipTypeStateSelector,
    (pickErrors, picks, isLinear, types) => (isLinear ? checkOfferChangedLinear(picks, types, pickErrors) : checkOfferChanged(pickErrors)),
);

export const selectHasOfferChangedFromBetPlacement = createSelector(selectAllPickErrors, (errors) =>
    errors.some((e) => isPickStatusChangeError(e) && e.origin === ErrorOrigin.BetPlacement),
);

export const selectDisplayablePickErrorsFactory = (pickId: PickId, betslipType: BetslipType) =>
    createSelector(selectTypePickErrorsForPickFactory(pickId, betslipType), (errors) => errors.filter((e) => isDisplayablePickError(e)));

export const selectTabbedPickErrorsFactory = (pickId: PickId, betslipType: BetslipType) =>
    createSelector(selectDisplayablePickErrorsFactory(pickId, betslipType), selectCanShowPickErrorAsBetslipError, (errors, hidePickErrors) =>
        !hidePickErrors ? errors : [],
    );

export const selectCurrentSummarySlipErrors = createSelector(
    selectSlipErrorsState,
    betslipTypeStateSelector,
    selectIsLinearBetslip,
    (state, types, isLinear) => {
        const currentType = types.base.currentSelectedType;

        return !currentType || currentType === BetslipType.Single ? [] : getSlipErrorsForType(currentType, state, types, isLinear);
    },
);

export const selectAllCurrentSummaryErrors = createSelector(
    selectBetslipErrors,
    selectCurrentBetslipTypeErrors,
    selectCurrentSummarySlipErrors,
    selectAllCurrentPickErrors,
    selectCanShowPickErrorAsBetslipError,
    (betslipErrors, typeErrors, slipErrors, pickErrors, showPickErrors) => {
        return !showPickErrors ? [...betslipErrors, ...typeErrors, ...slipErrors] : [...betslipErrors, ...typeErrors, ...slipErrors, ...pickErrors];
    },
);

export const selectSingleBetPickErrorsFactory = (pickId: PickId) =>
    createSelector(
        selectTypePickErrorsForPickFactory(pickId, BetslipType.Single),
        selectSingleSlipErrorsFactory(pickId),
        (pickErrors, slipErrors) => [...pickErrors, ...slipErrors],
    );

export const selectSinglePickErrorStateFactory = (pickId: PickId) =>
    createSelector(selectSingleBetPickErrorsFactory(pickId), selectSingleBetPickStakeFactory(pickId), (errors, stake) => ({
        errors,
        stake,
    }));

export const selectBetBuilderBetPickErrorsFactory = (pickId: PickId) =>
    createSelector(
        selectDisplayablePickErrorsFactory(pickId, BetslipType.BetBuilder),
        selectBetBuilderSlipErrorsFactory(pickId),
        (pickErrors, slipErrors) => [...pickErrors, ...slipErrors],
    );

export const selectBetBuilderPickErrorStateFactory = (pickId: LinearBetBuilderPickId) =>
    createSelector(selectBetBuilderBetPickErrorsFactory(pickId), selectBetBuilderPickStakeFactory(pickId), (errors, stake) => ({
        errors,
        stake,
    }));

export const selectSinglePickStakeErrorStateFactory = (pickId: PickId) =>
    createSelector(
        selectSinglePickErrorStateFactory(pickId),
        singleBetPicksGeneralStakeSelector,
        selectHasInsufficientMoneyError,
        (state, generalStake, hasInsufficientFunds) => ({
            stakeError: state.errors.find(isStakeError),
            stake: state.stake,
            showInsufficientFundsError: hasInsufficientFunds && !!state.stake?.actualStake && !generalStake?.actualStake,
        }),
    );

export const selectLinearSlipErrorStateFactory = (slipId: SlipId, betslipType: BetslipType) =>
    createSelector(
        selectSlipErrorsFactory(slipId),
        selectLinearStakeForSlip(slipId, betslipType),
        // We should not have any slip errors displayed when we have a combo prevention error
        selectHasComboPreventionForTypeFactory(betslipType),
        (errors, stake, hasComboPrevention) => ({ errors: hasComboPrevention ? [] : errors, stake }),
    );

const selectDrawerGroupErrorFactory = (pickId: PickId) =>
    createSelector(selectGroupErrors, (typeErrors) => {
        return typeErrors
            .filter(isDrawerGroupError)
            .find((invalidPriceError) => invalidPriceError.invalidPickIds.some((invalidid) => invalidid.isEqual(pickId)));
    });

const selectGroupErrors = createSelector(selectCurrentBetslipTypeErrors, (typeErrors) => {
    return typeErrors.filter(isGroupPickError);
});

const selectGroupOfferChangeErrorsFactory = (pickId: PickId) =>
    createSelector(selectAllPickErrorsForPickFactory(pickId), (pickErrors) => {
        return pickErrors.filter(isGroupPickOfferChange);
    });

export const selectRelevantGroupErrorsForPickFactory = (pickId: PickId) =>
    createSelector(selectDrawerGroupErrorFactory(pickId), selectGroupOfferChangeErrorsFactory(pickId), (groupPickError, groupOfferChange) => ({
        groupPickError,
        groupOfferChange,
    }));

export const selectAllDisplayablePickErrorsForTypeFactory = (type: BetslipType) =>
    createSelector(selectAllPickErrorsForTypeFactory(type), (pickErrors) => {
        return pickErrors.filter(isDisplayablePickError);
    });

export const selectTypeErrors = (type: BetslipType) =>
    createSelector(
        selectBetslipTypeErrorsFactory(type),
        selectSlipErrorsForTypeFactory(type),
        selectAllDisplayablePickErrorsForTypeFactory(type),
        (typeErrors, slipErrors, pickErrors) => {
            return [...typeErrors, ...slipErrors, ...pickErrors];
        },
    );

export const selectSystemContainerErrors = createSelector(
    selectBetslipTypeErrorsFactory(BetslipType.System),
    selectAllDisplayablePickErrorsForTypeFactory(BetslipType.System),
    (typeErrors, pickErrors) => {
        return [...typeErrors, ...pickErrors];
    },
);

export const selectContainerErrors = (type: BetslipType) =>
    createSelector(selectIsLinearBetslip, selectSystemContainerErrors, selectTypeErrors(type), (isLinear, systemContainerErrors, typeErrors) => {
        return isLinear && type === BetslipType.System ? systemContainerErrors : typeErrors;
    });

export const selectComboContainerModuleErrors = createSelector(selectTypeErrors(BetslipType.Combo), (errors) => {
    return errors;
});
export const selectCurrentSlipTokenErrors = createSelector(selectCurrentSlipErrors, (slipErrors) => slipErrors.filter(isRewardTokensError));
export const selectBetBuilderDrawerErrors = createSelector(selectCurrentBetslipTypeErrors, selectCurrentSlipTokenErrors, (errors, slipErrors) => [
    ...errors,
    ...slipErrors,
]);

export const selectLinearGlobalIndiactorErrorsForPickFactory = (pickId: PickId) =>
    createSelector(selectPickErrorsState, selectIsSystemBetExpanded, (pickErrors, isSystemBetExpanded) => {
        const pickIdString = pickId.toString();

        const globalIndicatorErrors: BetslipTypePickErrors = {
            ...pickErrors,
            SYSTEM: isSystemBetExpanded ? pickErrors.SYSTEM : {},
        };

        return Object.values(globalIndicatorErrors).flatMap((errors) => errors[pickIdString] ?? []);
    });
