import { Injectable } from '@angular/core';

import { OddsBoostFormula } from '@bpos';
import { Nullable } from '@frontend/sports/common/base-utils';
import { AccaBoostConfig, BetslipConfig } from '@frontend/sports/common/client-config-data-access';
import { NativePrice } from '@frontend/sports/common/odds-lib';
import { AccaBoostCalculations } from '@frontend/sports/types/components/acca-boost';
import { OddsDisplayFormat } from '@frontend/sports/types/models/user-settings';
import { Decimal } from 'decimal.js';
import { CalculatedOdds } from 'packages/sports/common/betslip/core/odds/odds';
import { OddsDisplayFormat as BetslipOddsDisplayFormat } from 'packages/sports/common/betslip/core/odds/odds-display-format';
import { OddsOperations } from 'packages/sports/common/betslip/core/odds/operations';

import { OddsFormatCalculatorService } from '../native-odds/odds-format-calculator.service';

@Injectable({ providedIn: 'root' })
export class TokensCalculatorService {
    constructor(
        private betslipConfig: BetslipConfig,
        private accaBoostConfig: AccaBoostConfig,
    ) {}

    getBoostedNativeOdds(price: Nullable<NativePrice> | number, boostFactor: number, oddsFormat?: OddsDisplayFormat): Nullable<NativePrice> {
        const boostMultiplier = new Decimal(boostFactor).dividedBy(100).plus(1);

        if (typeof price === 'number') {
            const result = OddsFormatCalculatorService.multiplyDecimal(new Decimal(price), boostMultiplier, this.betslipConfig.roundPriceValues);

            return NativePrice.fromDecimalsElement(result.toNumber());
        }

        return price
            ? OddsFormatCalculatorService.multiply(
                  price,
                  boostMultiplier.toNumber(),
                  oddsFormat || OddsDisplayFormat.EU,
                  this.betslipConfig.roundPriceValues,
              )
            : null;
    }

    getBoostedNativeOdds2(
        price: CalculatedOdds | null,
        boostFactor: number,
        oddsBoostFormula: OddsBoostFormula,
        oddsFormat?: BetslipOddsDisplayFormat,
    ): CalculatedOdds | null {
        if (!price) {
            return null;
        }

        const boostFactorDecimalValue = new Decimal(boostFactor).dividedBy(100);
        const boostMultiplier = boostFactorDecimalValue.plus(1);
        const decimal = OddsOperations.toDecimalValue(price, oddsFormat);
        let boostedOdds = OddsFormatCalculatorService.multiplyDecimal(decimal, boostMultiplier, this.betslipConfig.roundPriceValues);
        if (oddsBoostFormula === OddsBoostFormula.Profit) {
            boostedOdds = boostedOdds.minus(boostFactorDecimalValue);
        }

        return OddsOperations.fromDecimalValue(boostedOdds);
    }

    // if no riskFree or betandget Percentage or stake is supplied, then no calculation will happen
    // and maxPayout is considered as returnAmount
    getTokenPercentageReturns(maxPayout: number, riskFreePercentage?: number, stake?: number, minPayout?: number): number {
        if (riskFreePercentage !== undefined && stake !== undefined) {
            const decimalPercentage = new Decimal(riskFreePercentage);
            const decimalStake = new Decimal(stake);
            const payout = decimalPercentage.times(decimalStake).div(100).toNumber();
            if (minPayout && payout < minPayout) {
                return minPayout;
            }

            return Math.min(maxPayout, payout);
        } else {
            return maxPayout;
        }
    }

    getAccaBoostReturns(possibleWinnings: Decimal, stake: number, maxBoost: Decimal, boostRatio: Decimal): Decimal {
        if (this.accaBoostConfig.accaBoostCalculation === AccaBoostCalculations.Profit) {
            possibleWinnings = possibleWinnings.minus(stake);
        }
        const calculatedBoost = possibleWinnings.times(boostRatio);

        return Decimal.min(calculatedBoost, maxBoost);
    }
}
