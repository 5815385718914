import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { OfferSource } from '@cds';
import { Store } from '@ngrx/store';
import { difference, flattenDeep, values } from 'lodash-es';
import { filter, map, pairwise, withLatestFrom } from 'rxjs/operators';

import { FavouriteType, FavouritesViewModel } from '../favourites/favourites.model';
import { FavouritesService } from '../favourites/favourites.service';
import { isFixtureFavourite } from '../favourites/helpers';
import { UserService } from '../user/services/user.service';
import { GridActions } from './grid.actions';
import { GridEvent } from './grid.model';
import { IGridRootState, IGridState, gridStateSelector } from './grid.state';

@Injectable({ providedIn: 'root' })
export class GridFavouriteService {
    constructor(
        private favouriteService: FavouritesService,
        private store: Store<IGridRootState>,
        private user: UserService,
    ) {
        this.favouriteService.change
            .pipe(
                map((change) => change.favourites.filter(isFixtureFavourite)),
                pairwise(),
                map(([previousChange, currentChange]) => [
                    ...difference(previousChange, currentChange), // added stuff
                    ...difference(currentChange, previousChange), // removed stuff
                ]),
                filter((change) => change.length !== 0),
                withLatestFrom(this.store.select(gridStateSelector)),
                takeUntilDestroyed(),
            )
            .subscribe(([change, state]) => this.updateFavourited(change, state));
    }

    isFavourited(event: GridEvent): boolean | undefined {
        if (!this.user.isAuthenticated) {
            return;
        }

        return !!this.favouriteService.get(event.id, event.sport.id, this.getType(event));
    }

    private updateFavourited(diff: FavouritesViewModel[], state: IGridState): void {
        if (diff.length === 0) {
            return;
        }

        const grids = values(state);

        for (const grid of grids) {
            const events = flattenDeep<GridEvent>(grid.groups.map((group) => group.events));
            const changed = events.filter((event) => diff.some((c) => c.itemId === event.id));

            for (const change of changed) {
                change.favourited = this.isFavourited(change);
                this.store.dispatch(
                    GridActions.updateEvents({
                        payload: {
                            id: grid.id,
                            event: change,
                        },
                    }),
                );
            }
        }
    }

    private getType(event: GridEvent): FavouriteType {
        if (!event.offerSource || event.offerSource === OfferSource.V1) {
            return FavouriteType.Fixture;
        }

        return FavouriteType.FixtureV2;
    }
}
